<div>
    <mat-dialog-actions>
        <Button mat-dialog-close style="margin-left: 90%;">
            <mat-icon>close</mat-icon>
        </Button>
    </mat-dialog-actions>
</div>
<div>
    <form [formGroup]="updateForm" (ngSubmit)="updateData()">
        <div class="form-row">
            <mat-form-field style="width: 80%; margin-top: 10%; margin-left: 10%" appearance="outline">
                <mat-label>Category:</mat-label>
                <input matInput formControlName="category" />
            </mat-form-field>
        </div>
        <div class="form-row">
            <mat-form-field style="width: 80%; margin-top: 10px; margin-left: 10%" appearance="outline">
                <mat-label>Advt_size:</mat-label>
                <input matInput formControlName="Advt_size" />
            </mat-form-field>
        </div>
        <div class="form-group">
            <button style="margin-left: 35%;margin-top: 5%; width: 30%;" type="submit" mat-raised-button
                class="btn btn-info" [disabled]="updateForm.invalid">Update</button>
        </div>
    </form>
</div>