<div mat-dialog-content>
    <div mat-dialog-actions style="float:right" >
        <button mat-button mat-dialog-close>Close</button>
    </div>
    <div class="text-center">
        <h1 *ngIf="!updateMode"  style="font-size: medium;font-weight: bold;">Add New Subscriber</h1>
        <h1 *ngIf="updateMode" style="font-size: medium;font-weight: bold;">Update Subscriber</h1>
    </div>


    <div class="add-new-subscriber mat-elevation-z4">
        <form class="form" [formGroup]="form" (ngSubmit)=openModal()>
            <div class="form-row">

                <mat-form-field style="width: 10%; margin-left: 5%; margin-top: 20px;" appearance="fill">
                    <mat-label>Title</mat-label>
                    <mat-select formControlName="title" required>
                        <mat-option *ngFor="let c of titles" value="{{c['title']}}">{{c['title']}}</mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field class="example-full-width" style=" width:60%;margin-left: 1%; margin-top: 33px;"
                    [style.color]="validatefname?null:'red'">
                    <mat-label>Name</mat-label>
                    <input matInput (change)="validateName($event)" formControlName="name" required>

                    <mat-error *ngIf="validatefname">
                        Please enter a valid name
                    </mat-error>
                    <mat-error *ngIf="validatefname">
                        Name is <strong>required</strong>
                    </mat-error>

                </mat-form-field>
            </div>

            <div class="form-group">

                <mat-form-field class="example-full-width" style=" width:85%;margin-left: 5%; margin-top: 20px;"
                    appearance="fill">
                    <mat-label>Address</mat-label>
                    <textarea matInput placeholder="Address" formControlName="address" required></textarea>
                </mat-form-field>
            </div>



            <div class="form-row">
                <mat-form-field style="margin-left: 5%; margin-top: 20px;" class="example-full-width"
                    appearance="fill">
                    <mat-label>Pincode</mat-label>
                    <input (change)="getPincode($event)" matInput #postalCode maxlength="6" placeholder=""
                        formControlName="pincode">
                    <mat-hint align="end">{{postalCode.value.length}} / 6</mat-hint>
                </mat-form-field>

                <mat-form-field style="width: 30%; margin-left: 3%; margin-top: 20px;" appearance="fill">
                    <mat-label>Country</mat-label>
                    <mat-select (selectionChange)="getCountry($event)" formControlName="country" required>
                        <mat-option *ngFor="let c of countries" value="{{c['name']}}">{{c['name']}}</mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field style="width: 30%; margin-left: 3%; margin-top: 20px;" appearance="fill">
                    <mat-label>State</mat-label>
                    <mat-select (selectionChange)="getState($event)" formControlName="state" required>
                        <mat-option *ngFor="let c of states" value="{{c['state_name']}}">{{c['state_name']}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <div class="form-row">
                <mat-form-field style="width: 30%; margin-left: 5%; margin-top: 20px;" appearance="fill">
                    <mat-label>City</mat-label>
                    <mat-select formControlName="city" required>
                        <mat-option *ngFor="let c of cities" value="{{c['name']}}">{{c['name']}}</mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field class="example-full-width"
                    style="width: 30%; margin-left: 3%; margin-top: 33px;">
                    <mat-label>Taluka</mat-label>
                    <input matInput formControlName="taluka" required>
                </mat-form-field>

            </div>

            <div class="form-row">

                <mat-form-field class="example-full-width" style="margin-left: 5%; margin-top: 20px;"
                    [style.color]="validmobile?null:'red'">
                    <mat-label>Mobile</mat-label>
                    <span matPrefix>+91 &nbsp;</span>
                    <input required type="tel" #mobiles (change)="validateMobile($event)" maxlength="10"
                        formControlName="mobileNumber" matInput [readonly]="updateMode">
                    <mat-hint align="end">{{mobiles.value.length}} / 10</mat-hint>
                    <mat-error *ngIf="validmobile">
                        Please enter a valid mobile no.
                    </mat-error>
                    <mat-error *ngIf="validmobile">
                        Mobile no. is <strong>required</strong>
                    </mat-error>
                </mat-form-field>


                <mat-form-field class="example-full-width"
                    style="width: 35%; margin-left: 3%; margin-top: 20px;"
                    [style.color]="validemail?null:'red'">
                    <mat-label>Email</mat-label>
                    <input (change)="validateEmail($event)" type="email" matInput formControlName="email"
                        placeholder="Ex. pat@example.com">
                    <mat-error *ngIf="validemail">
                        Please enter a valid email address
                    </mat-error>
                    <mat-error *ngIf="validemail">
                        Email is <strong>required</strong>
                    </mat-error>
                </mat-form-field>

            </div>

            <div class="form-row">
                <div class="form-group" [style.color]="validdob?null:'red'">
                    <label style="margin-left:10%; margin-top: 20px;">Date of Birth : </label>
                    <mat-form-field appearance="fill" style="margin-left: 10%;">
                        <mat-label>Choose a date</mat-label>
                        <input formControlName="dob" matInput [matDatepicker]="datepicker"
                            (dateChange)="validatedob($event)">
                        <mat-datepicker-toggle matSuffix [for]="datepicker"></mat-datepicker-toggle>
                        <mat-datepicker #datepicker>
                        </mat-datepicker>
                        <mat-error *ngIf="validdob">
                            Please enter a valid DOB
                        </mat-error>
                        <mat-error *ngIf="validdob">
                            DOB is <strong>required</strong>
                        </mat-error>
                    </mat-form-field>
                </div>

                <div class="form-group" [style.color]="validdoa?null:'red'">
                    <label style="margin-left:5%; margin-top: 20px;">Marriage Date : </label>
                    <mat-form-field appearance="fill" style="margin-left: 5%;">
                        <mat-label>Choose a date</mat-label>
                        <input formControlName="doa" matInput [matDatepicker]="datepicker1"
                            (dateChange)="validatedoa($event)">
                        <mat-datepicker-toggle matSuffix [for]="datepicker1"></mat-datepicker-toggle>
                        <mat-datepicker #datepicker1>
                        </mat-datepicker>
                        <mat-error *ngIf="validdoa">
                            Please enter a valid DOA
                        </mat-error>
                        <mat-error *ngIf="validdoa">
                            DOA is <strong>required</strong>
                        </mat-error>
                    </mat-form-field>
                </div>

            </div>


            <div class="form-row">
                <mat-form-field appearance="fill" style="margin-left: 5%; width: 35%;">
                    <mat-label>Gender </mat-label>
                    <mat-select (selectionChange)="validategender($event)" formControlName="gender" required>
                        <mat-option value="male">Male</mat-option>
                        <mat-option value="female">Female</mat-option>
                        <mat-option value="other">Other</mat-option>
                    </mat-select>

                    <mat-error *ngIf="validgender">
                        Please enter a valid Gender
                    </mat-error>
                    <mat-error *ngIf="validgender">
                        Gender is <strong>required</strong>
                    </mat-error>


                </mat-form-field>

                <mat-form-field appearance="fill" style="margin-left: 5%; width: 35%;">
                    <mat-label>Current Occupation</mat-label>
                    <mat-select (selectionChange)="getSearchTerm($event)" formControlName="subCat">
                        <mat-option *ngFor="let item of occupations" value="{{item['occupation_value']}}">
                            {{item['occupation']}}
                        </mat-option>
                    </mat-select>

                    <mat-error *ngIf="validsubcat">
                        Please enter a valid value
                    </mat-error>
                    <mat-error *ngIf="validsubcat">
                        Occupation is <strong>required</strong>
                    </mat-error>

                </mat-form-field>
            </div>

            <div *ngIf="showbussiness">
                <mat-form-field style="margin-left: 5%;width: 90%; margin-top: 20px;"
                    [style.color]="validbusinessname?null:'red'">
                    <mat-label>Business Name </mat-label>
                    <textarea matInput (change)="validatebussiness($event)" style=" margin-left: 5%; "
                        formControlName="businessname" [required]="showbussiness"></textarea>
                    <mat-error *ngIf="validbusinessname">
                        Please enter a valid Name
                    </mat-error>
                    <mat-error *ngIf="validbusinessname">
                        Address is <strong>required</strong>
                    </mat-error>

                </mat-form-field>
            </div>


            <mat-form-field *ngIf="showbussiness" style="margin-left: 5%;width: 90%; margin-top: 20px;"
                [style.color]="validbusinessaddress?null:'red'">
                <mat-label>Address </mat-label>
                <textarea matInput (change)="validateBAddress($event)" style=" margin-left: 5%; "
                    formControlName="businessaddress" [required]="showbussiness"></textarea>
                <mat-error *ngIf="validbusinessaddress">
                    Please enter a valid address
                </mat-error>
                <mat-error *ngIf="validbusinessaddress">
                    Address is <strong>required</strong>
                </mat-error>

            </mat-form-field>

            <div class="form-row" *ngIf="showbussiness">

                <mat-form-field class="example-full-width" style="margin-left: 5%; margin-top: 20px;"
                    [style.color]="validbcity?null:'red'">
                    <mat-label>City</mat-label>
                    <input matInput (change)="validatebcity($event)" formControlName="bcity" required>

                    <mat-error *ngIf="validbcity">
                        Please enter a valid City
                    </mat-error>
                    <mat-error *ngIf="validbcity">
                        City is <strong>required</strong>
                    </mat-error>
                </mat-form-field>

                <mat-form-field class="example-full-width" style="margin-left: 25%; margin-top: 20px;"
                    [style.color]="validbpin?null:'red'">
                    <mat-label>Pincode</mat-label>
                    <input matInput (change)="validatebpincode($event)" formControlName="bpin" #postalCodeb
                        maxlength="6" type="tel" required>
                    <mat-hint align="end">{{postalCodeb.value.length}} / 6</mat-hint>

                    <mat-error *ngIf="validbpin">
                        Please enter a valid pincode
                    </mat-error>
                    <mat-error *ngIf="validbpin">
                        Pincode is <strong>required</strong>
                    </mat-error>

                </mat-form-field>
            </div>

            <div class="form-row" *ngIf="showbussiness">

                <mat-form-field class="example-full-width" style="margin-left: 5%; margin-top: 20px;"
                    [style.color]="validbusinessphone?null:'red'">
                    <mat-label>Business Phone</mat-label>
                    <span matPrefix>+91 &nbsp;</span>
                    <input type="tel" #mobiles (change)="validateBmobile($event)" maxlength="10"
                        formControlName="businessphone" [required]="showbussiness" matInput>
                    <mat-hint align="end">{{mobiles.value.length}} / 10</mat-hint>
                    <mat-error *ngIf="validbusinessphone">
                        Please enter a valid mobile no.
                    </mat-error>
                    <mat-error *ngIf="validbusinessphone">
                        Mobile no. is <strong>required</strong>
                    </mat-error>
                </mat-form-field>


                <mat-form-field class="example-full-width" style="margin-left: 20%; margin-top: 20px;"
                    [style.color]="validbusinessemail?null:'red'">
                    <mat-label>Business Email</mat-label>
                    <input (change)="validateBEmail($event)" type="email" matInput
                        formControlName="businessemail" placeholder="Ex. pat@example.com"
                        [required]="showbussiness">
                    <mat-error *ngIf="validbusinessemail">
                        Please enter a valid email address
                    </mat-error>
                    <mat-error *ngIf="validbusinessemail">
                        Email is <strong>required</strong>
                    </mat-error>
                </mat-form-field>
            </div>

            <div class="form-group" [style.color]="validbusinesstype?null:'red'" *ngIf="showbussiness">
                <label style="margin-left: 4%; margin-top: 20px;">Business Type: </label>

                <mat-form-field appearance="fill" style="margin-left: 4%; width: 90%;">
                    <mat-label>Choose an option</mat-label>
                    <mat-select (selectionChange)="checkBusiness($event)" formControlName="businesstype"
                        [required]="showbussiness">
                        <mat-option value="manufacturer ">Manufacturer </mat-option>
                        <mat-option value="distributor">Distributor</mat-option>
                        <mat-option value="serviceprovider">Service Provider</mat-option>
                        <mat-option value="retailershop">Retailer Shop</mat-option>
                    </mat-select>

                    <mat-error *ngIf="validbusinesstype">
                        Please enter a valid value
                    </mat-error>
                    <mat-error *ngIf="validbusinesstype">
                        Occupation is <strong>required</strong>
                    </mat-error>

                </mat-form-field>
            </div>
            <div class="form-group" *ngIf="!updateMode" >

                <mat-form-field appearance="fill" style="margin-left: 5%; width: 35%;">
                    <mat-label>Magazine Name </mat-label>
                    <mat-select required (selectionChange)="getmagdetails($event)" formControlName="magnames">
                        <mat-option *ngFor="let mags of magname" value="{{mags['name']}}">{{mags['name']}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field appearance="fill" style="margin-left: 5%; width: 35%;">
                    <mat-label>Edition </mat-label>
                    <mat-select required (selectionChange)="getmagEdition($event)" formControlName="magedition">
                        <mat-option value="Print">Print</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <div class="form-group" *ngIf="!updateMode" >

                <mat-form-field appearance="fill" style="margin-left: 5%; width: 35%;">
                    <mat-label>Subscription Year </mat-label>
                    <mat-select required (selectionChange)="getmagyear($event)" formControlName="magyear">
                        <mat-option *ngFor="let mags of magyear" value="{{mags['year']}}">{{mags['year']}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field appearance="fill" style="margin-left: 5%; width: 35%;">
                    <mat-label>Payment Mode </mat-label>
                    <mat-select required (selectionChange)="getcheque($event)" formControlName="paymentmode">
                        <mat-option value="cash">Cash</mat-option>
                        <mat-option value="cheque">Cheque</mat-option>
                        <mat-option value="complementry">Complementry</mat-option>
                    </mat-select>
                </mat-form-field>

            </div>

            <div class="form-group" *ngIf="displayChequqField">
                <mat-form-field class="example-full-width"
                    style=" width:60%;margin-left: 5%; margin-top: 20px;">
                    <mat-label>Cheque No.</mat-label>
                    <input matInput formControlName="checkno">
                </mat-form-field>

            </div>

            <div class="form-group" *ngIf="!updateMode">

                <mat-form-field class="example-full-width"
                    style=" width:35%;margin-left: 5%; margin-top: 20px;">
                    <mat-label>Other Donation</mat-label>
                    <input matInput formControlName="otherdonation" (change)="getotherDonation($event)">
                </mat-form-field>

                <mat-form-field appearance="fill" style="margin-left: 5%; width: 35%;" >
                    <mat-label>Representative Name</mat-label>
                    <mat-select required (selectionChange)="SelectRepresentative($event)"
                        formControlName="rep_id">
                        <mat-option *ngFor="let rep of getAllRepList" value="{{rep['id']}}">{{rep['name']}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>


            </div>

            <div class="form-group" *ngIf="!updateMode">
                <mat-form-field class="example-full-width"
                    style=" width:35%;margin-left: 5%; margin-top: 20px;">
                    <mat-label>Rep. Receipt No.</mat-label>
                    <input matInput formControlName="rep_receipt">
                </mat-form-field>
            </div>

            <div class="form-group" *ngIf="!updateMode">
                <label style="margin-left: 35px;font-weight: bold;">Total Amount : {{totalmagamount +
                    totaldonationamount}}</label>
            </div>

            <div class="form-group" *ngIf="!updateMode">
                <button style="margin-left: 35px; margin-right: 45px; width: 90%;" [disabled]="form.invalid"
                    type="submit" mat-raised-button color="primary" class="btn btn-primary btn-block">Add
                    Subscription</button>
            </div>

            <div mat-dialog-actions style="float:right" *ngIf="updateMode">
                <button mat-button mat-dialog-close>Close</button>
                <button mat-button (click)="update()"> Update</button>
            </div>

        </form>
    </div>
</div>