import { formatDate } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatOption } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSelect } from '@angular/material/select';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { DigisubservicesService } from 'src/app/services/digisubservices.service';
import { CancelRepDialogComponent } from '../cancel-rep-dialog/cancel-rep-dialog.component';
import { CreditvoucherDialogComponent } from '../creditvoucher-dialog/creditvoucher-dialog.component';
import { UpdateOfficeRepresentativeComponent } from '../update-office-representative/update-office-representative.component';

interface tableData {
  id: number;
  name: string;
  mobile: string;
}

export const _filter = (opt: string[], value: string): string[] => {
  const filterValue = value.toLowerCase();

  return opt.filter((item) => item.toLowerCase().includes(filterValue));
};

export const _filter1 = (opt: string[], value: string): string[] => {
  const filterValue = value.toLowerCase();

  return opt.filter((item) => item.toLowerCase().includes(filterValue));
};

@Component({
  selector: 'app-book-seller-registration',
  templateUrl: './book-seller-registration.component.html',
  styleUrls: ['./book-seller-registration.component.scss'],
})
export class BookSellerRegistrationComponent implements OnInit {
  countries = [];
  states = [];
  cities = [];
  advt_mag_name_details = [];
  selectedState = '';
  selectedCity = '';
  AdvtDetails = [];
  issuesellerentryForm: FormGroup;
  publicationsaleForm: FormGroup;
  publicationpaymententriesForm: FormGroup;
  advtCategorylist;
  issueSellerName;
  tableData: tableData[] = [];
  dataLoaded = false;
  yourMessage = [];
  data1: string[];
  key;
  data2: string[];
  issue_seller_name = [];
  filteredOptions: Observable<string[]>;
  filteredOptions1: Observable<string[]>;
  issuewisereplist;
  demo1TabIndex: number;

  constructor(
    private formBuilder: FormBuilder,
    public dialog: MatDialog,
    private router: Router,
    public service: DigisubservicesService
  ) {}

  public demo1BtnClick1(tabCount:any) {
    
    this.demo1TabIndex =  Number(tabCount);
    
  }

  ngOnInit(): void {
    this.demo1BtnClick1( localStorage.getItem("tab"))

    let today = new Date();
    let billNo = `${today.getMonth() + 1}/${today.getFullYear()}`;
    console.log('Toady month', new Date().getMonth());

    this.service.getIssuesellersname().subscribe((data) => {
      this.issue_seller_name = data;
      // console.log('issue_seller_name', this.issue_seller_name);
    });

    this.service.getIssueWiseRep().subscribe((data) => {
      // console.log(data);
      this.issuewisereplist = data;
    });

    // Read All Contries
    this.service.getAllContries().subscribe((data) => {
      console.log(data);

      this.countries = data;
    });

    this.service.getAdvtCategory().subscribe((data) => {
      // console.log('Database data', data);
      this.advtCategorylist = data;
    });

    this.service.getIssueSellerName().subscribe((data) => {
      this.issueSellerName = data;
      console.log('issueSellerName ', this.issueSellerName['issueSellerName']);
    });

    if (sessionStorage.getItem('mobile') == null) {
      this.router.navigate(['/']);
    }

    this.issuesellerentryForm = this.formBuilder.group({
      issuesellername: [''],
      // fname: [''],
      // mname: [''],
      address1: [''],
      address2: [''],
      address3: [''],
      pincode: [
        '',
        [Validators.maxLength(6), Validators.pattern('^[1-9][0-9]{5}$')],
      ],
      country: ['', [Validators.required, Validators.minLength(2)]],
      state: ['', [Validators.required, Validators.minLength(2)]],
      taluka: ['', [Validators.required, Validators.minLength(2)]],
      district: ['', [Validators.required, Validators.minLength(2)]],
      mnumber: [
        '',
        [
          Validators.minLength(10),
          Validators.maxLength(10),
          Validators.pattern('^[7-9][0-9]{9}$'),
        ],
      ],
      email: [
        '',
        [Validators.pattern('[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+.[a-z]{2,3}$')],
      ],
      landline_no: ['', Validators.pattern('^[0-9]\\d{2,4}-\\d{6,8}$')],
      dob: [],
      gstno: ['', [Validators.minLength(15), Validators.maxLength(15)]],
      panno: ['', [Validators.minLength(10), Validators.maxLength(10)]],
      depositamt: [],
      option: [''],
      bookingdate: [],
      issuerate: [''],
      issuecopies: [''],
      grossamt: [''],
      discount: [''],
      amount: [''],
      netamount: [''],
      complementrycopies: [''],
      subscriptioncopies: [''],
      distributedtype: [''],
      subtype: [''],
      details: [''],
    });

    this.publicationsaleForm = this.formBuilder.group({
      cdate: [],
      challanno: [''],
      issuesellername: [''],
      psalescopy: [''],
      complementarycopies: [''],
      subscriptioncopies: [''],
      issuerate: [''],
      discountamt: [''],
      saleamt: [''],
      salesdiscount: [''],
      netamt: [''],
      distributiontype: [''],
      subtype: [''],
      details: [''],
    });

    this.publicationpaymententriesForm = this.formBuilder.group({
      issuegranth: [''],
      issuedates: [],
      billno: [''],
      date: [],
      issuesellername: [''],
      monthdate: [],
    });

    this.filteredOptions = this.issuesellerentryForm
      .get('issuesellername')
      .valueChanges.pipe(
        startWith(''),
        map((value) => (typeof value === 'string' ? value : value.name)),
        map((name) =>
          name ? this._filter(name) : this.issue_seller_name.slice()
        )
      );

    this.filteredOptions1 = this.issuesellerentryForm
      .get('mnumber')
      .valueChanges.pipe(
        startWith(''),
        map((value) => (typeof value === 'string' ? value : value.mob_no)),
        map((mob_no) =>
          mob_no ? this._filter1(mob_no) : this.issue_seller_name.slice()
        )
      );

    this.publicationpaymententriesForm.get('billno').setValue(billNo);
    this.getAdvtMagDetails();
    this.getAllVouchers();
    this.rep_List();
  }

  getallVouchersData = [];
  getAllVouchers() {
    this.service.getvoucherbyIssueSale().subscribe((data) => {
      this.getallVouchersData = data;
    });
  }

  rep_List() {
    let filterdData: string[];
    this.service.get_AllRepresentative_List().subscribe((data) => {
      this.data1 = data;
      //console.log("Data from PHP : ", this.data1);
      //console.log(this.data1.filter(item => { return item['name'] != "office" }))

      this.data1 = this.data1.filter((item) => {
        return item['name'] != 'office';
      });

      //Object.values(this.data1).filter(key => !this.data1.includes)
      for (let index = 0; index < Object.keys(this.data1).length; ++index) {
        if (parseInt(this.data1[index]['id']) > 50) {
          this.tableData.push({
            id: this.data1[index]['id'],
            name: this.data1[index]['name'],
            mobile: this.data1[index]['mobile'],
          });
          continue;
        }
      }

      if (Object.keys(this.tableData).length == 0) {
        this.dataLoaded = false;
      } else {
        this.dataLoaded = true;
      }
    });
  }

  private _filter(name: string): any[] {
    const filterValue = name.toLowerCase();

    return this.issue_seller_name.filter((option) =>
      option.name.toLowerCase().includes(filterValue)
    );
  }

  private _filter1(mob_no: string): any[] {
    const filterValue = mob_no.toLowerCase();

    return this.issue_seller_name.filter((option) =>
      option.mob_no.toLowerCase().includes(filterValue)
    );
  }

  selected_name(id) {
    // console.log(name);

    this.service.getIssuesellersentry(parseInt(id.id)).subscribe((data) => {
      // console.log('getIssuesellersentry', data);
      this.issuesellerentryForm
        .get('issuesellername')
        .setValue(data[0]['name']);
      this.issuesellerentryForm.get('address1').setValue(data[0]['address']);
      this.issuesellerentryForm.get('pincode').setValue(data[0]['pincode']);
      this.issuesellerentryForm.get('country').setValue(data[0]['country']);
      this.issuesellerentryForm
        .get('state')
        .setValue({ state_name: data[0]['state'] });
      this.issuesellerentryForm
        .get('district')
        .setValue({ name: data[0]['district'] });
      this.issuesellerentryForm.get('taluka').setValue(data[0]['taluka']);
      this.issuesellerentryForm.get('mnumber').setValue(data[0]['mobile_no']);
      this.issuesellerentryForm.get('email').setValue(data[0]['email']);
      this.issuesellerentryForm
        .get('landline_no')
        .setValue(data[0]['landline_no']);
      this.issuesellerentryForm.get('dob').setValue(data[0]['dateofbirth']);
      this.issuesellerentryForm.get('gstno').setValue(data[0]['gst_no']);
      this.issuesellerentryForm.get('panno').setValue(data[0]['pan_no']);
      this.issuesellerentryForm
        .get('depositamt')
        .setValue(data[0]['deposit_amt']);
      this.issuesellerentryForm
        .get('option')
        .setValue(data[0]['issueSellerType']);
      this.selectedCity = data[0]['district'];
      this.selectedState = data[0]['state'];
      this.select(data[0]['issueSellerType']);

      // console.log(this.selectedCity, this.selectedState);
    });
  }

  action(row: any) {
    if (row.operation === 'updated') {
      //this.update(row); // This is your update call to API
      this.yourMessage.push('success', 'updated successfully'); // Show update success notification
      this.yourMessage = []; // make sure you empty it
    }

    if (row.operation === 'deleted') {
      //this.delete(row.id); // This is your delete call to API
      this.yourMessage.push('success', 'deleted successfully'); // Show delete success notification
      this.yourMessage = []; // make sure you empty it
    }
  }

  displayItemId(id, voucher_no) {
    this.dialog.open(CreditvoucherDialogComponent, {
      data: {
        id: id,
        voucher_no: voucher_no,
        dept: 'bookSeller',
      },
    });
  } //end of displayId

  validmobile = false;
  validemail = false;
  validdob = false;
  validateMobile(event) {
    if (this.issuesellerentryForm.get('mnumber').valid) {
      this.validmobile = true;
    } else {
      this.validmobile = false;
    }
  }

  validateEmail(event) {
    if (this.issuesellerentryForm.get('email').valid) {
      this.validemail = true;
    } else {
      this.validemail = false;
    }
  }

  validatedob(event) {
    // console.log('event.target.value', event.target.value);
    // this.issuesellerentryForm
    //   .get('dob')
    //   .setValue(
    //     formatDate(
    //       new Date(event.target.value),
    //       'yyyy-MM-dd',
    //       'en-IN',
    //       'GMT+0530'
    //     )
    //   );
    if (this.issuesellerentryForm.get('dob').valid) {
      this.validdob = true;
    } else {
      this.validdob = false;
    }
  }

  getDates;
  stringdates;
  getAdvtDates(e) {
    console.log(e.value.toString());
    this.getDates = e.value;
    this.getDates = this.getDates.filter((a) => a != 'all');
    this.stringdates = this.getDates.toString();
  }

  getData;
  stringdata;
  getISN(e) {
    console.log(e.value.toString());
    this.getData = e.value;
    this.getData = this.getData.filter((a) => a != 'all');
    this.stringdata = this.getData.toString();
  }

  addAdvt() {
    // console.log(
    //   'AdvtDetails',
    //   this.issueName,
    //   this.bookingdate,
    //   this.stringdates
    // );
    this.AdvtDetails.push({
      issue: this.issueName,
      bookingdate: this.issuesellerentryForm.get('bookingdate').value,
      advtdates: this.stringdates,
    });
  }

  deleteAdvt(i) {
    if (window.confirm('Do you really want to remove ?')) {
      this.AdvtDetails.splice(i, 1);
    }
  }

  update(item) {
    this.dialog.open(UpdateOfficeRepresentativeComponent, {
      data: {
        item: item,
        type: 'issue',
      },
    });
    this.service.getIssueWiseRep().subscribe((data) => {
      // console.log(data);
      this.issuewisereplist = data;
    });
  }

  option = false;
  select(val) {
    // console.log(event.value);
    if (val == 'agent') {
      this.option = true;
    } else {
      this.option = false;
    }
  }

  allSelected = false;
  @ViewChild('mySel') skillSel: MatSelect;
  toggleAllSelection() {
    this.allSelected = !this.allSelected; // to control select-unselect
    console.log(this.skillSel.options);
    if (this.allSelected) {
      this.skillSel.options.forEach((item: MatOption) => item.select());
    } else {
      this.skillSel.options.forEach((item: MatOption) => {
        item.deselect();
      });
    }
    this.skillSel.close();
  }

  @ViewChild('mySel1') skillSel1: MatSelect;
  toggleAllSelection1() {
    this.allSelected = !this.allSelected; // to control select-unselect
    console.log(this.skillSel1.options);
    if (this.allSelected) {
      this.skillSel1.options.forEach((item: MatOption) => item.select());
    } else {
      this.skillSel1.options.forEach((item: MatOption) => {
        item.deselect();
      });
    }
    this.skillSel.close();
  }

  @ViewChild('isn') skillSel2: MatSelect;
  toggleAllSelectionISN() {
    this.allSelected = !this.allSelected; // to control select-unselect
    // console.log(this.skillSel2.options);
    if (this.allSelected) {
      this.skillSel2.options.forEach((item: MatOption) => item.select());
    } else {
      this.skillSel2.options.forEach((item: MatOption) => {
        item.deselect();
      });
    }
    this.skillSel.close();
  }

  getAdvtMagDetails() {
    this.advt_mag_name_details = [];
    // this.stateGroups = [];
    let abc;
    this.service.getAdvtMagNames().subscribe((data) => {
      console.log(data);
      for (let index = 0; index < Object.keys(data).length; index++) {
        console.log(data[index]);
        this.advt_mag_name_details.push({
          id: data[index]['id'],
          is_active: data[index]['is_active'],
          issue_name: data[index]['issue_name'],
          category: data[index]['category'],
          publishing_date: data[index]['publishing_date'].split(','),
        });

        if (index == 0) {
          console.log(
            data.filter((a) => a.category == data[index]['category'])
          );
        }

        // if (
        //   this.stateGroups.find((a) => a.category == data[index]['category']) ==
        //   undefined
        // ) {
        //   let mags = data.filter((a) => a.category == data[index]['category']);
        //   let mag_to_list = [];
        //   for (let index = 0; index < Object.keys(mags).length; index++) {
        //     mag_to_list.push(mags[index]['issue_name']);
        //   }
        //   this.stateGroups.push({
        //     category: data[index]['category'],
        //     details: mag_to_list,
        //   });
        // }
      }

      // console.log('Issue/Grantha || stategroup', this.stateGroups);
      //this.advt_mag_name_details = data
    });
  } //end of getAdvtMagDetails

  issueName;
  bookingdate;
  advtsizebyCategory;
  getFilterMagPublishDates = ['10-10-2021', ''];
  getMagIssueDatesandNames;
  getIssues(e) {
    console.log(e);
    this.issueName = e.value;
    console.log('jgfdfgh', this.issueName);
    this.service.getAdvtSizeByCategory(e.value).subscribe((data) => {
      this.advtsizebyCategory = data;
    });
    // console.log('hi', e.option.group.label);
    // this.issuesellerentryForm.reset();
    // this.cust_mobile_filter = '';
    // this.rep_name = '';
    console.log(
      'advt_mag_name_details',
      this.advt_mag_name_details.filter((a) => a.issue_name == this.issueName)
    );
    // this.getFilterMagPublishDates = this.advt_mag_name_details.filter(
    //   (a) => a.issue_name == this.issueName
    // )[0]['publishing_date'];
    // console.log(this.getFilterMagPublishDates);
    this.service.getAdvtDatesAndNames(e.value).subscribe((data) => {
      this.getMagIssueDatesandNames = data;
      console.log('Issue Dates and Names:', this.getMagIssueDatesandNames);
    });
  }

  issueid;
  save_issuesellerentry() {
    let issueseller = {
      issuesellername: this.issuesellerentryForm.get('issuesellername').value,
      address1: this.issuesellerentryForm.get('address1').value,
      pincode: this.issuesellerentryForm.get('pincode').value,
      country: this.issuesellerentryForm.get('country').value,
      state: this.issuesellerentryForm.get('state').value['state_name'],
      taluka: this.issuesellerentryForm.get('taluka').value,
      district: this.issuesellerentryForm.get('district').value['name'],
      mnumber: this.issuesellerentryForm.get('mnumber').value,
      landline_no: this.issuesellerentryForm.get('landline_no').value
        ? null
        : '',
      email: this.issuesellerentryForm.get('email').value,
      dob: this.issuesellerentryForm.get('dob').value,
      gstno: this.issuesellerentryForm.get('gstno').value,
      panno: this.issuesellerentryForm.get('panno').value,
      depositamt: this.issuesellerentryForm.get('depositamt').value,
      option: this.issuesellerentryForm.get('option').value,
    };
    // console.log(' Issue seller entry', issueseller);

    this.service.addIssueSellerEntry(issueseller).subscribe((data) => {
      // console.log(' Issue seller entry id', data);
      this.issueid = data['id'];

      let issueselleragent = {
        id: this.issueid,
        issuerate: this.issuesellerentryForm.get('issuerate').value,
        issuecopies: this.issuesellerentryForm.get('issuecopies').value,
        grossamt: this.issuesellerentryForm.get('grossamt').value,
        discount: this.issuesellerentryForm.get('discount').value,
        amount: this.issuesellerentryForm.get('amount').value,
        netamount: this.issuesellerentryForm.get('netamount').value,
        complementrycopies:
          this.issuesellerentryForm.get('complementrycopies').value,
        subscriptioncopies:
          this.issuesellerentryForm.get('subscriptioncopies').value,
        distributedtype: this.issuesellerentryForm.get('distributedtype').value,
        subtype: this.issuesellerentryForm.get('subtype').value,
        details: this.issuesellerentryForm.get('details').value,
      };

      if (this.issuesellerentryForm.get('option').value == 'agent') {
        this.service
          .addIssueSellerAgent(issueselleragent)
          .subscribe((data1) => {
            // console.log('id', data1);
            for (
              let index = 0;
              index < Object.keys(this.AdvtDetails).length;
              index++
            ) {
              let newadvtdtl = {
                issueName: this.AdvtDetails[index]['issue'],
                advt_dates: this.AdvtDetails[index]['advtdates'],
                booking_date: this.AdvtDetails[index]['bookingdate'],
                id: data1['id'],
              };
              this.service
                .insertIssueSellerAgentAdvtDetails(newadvtdtl)
                .subscribe((details) => {
                  // console.log('id1', details['id']);
                  for (
                    let index = 0;
                    index < Object.keys(this.AdvtDetails).length;
                    index++
                  ) {
                    this.AdvtDetails.pop();
                  }
                });
            }
          });
      }
      window.alert('Issue Seller Added Successfully...');
      setTimeout(() => {
        this.issuesellerentryForm.reset();
      }, 2000);
    });
    // console.log(' Issue seller entry', issueseller);
  }

  // save_agentissueentry() {
  //   let agentissue = {
  //     repagent: this.agentissueentryForm.get('repagent').value,
  //     pname: this.agentissueentryForm.get('pname').value,
  //     edate: this.agentissueentryForm.get('edate').value,
  //     dissueper: this.agentissueentryForm.get('dissueper').value,
  //     psaleper: this.agentissueentryForm.get('psaleper').value,
  //     giftper: this.agentissueentryForm.get('giftper').value,
  //     prate: this.agentissueentryForm.get('prate').value,
  //     bpercentage: this.agentissueentryForm.get('bpercentage').value,
  //     bperissue: this.agentissueentryForm.get('bperissue').value,
  //     details: this.agentissueentryForm.get('details').value,
  //     alloctcode: this.agentissueentryForm.get('alloctcode').value,
  //     deptcode: this.agentissueentryForm.get('deptcode').value,
  //   };

  //   console.log('Agent Issue entry', agentissue);
  //   alert('Data not stored yet');
  //   setTimeout(() => {
  //     this.agentissueentryForm.reset();
  //   }, 2000);
  // }

  showigst = false;

  getState(event) {
    // console.log(event);
    console.log(event.value);
    this.selectedState = this.states['state_id'];
    let state = this.states.find((a) => a.state_name == event.value);
    console.log();
    if (state != undefined) {
      if (state.state_name != 'Maharashtra') {
        this.showigst = true;
      } else {
        this.showigst = false;
      }

      this.service.getCity(parseInt(state['state_id'])).subscribe((data) => {
        this.cities = data;
        this.selectedCity = this.cities['name'];
        // console.log(this.cities);
      });
    } else {
    }
  } //get state

  getPincode(event) {
    if (this.issuesellerentryForm.get('pincode').valid) {
      console.log(this.issuesellerentryForm.get('pincode').value);
      this.service
        .getPincodeDetails(
          parseInt(this.issuesellerentryForm.get('pincode').value)
        )
        .subscribe((data) => {
          console.log('pincode deatils', data);
          this.issuesellerentryForm
            .get('taluka')
            .setValue(data[0]['PostOffice'][0]['Block']);
          this.getCountry({ value: data[0]['PostOffice'][0]['Country'] });
          this.issuesellerentryForm
            .get('district')
            .setValue({ name: data[0]['PostOffice'][0]['District'] });
          this.issuesellerentryForm
            .get('state')
            .setValue({ state_name: data[0]['PostOffice'][0]['State'] });
          this.issuesellerentryForm
            .get('country')
            .setValue(data[0]['PostOffice'][0]['Country']);
          this.selectedCity = data[0]['PostOffice'][0]['District'];
          this.selectedState = data[0]['PostOffice'][0]['State'];

          console.log(this.selectedCity, this.selectedState);
        });
    }
  } //getPincode

  // getPincode1(event) {
  //   if (this.addform.get('pincode').valid) {
  //     console.log(this.addform.get('pincode').value);
  //     this.service
  //       .getPincodeDetails(parseInt(this.addform.get('pincode').value))
  //       .subscribe((data) => {
  //         console.log('pincode deatils', data);
  //         this.addform
  //           .get('taluka')
  //           .setValue(data[0]['PostOffice'][0]['Block']);
  //         this.getCountry({ value: data[0]['PostOffice'][0]['Country'] });
  //         this.addform
  //           .get('district')
  //           .setValue({ name: data[0]['PostOffice'][0]['District'] });
  //         this.addform
  //           .get('state')
  //           .setValue({ state_name: data[0]['PostOffice'][0]['State'] });
  //         this.addform
  //           .get('country')
  //           .setValue(data[0]['PostOffice'][0]['Country']);
  //         this.selectedCity = data[0]['PostOffice'][0]['District'];
  //         this.selectedState = data[0]['PostOffice'][0]['State'];

  //         console.log(this.selectedCity, this.selectedState);
  //       });
  //   }
  // } //getPincode

  getCountry(event) {
    // console.log(event);
    console.log(event.value);
    let country = this.countries.find((a) => a.name == event.value);
    if (country != undefined) {
      this.service
        .getstates(parseInt(country['country_code']))
        .subscribe((data) => {
          this.states = data;
          console.log(this.states);
        });
    } else {
    }
  } //

  save_publicationsale() {
    let publicationsalecurrency = {
      challandate: this.publicationsaleForm.get('cdate').value,
      challanno: this.publicationsaleForm.get('challanno').value,
      issuesellername: this.publicationsaleForm.get('issuesellername').value,
      psalescopy: this.publicationsaleForm.get('psalescopy').value,
      complementarycopies: this.publicationsaleForm.get('complementarycopies')
        .value,
      subscriptioncopies:
        this.publicationsaleForm.get('subscriptioncopies').value,
      issuerate: this.publicationsaleForm.get('issuerate').value,
      discountamt: this.publicationsaleForm.get('discountamt').value,
      saleamt: this.publicationsaleForm.get('saleamt').value,
      salesdiscount: this.publicationsaleForm.get('salesdiscount').value,
      netamt: this.publicationsaleForm.get('netamt').value,
      distributiontype: this.publicationsaleForm.get('distributiontype').value,
      subtype: this.publicationsaleForm.get('subtype').value,
      details: this.publicationsaleForm.get('details').value,
    };

    console.log(' Publication Sales Currency', publicationsalecurrency);
    alert('Data not stored yet');
    setTimeout(() => {
      this.publicationsaleForm.reset();
    }, 2000);
  }

  save_publicationpaymententries() {
    let publicationpayment = {
      issuegranth: this.issueName,
      issuedates: this.getDates,
      billno: this.publicationpaymententriesForm.get('billno').value,
      date: this.publicationpaymententriesForm.get('date').value,
      issuesellername:
        this.publicationpaymententriesForm.get('issuesellername').value,
      monthdate: this.publicationpaymententriesForm.get('monthdate').value,
    };

    console.log(' Publication Payment Entries', publicationpayment);
    alert('Data not stored yet');
    setTimeout(() => {
      this.publicationpaymententriesForm.reset();
    }, 2000);
  }
  onTabClick(event:any){
    console.log(event)
    localStorage.setItem("tab", event.index)
  }
}
