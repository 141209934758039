import { FileUploadModule } from 'ng2-file-upload';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { ClipboardModule } from 'ngx-clipboard';

import { AdminLayoutRoutes } from './admin-layout.routing';
import { DashboardComponent } from '../../pages/dashboard/dashboard.component';
import { IconsComponent } from '../../pages/icons/icons.component';
import { MapsComponent } from '../../pages/maps/maps.component';
import { UserProfileComponent } from '../../pages/user-profile/user-profile.component';
import { TablesComponent } from '../../pages/tables/tables.component';
import { SubTableComponent } from '../../pages/sub-table/sub-table.component';
import { RegisterComponent } from '../../pages/register/register.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
// import { ToastrModule } from 'ngx-toastr';
import { EditableTableModule } from '../../modules/editable-table/editable-table.module';
//import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatBadgeModule } from '@angular/material/badge';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatStepperModule } from '@angular/material/stepper';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import {
  MatNativeDateModule,
  MatRippleModule,
  MAT_DATE_LOCALE,
} from '@angular/material/core';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSliderModule } from '@angular/material/slider';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTreeModule } from '@angular/material/tree';
import { NewSubscribersDataComponent } from '../../pages/new-subscribers-data/new-subscribers-data.component';
import { AddNewMagazineComponent } from '../../pages/add-new-magazine/add-new-magazine.component';
import { AccountsComponent } from 'src/app/pages/accounts/accounts.component';
import { SubscriptionAccountsComponent } from 'src/app/pages/subscription-accounts/subscription-accounts.component';
import { MasterTablesComponent } from 'src/app/pages/master-tables/master-tables.component';
import { InvoiceComponent } from 'src/app/pages/invoice/invoice.component';
import { BookinvoiceComponent } from 'src/app/pages/bookinvoice/bookinvoice.component';
import { SubscriptionDetailsComponent } from 'src/app/pages/subscription/subscription-details/subscription-details.component';
import { AddNewSubscriberComponent } from 'src/app/pages/add-new-subscriber/add-new-subscriber.component';
import { SubscriptionRenewalComponent } from 'src/app/pages/subscription-renewal/subscription-renewal.component';
import { AddCancelSubscriberComponent } from 'src/app/pages/add-cancel-subscriber/add-cancel-subscriber.component';
import { AdvertisementDetailsComponent } from 'src/app/pages/advertisement-details/advertisement-details.component';
import { UpdateTrackingComponent } from 'src/app/pages/update-tracking/update-tracking.component';
import { UpdateAdvtDetailsComponent } from 'src/app/pages/update-advt-details/update-advt-details.component';
import { UpdatePlublishDatesComponent } from 'src/app/pages/update-plublish-dates/update-plublish-dates.component';
import { BookSellerRegistrationComponent } from 'src/app/pages/book-seller-registration/book-seller-registration.component';
import { UpdateMagazineNameAndCategoryComponent } from 'src/app/pages/update-magazine-name-and-category/update-magazine-name-and-category.component';
import { UpdateAdvtSizesComponent } from 'src/app/pages/update-advt-sizes/update-advt-sizes.component';
import { UpdateIssueDatesComponent } from 'src/app/pages/update-issue-dates/update-issue-dates.component';
import { UpdateReturnReceiptComponent } from 'src/app/pages/update-return-receipt/update-return-receipt.component';
// import { NgxMultipleDatesModule } from 'ngx-multiple-dates';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { NgxPrintModule } from 'ngx-print';
import { ToastrModule } from 'ngx-toastr';
import { Subscription1Component } from 'src/app/subscription1/subscription1.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(AdminLayoutRoutes),
    FormsModule,
    HttpClientModule,
    NgbModule,
    ClipboardModule,
    ReactiveFormsModule,
    EditableTableModule,
    FileUploadModule,
    //BrowserAnimationsModule
    MatAutocompleteModule,
    MatBadgeModule,
    MatBottomSheetModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatStepperModule,
    MatDatepickerModule,
    // NgxMultipleDatesModule,
    MatDialogModule,
    MatDividerModule,
    MatExpansionModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    MatTreeModule,
    ScrollingModule,
    NgxPrintModule,
    MatChipsModule,
    ToastrModule.forRoot({
      closeButton: true,
      timeOut: 15000, // 15 seconds
      progressBar: true,
    }),
  ],
  declarations: [
    DashboardComponent,
    UserProfileComponent,
    TablesComponent,
    IconsComponent,
    MapsComponent,
    SubTableComponent,
    RegisterComponent,
    NewSubscribersDataComponent,
    AddNewMagazineComponent,
    AccountsComponent,
    SubscriptionAccountsComponent,
    // MasterTablesComponent,
    InvoiceComponent,
    BookinvoiceComponent,
    SubscriptionDetailsComponent,
    AddNewSubscriberComponent,
    AddCancelSubscriberComponent,
    SubscriptionRenewalComponent,
    AdvertisementDetailsComponent,
    UpdateTrackingComponent,
    UpdateAdvtDetailsComponent,
    UpdatePlublishDatesComponent,
    BookSellerRegistrationComponent,
    UpdateMagazineNameAndCategoryComponent,
    UpdateAdvtSizesComponent,
    UpdateIssueDatesComponent,
    UpdateReturnReceiptComponent,
    Subscription1Component
  ],
  providers: [
    MatNativeDateModule,
    MatDatepickerModule,
    { provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AdminLayoutModule {}
