import { DatePipe, formatDate } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatOption } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSelect } from '@angular/material/select';
import { DigisubservicesService } from 'src/app/services/digisubservices.service';
import { SubscriptionAccountsComponent } from '../subscription-accounts/subscription-accounts.component';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatChipInputEvent } from '@angular/material/chips';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { CreditDebitLedgerReportComponent } from '../credit-debit-ledger-report/credit-debit-ledger-report.component';
import { RepledgerComponent } from 'src/app/repledger/repledger.component';
import { ExpenseledgerComponent } from 'src/app/expenseledger/expenseledger.component';
import { CreditorsreportComponent } from 'src/app/creditorsreport/creditorsreport.component';
import { UpdatejournalvoucherComponent } from 'src/app/updatejournalvoucher/updatejournalvoucher.component';
import { ToastrService } from 'ngx-toastr';
import { JournaledgerlvoucherComponent } from 'src/app/journaledgerlvoucher/journaledgerlvoucher.component';

export const _filter1 = (opt: string[], value: string): string[] => {
  const filterValue = value.toLowerCase();

  return opt.filter((item) => item.toLowerCase().includes(filterValue));
};

export const _filter2 = (opt: string[], value: string): string[] => {
  const filterValue = value.toLowerCase();

  return opt.filter((item) => item.toLowerCase().includes(filterValue));
};

export const _filter3 = (opt: string[], value: string): string[] => {
  const filterValue = value.toLowerCase();

  return opt.filter((item) => item.toLowerCase().includes(filterValue));
};

// export const _filter4 = (opt: string[], value: string): string[] => {
//   const filterValue = value.toLowerCase();

//   return opt.filter((item) => item.toLowerCase().includes(filterValue));
// };

@Component({
  selector: 'app-accounts',
  templateUrl: './accounts.component.html',
  styleUrls: ['./accounts.component.scss'],
})
export class AccountsComponent implements OnInit {

  
  addOnBlur = true;
  readonly separatorKeysCodes = [ENTER, COMMA] as const;
  names = [];
  names1 = [];
  names2 = [];
  names3 = [];
  names4 = [];
  names5 = [];
  names6 = [];
  creditors = 'पुरवठादार देणी'
  visibleRows: boolean[];
  subscriptionpaymentList = [];
  debitlistactive:boolean = false
  advtpaymentList = [];
  getallVouchersData = [];
  advt_mag_name_details = [];
  ionicForm: FormGroup;
  creditvoucherForm: FormGroup;
  pkgForm: FormGroup;
  pkgBookingregisterForm: FormGroup;
  bankForm: FormGroup;
  debitForm: FormGroup;
  ExpenseLedgerForm: FormGroup;
  repledger: FormGroup;
  kharedi: FormGroup;
  journalvoucher: FormGroup;
  journalvouchergetdate: FormGroup;
  SubExpenseLedgerForm: FormGroup;
  expenseledger1: FormGroup; 
  expensereport : FormGroup
  id;
  debit_voucher_id;
  displaycrform = false;
  advtCategorylist;
  vouchervalue: string = 'CR';
  filteredOptions1: Observable<string[]>;
  filteredOptions2: Observable<String[]>;
  filteredOptions3: Observable<String[]>;
  filteredOptions4: Observable<String[]>;
  issue_seller_name = [];
  packageData;
  packagenames = [];
  pkgBookingData = [];
  FilteredpkgBookingData = [];
  totalcrdtvreport;
  // pkgBookingData3 = [];
  // pkgBookingData5 = [];
  pkgBookingnames = [];
  pkgBookingrepnames = [];
  pkgBookingoffrepnames = [];
  pkgBookingcustnames = [];
  BankData = [];
  RCSBankData = [];
  allCreditVoucherListData = [];
  mainExpData = [];
  subExpData = [];
  getSubExpData;
  getDebitSubExpData;
  items: any[] = [];
  items1: any[] = [];

  creditdebitDateRange = new FormGroup({
    start: new FormControl(''),
    end: new FormControl(''),
  });

  reconsilationDateRange = new FormGroup({
    start: new FormControl(''),
    end: new FormControl(''),
  });
  BankData1: any;
  opening_yes_no: any=null;
  forupdate: any;
  vouchers_status: any;
  mergedArray1: any[];
  narrationname: any;
  creditornumber: any;
  insertid: any;
  demo1TabIndex: number;
  filteredArray: any[];
  searchQuery: string = '';
  journalvouchernumber: any;
  jnumber: any;
  journaldata: any;
  getjournaldata: any;
  deletevoucher_no: any;
  checkedstatus: any;
  getchquedata: any;
  debitvoucherlist: any;
  debitlist: boolean = false;
  transformedResult: { debitvoucher: { id: any; }[]; };
  mainidforupdatedebit: any;
  selectbank: any;
  selectvocuherdate: any;
  StartDate: any;
  EndDate: any;
  


  constructor(
    public service: DigisubservicesService,
    public dialog: MatDialog,
    private formBuilder: FormBuilder,
    private toaster: ToastrService,
    private router: Router,private datePipe:DatePipe
  ) {

    this.addItem();

    this.ionicForm = this.formBuilder.group({
      advtpayment: [''],
      bookpayment: [''],
      subpayment: [''],
      bookcomment: [''],
      advtcomment: [''],
      subcomment: [''],
    });

    this.creditvoucherForm = this.formBuilder.group({
      v_no: [''],
      bcaccount: [''],
      vnumber: [''],
      voucherdate: [''],
      rname: [''],
      othername: [''],
      damount: [''],
      pymtoptions: [''],
      cnumber: [''],
      bank: [''],
      branch: [''],
      chequedate: [],
      entrydate: [],
      types: [''],
      subscription: [''],
      subscriptionname: [''],
      isale: [''],
      isname: [''],
      adverties: [''],
      advertiesname: [''],
      deposits: [''],
      depositsname: [''],
      granthbook: [''],
      granthbookname: [''],
      bookName:[''],
      package: [''],
      packagename: [''],
      packageamt: [''],
      donation: [''],
      pancard: [''],
      narration: [''],
      donationname: [''],
      odeposit: [''],
      odepositname: [''],
      packagecount: [''],
      packageamount: [''],
      otheramount: [''],
      total: [''],
      expenditure: [''],
      travels: [''],
      subscommission: [''],
      issuecommission: [''],
      advtcommission: [''],
      bookcommission: [''],
      other: [''],
      // expenditured: [''],
      expname: [''],
      subexpname: [''],
      exp_amt: [''],
      totalexp: [''],
      ndeposit: [''],
      entername: [''],

      // entername1: [''],
      expnameother: [''],
      subexpnameother: [''],
      expnameotherd: [''],
      subexpnameotherd: [''],
      exp_amtother: [''],
      name:[''],
      // entername2: [''],
      // entername3: [''],
      // entername4: [''],
      // entername5: [''],
      // entername6: [''],
      // entername7: [''],
    });

    this.pkgForm = this.formBuilder.group({
      pkgname: [''],
      start: [''],
      end: [''],
      pkgamt: [''],
      periodicity: [''],
      vivekm1actualamt: [''],
      vivekm1concessionamt: [''],
      vivekh1actualamt: [''],
      vivekh1concessionamt: [''],
      vaidyraj1actualamt: [''],
      vaidyraj1concessionamt: [''],
      granth11actualamt: [''],
      granth11concessionamt: [''],
      granth21actualamt: [''],
      granth21concessionamt: [''],
      donation1actualamt: [''],
      donation1concessionamt: [''],
      advt1actualamt: [''],
      advt1concessionamt: [''],
      courier1actualamt: [''],
      courier1concessionamt: [''],
      other1actualamt: [''],
      other1concessionamt: [''],
      vivekm3actualamt: [''],
      vivekm3concessionamt: [''],
      vivekh3actualamt: [''],
      vivekh3concessionamt: [''],
      vaidyraj3actualamt: [''],
      vaidyraj3concessionamt: [''],
      granth13actualamt: [''],
      granth13concessionamt: [''],
      granth23actualamt: [''],
      granth23concessionamt: [''],
      donation3actualamt: [''],
      donation3concessionamt: [''],
      advt3actualamt: [''],
      advt3concessionamt: [''],
      courier3actualamt: [''],
      courier3concessionamt: [''],
      other3actualamt: [''],
      other3concessionamt: [''],
      vivekm5actualamt: [''],
      vivekm5concessionamt: [''],
      vivekh5actualamt: [''],
      vivekh5concessionamt: [''],
      vaidyraj5actualamt: [''],
      vaidyraj5concessionamt: [''],
      granth15actualamt: [''],
      granth15concessionamt: [''],
      granth25actualamt: [''],
      granth25concessionamt: [''],
      donation5actualamt: [''],
      donation5concessionamt: [''],
      advt5actualamt: [''],
      advt5concessionamt: [''],
      courier5actualamt: [''],
      courier5concessionamt: [''],
      other5actualamt: [''],
      other5concessionamt: [''],
    });

    this.pkgBookingregisterForm = this.formBuilder.group({
      pkg_name: [''],
      start: [''],
      end: [''],
      off_rep_name: [''],
      rep_name: [''],
      cust_name: [''],
    });

    this.bankForm = this.formBuilder.group({
      bank_name: [''],
      bank_short_name: [''],
      bank_o_bal: [''],
      crdtdebt: [''],
    });

    this.debitForm = formBuilder.group({
      bcaccount: [''],
      dvnumber: [''],
      dvoucherdate: [''],
      rname: [''],
      othername: [''],
      exp_amt: [''],
      chequedate: [''],
      chequeno: [''],
      dv_no: [''],
      // other: [''],
      // subscommission: [''],
      // issuecommission: [''],
      // advtcommission: [''],
      // bookcommission: [''],
      expname: [''],
      subexpname: [''],
      expamt: [''],
      totalexp: [''],
      // entrydate:[''],
    });

    this.ExpenseLedgerForm = formBuilder.group({
      expensename: [''],
      exptype: [''],
      openingbal: [''],
      opening_yes_no :  [''],
      start: [''],
      end: [''],
      crdr:[''],

    });

    this.repledger = formBuilder.group({
      start: [''],
      end: [''],
      repname: [''],
    });

    this.kharedi = formBuilder.group({
      expnameother: [''],
      subexpnameother: [''], 
      voucherdate: [''],
      creditorsnum: [''],
      totalamount: [''],
      billno: [''],
      narration: [''],
    });

    this.journalvouchergetdate = formBuilder.group({
      start: [''],
      end: [''],
    });

    this.journalvoucher = formBuilder.group({
      jvouchernum: [''],
      date: [''],
      narration: [''],
    });

    this.SubExpenseLedgerForm = formBuilder.group({
      expname: [''],
      subexpname: [''],
      bank_name: [''],
      bank_o_bal: [''],
      crdtdebt: [''],
      bank_short_name: [''],

    }); 
    this.expenseledger1 = formBuilder.group({
      expnameother: [''],
      subexpnameother: [''],
      start: [''],
      end: [''],

    });

    this.expensereport = formBuilder.group({
      start: [''],
      end: [''],
    });

    this.totalcrdtvreport = {
      depositamt: 0,
      sub: 0,
      issue: 0,
      advt: 0,
      deposit: 0,
      book: 0,
      pkg_amt: 0,
      donation: 0,
      other_deposit: 0,
      // travel: 0,
      // other: 0,
      // sub_com: 0,
      // is_com: 0,
      // advt_com: 0,
      // gb_com: 0,
      total: 0,
      total_exp: 0,
      net_deposit: 0,
    };
    
  }

  public demo1BtnClick1(tabCount:any) {
    
    this.demo1TabIndex =  Number(tabCount);
    
  }

  bookList:any
  getBooksList() {
    let resultState = this.service.get_book_names();
    resultState.subscribe((data)=>{
      this.bookList = data;
    
    });
  }

  DeleteJournalVoucher:any=""
  getDeleteJournalVoucher( obj:any) {
    const request= {
      "id":obj.jv_id
    }
    let resultState = this.service.delete_journal_voucher(request);
    resultState.subscribe((data)=>{
      this.DeleteJournalVoucher = data;

      // Show success alert
      alert("Journal Voucher deleted successfully!");

      // Refresh the page after deletion
      location.reload();
    
    });
  }

  DeleteMainExpense:any=""
  getDeleteMainExpense( obj:any) {
    const request= {
      "id":obj.id
    }
    let resultState = this.service.delete_main_expense(request);
    resultState.subscribe((data)=>{
      this.DeleteMainExpense = data;

      // Show success alert
      alert("Expense ledger deleted successfully!");

      // Refresh the page after deletion
      location.reload();
      
    });
  }

  DeleteSubExpense:any=""
  getDeleteSubExpense( obj:any) {
    const request= {
      "id":obj.id
    }
    let resultState = this.service.delete_sub_expense(request);
    resultState.subscribe((data)=>{
      this.DeleteSubExpense = data;

      // Show success alert
      alert("Sub-Expense ledger deleted successfully!");

      // Refresh the page after deletion
      location.reload();
    
    });
  }

  
  JournalVoucherList:any=""
  getJournalVoucherList() {
    let resultState = this.service.get_journal_voucher_list();
    resultState.subscribe((data)=>{
      this.JournalVoucherList = data;
    
    });
  }
  comaSepratedId:any=""
  getGetBookIds(event:any){
      this.comaSepratedId = event.value.join(',');
  }
  
//   insert_person_book_names(){
//     const request={

//       "crdt_vucher_id": this.creditvoucherForm.get('vnumber').value,
//       "name": this.creditvoucherForm.get('name').value,
//       "price": this.creditvoucherForm.get('granthbook').value,
//       "book_ids": this.comaSepratedId

//     }

//     const request1={
//       "saib":request
//     }
// console.log("method new payload ="+request1);
//     let resultState = this.service.insert_person_book_names( request);
//     resultState.subscribe((data)=>{
      
    
//     });
//   }

isSubmitting = false;

insert_person_book_names() {
  if (this.isSubmitting) return;  // Prevent multiple calls
  this.isSubmitting = true;       // Set flag to true

  const request = {
    "crdt_vucher_id": this.creditvoucherForm.get('vnumber').value,
    "name": this.creditvoucherForm.get('granthbookname').value, 
    "price": this.creditvoucherForm.get('granthbook').value,
    "book_ids": this.comaSepratedId
  };

  console.log("Method new payload =", request);

  this.service.insert_person_book_names(request).subscribe(
    (data) => {
      console.log("Success:", data);
      this.isSubmitting = false;  // Reset flag after success
    },
    (error) => {
      console.log("Error:", error);
      this.isSubmitting = false;  // Reset flag on error
    }
  );
}

  ngOnInit(): void {
  
    this.getJournalVoucherList();
this.getBooksList();
    this.service.getchequedata().subscribe((res) => {
      this.getchquedata = res
     });
    
    this.service.getMainExpenseLedger().subscribe((res) => {
      // console.log(res);
      this.mainExpData = res;
    });
    
    const defaultExpense = this.mainExpData.find(exp => exp.exp_name === 'पुरवठादार देणी');
  
    if (defaultExpense) {
      this.kharedi.get('expnameother').setValue(defaultExpense.id);
      // Or if using ngModel:
      this.creditors = defaultExpense.id;
    }
    this.demo1BtnClick1( localStorage.getItem("tab"))

    this.service.getIssuesellersname().subscribe((data) => {
      this.issue_seller_name = data;
      // console.log('issue_seller_name', this.issue_seller_name);
    });
    // console.log(new Date().getDate());
    // console.log(new Date().getHours());
    // console.log(new Date().getMinutes());
    // console.log(formatDate(new Date(), 'dd-MM-yyyy', 'en-IN', 'GMT+0530'));

    this.service.getAdvtCategory().subscribe((data) => {
      // console.log('Database data', data);
      this.advtCategorylist = data;
    });

    // this.service.getPackageBookingData().subscribe((data) => {
    //   this.pkgBookingData = data;
    //   this.FilteredpkgBookingData = this.pkgBookingData;

    //   for (
    //     let index = 0;
    //     index < Object.keys(this.FilteredpkgBookingData).length;
    //     index++
    //   ) {
    //     this.pkgBookingnames.push({
    //       pkg_name: this.FilteredpkgBookingData[index]['pkg_name'],
    //     });

    //     if (this.FilteredpkgBookingData[index]['rep_name'] != '') {
    //       this.pkgBookingrepnames.push({
    //         rep_name: this.FilteredpkgBookingData[index]['rep_name'],
    //       });
    //     }

    //     // this.pkgBookingoffrepnames.push({
    //     //   off_rep_name: this.FilteredpkgBookingData[index]['off_rep_name'],
    //     // });

    //     if (this.FilteredpkgBookingData[index]['client_name'] != '') {
    //       this.pkgBookingcustnames.push({
    //         cust_name: this.FilteredpkgBookingData[index]['client_name'],
    //       });
    //     }
    //   }

    //   this.pkgBookingnames = this.uniqueid(
    //     this.pkgBookingnames,
    //     (a) => a['pkg_name']
    //   );

    //   this.pkgBookingrepnames = this.uniqueid(
    //     this.pkgBookingrepnames,
    //     (a) => a.rep_name
    //   );

    //   this.pkgBookingcustnames = this.uniqueid(
    //     this.pkgBookingcustnames,
    //     (a) => a.cust_name
    //   );
    // });

    // this.service.getPackageBookingData3().subscribe((data) => {
    //   console.log(data);
    //   this.pkgBookingData3 = data;
    // });

    // this.service.getPackageBookingData5().subscribe((data) => {
    //   console.log(data);
    //   this.pkgBookingData5 = data;
    // });

    // this.getCreditVoucherNextId();

    this.service.getPackageId().subscribe((data) => {
      // console.log(data);
      if (data['id'] === null) {
        this.pkgForm.get('pkgname').setValue('योजना ' + 1);
      } else {
        this.pkgForm.get('pkgname').setValue('योजना ' + data['id']);
      }
    });

    // this.getDebitVoucherNextId();

    this.service.getPackageData().subscribe((data) => {
      this.packageData = data;
      console.log(data);

      for (
        let index = 0;
        index < Object.keys(this.packageData).length;
        index++
      ) {
        this.packagenames.push({
          pkgid: this.packageData[index]['pkg_id'],
          pkgname: this.packageData[index]['pkg_name'],
        });
      }
    });

    this.filteredautocom();

    this.creditvoucherForm
      .get('entrydate')
      .setValue(formatDate(new Date(), 'yyyy-MM-dd', 'en-IN', 'GMT+0530'));

    // this.getAllVouchers();
    this.getAdvtMagDetails();
    // this.getAllCreditVouchers(); // hiding because it is not is use in this component
    this.getAllCreditVoucherList();
    this.getBankNames();
    this.getBankNames1();

    this.getMainExpenseData();
    this.getSubExpenseData();
  }

  // getAllCreditVouchers() {
  //   this.service.getAllCreditVouchersData().subscribe((res) => {
  //     console.log(res);
  //   });
  // }

  getDebitVoucherNextId(date, type) {
    // console.log({ date });
    debugger
    this.service.getDebitVoucherPackageId(date, type).subscribe((res) => {
      // console.log({ res });
      if (Object.keys(res).length > 0) {
        let d = `${('0' + (date.getMonth() + 1)).slice(-2)}${(
          '0' + date.getDate()
        ).slice(-2)}`;
        // console.log('d', d, res[0]['d_voucher_no'].indexOf(d));
        let startIndex = res[0]['d_voucher_no'].indexOf(d) + d.length;
        // console.log('startIndex', startIndex);
        this.debit_voucher_id = parseInt(
          res[0]['d_voucher_no'].substring(startIndex)
        );
        if (isNaN(this.debit_voucher_id)) {
          this.debit_voucher_id = 0;
        }
        // console.log('debit_voucher_id', this.debit_voucher_id);
      } else {
        this.debit_voucher_id = 0;
        // console.log('debit_voucher_id', this.debit_voucher_id);
      }
    });
  }
  expense_report(){
    const dialogdata =  this.dialog.open(ExpenseledgerComponent, {
      data: {
        startDate: this.expenseledgerStartDate,
        endDate: this.expenseledgerEndDate,
        mainexp: this.expenseledger1.value.expnameother,
        subexp: this.expenseledger1.value.subexpnameother,
        mainexp1: this.expenseledger1.value.expnameotherd,
        subexp1: this.expenseledger1.value.subexpnameotherd,

      },
      
      width: '1200px',
      height: '1300px',
      maxWidth: '100%',
    });
    dialogdata.afterClosed().subscribe(result => {
      this.ngOnInit();  
      
    });
    console.log(this.expenseledger1.value)
  }

  journal_report(){
    console.log(this.expenReportStartDate11);
      console.log(this.expenReportEndDate11);
    const dialogdata =  this.dialog.open(JournaledgerlvoucherComponent, {
      data: {
        startDate: this.expenReportStartDate11,
        endDate: this.expenReportEndDate11,
        

      },
      
      width: '1200px',
      height: '1300px',
      maxWidth: '100%',
    });
    dialogdata.afterClosed().subscribe(result => {
      this.ngOnInit();  
      
    });
    console.log(this.expenseledger1.value)
  }

  getCreditVoucherNextId(date, type) {
    // console.log(date);
    this.service.getCreditVoucherId(date, type).subscribe((data) => {
      let dateObject = new Date(date);
      if (Object.keys(data).length > 0) {
        // console.log('id', this.id);
        let d = `${('0' + (dateObject.getMonth() + 1)).slice(-2)}${(
          '0' + dateObject.getDate()
        ).slice(-2)}`;
        let startIndex = data[0]['voucher_no'].indexOf(d) + d.length;
        this.id = parseInt(data[0]['voucher_no'].substring(startIndex));
        // this.id = 0;
        this.getCreditVoucherNo(dateObject);
      } else {
        this.id = 0;
        this.getCreditVoucherNo(dateObject);
        // console.log('id', this.id);
      }
      // console.log('id', this.id);
    });
  }

  getBankNames() {
    this.service.read_bank_names().subscribe((data) => {
      // console.log(data);
      if (data) {
        this.BankData = data;
        this.RCSBankData = data.filter((a) => a['bank_short_name'] !== 'CASH');
      }
    });
  }

  getBankNames1() {
    this.service.read_bank_names1().subscribe((data) => {
      // console.log(data);
      if (data) {
        this.BankData1 = data;
        console.log(this.BankData1, "bank detaolks")
        // this.RCSBankData = data.filter((a) => a['bank_short_name'] !== 'CASH');
      }
    });
  }

  filteredautocom() {
    this.filteredOptions1 = this.creditvoucherForm
      .get('rname')
      .valueChanges.pipe(
        startWith(''),
        map((value) => (typeof value === 'string' ? value : value.name)),
        map((name) =>
          name ? this._filter1(name) : this.issue_seller_name.slice()
        )
      );

    this.filteredOptions2 = this.pkgForm.get('pkgname').valueChanges.pipe(
      startWith(''),
      map((value) => (typeof value === 'string' ? value : value.pkgname)),
      map((pkgname) =>
        pkgname ? this._filter2(pkgname) : this.packagenames.slice()
      )
    );

    this.filteredOptions3 = this.pkgBookingregisterForm
      .get('pkg_name')
      .valueChanges.pipe(
        startWith(''),
        map((value) => (typeof value === 'string' ? value : value.pkg_name)),
        map((pkg_name) =>
          pkg_name ? this._filter3(pkg_name) : this.pkgBookingnames.slice()
        )
      );

    this.filteredOptions4 = this.debitForm.get('rname').valueChanges.pipe(
      startWith(''),
      map((value) => (typeof value === 'string' ? value : value.rep_name)),
      map((rep_name) =>
        rep_name ? this._filter1(rep_name) : this.issue_seller_name.slice()
      )
    );
  }

  private _filter1(name: string): any[] {
    const filterValue = name.toLowerCase();

    return this.issue_seller_name.filter((option) =>
      option.name.toLowerCase().includes(filterValue)
    );
  }

  private _filter2(pkgname: string): any[] {
    const filterValue = pkgname.toLowerCase();

    return this.packagenames.filter((option) =>
      option.pkgname.toLowerCase().includes(filterValue)
    );
  }

  private _filter3(pkg_name: string): any[] {
    const filterValue = pkg_name.toLowerCase();

    return this.pkgBookingnames.filter((option) =>
      option.pkg_name.toLowerCase().includes(filterValue)
    );
  }

  // private _filter4(rep_name: string): any[] {
  //   const filterValue = rep_name.toLowerCase();

  //   return this.pkgBookingrepnames.filter((option) =>
  //     option.rep_name.toLowerCase().includes(filterValue)
  //   );
  // }

  cashtype = true;
  bankType = '';
  getBankCash(event) {
    // console.log('Bank/Cash ', event.value);
    this.bankType = event.value;
    // let today = new Date();
    // if (this.id < 10) {
    //   this.id = '0' + (parseInt(this.id) + 1);
    // } else {
    //   this.id = parseInt(this.id) + 1;
    // }
    // console.log('voucherNo', voucherNo);
    // console.log('date of month', this.id);

    // console.log('vnumber from UI', this.creditvoucherForm.get('vnumber').value);
    this.cashtype = true;
    if (event.value === 'CASH') this.cashtype = false;
    this.creditvoucherForm.get('bcaccount').valueChanges.subscribe((val) => {
      if (event.value === 'CASH') {
        this.creditvoucherForm.controls['cnumber'].setValidators([
          Validators.required,
        ]);
      } else {
        this.creditvoucherForm.controls['cnumber'].clearValidators();
      }
      this.creditvoucherForm.controls['cnumber'].updateValueAndValidity();
    });
    // console.log(this.cashtype);
    this.check();
  }

  generateVoucherNo(event) {
    // console.log(event.target.value);
    // console.log(this.creditvoucherForm.get('voucherdate').value);
    let today = new Date(event.target.value);
    let formattedDate = today.toISOString().split('T')[0]; // This will give you yyyy-mm-dd format
    console.log(formattedDate, "date format ")

    this.getCreditVoucherNextId(formattedDate, this.bankType);
    // this.getCreditVoucherNo(today);
  }

  getCreditVoucherNo(today) {
    setTimeout(() => {
      console.log('id', this.id);
      let voucherNo = `${this.vouchervalue}${this.bankType}${(
        '0' +
        (today.getMonth() + 1)
      ).slice(-2)}${('0' + today.getDate()).slice(-2)}${
        this.id < 9 ? '0' + (parseInt(this.id) + 1) : this.id + 1
      }`;
      this.creditvoucherForm.get('vnumber').setValue(voucherNo);
    }, 200);
  }
  // getCreditVoucherNo(today) {
  //   setTimeout(() => {
  //     console.log('id before increment', this.id);
  //     this.id = parseInt(this.id) + 1; // Increment id by 1
  //     console.log('id after increment', this.id);
  
  // //     // Calculate financial year in "YY-YY" format
  // //     let currentYear = today.getFullYear();
  // //     let currentMonth = today.getMonth() + 1; // Month is zero-based
  // //     let financialYear;
  
  // //     // Financial year starts in April
  // //     if (currentMonth >= 4) {
  // //       let nextYear = currentYear + 1;
  // //       financialYear = `${currentYear % 100}-${nextYear % 100}`;
  // //     } else {
  // //       let lastYear = currentYear - 1;
  // //       financialYear = `${lastYear % 100}-${currentYear % 100}`;
  // //     }
  
  // //     // Generate the voucher number with only month and financial year
  // //     let voucherNo = `${this.vouchervalue}${this.bankType}${(
  // //       '0' + currentMonth
  // //     ).slice(-2)}${financialYear}/${
  // //       this.id < 10 ? '0' + this.id : this.id
  // //     }`;
  
  // //     // Set the voucher number in the form
  // //     this.creditvoucherForm.get('vnumber').setValue(voucherNo);
  // //   }, 200);
  // // }
  // // getCreditVoucherNo(today) {
  // //   setTimeout(() => {
  // //     console.log('id before increment', this.id);
  // //     this.id = parseInt(this.id) + 1; // Increment id by 1
  // //     console.log('id after increment', this.id);
  
  // //     // Calculate financial year in "YY-YY" format
  // //     let currentYear = today.getFullYear();
  // //     let currentMonth = today.getMonth() + 1; // Month is zero-based
  // //     let financialYear;
  
  // //     // Adjust financial year logic
  // //     if (currentMonth >= 4) {
  // //       // From April to December, financial year is currentYear-nextYear
  // //       let nextYear = currentYear + 1;
  // //       financialYear = `${currentYear % 100}-${nextYear % 100}`;
  // //     } else {
  // //       // From January to March, financial year is currentYear-nextYear
  // //       let nextYear = currentYear + 1;
  // //       financialYear = `${currentYear % 100}-${nextYear % 100}`;
  // //     }
  
  // //     // Generate the voucher number with only month and financial year
  // //     let voucherNo = `${this.vouchervalue}${this.bankType}${(
  // //       '0' + currentMonth
  // //     ).slice(-2)}${financialYear}/${
  // //       this.id < 10 ? '0' + this.id : this.id
  // //     }`;
  
  // //     // Set the voucher number in the form
  // //     this.creditvoucherForm.get('vnumber').setValue(voucherNo);
  // //   }, 200);
  // }
  
  
  
  
  
  

  debitbanktype = '';
  getBankCashDebit(event) {
    this.debitbanktype = event.value;
  }

  generateDebitVoucherNo(event) {
    
    let today = new Date(event.target.value);
    this.getDebitVoucherNextId(today, this.debitbanktype);
    this.getDebitVoucherNo(this.debitbanktype, today);
  }

  getDebitVoucherNo(type, today) {
    
    setTimeout(() => {
      // console.log('this.debit_voucher_id ', this.debit_voucher_id);

      let voucherNo = `DR${type}${('0' + (today.getMonth() + 1)).slice(-2)}${(
        '0' + today.getDate()
      ).slice(-2)}${
        this.debit_voucher_id < 9
          ? '0' + (parseInt(this.debit_voucher_id) + 1)
          : this.debit_voucher_id + 1
      }`;
      // console.log(voucherNo);
      this.debitForm.get('dvnumber').setValue(voucherNo);
    }, 200);
  }

  getAdvtMagDetails() {
    this.advt_mag_name_details = [];
    // this.stateGroups = [];
    let abc;
    this.service.getAdvtMagNames().subscribe((data) => {
      // console.log(data);
      for (let index = 0; index < Object.keys(data).length; index++) {
        // console.log(data[index]);
        this.advt_mag_name_details.push({
          id: data[index]['id'],
          is_active: data[index]['is_active'],
          issue_name: data[index]['issue_name'],
          category: data[index]['category'],
          publishing_date: data[index]['publishing_date'].split(','),
        });

        if (index == 0) {
          // console.log(
          //   data.filter((a) => a.category == data[index]['category'])
          // );
        }
      }
    });
  } //end of getAdvtMagDetails

  issueName;
  bookingdate;
  advtsizebyCategory;
  getFilterMagPublishDates = ['10-10-2021', ''];
  getMagIssueDatesandNames;
  getIssues(e) {
    // console.log(e);
    this.issueName = e.value;
    // console.log('jgfdfgh', this.issueName);
    this.service.getAdvtSizeByCategory(e.value).subscribe((data) => {
      this.advtsizebyCategory = data;
    });

    // console.log(
    //   'advt_mag_name_details',
    //   this.advt_mag_name_details.filter((a) => a.issue_name == this.issueName)
    // );
    this.service.getAdvtDatesAndNames(e.value).subscribe((data) => {
      this.getMagIssueDatesandNames = data;
      // console.log('Issue Dates and Names:', this.getMagIssueDatesandNames);
    });
  }

  getDates;
  stringdates;
  getAdvtDates(e) {
    // console.log(e.value.toString());
    this.getDates = e.value;
    this.getDates = this.getDates.filter((a) => a != 'all');
    // console.log('getDates', this.getDates);
    this.stringdates = this.getDates.toString();
    // console.log('StringDates', this.stringdates);
  }

  allSelected = false;
  @ViewChild('mySel') skillSel: MatSelect;
  toggleAllSelection() {
    this.allSelected = !this.allSelected; // to control select-unselect
    // console.log(this.skillSel.options);
    if (this.allSelected) {
      this.skillSel.options.forEach((item: MatOption) => item.select());
    } else {
      this.skillSel.options.forEach((item: MatOption) => {
        item.deselect();
      });
    }
    this.skillSel.close();
  }

  allSelected1 = false;
  @ViewChild('mySel1') skillSel1: MatSelect;
  toggleAllSelection1() {
    this.allSelected1 = !this.allSelected1; // to control select-unselect
    // console.log(this.skillSel1.options);
    if (this.allSelected1) {
      this.skillSel1.options.forEach((item: MatOption) => item.select());
    } else {
      this.skillSel1.options.forEach((item: MatOption) => {
        item.deselect();
      });
    }
    this.skillSel1.close();
  }

  allSelected2 = false;
  @ViewChild('mySel2') skillSel2: MatSelect;
  toggleAllSelection2() {
    this.allSelected2 = !this.allSelected2; // to control select-unselect
    // console.log(this.skillSel2.options);
    if (this.allSelected2) {
      this.skillSel2.options.forEach((item: MatOption) => item.select());
    } else {
      this.skillSel2.options.forEach((item: MatOption) => {
        item.deselect();
      });
    }
    this.skillSel2.close();
  }

  getAllVouchers() {
    this.service.getAllVouchers().subscribe((data) => {
      // console.log(data);
      this.getallVouchersData = data;
    });
  }

  deleteVoucherId(voucher_no) {
    if (window.confirm('Do you really want to delete this voucher?')) {
      this.service.updateVoucherStatus(voucher_no).subscribe((data) => {
        // console.log(data);
        this.getAllVouchers();
      });
    }
  }

  submitAdd() {
    window.alert('Started Generating the vouchers...');
    for (
      let index = 0;
      index < Object.keys(this.departmentSelection).length;
      index++
    ) {
      if (this.departmentSelection[index] == 'book') {
        this.service
          .insertCreditVoucher(
            this.vouchernumber,
            this.departmentSelection[index],
            this.ionicForm.get('bookcomment').value,
            this.ionicForm.get('bookpayment').value
          )
          .subscribe((data) => {
            console.log(data);
          });
      } else if (this.departmentSelection[index] == 'subscription') {
        this.service
          .insertCreditVoucher(
            this.vouchernumber,
            this.departmentSelection[index],
            this.ionicForm.get('subcomment').value,
            this.ionicForm.get('subpayment').value
          )
          .subscribe((data) => {
            console.log(data);
          });
      } else if (this.departmentSelection[index] == 'advertisement') {
        this.service
          .insertCreditVoucher(
            this.vouchernumber,
            this.departmentSelection[index],
            this.ionicForm.get('advtcomment').value,
            this.ionicForm.get('advtpayment').value
          )
          .subscribe((data) => {
            console.log(data);
          });
      }
    }

    setTimeout(() => {
      window.alert('Vouchers has been generated!');
      this.getAllVouchers();
      this.displaycrform = false;
      this.ionicForm.reset();
    }, 2000);
  }
  // val = [];
  // val1 = [];
  // val2 = [];
  // val3 = [];
  // val4 = [];
  // val5 = [];
  // val6 = [];

  sadd(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();

    // Add our fruit
    if (value) {
      this.names.push(value);
    }

    // Clear the input value
    if (event.input) {
      event.input.value = '';
    }
  }

  isadd(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();
    // let value1 = [];
    // Add our fruit
    if (value) {
      this.names1.push(value);
    }
    // Clear the input value
    if (event.input) {
      event.input.value = '';
      // this.val1 = [];
    }
  }

  aadd(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();

    // Add our fruit
    if (value) {
      this.names2.push(value);
    }

    // Clear the input value
    if (event.input) {
      event.input.value = '';
    }
  }
  dadd(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();

    // Add our fruit
    if (value) {
      this.names3.push(value);
    }

    // Clear the input value
    if (event.input) {
      event.input.value = '';
    }
  }
  gbadd(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();

    // Add our fruit
    if (value) {
      this.names4.push(value);
    }

    // Clear the input value
    if (event.input) {
      event.input.value = '';
    }
  }
  donadd(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();

    // Add our fruit
    if (value) {
      this.names5.push(value);
    }

    // Clear the input value
    if (event.input) {
      event.input.value = '';
    }
  }

  odadd(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();

    // Add our fruit
    if (value) {
      this.names6.push(value);
    }

    // Clear the input value
    // event.chipInput!.clear();
    if (event.input) {
      event.input.value = '';
    }
  }

  remove(fruit): void {
    const index = this.names.indexOf(fruit);
    const index1 = this.names1.indexOf(fruit);
    const index2 = this.names2.indexOf(fruit);
    const index3 = this.names3.indexOf(fruit);
    const index4 = this.names4.indexOf(fruit);
    const index5 = this.names5.indexOf(fruit);
    const index6 = this.names6.indexOf(fruit);

    if (index >= 0) {
      this.names.splice(index, 1);
    }
    if (index1 >= 0) {
      this.names1.splice(index1, 1);
    }
    if (index2 >= 0) {
      this.names2.splice(index2, 1);
    }
    if (index3 >= 0) {
      this.names3.splice(index3, 1);
    }
    if (index4 >= 0) {
      this.names4.splice(index4, 1);
    }
    if (index5 >= 0) {
      this.names5.splice(index5, 1);
    }
    if (index6 >= 0) {
      this.names6.splice(index6, 1);
    }
  }

  amt = 0;
  total(event) {
    let subscription =
      this.creditvoucherForm.get('subscription').value === ''
        ? 0
        : this.creditvoucherForm.get('subscription').value;
    let isale =
      this.creditvoucherForm.get('isale').value === ''
        ? 0
        : this.creditvoucherForm.get('isale').value;
    let adverties =
      this.creditvoucherForm.get('adverties').value === ''
        ? 0
        : this.creditvoucherForm.get('adverties').value;
    let deposits =
      this.creditvoucherForm.get('deposits').value === ''
        ? 0
        : this.creditvoucherForm.get('deposits').value;
    let granthbook =
      this.creditvoucherForm.get('granthbook').value === ''
        ? 0
        : this.creditvoucherForm.get('granthbook').value;
    let donation =
      this.creditvoucherForm.get('donation').value === ''
        ? 0
        : this.creditvoucherForm.get('donation').value;
    let odeposit =
      this.creditvoucherForm.get('odeposit').value === ''
        ? 0
        : this.creditvoucherForm.get('odeposit').value;
    // let value = event.target.value;
    // console.log('value', value);
    this.amt =
      parseFloat(subscription) +
      parseFloat(isale) +
      parseFloat(adverties) +
      parseFloat(deposits) +
      parseFloat(granthbook) +
      parseFloat(donation) +
      parseFloat(odeposit);
    this.creditvoucherForm.get('otheramount').setValue(this.amt);
    // this.creditvoucherForm.get('ndeposit').setValue(this.amt);
    this.totalAmt();
  }

  total_amt = 0;
  totalAmt() {
    // console.log('totalAmt run');
    let pkgamt = 0 + this.creditvoucherForm.get('packageamount')?.value;
    let otheramt = 0 + this.creditvoucherForm.get('otheramount')?.value;
    // console.log(this.creditvoucherForm.get('packageamount')?.value);
    // console.log(this.creditvoucherForm.get('otheramount')?.value);
    this.total_amt = parseFloat(pkgamt) + parseFloat(otheramt);
    // console.log(this.total_amt);

    this.creditvoucherForm.get('total').setValue(this.total_amt);
    this.creditvoucherForm.get('ndeposit').setValue(this.total_amt);
  }

  expamt = 0;
  totalExpense(event) {
    let travels =
      this.creditvoucherForm.get('travels').value === ''
        ? 0
        : this.creditvoucherForm.get('travels').value;
    let other =
      this.creditvoucherForm.get('other').value === ''
        ? 0
        : this.creditvoucherForm.get('other').value;
    let advtcommission =
      this.creditvoucherForm.get('advtcommission').value === ''
        ? 0
        : this.creditvoucherForm.get('advtcommission').value;
    let subscommission =
      this.creditvoucherForm.get('subscommission').value === ''
        ? 0
        : this.creditvoucherForm.get('subscommission').value;
    let issuecommission =
      this.creditvoucherForm.get('issuecommission').value === ''
        ? 0
        : this.creditvoucherForm.get('issuecommission').value;
    let bookcommission =
      this.creditvoucherForm.get('bookcommission').value === ''
        ? 0
        : this.creditvoucherForm.get('bookcommission').value;
    this.expamt =
      parseFloat(travels) +
      parseFloat(other) +
      parseFloat(subscommission) +
      parseFloat(issuecommission) +
      parseFloat(bookcommission) +
      parseFloat(advtcommission);
    this.creditvoucherForm.get('totalexp').setValue(this.expamt);

    if (this.total_amt != 0 && this.expamt != 0) {
      console.log(this.total_amt);

      this.creditvoucherForm
        .get('ndeposit')
        .setValue(this.total_amt - this.expamt);
    }
  }

  expdamt = 0;
  totaldebitExpense() {
    let travels =
      this.debitForm.get('travels').value === ''
        ? 0
        : this.debitForm.get('travels').value;
    let other =
      this.debitForm.get('other').value === ''
        ? 0
        : this.debitForm.get('other').value;
    let advtcommission =
      this.debitForm.get('advtcommission').value === ''
        ? 0
        : this.debitForm.get('advtcommission').value;
    let subscommission =
      this.debitForm.get('subscommission').value === ''
        ? 0
        : this.debitForm.get('subscommission').value;
    let issuecommission =
      this.debitForm.get('issuecommission').value === ''
        ? 0
        : this.debitForm.get('issuecommission').value;
    let bookcommission =
      this.debitForm.get('bookcommission').value === ''
        ? 0
        : this.debitForm.get('bookcommission').value;
    // console.log('bookcommission', bookcommission);
    this.expdamt =
      parseFloat(travels) +
      parseFloat(other) +
      parseFloat(subscommission) +
      parseFloat(issuecommission) +
      parseFloat(bookcommission) +
      parseFloat(advtcommission);
    this.debitForm.get('totalexp').setValue(this.expdamt);
    console.log(this.expdamt);

    // if (this.total_amt != 0 && this.expamt != 0) {
    //   this.creditvoucherForm
    //     .get('ndeposit')
    //     .setValue(this.total_amt - this.expamt);
    // }
  }

  displayadvtinput = false;
  displaybookinput = false;
  displaysubinput = false;
  departmentSelection = [];
  vouchernumber = '';
  getDepartment(event) {
    this.vouchernumber =
      formatDate(new Date(), 'yyyyMMdd', 'en-IN', 'GMT+0530') +
      new Date().getHours() +
      new Date().getMinutes();
    console.log(new Date().getMinutes());
    console.log('vouchernumber', this.vouchernumber);
    this.departmentSelection = event.value;

    if (event.value.find((a) => a == 'book') == 'book') {
      this.displaybookinput = true;
    } else {
      this.displaybookinput = false;
    }

    if (event.value.find((a) => a == 'subscription') == 'subscription') {
      this.displaysubinput = true;
    } else {
      this.displaysubinput = false;
    }

    if (event.value.find((a) => a == 'advertisement') == 'advertisement') {
      this.displayadvtinput = true;
    } else {
      this.displayadvtinput = false;
    }
  }

  credit_id = 0;
  save_creditvoucher() {
    this.insert_person_book_names();
    // console.log(this.val1);
    this.getCreditVoucherNextId(
      new Date(this.creditvoucherForm.get('voucherdate').value),
      this.bankType
    );
    // this.getCreditVoucherNo(
    //   new Date(this.creditvoucherForm.get('voucherdate').value)
    // );
    if (confirm('Do you want add credit voucher')) {
      let creditvoucher = {
        bcaccount: this.creditvoucherForm.get('bcaccount').value,
        vnumber: this.creditvoucherForm.get('vnumber').value,
        voucherdate: this.creditvoucherForm.get('voucherdate').value,
        rname: this.creditvoucherForm.get('rname').value,
        othername: this.creditvoucherForm.get('othername').value,
        damount: this.creditvoucherForm.get('damount').value,
        pymtoptions: this.creditvoucherForm.get('pymtoptions').value,
        cnumber: this.creditvoucherForm.get('cnumber').value,
        bank: this.creditvoucherForm.get('bank').value,
        branch: this.creditvoucherForm.get('branch').value,
        chequedate: this.creditvoucherForm.get('chequedate').value,
        entrydate: this.creditvoucherForm.get('entrydate').value,
        deposits: this.creditvoucherForm.get('deposits').value,
        donation: this.creditvoucherForm.get('donation').value,
        pancard: this.creditvoucherForm.get('pancard').value,
        narration: this.creditvoucherForm.get('narration').value,

        odeposit: this.creditvoucherForm.get('odeposit').value,
        packagecount: this.creditvoucherForm.get('packagecount').value,
        packageamount: this.creditvoucherForm.get('packageamount').value,
        otheramount: this.creditvoucherForm.get('otheramount').value,
        total: this.creditvoucherForm.get('total').value,
        expenditure: this.creditvoucherForm.get('expenditure').value,
        // travels: this.creditvoucherForm.get('travels').value,
        // commission: 0,
        // other: this.creditvoucherForm.get('other').value,
        totalexp: this.creditvoucherForm.get('totalexp').value,
        ndeposit: this.creditvoucherForm.get('ndeposit').value,
        
      };

      if (
        parseInt(this.creditvoucherForm.get('ndeposit').value) ===
        parseInt(this.creditvoucherForm.get('damount').value)
      ) {
        this.service.insertcreditVoucher(creditvoucher).subscribe((id) => {
          this.credit_id = parseInt(id['id']);

          // for (
          //   let index = 0;
          //   index < Object.keys(this.selectedPackages).length;
          //   index++
          // ) {
          //   let data = {
          //     crdt_id: this.credit_id,
          //     pkg_id: this.selectedPackages[index]['pkg_id'],
          //     pkg_name: this.selectedPackages[index]['pkg_name'],
          //     pkg_amt: this.selectedPackages[index]['pkg_amt'],
          //     client_name: this.selectedPackages[index]['client_name'],
          //   };
          //   this.service.insertCrdtPackage(data).subscribe((data) => {
          //     // console.log(data['id']);
          //     //no need to iterate over packageData
          //     for (let i = 0; i < Object.keys(this.packageData).length; i++) {
          //       let periodicty = this.packageData[i]['periodicity'].split(',');
          //       // console.log(periodicty);
          //       for (let j = 0; j < Object.keys(periodicty).length; j++) {
          //         if (
          //           this.packageData[i]['pkg_id'] ==
          //             this.selectedPackages[index]['pkg_id'] &&
          //           this.packageData[i]['pkg_name'] ==
          //             this.selectedPackages[index]['pkg_name'] &&
          //           periodicty[j] == '1 year'
          //         ) {
          //           // console.log('1 year');
          //           let pkgDetails = {
          //             crdt_pkg_details_id: data['id'],
          //             year: '1 year',
          //             sub_actual_amt:
          //               this.packageData[i]['vivek_m_1_actual_amt'],
          //             sub_concession_amt:
          //               this.packageData[i]['vivek_m_1_concession_amt'],
          //             vivek_h_actual_amt:
          //               this.packageData[i]['vivek_h_1_actual_amt'],
          //             vivek_h_concession_amt:
          //               this.packageData[i]['vivek_h_1_concession_amt'],
          //             vaidyraj_actual_amt:
          //               this.packageData[i]['vaidyraj_1_actual_amt'],
          //             vaidyraj_concession_amt:
          //               this.packageData[i]['vaidyraj_1_concession_amt'],
          //             granth_actual_amt:
          //               this.packageData[i]['granth1_1_actual_amt'],
          //             granth_concession_amt:
          //               this.packageData[i]['granth1_1_concession_amt'],
          //             book_actual_amt:
          //               this.packageData[i]['granth2_1_actual_amt'],
          //             book_concession_amt:
          //               this.packageData[i]['granth2_1_concession_amt'],
          //             donation_actual_amt:
          //               this.packageData[i]['donation_1_actual_amt'],
          //             donation_concession_amt:
          //               this.packageData[i]['donation_1_concession_amt'],
          //             courier_actual_amt:
          //               this.packageData[i]['courier_1_actual_amt'],
          //             courier_concession_amt:
          //               this.packageData[i]['courier_1_concession_amt'],
          //             advt_actual_amt: this.packageData[i]['advt_1_actual_amt'],
          //             advt_concession_amt:
          //               this.packageData[i]['advt_1_concession_amt'],
          //             other_actual_amt:
          //               this.packageData[i]['other_1_actual_amt'],
          //             other_concession_amt:
          //               this.packageData[i]['other_1_concession_amt'],
          //           };
          //           // console.log(pkgDetails);

          //           this.service
          //             .insertCrdtPackageDetails(pkgDetails)
          //             .subscribe((a) => {});
          //         } else if (
          //           this.packageData[i]['pkg_id'] ==
          //             this.selectedPackages[index]['pkg_id'] &&
          //           this.packageData[i]['pkg_name'] ==
          //             this.selectedPackages[index]['pkg_name'] &&
          //           periodicty[j] == '3 year'
          //         ) {
          //           // console.log('3 year');
          //           let pkgDetails = {
          //             crdt_pkg_details_id: data['id'],
          //             year: '3 year',
          //             sub_actual_amt:
          //               this.packageData[i]['vivek_m_3_actual_amt'],
          //             sub_concession_amt:
          //               this.packageData[i]['vivek_m_3_concession_amt'],
          //             vivek_h_actual_amt:
          //               this.packageData[i]['vivek_h_3_actual_amt'],
          //             vivek_h_concession_amt:
          //               this.packageData[i]['vivek_h_3_concession_amt'],
          //             vaidyraj_actual_amt:
          //               this.packageData[i]['vaidyraj_3_actual_amt'],
          //             vaidyraj_concession_amt:
          //               this.packageData[i]['vaidyraj_3_concession_amt'],
          //             granth_actual_amt:
          //               this.packageData[i]['granth1_3_actual_amt'],
          //             granth_concession_amt:
          //               this.packageData[i]['granth1_3_concession_amt'],
          //             book_actual_amt:
          //               this.packageData[i]['granth2_3_actual_amt'],
          //             book_concession_amt:
          //               this.packageData[i]['granth2_3_concession_amt'],
          //             donation_actual_amt:
          //               this.packageData[i]['donation_3_actual_amt'],
          //             donation_concession_amt:
          //               this.packageData[i]['donation_3_concession_amt'],
          //             courier_actual_amt:
          //               this.packageData[i]['courier_3_actual_amt'],
          //             courier_concession_amt:
          //               this.packageData[i]['courier_3_concession_amt'],
          //             advt_actual_amt: this.packageData[i]['advt_3_actual_amt'],
          //             advt_concession_amt:
          //               this.packageData[i]['advt_3_concession_amt'],
          //             other_actual_amt:
          //               this.packageData[i]['other_3_actual_amt'],
          //             other_concession_amt:
          //               this.packageData[i]['other_3_concession_amt'],
          //           };
          //           // console.log(pkgDetails);

          //           this.service
          //             .insertCrdtPackageDetails(pkgDetails)
          //             .subscribe((a) => {});
          //         } else if (
          //           this.packageData[i]['pkg_id'] ==
          //             this.selectedPackages[index]['pkg_id'] &&
          //           this.packageData[i]['pkg_name'] ==
          //             this.selectedPackages[index]['pkg_name'] &&
          //           periodicty[j] == '5 year'
          //         ) {
          //           // console.log('5 year');
          //           let pkgDetails = {
          //             crdt_pkg_details_id: data['id'],
          //             year: '5 year',
          //             sub_actual_amt:
          //               this.packageData[i]['vivek_m_5_actual_amt'],
          //             sub_concession_amt:
          //               this.packageData[i]['vivek_m_5_concession_amt'],
          //             vivek_h_actual_amt:
          //               this.packageData[i]['vivek_h_5_actual_amt'],
          //             vivek_h_concession_amt:
          //               this.packageData[i]['vivek_h_5_concession_amt'],
          //             vaidyraj_actual_amt:
          //               this.packageData[i]['vaidyraj_5_actual_amt'],
          //             vaidyraj_concession_amt:
          //               this.packageData[i]['vaidyraj_5_concession_amt'],
          //             granth_actual_amt:
          //               this.packageData[i]['granth1_5_actual_amt'],
          //             granth_concession_amt:
          //               this.packageData[i]['granth1_5_concession_amt'],
          //             book_actual_amt:
          //               this.packageData[i]['granth2_5_actual_amt'],
          //             book_concession_amt:
          //               this.packageData[i]['granth2_5_concession_amt'],
          //             donation_actual_amt:
          //               this.packageData[i]['donation_5_actual_amt'],
          //             donation_concession_amt:
          //               this.packageData[i]['donation_5_concession_amt'],
          //             courier_actual_amt:
          //               this.packageData[i]['courier_5_actual_amt'],
          //             courier_concession_amt:
          //               this.packageData[i]['courier_5_concession_amt'],
          //             advt_actual_amt: this.packageData[i]['advt_5_actual_amt'],
          //             advt_concession_amt:
          //               this.packageData[i]['advt_5_concession_amt'],
          //             other_actual_amt:
          //               this.packageData[i]['other_5_actual_amt'],
          //             other_concession_amt:
          //               this.packageData[i]['other_5_concession_amt'],
          //           };
          //           // console.log(pkgDetails);

          //           this.service
          //             .insertCrdtPackageDetails(pkgDetails)
          //             .subscribe((a) => {});
          //         }
          //       }
          //     }
          //   });
          // }
          for (
            let index = 0;
            index < Object.keys(this.selectedPackages).length;
            index++
          ) {
            let pkgdata = {
              crdt_id: this.credit_id,
              pkg_id: this.selectedPackages[index]['pkg_id'],
              pkg_name: this.selectedPackages[index]['pkg_name'],
              pkg_amt: this.selectedPackages[index]['pkg_amt'],
              client_name: this.selectedPackages[index]['client_name'],
            };
            this.service.insertCrdtPackage(pkgdata).subscribe((data) => {
              // console.log(data['id']);
              // let filterPackage = this.packageData.find(
              //   (a) => a['pkg_id'] == this.selectedPackages[index]['pkg_id']
              // );
              // console.log(filterPackage);
              //no need to iterate over packageData because it takes lot of time
              for (let i = 0; i < Object.keys(this.packageData).length; i++) {
                if (
                  this.packageData[i]['pkg_id'] ==
                  this.selectedPackages[index]['pkg_id']
                ) {
                  let periodicty =
                    this.packageData[i]['periodicity'].split(',');
                  // console.log(periodicty);
                  for (let j = 0; j < Object.keys(periodicty).length; j++) {
                    if (
                      this.packageData[i]['pkg_id'] ==
                        this.selectedPackages[index]['pkg_id'] &&
                      this.packageData[i]['pkg_name'] ==
                        this.selectedPackages[index]['pkg_name'] &&
                      periodicty[j] == '1 year'
                    ) {
                      console.log('1 year', this.packageData[i]);
                      let pkgDetails = {
                        crdt_pkg_details_id: data['id'],
                        year: '1 year',
                        sub_actual_amt:
                          this.packageData[i]['vivek_m_1_actual_amt'],
                        sub_concession_amt:
                          this.packageData[i]['vivek_m_1_concession_amt'],
                        vivek_h_actual_amt:
                          this.packageData[i]['vivek_h_1_actual_amt'],
                        vivek_h_concession_amt:
                          this.packageData[i]['vivek_h_1_concession_amt'],
                        vaidyraj_actual_amt:
                          this.packageData[i]['vaidyraj_1_actual_amt'],
                        vaidyraj_concession_amt:
                          this.packageData[i]['vaidyraj_1_concession_amt'],
                        granth_actual_amt:
                          this.packageData[i]['granth1_1_actual_amt'],
                        granth_remaining_amt:
                          this.selectedPackages[index]['pkg_amt'],
                        granth_concession_amt:
                          this.packageData[i]['granth1_1_concession_amt'],
                        book_actual_amt:
                          this.packageData[i]['granth2_1_actual_amt'],
                        book_concession_amt:
                          this.packageData[i]['granth2_1_concession_amt'],
                        donation_actual_amt:
                          this.packageData[i]['donation_1_actual_amt'],
                        donation_concession_amt:
                          this.packageData[i]['donation_1_concession_amt'],
                        courier_actual_amt:
                          this.packageData[i]['courier_1_actual_amt'],
                        courier_concession_amt:
                          this.packageData[i]['courier_1_concession_amt'],
                        advt_actual_amt:
                          this.packageData[i]['advt_1_actual_amt'],
                        advt_concession_amt:
                          this.packageData[i]['advt_1_concession_amt'],
                        other_actual_amt:
                          this.packageData[i]['other_1_actual_amt'],
                        other_concession_amt:
                          this.packageData[i]['other_1_concession_amt'],
                      };
                      // console.log(pkgDetails);

                      this.service
                        .insertCrdtPackageDetails(pkgDetails)
                        .subscribe((a) => {});
                    } else if (
                      this.packageData[i]['pkg_id'] ==
                        this.selectedPackages[index]['pkg_id'] &&
                      this.packageData[i]['pkg_name'] ==
                        this.selectedPackages[index]['pkg_name'] &&
                      periodicty[j] == '3 year'
                    ) {
                      // console.log('3 year');
                      let pkgDetails = {
                        crdt_pkg_details_id: data['id'],
                        year: '3 year',
                        sub_actual_amt:
                          this.packageData[i]['vivek_m_3_actual_amt'],
                        sub_concession_amt:
                          this.packageData[i]['vivek_m_3_concession_amt'],
                        vivek_h_actual_amt:
                          this.packageData[i]['vivek_h_3_actual_amt'],
                        vivek_h_concession_amt:
                          this.packageData[i]['vivek_h_3_concession_amt'],
                        vaidyraj_actual_amt:
                          this.packageData[i]['vaidyraj_3_actual_amt'],
                        vaidyraj_concession_amt:
                          this.packageData[i]['vaidyraj_3_concession_amt'],
                        granth_actual_amt:
                          this.packageData[i]['granth1_3_actual_amt'],
                        granth_remaining_amt:
                          this.selectedPackages[index]['pkg_amt'],
                        granth_concession_amt:
                          this.packageData[i]['granth1_3_concession_amt'],
                        book_actual_amt:
                          this.packageData[i]['granth2_3_actual_amt'],
                        book_concession_amt:
                          this.packageData[i]['granth2_3_concession_amt'],
                        donation_actual_amt:
                          this.packageData[i]['donation_3_actual_amt'],
                        donation_concession_amt:
                          this.packageData[i]['donation_3_concession_amt'],
                        courier_actual_amt:
                          this.packageData[i]['courier_3_actual_amt'],
                        courier_concession_amt:
                          this.packageData[i]['courier_3_concession_amt'],
                        advt_actual_amt:
                          this.packageData[i]['advt_3_actual_amt'],
                        advt_concession_amt:
                          this.packageData[i]['advt_3_concession_amt'],
                        other_actual_amt:
                          this.packageData[i]['other_3_actual_amt'],
                        other_concession_amt:
                          this.packageData[i]['other_3_concession_amt'],
                      };
                      // console.log(pkgDetails);

                      this.service
                        .insertCrdtPackageDetails(pkgDetails)
                        .subscribe((a) => {});
                    } else if (
                      this.packageData[i]['pkg_id'] ==
                        this.selectedPackages[index]['pkg_id'] &&
                      this.packageData[i]['pkg_name'] ==
                        this.selectedPackages[index]['pkg_name'] &&
                      periodicty[j] == '5 year'
                    ) {
                      // console.log('5 year');
                      let pkgDetails = {
                        crdt_pkg_details_id: data['id'],
                        year: '5 year',
                        sub_actual_amt:
                          this.packageData[i]['vivek_m_5_actual_amt'],
                        sub_concession_amt:
                          this.packageData[i]['vivek_m_5_concession_amt'],
                        vivek_h_actual_amt:
                          this.packageData[i]['vivek_h_5_actual_amt'],
                        vivek_h_concession_amt:
                          this.packageData[i]['vivek_h_5_concession_amt'],
                        vaidyraj_actual_amt:
                          this.packageData[i]['vaidyraj_5_actual_amt'],
                        vaidyraj_concession_amt:
                          this.packageData[i]['vaidyraj_5_concession_amt'],
                        granth_actual_amt:
                          this.packageData[i]['granth1_5_actual_amt'],
                        granth_remaining_amt:
                          this.selectedPackages[index]['pkg_amt'],
                        granth_concession_amt:
                          this.packageData[i]['granth1_5_concession_amt'],
                        book_actual_amt:
                          this.packageData[i]['granth2_5_actual_amt'],
                        book_concession_amt:
                          this.packageData[i]['granth2_5_concession_amt'],
                        donation_actual_amt:
                          this.packageData[i]['donation_5_actual_amt'],
                        donation_concession_amt:
                          this.packageData[i]['donation_5_concession_amt'],
                        courier_actual_amt:
                          this.packageData[i]['courier_5_actual_amt'],
                        courier_concession_amt:
                          this.packageData[i]['courier_5_concession_amt'],
                        advt_actual_amt:
                          this.packageData[i]['advt_5_actual_amt'],
                        advt_concession_amt:
                          this.packageData[i]['advt_5_concession_amt'],
                        other_actual_amt:
                          this.packageData[i]['other_5_actual_amt'],
                        other_concession_amt:
                          this.packageData[i]['other_5_concession_amt'],
                      };
                      // console.log(pkgDetails);

                      this.service
                        .insertCrdtPackageDetails(pkgDetails)
                        .subscribe((a) => {});
                    }
                  }
                }
              }
            });
          }

          if (parseInt(this.creditvoucherForm.get('donation').value) > 0) {
            // console.log(
            //   this.creditvoucherForm.get('donation').value,
            //   this.names5.toString()
            // );

            this.service
              .insertCreditVoucherDonationNames(
                this.credit_id,
                this.names5.toString()
              )
              .subscribe((data) => {
                this.names5 = [];
              });
          }

          if (parseInt(this.creditvoucherForm.get('deposits').value) > 0) {
            // console.log(
            //   this.creditvoucherForm.get('deposits').value,
            //   this.names3.toString()
            // );
            this.service
              .insertCreditVoucherDepositsNames(
                this.credit_id,
                this.names3.toString()
              )
              .subscribe((data) => {
                this.names3 = [];
              });
          }

          if (parseInt(this.creditvoucherForm.get('odeposit').value) > 0) {
            // console.log(
            //   this.creditvoucherForm.get('odeposit').value,
            //   this.names6.toString()
            // );
            this.service
              .insertCreditVoucherOtherDepositsNames(
                this.credit_id,
                this.names6.toString()
              )
              .subscribe((data) => {
                this.names6 = [];
              });
          }

          //Replaced by other logic

          // let subcom = {
          //   crdt_vucher_id: this.credit_id,
          //   subcom: this.creditvoucherForm.get('subscommission').value,
          // };
          // this.service.insertSubCommission(subcom).subscribe((data) => {});
          // let issuecom = {
          //   crdt_vucher_id: this.credit_id,
          //   subcom: this.creditvoucherForm.get('issuecommission').value,
          // };
          // this.service.insertIssueCommission(issuecom).subscribe((data) => {});
          // let advtcom = {
          //   crdt_vucher_id: this.credit_id,
          //   subcom: this.creditvoucherForm.get('advtcommission').value,
          // };
          // this.service.insertAdvtCommission(advtcom).subscribe((data) => {});
          // let bookcom = {
          //   crdt_vucher_id: this.credit_id,
          //   subcom: this.creditvoucherForm.get('bookcommission').value,
          // };
          // this.service.insertBookCommission(bookcom).subscribe((data) => {});

          this.selectedMainExpenses.map((exp) => {
            let data = {
              voucher_id: this.credit_id,
              mainexpid: exp.mainexpid,
              expamt: exp.expamt,
            };

            // console.log(data);
            this.service.addCreditMainExpense(data).subscribe((res) => {
              // console.log(res);
              let subData = this.selectedsubExpenses.filter(
                (a) => a['mainexpid'] === data['mainexpid']
              );
              // console.log(subData);
              if (Object.keys(subData).length > 0) {
                subData.map((a) => {
                  let subexpdata = {
                    mainid: res['id'],
                    subexpid: a['subexpid'],
                    expamt: a['expamt'],
                  };
                  this.service
                    .addCreditSubExpense(subexpdata)
                    .subscribe((data) => {
                      // console.log(data);
                    });
                });
              }
            });
          });

          this.selectedMainExpenses1.map((exp) => {
            let data = {
              voucher_id: this.credit_id,
              mainexpid: exp.mainexpid,
              expamt: exp.expamt,
            };

            // console.log(data);
            this.service.otherMainExpense(data).subscribe((res) => {
              // console.log(res);
              let subData = this.selectedsubExpenses1.filter(
                (a) => a['mainexpid'] === data['mainexpid']
              );
              // console.log(subData);
              if (Object.keys(subData).length > 0) {
                subData.map((a) => {
                  let subexpdata = {
                    mainid: res['id'],
                    subexpid: a['subexpid'],
                    expamt: a['expamt'],
                  };
                  this.service
                    .otherSubExpense(subexpdata)
                    .subscribe((data) => {
                      // console.log(data);
                    });
                });
              }
            });
          });

          //main income adding to respective department
          if (parseInt(this.creditvoucherForm.get('subscription').value) > 0) {
            console.log(
              this.creditvoucherForm.get('subscription').value,
              this.names.toString()
            );
            let sub = {
              crdt_vucher_id: this.credit_id,
              subscription: this.creditvoucherForm.get('subscription').value,
            };

            this.service
              .insertCreditVoucherSubscribers(sub)
              .subscribe((subid) => {
                let id = parseInt(subid['id']);
                this.service
                  .insertCreditVoucherSubscribersNames(
                    id,
                    this.names.toString()
                  )
                  .subscribe((data) => {
                    this.names = [];
                  });
              });
          }

          if (parseInt(this.creditvoucherForm.get('adverties').value) > 0) {
            console.log(
              this.creditvoucherForm.get('adverties').value,
              this.names2.toString()
            );
            let advt = {
              crdt_vucher_id: this.credit_id,
              adverties: this.creditvoucherForm.get('adverties').value,
            };

            this.service.insertCreditVoucherAdvt(advt).subscribe((advtid) => {
              let id = parseInt(advtid['id']);
              this.service
                .insertCreditVoucherAdvtNames(id, this.names2.toString())
                .subscribe((data) => {
                  this.names2 = [];
                });
            });
          }

          if (parseInt(this.creditvoucherForm.get('granthbook').value) > 0) {
            console.log(
              this.creditvoucherForm.get('granthbook').value,
              this.names4.toString()
            );
            let book = {
              crdt_vucher_id: this.credit_id,
              granthbook: this.creditvoucherForm.get('granthbook').value,
            };

            this.service.insertCreditVoucherBook(book).subscribe((bookid) => {
              let id = parseInt(bookid['id']);
              this.service
                .insertCreditVoucherBookNames(id, this.names4.toString())
                .subscribe((data) => {
                  this.names4 = [];
                });
            });
          }

          if (parseInt(this.creditvoucherForm.get('isale').value) > 0) {
            console.log(
              this.creditvoucherForm.get('isale').value,
              this.names1.toString()
            );
            let issuesale = {
              crdt_vucher_id: this.credit_id,
              isale: this.creditvoucherForm.get('isale').value,
            };

            this.service
              .insertCreditVoucherIssueSale(issuesale)
              .subscribe((issueid) => {
                let id = parseInt(issueid['id']);
                this.service
                  .insertCreditVoucherIssueSaleNames(id, this.names1.toString())
                  .subscribe((data) => {
                    this.names1 = [];
                  });
              });
          }
          // console.log(' Credit Voucher Values', creditvoucher);
          // console.log(' Credit Voucher subscription Values', sub);
          // console.log(' Credit Voucher advertisement Values', advt);
          // console.log(' Credit Voucher issuesale Values', issuesale);
          // console.log(' Credit Voucher book Values', book);

          setTimeout(() => {
            alert('Credit Voucher Added Successfully...');
            this.creditvoucherForm.reset();
            this.selectedMainExpenses = [];
            this.selectedsubExpenses = [];
            this.selectedMainExpenses1 = [];
            this.selectedsubExpenses1 = [];
            // this.ngOnInit();
            // location.reload();
          }, 200);
        });
      } else {
        alert('Please check the amount.');
      }
    }
    this.ngOnInit();
  }

  SelectRepresentative() {}

  getAllCreditVoucherList() {
    this.service.getAllCreditVoucherList().subscribe((res) => {
      // console.log(res);
      this.allCreditVoucherListData = res;

      this.totalcrdtvreport = {
        depositamt: 0,
        sub: 0,
        issue: 0,
        advt: 0,
        deposit: 0,
        book: 0,
        pkg_amt: 0,
        donation: 0,
        other_deposit: 0,
        // travel: 0,
        // other: 0,
        // sub_com: 0,
        // is_com: 0,
        // advt_com: 0,
        // gb_com: 0,
        total: 0,
        total_exp: 0,
        net_deposit: 0,
      };
      for (let i = 0; i < this.allCreditVoucherListData.length; i++) {
        this.totalcrdtvreport['depositamt'] += parseInt(
          this.allCreditVoucherListData[i]['deposit_amt']
        );
        this.totalcrdtvreport['sub'] += parseInt(
          this.allCreditVoucherListData[i]['subscription']
        );
        this.totalcrdtvreport['issue'] += parseInt(
          this.allCreditVoucherListData[i]['issuesale']
        );
        this.totalcrdtvreport['advt'] += parseInt(
          this.allCreditVoucherListData[i]['advertiesment']
        );
        this.totalcrdtvreport['deposit'] += parseInt(
          this.allCreditVoucherListData[i]['deposits']
        );
        this.totalcrdtvreport['book'] += parseInt(
          this.allCreditVoucherListData[i]['book']
        );
        this.totalcrdtvreport['pkg_amt'] += parseInt(
          this.allCreditVoucherListData[i]['packageamount']
        );
        this.totalcrdtvreport['donation'] += parseInt(
          this.allCreditVoucherListData[i]['donation']
        );
        this.totalcrdtvreport['other_deposit'] += parseInt(
          this.allCreditVoucherListData[i]['other_deposit']
        );
        this.totalcrdtvreport['total'] += parseInt(
          this.allCreditVoucherListData[i]['total']
        );
        // this.totalcrdtvreport['travel'] += parseInt(
        //   this.allCreditVoucherListData[i]['travels']
        // );
        // this.totalcrdtvreport['other'] += parseInt(
        //   this.allCreditVoucherListData[i]['other']
        // );
        // this.totalcrdtvreport['sub_com'] += parseInt(
        //   this.allCreditVoucherListData[i]['sub_commission']
        // );
        // this.totalcrdtvreport['is_com'] += parseInt(
        //   this.allCreditVoucherListData[i]['issue_commission']
        // );
        // this.totalcrdtvreport['advt_com'] += parseInt(
        //   this.allCreditVoucherListData[i]['advt_commission']
        // );
        // this.totalcrdtvreport['gb_com'] += parseInt(
        //   this.allCreditVoucherListData[i]['book_commission']
        // );
        this.totalcrdtvreport['total_exp'] += parseInt(
          this.allCreditVoucherListData[i]['totalexp']
        );
        this.totalcrdtvreport['net_deposit'] += parseInt(
          this.allCreditVoucherListData[i]['net_deposit']
        );
      }
    });
  }

  //hold on for now
  save_debitvoucher() {
    // debugger
    // this.getDebitVoucherNextId(
    //   new Date(),
    //   this.debitForm.get('bcaccount').value
    // );
    // debugger
    // this.getDebitVoucherNo(this.debitForm.get('bcaccount').value, new Date());
    setTimeout(() => {
      
      let data = {
        bcaccount: this.debitForm.get('bcaccount').value,
        dvnumber: this.debitForm.get('dvnumber').value,
        dvoucherdate: this.debitForm.get('dvoucherdate').value,
        rname: this.debitForm.get('rname').value,
        othername: this.debitForm.get('othername').value,
        exp_amt: this.debitForm.get('exp_amt').value,
        chequeno: this.debitForm.get('chequeno').value,
        chequedate: this.datePipe.transform(this.debitForm.get('chequedate').value, 'yyyy-MM-dd'),
        // other: this.debitForm.get('other').value,
        // subscommission: this.debitForm.get('subscommission').value,
        // issuecommission: this.debitForm.get('issuecommission').value,
        // advtcommission: this.debitForm.get('advtcommission').value,
        // bookcommission: this.debitForm.get('bookcommission').value,
        totalexp: this.debitForm.get('totalexp').value,
      };

      // console.log({ data });
      if (
        parseInt(this.debitForm.get('exp_amt').value) ===
        parseInt(this.debitForm.get('totalexp').value)
      ) {
        this.service.addDebitVoucher(data).subscribe((res) => {
          // console.log(res);
          this.selecteddebitMainExpenses.map((exp) => {
            let data = {
              voucher_id: res['id'],
              mainexpid: exp.mainexpid,
              expamt: exp.expamt,
            };

            console.log(data);
            let subData = this.selecteddebitsubExpenses.filter(
              (a) => a['mainexpid'] === data['mainexpid']
            );
            this.service.addDebitMainExpense(data).subscribe((result) => {
              console.log(result);
              console.log(subData);
              // if (Object.keys(subData).length > 0) {
              subData.map((a) => {
                let subexpdata = {
                  mainid: result['id'],
                  subexpid: a['subexpid'],
                  expamt: a['expamt'],
                };
                this.service
                  .addDebitSubExpense(subexpdata)
                  .subscribe((data) => {
                    console.log(data);
                  });
              });
              // }
            });
          });
          alert('Debit Voucher Added Successfully...');
          this.debitForm.reset();
          this.selecteddebitMainExpenses = [];
          this.selecteddebitsubExpenses = [];
          this.ngOnInit();
        });
      } else {
        alert('Please check the amount.');
      }
    }, 200);
  }

  pkgStartDate = '';
  pkgEndDate = '';
  getChangedDatepkgv() {
    if (
      this.pkgForm.get('start').value != null &&
      this.pkgForm.get('end').value != null
    ) {
      this.pkgStartDate = formatDate(
        new Date(this.pkgForm.get('start').value),
        'yyyy-MM-dd',
        'en-IN',
        'GMT+0530'
      ).toString();
      this.pkgEndDate = formatDate(
        new Date(this.pkgForm.get('end').value),
        'yyyy-MM-dd',
        'en-IN',
        'GMT+0530'
      ).toString();

      // console.log(this.pkgStartDate);
      // console.log(this.pkgEndDate);
    }
  }

  show1year: boolean = false;
  show3year: boolean = false;
  show5year: boolean = false;
  getPeriodicity(event) {
    // let data = event.value.toString();
    // console.log('object',data.split(','));
    // console.log(event.value.toString());

    if (event.value.find((a) => a == '1 year') == '1 year') {
      this.show1year = true;
    } else {
      this.show1year = false;
    }

    if (event.value.find((a) => a == '3 year') == '3 year') {
      this.show3year = true;
    } else {
      this.show3year = false;
    }

    if (event.value.find((a) => a == '5 year') == '5 year') {
      this.show5year = true;
    } else {
      this.show5year = false;
    }
  }

  actualamt1total = 0;
  getActualAmt1(event) {
    // console.log(event.target.value);
    this.actualamt1total =
      (this.pkgForm.get('vivekm1actualamt').value === ''
        ? 0
        : parseFloat(this.pkgForm.get('vivekm1actualamt').value)) +
      (this.pkgForm.get('vivekh1actualamt').value === ''
        ? 0
        : parseFloat(this.pkgForm.get('vivekh1actualamt').value)) +
      (this.pkgForm.get('vaidyraj1actualamt').value === ''
        ? 0
        : parseFloat(this.pkgForm.get('vaidyraj1actualamt').value)) +
      (this.pkgForm.get('granth11actualamt').value === ''
        ? 0
        : parseFloat(this.pkgForm.get('granth11actualamt').value)) +
      (this.pkgForm.get('granth21actualamt').value === ''
        ? 0
        : parseFloat(this.pkgForm.get('granth21actualamt').value)) +
      (this.pkgForm.get('donation1actualamt').value === ''
        ? 0
        : parseFloat(this.pkgForm.get('donation1actualamt').value)) +
      (this.pkgForm.get('advt1actualamt').value === ''
        ? 0
        : parseFloat(this.pkgForm.get('advt1actualamt').value)) +
      (this.pkgForm.get('courier1actualamt').value === ''
        ? 0
        : parseFloat(this.pkgForm.get('courier1actualamt').value)) +
      (this.pkgForm.get('other1actualamt').value === ''
        ? 0
        : parseFloat(this.pkgForm.get('other1actualamt').value));
  }

  concessionamt1total = 0;
  getConcessionAmt1(event) {
    this.concessionamt1total =
      (this.pkgForm.get('vivekm1concessionamt').value === ''
        ? 0
        : parseFloat(this.pkgForm.get('vivekm1concessionamt').value)) +
      (this.pkgForm.get('vivekh1concessionamt').value === ''
        ? 0
        : parseFloat(this.pkgForm.get('vivekh1concessionamt').value)) +
      (this.pkgForm.get('vaidyraj1concessionamt').value === ''
        ? 0
        : parseFloat(this.pkgForm.get('vaidyraj1concessionamt').value)) +
      (this.pkgForm.get('granth11concessionamt').value === ''
        ? 0
        : parseFloat(this.pkgForm.get('granth11concessionamt').value)) +
      (this.pkgForm.get('granth21concessionamt').value === ''
        ? 0
        : parseFloat(this.pkgForm.get('granth21concessionamt').value)) +
      (this.pkgForm.get('donation1concessionamt').value === ''
        ? 0
        : parseFloat(this.pkgForm.get('donation1concessionamt').value)) +
      (this.pkgForm.get('advt1concessionamt').value === ''
        ? 0
        : parseFloat(this.pkgForm.get('advt1concessionamt').value)) +
      (this.pkgForm.get('courier1concessionamt').value === ''
        ? 0
        : parseFloat(this.pkgForm.get('courier1concessionamt').value)) +
      (this.pkgForm.get('other1concessionamt').value === ''
        ? 0
        : parseFloat(this.pkgForm.get('other1concessionamt').value));
  }

  actualamt3total = 0;
  getActualAmt3(event) {
    // console.log(event.target.value);
    this.actualamt3total =
      (this.pkgForm.get('vivekm3actualamt').value === ''
        ? 0
        : parseFloat(this.pkgForm.get('vivekm3actualamt').value)) +
      (this.pkgForm.get('vivekh3actualamt').value === ''
        ? 0
        : parseFloat(this.pkgForm.get('vivekh3actualamt').value)) +
      (this.pkgForm.get('vaidyraj3actualamt').value === ''
        ? 0
        : parseFloat(this.pkgForm.get('vaidyraj3actualamt').value)) +
      (this.pkgForm.get('granth13actualamt').value === ''
        ? 0
        : parseFloat(this.pkgForm.get('granth13actualamt').value)) +
      (this.pkgForm.get('granth23actualamt').value === ''
        ? 0
        : parseFloat(this.pkgForm.get('granth23actualamt').value)) +
      (this.pkgForm.get('donation3actualamt').value === ''
        ? 0
        : parseFloat(this.pkgForm.get('donation3actualamt').value)) +
      (this.pkgForm.get('advt3actualamt').value === ''
        ? 0
        : parseFloat(this.pkgForm.get('advt3actualamt').value)) +
      (this.pkgForm.get('courier3actualamt').value === ''
        ? 0
        : parseFloat(this.pkgForm.get('courier3actualamt').value)) +
      (this.pkgForm.get('other3actualamt').value === ''
        ? 0
        : parseFloat(this.pkgForm.get('other3actualamt').value));
  }

  concessionamt3total = 0;
  getConcessionAmt3(event) {
    this.concessionamt3total =
      (this.pkgForm.get('vivekm3concessionamt').value === ''
        ? 0
        : parseFloat(this.pkgForm.get('vivekm3concessionamt').value)) +
      (this.pkgForm.get('vivekh3concessionamt').value === ''
        ? 0
        : parseFloat(this.pkgForm.get('vivekh3concessionamt').value)) +
      (this.pkgForm.get('vaidyraj3concessionamt').value === ''
        ? 0
        : parseFloat(this.pkgForm.get('vaidyraj3concessionamt').value)) +
      (this.pkgForm.get('granth13concessionamt').value === ''
        ? 0
        : parseFloat(this.pkgForm.get('granth13concessionamt').value)) +
      (this.pkgForm.get('granth23concessionamt').value === ''
        ? 0
        : parseFloat(this.pkgForm.get('granth23concessionamt').value)) +
      (this.pkgForm.get('donation3concessionamt').value === ''
        ? 0
        : parseFloat(this.pkgForm.get('donation3concessionamt').value)) +
      (this.pkgForm.get('advt3concessionamt').value === ''
        ? 0
        : parseFloat(this.pkgForm.get('advt3concessionamt').value)) +
      (this.pkgForm.get('courier3concessionamt').value === ''
        ? 0
        : parseFloat(this.pkgForm.get('courier3concessionamt').value)) +
      (this.pkgForm.get('other3concessionamt').value === ''
        ? 0
        : parseFloat(this.pkgForm.get('other3concessionamt').value));
  }

  actualamt5total = 0;
  getActualAmt5(event) {
    // console.log(event.target.value);
    this.actualamt5total =
      (this.pkgForm.get('vivekm5actualamt').value === ''
        ? 0
        : parseFloat(this.pkgForm.get('vivekm5actualamt').value)) +
      (this.pkgForm.get('vivekh5actualamt').value === ''
        ? 0
        : parseFloat(this.pkgForm.get('vivekh5actualamt').value)) +
      (this.pkgForm.get('vaidyraj5actualamt').value === ''
        ? 0
        : parseFloat(this.pkgForm.get('vaidyraj5actualamt').value)) +
      (this.pkgForm.get('granth15actualamt').value === ''
        ? 0
        : parseFloat(this.pkgForm.get('granth15actualamt').value)) +
      (this.pkgForm.get('granth25actualamt').value === ''
        ? 0
        : parseFloat(this.pkgForm.get('granth25actualamt').value)) +
      (this.pkgForm.get('donation5actualamt').value === ''
        ? 0
        : parseFloat(this.pkgForm.get('donation5actualamt').value)) +
      (this.pkgForm.get('advt5actualamt').value === ''
        ? 0
        : parseFloat(this.pkgForm.get('advt5actualamt').value)) +
      (this.pkgForm.get('courier5actualamt').value === ''
        ? 0
        : parseFloat(this.pkgForm.get('courier5actualamt').value)) +
      (this.pkgForm.get('other5actualamt').value === ''
        ? 0
        : parseFloat(this.pkgForm.get('other5actualamt').value));
  }

  concessionamt5total = 0;
  getConcessionAmt5(event) {
    this.concessionamt5total =
      (this.pkgForm.get('vivekm5concessionamt').value === ''
        ? 0
        : parseFloat(this.pkgForm.get('vivekm5concessionamt').value)) +
      (this.pkgForm.get('vivekh5concessionamt').value === ''
        ? 0
        : parseFloat(this.pkgForm.get('vivekh5concessionamt').value)) +
      (this.pkgForm.get('vaidyraj5concessionamt').value === ''
        ? 0
        : parseFloat(this.pkgForm.get('vaidyraj5concessionamt').value)) +
      (this.pkgForm.get('granth15concessionamt').value === ''
        ? 0
        : parseFloat(this.pkgForm.get('granth15concessionamt').value)) +
      (this.pkgForm.get('granth25concessionamt').value === ''
        ? 0
        : parseFloat(this.pkgForm.get('granth25concessionamt').value)) +
      (this.pkgForm.get('donation5concessionamt').value === ''
        ? 0
        : parseFloat(this.pkgForm.get('donation5concessionamt').value)) +
      (this.pkgForm.get('advt5concessionamt').value === ''
        ? 0
        : parseFloat(this.pkgForm.get('advt5concessionamt').value)) +
      (this.pkgForm.get('courier5concessionamt').value === ''
        ? 0
        : parseFloat(this.pkgForm.get('courier5concessionamt').value)) +
      (this.pkgForm.get('other5concessionamt').value === ''
        ? 0
        : parseFloat(this.pkgForm.get('other5concessionamt').value));
  }

  AddPackage() {
    let data = {
      pkgname: this.pkgForm.get('pkgname').value,
      startdate: this.pkgStartDate,
      enddate: this.pkgEndDate,
      pkgamt: this.pkgForm.get('pkgamt').value,
      periodicity: this.pkgForm.get('periodicity').value.toString(),
    };

    // console.log('before api call', data);
    this.service.addPackage(data).subscribe((data) => {
      console.log(data);
      if (this.show1year) {
        // console.log('inside 1 year');

        let pkgdata1 = {
          pkg_id: data['id'],
          vivekm1actualamt: this.pkgForm.get('vivekm1actualamt').value,
          vivekm1concessionamt: this.pkgForm.get('vivekm1concessionamt').value,
          vivekh1actualamt: this.pkgForm.get('vivekh1actualamt').value,
          vivekh1concessionamt: this.pkgForm.get('vivekh1concessionamt').value,
          vaidyraj1actualamt: this.pkgForm.get('vaidyraj1actualamt').value,
          vaidyraj1concessionamt: this.pkgForm.get('vaidyraj1concessionamt')
            .value,
          granth11actualamt: this.pkgForm.get('granth11actualamt').value,
          granth11concessionamt: this.pkgForm.get('granth11concessionamt')
            .value,
          granth21actualamt: this.pkgForm.get('granth21actualamt').value,
          granth21concessionamt: this.pkgForm.get('granth21concessionamt')
            .value,
          donation1actualamt: this.pkgForm.get('donation1actualamt').value,
          donation1concessionamt: this.pkgForm.get('donation1concessionamt')
            .value,
          advt1actualamt: this.pkgForm.get('advt1actualamt').value,
          advt1concessionamt: this.pkgForm.get('advt1concessionamt').value,
          courier1actualamt: this.pkgForm.get('courier1actualamt').value,
          courier1concessionamt: this.pkgForm.get('courier1concessionamt')
            .value,
          other1actualamt: this.pkgForm.get('other1actualamt').value,
          other1concessionamt: this.pkgForm.get('other1concessionamt').value,
        };

        this.service.addPackage1(pkgdata1).subscribe((data) => {
          console.log('pkgdata1', data);
        });
      }

      if (this.show3year) {
        // console.log('inside 3 year');
        let pkgdata3 = {
          pkg_id: data['id'],
          vivekm3actualamt: this.pkgForm.get('vivekm3actualamt').value,
          vivekm3concessionamt: this.pkgForm.get('vivekm3concessionamt').value,
          vivekh3actualamt: this.pkgForm.get('vivekh3actualamt').value,
          vivekh3concessionamt: this.pkgForm.get('vivekh3concessionamt').value,
          vaidyraj3actualamt: this.pkgForm.get('vaidyraj3actualamt').value,
          vaidyraj3concessionamt: this.pkgForm.get('vaidyraj3concessionamt')
            .value,
          granth13actualamt: this.pkgForm.get('granth13actualamt').value,
          granth13concessionamt: this.pkgForm.get('granth13concessionamt')
            .value,
          granth23actualamt: this.pkgForm.get('granth23actualamt').value,
          granth23concessionamt: this.pkgForm.get('granth23concessionamt')
            .value,
          donation3actualamt: this.pkgForm.get('donation3actualamt').value,
          donation3concessionamt: this.pkgForm.get('donation3concessionamt')
            .value,
          advt3actualamt: this.pkgForm.get('advt3actualamt').value,
          advt3concessionamt: this.pkgForm.get('advt3concessionamt').value,
          courier3actualamt: this.pkgForm.get('courier3actualamt').value,
          courier3concessionamt: this.pkgForm.get('courier3concessionamt')
            .value,
          other3actualamt: this.pkgForm.get('other3actualamt').value,
          other3concessionamt: this.pkgForm.get('other3concessionamt').value,
        };

        this.service.addPackage3(pkgdata3).subscribe((data) => {
          console.log('pkgdata3', data);
        });
      }

      if (this.show5year) {
        console.log('inside 5 year');
        let pkgdata5 = {
          pkg_id: data['id'],
          vivekm5actualamt: this.pkgForm.get('vivekm5actualamt').value,
          vivekm5concessionamt: this.pkgForm.get('vivekm5concessionamt').value,
          vivekh5actualamt: this.pkgForm.get('vivekh5actualamt').value,
          vivekh5concessionamt: this.pkgForm.get('vivekh5concessionamt').value,
          vaidyraj5actualamt: this.pkgForm.get('vaidyraj5actualamt').value,
          vaidyraj5concessionamt: this.pkgForm.get('vaidyraj5concessionamt')
            .value,
          granth15actualamt: this.pkgForm.get('granth15actualamt').value,
          granth15concessionamt: this.pkgForm.get('granth15concessionamt')
            .value,
          granth25actualamt: this.pkgForm.get('granth25actualamt').value,
          granth25concessionamt: this.pkgForm.get('granth25concessionamt')
            .value,
          donation5actualamt: this.pkgForm.get('donation5actualamt').value,
          donation5concessionamt: this.pkgForm.get('donation5concessionamt')
            .value,
          advt5actualamt: this.pkgForm.get('advt5actualamt').value,
          advt5concessionamt: this.pkgForm.get('advt5concessionamt').value,
          courier5actualamt: this.pkgForm.get('courier5actualamt').value,
          courier5concessionamt: this.pkgForm.get('courier5concessionamt')
            .value,
          other5actualamt: this.pkgForm.get('other5actualamt').value,
          other5concessionamt: this.pkgForm.get('other5concessionamt').value,
        };

        this.service.addPackage5(pkgdata5).subscribe((data) => {
          console.log('pkgdata5', data);
        });
      }

      alert('Data added successfully...');
      this.pkgForm.reset();
      this.ngOnInit();
    });
  }

  showpkg: boolean = false;
  shownopkg: boolean = false;
  changeType(event) {
    // console.log(event.value);
    if (event.value == 'package') {
      this.showpkg = true;
    } else if (event.value == 'nopackage') {
      this.showpkg = false;
      this.shownopkg = true;
    }
  }

  selected_package_details(pkgid) {
    console.log(pkgid);
  }

  pkgAmount = 0;
  packageAmount(event) {
    // console.log(event.target.value);
    this.pkgAmount =
      event.target.value === '' ? 0 : parseInt(event.target.value);
    // console.log(this.pkgAmount);
  }

  user_id = 0;
  isUser: boolean = false;
  getpkgname(event, option) {
    if (event.isUserInput) {
      this.user_id = option['pkgid'];
      let data = this.packageData.filter((a) => a['pkg_id'] == this.user_id);
      if (Object.keys(data).length > 0) {
        this.isUser = true;
        // this.show1year = true;
        // this.show3year = true;
        // this.show5year = true;

        // console.log(data);
        // this.pkgForm.get('pkgname').setValue(data['pkg_name']);
        this.pkgForm.get('start').setValue(data[0]['pkg_start_date']);
        this.pkgForm.get('end').setValue(data[0]['pkg_end_date']);
        this.pkgForm.get('pkgamt').setValue(data[0]['pkg_amt']);
        this.pkgForm
          .get('periodicity')
          .setValue(data[0]['periodicity'].split(','));
        // console.log('db',data[0]['periodicity']);
        let check = data[0]['periodicity'].split(',');
        if (check.find((a) => a == '1 year') == '1 year') {
          this.show1year = true;
        } else {
          this.show1year = false;
        }

        if (check.find((a) => a == '3 year') == '3 year') {
          this.show3year = true;
        } else {
          this.show3year = false;
        }

        if (check.find((a) => a == '5 year') == '5 year') {
          this.show5year = true;
        } else {
          this.show5year = false;
        }
        if (this.show1year) {
          this.pkgForm
            .get('vivekm1actualamt')
            .setValue(data[0]['vivek_m_1_actual_amt']);
          this.pkgForm
            .get('vivekm1concessionamt')
            .setValue(data[0]['vivek_m_1_concession_amt']);
          this.pkgForm
            .get('vivekh1actualamt')
            .setValue(data[0]['vivek_h_1_actual_amt']);
          this.pkgForm
            .get('vivekh1concessionamt')
            .setValue(data[0]['vivek_h_1_concession_amt']);
          this.pkgForm
            .get('vaidyraj1actualamt')
            .setValue(data[0]['vaidyraj_1_actual_amt']);
          this.pkgForm
            .get('vaidyraj1concessionamt')
            .setValue(data[0]['vaidyraj_1_concession_amt']);
          this.pkgForm
            .get('granth11actualamt')
            .setValue(data[0]['granth1_1_actual_amt']);
          this.pkgForm
            .get('granth11concessionamt')
            .setValue(data[0]['granth1_1_concession_amt']);
          this.pkgForm
            .get('granth21actualamt')
            .setValue(data[0]['granth2_1_actual_amt']);
          this.pkgForm
            .get('granth21concessionamt')
            .setValue(data[0]['granth2_1_concession_amt']);
          this.pkgForm
            .get('donation1actualamt')
            .setValue(data[0]['donation_1_actual_amt']);
          this.pkgForm
            .get('donation1concessionamt')
            .setValue(data[0]['donation_1_concession_amt']);
          this.pkgForm
            .get('advt1actualamt')
            .setValue(data[0]['advt_1_actual_amt']);
          this.pkgForm
            .get('advt1concessionamt')
            .setValue(data[0]['advt_1_concession_amt']);
          this.pkgForm
            .get('courier1actualamt')
            .setValue(data[0]['courier_1_actual_amt']);
          this.pkgForm
            .get('courier1concessionamt')
            .setValue(data[0]['courier_1_concession_amt']);
          this.pkgForm
            .get('other1actualamt')
            .setValue(data[0]['other_1_actual_amt']);
          this.pkgForm
            .get('other1concessionamt')
            .setValue(data[0]['other_1_concession_amt']);

          this.actualamt1total =
            parseInt(data[0]['other_1_actual_amt']) +
            parseInt(data[0]['courier_1_actual_amt']) +
            parseInt(data[0]['advt_1_actual_amt']) +
            parseInt(data[0]['donation_1_actual_amt']) +
            parseInt(data[0]['granth2_1_actual_amt']) +
            parseInt(data[0]['granth1_1_actual_amt']) +
            parseInt(data[0]['vaidyraj_1_actual_amt']) +
            parseInt(data[0]['vivek_h_1_actual_amt']) +
            parseInt(data[0]['vivek_m_1_actual_amt']);

          this.concessionamt1total =
            parseInt(data[0]['other_1_concession_amt']) +
            parseInt(data[0]['courier_1_concession_amt']) +
            parseInt(data[0]['advt_1_concession_amt']) +
            parseInt(data[0]['donation_1_concession_amt']) +
            parseInt(data[0]['granth2_1_concession_amt']) +
            parseInt(data[0]['granth1_1_concession_amt']) +
            parseInt(data[0]['vaidyraj_1_concession_amt']) +
            parseInt(data[0]['vivek_h_1_concession_amt']) +
            parseInt(data[0]['vivek_m_1_concession_amt']);
        }
        if (this.show3year) {
          this.pkgForm
            .get('vivekm3actualamt')
            .setValue(data[0]['vivek_m_3_actual_amt']);
          this.pkgForm
            .get('vivekm3concessionamt')
            .setValue(data[0]['vivek_m_3_concession_amt']);
          this.pkgForm
            .get('vivekh3actualamt')
            .setValue(data[0]['vivek_h_3_actual_amt']);
          this.pkgForm
            .get('vivekh3concessionamt')
            .setValue(data[0]['vivek_h_3_concession_amt']);
          this.pkgForm
            .get('vaidyraj3actualamt')
            .setValue(data[0]['vaidyraj_3_actual_amt']);
          this.pkgForm
            .get('vaidyraj3concessionamt')
            .setValue(data[0]['vaidyraj_3_concession_amt']);
          this.pkgForm
            .get('granth13actualamt')
            .setValue(data[0]['granth1_3_actual_amt']);
          this.pkgForm
            .get('granth13concessionamt')
            .setValue(data[0]['granth1_3_concession_amt']);
          this.pkgForm
            .get('granth23actualamt')
            .setValue(data[0]['granth2_3_actual_amt']);
          this.pkgForm
            .get('granth23concessionamt')
            .setValue(data[0]['granth2_3_concession_amt']);
          this.pkgForm
            .get('donation3actualamt')
            .setValue(data[0]['donation_3_actual_amt']);
          this.pkgForm
            .get('donation3concessionamt')
            .setValue(data[0]['donation_3_concession_amt']);
          this.pkgForm
            .get('advt3actualamt')
            .setValue(data[0]['advt_3_actual_amt']);
          this.pkgForm
            .get('advt3concessionamt')
            .setValue(data[0]['advt_3_concession_amt']);
          this.pkgForm
            .get('courier3actualamt')
            .setValue(data[0]['courier_3_actual_amt']);
          this.pkgForm
            .get('courier3concessionamt')
            .setValue(data[0]['courier_3_concession_amt']);
          this.pkgForm
            .get('other3actualamt')
            .setValue(data[0]['other_3_actual_amt']);
          this.pkgForm
            .get('other3concessionamt')
            .setValue(data[0]['other_3_concession_amt']);

          this.actualamt3total =
            parseInt(data[0]['other_3_actual_amt']) +
            parseInt(data[0]['courier_3_actual_amt']) +
            parseInt(data[0]['advt_3_actual_amt']) +
            parseInt(data[0]['donation_3_actual_amt']) +
            parseInt(data[0]['granth2_3_actual_amt']) +
            parseInt(data[0]['granth1_3_actual_amt']) +
            parseInt(data[0]['vaidyraj_3_actual_amt']) +
            parseInt(data[0]['vivek_h_3_actual_amt']) +
            parseInt(data[0]['vivek_m_3_actual_amt']);

          this.concessionamt3total =
            parseInt(data[0]['other_3_concession_amt']) +
            parseInt(data[0]['courier_3_concession_amt']) +
            parseInt(data[0]['advt_3_concession_amt']) +
            parseInt(data[0]['donation_3_concession_amt']) +
            parseInt(data[0]['granth2_3_concession_amt']) +
            parseInt(data[0]['granth1_3_concession_amt']) +
            parseInt(data[0]['vaidyraj_3_concession_amt']) +
            parseInt(data[0]['vivek_h_3_concession_amt']) +
            parseInt(data[0]['vivek_m_3_concession_amt']);
        }
        if (this.show5year) {
          this.pkgForm
            .get('vivekm5actualamt')
            .setValue(data[0]['vivek_m_5_actual_amt']);
          this.pkgForm
            .get('vivekm5concessionamt')
            .setValue(data[0]['vivek_m_5_concession_amt']);
          this.pkgForm
            .get('vivekh5actualamt')
            .setValue(data[0]['vivek_h_5_actual_amt']);
          this.pkgForm
            .get('vivekh5concessionamt')
            .setValue(data[0]['vivek_h_5_concession_amt']);
          this.pkgForm
            .get('vaidyraj5actualamt')
            .setValue(data[0]['vaidyraj_5_actual_amt']);
          this.pkgForm
            .get('vaidyraj5concessionamt')
            .setValue(data[0]['vaidyraj_5_concession_amt']);
          this.pkgForm
            .get('granth15actualamt')
            .setValue(data[0]['granth1_5_actual_amt']);
          this.pkgForm
            .get('granth15concessionamt')
            .setValue(data[0]['granth1_5_concession_amt']);
          this.pkgForm
            .get('granth25actualamt')
            .setValue(data[0]['granth2_5_actual_amt']);
          this.pkgForm
            .get('granth25concessionamt')
            .setValue(data[0]['granth2_5_concession_amt']);
          this.pkgForm
            .get('donation5actualamt')
            .setValue(data[0]['donation_5_actual_amt']);
          this.pkgForm
            .get('donation5concessionamt')
            .setValue(data[0]['donation_5_concession_amt']);
          this.pkgForm
            .get('advt5actualamt')
            .setValue(data[0]['advt_5_actual_amt']);
          this.pkgForm
            .get('advt5concessionamt')
            .setValue(data[0]['advt_5_concession_amt']);
          this.pkgForm
            .get('courier5actualamt')
            .setValue(data[0]['courier_5_actual_amt']);
          this.pkgForm
            .get('courier5concessionamt')
            .setValue(data[0]['courier_5_concession_amt']);
          this.pkgForm
            .get('other5actualamt')
            .setValue(data[0]['other_5_actual_amt']);
          this.pkgForm
            .get('other5concessionamt')
            .setValue(data[0]['other_5_concession_amt']);

          this.actualamt5total =
            parseInt(data[0]['other_5_actual_amt']) +
            parseInt(data[0]['courier_5_actual_amt']) +
            parseInt(data[0]['advt_5_actual_amt']) +
            parseInt(data[0]['donation_5_actual_amt']) +
            parseInt(data[0]['granth2_5_actual_amt']) +
            parseInt(data[0]['granth1_5_actual_amt']) +
            parseInt(data[0]['vaidyraj_5_actual_amt']) +
            parseInt(data[0]['vivek_h_5_actual_amt']) +
            parseInt(data[0]['vivek_m_5_actual_amt']);

          this.concessionamt5total =
            parseInt(data[0]['other_5_concession_amt']) +
            parseInt(data[0]['courier_5_concession_amt']) +
            parseInt(data[0]['advt_5_concession_amt']) +
            parseInt(data[0]['donation_5_concession_amt']) +
            parseInt(data[0]['granth2_5_concession_amt']) +
            parseInt(data[0]['granth1_5_concession_amt']) +
            parseInt(data[0]['vaidyraj_5_concession_amt']) +
            parseInt(data[0]['vivek_h_5_concession_amt']) +
            parseInt(data[0]['vivek_m_5_concession_amt']);
        }
      }
    }
  }

  updatePackage() {
    debugger


    this.selectedPackages.forEach(element => {
      if(element.pkg_name===this.creditvoucherForm.get('package').value){
        element.pkg_amt=this.creditvoucherForm.get('packageamt').value
        element.client_name=this.creditvoucherForm.get('packagename').value
      }
    });

    let data = {
      pkgid: this.user_id,
      pkgname: this.pkgForm.get('package').value,
      startdate: this.pkgStartDate,
      enddate: this.pkgEndDate,
      pkgamt: this.pkgForm.get('packageamt').value,
      periodicity: this.pkgForm.get('periodicity').value.toString(),
    };

    // console.log(data);
    this.service.updatePackage(data).subscribe((data) => {
      debugger
      if (this.show1year) {
        let pkg1year = {
          pkgid: this.user_id,
          vivekm1actualamt: this.pkgForm.get('vivekm1actualamt').value,
          vivekm1concessionamt: this.pkgForm.get('vivekm1concessionamt').value,
          vivekh1actualamt: this.pkgForm.get('vivekh1actualamt').value,
          vivekh1concessionamt: this.pkgForm.get('vivekh1concessionamt').value,
          vaidyraj1actualamt: this.pkgForm.get('vaidyraj1actualamt').value,
          vaidyraj1concessionamt: this.pkgForm.get('vaidyraj1concessionamt')
            .value,
          granth11actualamt: this.pkgForm.get('granth11actualamt').value,
          granth11concessionamt: this.pkgForm.get('granth11concessionamt')
            .value,
          granth21actualamt: this.pkgForm.get('granth21actualamt').value,
          granth21concessionamt: this.pkgForm.get('granth21concessionamt')
            .value,
          donation1actualamt: this.pkgForm.get('donation1actualamt').value,
          donation1concessionamt: this.pkgForm.get('donation1concessionamt')
            .value,
          advt1actualamt: this.pkgForm.get('advt1actualamt').value,
          advt1concessionamt: this.pkgForm.get('advt1concessionamt').value,
          courier1actualamt: this.pkgForm.get('courier1actualamt').value,
          courier1concessionamt: this.pkgForm.get('courier1concessionamt')
            .value,
          other1actualamt: this.pkgForm.get('other1actualamt').value,
          other1concessionamt: this.pkgForm.get('other1concessionamt').value,
        };

        this.service.updatePackage1(pkg1year).subscribe((data) => {});
      }

      if (this.show3year) {
        let pkg3year = {
          pkgid: this.user_id,
          vivekm3actualamt: this.pkgForm.get('vivekm3actualamt').value,
          vivekm3concessionamt: this.pkgForm.get('vivekm3concessionamt').value,
          vivekh3actualamt: this.pkgForm.get('vivekh3actualamt').value,
          vivekh3concessionamt: this.pkgForm.get('vivekh3concessionamt').value,
          vaidyraj3actualamt: this.pkgForm.get('vaidyraj3actualamt').value,
          vaidyraj3concessionamt: this.pkgForm.get('vaidyraj3concessionamt')
            .value,
          granth13actualamt: this.pkgForm.get('granth13actualamt').value,
          granth13concessionamt: this.pkgForm.get('granth13concessionamt')
            .value,
          granth23actualamt: this.pkgForm.get('granth23actualamt').value,
          granth23concessionamt: this.pkgForm.get('granth23concessionamt')
            .value,
          donation3actualamt: this.pkgForm.get('donation3actualamt').value,
          donation3concessionamt: this.pkgForm.get('donation3concessionamt')
            .value,
          advt3actualamt: this.pkgForm.get('advt3actualamt').value,
          advt3concessionamt: this.pkgForm.get('advt3concessionamt').value,
          courier3actualamt: this.pkgForm.get('courier3actualamt').value,
          courier3concessionamt: this.pkgForm.get('courier3concessionamt')
            .value,
          other3actualamt: this.pkgForm.get('other3actualamt').value,
          other3concessionamt: this.pkgForm.get('other3concessionamt').value,
        };

        this.service.updatePackage3(pkg3year).subscribe((data) => {});
      }

      if (this.show5year) {
        let pkg5year = {
          pkgid: this.user_id,
          vivekm5actualamt: this.pkgForm.get('vivekm5actualamt').value,
          vivekm5concessionamt: this.pkgForm.get('vivekm5concessionamt').value,
          vivekh5actualamt: this.pkgForm.get('vivekh5actualamt').value,
          vivekh5concessionamt: this.pkgForm.get('vivekh5concessionamt').value,
          vaidyraj5actualamt: this.pkgForm.get('vaidyraj5actualamt').value,
          vaidyraj5concessionamt: this.pkgForm.get('vaidyraj5concessionamt')
            .value,
          granth15actualamt: this.pkgForm.get('granth15actualamt').value,
          granth15concessionamt: this.pkgForm.get('granth15concessionamt')
            .value,
          granth25actualamt: this.pkgForm.get('granth25actualamt').value,
          granth25concessionamt: this.pkgForm.get('granth25concessionamt')
            .value,
          donation5actualamt: this.pkgForm.get('donation5actualamt').value,
          donation5concessionamt: this.pkgForm.get('donation5concessionamt')
            .value,
          advt5actualamt: this.pkgForm.get('advt5actualamt').value,
          advt5concessionamt: this.pkgForm.get('advt5concessionamt').value,
          courier5actualamt: this.pkgForm.get('courier5actualamt').value,
          courier5concessionamt: this.pkgForm.get('courier5concessionamt')
            .value,
          other5actualamt: this.pkgForm.get('other5actualamt').value,
          other5concessionamt: this.pkgForm.get('other5concessionamt').value,
        };

        this.service.updatePackage5(pkg5year).subscribe((data) => {});
      }

      alert('Package updated successfully...');
      this.pkgForm.reset();
    });
  }

  vouchers_no;
  getVoucherNo(event) {
    // console.log('length',event.target.value.length);
    if (event.target.value.length > 5) {
      this.service.getCreditVoucherNo(event.target.value).subscribe((res) => {
        this.vouchers_no = res;
        // console.log(res);
      });
    }
  }

  d_vouchers_no;
  getDVVoucherNo(event) {
    // console.log('length',event.target.value.length);
    if (event.target.value.length > 5) {
      this.service.getDebitVoucherNo(event.target.value).subscribe((res) => {
        this.vouchers_no = res;
        // console.log(res);
      });
    }
  }

  selected_d_vouchers(event, option) {
    this.debitlistactive = true
    this.mainidforupdatedebit = option.id
    // this.selectbank = option.bank_cash_account
    // this.selectvocuherdate = option.d_voucher_date

    debugger;
    this.debitlist = true
    this.service.getDebitVoucherData(option['id']).subscribe((data) => {
      console.log(data); 
      this.selectbank = data[0]['bank_cash_account']
      this.selectvocuherdate = data[0]['d_voucher_date']
      // this.selectvocuherdate = option.d_voucher_date
      this.debitForm.get('rname').setValue(data[0]['rep_name']);
      this.debitForm.get('dvoucherdate').setValue(data[0]['d_voucher_date']);
      this.debitForm.get('dvnumber').setValue(data[0]['d_voucher_no']);
      this.debitForm.get('othername').setValue(data[0]['narration']);
      this.debitForm.get('exp_amt').setValue(data[0]['exp_amt']);
      this.debitForm.get('chequeno').setValue(data[0]['cheque_no']);
      this.debitForm.get('chequedate').setValue(data[0]['cheque_date']);
      this.debitForm.get('totalexp').setValue(data[0]['total_exp']);

    });

    this.service.getDebitVoucherlist(option['id']).subscribe((data:any) => {
      console.log(data);
      this.debitvoucherlist = data

      this.debitvoucherlist.forEach(element => {
        element.visible = true
      });
      // mainExpData getDebitSubExpData
      // this.debitForm.get('rname').setValue(data[0]['rep_name']);
      // this.debitForm.get('dvoucherdate').setValue(data[0]['d_voucher_date']);
      // this.debitForm.get('dvnumber').setValue(data[0]['d_voucher_no']);
      // this.debitForm.get('othername').setValue(data[0]['narration']);
      // this.debitForm.get('exp_amt').setValue(data[0]['exp_amt']);
      // this.debitForm.get('chequeno').setValue(data[0]['cheque_no']);
      // this.debitForm.get('chequedate').setValue(data[0]['cheque_date']);
    });
  }
  deleterow: any[] = [];
  grandtotal:any
  removeDebitlist(item, i) {
    item.visible = false;
    this.deleterow.push(item.main_exp_id);
    this.transformedResult = {
      debitvoucher: this.deleterow.map(id => ({ id: id }))
    };

    console.log(this.transformedResult);
    
console.log(this.deleterow, "delete row")
    let id = item.main_exp_id;
    let mainamt = item.main_exp_amt;
    let subamt = item.sub_exp_amt;
    let total: any
    // let grandtotal : any
    debugger;
    if (subamt == null) {
      subamt = 0;
    }
    if (subamt == 0) {
      total = this.debitForm.value.totalexp
      this.grandtotal = parseFloat(total) - parseFloat(mainamt)
    }
    else {
      total = this.debitForm.value.totalexp
      this.grandtotal = parseFloat(total) - parseFloat(subamt)
    }
    console.log('Grand Total:', this.grandtotal);

    this.debitForm.get('totalexp').setValue(this.grandtotal);    
  }
  update_debitvoucher(){
    this.service.deleteDebitVoucherlist(this.transformedResult).subscribe((data: any) => {
      // console.log(data);
      // this.debitvoucherlist = data
    });  

    

    const debitvoucher:any = {
      "id":this.mainidforupdatedebit,
      "rname":this.debitForm.value.rname,
      "damount":this.debitForm.value.exp_amt,
      "total_exp":this.debitForm.value.totalexp,
      "cheque_no":this.debitForm.value.chequeno,
      "cheque_date":this.debitForm.value.chequedate,
      "narration":this.debitForm.value.othername,
      "bcaccount":this.selectbank,
      "vnumber":this.debitForm.value.dvnumber,
      "voucherdate":this.selectvocuherdate, 
    }
     this.service.updateDebitVoucherlist(debitvoucher).subscribe((data: any) => {
      console.log(data);
    });
    console.log(debitvoucher)

    let data = {
      bcaccount: this.debitForm.get('bcaccount').value,
      dvnumber: this.debitForm.get('dvnumber').value,
      dvoucherdate: this.debitForm.get('dvoucherdate').value,
      rname: this.debitForm.get('rname').value,
      othername: this.debitForm.get('othername').value,
      exp_amt: this.debitForm.get('exp_amt').value,
      chequeno: this.debitForm.get('chequeno').value,
      chequedate: this.datePipe.transform(this.debitForm.get('chequedate').value, 'yyyy-MM-dd'),
      // other: this.debitForm.get('other').value,
      // subscommission: this.debitForm.get('subscommission').value,
      // issuecommission: this.debitForm.get('issuecommission').value,
      // advtcommission: this.debitForm.get('advtcommission').value,
      // bookcommission: this.debitForm.get('bookcommission').value,
      totalexp: this.debitForm.get('totalexp').value,
    };

    // console.log({ data });
    if (
      parseInt(this.debitForm.get('exp_amt').value) ===
      parseInt(this.debitForm.get('totalexp').value)
    ) {
      // this.service.addDebitVoucher(data).subscribe((res) => {
        // console.log(res);
        this.selecteddebitMainExpenses.map((exp) => {
          let data = {
            voucher_id: this.mainidforupdatedebit,
            mainexpid: exp.mainexpid,
            expamt: exp.expamt,
          };

          console.log(data);
          let subData = this.selecteddebitsubExpenses.filter(
            (a) => a['mainexpid'] === data['mainexpid']
          );
          this.service.addDebitMainExpense(data).subscribe((result) => {
            console.log(result);
            console.log(subData);
            // if (Object.keys(subData).length > 0) {
            subData.map((a) => {
              let subexpdata = {
                mainid: result['id'],
                subexpid: a['subexpid'],
                expamt: a['expamt'],
              };
              this.service
                .addDebitSubExpense(subexpdata)
                .subscribe((data) => {
                  console.log(data);
                });
            });
            // }
          });
        });
        alert('Debit Voucher Added Successfully...');
        this.debitForm.reset();
        this.selecteddebitMainExpenses = [];
        this.selecteddebitsubExpenses = [];
        // this.ngOnInit();
      // });
    } else {
      alert('Please check the amount.');
    }
  }

  delete_debitvoucher1(){
    this.mainidforupdatedebit;
    debugger;
    this.service.deleteDebitVoucher( this.mainidforupdatedebit).subscribe((data: any) => {
      // console.log(data);
      // this.debitvoucherlist = data
     
    });
    alert('Debit vocuher deletet sucessfully...');
    this.ngOnInit()
  }



  crdt_voucher_id = 0;
  sub_id = 0;
  book_id = 0;
  other_deposits_id = 0;
  issue_id = 0;
  donation_id = 0;
  deposits_id = 0;
  advt_id = 0;
  showCreditupdate = false;
  mainIdObject = [{ s_id: 0, a_id: 0, b_id: 0, i_id: 0 }];
  selected_vouchers(event, option) {
    // console.log(event.value);
    // console.log(option['id']);
    this.service.getCrditVoucherData(option['id']).subscribe((data) => {
      console.log(data);
      this.crdt_voucher_id = data[0]['id'];
      this.showCreditupdate = true;
      this.creditvoucherForm.get('entrydate').setValue(data[0]['entry_date']);
      this.creditvoucherForm
        .get('bcaccount')
        .setValue(data[0]['bank_cash_account']);
      if (data[0]['bank_cash_account'] === 'CASH') {
        this.cashtype = false;
      } else {
        this.cashtype = true;
      }
      this.creditvoucherForm.get('vnumber').setValue(data[0]['voucher_no']);
      this.creditvoucherForm
        .get('voucherdate')
        .setValue(data[0]['voucher_date']);
      this.creditvoucherForm.get('rname').setValue(data[0]['rep_name']);
      this.creditvoucherForm
        .get('othername')
        .setValue(data[0]['narration_name']);
      this.creditvoucherForm.get('damount').setValue(data[0]['deposit_amt']);
      this.creditvoucherForm
        .get('pymtoptions')
        .setValue(data[0]['pymt_options']);
      if (this.cashtype) {
        this.creditvoucherForm.get('cnumber').setValue(data[0]['cheque_no']);
        this.creditvoucherForm
          .get('chequedate')
          .setValue(
            data[0]['cheque_date'] === '0000-00-00'
              ? ''
              : data[0]['cheque_date']
          );
        this.creditvoucherForm.get('bank').setValue(data[0]['bank']);
        this.creditvoucherForm.get('branch').setValue(data[0]['branch_name']);
      }
      this.creditvoucherForm
        .get('types')
        .setValue(
          parseInt(data[0]['packagecount']) > 0 ? 'package' : 'nopackage'
        );
      if (parseInt(data[0]['packagecount']) > 0) {
        this.showpkg = true;
        // console.log(data[0]['id']);
        this.service
          .getCrditVoucherPackageData(parseInt(data[0]['id']))
          .subscribe((res) => {
            console.log(res);
            //assign these data to selectedPackages array
            let data = res;
            Object(data).map((a) => {
              this.selectedPackages.push({
                id: a['id'],
                pkg_id: a['pkg_id'],
                pkg_name: a['pkg_name'],
                pkg_amt: a['pkg_price'],
                client_name: a['client_name'],
                isupdated: 'N',
              });
            });
            console.log(this.selectedPackages);
          });
      } else {
        this.showpkg = false;
      }
      this.creditvoucherForm
        .get('subscription')
        .setValue(
          data[0]['subscription'] == null ? 0 : data[0]['subscription']
        );
      // console.log(parseInt(data[0]['subscription']));

      if (
        data[0]['subscription'] != null &&
        parseInt(data[0]['subscription']) > 0
      ) {
        // mainIdObject = [{ s_id: 0, a_id: 0, b_id: 0, i_id: 0 }];
        this.mainIdObject['s_id'] = parseInt(data[0]['s_id']);
        // console.log('sub_names', data[0]['sub_names'].split(','));
        this.sub_id = data[0]['sub_id'];
        this.names =
          data[0]['sub_names'] === null ? [] : data[0]['sub_names'].split(',');
      }
      this.creditvoucherForm
        .get('isale')
        .setValue(data[0]['issuesale'] == null ? 0 : data[0]['issuesale']);
      if (data[0]['issuesale'] != null && parseInt(data[0]['issuesale']) > 0) {
        // console.log('issuesale_names', data[0]['issuesale_names'].split(','));
        this.mainIdObject['i_id'] = parseInt(data[0]['i_id']);
        this.issue_id = data[0]['issue_id'];
        this.names1 =
          data[0]['issuesale_names'] === null
            ? []
            : data[0]['issuesale_names'].split(',');
      }
      this.creditvoucherForm
        .get('adverties')
        .setValue(
          data[0]['advertiesment'] == null ? 0 : data[0]['advertiesment']
        );
      // console.log(parseInt(data[0]['advertiesment']));
      if (
        data[0]['advertiesment'] != null &&
        parseInt(data[0]['advertiesment']) > 0
      ) {
        this.mainIdObject['a_id'] = data[0]['a_id'];
        // console.log('advt_names', data[0]['advt_names'].split(','));
        this.advt_id = parseInt(data[0]['advt_id']);
        this.names2 =
          data[0]['advt_names'] === null
            ? []
            : data[0]['advt_names'].split(',');
      }
      this.creditvoucherForm
        .get('deposits')
        .setValue(data[0]['deposits'] == null ? 0 : data[0]['deposits']);
      if (data[0]['deposits'] != null && parseInt(data[0]['deposits']) > 0) {
        // console.log('deposits_names', data[0]['deposits_names'].split(','));
        this.deposits_id = data[0]['deposits_id'];
        this.names3 =
          data[0]['deposits_names'] === null
            ? []
            : data[0]['deposits_names'].split(',');
      }
      this.creditvoucherForm
        .get('granthbook')
        .setValue(data[0]['book'] == null ? 0 : data[0]['book']);
      // if (data[0]['book'] != null && parseInt(data[0]['book']) > 0) {
      //   // console.log('book_names', data[0]['book_names'].split(','));
      //   this.mainIdObject['b_id'] = parseInt(data[0]['b_id']);
      //   this.book_id = data[0]['book_id'];
      //   this.names4 =
      //     data[0]['book_names'] === null
      //       ? []
      //       : data[0]['book_names'].split(',');
      // }
      this.creditvoucherForm
  .get('granthbookname')
  .setValue(data[0]?.book_seller_name ?? '');
      this.creditvoucherForm
        .get('donation')
        .setValue(data[0]['donation'] == null ? 0 : data[0]['donation']);
      if (data[0]['donation'] != null && parseInt(data[0]['donation']) > 0) {
        // console.log('donation_names', data[0]['donation_names'].split(','));
        this.donation_id = data[0]['donation_id'];
        this.names5 =
          data[0]['donation_names'] === null
            ? []
            : data[0]['donation_names'].split(',');
      }
      this.creditvoucherForm
        .get('odeposit')
        .setValue(
          data[0]['other_deposit'] == null ? 0 : data[0]['other_deposit']
        );
      if (
        data[0]['other_deposit'] != null &&
        parseInt(data[0]['other_deposit']) > 0
      ) {
        // console.log('donation_names', data[0]['donation_names'].split(','));
        this.other_deposits_id = data[0]['other_deposits_id'];
        this.names6 =
          data[0]['other_deposits_name'] === null
            ? []
            : data[0]['other_deposits_name'].split(',');
      }
      this.creditvoucherForm
        .get('packagecount')
        .setValue(data[0]['packagecount']);
      this.creditvoucherForm
        .get('packageamount')
        .setValue(data[0]['packageamount']);
      this.creditvoucherForm
        .get('otheramount')
        .setValue(data[0]['otheramount']);
      this.creditvoucherForm.get('total').setValue(data[0]['total']);
      this.total_amt = parseFloat(data[0]['total']);
      this.creditvoucherForm
        .get('expenditure')
        .setValue(data[0]['expenditure']);
      if (parseFloat(data[0]['expenditure']) > 0) {
        //get the credit expense data
        this.service
          .getCreditMainExpenseDataById(parseInt(data[0]['id']))
          .subscribe((res) => {
            console.log(res);
            if (Object.keys(res).length > 0) {
              for (let index = 0; index < Object.keys(res).length; index++) {
                // this.selectedMainExpenses.push({
                //   id: res[index]['id'],
                //   exp_id: res[index]['exp_id'],
                //   expamt: res[index]['exp_amt'],
                //   mainexpname: res[index]['exp_name'],
                //   isUpdated: 'N',
                // });
                this.service
                .getCreditSubExpenseDataById(parseInt(res[index]['id']))
                .subscribe((result) => {
                  console.log(result);
                  if (Object.keys(result).length > 0) {
                    this.selectedMainExpenses.push({
                      id: res[index]['id'],
                      exp_id: res[index]['exp_id'],
                      expamt: res[index]['exp_amt'],
                      mainexpname: res[index]['exp_name'],
                      isUpdated: 'N',
                      isSubExp: 'Y',
                    });
                    for (
                      let index1 = 0;
                      index1 < Object.keys(result).length;
                      index1++
                    ) {
                      this.selectedsubExpenses.push({
                        id: result[index1]['id'],
                        sub_exp_id: result[index1]['sub_exp_id'],
                        expamt: result[index1]['sub_exp_amt'],
                        exp_id: res[index]['exp_id'],
                        mainexpname: res[index]['exp_name'],
                        subexpname: result[index1]['sub_exp_name'],
                        isUpdated: 'N',
                      });
                    }
                  } else {
                    this.selectedMainExpenses.push({
                      id: res[index]['id'],
                      exp_id: res[index]['exp_id'],
                      expamt: res[index]['exp_amt'],
                      mainexpname: res[index]['exp_name'],
                      isUpdated: 'N',
                      isSubExp: 'N',
                    });
                  }
                });
              }
            }
          });
      }
      if (parseFloat(data[0]['deposit_amt']) > 0) {
        //get the credit expense data
        this.service
          .get_other_deposit_main_expenses_by_cv_id(parseInt(data[0]['id']))
          .subscribe((res) => {
            console.log(res);
            if (Object.keys(res).length > 0) {
              this.selectedMainExpenses1=[]
              for (let index = 0; index < Object.keys(res).length; index++) {
                // this.selectedMainExpenses.push({
                //   id: res[index]['id'],
                //   exp_id: res[index]['exp_id'],
                //   expamt: res[index]['exp_amt'],
                //   mainexpname: res[index]['exp_name'],
                //   isUpdated: 'N',
                // });
                this.service
                .get_other_deposit_sub_expenses_by_exp_id(parseInt(res[index]['id']))
                .subscribe((result) => {
                  console.log(result);
               
                  if (Object.keys(result).length > 0) {
                    this.selectedMainExpenses1.push({
                      id: res[index]['id'],
                      exp_id: res[index]['exp_id'],
                      expamt: res[index]['exp_amt'],
                      mainexpname: res[index]['exp_name'],
                      isUpdated: 'N',
                      isSubExp: 'Y',
                    });
                    for (
                      let index1 = 0;
                      index1 < Object.keys(result).length;
                      index1++
                    ) {
                      this.selectedsubExpenses1.push({
                        id: result[index1]['id'],
                        sub_exp_id: result[index1]['sub_exp_id'],
                        expamt: result[index1]['sub_exp_amt'],
                        exp_id: res[index]['exp_id'],
                        mainexpname: res[index]['exp_name'],
                        subexpname: result[index1]['sub_exp_name'],
                        isUpdated: 'N',
                        isSubExo: 'N',
                      });
                    }
                  }
                  else {
                    this.selectedMainExpenses1.push({
                      id: res[index]['id'],
                      exp_id: res[index]['exp_id'],
                      expamt: res[index]['exp_amt'],
                      mainexpname: res[index]['exp_name'],
                      isUpdated: 'N',
                      isSubExp: 'N',
                    });
                  }
                });
              }
            }
          });
      }
      this.creditvoucherForm.get('totalexp').setValue(data[0]['totalexp']);
      this.creditvoucherForm.get('ndeposit').setValue(data[0]['net_deposit']);
    });
  }

  updatepkgId = 0;
  isupdatepkgamt: boolean = false;
  
  isUpdate: boolean = false;
  updatepkg(item) {
    this.updatepkgId = 0;
    this.isupdatepkgamt = false;
    this.isUpdate = true;
    this.creditvoucherForm.get('package').setValue(item['pkg_name']);
    this.creditvoucherForm.get('packagename').setValue(item['client_name']);
    this.creditvoucherForm.get('packageamt').setValue(item['pkg_amt']);

    this.updatepkgId = item['id'];
    console.log(item);
    this.packageData.map((pkg) => {
      if (
        item['pkg_id'] === pkg['pkg_id'] &&
        item['package'] != pkg['package']
       
      ) {
        this.isupdatepkgamt = true;
        console.log(pkg);
        this.creditvoucherForm.get('packageamt').setValue(item['pkg_amt']);
      }
    });
  }

  updateSelectedPkg() {
    for (
      let index = 0;
      index < Object.keys(this.selectedPackages).length;
      index++
    ) {
      if (this.updatepkgId === this.selectedPackages[index]['id']) {
        if (this.selectedPackageId != 0) {
          for (
            let index = 0;
            index < Object.keys(this.packageData).length;
            index++
          ) {
            if (
              this.packageData[index]['pkg_id'] == this.selectedPackageId &&
              this.packageData[index]['pkg_name'] ==
                this.creditvoucherForm.get('package').value
            ) {
              this.selectedPackages[index]['pkg_id'] =
                this.packageData[index]['pkg_id'];
              this.selectedPackages[index]['client_name'] =
                this.creditvoucherForm.get('packagename').value;
              this.selectedPackages[index]['pkg_name'] =
                this.packageData[index]['pkg_name'];
              this.selectedPackages[index]['isupdated'] = 'Y';
              if (this.pkgAmount !== 0) {
                this.selectedPackages[index]['pkg_amt'] = this.pkgAmount;
              } else {
                this.selectedPackages[index]['pkg_amt'] =
                  this.packageData[index]['pkg_amt'];
              }
            }
          }
        } else {
          this.selectedPackages[index]['client_name'] =
            this.creditvoucherForm.get('packagename').value;
          this.selectedPackages[index]['pkg_name'] =
            this.creditvoucherForm.get('package').value;
          this.selectedPackages[index]['isupdated'] = 'Y';
          if (this.isupdatepkgamt) {
            this.selectedPackages[index]['pkg_amt'] =
              this.creditvoucherForm.get('packageamt').value;
          }
        }
      }
    }
    this.creditvoucherForm.get('package').reset();
    this.creditvoucherForm.get('packagename').reset();
    this.creditvoucherForm.get('packageamt').reset();
    // this.selectedPackages = this.uniqueid(this.selectedPackages, (a) => a.pkg_id);
    // console.log(this.selectedPackages);
    let pkg_amt_total = 0;
    for (let i = 0; i < Object.keys(this.selectedPackages).length; i++) {
      pkg_amt_total =
        pkg_amt_total + parseInt(this.selectedPackages[i]['pkg_amt']);
    }
    this.creditvoucherForm.get('packageamount').setValue(pkg_amt_total);
    this.creditvoucherForm
      .get('packagecount')
      .setValue(Object.keys(this.selectedPackages).length);
    this.totalAmt();
  }

  update_creditvoucher() {
    
    this.forupdate =  this.creditvoucherForm.get('vnumber').value

    this.service.getCreditVoucherNo(this.forupdate).subscribe((res) => {
      this.vouchers_status = res;
      // if(this.vouchers_status == 'N' ){
        if (confirm('Do you want update credit voucher')) {
          let creditvoucher = {
            id: this.crdt_voucher_id,
            bcaccount: this.creditvoucherForm.get('bcaccount').value,
            vnumber: this.creditvoucherForm.get('vnumber').value,
            voucherdate: this.creditvoucherForm.get('voucherdate').value,
            rname: this.creditvoucherForm.get('rname').value,
            othername: this.creditvoucherForm.get('othername').value,
            damount: this.creditvoucherForm.get('damount').value,
            pymtoptions: this.creditvoucherForm.get('pymtoptions').value,
            cnumber: this.creditvoucherForm.get('cnumber').value,
            bank: this.creditvoucherForm.get('bank').value,
            branch: this.creditvoucherForm.get('branch').value,
            chequedate: this.creditvoucherForm.get('chequedate').value,
            entrydate: this.creditvoucherForm.get('entrydate').value,
            deposits: this.creditvoucherForm.get('deposits').value,
            donation: this.creditvoucherForm.get('donation').value,
            odeposit: this.creditvoucherForm.get('odeposit').value,
            packagecount: this.creditvoucherForm.get('packagecount').value,
            packageamount: this.creditvoucherForm.get('packageamount').value,
            otheramount: this.creditvoucherForm.get('otheramount').value,
            total: this.creditvoucherForm.get('total').value,
            expenditure: this.creditvoucherForm.get('expenditure').value,
            totalexp: this.creditvoucherForm.get('totalexp').value,
            ndeposit: this.creditvoucherForm.get('ndeposit').value,
          };
          console.log(creditvoucher);
    
          if (
            parseInt(this.creditvoucherForm.get('ndeposit').value) ===
            parseInt(this.creditvoucherForm.get('damount').value)
          ) {
            this.service.updatecreditVoucher(creditvoucher).subscribe((res) => {
              console.log(res);
              for (
                let index = 0;
                index < Object.keys(this.selectedPackages).length;
                index++
              ) {
                // console.log(this.selectedPackages[index]);
                if (this.selectedPackages[index]['isupdated'] === 'Y') {
                  let pkgdata = {
                    id: this.selectedPackages[index]['id'],
                    pkg_id: this.selectedPackages[index]['pkg_id'],
                    pkg_name: this.selectedPackages[index]['pkg_name'],
                    pkg_amt: this.selectedPackages[index]['pkg_amt'],
                    client_name: this.selectedPackages[index]['client_name'],
                  };
    
                  this.service.updateCrdtPackage(pkgdata).subscribe((res) => {
                    // console.log(res);
                    for (let i = 0; i < Object.keys(this.packageData).length; i++) {
                      if (
                        this.packageData[i]['pkg_id'] ==
                        this.selectedPackages[index]['pkg_id']
                      ) {
                        let periodicty =
                          this.packageData[i]['periodicity'].split(',');
                        // console.log(periodicty);
                        for (let j = 0; j < Object.keys(periodicty).length; j++) {
                          if (
                            this.packageData[i]['pkg_id'] ==
                              this.selectedPackages[index]['pkg_id'] &&
                            this.packageData[i]['pkg_name'] ==
                              this.selectedPackages[index]['pkg_name'] &&
                            periodicty[j] == '1 year'
                          ) {
                            // console.log('1 year', this.packageData[i]);
                            let pkgDetails = {
                              crdt_pkg_details_id:
                                this.selectedPackages[index]['id'],
                              year: '1 year',
                              sub_actual_amt:
                                this.packageData[i]['vivek_m_1_actual_amt'],
                              sub_concession_amt:
                                this.packageData[i]['vivek_m_1_concession_amt'],
                              vivek_h_actual_amt:
                                this.packageData[i]['vivek_h_1_actual_amt'],
                              vivek_h_concession_amt:
                                this.packageData[i]['vivek_h_1_concession_amt'],
                              vaidyraj_actual_amt:
                                this.packageData[i]['vaidyraj_1_actual_amt'],
                              vaidyraj_concession_amt:
                                this.packageData[i]['vaidyraj_1_concession_amt'],
                              granth_actual_amt:
                                this.packageData[i]['granth1_1_actual_amt'],
                              granth_remaining_amt:
                                this.selectedPackages[index]['pkg_amt'],
                              granth_concession_amt:
                                this.packageData[i]['granth1_1_concession_amt'],
                              book_actual_amt:
                                this.packageData[i]['granth2_1_actual_amt'],
                              book_concession_amt:
                                this.packageData[i]['granth2_1_concession_amt'],
                              donation_actual_amt:
                                this.packageData[i]['donation_1_actual_amt'],
                              donation_concession_amt:
                                this.packageData[i]['donation_1_concession_amt'],
                              courier_actual_amt:
                                this.packageData[i]['courier_1_actual_amt'],
                              courier_concession_amt:
                                this.packageData[i]['courier_1_concession_amt'],
                              advt_actual_amt:
                                this.packageData[i]['advt_1_actual_amt'],
                              advt_concession_amt:
                                this.packageData[i]['advt_1_concession_amt'],
                              other_actual_amt:
                                this.packageData[i]['other_1_actual_amt'],
                              other_concession_amt:
                                this.packageData[i]['other_1_concession_amt'],
                            };
                            // console.log(pkgDetails);
    
                            this.service
                              .updateCrdtPackageDetails(pkgDetails)
                              .subscribe((a) => {});
                          } else if (
                            this.packageData[i]['pkg_id'] ==
                              this.selectedPackages[index]['pkg_id'] &&
                            this.packageData[i]['pkg_name'] ==
                              this.selectedPackages[index]['pkg_name'] &&
                            periodicty[j] == '3 year'
                          ) {
                            // console.log('3 year');
                            let pkgDetails = {
                              crdt_pkg_details_id:
                                this.selectedPackages[index]['id'],
                              year: '3 year',
                              sub_actual_amt:
                                this.packageData[i]['vivek_m_3_actual_amt'],
                              sub_concession_amt:
                                this.packageData[i]['vivek_m_3_concession_amt'],
                              vivek_h_actual_amt:
                                this.packageData[i]['vivek_h_3_actual_amt'],
                              vivek_h_concession_amt:
                                this.packageData[i]['vivek_h_3_concession_amt'],
                              vaidyraj_actual_amt:
                                this.packageData[i]['vaidyraj_3_actual_amt'],
                              vaidyraj_concession_amt:
                                this.packageData[i]['vaidyraj_3_concession_amt'],
                              granth_actual_amt:
                                this.packageData[i]['granth1_3_actual_amt'],
                              granth_remaining_amt:
                                this.selectedPackages[index]['pkg_amt'],
                              granth_concession_amt:
                                this.packageData[i]['granth1_3_concession_amt'],
                              book_actual_amt:
                                this.packageData[i]['granth2_3_actual_amt'],
                              book_concession_amt:
                                this.packageData[i]['granth2_3_concession_amt'],
                              donation_actual_amt:
                                this.packageData[i]['donation_3_actual_amt'],
                              donation_concession_amt:
                                this.packageData[i]['donation_3_concession_amt'],
                              courier_actual_amt:
                                this.packageData[i]['courier_3_actual_amt'],
                              courier_concession_amt:
                                this.packageData[i]['courier_3_concession_amt'],
                              advt_actual_amt:
                                this.packageData[i]['advt_3_actual_amt'],
                              advt_concession_amt:
                                this.packageData[i]['advt_3_concession_amt'],
                              other_actual_amt:
                                this.packageData[i]['other_3_actual_amt'],
                              other_concession_amt:
                                this.packageData[i]['other_3_concession_amt'],
                            };
                            // console.log(pkgDetails);
    
                            this.service
                              .updateCrdtPackageDetails(pkgDetails)
                              .subscribe((a) => {});
                          } else if (
                            this.packageData[i]['pkg_id'] ==
                              this.selectedPackages[index]['pkg_id'] &&
                            this.packageData[i]['pkg_name'] ==
                              this.selectedPackages[index]['pkg_name'] &&
                            periodicty[j] == '5 year'
                          ) {
                            // console.log('5 year');
                            let pkgDetails = {
                              crdt_pkg_details_id:
                                this.selectedPackages[index]['id'],
                              year: '5 year',
                              sub_actual_amt:
                                this.packageData[i]['vivek_m_5_actual_amt'],
                              sub_concession_amt:
                                this.packageData[i]['vivek_m_5_concession_amt'],
                              vivek_h_actual_amt:
                                this.packageData[i]['vivek_h_5_actual_amt'],
                              vivek_h_concession_amt:
                                this.packageData[i]['vivek_h_5_concession_amt'],
                              vaidyraj_actual_amt:
                                this.packageData[i]['vaidyraj_5_actual_amt'],
                              vaidyraj_concession_amt:
                                this.packageData[i]['vaidyraj_5_concession_amt'],
                              granth_actual_amt:
                                this.packageData[i]['granth1_5_actual_amt'],
                              granth_remaining_amt:
                                this.selectedPackages[index]['pkg_amt'],
                              granth_concession_amt:
                                this.packageData[i]['granth1_5_concession_amt'],
                              book_actual_amt:
                                this.packageData[i]['granth2_5_actual_amt'],
                              book_concession_amt:
                                this.packageData[i]['granth2_5_concession_amt'],
                              donation_actual_amt:
                                this.packageData[i]['donation_5_actual_amt'],
                              donation_concession_amt:
                                this.packageData[i]['donation_5_concession_amt'],
                              courier_actual_amt:
                                this.packageData[i]['courier_5_actual_amt'],
                              courier_concession_amt:
                                this.packageData[i]['courier_5_concession_amt'],
                              advt_actual_amt:
                                this.packageData[i]['advt_5_actual_amt'],
                              advt_concession_amt:
                                this.packageData[i]['advt_5_concession_amt'],
                              other_actual_amt:
                                this.packageData[i]['other_5_actual_amt'],
                              other_concession_amt:
                                this.packageData[i]['other_5_concession_amt'],
                            };
                            // console.log(pkgDetails);
    
                            this.service
                              .updateCrdtPackageDetails(pkgDetails)
                              .subscribe((a) => {});
                          }
                        }
                      }
                    }
                  });
                }
              }
    
              if (parseInt(this.creditvoucherForm.get('donation').value) > 0) {
                console.log(
                  this.creditvoucherForm.get('donation').value,
                  this.names5.toString()
                );
    
                this.service
                  .updateCrdtVoucherDonationNames(
                    this.donation_id,
                    this.names5.toString()
                  )
                  .subscribe(
                    (data) => {
                      this.names5 = [];
                      this.donation_id = 0;
                    },
                    (error) => {
                      console.error(error);
                      this.service
                        .insertCreditVoucherDonationNames(
                          this.crdt_voucher_id,
                          this.names5.toString()
                        )
                        .subscribe((data) => {
                          this.names5 = [];
                        });
                    }
                  );
              }
    
              if (parseInt(this.creditvoucherForm.get('deposits').value) > 0) {
                console.log(
                  this.creditvoucherForm.get('deposits').value,
                  this.names3.toString()
                );
    
                this.service
                  .updateCrdtVoucherDepositsNames(
                    this.deposits_id,
                    this.names3.toString()
                  )
                  .subscribe(
                    (data) => {
                      this.names3 = [];
                      this.deposits_id = 0;
                    },
                    (error) => {
                      console.error(error);
                      this.service
                        .insertCreditVoucherDepositsNames(
                          this.crdt_voucher_id,
                          this.names3.toString()
                        )
                        .subscribe((data) => {
                          this.names3 = [];
                        });
                    }
                  );
              }
    
              if (parseInt(this.creditvoucherForm.get('odeposit').value) > 0) {
                console.log(
                  this.creditvoucherForm.get('odeposit').value,
                  this.names6.toString()
                );
    
                this.service
                  .updateCrdtVoucherOtherDepositsNames(
                    this.other_deposits_id,
                    this.names6.toString()
                  )
                  .subscribe(
                    (data) => {
                      this.names6 = [];
                      this.other_deposits_id = 0;
                    },
                    (error) => {
                      console.error(error);
                      this.service
                        .insertCreditVoucherOtherDepositsNames(
                          this.crdt_voucher_id,
                          this.names6.toString()
                        )
                        .subscribe((data) => {
                          this.names6 = [];
                        });
                    }
                  );
              }
    
              if (parseInt(this.creditvoucherForm.get('subscription').value) > 0) {
                console.log(
                  this.creditvoucherForm.get('subscription').value,
                  this.names.toString()
                );
                let sub = {
                  crdt_vucher_id: this.crdt_voucher_id,
                  subscription: this.creditvoucherForm.get('subscription').value,
                };
    
                this.service.updateCrdtVoucherSubcribers(sub).subscribe((subid) => {
                  let id = parseInt(subid['id']);
    
                  this.service
                    .updateCrdtVoucherSubscribersNames(
                      this.sub_id,
                      this.names.toString()
                    )
                    .subscribe(
                      (data) => {
                        this.names = [];
                        this.sub_id = 0;
                      },
                      (error) => {
                        console.error(error);
                        this.service
                          .insertCreditVoucherSubscribersNames(
                            parseInt(this.mainIdObject['s_id']),
                            this.names.toString()
                          )
                          .subscribe((data) => {
                            this.names = [];
                          });
                      }
                    );
                });
              }
    
              if (parseInt(this.creditvoucherForm.get('adverties').value) > 0) {
                console.log(
                  this.creditvoucherForm.get('adverties').value,
                  this.names2.toString()
                );
                let advt = {
                  crdt_vucher_id: this.crdt_voucher_id,
                  adverties: this.creditvoucherForm.get('adverties').value,
                };
    
                this.service.updateCreditVoucherAdvt(advt).subscribe((advtid) => {
                  let id = parseInt(advtid['id']);
                  this.service
                    .updateCrdtVoucherAdvtNames(
                      this.advt_id,
                      this.names2.toString()
                    )
                    .subscribe(
                      (data) => {
                        this.names2 = [];
                        this.advt_id = 0;
                      },
                      (error) => {
                        console.error(error);
                        this.service
                          .insertCreditVoucherAdvtNames(
                            parseInt(this.mainIdObject['a_id']),
                            this.names2.toString()
                          )
                          .subscribe((data) => {
                            this.names2 = [];
                          });
                      }
                    );
                });
              }
    
              if (parseInt(this.creditvoucherForm.get('granthbook').value) > 0) {
                console.log(
                  this.creditvoucherForm.get('granthbook').value,
                  this.names4.toString()
                );
                let book = {
                  crdt_vucher_id: this.crdt_voucher_id,
                  granthbook: this.creditvoucherForm.get('granthbook').value,
                };
    
                this.service.updateCreditVoucherBook(book).subscribe((book) => {
                  let id = parseInt(book['id']);
                  this.service
                    .updateCrdtVoucherBookNames(
                      this.book_id,
                      this.names4.toString()
                    )
                    .subscribe(
                      (data) => {
                        this.names4 = [];
                        this.book_id = 0;
                      },
                      (error) => {
                        console.error(error);
                        this.service
                          .insertCreditVoucherBookNames(
                            parseInt(this.mainIdObject['b_id']),
                            this.names4.toString()
                          )
                          .subscribe((data) => {
                            this.names4 = [];
                          });
                      }
                    );
                });
              }
    
              if (parseInt(this.creditvoucherForm.get('isale').value) > 0) {
                console.log(
                  this.creditvoucherForm.get('isale').value,
                  this.names1.toString()
                );
                let issuesale = {
                  crdt_vucher_id: this.crdt_voucher_id,
                  isale: this.creditvoucherForm.get('isale').value,
                };
    
                this.service
                  .updateCreditVoucherIssueSale(issuesale)
                  .subscribe((issuesale) => {
                    let id = parseInt(issuesale['id']);
                    this.service
                      .updateCrdtVoucherIssueSaleNames(
                        this.issue_id,
                        this.names1.toString()
                      )
                      .subscribe(
                        (data) => {
                          this.names1 = [];
                          this.issue_id = 0;
                        },
                        (error) => {
                          console.error(error);
                          this.service
                            .insertCreditVoucherIssueSaleNames(
                              parseInt(this.mainIdObject['i_id']),
                              this.names1.toString()
                            )
                            .subscribe((data) => {
                              this.names1 = [];
                            });
                        }
                      );
                  });
    
                this.service
                  .insertCreditVoucherIssueSale(issuesale)
                  .subscribe((issueid) => {
                    let id = parseInt(issueid['id']);
                    this.service
                      .insertCreditVoucherIssueSaleNames(id, this.names1.toString())
                      .subscribe((data) => {
                        this.names1 = [];
                      });
                  });
              }
    
              //exp ledger update code here
              this.selectedMainExpenses.map((exp) => {
                if (exp['isUpdated'] !== 'N') {
                  // console.log(exp);
                  let data = {
                    id: exp.id,
                    mainexpid: exp.exp_id,
                    expamt: exp.expamt,
                  };
    
                  this.service.updateCreditMainExpense(data).subscribe((res) => {
                    let subData = this.selectedsubExpenses.filter(
                      (a) => a['exp_id'] === data['mainexpid']
                    );
                    // console.log(subData);
    
                    if (Object.keys(subData).length > 0) {
                      subData.map((subexp) => {
                        if (subexp['isUpdated'] !== 'N') {
                          // console.log(subexp);
                          let subexpdata = {
                            id: subexp['id'],
                            subexpid: subexp['sub_exp_id'],
                            expamt: subexp['expamt'],
                          };
                          this.service
                            .updateCreditSubExpense(subexpdata)
                            .subscribe((data) => {
                              // console.log(data);
                            });
                        }
                      });
                    }
                  });
                }
              });

              this.selectedMainExpenses1.map((exp) => {
                if (exp['isUpdated'] !== 'N') {
                  // console.log(exp);
                  let data = {
                    id: exp.id,
                    mainexpid: exp.exp_id,
                    expamt: exp.expamt,
                  };
    
                  this.service.updateOtherMainExpense(data).subscribe((res) => {
                    let subData = this.selectedsubExpenses1.filter(
                      (a) => a['exp_id'] === data['mainexpid']
                    );
                    // console.log(subData);
    
                    if (Object.keys(subData).length > 0) {
                      subData.map((subexp) => {
                        if (subexp['isUpdated'] !== 'N') {
                          // console.log(subexp);
                          let subexpdata = {
                            id: subexp['id'],
                            subexpid: subexp['sub_exp_id'],
                            expamt: subexp['expamt'],
                          };
                          this.service
                            .updateOtherSubExpense(subexpdata)
                            .subscribe((data) => {
                              // console.log(data);
                            });
                        }
                      });
                    }
                  });
                }
              });
    
              setTimeout(() => {
                alert('Credit Voucher Updated Successfully...');
                this.creditvoucherForm.reset();
                this.selectedMainExpenses = [];
                this.selectedsubExpenses = [];
                // this.ngOnInit();
                // location.reload();
              }, 200);
            });
          } else {
            alert('Please check the amount.');
          }
        }
      // }else{
      //   alert('Receipt is alreday created, now you can`t update');
  
      // }
    });

   

    
  }

  showUpdateOnMainOrSub: boolean = false;
  main_exp_id = 0;
  updateMainExp(item) {
    // console.log(item);
    this.showUpdateOnMainOrSub = true;
    this.main_exp_id = item['id'];
    this.creditvoucherForm.get('expname').setValue(item['exp_id']);
    this.creditvoucherForm.get('exp_amt').setValue(item['expamt']);
  }

  showUpdateOnMainOrSubOther: boolean = false;
  
  updateMainExp1(item) {
    // console.log(item);
    this.showUpdateOnMainOrSubOther = true;
    this.main_exp_id = item['id'];
    this.creditvoucherForm.get('expnameotherd').setValue(item['exp_id']);
    this.creditvoucherForm.get('exp_amtother').setValue(item['expamt']);
  }

  sub_exp_id = 0;
  updateSubExp(item) {
    // console.log(item);
    this.showUpdateOnMainOrSub = true;
    this.getMainExpName({ value: item['exp_id'] });
    this.sub_exp_id = item['id'];
    this.creditvoucherForm.get('expname').setValue(item['exp_id']);
    this.subexpense = true;
    this.creditvoucherForm.get('subexpname').setValue(item['sub_exp_id']);
    this.creditvoucherForm.get('exp_amt').setValue(item['expamt']);
  }
  subexpense1:any
  sub_exp_id1:any
  updateSubExp1(item) {
    // console.log(item);
    this.showUpdateOnMainOrSub = true;
    this.getMainExpName1({ value: item['exp_id'] });
    this.sub_exp_id1 = item['id'];
    this.creditvoucherForm.get('expnameotherd').setValue(item['exp_id']);
    this.subexpense1 = true;
    this.creditvoucherForm.get('subexpnameother').setValue(item['sub_exp_id']);
    this.creditvoucherForm.get('exp_amtother').setValue(item['expamt']);

    // this.creditvoucherForm.patchValue({
    //   expnameotherd:item['exp_id'],
    //   subexpnameother:item['sub_exp_id'],
    //   exp_amtother:item['expamt'],
    // })
  }

  updateMainExpense() {
    // update the main expense and subexpense
    // console.log(this.subexpense);
    // console.log(this.selectedsubExpenses);
    // console.log(this.selectedMainExpenses);

    if (this.subexpense) {
      for (
        let index = 0;
        index < Object.keys(this.selectedsubExpenses).length;
        index++
      ) {
        if (this.selectedsubExpenses[index]['id'] === this.sub_exp_id) {
          if (
            this.selectedsubExpenses[index]['sub_exp_id'] !== this.sub_expId &&
            this.sub_expId !== 0
          ) {
            this.selectedsubExpenses[index]['subexpname'] = this.sub_expName;
            this.selectedsubExpenses[index]['sub_exp_id'] = this.sub_expId;
            this.sub_expName = '';
            this.sub_expId = 0;
          }
          if (
            this.selectedsubExpenses[index]['exp_id'] !== this.mainexpId &&
            this.mainexpId !== 0
          ) {
            for (
              let j = 0;
              j < Object.keys(this.selectedMainExpenses).length;
              j++
            ) {
              if (
                this.selectedsubExpenses[index]['exp_id'] ===
                this.selectedMainExpenses[j]['exp_id']
              ) {
                this.selectedMainExpenses[j]['exp_id'] = this.mainexpId;
                this.selectedMainExpenses[j]['mainexpname'] = this.mainexpName;
                this.selectedMainExpenses[j]['isUpdated'] = 'Y';
              }
            }
            this.selectedsubExpenses[index]['mainexpname'] = this.mainexpName;
            this.selectedsubExpenses[index]['exp_id'] = this.mainexpId;
            this.mainexpName = '';
            this.mainexpId = 0;
          } else {
            for (
              let j = 0;
              j < Object.keys(this.selectedMainExpenses).length;
              j++
            ) {
              if (
                this.selectedsubExpenses[index]['exp_id'] ===
                this.selectedMainExpenses[j]['exp_id']
              ) {
                this.selectedMainExpenses[j]['isUpdated'] = 'Y';
              }
            }
          }
          this.selectedsubExpenses[index]['isUpdated'] = 'Y';
          this.selectedsubExpenses[index]['expamt'] =
            this.creditvoucherForm.get('exp_amt').value;
          this.creditvoucherForm.get('exp_amt').reset();
          this.creditvoucherForm.get('expname').reset();
          this.creditvoucherForm.get('subexpname').reset();
          this.subexpense = false;
        }
      }
    } else {
      for (let j = 0; j < Object.keys(this.selectedMainExpenses).length; j++) {
        if (this.selectedMainExpenses[j]['id'] === this.main_exp_id) {
          if (
            this.selectedMainExpenses[j]['exp_id'] !== this.mainexpId &&
            this.mainexpId !== 0
          ) {
            this.selectedMainExpenses[j]['exp_id'] = this.mainexpId;
            this.selectedMainExpenses[j]['mainexpname'] = this.mainexpName;
            this.mainexpId = 0;
            this.mainexpName = '';
          }
          this.selectedMainExpenses[j]['expamt'] =
            this.creditvoucherForm.get('exp_amt').value;
          this.selectedMainExpenses[j]['isUpdated'] = 'Y';
          this.creditvoucherForm.get('expname').reset();
          this.creditvoucherForm.get('exp_amt').reset();
        }
      }
    }

    for (
      let index = 0;
      index < Object.keys(this.selectedMainExpenses).length;
      index++
    ) {
      let subData = this.selectedsubExpenses.filter((a) => {
        return a['exp_id'] === this.selectedMainExpenses[index]['exp_id'];
      });

      if (Object.keys(subData).length > 0) {
        this.selectedMainExpenses[index]['expamt'] = 0;
        subData.map((sub) => {
          this.selectedMainExpenses[index]['expamt'] =
            parseFloat(this.selectedMainExpenses[index]['expamt']) +
            parseFloat(sub['expamt']);
        });
      }
    }

    // this.mainexpName = '';
    // this.mainexpId = 0;
    this.showUpdateOnMainOrSub = false;
    this.totalAmount();
  }

  updateMainExpense1() {
    // update the main expense and subexpense
    // console.log(this.subexpense);
    // console.log(this.selectedsubExpenses);
    // console.log(this.selectedMainExpenses);

    if (this.subexpense1) {
      for (
        let index = 0;
        index < Object.keys(this.selectedsubExpenses1).length;
        index++
      ) {
        if (this.selectedsubExpenses1[index]['id'] === this.sub_exp_id1) {
          if (
            this.selectedsubExpenses1[index]['sub_exp_id'] !== this.sub_expId1 &&
            this.sub_expId1 !== 0
          ) {
            this.selectedsubExpenses1[index]['subexpname'] = this.sub_expName1;
            this.selectedsubExpenses1[index]['sub_exp_id'] = this.sub_expId1;
            this.sub_expName1 = '';
            this.sub_expId1 = 0;
          }
          if (
            this.selectedsubExpenses1[index]['exp_id'] !== this.mainexpId1 &&
            this.mainexpId1 !== 0
          ) {
            for (
              let j = 0;
              j < Object.keys(this.selectedMainExpenses1).length;
              j++
            ) {
              if (
                this.selectedsubExpenses1[index]['exp_id'] ===
                this.selectedMainExpenses1[j]['exp_id']
              ) {
                this.selectedMainExpenses1[j]['exp_id'] = this.mainexpId1;
                this.selectedMainExpenses1[j]['mainexpname'] = this.mainexpName1;
                this.selectedMainExpenses1[j]['isUpdated'] = 'Y';
              }
            }
            this.selectedsubExpenses1[index]['mainexpname'] = this.mainexpName1;
            this.selectedsubExpenses1[index]['exp_id'] = this.mainexpId1;
            this.mainexpName1 = '';
            this.mainexpId1 = 0;
          } else {
            for (
              let j = 0;
              j < Object.keys(this.selectedMainExpenses1).length;
              j++
            ) {
              if (
                this.selectedsubExpenses1[index]['exp_id'] ===
                this.selectedMainExpenses1[j]['exp_id']
              ) {
                this.selectedMainExpenses1[j]['isUpdated'] = 'Y';
              }
            }
          }
          this.selectedsubExpenses1[index]['isUpdated'] = 'Y';
          this.selectedsubExpenses1[index]['expamt'] =
            this.creditvoucherForm.get('exp_amtother').value;
          this.creditvoucherForm.get('exp_amtother').reset();
          this.creditvoucherForm.get('expnameotherd').reset();
          this.creditvoucherForm.get('subexpnameotherd').reset();
          this.subexpense1 = false;
        }
      }
    } else {
      for (let j = 0; j < Object.keys(this.selectedMainExpenses1).length; j++) {
        if (this.selectedMainExpenses1[j]['id'] === this.main_exp_id) {
          if (
            this.selectedMainExpenses1[j]['exp_id'] !== this.mainexpId &&
            this.mainexpId !== 0
          ) {
            this.selectedMainExpenses1[j]['exp_id'] = this.mainexpId;
            this.selectedMainExpenses1[j]['mainexpname'] = this.mainexpName;
            this.mainexpId = 0;
            this.mainexpName = '';
          }
          this.selectedMainExpenses1[j]['expamt'] =
            this.creditvoucherForm.get('exp_amtother').value;
          this.selectedMainExpenses1[j]['isUpdated'] = 'Y';
          this.creditvoucherForm.get('expnameotherd').reset();
          this.creditvoucherForm.get('exp_amtother').reset();
        }
      }
    }

    for (
      let index = 0;
      index < Object.keys(this.selectedMainExpenses1).length;
      index++
    ) {
      let subData = this.selectedsubExpenses1.filter((a) => {
        return a['exp_id'] === this.selectedMainExpenses1[index]['exp_id'];
      });

      if (Object.keys(subData).length > 0) {
        this.selectedMainExpenses1[index]['expamt'] = 0;
        subData.map((sub) => {
          this.selectedMainExpenses1[index]['expamt'] =
            parseFloat(this.selectedMainExpenses1[index]['expamt']) +
            parseFloat(sub['expamt']);
        });
      }
    }

    // this.mainexpName = '';
    // this.mainexpId = 0;
    this.showUpdateOnMainOrSubOther = false;
    this.totalAmount1();
  }

  selectedPackageData = [];
  selectedPackageId;
  selectpackage(id) {
    this.selectedPackageId = 0;
    // console.log(this.packageData);

    for (let index = 0; index < Object.keys(this.packageData).length; index++) {
      if (this.packageData[index]['pkg_id'] == id) {
        this.selectedPackageId = id;
        this.selectedPackageData = this.packageData[index];
      }
    }
    // console.log(Object.keys(this.selectedPackageData).length);
    if (Object.keys(this.selectedPackageData).length != 0) {
      this.creditvoucherForm
        .get('package')
        .setValue(this.selectedPackageData['pkg_name']);
    }
  }

  selectedPackages = [];
  addPackage() {
    if (this.selectedPackageId != 0) {
      for (
        let index = 0;
        index < Object.keys(this.packageData).length;
        index++
      ) {
        if (
          this.packageData[index]['pkg_id'] == this.selectedPackageId &&
          this.packageData[index]['pkg_name'] ==
            this.creditvoucherForm.get('package').value
        ) {
          if (this.pkgAmount !== 0) {
            this.selectedPackages.push({
              pkg_id: this.packageData[index]['pkg_id'],
              pkg_name: this.packageData[index]['pkg_name'],
              pkg_amt: this.pkgAmount,
              client_name: this.creditvoucherForm.get('packagename').value,
            });
          } else {
            this.selectedPackages.push({
              pkg_id: this.packageData[index]['pkg_id'],
              pkg_name: this.packageData[index]['pkg_name'],
              pkg_amt: this.packageData[index]['pkg_amt'],
              client_name: this.creditvoucherForm.get('packagename').value,
            });
          }
        }
      }
    }
    this.creditvoucherForm.get('package').reset();
    this.creditvoucherForm.get('packagename').reset();
    // this.selectedPackages = this.uniqueid(this.selectedPackages, (a) => a.pkg_id);
    // console.log(this.selectedPackages);
    let pkg_amt_total = 0;
    for (let i = 0; i < Object.keys(this.selectedPackages).length; i++) {
      pkg_amt_total =
        pkg_amt_total + parseInt(this.selectedPackages[i]['pkg_amt']);
    }
    this.creditvoucherForm.get('packageamount').setValue(pkg_amt_total);
    this.creditvoucherForm
      .get('packagecount')
      .setValue(Object.keys(this.selectedPackages).length);
    this.totalAmt();
  }

  uniqueid(data, key) {
    return [...new Map(data.map((x) => [key(x), x])).values()];
  }

  removepkg(i) {
    console.log(i);
    if (window.confirm('Do you really want to remove ?')) {
      this.selectedPackages.splice(i, 1);

      let pkg_amt_total = 0;
      for (let i = 0; i < Object.keys(this.selectedPackages).length; i++) {
        pkg_amt_total =
          pkg_amt_total + parseInt(this.selectedPackages[i]['pkg_amt']);
      }
      this.creditvoucherForm.get('packageamount').setValue(pkg_amt_total);
      this.creditvoucherForm
        .get('packagecount')
        .setValue(Object.keys(this.selectedPackages).length);
      this.totalAmt();
    }
    // console.log(this.selectedPackages);
  }

  pkg_name1 = '';
  getPackageName(event, name) {
    if (event.isUserInput) {
      this.pkg_name1 = name;
      // console.log(name);

      this.filters();
    }
  }

  reset_pkg_name() {
    this.pkg_name1 = '';
    this.pkgBookingregisterForm.get('pkg_name').reset();
    if (
      this.pkg_name1 == '' &&
      this.pkgbrStartDate != '' &&
      this.pkgbrEndDate != ''
    ) {
      this.FilteredpkgBookingData = this.pkgBookingData.filter(
        (a) =>
          a['entry_date'] >= this.pkgbrStartDate &&
          a['entry_date'] <= this.pkgbrEndDate
      );
    } else if (
      this.pkg_name1 == '' &&
      this.pkgbrStartDate == '' &&
      this.pkgbrEndDate == ''
    ) {
      this.FilteredpkgBookingData = this.pkgBookingData;
    }

    this.filteredautocom();
  }

  pkgbrStartDate = '';
  pkgbrEndDate = '';
  // getchangedDates() {
  //   if (
  //     this.repledger.get('start').value != '' &&
  //     this.repledger.get('end').value != ''
  //   ) {
  //     this.pkgbrStartDate = formatDate(
  //       this.repledger.get('start').value,
  //       'yyyy-MM-dd',
  //       'en-IN',
  //       'GMT+0530'
  //     );
  //     this.pkgbrEndDate = formatDate(
  //       this.repledger.get('end').value,
  //       'yyyy-MM-dd',
  //       'en-IN',
  //       'GMT+0530'
  //     );

  //     this.filters();
  //   }
  // }
  getchangedDates1() {
    if (
      this.pkgBookingregisterForm.get('start').value != '' &&
      this.pkgBookingregisterForm.get('end').value != ''
    ) {
      this.pkgbrStartDate = formatDate(
        this.pkgBookingregisterForm.get('start').value,
        'yyyy-MM-dd',
        'en-IN',
        'GMT+0530'
      );
      this.pkgbrEndDate = formatDate(
        this.pkgBookingregisterForm.get('end').value,
        'yyyy-MM-dd',
        'en-IN',
        'GMT+0530'
      );
      console.log(this.pkgbrStartDate , this.pkgbrEndDate, "this.pkgbrStartDate ")

      this.filters();
    }
  }
  repledStartDate = '';
  repledEndDate = '';
  getchangedDatesrepledger() {
    
    if (
      this.repledger.get('start').value != '' &&
      this.repledger.get('end').value != ''
    ) {
      this.repledStartDate = formatDate(
        this.repledger.get('start').value,
        'yyyy-MM-dd',
        'en-IN',
        'GMT+0530'
      );
      this.repledEndDate = formatDate(
        this.repledger.get('end').value,
        'yyyy-MM-dd',
        'en-IN',
        'GMT+0530'
      );
      console.log(this.repledStartDate , this.repledEndDate, "this.pkgbrStartDate ")

      this.filters();
    }
  }
  
  kharediStartDate = '';
  kharediEndDate = '';
  getchangedDates2() {
    
    if (
      this.kharedi.get('start').value != '' &&
      this.kharedi.get('end').value != ''
    ) 
    {
      this.kharediStartDate = formatDate(
        this.kharedi.get('start').value,
        'yyyy-MM-dd',
        'en-IN',
        'GMT+0530'
      );
      this.kharediEndDate = formatDate(
        this.kharedi.get('end').value,
        'yyyy-MM-dd',
        'en-IN',
        'GMT+0530'
      );
      console.log(this.kharediStartDate , this.kharediEndDate, "kharedi date ")

      this.filters();
    }
  }
  getrepledger(){
    
    this.dialog.open(RepledgerComponent, {
      data: {
        data: '',
        startDate: this.repledStartDate,
        endDate: this.repledEndDate,
        repname: this.rep_name2,
      },
      
      width: '1200px',
      height: '1300px',
      maxWidth: '100%',
      
    });

  }

  filters() {
    if (
      this.pkg_name1 != '' &&
      this.pkgbrStartDate == '' &&
      this.pkgbrEndDate == ''
    ) {
      this.FilteredpkgBookingData = this.pkgBookingData.filter(
        (a) =>
          a['pkg_name'].toLowerCase().indexOf(this.pkg_name1.toLowerCase()) > -1
      );
    } else if (
      this.pkg_name1 != '' &&
      this.pkgbrStartDate != '' &&
      this.pkgbrEndDate != ''
    ) {
      this.FilteredpkgBookingData = this.pkgBookingData.filter((a) => {
        return (
          new Date(a['voucher_date']) >= new Date(this.pkgbrStartDate) &&
          new Date(a['voucher_date']) <= new Date(this.pkgbrEndDate) &&
          a['pkg_name'].toLowerCase().indexOf(this.pkg_name1.toLowerCase()) > -1
        );
      });
    } else if (
      this.pkg_name1 == '' &&
      this.pkgbrStartDate != '' &&
      this.pkgbrEndDate != ''
    ) {
      this.FilteredpkgBookingData = this.pkgBookingData.filter((a) => {
        return (
          new Date(a['voucher_date']) >= new Date(this.pkgbrStartDate) &&
          new Date(a['voucher_date']) <= new Date(this.pkgbrEndDate)
        );
      });
    }
  }

  off_rep_name = '';
  getOffrepnames(event) {
    if (event.isUserInput) {
      this.off_rep_name = event.value;
      // console.log(event.value);
      if (this.off_rep_name != '') {
        this.FilteredpkgBookingData = this.pkgBookingData.filter((a) =>
          this.off_rep_name.includes(a['off_rep_name'])
        );
      }
    }
  }

  rep_name1 = '';
  getrepnames(event) {
    console.log(event);
    this.rep_name1 = event.value;
    if (this.rep_name1 != '') {
      this.FilteredpkgBookingData = this.pkgBookingData.filter((a) => {
        return this.rep_name1.includes(a['rep_name']);
      });
    }
  }

  rep_name2 = '';
  getrepnames1(event) {
    console.log(event);
    this.rep_name2 = event.value;
    if (this.rep_name1 != '') {
      this.FilteredpkgBookingData = this.pkgBookingData.filter((a) => {
        return this.rep_name1.includes(a['rep_name']);
      });
    }
  }

  reset_rep_name() {
    this.rep_name1 = '';
    this.pkgBookingregisterForm.get('rep_name').reset();
    if (this.rep_name1 == '') {
      this.FilteredpkgBookingData = this.pkgBookingData;
    }
    this.filteredautocom();
  }

  cust_name1 = '';
  getcustnames(event) {
    console.log(event);
    this.cust_name1 = event.value;
    if (this.cust_name1 != '') {
      this.FilteredpkgBookingData = this.pkgBookingData.filter((a) => {
        return this.cust_name1.includes(a['client_name']);
      });
    }
  }

  reset_cust_name() {
    this.cust_name1 = '';
    this.pkgBookingregisterForm.get('cust_name').reset();
    if (this.cust_name1 == '') {
      this.FilteredpkgBookingData = this.pkgBookingData;
    }
    this.filteredautocom();
  }

  addBankName() {
    let bankData = {
      bank_name: this.bankForm.get('bank_name').value,
      bank_short_name: this.bankForm.get('bank_short_name').value,
      bank_o_bal: this.bankForm.get('bank_o_bal').value,
      crdtdebt: this.bankForm.get('crdtdebt').value,
      is_active: 'Y',
    };

    // console.log(bankData);

    this.service.insertBankName(bankData).subscribe((data) => {
      // console.log(data);
      alert('Bank name added successfully...');
      this.bankForm.reset('');
      this.ngOnInit();
    });
  }

  showUpdate: boolean = false;
  bankId = 0;
  selectBankMaster(bank) {
    if (bank) {
      this.showUpdate = true;
      this.bankId = bank['id'];
      this.bankForm.get('bank_name').setValue(bank['bank_name']);
      this.bankForm.get('bank_short_name').setValue(bank['bank_short_name']);
      this.bankForm.get('bank_o_bal').setValue(bank['opening_bal']);
      this.bankForm.get('crdtdebt').setValue(bank['crdt_debt']);
    }
  }

  updateBankMaster() {
    let bankData = {
      id: this.bankId,
      bank_name: this.bankForm.get('bank_name').value,
      bank_short_name: this.bankForm.get('bank_short_name').value,
      bank_o_bal: this.bankForm.get('bank_o_bal').value,
      crdtdebt: this.bankForm.get('crdtdebt').value,
    };

    this.service.updateBankName(bankData).subscribe((res) => {
      alert('Bank details updated successfully...');
      this.bankForm.reset('');
      this.ngOnInit();
    });
  }

  creditdebitledgerdata() {
    this.dialog.open(CreditDebitLedgerReportComponent, {
      data: {
        data: '',
        startDate: this.creditdebitStartDate,
        endDate: this.creditdebitEndDate,
        bank: this.selectedBank,
      },
      width: '1200px',
      height: '1300px',
      maxWidth: '100%',
    });
  }

  creditdebitStartDate = '';
  creditdebitEndDate = '';
  getCreditDebitDateRange() {
    if (
      this.creditdebitDateRange.get('start').value != null &&
      this.creditdebitDateRange.get('end').value != null
    ) {
      this.creditdebitStartDate = formatDate(
        this.creditdebitDateRange.get('start').value,
        'yyyy-MM-dd',
        'en-IN',
        'GMT+0530'
      );
      // console.log(this.creditdebitStartDate);

      this.creditdebitEndDate = formatDate(
        this.creditdebitDateRange.get('end').value,
        'yyyy-MM-dd',
        'en-IN',
        'GMT+0530'
      );
      // console.log(this.creditdebitEndDate);
    }
  }

  selectedBank = '';
  getBankCashOnCD(event) {
    this.selectedBank = event.value;
    // console.log(this.selectedBank);
  }

  reconsilationStartDate = '';
  reconsilationEndDate = '';
  getReconsilationDateRange() {
    if (
      this.reconsilationDateRange.get('start').value != null &&
      this.reconsilationDateRange.get('end').value != null
    ) {
      this.reconsilationStartDate = formatDate(
        this.reconsilationDateRange.get('start').value,
        'yyyy-MM-dd',
        'en-IN',
        'GMT+0530'
      );
      // console.log(this.creditdebitStartDate);

      this.reconsilationEndDate = formatDate(
        this.reconsilationDateRange.get('end').value,
        'yyyy-MM-dd',
        'en-IN',
        'GMT+0530'
      );
      // console.log(this.creditdebitEndDate);
    }
  }
  expenseledgerStartDate = '';
  expenseledgerEndDate = '';
  expenseledgerDateRange() {
    if (
      this.ExpenseLedgerForm.get('start').value != null &&
      this.ExpenseLedgerForm.get('end').value != null
    ) {
      this.expenseledgerStartDate = formatDate(
        this.expenseledger1.get('start').value,
        'yyyy-MM-dd',
        'en-IN'
      );
      // console.log(this.creditdebitStartDate);

      this.expenseledgerEndDate = formatDate(
        this.expenseledger1.get('end').value,
        'yyyy-MM-dd',
        'en-IN'
      );
      // console.log(this.creditdebitEndDate);
    }
  }
  expenReportStartDate = '';
  expenReportEndDate = '';
  expenseReportDateRange() {
    if (
      this.expensereport.get('start').value != null &&
      this.expensereport.get('end').value != null
    ) {
      this.expenReportStartDate = formatDate(
        this.expensereport.get('start').value,
        'yyyy-MM-dd',
        'en-IN'
      );
      // console.log(this.creditdebitStartDate);

      this.expenReportEndDate = formatDate(
        this.expensereport.get('end').value,
        'yyyy-MM-dd',
        'en-IN'
      );
      console.log(this.expenReportStartDate);
      console.log(this.expenReportEndDate);

    }
  }

  expenReportStartDate11 = '';
  expenReportEndDate11 = '';
  expenseReportDateRange11() {
    if (
      this.journalvouchergetdate.get('start').value != null &&
      this.journalvouchergetdate.get('end').value != null
    ) {
      this.expenReportStartDate11 = formatDate(
        this.journalvouchergetdate.get('start').value,
        'yyyy-MM-dd',
        'en-IN'
      );
      // console.log(this.creditdebitStartDate);

      this.expenReportEndDate11 = formatDate(
        this.journalvouchergetdate.get('end').value,
        'yyyy-MM-dd',
        'en-IN'
      );
      console.log(this.expenReportStartDate11);
      console.log(this.expenReportEndDate11);

     

    }
  }

  selectedRCSBank = '';
  bankfullname = '';
  getBankCashOnReconsilation(event) {
    
    this.selectedRCSBank = event.shortName;
    this.bankfullname = event.subExpName
  }
  cheque_no = '';
  getcheque_no(event: Event) {
    const inputElement = event.target as HTMLInputElement;
    this.cheque_no = inputElement.value;
    
  }

  reconsilationReportData;
  reconsilationReportData1;
  getReconsilationData() {
    

    if (
      this.reconsilationStartDate !== '' &&
      this.reconsilationEndDate !== '' &&
      this.selectedRCSBank !== '' &&
      this.bankfullname
     
    ) {
      this.service
        .getReconsilationReportData(
          this.reconsilationStartDate,
          this.reconsilationEndDate,
          this.selectedRCSBank,
          this.cheque_no,
          this.bankfullname
        )
        .subscribe((res) => {
          console.log(res);
          this.reconsilationReportData = res;
          if(this.reconsilationReportData ==undefined){
            this.reconsilationReportData = []
          }

        //   console.log(this.reconsilationReportData,"this first")
        //   console.log(this.reconsilationReportData1,"this second")
          
        //  this.mergedArray1 = [...this.reconsilationReportData, ...this.reconsilationReportData1];
        //  console.log(this.mergedArray1)
        });

        this.service
        .getReconsilationReportData1(
          this.reconsilationStartDate,
          this.reconsilationEndDate,
          this.selectedRCSBank,
          this.cheque_no,
          this.bankfullname

        )
        .subscribe((res) => {
          console.log(res);
          this.reconsilationReportData1 = res;
          if(this.reconsilationReportData1 ==undefined){
            this.reconsilationReportData1 = []
          }
          console.log(this.reconsilationReportData,"this first")
          console.log(this.reconsilationReportData1,"this second")
          
         this.mergedArray1 = [...this.reconsilationReportData, ...this.reconsilationReportData1];

         this.mergedArray1.sort((a, b) => {
          const dateA = new Date(a.cheq_clearing_date);
          const dateB = new Date(b.cheq_clearing_date);
          return dateA.getTime() - dateB.getTime(); // For ascending order
          // return dateB.getTime() - dateA.getTime(); // For descending order
        });
         console.log(this.mergedArray1, "merger list")
        });



    } else if (
      this.reconsilationStartDate !== '' &&
      this.reconsilationEndDate !== '' &&
      this.selectedRCSBank === ''
    ) {
      alert('Please select bank');
    } else if (
      this.reconsilationStartDate === '' &&
      this.reconsilationEndDate === '' &&
      this.selectedRCSBank !== ''
    ) {
      alert('Please select date');
    }
  }

  clearingDate = '';
  showclearingUpdatebtn = false;
  clearUpdateId = 0;
  updateReconsilationReport(event, item) {
    
    console.log(event.target.value, item);
    // setTimeout(() => {
    // this.reconsilationReportData.map((data) => {
    //   if (data['id'] === id) {
    //     data['cheq_clearing_date'] = event.target.value;
    //   }
    // });
    // }, 800);
    if (event.target.value >= item['voucher_date']) {
      if (confirm('Are sure you want to update clearing date')) {
        this.service
          .updateClearingDate(
            item['id'],
            formatDate(event.target.value, 'yyyy-MM-dd', 'en-IN', 'GMT+0530')
          )
          .subscribe((res) => {
            alert('Clearing date updated successfully..');
            this.showclearingUpdatebtn = false;
            this.clearUpdateId = 0;
            this.getReconsilationData();
          });
          this.service
          .updateClearingDate1(
            item['id'],
            formatDate(event.target.value, 'yyyy-MM-dd', 'en-IN', 'GMT+0530')
          )
          .subscribe((res) => {
            alert('Clearing date updated successfully..');
            this.showclearingUpdatebtn = false;
            this.clearUpdateId = 0;
            this.getReconsilationData();
          });
      }
    } else {
      alert('Please select the date after voucher date');
    }
  }

  updateClearingDate(item) {
    this.showclearingUpdatebtn = true;
    this.clearUpdateId = item['id'];
    this.clearingDate = item['cheq_clearing_date'];
  }

  check() {
    if (this.cashtype) {
      return true;
    }
    return false;
  }

  checkvalue_yes_no(exp){
    this.opening_yes_no = exp.opening_yes_no
    console.log(exp)

  }

  save_ExpenseLedger() {
    // console.log(this.ExpenseLedgerForm.get('openingbal').value);
    let data = {
      expensename: this.ExpenseLedgerForm.get('expensename').value,
      exptype: this.ExpenseLedgerForm.get('exptype').value,
      opening_bal: this.ExpenseLedgerForm.get('openingbal').value,
      opening_yes_no :  this.ExpenseLedgerForm.get('opening_yes_no').value,
      type :  this.ExpenseLedgerForm.get('crdr').value,
    };
    console.log(data);
    this.service.addMainExpenseLedger(data).subscribe((res) => {
      console.log('added main exp', res);
      alert('Expense ledger added successfully...');
      this.ExpenseLedgerForm.reset();
      this.ngOnInit();
    });
  }

  expId = 0;
  mainExpSelected = false;
  selectMainExp(exp) {
    this.expId = exp['id'];
    this.mainExpSelected = true;
    this.ExpenseLedgerForm.get('expensename').setValue(exp['exp_name']);
    this.ExpenseLedgerForm.get('exptype').setValue(exp['exp_type']);
    this.ExpenseLedgerForm.get('openingbal').setValue(exp['opening_bal']);
  }

  update_ExpenseLedger() {
    this.service
      .updateMainExpenseLedger(
        this.expId,
        this.ExpenseLedgerForm.get('expensename').value,
        this.ExpenseLedgerForm.get('exptype').value,
        this.ExpenseLedgerForm.get('openingbal').value,
      )
      .subscribe((res) => {
        // console.log('added main exp');
        alert('Expense ledger updated successfully...');
        this.ExpenseLedgerForm.reset();
        this.mainExpSelected = false;
        this.ngOnInit();
      });
  }

  getMainExpenseData() {
    this.service.getMainExpenseLedger().subscribe((res) => {
      // console.log(res);
      this.mainExpData = res;
    });
  }

  save_SubExpenseLedger() {
    let data = {
      mainexpid: this.SubExpenseLedgerForm.get('expname').value,
      subexpname: this.SubExpenseLedgerForm.get('subexpname').value,
      // bank_name: this.SubExpenseLedgerForm.get('bank_name').value,
      subShortName: this.SubExpenseLedgerForm.get('bank_short_name').value,
      subOpeningBal: this.SubExpenseLedgerForm.get('bank_o_bal').value,
      subStatus: this.SubExpenseLedgerForm.get('crdtdebt').value,
    };

    console.log(data, "bank exp");

    this.service.addSubExpenseLedger(data).subscribe((res) => {
      alert('SubExpense ledger added successfully...');
      this.SubExpenseLedgerForm.reset('');
      this.ngOnInit();
    });
  }

  subexpId = 0;
  subExpSelected = false;
  selectSubExp(exp) {
    this.subexpId = exp['id'];
    this.subExpSelected = true;
    this.SubExpenseLedgerForm.get('expname').setValue(exp['main_exp_id']);
    this.SubExpenseLedgerForm.get('subexpname').setValue(exp['sub_exp_name']);
    this.SubExpenseLedgerForm.get('bank_o_bal').setValue(exp['opening_bal']);
    this.SubExpenseLedgerForm.get('bank_short_name').setValue(exp['short_name']);
    this.SubExpenseLedgerForm.get('crdtdebt').setValue(exp['status']);
  }

  update_SubExpenseLedger() {
    
    let data = {
      id: this.subexpId,
      mainexpid: this.SubExpenseLedgerForm.get('expname').value,
      subexpname: this.SubExpenseLedgerForm.get('subexpname').value,
      opening_bal: this.SubExpenseLedgerForm.get('bank_o_bal').value,
      short_name: this.SubExpenseLedgerForm.get('bank_short_name').value,
      status: this.SubExpenseLedgerForm.get('crdtdebt').value,
      // opba: this.SubExpenseLedgerForm.get('bank_o_bal').value,
    };

    this.service.updateSubExpenseLedger(data).subscribe((res) => {
      alert('SubExpense ledger updated successfully...');
      this.SubExpenseLedgerForm.reset('');
      this.subExpSelected = false;
      this.ngOnInit();
    });
  }

  getSubExpenseData() {
    this.service.getSubExpenseLedger().subscribe((res) => {
      // console.log(res);
      this.subExpData = res;
    });
  }

  subexpense = false;

  mainexpId = 0;
  mainexpName = '';
  mainexpId1 = 0;
  mainexpName1 = '';
  getMainExpName1(event) {
    for (let index = 0; index < Object.keys(this.mainExpData).length; index++) {
      if (this.mainExpData[index]['id'] === event.value) {
        this.mainexpId1 = this.mainExpData[index]['id'];
        this.mainexpName1 = this.mainExpData[index]['exp_name'];
      }
    }
    debugger;
    // console.log(exp, this.mainExpData);
    this.service.getSubExpenseLedgerWithId(event.value).subscribe((res) => {
      this.getSubExpData1 = res;
      // console.log(this.getSubExpData);
      if (Object.keys(this.getSubExpData1).length > 0) {
        this.subexpense1 = true;
      } else {
        this.subexpense1 = false;
      }
    });
  }
  // getMainExpName1(event) {
  //   for (let index = 0; index < Object.keys(this.mainExpData).length; index++) {
  //     if (this.mainExpData[index]['id'] === event.value) {
  //       this.mainexpId = this.mainExpData[index]['id'];
  //       this.mainexpName = this.mainExpData[index]['exp_name'];
  //     }
  //   }
  //   // console.log(exp, this.mainExpData);
  //   this.service.getSubExpenseLedgerWithId(event.value).subscribe((res) => {
  //     this.getSubExpData = res;
  //     // console.log(this.getSubExpData);
  //     if (Object.keys(this.getSubExpData).length > 0) {
  //       this.subexpense = true;
  //     } else {
  //       this.subexpense = false;
  //     }
  //   });
  // }
  getSubExpData1:any
  getMainExpName2(event, item) {
    
    for (let index = 0; index < Object.keys(this.mainExpData).length; index++) {
      if (this.mainExpData[index]['id'] === event.value) {
        this.mainexpId = this.mainExpData[index]['id'];
        this.mainexpName = this.mainExpData[index]['exp_name'];
      }
    }
    // console.log(exp, this.mainExpData);
    this.service.getSubExpenseLedgerWithId(event.value).subscribe((res) => {
      this.getSubExpData1 = res;
      item.getSubExpData1 = res;
      // console.log(this.getSubExpData);
      if (Object.keys(this.getSubExpData1).length > 0) {
        this.subexpense = true;
      } else {
        this.subexpense = false;
      }
    });
  }
  getMainExpName(event) {
    for (let index = 0; index < Object.keys(this.mainExpData).length; index++) {
      if (this.mainExpData[index]['id'] === event.value) {
        this.mainexpId = this.mainExpData[index]['id'];
        this.mainexpName = this.mainExpData[index]['exp_name'];
      }
    }
    // console.log(exp, this.mainExpData);
    this.service.getSubExpenseLedgerWithId(event.value).subscribe((res) => {
      this.getSubExpData = res;
      // console.log(this.getSubExpData);
      if (Object.keys(this.getSubExpData).length > 0) {
        this.subexpense = true;
      } else {
        this.subexpense = false;
      }
    });
  }

  sub_expId = 0;
  sub_expName = '';

  sub_expId1 = 0;
  sub_expName1 = '';
  getSubExpName1(event) {
    // console.log(this.getSubExpData, event.value);
debugger
    for (
      let index = 0;
      index < Object.keys(this.getSubExpData1).length;
      index++
    ) {
      if (this.getSubExpData1[index]['sub_id'] === event.value) {
        // console.log(this.getSubExpData[index]);
        this.sub_expId1 = this.getSubExpData1[index]['sub_id'];
        this.sub_expName1 = this.getSubExpData1[index]['sub_exp_name'];
      }
    }
  }

  getSubExpName2(event, item) {
    
    const subIdToCheck = 58; // Set default value to 58 if event.value is undefined
  
    for (
      let index = 0;
      index < Object.keys(item.getSubExpData1).length;
      index++
    ) {
      if (item.getSubExpData1[index]['sub_id'] === event.value) {
        // console.log(this.getSubExpData[index]);
        // this.sub_expId = this.getSubExpData[index]['sub_id'];
        let sub_expName = item.getSubExpData1[index]['sub_exp_name'];

        let name = sub_expName.split(' ');
         
        let name2 = name[1].split('%');
        console.log(name2[0])
        let gstPercentage = parseFloat(name2[0]);

        if (!isNaN(gstPercentage)) {
        //  let netamount:any= (this.items.length -1 )- 1;
          // Assuming you want to calculate GST for the exp_amt field in the item
          let expAmount = parseFloat(this.items[0].netAmount);
          let gstAmount = expAmount * (gstPercentage / 100);
          console.log(`GST Amount: ${gstAmount}`);
          item.netAmount = gstAmount.toFixed(2);
          item.totalAmount = gstAmount.toFixed(2);


      } else {
          console.log('Invalid GST percentage');
      }

      }
    }


    this.totalAmounts=0
    this.items.forEach(element => {
      this.totalAmounts = Number(this.totalAmounts)+Number(element.netAmount)  
    });
  }
  
  getSubExpName(event) {
    // console.log(this.getSubExpData, event.value);

    for (
      let index = 0;
      index < Object.keys(this.getSubExpData).length;
      index++
    ) {
      if (this.getSubExpData[index]['sub_id'] === event.value) {
        // console.log(this.getSubExpData[index]);
        this.sub_expId = this.getSubExpData[index]['sub_id'];
        this.sub_expName = this.getSubExpData[index]['sub_exp_name'];
      }
    }
  }

  selectedsubExpenses = [];
  selectedMainExpenses = [];

  selectedsubExpenses1 = [];
  selectedMainExpenses1 = [];

  addMainExpense1() {
    if (this.subexpense1) {
      this.selectedsubExpenses1.push({
        mainexpname: this.mainexpName1,
        mainexpid: this.mainexpId1,
        subexpname: this.sub_expName1,
        subexpid: this.sub_expId1,
        expamt: this.creditvoucherForm.get('exp_amtother').value,
      });

      if (Object.keys(this.selectedMainExpenses1).length === 0) {
        this.selectedMainExpenses1.push({
          mainexpname: this.mainexpName1,
          mainexpid: this.mainexpId1,
          expamt: this.creditvoucherForm.get('exp_amtother').value,
        });
      } else {
        let flag = false;
        for (
          let index = 0;
          index < Object.keys(this.selectedMainExpenses1).length;
          index++
        ) {
          if (
            this.selectedMainExpenses1[index]['mainexpid'] === this.mainexpId1
          ) {
            this.selectedMainExpenses1[index]['expamt'] =
              parseFloat(this.selectedMainExpenses1[index]['expamt']) +
              parseFloat(this.creditvoucherForm.get('exp_amtother').value);
            flag = true;
            break;
          } else {
            console.log('else is running', flag);
          }
        }
        if (!flag) {
          this.selectedMainExpenses1.push({
            mainexpname: this.mainexpName1,
            mainexpid: this.mainexpId1,
            expamt: this.creditvoucherForm.get('exp_amtother').value,
          });
        }
      }
      this.sub_expName1 = '';
      this.sub_expId1 = 0;
      this.creditvoucherForm.get('subexpnameotherd').reset();
      this.subexpense1 = false;
    } else {
      this.selectedMainExpenses1.push({
        mainexpname: this.mainexpName1,
        mainexpid: this.mainexpId1,
        expamt: this.creditvoucherForm.get('exp_amtother').value,
      });
    }

    this.mainexpName1 = '';
    this.mainexpId1 = 0;
    this.creditvoucherForm.get('expnameotherd').reset();
    this.creditvoucherForm.get('exp_amtother').reset();
    this.totalAmount1();
  }

  expamt1 = 0;
  total_amt1 = 0;
  totalAmount1() {
    this.expamt1 = 0;
    this.selectedMainExpenses1.map((exp) => {
      this.expamt1 = this.expamt1 + parseFloat(exp['expamt']);
    });
    this.creditvoucherForm.get('totalexp').setValue(this.expamt1);
    if (this.total_amt1 != 0) {
      // console.log(this.total_amt);

      this.creditvoucherForm
        .get('ndeposit')
        .setValue(this.total_amt1 - this.expamt1);
    }
  }

  addMainExpense() {
    if (this.subexpense) {
      this.selectedsubExpenses.push({
        mainexpname: this.mainexpName,
        mainexpid: this.mainexpId,
        subexpname: this.sub_expName,
        subexpid: this.sub_expId,
        expamt: this.creditvoucherForm.get('exp_amt').value,
      });

      if (Object.keys(this.selectedMainExpenses).length === 0) {
        this.selectedMainExpenses.push({
          mainexpname: this.mainexpName,
          mainexpid: this.mainexpId,
          expamt: this.creditvoucherForm.get('exp_amt').value,
        });
      } else {
        let flag = false;
        for (
          let index = 0;
          index < Object.keys(this.selectedMainExpenses).length;
          index++
        ) {
          if (
            this.selectedMainExpenses[index]['mainexpid'] === this.mainexpId
          ) {
            this.selectedMainExpenses[index]['expamt'] =
              parseFloat(this.selectedMainExpenses[index]['expamt']) +
              parseFloat(this.creditvoucherForm.get('exp_amt').value);
            flag = true;
            break;
          } else {
            console.log('else is running', flag);
          }
        }
        if (!flag) {
          this.selectedMainExpenses.push({
            mainexpname: this.mainexpName,
            mainexpid: this.mainexpId,
            expamt: this.creditvoucherForm.get('exp_amt').value,
          });
        }
      }
      this.sub_expName = '';
      this.sub_expId = 0;
      this.creditvoucherForm.get('subexpname').reset();
      this.subexpense = false;
    } else {
      this.selectedMainExpenses.push({
        mainexpname: this.mainexpName,
        mainexpid: this.mainexpId,
        expamt: this.creditvoucherForm.get('exp_amt').value,
      });
    }

    this.mainexpName = '';
    this.mainexpId = 0;
    this.creditvoucherForm.get('expname').reset();
    this.creditvoucherForm.get('exp_amt').reset();
    this.totalAmount();
  }

  removeMainExp(mainId) {
    if (confirm('Do you really want to remove?')) {
      // console.log(mainId);
      this.selectedMainExpenses = this.selectedMainExpenses.filter(
        (a) => a['mainexpid'] !== mainId
      );
      this.selectedsubExpenses = this.selectedsubExpenses.filter(
        (a) => a['mainexpid'] !== mainId
      );

      this.totalAmount();
    }
  }

  removeMainExp1(mainId) {
    if (confirm('Do you really want to remove?')) {
      // console.log(mainId);
      this.selectedMainExpenses1 = this.selectedMainExpenses1.filter(
        (a) => a['mainexpid'] !== mainId
      );
      this.selectedsubExpenses1 = this.selectedsubExpenses1.filter(
        (a) => a['mainexpid'] !== mainId
      );

      this.totalAmount();
    }
  }

  removeSubExp(subId) {
    if (confirm('Do you really want to remove?')) {
      // console.log(subId);
      let data = this.selectedsubExpenses.find((a) => a['subexpid'] === subId);
      // console.log(data);
      this.selectedMainExpenses.map((exp) => {
        if (exp['mainexpid'] === data['mainexpid']) {
          exp['expamt'] =
            parseFloat(exp['expamt']) - parseFloat(data['expamt']);
        }
      });
      // console.log(this.selectedMainExpenses);

      this.selectedsubExpenses = this.selectedsubExpenses.filter(
        (a) => a['subexpid'] !== subId
      );

      this.totalAmount();
    }
  }

  removeSubExp1(subId) {
    if (confirm('Do you really want to remove?')) {
      // console.log(subId);
      let data = this.selectedsubExpenses1.find((a) => a['subexpid'] === subId);
      // console.log(data);
      this.selectedMainExpenses1.map((exp) => {
        if (exp['mainexpid'] === data['mainexpid']) {
          exp['expamt'] =
            parseFloat(exp['expamt']) - parseFloat(data['expamt']);
        }
      });
      // console.log(this.selectedMainExpenses);

      this.selectedsubExpenses1 = this.selectedsubExpenses1.filter(
        (a) => a['subexpid'] !== subId
      );

      this.totalAmount();
    }
  }

  totalAmount() {
    this.expamt = 0;
    this.selectedMainExpenses.map((exp) => {
      this.expamt = this.expamt + parseFloat(exp['expamt']);
    });
    this.creditvoucherForm.get('totalexp').setValue(this.expamt);
    if (this.total_amt != 0) {
      // console.log(this.total_amt);

      this.creditvoucherForm
        .get('ndeposit')
        .setValue(this.total_amt - this.expamt);
    }
  }

  debitsubexpense = true;
  debitmainexpId = 0;
  debitmainexpName = '';
  getDebitMainExpName(event) {
    
    for (let index = 0; index < Object.keys(this.mainExpData).length; index++) {
      if (this.mainExpData[index]['id'] === event.value) {
        this.debitmainexpId = this.mainExpData[index]['id'];
        this.debitmainexpName = this.mainExpData[index]['exp_name'];
      }
    }
    // console.log(event.value, this.mainExpData);
    this.service.getSubExpenseLedgerWithId(event.value).subscribe((res) => {
      this.getDebitSubExpData = res;
      
      // console.log(this.getSubExpData);
      if (Object.keys(this.getDebitSubExpData).length > 0) {
        this.debitsubexpense = true;
      } else {
        this.debitsubexpense = false;
      }
    });
  }

  debitsub_expId = 0;
  debitsub_expName = '';
  getDebitSubExpName(event) {
    // console.log(this.getDebitSubExpData, event.value);

    for (
      let index = 0;
      index < Object.keys(this.getDebitSubExpData).length;
      index++
    ) {
      if (this.getDebitSubExpData[index]['sub_id'] === event.value) {
        // console.log(this.getDebitSubExpData[index]);
        this.debitsub_expId = this.getDebitSubExpData[index]['sub_id'];
        this.debitsub_expName = this.getDebitSubExpData[index]['sub_exp_name'];
      }
    }
  }

  selecteddebitsubExpenses = [];
  selecteddebitMainExpenses = [];
  addDebitExpense() {
    if (this.debitsubexpense) {
      this.selecteddebitsubExpenses.push({
        mainexpname: this.debitmainexpName,
        narration : this.debitForm.value.othername,

        mainexpid: this.debitmainexpId,
        subexpname: this.debitsub_expName,
        subexpid: this.debitsub_expId,
        expamt: this.debitForm.get('expamt').value, 
      });

      if (Object.keys(this.selecteddebitMainExpenses).length === 0) {
        this.selecteddebitMainExpenses.push({
          mainexpname: this.debitmainexpName,
          narration : this.debitForm.value.othername,
          mainexpid: this.debitmainexpId,
          expamt: this.debitForm.get('expamt').value,
        });
      } else {
        let flag = false;
        for (
          let index = 0;
          index < Object.keys(this.selecteddebitMainExpenses).length;
          index++
        ) {
          if (
            this.selecteddebitMainExpenses[index]['mainexpid'] ===
            this.debitmainexpId
          ) {
            this.selecteddebitMainExpenses[index]['expamt'] =
              parseFloat(this.selecteddebitMainExpenses[index]['expamt']) +
              parseFloat(this.debitForm.get('expamt').value);
            flag = true;
            break;
          } else {
            console.log('else is running', flag);
          }
        }
        if (!flag) {
          this.selecteddebitMainExpenses.push({
            mainexpname: this.debitmainexpName,
            narration : this.debitForm.value.othername,
            mainexpid: this.debitmainexpId,
            expamt: this.debitForm.get('expamt').value,
          });
        }
      }
      this.debitsub_expName = '';
      this.debitsub_expId = 0;
      this.debitForm.get('subexpname').reset();
      this.debitsubexpense = false;
    } else {
      this.selecteddebitMainExpenses.push({
        mainexpname: this.debitmainexpName,
        narration : this.debitForm.value.othername,
        mainexpid: this.debitmainexpId,
        expamt: this.debitForm.get('expamt').value,
      });
    }

    this.debitmainexpName = '';
    this.debitmainexpId = 0;
    this.debitForm.get('expname').reset();
    this.debitForm.get('expamt').reset();
    this.debitTotalAmount();
  }

  // debitTotalAmount() {
  //   this.expdamt = 0;
  //   let amt = parseFloat(this.debitForm.get('totalexp').value || 0); 
  //   this.selecteddebitMainExpenses.map((exp) => {
  //     this.expdamt = this.expdamt + parseFloat(exp['expamt']) + parseFloat(amt);
  //   });
  //   this.debitForm.get('totalexp').setValue(this.expdamt);
  // }

  debitTotalAmount() {
    this.expdamt = 0;
    let amt = parseFloat(this.debitForm.get('totalexp').value || 0); 
  
    this.selecteddebitMainExpenses.map((exp) => {
      this.expdamt += parseFloat(exp['expamt']) || 0; 
    });
    if(this.debitlistactive === true){
      this.expdamt += amt;
    }
    this.debitForm.get('totalexp').setValue(this.expdamt); 
  }
  removeDebitMainExp(mainId) {
    if (confirm('Do you really want to remove?')) {
      // console.log(mainId);
      this.selecteddebitMainExpenses = this.selecteddebitMainExpenses.filter(
        (a) => a['mainexpid'] !== mainId
      );
      this.selecteddebitsubExpenses = this.selecteddebitsubExpenses.filter(
        (a) => a['mainexpid'] !== mainId
      );

      this.debitTotalAmount();
    }
  }

  removeDebitSubExp(subId) {
    if (confirm('Do you really want to remove?')) {
      // console.log(subId);
      let data = this.selecteddebitsubExpenses.find(
        (a) => a['subexpid'] === subId
      );
      // console.log(data);
      this.selecteddebitMainExpenses.map((exp) => {
        if (exp['mainexpid'] === data['mainexpid']) {
          exp['expamt'] =
            parseFloat(exp['expamt']) - parseFloat(data['expamt']);
        }
      });
      // console.log(this.selecteddebitMainExpenses);

      this.selecteddebitsubExpenses = this.selecteddebitsubExpenses.filter(
        (a) => a['subexpid'] !== subId
      );

      this.debitTotalAmount();
    }
  }
  generateCreditorsdate(event){
    let newpb = 1;
    let today = new Date(event.target.value);
    let voucherNo = `PB${('0' + (today.getMonth() + 1)).slice(-2)}${(
      '0' + today.getDate()
    ).slice(-2)}${newpb}`;
    this.kharedi.get('creditorsnum').setValue(voucherNo);

    console.log(today);
  }

  addItem() {
    this.items.push({
      expense: '',
      subExpense: '',
      netAmount: 0,
      totalAmount: 0
    });
  }
  addItem1() {
    this.items1.push({
      expense: '',
      subExpense: '',
      amt: 0,
      amt_status: '',
      entry_date:this.jdate,
      journal_voucher:this.jnumber,
      narration : this.journalvoucher.value.narration

    });
  }
  getnarration(event:any){
    
    // this.narrationname = event.value;
    for (
      let index = 0;
      index < Object.keys(this.getSubExpData).length;
      index++
    )
    if (this.getSubExpData[index]['sub_id'] === event.value) {
      // console.log(this.getSubExpData[index]);
      // this.sub_expId = this.getSubExpData[index]['sub_id'];
      this.sub_expName = this.getSubExpData[index]['sub_exp_name'];
      this.sub_expName=this.sub_expName+" यांसकडून खरेदी "
      this.kharedi.patchValue({
        narration:this.sub_expName
      })
    }
    
    console.log( this.sub_expName , "narration name")
  }

  //testing purpose created this function
  // test() {
  //   console.log(this.selectedMainExpenses);
  //   console.log(this.selectedsubExpenses);
  //   console.log('***************************');

  //   this.selectedMainExpenses.map((exp) => {
  //     if (exp['isUpdated'] !== 'N') {
  //       console.log(exp);
  //       let data = {
  //         id: exp.id,
  //         mainexpid: exp.exp_id,
  //         expamt: exp.expamt,
  //       };

  //       this.service.updateCreditMainExpense(data).subscribe((res) => {
  //         console.log(res);
  //         let subData = this.selectedsubExpenses.filter(
  //           (a) => a['exp_id'] === data['mainexpid']
  //         );
  //         console.log(subData);

  //         if (Object.keys(subData).length > 0) {
  //           subData.map((subexp) => {
  //             if (subexp['isUpdated'] !== 'N') {
  //               console.log(subexp);
  //               let subexpdata = {
  //                 id: subexp['id'],
  //                 subexpid: subexp['sub_exp_id'],
  //                 expamt: subexp['expamt'],
  //               };
  //               this.service
  //                 .updateCreditSubExpense(subexpdata)
  //                 .subscribe((data) => {
  //                   console.log(data);
  //                 });
  //             }
  //           });
  //         }
  //       });
  //     }
  //   });
  // }
  totalAmounts:any=0

  calTotal(event:any,item:any){
    
    item.totalAmount=event.target.value
    this.totalAmounts=0
    this.items.forEach(element => {
      this.totalAmounts = this.totalAmounts+element.netAmount  
    });
  console.log(this.items,   item.totalAmount)
  }
  getTotal(type: string): number {
    return this.items.reduce((sum, item) => sum + (item[type] || 0), 0);
  }


  save_creditors(){
    
    
    this.service.creditorsbookingnum(this.kharedi.value.voucherdate).subscribe((data) => {
      this.creditornumber = data;
      console.log(this.creditornumber , "new pb number")
      

      let req:any  = {
        creditornumber: this.creditornumber.new_creditor_number,
        main_exp : this.kharedi.value.expnameother,
        sub_exp : this.kharedi.value.subexpnameother,
        voucher_date : this.kharedi.value.voucherdate,
        total_amt :  this.kharedi.value.totalamount,
        bill_no :  this.kharedi.value.billno,
        narration :  this.kharedi.value.narration,
      }
      
      this.service.savecreditorsdetail(req).subscribe((data) => {
        this.insertid = data;
        console.log(this.insertid , "new id")
        console.log(this.items , "items id")

        this.items.forEach(element => {
          element.linkid= this.insertid.id
        });
        this.service.savecreditorsdetail1(this.items).subscribe((data) => {
          this.insertid = data;
           
        }       
        );
        // location.reload(); 
        this.kharedi.reset();
        this.addItem();

    });
    });
    
  }
  delete_creditvoucher(){
    this.deletevoucher_no= this.creditvoucherForm.get('vnumber').value,

    console.log( this.deletevoucher_no)

    this.service.checkrepforcredit( this.deletevoucher_no).subscribe((data) => {
    this.checkedstatus = data.status
    console.log( this.checkedstatus)

    if(this.checkedstatus == false){
      this.toaster.success('success', 'Credit voucher is deleted');

       this.service.deletecrvoucher( this.crdt_voucher_id).subscribe((data) => {
    });
    }
    else{
      this.toaster.error('error', 'Sorry, Credit voucher is already used. Cannot delete credit voucher');
    }


    });

    // this.service.deletecrvoucher( this.crdt_voucher_id).subscribe((data) => {
    // });
  }
  onTabClick(event:any){
    console.log(event)
    localStorage.setItem("tab", event.index)
  }

  creditors_report(){
       this.dialog.open(CreditorsreportComponent, {
      data: {
        startDate: this.expenReportStartDate,
        endDate: this.expenReportEndDate,
      },
      width: '1200px',
      height: '1300px',
      maxWidth: '100%',
    });
  }
  jdate:any
  generateJvocuhernum(event){
    this.jdate =  formatDate(event.target.value, 'yyyy-MM-dd', 'en-IN', 'GMT+0530');
    // const formattedDate = format(this.jdate, 'yyyy-MM-dd');
    console.log(this.jdate, "date format")
    this.service.getJournalVoucherNumber(this.jdate).subscribe((res) => {
      // console.log(res);
      this.journalvouchernumber = res;
      this.jnumber = this.journalvouchernumber.new_journal_number
      // console.log(this.jnumber, "date format")
      this.journalvoucher.get('jvouchernum').setValue(this.jnumber);
    });
    debugger;
  }

  calTotal1(event:any,item:any){
    
    item.amt=event.target.value
  console.log(this.items,   item.totalAmount)
  }

  selectstatus(event:any, item:any){
    item.amt_status = event.value
    debugger;
  }

  save_jvocuher(){
    console.log(this.items1)

    let list:any=[]

    this.items1.forEach(element => {
      const data:any={
        amt:element.amt,
        expense:element.expense,
        subExpense:element.subExpense,
        amt_status:element.amt_status,
        journal_voucher:element.journal_voucher,
        entry_date:element.entry_date,
        narration:element.narration
      }
      list.push(data);
    });

    this.service.getsavejournal(list).subscribe((res) => {
      // console.log(res);
      this.journaldata = res;
    });
    this.ngOnInit();
  }
  selectJournal(exp) {
    // console.log(exp)
    this.dialog.open(UpdatejournalvoucherComponent, {
      data: {
        data: exp,
      },
      
      width: '600px',
      height: '400px',
      maxWidth: '100%',
      
    });
  }
  deletejvoucher(id){
console.log(id)
this.service.getdeletejvoucher(id).subscribe((res) => {
  // console.log(res);
  this.journaldata = res;
});
this.toaster.error('error', 'Journal Voucher Delete Succesfully');
this.ngOnInit()
  }

  canclecheque(credit_voucher_id,voucher_no,cheque){
    this.service.changestatus(credit_voucher_id,voucher_no,cheque).subscribe((res) => {
     
    });

    this.service.changestatusadvt_sub(credit_voucher_id,voucher_no,cheque).subscribe((res) => {
    });



  }
}

// SELECT a.pkg_id as pkg_id, a.pkg_name as pkg_name, a.pkg_start_date as pkg_start_date, a.pkg_end_date as pkg_end_date, a.pkg_amt as pkg_amt, a.periodicity as periodicity,  b.vivek_m_1_concession_amt as vivek_m_1_concession_amt, b.vivek_h_1_actual_amt as vivek_h_1_actual_amt, b.vivek_h_1_concession_amt as vivek_h_1_concession_amt, b.vaidyraj_1_actual_amt as vaidyraj_1_actual_amt, b.vaidyraj_1_concession_amt as vaidyraj_1_concession_amt, b.granth1_1_actual_amt as granth1_1_actual_amt, b.granth1_1_concession_amt as granth1_1_concession_amt, b.granth2_1_actual_amt as granth2_1_actual_amt, b.granth2_1_concession_amt as granth2_1_concession_amt, b.donation_1_actual_amt as donation_1_actual_amt, b.donation_1_concession_amt as donation_1_concession_amt, b.advt_1_actual_amt as advt_1_actual_amt, b.advt_1_concession_amt as advt_1_concession_amt, b.courier_1_actual_amt as courier_1_actual_amt, b.courier_1_concession_amt as courier_1_concession_amt, b.other_1_actual_amt as other_1_actual_amt, b.other_1_concession_amt as other_1_concession_amt from Package_Details a Join package_details_of_1_year b ON a.pkg_id=b.pkg_id;
