import { DatePipe } from '@angular/common';
import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DigisubservicesService } from 'src/app/services/digisubservices.service';
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-registration-report',
  templateUrl: './registration-report.component.html',
  styleUrls: ['./registration-report.component.scss'],
})
export class RegistrationReportComponent implements OnInit {
  isLoading: boolean = false;
  type;
  start_date = '';
  end_date = '';
  rep_name = '';
  off_rep_name = '';
  issue_cat = '';
  issue_date = '';
  newindetails: boolean = false;
  indetail: boolean = false;
  brfly: boolean = false;
  verybrfly: boolean = false;
  datewise: boolean = false;
  officerep: boolean = false;
  inDetailsRegReport = [];
  inDetailsRegReportTotal = {
    total_advt: 0,
    advt_total: 0,
    net_amt: 0,
    gst: 0,
    total_amt: 0,
    rcvd_amt: 0,
    pndg_amt: 0,
  };
  inDetailsOffRegReportTotal = {
    total_advt: 0,
    advt_total: 0,
    net_amt: 0,
    gst: 0,
    total_amt: 0,
    rcvd_amt: 0,
    pndg_amt: 0,
  };
  tempdata = [];
  inDetailsRegReportsubTotalData = [];
  today;
  issue_name = '';
  issueDate;
  inDetailsRegReport1: any;
  advtamt: number=0;
  netadvtamt: number=0;
  gstadvtamt: number=0;
  totaladvtamt: number=0;
  totalpendingadvtamt: number=0;
  totalgst: number=0;
  today1: string;
  issuenamewithdate: any;
  remaingtotal: number=0;
  jamatotal: number=0;
  inDetailsbriflydata: any;
  advtamtb: number=0;
  netadvtamtb: number=0;
  jamatotalb: number=0;
  remaingtotalb:number=0;
  inDetailsoff_repdata: any;
  inDetailsoff_repdatab: any;
  inDetailsRegReportrep: any;
  beforegst_amt: number;
  totalbeforegst_amt: number;
  totaldisc_total: number;
  totalbefore_gst: any;
  inDetailsRegReportbtoff: any;
  total_pending: any;
  inDetailsRegReportdatewise: any;
  showindetail: boolean = false;
  showindetaildatewise: boolean = false;
  showoffdatewise: boolean = false;
  showdatewisevrybrfly: boolean = false;
  showoffrepvrybrfly: boolean = false;
  showindetailoffrep: boolean = false;


  constructor(
    private dialogref: MatDialogRef<RegistrationReportComponent>,
    private datePipe: DatePipe,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private service: DigisubservicesService
  ) {}
  newissuedatepp:any
  ngOnInit(): void {
    
  
    this.type = this.data['type'];
    this.start_date = this.data['start_date'];
    this.end_date = this.data['end_date'];
    this.rep_name = this.data['rep_name'];
    this.off_rep_name = this.data['off_rep_name'];
    this.newissuedatepp=this.data['newissuedatepp']
    debugger
    this.issue_cat =
      this.data['issue_cat'] === undefined ? '' : this.data['issue_cat'];
      debugger
    this.issue_date =
      this.data['issueDates'] === undefined ? '' : this.data['issueDates'];      
    console.log(this.data);
    this.issueDate = this.issue_date + '';
    this.issue_name =
      this.issueDate.split(',')[0] == 'all'
        ? this.issueDate.split(',')[2]
        : this.issueDate.split(',')[1];
    this.issueDate =
      this.issueDate.split(',')[0] == 'all'
        ? this.issueDate.split(',')[1]
        : this.issueDate.split(',')[0];
    this.issueDate = new Date(
      `${this.issueDate.split('/')[1]}/${this.issueDate.split('/')[0]}/${
        this.issueDate.split('/')[2]
      }`
    );
    this.today = new Date();
    this.today1 = this.datePipe.transform(this.today, 'dd-MM-yyyy');

debugger
    // this.type.forEach((type) => {
    //   if (type === 'InDetail') {
    //     this.indetail = true;
    //     if (this.off_rep_name !== '') {
    //       this.officerep = true;
    //     }
    //   } else if (type === 'Briefly') {
    //     this.brfly = true;
    //   } else if (type === 'Very Briefly') {
    //     this.verybrfly = true;
    //     if (this.off_rep_name !== '') {
    //       this.officerep = true;
    //     }
    //   } else if (type === 'Datewise') {
    //     this.datewise = true;
    //     this.verybrfly = true;
    //   }
    // });
   
    
 

    
    this. getReportData();
    if (this.indetail && this.datewise && !this.officerep) {
      this.inDetailsDatewise();
    } else if (this.datewise && this.verybrfly) {
      this.datewiseVeryBriefly();
    } else if (this.indetail && this.officerep && !this.datewise) {
      this.inDetailsOffRep();
    } else if (this.brfly && this.officerep) {
      // this.inDetails();
    } else if (this.indetail && !this.datewise && !this.officerep) {
      this.inDetails();
    } else if (this.verybrfly && this.officerep) {
      this.offrepVeryBriefly();
    }else{
      this.getReportData()
    }
  }
  getRegReportData() {
    throw new Error('Method not implemented.');
  }

  exportexcel(fileName): void {
    /* table id is passed over here */
    let element = document.getElementById('excel-table');
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* save to file */
    XLSX.writeFile(wb, fileName + '.xlsx');
  }
  
  filteredinDetailsRegReport;
  addFilters() {
   
    // console.log('call addFilters');

    if (
      this.start_date != '' &&
      this.end_date != '' &&
      this.rep_name != '' &&
      this.off_rep_name != '' &&
      this.issue_cat != '' &&
      this.issue_date != ''
    ) {
      // console.log('all Filters');
      this.filteredinDetailsRegReport = this.inDetailsRegReport.filter((a) => {
        return (
          a['booking_date'] >= this.start_date &&
          a['booking_date'] <= this.end_date &&
          this.rep_name.includes(a['rep_name']) &&
          this.off_rep_name.includes(a['off_rep_name']) &&
          a['issuename'] === this.issue_cat &&
          this.issue_date.includes(a['issue_date'])
        );
      });
      console.log(this.filteredinDetailsRegReport,"kjl");
    } else if (
      this.start_date != '' &&
      this.end_date != '' &&
      this.rep_name == '' &&
      this.off_rep_name != '' &&
      this.issue_cat != '' &&
      this.issue_date != ''
    ) {
      console.log('excluded repname Filters');
      this.filteredinDetailsRegReport = this.inDetailsRegReport.filter((a) => {
        return (
          a['booking_date'] >= this.start_date &&
          a['booking_date'] <= this.end_date &&
          this.off_rep_name.includes(a['off_rep_name']) &&
          a['issuename'] === this.issue_cat &&
          this.issue_date.includes(a['issue_date'])
        );
      });
      // console.log(this.filteredinDetailsRegReport);
    } else if (
      this.start_date != '' &&
      this.end_date != '' &&
      this.rep_name != '' &&
      this.off_rep_name == '' &&
      this.issue_cat != '' &&
      this.issue_date != ''
    ) {
      // console.log('excluded offrepname Filters');
      this.filteredinDetailsRegReport = this.inDetailsRegReport.filter((a) => {
        return (
          a['booking_date'] >= this.start_date &&
          a['booking_date'] <= this.end_date &&
          this.rep_name.includes(a['rep_name']) &&
          a['issuename'] === this.issue_cat &&
          this.issue_date.includes(a['issue_date'])
        );
      });
      // console.log(this.filteredinDetailsRegReport);
    } else if (
      this.start_date != '' &&
      this.end_date != '' &&
      this.rep_name != '' &&
      this.off_rep_name != '' &&
      this.issue_cat == '' &&
      this.issue_date == ''
    ) {
      // console.log('excluded offrepname Filters');
      this.filteredinDetailsRegReport = this.inDetailsRegReport.filter((a) => {
        return (
          a['booking_date'] >= this.start_date &&
          a['booking_date'] <= this.end_date &&
          this.rep_name.includes(a['rep_name']) &&
          this.off_rep_name.includes(a['off_rep_name'])
        );
      });
      // console.log(this.filteredinDetailsRegReport);
    } else if (
      this.start_date != '' &&
      this.end_date != '' &&
      this.rep_name == '' &&
      this.off_rep_name == '' &&
      this.issue_cat != '' &&
      this.issue_date != ''
    ) {
      // console.log('excluded repname and offrepname Filters');
      this.filteredinDetailsRegReport = this.inDetailsRegReport.filter((a) => {
        return (
          a['booking_date'] >= this.start_date &&
          a['booking_date'] <= this.end_date &&
          a['issuename'] === this.issue_cat &&
          this.issue_date.includes(a['issue_date'])
        );
      });
      // console.log(this.filteredinDetailsRegReport);
    } else if (
      this.start_date != '' &&
      this.end_date != '' &&
      this.rep_name == '' &&
      this.off_rep_name != '' &&
      this.issue_cat == '' &&
      this.issue_date == ''
    ) {
      // console.log('all Filters');
      this.filteredinDetailsRegReport = this.inDetailsRegReport.filter((a) => {
        return (
          a['booking_date'] >= this.start_date &&
          a['booking_date'] <= this.end_date &&
          this.off_rep_name.includes(a['off_rep_name'])
        );
      });
      // console.log(this.filteredinDetailsRegReport);
    } else if (
      this.start_date != '' &&
      this.end_date != '' &&
      this.rep_name != '' &&
      this.off_rep_name == '' &&
      this.issue_cat == '' &&
      this.issue_date == ''
    ) {
      // console.log('all Filters');
      this.filteredinDetailsRegReport = this.inDetailsRegReport.filter((a) => {
        return (
          a['booking_date'] >= this.start_date &&
          a['booking_date'] <= this.end_date &&
          this.rep_name.includes(a['rep_name'])
        );
      });
      // console.log(this.filteredinDetailsRegReport);
    } else if (
      this.start_date != '' &&
      this.end_date != '' &&
      this.rep_name == '' &&
      this.off_rep_name == '' &&
      this.issue_cat == '' &&
      this.issue_date == ''
    ) {
      console.log('just datewise Filters');
      this.filteredinDetailsRegReport = this.inDetailsRegReport.filter((a) => {
        return (
          a['booking_date'] >= this.start_date &&
          a['booking_date'] <= this.end_date
        );
      });
      console.log(this.filteredinDetailsRegReport);
    } else if (
      this.start_date == '' &&
      this.end_date == '' &&
      this.rep_name != '' &&
      this.off_rep_name != '' &&
      this.issue_cat != '' &&
      this.issue_date != ''
    ) {
      // console.log('all Filters');
      this.filteredinDetailsRegReport = this.inDetailsRegReport.filter((a) => {
        return (
          this.rep_name.includes(a['rep_name']) &&
          this.off_rep_name.includes(a['off_rep_name']) &&
          a['issuename'] === this.issue_cat &&
          this.issue_date.includes(a['issue_date'])
        );
      });
      // console.log(this.filteredinDetailsRegReport);
    } else if (
      this.start_date == '' &&
      this.end_date == '' &&
      this.rep_name == '' &&
      this.off_rep_name != '' &&
      this.issue_cat != '' &&
      this.issue_date != ''
    ) {
      // console.log('all Filters');
      this.filteredinDetailsRegReport = this.inDetailsRegReport.filter((a) => {
        return (
          this.off_rep_name.includes(a['off_rep_name']) &&
          a['issuename'] === this.issue_cat &&
          this.issue_date.includes(a['issue_date'])
        );
      });
      // console.log(this.filteredinDetailsRegReport);
    } else if (
      this.start_date == '' &&
      this.end_date == '' &&
      this.rep_name != '' &&
      this.off_rep_name == '' &&
      this.issue_cat != '' &&
      this.issue_date != ''
    ) {
      // console.log('all Filters');
      this.filteredinDetailsRegReport = this.inDetailsRegReport.filter((a) => {
        return (
          this.rep_name.includes(a['rep_name']) &&
          a['issuename'] === this.issue_cat &&
          this.issue_date.includes(a['issue_date'])
        );
      });
      // console.log(this.filteredinDetailsRegReport);
    } else if (
      this.start_date == '' &&
      this.end_date == '' &&
      this.rep_name != '' &&
      this.off_rep_name != '' &&
      this.issue_cat == '' &&
      this.issue_date == ''
    ) {
      // console.log('all Filters');
      this.filteredinDetailsRegReport = this.inDetailsRegReport.filter((a) => {
        return (
          this.rep_name.includes(a['rep_name']) &&
          this.off_rep_name.includes(a['off_rep_name'])
        );
      });
      // console.log(this.filteredinDetailsRegReport);
    } else if (
      this.start_date == '' &&
      this.end_date == '' &&
      this.rep_name == '' &&
      this.off_rep_name == '' &&
      this.issue_cat != '' &&
      this.issue_date != ''
    ) {
      // console.log('all Filters');
      this.filteredinDetailsRegReport = this.inDetailsRegReport.filter((a) => {
        return (
          a['issuename'] === this.issue_cat &&
          this.issue_date.includes(a['issue_date'])
        );
      });
      // console.log(this.filteredinDetailsRegReport);
    } else if (
      this.start_date == '' &&
      this.end_date == '' &&
      this.rep_name == '' &&
      this.off_rep_name != '' &&
      this.issue_cat == '' &&
      this.issue_date == ''
    ) {
      // console.log('all Filters');
      this.filteredinDetailsRegReport = this.inDetailsRegReport.filter((a) => {
        return this.off_rep_name.includes(a['off_rep_name']);
      });
      // console.log(this.filteredinDetailsRegReport);
    } else if (
      this.start_date == '' &&
      this.end_date == '' &&
      this.rep_name != '' &&
      this.off_rep_name == '' &&
      this.issue_cat == '' &&
      this.issue_date == ''
    ) {
      // console.log('all Filters');
      this.filteredinDetailsRegReport = this.inDetailsRegReport.filter((a) => {
        return this.rep_name.includes(a['rep_name']);
      });
      // console.log(this.filteredinDetailsRegReport);
    } else if (
      this.start_date == '' &&
      this.end_date == '' &&
      this.rep_name == '' &&
      this.off_rep_name != '' &&
      this.issue_cat == '' &&
      this.issue_date == ''
    ) {
      // console.log('all Filters');
      this.filteredinDetailsRegReport = this.inDetailsRegReport.filter((a) => {
        return this.off_rep_name.includes(a['off_rep_name']);
      });
      // console.log(this.filteredinDetailsRegReport);
    } else if (
      this.start_date == '' &&
      this.end_date == '' &&
      this.rep_name == '' &&
      this.off_rep_name == '' &&
      this.issue_cat != '' &&
      this.issue_date != ''
    ) {
      // console.log('all Filters');
      this.filteredinDetailsRegReport = this.inDetailsRegReport.filter((a) => {
        return (
          a['issuename'] === this.issue_cat &&
          this.issue_date.includes(a['issue_date'])
        );
      });
      // console.log(this.filteredinDetailsRegReport);
    } else if (
      this.start_date == '' &&
      this.end_date == '' &&
      this.rep_name == '' &&
      this.off_rep_name == '' &&
      this.issue_cat == '' &&
      this.issue_date == ''
    ) {
      // console.log('all Filters');
      this.filteredinDetailsRegReport = this.inDetailsRegReport;
      // console.log(this.filteredinDetailsRegReport);
    }
    console.log(this.filteredinDetailsRegReport);
    this.filtersCalculateData();
  }

  filtersCalculateData() {
    this.inDetailsRegReportTotal.total_advt =
      this.filteredinDetailsRegReport.length;
    for (
      let index = 0;
      index < this.filteredinDetailsRegReport.length;
      index++
    ) {
      this.inDetailsRegReportTotal.net_amt += parseFloat(
        this.filteredinDetailsRegReport[index]['total_amount']
      );
      this.inDetailsRegReportTotal.advt_total += parseFloat(
        this.filteredinDetailsRegReport[index]['advt_amt']
      );
      this.inDetailsRegReportTotal.gst += parseFloat(
        this.filteredinDetailsRegReport[index]['sgst']
      );
      this.inDetailsRegReportTotal.gst += parseFloat(
        this.filteredinDetailsRegReport[index]['cgst']
      );
      this.inDetailsRegReportTotal.total_amt += parseFloat(
        this.filteredinDetailsRegReport[index]['amount']
      );
      this.inDetailsRegReportTotal.rcvd_amt += parseFloat(
        this.filteredinDetailsRegReport[index]['part_pymt_amt']
      );
      this.inDetailsRegReportTotal.pndg_amt += parseFloat(
        this.filteredinDetailsRegReport[index]['part_pymt_remaning_amt']
      );
    }
  }

  getReportData() {
    
    const req1:any = {
   
      "start_date":this.start_date,
      "end_date":this.end_date,
      "rep_name" : this.rep_name,
      "off_rep_name":this.off_rep_name,
      "issuename":this.issue_cat,
      "issue_date" : this.issue_date
    }
    
    this.service.getRegReportInDetails1(req1).subscribe((data) => {
      debugger
      this.inDetailsRegReport = data;
      this.inDetailsRegReport1 = data;
      this.issuenamewithdate = this.inDetailsRegReport1.issue_date;
      console.log(this.inDetailsRegReport1,  "reg report")
      debugger

      this.advtamt =0;
      this.netadvtamt=0;
      this.gstadvtamt=0;
      this.totaladvtamt=0;
      this.totalgst =0;
      this.remaingtotal = 0;
      this.totalpendingadvtamt=0;
      this.jamatotal =0;
      this.totalbeforegst_amt = 0;
      this.totaldisc_total = 0;
      for(let data1 of this.inDetailsRegReport1){
        debugger;
        this.advtamt += parseFloat(data1.advt_amt) || 0;
        this.netadvtamt += parseFloat(data1.amount) || 0;
        this.totaladvtamt += parseFloat(data1.amount) || 0;
        // data1.remaingamt = Number(data1.amount) - Number(data1.part_pymt_amt)
        this.remaingtotal += parseFloat(data1.remaingamt) || 0;
        this.totalbeforegst_amt += parseFloat(data1.beforegst_amt) || 0;
        this.jamatotal += parseFloat(data1.pymt_rcv_amt) || 0;
        this.totaldisc_total += parseFloat(data1.disc_amt) || 0;

        
        this.totalpendingadvtamt += parseFloat(data1.part_pymt_remaning_amt) || 0;
        data1.totalgst = parseFloat(data1.sgst) + parseFloat(data1.cgst) + parseFloat(data1.igst)
        this.totalgst +=  parseFloat(data1.totalgst)
      }
      console.log(this.totalbeforegst_amt,"before gst")

      console.log(this.inDetailsRegReport, "indeatils");
      this.addFilters();
      this.getReportDatewise();
      
      // this.getReportDatewise();
    });

// off indetails
this.service.getRegReportInDetailsoff(req1).subscribe((data) => {
  this.inDetailsRegReport = data;
  this.inDetailsRegReportbtoff = data;
  this.issuenamewithdate = this.inDetailsRegReport1.issue_date;
  console.log(this.inDetailsRegReport1,  "reg report")

  this.advtamt =0;
  this.netadvtamt=0;
  this.gstadvtamt=0;
  this.totaladvtamt=0;
  this.totalgst =0;
  this.remaingtotal = 0;
  this.totalpendingadvtamt=0;
  this.jamatotal =0;
  this.totalbeforegst_amt = 0;
  this.totaldisc_total = 0;
  for(let data1 of this.inDetailsRegReport1){
    debugger;
    this.advtamt += parseFloat(data1.advt_amt) || 0;
    this.netadvtamt += parseFloat(data1.total_amount) || 0;
    this.totaladvtamt += parseFloat(data1.amount) || 0;
    // data1.remaingamt = Number(data1.amount) - Number(data1.part_pymt_amt)
    this.remaingtotal += parseFloat(data1.remaingamt) || 0;
    this.totalbeforegst_amt += parseFloat(data1.beforegst_amt) || 0;
    this.jamatotal += parseFloat(data1.pymt_rcv_amt) || 0;
    this.totaldisc_total += parseFloat(data1.disc_amt) || 0;

    
    this.totalpendingadvtamt += parseFloat(data1.part_pymt_remaning_amt) || 0;
    data1.totalgst = parseFloat(data1.sgst) + parseFloat(data1.cgst) + parseFloat(data1.igst)
    this.totalgst +=  parseFloat(data1.totalgst)
  }
  console.log(this.advtamt,"advt total")

  console.log(this.inDetailsRegReport, "indeatils");
  this.addFilters();
  this.getReportDatewise();
  
  // this.getReportDatewise();
});

    this.service.getRegReportBriefly(req1).subscribe((data) => {
      this.inDetailsRegReport = data;
      this.inDetailsbriflydata = data;
      

      this.advtamtb =0;
      this.netadvtamtb=0;
      this.gstadvtamt=0;
      this.totaladvtamt=0;
      this.totalgst =0;
      this.remaingtotalb = 0;
      this.totalpendingadvtamt=0;
      this.jamatotalb =0;
      for(let data1 of this.inDetailsbriflydata){
        debugger;
        this.advtamtb += parseFloat(data1.advt_amt) || 0;
        this.netadvtamtb += parseFloat(data1.total_amount) || 0;
        debugger;
        this.totaladvtamt += parseFloat(data1.amount) || 0;
        // data1.remaingamt = Number(data1.amount) - Number(data1.part_pymt_amt)
        this.remaingtotalb += parseFloat(data1.remaingamt) || 0;
        this.jamatotalb += parseFloat(data1.pymt_rcv_amt) || 0;
        
        this.totalpendingadvtamt += parseFloat(data1.part_pymt_remaning_amt) || 0;
        // this.totalgst += parseFloat(data1.gst)
        this.totalgst +=  parseFloat(data1.gst)
      }

      console.log(this.inDetailsRegReport, "indeatils");
      this.addFilters();
      this.getReportDatewise();
      
      // this.getReportDatewise();
    });

    this.service.getRegReportOff_rep(req1).subscribe((data) => {
      this.inDetailsRegReport = data;
      this.inDetailsoff_repdata = data;
      

      this.advtamtb =0;
      this.netadvtamtb=0;
      this.gstadvtamt=0;
      this.totaladvtamt=0;
      this.totalgst =0;
      this.remaingtotalb = 0;
      this.totalpendingadvtamt=0;
      this.jamatotalb =0;
      for(let data1 of this.inDetailsoff_repdata){
       
        this.advtamtb += parseFloat(data1.advt_amt) || 0;
        this.netadvtamtb += parseFloat(data1.amount) || 0;
        this.totaladvtamt += parseFloat(data1.amount) || 0;
        // data1.remaingamt = Number(data1.amount) - Number(data1.part_pymt_amt)
        this.remaingtotalb += parseFloat(data1.remaingamt) || 0;
        // this.jamatotalb += parseFloat(data1.pymt_rcv_amt) || 0;
        
        this.totalpendingadvtamt += parseFloat(data1.part_pymt_remaning_amt) || 0;
        // this.totalgst += parseFloat(data1.gst)
        this.totalgst +=  parseFloat(data1.gst)
        for(let data2 of data1.advt_data){
          debugger;
          this.jamatotalb += parseFloat(data2.rcv_amt) || 0;
        }
      }

      console.log(this.inDetailsRegReport, "indeatils");
      this.addFilters();
      this.getReportDatewise();
      
      // this.getReportDatewise();
    });

    this.service.getRegReportOff_repb(req1).subscribe((data) => {
      this.inDetailsRegReport = data;
      this.inDetailsoff_repdatab = data;
      

      this.advtamtb =0;
      this.netadvtamtb=0;
      this.gstadvtamt=0;
      this.totaladvtamt=0;
      this.totalgst =0;
      this.remaingtotalb = 0;
      this.totalpendingadvtamt=0;
      this.jamatotalb =0;
      for(let data1 of this.inDetailsbriflydata){
        debugger;
        this.advtamtb += parseFloat(data1.advt_amt) || 0;
        this.netadvtamtb += parseFloat(data1.amount) || 0;
        this.totaladvtamt += parseFloat(data1.amount) || 0;
        // data1.remaingamt = Number(data1.amount) - Number(data1.part_pymt_amt)
        this.remaingtotalb += parseFloat(data1.remaingamt) || 0;
        this.jamatotalb += parseFloat(data1.pymt_rcv_amt) || 0;
        
        this.totalpendingadvtamt += parseFloat(data1.part_pymt_remaning_amt) || 0;
        // this.totalgst += parseFloat(data1.gst)
        this.totalgst +=  parseFloat(data1.gst)
      }

      console.log(this.inDetailsRegReport, "indeatils");
      this.addFilters();
      this.getReportDatewise();
      
      // this.getReportDatewise();
    });

    this.service.getRegReportInDetailsrep(req1).subscribe((data) => {
      this.inDetailsRegReport = data;
      this.inDetailsRegReportrep = data;
      this.issuenamewithdate = this.inDetailsRegReport1.issue_date;
      // console.log(this.inDetailsRegReport1,  "reg report")

      this.advtamt =0;
      this.netadvtamt=0;
      this.gstadvtamt=0;
      this.totaladvtamt=0;
      this.totalgst =0;
      this.remaingtotal = 0;
      this.totalpendingadvtamt=0;
      this.jamatotal =0;
      this.totaldisc_total =0;
      this.totalbefore_gst =0;
      this.total_pending =0 
      for(let data2 of this.inDetailsRegReportrep){
        for(let data1 of data2.advt_data){
          this.advtamt += parseFloat(data1.advt_amt) || 0;
          this.netadvtamt += parseFloat(data1.total_amount) || 0;
          this.totaladvtamt += parseFloat(data1.amount) || 0;
          // data1.remaingamt = Number(data1.amount) - Number(data1.part_pymt_amt)
          this.remaingtotal += parseFloat(data1.remaingamt) || 0;
          this.jamatotal += parseFloat(data1.pymt_rcv_amt) || 0;
          this.totaldisc_total += parseFloat(data1.disc_amt) || 0;
          this.totalbefore_gst += parseFloat(data1.beforegst_amt) || 0;    
          this.totalpendingadvtamt += parseFloat(data1.part_pymt_remaning_amt) || 0;
          data1.totalgst = parseFloat(data1.sgst) + parseFloat(data1.cgst) + parseFloat(data1.igst)
          this.totalgst +=  parseFloat(data1.totalgst) || 0;
          this.total_pending += parseFloat(data1.remaining_amt)
        }
       
      }

      console.log(this.total_pending, "total_pending");
      this.addFilters();
      this.getReportDatewise();
      
      // this.getReportDatewise();
    });

    this.service.getRegReportInDetailsdatewise(req1).subscribe((data) => {
      this.inDetailsRegReport = data;
      this.inDetailsRegReportdatewise = data;
      this.issuenamewithdate = this.inDetailsRegReport1.issue_date;
      // console.log(this.inDetailsRegReport1,  "reg report")

      this.advtamt =0;
      this.netadvtamt=0;
      this.gstadvtamt=0;
      this.totaladvtamt=0;
      this.totalgst =0;
      this.remaingtotal = 0;
      this.totalpendingadvtamt=0;
      this.jamatotal =0;
      this.totaldisc_total =0;
      this.totalbefore_gst =0;
      this.total_pending =0 
      for(let data2 of this.inDetailsRegReportrep){
        for(let data1 of data2.advt_data){
          this.advtamt += parseFloat(data1.advt_amt) || 0;
          this.netadvtamt += parseFloat(data1.total_amount) || 0;
          this.totaladvtamt += parseFloat(data1.amount) || 0;
          // data1.remaingamt = Number(data1.amount) - Number(data1.part_pymt_amt)
          this.remaingtotal += parseFloat(data1.remaingamt) || 0;
          this.jamatotal += parseFloat(data1.pymt_rcv_amt) || 0;
          this.totaldisc_total += parseFloat(data1.disc_amt) || 0;
          this.totalbefore_gst += parseFloat(data1.beforegst_amt) || 0;    
          this.totalpendingadvtamt += parseFloat(data1.part_pymt_remaning_amt) || 0;
          data1.totalgst = parseFloat(data1.sgst) + parseFloat(data1.cgst) + parseFloat(data1.igst)
          this.totalgst +=  parseFloat(data1.totalgst) || 0;
          this.total_pending += parseFloat(data1.remaining_amt)
        }
       
      }

      console.log(this.inDetailsRegReportdatewise, "inDetailsRegReportdatewise");
      this.addFilters();
      this.getReportDatewise();
      
      // this.getReportDatewise();
    });
  }

  getReportDatewise() {
    
    if (this.filteredinDetailsRegReport.length > 0) {
      this.tempdata = this.uniqueId(
        this.filteredinDetailsRegReport,
        (a) => a.booking_date
      );
      // console.log('why', tempData);
      for (let index = 0; index < this.tempdata.length; index++) {
        let arr = this.filteredinDetailsRegReport.filter((a) => {
          return a.booking_date == this.tempdata[index]['booking_date'];
        });

        let inDetailsRegReportsubTotal = {
          total_advt: 0,
          advt_total: 0,
          net_amt: 0,
          gst: 0,
          total_amt: 0,
          rcvd_amt: 0,
          pndg_amt: 0,
        };

        inDetailsRegReportsubTotal.total_advt = arr.length;
        debugger
        for (let index = 0; index < arr.length; index++) {
          inDetailsRegReportsubTotal.net_amt += parseFloat(
            arr[index]['total_amount']
          );
          inDetailsRegReportsubTotal.advt_total += parseFloat(
            arr[index]['advt_amt']
          );
          inDetailsRegReportsubTotal.gst += parseFloat(arr[index]['sgst']);
          inDetailsRegReportsubTotal.gst += parseFloat(arr[index]['cgst']);
          inDetailsRegReportsubTotal.total_amt += parseFloat(
            arr[index]['amount']
          );
          inDetailsRegReportsubTotal.rcvd_amt += parseFloat(
            arr[index]['part_pymt_amt']
          );
          inDetailsRegReportsubTotal.pndg_amt += parseFloat(
            arr[index]['part_pymt_remaning_amt']
          );
        }
        arr.push(inDetailsRegReportsubTotal);
        this.inDetailsRegReportsubTotalData.push(arr);
        console.log( this.inDetailsRegReportsubTotalData, "abc")
      }
    }
  }

  inDetails() {
    console.log('inDetails');
    this.getReportData();
  }

  inDetailsDatewise() {
    console.log('inDetailsDatewise');
    this.getReportData();
    // setTimeout(() => {
      this.getReportDatewise();
    // }, 200);
  }

  offrepVeryBriefly() {
    console.log('offrepVeryBriefly');
    this.getReportData();
    setTimeout(() => {
      this.getReportOffRepVeryBriefly();
    }, 200);
  }

  datewiseVeryBriefly() {
    this.getReportData();
    setTimeout(() => {
      this.getReportDatewiseVeryBriefly();
    }, 200);
  }

  inDetailsOffRep() {
    this.getReportData();
    setTimeout(() => {
      this.getReportInDetailOffRep();
    }, 200);
  }

  uniqueId(data, key) {
    return [...new Map(data.map((x) => [key(x), x])).values()];
  }

  toInt(no) {
    return parseFloat(no);
  }

  getReportDatewiseVeryBriefly() {
    if (this.inDetailsRegReport.length > 0) {
      this.tempdata = this.uniqueId(
        this.inDetailsRegReport,
        (a) => a.booking_date
      );
      // console.log('why', tempData);
      for (let index = 0; index < this.tempdata.length; index++) {
        let arr = this.inDetailsRegReport.filter((a) => {
          return a.booking_date == this.tempdata[index]['booking_date'];
        });

        let inDetailsRegReportsubTotal = {
          date: '',
          total_advt: 0,
          advt_total: 0,
          net_amt: 0,
          gst: 0,
          total_amt: 0,
          rcvd_amt: 0,
          pndg_amt: 0,
        };

        inDetailsRegReportsubTotal.total_advt = arr.length;
        inDetailsRegReportsubTotal.date = arr[0]['booking_date'];
        for (let index = 0; index < arr.length; index++) {
          inDetailsRegReportsubTotal.net_amt += parseFloat(
            arr[index]['total_amount']
          );
          inDetailsRegReportsubTotal.advt_total += parseFloat(
            arr[index]['advt_amt']
          );
          inDetailsRegReportsubTotal.gst += parseFloat(arr[index]['sgst']);
          inDetailsRegReportsubTotal.gst += parseFloat(arr[index]['cgst']);
          inDetailsRegReportsubTotal.total_amt += parseFloat(
            arr[index]['amount']
          );
          inDetailsRegReportsubTotal.rcvd_amt += parseFloat(
            arr[index]['part_pymt_amt']
          );
          inDetailsRegReportsubTotal.pndg_amt += parseFloat(
            arr[index]['part_pymt_remaning_amt']
          );
        }
        this.inDetailsRegReportsubTotalData.push(inDetailsRegReportsubTotal);
      }
      // console.log(this.inDetailsRegReportsubTotalData);
    }
  }

  repData = [];
  getReportInDetailOffRep() {
    if (this.inDetailsRegReport.length > 0) {
      this.tempdata = this.inDetailsRegReport.filter((a) => {
        return this.off_rep_name.includes(a.off_rep_name);
      });
      this.calculateTotal(this.tempdata);

      let data = this.uniqueId(this.tempdata, (a) => a.rep_name);

      for (let index = 0; index < Object.keys(data).length; index++) {
        let arr = this.tempdata.filter((a) => {
          return a.rep_name == data[index]['rep_name'];
        });

        let inDetailsRegReportsubTotal = {
          total_advt: 0,
          advt_total: 0,
          net_amt: 0,
          gst: 0,
          total_amt: 0,
          rcvd_amt: 0,
          pndg_amt: 0,
        };

        inDetailsRegReportsubTotal.total_advt = arr.length;
        for (let index = 0; index < arr.length; index++) {
          inDetailsRegReportsubTotal.net_amt += parseFloat(
            arr[index]['total_amount']
          );
          inDetailsRegReportsubTotal.advt_total += parseFloat(
            arr[index]['advt_amt']
          );
          inDetailsRegReportsubTotal.gst += parseFloat(arr[index]['sgst']);
          inDetailsRegReportsubTotal.gst += parseFloat(arr[index]['cgst']);
          inDetailsRegReportsubTotal.total_amt += parseFloat(
            arr[index]['amount']
          );
          inDetailsRegReportsubTotal.rcvd_amt += parseFloat(
            arr[index]['part_pymt_amt']
          );
          inDetailsRegReportsubTotal.pndg_amt += parseFloat(
            arr[index]['part_pymt_remaning_amt']
          );
        }
        arr.push(inDetailsRegReportsubTotal);
        this.inDetailsRegReportsubTotalData.push(arr);
      }
    }
  }

  calculateTotal(data) {
    this.inDetailsOffRegReportTotal.total_advt = data.length;
    for (let index = 0; index < data.length; index++) {
      this.inDetailsOffRegReportTotal.net_amt += parseFloat(
        data[index]['total_amount']
      );
      this.inDetailsOffRegReportTotal.advt_total += parseFloat(
        data[index]['advt_amt']
      );
      this.inDetailsOffRegReportTotal.gst += parseFloat(data[index]['sgst']);
      this.inDetailsOffRegReportTotal.gst += parseFloat(data[index]['cgst']);
      this.inDetailsOffRegReportTotal.total_amt += parseFloat(
        data[index]['amount']
      );
      this.inDetailsOffRegReportTotal.rcvd_amt += parseFloat(
        data[index]['part_pymt_amt']
      );
      this.inDetailsOffRegReportTotal.pndg_amt += parseFloat(
        data[index]['part_pymt_remaning_amt']
      );
    }
  }

  getReportOffRepVeryBriefly() {
    if (this.inDetailsRegReport.length > 0) {
      this.tempdata = this.inDetailsRegReport.filter((a) => {
        return this.off_rep_name.includes(a.off_rep_name);
      });
      this.calculateTotal(this.tempdata);

      let data = this.uniqueId(this.tempdata, (a) => a.rep_name);

      for (let index = 0; index < Object.keys(data).length; index++) {
        let arr = this.tempdata.filter((a) => {
          return a.rep_name == data[index]['rep_name'];
        });

        let inDetailsRegReportsubTotal = {
          off_rep_name: '',
          rep_name: '',
          total_advt: 0,
          advt_total: 0,
          net_amt: 0,
          gst: 0,
          total_amt: 0,
          rcvd_amt: 0,
          pndg_amt: 0,
        };

        inDetailsRegReportsubTotal.total_advt = arr.length;
        for (let index = 0; index < arr.length; index++) {
          inDetailsRegReportsubTotal.off_rep_name = arr[index]['off_rep_name'];
          inDetailsRegReportsubTotal.rep_name = arr[index]['rep_name'];
          inDetailsRegReportsubTotal.net_amt += parseFloat(
            arr[index]['total_amount']
          );
          inDetailsRegReportsubTotal.advt_total += parseFloat(
            arr[index]['advt_amt']
          );
          inDetailsRegReportsubTotal.gst += parseFloat(arr[index]['sgst']);
          inDetailsRegReportsubTotal.gst += parseFloat(arr[index]['cgst']);
          inDetailsRegReportsubTotal.total_amt += parseFloat(
            arr[index]['amount']
          );
          inDetailsRegReportsubTotal.rcvd_amt += parseFloat(
            arr[index]['part_pymt_amt']
          );
          inDetailsRegReportsubTotal.pndg_amt += parseFloat(
            arr[index]['part_pymt_remaning_amt']
          );
        }
        // arr.push(inDetailsRegReportsubTotal);
        this.inDetailsRegReportsubTotalData.push(inDetailsRegReportsubTotal);
      }
    }
  }
}
