import { Component, OnInit, Inject } from '@angular/core';
import { formatDate } from '@angular/common';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DigisubservicesService } from 'src/app/services/digisubservices.service';
import { ToWords } from 'to-words';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-advt-bill',
  templateUrl: './advt-bill.component.html',
  styleUrls: ['./advt-bill.component.scss'],
})
export class AdvtBillComponent implements OnInit {
  date = new Date();
  issuenames = '';
  issueDates = '';
  advtName = '';
  billno = '';
  isupdate:boolean = false
  advtData = [];
  filteredData;
  isLoading = false;

  printShowData :any= [];
  issueName: any;

  constructor(
    private dialogRef: MatDialogRef<AdvtBillComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private toaster: ToastrService,
    private service: DigisubservicesService
  ) {}

  ngOnInit(): void {
    
    this.isLoading = true;
    // console.log(this.data);
    this.issuenames = this.data['issuenames'];
    this.issueName = this.data['issueName'];
    this.issueDates = this.data['issueDates'];
    this.advtName = this.data['advtName'];
    this.date = this.data['date'];
    this.billno = this.data['billno'];
    this.isupdate = this.data['isupdate']
debugger;
    this.getData();
  }

  getData() {
    this.service.getAdvtInvoiceData().subscribe((res: any) => {
      this.advtData = res;
      console.log(this.advtData);
      this.filterData();
    });
  }

  filterData() {
    debugger;
    // console.log('called');
    if (this.issuenames != '' && this.issueDates != '' && this.advtName == '') {
      this.filteredData = this.advtData.filter((a) => {
        return (
          this.issuenames === a['DS_ISSUENAME'] &&
          this.issueDates.includes(a['DS_ISSUEDATE'])
        );
      });
      // console.log('filteredData', this.filteredData);
    } else if (
      this.issuenames == '' &&
      this.issueDates == '' &&
      this.advtName !== ''
    ) {
      this.filteredData = this.advtData.filter((a) => {
        return this.advtName.includes(a['name']);
      });
      // console.log('filteredData', this.filteredData);
    } else if (
      this.issuenames != '' &&
      this.issueDates != '' &&
      this.advtName != ''
    ) {
      this.filteredData = this.advtData.filter((a) => {
        return (
          this.issuenames === a['DS_ISSUENAME'] &&
          this.issueDates.includes(a['DS_ISSUEDATE']) &&
          this.advtName.includes(a['name'])
        );
      });
      // console.log('filteredData', this.filteredData);
      // console.log('filteredData length', this.filteredData.length);
    }

    setTimeout(() => {
      this.setData();
    }, 700);
  }

  uniqueid(data, key) {
    return [...new Map(data.map((x) => [key(x), x])).values()];
  }

  setData() {
    debugger;
    if (this.filteredData.length == 0) {
        this.isLoading = false;
        alert('Data is not Present.');
        this.dialogRef.close();
        return;
    }

    let data = this.uniqueid(this.filteredData, (a) => a.name);
    let parentIndex = 0;
    let remainingData: any[] = [];

    for (let i = 0; i < data.length; i++) {
        let eachData = this.filteredData.filter((a) => data[i]['name'] == a.name);

        while (eachData.length > 0) {
            let showData = {
                invoice_no: '',
                advt_name: '',
                address: '',
                state: '',
                cust_gst_no: '',
                cust_name: '',
                ro_no: '',
                ro_date: '',
                advt_order_no: '',
                advt_order_date: '',
                firstweek: this.getEmptyWeekData(),
                secondweek: this.getEmptyWeekData(),
                thirdweek: this.getEmptyWeekData(),
                fourthweek: this.getEmptyWeekData(),
                fifthweek: this.getEmptyWeekData(),
                issueName:'',
                total_amt: {
                    page_no: 0,
                    advt_rate: 0,
                    dis: 0,
                    net: 0,
                    cgst: 0,
                    sgst: 0,
                    igst: 0,
                    round_off: 0,
                    total: 0,
                },
                total_in_words: '',
                pre_bal: 0,
                total: 0,
                remaining_amt: 0,
            };

            // Create invoice number
            showData['invoice_no'] = `${this.billno.split('/')[0]}/${this.billno.split('/')[1]}/${this.billno.split('/')[2]}/${('0000' + (parseInt(this.billno.split('/')[3]) + parentIndex)).slice(-4)}`;
            showData['advt_name'] = eachData[0]['name'];
            showData['advt_order_no'] = eachData[0]['booking_no'];
            showData['advt_order_date'] = eachData[0]['booking_date'];
            showData['cust_name'] = eachData[0]['cust_name'];
            showData['cust_gst_no'] = eachData[0]['gst_no'];
            showData['address'] = eachData[0]['address'];
            showData['state'] = eachData[0]['state'];
            showData['issuenames'] = eachData[0]['DS_ISSUENAME'];
            showData['issueName'] = eachData[0]['issueName'];

            let remainingData: any[] = [];

            for (let index = 0; index < eachData.length; index++) {
                let issuedate = eachData[index]['DS_ISSUEDATE'].split(',')[0];
                let firstdate = formatDate(this.firstSunday(this.date.getMonth(), this.date.getFullYear()), 'dd/MM/yyyy', 'en-IN', 'GMT+0530');
                const weekData = {
                    id: eachData[index]['id'],
                    issue_date: issuedate,
                    // issueName:this.issueName,
                    advt_size: eachData[index]['DS_advtsize'],
                    page_no: eachData[index]['pages_no'],
                    hsn: '998363',
                    advt_rate: eachData[index]['total_amount'],
                    dis: eachData[index]['advt_discnt'],
                    net: eachData[index]['amount'],
                    cgst: eachData[index]['cgst'],
                    sgst: eachData[index]['sgst'],
                    igst: eachData[index]['igst'],
                    round_off: eachData[index]['advt_amt'],
                    total: eachData[index]['advt_amt'],
                    gstype: eachData[index]['gst_type'],
                    issueName: eachData[index]['issueName'],
                  
                };

                // Update invoice if id and issue_date match
                if (issuedate <= firstdate && showData.firstweek.issue_date === '') {
                    showData.firstweek = weekData;
                } else if (issuedate <= formatDate(this.secondSunday(this.date.getMonth(), this.date.getFullYear()), 'dd/MM/yyyy', 'en-IN', 'GMT+0530') && showData.secondweek.issue_date === '') {
                    showData.secondweek = weekData;
                } else if (issuedate <= formatDate(this.thirdSunday(this.date.getMonth(), this.date.getFullYear()), 'dd/MM/yyyy', 'en-IN', 'GMT+0530') && showData.thirdweek.issue_date === '') {
                    showData.thirdweek = weekData;
                } else if (issuedate <= formatDate(this.fourthSunday(this.date.getMonth(), this.date.getFullYear()), 'dd/MM/yyyy', 'en-IN', 'GMT+0530') && showData.fourthweek.issue_date === '') {
                    showData.fourthweek = weekData;
                } else if (issuedate <= formatDate(this.fifthSunday(this.date.getMonth(), this.date.getFullYear()), 'dd/MM/yyyy', 'en-IN', 'GMT+0530') && showData.fifthweek.issue_date === '') {
                    showData.fifthweek = weekData;
                } else {
                    // If the date doesn't fit, move it to remainingData for future processing
                    remainingData.push(eachData[index]);
                }
                
            }

            // Ensure first week is not empty
            if (showData.firstweek.issue_date === '') {
                showData.firstweek = this.getEmptyWeekData();
            }

            // Recalculate totals for the current showData object
            this.calculateTotals(showData);

            // Add the showData to the final data array
            this.printShowData.push(showData);
            parentIndex++;

            // Update the remainingData for the next iteration
            eachData = remainingData;
        }

        console.log('printShowData', this.printShowData);

        // Disable loading once all data is processed
        if (i == data.length - 1) {
            this.isLoading = false;
        }
    }
}
  
  // Function to return an empty week data object
  getEmptyWeekData() {
    return {
      id: 0,
      issue_date: '',
      advt_size: '',
      page_no: '',
      hsn: '',
      advt_rate: '',
      dis: '',
      net: '',
      cgst: '',
      sgst: '',
      igst: '',
      round_off: '',
      total: '',
      issueName:'',
      
      
    };
  }
  
  // Function to calculate the total for a specific field
  calculateTotal(showData, field) {
    return ['firstweek', 'secondweek', 'thirdweek', 'fourthweek', 'fifthweek'].reduce((total, week) => {
      return total + (parseInt(showData[week][field]) || 0);
    }, 0);
  }
  
  // Function to calculate totals for showData
  calculateTotals(showData) {
    showData.total_amt.page_no = this.calculateTotal(showData, 'page_no');
    showData.total_amt.advt_rate = this.calculateTotal(showData, 'advt_rate');
    showData.total_amt.dis = this.calculateTotal(showData, 'dis');
    showData.total_amt.net = this.calculateTotal(showData, 'net');
    showData.total_amt.cgst = this.calculateTotal(showData, 'cgst');
    showData.total_amt.sgst = this.calculateTotal(showData, 'sgst');
    showData.total_amt.igst = this.calculateTotal(showData, 'igst');
    showData.total_amt.round_off = this.calculateTotal(showData, 'round_off');
    showData.total_amt.total = this.calculateTotal(showData, 'total');
  
    showData.total_in_words = new ToWords().convert(showData.total_amt.net ,{ currency: true });
    showData.total = showData.total_amt.net;
    showData.remaining_amt = showData.pre_bal + showData.total;
  }
  
  

  // removeDuplicates(arr: any[]): any[] {
  //   
  //   const map = new Map();
  //   return arr.filter(item => {
  //     if (!map.has(item.DS_ISSUEDATE)) {
  //       map.set(item.DS_ISSUEDATE, true);
  //       return true;
  //     }
  //     return false;
  //   });
  // }

  // exportExcel(fileName) {}

  firstSunday(month, year) {
    let tempDate = new Date();
    tempDate.setHours(0, 0, 0, 0);
    // first SUNDAY of april
    tempDate.setMonth(month);
    tempDate.setFullYear(year);
    tempDate.setDate(1);

    let day = tempDate.getDay();
    let toNextSun = day !== 0 ? 7 - day : 0;
    tempDate.setDate(tempDate.getDate() + toNextSun);

    // return formatDate(tempDate, 'dd/MM/yy', 'en-IN', 'GMT+0530');
    return tempDate;
  }

  secondSunday(month, year) {
    let tempDate = new Date(this.firstSunday(month, year));

    tempDate.setHours(0, 0, 0, 0);
    tempDate.setDate(tempDate.getDate() + 7);

    let day = tempDate.getDay();
    // console.log('day', day);

    let toNextSun = day !== 0 ? 7 - day : 0;
    // console.log('toNextSun', toNextSun);
    tempDate.setDate(tempDate.getDate() + toNextSun);

    return tempDate;
  }

  thirdSunday(month, year) {
    let tempDate = new Date(this.secondSunday(month, year));
    tempDate.setHours(0, 0, 0, 0);
    tempDate.setDate(tempDate.getDate() + 7);

    let day = tempDate.getDay();
    // console.log('thirdsunday', day);

    let toNextSun = day !== 0 ? 7 - day : 0;
    // console.log('toNextSun', toNextSun);
    tempDate.setDate(tempDate.getDate() + toNextSun);

    return tempDate;
  }

  fourthSunday(month, year) {
    let tempDate = new Date(this.thirdSunday(month, year));
    tempDate.setHours(0, 0, 0, 0);
    tempDate.setDate(tempDate.getDate() + 7);
    let day = tempDate.getDay();
    // console.log('thirdsunday', day);
    let toNextSun = day !== 0 ? 7 - day : 0;
    // console.log('toNextSun', toNextSun);
    tempDate.setDate(tempDate.getDate() + toNextSun);

    return tempDate;
  }

  fifthSunday(month, year) {
    let tempDate = new Date(this.fourthSunday(month, year));
    tempDate.setHours(0, 0, 0, 0);
    tempDate.setDate(tempDate.getDate() + 7);

    let day = tempDate.getDay();
    // console.log('thirdsunday', day);
    let toNextSun = day !== 0 ? 7 - day : 0;
    // console.log('toNextSun', toNextSun);
    tempDate.setDate(tempDate.getDate() + toNextSun);

    return tempDate;
  }

  saveAdvtInvoice() {
    console.log(this.date)
    
    this.isLoading = true;
    for (let index = 0; index < this.printShowData.length; index++) {
      let data = {
        invoice_no: this.printShowData[index]['invoice_no'],
        booking_no: this.printShowData[index]['advt_order_no'] ,
        // invoice_date: this.date,
        invoice_date:  formatDate(
          this.date,
          'yyyy-MM-dd',
          'en-IN',
          'GMT+0530'
        ),
       
        gst_no: '27AAATH0433Q1ZI',
        pan_no: 'AAATH0433Q',
        advt_name: this.printShowData[index]['advt_name'],
        first_week_id: this.printShowData[index]['firstweek']['id'],
        second_week_id: this.printShowData[index]['secondweek']['id'],
        third_week_id: this.printShowData[index]['thirdweek']['id'],
        fourth_week_id: this.printShowData[index]['fourthweek']['id'],
        fifth_week_id: this.printShowData[index]['fifthweek']['id'],
        pre_amt: this.printShowData[index]['pre_bal'],
        pay_amt: this.printShowData[index]['total'],
        remaining_amt: this.printShowData[index]['remaining_amt'],
      };

      if (data['invoice_no'] != '') {
        if (
          data['first_week_id'] != 0 ||
          data['second_week_id'] != 0 ||
          data['third_week_id'] != 0 ||
          data['fourth_week_id'] != 0 ||
          data['fifth_week_id'] != 0
        ) {
          
          // console.log(data);
          this.service.insertAdvtInvoice(data).subscribe(
            (res) => {
              // console.log('res');
              if (index == this.printShowData.length - 1) {
                this.isLoading = false;
                alert('Advt Invoice created successfully...');
                this.dialogRef.close();
              }
            },
            (error) => {
              console.log(error);
              alert('Something went wrong!. Please try again..');
            }
          );
        } else {
          alert('No records found.');
        }
      } else {
        alert('Invoice no is not valid.');
      }
    }
    console.log(this.data.booking_no);

  }

  updateInvoice(){
    for (let index = 0; index < this.printShowData.length; index++) {
      let data = {
        invoice_no: this.printShowData[index]['invoice_no'],
        booking_no: this.printShowData[index]['advt_order_no'] ,
        // invoice_date: this.date,
        invoice_date:  formatDate(
          this.date,
          'yyyy-MM-dd',
          'en-IN',
          'GMT+0530'
        ),
        // gst_no: '27AAATH0433Q1ZI',
        // pan_no: 'AAATH0433Q',
        advt_name: this.printShowData[index]['advt_name'],
        first_week_id: this.printShowData[index]['firstweek']['id'],
        second_week_id: this.printShowData[index]['secondweek']['id'],
        third_week_id: this.printShowData[index]['thirdweek']['id'],
        fourth_week_id: this.printShowData[index]['fourthweek']['id'],
        fifth_week_id: this.printShowData[index]['fifthweek']['id'],
        pre_amt: this.printShowData[index]['pre_bal'],
        pay_amt: this.printShowData[index]['total'],
        remaining_amt: this.printShowData[index]['remaining_amt'],
      };

      this.service.updateAdvtInvoice(data).subscribe(
        (res) =>{
      });
  }
  this.toaster.success('success', 'data updated sucessfully');
  this.dialogRef.close()

  this.ngOnInit();
}
calculateDiscount(dis:any,total:any) {
  return  (Number(total) * Number(dis)) / 100;
}


}
