<div mat-dialog-content>
    <button mat-button mat-dialog-close style="float: right;">
        <mat-icon>close</mat-icon>
    </button>
    <div class="text-center">
        <h1 *ngIf="!manage_mode && !issue_date_mode" style="font-size: medium;font-weight: bold;">Update Publish Date</h1>
        <h1 *ngIf="manage_mode" style="font-size: medium;font-weight: bold;">Manage Date</h1>
        <h1 *ngIf="issue_date_mode" style="font-size: medium;font-weight: bold;">Issue Date</h1>
        
    </div>
    


    <mat-form-field appearance="fill" class="example-form-field">
        <mat-label >Add New Date</mat-label>
        <input matInput [matDatepicker]="datepicker" (dateChange)="getChangedDate($event)">
        <mat-datepicker-toggle matSuffix [for]="datepicker"></mat-datepicker-toggle>
        <mat-datepicker #datepicker>
        </mat-datepicker>
    </mat-form-field>


    <div class="add-new-subscriber mat-elevation-z4" style="overflow-x: scroll !important;" id="excel-table">
        <div style="margin-top: 20px;" class="tableFixHead">
            <table class="table table-striped text-center">
                <thead>
                    <tr>
                        <th>अ.क्र.</th>
                        <th  *ngIf="!manage_mode" >प्रकाशित झालेली तारीख </th>
                        <th  *ngIf="manage_mode || issue_date_mode" >प्रकाशनाची तारीख </th>
                        <th colspan="2">Action </th>
                    </tr>

                </thead>
                <tbody>
                    <tr *ngFor="let advt of this.publishedDates;let i = index">
                        <td>{{i+1}}</td>
                        <td> {{advt|date:'dd-MM-yyy'}}  </td>
                        <td><button type="button" class="btn btn-danger btn-sm"
                                (click)="update_date(i)">Update</button></td>
                        <td><button type="button" class="btn btn-danger btn-sm"
                                (click)="remove_date(i)">Remove</button></td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>



</div>