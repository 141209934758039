import { Component, OnInit } from '@angular/core';
import { DigisubservicesService } from 'src/app/services/digisubservices.service';
import { Router } from '@angular/router';

interface tableData {
  id: number;
  name: string;
  mobile: string;
}

@Component({
  selector: 'app-tables',
  templateUrl: './tables.component.html',
  styleUrls: ['./tables.component.scss']
})
export class TablesComponent implements OnInit {

  dataLoaded = false;
  tableData: tableData[] = [];
  yourMessage = []
  data1: string[];
  key;
  data2: string[];
  constructor(private service: DigisubservicesService, private router: Router) { }

  ngOnInit() {
    if (sessionStorage.getItem("mobile") == null) {
      this.router.navigate(['/']);
    }
    this.rep_List();
  }

  rep_List() {
    let filterdData: string[];
    this.service.get_AllRepresentative_List().subscribe(data => {
      this.data1 = data;
      //console.log("Data from PHP : ", this.data1);
      //console.log(this.data1.filter(item => { return item['name'] != "office" }))

      this.data1 = this.data1.filter(item => { return item['name'] != "office" })

      // //console.log(this.data1)
      // for(let index = 0; index < Object.keys(this.data1).length ; ++index)
      // {
      //   ////console.log("Name's from data1 : ",this.data1[index]['name']);
      //   if(this.data1[index]['name'] == "office")
      //   {
      //     delete this.data1[index];
      //   }
      // }

      // this.data2 = this.data1;

      // for(let index = 0; index < Object.keys(this.data1).length; ++index)
      // {
      //   if(this.data1[index]['id'] == null)
      //   {
      //     continue;
      //   }
      //   else
      //   {
      //     this.data2[index]['id'] = this.data1[index]['id'];
      //     this.data2[index]['name'] = this.data1[index]['name'];
      //     this.data2[index]['mobile'] = this.data1[index]['mobile'];
      //   }
      // }

      // //console.log("Data from data2 : ",this.data2);

      // for(let index = 0; index<Object.keys(this.data1).length; ++index)
      // {
      //   //filterdData[index] = Object.values(this.data1[index]['name']);
      //    filterdData[index] =  this.data1[index]['name'];
      // }

      // for(let index = 0; index<Object.keys(this.data1).length; ++index)
      // {
      //   if(filterdData.includes("office"))
      //   {
      //     delete this.data1[index];
      //   }
      // }

      // //console.log("Filtered Data : ",this.data1);

      //Object.values(this.data1).filter(key => !this.data1.includes)
      for (let index = 0; index < Object.keys(this.data1).length; ++index) {
        if (parseInt(this.data1[index]['id']) > 50) {
          this.tableData.push({
            id: this.data1[index]['id'],
            name: this.data1[index]['name'],
            mobile: this.data1[index]['mobile']
          });
          continue;
        }

      }

      if (Object.keys(this.tableData).length == 0) {
        this.dataLoaded = false;
      }
      else {
        this.dataLoaded = true;
      }
    });

  }

  action(row: any) {
    if (row.operation === 'updated') {
      //this.update(row); // This is your update call to API
      this.yourMessage.push('success', 'updated successfully') // Show update success notification
      this.yourMessage = []; // make sure you empty it
    }

    if (row.operation === 'deleted') {
      //this.delete(row.id); // This is your delete call to API
      this.yourMessage.push('success', 'deleted successfully') // Show delete success notification
      this.yourMessage = []; // make sure you empty it
    }
  }

}
