<div>
    <div style="margin-left: 70%;  margin-bottom: 2%">
        <button
            [printStyle]="{table : {'border-collapse':'collapse'}, td : {'border': 'solid 1px', 'font-size':'12px', 'font-weight':'400', 'text-align':'center'}, th : {'border': 'solid 1px', 'font-size':'15px', 'font-weight':'600', 'text-align':'center'}, div : {'font-size':'14px', 'font-weight':'600'}, span : {'font-size':'14px', 'font-weight':'600', 'float':'left'}}"
            class="noPrint" style="margin-right: 2%; margin-left: 2%" mat-raised-button color="primary"   [useExistingCss]="true"

            printSectionId="print-section" ngxPrint>
            print
        </button>
        <!-- <button class="noPrint" style="margin-top: 2% 2% 0 2%;"
            (click)="exportExcel('Datewise_Subscription_Report')" mat-raised-button color="primary">
            Export
        </button> -->
        <button mat-dialog-close class="btn noPrint">
            Close
        </button>
    </div>

<table  class="table table-bordered">
    <thead>
        <tr>
            <th>Pending Address</th>
        </tr>
    </thead>

    <tbody id="print-section">
        <div class="row" >

            <div class="col-md-3" style="padding: 40px; font-weight: 40px;" *ngFor="let data2 of AllAdd; let i = index">
                <b>   {{data2.sub_no}}<br> {{data2.name}} <br> {{data2.address}} <br>{{data2.taluka}} <br> {{data2.district}} - {{data2.pincode}} {{data2.state}} <br> 
                    मो.क्र. - {{data2.mobileNumber}} </b>
            </div>

        </div>

    </tbody>

</table>
</div>