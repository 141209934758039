import { DatePipe, formatDate } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PrintOptions } from 'ngx-print';
import { ExpenseledgerComponent } from '../expenseledger/expenseledger.component';
import { DigisubservicesService } from '../services/digisubservices.service';

@Component({
  selector: 'app-journaledgerlvoucher',
  templateUrl: './journaledgerlvoucher.component.html',
  styleUrls: ['./journaledgerlvoucher.component.scss']
})
export class JournaledgerlvoucherComponent {
// getFormattedDate() {
// throw new Error('Method not implemented.');
// }



printOptions: PrintOptions = {
  printSectionId: 'print-section',
  printTitle: 'My Print Title',
  useExistingCss: true,
  bodyClass: 'print-body',
  openNewTab: true,
  previewOnly: false,
  closeWindow: false,
  printDelay: 500
};
currentDate: Date;
startDate: any;
endDate: any;
mainexp: any;
subexp: any;
alldebitvoucher: any;
allcreditvoucher: any;
allvoucherdata: any = [];
credittotal: number=0;
debittotal: number=0;
expname: any;
cr_opening_bal : any;
dr_opening_bal : any;
  databyvocuher: any;
  databyvc: Object;
  totalcvamt: number;

constructor(
  private datePipe: DatePipe,
  private dialogRef: MatDialogRef<ExpenseledgerComponent>,
  @Inject(MAT_DIALOG_DATA) public data: any,
  private service: DigisubservicesService,
) {

}
getFormattedDate(): string {
  return this.datePipe.transform(this.currentDate, 'dd-MM-yyyy');
}


ngOnInit(): void {

  Promise.all([
    new Promise<void>((resolve) => {
      this.currentDate = new Date();
      this.startDate = this.data['startDate'];
      this.endDate = this.data['endDate'];
      this.mainexp = this.data['mainexp'];
      this.subexp = this.data['subexp'];
      console.log(this.startDate, this.endDate, this.mainexp, this.subexp);
      resolve(); 
    }),
    ...(this.mainexp == '30' || this.mainexp == '38' || this.mainexp == '39' || this.mainexp == '60'? [this.subscriptiontype()] :[this.getdebitvocher(), this.getcreditvocher()])


  ]).then(() => {
      console.log('All promises resolved successfully');
      this.calculation()
      this.expname=this.allvoucherdata[0]['exp_name'];
     
    

      this.allvoucherdata = this.allvoucherdata.sort((a, b) => {
        return new Date(a.voucher_date).getTime() - new Date(b.voucher_date).getTime();
    });

    this.allvoucherdata.forEach((element, index) => {
      debugger;
      if (index > 0) {
        

        if(this.mainexp == '30' || this.mainexp == '38' || this.mainexp == '39' || this.mainexp == '60'){
          
          if (element.voucher == 'cr') {
            element.total = Number(this.allvoucherdata[index - 1].total) - Number(element.exp_amt)
          
          } else {
            element.total = Number(this.allvoucherdata[index - 1].total) + Number(element.exp_amt)
          }
        }else{
          if (element.voucher == 'cr') {
            element.total = Number(this.allvoucherdata[index - 1].total) - Number(element.exp_amt)
          } else {
            element.total = Number(this.allvoucherdata[index - 1].total) + Number(element.exp_amt)
          }
        }
     
      } else {
        
        element.total = this.allvoucherdata[0].exp_amt

       
      }
    });
      console.log(this.allvoucherdata)
      if(this.cr_opening_bal=='' || this.cr_opening_bal == undefined ||this.cr_opening_bal == null){
        this.cr_opening_bal = 0;
      }

      if(this.dr_opening_bal=='' || this.dr_opening_bal == undefined || this.dr_opening_bal == null){
        this.dr_opening_bal = 0;
      }

      this.allvoucherdata.forEach((element, index) => {    
       
        if(element.voucher!=undefined){
          if(this.mainexp == '30' || this.mainexp == '38' || this.mainexp == '39' || this.mainexp == '60'){

            if (element.voucher == 'cr') {
              if(index==0)
                {
                 this.allvoucherdata[0].total =Number( this.allvoucherdata[0].total)- Number(this.cr_opening_bal)
                  
                }else{
                  element.total = Number(this.allvoucherdata[index - 1].total) - Number(element.exp_amt)
            
                }
              
            } else {
              if(index==0)
                {
                 this.allvoucherdata[0].total =Number( this.allvoucherdata[0].total) + Number(this.dr_opening_bal)
                  
                }else{
                  element.total = Number(this.allvoucherdata[index - 1].total) + Number(element.exp_amt)
          
                }
                }
          }else{
            if (element.voucher == 'cr') {
              if(index==0)
                {
                 this.allvoucherdata[0].total =Number( this.allvoucherdata[0].total)-Number(this.cr_opening_bal)
                //  element.total = Number(this.allvoucherdata[index - 1].total) - Number(element.exp_amt)
                }else{
                  element.total = Number(this.allvoucherdata[index - 1].total) - Number(element.exp_amt)
            
                }
              
            } else {
              if(index==0)
                {
                 this.allvoucherdata[0].total =Number( this.allvoucherdata[0].total) + Number(this.dr_opening_bal)
                  
                }else{
                  element.total = Number(this.allvoucherdata[index - 1].total) + Number(element.exp_amt)
          
                }
              
            }
        }
        
          }
       
      });

    

    })
    .catch((error) => {
      console.error('Error:', error);
    });
}
getdebitvocher(): Promise<any> {
  return new Promise((resolve: any, reject) => {
    const dv: any = {
      "startdate": this.startDate,
      "enddate": this.endDate,

    }
    this.service.getdatajournal(this.startDate,this.endDate).subscribe((a) => {
      // console.log(a);
      this.alldebitvoucher = a;
      // let debitlist = a
      // debitlist.forEach(element => {
      //   this.allvoucherdata.push(element);

      // });

      // this.dr_opening_bal = debitlist[0].opening_bal_dr


      console.log(this.expname, "contract deatail dv");
    });

    setTimeout(() => {
      resolve();
    }, 1000);
  })

  

}

getcreditvocher() {

  return new Promise((resolve: any, reject) => {
    const cv: any = {
      "exp_id": this.mainexp,
      "subexp_id": this.subexp,
      "startdate": this.startDate,
      "enddate": this.endDate,
    }
    // this.service.getAllCreditVocher(cv).subscribe((a) => {
    //   // console.log(a);
    //   let creditlist = a;
    //   // this.allvoucherdata.push(a);
    //   creditlist.forEach(element => {
    //     this.allvoucherdata.push(element);
    //   });
    //   this.cr_opening_bal = creditlist[0].opening_bal_cr
    //   console.log(this.allvoucherdata, "contract deatail cv");
    // });

    setTimeout(() => {
      resolve();
    }, 1000);
  })


}

startDate_contract_data;
  enddate_contract_data; 
  getChangedDateContractByVC() {
    if (
      this.databyvocuher.get('start').value != null &&
      this.databyvocuher.get('end').value != null
    ) {
      // console.log(this.contractDetailsForm.value);
      this.startDate_contract_data = formatDate(
        new Date(this.databyvocuher.get('start').value),
        'yyyy-MM-dd',
        'en-IN',
        'GMT+0530'
      );
      
      this.enddate_contract_data = formatDate(
        new Date(this.databyvocuher.get('end').value),
        'yyyy-MM-dd',
        'en-IN',
        'GMT+0530'
      );
    }
    console.log( this.startDate_contract_data , this.enddate_contract_data)

    const req1:any = { 
      "start_date":this.startDate_contract_data,
      "invoice_rep_name" : this.databyvocuher.value.repname,
      // "financialYear":this.date,
      "end_date":this.enddate_contract_data,
      "voucher_no":this.databyvocuher.value.cvnumber,
    }

    this.service.getdatabyvc(req1).subscribe((data) => {
      // console.log('pkgBookingData', data);
      debugger;
      this.databyvc = data;
      this.totalcvamt = 0
      // for(let data1 of this.databyvc){
       
      //   for(let data2 of data1.voucher_data){
      //     debugger;
      //     this.totalcvamt += parseInt(data2.crdt_voucher_amt) || 0;
      //   }
      // }
    });

    // this.generateMonthNames(this.startDate_contract,this.enddate_contract)
  }

subscriptiontype(): Promise<any> {
  return new Promise((resolve: any, reject) => {
    const cv: any = {
      "exp_id": this.mainexp,
      "subexp_id": this.subexp,
      "startdate": this.startDate,
      "enddate": this.endDate,

    }
    this.service.getAllSubscriptiontype(cv).subscribe((a) => {
      // console.log(a);
      // this.alldebitvoucher = a;
      let debitlist = a
      debitlist.forEach(element => {
     
        this.allvoucherdata.push(element);

      });
      
      this.dr_opening_bal = 0
      

      console.log(this.allvoucherdata, "subscription type");
    });

    setTimeout(() => {
      resolve();
    }, 1000);
  })

  

}
calculation() {
  // if (this.allvoucherdata['exp_amt'] === 'cr') {
  //   this.credittotal += parseFloat(this.allvoucherdata['exp_amt']);
  // } else if (this.allvoucherdata['exp_amt'] === 'dr') {
  //   this.debittotal += parseFloat(this.allvoucherdata['exp_amt']);
  // }

  this.allvoucherdata.forEach(element => {
    if (element['voucher'] === 'cr') {
         this.credittotal -= Number(element['exp_amt']);
       } else if (element['voucher'] === 'dr') {
         this.debittotal -= Number(element['exp_amt']);
       }
    
  });
}

closeexp(){
  this.dialogRef.close()
}


}
