import { Component, OnInit } from '@angular/core';
import { DigisubservicesService } from 'src/app/services/digisubservices.service';
import { Router } from '@angular/router';

interface tableData {
  id:number;
  name:string;
  mobile : string;
  email : string;
}

@Component({
  selector: 'app-sub-table',
  templateUrl: './sub-table.component.html',
  styleUrls: ['./sub-table.component.scss']
})
export class SubTableComponent implements OnInit {

  dataLoaded = false;
   tableData: tableData[] = [];
   data1;

  constructor(private router : Router,public service : DigisubservicesService) { }

  ngOnInit(): void 
  {
    if(sessionStorage.getItem("mobile") == null){
      this.router.navigate(['/']);
    }
    this.subList();
  }

  subList()
  {
    let resultState = this.service.getAllSub();
    resultState.subscribe((data)=>{
      this.data1 = data;
      for(let index = 0; index < Object.keys(this.data1).length; ++index)
    {
      this.tableData.push({
        id : this.data1[index]['subid'],
        name : this.data1[index]['name'],
        mobile : this.data1[index]['mobile'],
        email : this.data1[index]['email']
      });
    }

    if(Object.keys(this.tableData).length == 0)
    {
      this.dataLoaded = false;
    }
    else
    {
      this.dataLoaded = true;
    }
    });
  }

}
