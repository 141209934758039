import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DigisubservicesService } from 'src/app/services/digisubservices.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-security',
  templateUrl: './security.component.html',
  styleUrls: ['./security.component.scss']
})
export class SecurityComponent {
userName:any
password:any
message:any

 constructor(
    private dialogRef: MatDialogRef<SecurityComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, public service: DigisubservicesService,
    private toaster:ToastrService
  ) {}


  closeDialog() {
    this.dialogRef.close({
      data: 'close',
    }); // Closes the dialog
  }

  submit(){
    console.log(this.userName,this.password)
    const req={
       "username":this.userName,
    "password":this.password
    }

    this.service.accountUserLogin(req).subscribe((data) => {
      console.log('data', data);
     if(data.status=="success"){
      this.dialogRef.close({
        data: 'success',
      }); // Closes the dialog
     }else{
        this.toaster.info('Error', data.message);
     }
    });
  }
}
