<div mat-dialog-content>
    <div class="text-center">
        <h1 *ngIf="isTrackingDetails"  style="font-size: medium;font-weight: bold;">Update Tracking Details</h1>
        <h1 *ngIf="isStartDate"  style="font-size: medium;font-weight: bold;">Update Start Date</h1>
        <h1 *ngIf="isEndDate"  style="font-size: medium;font-weight: bold;">Update End Date</h1>
    </div>

    <form class="form" [formGroup]="TrackingDetails" *ngIf="!isStartDate && !isEndDate" (ngSubmit)=save()>
        <div class="form-row">
            <div class="col form-group">
                <label style=" margin-top: 20px;">Tracking Details :</label>
                <input required style="width: 90%;" formControlName="trasnport_details" type="text"
                    class="form-control" placeholder="">
            </div>
            <!-- form-group end.// -->

            <div class="col form-group">
                <label style="margin-left: 35px; margin-top: 20px;">Trasnport Mode :</label>

                <select style="width: 90%; margin-left: 35px;" class="form-control" placeholder="Select One"
                    formControlName="trasnport_method" (change)="getTrackingMethod($event)" required>
                    <option value="self">Self</option>
                    <option value="byhand">हस्ते</option>
                    <option value="byhandpost">हस्ते by Post</option>
                    <option value="post">Post</option>
                    <option value="courier">Courier</option>
                    <option value="st">ST</option>
                    <option value="railway">Railway</option>
                </select>
            </div>
        </div>
        <div class="form-row" *ngIf="displayByhand">
            <div class="col form-group">
                <label style="margin-top: 20px; ">हस्ते वितरकाचे नाव :</label>
                <input required style="width: 90%;" formControlName="trasnporter_name" type="text" class="form-control"
                    placeholder="">
            </div>
        </div>


        <div mat-dialog-actions style="float:right">
            <button mat-button mat-dialog-close>Close</button>
            <button [disabled]="TrackingDetails.invalid" mat-button (click)="update()"> Update</button>
        </div>
        <!-- form-group// -->


    </form>


    <!-- <mat-form-field appearance="fill" *ngIf="!isTrackingDetails" >
        <mat-label *ngIf="isStartDate">Choose Start Date</mat-label>
        <mat-label *ngIf="isEndDate">Choose End Date</mat-label>
        <input matInput [matDatepicker]="picker" type="date" (dateChange)="getChangedDate($event)">
        <mat-datepicker-toggle matSuffix [for]="picker">
        </mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
    </mat-form-field> -->

    <mat-form-field appearance="fill" class="example-form-field">
        <mat-label *ngIf="isStartDate">Choose Start Date</mat-label>
        <mat-label *ngIf="isEndDate">Choose End Date</mat-label>
        <input matInput [matDatepicker]="datepicker" (dateChange)="getChangedDate($event)">
        <mat-datepicker-toggle matSuffix [for]="datepicker"></mat-datepicker-toggle>
        <mat-datepicker #datepicker>
          
        </mat-datepicker>
      </mat-form-field>

    <div mat-dialog-actions style="float:right" *ngIf="!isTrackingDetails">
        <button mat-button mat-dialog-close>Close</button>
    </div>

    <div class="row" *ngIf="!(!OldDates || OldDates.length == 0)" >
        <div class="col" style="font-size: medium; font-weight: bold;" >Old Dates</div>
        <div class="col" style="font-size: medium; font-weight: bold;" >Updated On</div>
    </div>

    <div class="row" *ngFor="let item of OldDates" >
        <div class="col">{{item['mag_date']}}</div>
        <div class="col">{{item['updated_on']}}</div>
    </div>

</div>