import { Routes } from '@angular/router';

import { DashboardComponent } from '../../pages/dashboard/dashboard.component';
import { IconsComponent } from '../../pages/icons/icons.component';
import { MapsComponent } from '../../pages/maps/maps.component';
import { UserProfileComponent } from '../../pages/user-profile/user-profile.component';
import { TablesComponent } from '../../pages/tables/tables.component';
import { LoginComponent } from '../../pages/login/login.component';
import { RegisterComponent } from '../../pages/register/register.component';
import { AccountsComponent } from 'src/app/pages/accounts/accounts.component';
import { MasterTablesComponent } from 'src/app/pages/master-tables/master-tables.component';
import { InvoiceComponent } from 'src/app/pages/invoice/invoice.component';
import { SubscriptionDetailsComponent } from 'src/app/pages/subscription/subscription-details/subscription-details.component';
import { AdvertisementDetailsComponent } from 'src/app/pages/advertisement-details/advertisement-details.component';
import { BookSellerRegistrationComponent } from 'src/app/pages/book-seller-registration/book-seller-registration.component';
import { Subscription1Component } from 'src/app/subscription1/subscription1.component';

export const AdminLayoutRoutes: Routes = [
  { path: 'login', component: LoginComponent },
  { path: 'dashboard', component: DashboardComponent },
  { path: 'book-store', component: UserProfileComponent },
  // { path: 'representative', component: TablesComponent },
  { path: 'admin', component: IconsComponent },
  { path: 'subscribers', component: MapsComponent },
  { path: 'reports', component: RegisterComponent },
  { path: 'accounts', component: AccountsComponent },
  // { path: 'masters', component: MasterTablesComponent },
  // { path: 'invoice', component: InvoiceComponent },
  { path: 'subscription', component: SubscriptionDetailsComponent },
  { path: 'subscription-report', component: Subscription1Component },
  { path: 'advertisement', component: AdvertisementDetailsComponent },
  { path: 'book-seller', component: BookSellerRegistrationComponent },
];
