<div mat-dialog-content>
  <div class="text-center">
    <h1 *ngIf="view_mode" style="font-size: medium; font-weight: bold">
      View Advertisement
    </h1>
    <h1 *ngIf="!view_mode" style="font-size: medium; font-weight: bold">
      Update Advertisement
    </h1>
  </div>

  <div class="form-group" style="float: right">
    <button mat-button mat-dialog-close>Close</button>
  </div>

  <div class="add-new-subscriber mat-elevation-z4">
    <form class="form" [formGroup]="ionicForm" (ngSubmit)="submitAdd()">
      <div class="form-row" style="justify-content: center">
        <h3 style="margin-top: 5%; margin-left: 5%">Personal Details</h3>
      </div>

      <div class="form-row">
        <mat-form-field
          class="example-full-width"
          style="width: 40%; margin-left: 5%; margin-top: 2%"
        >
          <mat-label>Agency/Name :</mat-label>
          <input matInput formControlName="name" required readonly />
        </mat-form-field>

        <mat-form-field
          class="example-full-width"
          style="width: 40%; margin-left: 3%; margin-top: 2%"
        >
          <mat-label>Cust.Name :</mat-label>
          <input matInput formControlName="custname" readonly />
        </mat-form-field>
      </div>

      <div class="form-group">
        <mat-form-field
          class="example-full-width"
          style="width: 85%; margin-left: 5%; margin-top: 20px"
          appearance="fill"
        >
          <mat-label>Address</mat-label>
          <textarea
            matInput
            placeholder="Address"
            formControlName="address"
            required
            [readOnly]="view_mode"
          ></textarea>
        </mat-form-field>
      </div>

      <div class="form-row">
        <mat-form-field
          style="margin-left: 5%; margin-top: 20px"
          class="example-full-width"
          appearance="fill"
        >
          <mat-label>Pincode</mat-label>
          <input
            (change)="getPincode($event)"
            [readOnly]="view_mode"
            matInput
            #postalCode
            maxlength="6"
            placeholder=""
            formControlName="pincode"
          />
          <mat-hint align="end">{{ postalCode.value.length }} / 6</mat-hint>
        </mat-form-field>

        <mat-form-field
          style="width: 30%; margin-left: 3%; margin-top: 20px"
          appearance="fill"
        >
          <mat-label>Country</mat-label>
          <mat-select
            (selectionChange)="getCountry($event)"
            formControlName="country"
            required
          >
            <mat-option *ngFor="let c of countries" value="{{ c['name'] }}">{{
              c["name"]
            }}</mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field
          style="width: 30%; margin-left: 3%; margin-top: 20px"
          appearance="fill"
        >
          <mat-label>State</mat-label>
          <mat-select
            (selectionChange)="getState($event)"
            formControlName="state"
            required
          >
            <mat-option *ngFor="let c of states" value="{{ c['state_name'] }}"
              >{{ c["state_name"] }}
            </mat-option>
          </mat-select>
          <span> {{ selectedState }}</span>
        </mat-form-field>
      </div>

      <div class="form-row">
        <mat-form-field
          style="width: 30%; margin-left: 5%; margin-top: 20px"
          appearance="fill"
        >
          <mat-label>City</mat-label>
          <mat-select formControlName="district" required>
            <mat-option *ngFor="let c of cities" value="{{ c['name'] }}">{{
              c["name"]
            }}</mat-option>
          </mat-select>
          <span> {{ selectedCity }}</span>
        </mat-form-field>

        <mat-form-field
          class="example-full-width"
          style="width: 30%; margin-left: 3%; margin-top: 33px"
        >
          <mat-label>Taluka</mat-label>
          <input
            [readOnly]="view_mode"
            matInput
            formControlName="taluka"
            required
          />
        </mat-form-field>
      </div>

      <div class="form-row">
        <mat-form-field
          class="example-full-width"
          style="margin-left: 5%; margin-top: 20px"
        >
          <mat-label>Mobile</mat-label>
          <span matPrefix>+91 &nbsp;</span>
          <input
            type="tel"
            #mobiles
            readonly
            (change)="validateMobile($event)"
            maxlength="10"
            formControlName="mobileNumber"
            matInput
          />
          <mat-hint align="end">{{ mobiles.value.length }} / 10</mat-hint>
          <mat-error *ngIf="validmobile">
            Please enter a valid mobile no.
          </mat-error>
          <mat-error *ngIf="validmobile">
            Mobile no. is <strong>required</strong>
          </mat-error>
        </mat-form-field>

        <mat-form-field
          class="example-full-width"
          style="width: 35%; margin-left: 3%; margin-top: 20px"
          [style.color]="validemail ? null : 'red'"
        >
          <mat-label>Email</mat-label>
          <input
            (change)="validateEmail($event)"
            readonly
            type="email"
            matInput
            formControlName="email"
            placeholder="Ex. pat@example.com"
          />
          <mat-error *ngIf="validemail">
            Please enter a valid email address
          </mat-error>
          <mat-error *ngIf="validemail">
            Email is <strong>required</strong>
          </mat-error>
        </mat-form-field>

        <mat-form-field
          class="example-full-width"
          style="margin-left: 3%; margin-top: 20px"
        >
          <mat-label>Landline No.</mat-label>
          <input type="text" readonly formControlName="landline_no" matInput />
        </mat-form-field>
      </div>

      <div class="form-row">
        <label style="margin-left: 5%; margin-top: 20px"
          >Date of Birth :
        </label>
        <label style="margin-left: 30%; margin-top: 20px"
          >Company Registration Date :
        </label>

        <mat-form-field appearance="fill" style="margin-left: 5%">
          <mat-label>Choose a date</mat-label>
          <input
            formControlName="dob"
            matInput
            [readOnly]="view_mode"
            [matDatepicker]="datepicker"
            (dateChange)="validatedob($event)"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="datepicker"
          ></mat-datepicker-toggle>
          <mat-datepicker #datepicker> </mat-datepicker>
          <mat-error *ngIf="validdob"> Please enter a valid DOB </mat-error>
          <mat-error *ngIf="validdob">
            DOB is <strong>required</strong>
          </mat-error>
        </mat-form-field>

        <mat-form-field appearance="fill" style="margin-left: 10%">
          <mat-label>Choose a date</mat-label>
          <input
            formControlName="companyRegistration"
            [readOnly]="view_mode"
            matInput
            [matDatepicker]="datepicker1"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="datepicker1"
          ></mat-datepicker-toggle>
          <mat-datepicker #datepicker1> </mat-datepicker>
        </mat-form-field>
      </div>

      <div class="form-row">
        <mat-form-field appearance="fill" style="margin-left: 5%; width: 35%">
          <mat-label>Advt. Type : </mat-label>
          <mat-select readonly formControlName="advtType" required>
            <mat-option
              *ngFor="let item of advtTypes"
              value="{{ item['advt_types_select'] }}"
            >
              {{ item["advt_type"] }}</mat-option
            >
          </mat-select>
        </mat-form-field>

        <mat-form-field
          style="margin-left: 5%; width: 35%"
          class="example-full-width"
          appearance="fill"
        >
          <mat-label>Gst No.</mat-label>
          <input
            [readOnly]="view_mode"
            matInput
            type="text"
            placeholder="Gst No."
            formControlName="gstno"
          />
        </mat-form-field>
      </div>

      <div class="form-row" style="justify-content: center">
        <h3 style="margin-top: 5%; margin-left: 5%; margin-bottom: 5%">
          Advertisment Details
        </h3>
      </div>

      <!-- <div class="form-row" *ngIf="!view_mode">
                <mat-form-field appearance="fill" style="margin-left: 5%; width: 35%;">
                    <mat-label>Issue/grantha : </mat-label>
                    <mat-select (selectionChange)="getIssues($event)" required>
                        <mat-option  *ngFor="let item of bookList" value="{{item['name']}}">{{item['name']}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field appearance="fill" style="margin-left: 5%; width: 35%;">
                    <mat-label>Advt. Sizes : </mat-label>
                    <mat-select (selectionChange)="getAdvtSize($event)" required>
                        <mat-option *ngFor="let item of advtSizes" value="{{item['name']}}">{{item['name']}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>

                <button style="height: 1%;     margin-left: 3%; margin-top: 1%;" mat-raised-button color="warn" (click)="addAdvt()">Add</button>
            </div> -->

      <div class="form-row" *ngIf="!view_mode">
        <mat-form-field appearance="fill" style="margin-left: 5%">
          <mat-label>Issue/grantha : </mat-label>
          <mat-select (selectionChange)="getIssues($event)" required>
            <mat-option
              *ngFor="let item of advt_mag_name_details"
              value="{{ item['issue_name'] }}"
            >
              {{ item["issue_name"] }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field appearance="fill" style="margin-left: 5%">
          <mat-label>Advt. Sizes : </mat-label>
          <mat-select (selectionChange)="getAdvtSize($event)" required>
            <mat-option
              *ngFor="let item of advtSizes"
              value="{{ item['name'] }}"
              >{{ item["name"] }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field appearance="fill" style="margin-left: 5%">
          <mat-label>Advt. Dates : </mat-label>
          <mat-select #mySel (selectionChange)="getAdvtDates($event)" multiple>
            <mat-option value="all" (click)="toggleAllSelection()"
              >All</mat-option
            >
            <mat-option
              *ngFor="let item of getFilterMagPublishDates"
              [value]="item"
              >{{ item | date: "dd-MM-yyy" }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <button
          type="button"
          style="height: 1%; margin-left: 3%; margin-top: 1%"
          mat-raised-button
          color="warn"
          (click)="addAdvt()"
        >
          Add
        </button>
      </div>

      <div class="form-row">
        <table class="table table-striped text-center">
          <thead>
            <tr>
              <th>Sr.No.</th>
              <th>Issue Name</th>
              <th>Advt. Size</th>
              <th>Date</th>
              <th *ngIf="!view_mode">Action</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let sub of AdvtDetails; let i = index">
              <td>{{ i + 1 }}</td>
              <td>{{ sub["issue"] }}</td>
              <td>{{ sub["advtsize"] }}</td>
              <td>{{ sub["advtdates"] }}</td>
              <td *ngIf="!view_mode">
                <button
                  type="button"
                  class="btn btn-danger btn-sm"
                  (click)="deleteAdvt(i)"
                >
                  Remove
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="form-row">
        <div class="col">
          <mat-form-field
            style="margin-top: 10px; margin-left: 5%; width: 45%"
            class="example-full-width"
          >
            <mat-label>RO Number</mat-label>
            <input
              [readOnly]="view_mode"
              type="text"
              formControlName="ro_number"
              matInput
            />
          </mat-form-field>
        </div>
      </div>

      <div class="form-row">
        <mat-form-field
          style="margin-left: 5%; margin-top: 20px; width: 44%"
          class="example-full-width"
        >
          <mat-label>Comments</mat-label>
          <textarea
            [readOnly]="view_mode"
            matInput
            type="text"
            placeholder="Comments"
            formControlName="comment"
          >
          </textarea>
        </mat-form-field>

        <mat-form-field
          style="margin-left: 3%; margin-top: 20px; width: 44%"
          class="example-full-width"
        >
          <mat-label>Tapshil</mat-label>
          <textarea
            [readOnly]="view_mode"
            matInput
            type="text"
            placeholder="Tapshil"
            formControlName="tapshil"
          >
          </textarea>
        </mat-form-field>
      </div>

      <div class="form-group">
        <mat-form-field appearance="fill" style="margin-left: 5%; width: 35%">
          <mat-label>Representative Name</mat-label>
          <mat-select
            required
            (selectionChange)="SelectRepresentative($event)"
            formControlName="rep_id"
          >
            <mat-option
              *ngFor="let rep of getAllRepList"
              value="{{ rep['id'] }}"
              >{{ rep["name"] }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="form-group">
        <mat-form-field
          class="example-full-width"
          style="width: 35%; margin-left: 5%; margin-top: 20px"
        >
          <mat-label>Advt. Amount : </mat-label>
          <input matInput formControlName="advtamout" type="number" />
        </mat-form-field>

        <mat-form-field
          class="example-full-width"
          style="width: 35%; margin-left: 5%; margin-top: 20px"
        >
          <mat-label>Advt. Discount : </mat-label>
          <input matInput type="number" formControlName="advtdiscount" />
        </mat-form-field>
      </div>

      <div class="form-row">
        <span style="margin-top: 5%; margin-left: 5%; margin-bottom: 5%">
          Total Discount :
          {{
            this.advtamount * (this.discount / 100) | currency: "INR":"symbol"
          }}
        </span>
      </div>

      <div class="form-group" style="margin-left: 5%">
        <mat-radio-group aria-label="Select an option" formControlName="gstie">
          <mat-radio-button
            value="gstInclude"
            style="margin-right: 5%"
            (change)="changeType($event)"
            >GST Including</mat-radio-button
          >
          <mat-radio-button value="gstExclude" (change)="changeType($event)"
            >GST Excluding</mat-radio-button
          >
        </mat-radio-group>
      </div>

      <div class="form-group">
        <mat-form-field
          class="example-full-width"
          *ngIf="!showigst"
          style="width: 35%; margin-left: 5%; margin-top: 20px"
        >
          <mat-label>GST 5% (SGST-2.5% + CGST-2.5%): </mat-label>
          <input matInput formControlName="gstamount" type="number" />
        </mat-form-field>

        <mat-form-field
          class="example-full-width"
          *ngIf="showigst"
          style="width: 35%; margin-left: 5%; margin-top: 20px"
        >
          <mat-label>IGST 5% : </mat-label>
          <input matInput type="number" formControlName="igstamount" />
        </mat-form-field>
      </div>

      <div class="form-group">
        <mat-form-field
          class="example-full-width"
          style="width: 35%; margin-left: 5%; margin-top: 20px"
        >
          <mat-label>Total Amount: </mat-label>
          <input matInput formControlName="amount" type="number" />
        </mat-form-field>
      </div>

      <div class="form-group">
        <mat-form-field
          class="example-full-width"
          style="width: 35%; margin-left: 5%; margin-top: 20px"
        >
          <mat-label>Rep. Receipt No.</mat-label>
          <input [readOnly]="view_mode" matInput formControlName="receiptno" />
        </mat-form-field>

        <mat-form-field
          class="example-full-width"
          style="width: 35%; margin-left: 5%; margin-top: 20px"
        >
          <mat-label>Receipt Date : </mat-label>
          <input
            [readOnly]="view_mode"
            matInput
            type="date"
            formControlName="receiptdate"
          />
        </mat-form-field>
      </div>

      <div class="form-group">
        <mat-form-field appearance="fill" style="margin-left: 5%; width: 35%">
          <mat-label>Payment Mode </mat-label>
          <mat-select
            readonly
            (selectionChange)="getcheque($event)"
            formControlName="paymentmode"
          >
            <mat-option value="cash">Cash</mat-option>
            <mat-option value="cheque">Cheque</mat-option>
            <mat-option value="complementry">Complementry</mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="form-group">
        <img src="{{ this.newImgpath }}" width="100px" />
      </div>

      <div class="form-group" style="float: right">
        <button mat-button mat-dialog-close>Close</button>
        <button *ngIf="!view_mode" mat-button (click)="update()">Update</button>
      </div>
    </form>
  </div>
</div>
