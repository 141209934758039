<div class="header bg-gradient-danger pb-1 pt-5 pt-md-8">
  <div class="container-fluid">
    <div class="header-body"></div>
  </div>
</div>
<div class="container-fluid" style="margin-top: -4.1em">
  <!-- Icons is Used as Admin -->
  <mat-tab-group dynamicHeight  [(selectedIndex)]="demo1TabIndex"  (selectedTabChange)="onTabClick($event)">
    <mat-tab label="Manage Admins" >
      <div class="cancel-subscriber mat-elevation-z4">
        <div>
          <h1 style="text-align: center; margin-top: 10px">Add New Admin</h1>
        </div>
        <form class="form" [formGroup]="addNewAdminForm" (ngSubmit)="addNewAdmin()" *ngIf="isAdminAuthorize">
          <div class="form-group">
            <label style="margin-left: 35px; margin-top: 20px" (change)="isadminexist($event)">Mobile</label>
            <input required style="width: 90%; margin-left: 35px" formControlName="adminMobile" type="number"
              class="form-control" placeholder="" />
          </div>
          <!-- form-row end.// -->

          <div class="form-group">
            <label style="margin-left: 35px">Email</label>
            <input required style="margin-left: 35px; margin-right: 45px; width: 90%" formControlName="adminEmail"
              type="email" class="form-control" placeholder="" />
          </div>

          <div class="form-group">
            <label style="margin-left: 35px">Password</label>
            <input required style="margin-left: 35px; margin-right: 45px; width: 90%" formControlName="adminPassword"
              type="text" class="form-control" placeholder=" " />
          </div>

          <div class="form-group">
            <button style="margin-left: 200px; width: 50%" [disabled]="addNewAdminForm.invalid" type="submit"
              class="btn btn-danger btn-block">
              Add
            </button>
          </div>
          <!-- form-group// -->
        </form>

        <div class="form-group" *ngIf="!isAdminAuthorize">
          <label style="
              font-weight: bold;
              font-size: large;
              margin-left: 33%;
              margin-top: 20%;
            ">
            You are not Authorize to this Section.
          </label>
        </div>
      </div>

      <div class="add-new-subscriber mat-elevation-z4">
        <form class="form" [formGroup]="addNewAdminForm" *ngIf="isAdminAuthorize">
          <div class="form-group">
            <label style="margin-left: 35px; margin-top: 20px">Mobile Number</label>
            <input required style="width: 70%; margin-left: 100px" (change)="validateAdmin($event)"
              formControlName="adminMobile" type="number" class="form-control" placeholder="" />
            <label *ngIf="!isvalidAdmin" style="color: red; font-weight: bold; margin-left: 25px">Please Validate Your
              Mobile Number.</label>
            <label *ngIf="isvalidAdmin" style="color: green; font-weight: bold; margin-left: 25px">Validated.</label>
          </div>
          <!-- form-row end.// -->

          <div *ngIf="isvalidAdmin">
            <table class="table table-striped text-center">
              <thead>
                <th>Name</th>
                <th>Mobile Number</th>
                <th>Email</th>
                <th>Action</th>
                <th>Make Super</th>
                <th>Remove Super</th>
              </thead>
              <tbody>
                <tr *ngFor="let ads of getalladmins; let i = index">
                  <td>{{ "office" + ads["id"] }}</td>
                  <td>{{ ads["mobile"] }}</td>
                  <td>{{ ads["email"] }}</td>
                  <td>
                    <button type="button" class="btn btn-danger btn-sm" (click)="removeAdmin(ads['id'])">
                      Remove
                    </button>
                  </td>
                  <td>
                    <button type="button" [disabled]="ads['issuper']" class="btn btn-success btn-sm"
                      (click)="superAdmin(ads['id'])">
                      Super
                    </button>
                  </td>
                  <td>
                    <button type="button" [disabled]="!ads['issuper']" class="btn btn-warning btn-sm"
                      (click)="removeadmin(ads['id'])">
                      Remove Super
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div class="form-group">
            <button *ngIf="!isvalidAdmin" style="margin-left: 200px; width: 50%" class="btn btn-danger btn-block"
              type="button">
              Validate Number
            </button>
          </div>
        </form>
        <div class="form-group" *ngIf="!isAdminAuthorize">
          <label style="
              font-weight: bold;
              font-size: large;
              margin-left: 33%;
              margin-top: 20%;
            ">
            You are not Authorize to this Section.
          </label>
        </div>
      </div>
    </mat-tab>
  
    <!-- <mat-tab label="Manage Magazine"> -->
    <!-- <mat-card style="margin-top: 2%;border-radius: 15px;">
        <form [formGroup]="NewMagazineForm">
          <div class="form-row">
            <mat-form-field style="width: 35%; margin-top: 10px; margin-left: 5%" appearance="outline">
              <mat-label>Magazine Name:</mat-label>
              <input matInput formControlName="mag_name" [matAutocomplete]="auto11" required />
              <mat-autocomplete autoActiveFirstOption #auto11="matAutocomplete">
                <mat-option (onSelectionChange)="selected_magName($event,option)"
                  *ngFor="let option of filteredOptions11 | async" [value]="option['mag_name']">
                  {{ option["mag_name"] }}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
            <mat-form-field style="width: 35%; margin-top: 10px; margin-left: 2%" appearance="outline">
              <mat-label>Issue/Granth Category:</mat-label>
              <input matInput formControlName="ig_cat" />
            </mat-form-field>
            <div style="margin-top: 15px; margin-left: 2%;">
              <button class="btn btn-info" type="button" (click)="addmags()">Add</button>
            </div>
          </div>
          <div class="tableFixHead" style="margin-left: 5%; margin-right: 5%;">
            <table class="table table-striped text-center">
              <thead>
                <tr>
                  <th>Magazine Name</th>
                  <th>Issue/Granth Category</th>
                  <th colspan="2">Action</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let item of magazineData; let i=index">
                  <td>{{ item['mag_name'] }}</td>
                  <td>{{ item['ig_cat'] }}</td>
                  <td colspan="2">
                    <button matTooltip="Update" class="btn-sm btn-outline-info" style="margin-right: 2%;" type="button"
                      (click)="updateItem(item, i)">
                      <mat-icon>edit</mat-icon>
                    </button>
                    <button matTooltip="Remove" class="btn-sm btn-outline-danger" type="button"
                      (click)="removeItem(item, i)">
                      <mat-icon>close</mat-icon>
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="form-group">
            <button mat-raised-button class="btn btn-primary" style="margin-left: 30%;width: 25%;" type="button"
              (click)="addNewMagazine()">Add Magazine</button>
          </div>
        </form>
      </mat-card> -->
    <!-- <div class="add-new-subscriber mat-elevation-z4"> -->

    <!-- <div style="margin-top: 20px">
          <table class="table table-striped text-center">
            <thead>
              <th>Sr.No.</th>
              <th>Magazine Name</th>
              <th>Year</th>
              <th>Print Edition Price</th>
              <th>Digital Edition Price</th>
              <th>Action</th>
            </thead>
            <tbody>
              <tr *ngFor="let magdetails of this.mag; let i = index">
                <td>{{ i + 1 }}</td>
                <td>{{ magdetails["name"] }}</td>
                <td>{{ magdetails["year"] }}</td>
                <td>{{ magdetails["print"] }}</td>
                <td>{{ magdetails["digital"] }}</td>
                <td>
                  <button
                    type="button"
                    class="btn btn-danger btn-sm"
                    (click)="removeMag(magdetails['name'])"
                  >
                    Remove
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div> -->

    <!-- <div>
          <div class="text-center">
            <h1 style="font-size: medium; font-weight: bold">
              Change Magazine Price :
            </h1>
          </div>

          <form
            class="form"
            [formGroup]="magpriceupdate"
            (ngSubmit)="changeMagPrice()"
          >
            <div class="form-row">
              <div class="col form-group">
                <label style="margin-left: 20px; margin-top: 20px"
                  >Magazine Name :</label
                >
                <select
                  class="form-control"
                  style="width: 85%; margin-left: 20px"
                  placeholder="Select One"
                  formControlName="magname"
                >
                  <option
                    *ngFor="let item of magname"
                    value="{{ item['name'] }}"
                  >
                    {{ item["name"] }}
                  </option>
                </select>
              </div>

              <div class="col form-group">
                <label style="margin-left: 20px; margin-top: 20px"
                  >Magazine Edition :</label
                >
                <select
                  class="form-control"
                  style="width: 85%; margin-left: 20px"
                  placeholder="Select One"
                  formControlName="magedition"
                >
                  <option value="Print">Print</option>
                </select>
              </div>

              <div class="col form-group">
                <label style="margin-left: 20px; margin-top: 20px"
                  >Magazine Year :</label
                >
                <select
                  class="form-control"
                  style="width: 85%; margin-left: 20px"
                  placeholder="Select One"
                  formControlName="magyear"
                >
                  <option value="1">1</option>
                  <option value="3">3</option>
                  <option value="5">5</option>
                </select>
              </div>
            </div>
            <div class="form-row">
              <div class="col form-group">
                <label style="margin-left: 35px; margin-top: 20px"
                  >Magazine Price :</label
                >
                <input
                  required
                  style="width: 90%; margin-left: 35px"
                  formControlName="magamount"
                  type="number"
                  class="form-control"
                  placeholder=""
                />
              </div>
            </div>

            <div class="form-group">
              <button
                style="margin-left: 200px; width: 50%"
                [disabled]="magpriceupdate.invalid"
                type="submit"
                class="btn btn-danger btn-block"
              >
                Change Price
              </button>
            </div>
          </form>
        </div> -->

    <!-- <div>
          <div class="text-center">
            <h1 style="font-size: medium; font-weight: bold">
              Add New Magazine
            </h1>
          </div>
          <form
            class="form"
            [formGroup]="magform"
            (ngSubmit)="AddNewMagazine()"
          >
            <div class="form-row">
              <div class="col form-group">
                <label style="margin-left: 35px; margin-top: 20px"
                  >Magazine short Name :</label
                >
                <input
                  required
                  style="width: 90%; margin-left: 35px"
                  formControlName="magname"
                  type="text"
                  class="form-control"
                  placeholder=""
                />
              </div>

              <div class="col form-group">
                <label style="margin-left: 35px; margin-top: 20px"
                  >Magazine Full Name :</label
                >
                <input
                  required
                  style="width: 90%; margin-left: 35px"
                  formControlName="magfullname"
                  type="text"
                  class="form-control"
                  placeholder=""
                />
              </div>
            </div>
            <div class="form-row">
              <div class="col form-group">
                <label style="margin-left: 20px; margin-top: 20px"
                  >Magazine Edition :</label
                >
                <select
                  class="form-control"
                  style="margin-left: 20px"
                  placeholder="Select One"
                  formControlName="magedition"
                >
                  <option value="print">Print</option>
                  <option value="digital">Digital</option>
                </select>
              </div>

              <div class="col form-group">
                <label style="margin-left: 35px">Magazine Amount :</label>
                <input
                  required
                  style="margin-left: 35px; margin-right: 45px"
                  formControlName="magamount"
                  type="number"
                  class="form-control"
                  placeholder=""
                />
              </div>
            </div>


            <div class="form-row">
              <div class="col form-group">
                <label style="margin-left: 35px">Year : </label>
                <input
                  style="margin-left: 35px; margin-right: 45px; width: 90%"
                  formControlName="magyear"
                  type="number"
                  class="form-control"
                  placeholder=" "
                />
              </div>

              <div class="col form-group">
                <label style="margin-left: 35px"
                  >Want to display on Application :
                </label>
                <select
                  class="form-control"
                  style="margin-left: 35px; margin-right: 45px; width: 90%"
                  class="form-control"
                  placeholder="Select One"
                  formControlName="magapp"
                >
                  <option value="Y">Yes</option>
                  <option value="N">No</option>
                </select>
              </div>
            </div>
            <div class="form-group">
              <label style="margin-left: 35px">Magazine description : </label>
              <input
                style="margin-left: 35px; margin-right: 45px; width: 90%"
                formControlName="magdesc"
                type="text"
                class="form-control"
                placeholder=" "
              />
            </div>
            <div class="form-group">
              <button
                style="margin-left: 200px; width: 50%"
                [disabled]="magform.invalid"
                type="submit"
                class="btn btn-danger btn-block"
              >
                Add New Magazine
              </button>
            </div>
          </form>
        </div> -->
    <!-- </div> -->
    <!-- </mat-tab> -->

    <mat-tab label="Manage Issue Date & Size" >
      <mat-card style="margin-top: 2%;border-radius: 15px;">
        <form [formGroup]="NewMagazineForm">
          <div class="form-row">
            <mat-form-field style="width: 35%; margin-top: 10px; margin-left: 5%" appearance="outline">
              <mat-label>Magazine Name:</mat-label>
              <input matInput formControlName="mag_name" [matAutocomplete]="auto11" required />
              <mat-autocomplete autoActiveFirstOption #auto11="matAutocomplete">
                <mat-option (onSelectionChange)="selected_magName($event,option)"
                  *ngFor="let option of filteredOptions11 | async" [value]="option['mag_name']">
                  {{ option["mag_name"] }}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
            <!--<mat-form-field style="width: 35%; margin-top: 10px; margin-left: 5%" appearance="outline">
                    <mat-label>Magazine Name:</mat-label>
                    <input matInput formControlName="mag_name" />
                  </mat-form-field> -->
            <mat-form-field style="width: 35%; margin-top: 10px; margin-left: 2%" appearance="outline">
              <mat-label>Issue/Granth Category:</mat-label>
              <input matInput formControlName="ig_cat" />
            </mat-form-field>
            <div style="margin-top: 15px; margin-left: 2%;">
              <button class="btn btn-info" type="button" (click)="addmags()">Add</button>
            </div>
          </div>
          <div class="tableFixHead" style="margin-left: 5%; margin-right: 5%;">
            <table class="table table-striped text-center">
              <thead>
                <tr>
                  <th>Magazine Name</th>
                  <th>Issue/Granth Category</th>
                  <th colspan="2">Action</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let item of magazineData; let i=index">
                  <td>{{ item['mag_name'] }}</td>
                  <td>{{ item['ig_cat'] }}</td>
                  <td colspan="2">
                    <button matTooltip="Update" mat-raised-button class="btn-sm btn-outline-info"
                      style="margin-right: 2%;" type="button" (click)="updateItem(item, i)">
                      <mat-icon>edit</mat-icon>
                    </button>
                    <button matTooltip="Remove" mat-raised-button class="btn-sm btn-outline-danger" type="button"
                      (click)="removeItem(item, i)">
                      <mat-icon>close</mat-icon>
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="form-group">
            <button mat-raised-button class="btn btn-primary" style="margin-left: 30%;width: 25%;" type="button"
              (click)="addNewMagazine()">Add Magazine</button>
          </div>
        </form>
      </mat-card>

      <!-- <mat-card class="IssueGM" style="margin-top: 20px">
        <mat-card-title style="text-align: center">Add Issues & Category</mat-card-title>
        <div style="margin-top: 20px">
          <form class="form" [formGroup]="addIssueGranthaForm" (ngSubmit)="addIssueGrantha()" *ngIf="isAdminAuthorize">
            <div class="form-row">
              <mat-form-field class="example-full-width" style="width: 40%; margin-top: 10px; margin-left: 45px"
                appearance="outline">
                <mat-label>Issue/Grantha Name:</mat-label>
                <input matInput formControlName="igcategory" required />
              </mat-form-field>
              <mat-form-field class="example-full-width" style="margin-left: 10px; width: 40%; margin-top: 10px"
                appearance="outline">
                <mat-label>Issue/Grantha Category :</mat-label>
                <input matInput formControlName="igsubcategory" required />
              </mat-form-field>
            </div>
            
            <div class="form-group">
              <button style="margin-left: 200px; width: 50%" [disabled]="
                  addIssueGranthaForm.invalid && this.newDateArray.lenth != 0
                " type="submit" class="btn btn-danger btn-block">
                Add Issue/Grantha
              </button>
            </div>
          </form>
        </div>
      </mat-card> -->

      <!-- <mat-tab label="Manage Advt. Sizes"> -->
      <mat-card style="margin-top: 20px">
        <mat-card-title style="text-align: center">Add Issue Sizes</mat-card-title>
        <div style="margin-top: 20px">
          <form class="form" [formGroup]="advtSizesForm" (ngSubmit)="save_advtSizes()">
            <div class="form-row">
              <mat-form-field style="margin-left: 45px; margin-top: 10px; width: 40%" appearance="outline">
                <mat-label>Issue/Grantha Category : </mat-label>
                <mat-select (selectionChange)="getAdvtCategory($event)" required>
                  <mat-option *ngFor="let item of advtCategorylist" value="{{ item['category'] }}">
                    {{ item["category"] }}
                  </mat-option>
                </mat-select>
              </mat-form-field>

              <mat-form-field style="margin-left: 20px; margin-top: 10px; width: 40%" class="example-full-width"
                appearance="outline">
                <mat-label>Advt. Sizes :</mat-label>
                <input type="text" formControlName="advt_size_name" matInput required />
              </mat-form-field>
            </div>

            <div class="form-group">
              <button [disabled]="advtSizesForm.invalid" style="margin-left: 25%; width: 40%" type="submit"
                class="btn btn-danger btn-block">
                Add Advt. Sizes
              </button>
            </div>
          </form>
        </div>
        <div class="tableFixHead">
          <table class="table table-striped text-center">
            <thead>
              <tr>
                <th>Sr. No.</th>
                <th>Issue/Granth Category</th>
                <th>Advt. Size</th>
                <th colspan="2">Action</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of FilteredadvtsizesData; let i=index">
                <td>{{ i+1 }}</td>
                <td>{{ item['category'] }}</td>
                <td>{{ item['Advt_size'] }}</td>
                <td><button type="button" matTooltip="Update" mat-raised-button class="btn-sm btn-outline-info"
                    (click)="updateAdvtSize(item)">
                    <mat-icon>edit</mat-icon>
                  </button>
                  <button type="button" matTooltip="Remove" mat-raised-button class="btn-sm btn-outline-danger"
                    (click)="removeAdvtSize(item)">
                    <mat-icon>close</mat-icon>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </mat-card>

      <mat-card style="margin-top: 20px">
        <mat-card-title style="text-align: center">Add Issue Dates</mat-card-title>
        <div style="margin-top: 20px">
          <form class="form" [formGroup]="IssueDates" (ngSubmit)="save_issue_dates()">
            <div class="form-row">
              <mat-form-field style="margin-left: 45px; margin-top: 20px; width: 25%" appearance="outline">
                <mat-label>Issue/Grantha Category : </mat-label>
                <mat-select (selectionChange)="getAdvtCategory1($event)" required>
                  <mat-option *ngFor="let item of advtCategorylist" value="{{ item['category'] }}">
                    {{ item["category"] }}
                  </mat-option>
                </mat-select>
              </mat-form-field>

              <mat-form-field style="margin-left: 3%; margin-top: 20px; width: 25%" appearance="outline">
                <mat-label>Select Issue date</mat-label>
                <input matInput required formControlName="issuedate" (dateChange)="selectDateChange()"
                  [matDatepicker]="picker" />
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
              </mat-form-field>

              <mat-form-field style="margin-left: 3%; margin-top: 20px; width: 25%" appearance="outline">
                <mat-label>Select Published date</mat-label>
                <input matInput required formControlName="publisheddate" [matDatepicker]="picker1" />
                <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                <mat-datepicker #picker1></mat-datepicker>
              </mat-form-field>
            </div>
            <div class="form-row">
              <mat-form-field class="example-full-width" style="width: 40%; margin-top: 10px; margin-left: 45px"
                appearance="outline">
                <mat-label>Issue name</mat-label>
                <input matInput formControlName="issue_name" required />
              </mat-form-field>

              <mat-form-field class="example-full-width" style="margin-left: 10px; width: 40%; margin-top: 10px"
                appearance="outline">
                <mat-label>Price</mat-label>
                <input matInput formControlName="issue_price" required />
              </mat-form-field>
            </div>
            <div class="form-row">
              <mat-form-field class="example-full-width" style="margin-left: 45px; width: 40%; margin-top: 10px"
                appearance="outline">
                <mat-label>Language</mat-label>
                <mat-select (selectionChange)="getAdvtCategory1($event)" formControlName="lang" required>
                  <mat-option *ngFor="let item of getLanguageList1" value="{{ item['Languages'] }}">
                    {{ item["Languages"] }}
                  </mat-option>
                </mat-select>
                <!-- <input matInput formControlName="lang" required /> -->
              </mat-form-field>
            </div>
            <div class="form-group">
              <label style="margin-left: 45px;">Pages</label>
              <div class="form-row">
                <mat-form-field class="example-full-width" style="margin-left: 45px; width: 20%; margin-top: 10px"
                  appearance="outline">
                  <mat-label>Black White</mat-label>
                  <input type="number" matInput formControlName="blackwhite" (change)="calcPages($event)" />
                </mat-form-field>
                <mat-form-field class="example-full-width" style="margin-left: 2%; width: 18%; margin-top: 10px"
                  appearance="outline">
                  <mat-label>Inside Colour</mat-label>
                  <input type="number" matInput formControlName="insidecolour" (change)="calcPages($event)" />
                </mat-form-field>
                <mat-form-field class="example-full-width" style="margin-left: 2%; width: 18%; margin-top: 10px"
                  appearance="outline">
                  <mat-label>Cover</mat-label>
                  <input type="number" matInput formControlName="cover" (change)="calcPages($event)" />
                </mat-form-field>
                <mat-form-field class="example-full-width" style="margin-left: 2%; width: 18%; margin-top: 10px"
                  appearance="outline">
                  <mat-label>Total Pages</mat-label>
                  <input type="number" matInput formControlName="totalpages" readonly />
                </mat-form-field>
              </div>
            </div>
            <div class="form-group">
              <label style="margin-left: 45px;">Paper</label>
              <div class="form-row">
                <mat-form-field class="example-full-width" style="margin-left: 45px; width: 25%; margin-top: 10px"
                  appearance="outline">
                  <mat-label>Maflitho</mat-label>
                  <input matInput formControlName="maflitho" />
                </mat-form-field>
                <mat-form-field class="example-full-width" style="margin-left: 3%; width: 25%; margin-top: 10px"
                  appearance="outline">
                  <mat-label>LWC</mat-label>
                  <input matInput formControlName="lwc" />
                </mat-form-field>
                <mat-form-field class="example-full-width" style="margin-left: 3%; width: 25%; margin-top: 10px"
                  appearance="outline">
                  <mat-label>Art Paper</mat-label>
                  <input matInput formControlName="artpaper" />
                </mat-form-field>
              </div>
            </div>
            <div class="form-group">
              <label style="margin-left: 45px;">Paper Reams</label>
              <div class="form-row">
                <mat-form-field class="example-full-width" style="margin-left: 45px; width: 25%; margin-top: 10px"
                  appearance="outline">
                  <mat-label></mat-label>
                  <input matInput formControlName="paperreams1" />
                </mat-form-field>
                <mat-form-field class="example-full-width" style="margin-left: 3%; width: 25%; margin-top: 10px"
                  appearance="outline">
                  <mat-label></mat-label>
                  <input matInput formControlName="paperreams2" />
                </mat-form-field>
                <mat-form-field class="example-full-width" style="margin-left: 3%; width: 25%; margin-top: 10px"
                  appearance="outline">
                  <mat-label></mat-label>
                  <input matInput formControlName="paperreams3" />
                </mat-form-field>
              </div>
            </div>
            <div class="form-row">
              <mat-form-field class="example-full-width" style="margin-left: 45px; width: 25%; margin-top: 10px"
                appearance="outline">
                <mat-label>Issue Size</mat-label>
                <input matInput formControlName="issuesize" />
              </mat-form-field>
              <mat-form-field class="example-full-width" style="margin-left: 3%; width: 25%; margin-top: 10px"
                appearance="outline">
                <mat-label>Per Copy Weight</mat-label>
                <input matInput formControlName="percopyweight" />
              </mat-form-field>
              <mat-form-field class="example-full-width" style="margin-left: 3%; width: 25%; margin-top: 10px"
                appearance="outline">
                <mat-label>Printed Copies</mat-label>
                <input type="number" matInput formControlName="prntdcopies" />
              </mat-form-field>
            </div>
            <div class="form-group">
              <mat-form-field appearance="outline" style="margin-left: 45px; width: 25%;">
                <mat-label>RNI Releated</mat-label>
                <mat-select formControlName="rnireleated" required>
                  <mat-option value="yes">Yes</mat-option>
                  <mat-option value="no">No</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="form-group">
              <button [disabled]="IssueDates.invalid" style="margin-left: 27%; width: 40%" type="submit"
                class="btn btn-danger btn-block">
                Add New Dates
              </button>
            </div>
          </form>
        </div>
      </mat-card>

      <mat-card>
        <div class="tableFixHead" style="margin-left: 5%; margin-right: 5%;">
          <table class="table table-striped text-center">
            <thead>
              <tr>
                <th>Sr. No.</th>
                <th>Issue Name</th>
                <th>Issue Category</th>
                <th>Issue Date</th>
                <th>Published Date</th>
                <th>Price</th>
                <th>Language</th>
                <th colspan="2">Action</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of FilteredissueDatesData; let i = index">
                <td>{{ i + 1 }}</td>
                <td>{{ item['issueName'] }}</td>
                <td>{{ item['advt_category'] }}</td>
                <td>{{ item['issueDate'] | date: "dd-MM-yyy" }}</td>
                <td>{{ item['PublishedDate'] | date: "dd-MM-yyy" }}</td>
                <td>{{ item['price'] }}</td>
                <td>{{ item['lang'] }}</td>
                <td colspan="2">
                  <button matTooltip="Update" mat-raised-button class="btn-sm btn-outline-info"
                    style="margin-right: 2%;" type="button" (click)="updateIssueDates(item)">
                    <mat-icon>edit</mat-icon>
                  </button>
                  <button matTooltip="Remove" mat-raised-button class="btn-sm btn-outline-danger" type="button"
                    (click)="removeIssueDates(item)">
                    <mat-icon>close</mat-icon>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <!-- <div class="add-new-subscriber mat-elevation-z4" style="overflow-x: scroll !important" id="excel-table">
          <div style="margin-top: 20px" class="tableFixHead">
            <table class="table table-striped text-center">
              <thead>
                <tr>
                  <th>अ.क्र.</th>
                  <th>Category</th>
                  <th>प्रकाशनाच्या तारखा</th>
                  <th colspan="2">Action</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let advt of this.Filteredadvt_mag_name_details; let i = index">
                  <td>{{ i + 1 }}</td>
                  <td>{{ advt["category"] }}</td>
                  <td>
                    <mat-form-field style="margin-left: 3%">
                      <mat-label>Publishig Dates</mat-label>
                      <mat-select name="issue">
                        <mat-option *ngFor="
                            let issue of advt['publishing_date'];
                            let newindex = index
                          " [value]="issue">
                          {{ issue | date: "dd-MM-yyy" }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </td>

                  <td>
                    <button type="button" matTooltip="Update" mat-raised-button class="btn-sm btn-outline-info"
                      (click)="manageAdvtDates(advt)">
                      <mat-icon>edit</mat-icon>
                    </button>
                    <button *ngIf="advt['is_active'] == 'Y'" type="button" matTooltip="Inactivate" matTooltip="Inactive"
                      mat-raised-button class="btn-sm btn-outline-danger" (click)="remove_mag_advt(advt)">
                      <mat-icon>close</mat-icon>
                    </button>
                    <button *ngIf="advt['is_active'] == 'N'" type="button" matTooltip="Activate" mat-raised-button
                      class="btn-sm btn-outline-success" (click)="make_it_activate(advt)">
                      <mat-icon>done</mat-icon>
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div> -->
      </mat-card>
    </mat-tab>

    <mat-tab label="Manage States">
      <div class="add-new-subscriber mat-elevation-z4" style="overflow-x: scroll !important">
        <div style="margin-top: 20px">
          <div class="text-center">
            <h1 style="font-size: medium; font-weight: bold">Add New State</h1>
          </div>
          <form class="form" [formGroup]="StateMaster" (ngSubmit)="save_state()">
            <div class="form-row">
              <div class="col form-group">
                <label style="margin-left: 35px; margin-top: 20px">State Name :</label>
                <input required style="width: 90%; margin-left: 35px" formControlName="State_name" type="text"
                  class="form-control" placeholder="" />
              </div>
              <!-- form-group end.// -->
            </div>

            <div class="form-group">
              <button [disabled]="StateMaster.invalid" style="margin-left: 27%; width: 40%" type="submit"
                class="btn btn-danger btn-block">
                Add new State
              </button>
            </div>
            <!-- form-group// -->
          </form>
        </div>

        <div class="tableFixHead">
          <table class="table table-striped text-center table-fixed">
            <thead>
              <th>Sr.No.</th>
              <th>State</th>
              <th>Action</th>
            </thead>
            <tbody>
              <tr *ngFor="let item of this.states; let i = index">
                <td>{{ i + 1 }}</td>
                <td>{{ item["state_name"] }}</td>
                <td>
                  <button type="button" class="btn-sm btn-outline-info" matTooltip="Update" (click)="updateState(item)">
                    <mat-icon>edit</mat-icon>
                  </button>
                  <button type="button" style="margin-left: 5%;" type="button" class="btn-outline-danger btn-sm"
                    matTooltip="Remove" (click)="removeState(item['state_id'])">
                    <mat-icon>delete</mat-icon>
                  </button>
                </td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <td>Total : {{ state_count }}</td>
                <td colspan="2"></td>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
    </mat-tab>

    <mat-tab label="Manage Prant">
      <div class="add-new-subscriber mat-elevation-z4" style="overflow-x: scroll !important">
        <div style="margin-top: 20px">
          <div class="text-center">
            <h1 style="font-size: medium; font-weight: bold">Add New Prant</h1>
          </div>

          <form class="form" [formGroup]="prantMaster" (ngSubmit)="save_prant()">
            <div class="form-row">
              <div class="col form-group">
                <label style="margin-left: 35px; margin-top: 20px">Prant Name :</label>
                <input required style="width: 90%; margin-left: 35px" formControlName="prant_name" class="form-control"
                  type="text" placeholder="" />
              </div>
              <!-- form-group end.// -->
            </div>

            <div class="form-group">
              <button [disabled]="prantMaster.invalid" style="margin-left: 27%; width: 40%" type="submit"
                class="btn btn-danger btn-block">
                Add New Prant
              </button>
            </div>
            <!-- form-group// -->
          </form>
        </div>

        <div class="tableFixHead">
          <table class="table table-striped text-center table-fixed">
            <thead>
              <th>Sr.No.</th>
              <th>Prant Name</th>
              <th>Action</th>
            </thead>
            <tbody>
              <tr *ngFor="let item of this.prantData; let i = index">
                <td>{{ i + 1 }}</td>
                <td>{{ item["prant_name"] }}</td>
                <td>
                  <button type="button" class="btn-sm btn-outline-info" matTooltip="Update" (click)="updatePrant(item)">
                    <mat-icon>edit</mat-icon>
                  </button>
                  <button type="button" style="margin-left: 5%;" type="button" class="btn-outline-danger btn-sm"
                    matTooltip="Remove" (click)="removePrant(item['id'])">
                    <mat-icon>delete</mat-icon>
                  </button>
                </td>
              </tr>
            </tbody>
            <!-- <tfoot>
              <tr>
                <td>Total : {{ city_count }}</td>
                <td colspan="2"></td>
              </tr>
            </tfoot> -->
          </table>
        </div>
      </div>
    </mat-tab>

    <mat-tab label="Manage District">
      <div class="add-new-subscriber mat-elevation-z4" style="overflow-x: scroll !important">
        <div style="margin-top: 20px">
          <div class="text-center">
            <h1 style="font-size: medium; font-weight: bold">Add New City</h1>
          </div>
          <mat-form-field appearance="fill" style="margin-left: 3%">
            <mat-label>Select State : </mat-label>
            <mat-select (selectionChange)="getState($event)">
              <mat-option *ngFor="let item of states" value="{{ item['state_id'] }}">{{ item["state_name"] }}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <form class="form" [formGroup]="CityMaster" (ngSubmit)="save_city()">
            <div class="form-row">
              <div class="col form-group">
                <label style="margin-left: 35px; margin-top: 20px">City Name :</label>
                <input required style="width: 90%; margin-left: 35px" formControlName="city_name" class="form-control"
                  type="text" placeholder="" />
              </div>
              <div class="col form-group">
                <label style="margin-left: 35px; margin-top: 20px">Prant Name :</label>
                <mat-form-field appearance="outline" style="width: 90%; margin-left: 35px">
                  <mat-select formControlName="prant_name" required>
                    <mat-option *ngFor="let item of prantData" value="{{ item['id'] }}">{{ item["prant_name"] }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <!-- form-group end.// -->
            </div>

            <div class="form-group">
              <button [disabled]="CityMaster.invalid" style="margin-left: 27%; width: 40%" type="submit"
                class="btn btn-danger btn-block">
                Add new City
              </button>
            </div>
            <!-- form-group// -->
          </form>
        </div>

        <div class="tableFixHead">
          <mat-form-field appearance="fill" style="margin-left: 3%">
            <mat-label>Select State : </mat-label>
            <mat-select (selectionChange)="getChangeState($event)">
              <mat-option *ngFor="let item of states" value="{{ item['state_id'] }}">{{ item["state_name"] }}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <table class="table table-striped text-center table-fixed">
            <thead>
              <th>Sr.No.</th>
              <th>City</th>
              <th>Prant</th>
              <th>Action</th>
            </thead>
            <tbody>
              <tr *ngFor="let item of this.getAllCities; let i = index">
                <td>{{ i + 1 }}</td>
                <td>{{ item["name"] }}</td>
                <td>{{ item['prant_name'] }}</td>
                <td>
                  <button type="button" class="btn-sm btn-outline-info" matTooltip="Update"
                    (click)="updateDistrict(item)">
                    <mat-icon>edit</mat-icon>
                  </button>
                  <button type="button" style="margin-left: 5%;" type="button" class="btn-outline-danger btn-sm"
                    matTooltip="Remove" (click)="removeCity(item['id'])">
                    <mat-icon>delete</mat-icon>
                  </button>
                </td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <td>Total : {{ city_count }}</td>
                <td colspan="3"></td>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
    </mat-tab>

    <mat-tab label="Manage Talukas">
      <mat-card style="border-radius: 25px;
    margin: 02% 02%;">
        <h2 style="text-align: center;">Add New Taluka</h2>
        <mat-form-field appearance="fill" style="margin-left: 3%">
          <mat-label>Select State : </mat-label>
          <mat-select (selectionChange)="getChangeState($event)">
            <mat-option *ngFor="let item of states" value="{{ item['state_id'] }}">{{ item["state_name"] }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field appearance="fill" style="margin-left: 3%">
          <mat-label>Select District : </mat-label>
          <mat-select (selectionChange)="getDistrict($event)">
            <mat-option *ngFor="let item of getAllCities" value="{{ item['id'] }}">{{ item["name"] }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <form class="form" [formGroup]="TalukaMaster" (ngSubmit)="save_taluka()">
          <div class="form-row">
            <div class="col form-group">
              <label style="margin-left: 35px; margin-top: 20px">Taluka Name :</label>
              <input required style="width: 90%; margin-left: 35px" formControlName="taluka_name" type="text"
                class="form-control" placeholder="" />
            </div>
            <div class="col form-group">
              <label style="margin-left: 35px; margin-top: 20px">Pincode :</label>
              <input required style="width: 90%; margin-left: 35px" formControlName="pincode" type="number"
                class="form-control" placeholder="" />
            </div>
            <!-- form-group end.// -->
          </div>

          <div class="form-group">
            <button  style="margin-left: 27%; width: 40%" type="submit"
              class="btn btn-danger btn-block">
              Add New Taluka
            </button>
          </div>
          <!-- form-group// -->
        </form>

        <div class="tableFixHead">
          <mat-form-field appearance="fill" style="margin-left: 3%">
            <mat-label>Select State : </mat-label>
            <mat-select (selectionChange)="getChangeState($event)">
              <mat-option *ngFor="let item of states" value="{{ item['state_id'] }}">{{ item["state_name"] }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field appearance="fill" style="margin-left: 3%">
            <mat-label>Select District : </mat-label>
            <mat-select (selectionChange)="getChangeDistrict($event)">
              <mat-option *ngFor="let item of getAllCities" value="{{ item['id'] }}">{{ item["name"] }}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <table class="table table-striped text-center table-fixed">
            <thead>
              <th>Sr.No.</th>
              <th>Taluka</th>
              <th>Pincode</th>
              <th>Action</th>
            </thead>
            <tbody>
              <tr *ngFor="let item of this.getAllTalukas; let i = index">
                <td>{{ i + 1 }}</td>
                <td>{{ item["taluka_name"] }}</td>
                <td>{{ item["pincode"] }}</td>
                <td>
                  <button type="button" class="btn-sm btn-outline-info" matTooltip="Update"
                    (click)="updateTaluka(item)">
                    <mat-icon>edit</mat-icon>
                  </button>
                  <button type="button" style="margin-left: 5%;" type="button" class="btn-outline-danger btn-sm"
                    matTooltip="Remove" (click)="removeTaluka(item['taluka_id'])">
                    <mat-icon>delete</mat-icon>
                  </button>
                </td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <td>Total : {{ taluka_count }}</td>
                <td colspan="3"></td>
              </tr>
            </tfoot>
          </table>
        </div>
      </mat-card>

      <!-- <mat-card style="border-radius: 25px;
    margin: 02% 02%;">
        
      </mat-card> -->
    </mat-tab>

    <mat-tab label="Manage Titles">
      <div class="add-new-subscriber mat-elevation-z4" style="overflow-x: scroll !important">
        <div style="margin-top: 20px">
          <div class="text-center">
            <h1 style="font-size: medium; font-weight: bold">Add New Titles</h1>
          </div>
          <form class="form" [formGroup]="MasterTable" (ngSubmit)="save()">
            <div class="form-row">
              <div class="col form-group">
                <label style="margin-left: 35px; margin-top: 20px">Title :</label>
                <input required style="width: 90%; margin-left: 35px" formControlName="title" type="text"
                  class="form-control" placeholder="" />
              </div>
              <!-- form-group end.// -->
            </div>

            <div class="form-group">
              <button [disabled]="MasterTable.invalid" style="margin-right: 27%; width: 40%" type="submit"
                class="btn btn-danger btn-block">
                Add new title
              </button>
            </div>
            <!-- form-group// -->
          </form>
        </div>

        <div class="tableFixHead">
          <table class="table table-striped text-center table-fixed">
            <thead>
              <th>Sr.No.</th>
              <th>Title</th>
              <th>Action</th>
            </thead>
            <tbody>
              <tr *ngFor="let item of this.titles; let i = index">
                <td>{{ i + 1 }}</td>
                <td>{{ item["title"] }}</td>
                <td>
                  <button type="button" class="btn-danger btn-sm" (click)="removeTitle(item['title'])">
                    Remove
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </mat-tab>

    <mat-tab label="Manage Occupation">
      <div class="add-new-subscriber mat-elevation-z4" style="overflow-x: scroll !important">
        <div style="margin-top: 20px">
          <div class="text-center">
            <h1 style="font-size: medium; font-weight: bold">
              Add New Occupation
            </h1>
          </div>
          <form class="form" [formGroup]="OccupationMaster" (ngSubmit)="save_occupation()">
            <div class="form-row">
              <div class="col form-group">
                <label style="margin-left: 35px; margin-top: 20px">Occupation :</label>
                <input required style="width: 90%; margin-left: 35px" formControlName="occupation" type="text"
                  class="form-control" placeholder="" />
              </div>
              <!-- form-group end.// -->
            </div>

            <div class="form-group">
              <button [disabled]="OccupationMaster.invalid" style="margin-right: 27%; width: 40%" type="submit"
                class="btn btn-danger btn-block">
                Add new occupation
              </button>
            </div>
            <!-- form-group// -->
          </form>
        </div>

        <div style="margin-top: 20%" class="tableFixHead">
          <table class="table table-striped text-center table-fixed">
            <thead>
              <th>Sr.No.</th>
              <th>Occupation</th>
              <th>Action</th>
            </thead>
            <tbody>
              <tr *ngFor="let item of this.occupations; let i = index">
                <td>{{ i + 1 }}</td>
                <td>{{ item["occupation"] }}</td>
                <td>
                  <button type="button" class="btn-danger btn-sm" (click)="removeOccupdation(item['occupation'])">
                    Remove
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </mat-tab>

    <mat-tab label="Representative">
      <mat-card class="card">
        <form class="form" [formGroup]="addform" (ngSubmit)="addRepresentativeForm()">
          <div class="form-row">
            <mat-form-field style="margin-left: 7%; margin-top: 10px; width: 40%" class="example-full-width"
              appearance="outline">
              <mat-label>Issue Seller Name :</mat-label>
              <input type="text" formControlName="issuesellername" matInput [matAutocomplete]="autorep" />
              <mat-autocomplete autoActiveFirstOption #autorep="matAutocomplete">
                <mat-option (onSelectionChange)="selected_rep_name($event, option)"
                  *ngFor="let option of filteredOptions4 | async" [value]="option['name']">
                  {{ option["name"] }}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>

            <mat-form-field style="margin-left: 20px; margin-top: 10px; width: 40%" class="example-full-width"
              appearance="outline">
              <mat-label>Mobile No :</mat-label>
              <input type="number" formControlName="mnumber" matInput [matAutocomplete]="automob"
                placeholder="Search here" />
              <mat-autocomplete autoActiveFirstOption #automob="matAutocomplete">
                <mat-option (onSelectionChange)="selected_rep_name($event, option)"
                  *ngFor="let option of filteredOptions5 | async" [value]="option['mob_no']">
                  {{ option["mob_no"] }}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
          </div>
          <div class="form-row">
            <mat-form-field class="example-full-width" style="width: 60%; margin-left: 7%; margin-top: 10px"
              appearance="outline">
              <mat-label>Address :</mat-label>
              <textarea matInput placeholder="Address" formControlName="address1"></textarea>
            </mat-form-field>
          </div>
          <div class="form-row">
            <mat-form-field style="margin-left: 7%; margin-top: 10px; width: 24%" class="example-full-width"
              appearance="outline">
              <mat-label>Pincode</mat-label>
              <input (change)="getPincode1($event)" matInput #postalCode1 maxlength="6" placeholder=""
                formControlName="pincode" required />
              <mat-hint align="end">{{ postalCode1.value.length }} / 6</mat-hint>
            </mat-form-field>

            <mat-form-field style="width: 27%; margin-left: 15px; margin-top: 10px" appearance="outline">
              <mat-label>Country</mat-label>
              <mat-select (selectionChange)="getCountry($event)" formControlName="country" required>
                <mat-option *ngFor="let c of countries" value="{{ c['country_name'] }}">{{ c["country_name"] }}
                </mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field style="width: 28%; margin-left: 15px; margin-top: 10px" appearance="outline">
              <mat-label>State</mat-label>
              <mat-select (selectionChange)="getState($event)" formControlName="state" required>
                <mat-option *ngFor="let c of states" value="{{ c['state_name'] }}">{{ c["state_name"] }}
                </mat-option>
              </mat-select>
              <span>{{ selectedState }}</span>
            </mat-form-field>
          </div>

          <div class="form-row">
            <mat-form-field style="width: 40%; margin-left: 7%; margin-top: 10px" appearance="outline">
              <mat-label>District</mat-label>
              <mat-select formControlName="district" (selectionChange)="getDistricts($event)" required>
                <mat-option *ngFor="let c of cities" value="{{ c['district_name'] }}">
                  {{ c["district_name"] }}
                </mat-option>
              </mat-select>
              <span>{{ selectedCity }}</span>
            </mat-form-field>

            <!-- <mat-form-field class="example-full-width" style="width: 40%; margin-left: 20px; margin-top: 10px"
              appearance="outline">
              <mat-label>Taluka</mat-label>
              <input matInput formControlName="taluka" required />
            </mat-form-field> -->
            <mat-form-field style="width: 23%; margin-left: 3%; margin-top: 10px" appearance="outline">
              <mat-label>Taluka</mat-label>
              <mat-select formControlName="taluka">
                <mat-option *ngFor="let c of talukas" value="{{ c['taluka_name'] }}">{{ c["taluka_name"] }}</mat-option>
              </mat-select>
              <span>{{ selectedTaluka }}</span>
            </mat-form-field>
          </div>
          <div class="form-row">
            <mat-form-field style="margin-left: 7%; margin-top: 10px; width: 40%"
              [style.color]="validmobile ? null : 'red'" class="example-full-width">
              <mat-label>Mobile</mat-label>
              <span matPrefix style="color: gray">+91 &nbsp;</span>
              <input type="tel" #mobile1 (change)="validateMobile($event)" maxlength="10" formControlName="mnumber"
                matInput />
              <span matSuffix style="color: gray">
                <mat-icon> call</mat-icon>
              </span>
              <mat-hint align="end">{{ mobile1.value.length }} / 10</mat-hint>
              <mat-error *ngIf="validmobile">
                Please enter a valid mobile no.
              </mat-error>
              <mat-error *ngIf="validmobile">
                Mobile no. is <strong>required</strong>
              </mat-error>
            </mat-form-field>
            <mat-form-field class="example-full-width" style="width: 40%; margin-left: 20px; margin-top: 10px"
              [style.color]="validemail ? null : 'red'" appearance="outline">
              <mat-label>Email</mat-label>
              <input (change)="validateEmail($event)" type="email" matInput formControlName="email"
                placeholder="Ex. pat@example.com" />
              <span matSuffix style="color: gray">
                <mat-icon> email</mat-icon>
              </span>
              <mat-error *ngIf="validemail">
                Please enter a valid email address
              </mat-error>
              <mat-error *ngIf="validemail">
                Email is <strong>required</strong>
              </mat-error>
            </mat-form-field>
          </div>
          <div class="form-row">
            <mat-form-field class="example-full-width" style="margin-left: 7%; margin-top: 10px; width: 40%"
              appearance="outline">
              <mat-label>Landline No.</mat-label>
              <input type="text" #land1 maxlength="12" formControlName="landline_no" matInput />
              <mat-hint align="end">{{ land1.value.length }} / 12</mat-hint>
            </mat-form-field>

            <!-- <label style="margin-left: 10px; margin-top: 10px"
              >Date of Birth :
            </label> -->

            <mat-form-field style="margin-left: 20px; margin-top: 10px; width: 40%" appearance="outline">
              <mat-label>Date of Birth</mat-label>
              <input matInput type="date" formControlName="dob" (dateChange)="validateDob($event)" />
              <mat-error *ngIf="validdob"> Please enter a valid DOB </mat-error>
              <mat-error *ngIf="validdob">
                DOB is <strong>required</strong>
              </mat-error>
            </mat-form-field>

            <!-- <mat-form-field style="margin-left: 20px; margin-top: 10px; width: 40%" appearance="outline">
              <mat-label>Date of Birth</mat-label>
              <input formControlName="dob" matInput [matDatepicker]="datepick" (dateChange)="validatedob($event)" />
              <mat-datepicker-toggle matSuffix [for]="datepick"></mat-datepicker-toggle>
              <mat-datepicker #datepick> </mat-datepicker>
              <mat-error *ngIf="validdob"> Please enter a valid DOB </mat-error>
              <mat-error *ngIf="validdob">
                DOB is <strong>required</strong>
              </mat-error>
            </mat-form-field> -->
          </div>
          <div class="form-row">
            <mat-form-field style="margin-left: 7%; width: 40%; margin-top: 10px" class="example-full-width"
              appearance="outline">
              <mat-label>Gst No.</mat-label>
              <input matInput maxlength="15" #gst1 type="text" formControlName="gstno" />
              <mat-hint align="end">{{ gst1.value.length }} / 15</mat-hint>
            </mat-form-field>
            <mat-form-field style="margin-left: 20px; width: 40%; margin-top: 10px" class="example-full-width"
              appearance="outline">
              <mat-label>PAN No.</mat-label>
              <input matInput maxlength="10" #pan1 type="text" formControlName="panno" />
              <mat-hint align="end">{{ pan1.value.length }} / 10</mat-hint>
            </mat-form-field>
          </div>
          <div class="form-row">
            <mat-form-field style="margin-left: 7%; margin-top: 10px; width: 40%" class="example-full-width"
              appearance="outline">
              <mat-label>Deposit Amount &#8377;:</mat-label>
              <input type="number" formControlName="depositamt" matInput />
            </mat-form-field>
          </div>
          <div class="form-row">
            <mat-radio-group aria-label="Select an option" style="margin-left: 7%" formControlName="option"
              (change)="select($event)">
              <mat-radio-button value="agent">Agent</mat-radio-button>
              <mat-radio-button value="representative" style="margin-left: 20px">Representative</mat-radio-button>
              <mat-radio-button value="distributor" style="margin-left: 20px">Distributor</mat-radio-button>
              <mat-radio-button value="all" style="margin-left: 20px">All</mat-radio-button>
            </mat-radio-group>
          </div>

          <div class="form-row" style="visibility: hidden;">
            <mat-form-field style="margin-left: 7%; margin-top: 10px; width: 40%" class="example-full-width"
              appearance="outline">
              <mat-label>सप्रेम भेट</mat-label>
              <input type="number" formControlName="sampremebhet" matInput />
            </mat-form-field>
            <mat-form-field appearance="outline" style="margin-left: 5%; width: 35%">
              <mat-label>Delivery Mode</mat-label>
              <mat-select formControlName="distype">
                <mat-option *ngFor="let rep of delivery_mode" value="{{ rep['delivery_mode'] }}">{{ rep["delivery_mode"]
                  }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div>
           
      
            
                <!-- <mat-option *ngFor="let item of getMagIssueDatesandNames" value="{{ item.id }}">{{ item["issueDate"] | date :"dd/MM/yyyy" }}
      {{ item["issueName"] }}
      
      </mat-option> -->
             
          </div>

          <div class="form-group" *ngIf="!selected_repname">
            <button style="margin-left: 200px; width: 50%" [disabled]="addform.invalid" type="submit"
              class="btn btn-danger btn-block">
              Add Representative
            </button>
          </div>
          <div class="form-group" *ngIf="selected_repname">
            <button style="margin-left: 200px; width: 50%" type="button" (click)="updaterep()"
              class="btn btn-primary btn-block">
              Update Representative
            </button>
          </div>
        </form>
      </mat-card>
      <mat-card class="card">
        <div class="tableFixHead">
          <table class="table table-striped">
            <thead>
              <tr>
                <th>Sr</th>
                <th>Name</th>
                <th>Address</th>
                <th>Pincode</th>
                <th>Country</th>
                <th>State</th>
                <th>District</th>
                <th>Taluka</th>
                <th>Mobile No.</th>
                <th>Email Id</th>
                <th>Landline No.</th>
                <th>Date of Birth</th>
                <th>GST No</th>
                <th>PAN No</th>
                <th>Deposit Amount</th>
                <th>Type</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of issueSellerName;  let i = index">
                <td>{{i+1}}</td>
                <td>{{ item['issueSellerName'] }}</td>
                <td>{{ item['address'] }}</td>
                <td>{{ item['pincode'] }}</td>
                <td>{{ item['country'] }}</td>
                <td>{{ item['state'] }}</td>
                <td>{{ item['district'] }}</td>
                <td>{{ item['taluka'] }}</td>
                <td>{{ item['mobile_no'] }}</td>
                <td>{{ item['email'] }}</td>
                <td>{{ item['landline_no'] }}</td>
                <td><span *ngIf="item['dateofbirth'] !== '0000-00-00 00:00:00'">
                    {{ item['dateofbirth'] | date:"dd-MM-yyy" }}
                  </span>
                </td>
                <td>{{ item['gst_no'] }}</td>
                <td>{{ item['pan_no'] }}</td>
                <td>{{ item['deposit_amt'] }}</td>
                <td>{{ item['issueSellerType'] }}</td>
                <td><button matTooltip="Update" class="btn-sm btn-outline-info" mat-raised-button
                    (click)="updateRepData(item)">
                    <mat-icon>edit</mat-icon>
                  </button>
                  <button matTooltip="Update" class="btn-sm btn-outline-info" mat-raised-button
                  (click)="deleteoldlist(item)">
                  <mat-icon>delete</mat-icon>
                </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </mat-card>

      <mat-card class="mt-5">
        <form class="form" [formGroup]="sbhet" >
          <div class="form-row">
            <mat-form-field style="margin-left: 7%; margin-top: 10px; width: 40%" class="example-full-width"
              appearance="outline">
              <mat-label>Issue Seller Name :</mat-label>
              <input type="text" formControlName="id" matInput [matAutocomplete]="autorep1" />
              <mat-autocomplete autoActiveFirstOption1 #autorep1="matAutocomplete">
                <mat-option (onSelectionChange)="selected_rep_name1($event, option)"
                  *ngFor="let option of issue_seller_name" [value]="option['name']">
                  {{ option["name"] }}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
      
            <mat-form-field style="margin-left: 5%; width: 40%;" appearance="outline">
              <mat-label>Issue/grantha : </mat-label>
              <mat-select  (selectionChange)="getIssues($event)" formControlName="issuename"  required>
                <mat-option *ngFor="let item of advtCategorylist" value="{{ item.category }}">
                  {{ item.category }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
      
          <div class="form-row">
            <!-- <mat-form-field style="margin-left: 7%; width: 40%;" appearance="outline">
              <mat-label>Issue Dates : </mat-label>
              <mat-select #mySel2 (selectionChange)="getAdvtDates($event)" formControlName="issuedate" required>
                <mat-option *ngFor="let item of getMagIssueDatesandNames" value="{{ [item['issueDate'] | date : 'yyyy-MM-dd'] }}">{{ item["issueDate"] | date :"dd/MM/yyyy" }}
                </mat-option>
              </mat-select>
            </mat-form-field> -->
            <mat-form-field style="margin-left: 7%; width: 40%" appearance="outline">
              <mat-label>Select date</mat-label>
              <input matInput required formControlName="date" [matDatepicker]="pickerpubdatex" />
              <mat-datepicker-toggle matSuffix [for]="pickerpubdatex"></mat-datepicker-toggle>
              <mat-datepicker #pickerpubdatex></mat-datepicker>
            </mat-form-field>

            <mat-form-field style="margin-left: 5%; margin-top: 10px; width: 40%" class="example-full-width"
              appearance="outline">
              <mat-label>सप्रेम भेट</mat-label>
              <input type="number" formControlName="sbhet" matInput />
            </mat-form-field>
          </div>

          <div class="form-group">
            
          </div>
      
      
          <div class="form-group d-flex">
            
            <button style="margin-left: 80px; width: 20%"  [disabled]="sbhet.invalid" 
              class="btn btn-danger btn-block" (click)="addsbhet()">
              Add Saprem Bhet
            </button>

            <button style=" width: 20%; margin-left: 80px;"  *ngIf="updatesbhet == true"
              class="btn btn-primary btn-block" (click)="updatesbhetdata()">
              Update Saprem Bhet
            </button>
          </div>
          <!-- <div class="form-group">
            <button style="margin-left: 200px; width: 50%" type="button" (click)="updaterep()"
              class="btn btn-primary btn-block">
              Add Saprem Bhet
            </button>
          </div> -->
        </form>


        <div>
          <div class="tableFixHead">
            <table class="table table-striped">
              <thead>
                <tr>
                  <th>Sr</th>
                  <th>Issue Seller Name</th>
                  <th>Issue Name</th>
                  <th>Start Date</th>
                  <th>End Date</th>
                  <th>Saprem Bhet</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let item of sbhetdata;  let i = index">
                  <td>{{i+1}}</td>
                  <td>{{ item['issueSellerName'] }}</td>
                  <td>{{ item['issueName'] }}</td>
                  <td>{{ item['start_date'] }}</td>
                  <td>{{ item['end_date'] }}</td>
                  <td>{{ item['s_bhet'] }}</td>

                
                  <td><button matTooltip="Update" class="btn-sm btn-outline-info" mat-raised-button
                      (click)="updateSbhetData(item)">
                      <mat-icon>edit</mat-icon>
                    </button>

                    <button matTooltip="Update" class="btn-sm btn-outline-info" mat-raised-button
                      (click)="deletesbhet(item)">
                      <mat-icon>delete</mat-icon>
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      
      </mat-card>

      <mat-card class="mt-5"> 
        <div class="text-center">
          <p class="adddates">Add Subscription Stop Dates</p>
        </div>
        <form class="form" [formGroup]="startenddate" >
          <div class="form-row">
            <mat-form-field style="margin-left: 7%; width: 40%" class="example-full-width"
            appearance="outline">
            <mat-label>Issue Seller Name :</mat-label>
            <input type="text" formControlName="id" matInput [matAutocomplete]="autorep1" />
            <mat-autocomplete autoActiveFirstOption1 #autorep1="matAutocomplete">
              <mat-option (onSelectionChange)="selected_rep_name1($event, option)"
                *ngFor="let option of issue_seller_name" [value]="option['name']">
                {{ option["name"] }}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>


            <mat-form-field style="margin-left: 5%; width: 35%;" appearance="outline">
              <mat-label>Receipt Date</mat-label>
              <mat-date-range-input [rangePicker]="pickerrPR" >
                <input matStartDate formControlName="start" placeholder="Start date" />
                <input matEndDate formControlName="end" placeholder="End date"
                  (dateChange)="getChangedDateContractSE()" />
              </mat-date-range-input>
              <mat-datepicker-toggle matSuffix [for]="pickerrPR"></mat-datepicker-toggle>
              <mat-date-range-picker #pickerrPR></mat-date-range-picker>

              <!-- <mat-error *ngIf="
              startenddate.controls.start.hasError(
                                    'matStartDateInvalid'
                                  )
                                ">Invalid start date
              </mat-error>
              <mat-error *ngIf="
              startenddate.controls.end.hasError('matEndDateInvalid')
                                ">Invalid end date
              </mat-error> -->
            </mat-form-field>
          </div>
          <div class="d-flex">
            <button style="margin-left: 80px; width: 20%"  [disabled]="startenddate.invalid" 
              class="btn btn-primary btn-block" (click)="adddates()">
              Add 
            </button>
            <button style=" width: 20%; margin-left: 80px;"  *ngIf="updatesbhet == true"
            class="btn btn-primary btn-block" (click)="updatedates()">
            Update Dates
          </button>
          </div>
        </form >

        <div class="mt-5">
          <div class="tableFixHead">
            <table class="table table-striped">
              <thead>
                <tr>
                  <th>Sr</th>
                  <th>Issue Seller Name</th>
                  <th>Start Date</th>
                  <th>End Date</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let item of deactivedata;  let i = index">
                  <td>{{i+1}}</td>
                  <td>{{ item['issueSellerName'] }}</td>
                  <td>{{ item['start_date'] }}</td>
                  <td>{{ item['end_date'] }}</td>
                  <td><button matTooltip="Update" class="btn-sm btn-outline-info btn-warning" mat-raised-button
                      (click)="updateDates(item)">
                      <mat-icon>edit</mat-icon>
                    </button>

                    <button matTooltip="Update" class="btn-sm btn-outline-info btn-danger" mat-raised-button
                      (click)="deletesbhet1(item)">
                      <mat-icon>delete</mat-icon>
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>


      </mat-card>
    </mat-tab>

    <!-- <mat-tab label="Representative">
      <div class="container-fluid mt--5">
        <div class="row mt-5">
          <div class="col">
            <div class="card1 shadow">
              <div class="table-responsive">
                <div *ngIf="dataLoaded">
                  <app-editable-table
                    [columns]="['name', 'mobile', 'Actions']"
                    [editableColumns]="['name', 'mobile']"
                    [dateColumns]="['']"
                    [data]="tableData"
                    [pageSizeOptions]="[5, 6, 9, 12, 20, 50, 100]"
                    [searchable]="true"
                    (action)="action($event)"
                    [notification]="yourMessage"
                    [maxChar]="23"
                  >
                  </app-editable-table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </mat-tab> -->
    <mat-tab label="Cancel Representative">
      <div class="cancel-subscriber mat-elevation-z4">
        <form class="form" [formGroup]="form2" (ngSubmit)="cancelRepresentativeForm()">
          <div class="form-row">
            <div class="col form-group">
              <label style="margin-left: 35px; margin-top: 20px">Name</label>
              <input required style="width: 90%; margin-left: 35px" formControlName="name" type="text"
                class="form-control" placeholder="" />
            </div>

            <div class="col form-group">
              <label style="margin-left: 20px; margin-top: 20px">Mobile Number
              </label>
              <input required style="width: 85%; margin-left: 20px" formControlName="mobileNumber" type="number"
                class="form-control" placeholder="" />
            </div>
          </div>

          <div class="form-group">
            <label style="margin-left: 35px">Email</label>
            <input required style="margin-left: 35px; margin-right: 45px; width: 90%" formControlName="email"
              type="text" class="form-control" placeholder="" />
          </div>

          <div class="form-group">
            <button style="margin-left: 200px; width: 50%" [disabled]="form2.invalid" type="submit"
              class="btn btn-danger btn-block">
              Cancel Representative
            </button>
          </div>
        </form>
      </div>
    </mat-tab>

    <mat-tab label="Office Representative">
      <mat-card class="card">
        <form class="form" [formGroup]="officerepform" (ngSubmit)="addofficerepform()">
          <div class="form-row">
            <mat-form-field class="example-full-width" style="width: 60%; margin-left: 7%; margin-top: 10px"
              appearance="outline">
              <mat-label>Office Representative Name</mat-label>
              <input matInput formControlName="issuesellername" />
            </mat-form-field>
            <!-- <mat-form-field
              style="margin-left: 7%; margin-top: 10px; width: 40%"
              class="example-full-width"
              appearance="outline"
            >
              <mat-label>Office Representative Name :</mat-label>
              <input
                type="text"
                formControlName="issuesellername"
                matInput
                [matAutocomplete]="autorep"
              />
              <mat-autocomplete
                autoActiveFirstOption
                #autorep="matAutocomplete"
              >
                <mat-option
                  (onSelectionChange)="selected_rep_name(option)"
                  *ngFor="let option of filteredOptions4 | async"
                  [value]="option['name']"
                >
                  {{ option["name"] }}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field> -->

            <!-- <mat-form-field
              style="margin-left: 20px; margin-top: 10px; width: 40%"
              class="example-full-width"
              appearance="outline"
            >
              <mat-label>Mobile No :</mat-label>
              <input
                type="number"
                formControlName="mnumber"
                matInput
                [matAutocomplete]="automob"
              />
              <mat-autocomplete
                autoActiveFirstOption
                #automob="matAutocomplete"
              >
                <mat-option
                  (onSelectionChange)="selected_rep_name(option)"
                  *ngFor="let option of filteredOptions5 | async"
                  [value]="option['mob_no']"
                >
                  {{ option["mob_no"] }}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field> -->
          </div>
          <div class="form-row">
            <mat-form-field class="example-full-width" style="width: 60%; margin-left: 7%; margin-top: 10px"
              appearance="outline">
              <mat-label>Address :</mat-label>
              <textarea matInput placeholder="Address" formControlName="address1"></textarea>
            </mat-form-field>
          </div>
          <div class="form-row">
            <mat-form-field style="margin-left: 7%; margin-top: 10px; width: 24%" class="example-full-width"
              appearance="outline">
              <mat-label>Pincode</mat-label>
              <input (change)="getPincode2($event)" matInput #postalCode2 maxlength="6" placeholder=""
                formControlName="pincode" required />
              <mat-hint align="end">{{ postalCode2.value.length }} / 6</mat-hint>
            </mat-form-field>

            <mat-form-field style="width: 27%; margin-left: 15px; margin-top: 10px" appearance="outline">
              <mat-label>Country</mat-label>
              <mat-select (selectionChange)="getCountry($event)" formControlName="country" required>
                <mat-option *ngFor="let c of countries" value="{{ c['country_name'] }}">{{ c["country_name"] }}
                </mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field style="width: 28%; margin-left: 15px; margin-top: 10px" appearance="outline">
              <mat-label>State</mat-label>
              <mat-select (selectionChange)="getState($event)" formControlName="state" required>
                <mat-option *ngFor="let c of states" value="{{ c['state_name'] }}">{{ c["state_name"] }}
                </mat-option>
              </mat-select>
              <span>{{ selectedState }}</span>
            </mat-form-field>
          </div>

          <div class="form-row">
            <mat-form-field style="width: 40%; margin-left: 7%; margin-top: 10px" appearance="outline">
              <mat-label>District</mat-label>
              <mat-select formControlName="district" (selectionChange)="getDistricts($event)" required>
                <mat-option *ngFor="let c of cities" value="{{ c['district_name'] }}">
                  {{ c["district_name"] }}
                </mat-option>
              </mat-select>
              <span>{{ selectedCity }}</span>
            </mat-form-field>

            <!-- <mat-form-field class="example-full-width" style="width: 40%; margin-left: 20px; margin-top: 10px"
              appearance="outline">
              <mat-label>Taluka</mat-label>
              <input matInput formControlName="taluka" required />
            </mat-form-field> -->
            <mat-form-field style="width: 23%; margin-left: 3%; margin-top: 10px" appearance="outline">
              <mat-label>Taluka</mat-label>
              <mat-select formControlName="taluka">
                <mat-option *ngFor="let c of talukas" value="{{ c['taluka_name'] }}">{{ c["taluka_name"] }}</mat-option>
              </mat-select>
              <span>{{ selectedTaluka }}</span>
            </mat-form-field>
          </div>
          <div class="form-row">
            <mat-form-field style="margin-left: 7%; margin-top: 10px; width: 40%"
              [style.color]="validmobile ? null : 'red'" class="example-full-width">
              <mat-label>Mobile</mat-label>
              <span matPrefix style="color: gray">+91 &nbsp;</span>
              <input type="tel" #mobile2 (change)="validatemobile($event)" maxlength="10" formControlName="mnumber"
                matInput />
              <span matSuffix style="color: gray">
                <mat-icon> call</mat-icon>
              </span>
              <mat-hint align="end">{{ mobile2.value.length }} / 10</mat-hint>
              <mat-error *ngIf="validmobile">
                Please enter a valid mobile no.
              </mat-error>
              <mat-error *ngIf="validmobile">
                Mobile no. is <strong>required</strong>
              </mat-error>
            </mat-form-field>
            <mat-form-field class="example-full-width" style="width: 40%; margin-left: 20px; margin-top: 10px"
              [style.color]="validemail ? null : 'red'" appearance="outline">
              <mat-label>Email</mat-label>
              <input (change)="validateemail($event)" type="email" matInput formControlName="email"
                placeholder="Ex. pat@example.com" />
              <span matSuffix style="color: gray">
                <mat-icon> email</mat-icon>
              </span>
              <mat-error *ngIf="validemail">
                Please enter a valid email address
              </mat-error>
              <mat-error *ngIf="validemail">
                Email is <strong>required</strong>
              </mat-error>
            </mat-form-field>
          </div>
          <div class="form-row">
            <mat-form-field class="example-full-width" style="margin-left: 7%; margin-top: 10px; width: 40%"
              appearance="outline">
              <mat-label>Landline No.</mat-label>
              <input type="text" #land2 maxlength="12" formControlName="landline_no" matInput />
              <mat-hint align="end">{{ land2.value.length }} / 12</mat-hint>
            </mat-form-field>

            <!-- <label style="margin-left: 10px; margin-top: 10px"
              >Date of Birth :
            </label> -->
            <mat-form-field style="margin-left: 20px; margin-top: 10px; width: 40%" appearance="outline">
              <mat-label>Date of Birth</mat-label>
              <input matInput type="date" formControlName="dob" (dateChange)="validateDob($event)" />
              <mat-error *ngIf="validdob"> Please enter a valid DOB </mat-error>
              <mat-error *ngIf="validdob">
                DOB is <strong>required</strong>
              </mat-error>
            </mat-form-field>
            <!-- <mat-form-field style="margin-left: 20px; margin-top: 10px; width: 40%" appearance="outline">
              <mat-label>Date of Birth</mat-label>
              <input formControlName="dob" matInput [matDatepicker]="datepic" (dateChange)="validateDob($event)" />
              <mat-datepicker-toggle matSuffix [for]="datepic"></mat-datepicker-toggle>
              <mat-datepicker #datepic> </mat-datepicker>
              <mat-error *ngIf="validdob"> Please enter a valid DOB </mat-error>
              <mat-error *ngIf="validdob">
                DOB is <strong>required</strong>
              </mat-error>
            </mat-form-field> -->
          </div>
          <div class="form-row">
            <mat-form-field style="margin-left: 7%; width: 40%; margin-top: 10px" class="example-full-width"
              appearance="outline">
              <mat-label>Gst No.</mat-label>
              <input matInput maxlength="15" #gst2 type="text" formControlName="gstno" />
              <mat-hint align="end">{{ gst2.value.length }} / 15</mat-hint>
            </mat-form-field>
            <mat-form-field style="margin-left: 20px; width: 40%; margin-top: 10px" class="example-full-width"
              appearance="outline">
              <mat-label>PAN No.</mat-label>
              <input matInput maxlength="10" #pan2 type="text" formControlName="panno" />
              <mat-hint align="end">{{ pan2.value.length }} / 10</mat-hint>
            </mat-form-field>
          </div>
          <!-- <div class="form-row">
            <mat-form-field
              style="margin-left: 7%; margin-top: 10px; width: 40%"
              class="example-full-width"
              appearance="outline"
            >
              <mat-label>Deposit Amount &#8377;:</mat-label>
              <input type="number" formControlName="depositamt" matInput />
            </mat-form-field>
          </div> -->
          <!-- <div class="form-row">
            <mat-radio-group
              aria-label="Select an option"
              style="margin-left: 7%"
              formControlName="option"
              (change)="select($event)"
            >
              <mat-radio-button value="agent">Agent</mat-radio-button>
              <mat-radio-button value="representative" style="margin-left: 20px"
                >Representative</mat-radio-button
              >
              <mat-radio-button value="both" style="margin-left: 20px"
                >Both</mat-radio-button
              >
            </mat-radio-group>
          </div> -->
          <div class="form-group">
            <button style="margin-left: 200px; width: 50%" [disabled]="officerepform.invalid" type="submit"
              class="btn btn-danger btn-block">
              Add Office Representative
            </button>
          </div>
        </form>
      </mat-card>
    </mat-tab>

    <mat-tab label="Assign Representative">
      <mat-card class="card" style="margin: 20px 120px">
        <form [formGroup]="repassignform" (ngSubmit)="addassignrep()">
          <div class="form-row">
            <mat-form-field class="example-full-width" style="width: 40%; margin-left: 5%; margin-top: 2%"
              appearance="outline">
              <mat-label>Representative :</mat-label>
              <input matInput formControlName="rep" [matAutocomplete]="auto" required />
              <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
                <mat-option (onSelectionChange)="selected_name($event, option)"
                  *ngFor="let option of filteredOptions6 | async" [value]="option['name']">
                  {{ option["name"] }}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
            <mat-form-field class="example-full-width" style="width: 15%; margin-left: 5%; margin-top: 2%"
              appearance="outline">
              <mat-label>District</mat-label>
              <input matInput formControlName="district" />
            </mat-form-field>
            <mat-form-field class="example-full-width" style="width: 15%; margin-left: 2%; margin-top: 2%"
              appearance="outline">
              <mat-label>Taluka</mat-label>
              <input matInput formControlName="taluka" />
            </mat-form-field>
          </div>
          <div class="form-row">
            <mat-form-field class="example-full-width" style="width: 40%; margin-left: 5%" appearance="outline">
              <mat-label>Book Store :</mat-label>
              <input matInput formControlName="bookstore" [matAutocomplete]="auto7" required />
              <mat-autocomplete autoActiveFirstOption #auto7="matAutocomplete">
                <mat-option (onSelectionChange)="selected_bookstore(option)"
                  *ngFor="let option of filteredOptions7 | async" [value]="option['name']">
                  {{ option["name"] }}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
          </div>
          <div class="form-row">
            <mat-form-field class="example-full-width" style="width: 40%; margin-left: 5%" appearance="outline">
              <mat-label>Subscription :</mat-label>
              <input matInput formControlName="subscription" [matAutocomplete]="auto8" required />
              <mat-autocomplete autoActiveFirstOption #auto8="matAutocomplete">
                <mat-option (onSelectionChange)="selected_subs(option)" *ngFor="let option of filteredOptions8 | async"
                  [value]="option['name']">
                  {{ option["name"] }}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
          </div>
          <div class="form-row">
            <mat-form-field class="example-full-width" style="width: 40%; margin-left: 5%" appearance="outline">
              <mat-label>Advertiesment :</mat-label>
              <input matInput formControlName="advt" [matAutocomplete]="auto9" required />
              <mat-autocomplete autoActiveFirstOption #auto9="matAutocomplete">
                <mat-option (onSelectionChange)="selected_advt(option)" *ngFor="let option of filteredOptions9 | async"
                  [value]="option['name']">
                  {{ option["name"] }}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
          </div>
          <div class="form-row">
            <mat-form-field class="example-full-width" style="width: 40%; margin-left: 5%" appearance="outline">
              <mat-label>Issue/Granth Seller :</mat-label>
              <input matInput formControlName="issue" [matAutocomplete]="auto10" required />
              <mat-autocomplete autoActiveFirstOption #auto10="matAutocomplete">
                <mat-option (onSelectionChange)="selected_issue(option)"
                  *ngFor="let option of filteredOptions10 | async" [value]="option['name']">
                  {{ option["name"] }}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
          </div>
          <div class="form-group">
            <button *ngIf="!isdata" type="submit" class="btn btn-primary" style="width: 40%; margin-left: 20%">
              Submit
            </button>
            <button *ngIf="isdata" type="button" (click)="updateassignrep()" class="btn btn-primary"
              style="width: 40%; margin-left: 20%">
              Update
            </button>
          </div>
        </form>
      </mat-card>
      <mat-card>
        <mat-card-header>
          <h1 style="text-align: center">
            List of Assign Representative
          </h1>
        </mat-card-header>
        <div class="tableFixHead">
          <table class="table table-striped text-center">
            <thead>
              <tr>
                <th>Sr. no.</th>
                <th>Representative Name</th>
                <th>Book Store</th>
                <th>Subscription</th>
                <th>Advertiesment</th>
                <th>Issue Sale</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of deptwise_off_rep_list; let i = index">
                <td>{{ i + 1 }}</td>
                <td>{{ item["rep_name"] }}</td>
                <!-- <td>{{ item["district"] }}</td>
                <td>{{ item["taluka"] }}</td> -->
                <td>{{ item["book_off_rep_name"] }}</td>
                <td>{{ item["sub_off_rep_name"] }}</td>
                <td>{{ item["advt_off_rep_name"] }}</td>
                <td>{{ item["issue_off_rep_name"] }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </mat-card>

      <mat-card class="mt-5">
        <h1 style="text-align: center;" class="mt-3">
          List of Assigne Representative wise type </h1>
        <form [formGroup]="repassigntype">
          <!-- <mat-form-field class="example-full-width" style="width: 40%; margin-left: 5%" appearance="outline">
                  <mat-label>Select Type :</mat-label>
                  <input matInput formControlName="bookstore" [matAutocomplete]="auto9" required />
                  <mat-autocomplete autoActiveFirstOption1 #auto9="matAutocomplete">
                    <mat-option (onSelectionChange)="selected_bookstore(option)"
                      *ngFor="let option of getdepwiserepcount1 " [value]="option['name']">
                      {{ option["name"] }}
                    </mat-option>
                  </mat-autocomplete>
                </mat-form-field> -->
          <mat-form-field class="example-full-width" style="width: 40%; margin-left: 5%" appearance="outline">
            <mat-label>Select an option</mat-label>
            <mat-select formControlName="type">
              <mat-option (onSelectionChange)="onSelectionChange()" *ngFor="let option of getdepwiserepcount1" [value]="option.rep_type">{{ option.rep_type
                }}</mat-option>
            </mat-select>
          </mat-form-field>
      
          <button type="button" (click)="assignoffwithtype()" class="btn btn-primary" style="width: 20%; margin-left: 5%">
            Search
          </button>

          <span>Total count is: {{count}}</span>
        </form>
        <div class="tableFixHead">
          <table class="table table-striped text-center">
            <thead>
              <tr>
                <th>Sr. no.</th>
                <th>Off Representative Name</th>
                <th>Taluka</th>
                <th>District</th>
                <th>State</th>
                <th>Address</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of getdatabydep; let i = index">
                <td>{{ i + 1 }}</td>
                <td>{{ item["office_rep_name"] }}</td>
                <!-- <td>{{ item["district"] }}</td>
                      <td>{{ item["taluka"] }}</td> -->
                <td>{{ item["taluka"] }}</td>
                <td>{{ item["district"] }}</td>
                <td>{{ item["state"] }}</td>
                <td>{{ item["address"] }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      
      </mat-card>


      <mat-card class="mt-5">
        <h1 style="text-align: center;" class="mt-3">
          List of Assigne Representative Office Representative Wise</h1>
        <form [formGroup]="repassignoff">
          <!-- <mat-form-field class="example-full-width" style="width: 40%; margin-left: 5%" appearance="outline">
                  <mat-label>Select Type :</mat-label>
                  <input matInput formControlName="bookstore" [matAutocomplete]="auto9" required />
                  <mat-autocomplete autoActiveFirstOption1 #auto9="matAutocomplete">
                    <mat-option (onSelectionChange)="selected_bookstore(option)"
                      *ngFor="let option of getdepwiserepcount1 " [value]="option['name']">
                      {{ option["name"] }}
                    </mat-option>
                  </mat-autocomplete>
                </mat-form-field> -->
          <mat-form-field class="example-full-width" style="width: 40%; margin-left: 5%" appearance="outline">
            <mat-label>Select an option</mat-label>
            <mat-select formControlName="off_rep" multiple>
              <mat-option  *ngFor="let option of getrepname" [value]="option.id">{{ option.officerep
                }}</mat-option>
            </mat-select>
          </mat-form-field>
      
          <button type="button" (click)="assignoffwithoffrep()" class="btn btn-primary" style="width: 20%; margin-left: 5%">
            Search
          </button>

          <!-- <span>Total count is: {{count}}</span> -->
        </form>
        <div class="tableFixHead">
          <table class="table table-striped text-center">
            <thead>
              <tr>
                <th>Sr. no.</th>
                <th>Representative Name</th>
                <th>Mobile No</th>
                <th>Address</th>
               
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of getdatabyoff; let i = index">
                <td>{{ i + 1 }}</td>
                <td>{{ item["rep_name"] }}</td>
                <!-- <td>{{ item["district"] }}</td>
                      <td>{{ item["taluka"] }}</td> -->
                <td>{{ item["mobile_no"] }}</td>
                <td>{{ item["rep_address"]}}  {{item["rep_taluka"]}}  {{item["rep_district"]}} {{item["rep_state"] }}</td>
               
              </tr>
            </tbody>
          </table>
        </div>
      
      </mat-card>
    </mat-tab>


    <mat-tab label="Add Country">
      <mat-card class="card mt-5">
        <form [formGroup]="addcountry">
          <div class="form-row">
            <mat-form-field class="example-full-width" style="width: 35%; margin-left: 5%" appearance="outline">
              <mat-label>Country Name :</mat-label>
              <input matInput formControlName="country_name" required />
            </mat-form-field>
            <mat-form-field class="example-full-width" style="width: 35%; margin-left: 5%" appearance="outline">
              <mat-label>Country Code :</mat-label>
              <input matInput formControlName="country_code" required />
            </mat-form-field>
            </div>

            <div>
              <button type="button" (click)="addcountryfun()" class="btn btn-primary" style="width: 10%; margin-left: 36%;">
                Add
              </button>
              <button *ngIf="updatecountry123" type="button" (click)="updatecountryfun()" class="btn btn-primary" style="width: 10%; margin-left: 36%;">
                Update
              </button>
            </div>
        </form>

        <div class="tableFixHead">
          <table class="table table-striped text-center">
            <thead>
              <tr>
                <th>Sr. no.</th>
                <th>Country Name</th>
                <th>Country Code</th>
                <th>Action</th>
               
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of getcountry; let i = index">
                <td>{{ i + 1 }}</td>
                <td>{{ item["country_name"] }}</td>
                <!-- <td>{{ item["district"] }}</td>
                      <td>{{ item["taluka"] }}</td> -->
                <td>{{ item["country_code"] }}</td>
                <td>
                  
                  <button type="button" style="margin-left: 5%;" type="button" class="btn btn-primary"
                    matTooltip="Update" (click)="updatecountry(item)">
                    <mat-icon>edit</mat-icon>
                  </button>
                </td>               
              </tr>
            </tbody>
          </table>
        </div>
      
      </mat-card>
      
    </mat-tab>


    <mat-tab label="Add Seniority">
      <mat-card class="card mt-5">
        <form [formGroup]="addseni">
          <div class="form-row mt-3">
            <mat-form-field class="example-full-width" style="width: 25%; margin-left: 5%" appearance="outline">
              <mat-label>Select an Off Reprensantive</mat-label>
              <mat-select formControlName="off_repname">
                <mat-option  *ngFor="let option of getrepname" [value]="option.officerep">{{ option.officerep
                  }}</mat-option>
              </mat-select>
            </mat-form-field>

            <!-- <mat-form-field class="example-full-width" style="width: 25%; margin-left: 5%" appearance="outline">
              <mat-label>Select an Off Reprensantive</mat-label>
              <mat-select formControlName="off_repname" >
                <mat-option  *ngFor="let option of getdeptdata" [value]="option.id">{{ option.dep_name
                  }}</mat-option>
              </mat-select>
            </mat-form-field> -->
            <mat-form-field class="example-full-width" style="width: 25%; margin-left: 5%" appearance="outline">
              <mat-label>Select an Dept</mat-label>
              <mat-select formControlName="dept">
                <mat-option  *ngFor="let book of getdeptdata" [value]="book.dep_name">{{ book.dep_name
                  }}</mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field class="example-full-width" style="width: 25%; margin-left: 5%" appearance="outline">
              <mat-label>Add Language :</mat-label>
              <input matInput formControlName="seniority" required />
            </mat-form-field>

            </div>

            <div>
              <button type="button" (click)="addseniority()" class="btn btn-primary" style="width: 10%; margin-left: 36%;">
                Add
              </button>
              <button *ngIf="updatesenioritybtn "  type="button" (click)="updateseniority()" class="btn btn-primary" style="width: 10%; margin-left: 36%;">
                Update
              </button>
            </div>
            <div>
              
            </div>
        </form>

        <div class="tableFixHead">
          <table class="table table-striped text-center">
            <thead>
              <tr>
                <th>Sr. no.</th>
                <th>Dept. Name</th>
                <th>OffReprensantive Name</th>
                <th>Seniority</th>
                <th>Action</th>
               
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of senioritylist; let i = index">
                <td>{{ i + 1 }}</td>
                <td>{{ item["dep_id"] }}</td>
                <!-- <td>{{ item["district"] }}</td>
                      <td>{{ item["taluka"] }}</td> -->
                <td>{{ item["officerep"] }}</td>
                <td>{{ item["seniority"] }}</td>

                <td>
                  
                  <button type="button" style="margin-left: 5%;" type="button" class="btn btn-primary"
                    matTooltip="Update" (click)="deleteseniority(item.id)">
                    <mat-icon>clear</mat-icon>
                  </button>
                </td>               
              </tr>
            </tbody>
          </table>
        </div>
      
      </mat-card>
      
    </mat-tab>
    
    <mat-tab label="Add Language">
      <mat-card class="card mt-5">
        <form [formGroup]="addlang">
          <div class="form-row mt-3">
            <!-- <mat-form-field class="example-full-width" style="width: 25%; margin-left: 5%" appearance="outline">
              <mat-label>Select an Off Reprensantive</mat-label>
              <mat-select formControlName="off_repname">
                <mat-option  *ngFor="let option of getrepname" [value]="option.officerep">{{ option.officerep
                  }}</mat-option>
              </mat-select>
            </mat-form-field> -->

            <!-- <mat-form-field class="example-full-width" style="width: 25%; margin-left: 5%" appearance="outline">
              <mat-label>Select an Off Reprensantive</mat-label>
              <mat-select formControlName="off_repname" >
                <mat-option  *ngFor="let option of getdeptdata" [value]="option.id">{{ option.dep_name
                  }}</mat-option>
              </mat-select>
            </mat-form-field> -->
            <!-- <mat-form-field class="example-full-width" style="width: 25%; margin-left: 5%" appearance="outline">
              <mat-label>Select an Dept</mat-label>
              <mat-select formControlName="dept">
                <mat-option  *ngFor="let book of getdeptdata" [value]="book.dep_name">{{ book.dep_name
                  }}</mat-option>
              </mat-select>
            </mat-form-field> -->

            <mat-form-field class="example-full-width" style="width: 25%; margin-left: 5%" appearance="outline">
              <mat-label>Add Language :</mat-label>
              <input matInput formControlName="language" required />
            </mat-form-field>

            </div>

            <div>
              <button type="button" (click)="addlanguage()" class="btn btn-primary" style="width: 10%; margin-left: 36%;">
                Add
              </button>
              <!-- <button *ngIf="updatesenioritybtn "  type="button" (click)="updateseniority()" class="btn btn-primary" style="width: 10%; margin-left: 36%;">
                Update
              </button> -->
            </div>
            <div>
              
            </div>
        </form>

        <div class="tableFixHead">
          <table class="table table-striped text-center">
            <thead>
              <tr>
                <th>Sr. no.</th>
                <th>Language</th>
                <!-- <th>OffReprensantive Name</th> -->
                <!-- <th>Seniority</th> -->
                <th>Action</th>
               
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of languagelist; let i = index">
                <!-- <td>{{ i + 1 }}</td> -->
                <td>{{ item["id"] }}</td>
                <!-- <td>{{ item["district"] }}</td>
                      <td>{{ item["taluka"] }}</td> -->
                <td>{{ item["Languages"] }}</td>
                <!-- <td>{{ item["seniority"] }}</td> -->

                <td>
                  
                  <button type="button" style="margin-left: 5%;" type="button" class="btn btn-primary"
                    matTooltip="Update" (click)="deleteseniority(item.id)">
                    <mat-icon>clear</mat-icon>
                  </button>
                </td>               
              </tr>
            </tbody>
          </table>
        </div>
      
      </mat-card>
      
    </mat-tab>
    
  </mat-tab-group>
</div>