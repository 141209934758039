import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DigisubservicesService } from 'src/app/services/digisubservices.service';
import { ToWords } from 'to-words';

@Component({
  selector: 'app-subscriptioninvoice',
  templateUrl: './subscriptioninvoice.component.html',
  styleUrls: ['./subscriptioninvoice.component.scss']
})
export class SubscriptioninvoiceComponent implements OnInit {

  
  rep_details
  viewmode = false

  invoiceData = {
    rep_id: 0,
    rep_name: '',
    rep_mobile: '',
    rep_address: '',
    book_data: [],
    invoice_id: 123,
    invoice_date: '08-10-2021'
  }
  totalAmount = 0
  totalCommision = 0
  cgstamt = 0
  sgstamt = 0
  taxablevalue = 0
  toWords
  invoiceValue = 0
  taxAmountInWords
  isGstinvoice
  gst_number
  rep_invoice
  constructor(private fb: FormBuilder, public service: DigisubservicesService,
    private dialogRef: MatDialogRef<SubscriptioninvoiceComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    console.log(this.data)
  }

  ngOnInit(): void {
    this.isGstinvoice = this.data['book']['GST']
    this.gst_number = this.data['book']['GST_no']
    this.viewmode = this.data['view']
    this.rep_invoice = this.data['rep_invoice']

    if (this.rep_invoice == "Y") {
      //Rep Invoice
      if (!this.viewmode) {
        this.invoiceData = {
          rep_id: this.data['book']['rep']['id'],
          rep_name: this.data['book']['rep']['name'],
          rep_mobile: this.data['book']['rep']['mobile'],
          rep_address: this.data['book']['rep']['rep_address'],
          book_data: this.data['book']['books'],
          invoice_id: 123,
          invoice_date: '08-10-2021'
        }

        //this.rep_details = this.data['book']['rep']
        this.totalAmount = 0
        this.totalCommision = 0
        this.cgstamt = 0
        this.taxablevalue = 0
        for (let index = 0; index < Object.keys(this.data['book']['books']).length; index++) {
          this.totalAmount = this.totalAmount + parseInt(this.data['book']['books'][index]['book_total']);
          this.totalCommision = this.totalCommision + parseInt(this.data['book']['books'][index]['commission']);
        }

        setTimeout(() => {
          if (this.isGstinvoice == "Y") {
            //calculate GST
            this.cgstamt = Math.round((this.totalAmount * 9) / 100);
            this.sgstamt = Math.round((this.totalAmount * 9) / 100);
            this.taxablevalue = this.totalAmount + this.cgstamt + this.sgstamt
            this.invoiceValue = this.taxablevalue - this.totalCommision
            // Convert Total Amount in Words
            this.toWords = new ToWords()
            this.taxAmountInWords = this.toWords.convert(this.invoiceValue, { currency: true })
          } else {
            //calculate GST
            //this.cgstamt = Math.round((this.totalAmount * 9)/100);
            this.taxablevalue = this.totalAmount //+ this.cgstamt + this.cgstamt
            this.invoiceValue = this.taxablevalue - this.totalCommision
            // Convert Total Amount in Words
            this.toWords = new ToWords()
            this.taxAmountInWords = this.toWords.convert(this.invoiceValue, { currency: true })
          }

        }, 2000);


      } else {
        console.log(this.data['book']['books']['bookDetail']);
        this.invoiceData = {
          rep_id: this.data['book']['rep']['id'],
          rep_name: this.data['book']['rep']['name'],
          rep_mobile: this.data['book']['rep']['mobile'],
          rep_address: this.data['book']['rep']['rep_address'],
          book_data: this.data['book']['books']['bookDetail'],
          invoice_id: this.data['book']['books']['invoice_details']['invoice_id'],
          invoice_date: this.data['book']['books']['invoice_details']['invoice_date']
        }

        if (this.isGstinvoice == "Y") {
          //this.rep_details = this.data['book']['rep']
          this.totalAmount = this.data['book']['books']['invoice_details']['total_amount']
          this.totalCommision = this.data['book']['books']['invoice_details']['rep_comission']
          this.cgstamt = this.data['book']['books']['invoice_details']['cgst']
          this.sgstamt = this.data['book']['books']['invoice_details']['sgst']
          this.taxablevalue = this.totalAmount + this.cgstamt + this.sgstamt
          this.invoiceValue = this.taxablevalue - this.totalCommision

          // Convert Total Amount in Words
          this.toWords = new ToWords()
          this.taxAmountInWords = this.toWords.convert(this.invoiceValue, { currency: true })
        } else {
          //this.rep_details = this.data['book']['rep']
          this.totalAmount = this.data['book']['books']['invoice_details']['invoice_total']
          this.totalCommision = this.data['book']['books']['invoice_details']['rep_comission']
          //this.cgstamt = this.data['book']['books']['invoice_details']['cgst']
          //this.sgstamt = this.data['book']['books']['invoice_details']['sgst']
          this.taxablevalue = this.totalAmount// + this.cgstamt + this.sgstamt
          this.invoiceValue = this.taxablevalue - this.totalCommision

          // Convert Total Amount in Words
          this.toWords = new ToWords()
          this.taxAmountInWords = this.toWords.convert(this.invoiceValue, { currency: true })
        }
      }


    } else {
      //Cust Invoice

      if (!this.viewmode) {
        this.invoiceData = {
          rep_id: this.data['book']['rep']['cust_id'],
          rep_name: this.data['book']['rep']['name'],
          rep_mobile: this.data['book']['rep']['mobile'],
          rep_address: this.data['book']['rep']['address'],
          book_data: this.data['book']['books'],
          invoice_id: 123,
          invoice_date: '08-10-2021'
        }

        //this.rep_details = this.data['book']['rep']
        this.totalAmount = 0
        //this.totalCommision = 0
        this.cgstamt = 0
        this.taxablevalue = 0
        for (let index = 0; index < Object.keys(this.data['book']['books']).length; index++) {
          this.totalAmount = this.totalAmount + parseInt(this.data['book']['books'][index]['book_total']);
        }

        setTimeout(() => {
          if (this.isGstinvoice == "Y") {
            //calculate GST
            this.cgstamt = Math.round((this.totalAmount * 9) / 100);
            this.sgstamt = Math.round((this.totalAmount * 9) / 100);
            this.taxablevalue = this.totalAmount + this.cgstamt + this.sgstamt
            this.invoiceValue = this.taxablevalue //- this.totalCommision
            // Convert Total Amount in Words
            this.toWords = new ToWords()
            this.taxAmountInWords = this.toWords.convert(this.invoiceValue, { currency: true })
          } else {
            //calculate Non GST
            
            this.taxablevalue = this.totalAmount 
            this.invoiceValue = this.taxablevalue 
            // Convert Total Amount in Words
            this.toWords = new ToWords()
            this.taxAmountInWords = this.toWords.convert(this.invoiceValue, { currency: true })
          }

        }, 2000);


      } else {
        console.log(this.data['book']['books']['bookDetail']);
        this.invoiceData = {
          rep_id: this.data['book']['rep']['id'],
          rep_name: this.data['book']['rep']['name'],
          rep_mobile: this.data['book']['rep']['mobile'],
          rep_address: this.data['book']['rep']['address'],
          book_data: this.data['book']['books']['bookDetail'],
          invoice_id: this.data['book']['books']['invoice_details']['invoice_id'],
          invoice_date: this.data['book']['books']['invoice_details']['invoice_date']
        }

        if (this.isGstinvoice == "Y") {
          //this.rep_details = this.data['book']['rep']
          this.totalAmount = this.data['book']['books']['invoice_details']['total_amount']
          //this.totalCommision = this.data['book']['books']['invoice_details']['rep_comission']
          this.cgstamt = this.data['book']['books']['invoice_details']['cgst']
          this.sgstamt = this.data['book']['books']['invoice_details']['sgst']
          this.taxablevalue = this.totalAmount + this.cgstamt + this.sgstamt
          this.invoiceValue = this.taxablevalue //- this.totalCommision

          // Convert Total Amount in Words
          this.toWords = new ToWords()
          this.taxAmountInWords = this.toWords.convert(this.invoiceValue, { currency: true })
        } else {
          //this.rep_details = this.data['book']['rep']
          this.totalAmount = this.data['book']['books']['invoice_details']['invoice_total']
          //this.totalCommision = this.data['book']['books']['invoice_details']['rep_comission']
          //this.cgstamt = this.data['book']['books']['invoice_details']['cgst']
          //this.sgstamt = this.data['book']['books']['invoice_details']['sgst']
          this.taxablevalue = this.totalAmount// + this.cgstamt + this.sgstamt
          this.invoiceValue = this.taxablevalue //- this.totalCommision

          // Convert Total Amount in Words
          this.toWords = new ToWords()
          this.taxAmountInWords = this.toWords.convert(this.invoiceValue, { currency: true })
        }
      }

    }

  }

  print() {
    window.print()
  }

  save() {

    if(this.rep_invoice == "Y")
    {
        //rep Invoice
        this.service.InsertInvoice(this.invoiceData.rep_name, this.invoiceData.rep_mobile, this.invoiceData.rep_id,
          this.invoiceData.rep_address, "", "", this.gst_number, this.totalAmount,
          this.totalCommision, this.cgstamt, this.cgstamt, 0).subscribe(data => {
            console.log(data)
    
            for (let index = 0; index < Object.keys(this.invoiceData['book_data']).length; index++) {
    
              this.service.InsertInvoiceData(parseInt(data['id']), this.invoiceData['book_data'][index]['book_name'],
                parseInt(this.invoiceData['book_data'][index]['book_qty']), parseInt(this.invoiceData['book_data'][index]['book_rate']),
                parseInt(this.invoiceData['book_data'][index]['book_total'])).subscribe(invoice => {
                  console.log(invoice)
                })
    
            }
    
            setTimeout(() => {
              window.alert("Invoice " + parseInt(data['id']) + " has been created.")
              this.dialogRef.close()
    
            }, ((Object.keys(this.invoiceData['book_data']).length / 2) * 1000));
    
          })

    }else {
      //CustInvoice

      this.service.InsertCustInvoiceSubscription(this.invoiceData.rep_name, this.invoiceData.rep_mobile, this.invoiceData.rep_id,
        this.invoiceData.rep_address, "", "", this.gst_number, this.totalAmount,
        this.totalCommision, this.cgstamt, this.cgstamt, 0).subscribe(data => {
          console.log(data)
  
          for (let index = 0; index < Object.keys(this.invoiceData['book_data']).length; index++) {
  
            this.service.InsertCustInvoiceDataSubscription(parseInt(data['id']), this.invoiceData['book_data'][index]['book_name'],
              parseInt(this.invoiceData['book_data'][index]['book_qty']), parseInt(this.invoiceData['book_data'][index]['book_rate']),
              parseInt(this.invoiceData['book_data'][index]['book_total'])).subscribe(invoice => {
                console.log(invoice)
              })
  
          }
  
          setTimeout(() => {
            window.alert("Invoice " + parseInt(data['id']) + " has been created.")
            this.dialogRef.close()
  
          }, ((Object.keys(this.invoiceData['book_data']).length / 2) * 1000));
  
        })

   

    }
    
  }

}
