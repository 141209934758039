import { formatDate } from '@angular/common';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatOption } from '@angular/material/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSelect } from '@angular/material/select';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { DigisubservicesService } from 'src/app/services/digisubservices.service';
import { EncdecriptService } from 'src/app/services/encdecrypt.service';

@Component({
  selector: 'app-update-advt-details',
  templateUrl: './update-advt-details.component.html',
  styleUrls: ['./update-advt-details.component.scss'],
})
export class UpdateAdvtDetailsComponent implements OnInit {
  ionicForm: FormGroup;
  advtTypes;
  validDOB;
  defaultDate = '1987-06-30';
  countries = [];
  states = [];
  cities = [];
  titles = [];
  bookList = [];
  selectedState = '';
  selectedCity = '';
  AdvtDetails = [];
  advtSizes = [];
  occupations = [];
  getAllRepList = [
    {
      id: 777,
      mobile: '1234567893',
      name: 'All',
      rep_address: '',
      rep_advt_comm: '0',
      rep_book_comm: '0',
      rep_comm: '0',
    },
  ];
  advt_details;
  view_mode = false;
  imgpath = 'https://minimasters.in/VivekApplication/uploads/';
  newImgpath = '';

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    public service: DigisubservicesService,
    private dialogRef: MatDialogRef<UpdateAdvtDetailsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public encr: EncdecriptService,
    private _snackBar: MatSnackBar
  ) {
    this.ionicForm = this.formBuilder.group({
      name: ['', [Validators.required, Validators.minLength(2)]],
      custname: [''],
      email: [
        '',
        [Validators.pattern('[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+.[a-z]{2,3}$')],
      ],
      address: ['', [Validators.required, Validators.minLength(5)]],
      country: ['', [Validators.required, Validators.minLength(2)]],
      state: ['', [Validators.required, Validators.minLength(2)]],
      taluka: ['', [Validators.required, Validators.minLength(2)]],
      district: ['', [Validators.required, Validators.minLength(2)]],
      mobileNumber: [
        '',
        [Validators.maxLength(10), Validators.pattern('^[7-9][0-9]{9}$')],
      ],
      paymentmode: ['', [Validators.minLength(2)]],
      pincode: [
        '',
        [Validators.maxLength(6), Validators.pattern('^[1-9][0-9]{5}$')],
      ],
      advtType: ['', [Validators.required]],
      checkno: ['', [Validators.maxLength(15)]],
      dob: [this.defaultDate],
      companyRegistration: [''],
      gstno: ['', [Validators.maxLength(15)]],
      amount: ['', [Validators.required, Validators.minLength(2)]],
      ispartpayment: ['', [Validators.required, Validators.minLength(1)]],
      partAmount: ['', [Validators.minLength(2)]],
      remainingAmount: ['', [Validators.minLength(2)]],
      receiptdate: [''],
      receiptno: [''],
      advtamout: [''],
      advtdiscount: [''],
      gstie: [''],
      gstamount: [''],
      igstamount: [''],
      comment: [''],
      rep_id: [''],
      issueName: [''],
      advtSize: [''],
      issuedates: [''],
      tapshil: [''],
      document_name: [''],
      landline_no: ['', Validators.pattern('/^[0-9]d{2,4}-d{6,8}$/')],
      ro_number: [''],
    });
  }

  advtdates;

  ngOnInit(): void {
    console.log(this.data);
    this.view_mode = this.data['view_mode'];
    this.advt_details = this.data['advt'];

    this.service.getAdvtTypes().subscribe((data) => {
      console.log(data);
      this.advtTypes = data;
    });

    this.service.get_AllRepresentative_List().subscribe((data) => {
      console.log(data);
      for (let index = 0; index < Object.keys(data).length; index++) {
        this.getAllRepList.push(data[index]);
      }
    });

    // Read All Contries
    this.service.getAllContries().subscribe((data) => {
      console.log(data);

      this.countries = data;
    });

    this.service.getOccupation().subscribe((data) => {
      console.log(data);
      this.occupations = data;
    });

    this.service.getAlltitle().subscribe((data) => {
      console.log(data);
      this.titles = data;
    });

    this.service.getAdvtSizes().subscribe((data) => {
      console.log(data);
      for (let index = 0; index < Object.keys(data).length; index++) {
        this.advtSizes.push({ name: data[index]['advt_size'] });
      }
    });

    this.service.getAllBooks().subscribe((data) => {
      for (let index = 0; index < Object.keys(data).length; index++) {
        if (index == 0) {
          this.bookList.push({ name: 'Vivek_Weekly' });
        }
        this.bookList.push({ name: data[index]['bookname'] });
      }
    });

    this.ionicForm.get('name').setValue(this.advt_details['name']);
    this.ionicForm.get('custname').setValue(this.advt_details['cust_name']);
    this.ionicForm.get('email').setValue(this.advt_details['email']);
    this.ionicForm.get('address').setValue(this.advt_details['address']);
    this.ionicForm.get('country').setValue(this.advt_details['country']);
    this.ionicForm
      .get('state')
      .setValue({ state_name: this.advt_details['state'] });
    this.ionicForm.get('taluka').setValue(this.advt_details['taluka']);
    this.ionicForm
      .get('district')
      .setValue({ name: this.advt_details['district'] });
    this.ionicForm.get('mobileNumber').setValue(this.advt_details['mobile']);
    this.ionicForm
      .get('paymentmode')
      .setValue(this.advt_details['paymentmode']);
    this.ionicForm.get('pincode').setValue(this.advt_details['pincode']);
    this.ionicForm.get('advtType').setValue(this.advt_details['advt_type']);
    this.ionicForm.get('checkno').setValue(this.advt_details['chequeno']);
    this.ionicForm.get('dob').setValue(this.advt_details['dob']);
    this.ionicForm
      .get('companyRegistration')
      .setValue(this.advt_details['company_reg_date']);
    this.ionicForm.get('gstno').setValue(this.advt_details['gstno']);
    this.ionicForm.get('issueName').setValue(this.advt_details['issue_name']);
    this.ionicForm.get('advtSize').setValue(this.advt_details['advt_Size']);
    this.ionicForm
      .get('ispartpayment')
      .setValue(this.advt_details['DS_ispartpayment']);
    this.ionicForm
      .get('partAmount')
      .setValue(this.advt_details['DS_partAmount']);
    this.ionicForm
      .get('remainingAmount')
      .setValue(this.advt_details['DS_remainingAmount']);
    this.ionicForm
      .get('receiptdate')
      .setValue(this.advt_details['receipt_date']);
    this.ionicForm.get('receiptno').setValue(this.advt_details['receipt_no']);
    this.ionicForm.get('advtamout').setValue(this.advt_details['advt_amount']);
    this.ionicForm
      .get('advtdiscount')
      .setValue(this.advt_details['advt_discount']);
    this.ionicForm
      .get('gstamount')
      .setValue(
        parseInt(this.advt_details['cgst']) +
          parseInt(this.advt_details['sgst'])
      );
    this.ionicForm.get('igstamount').setValue(this.advt_details['igst']);
    this.ionicForm.get('comment').setValue(this.advt_details['comment']);
    this.ionicForm.get('rep_id').setValue(this.advt_details['rep_id']);
    this.ionicForm.get('tapshil').setValue(this.advt_details['tapshil']);
    this.ionicForm
      .get('document_name')
      .setValue(this.advt_details['document_name']);
    this.ionicForm.get('ro_number').setValue(this.advt_details['ro_number']);
    this.ionicForm
      .get('landline_no')
      .setValue(this.advt_details['landline_no']);
    this.newImgpath = this.imgpath + this.advt_details['document_name'];
    this.advtamount = parseInt(this.advt_details['advt_amount']);
    this.discount = parseInt(this.advt_details['advt_discount']);
    this.ionicForm
      .get('amount')
      .setValue(
        parseInt(this.advt_details['advt_amount']) -
          (parseInt(this.advt_details['advt_amount']) *
            parseInt(this.advt_details['advt_discount'])) /
            100 +
          parseInt(this.advt_details['cgst']) +
          parseInt(this.advt_details['sgst'])
      );

    this.issueName = this.advt_details['issue_name'];
    this.advtSize = this.advt_details['advt_Size'];
    this.advtdates = this.advt_details['issue_dates'];

    this.selectedCity = this.advt_details['district'];
    this.selectedState = this.advt_details['state'];
    this.AdvtDetails.push({
      issue: this.issueName,
      advtsize: this.advtSize,
      advtdates: this.advtdates,
    });
    this.getDates = this.advtdates.split(',');
  } //end of ngOnInit()

  getPincode(event) {
    if (this.ionicForm.get('pincode').valid) {
      console.log(this.ionicForm.get('pincode').value);
      this.service
        .getPincodeDetails(parseInt(this.ionicForm.get('pincode').value))
        .subscribe((data) => {
          console.log(data);
          this.ionicForm
            .get('taluka')
            .setValue(data[0]['PostOffice'][0]['Block']);
          this.getCountry({ value: data[0]['PostOffice'][0]['Country'] });
          this.ionicForm
            .get('district')
            .setValue({ name: data[0]['PostOffice'][0]['District'] });
          this.ionicForm
            .get('state')
            .setValue({ state_name: data[0]['PostOffice'][0]['State'] });
          this.ionicForm
            .get('country')
            .setValue(data[0]['PostOffice'][0]['Country']);
          this.selectedCity = data[0]['PostOffice'][0]['District'];
          this.selectedState = data[0]['PostOffice'][0]['State'];

          console.log(this.selectedCity, this.selectedState);
        });
    }
  } //getPincode

  getCountry(event) {
    console.log(event);
    console.log(event.value);
    let country = this.countries.find((a) => a.name == event.value);
    if (country != undefined) {
      this.service
        .getstates(parseInt(country['country_code']))
        .subscribe((data) => {
          this.states = data;
          console.log(this.states);
        });
    } else {
    }
  } //getcountry

  showigst = false;

  getState(event) {
    console.log(event.value);
    this.selectedState = event.value;

    let state = this.states.find((a) => a.state_name == event.value);
    console.log();
    if (state != undefined) {
      if (state.state_name != 'Maharashtra') {
        this.showigst = true;
      } else {
        this.showigst = false;
      }

      this.service.getCity(parseInt(state['state_id'])).subscribe((data) => {
        this.cities = data;
      });
    } else {
    }
  } //get state

  validmobile = false;
  validemail = false;
  validdob = false;
  validateMobile(event) {
    if (this.ionicForm.get('mobileNumber').valid) {
      this.validmobile = true;
    } else {
      this.validmobile = false;
    }
  }

  validateEmail(event) {
    if (this.ionicForm.get('email').valid) {
      this.validemail = true;
    } else {
      this.validemail = false;
    }
  }

  validatedob(event) {
    this.ionicForm
      .get('dob')
      .setValue(
        formatDate(
          new Date(event.target.value),
          'yyyy-MM-dd',
          'en-IN',
          'GMT+0530'
        )
      );
    if (this.ionicForm.get('dob').valid) {
      this.validdob = true;
    } else {
      this.validdob = false;
    }
  }

  /*issueName
  advtSize
  getIssues(e)
  {
    console.log(e)
    this.issueName = e.value
  } */

  advt_mag_name_details = [];
  getAdvtMagDetails() {
    this.advt_mag_name_details = [];
    this.service.getAdvtMagNames().subscribe((data) => {
      console.log(data);
      for (let index = 0; index < Object.keys(data).length; index++) {
        console.log(data[index]);
        this.advt_mag_name_details.push({
          id: data[index]['id'],
          is_active: data[index]['is_active'],
          issue_name: data[index]['issue_name'],
          publishing_date: data[index]['publishing_dates'].split(','),
        });
      }
      //this.advt_mag_name_details = data
    });
  } //end of getAdvtMagDetails

  issueName;
  advtSize;
  getFilterMagPublishDates = ['2021-10-10', ''];
  getIssues(e) {
    console.log(e);
    this.issueName = e.value;
    //this.rangeRegistration.reset();
    //this.cust_mobile_filter = '';
    console.log(
      this.advt_mag_name_details.filter((a) => a.issue_name == this.issueName)
    );
    this.getFilterMagPublishDates = this.advt_mag_name_details.filter(
      (a) => a.issue_name == this.issueName
    )[0]['publishing_date'];
    console.log(this.getFilterMagPublishDates);
  }

  getAdvtSize(e) {
    console.log(e);
    this.advtSize = e.value;
  }

  getDates;
  getAdvtDates(e) {
    console.log(e.value.toString());
    this.getDates = e.value;
    this.getDates = this.getDates.filter((a) => a != 'all');
  }

  addAdvt() {
    console.log(this.issueName, this.advtSize);
    this.AdvtDetails.push({
      issue: this.issueName,
      advtsize: this.advtSize,
      advtdates: this.getDates.toString(),
    });
  }

  deleteAdvt(i) {
    if (window.confirm('Do you really want to remove the Advertisement?')) {
      this.AdvtDetails.splice(i, 1);
    }
  }

  displayChequqField = false;

  getcheque(event) {
    const value = event.value;
    if (value == 'cheque') {
      this.displayChequqField = true;
    } else {
      this.displayChequqField = false;
    }
  }

  advtamount = 0;
  discount = 0;
  sgstamt = 0;
  igstamt = 0;
  totalamt = 0;
  remainingamt = 0;
  amountTobePay = 0;

  // getAdvtAmount() {
  //   // console.log(this.ionicForm.get('advtamout').value);
  //   // console.log(event.target.value);
  //   if (
  //     this.ionicForm.get('advtamout').value != '' ||
  //     this.ionicForm.get('advtamout').value != 0
  //   ) {
  //     this.advtamount = parseInt(this.ionicForm.get('advtamout').value);

  //     if (this.showigst) {
  //       this.igstamt = Math.round(
  //         ((this.advtamount - this.advtamount * (this.discount / 100)) * 5) /
  //           100
  //       );
  //       this.ionicForm.get('igstamount').setValue(this.igstamt);
  //       this.totalamt = Math.round(
  //         this.advtamount -
  //           this.advtamount * (this.discount / 100) +
  //           this.igstamt
  //       );
  //       this.ionicForm.get('amount').setValue(this.totalamt);
  //     } else {
  //       this.sgstamt = Math.round(
  //         ((this.advtamount - this.advtamount * (this.discount / 100)) * 2.5) /
  //           100
  //       );
  //       this.ionicForm.get('gstamount').setValue(this.sgstamt + this.sgstamt);
  //       this.totalamt = Math.round(
  //         this.advtamount -
  //           this.advtamount * (this.discount / 100) +
  //           this.sgstamt +
  //           this.sgstamt
  //       );
  //       this.ionicForm.get('amount').setValue(this.totalamt);
  //     }
  //   } else {
  //     this.advtamount = 0;

  //     if (this.showigst) {
  //       this.igstamt = 0;
  //       this.ionicForm.get('igstamount').setValue(0);
  //       this.totalamt = 0;
  //       this.ionicForm.get('amount').setValue(0);
  //     } else {
  //       this.sgstamt = 0;
  //       this.ionicForm.get('gstamount').setValue(0);
  //       this.totalamt = 0;
  //       this.ionicForm.get('amount').setValue(0);
  //     }
  //   }
  // }

  getAdvtAmount() {
    // console.log(this.ionicForm.get('advtamout').value);
    // console.log(event.target.value);
    // console.log(this.ionicForm.get('gstie').value);
    if (
      this.ionicForm.get('advtamout').value != '' ||
      this.ionicForm.get('advtamout').value != 0
    ) {
      this.advtamount = parseInt(this.ionicForm.get('advtamout').value);
      if (this.gstie == 'gstInclude') {
        if (this.showigst) {
          this.igstamt = Math.round(
            ((this.advtamount - this.advtamount * (this.discount / 100)) *
              4.76) /
              100
          );
          console.log(this.igstamt);
          this.ionicForm.get('igstamount').setValue(this.igstamt);
          this.totalamt = Math.round(this.advtamount - this.igstamt);
          this.ionicForm.get('amount').setValue(this.totalamt);
        } else {
          this.sgstamt = Math.round(
            ((this.advtamount - this.advtamount * (this.discount / 100)) *
              2.38) /
              100
          );
          // console.log(this.sgstamt);
          this.ionicForm.get('gstamount').setValue(this.sgstamt + this.sgstamt);
          this.totalamt = Math.round(
            this.advtamount - (this.sgstamt + this.sgstamt)
          );
          this.ionicForm.get('amount').setValue(this.totalamt);
        }
      } else if (this.gstie == 'gstExclude') {
        if (this.showigst) {
          this.igstamt = Math.round(
            ((this.advtamount - this.advtamount * (this.discount / 100)) * 5) /
              100
          );
          this.ionicForm.get('igstamount').setValue(this.igstamt);
          this.totalamt = Math.round(
            this.advtamount -
              this.advtamount * (this.discount / 100) +
              this.igstamt
          );
          this.ionicForm.get('amount').setValue(this.totalamt);
        } else {
          this.sgstamt = Math.round(
            ((this.advtamount - this.advtamount * (this.discount / 100)) *
              2.5) /
              100
          );
          this.ionicForm.get('gstamount').setValue(this.sgstamt + this.sgstamt);
          this.totalamt = Math.round(
            this.advtamount -
              this.advtamount * (this.discount / 100) +
              this.sgstamt +
              this.sgstamt
          );
          this.ionicForm.get('amount').setValue(this.totalamt);
        }
      }
    } else {
      if (this.showigst) {
        this.igstamt = 0;
        this.ionicForm.get('igstamount').setValue(0);
        this.totalamt = 0;
        this.ionicForm.get('amount').setValue(0);
      } else {
        this.sgstamt = 0;
        this.ionicForm.get('gstamount').setValue(0);
        this.totalamt = 0;
        this.ionicForm.get('amount').setValue(0);
      }
    }
  }

  gstie = '';
  changeType(type) {
    this.gstie = type.value;
    console.log(this.gstie);
    console.log(this.advtamount);
    if (
      this.ionicForm.get('advtdiscount').value != '' ||
      this.ionicForm.get('advtdiscount').value != 0
    ) {
      this.getAdvtDiscount();
    } else if (
      this.ionicForm.get('advtamout').value != '' ||
      this.ionicForm.get('advtamout').value != 0
    ) {
      this.getAdvtAmount();
    }
  }

  // getAdvtDiscount() {
  //   if (this.advtamount != 0) {
  //     if (
  //       this.ionicForm.get('advtdiscount').value != '' ||
  //       this.ionicForm.get('advtdiscount').value != 0
  //     ) {
  //       this.discount = parseInt(this.ionicForm.get('advtdiscount').value);

  //       if (this.showigst) {
  //         this.igstamt = Math.round(
  //           ((this.advtamount - this.advtamount * (this.discount / 100)) * 5) /
  //             100
  //         );
  //         this.ionicForm.get('igstamount').setValue(this.igstamt);
  //         this.totalamt = Math.round(
  //           this.advtamount -
  //             this.advtamount * (this.discount / 100) +
  //             this.igstamt
  //         );
  //         this.ionicForm.get('amount').setValue(this.totalamt);
  //       } else {
  //         this.sgstamt = Math.round(
  //           ((this.advtamount - this.advtamount * (this.discount / 100)) *
  //             2.5) /
  //             100
  //         );
  //         this.ionicForm.get('gstamount').setValue(this.sgstamt + this.sgstamt);
  //         this.totalamt = Math.round(
  //           this.advtamount -
  //             this.advtamount * (this.discount / 100) +
  //             this.sgstamt +
  //             this.sgstamt
  //         );
  //         this.ionicForm.get('amount').setValue(this.totalamt);
  //       }
  //     } else {
  //       this.discount = 0;

  //       if (this.showigst) {
  //         this.igstamt = (this.advtamount * 5) / 100;
  //         this.ionicForm.get('igstamount').setValue(this.igstamt);
  //         this.totalamt = this.advtamount + this.igstamt;
  //         this.ionicForm.get('amount').setValue(this.totalamt);
  //       } else {
  //         this.sgstamt = (this.advtamount * 2.5) / 100;
  //         this.ionicForm.get('gstamount').setValue(this.sgstamt + this.sgstamt);
  //         this.totalamt = this.advtamount + this.sgstamt + this.sgstamt;
  //         this.ionicForm.get('amount').setValue(this.totalamt);
  //       }
  //     }
  //   }
  // }

  getAdvtDiscount() {
    if (this.advtamount != 0) {
      // if(this.ionicForm.get('gstie').value)
      if (
        this.ionicForm.get('advtdiscount').value != '' ||
        this.ionicForm.get('advtdiscount').value != 0
      ) {
        this.discount = parseInt(this.ionicForm.get('advtdiscount').value);
        if (this.gstie == 'gstInclude') {
          if (this.showigst) {
            this.igstamt = Math.round(
              ((this.advtamount - this.advtamount * (this.discount / 100)) *
                4.76) /
                100
            );
            this.ionicForm.get('igstamount').setValue(this.igstamt);
            this.totalamt = Math.round(
              this.advtamount -
                this.advtamount * (this.discount / 100) -
                this.igstamt
            );
            this.ionicForm.get('amount').setValue(this.totalamt);
          } else {
            this.sgstamt = Math.round(
              ((this.advtamount - this.advtamount * (this.discount / 100)) *
                2.38) /
                100
            );
            this.ionicForm
              .get('gstamount')
              .setValue(this.sgstamt + this.sgstamt);
            this.totalamt = Math.round(
              this.advtamount -
                this.advtamount * (this.discount / 100) -
                (this.sgstamt + this.sgstamt)
            );
            this.ionicForm.get('amount').setValue(this.totalamt);
          }
        } else if (this.gstie == 'gstExclude') {
          if (this.showigst) {
            this.igstamt = Math.round(
              ((this.advtamount - this.advtamount * (this.discount / 100)) *
                5) /
                100
            );
            this.ionicForm.get('igstamount').setValue(this.igstamt);
            this.totalamt = Math.round(
              this.advtamount -
                this.advtamount * (this.discount / 100) +
                this.igstamt
            );
            this.ionicForm.get('amount').setValue(this.totalamt);
          } else {
            this.sgstamt = Math.round(
              ((this.advtamount - this.advtamount * (this.discount / 100)) *
                2.5) /
                100
            );
            this.ionicForm
              .get('gstamount')
              .setValue(this.sgstamt + this.sgstamt);
            this.totalamt = Math.round(
              this.advtamount -
                this.advtamount * (this.discount / 100) +
                this.sgstamt +
                this.sgstamt
            );
            this.ionicForm.get('amount').setValue(this.totalamt);
          }
        }
      } else {
        this.discount = 0;

        if (this.showigst) {
          this.igstamt = (this.advtamount * 5) / 100;
          this.ionicForm.get('igstamount').setValue(this.igstamt);
          this.totalamt = this.advtamount + this.igstamt;
          this.ionicForm.get('amount').setValue(this.totalamt);
        } else {
          this.sgstamt = (this.advtamount * 2.5) / 100;
          this.ionicForm.get('gstamount').setValue(this.sgstamt + this.sgstamt);
          this.totalamt = this.advtamount + this.sgstamt + this.sgstamt;
          this.ionicForm.get('amount').setValue(this.totalamt);
        }
      }
    }
  }

  getSelectedRepId;
  getSelectedRep;
  repCommAmt;
  SelectRepresentative(event) {
    console.log(event.value);
    this.getSelectedRepId = event.value;
    this.getSelectedRep = this.getAllRepList.find(
      (a) => a['id'] == parseInt(this.getSelectedRepId)
    );
    console.log(this.getSelectedRep);
    this.repCommAmt = parseInt(this.getSelectedRep['rep_comm']);
  }

  submitAdd() {}

  totalAmount = 0;
  update() {
    console.log(this.ionicForm.get('state').value);

    let newadvt = {
      address: this.ionicForm.get('address').value,
      country: this.ionicForm.get('country').value,
      state: this.ionicForm.get('state').value,
      taluka: this.ionicForm.get('taluka').value,
      district: this.ionicForm.get('district').value['name'],
      mobile: this.ionicForm.get('mobileNumber').value,
      pincode: this.ionicForm.get('pincode').value,
      advtType: this.ionicForm.get('advtType').value,
      dob: this.ionicForm.get('dob').value,
      companyRegistration: this.ionicForm.get('companyRegistration').value,
      gstno: this.ionicForm.get('gstno').value,
      receiptdate: this.ionicForm.get('receiptdate').value,
      receiptno: this.ionicForm.get('receiptno').value,
    };

    this.UpdateData(newadvt);
  }

  UpdateData(newadvt) {
    console.log(newadvt);

    this.service.updateAdvtPerson(newadvt).subscribe((data) => {
      console.log(data);
      window.alert('Details has been updated! Please try to refresh the page');
    });
  }

  allSelected = false;
  @ViewChild('mySel') skillSel: MatSelect;
  toggleAllSelection() {
    this.allSelected = !this.allSelected; // to control select-unselect
    console.log(this.skillSel.options);
    if (this.allSelected) {
      this.skillSel.options.forEach((item: MatOption) => item.select());
    } else {
      this.skillSel.options.forEach((item: MatOption) => {
        item.deselect();
      });
    }
    this.skillSel.close();
  }
}
