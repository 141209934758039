import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-subscription-renewal',
  templateUrl: './subscription-renewal.component.html',
  styleUrls: ['./subscription-renewal.component.scss']
})
export class SubscriptionRenewalComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
