import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DigisubservicesService } from 'src/app/services/digisubservices.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-update-return-receipt',
  templateUrl: './update-return-receipt.component.html',
  styleUrls: ['./update-return-receipt.component.scss'],
})
export class UpdateReturnReceiptComponent implements OnInit {
  updateForm: FormGroup;
  item;

  constructor(
    private dialogref: MatDialogRef<UpdateReturnReceiptComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    private service: DigisubservicesService
  ) {
    this.updateForm = fb.group({
      clientname: [''],
      returnreason: [''],
      karyavahi: [''],
    });
  }

  ngOnInit(): void {
    this.item = this.data['val'];

    this.updateForm.get('clientname').setValue(this.item['name']);
    this.updateForm.get('returnreason').setValue(this.item['reason']);
    this.updateForm.get('karyavahi').setValue(this.item['karyavahi']);
  }

  updateData() {
    let data = {
      id: this.item['id'],
      clientname: this.updateForm.get('clientname').value,
      returnreason: this.updateForm.get('returnreason').value,
      karyavahi: this.updateForm.get('karyavahi').value,
    };

    this.service.updateReturnReceipt(data).subscribe((res) => {
      this.dialogref.close({ res: 'close' });
    });
  }
}
