<div>
    <div style="margin-left: 70%;  margin-bottom: 2%">
        <!-- <button class="btn btn-info" mat-raised-button (click)="assigndisrep()">
                Add Distribution Rep.
            </button> -->
        <button class="noPrint" style="margin-right: 2%; margin-left: 2%" mat-raised-button
            color="primary" printSectionId="print-section" ngxPrint  [useExistingCss]="true">
            print
        </button>
        <button mat-dialog-close onclick="closeexp()" class="btn noPrint">Close</button>
    </div>
    <div class="container"  id="print-section">
        <div class="wrapper">
            <div class="row rowborder ">
                <div class="col-md-3 col-lg-3 col-6">
                    <span>दिनांक : {{ getFormattedDate() }}</span><br>
                    <span>पृष्ठ क्र : 1</span>
                </div>
                <div class="col-md-4 col-lg-4 col-6">
                    <span>हिंदुस्थान प्रकाशन संस्था</span>
                    <span>विवेक साप्ताहिक</span><br>
                    <span>जर्नल व्हाऊचर नोंदी</span>
                </div>
                <div class="col-md-5 col-lg-5 col-6">
                    <span>दि. पासून :{{startDate}}</span> <span>दि. पर्यंत :{{endDate}}</span>
                </div>
            </div>
<ng-container *ngFor="let data of alldebitvoucher">
    <div class="row rowborder hcs" style="gap: 0;">
        <div class="col-2 common">
            <span>सांकेतांक : {{data.voucher_no}}</span>
        </div>
        <div class="col-2 common">
            <span>दिनांक : {{data.voucher_date}}</span>
        </div>
        <div class="col-4">
            <span>तपशील  {{data.expenses[0].narration}}</span>
        </div>

        <div class="col-2">
            <span>डेबिट</span>
        </div>
        <div class="col-2">
            <span>क्रेडिट</span>
        </div>
       
    </div>
    <ng-container *ngFor="let data1 of data.expenses">
        <div class="row rowborder">
            <div class="col-2">
                <span></span>
            </div>
            <!-- <div class="col-1"></div> -->
            <div class="col-2">
                <span>{{data1.exp_name}}</span>
            </div>
            <div class="col-4">
                <span></span>
            </div>
            <div class="col-2">
                <span *ngIf="data1.amt_status=='DR'">{{data1.exp_amt}}</span>
            </div>
            <div class="col-2">
                <span *ngIf="data1.amt_status=='CR'">{{data1.exp_amt}}</span>
            </div>
            
        </div>
    </ng-container>
  
    <div class="row rowborder bcs">
        <div class="col-2">
            <span></span>
        </div>
        <!-- <div class="col-1"></div> -->
        <div class="col-2">
            <span></span>
        </div>
        <div class="col-4">
            <span>नोंदणीची एकूण :</span>
        </div>
        <div class="col-2">
            <span>{{data.total_dr}}</span>
        </div>
        <div class="col-2">
            <span >{{data.total_cr}}</span>
        </div>
        
    </div>
</ng-container>

           
          
        </div>
        <print-preview-layout-settings class="settings-section"></print-preview-layout-settings>

    </div>
</div>