import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatCardModule } from '@angular/material/card';
import { MatSortModule } from '@angular/material/sort';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatPaginatorModule } from '@angular/material/paginator';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EditableTableComponent } from './editable-table.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatTableModule} from '@angular/material/table';


// https://www.npmjs.com/package/angular-notifier
import { NotifierModule } from 'angular-notifier';
// https://www.npmjs.com/package/ngx-spinner#demo
import { NgxSpinnerModule } from 'ngx-spinner';

import { DialogComponent } from './dialog/dialog.component';

@NgModule({
  imports: [
    CommonModule, MatTableModule, MatPaginatorModule, MatFormFieldModule, MatInputModule, MatDialogModule,
    MatButtonModule, MatIconModule, MatGridListModule, MatSortModule, MatCardModule, MatTooltipModule, MatDatepickerModule, MatNativeDateModule,
    FormsModule,
    ReactiveFormsModule,
    NgxSpinnerModule,
    NotifierModule.withConfig({
      // Custom options in here
    }),
  ],
  declarations: [EditableTableComponent, DialogComponent],
  exports: [
    EditableTableComponent,
  ],
  // entryComponents: [DialogComponent]

})
export class EditableTableModule { }
