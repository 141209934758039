import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DigisubservicesService } from '../services/digisubservices.service';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-alladdlist',
  templateUrl: './alladdlist.component.html',
  styleUrls: ['./alladdlist.component.scss']
})
// export class AlladdlistComponent {

// }

export class AlladdlistComponent implements OnInit {
  allAddList: any;

  constructor(private dialogRef: MatDialogRef<AlladdlistComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private service: DigisubservicesService,
    private cdr: ChangeDetectorRef,private fb: FormBuilder,private datePipe:DatePipe
  )
   { }

   district:any
   taluka:any;
   modetype:any
   start_date:any
   end_date:any
   prantname:any
   country:any
   state:any
   duration:any
  ngOnInit(): void {

    this.district= this.data['districtName']
    this.taluka= this.data['talukaName']
    this.modetype= this.data['modeType']
    this.start_date=  this.data['date']
    this.end_date=  this.data['endDate']
    this.prantname= this.data['prantname']
    this.country=this.data['country']
    this.state=this.data['state']
    this.duration=this.data['duration']


    const req:any = {
    
      "delivery_mode":this.modetype,
      "district":this.district,
      "taluka":this.taluka,
      "start_date": this.start_date,
      "end_date": this.end_date,
      "prant_name": this.prantname,
      "country": this.country,
      "state": this.state,
      "sub_type":this.duration,
    
    }

    console.log(req, "new all address")

    this.service.getgetAllAddList(req).subscribe((data:any) => {
debugger
      // console.log("pending add=",data);
     this.allAddList = (data as any);;
     debugger
    });
debugger
    // console.log("req 2 for add", req)
  }

}
