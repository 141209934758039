<div class="header bg-gradient-danger pb-1 pt-5 pt-md-8">
    <div class="container-fluid">
      <div class="header-body"></div>
    </div>
  </div>

  <div class="container-fluid" style="margin-top: -4.1em">
    <!-- Icons is Used as Admin -->
    <mat-tab-group dynamicHeight [(selectedIndex)]="demo1TabIndex"  (selectedTabChange)="onTabClick($event)">

        <mat-tab label="Subscription Renewal Report">
            <ng-container *ngIf="demo1TabIndex==0">
            <mat-card style="height: 600px;">
              <h3 class="text-center" style="font-size: 25px;">Delivery Modewise</h3>
              <div class="form-row " [formGroup]="RenewalreportForm">
                <mat-form-field appearance="outline" style="width: 40%; margin-top: 2%; margin-left: 7%;">
                  <mat-label >Enter a date range</mat-label>
                  <mat-date-range-input  [rangePicker]="pickerdist1">
                    <input matStartDate formControlName="start" placeholder="Start date" />
                    <input matEndDate formControlName="end" placeholder="End date" (dateChange)="getChangedDatedist()" />
                  </mat-date-range-input>
                  <mat-datepicker-toggle matSuffix [for]="pickerdist1"></mat-datepicker-toggle>
                  <mat-date-range-picker #pickerdist1></mat-date-range-picker>
                  <mat-error *ngIf="
                  RenewalreportForm.controls.start.hasError(
                                                  'matStartDateInvalid'
                                                )
                                              ">Invalid start date</mat-error>
                  <mat-error *ngIf="
                  RenewalreportForm.controls.end.hasError('matEndDateInvalid')
                                              ">Invalid end date</mat-error>
                  <!-- <mat-select formControlName="date">
                    <mat-option value="2023-2024">2023-2024</mat-option>
                    <mat-option value="2024-2025">2024-2025</mat-option>
                    <mat-option value="2025-2026">2025-2026</mat-option>
                    <mat-option value="2026-2027">2026-2027</mat-option>
                    <mat-option value="2027-2028">2027-2028</mat-option>
      
                  </mat-select> -->
                </mat-form-field>
      
                <mat-form-field appearance="outline" style="margin-left: 3%; margin-top: 2%;width: 40%;">
                  <mat-label>Officer Name</mat-label>
                  <mat-select formControlName="officer" multiple>
                    <mat-option value="ALL" (click)="toggleAllSelection()">All</mat-option>
                    <!-- <mat-option *ngFor="let option of GetOffRep" [value]="option['office_rep']">{{ option["office_rep"]
                      }}</mat-option> -->
                      <mat-option *ngFor="let option of LabelsDispatchOffRepNames" [value]="option['off_rep_name']">{{
                        option["off_rep_name"]
                        }}</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
      
              <div class="form-row" [formGroup]="RenewalreportForm">
                <mat-form-field appearance="outline" style="margin-left: 7%; width: 40%" class="example-full-width">
                  <mat-label>Country</mat-label>
                  <mat-select (selectionChange)="getCountry($event)" formControlName="country" >
                    <mat-option *ngFor="let c of countries" value="{{ c['country_name'] }}">{{ c["country_name"] }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
      
      
                <mat-form-field appearance="outline" style="margin-left: 3%; width: 40%">
                  <mat-label>State Name</mat-label>
                  <mat-select #mySel4 formControlName="statename" (selectionChange)="getstatename($event)" multiple>
                   
                    <mat-option *ngFor="let option of states" [value]="option['state_name']">{{
                      option["state_name"]
                      }}</mat-option>
                  </mat-select>
                  <button *ngIf="state_names" matSuffix mat-icon-button aria-label="Clear" (click)="reset_state_names()">
                    <mat-icon>close</mat-icon>
                  </button>
                </mat-form-field>
                <mat-form-field appearance="outline" style="margin-left: 7%; width: 40%" class="example-full-width">
                  <mat-label>District Name</mat-label>
                  <mat-select #mySel5 formControlName="districtname"
                    (selectionChange)="getDistricts($event); getdistrictname($event)" multiple>
                    <!-- <mat-option value="all" (click)="toggleAllSelection5()">All</mat-option> -->
                    <!-- LabelsDispatchDistrictName getdistrictname() -->
                    <mat-option *ngFor="let option of cities" [value]="option['district_name']">{{
                      option["district_name"]
                      }}</mat-option>
                  </mat-select>
                  <button *ngIf="district_names" matSuffix mat-icon-button aria-label="Clear"
                    (click)="reset_district_names()">
                    <mat-icon>close</mat-icon>
                  </button>
                </mat-form-field>
                <mat-form-field appearance="outline" style="margin-left: 3%; width: 40%">
                  <mat-label>Taluka Name</mat-label>
                  <mat-select #mySel6 formControlName="talukaname" (selectionChange)="gettalukaname($event)" multiple>
                    <mat-option value="ALL" (click)="toggleAllSelection6()">All</mat-option>
                    <!-- LabelsDispatchTalukaName -->
                    <mat-option *ngFor="let option of talukas" [value]="option['taluka_name']">{{
                      option["taluka_name"]
                      }}</mat-option>
                  </mat-select>
                  <button *ngIf="taluka_names" matSuffix mat-icon-button aria-label="Clear" (click)="reset_taluka_names()">
                    <mat-icon>close</mat-icon>
                  </button>
                </mat-form-field>
              </div>
      
              <div class="form-row" [formGroup]="RenewalreportForm">
                <mat-form-field appearance="outline" style="margin-left: 7%; width: 40%" class="example-full-width">
                  <mat-label>Represantative Name</mat-label>
                  <mat-select formControlName="rep"  multiple>
                    <mat-option value="ALL" (click)="toggleAllSelection()">All</mat-option>
                    <mat-option *ngFor="let option of GetOffRep" [value]="option['rep_name']">{{ option["rep_name"]
                      }}</mat-option>
                  </mat-select>      
                </mat-form-field> 
                <mat-form-field appearance="outline" style="margin-left: 3%; width: 40%" class="example-full-width">
                  <mat-label>Prant Name</mat-label>
                  <mat-select formControlName="prantname"  multiple>
                    <mat-option value="ALL" (click)="toggleAllSelection()">All</mat-option>
                    <mat-option *ngFor="let option of Getprantname" [value]="option['prant_name']">{{ option["prant_name"]
                      }}</mat-option>
                  </mat-select>      
                </mat-form-field> 
                
              </div>
              <div class="form-row" [formGroup]="RenewalreportForm">
                
                
                <mat-form-field appearance="outline" style="margin-left: 7%; width: 40%;">
                  <mat-label>Select type of report</mat-label>
                  <mat-select #mySel1 formControlName="reportType" (selectionChange)="getContractReportType($event)">
                    <!-- <mat-option value="all" (click)="toggleAllSelection()">All</mat-option> -->
                    <mat-option value="Renewaljilha">Zilha Wise
                    </mat-option>
                    <mat-option value="Renewaltaluka">Taluka Wise
                    </mat-option>
                    <mat-option value="RenewalOff">Office Wise
                    </mat-option>
                    <mat-option value="Renewalrep">Reprensentative Wise
                    </mat-option>
                    <mat-option value="PrantwiseDeliverymode">Prant Wise
                    </mat-option>
                    <!-- <mat-option value="Statewise">StateWise
                    </mat-option> -->
                  </mat-select>
                </mat-form-field>
              </div>
              <div class="form-row" style="margin-left: 7%;">
                <button class="btn btn-info" (click)="getrenewalreport1()">Subscription
                  Report Print</button>
                  <button type="button" (click)="excelToExportGSTReport('GST_Report')" class="btn btn-danger">
                    Export
                  </button>
              </div>
              
              <div id="excel-table">
                <div class="tableFixHead">
                 
                </div>
              </div>
            </mat-card>
      
            <mat-card style="height: 600px;">
              <h3 class="text-center" style="font-size: 25px;">Subscription Type Report</h3>
              <div class="form-row " [formGroup]="RenewalTypereportForm">
                <mat-form-field appearance="outline" style="margin-left: 7%; width: 40%; ">
                  <mat-label>Receipt Date</mat-label>
                  <mat-date-range-input [rangePicker]="pickerrRT1" >
                    <input matStartDate formControlName="start" placeholder="Start date" />
                    <input matEndDate formControlName="end" placeholder="End date"
                      (dateChange)="getChangedDateContractM1()" />
                  </mat-date-range-input>
                  <mat-datepicker-toggle matSuffix [for]="pickerrRT1"></mat-datepicker-toggle>
                  <mat-date-range-picker #pickerrRT1></mat-date-range-picker>
      
                  <mat-error *ngIf="
                  RenewalTypereportForm.controls.start.hasError(
                                        'matStartDateInvalid'
                                      )
                                    ">Invalid start date
                  </mat-error>
                  <mat-error *ngIf="
                  RenewalTypereportForm.controls.end.hasError('matEndDateInvalid')
                                    ">Invalid end date
                  </mat-error>
                </mat-form-field>
      
                <mat-form-field appearance="outline" style="margin-left: 3%; width: 40%;">
                  <mat-label>Officer Name</mat-label>
                  <mat-select formControlName="officer" multiple>
                    <mat-option value="ALL" (click)="toggleAllSelection()">All</mat-option>
                    <!-- <mat-option *ngFor="let option of GetOffRep" [value]="option['office_rep']">{{ option["office_rep"]
                      }}</mat-option> -->
                      <mat-option *ngFor="let option of LabelsDispatchOffRepNames" [value]="option['off_rep_name']">{{
                        option["off_rep_name"]
                        }}</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
      
              <div class="form-row" [formGroup]="RenewalTypereportForm">
                <mat-form-field appearance="outline" style="margin-left: 7%; width: 40%" class="example-full-width">
                  <mat-label>Country</mat-label>
                  <mat-select (selectionChange)="getCountry($event)" formControlName="country" >
                    <mat-option *ngFor="let c of countries" value="{{ c['country_name'] }}">{{ c["country_name"] }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
      
                <mat-form-field appearance="outline" style="margin-left: 3%; width: 40%">
                  <mat-label>State Name</mat-label>
                  <mat-select #mySel4 formControlName="statename" (selectionChange)="getstatename($event)" multiple>
                    <!-- <mat-option value="all" (click)="toggleAllSelection4()">All</mat-option> -->
                    <!-- LabelsDispatchStateName getstatename()-->
                    <mat-option *ngFor="let option of states" [value]="option['state_name']">{{
                      option["state_name"]
                      }}</mat-option>
                  </mat-select>
                  <button *ngIf="state_names" matSuffix mat-icon-button aria-label="Clear" (click)="reset_state_names()">
                    <mat-icon>close</mat-icon>
                  </button>
                </mat-form-field>
                <mat-form-field appearance="outline" style="margin-left: 7%; width: 40%" class="example-full-width">
                  <mat-label>District Name</mat-label>
                  <mat-select #mySel5 formControlName="districtname"
                    (selectionChange)="getDistricts($event); getdistrictname($event)" multiple>
                    <!-- <mat-option value="all" (click)="toggleAllSelection5()">All</mat-option> -->
                    <!-- LabelsDispatchDistrictName getdistrictname() -->
                    <mat-option *ngFor="let option of cities" [value]="option['district_name']">{{
                      option["district_name"]
                      }}</mat-option>
                  </mat-select>
                  <button *ngIf="district_names" matSuffix mat-icon-button aria-label="Clear"
                    (click)="reset_district_names()">
                    <mat-icon>close</mat-icon>
                  </button>
                </mat-form-field>
                <mat-form-field appearance="outline" style="margin-left: 3%; width: 40%">
                  <mat-label>Taluka Name</mat-label>
                  <mat-select #mySel6 formControlName="talukaname" (selectionChange)="gettalukaname($event)" multiple>
                    <mat-option value="ALL" (click)="toggleAllSelection6()">All</mat-option>
                    <!-- LabelsDispatchTalukaName -->
                    <mat-option *ngFor="let option of talukas" [value]="option['taluka_name']">{{
                      option["taluka_name"]
                      }}</mat-option>
                  </mat-select>
                  <button *ngIf="taluka_names" matSuffix mat-icon-button aria-label="Clear" (click)="reset_taluka_names()">
                    <mat-icon>close</mat-icon>
                  </button>
                </mat-form-field>
              </div>
      
              <div class="form-row" [formGroup]="RenewalTypereportForm">
                <mat-form-field appearance="outline" style="margin-left: 7%; width: 40%" class="example-full-width">
                  <mat-label>Represantative Name</mat-label>
                  <mat-select formControlName="rep"  multiple>
                    <mat-option value="ALL" (click)="toggleAllSelection()">All</mat-option>
                    <mat-option *ngFor="let option of GetOffRep" [value]="option['rep_name']">{{ option["rep_name"]
                      }}</mat-option>
                  </mat-select>          </mat-form-field>
      
                <mat-form-field appearance="outline" style="margin-left: 3%; width: 40%" class="example-full-width">
                  <mat-label>Prant Name</mat-label>
                  <mat-select formControlName="prantname"  multiple>
                    <mat-option value="ALL" (click)="toggleAllSelection()">All</mat-option>
                    <mat-option *ngFor="let option of Getprantname" [value]="option['prant_name']">{{ option["prant_name"]
                      }}</mat-option>
                  </mat-select>      
                </mat-form-field> 
      
                
                <mat-form-field appearance="outline" style="margin-left: 7%; width: 40%;">
                  <mat-label>Select type of report</mat-label>
                  <mat-select #mySel1 formControlName="reportType" (selectionChange)="getContractReportType($event)">
                    <!-- <mat-option value="all" (click)="toggleAllSelection()">All</mat-option> -->
                    <mat-option value="SubscriptionTypeAll">Subscription Type 
                    </mat-option>
                    <mat-option value="SubscriptionTypeTaluka">Subscription Type Taluka
                    </mat-option>
                    <mat-option value="SubscriptionTypeOffReprensantive">Subscription Type Off Reprensantive
                    </mat-option>
                    <mat-option value="SubscriptionTypeReprensantive">Subscription Type Reprensantive
                    </mat-option>
                  </mat-select>
                </mat-form-field>
                
              </div>
              <div class="form-row" style="margin-left: 7%;">
                <button class="btn btn-info" (click)="getrenewalreport3()">Subscription
                  Report Print</button>
                  <button type="button" (click)="excelToExportGSTReport('GST_Report')" class="btn btn-danger">
                    Export
                  </button>
              </div>
              
              <div id="excel-table">
                <div class="tableFixHead">
                  
                </div>
              </div>
            </mat-card>
      
            <mat-card style="height: 600px;">
              <h3 class="text-center" style="font-size: 25px;">Remaining Subscription Renewal</h3>
      
      
              <div class="form-row " [formGroup]="RemainingRenewalreportForm">
                <mat-form-field appearance="outline" style="margin-left: 7%; width: 40%; margin-top: 3%;">
                  <mat-label>Receipt Date</mat-label>
                  <mat-date-range-input [rangePicker]="pickerrRSR" >
                    <input matStartDate formControlName="start" placeholder="Start date" />
                    <input matEndDate formControlName="end" placeholder="End date"
                      (dateChange)="getChangedDateContractM1()" />
                  </mat-date-range-input>
                  <mat-datepicker-toggle matSuffix [for]="pickerrRSR"></mat-datepicker-toggle>
                  <mat-date-range-picker #pickerrRSR
                  ></mat-date-range-picker>
      
                  <mat-error *ngIf="
                  RemainingRenewalreportForm.controls.start.hasError(
                                        'matStartDateInvalid'
                                      )
                                    ">Invalid start date
                  </mat-error>
                  <mat-error *ngIf="
                  RemainingRenewalreportForm.controls.end.hasError('matEndDateInvalid')
                                    ">Invalid end date
                  </mat-error>
                </mat-form-field>
      
                <mat-form-field appearance="outline" style="margin-left: 3%; margin-top: 2%;width: 40%; padding-top: 10px;">
                  <mat-label>Officer Name</mat-label>
                  <mat-select formControlName="officer" multiple>
                    <mat-option value="ALL" (click)="toggleAllSelection()">All</mat-option>
                    <!-- <mat-option *ngFor="let option of GetOffRep" [value]="option['office_rep']">{{ option["office_rep"]
                      }}</mat-option> -->
                      <mat-option *ngFor="let option of LabelsDispatchOffRepNames" [value]="option['off_rep_name']">{{
                        option["off_rep_name"]
                        }}</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
      
              <div class="form-row" [formGroup]="RemainingRenewalreportForm">
                <mat-form-field appearance="outline" style="margin-left: 7%; width: 40%" class="example-full-width">
                  <mat-label>Country</mat-label>
                  <mat-select (selectionChange)="getCountry($event)" formControlName="country" >
                    <mat-option *ngFor="let c of countries" value="{{ c['country_name'] }}">{{ c["country_name"] }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
                <mat-form-field appearance="outline" style="margin-left: 3%; width: 40%">
                  <mat-label>State Name</mat-label>
                  <mat-select #mySel4 formControlName="statename" (selectionChange)="getstatename($event)" multiple>
                    <!-- <mat-option value="all" (click)="toggleAllSelection4()">All</mat-option> -->
                    <!-- LabelsDispatchStateName getstatename()-->
                    <mat-option *ngFor="let option of states" [value]="option['state_name']">{{
                      option["state_name"]
                      }}</mat-option>
                  </mat-select>
                  <button *ngIf="state_names" matSuffix mat-icon-button aria-label="Clear" (click)="reset_state_names()">
                    <mat-icon>close</mat-icon>
                  </button>
                </mat-form-field>
                <mat-form-field appearance="outline" style="margin-left: 7%; width: 40%" class="example-full-width">
                  <mat-label>District Name</mat-label>
                  <mat-select #mySel5 formControlName="districtname"
                    (selectionChange)="getDistricts($event); getdistrictname($event)" multiple>
                    <!-- <mat-option value="all" (click)="toggleAllSelection5()">All</mat-option> -->
                    <!-- LabelsDispatchDistrictName getdistrictname() -->
                    <mat-option *ngFor="let option of cities" [value]="option['district_name']">{{
                      option["district_name"]
                      }}</mat-option>
                  </mat-select>
                  <button *ngIf="district_names" matSuffix mat-icon-button aria-label="Clear"
                    (click)="reset_district_names()">
                    <mat-icon>close</mat-icon>
                  </button>
                </mat-form-field>
                <mat-form-field appearance="outline" style="margin-left: 3%; width: 40%">
                  <mat-label>Taluka Name</mat-label>
                  <mat-select #mySel6 formControlName="talukaname" (selectionChange)="gettalukaname($event)" multiple>
                    <mat-option value="ALL" (click)="toggleAllSelection6()">All</mat-option>
                    <!-- LabelsDispatchTalukaName -->
                    <mat-option *ngFor="let option of talukas" [value]="option['taluka_name']">{{
                      option["taluka_name"]
                      }}</mat-option>
                  </mat-select>
                  <button *ngIf="taluka_names" matSuffix mat-icon-button aria-label="Clear" (click)="reset_taluka_names()">
                    <mat-icon>close</mat-icon>
                  </button>
                </mat-form-field>
                
              </div>
              <div class="form-row" [formGroup]="RemainingRenewalreportForm">
                <mat-form-field appearance="outline" style="margin-left: 7%; width: 40%" class="example-full-width">
                  <mat-label>Represantative Name</mat-label>
                  <mat-select formControlName="rep"  multiple>
                    <mat-option value="ALL" (click)="toggleAllSelection()">All</mat-option>
                    <mat-option *ngFor="let option of GetOffRep" [value]="option['rep_name']">{{ option["rep_name"]
                      }}</mat-option>
                  </mat-select>          </mat-form-field>
          
      
                  
                  <mat-form-field appearance="outline" style="margin-left: 3%; width: 40%" class="example-full-width">
                    <mat-label>Prant Name</mat-label>
                    <mat-select formControlName="prantname"  multiple>
                      <mat-option value="ALL" (click)="toggleAllSelection()">All</mat-option>
                      <mat-option *ngFor="let option of Getprantname" [value]="option['prant_name']">{{ option["prant_name"]
                        }}</mat-option>
                    </mat-select>      
                  </mat-form-field> 
                 
                  <!-- <mat-form-field style="margin-left: 7%; width: 40%;" appearance="fill"> -->
      
                <mat-form-field appearance="outline" style="margin-left: 7%; width: 40%;">
                  <mat-label>Select type of report</mat-label>
                  <mat-select #mySel1 formControlName="reportType" (selectionChange)="getContractReportType($event)">
                    <!-- <mat-option value="all" (click)="toggleAllSelection()">All</mat-option> -->
                    <mat-option value="RemainingSubscriptionDistrict">Remaining Subscription District
                    </mat-option>
                    <mat-option value="RemainingSubscriptionTaluka">Remaining Subscription Taluka
                    </mat-option>
                    <mat-option value="RemainingSubscriptionOffReprensantive">Remaining Subscription Off Reprensantive
                    </mat-option>
                    <mat-option value="RemainingSubscriptionReprensantive">Remaining Subscription Reprensantive
                    </mat-option>

                   <mat-option value="RemainingSubscriptionoffReprensantiveBriefly">Remaining Subscription Off-Reprensantive Briefly
                    </mat-option>

                    <mat-option value="RemainingSubscriptionReprensantiveOffvb">Remaining Subscription off Reprensantive very Briefly
                    </mat-option>

                    <mat-option value="RemainingSubscriptionReprensantiveRepb">Remaining Subscription Reprensantive Briefly
                    </mat-option>

                    <mat-option value="RemainingSubscriptionReprensantiveRepvb">Remaining Subscription Reprensantive very Briefly
                    </mat-option>

                  
                  </mat-select>
                </mat-form-field>
                
              </div>
               
              <div class="form-row" style="margin-left: 7%;">
                <button class="btn btn-info" (click)="getrenewalreportRemaing()">Remaining Subscription
                  </button>
                  <button type="button" (click)="excelToExportGSTReport('GST_Report')" class="btn btn-danger">
                    Export
                  </button>
              </div>
              
              <div id="excel-table">
                <div class="tableFixHead">
                 
                 
                </div>
              </div>
            </mat-card>
      
      
            <mat-card style="height: 500px;">
              <h3 class="text-center" style="font-size: 25px;">Address Label</h3>
      
      
              <div class="form-row" [formGroup]="AllAddForm">
                <mat-form-field appearance="outline" style="margin-left: 7%; width: 40%; ">
                  <mat-label>Receipt Date</mat-label>
                  <mat-date-range-input [rangePicker]="pickerAA" >
                    <input matStartDate formControlName="start" placeholder="Start date" />
                    <input matEndDate formControlName="end" placeholder="End date"
                      (dateChange)="getChangedDateContractA1()" />
                  </mat-date-range-input>
                  <mat-datepicker-toggle matSuffix [for]="pickerAA"></mat-datepicker-toggle>
                  <mat-date-range-picker #pickerAA
                  ></mat-date-range-picker>
      
                  <mat-error *ngIf="
                  AllAddForm.controls.start.hasError(
                                        'matStartDateInvalid'
                                      )
                                    ">Invalid start date
                  </mat-error>
                  <mat-error *ngIf="
                  AllAddForm.controls.end.hasError('matEndDateInvalid')
                                    ">Invalid end date
                  </mat-error>
                </mat-form-field>
      
                <mat-form-field appearance="outline" style="margin-left: 3%; width: 40%" class="example-full-width">
                  <mat-label>Country</mat-label>
                  <mat-select (selectionChange)="getCountry($event)" formControlName="country" >
                    <mat-option *ngFor="let c of countries" value="{{ c['country_name'] }}">{{ c["country_name"] }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
      
                <mat-form-field appearance="outline" style="margin-left: 7%; width: 40%; margin-top: 2%">
                  <mat-label>State Name</mat-label>
                  <mat-select #mySel4 formControlName="statename" (selectionChange)="getstatename($event)" multiple>
                    <!-- <mat-option value="all" (click)="toggleAllSelection4()">All</mat-option> -->
                    <!-- LabelsDispatchStateName getstatename()-->
                    <mat-option *ngFor="let option of states" [value]="option['state_name']">{{
                      option["state_name"]
                      }}</mat-option>
                  </mat-select>
                  <button *ngIf="state_names" matSuffix mat-icon-button aria-label="Clear" (click)="reset_state_names()">
                    <mat-icon>close</mat-icon>
                  </button>
                </mat-form-field>
                <mat-form-field appearance="outline" style="margin-left: 3%; width: 40%" class="example-full-width">
                  <mat-label>District Name</mat-label>
                  <mat-select #mySel5 formControlName="districtname"
                    (selectionChange)="getDistricts($event); getdistrictname($event)" multiple>
                    <!-- <mat-option value="all" (click)="toggleAllSelection5()">All</mat-option> -->
                    <!-- LabelsDispatchDistrictName getdistrictname() -->
                    <mat-option *ngFor="let option of cities" [value]="option['district_name']">{{
                      option["district_name"]
                      }}</mat-option>
                  </mat-select>
                  <button *ngIf="district_names" matSuffix mat-icon-button aria-label="Clear"
                    (click)="reset_district_names()">
                    <mat-icon>close</mat-icon>
                  </button>
                </mat-form-field>
               <mat-form-field appearance="outline" style="margin-left: 7%; width: 40%; margin-top: 2%">
                  <mat-label>Taluka Name</mat-label>
                  <mat-select #mySel6 formControlName="talukaname" (selectionChange)="gettalukaname($event)" multiple>
                    <mat-option value="ALL" (click)="toggleAllSelection6()">All</mat-option>
                    <!-- LabelsDispatchTalukaName -->
                    <mat-option *ngFor="let option of talukas" [value]="option['taluka_name']">{{
                      option["taluka_name"]
                      }}</mat-option>
                  </mat-select>
                  <button *ngIf="taluka_names" matSuffix mat-icon-button aria-label="Clear" (click)="reset_taluka_names()">
                    <mat-icon>close</mat-icon>
                  </button>
                </mat-form-field>
      
                <mat-form-field appearance="outline" style="margin-left: 3%; margin-top: 2%;width: 40%;">
                  <mat-label>Delivery Mode</mat-label>
                  <mat-select formControlName="modetype"  multiple>
                    <mat-option value="ALL" (click)="toggleAllSelection()">All</mat-option>
                    <mat-option value="टपाल" >टपाल</mat-option>
                    <mat-option value="हस्ते" >हस्ते</mat-option>
                  </mat-select>      
                  </mat-form-field>
              </div>
      
              <div class="form-row" [formGroup]="AllAddForm">
                
              </div>
              <!-- <div class="form-row" style="margin-left: 7%;"> -->
                <div class="form-row" [formGroup]="AllAddForm" class="example-full-width">
                <!-- <button class="btn btn-info" (click)="getrenewalreport6()">Search</button> -->
              
                <mat-form-field appearance="outline" style="margin-left: 7%; width: 40%" class="example-full-width">
                  <mat-label>Prant Name</mat-label>
                  <mat-select formControlName="prantname" multiple>
                    <mat-option value="ALL" (click)="toggleAllSelection()">All</mat-option>
                    <mat-option *ngFor="let option of Getprantname" [value]="option['prant_name']">
                      {{ option["prant_name"] }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
      
               
                <mat-form-field class="example-full-width" appearance="outline" style="width: 40%; margin-left: 3%;">
                  <mat-label>Subscriber Type</mat-label>
                  <mat-select #mySel2 formControlName="subtype" (selectionChange)="getsubtypes($event)" multiple>
                    <!-- <mat-option value="all" (click)="toggleAllSelection2()">All</mat-option> -->
                    <mat-option *ngFor="let option of LabelsDispatchSubtypes" [value]="option['sub_type']">{{
                      option["sub_type"]
                      }}</mat-option>
                  </mat-select>
                  <button *ngIf="sub_types" matSuffix mat-icon-button aria-label="Clear" (click)="reset_sub_types()">
                    <mat-icon>close</mat-icon>
                  </button>
                </mat-form-field>
      
                <!-- <div class="form-row" [formGroup]="AllAddForm"> -->
      
                  <mat-form-field class="example-full-width" appearance="outline" style="width: 40%; margin-left: 7%">
                    <mat-label>Select type of report</mat-label>
                    <mat-select #mySel15 formControlName="reportType" >
                      <!-- <mat-option value="all" (click)="toggleAllSelection()">All</mat-option> -->
                      <mat-option value="Address">Addresss
                      </mat-option>
                      <mat-option value="Address List">Address List
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
        
                <!-- </div> -->
                <button class="btn btn-info " [disabled]="AllAddForm.invalid" (click)="getrenewalreport6()">Search</button>
              
      
              </div>
            </mat-card>
      
      
            <mat-card style="height: 600px;">
              <h3 class="text-center" style="font-size: 25px;"> Representativewise Address</h3>
      
              <div class="form-row" [formGroup]="PendingAddForm">
                <mat-form-field appearance="outline" style="margin-left: 7%; width: 40%; margin-top: 2%;">
                  <mat-label>Receipt Date</mat-label>
                  <mat-date-range-input [rangePicker]="pickerAA4" >
                    <input matStartDate formControlName="start" placeholder="Start date" />
                    <input matEndDate formControlName="end" placeholder="End date"
                      (dateChange)="getChangedDateContractA4()" />
                  </mat-date-range-input>
                  <mat-datepicker-toggle matSuffix [for]="pickerAA4"></mat-datepicker-toggle>
                  <mat-date-range-picker #pickerAA4
                  ></mat-date-range-picker>
      
                </mat-form-field>
                <mat-form-field appearance="outline" style="margin-left: 1%; width: 40%; margin-top: 2%;">
                  <mat-label>Country</mat-label>
                  <mat-select (selectionChange)="getCountry($event)" formControlName="country" >
                    <mat-option *ngFor="let c of countries" value="{{ c['country_name'] }}">{{ c["country_name"] }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
      
                <mat-form-field appearance="outline" style="margin-left: 7%; width: 40%; margin-top: 2%;">
                  <mat-label>State Name</mat-label>
                  <mat-select #mySel4 formControlName="statename" (selectionChange)="getstatename($event)" multiple>
                    <!-- <mat-option value="all" (click)="toggleAllSelection4()">All</mat-option> -->
                    <!-- LabelsDispatchStateName getstatename()-->
                    <mat-option *ngFor="let option of states" [value]="option['state_name']">{{
                      option["state_name"]
                      }}</mat-option>
                  </mat-select>
                  <button *ngIf="state_names" matSuffix mat-icon-button aria-label="Clear" (click)="reset_state_names()">
                    <mat-icon>close</mat-icon>
                  </button>
                </mat-form-field>
      
                <mat-form-field appearance="outline"style="margin-left: 1%; width: 40%; margin-top: 2%;">
                  <mat-label>District Name</mat-label>
                  <mat-select #mySel5 formControlName="districtname"
                    (selectionChange)="getDistricts($event); getdistrictname($event)" multiple>
                    <!-- <mat-option value="all" (click)="toggleAllSelection5()">All</mat-option> -->
                    <!-- LabelsDispatchDistrictName getdistrictname() -->
                    <mat-option *ngFor="let option of cities" [value]="option['district_name']">{{
                      option["district_name"]
                      }}</mat-option>
                  </mat-select>
                  <button *ngIf="district_names" matSuffix mat-icon-button aria-label="Clear"
                    (click)="reset_district_names()">
                    <mat-icon>close</mat-icon>
                  </button>
                </mat-form-field>
              
              </div>
      
              <div class="form-row" [formGroup]="PendingAddForm">
                
                <mat-form-field appearance="outline" style="margin-left: 7%; width: 40%; margin-top: 2%;">
                  <mat-label>Taluka Name</mat-label>
                  <mat-select #mySel6 formControlName="talukaname" (selectionChange)="gettalukaname($event)" multiple>
                    <mat-option value="ALL" (click)="toggleAllSelection6()">All</mat-option>
                    <!-- LabelsDispatchTalukaName -->
                    <mat-option *ngFor="let option of talukas" [value]="option['taluka_name']">{{
                      option["taluka_name"]
                      }}</mat-option>
                  </mat-select>
                  <button *ngIf="taluka_names" matSuffix mat-icon-button aria-label="Clear" (click)="reset_taluka_names()">
                    <mat-icon>close</mat-icon>
                  </button>
                </mat-form-field>
      
                <mat-form-field appearance="outline" style="margin-left: 1%; width: 40%; margin-top: 2%;" class="example-full-width">
                  <mat-label>Represantative Name</mat-label>
                  <mat-select formControlName="rep"  multiple>
                    <mat-option value="ALL" (click)="toggleAllSelection()">All</mat-option>
                    <mat-option *ngFor="let option of GetOffRep" [value]="option['rep_name']">{{ option["rep_name"]
                      }}</mat-option>
                  </mat-select>      
                    </mat-form-field>
                
      
              </div>
              <div class="form-row" [formGroup]="PendingAddForm">
                
             
                <mat-form-field appearance="outline" style="margin-left: 7%; width: 40%" class="example-full-width">
                  <mat-label>Delivery Mode</mat-label>
                  <mat-select formControlName="modetype"  multiple>
                    <mat-option value="ALL" (click)="toggleAllSelection()">All</mat-option>
                    <mat-option value="टपाल" >टपाल</mat-option>
                    <mat-option value="हस्ते" >हस्ते</mat-option>
                  </mat-select>      
                  </mat-form-field>
      
                  <mat-form-field appearance="outline" style="margin-left: 1%; width: 40%" class="example-full-width">
                    <mat-label>Prant Name</mat-label>
                    <mat-select formControlName="prantname"  multiple>
                      <mat-option value="ALL" (click)="toggleAllSelection()">All</mat-option>
                      <mat-option *ngFor="let option of Getprantname" [value]="option['prant_name']">{{ option["prant_name"]
                        }}</mat-option>
                    </mat-select>      
                  </mat-form-field> 
              </div>
      
              
           
              <div class="form-row" [formGroup]="PendingAddForm" class="example-full-width">
                <mat-form-field class="example-full-width" appearance="outline" style="width: 35%; margin-left: 7%;">
                  <mat-label>Subscriber Type</mat-label>
                  <mat-select #mySel2 formControlName="subtype" (selectionChange)="getsubtypes($event)" multiple>
             
                    <mat-option *ngFor="let option of LabelsDispatchSubtypes" [value]="option['sub_type']">{{
                      option["sub_type"]
                      }}</mat-option>
                  </mat-select>
                  <button *ngIf="sub_types" matSuffix mat-icon-button aria-label="Clear" (click)="reset_sub_types()">
                    <mat-icon>close</mat-icon>
                  </button>
                </mat-form-field>
      
               
             
      
                <button class="btn btn-info" (click)="getrenewalreport5()">Search</button>
              </div>
      
              
              
              </mat-card>

            </ng-container>
          </mat-tab>
          <mat-tab label="Subscription Data By Credit Voucher" >
            <ng-container *ngIf="demo1TabIndex==1">
            <mat-card>
              <div >
                <form [formGroup]="databyvocuher">
                <mat-form-field style="margin-left: 1%; width: 25%; " appearance="outline">
                  <mat-label>Receipt Date</mat-label>
                  <mat-date-range-input [rangePicker]="pickerrRT1vc" >
                    <input matStartDate formControlName="start" placeholder="Start date" />
                    <input matEndDate formControlName="end" placeholder="End date"
                      (dateChange)="getChangedDateContractByVC()" />
                  </mat-date-range-input>
                  <mat-datepicker-toggle matSuffix [for]="pickerrRT1vc"></mat-datepicker-toggle>
                  <mat-date-range-picker #pickerrRT1vc></mat-date-range-picker>
                </mat-form-field>
      
      
                  <mat-form-field class="example-full-width" appearance="outline" style="width: 25%; margin-left: 3%">
                    <mat-label>Search Credit Voucher No :</mat-label>
                    <!-- formControlName="sadvtname" -->
                    <input matInput formControlName="cvnumber" (keyup)="getVoucherNo($event)" [matAutocomplete]="cvnumber" />
                    <mat-autocomplete autoActiveFirstOption #cvnumber="matAutocomplete">
                      <mat-option (onSelectionChange)="selected_vouchers($event)" *ngFor="let option of voucherNoList"
                        [value]="option['voucher_no']">
                        {{ option["voucher_no"] }}
                      </mat-option>
                    </mat-autocomplete>
                  </mat-form-field>
      
                  <mat-form-field class="example-full-width" appearance="outline" style="width: 25%; margin-left: 3%">
                    <mat-label>Representative Name</mat-label>
                    <input matInput formControlName="repname" (keyup)="getRepNames($event)" [matAutocomplete]="autoreabc" />
                    <mat-autocomplete autoActiveFirstOption #autoreabc="matAutocomplete">
                      <mat-option (onSelectionChange)="selected_rep_names($event, option)"
                        *ngFor="let option of rep_name_data" [value]="option['repname']">
                        {{ option["repname"] }}
                      </mat-option>
                    </mat-autocomplete>
                  </mat-form-field>
      
                  <button class="noPrint" style="margin-left: 3%;" mat-raised-button color="primary"
                  printSectionId="print-section" (click)="searchdatabycv()">
                  Search
              </button>
                 
                </form>
              </div>
      
              <div>
                <div >
                  <button class="noPrint" style="margin-top: 2%;" mat-raised-button color="primary"
              [useExistingCss]="true"
                  printSectionId="print-section" ngxPrint>
                  print
              </button>
                  <div id="print-section" style="margin-top: 50px;">
                      <table class="table table-bordered">
                          <thead>
                              <tr>
                                  <th colspan="3" style="border:none; text-align: center;" class="br-bottom br-right br-top">हिंदुस्तान प्रकाशन संस्था - विवेक साप्ताहिक  </th>
      
                                  <th colspan="2" style="border:none; text-align: center;" class="br-bottom br-right br-top">Data By Credit Voucher</th>
                                  <!-- <th colspan="2">Advt. Outstanding Report</th> -->
                                 
                                  <th colspan="3" style="border:none" class="br-bottom br-right br-top"> Date : From {{ startDate_contract_data | date: 'dd-MM-yyyy' }} - To {{ enddate_contract_data | date: 'dd-MM-yyyy' }}</th>                   
                                   <th colspan="3" style="border:none" class="br-bottom br-right br-top">दिनांक :</th>
                                  
                              </tr>
                                  <tr>
      
                                  </tr>
                                  <tr>
                                      <th colspan="1" style="border:none" class="br-bottom br-right">Sr</th>
                                      <th colspan="1" style="border:none" class="br-bottom br-right">Receipt No</th>
                                      <th colspan="1" style="border:none;" class="br-bottom br-right">Voucher Date</th>
                                      <th colspan="1" style="border:none" class="br-bottom br-right">Name</th>
                                      <th colspan="1" style="border:none" class="br-bottom br-right">Sub No</th>
                                      <th colspan="1" style="border:none" class="br-bottom br-right">Sub Type</th>                                
                                      <th colspan="1" style="border:none" class="br-bottom br-right" >New/Renew</th>
                                      <th colspan="1" style="border:none" class="br-bottom br-right" >Bank</th>
                                      <th colspan="1" style="border:none" class="br-bottom br-right" >Amt</th>
                                      <!-- <th colspan="1" style="border:none"  class="br-bottom br-right" >Balance</th> -->
                                      <th colspan="1" style="border:none"  class="br-bottom br-right" >End Date</th>
                                  </tr> 
                             
                                
                          </thead>
                          <tbody>
                              <ng-container  *ngFor="let item1 of databyvc; let i = index">
                              <tr>
                                  <td colspan="4" style="border:none; font-weight: bold;" class=" br-right br-bottom br-top">Voucher Number : {{item1.voucher_no}}</td>
                                  <td colspan="3" style="border:none; font-weight: bold;" class=" br-right br-bottom br-top">Voucher Amt : {{item1.crdt_voucher_amt}}</td>
                                  <td colspan="3" style="border:none; font-weight: bold;" class=" br-right br-bottom br-top">Voucher Rep Name : {{item1.invoice_rep_name}}</td>
          
                              </tr>
                                  <ng-container  *ngFor="let item of item1.voucher_data; let i = index">
                                      <tr>
                                        <td colspan="1" style="border:none;" class=" br-right">{{i + 1}}</td>
                                          <td colspan="1" style="border:none;" class=" br-right">{{item.rcpt_no}}</td>
                                          <td colspan="1" style="border:none" class=" br-right">{{item.crdt_voucher_date}}</td>
                                          <td colspan="1" style="border:none" class=" br-right">{{item.name}}</td>
                                          <td colspan="1" style="border:none" class=" br-right">{{item.subno}}</td>
                                          <td colspan="1" style="border:none" class=" br-right">{{item.subtype}}</td>
                                          <td colspan="1" style="border:none; text-align: end;" class=" br-right">{{item.rcpt_type}}</td>
                                          <td colspan="1" style="border:none; text-align: end;" class=" br-right">{{item.bank_name}}</td>
                                          <td colspan="1" style="border:none; text-align: end;" class=" br-right">{{item.select_entry_amt}}</td>
                                          <!-- <td colspan="1" style="border:none; text-align: end;" class=" br-right">{{item.balance_amt}}</td> -->
                                          <td colspan="1" style="border:none; text-align: end;" class=" br-right">{{item.sub_perid_to_date}}</td> 
                                        </tr>
                                    </ng-container >
                                    <tr>
                                      <th colspan="8">Total</th>
                                      <th colspan="1" style="text-align: end"> {{item1.total_entry_amt}}</th>
                                      <th colspan="1" style="text-align: end">{{item1.invoice_bal_amt}}</th>
                                      <th colspan="1"></th>
      
            
                                    </tr>
                                    
                                 
                                    
                              </ng-container>
                              </tbody>
              
                          <tfoot>
                         
                          </tfoot>  
                      </table>
                     
                  </div>
              </div>
              </div>
      
      
            </mat-card>
            </ng-container>
          </mat-tab>
    </mat-tab-group>

  </div>