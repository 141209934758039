import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DigisubservicesService } from 'src/app/services/digisubservices.service';

@Component({
  selector: 'app-update-magazine-name-and-category',
  templateUrl: './update-magazine-name-and-category.component.html',
  styleUrls: ['./update-magazine-name-and-category.component.scss'],
})
export class UpdateMagazineNameAndCategoryComponent implements OnInit {
  item;
  updateForm: FormGroup;
  constructor(
    private dialogref: MatDialogRef<UpdateMagazineNameAndCategoryComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    private service: DigisubservicesService
  ) {
    this.updateForm = fb.group({
      mag_name: [''],
      ig_cat: [''],
    });
  }

  ngOnInit(): void {
    this.item = this.data['val'];
    // console.log(this.item);
    this.updateForm.get('mag_name').setValue(this.item['mag_name']);
    this.updateForm.get('ig_cat').setValue(this.item['ig_cat']);
  }

  updateData() {
    if (this.item['id']) {
      let data = {
        id: this.item['id'],
        mag_name: this.updateForm.get('mag_name').value,
        ig_cat: this.updateForm.get('ig_cat').value,
      };

      this.service
        .updateMagazineIssueGranthCategory(data, this.item['mag_name'])
        .subscribe((res) => {});
      this.dialogref.close({ res: 'close' });
      // console.log(data);
    } else {
      let data = {
        mag_name: this.updateForm.get('mag_name').value,
        ig_cat: this.updateForm.get('ig_cat').value,
      };
      this.dialogref.close({ res: data });
    }
  }
}
