import { Component, Inject, OnInit } from '@angular/core';
import { ToWords } from 'to-words';
import { FormBuilder } from '@angular/forms';
import { formatDate } from '@angular/common';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DigisubservicesService } from 'src/app/services/digisubservices.service';

@Component({
  selector: 'app-bookinvoice',
  templateUrl: './bookinvoice.component.html',
  styleUrls: ['./bookinvoice.component.scss'],
})
export class BookinvoiceComponent implements OnInit {
  rep_details;
  viewmode = false;
  date;

  invoiceData = {
    rep_id: 0,
    rep_name: '',
    rep_mobile: '',
    rep_address: '',
    book_data: [],
    invoice_id: '',
    invoice_date: '',
  };
  invoiceno = '';
  totalAmount = 0;
  totalCommision = 0;
  cgstamt = 0;
  sgstamt = 0;
  taxablevalue = 0;
  toWords;
  invoiceValue = 0;
  taxAmountInWords;
  isGstinvoice;
  gst_number;
  rep_invoice;
  book_seller_data;
  book_data;
  book_price_total = 0;
  book_sale_price_total = 0;
  book_copies_total = 0;
  shipping_charges_total = 0;
  total_total = 0;
  amtinwords;
  totals = 0;
  val = 0;
  month;
  fullyear;
  nextyear;
  bid = 0;

  constructor(
    private fb: FormBuilder,
    public service: DigisubservicesService,
    private dialogRef: MatDialogRef<BookinvoiceComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    // console.log(this.data);
  }

  ngOnInit(): void {
    let id = this.data['book']['id'];
    this.date = this.data['book']['date'];
    // console.log('id', id);
    // this.service.getBookCustInvoiceId(this.date).subscribe((data) => {
    //   // console.log('data', data);
    //   let value: number;
    //   if (Object.keys(data).length > 0) {
    //     // console.log('1', data[0]['invoice_no'].split('/'));
    //     value = parseInt(data[0]['invoice_no'].split('/')[2]);
    //   } else {
    //     value = 0;
    //   }
    //   // console.log('value', value);
    //   let month = ('0' + (new Date(this.date).getMonth() + 1)).slice(-2);
    //   let year = new Date(this.date).getFullYear().toString().slice(-2);
    //   let nextyear = (new Date(this.date).getFullYear() + 1)
    //     .toString()
    //     .slice(-2);
    //   // console.log(
    //   //   formatDate(
    //   //     new Date(new Date().getFullYear(), new Date('4').getMonth(), 1),
    //   //     'yyyy-MM-dd',
    //   //     'en-IN',
    //   //     'GMT+0530'
    //   //   )
    //   // );
    //   if (
    //     this.date >=
    //     formatDate(
    //       new Date(new Date().getFullYear(), new Date('4').getMonth(), 1),
    //       'yyyy-MM-dd',
    //       'en-IN',
    //       'GMT+0530'
    //     )
    //   ) {
    //     this.invoiceno = `VBD${month}/${year}-${nextyear}/${
    //       value <= 8 ? '0' + (value + 1) : value + 1
    //     }`;
    //   } else {
    //     this.invoiceno = `VBD${month}/${parseInt(year) - 1}-${year}/${
    //       value <= 8 ? '0' + (value + 1) : value + 1
    //     }`;
    //   }
    //   // console.log('invoice no', this.invoiceno);
    // });
    this.getInvoiceNo();

    this.isGstinvoice = this.data['book']['GST'];
    this.gst_number = this.data['book']['GST_no'];
    this.viewmode = this.data['view'];
    this.rep_invoice = this.data['rep_invoice'];
    // console.log('this.rep_invoice', this.rep_invoice);
    // this.month = this.date.getMonth() + 1;
    // this.fullyear = this.date.getFullYear().toString().substr(-2);
    // this.nextyear = parseInt(this.fullyear) + 1;

    this.service
      .getSingleBookSellerEntry(this.data['book']['id'])
      .subscribe((data) => {
        this.book_seller_data = data;
        // console.log('book_seller_data', this.book_seller_data);
        this.bid = data[0]['bid'];
        this.invoiceData = {
          rep_id: this.book_seller_data[0]['id'],
          rep_name: this.book_seller_data[0]['name'],
          rep_mobile: this.book_seller_data[0]['mob_no'],
          rep_address:
            this.book_seller_data[0]['address'] +
            ' ' +
            this.book_seller_data[0]['taluka'] +
            ', ' +
            this.book_seller_data[0]['district'] +
            ', ' +
            this.book_seller_data[0]['state'] +
            ', ' +
            this.book_seller_data[0]['country'] +
            '-' +
            this.book_seller_data[0]['pincode'],
          book_data: this.book_seller_data[0]['delivery_mode'],
          invoice_id: this.invoiceno,
          invoice_date: this.date,
        };

        // console.log('invoiceData', this.invoiceData);
        // console.log('bid', this.bid);
        this.getBook(this.bid);
      });

    if (this.rep_invoice == 'Y') {
      //Rep Invoice
      // if (!this.viewmode) {
      //   this.invoiceData = {
      //     rep_id: this.book_seller_data[0]['id'],
      //     rep_name: this.book_seller_data[0]['crep_name'],
      //     rep_mobile: this.book_seller_data[0]['rep_mob_no'],
      //     rep_address: this.book_seller_data[0]['address'],
      //     book_data: this.book_seller_data[0]['delivery_mode'],
      //     invoice_id: this.invoiceno,
      //     invoice_date: this.date,
      //   };
      //   //this.rep_details = this.data['book']['rep']
      //   this.totalAmount = 0;
      //   this.totalCommision = 0;
      //   this.cgstamt = 0;
      //   this.taxablevalue = 0;
      //   for (
      //     let index = 0;
      //     index < Object.keys(this.data['book']['books']).length;
      //     index++
      //   ) {
      //     this.totalAmount =
      //       this.totalAmount +
      //       parseInt(this.data['book']['books'][index]['book_total']);
      //     this.totalCommision =
      //       this.totalCommision +
      //       parseInt(this.data['book']['books'][index]['commission']);
      //   }
      //   setTimeout(() => {
      //     if (this.isGstinvoice == 'Y') {
      //       //calculate GST
      //       this.cgstamt = Math.round((this.totalAmount * 9) / 100);
      //       this.sgstamt = Math.round((this.totalAmount * 9) / 100);
      //       this.taxablevalue = this.totalAmount + this.cgstamt + this.sgstamt;
      //       this.invoiceValue = this.taxablevalue - this.totalCommision;
      //       // Convert Total Amount in Words
      //       this.toWords = new ToWords();
      //       this.taxAmountInWords = this.toWords.convert(this.invoiceValue, {
      //         currency: true,
      //       });
      //     } else {
      //       //calculate GST
      //       //this.cgstamt = Math.round((this.totalAmount * 9)/100);
      //       this.taxablevalue = this.totalAmount; //+ this.cgstamt + this.cgstamt
      //       this.invoiceValue = this.taxablevalue - this.totalCommision;
      //       // Convert Total Amount in Words
      //       this.toWords = new ToWords();
      //       this.taxAmountInWords = this.toWords.convert(this.invoiceValue, {
      //         currency: true,
      //       });
      //     }
      //   }, 2000);
      // } else {
      //   console.log(this.data['book']['books']['bookDetail']);
      //   this.invoiceData = {
      //     rep_id: this.data['book']['rep']['id'],
      //     rep_name: this.data['book']['rep']['name'],
      //     rep_mobile: this.data['book']['rep']['mobile'],
      //     rep_address: this.data['book']['rep']['rep_address'],
      //     book_data: this.data['book']['books']['bookDetail'],
      //     invoice_id:
      //       this.data['book']['books']['invoice_details']['invoice_id'],
      //     invoice_date:
      //       this.data['book']['books']['invoice_details']['invoice_date'],
      //   };
      //   if (this.isGstinvoice == 'Y') {
      //     //this.rep_details = this.data['book']['rep']
      //     this.totalAmount =
      //       this.data['book']['books']['invoice_details']['total_amount'];
      //     this.totalCommision =
      //       this.data['book']['books']['invoice_details']['rep_comission'];
      //     this.cgstamt = this.data['book']['books']['invoice_details']['cgst'];
      //     this.sgstamt = this.data['book']['books']['invoice_details']['sgst'];
      //     this.taxablevalue = this.totalAmount + this.cgstamt + this.sgstamt;
      //     this.invoiceValue = this.taxablevalue - this.totalCommision;
      //     // Convert Total Amount in Words
      //     this.toWords = new ToWords();
      //     this.taxAmountInWords = this.toWords.convert(this.invoiceValue, {
      //       currency: true,
      //     });
      //   } else {
      //     //this.rep_details = this.data['book']['rep']
      //     this.totalAmount =
      //       this.data['book']['books']['invoice_details']['invoice_total'];
      //     this.totalCommision =
      //       this.data['book']['books']['invoice_details']['rep_comission'];
      //     //this.cgstamt = this.data['book']['books']['invoice_details']['cgst']
      //     //this.sgstamt = this.data['book']['books']['invoice_details']['sgst']
      //     this.taxablevalue = this.totalAmount; // + this.cgstamt + this.sgstamt
      //     this.invoiceValue = this.taxablevalue - this.totalCommision;
      //     // Convert Total Amount in Words
      //     this.toWords = new ToWords();
      //     this.taxAmountInWords = this.toWords.convert(this.invoiceValue, {
      //       currency: true,
      //     });
      //   }
      // }
    } else {
      //Cust Invoice
      if (!this.viewmode) {
        // console.log('book_seller_data', this.book_seller_data);
        if (this.book_seller_data) {
          this.invoiceData = {
            rep_id: this.book_seller_data[0]['id'],
            rep_name: this.book_seller_data[0]['crep_name'],
            rep_mobile: this.book_seller_data[0]['rep_mob_no'],
            rep_address: this.book_seller_data[0]['address'],
            book_data: this.book_seller_data[0]['delivery_mode'],
            invoice_id: this.invoiceno,
            invoice_date: this.date,
          };
        }
        // console.log('this.invoiceData', this.invoiceData);

        //this.rep_details = this.data['book']['rep']
        this.totalAmount = 0;
        //this.totalCommision = 0
        this.cgstamt = 0;
        this.taxablevalue = 0;
        for (
          let index = 0;
          index < Object.keys(this.data['book']['books']).length;
          index++
        ) {
          this.totalAmount =
            this.totalAmount +
            parseInt(this.data['book']['books'][index]['book_total']);
          //this.totalCommision = this.totalCommision + parseInt(this.data['book']['books'][index]['commission']);
        }

        setTimeout(() => {
          if (this.isGstinvoice == 'Y') {
            //calculate GST
            this.cgstamt = Math.round((this.totalAmount * 9) / 100);
            this.sgstamt = Math.round((this.totalAmount * 9) / 100);
            this.taxablevalue = this.totalAmount + this.cgstamt + this.sgstamt;
            this.invoiceValue = this.taxablevalue; //- this.totalCommision
            // Convert Total Amount in Words
            this.toWords = new ToWords();
            this.taxAmountInWords = this.toWords.convert(this.invoiceValue, {
              currency: true,
            });
          } else {
            //calculate GST
            //this.cgstamt = Math.round((this.totalAmount * 9)/100);
            this.taxablevalue = this.totalAmount; //+ this.cgstamt + this.cgstamt
            this.invoiceValue = this.taxablevalue; //- this.totalCommision
            // Convert Total Amount in Words
            this.toWords = new ToWords();
            this.taxAmountInWords = this.toWords.convert(this.invoiceValue, {
              currency: true,
            });
          }
        }, 2000);
      } else {
        // console.log(this.data['book']['books']['bookDetail']);
        this.invoiceData = {
          rep_id: this.data['book']['rep']['id'],
          rep_name: this.data['book']['rep']['name'],
          rep_mobile: this.data['book']['rep']['mobile'],
          rep_address: this.data['book']['rep']['rep_address'],
          book_data: this.data['book']['books']['bookDetail'],
          invoice_id:
            this.data['book']['books']['invoice_details']['invoice_id'],
          invoice_date:
            this.data['book']['books']['invoice_details']['invoice_date'],
        };

        if (this.isGstinvoice == 'Y') {
          //this.rep_details = this.data['book']['rep']
          this.totalAmount =
            this.data['book']['books']['invoice_details']['total_amount'];
          //this.totalCommision = this.data['book']['books']['invoice_details']['rep_comission']
          this.cgstamt = this.data['book']['books']['invoice_details']['cgst'];
          this.sgstamt = this.data['book']['books']['invoice_details']['sgst'];
          this.taxablevalue = this.totalAmount + this.cgstamt + this.sgstamt;
          this.invoiceValue = this.taxablevalue; //- this.totalCommision

          // Convert Total Amount in Words
          this.toWords = new ToWords();
          this.taxAmountInWords = this.toWords.convert(this.invoiceValue, {
            currency: true,
          });
        } else {
          //this.rep_details = this.data['book']['rep']
          this.totalAmount =
            this.data['book']['books']['invoice_details']['invoice_total'];
          //this.totalCommision = this.data['book']['books']['invoice_details']['rep_comission']
          //this.cgstamt = this.data['book']['books']['invoice_details']['cgst']
          //this.sgstamt = this.data['book']['books']['invoice_details']['sgst']
          this.taxablevalue = this.totalAmount; // + this.cgstamt + this.sgstamt
          this.invoiceValue = this.taxablevalue; //- this.totalCommision

          // Convert Total Amount in Words
          this.toWords = new ToWords();
          this.taxAmountInWords = this.toWords.convert(this.invoiceValue, {
            currency: true,
          });
        }
      }
    }
    // setTimeout(() => {
    // }, 1000);
  }

  getInvoiceNo() {
    this.service.getBookCustInvoiceId(this.date).subscribe((data) => {
      // console.log('data', data);
      let value: number;
      if (Object.keys(data).length > 0) {
        // console.log('1', data[0]['invoice_no'].split('/'));
        value = parseInt(data[0]['invoice_no'].split('/')[2]);
      } else {
        value = 0;
      }
      // console.log('value', value);
      let month = ('0' + (new Date(this.date).getMonth() + 1)).slice(-2);
      let year = new Date(this.date).getFullYear().toString().slice(-2);
      let nextyear = (new Date(this.date).getFullYear() + 1)
        .toString()
        .slice(-2);
      if (
        this.date >=
        formatDate(
          new Date(new Date().getFullYear(), new Date('4').getMonth(), 1),
          'yyyy-MM-dd',
          'en-IN',
          'GMT+0530'
        )
      ) {
        this.invoiceno = `VBD${month}/${year}-${nextyear}/${
          value <= 8 ? '0' + (value + 1) : value + 1
        }`;
      } else {
        this.invoiceno = `VBD${month}/${parseInt(year) - 1}-${year}/${
          value <= 8 ? '0' + (value + 1) : value + 1
        }`;
      }
      // console.log('invoice no', this.invoiceno);
    });
  }

  getBook(id) {
    this.service.getBooks(id).subscribe((data) => {
      // console.log('books data', data);
      this.book_data = data;
      for (let index = 0; index < Object.keys(this.book_data).length; index++) {
        this.book_price_total =
          this.book_price_total + parseInt(this.book_data[index]['bookprice']);
        this.book_sale_price_total =
          this.book_sale_price_total +
          parseInt(this.book_data[index]['booksaleprice']);
        this.book_copies_total =
          this.book_copies_total +
          parseInt(this.book_data[index]['bookcopies']);
        this.shipping_charges_total =
          this.shipping_charges_total +
          parseInt(this.book_data[index]['shippingcharges']);
        this.total_total =
          this.total_total + parseInt(this.book_data[index]['total']);
      }
      this.totals = this.total_total + this.shipping_charges_total;
      this.toWords = new ToWords();
      this.amtinwords = this.toWords.convert(this.totals, {
        currency: true,
      });
      // console.log('book_price_total', this.book_price_total);
    });
    // console.log('this.invoiceData.rep_id', this.invoiceData.rep_id);
  }

  print() {
    window.print();
  }

  save() {
    if (this.rep_invoice == 'Y') {
      //rep Invoice this.bid
      // this.service
      //   .InsertInvoice(
      //     this.invoiceData.rep_name,
      //     this.invoiceData.rep_mobile,
      //     this.invoiceData.rep_id,
      //     this.invoiceData.rep_address,
      //     '',
      //     '',
      //     this.gst_number,
      //     this.totalAmount,
      //     this.totalCommision,
      //     this.cgstamt,
      //     this.cgstamt,
      //     0
      //   )
      //   .subscribe((data) => {
      //     console.log(data);
      //     for (
      //       let index = 0;
      //       index < Object.keys(this.invoiceData['book_data']).length;
      //       index++
      //     ) {
      //       this.service
      //         .InsertInvoiceData(
      //           parseInt(data['id']),
      //           this.invoiceData['book_data'][index]['book_name'],
      //           parseInt(this.invoiceData['book_data'][index]['book_qty']),
      //           parseInt(this.invoiceData['book_data'][index]['book_rate']),
      //           parseInt(this.invoiceData['book_data'][index]['book_total'])
      //         )
      //         .subscribe((invoice) => {
      //           console.log(invoice);
      //         });
      //     }
      //     setTimeout(() => {
      //       window.alert(
      //         'Invoice ' + parseInt(data['id']) + ' has been created.'
      //       );
      //       this.dialogRef.close();
      //     }, (Object.keys(this.invoiceData['book_data']).length / 2) * 1000);
      //   });
    } else {
      //CustInvoice
      if (
        !isNaN(parseInt(this.invoiceData.invoice_id.split('/')[2])) ||
        this.invoiceData.invoice_id !== ''
      ) {
        this.service
          .InsertCustInvoice(
            this.invoiceData.rep_name,
            this.invoiceData.rep_mobile,
            this.bid,
            this.invoiceData.rep_address,
            this.invoiceData.invoice_id,
            this.invoiceData.invoice_date,
            '',
            '',
            this.gst_number,
            this.totals,
            this.shipping_charges_total,
            this.totalCommision,
            this.cgstamt,
            this.cgstamt,
            0
          )
          .subscribe((data) => {
            // console.log(data);
            // console.log(this.book_data);
            for (
              let index = 0;
              index < Object.keys(this.book_data).length;
              index++
            ) {
              this.service
                .InsertCustInvoiceData(
                  parseInt(data['id']),
                  this.book_data[index]['bookname'],
                  this.book_data[index]['authorname'],
                  parseInt(this.book_data[index]['bookcopies']),
                  parseInt(this.book_data[index]['discount']),
                  parseInt(this.book_data[index]['discountamt']),
                  parseInt(this.book_data[index]['bookprice']),
                  parseInt(this.book_data[index]['booksaleprice']),
                  parseInt(this.book_data[index]['total']),
                  parseInt(this.book_data[index]['shippingcharges']),
                  parseInt(this.book_data[index]['total_amount']),
                  parseInt(this.book_data[index]['received_amt']),
                  parseInt(this.book_data[index]['remaining_amt']) == 0
                    ? parseInt(this.book_data[index]['total']) +
                        parseInt(this.book_data[index]['shippingcharges'])
                    : parseInt(this.book_data[index]['remaining_amt']),
                  parseInt(this.book_data[index]['rep_commission_precent']),
                  parseInt(this.book_data[index]['rep_commission_amt'])
                )
                .subscribe((invoice) => {
                  console.log(invoice);
                });
            }

            setTimeout(() => {
              window.alert(
                'Invoice ' + parseInt(data['id']) + ' has been created.'
              );
              this.dialogRef.close();
            }, 1000);
          });
      } else {
        alert('Invoice No is not Valid! Please try again');
        this.getInvoiceNo();
      }
    }
  }
}
