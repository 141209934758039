
<div class="header bg-gradient-danger pb-1 pt-5 pt-md-8">
  <div class="container-fluid">
    <div class="header-body">

    </div>
  </div>
</div>
<div class="container-fluid" style="margin-top: -3.4em;">


<!-- Icons is Used as Admin -->
<mat-tab-group dynamicHeight>
  <mat-tab label="Representative List">
    <!-- <a style="cursor: pointer" (click)="exportexcel()">
      <mat-icon> cloud_download</mat-icon>
    </a> -->
    <button type="button" (click)="exportexcel()" class="btn btn-danger">
      Export
    </button>
    <div class="table-wrapper-scroll-y my-custom-scrollbar">
      <table class="table table-bordered table-striped mb-0" id="excel-table">
        <thead>
          <tr>
            <th scope="col" style="text-align: center;">Sr. No.</th>
            <th scope="col" style="text-align: center;">Name</th>
            <th scope="col" style="text-align: center;">City</th>
            <th scope="col" style="text-align: center;">Mobile</th>
            <th scope="col" style="text-align: center;">Cash Credit Limit</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of data1; let i=index">
              <th style="text-align: center;">{{i+1}}</th>
              <th style="text-align: center;">{{item.name}}</th>
              <th style="text-align: center;">{{item.city}}</th>
              <th style="text-align: center;">{{item.mobile}}</th>
              <th style="text-align: center;">{{item.cash_credit_limit}}</th>
          </tr>
        </tbody>
      </table>
    </div>
  </mat-tab> 

  <mat-tab label="Collection">
    <mat-form-field style="margin-top: 2em;margin-left: 2em;">
      <input matInput [matDatepicker]="picker1" placeholder="To Date"  [formControl]="todate">
      <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
      <mat-datepicker #picker1></mat-datepicker>
    </mat-form-field>
    
    <mat-form-field style="margin-top: 2em;margin-left: 2em;">
      <input matInput [matDatepicker]="picker2" placeholder="From Date"  [formControl]="fromdate">
      <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
      <mat-datepicker #picker2></mat-datepicker>
    </mat-form-field>

    <div style="margin-top: 1em;margin-left: 2em;">
      <button mat-raised-button color="primary" (click)="selectDailydata()">get Report</button>
    </div>

    
    <div *ngIf="datalength > 0">
      <app-editable-table 
        [columns]="['Dates', 'Name', 'Commission', 'Collected Amount', 'Submitted Amount', 'Outstanding Payment', 'Payment Status']"
        [editableColumns] = "[]"
        [dateColumns] ="[]"
        [data]="tableData" 
        [pageSizeOptions]="[5,6,9,12,20,50,100]" 
        [searchable]="true" 
        (action)="action($event)"
        [notification]="yourMessage"
        [maxChar]="23"
        >
        
      </app-editable-table>
      </div>
    
      <div *ngIf="dataLoaded">
            <h1>There is No Data Available for this date. Please select working date.</h1>
      </div>
    
  </mat-tab>

  <mat-tab label="New Subscribers">
    <app-new-subscribers-data></app-new-subscribers-data>
  </mat-tab>

  <!-- Cancel Subscription -->
  <mat-tab label="Commission">
    <button type="button" (click)="exportexcel()" class="btn btn-danger">
      Export
    </button>
    <div class="table-wrapper-scroll-y my-custom-scrollbar" id="excel-table">
      <table class="table table-bordered table-striped mb-0">
        <thead>
          <tr>
            <th scope="col" style="text-align: center;">Sr.No</th>
            <th scope="col" style="text-align: center;">Name</th>
            <th scope="col" style="text-align: center;">Commission</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of data2 ; let i=index">
              <th style="text-align: center;">{{i+1}}</th>
              <th style="text-align: center;">{{item.repname}}</th>
              <th style="text-align: center;">{{item.comission_payable}}</th>
          </tr>
        </tbody>
      </table>
    </div>
  </mat-tab>

  <mat-tab label="Renewal">
    <button type="button" (click)="exportexcel()" class="btn btn-danger">
      Export
    </button>
    <div class="table-wrapper-scroll-y my-custom-scrollbar" id="excel-table">
      <table class="table table-bordered table-striped mb-0">
        <thead>
          <tr>
            <th scope="col" style="text-align: center;">Mobile</th>
            <th scope="col" style="text-align: center;">Name</th>
            <th scope="col" style="text-align: center;">End Date</th>
            <th scope="col" style="text-align: center;">City</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of data3">
              <th style="text-align: center;">{{item.submobile}}</th>
              <th style="text-align: center;">{{item.subname}}</th>
              <th style="text-align: center;">{{item.subscriptonenddt}}</th>
              <th style="text-align: center;">{{item.city}}</th>
          </tr>
        </tbody>
      </table>
    </div>
  </mat-tab>

  <mat-tab label="Subscribers Contact">
    <button type="button" (click)="exportexcel()" class="btn btn-danger">
      Export
    </button>
    <div class="table-wrapper-scroll-y my-custom-scrollbar">
      <table class="table table-bordered table-striped mb-0" id="excel-table">
        <thead>
          <tr>
            <th scope="col" style="text-align: center;">Name</th>
            <th scope="col" style="text-align: center;">Mobile</th>
            <th scope="col" style="text-align: center;">Email</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of data4">
              <th style="text-align: center;">{{item.subname}}</th>
              <th style="text-align: center;">{{item.submobile}}</th>
              <th style="text-align: center;">{{item.subemail}}</th>
          </tr>
        </tbody>
      </table>
    </div>
  </mat-tab>

  <!-- <mat-tab disabled>
    <ng-template mat-tab-label>
      <button type="submit" class="btn btn-danger" (click) = "logout()">Logout</button>
    </ng-template>
  </mat-tab> -->
</mat-tab-group>


</div>
