import { formatDate } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { DigisubservicesService } from 'src/app/services/digisubservices.service';
import { EncdecriptService } from 'src/app/services/encdecrypt.service';
import { AddNewSubOtpModalComponent } from '../add-new-sub-otp-modal/add-new-sub-otp-modal.component';

@Component({
  selector: 'app-add-new-subscriber',
  templateUrl: './add-new-subscriber.component.html',
  styleUrls: ['./add-new-subscriber.component.scss'],
})
export class AddNewSubscriberComponent implements OnInit {
  form: FormGroup;
  getAllRepList = [
    {
      id: 777,
      mobile: '1234567893',
      name: 'All',
      rep_address: '',
      rep_advt_comm: '0',
      rep_book_comm: '0',
      rep_comm: '0',
    },
  ];
  //BookForm: FormGroup
  //invoiceForm: FormGroup
  //displayinvoiceForm = true;
  //invoicedate = formatDate(new Date(), "yyyy-MM-dd", "en-IN", "GMT+0530")
  searchTerm = false;
  displayChequqField = false;
  mag = [];
  magname = [];
  magyear = [];
  subscriberDetails = {
    name: '',
    address: '',
  };
  submagsDetails;
  totalamount = 0;
  totalmagamount = 0;
  totaldonationamount = 0;
  displayTotal = false;
  public copy: string;
  data1;
  data2;
  needToUpdate = false;
  imgPath: string;
  disableOtherFields = true;
  getMagazine;
  subid;
  cancelCheckbox = [];
  changedMobileNumber;
  nochangenumber = false;
  isAdminAuthorize = false;
  countries = [];
  states = [];
  cities = [];
  titles = [];
  occupations = [];
  updateMode = false;

  constructor(
    private router: Router,
    private fb: FormBuilder,
    public service: DigisubservicesService,
    public dialog: MatDialog,
    public encr: EncdecriptService,
    private _snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    console.log(this.data);
    this.updateMode = this.data['updatemode'];
    this.form = fb.group({
      name: ['', [Validators.required, Validators.minLength(2)]],
      email: [
        '',
        [Validators.pattern('[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+.[a-z]{2,3}$')],
      ],
      dob: [''],
      address: ['', [Validators.required, Validators.minLength(5)]],
      city: ['', [Validators.required, Validators.minLength(2)]],
      bcity: ['', [Validators.minLength(2)]],
      bpin: [
        '',
        [Validators.maxLength(6), Validators.pattern('^[1-9][0-9]{5}$')],
      ],
      subCat: ['', [Validators.required, Validators.minLength(2)]],
      businessaddress: ['', [Validators.minLength(5)]],
      businessemail: [
        '',
        [Validators.pattern('[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,3}$')],
      ],
      businessname: ['', [Validators.minLength(2)]],
      businessphone: [
        '',
        [Validators.maxLength(10), Validators.pattern('^[7-9][0-9]{9}$')],
      ],
      businesstype: ['', [Validators.minLength(2)]],
      mobileNumber: [
        '',
        [
          Validators.required,
          Validators.maxLength(10),
          Validators.pattern('^[7-9][0-9]{9}$'),
        ],
      ],
      otherdonation: ['', [Validators.pattern('^[0-9]+$')]],
      paymentmode: ['', [Validators.required, Validators.minLength(2)]],
      pincode: [
        '',
        [
          Validators.required,
          Validators.maxLength(6),
          Validators.pattern('^[1-9][0-9]{5}$'),
        ],
      ],
      gender: ['', [Validators.required]],
      checkno: ['', [Validators.maxLength(15)]],
      isPaymentCmplt: [''],
      country: ['', [Validators.required, Validators.minLength(2)]],
      state: ['', [Validators.required, Validators.minLength(2)]],
      taluka: ['', [Validators.required, Validators.minLength(2)]],
      title: ['', [Validators.minLength(2)]],
      doa: [''],
      rep_receipt: [],
      magnames: [''],
      magprice: [''],
      magedition: [''],
      magstartdt: [''],
      magenddt: [''],
      magyear: [''],
      rep_id: [''],
      rep_comm: [''],
      totalMagAmount: [''],
    });

    if (this.data['updatemode']) {
      this.form.get('name').setValue(this.data['sub']['name']);
      this.form.get('email').setValue(this.data['sub']['email']);
      this.form.get('dob').setValue(this.data['sub']['dob']);
      this.form.get('address').setValue(this.data['sub']['address']);
      this.form.get('city').setValue({ name: this.data['sub']['city'] });
      this.form.get('bcity').setValue(this.data['sub']['bcity']);
      this.form.get('bpin').setValue(this.data['sub']['bpin']);
      this.form.get('subCat').setValue(this.data['sub']['subCat']);
      this.form
        .get('businessaddress')
        .setValue(this.data['sub']['businessaddress']);
      this.form
        .get('businessemail')
        .setValue(this.data['sub']['businessemail']);
      this.form.get('businessname').setValue(this.data['sub']['businessname']);
      this.form
        .get('businessphone')
        .setValue(this.data['sub']['businessphone']);
      this.form.get('businesstype').setValue(this.data['sub']['businesstype']);
      this.form.get('mobileNumber').setValue(this.data['sub']['mobileNumber']);
      this.form
        .get('otherdonation')
        .setValue(this.data['sub']['otherdonation']);
      this.form.get('paymentmode').setValue(this.data['sub']['paymentmode']);
      this.form.get('pincode').setValue(this.data['sub']['pincode']);
      this.form.get('gender').setValue(this.data['sub']['gender']);
      this.form.get('checkno').setValue(this.data['sub']['chequeno']);
      this.form.get('isPaymentCmplt').setValue(this.data['sub']['']);
      this.form.get('country').setValue(this.data['sub']['country']);
      this.form
        .get('state')
        .setValue({ state_name: this.data['sub']['state'] });
      this.form.get('taluka').setValue(this.data['sub']['taluka']);
      this.form.get('title').setValue(this.data['sub']['title']);
      this.form.get('doa').setValue(this.data['sub']['doa']);
    }
  }

  ngOnInit() {
    this.service.get_AllRepresentative_List().subscribe((data) => {
      console.log(data);
      for (let index = 0; index < Object.keys(data).length; index++) {
        this.getAllRepList.push(data[index]);
      }
    });

    // Read All Contries
    this.service.getAllContries().subscribe((data) => {
      console.log(data);

      this.countries = data;
    });

    this.service.getOccupation().subscribe((data) => {
      console.log(data);
      this.occupations = data;
    });

    this.service.getAlltitle().subscribe((data) => {
      console.log(data);
      this.titles = data;
    });

    if (sessionStorage.getItem('mobile') == null) {
      this.router.navigate(['/']);
    }

    this.service
      .checkadminissuper(parseInt(sessionStorage.getItem('id')))
      .subscribe((a) => {
        if (Object.keys(a).length > 0) {
          this.isAdminAuthorize = true;
        } else {
          this.isAdminAuthorize = false;
        }
      });

    //change for OEM
    this.service.getMag().subscribe((res) => {
      //console.log(res['name'])
      this.mag = [];
      this.magname = [];
      this.magyear = [];

      for (let i = 0; i < Object.keys(res).length; i++) {
        this.mag.push({
          name: res[i]['name'],
          print: res[i]['print'],
          digital: res[i]['digital'],
          printdigital: res[i]['printdigital'],
          year: parseInt(res[i]['year']),
        });

        if (i == 0) {
          this.magname[0] = {
            name: res[i]['name'],
          };

          this.magyear[0] = {
            year: parseInt(res[i]['year']),
          };
        } else {
          console.log(this.magname.find((a) => a['name'] == res[i]['name']));

          if (
            this.magname.find((a) => a['name'] == res[i]['name']) == undefined
          ) {
            this.magname.push({
              name: res[i]['name'],
            });
          }

          this.magyear.push({
            year: parseInt(res[i]['year']),
          });
        }

        console.log(this.magname);
      }
    });
  }

  validsubcat = false;
  showbussiness = false;
  getSearchTerm(event) {
    if (this.form.get('subCat').valid) {
      const value = event.value;
      this.validsubcat = true;
      if (value == 'business') {
        this.searchTerm = true;
        this.showbussiness = true;
      } else {
        this.searchTerm = false;
        this.showbussiness = false;
      }
    } else {
      this.validsubcat = false;
      this.showbussiness = false;
    }
  }

  getcheque(event) {
    const value = event.value;
    if (value == 'cheque') {
      this.displayChequqField = true;
    } else {
      this.displayChequqField = false;
    }
  }

  magedition;
  getmagEdition(event) {
    this.magedition = event.value;
  }

  magnames;
  getmagyear(event) {
    const year = parseInt(event.value);
    console.log(year);
    let todayDateStringto = new Date();
    todayDateStringto.setFullYear(todayDateStringto.getFullYear() + year);
    console.log(this.mag);
    for (let index = 0; index < Object.keys(this.mag).length; index++) {
      if (
        this.mag[index]['name'] == this.magnames &&
        parseInt(this.mag[index]['year']) == year
      ) {
        this.displayTotal = true;
        this.form.get('magnames').setValue(this.mag[index]['name']);
        this.form.get('magprice').setValue(this.mag[index][this.magedition]);
        this.form.get('magedition').setValue(this.magedition);
        this.form
          .get('magstartdt')
          .setValue(formatDate(new Date(), 'yyyy-MM-dd', 'en-IN', 'GMT+0530'));
        this.form
          .get('magenddt')
          .setValue(
            formatDate(todayDateStringto, 'yyyy-MM-dd', 'en-IN', 'GMT+0530')
          );
        this.totalmagamount = parseInt(this.mag[index][this.magedition]);
      } else {
        this.displayTotal = false;
      }
    }
    console.log(this.totalmagamount);
    this.form.get('totalMagAmount').setValue(this.totalmagamount);
  }

  getmagdetails(event) {
    this.magnames = event.value;
  }

  getotherDonation(event) {
    const value = event.value;
    if (parseInt(value) >= 0) {
      this.totaldonationamount = parseInt(value);
    } else {
      this.totaldonationamount = 0;
    }
    this.form.get('otherdonation').setValue(this.totaldonationamount);
  }

  startLoading = false;
  getsubmags() {
    this.startLoading = true;
    this.cancelCheckbox = [];
    let mobileNumber = this.changedMobileNumber;
    this.service
      .getSubmagByIdorNumberCancel(parseInt(mobileNumber))
      .subscribe((res) => {
        console.log(res);
        this.getMagazine = res;
        this.nochangenumber = true;
        this.startLoading = false;
        if (Object.keys(res).length > 0) {
          this.disableOtherFields = false;
        } else {
          this.disableOtherFields = true;
        }

        console.log(res);
        setTimeout(() => {
          for (
            let index = 0;
            index < Object.keys(this.getMagazine).length;
            index++
          ) {
            this.cancelCheckbox.push({
              name: this.getMagazine[index]['ds_mag1'],
              edition: this.getMagazine[index]['ds_mag1edition'],
              end_date: this.getMagazine[index]['ds_mag1enddt'],
            });
          }
        }, 1000);

        console.log(this.cancelCheckbox);
      });
  }

  openModal() {
    let mobile = parseInt(sessionStorage.getItem('mobile'));

    var sixdigitsrandom = Math.floor(100000 + Math.random() * 900000);
    let msg =
      'Dear Subscriber, Your Verification Code is ' +
      sixdigitsrandom +
      ', Regards Hindustan Prakashan Sanstha.';
    let template = 'VIVEK1';
    this.service.sendSMS(mobile, msg, template).subscribe((a) => {});

    this.dialog.open(AddNewSubOtpModalComponent, {
      data: {
        message: sixdigitsrandom,
        Form: this.form,
        height: '600px',
        width: '600px',
      },
    });
  }

  validatefname = false;
  validateName(event) {
    if (this.form.get('name').valid) {
      this.validatefname = true;
    } else {
      this.validatefname = false;
    }
  }

  getPincode(event) {
    if (this.form.get('pincode').valid) {
      console.log(this.form.get('pincode').value);
      this.service
        .getPincodeDetails(parseInt(this.form.get('pincode').value))
        .subscribe((data) => {
          console.log(data);
          this.form.get('taluka').setValue(data[0]['PostOffice'][0]['Block']);
          this.form
            .get('city')
            .setValue({ name: data[0]['PostOffice'][0]['District'] });
          this.form
            .get('state')
            .setValue({ state_name: data[0]['PostOffice'][0]['State'] });
          this.form
            .get('country')
            .setValue(data[0]['PostOffice'][0]['Country']);
        });
    }
  } //getPincode

  getCountry(event) {
    console.log(event);
    console.log(event.value);
    let country = this.countries.find((a) => a.name == event.value);
    if (country != undefined) {
      this.service
        .getstates(parseInt(country['country_code']))
        .subscribe((data) => {
          this.states = data;
          console.log(this.states);
        });
    } else {
    }
  } //getcountry

  getState(event) {
    console.log(event.value);
    let state = this.states.find((a) => a.state_name == event.value);
    console.log();
    if (state != undefined) {
      this.service.getCity(parseInt(state['state_id'])).subscribe((data) => {
        this.cities = data;
      });
    } else {
    }
  } //get state

  validmobile = false;
  validemail = false;
  validateMobile(event) {
    if (this.form.get('mobileNumber').valid) {
      this.validmobile = true;
    } else {
      this.validmobile = false;
    }
  }

  validateEmail(event) {
    if (this.form.get('email').valid) {
      this.validemail = true;
    } else {
      this.validemail = false;
    }
  }

  validdoa = false;
  validdob = false;
  validgender = false;
  validatedoa(event) {
    this.form
      .get('doa')
      .setValue(
        formatDate(
          new Date(event.target.value),
          'yyyy-MM-dd',
          'en-IN',
          'GMT+0530'
        )
      );
    if (this.form.get('doa').valid) {
      this.validdoa = true;
    } else {
      this.validdoa = false;
    }
  }

  validatedob(event) {
    this.form
      .get('dob')
      .setValue(
        formatDate(
          new Date(event.target.value),
          'yyyy-MM-dd',
          'en-IN',
          'GMT+0530'
        )
      );
    if (this.form.get('dob').valid) {
      this.validdob = true;
    } else {
      this.validdob = false;
    }
  }

  validategender(event) {
    if (this.form.get('gender').valid) {
      this.validgender = true;
    } else {
      this.validgender = false;
    }
  }

  validbusinessname = false;
  validbusinessaddress = false;
  validbusinessemail = false;
  validbusinessphone = false;

  validatebussiness(event) {
    if (this.form.get('businessname').valid) {
      this.validbusinessname = true;
    } else {
      this.validbusinessname = false;
    }
  }

  validateBAddress(event) {
    if (this.form.get('businessaddress').valid) {
      this.validbusinessaddress = true;
    } else {
      this.validbusinessaddress = false;
    }
  }

  validateBEmail(event) {
    if (this.form.get('businessemail').valid) {
      this.validbusinessemail = true;
    } else {
      this.validbusinessemail = false;
    }
  }

  validateBmobile(event) {
    if (this.form.get('businessphone').valid) {
      this.validbusinessphone = true;
    } else {
      this.validbusinessphone = false;
    }
  }

  validbusinesstype = false;

  checkBusiness(event) {
    if (this.form.get('businesstype').valid) {
      this.validbusinesstype = true;
    } else {
      this.validbusinesstype = false;
    }
  }

  validbcity = false;
  validbpin = false;

  validatebcity(event) {
    if (this.form.get('bcity').valid) {
      this.validbcity = true;
    } else {
      this.validbcity = false;
    }
  }

  validatebpincode(event) {
    if (this.form.get('bpin').valid) {
      this.validbpin = true;
    } else {
      this.validbpin = false;
    }
  }

  getSelectedRepId;
  getSelectedRep;
  SelectRepresentative(event) {
    console.log(event.value);
    this.getSelectedRepId = event.value;
    this.getSelectedRep = this.getAllRepList.find(
      (a) => a['id'] == parseInt(this.getSelectedRepId)
    );
    console.log(this.getSelectedRep);
    this.form
      .get('rep_comm')
      .setValue(parseInt(this.getSelectedRep['rep_comm']));
  }

  update() {
    let newsub = {
      address: this.form.value['address'],
      //city: this.form.get('city').value['name'],
      bcity: this.form.value['bcity'],
      bpin: this.form.value['bpin'],
      businessaddress: this.form.value['businessaddress'],
      businessemail: this.form.value['businessemail'],
      businessname: this.form.value['businessname'],
      businessphone: this.form.value['businessphone'],
      businesstype: this.form.value['businesstype'],
      email: this.form.value['email'],
      mobileNumber: this.form.value['mobileNumber'],
      name: this.form.value['name'],
      otherdonation: this.form.value['otherdonation'],
      paymentmode: this.form.value['paymentmode'],
      pincode: this.form.value['pincode'],
      dob: this.form.value['dob'],
      subCat: this.form.value['subCat'],
      gender: this.form.value['gender'],
      isPaymentCmplt: this.data['sub']['isPaymentCmplt'],
      ds_subisactive: this.data['sub']['ds_subisactive'],
      chequeno: this.form.value['checkno'],
      country: this.form.get('country').value,
      state: this.form.get('state').value['state_name'],
      city: this.form.get('city').value['name'],
      taluka: this.form.get('taluka').value,
      doa: this.form.value['doa'],
      title: this.form.value['title'],
    };

    this.service.UpdatenewSubscriber(newsub).subscribe((res) => {
      console.log(res);
      window.alert('Data has been updated!');
      this.service
        .updateNewSubSubscription(parseInt(newsub['mobileNumber']), 'Y', 'Y')
        .subscribe((res) => {
          console.log(res);
        });
      //this.service.insertCustRepdata(parseInt(newsub['mobileNumber']), this.getMagazine[0]['ds_repid']).subscribe(res=>{
      //    console.log(res)
      // })

      let msg =
        'Dear ' +
        newsub['name'] +
        ', Your profile has been updated. Regards , Hindustan Prakashan Sanstha.';
      let template = 'VIVEK25';
      this.service
        .sendSMS(parseInt(newsub['mobileNumber']), msg, template)
        .subscribe((a) => {
          console.log(a);
        });
      this.dialog.closeAll();
    });
  }
}
