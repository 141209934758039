import { DatePipe } from '@angular/common';
import { ChangeDetectorRef, Component, Inject } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { DigisubservicesService } from 'src/app/services/digisubservices.service';
import { ToWords } from 'to-words';

@Component({
  selector: 'app-three-stamp-receipt',
  templateUrl: './three-stamp-receipt.component.html',
  styleUrls: ['./three-stamp-receipt.component.scss']
})
export class ThreeStampReceiptComponent {

 stampdata: any;
  total: any;
  invoicebillnumber: any="";
  advtname : any
  advncestampnum : any
  AdvtSnum :any
  date1 : any
  toWords: any;
  totalinWords: string;
  currentDate: any;
  address: any;
  mobile: any;
  creation_date:any
  rep_name:any
  stamp_number:any
  current_date:any
  totalamt:any=0
  cheque_date:any=0
  id: any;
  amt: any; 
  

  constructor(private dialogRef: MatDialogRef<ThreeStampReceiptComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private service: DigisubservicesService,
    private cdr: ChangeDetectorRef,private fb: FormBuilder,
    private datePipe : DatePipe,
    private toaster: ToastrService,

  )
   { }

   ngOnInit(): void {

    this.stampdata = this.data['stampdata'];
    this.stampdata.id

    // console.log(this.id, "recpt number");

    this.service.getStamDataByReceiptNo(this.stampdata.id).subscribe((res) => {
      // console.log(res);
      this.data = res[0];
      
    
    });


this.numbertotext();
   
   }



   numbertotext(){
  this.toWords = new ToWords();
  this.totalinWords = this.toWords.convert(this.data['stampdata'].amt, {
    currency: true,
  });
  console.log(this.totalinWords,"numbertotext")
  return this.totalinWords
}

print(): void {
  window.print();
}



}
