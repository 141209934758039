<div class="container-fluid" style="padding:35px">
  

   <mat-card class="example-card" style="margin-top: 20px;">
    <mat-card-header style="display: grid;align-items: center;
    justify-content: center;
">
      <!-- <div mat-card-avatar class="example-header-image"></div> -->
      <img class="img-fluid" src="assets/img/bg.jpeg" width="80" height="80">
      <div style="    font-family: var(--mat-card-title-text-font);
      line-height: var(--mat-card-title-text-line-height);
      font-size: var(--mat-card-title-text-size);
      letter-spacing: var(--mat-card-title-text-tracking);
      margin-bottom: 20px;
      font-weight: var(--mat-card-title-text-weight);
      margin-left: 10px;">Sign In</div>
     
      <!-- <mat-card-subtitle>Dog Breed</mat-card-subtitle> -->
    </mat-card-header>
    <mat-card-content>
        <div class="row">
            <div class="col-12">
                <mat-form-field appearance="outline" class="example-full-width" style="display: unset !important;">
                    <mat-label>Username</mat-label>
                    <input matInput type="text" [(ngModel)]="userName"/>
                </mat-form-field>
    
            </div>
    
            <div class="col-12">
                <mat-form-field appearance="outline" class="example-full-width"  style="display: unset !important;;">
                    <mat-label>Password</mat-label>
                    <input matInput type="password" autocomplete="new-password" [(ngModel)]="password"/>
                </mat-form-field>
            </div>
    
        </div>
    </mat-card-content>
    <mat-card-actions style="justify-content: end !important;">
      <button mat-button (click)="closeDialog()">Close</button>
      <button mat-button color="primary" (click)="submit()">Submit</button>
    </mat-card-actions>
  </mat-card>

</div>