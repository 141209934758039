import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DigisubservicesService } from 'src/app/services/digisubservices.service';

@Component({
  selector: 'app-update-advt-sizes',
  templateUrl: './update-advt-sizes.component.html',
  styleUrls: ['./update-advt-sizes.component.scss'],
})
export class UpdateAdvtSizesComponent implements OnInit {
  advtSize;
  updateForm: FormGroup;

  constructor(
    private dialogref: MatDialogRef<UpdateAdvtSizesComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    private service: DigisubservicesService
  ) {
    this.updateForm = fb.group({
      category: [''],
      Advt_size: [''],
    });
  }

  ngOnInit(): void {
    this.advtSize = this.data['val'];
    this.updateForm.get('category').setValue(this.advtSize['category']);
    this.updateForm.get('Advt_size').setValue(this.advtSize['Advt_size']);
  }

  updateData() {
    let data = {
      id: this.advtSize['id'],
      category: this.updateForm.get('category').value,
      Advt_size: this.updateForm.get('Advt_size').value,
    };

    this.service.updateAdvtSizes(data).subscribe((data) => {
      console.log(data);
    });

    this.dialogref.close({ res: 'close' });
  }
}
