import { formatDate } from '@angular/common';
import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DigisubservicesService } from 'src/app/services/digisubservices.service';
import { MatDialog } from '@angular/material/dialog';
import { AddNewSubOtpModalComponent } from 'src/app/pages/add-new-sub-otp-modal/add-new-sub-otp-modal.component';
import { CancelSubDialogComponent } from 'src/app/pages/cancel-sub-dialog/cancel-sub-dialog.component';
import { CancelRepDialogComponent } from 'src/app/pages/cancel-rep-dialog/cancel-rep-dialog.component';
import { EncdecriptService } from 'src/app/services/encdecrypt.service';
import { AddNewAdminDialogComponent } from 'src/app/pages/add-new-admin-dialog/add-new-admin-dialog.component';
import { Router } from '@angular/router';
import jsPDF from 'jspdf';
import { FileUploader } from 'ng2-file-upload';
import html2canvas from 'html2canvas';
import { Observable, timer } from 'rxjs';
import { UpdatePlublishDatesComponent } from '../update-plublish-dates/update-plublish-dates.component';
import { map, startWith } from 'rxjs/operators';
import { UpdateMagazineNameAndCategoryComponent } from '../update-magazine-name-and-category/update-magazine-name-and-category.component';
import { UpdateAdvtSizesComponent } from '../update-advt-sizes/update-advt-sizes.component';
import { UpdateIssueDatesComponent } from 'src/app/pages/update-issue-dates/update-issue-dates.component';
import { UpdateStateMasterComponent } from '../update-state-master/update-state-master.component';
import { UpdateDistrictMasterComponent } from '../update-district-master/update-district-master.component';
import { UpdateTalukaMasterComponent } from '../update-taluka-master/update-taluka-master.component';
import { UpdatePrantComponent } from '../admin/update-prant/update-prant.component';
import { MatOption, MatSelect } from '@angular/material/select';
import { MatOptionSelectionChange } from '@angular/material/core';

interface tableData {
  id: number;
  name: string;
  mobile: string;
}

export interface StateGroup {
  category: string;
  details: string[];
}

export const _filter4 = (opt: string[], value: string): string[] => {
  const filterValue = value.toLowerCase();

  return opt.filter((item) => item.toLowerCase().includes(filterValue));
};

export const _filter5 = (opt: string[], value: string): string[] => {
  const filterValue = value.toLowerCase();

  return opt.filter((item) => item.toLowerCase().includes(filterValue));
};

export const _filter6 = (opt: string[], value: string): string[] => {
  const filterValue = value.toLowerCase();

  return opt.filter((item) => item.toLowerCase().includes(filterValue));
};

export const _filter7 = (opt: string[], value: string): string[] => {
  const filterValue = value.toLowerCase();

  return opt.filter((item) => item.toLowerCase().includes(filterValue));
};

export const _filter8 = (opt: string[], value: string): string[] => {
  const filterValue = value.toLowerCase();

  return opt.filter((item) => item.toLowerCase().includes(filterValue));
};

export const _filter9 = (opt: string[], value: string): string[] => {
  const filterValue = value.toLowerCase();

  return opt.filter((item) => item.toLowerCase().includes(filterValue));
};

export const _filter10 = (opt: string[], value: string): string[] => {
  const filterValue = value.toLowerCase();

  return opt.filter((item) => item.toLowerCase().includes(filterValue));
};

export const _filter11 = (opt: string[], value: string): string[] => {
  const filterValue = value.toLowerCase();

  return opt.filter((item) => item.toLowerCase().includes(filterValue));
};

const uploadAPI = 'http://minimasters.in/digisub/portal_fileUpload.php';
@Component({
  selector: 'app-icons',
  templateUrl: './icons.component.html',
  styleUrls: ['./icons.component.scss'],
})
export class IconsComponent implements OnInit {
  @ViewChild('htmlData') htmlData: ElementRef;

  BookForm: FormGroup;
  addform: FormGroup;
  MasterTable: FormGroup;
  OccupationMaster: FormGroup;
  sbhet: FormGroup;
  startenddate: FormGroup;
  tableData: tableData[] = [];
  selectedState = '';
  selectedCity = '';
  selectedState2 = '';
  selectedCity2 = '';
  displayinvoiceForm = true;
  invoicedate = formatDate(new Date(), 'yyyy-MM-dd', 'en-IN', 'GMT+0530');
  magform: FormGroup;
  magpriceupdate: FormGroup;
  searchTerm = false;
  displayChequqField = false;
  dataLoaded = false;
  mag = [];
  magname = [];
  magyear = [];
  subscriberDetails = {
    name: '',
    address: '',
  };
  submagsDetails;
  totalamount = 0;
  totalmagamount = 0;
  totaldonationamount = 0;
  displayTotal = false;
  addNewAdminForm: FormGroup;
  addIssueGranthaForm: FormGroup;
  advtSizesForm: FormGroup;
  IssueDates: FormGroup;
  StateMaster: FormGroup;
  CityMaster: FormGroup;
  prantMaster: FormGroup;
  TalukaMaster: FormGroup;
  form2: FormGroup;
  officerepform: FormGroup;
  repassignform: FormGroup; 
  repassigntype: FormGroup;
  repassignoff: FormGroup;
  addcountry : FormGroup;
  addseni : FormGroup;
  addlang : FormGroup;

  NewMagazineForm: FormGroup;
  public copy: string;
  data1;
  data2;
  needToUpdate = false;
  imgPath: string;
  newDateValue;
  newDateArray = [];
  yourMessage = [];
  stateGroups: StateGroup[] = [
    { category: 'Magazine', details: ['Vivek_Weekly'] },
    {
      category: 'Grantha',
      details: ['abc', 'pqr'],
    },
    { category: 'Special Magazine', details: ['xyq'] },
  ];
  advt_mag_name_details = [];
  Filteredadvt_mag_name_details = [];
  delivery_mode;

  public uploader: FileUploader = new FileUploader({
    url: uploadAPI,
    itemAlias: 'file',
  });
  issueName: any;
  advtsizebyCategory: any;
  getMagIssueDatesandNames: any[];
  filterdMagIssueDatesandNames: any;
  issueGranthName: any;
  issueForm: any;
  _filter1: any;
  issueDates: any;
  sbhetdata: any;
  id: any;
  updatesbhet: boolean;
  getdepwiserepcount1: any;
  advt_type: any;
  getdatabydep: any;
  rep_type: any;
  count: any;
  getrepname: any;
  off_rep: any;
  getdatabyoff: any;
  issuedate: any;
  formattedDate: string;
  deactivedata: any;
  getcountry: any;
  countryid: any;
  getdeptdata: any;
  senioritylist: any;
  languagelist: any;
  off_rep_id: any;
  getLanguageList: any;
  getLanguageList1: any;
  setlangugae: any;
  updatecountry123:any=false;
  constructor(
    private router: Router,
    private fb: FormBuilder,
    public service: DigisubservicesService,
    public dialog: MatDialog,
    public encr: EncdecriptService
  ) {
    this.BookForm = fb.group({
      book_name: '',
      book_desc: '',
      book_price: '',
      event_saleprice: '',
      book_img: '',
      book_active: '',
    });

    this.magform = fb.group({
      magname: [''],
      magedition: [''],
      magamount: '',
      magyear: '',
      magdesc: '',
      magapp: '',
      magfullname: '',
    });

    this.magpriceupdate = fb.group({
      magname: [''],
      magedition: [''],
      magamount: '',
      magyear: '',
    });

    this.addNewAdminForm = fb.group({
      adminMobile: [''],
      adminEmail: [''],
      adminPassword: [''],
    });
    this.addIssueGranthaForm = fb.group({
      igcategory: [''],
      igsubcategory: [''],
    });
    this.advtSizesForm = fb.group({
      advt_category: [''],
      advt_size_name: ['', [Validators.required]],
      isactive: [''],
    });
    this.IssueDates = fb.group({
      advt_category: [''],
      issuedate: [],
      publisheddate: [],
      issue_name: ['', [Validators.required]],
      issue_price: [],
      lang: [],
      blackwhite: [''],
      insidecolour: [''],
      cover: [''],
      totalpages: [''],
      issuesize: [''],
      percopyweight: [''],
      prntdcopies: [''],
      maflitho: [''],
      lwc: [''],
      artpaper: [''],
      paperreams1: [''],
      paperreams2: [''],
      paperreams3: [''],
      rnireleated: [''],
    });
    this.StateMaster = fb.group({
      State_name: '',
    });

    this.CityMaster = fb.group({
      city_name: '',
      prant_name: '',
    });

    this.prantMaster = fb.group({
      prant_name: '',
    });

    this.TalukaMaster = fb.group({
      taluka_name: [''],
      pincode: [''],
    });

    this.MasterTable = fb.group({
      title: '',
    });

    this.OccupationMaster = fb.group({
      occupation: '',
    });

    this.repassigntype = fb.group({
      type: '',
    });
    this.repassignoff = fb.group({
      off_rep: '',
    });
    
    this.sbhet = fb.group({
      issuename: '',
      issuedate: '',
      issueseller : '',
      sbhet : '',
      id : '',
      date : '',
    });  

    this.startenddate = fb.group({
      start: '',
      end: '',
      id : '',
     
    });

    this.addform = this.fb.group({
      issuesellername: [''],
      address1: [''],
      pincode: [
        '',
        [Validators.maxLength(6), Validators.pattern('^[1-9][0-9]{5}$')],
      ],
      country: ['', [Validators.required, Validators.minLength(2)]],
      state: ['', [Validators.required, Validators.minLength(2)]],
      taluka: ['', [Validators.required, Validators.minLength(2)]],
      district: ['', [Validators.required, Validators.minLength(2)]],
      mnumber: [
        '',
        [
          Validators.minLength(10),
          Validators.maxLength(10),
          Validators.pattern('^[7-9][0-9]{9}$'),
        ],
      ],
      email: [
        '',
        [Validators.pattern('[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+.[a-z]{2,3}$')],
      ],
      landline_no: ['', Validators.pattern('^[0-9]\\d{2,4}-\\d{6,8}$')],
      dob: [],
      gstno: ['', [Validators.minLength(15), Validators.maxLength(15)]],
      panno: ['', [Validators.minLength(10), Validators.maxLength(10)]],
      depositamt: [],
      option: [''],
      sampremebhet: [''],
      distype: [''],
      issuename: [''],
      issuedate: [''],
    });

    this.form2 = this.fb.group({
      name: [''],
      mobileNumber: [''],
      email: '',
    });

    this.officerepform = fb.group({
      issuesellername: [''],
      address1: [''],
      pincode: [
        '',
        [Validators.maxLength(6), Validators.pattern('^[1-9][0-9]{5}$')],
      ],
      country: ['', [Validators.required, Validators.minLength(2)]],
      state: ['', [Validators.required, Validators.minLength(2)]],
      taluka: ['', [Validators.required, Validators.minLength(2)]],
      district: ['', [Validators.required, Validators.minLength(2)]],
      mnumber: [
        '',
        [
          Validators.minLength(10),
          Validators.maxLength(10),
          Validators.pattern('^[7-9][0-9]{9}$'),
        ],
      ],
      email: [
        '',
        [Validators.pattern('[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+.[a-z]{2,3}$')],
      ],
      landline_no: ['', Validators.pattern('^[0-9]\\d{2,4}-\\d{6,8}$')],
      dob: [],
      gstno: ['', [Validators.minLength(15), Validators.maxLength(15)]],
      panno: ['', [Validators.minLength(10), Validators.maxLength(10)]],
    });

    this.repassignform = fb.group({
      rep: [''],
      district: [''],
      taluka: [''],
      bookstore: [''],
      subscription: [''],
      advt: [''],
      issue: [''],
    });

    this.addcountry = fb.group({
      country_name: [''],
      country_code: [''],
      
    });
    this.addseni = fb.group({
      off_repname: [''],
      seniority: [''],
      dept: ['']
      
    });

    this.addlang = fb.group({
      langauge: ['']
  
    });


    this.NewMagazineForm = fb.group({
      mag_name: [''],
      ig_cat: [''],
    });

    this.rep_List();
  }

  filteredOptions4: Observable<string[]>;
  filteredOptions5: Observable<string[]>;
  filteredOptions6: Observable<string[]>;
  filteredOptions7: Observable<string[]>;
  filteredOptions8: Observable<string[]>;
  filteredOptions9: Observable<string[]>;
  filteredOptions10: Observable<string[]>;
  filteredOptions11: Observable<string[]>;
  isAdminAuthorize = false;
  countries = [];
  states = [];
  state_count = 0;
  cities = [];
  talukas = [];
  selectedTaluka;
  city_count = 0;
  taluka_count = 0;
  titles = [];
  occupations = [];
  issueSellerName: any = [];
  issue_seller_name = [];
  office_rep = [];
  advtCategorylist;
  deptwise_off_rep_list = [];
  MagazineIssueGranthData = [];
  MagazineIssueGranthMag_names = [];
  advtsizesData = [];
  FilteredadvtsizesData = [];
  issueDatesData = [];
  FilteredissueDatesData = [];
  prantData;
  public demo1TabIndex = 0

  public demo1BtnClick1(tabCount:any) {
    
    this.demo1TabIndex =  Number(tabCount);
    
  }

  ngOnInit() {
   
    this.demo1BtnClick1( localStorage.getItem("tab"))

    this.service.getdepwiserepcount().subscribe((data) => {
      this.getdepwiserepcount1 = data;
    });

    this.service.getcountry().subscribe((data) => {
      this.getcountry = data;
    });

    this.service.getseniority().subscribe((data) => {
      this.senioritylist = data;
    });

    this.service.getlanguagelist().subscribe((data) => {
      this.languagelist = data;
    });


    this.service.getoffrepname().subscribe((data) => {
      this.getrepname = data;
    });

    this.service.getdept().subscribe((data) => {
      this.getdeptdata = data;
    });
   
    this.IssueDates.get('issue_name').setValue('विवेक साप्ताहिक');
    this.IssueDates.get('issue_price').setValue(20);
    this.IssueDates.get('lang').setValue('मराठी');
    // Read All Contries
    this.service.getCountry().subscribe((data) => {
      this.countries = data;
    });

    this.service.getDeliveryMode().subscribe((data) => {
      // console.log('getDeliveryMode', data);
      this.delivery_mode = data;
    });

    this.service.getIssueSellerName().subscribe((data) => {
      this.issueSellerName = data;  
      // console.log('issueSellerName ', data);
    });

    this.service.getSbhetdataadmin().subscribe((data) => {
      this.sbhetdata = data;
      console.log('issueSellerName of sbhet ', data);
    });
    this.service.getdeactivedata().subscribe((data) => {
      this.deactivedata = data;
      console.log('issueSellerName of sbhet ', data);
    });

    this.service.getIssuesellersname().subscribe((data) => {
      this.issue_seller_name = data;
      console.log('issue_seller_name', this.issue_seller_name);
    });

    this.service.getOfficeRepName().subscribe((officedata) => {
      // console.log('office_rep', officedata);
      this.office_rep = officedata;
    });

    this.service.getAdvtCategory().subscribe((data) => {
      // console.log('Database data', data);
      this.advtCategorylist = data;
    });

    this.service.getLanguageList().subscribe((data) => {
      // console.log('Database data', data);
      this.getLanguageList1 = data;
    });

    this.service.getOccupation().subscribe((data) => {
      // console.log(data);
      this.occupations = data;
    });

    this.service.getstates(1).subscribe((data) => {
      // console.log(data);
      this.states = data;
      this.state_count = this.states.length;
    });

    this.getAdvtMagDetails();

    this.service.getAlltitle().subscribe((data) => {
      // console.log(data);
      this.titles = data;
    });

    if (sessionStorage.getItem('mobile') == null) {
      this.router.navigate(['/']);
    }

    this.magform.get('magedition').setValue('Digital');

    this.service
      .checkadminissuper(parseInt(sessionStorage.getItem('id')))
      .subscribe((a) => {
        if (Object.keys(a).length > 0) {
          this.isAdminAuthorize = true;
        } else {
          this.isAdminAuthorize = false;
        }
      });

    //change for OEM
    this.service.getMag().subscribe((res) => {
      //console.log(res['name'])
      this.mag = [];
      this.magname = [];
      this.magyear = [];

      for (let i = 0; i < Object.keys(res).length; i++) {
        this.mag.push({
          name: res[i]['name'],
          print: res[i]['print'],
          digital: res[i]['digital'],
          printdigital: res[i]['printdigital'],
          year: parseInt(res[i]['year']),
        });

        if (i == 0) {
          this.magname[0] = {
            name: res[i]['name'],
          };

          this.magyear[0] = {
            year: parseInt(res[i]['year']),
          };
        } else {
          // console.log(this.magname.find((a) => a['name'] == res[i]['name']));

          if (
            this.magname.find((a) => a['name'] == res[i]['name']) == undefined
          ) {
            this.magname.push({
              name: res[i]['name'],
            });
          }

          this.magyear.push({
            year: parseInt(res[i]['year']),
          });
        }

        // console.log(this.magname);
      }

      this.autocomfunction();
      //name.push(amount)
    });
    // console.log('array ', this.newDateArray.length)
    this.getMagazineIssueGranthData();
    this.getAllAdvtSizes();
    this.getAllIssueDates();
    this.getAllPrantData();
  }

  getAllAdvtSizes() {
    this.service.getAllAdvtSizes().subscribe((data) => {
      // console.log(data);
      this.advtsizesData = data;
      this.FilteredadvtsizesData = this.advtsizesData;
    });
  }

  getAllIssueDates() {
    this.service.getAllIssueDates().subscribe((data) => {
      this.issueDatesData = data;
      this.FilteredissueDatesData = this.issueDatesData;
    });
  }

  autocomfunction() {
    this.service.getDeptwiseOffRepList().subscribe((data) => {
      // console.log(data);
      this.deptwise_off_rep_list = data;
    });

    this.filteredOptions4 = this.addform
      .get('issuesellername')
      .valueChanges.pipe(
        startWith(''),
        map((value) => (typeof value === 'string' ? value : value.name)),
        map((name) =>
          name ? this._filter4(name) : this.issue_seller_name.slice()
        )
      );

      console.log( this.issue_seller_name , "this name list")

    this.filteredOptions5 = this.addform.get('mnumber').valueChanges.pipe(
      startWith(''),
      map((value) => (typeof value === 'string' ? value : value.mob_no)),
      map((mob_no) =>
        mob_no ? this._filter5(mob_no) : this.issue_seller_name.slice()
      )
    );

    this.filteredOptions6 = this.repassignform.get('rep').valueChanges.pipe(
      startWith(''),
      map((value) => (typeof value === 'string' ? value : value.name)),
      map((name) =>
        name ? this._filter6(name) : this.issue_seller_name.slice()
      )
    );

    this.filteredOptions7 = this.repassignform
      .get('bookstore')
      .valueChanges.pipe(
        startWith(''),
        map((value) => (typeof value === 'string' ? value : value.name)),
        map((name) => (name ? this._filter7(name) : this.office_rep.slice()))
      );

    this.filteredOptions8 = this.repassignform
      .get('subscription')
      .valueChanges.pipe(
        startWith(''),
        map((value) => (typeof value === 'string' ? value : value.name)),
        map((name) => (name ? this._filter8(name) : this.office_rep.slice()))
      );

    this.filteredOptions9 = this.repassignform.get('advt').valueChanges.pipe(
      startWith(''),
      map((value) => (typeof value === 'string' ? value : value.name)),
      map((name) => (name ? this._filter9(name) : this.office_rep.slice()))
    );

    this.filteredOptions10 = this.repassignform.get('issue').valueChanges.pipe(
      startWith(''),
      map((value) => (typeof value === 'string' ? value : value.name)),
      map((name) => (name ? this._filter10(name) : this.office_rep.slice()))
    );

    this.filteredOptions11 = this.NewMagazineForm.get(
      'mag_name'
    ).valueChanges.pipe(
      startWith(''),
      map((value) => (typeof value === 'string' ? value : value.mag_name)),
      map((mag_name) =>
        mag_name
          ? this._filter11(mag_name)
          : this.MagazineIssueGranthMag_names.slice()
      )
    );
  }

  private _filter4(name: string): any[] {
    const filterValue = name;
    return this.issue_seller_name.filter((option) =>
      // option.mobno.toLowerCase().includes(filterValue)
      option.name.toLowerCase().includes(filterValue)
    );
  }

  private _filter5(mob_no: string): any[] {
    const filterValue = mob_no;
    return this.issue_seller_name.filter((option) =>
      // option.mobno.toLowerCase().includes(filterValue)
      option.mob_no.toLowerCase().includes(filterValue)
    );
  }

  private _filter6(name: string): any[] {
    const filterValue = name.toLowerCase();

    return this.issue_seller_name.filter((option) =>
      option.name.toLowerCase().includes(filterValue)
    );
  }

  private _filter7(name: string): any[] {
    const filterValue = name.toLowerCase();

    return this.office_rep.filter((option) =>
      option.name.toLowerCase().includes(filterValue)
    );
  }

  private _filter8(name: string): any[] {
    const filterValue = name.toLowerCase();

    return this.office_rep.filter((option) =>
      option.name.toLowerCase().includes(filterValue)
    );
  }

  private _filter9(name: string): any[] {
    const filterValue = name.toLowerCase();

    return this.office_rep.filter((option) =>
      option.name.toLowerCase().includes(filterValue)
    );
  }

  private _filter10(name: string): any[] {
    const filterValue = name.toLowerCase();

    return this.office_rep.filter((option) =>
      option.name.toLowerCase().includes(filterValue)
    );
  }

  private _filter11(mag_name: string): any[] {
    const filterValue = mag_name.toLowerCase();

    return this.MagazineIssueGranthMag_names.filter((option) =>
      option.mag_name.toLowerCase().includes(filterValue)
    );
  }

  updateRepData(item) {
    console.log(item);

    this.selected_rep_name(
      { isUserInput: true },
      { name: item['issueSellerName'] }
    );
  }

  selected_repname: boolean = false;
  repId = 0;
  selected_rep_name(event, option) {
    // console.log(event.isUserInput);
    if (event.isUserInput) {
      let data = this.issueSellerName.filter(
        (a) => a['issueSellerName'] == option['name']
      );
      console.log('data', data);
      this.selected_repname = true;
      this.repId = data[0]['id'];
      this.addform.get('issuesellername').setValue(data[0]['issueSellerName']);
      this.addform.get('address1').setValue(data[0]['address']);
      this.addform.get('pincode').setValue(data[0]['pincode']);
      this.addform.get('mnumber').setValue(data[0]['mobile_no']);
      this.addform.get('email').setValue(data[0]['email']);
      this.addform.get('landline_no').setValue(data[0]['landline_no']);
      this.addform.get('dob').setValue((data[0]['dateofbirth']));
      // this.addform.get('gstno').setValue(data[0]['gstno']);
      this.addform.get('panno').setValue(data[0]['panno']);
      this.addform.get('depositamt').setValue(data[0]['deposit_amt']);
      this.addform.get('option').setValue(data[0]['issueSellerType']);
      this.addform.get('sampremebhet').setValue(data[0]['s_bhet']);
      this.addform.get('distype').setValue(data[0]['distype']);
      this.getCountry({ value: data[0]['country'] });
      setTimeout(() => {
        this.getState({ value: data[0]['state'] });
      }, 100);
      setTimeout(() => {
        this.getDistricts({ value: data[0]['district'] });
      }, 500);
      this.addform.get('country').setValue(data[0]['country']);
      this.addform.get('state').setValue(data[0]['state']);
      this.addform.get('district').setValue(data[0]['district']);
      setTimeout(() => {
        this.addform.get('taluka').setValue(data[0]['taluka']);
      }, 500);
      this.addform.get('country').setValue(data[0]['country']);
      this.addform.get('state').setValue(data[0]['state']);
      this.addform.get('district').setValue(data[0]['district']);
      this.addform.get('taluka').setValue(data[0]['taluka']);
      this.selectedCity = data[0]['district'];
    }
  }

  option = false;
  select(event) {
    // console.log(event.value);
    if (event.value == 'agent') {
      this.option = true;
    } else {
      this.option = false;
    }
  }

  validmobile = false;
  validemail = false;
  validdob = false;
  validateMobile(event) {
    if (this.addform.get('mnumber').valid) {
      this.validmobile = true;
    } else {
      this.validmobile = false;
    }
  }

  validatemobile(event) {
    if (this.officerepform.get('mnumber').valid) {
      this.validmobile = true;
    } else {
      this.validmobile = false;
    }
  }

  validateEmail(event) {
    if (this.addform.get('email').valid) {
      this.validemail = true;
    } else {
      this.validemail = false;
    }
  }

  validateemail(event) {
    if (this.officerepform.get('email').valid) {
      this.validemail = true;
    } else {
      this.validemail = false;
    }
  }

  validatedob(event) {
    if (this.addform.get('dob').valid) {
      this.validdob = true;
    } else {
      this.validdob = false;
    }
  }

  validateDob(event) {
    if (this.addform.get('dob').valid) {
      this.validdob = true;
    } else {
      this.validdob = false;
    }
  }

  rep_id = 0;
  rep_name = '';
  rep_off_assign_id = 0;
  isdata: boolean = false;
  selected_name(event, option) {
    if (event.isUserInput) {
      this.rep_id = option['id'];
      this.repassignform.get('district').setValue(option['district']);
      this.repassignform.get('taluka').setValue(option['taluka']);

      for (
        let index = 0;
        index < Object.keys(this.deptwise_off_rep_list).length;
        index++
      ) {
        if (this.deptwise_off_rep_list[index]['rep_name'] == option['name']) {
          this.isdata = true;
          this.rep_off_assign_id = this.deptwise_off_rep_list[index]['id'];
          this.rep_id = this.deptwise_off_rep_list[index]['rep_id'];
          this.book_id = this.deptwise_off_rep_list[index]['book_id'];
          this.repassignform
            .get('bookstore')
            .setValue(this.deptwise_off_rep_list[index]['book_off_rep_name']);
          this.sub_id = this.deptwise_off_rep_list[index]['sub_id'];
          this.repassignform
            .get('subscription')
            .setValue(this.deptwise_off_rep_list[index]['sub_off_rep_name']);
          this.advt_id = this.deptwise_off_rep_list[index]['advt_id'];
          this.repassignform
            .get('advt')
            .setValue(this.deptwise_off_rep_list[index]['advt_off_rep_name']);
          this.issue_id = this.deptwise_off_rep_list[index]['issue_id'];
          this.repassignform
            .get('issue')
            .setValue(this.deptwise_off_rep_list[index]['issue_off_rep_name']);
        }
      }
    }
  }

  book_id;
  selected_bookstore(event) {
    this.book_id = event['id'];
    this.repassignform.get('bdistrict').setValue(event['district']);
    this.repassignform.get('btaluka').setValue(event['taluka']);
  }

  sub_id;
  selected_subs(event) {
    this.sub_id = event['id'];
    this.repassignform.get('sdistrict').setValue(event['district']);
    this.repassignform.get('staluka').setValue(event['taluka']);
  }

  advt_id;
  selected_advt(event) {
    this.advt_id = event['id'];
    this.repassignform.get('adistrict').setValue(event['district']);
    this.repassignform.get('ataluka').setValue(event['taluka']);
  }

  issue_id;
  selected_issue(event) {
    this.issue_id = event['id'];
    this.repassignform.get('idistrict').setValue(event['district']);
    this.repassignform.get('italuka').setValue(event['taluka']);
  }

  removeEvent(event_id) {
    this.service.removeEvent(parseInt(event_id)).subscribe((res) => {
      window.alert('Event Deleted !');
    });
  }

  removeMag(magname) {
    if (window.confirm('Do you really want to remove the magazine?')) {
      // console.log(magname);
      this.service.removeMag(magname).subscribe((mag) => {
        //console.log(mag)

        window.alert('Magazine Removed Successfully.');
        //change for OEM
        this.service.getMag().subscribe((res) => {
          //console.log(res['name'])
          this.mag = [];

          for (let i = 0; i < Object.keys(res).length; i++) {
            this.mag.push({
              name: res[i]['name'],
              print: res[i]['print'],
              digital: res[i]['digital'],
              printdigital: res[i]['printdigital'],
              year: parseInt(res[i]['year']),
            });
          }
          //name.push(amount)
        });
      });
    }
  }
  

  action(row: any) {
    if (row.operation === 'updated') {
      //this.update(row); // This is your update call to API
      this.yourMessage.push('success', 'updated successfully'); // Show update success notification
      this.yourMessage = []; // make sure you empty it
    }

    if (row.operation === 'deleted') {
      //this.delete(row.id); // This is your delete call to API
      this.yourMessage.push('success', 'deleted successfully'); // Show delete success notification
      this.yourMessage = []; // make sure you empty it
    }
  }

  rep_List() {
    let filterdData: string[];
    this.service.get_AllRepresentative_List().subscribe((data) => {
      this.data1 = data;
      //console.log("Data from PHP : ", this.data1);
      //console.log(this.data1.filter(item => { return item['name'] != "office" }))

      this.data1 = this.data1.filter((item) => {
        return item['name'] != 'office';
      });

      //Object.values(this.data1).filter(key => !this.data1.includes)
      for (let index = 0; index < Object.keys(this.data1).length; ++index) {
        if (parseInt(this.data1[index]['id']) > 50) {
          this.tableData.push({
            id: this.data1[index]['id'],
            name: this.data1[index]['name'],
            mobile: this.data1[index]['mobile'],
          });
          continue;
        }
      }

      if (Object.keys(this.tableData).length == 0) {
        this.dataLoaded = false;
      } else {
        this.dataLoaded = true;
      }
    });
  }

  getAdvtMagDetails() {
    this.advt_mag_name_details = [];
    this.stateGroups = [];
    let abc;
    this.service.getAdvtMagNames().subscribe((data) => {
      // console.log(data);
      for (let index = 0; index < Object.keys(data).length; index++) {
        // console.log(data[index]);
        this.advt_mag_name_details.push({
          id: data[index]['id'],
          is_active: data[index]['is_active'],
          issue_name: data[index]['issue_name'],
          category: data[index]['category'],
          publishing_date: data[index]['publishing_date'].split(','),
        });

        if (index == 0) {
          // console.log(
          //   data.filter((a) => a.category == data[index]['category'])
          // );
        }

        if (
          this.stateGroups.find((a) => a.category == data[index]['category']) ==
          undefined
        ) {
          let mags = data.filter((a) => a.category == data[index]['category']);
          let mag_to_list = [];
          for (let index = 0; index < Object.keys(mags).length; index++) {
            mag_to_list.push(mags[index]['issue_name']);
          }
          this.stateGroups.push({
            category: data[index]['category'],
            details: mag_to_list,
          });
        }
      }

      this.Filteredadvt_mag_name_details = this.advt_mag_name_details;

      // console.log(this.stateGroups);
      //this.advt_mag_name_details = data
    });
  } //end of getAdvtMagDetails

  make_it_activate(advt) {
    // console.log(' advt id', advt['id']);
    this.service.updateAdvtMagStatus(advt['id'], 'Y').subscribe((data) => {
      // console.log('fetched data', data);
      this.getAdvtMagDetails();
      window.alert('Status Change!');
    });
  }

  isadminexist(event) {
    const mobile = event.target.value;
    this.service.checkadminisexist(parseInt(mobile)).subscribe((a) => {
      if (Object.keys(a).length > 0) {
        window.alert('Admin Already exist with this Number');
        this.addNewAdminForm.get('adminPassword').disable();
      } else {
        this.addNewAdminForm.get('adminPassword').disable();
      }
    });
  }

  superAdmin(id) {
    this.service.makesuperAdmin(id, 'Y').subscribe((a) => {
      this.service.getalladmin().subscribe((ad) => {
        this.getalladmins = [];
        for (let index = 0; index < Object.keys(ad).length; index++) {
          if (ad[index]['issuper'] == 'true') {
            this.getalladmins.push({
              id: ad[index]['id'],
              mobile: ad[index]['mobile'],
              email: ad[index]['email'],
              issuper: true,
            });
          } else {
            this.getalladmins.push({
              id: ad[index]['id'],
              mobile: ad[index]['mobile'],
              email: ad[index]['email'],
              issuper: false,
            });
          }
        }
      });
    });
  }

  advtCategory;
  getAdvtCategory(e) {
    // console.log(e);
    this.advtCategory = e.value;

    this.FilteredadvtsizesData = this.advtsizesData.filter((a) => {
      return (
        a['category'].toLowerCase().indexOf(this.advtCategory.toLowerCase()) >
        -1
      );
    });
  }

  getAdvtCategory1(e) {
    // console.log(e);
    // console.log(this.FilteredissueDatesData);

    this.advtCategory = e.value;

    this.FilteredissueDatesData = this.issueDatesData.filter((a) => {
      return (
        a['advt_category']
          .toLowerCase()
          .indexOf(this.advtCategory.toLowerCase()) > -1
      );
    });
  }
  setLanguage(e) {
    // console.log(e);
    // console.log(this.FilteredissueDatesData);

    this.setlangugae = e.value;

    // this.FilteredissueDatesData = this.issueDatesData.filter((a) => {
    //   return (
    //     a['advt_category']
    //       .toLowerCase()
    //       .indexOf(this.advtCategory.toLowerCase()) > -1
    //   );
    // });
  }

  save_advtSizes() {
    let advtdata = {
      advt_category: this.advtCategory,
      advt_size_name: this.advtSizesForm.get('advt_size_name').value,
      isactive: 'Y',
    };
    this.service.addAdvtSizes(advtdata).subscribe((res) => {
      alert('Advertiesment Sizes Added Successfully...');
      this.advtSizesForm.reset();
    });
  }

  save_issue_dates() {
    if (!this.checkDuplicateEntry) {
      let issuedata = {
        advt_category: this.advtCategory,
        issuedate: formatDate(
          this.IssueDates.get('issuedate').value,
          'yyyy-MM-dd',
          'en-IN',
          'GMT+0530'
        ),
        publisheddate: formatDate(
          this.IssueDates.get('publisheddate').value,
          'yyyy-MM-dd',
          'en-IN',
          'GMT+0530'
        ),
        issue_name: this.IssueDates.get('issue_name').value,
        issue_price: this.IssueDates.get('issue_price').value,
        lang: this.IssueDates.get('lang').value,
        blackwhite: this.IssueDates.get('blackwhite').value,
        insidecolour: this.IssueDates.get('insidecolour').value,
        cover: this.IssueDates.get('cover').value,
        totalpages: this.IssueDates.get('totalpages').value,
        issuesize: this.IssueDates.get('issuesize').value,
        percopyweight: this.IssueDates.get('percopyweight').value,
        prntdcopies: this.IssueDates.get('prntdcopies').value,
        maflitho: this.IssueDates.get('maflitho').value,
        lwc: this.IssueDates.get('lwc').value,
        artpaper: this.IssueDates.get('artpaper').value,
        paperreams1: this.IssueDates.get('paperreams1').value,
        paperreams2: this.IssueDates.get('paperreams2').value,
        paperreams3: this.IssueDates.get('paperreams3').value,
        rnireleated: this.IssueDates.get('rnireleated').value,
      };
     
      this.service.addAdvtDates(issuedata).subscribe(
        (res) => {
          alert('Advertiesment Dates Added Successfully...');
          this.IssueDates.reset();
          this.ngOnInit();
        }
        // ,
        // (error) => {
        //   alert('Advertiesment Dates Already Present...');
        // }
      );
    } else {
      alert('Issue Date is Already Present. Please change!...');
    }
  }

  checkDuplicateEntry: boolean = false;
  selectDateChange() {
    if (this.IssueDates.get('issuedate').value != null) {
      let day = new Date(this.IssueDates.get('issuedate').value);
      day.setDate(day.getDate() - 7);
      // console.log(formatDate(day, 'yyyy-MM-dd', 'en-IN', 'GMT+0530'));
      this.IssueDates.get('publisheddate').setValue(
        formatDate(day, 'yyyy-MM-dd', 'en-IN', 'GMT+0530')
      );

      if (this.advtCategory === 'नियिमत अंक') {
        this.service
          .checkDuplicateIssueDate(
            this.advtCategory,
            formatDate(
              this.IssueDates.get('issuedate').value,
              'yyyy-MM-dd',
              'en-IN',
              'GMT+0530'
            ),
            formatDate(
              this.IssueDates.get('publisheddate').value,
              'yyyy-MM-dd',
              'en-IN',
              'GMT+0530'
            )
          )
          .subscribe((res) => {
            if (Object.keys(res).length) {
              this.checkDuplicateEntry = true;
              alert('Issue Date is Already Present. Please change!...');
            }
          });
      } else {
        this.checkDuplicateEntry = false;
      }
      // console.log(this.IssueDates.get('publisheddate').value);
    }
  }

  addIssueGrantha() {
    let issueGrantha = {
      igcategory: this.addIssueGranthaForm.get('igcategory').value,
      igsubcategory: this.addIssueGranthaForm.get('igsubcategory').value,
    };
    this.service.insertAdvtMagazine(issueGrantha).subscribe((a) => {
      window.alert('Issue/Grantha added sucessfully...');
      this.addIssueGranthaForm.reset();
      this.newDateArray = [];
    });
    console.log(this.newDateArray);
  }

  updateIssueDates(item) {
    // console.log(item);
    let result = this.dialog.open(UpdateIssueDatesComponent, {
      data: {
        val: item,
      },
      width: '500px',
      height: '600px',
      maxWidth: '100%',
    });

    result.afterClosed().subscribe((res) => {
      // console.log(res);
      if (res['res'] == 'close') {
        window.alert('Data Uploaded Successfully...');
        this.ngOnInit();
      }
    });
  }

  removeIssueDates(item) {
    if (window.confirm('Do you really want to remove ?')) {
      this.service.updateIssueDatetoInactive(item['id']).subscribe((data) => {
        window.alert('Data Removed Successfully...');
        this.ngOnInit();
      });
    }
  }

  manageAdvtDates(advt) {
    this.dialog.open(UpdatePlublishDatesComponent, {
      data: {
        published_dates: advt['publishing_date'],
        advt_dtl_id: advt['id'],
        manage_mode: true,
        issue_date_mode: false,
        height: '600px',
        width: '600px',
      },
    });
  }

  remove_mag_advt(advt) {
    this.service.updateAdvtMagStatus(advt['id'], 'N').subscribe((data) => {
      // console.log(data);
      this.getAdvtMagDetails();
      window.alert('Status Change!');
    });
  } //remove Magazine advt

  removeadmin(id) {
    this.service.makesuperAdmin(id, 'N').subscribe((a) => {
      this.service.getalladmin().subscribe((ad) => {
        this.getalladmins = [];
        for (let index = 0; index < Object.keys(ad).length; index++) {
          if (ad[index]['issuper'] == 'true') {
            this.getalladmins.push({
              id: ad[index]['id'],
              mobile: ad[index]['mobile'],
              email: ad[index]['email'],
              issuper: true,
            });
          } else {
            this.getalladmins.push({
              id: ad[index]['id'],
              mobile: ad[index]['mobile'],
              email: ad[index]['email'],
              issuper: false,
            });
          }
        }
      });
    });
  }

  isvalidAdmin = false;
  getalladmins = [
    {
      id: 1,
      mobile: 7276169890,
      email: '',
      issuper: false,
    },
  ];
  validateAdmin(event) {
    const value = event.target.value;
    this.service.getvalidAdmin(parseInt(value)).subscribe((admobile) => {
      if (Object.keys(admobile).length > 0) {
        this.isvalidAdmin = true;
      } else {
        this.isvalidAdmin = false;
      }

      if (Object.keys(admobile).length > 0) {
        this.service.getalladmin().subscribe((ad) => {
          this.getalladmins = [];
          for (let index = 0; index < Object.keys(ad).length; index++) {
            if (ad[index]['issuper'] == 'true') {
              this.getalladmins.push({
                id: ad[index]['id'],
                mobile: ad[index]['mobile'],
                email: ad[index]['email'],
                issuper: true,
              });
            } else {
              this.getalladmins.push({
                id: ad[index]['id'],
                mobile: ad[index]['mobile'],
                email: ad[index]['email'],
                issuper: false,
              });
            }
          }
        });
      }
    });
  }

  save_state() {
    this.service
      .InsertNewState(this.StateMaster.value['State_name'], 'IND', 1)
      .subscribe((data) => {
        window.alert('State has been insert');
        this.service.getstates(1).subscribe((data) => {
          // console.log(data);
          this.states = data;
        });
      });
  }

  save_city() {
    if (this.state_id == 0) {
      window.alert('Please select State !');
    } else {
      this.service
        .InsertNewCity(
          this.CityMaster.value['city_name'],
          this.CityMaster.value['prant_name'],
          this.state_id
        )
        .subscribe((data) => {
          // console.log(data);
          window.alert('District added successfully..');
          this.CityMaster.reset();
          this.getChangeState(this.changeState_id);
        });
    }
  }

  save_prant() {
    console.log(this.prantMaster.value['prant_name']);
    this.service
      .insertPrantData(this.prantMaster.value['prant_name'])
      .subscribe((res) => {
        if (res['success']) {
          alert('Prant name added successfully');
          this.getAllPrantData();
        }
      });
  }

  getAllPrantData() {
    this.service.getAllPrantData().subscribe((res) => {
      // console.log(res);
      this.prantData = res;
    });
  }

  updatePrant(data) {
    console.log(data);
    let result = this.dialog.open(UpdatePrantComponent, {
      data: {
        prant: data,
      },
      width: '500px',
      height: '500px',
      maxWidth: '100%',
    });

    result.afterClosed().subscribe((res) => {
      if (res['res'] == 'close') {
        this.getAllPrantData();
      }
    });
  }

  removePrant(id: number) {
    if (window.confirm('Are you sure! This operation is not reversable?')) {
      this.service.removePrant(id).subscribe((res) => {
        if (res['success']) {
          alert(res['message']);
          this.getAllPrantData();
        }
      });
    }
  }

  save_taluka() {
    if (this.district_id == 0) {
      window.alert('Please select District!');
    } else {
      this.service
        .InsertNewTaluka(
          this.TalukaMaster.value['taluka_name'],
          this.TalukaMaster.value['pincode'],
          this.district_id
        )
        .subscribe((data) => {
          // console.log(data);
          window.alert('Taluka added successfully..');
          this.TalukaMaster.reset();
          this.getChangeDistrict(this.changeDistrict_id);
        });
    }
  }

  getAllCities;
  changeState_id;
  getChangeState(event) {
    
    // console.log(parseInt(event.value));
    this.changeState_id = event;
    this.service.getCity(parseInt(event.value)).subscribe((data) => {
      this.getAllCities = data;
      this.city_count = this.getAllCities.length;
    });
  }

  getAllTalukas;
  changeDistrict_id;
  getChangeDistrict(event) {
    // console.log(parseInt(event.value));
    this.changeDistrict_id = event;
    this.service.getTaluka([(event.value)]).subscribe((data) => {
      this.getAllTalukas = data;
      this.taluka_count = this.getAllTalukas.length;
    });
  }

  updateState(data) {
    let result = this.dialog.open(UpdateStateMasterComponent, {
      data: {
        state: data,
      },
      width: '500px',
      height: '500px',
      maxWidth: '100%',
    });

    result.afterClosed().subscribe((result) => {
      if (result['res'] == 'close') {
        this.ngOnInit();
      }
    });
  }

  removeState(state_id) {
    window.alert(
      'This is hard delete operation and will be unrecoverable. This may cause your ' +
        'application behave differently.'
    );
    if (
      window.confirm(
        'Do you really want to remove the State and its corresponding cities ?'
      )
    ) {
      this.service.removeStateandCity(parseInt(state_id)).subscribe((data) => {
        this.service.getstates(1).subscribe((data) => {
          console.log(data);
          this.states = data;
        });
      });
    }
  }
  removecountry(id){
    this.service.getstates(id).subscribe((data) => {
      console.log(data);
      this.states = data;
    });
  }

  updateDistrict(data) {
    let result = this.dialog.open(UpdateDistrictMasterComponent, {
      data: {
        district: data,
      },
      width: '500px',
      height: '500px',
      maxWidth: '100%',
    });

    result.afterClosed().subscribe((res) => {
      if (res['res'] == 'close') {
        this.getChangeState(this.changeState_id);
      }
    });
  }

  removeCity(id) {
    window.alert(
      'This is hard delete operation and will be unrecoverable. This may cause your ' +
        'application behave differently.'
    );
    if (window.confirm('Do you really want to remove City ?')) {
      this.service.removeCity(parseInt(id)).subscribe((data) => {
        this.getChangeState(this.changeState_id);
      });
    }
  }

  updateTaluka(data) {
    let result = this.dialog.open(UpdateTalukaMasterComponent, {
      data: {
        taluka: data,
      },
      width: '500px',
      height: '500px',
      maxWidth: '100%',
    });

    result.afterClosed().subscribe((res) => {
      if (res['res'] == 'close') {
        // this.getChangeState(this.changeState_id);
        this.getChangeDistrict(this.changeDistrict_id);
      }
    });
  }

  removeTaluka(id) {
    window.alert(
      'This is hard delete operation and will be unrecoverable. This may cause your ' +
        'application behave differently.'
    );
    if (window.confirm('Do you really want to remove Taluka ?')) {
      this.service.removeTaluka(parseInt(id)).subscribe((data) => {
        this.getChangeDistrict(this.changeDistrict_id);
      });
    }
  }

  state_id = 0;
  getState(event) {
    // console.log(event);
    console.log(event.value);
    this.state_id = event.value;
    this.selectedState = this.states['state_id'];
    let state = this.states.find((a) => a.state_name == event.value);
    // console.log();
    if (state != undefined) {
      // if (
      //   state.state_name != 'Maharashtra' ||
      //   state.state_name != 'महाराष्ट्र'
      // ) {
      //   this.showigst = true;
      // } else {
      //   this.showigst = false;
      // }
      this.service
        .getDistrict(parseInt(state['state_id']))
        .subscribe((data) => {
          this.cities = data;
          this.selectedCity = this.cities['district_name'];
          // console.log(this.cities);
        });
    } else {
    }
  } //get state
  // getState(event) {
  //   this.state_id = parseInt(event.value);
  // }

  district_id = 0;
  getDistricts(event) {
    this.selectedCity = this.cities['district_id'];
    let district = this.cities.find((a) => a.district_name == event.value);
    let dist=[]
    dist.push(district['district_id'])
    if (district != undefined) {
      this.service.getTaluka(dist).subscribe((data) => {
        this.talukas = data;
        this.selectedTaluka = this.talukas['taluka_name'];
      });
    }
  }
  getDistrict(event) {
    
    this.district_id = parseInt(event.value);
    this.getDistricts(event)
  }

  changeMagPrice() {
    // console.log(
    //   this.magpriceupdate.value['magedition'],
    //   this.magpriceupdate.value['magname'],
    //   this.magpriceupdate.value['magyear']
    // );
    if (parseInt(this.magpriceupdate.value['magamount']) <= 0) {
      window.alert('Please enter valid amount');
    } else {
      let magprintamount = 0;
      let magdigitalamount = 0;
      let magbothamount = 0;
      if (this.magpriceupdate.value['magedition'] == 'print') {
        magprintamount = this.magpriceupdate.value['magamount'];
      } else if (this.magpriceupdate.value['magedition'] == 'digital') {
        magdigitalamount = this.magpriceupdate.value['magamount'];
      } else if (this.magpriceupdate.value['magedition'] == 'printdigital') {
        magbothamount = this.magpriceupdate.value['magamount'];
      }

      this.service
        .changeMagPrice(
          this.magpriceupdate.value['magname'],
          this.magpriceupdate.value['magedition'],
          magprintamount,
          magdigitalamount,
          magbothamount,
          parseInt(this.magpriceupdate.value['magyear'])
        )
        .subscribe((res) => {
          // console.log(res);
          window.alert(
            'Price has been updated! Please try to refresh the page for updated values.'
          );
          this.magpriceupdate.reset();
        });
    }
  }

  AddNewMagazine() {
    if (
      this.magform.value['magyear'] == undefined ||
      this.magform.value['magyear'] < 0
    ) {
      this.magform.get('magyear').setValue(1);
    }
    let magprintamount = 0;
    let magdigitalamount = 0;
    let magbothamount = 0;
    if (this.magform.value['magedition'] == 'print') {
      magprintamount = this.magform.value['magamount'];
    } else if (this.magform.value['magedition'] == 'digital') {
      magdigitalamount = this.magform.value['magamount'];
    } else if (this.magform.value['magedition'] == 'printdigital') {
      magbothamount = this.magform.value['magamount'];
    }

    // console.log(
    //   this.magform.value['magname'],
    //   this.magform.value['magedition'],
    //   this.magform.value['magamount'],
    //   this.magform.value['magyear'],
    //   this.magform.value['magdesc'],
    //   this.magform.value['magapp'],
    //   this.magform.value['magfullname']
    // );

    this.service
      .AddNewMag(
        this.magform.value['magname'],
        this.magform.value['magedition'],
        magprintamount,
        magdigitalamount,
        magbothamount,
        this.magform.value['magyear'],
        this.magform.value['magdesc'],
        this.magform.value['magapp'],
        this.magform.value['magfullname']
      )
      .subscribe((a) => {
        window.alert('Magazine Added Successfully.');
        //change for OEM
        this.service.getMag().subscribe((res) => {
          this.magform.reset();
          //console.log(res['name'])
          this.mag = [];

          for (let i = 0; i < Object.keys(res).length; i++) {
            this.mag.push({
              name: res[i]['name'],
              print: res[i]['print'],
              digital: res[i]['digital'],
              printdigital: res[i]['printdigital'],
              year: parseInt(res[i]['year']),
            });
          }
        });
      });
  }

  removeAdmin(id) {
    this.service.removeAdmin(parseInt(id)).subscribe((rmv) => {
      //console.log(rmv);
      this.service.getalladmin().subscribe((ad) => {
        this.getalladmins = [];
        for (let index = 0; index < Object.keys(ad).length; index++) {
          if (ad[index]['issuper'] == 'true') {
            this.getalladmins.push({
              id: ad[index]['id'],
              mobile: ad[index]['mobile'],
              email: ad[index]['email'],
              issuper: true,
            });
          } else {
            this.getalladmins.push({
              id: ad[index]['id'],
              mobile: ad[index]['mobile'],
              email: ad[index]['email'],
              issuper: false,
            });
          }
        }
      });
    });
  }

  magedition;
  getmagEdition(event) {
    this.magedition = event.value;
  }
  magnames;
  getmagdetails(event) {
    this.magnames = event.value;
  }

  disableOtherFields = true;
  getMagazine;
  subid;
  cancelCheckbox = [];
  changedMobileNumber;
  nochangenumber = false;
  getValidateEvent(event) {
    this.changedMobileNumber = event.target.value;
  }

  addNewAdmin() {
    let mobile = parseInt(this.addNewAdminForm.value['adminMobile']);
    this.addNewAdminForm.patchValue({ adminMobile: mobile });
    var encrypted = this.encr.set(
      '123456$#@$^@1ERF',
      this.addNewAdminForm.value['adminPassword']
    );
    this.addNewAdminForm.patchValue({ adminPassword: encrypted });

    let sixdigitsrandom = Math.floor(100000 + Math.random() * 900000);
    let msg =
      'Dear Subscriber, Your Verification Code is ' +
      sixdigitsrandom +
      ', Regards OEM Ekvira Media Pvt. Ltd.';
    let template = 'Brahman20';
    this.service.sendSMS(mobile, msg, template).subscribe((a) => {});
    this.dialog.open(AddNewAdminDialogComponent, {
      data: {
        otp: sixdigitsrandom,
        mobileNumber: mobile,
        adPassword: encrypted,
        email: this.addNewAdminForm.get('adminEmail').value,
        height: '600px',
        width: '600px',
      },
    });

    this.addNewAdminForm.reset();
  }

  open_cancel_subsciber_dialog(value) {
    let mobile = parseInt(value.mobileNumber);
    var sixdigitsrandom = Math.floor(100000 + Math.random() * 900000);
    // console.log(value);
    let msg =
      'Dear Subscriber, Your Verification Code is ' +
      sixdigitsrandom +
      ', Regards OEM Ekvira Media Pvt. Ltd.';
    let template = 'Brahman20';

    this.service
      .sendSMS(parseInt(sessionStorage.getItem('mobile')), msg, template)
      .subscribe((a) => {});

    this.dialog.open(CancelSubDialogComponent, {
      data: {
        message: sixdigitsrandom,
        magdata: value,
        height: '600px',
        width: '600px',
      },
    });
  }

  save() {
    this.service
      .insertTitle(this.MasterTable.value['title'])
      .subscribe((data) => {
        // console.log(data);
        this.service.getAlltitle().subscribe((data) => {
          // console.log(data);
          this.titles = data;
          this.MasterTable.reset();
        });
      });
  }

  removeTitle(title) {
    if (window.confirm('Do you really want to remove the title?')) {
      this.service.removetitle(title).subscribe((data) => {
        this.service.getAlltitle().subscribe((data) => {
          // console.log(data);
          this.titles = data;
        });
      });
    }
  }

  save_occupation() {
    this.service
      .insertOccupations(
        this.OccupationMaster.value['occupation'],
        this.OccupationMaster.value['occupation_value'].toLowerCase()
      )
      .subscribe((data) => {
        // console.log(data);
        this.service.getOccupation().subscribe((data) => {
          // console.log(data);
          this.occupations = data;
        });
      });
  }

  addRepresentativeForm() {
    let addrep = {
      issuesellername: this.addform.get('issuesellername').value,
      address1: this.addform.get('address1').value,
      pincode: this.addform.get('pincode').value,
      country: this.addform.get('country').value,
      state: this.addform.get('state').value,
      taluka: this.addform.get('taluka').value,
      district: this.addform.get('district').value,
      mnumber: this.addform.get('mnumber').value,
      email: this.addform.get('email').value,
      landline_no: this.addform.get('landline_no').value ? null : '',
      dob: this.addform.get('dob')?.value,
      gstno: this.addform.get('gstno').value,
      panno: this.addform.get('panno').value,
      depositamt: this.addform.get('depositamt').value,
      option: this.addform.get('option').value,
      sbhet: this.addform.get('sampremebhet').value,
      distype: this.addform.get('distype').value,
      issue_date: this.addform.get('issuedate').value,
      issue_name: this.addform.get('issuename').value,
    };

    this.service.addIssueSellerEntry(addrep).subscribe(
      (data) => {
        alert('Representative added successfully...');
        this.addform.reset();
        this.ngOnInit();
      },
      (error) => {
        alert('Representative Already Present ...');
      }
    );

    // console.log('addrep', addrep);
  }

  updaterep() {
    let addrep = {
      id: this.repId,
      issuesellername: this.addform.get('issuesellername').value,
      address1: this.addform.get('address1').value,
      pincode: this.addform.get('pincode').value,
      country: this.addform.get('country').value,
      state: this.addform.get('state').value,
      taluka: this.addform.get('taluka').value,
      district: this.addform.get('district').value,
      mnumber: this.addform.get('mnumber').value,
      email: this.addform.get('email').value,
      landline_no: this.addform.get('landline_no').value ? null : '',
      dob: this.addform.get('dob').value,
      gstno: this.addform.get('gstno').value,
      panno: this.addform.get('panno').value,
      depositamt: this.addform.get('depositamt').value,
      option: this.addform.get('option').value,
      sbhet: this.addform.get('sampremebhet').value,
      distype: this.addform.get('distype').value,
    };

    this.service.updateIssueSellerEntry(addrep).subscribe((data) => {
      alert('Representative Updated Successfully...');
      this.ngOnInit();
      this.addform.reset()
    });
  }

  addofficerepform() {
    let addofficerep = {
      officerep: this.officerepform.get('issuesellername').value,
      address1: this.officerepform.get('address1').value,
      pincode: this.officerepform.get('pincode').value,
      country: this.officerepform.get('country').value,
      state: this.officerepform.get('state').value,
      taluka: this.officerepform.get('taluka').value,
      district: this.officerepform.get('district').value,
      mnumber: this.officerepform.get('mnumber').value,
      email: this.officerepform.get('email').value,
      landline_no: this.officerepform.get('landline_no').value ? null : '',
      dob: this.officerepform.get('dob')?.value,
      gstno: this.officerepform.get('gstno').value,
      panno: this.officerepform.get('panno').value,
    };
    // console.log(addofficerep);

    this.service.addOfficeRep(addofficerep).subscribe((data) => {
      // console.log('addOfficeRep', data);
      window.alert('Office Representative Added Successfully....');
      this.officerepform.reset();
      this.ngOnInit();
    });
  }

  addassignrep() {
    let dataofrep = {
      rep_id: this.rep_id,
      book_id: this.book_id,
      sub_id: this.sub_id,
      advt_id: this.advt_id,
      issue_id: this.issue_id,
    };

    setTimeout(() => {
      this.service.adddeptwiseOfficeRep(dataofrep).subscribe((data) => {
        // console.log('adddeptwiseOfficeRep', data);
        window.alert('Department wise Representative assigned successfully...');
        setTimeout(() => {
          this.repassignform.reset();
        }, 2000);
      });
      this.ngOnInit();
      window.alert('Representative already available...');
    }, 2000);
    this.ngOnInit();
  }

  updateassignrep() {
    let dataofrep = {
      id: this.rep_off_assign_id,
      book_id: this.book_id,
      sub_id: this.sub_id,
      advt_id: this.advt_id,
      issue_id: this.issue_id,
    };

    // console.log(dataofrep);
    this.service.updateDeptwiseOffRepAssign(dataofrep).subscribe((data) => {
      window.alert('Department wise Representative updated successfully...');
      // location.reload();
      this.repassignform.reset();
      this.autocomfunction();
    });
    this.ngOnInit();
  }

  getPincode1(event) {
    if (this.addform.get('pincode').valid) {
      // console.log(this.addform.get('pincode').value);
      this.service
        .getPincodeData(parseInt(this.addform.get('pincode').value))
        .subscribe((data) => {
          // console.log('pincode data', data);
          this.getCountry({ value: data[0]['country_name'] });
          setTimeout(() => {
            this.getState({ value: data[0]['state_name'] });
          }, 100);
          setTimeout(() => {
            this.getDistricts({ value: data[0]['district_name'] });
          }, 500);
          this.addform.get('country').setValue(data[0]['country_name']);
          this.addform.get('state').setValue(data[0]['state_name']);
          this.addform.get('district').setValue(data[0]['district_name']);
          setTimeout(() => {
            this.addform.get('taluka').setValue(data[0]['taluka_name']);
          }, 500);
          // this.selectedCity = data[0]['district_name'];
          // this.selectedState = data[0]['state_name'];
          // this.selectedTaluka = data[0]['taluka_name'];
        });
    }
  } //getPincode

  getPincode2(event) {
    if (this.officerepform.get('pincode').valid) {
      // console.log(this.officerepform.get('pincode').value);
      this.service
        .getPincodeData(parseInt(this.officerepform.get('pincode').value))
        .subscribe((data) => {
          // console.log('pincode data', data);
          this.getCountry({ value: data[0]['country_name'] });
          setTimeout(() => {
            this.getState({ value: data[0]['state_name'] });
          }, 100);
          setTimeout(() => {
            this.getDistricts({ value: data[0]['district_name'] });
          }, 500);
          this.officerepform.get('country').setValue(data[0]['country_name']);
          this.officerepform.get('state').setValue(data[0]['state_name']);
          this.officerepform.get('district').setValue(data[0]['district_name']);
          setTimeout(() => {
            this.officerepform.get('taluka').setValue(data[0]['taluka_name']);
          }, 500);
        });
    }
  } //getPincode

  getCountry(event) {
    // console.log(event);
    // console.log(this.countries);
    let country = this.countries.find((a) => a.country_name == event.value);
    if (country != undefined) {
      this.service.getState(country['country_code']).subscribe((data) => {
        this.states = data;
        // console.log(this.states);
      });
    }
  }

  cancelRepresentativeForm() {
    let mobile = this.form2.get('mobileNumber').value;
    var sixdigitsrandom = Math.floor(100000 + Math.random() * 900000);
    let msg =
      'Dear Subscriber, Your Verification Code is ' +
      sixdigitsrandom +
      ', Regards OEM Ekvira Media Pvt. Ltd.';
    let template = 'Brahman20';
    this.service.sendSMS(mobile, msg, template).subscribe((a) => {});
    this.dialog.open(CancelRepDialogComponent, {
      data: {
        message: sixdigitsrandom,
        mobile: mobile,
        height: '600px',
        width: '600px',
      },
    });
    this.form2.reset();
  }

  magazineData = [];
  addmags() {
    if (
      this.NewMagazineForm.get('mag_name').value != null &&
      this.NewMagazineForm.get('ig_cat').value != null
    ) {
      let mag_name = this.NewMagazineForm.get('mag_name').value;
      let ig_cat = this.NewMagazineForm.get('ig_cat').value;

      this.magazineData.push({
        mag_name: mag_name,
        ig_cat: ig_cat,
      });
      // this.NewMagazineForm.get('mag_name').reset();
      this.NewMagazineForm.get('ig_cat').reset();
    }
  }

  removeItem(item, i) {
    if (window.confirm('Do you really want to remove ?')) {
      if (item['id']) {
        // console.log('remove from DB');
        this.service
          .updateMagazineIssueGranthCategorytoInactive(item['id'])
          .subscribe((data) => {
            window.alert('Data Removed Successfully...');
            location.reload();
          });
      } else {
        this.magazineData.splice(i, 1);
      }
    }
  }

  addNewMagazine() {
    // console.log(this.magazineData.length);
    if (this.magazineData.length != 0) {
      for (
        let index = 0;
        index < Object.keys(this.magazineData).length;
        index++
      ) {
        if (this.magazineData[index]['id']) {
          this.magazineData.splice(index, 1);
        }

        // console.log(this.magazineData);

        let data = {
          mag_name: this.magazineData[index]['mag_name'],
          ig_cat: this.magazineData[index]['ig_cat'],
        };

        this.service.insertMagazineIssueGranthCate(data).subscribe((res) => {
          // console.log(res);
        });
      }

      setTimeout(() => {
        alert('Magazine Added Successfully...');
        this.magazineData = [];
      }, 1000);
    }
  }

  getMagazineIssueGranthData() {
    this.service.getMagazineIssueGranthCategoryData().subscribe((data) => {
      // console.log(data);
      this.MagazineIssueGranthData = data;
      for (
        let index = 0;
        index < Object.keys(this.MagazineIssueGranthData).length;
        index++
      ) {
        this.MagazineIssueGranthMag_names.push({
          mag_name: this.MagazineIssueGranthData[index]['mag_name'],
        });
      }

      this.MagazineIssueGranthMag_names = this.uniqueid(
        this.MagazineIssueGranthMag_names,
        (a) => a['mag_name']
      );
    });
  }

  uniqueid(data, key) {
    return [...new Map(data.map((x) => [key(x), x])).values()];
  }

  selected_magName(event, option) {
    if (event.isUserInput) {
      let data = this.MagazineIssueGranthData.filter(
        (a) => a['mag_name'] == option['mag_name']
      );
      // console.log(data);
      this.magazineData = data;
    }
  }

  updateItem(item, i) {
    // console.log(item);
    let result = this.dialog.open(UpdateMagazineNameAndCategoryComponent, {
      data: {
        val: item,
      },
      width: '500px',
      height: '400px',
      maxWidth: '100%',
    });

    // console.log(this.magazineData[i]);

    result.afterClosed().subscribe((res) => {
      // console.log(res);
      if (res['res'] != 'close') {
        this.magazineData[i]['mag_name'] = res['res']['mag_name'];
        this.magazineData[i]['ig_cat'] = res['res']['ig_cat'];
      } else {
        window.alert('Data Uploaded Successfully...');
        location.reload();
      }
    });
  }

  updateAdvtSize(item) {
    // console.log(item);

    let result = this.dialog.open(UpdateAdvtSizesComponent, {
      data: {
        val: item,
      },
      width: '500px',
      height: '400px',
      maxWidth: '100%',
    });

    result.afterClosed().subscribe((res) => {
      if (res['res'] == 'close') {
        window.alert('Data Updated Successfully...');
        this.ngOnInit();
      }
    });
  }

  removeAdvtSize(item) {
    if (window.confirm('Do you really want to remove ?')) {
      this.service.updateAdvtSizeInactive(item['id']).subscribe((data) => {
        this.ngOnInit();
      });
    }
  }

  total_pages = 0;
  calcPages(event) {
    // console.log(event.target.value);
    this.total_pages += parseInt(event.target.value);
    this.IssueDates.get('totalpages').setValue(this.total_pages);
  }

  getIssues(e) {
    
    // console.log(e);
    this.issueName = e.value;
    // console.log('jgfdfgh', this.issueName);
    this.service.getAdvtSizeByCategory(e.value).subscribe((data) => {
      this.advtsizebyCategory = data;
    });
    // console.log('hi', e.option.group.label);
   
    // console.log(
    //   'advt_mag_name_details',
    //   this.advt_mag_name_details.filter((a) => a.issue_name == this.issueName)
    // );
    // this.getFilterMagPublishDates = this.advt_mag_name_details.filter(
    //   (a) => a.issue_name == this.issueName
    // )[0]['publishing_date'];
    // console.log(this.getFilterMagPublishDates);
    this.service.getAdvtDatesAndNames(e.value).subscribe((data) => {
      this.getMagIssueDatesandNames = data;
      // console.log('Issue Dates and Names:', this.getMagIssueDatesandNames);
      for (
        let index = 0;
        index < Object.keys(this.getMagIssueDatesandNames).length;
        index++
      ) {
        this.filterdMagIssueDatesandNames.push({
          value: `${formatDate(
            this.getMagIssueDatesandNames[index]['issueDate'],
            'dd/MM/yyyy',
            'en-IN',
            'GMT+0530'
          )},${this.getMagIssueDatesandNames[index]['issueName']},${formatDate(
            this.getMagIssueDatesandNames[index]['publishedDate'],
            'dd/MM/yyyy',
            'en-IN',
            'GMT+0530'
          )}`,
          data: `${formatDate(
            this.getMagIssueDatesandNames[index]['issueDate'],
            'dd/MM/yyyy',
            'en-IN',
            'GMT+0530'
          )} ${this.getMagIssueDatesandNames[index]['issueName']} ${formatDate(
            this.getMagIssueDatesandNames[index]['publishedDate'],
            'dd/MM/yyyy',
            'en-IN',
            'GMT+0530'
          )}`,
        });
      }
      // console.log(
      //   'filterdMagIssueDatesandNames:',
      //   this.filterdMagIssueDatesandNames
      // );
    });
  }

  getDates;
  stringdates;
  getAdvtDates(e) {
    
    
    // console.log(e.value)


    if(e.value[0]=='ALL'){
      this.getDates =e.value;;
      return
    }
    this.getDates =e.value;;
    // this.getDates = this.getDates.filter((a) => a != 'all');
    // console.log('getDates', this.getDates);
    this.stringdates = this.getDates.toString();
    this.issueDates = this.stringdates;
    // console.log('StringDates', this.stringdates);
    // this.startDate_registrationReport = '';
    // this.enddate_registrationReport = '';
    // this.getAnkSerialNo(this.issueDates);
  }

  allSelected2 = false;
  @ViewChild('mySel2') skillSel2: MatSelect;
  toggleAllSelection2() {
    this.allSelected2 = !this.allSelected2; // to control select-unselect
    // console.log(this.skillSel2.options);
    if (this.allSelected2) {
      this.skillSel2.options.forEach((item: MatOption) => item.select());
    } else {
      this.skillSel2.options.forEach((item: MatOption) => {
        item.deselect();
      });
    }
    this.skillSel2.close();
  }
  adddates(){
    let issueseller = {
      issueSellerId : this.repId,
      start_date : this.startDate_contract,
      end_date : this.enddate_contract
    }

    this.service.addstart_end_dates_to_rep(issueseller).subscribe(
      (data) => {
        alert('Representative dates added successfully...');
        // this.addform.reset();
        this.ngOnInit();
      },
      (error) => {
        alert('Representative Already Present ...');
      }
    );


  }

  addsbhet(){

    this.issuedate = this.sbhet.get('date').value

    this.formattedDate = formatDate(this.issuedate, 'yyyy-MM-dd', 'en-IN');

    
    let issueseller = {
      issueSellerId: this.sbhet.get('issueseller').value,
      sbhet: this.sbhet.get('sbhet').value,
      // issue_date: this.sbhet.get('issuedate').value,
      issue_name: this.sbhet.get('issuename').value,
      issue_date : this.formattedDate,
    };

    this.service.addIssueSellerSaprembhet(issueseller).subscribe(
      (data) => {
        alert('Representative added successfully...');
        this.addform.reset();
        this.ngOnInit();
      },
      (error) => {
        alert('Representative Already Present ...');
      }
    );


    console.log(this.sbhet.value)
  }

  selected_repname1: boolean = false;
  repId1 = 0;
  selected_rep_name1(event, option) {
    

    // console.log(event.isUserInput);
    if (event.isUserInput) {
      let data = this.issue_seller_name.filter(
        (a) => a['name'] == option['name']
      );
      console.log('data', data);
      this.sbhet.get('issueseller').setValue(data[0]['id']);

      this.selected_repname1 = true;
      this.repId = data[0]['id'];
      this.addform.get('issuesellername').setValue(data[0]['issueSellerName']);
      this.addform.get('address1').setValue(data[0]['address']);
      this.addform.get('pincode').setValue(data[0]['pincode']);
      this.addform.get('mnumber').setValue(data[0]['mobile_no']);
      this.addform.get('email').setValue(data[0]['email']);
      this.addform.get('landline_no').setValue(data[0]['landline_no']);
      this.addform
        .get('dob')
        .setValue(
          formatDate(data[0]['dateofbirth'], 'yyyy-MM-dd', 'en-IN', 'GMT+0530')
        );
      // this.addform.get('gstno').setValue(data[0]['gstno']);
      this.addform.get('panno').setValue(data[0]['panno']);
      this.addform.get('depositamt').setValue(data[0]['deposit_amt']);
      this.addform.get('option').setValue(data[0]['issueSellerType']);
      this.addform.get('sampremebhet').setValue(data[0]['s_bhet']);
      this.addform.get('distype').setValue(data[0]['distype']);
      this.getCountry({ value: data[0]['country'] });
      setTimeout(() => {
        this.getState({ value: data[0]['state'] });
      }, 100);
      setTimeout(() => {
        this.getDistricts({ value: data[0]['district'] });
      }, 500);
      this.addform.get('country').setValue(data[0]['country']);
      this.addform.get('state').setValue(data[0]['state']);
      this.addform.get('district').setValue(data[0]['district']);
      setTimeout(() => {
        this.addform.get('taluka').setValue(data[0]['taluka']);
      }, 500);
      this.addform.get('country').setValue(data[0]['country']);
      this.addform.get('state').setValue(data[0]['state']);
      this.addform.get('district').setValue(data[0]['district']);
      this.addform.get('taluka').setValue(data[0]['taluka']);
      this.selectedCity = data[0]['district'];
    }
  }

  deletesbhet(item){
    let issueseller = {
      issueSellerId: item.id,
    };

    this.service.deleteIssueSellerSaprembhet(issueseller).subscribe(
      (data) => {
        alert('Dates delete successfully...');
        this.startenddate.reset();
        this.ngOnInit();
      }
    );
  }
  deletesbhet1(item){
    let issueseller = {
      id: item.id,
    };

    this.service.deleteIssueStartEndDate(issueseller).subscribe(
      (data) => {
        alert('Saprem Bhet delete successfully...');
        this.addform.reset();
        this.ngOnInit();
      }
    );
  }

  deletesbhetlist(item){
    let issueseller = {
      issueSellerId: item.id,
    };

    this.service.deleteIssueSellerSaprembhet(issueseller).subscribe(
      (data) => {
        alert('Saprem Bhet list delete successfully...');
        this.addform.reset();
        this.ngOnInit();
      }
    );
  }

  deleteoldlist(item){
    let issueseller = {
      issueSellerId: item.id,
    };

    this.service.deleteolddatalist(issueseller).subscribe(
      (data) => {
        alert('Saprem Bhet list delete successfully...');
        // this.addform.reset();
        // this.ngOnInit();
      }
    );
  }
  obj:any
  update_sapremBhetdata:any
  updateSbhetData(item){
    this.update_sapremBhetdata = item
    this.updatesbhet = true;
    
    this.sbhet.get('id').setValue(item['issueSellerName']);
    this.sbhet.get('issuename').setValue(item['issueName']);
    this.sbhet.get('sbhet').setValue(item['s_bhet']); 
    this.sbhet.get('date').setValue(item['start_date'])
    this.getIssues1(item['issueName'],item['issueDate'])
  }

  update_sapremBhetdata1:any
  updateDates(item){
    this.update_sapremBhetdata1 = item
    this.updatesbhet = true;
    
    this.startenddate.get('id').setValue(item['issueSellerName']);
    this.startenddate.get('start').setValue(item['start_date']);
    // this.sbhet.get('sbhet').setValue(item['s_bhet']); 
    this.startenddate.get('end').setValue(item['end_date'])
    // this.getIssues1(item['issueName'],item['issueDate'])
  }

  getIssues1(e,issuedate) {
    
    // console.log(e);
    this.issueName = e;
    // console.log('jgfdfgh', this.issueName);
    this.service.getAdvtSizeByCategory(e).subscribe((data) => {
      this.advtsizebyCategory = data;
    });
    // console.log('hi', e.option.group.label);
   
    // console.log(
    //   'advt_mag_name_details',
    //   this.advt_mag_name_details.filter((a) => a.issue_name == this.issueName)
    // );
    // this.getFilterMagPublishDates = this.advt_mag_name_details.filter(
    //   (a) => a.issue_name == this.issueName
    // )[0]['publishing_date'];
    // console.log(this.getFilterMagPublishDates);
    this.service.getAdvtDatesAndNames(e).subscribe((data) => {
      this.getMagIssueDatesandNames = data;

      this.obj = this.getMagIssueDatesandNames.filter((x:any) => x.issueDate ===issuedate )[0];
      this.sbhet.get('issuedate').setValue(this.obj.id);
  
      // console.log('Issue Dates and Names:', this.getMagIssueDatesandNames);
      for (
        let index = 0;
        index < Object.keys(this.getMagIssueDatesandNames).length;
        index++
      ) {

        this.filterdMagIssueDatesandNames.push({ id :  this.getMagIssueDatesandNames[index]['issueDate']})
        this.filterdMagIssueDatesandNames.push({
          value: `${formatDate(
            this.getMagIssueDatesandNames[index]['issueDate'],
            'dd/MM/yyyy',
            'en-IN',
            'GMT+0530'
          )},${this.getMagIssueDatesandNames[index]['issueName']},${formatDate(
            this.getMagIssueDatesandNames[index]['publishedDate'],
            'dd/MM/yyyy',
            'en-IN',
            'GMT+0530'
          )}`,
          data: `${formatDate(
            this.getMagIssueDatesandNames[index]['issueDate'],
            'dd/MM/yyyy',
            'en-IN',
            'GMT+0530'
          )} ${this.getMagIssueDatesandNames[index]['issueName']} ${formatDate(
            this.getMagIssueDatesandNames[index]['publishedDate'],
            'dd/MM/yyyy',
            'en-IN',
            'GMT+0530'
          )}`,
        });
      }
      console.log(
        'filterdMagIssueDatesandNames:',
        this.filterdMagIssueDatesandNames
      );
    });
  }

  updatedates(){
    debugger
    let issueseller = {
      id: this.update_sapremBhetdata1.id,
      start_date: this.startDate_contract,
      end_date: this.enddate_contract,
    };

    this.service.updatesdates(issueseller).subscribe(
      (data) => {
        alert('Dates Updated  successfully...');
        this.addform.reset();
        this.ngOnInit();
      }
    );
  }
  updatesbhetdata(){
    debugger
    let issueseller = {
      issueSellerId: this.update_sapremBhetdata.id,
      sbhet: this.sbhet.get('sbhet').value,
      issue_date: this.sbhet.value.date,
      issue_name: this.sbhet.get('issuename').value,
    };

    this.service.updatesbhetdata(issueseller).subscribe(
      (data) => {
        alert('Saprem Bhet Updated  successfully...');
        this.addform.reset();
        this.ngOnInit();
      }
    );
  }
  assignoffwithtype(){
    
    this.rep_type = this.repassigntype.value.type

    this.service.getListofassignrep(this.rep_type).subscribe((data) => {
      this.getdatabydep = data;
      console.log(this.getdatabydep)
    });
    
  }

  addcountryfun(){
    const data:any = {
      "country_name":this.addcountry.value.country_name,
      "country_code":this.addcountry.value.country_code,
    }

    this.service.addcountry(data).subscribe(
      (data) => {
        alert('Country Added Successfully...');
        // this.addform.reset();
        // this.ngOnInit();
      }
    );
  }

  addseniority(){
    const data:any = {
      "off_rep_id":this.addseni.value.off_repname,
      "dep_id":this.addseni.value.dept,
      "seniority":this.addseni.value.seniority,
    }

    console.log(data)

    this.service.addseniority(data).subscribe(
      (data) => {
        alert('Seniority Added Successfully...');
        // this.addform.reset();
        this.ngOnInit();
      }
    );
  }

  addlanguage(){
    const data:any = {
      "language":this.addlang.value.language,
    
    }

    console.log(data)

    this.service.addlanguage(data).subscribe(
      (data) => {
        alert('Language Added Successfully...');
        // this.addform.reset();
        this.ngOnInit();
      }
    );
  }

  updatecountryfun(){
    const data:any = {
      "id": this.countryid,
      "country_name":this.addcountry.value.country_name,
      "country_code":this.addcountry.value.country_code,
    }
    debugger;

    this.service.updatecountry(data).subscribe(
      (data) => {
        alert('Country Updated Successfully...');
        // this.addform.reset();
        // this.ngOnInit();
      }
    );
  }

  assignoffwithoffrep(){
    
    this.off_rep = this.repassignoff.value.off_rep

    this.service.getListofassignoff(this.off_rep).subscribe((data) => {
      this.getdatabyoff = data;
      console.log(this.getdatabydep)
    });
    
  }

  onSelectionChange() {
    const selectedType = this.repassigntype.value.type
    const selectedOption = this.getdepwiserepcount1.find(option => option.rep_type === selectedType);
    this.count = selectedOption.count;
  }

  setTabIndex(tabindex:any){
    localStorage.setItem("tab", tabindex)
  }


  onTabClick(event:any){
    console.log(event)
    localStorage.setItem("tab", event.index)
  }
  startDate_contract:any='';
  enddate_contract:any='';
  getChangedDateContractSE() {
    if (
      this.startenddate.get('start').value != null &&
      this.startenddate.get('end').value != null
    ) {
      // console.log(this.contractDetailsForm.value);
      this.startDate_contract = formatDate(
        new Date(this.startenddate.get('start').value),
        'yyyy-MM-dd',
        'en-IN',
        'GMT+0530'
      );
      this.enddate_contract = formatDate(
        new Date(this.startenddate.get('end').value),
        'yyyy-MM-dd',
        'en-IN',
        'GMT+0530'
      );
    }
    console.log( this.startDate_contract ,  this.enddate_contract, this.repId)
    console.log(this.issue_seller_name)
  }

  selected_rep_name2(event: MatOptionSelectionChange, option: any) {
    if (event.isUserInput) {
      const selectedName = option.name;
      const selectedId = option.id;
      
      console.log('Selected Name:', selectedName);
      console.log('Selected ID:', selectedId);
  
      // You can now use the selected ID as needed in your component.
    }
  }

  updatecountry(item){
    this.updatecountry123 = true;
    debugger;
    this.countryid = item.country_id
    this.addcountry.get('country_name').setValue(item['country_name']);
    this.addcountry.get('country_code').setValue(item['country_code']);
  }


  updatecountrysen(item){
    this.updatesenioritybtn = true
    debugger;
    this.off_rep_id = item.id
    this.addseni.get('off_repname').setValue(item['officerep']);
    this.addseni.get('dept').setValue(item['dep_name']);
    this.addseni.get('seniority').setValue(item['seniority']);
  }
  deleteseniority(id){
    this.service.deleteseniority(id).subscribe(
      (data) => {
        alert('Seniority Delete Successfully...');
        // this.addform.reset();
        // this.ngOnInit();
      }
    );
  }
  
  
  updatesenioritybtn:boolean=false;
  updateseniority(){
    const data:any = {
      "id": this.off_rep_id,
      "off_rep_id":this.addseni.value.off_repname,
      "dep_id":this.addseni.value.dept,
      "seniority":this.addseni.value.seniority,

    }
    debugger;

    this.service.updateseniority(data).subscribe(
      (data) => {
        alert('Seniority Updated Successfully...');
        // this.addform.reset();
        // this.ngOnInit();
      }
    );
  }
  

  

}
