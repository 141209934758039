<div class="cancel-subscriber mat-elevation-z4">

  <mat-dialog-actions align="start">
    <div style="margin-left: 3%;margin-top: 2%;"> <b>Cust. Id : </b>{{getSubAllDetails['cust_id']}} </div>
  </mat-dialog-actions>

  <mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>Cancel</button>
  </mat-dialog-actions>

  <form class="form" [formGroup]="form" (ngSubmit)=insertNewMag()>
    <div class="form-row">
      <div class="form-group col">
        <label style="margin-left: 35px; margin-top: 20px;">Customer Mobile</label>
        <input style="margin-left: 35px; margin-right: 45px; width: 70%;" formControlName="ds_custmobile" type="number"
          class="form-control" placeholder=" " (change)="getsubmags($event)">
        <label *ngIf="disableOtherFields && !startLoading"
          style="color: red;font-weight: bold;margin-left: 25px;">Please check
          subscriber is exist or not.</label>
        <label *ngIf="startLoading" style="color: red;font-weight: bold;margin-left: 25px;">Loading...Please
          wait</label>
        <label *ngIf="!disableOtherFields" style="color: green;font-weight: bold;margin-left: 25px;">You are Adding
          Magazine for : {{subscribersName}}</label>
      </div> <!-- form-group end.// -->


      <div class="col form-group">
        <label style="margin-left: 35px;margin-top: 20px;">Magazine Name : </label>
        <select class="form-control" (change)="getmagname($event)"
          style="margin-left: 35px; margin-right: 45px; width: 70%;" placeholder="Select One" formControlName="ds_mag1"
          [disabled]="disableOtherFields" required>
          <option *ngFor="let mags of getMagNames" value="{{mags}}">{{mags}} </option>
        </select>
      </div>
    </div> 

    <div class="form-row" >
      <div class="col form-group">
        <label style="margin-left: 35px; margin-top: 20px;font-weight: bold;">Address :</label>
        
        <mat-form-field style="width: 70%; background-color: white;"  >
          <mat-label>Address</mat-label>
          <textarea readonly style="background-color: white;" matInput placeholder="">{{getSubAllDetails['address']}}  {{getSubAllDetails['taluka']}} {{getSubAllDetails['city']}} {{getSubAllDetails['state']}} </textarea>
        </mat-form-field>
        <!-- <label style="margin-left: 35px; margin-top: 20px;">{{getSubAllDetails['address']}} <br/> {{getSubAllDetails['taluka']}} <br/> {{getSubAllDetails['city']}} <br/> {{getSubAllDetails['state']}} </label> -->
      </div>
      
    </div>
    
    
    <div class="form-row">
      <div class="col form-group">
        <label style="margin-left: 35px; margin-top: 20px;">Magazine Edition :</label>
        <select class="form-control" (change)="getmagedition($event)" style="margin-left: 35px;  width: 70%;"
          placeholder="Select One" formControlName="ds_mag1edition">
          <option value="Print">Print</option>
        </select>
      </div> 

      <div class="col form-group">
        <label style="margin-left: 20px; margin-top: 20px;">Magazine year :</label>
        <select class="form-control" (change)="getmagdetails($event)" style="margin-left: 20px;  width: 70%;"
          placeholder="Select One" formControlName="year">
          <option *ngFor="let item of getMagYears" value="{{item}}">{{item}}</option>
        </select>
      </div> <!-- form-group end.// -->

    </div>

    <div class="form-row"> 
      <div class="form-group col">

        <label style="margin-left: 35px;">Payment Mode: </label>
        <select class="form-control" (change)="getcheque($event)"
          style="margin-left: 35px; margin-right: 45px; width: 70%;" class="form-control" placeholder="Select One"
          formControlName="paymethod" [disabled]="disableOtherFields" required>
          <option value="cash">Cash</option>
          <option value="cheque">Cheque</option>
        </select>
      </div>
      <div class="form-group col">

      
        <label style="margin-left:20px">Representative: </label>
        <select class="form-control" (change)="SelectRepresentative($event)"
          style="margin-left: 20px; width: 70%;" class="form-control" placeholder="Select One"
          formControlName="paymethod" required>
          <option *ngFor="let rep of getAllRepList" value="{{rep['id']}}">{{rep['name']}}</option>
        </select>
  
      </div> <!-- form-group end.// -->
    </div>
    

    <div class="form-group" *ngIf="displayChequqField">
      <label style="margin-left: 35px;">Cheque No.</label>
      <input style="margin-left: 35px; margin-right: 45px; width: 90%;" formControlName="chequeno" type="text"
        class="form-control" placeholder=" ">
    </div> <!-- form-group end.// -->

    <div class="form-group">
      <label style="margin-left: 35px;font-weight: bold;">Total Amount : {{totalmagamount}}</label>
    </div>

    <div class="form-group">
      <button [disabled]="form.invalid" style="margin-left: 200px; width: 50%;" type="submit"
        class="btn btn-danger btn-block">Subscription Renew</button>
    </div> <!-- form-group// -->
  </form>
</div>