import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { DigisubservicesService } from 'src/app/services/digisubservices.service';
import { forkJoin } from 'rxjs';

@Component({
  selector: 'app-credit-debit-ledger-report',
  templateUrl: './credit-debit-ledger-report.component.html',
  styleUrls: ['./credit-debit-ledger-report.component.scss'],
})
export class CreditDebitLedgerReportComponent implements OnInit {
  creditdebitledgerData = [];
  bankwiseData;
  creditledgerData;
  debitledgerData;
  debittotal = 0.0;
  credittotal = 0.0;
  previousbaki = 0.0;
  nextbaki = 0.0;
  startDate;
  endDate;
  bank;
  bankname: number;
  contraData: any;

  constructor(
    private dialogref: MatDialogRef<CreditDebitLedgerReportComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private router: Router,
    private service: DigisubservicesService
  ) {}

  ngOnInit(): void {
    this.startDate = this.data['startDate'];
    this.endDate = this.data['endDate'];
    this.bank = this.data['bank'];
    this.getAllCreditVouchers();
  }

  // getAllCreditVouchers() {
    
  //   if (this.startDate !== '' && this.endDate !== '' && this.bank !== '') {
  //     this.service.readBankwiseOpeningBalance(this.bank).subscribe((res) => {
  //       this.bankwiseData = res;
  //       this.previousbaki = parseFloat(this.bankwiseData['opening_bal']);
  //       this.bankname = this.bankwiseData['sub_exp_name'];

  //     });

  //     const req:any = {
        
  //       "startdate":this.startDate,
  //       "enddate":this.endDate,
  //       "banktype":this.bank,
  //     }

  //     this.service
  //     .getAllcontra(req)
  //     .subscribe((res) => {
  //       // console.log(res);
  //       this.contraData = res;
  //       this.creditdebitledgerData.push(this.contraData);
  //       this.contraData.map((a) => {
  //         this.previousbaki = this.previousbaki + parseInt(a.deposit_amt);
  //         this.creditdebitledgerData.push(a);
  //         console.log(this.creditdebitledgerData, "contra entry")
  //       });
  //     });

  //     this.service
  //       .getAllCreditVouchersData(this.startDate, this.endDate, this.bank)
  //       .subscribe((res) => {
  //         // console.log(res);
  //         this.creditledgerData = res;
  //         this.creditledgerData.map((a) => {
  //           // this.previousbaki = this.previousbaki + parseInt(a.deposit_amt);
  //           this.creditdebitledgerData.push(a);
  //         });
  //       });

  //     this.service
  //       .getAllDebitVouchersData(this.startDate, this.endDate, this.bank)
  //       .subscribe((res) => {
  //         // console.log(res);
  //         this.debitledgerData = res;
  //         this.debitledgerData.map((a) => {
  //           // this.previousbaki = this.previousbaki - parseInt(a.deposit_amt);
  //           this.creditdebitledgerData.push(a);
  //         });
  //       });
  //   } else if (
  //     this.startDate !== '' &&
  //     this.endDate !== '' &&
  //     this.bank === ''
  //   ) {
  //     this.service
  //       .getAllCreditVouchersData(this.startDate, this.endDate, 'xyz')
  //       .subscribe((res) => {
  //         // console.log(res);
  //         this.creditledgerData = res;
  //         this.creditledgerData.map((a) => {
  //           this.creditdebitledgerData.push(a);
  //         });
  //       });

  //     this.service
  //       .getAllDebitVouchersData(this.startDate, this.endDate, 'xyz')
  //       .subscribe((res) => {
  //         // console.log(res);
  //         this.debitledgerData = res;
  //         this.debitledgerData.map((a) => {
  //           this.creditdebitledgerData.push(a);
  //         });
  //       });
        
  //   } else if (
  //     this.startDate === '' &&
  //     this.endDate === '' &&
  //     this.bank !== ''
  //   ) {
  //     this.service.readBankwiseOpeningBalance(this.bank).subscribe((res) => {
        
  //       this.bankwiseData = res;
  //       this.previousbaki = parseFloat(this.bankwiseData['opening_bal']);
  //       this.bankname = this.bankwiseData['sub_exp_name'];

  //     });
      

  //     this.service
  //       .getAllCreditVouchersData('abc', 'pqr', this.bank)
  //       .subscribe((res) => {
  //         // console.log(res);
  //         this.creditledgerData = res;
  //         this.creditledgerData.map((a) => {
  //           // this.previousbaki = this.previousbaki + parseInt(a.deposit_amt);
  //           this.creditdebitledgerData.push(a);
  //         });
  //       });

  //     this.service
  //       .getAllDebitVouchersData('abc', 'pqr', this.bank)
  //       .subscribe((res) => {
  //         // console.log(res);
  //         this.debitledgerData = res;
  //         this.debitledgerData.map((a) => {
  //           // this.previousbaki = this.previousbaki - parseInt(a.deposit_amt);
  //           this.creditdebitledgerData.push(a);
  //         });
  //       });

  //     // console.log(this.bank);
  //   }
  //   setTimeout(() => {
  //     // console.log(this.creditdebitledgerData);
  //     let data = this.creditdebitledgerData.sort((a, b) =>
  //       a.voucher_date < b.voucher_date ? -1 : 1
  //     );

  //     this.creditdebitledgerData = [];
  //     if(this.bank== 'JSBP' || this.bank== 'JSBL' || this.bank== 'TJSBOD' || this.bank== 'BOMR') {
  //     data.map((a) => {
        
  //       if (a.type=='Cr' ) {
  //         this.previousbaki = parseFloat(this.previousbaki.toString()) - parseFloat(a.deposit_amt);
  //       } else if (a.type=='Dr' ) {
  //         this.previousbaki = parseFloat(this.previousbaki.toString()) + parseFloat(a.deposit_amt);
  //       } else if ( a.type=='contra') {
  //         this.previousbaki = parseFloat(this.previousbaki.toString()) - parseFloat(a.deposit_amt);
  //       }
  //       this.creditdebitledgerData.push({
  //         ...a,
  //         total: this.previousbaki,
  //         type:a.type=='Dr'? 'CR':'DR'
          
  //       });
  //     });
  //   }else{
  //     data.map((a) => {
        
  //       if (a.type=='Cr' ) {
  //         this.previousbaki = parseFloat(this.previousbaki.toString()) + parseFloat(a.deposit_amt);
  //       } else if (a.type=='Dr' ) {
  //         this.previousbaki = parseFloat(this.previousbaki.toString()) - parseFloat(a.deposit_amt);
  //       } else if ( a.type=='contra') {
  //         this.previousbaki = parseFloat(this.previousbaki.toString()) + parseFloat(a.deposit_amt);
  //       }
  //       this.creditdebitledgerData.push({
  //         ...a,
  //         total: this.previousbaki,
  //         type:a.type=='Dr'? 'CR':'DR'
          
  //       });
  //     });
  //   }
  //     // console.log(this.creditdebitledgerData);
  //     this.calculatecrdtdebtVouchersData();
  //   }, 1000);
  // }


  getAllCreditVouchers() {
  if (this.startDate !== '' && this.endDate !== '' && this.bank !== '') {
    // Fetch bank opening balance
    this.service.readBankwiseOpeningBalance(this.bank).subscribe((res) => {
      this.bankwiseData = res;
      this.previousbaki = parseFloat(this.bankwiseData['opening_bal']);
      this.bankname = this.bankwiseData['sub_exp_name'];
    });

    const req: any = {
      startdate: this.startDate,
      enddate: this.endDate,
      banktype: this.bank,
    };

    // Fetch all data in parallel
    forkJoin({
      contraData: this.service.getAllcontra(req),
      creditData: this.service.getAllCreditVouchersData(this.startDate, this.endDate, this.bank),
      debitData: this.service.getAllDebitVouchersData(this.startDate, this.endDate, this.bank),
    }).subscribe(({ contraData, creditData, debitData }) => {
      this.creditdebitledgerData = []; // Reset array

       // Ensure arrays before merging
       const safeContraData = Array.isArray(contraData) ? contraData : [];
       const safeCreditData = Array.isArray(creditData) ? creditData : [];
       const safeDebitData = Array.isArray(debitData) ? debitData : [];

      // Merge all data
      const combinedData = [...safeContraData, ...safeCreditData, ...safeDebitData];

      // Sort by `voucher_date`
      combinedData.sort((a, b) => new Date(a.voucher_date).getTime() - new Date(b.voucher_date).getTime());

      // Process and push sorted data
      combinedData.forEach((a) => {
        if (this.bank === 'JSBP' || this.bank === 'JSBL' || this.bank === 'TJSBOD') {
          if (a.type === 'Cr') {
            this.previousbaki -= parseFloat(a.deposit_amt);
          } else if (a.type === 'Dr') {
            this.previousbaki += parseFloat(a.deposit_amt);
          } else if (a.type === 'contra') {
            this.previousbaki -= parseFloat(a.deposit_amt);
          }
        } else {
          if (a.type === 'Cr') {
            this.previousbaki += parseFloat(a.deposit_amt);
          } else if (a.type === 'Dr') {
            this.previousbaki -= parseFloat(a.deposit_amt);
          } else if (a.type === 'contra') {
            this.previousbaki += parseFloat(a.deposit_amt);
          }
        }

        this.creditdebitledgerData.push({
          ...a,
          total: this.previousbaki,
          type: a.type === 'Dr' ? 'CR' : 'DR',
        });
      });

      this.calculatecrdtdebtVouchersData(); // Final processing
    });
  }
}


  // calculateCreditVouchersData() {
  //   for (let i = 0; i < this.creditledgerData.length; i++) {
  //     this.debittotal =
  //       this.debittotal + parseInt(this.creditledgerData[i]['deposit_amt']);
  //     this.nextbaki = this.debittotal - this.credittotal;
  //   }
  // }

  // calculateDebitVouchersData() {
  //   for (let i = 0; i < this.debitledgerData.length; i++) {
  //     this.credittotal =
  //       this.credittotal + parseInt(this.debitledgerData[i]['deposit_amt']);
  //     this.nextbaki = this.debittotal - this.credittotal;
  //   }
  // }

  // calculatecrdtdebtVouchersData() {
  //   // this.debittotal = 0;
  //   // this.credittotal = 0;
  //   if (this.bankwiseData['crdt_debt'] === 'Cr') {
  //     this.credittotal += parseFloat(this.bankwiseData['opening_bal']);
  //   } else if (this.bankwiseData['crdt_debt'] === 'Dr') {
  //     this.debittotal += parseFloat(this.bankwiseData['opening_bal']);
  //   }
  //   for (let i = 0; i < this.creditdebitledgerData.length; i++) {
  //     if (this.creditdebitledgerData[i].voucher_no.indexOf('CR') === 0) {
  //       this.debittotal =
  //       parseFloat(this.debittotal.toString()) +
  //         parseFloat(this.creditdebitledgerData[i]['deposit_amt']);
  //     } else if (this.creditdebitledgerData[i].voucher_no.indexOf('DR') === 0 ) {
  //       this.credittotal =
  //       parseFloat(this.credittotal.toString()) +
  //         parseFloat(this.creditdebitledgerData[i]['deposit_amt']);
  //     }
  //   }
  //   this.nextbaki = parseFloat(this.debittotal.toString()) - parseFloat(this.credittotal.toString());
  // }

  calculatecrdtdebtVouchersData() {
    // Initialize totals
    this.debittotal = 0;
    this.credittotal = 0;

    // Include opening balance
    const openingBalance = parseFloat(this.bankwiseData?.opening_bal || '0');
    if (this.bankwiseData?.crdt_debt === 'Cr') {
        this.credittotal += openingBalance;
    } else if (this.bankwiseData?.crdt_debt === 'Dr') {
        this.debittotal += openingBalance;
    }

    // Ensure data is valid
    if (Array.isArray(this.creditdebitledgerData)) {
        this.creditdebitledgerData.forEach((item) => {
            const depositAmt = parseFloat(item?.deposit_amt || '0');

            if (!isNaN(depositAmt)) {
                if (item.type === 'DR') {
                    this.debittotal += depositAmt;  // Sum of डेबिट column
                } else if (item.type === 'CR') {
                    this.credittotal += depositAmt; // Sum of क्रेडिट column
                }
            }
        });
    }

    // Calculate remaining balance
    this.nextbaki = this.debittotal - this.credittotal;

    // Log values for debugging
    console.log(`Debit Total: ${this.debittotal}`);
    console.log(`Credit Total: ${this.credittotal}`);
    console.log(`Final Balance (nextbaki): ${this.nextbaki}`);
}

  
  toInt(amt) {
    return parseFloat(amt);
  }
}