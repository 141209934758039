import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as moment from 'moment';
import { DigisubservicesService } from 'src/app/services/digisubservices.service';
import { ToWords } from 'to-words';

@Component({
  selector: 'app-view-advt-bill',
  templateUrl: './view-advt-bill.component.html',
  styleUrls: ['./view-advt-bill.component.scss'],
})
export class ViewAdvtBillComponent implements OnInit {
  viewData;
  isLoading = false;

  filteredShowData = [];

  constructor(
    private dialogRef: MatDialogRef<ViewAdvtBillComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private service: DigisubservicesService
  ) {}

  ngOnInit(): void {
    this.isLoading = false;
    this.viewData = this.data['advtData'];
    // console.log(this.viewData);

    this.setData();
  }

  setData() {
    if (!this.viewData) {
      this.isLoading = false;
      alert('Data is not Present.');
      this.dialogRef.close();
    }
    if (Object.keys(this.viewData).length > 0) {
      // console.log('called', this.viewData);

      for (let index = 0; index < this.viewData.length; index++) {
        let showData = {
          invoice_no: '',
          invoice_date: '',
          advt_name: '',
          address: '',
          state: '',
          cust_gst_no: '',
          cust_name: '',
          ro_no:'',
          ro_date:'',
          advt_order_no: '',
          advt_order_date: '',
          firstweek: {
            id: 0,
            issue_date: '',
            advt_size: '',
            page_no: '',
            hsn: '',
            advt_rate: '',
            dis: '',
            net: '',
            cgst: '',
            sgst: '',
            igst: '',
            round_off: '',
            total: '',
            ro_no:'',
            ro_date:'',
          },
          secondweek: {
            id: 0,
            issue_date: '',
            advt_size: '',
            page_no: '',
            hsn: '',
            advt_rate: '',
            dis: '',
            net: '',
            cgst: '',
            sgst: '',
            igst: '',
            round_off: '',
            total: '',
            ro_no:'',
            ro_date:'',
          },
          thirdweek: {
            id: 0,
            issue_date: '',
            advt_size: '',
            page_no: '',
            hsn: '',
            advt_rate: '',
            dis: '',
            net: '',
            cgst: '',
            sgst: '',
            igst: '',
            round_off: '',
            total: '',
            ro_no:'',
            ro_date:'',
          },
          fourthweek: {
            id: 0,
            issue_date: '',
            advt_size: '',
            page_no: '',
            hsn: '',
            advt_rate: '',
            dis: '',
            net: '',
            cgst: '',
            sgst: '',
            igst: '',
            round_off: '',
            total: '',
            ro_no:'',
            ro_date:'',
          },
          fifthweek: {
            id: 0,
            issue_date: '',
            advt_size: '',
            page_no: '',
            hsn: '',
            advt_rate: '',
            dis: '',
            net: '',
            cgst: '',
            sgst: '',
            igst: '',
            round_off: '',
            total: '',
            ro_no:'',
            ro_date:'',
          },
          total_amt: {
            page_no: 0,
            advt_rate: 0,
            dis: 0,
            net: 0,
            cgst: 0,
            sgst: 0,
            igst: 0,
            round_off: 0,
            total: 0,
          },
          total_in_words: '',
          pre_bal: 0,
          total: 0,
          remaining_amt: 0,
        };
        this.service
          .getBasicInfoOfAdvtInvoice(
            this.viewData[index]['first_week_id'] ??
              this.viewData[index]['second_week_id'] ??
              this.viewData[index]['third_week_id'] ??
              this.viewData[index]['fourth_week_id'] ??
              this.viewData[index]['fifth_week_id']
          )
          .subscribe((res) => {
            // console.log(res);
            showData['advt_order_no'] = res[0]['booking_no'];
            showData['advt_order_date'] = res[0]['booking_date'];
            showData['cust_name'] = res[0]['cust_name'];
            showData['ro_no'] = res[0]['ro_no'];
            showData['ro_date'] = res[0]['ro_date'];
            showData['cust_gst_no'] = res[0]['gstno'];
            showData['address'] = res[0]['address'];
            showData['state'] = res[0]['state'];
          });

        showData['advt_name'] = this.viewData[index]['advt_name'];
        showData['invoice_no'] = this.viewData[index]['invoice_no'];
        showData['invoice_date'] = this.viewData[index]['invoice_date'];

        // for (let index = 0; index < this.viewData[index].length; index++) {
        // let issuedate = this.viewData[index]['DS_ISSUEDATE'].split(',')[0];

        // showData['total_in_words'] = new ToWords().convert(
        //   showData['total_amt']['total'],
        //   {
        //     currency: true,
        //   }
        // );
        // showData['pre_bal'] = ;
        // showData['total'] = showData['total_amt']['total'];
        // showData['remaining_amt'] =
        //   showData['pre_bal'] + showData['total'];
        if (this.viewData[index]['first_week_id'] != null) {
          console.log('first', parseInt(this.viewData[index]['first_week_id']));
          showData['firstweek']['id'] = this.viewData[index]['first_week_id'];
          showData['firstweek']['issue_date'] =
            this.viewData[index]['first_week_issue_date'].split(',')[0];
          showData['firstweek']['advt_size'] =
            this.viewData[index]['first_week_advt_size'];
          showData['firstweek']['page_no'] =
            this.viewData[index]['first_week_pages_no'];
          showData['firstweek']['hsn'] = '998363';
          showData['firstweek']['advt_rate'] =
            this.viewData[index]['first_week_advt_amt'];
          showData['firstweek']['dis'] =
            this.viewData[index]['first_week_advt_discnt'];
          showData['firstweek']['net'] =
            this.viewData[index]['first_week_amount'];
          showData['firstweek']['cgst'] =
            this.viewData[index]['first_week_cgst'];
          showData['firstweek']['sgst'] =
            this.viewData[index]['first_week_sgst'];
          showData['firstweek']['igst'] =
            this.viewData[index]['first_week_igst'];
          showData['firstweek']['round_off'] =
            this.viewData[index]['first_week_total_amount'];
          showData['firstweek']['total'] =
            this.viewData[index]['first_week_total_amount'];
          showData['total_amt']['page_no'] += parseInt(
            this.viewData[index]['first_week_pages_no']
          );
          showData['total_amt']['advt_rate'] += parseInt(
            this.viewData[index]['first_week_advt_amt']
          );
          showData['total_amt']['dis'] += parseInt(
            this.viewData[index]['first_week_advt_discnt']
          );
          showData['total_amt']['net'] += parseInt(
            this.viewData[index]['first_week_amount']
          );
          showData['total_amt']['cgst'] += parseInt(
            this.viewData[index]['first_week_cgst']
          );
          showData['total_amt']['sgst'] += parseInt(
            this.viewData[index]['first_week_sgst']
          );
          showData['total_amt']['igst'] += parseInt(
            this.viewData[index]['first_week_igst']
          );
          showData['total_amt']['round_off'] += parseInt(
            this.viewData[index]['first_week_total_amount']
          );
          showData['total_amt']['total'] += parseInt(
            this.viewData[index]['first_week_total_amount']
          );
        } 
         if (this.viewData[index]['second_week_id'] != null) {
          showData['secondweek']['id'] = this.viewData[index]['second_week_id'];
          showData['secondweek']['issue_date'] =
            this.viewData[index]['second_week_issue_date'].split(',')[0];
          showData['secondweek']['advt_size'] =
            this.viewData[index]['second_week_advt_size'];
          showData['secondweek']['page_no'] =
            this.viewData[index]['second_week_pages_no'];
          showData['secondweek']['hsn'] = '998363';
          showData['secondweek']['advt_rate'] =
            this.viewData[index]['second_week_advt_amt'];
          showData['secondweek']['dis'] =
            this.viewData[index]['second_week_advt_discnt'];
          showData['secondweek']['net'] =
            this.viewData[index]['second_week_amount'];
          showData['secondweek']['cgst'] =
            this.viewData[index]['second_week_cgst'];
          showData['secondweek']['sgst'] =
            this.viewData[index]['second_week_sgst'];
          showData['secondweek']['igst'] =
            this.viewData[index]['second_week_igst'];
          showData['secondweek']['round_off'] =
            this.viewData[index]['second_week_total_amount'];
          showData['secondweek']['total'] =
            this.viewData[index]['second_week_total_amount'];

          showData['total_amt']['page_no'] += parseInt(
            this.viewData[index]['second_week_pages_no']
          );
          showData['total_amt']['advt_rate'] += parseInt(
            this.viewData[index]['second_week_advt_amt']
          );
          showData['total_amt']['dis'] += parseInt(
            this.viewData[index]['second_week_advt_discnt']
          );
          showData['total_amt']['net'] += parseInt(
            this.viewData[index]['second_week_amount']
          );
          showData['total_amt']['cgst'] += parseInt(
            this.viewData[index]['second_week_cgst']
          );
          showData['total_amt']['sgst'] += parseInt(
            this.viewData[index]['second_week_sgst']
          );
          showData['total_amt']['igst'] += parseInt(
            this.viewData[index]['second_week_igst']
          );
          showData['total_amt']['round_off'] += parseInt(
            this.viewData[index]['second_week_total_amount']
          );
          showData['total_amt']['total'] += parseInt(
            this.viewData[index]['second_week_total_amount']
          );
        } 
         if (this.viewData[index]['third_week_id'] != null) {
          showData['thirdweek']['id'] = this.viewData[index]['third_week_id'];
          showData['thirdweek']['issue_date'] =
            this.viewData[index]['third_week_issue_date'].split(',')[0];
          showData['thirdweek']['advt_size'] =
            this.viewData[index]['third_week_advt_size'];
          showData['thirdweek']['page_no'] =
            this.viewData[index]['third_week_pages_no'];
          showData['thirdweek']['hsn'] = '998363';
          showData['thirdweek']['advt_rate'] =
            this.viewData[index]['third_week_advt_amt'];
          showData['thirdweek']['dis'] =
            this.viewData[index]['third_week_advt_discnt'];
          showData['thirdweek']['net'] =
            this.viewData[index]['third_week_amount'];
          showData['thirdweek']['cgst'] =
            this.viewData[index]['third_week_cgst'];
          showData['thirdweek']['sgst'] =
            this.viewData[index]['third_week_sgst'];
          showData['thirdweek']['igst'] =
            this.viewData[index]['third_week_igst'];
          showData['thirdweek']['round_off'] =
            this.viewData[index]['third_week_total_amount'];
          showData['thirdweek']['total'] =
            this.viewData[index]['third_week_total_amount'];

          showData['total_amt']['page_no'] += parseInt(
            this.viewData[index]['third_week_pages_no']
          );
          showData['total_amt']['advt_rate'] += parseInt(
            this.viewData[index]['third_week_advt_amt']
          );
          showData['total_amt']['dis'] += parseInt(
            this.viewData[index]['third_week_advt_discnt']
          );
          showData['total_amt']['net'] += parseInt(
            this.viewData[index]['third_week_amount']
          );
          showData['total_amt']['cgst'] += parseInt(
            this.viewData[index]['third_week_cgst']
          );
          showData['total_amt']['sgst'] += parseInt(
            this.viewData[index]['third_week_sgst']
          );
          showData['total_amt']['igst'] += parseInt(
            this.viewData[index]['third_week_igst']
          );
          showData['total_amt']['round_off'] += parseInt(
            this.viewData[index]['third_week_total_amount']
          );
          showData['total_amt']['total'] += parseInt(
            this.viewData[index]['third_week_total_amount']
          );
        } 
         if (this.viewData[index]['fourth_week_id'] != null) {
          showData['fourthweek']['id'] = this.viewData[index]['fourth_week_id'];
          showData['fourthweek']['issue_date'] =
            this.viewData[index]['fourth_week_issue_date'].split(',')[0];
          showData['fourthweek']['advt_size'] =
            this.viewData[index]['fourth_week_advt_size'];
          showData['fourthweek']['page_no'] =
            this.viewData[index]['fourth_week_pages_no'];
          showData['fourthweek']['hsn'] = '998363';
          showData['fourthweek']['advt_rate'] =
            this.viewData[index]['fourth_week_advt_amt'];
          showData['fourthweek']['dis'] =
            this.viewData[index]['fourth_week_advt_discnt'];
          showData['fourthweek']['net'] =
            this.viewData[index]['fourth_week_amount'];
          showData['fourthweek']['cgst'] =
            this.viewData[index]['fourth_week_cgst'];
          showData['fourthweek']['sgst'] =
            this.viewData[index]['fourth_week_sgst'];
          showData['fourthweek']['igst'] =
            this.viewData[index]['fourth_week_igst'];
          showData['fourthweek']['round_off'] =
            this.viewData[index]['fourth_week_total_amount'];
          showData['fourthweek']['total'] =
            this.viewData[index]['fourth_week_total_amount'];

          showData['total_amt']['page_no'] += parseInt(
            this.viewData[index]['fourth_week_pages_no']
          );
          showData['total_amt']['advt_rate'] += parseInt(
            this.viewData[index]['fourth_week_advt_amt']
          );
          showData['total_amt']['dis'] += parseInt(
            this.viewData[index]['fourth_week_advt_discnt']
          );
          showData['total_amt']['net'] += parseInt(
            this.viewData[index]['fourth_week_amount']
          );
          showData['total_amt']['cgst'] += parseInt(
            this.viewData[index]['fourth_week_cgst']
          );
          showData['total_amt']['sgst'] += parseInt(
            this.viewData[index]['fourth_week_sgst']
          );
          showData['total_amt']['igst'] += parseInt(
            this.viewData[index]['fourth_week_igst']
          );
          showData['total_amt']['round_off'] += parseInt(
            this.viewData[index]['fourth_week_total_amount']
          );
          showData['total_amt']['total'] += parseInt(
            this.viewData[index]['fourth_week_total_amount']
          );
        } 
         if (this.viewData[index]['fifth_week_id'] != null) {
          showData['fifthweek']['id'] = this.viewData[index]['fifth_week_id'];
          showData['fifthweek']['issue_date'] =
            this.viewData[index]['fifth_week_issue_date'].split(',')[0];
          showData['fifthweek']['advt_size'] =
            this.viewData[index]['fifth_week_advt_size'];
          showData['fifthweek']['page_no'] =
            this.viewData[index]['fifth_week_pages_no'];
          showData['fifthweek']['hsn'] = '998363';
          showData['fifthweek']['advt_rate'] =
            this.viewData[index]['fifth_week_advt_amt'];
          showData['fifthweek']['dis'] =
            this.viewData[index]['fifth_week_advt_discnt'];
          showData['fifthweek']['net'] =
            this.viewData[index]['fifth_week_amount'];
          showData['fifthweek']['cgst'] =
            this.viewData[index]['fifth_week_cgst'];
          showData['fifthweek']['sgst'] =
            this.viewData[index]['fifth_week_sgst'];
          showData['fifthweek']['igst'] =
            this.viewData[index]['fifth_week_igst'];
          showData['fifthweek']['round_off'] =
            this.viewData[index]['fifth_week_total_amount'];
          showData['fifthweek']['total'] =
            this.viewData[index]['fifth_week_total_amount'];

          showData['total_amt']['page_no'] += parseInt(
            this.viewData[index]['fifth_week_pages_no']
          );
          showData['total_amt']['advt_rate'] += parseInt(
            this.viewData[index]['fifth_week_advt_amt']
          );
          showData['total_amt']['dis'] += parseInt(
            this.viewData[index]['fifth_week_advt_discnt']
          );
          showData['total_amt']['net'] += parseInt(
            this.viewData[index]['fifth_week_amount']
          );
          showData['total_amt']['cgst'] += parseInt(
            this.viewData[index]['fifth_week_cgst']
          );
          showData['total_amt']['sgst'] += parseInt(
            this.viewData[index]['fifth_week_sgst']
          );
          showData['total_amt']['igst'] += parseInt(
            this.viewData[index]['fifth_week_igst']
          );
          showData['total_amt']['round_off'] += parseInt(
            this.viewData[index]['fifth_week_total_amount']
          );
          showData['total_amt']['total'] += parseInt(
            this.viewData[index]['fifth_week_total_amount']
          );
        }
        showData.total_in_words = new ToWords().convert(showData.total_amt.net ,{ currency: true });

        // showData['total_in_words'] = new ToWords().convert(
        //   showData['total_amt']['total'],
        //   {
        //     currency: true,
        //   }
        // );
        // showData['pre_bal'] = ;
        showData['total'] = showData['total_amt']['total'];
        showData['remaining_amt'] = showData['pre_bal'] + showData['total'];
        // }

        this.filteredShowData.push(showData);
        // console.log('filtered', this.filteredShowData);
        if (index == this.viewData.length - 1) {
          this.isLoading = false;
        }
        console.log(showData, "this is show data")
      }
    }
  }
  transform(value: any): any {
    // Use moment.js to parse the date string
    const date = moment(value, 'DD/MM/YYYY');
    // Format the date to return only the month name
    return date.format('MMMM');
  }

  calculateDiscount(dis:any,total:any) {
    return  (Number(total) * Number(dis)) / 100;
  }
}
