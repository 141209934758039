import { DatePipe, formatDate } from '@angular/common';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { DigisubservicesService } from 'src/app/services/digisubservices.service';
import { EncdecriptService } from 'src/app/services/encdecrypt.service';
import { UpdateAdvtDetailsComponent } from '../update-advt-details/update-advt-details.component';
import * as XLSX from 'xlsx';
import { UpdatePlublishDatesComponent } from '../update-plublish-dates/update-plublish-dates.component';
import { MatSelect } from '@angular/material/select';
import { MAT_DATE_FORMATS, MAT_DATE_LOCALE, MatOption } from '@angular/material/core';
import { Observable } from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import { FileUploader } from 'ng2-file-upload';
import { SubscriptionAccountsComponent } from '../subscription-accounts/subscription-accounts.component';
import { AdvertisementInvoiceComponent } from '../advertisement-invoice/advertisement-invoice.component';
import { CreditvoucherDialogComponent } from '../creditvoucher-dialog/creditvoucher-dialog.component';
import { ReciptComponent } from '../recipt/recipt.component';
import { UpdateOfficeRepresentativeComponent } from '../update-office-representative/update-office-representative.component';
import { RegistrationReportComponent } from '../registration-report/registration-report.component';
import { PrasiddhipatrakComponent } from '../prasiddhipatrak/prasiddhipatrak.component';
import { PrasiddhipatrakbrowseComponent } from '../prasiddhipatrakbrowse/prasiddhipatrakbrowse.component';
import { ContractBriefReportComponent } from '../contract-brief-report/contract-brief-report.component';
import { AdvtBillComponent } from '../advt-bill/advt-bill.component';
import { ViewAdvtBillComponent } from '../view-advt-bill/view-advt-bill.component';
import { AdvtInvoiceRegisterComponent } from '../advt-invoice-register/advt-invoice-register.component';
import { AddAdvtIssuePerPriceComponent } from '../add-advt-issue-per-price/add-advt-issue-per-price.component';
import { UpdateAdvtIssuePerPriceComponent } from '../update-advt-issue-per-price/update-advt-issue-per-price.component';
import { AdvtInvoicePaymentReceiptComponent } from '../advt-invoice-payment-receipt/advt-invoice-payment-receipt.component';
import { OpenselectmodalComponent } from 'src/app/openselectmodal/openselectmodal.component';
import { CommmodalComponent } from 'src/app/commmodal/commmodal.component';
import { CrreceiptComponent } from 'src/app/crreceipt/crreceipt.component';
import { TworeceiptComponent } from 'src/app/tworeceipt/tworeceipt.component';
import { ToastrService } from 'ngx-toastr';
import { StampreceiptComponent } from 'src/app/stampreceipt/stampreceipt.component';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { OlddataupdateComponent } from 'src/app/olddataupdate/olddataupdate.component';
import { AgGridAngular } from 'ag-grid-angular'; // Angular Data Grid Component
import { ColDef } from 'ag-grid-community'; 
import { ThreeStampReceiptComponent } from '../three-stamp-receipt/three-stamp-receipt.component';


export interface StateGroup {
  category: string;
  details: string[];
}

const uploadAPI = 'https://vivekweb.in/VivekApplication/portal_fileUpload.php';

export const _filter = (opt: string[], value: string): string[] => {
  const filterValue = value.toLowerCase();

  return opt.filter((item) => item.toLowerCase().includes(filterValue));
};

export const _filter1 = (opt: string[], value: string): string[] => {
  const filterValue = value.toLowerCase();

  return opt.filter((item) => item.toLowerCase().includes(filterValue));
};

export const _filter2 = (opt: string[], value: string): string[] => {
  const filterValue = value.toLowerCase();

  return opt.filter((item) => item.toLowerCase().includes(filterValue));
};

export const _filter3 = (opt: string[], value: string): string[] => {
  const filterValue = value.toLowerCase();

  return opt.filter((item) => item.toLowerCase().includes(filterValue));
};

export const _filter4 = (opt: string[], value: string): string[] => {
  const filterValue = value.toLowerCase();

  return opt.filter((item) => item.toLowerCase().includes(filterValue));
};

export const _filter7 = (opt: string[], value: string): string[] => {
  const filterValue = value.toLowerCase();

  return opt.filter((item) => item.toLowerCase().includes(filterValue));
};

const MY_DATE_FORMATS = {
  parse: {
    dateInput: 'MM/DD/YYYY', // Input format (e.g., from the input)
  },
  display: {
    dateInput: 'MM/DD/YYYY', // Output format (e.g., displayed on the input)
    monthYearLabel: 'MMM YYYY', // Optional: Month and year display format
    dateA11yLabel: 'LL', // Optional: Accessibility date format
    monthYearA11yLabel: 'MMMM YYYY', // Optional: Accessibility month and year format
  },
};

@Component({
  selector: 'app-advertisement-details',
  templateUrl: './advertisement-details.component.html',
  styleUrls: ['./advertisement-details.component.scss'],
  providers: [{provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMATS }],
})
export class AdvertisementDetailsComponent implements OnInit {
  repName = '';
  // offRepName = '';
  advtName = '';
  public uploader: FileUploader = new FileUploader({
    url: uploadAPI,
    itemAlias: 'file',
  });

  imgPath;
  stateForm: FormGroup = this.formBuilder.group({
    stateGroup: '',
  });

  stateGroups: StateGroup[] = [
    { category: 'Magazine', details: ['Vivek_Weekly'] },
    {
      category: 'Grantha',
      details: ['abc', 'pqr'],
    },
    { category: 'Special Magazine', details: ['xyq'] },
  ];

  AdvtGeneratedInvoice = new FormGroup({
    start: new FormControl(),
    end: new FormControl(),
  });

  advtinvoiceForm = new FormGroup({
    start: new FormControl(''),
    end: new FormControl(''),
    issuename: new FormControl(''),
    issuedate: new FormControl(''),
  });
  stampgetdata = new FormGroup({
    start: new FormControl(''),
    end: new FormControl(''),
    advtname: new FormControl(''),
    // issuedate: new FormControl(''),
  });
  advtAdvanceStampform = new FormGroup({
    start: new FormControl(''),
    end: new FormControl(''),
    date: new FormControl(''),
    recptdate : new FormControl(''),
    crdtvno :  new FormControl(''),
    advtname :  new FormControl(''),
    repname : new FormControl(''),
    newordeleted : new FormControl(''),

  });
  PersonalNoteform = new FormGroup({
    start: new FormControl(''),
    end: new FormControl(''),
    advtname: new FormControl(''),
  });

  @ViewChild('myInput') myInputField: ElementRef;
  stateGroupOptions: Observable<StateGroup[]>;
  advtInvoiceList;
  ionicForm: FormGroup;
  contractForm: FormGroup;
  issueForm: FormGroup;
  advtbillForm: FormGroup;
  advtbillFormsecond: FormGroup;

  contractDetailsForm: FormGroup;
  advtpymtrecptform: FormGroup;
  advtCreditNoteform: FormGroup;


  advtTypes;
  validDOB;
  defaultDate;
  countries = [];
  states = [];
  cities = [];
  talukas = [];
  titles = [];
  bookList = [];
  selectedState = '';
  selectedCity = '';
  selectedTaluka = '';
  AdvtDetails = [];
  advtSizes = [];
  occupations = [];
  advtpaymentList = [];
  invoicenumber = 'VSJ-'
  getAllRepList = [
    {
      id: 777,
      mobile: '1234567893',
      name: 'All',
      rep_address: '',
      rep_advt_comm: '0',
      rep_book_comm: '0',
      rep_comm: '0',
    },
  ];

  getFinancialYears = [
    { startdate: '2020 April', enddate: '2021 March' },
    { startdate: '2021 April', enddate: '2022 March' },
    { startdate: '2022 April', enddate: '2023 March' },
    { startdate: '2023 April', enddate: '2024 March' },
    { startdate: '2024 April', enddate: '2025 March' },
    { startdate: '2025 April', enddate: '2026 March' },
    { startdate: '2026 April', enddate: '2027 March' },
    { startdate: '2027 April', enddate: '2028 March' },
    { startdate: '2028 April', enddate: '2029 March' },
    { startdate: '2029 April', enddate: '2030 March' },
    { startdate: '2020 April', enddate: '2031 March' },
  ];

  rangeRegistration = new FormGroup({
    start: new FormControl(),
    end: new FormControl(),
  });

  RegistrationreportRange = new FormGroup({
    start: new FormControl(),
    end: new FormControl(),
    repname: new FormControl(),
    offrepname: new FormControl(),
    issuename: new FormControl(),
    issuedate: new FormControl(),
  });

  PrasiddhipatrakRange = new FormGroup({
    start: new FormControl(),
    end: new FormControl(),
    pagetype: new FormControl(),
    issueDates: new FormControl(),
    
  });

  RepwiseRegistrationreportRange = new FormGroup({
    start: new FormControl(),
    end: new FormControl(),
    
  });
  PaymentReceiptRegister = new FormGroup({
    start: new FormControl(),
    end: new FormControl(),
    rep_name: new FormControl(),
    off_rep_name: new FormControl(),
    issuename: new FormControl(),
    issueDates:new FormControl(),
  });
  // CreditReport1 = new FormGroup({
  //   start: new FormControl(),
  //   end: new FormControl(),
  // });
  CreditNoteForm = new FormGroup({
    start: new FormControl(),
    end: new FormControl(),
    rep_name : new FormControl(),
    off_rep_name: new FormControl(),
    issue_cat : new FormControl(),
    newordeleted : new FormControl(''),

  });
  OutStandingForm = new FormGroup({
    start: new FormControl(),
    end: new FormControl(),
    repname: new FormControl(),
    offrepname: new FormControl(),
    issuecate: new FormControl(),
    advtname: new FormControl(),
  });
  GSTFullReportForm = new FormGroup({
    start: new FormControl(),
    end: new FormControl(),
  });
  CreditNoteReportForm = new FormGroup({
    start: new FormControl(),
    end: new FormControl(),
  });
  CommissionReportForm = new FormGroup({
    start: new FormControl(),
    end: new FormControl(),
  });
  Sendreminderform = new FormGroup({
    start: new FormControl(),
    end: new FormControl(),
    Repname: new FormControl(),
    Advtname: new FormControl(),

  });
    Advtremainingamt = new FormGroup({
      invoiceNumber: new FormControl(),
      invoiceDate: new FormControl(),
      advtName: new FormControl(),
      issueName: new FormControl(),
      issueDate: new FormControl(),
      receivedAmount: new FormControl(),
      totalamt: new FormControl(),
      pendingamt: new FormControl(),
      rep_name : new FormControl(),
      invoicedate : new FormControl(), 
      issuedate : new FormControl(), 
      off_rep_name : new FormControl(),
  });
  Addadvttype = new FormGroup({
    advttype: new FormControl('', []),
    advttypedeatils: new FormControl('', []),
    id: new FormControl('',[]) // Assuming 'id' is a string, defaulting to an empty string
  });
  advt_mag_name_details = [];
  advt_person_name = [];
  filteredOptions: Observable<string[]>;
  filteredOptions1: Observable<string[]>;
  filteredOptions2: Observable<string[]>;
  filteredOptions3: Observable<string[]>;
  filteredOptions4: Observable<string[]>;
  filteredOptions5: Observable<string[]>;
  filteredOptions6: Observable<string[]>;
  filteredOptions7: Observable<string[]>;
  advtCategorylist;
  invoiceDetails;
  viewmode = false;
  repInvoice = 'Y';
  AdvtInvoiceData = [];
  newCustadvtInvoice = false;
  gstNumber = '';
  displayadvtSubscriber = false;
  getAdvtPersonDetail = [];
  getAdvertisement;
  advt_mobile;
  cust_id;
  cust_mobile;
  advt_id;
  today = formatDate(new Date(), 'dd-MM-yyyy', 'en-IN', 'GMT+0530');
  issueSellerName;
  issue_seller_name = [];
  advtwisereplist;
  advtshow = false;
  issueshow = false;
  contractshow = false;
  advt_names = [];
  advtNamesData = [];
  advt_mobile_no = [];
  advt_owner_name = [];
  contract_no = '';
  booking_no = '';
  contractDetails = [];
  advtSubDetails = [];
  booking_date = '';
  inDetailsRegReport = [];
  inDetailsRegReportTotal = {
    total_advt: 0,
    advt_total: 0,
    net_amt: 0,
    gst: 0,
    total_amt: 0,
    rcvd_amt: 0,
    pndg_amt: 0,
  };
  inDetailsOffRegReportTotal = {
    total_advt: 0,
    advt_total: 0,
    net_amt: 0,
    gst: 0,
    total_amt: 0,
    rcvd_amt: 0,
    pndg_amt: 0,
  };
  offrepNamesOfRegReport = [];
  repNamesOfRegReport = [];
  contractData = [];
  contractRepName = [];
  contractOffRepName = [];
  contractAdvtName = [];
  http: any;
  currentDate: Date;
  oneMonthAgo: Date;
  startDate: Date;
  generatedCreditNote: string;
  replistFormBeans: any;
  data: any;
  contractRepNamenew: any;
  advtnewreplistForm: any;
  advtnewNewlistForm: any;
  autogeneratedno: any;
  newg: any;
  advtCreditNote: any;
  selecteddata: number;
  endDate: any;
  offRepName: any;
  CommissionData: any;
  advtnamesearch: any;
  advtCreditNote1: any;
  data_for_two_receipt: any;
  old_data: any;
  start: any;
  end: any;
  onselect :any;
  credit_type: any;
  remark: any;
  GetOffRep: any;
  issuesub: any;
  getMagIssueDatesandAdvtNames1: any[];
  newadvtname: any;
  filterrep: any;
  filterissuedate: any[];
  oldissuename: Object;
  oldadvttype: any;
  PPwithoutnumber: any;
  advtcatsize: any;
  advtplus: number;
  pendingTotal: number=0;
  totalTotal: number=0;
  isupdate=false;
  // selectedOption: any;
  // GSTFullReportForm: any;
    
  rowData = [
    { make: "Tesla", model: "Model Y", price: 64950, electric: true },
    { make: "Ford", model: "F-Series", price: 33850, electric: false },
    { make: "Toyota", model: "Corolla", price: 29600, electric: false },
  ];
 
  // Column Definitions: Defines the columns to be displayed.
  colDefs: ColDef[] = [
    { field: "make" },
    { field: "model" },
    { field: "price" },
    { field: "electric" }
  ];
  getentrybyrepnamelist: any;
  latestbookingnumber: any;
  issuedate: any;
  issuedatelist: any = [];
  advtPaymentRegiInDetailsFormBeans: any;
  advtcreditnotetRegiInDetailsFormBeans: any;
  PaymentadvtOffInDTotal: any;
  voucherdate: any;
  advtsn: any;
  cid: any;
  newissuedate: any;
  newissuedate1: any;
  stampdata: any;
  deleterec: boolean = false;
  getdeleterecipt: any;
  
  getdeletereciptinvoice: any;
  getdeletecnrecipt:any;
  getdeleterecitstamp: any;
  getdeleterecitcn:any;
  advtupdatestatus: any;
  newissuedatepp: any;
  issueDates1: any;
  issuedatespp: any;
  invoicenumberlist: any=[];
  arraynew: any;
  getMagIssueDatesandNames1: any;
  cust_name: any;


  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    public service: DigisubservicesService,
    public dialog: MatDialog,
    public encr: EncdecriptService,
    private _snackBar: MatSnackBar,
    private datePipe: DatePipe,
    private toaster: ToastrService
  ) { }

  addEvent(event: MatDatepickerInputEvent<Date>): void {
    this.Advtremainingamt.get('invoiceDate').setValue(event.value);
    console.log(this.Advtremainingamt)
  }
  public demo1BtnClick1(tabCount:any) {
    
    this.demo1TabIndex =  Number(tabCount);
    
  }
  date: any
  ngOnInit(): void {
    this.getstampdata()

    this.demo1BtnClick1( localStorage.getItem("tab"))
    
    this.Advtremainingamt.get('totalamt').valueChanges.subscribe(() => {
      this.updatePendingAmount();
    });
    this.Advttype();
    
    this.Advtremainingamt.get('receivedAmount').valueChanges.subscribe(() => {
      this.updatePendingAmount();
    });

    this.getNewRepList();
    this.getNewNameList();
    this.practice();
    this.oldata();
    this.getOffRep();
    this.getNewAdvtName();
    this.withoutpagenopatrak();
    // this.dataforreceipt();

    
    
    // this.getCreditNoteMain(req1);
    // this.advtCreditNoteform = this.formBuilder.group({
    //   date: new FormControl(new Date().toISOString().split('T')[0]) // Set the default value to today's date
    // });

    this.currentDate = new Date();
    this.oneMonthAgo = new Date();
    this.oneMonthAgo.setMonth(this.currentDate.getMonth() - 1);
    this.PaymentReceiptRegister.value.start = this.datePipe.transform(this.currentDate, 'dd/MM/yyyy') || '';
    this.PaymentReceiptRegister.value.end = this.datePipe.transform(this.oneMonthAgo, 'dd/MM/yyyy') || '';


    this.date = this.datePipe.transform(this.currentDate, 'dd/MM/yyyy') || ''
    this.fetchAdvtSizes();
    this.ionicForm = this.formBuilder.group({
      sadvtname: [''],
      smobno: [''],
      sownername: [''],
      name: ['', [Validators.required, Validators.minLength(2)]],
      e_advt_name: [''],
      ownername: [''],
      email: [
        '',
        [Validators.pattern('[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+.[a-z]{2,3}$')],
      ],
      address: ['', [Validators.required, Validators.minLength(2)]],
      country: ['', [Validators.required, Validators.minLength(2)]],
      state: ['', [Validators.required, Validators.minLength(2)]],
      taluka: ['', [Validators.required, Validators.minLength(2)]],
      district: ['', [Validators.required, Validators.minLength(2)]],
      e_district: [''],
      mobileNumber: [
        '',
        [Validators.maxLength(10), Validators.pattern('^[7-9][0-9]{9}$')],
      ],
      pincode: [
        '',
        [Validators.maxLength(6), Validators.pattern('^[1-9][0-9]{5}$')],
      ],
      advtType: ['', [Validators.required]],
      dob: [],
      companyRegistration: [''],
      gstno: ['', [Validators.maxLength(15)]],
      rep_name: [''],
      rep_mobile_no: [''],
      stateGroup: [''],
      landline_no: ['', Validators.pattern('^[0-9]\\d{2,4}-\\d{6,8}$')],
      instruction: [''],
    });

    this.contractForm = this.formBuilder.group({
      contractno: [''],
      contractdate: [''],
      repname: [''],
      offrepname: [''],
      advtname: [''],
      priviousbal: [''],
      contractamt: [''],
      discountamt: [''],
      gstie: [''],
      netamt: [''],
      netgstprice: [''],
      nettotal: [''],
      contractfromdate: [''],
      contracttodate: [''],
      bookingadvtamt: [''],
      balamt: [''],
      instruction: [''],
    });

    this.issueForm = this.formBuilder.group({
      bookingdate: [''],
      bookingno: [''],
      advtname: [''],
      custname: [''],
      repname: [''],
      issue: [''],
      advtsize: [''],
      issuedates: [''],
      rep_mobile_no: [''],
      offrepname: [''],
      ro_number: [''],
      ro_dates: [''],
      instruction: [''],
      otherdetls: [''],
      pageno: [''],
      advtamout: [''],
      advtdiscount: [''],
      gstie: [''],
      gstamount: [''],
      igstamount: [''],
      totalamt: [''],
      amount: ['', [Validators.minLength(2)]],
      ispartpayment: [''],
      partAmount: [''],
      remainingAmount: [''],
      paymentmode: [''],
      checkno: [''],
      receiptdate: [''],
      receiptno: [''],
      document_name: [''],
    });

    this.advtbillForm = this.formBuilder.group({
      billno: [''],
      date: [''],
      issuename: [''],
      issuedate: [''],
      advt_name:[''],
      newordeleted:[''],
    });

    this.advtbillFormsecond = this.formBuilder.group({
      start: [''],
      end: [''],
    });


    this.contractDetailsForm = this.formBuilder.group({
      start: [''],
      end: [''],
      rep_name: [''],
      off_rep_name: [''],
      advt_name: [''],
    });

    this.advtpymtrecptform = this.formBuilder.group({
      date: [''],
      crdtvno: [''],
      recptdate: [''],
      repname: [''],
      recptno: [''],
      crdtvamt: [''],
      seamt: [''],
      balamt: [''],
      advtname: [''],
      advtnamesearch: [''],
      newordeleted: [''],
    });

    this.advtCreditNoteform = this.formBuilder.group({
      date: [''],
      crdtvno: [''],
      recptdate: [''],
      repname: [''],
      recptno: [''],
      CrNno: [''],
      seamt: [''],
      balamt: [''],
      advtname: [''],
      advtnamesearch: [''],
      newordeleted : [''],
      
    })

    

    this.advtCreditNoteform.patchValue({
      date: this.datePipe.transform(this.currentDate, 'dd-MM-yyyy') || '',

    })

    this.advtAdvanceStampform.patchValue({
      date: this.datePipe.transform(this.currentDate, 'dd-MM-yyyy') || '',

    })

    this.service.getAdvtissuename().subscribe((data) => {
      // console.log('Database data', data);
      this.issuesub = data;
      this.oldissuename = data;
      console.log(this.issuesub, "issue sub name")
    });

    // advtinvoiceForm;
    this.booking_date = formatDate(
      new Date(),
      'yyyy-MM-dd',
      'en-IN',
      'GMT+0530'
    );
    this.issueForm.get('bookingdate').setValue(this.booking_date);

    this.service.getAdvtPersonName().subscribe((data) => {
      this.advt_person_name = data;
      // console.log('advt_person_name', data);
      for (
        let index = 0;
        index < Object.keys(this.advt_person_name).length;
        index++
      ) {
        // if (
        //   this.advt_person_name[index]['name'] != '' &&
        //   this.advt_person_name[index]['name'] != null
        // ) {
        //   this.advt_names.push({
        //     name: this.advt_person_name[index]['name'],
        //   });
        // }

        if (this.advt_person_name[index]['mobile'] != 0) {
          this.advt_mobile_no.push({
            mobile: this.advt_person_name[index]['mobile'],
          });
        }

        if (
          this.advt_person_name[index]['owner_name'] != '' &&
          this.advt_person_name[index]['owner_name'] != null
        ) {
          this.advt_owner_name.push({
            owner_name: this.advt_person_name[index]['owner_name'],
          });
        }
      }

      // this.advt_names = this.uniqueid(this.advt_names, (a) => a.name);

      this.advt_mobile_no = this.uniqueid(this.advt_mobile_no, (a) => a.mobile);

      this.advt_owner_name = this.uniqueid(
        this.advt_owner_name,
        (a) => a.owner_name
      );

      // console.log('advt_owner_name', this.advt_owner_name);
    });



    this.service.getAdvtWiseRep().subscribe((data) => {
      this.advtwisereplist = data;
    });

    this.service.getIssuesellersname().subscribe((data) => {
      this.issue_seller_name = data;
      // console.log('issue_seller_name', this.issue_seller_name);
    });

    this.service.getAdvtCategory().subscribe((data) => {
      // console.log('Database data', data);
      this.advtCategorylist = data;
    });



    this.service.getIssueSellerName().subscribe((data) => {
      this.issueSellerName = data;
      // console.log('issueSellerName ', this.issueSellerName);
    });

    //Autocomplete :
    this.stateGroupOptions = this.stateForm
      .get('stateGroup')!
      .valueChanges.pipe(
        startWith(''),
        map((value) => this._filterGroup(value))
      );

    this.getAdvtMagDetails();

    this.service.getAdvtTypes().subscribe((data) => {
      // console.log(data);
      this.advtTypes = data;
    });

    this.service.get_AllRepresentative_List().subscribe((data) => {
      // console.log(data);
      for (let index = 0; index < Object.keys(data).length; index++) {
        this.getAllRepList.push(data[index]);
      }
      // console.log('getAllRepList', this.getAllRepList);
    });
    this.service.get_AllRepresentative_List().subscribe((data: any) => {
      console.log("rep name", data);
      this.replistFormBeans = (data as any);;
    });
    // Read All Contries
    // this.service.getAllContries().subscribe((data) => {
    // console.log(data);
    //   this.countries = data;
    // });

    this.getRepAndOffNames();

    this.service.getCountry().subscribe((data) => {
      this.countries = data;
    });

    this.service.getOccupation().subscribe((data) => {
      // console.log(data);
      this.occupations = data;
    });

    this.service.getAlltitle().subscribe((data) => {
      // console.log(data);
      this.titles = data;
    });

    this.service.getAdvtSizes().subscribe((data) => {
      // console.log(data);
      for (let index = 0; index < Object.keys(data).length; index++) {
        this.advtSizes.push({ name: data[index]['advt_size'] });
      }
    });

    this.service.getAllBooks().subscribe((data) => {
      // console.log(data);
      for (let index = 0; index < Object.keys(data).length; index++) {
        if (index == 0) {
          this.bookList.push({ name: 'Vivek_Weekly' });
        }
        this.bookList.push({ name: data[index]['bookname'] });
      }
    });



    this.filteredOptions = this.ionicForm.get('sadvtname').valueChanges.pipe(
     
      startWith(''),
      
      map((value) => (typeof value === 'string' ? value : value.name)),
      map((name) => (name ? this._filter(name) : this.advt_names.slice()))
    );


    this.filteredOptions1 = this.ionicForm.get('rep_name').valueChanges.pipe(
      startWith(''),
      map((value) => (typeof value === 'string' ? value : value.name)),
      map((name) =>
        name ? this._filter1(name) : this.issue_seller_name.slice()
      )
    );

    this.filteredOptions2 = this.ionicForm.get('smobno').valueChanges.pipe(
      startWith(''),
      map((value) => (typeof value === 'string' ? value : value.mobile)),
      map((mobile) =>
        mobile ? this._filter2(mobile) : this.advt_mobile_no.slice()
      )
    );

    this.filteredOptions3 = this.ionicForm.get('sownername').valueChanges.pipe(
      startWith(''),
      map((value) => (typeof value === 'string' ? value : value.owner_name)),
      map((owner_name) =>
        owner_name ? this._filter3(owner_name) : this.advt_owner_name.slice()
      )
    );

    this.filteredOptions4 = this.issueForm.get('repname').valueChanges.pipe(
      startWith(''),
      map((value) => (typeof value === 'string' ? value : value.name)),
      map((name) =>
        name ? this._filter1(name) : this.issue_seller_name.slice()
      )
    );

    this.filteredOptions6 = this.contractForm.get('repname').valueChanges.pipe(
      startWith(''),
      map((value) => (typeof value === 'string' ? value : value.name)),
      map((name) =>
        name ? this._filter1(name) : this.issue_seller_name.slice()
      )
    );

    this.filteredOptions7 = this.advtpymtrecptform
      .get('advtnamesearch')
      .valueChanges.pipe(
        startWith(''),
        map((value) => (typeof value === 'string' ? value : value.advt_name)),
        map((advt_name) =>
          advt_name ? this._filter7(advt_name) : this.advtNamesData.slice()
        )
      );
    this.filteredOptions7 = this.advtCreditNoteform
      .get('advtnamesearch')
      .valueChanges.pipe(
        startWith(''),
        map((value) => (typeof value === 'string' ? value : value.advt_name)),
        map((advt_name) =>
          advt_name ? this._filter7(advt_name) : this.advtNamesData.slice()
        )
      );

    this.uploader.onAfterAddingFile = (file) => {
      file.withCredentials = false;
    };

    this.uploader.onCompleteItem = (
      item: any,
      response: any,
      status: any,
      headers: any
    ) => {
      console.log('FileUpload:uploaded successfully:', item, status, response);
      this.imgPath = item['file']['name'];
      this.issueForm.get('document_name').setValue(this.imgPath);
      console.log('imgPath', this.imgPath);

      alert('Your file has been uploaded successfully');
    };

    this.getContractNo();
    this.getAdvtPayments();
    this.getAllVouchers();
    this.enableDisableRule('Contract');
    this.getBookingNo(formatDate(new Date(), 'MM', 'en-IN', 'GMT+0530'));
    this.getPrasiddhiPatrakNamesData();
    this.getallcontarctdetails();
    this.getAllAdvtInovicesData();
  } //end of ngOnInit()

  getstampdata() {
    
  
    // Get current date
    const currentDate = new Date();
  
    // Calculate start date as 3 months before the current date
    const startDate = new Date();
    startDate.setMonth(currentDate.getMonth() - 3);
  
    // Format dates as 'yyyy-mm-dd'
    const formatDate = (date: Date) => {
      const year = date.getFullYear();
      const month = ('0' + (date.getMonth() + 1)).slice(-2); // Add leading zero if necessary
      const day = ('0' + date.getDate()).slice(-2); // Add leading zero if necessary
      return `${year}-${month}-${day}`;
    };
  
    // Assign formatted dates to variables
    const advt_name = '';
    const start_date = formatDate(startDate);
    const end_date = formatDate(currentDate);
  
    // Call the service with the calculated dates
    this.service.getStampdata(advt_name, start_date, end_date).subscribe((data: any) => {
      console.log("ADVT new Credit name Data=", data);
      this.stampdata = data;
      console.log(this.stampdata, "stamp data");
      // this.ngOnInit();
    });
  }
  

  getAdvtissuename(event:any){
   
  }


  
  fetchAdvtSizes() {
    // Fetch or set advtsizebyCategory
    this.advtsizebyCategory = []; // Update with your data
  }

  calculatebookingamt(){
      const bookingAmt = this.contractForm.get('bookingadvtamt').value;
      const netTotal = this.contractForm.get('nettotal').value;
      const balanceAmt = parseInt(netTotal) - parseInt(bookingAmt);
      // alert(netTotal+","+bookingAmt+","+balanceAmt)
      this.contractForm.patchValue({ balamt: balanceAmt }, { emitEvent: false });
  
  }
 



  getRepAndOffNames() {
    this.service.getOffRepAndRepForRegReport().subscribe((data) => {
      // console.log(data);
      for (let index = 0; index < data.length; index++) {
        this.offrepNamesOfRegReport.push({
          off_rep_name: data[index]['off_rep_name'],
        });
        this.repNamesOfRegReport.push({
          rep_name: data[index]['rep_name'],
        });
      }

      this.offrepNamesOfRegReport = this.uniqueid(
        this.offrepNamesOfRegReport,
        (a) => a.off_rep_name
      );

      this.repNamesOfRegReport = this.uniqueid(
        this.repNamesOfRegReport,
        (a) => a.rep_name
      );
    });
  }

  bookingdateChange(event) {
    // console.log(event.target.value);
    this.booking_date = formatDate(
      event.target.value,
      'yyyy-MM-dd',
      'en-IN',
      'GMT+0530'
    );
    // console.log(this.booking_date);
    this.getBookingNo(
      formatDate(event.target.value, 'MM', 'en-IN', 'GMT+0530')
    );
  }

  getBookingNo(date) {
    this.service.getNextBookingNo(date).subscribe((res) => {
      // console.log('booking no db', res);
      if (Object.keys(res).length > 0) {
        this.booking_no = res[0]['booking_no'];
      }
    });

    setTimeout(() => {
      
      const currentYear = this.currentDate.getFullYear().toString().slice(-2); 
      if (this.booking_no != '' && this.booking_no != null) {
        let val = this.booking_no.split('-');
        console.log('booking no', this.booking_no, val, currentYear);
        this.booking_no = `ABN${formatDate(
          new Date(date),
          'MM',
          'en-IN',
          'GMT+0530'
        )}-${currentYear}-${parseInt(val[2]) <= 9
            ? '0' + (parseInt(val[2]) + 1)
            : parseInt(val[2]) + 1
          }`;
        this.issueForm.get('bookingno').setValue(this.booking_no);
        
      } 
      // else {
      //   this.booking_no = `ABN${formatDate(
      //     new Date(date),
      //     'MM',
      //     'en-IN',
      //     'GMT+0530'
      //   )}`;
      //   // console.log('booking no', this.booking_no);
      //   this.issueForm.get('bookingno').setValue(this.booking_no);
      // }
    }, 1000);
  }

  getContractNo() {
    this.service.getNextContractNo().subscribe((res) => {
      // console.log(res);
      this.contract_no = `ADVTCON-${res['id']}`;
      // console.log(this.contract_no);
      this.contractshow=false
      this.contractForm.get('contractno').setValue(this.contract_no);
    });
  }

  uniqueid(data, key) {
    return [...new Map(data.map((x) => [key(x), x])).values()];
  }

  toggle = false;
  toggle1 = false;
  toggle2 = false;
  enableDisableRule(val) {
    if (val == 'Special Issue') {
      this.toggle = true;
      this.toggle1 = false;
      this.toggle2 = false;
      this.contractForm.reset();
      this.contractshow = false;
    } else if (val == 'Occasional') {
      this.toggle = false;
      this.toggle1 = true;
      this.toggle2 = false;
      this.contractForm.reset();
      this.contractshow = false;
    } else if (val == 'Contract') {
      this.toggle = false;
      this.toggle1 = false;
      this.toggle2 = true;
      this.getContractNo();
      this.getcontractDetails(this.advt_id1);
      if (this.contractForm.get('repname').value != '') {
        this.setAdvtSubData(this.contractForm.get('repname').value);
        // this.setContractData(this.ionicForm.get('rep_name').value);
      }
    }
  }

  getAdvtPayments() {
    this.service.getAdvtPendingPayment().subscribe((res) => {
      // console.log(res);

      this.advtpaymentList = [];
      for (let index = 0; index < Object.keys(res).length; index++) {
        this.advtpaymentList.push({
          rep_id: parseInt(res[index]['repId']),
          name: res[index]['repName'],
          id: parseInt(res[index]['id']),
          claim_name: res[index]['name'],
          totalAmount: parseInt(res[index]['totalAmount']),
          payoptn: res[index]['payoptn'],
          repName: res[index]['repName'],
          repmobile: res[index]['repmobile'],
          rep_receipt: res[index]['rep_receipt'],
          task: 'advt',
        });
      }
    });
  }

  displayBookPayment(paymentdetails) {
    // console.log(paymentdetails);
    this.dialog.open(SubscriptionAccountsComponent, {
      data: {
        rep: paymentdetails,
        view: false,
      },
    });
  }

  private _filter(name: string): any[] {
    const filterValue = name.toLowerCase();

    return this.advt_names.filter((option) =>
      option.name.toLowerCase().includes(filterValue)
    );
  }

  private _filter1(name: string): any[] {
    const filterValue = name.toLowerCase();

    return this.issue_seller_name.filter((option) =>
      option.name.toLowerCase().includes(filterValue)
    );
  }

  private _filter2(mobile: string): any[] {
    const filterValue = mobile.toLowerCase();

    return this.advt_mobile_no.filter((option) =>
      option.mobile.toLowerCase().includes(filterValue)
    );
  }

  private _filter3(owner_name: string): any[] {
    const filterValue = owner_name.toLowerCase();

    return this.advt_owner_name.filter((option) =>
      option.owner_name.toLowerCase().includes(filterValue)
    );
  }

  private _filter4(data: string): any[] {
    const filterValue = data.toLowerCase();

    return this.filterdMagIssueDatesandNames.filter((option) =>
      option.data.toLowerCase().includes(filterValue)
    );
  }

  private _filter7(advt_name: string): any[] {
    const filterValue = advt_name.toLowerCase();

    return this.advtNamesData.filter((option) =>
      option.advt_name.toLowerCase().includes(filterValue)
    );
  }

  private _filterGroup(value: string): StateGroup[] {
    if (value) {
      return this.stateGroups
        .map((group) => ({
          category: group.category,
          details: _filter(group.details, value),
        }))
        .filter((group) => group.details.length > 0);
    }

    return this.stateGroups;
  }

  update(item) {
    this.dialog.open(UpdateOfficeRepresentativeComponent, {
      data: {
        item: item,
        type: 'advt',
      },
    });
    this.service.getAdvtWiseRep().subscribe((data) => {
      this.advtwisereplist = data;
    });
  }

  mob_no1;
  selected_mob_no(event, option) {
    if (event.isUserInput) {
      this.mob_no1 = option['mobile'];
      this.owner_name = 'abc';
      this.advt_name1 = 'xyz';
    }
  }

  owner_name;
  selected_owner_name(event, option) {
    if (event.isUserInput) {
      this.owner_name = option['owner_name'];
      this.mob_no1 = 777;
      this.advt_name1 = 'xyz';
    }
  }

  advt_name1;
  selected_name1(event, option) {
    if (event.isUserInput) {
      this.advt_name1 = option['name'];
      this.owner_name = 'abc';
      this.mob_no1 = 777;
    }
  }
  advt_name2;
  filterstampdata:any
  selected_name2(event) {
    
    this.advt_name2 = event.source.value
  
    this.filterstampdata = this.stampdata.filter(data=>data.advt_name ==this.advt_name2);
    console.log( this.filterstampdata , " this.filterstampdata")
    this.stampdata = this.filterstampdata
  }

  advt_id1 = 0;
  contract_id = 0;
  old_name = ''
  selected_name() {
    // console.log(this.owner_name, this.mob_no1, this.advt_name1);
    this.service
      .getAdvtMobileorid(this.owner_name, this.mob_no1, this.advt_name1)
      .subscribe((data) => {
        // console.log('getAdvtMobileorid', data);
        this.old_name = data[0]['name']
        this.advtshow = true;
        this.advt_id1 = data[0]['advt_id'];
        this.ionicForm.get('name').setValue(data[0]['name']);
        this.issueForm.get('advtname').setValue(data[0]['name']);
        // this.contractForm.get('advtname').setValue(data[0]['name']);
        this.ionicForm.get('ownername').setValue(data[0]['owner_name']);
        this.ionicForm.get('email').setValue(data[0]['email']);
        this.ionicForm.get('address').setValue(data[0]['address']);
        this.getCountry({ value: data[0]['country'] });
        this.ionicForm.get('country').setValue(data[0]['country']);
        this.ionicForm.get('advtType').setValue(data[0]['advt_type']);
        this.ionicForm.get('e_advt_name').setValue(data[0]['e_advt_name']);
        this.ionicForm.get('e_district').setValue(data[0]['e_district']);
        this.ionicForm.get('dob').setValue(data[0]['dob']);
        setTimeout(() => {
          this.getState({ value: data[0]['state'] });
          this.ionicForm.get('state').setValue(data[0]['state']);
        }, 1000);
        this.ionicForm.get('district').setValue(data[0]['district']);
        setTimeout(() => {
          this.getDistricts({ value: data[0]['district'] });
        }, 1500);
        this.ionicForm.get('taluka').setValue(data[0]['taluka']);
        this.ionicForm.get('mobileNumber').setValue(data[0]['mobile']);
        this.ionicForm.get('pincode').setValue(data[0]['pincode']);
        this.ionicForm
          .get('companyRegistration')
          .setValue(data[0]['company_reg_date']);
        this.ionicForm.get('gstno').setValue(data[0]['gstno']);
        this.ionicForm.get('landline_no').setValue(data[0]['landline_no']);
        this.ionicForm.get('instruction').setValue(data[0]['instruction']);
        this.ionicForm.get('rep_name').setValue(data[0]['rep_name']);
        // this.setContractData(data[0]['rep_name']);
        this.ionicForm.get('rep_mobile_no').setValue(data[0]['rep_mob_no']);
        this.selectedCity = data[0]['district'];
        this.selectedState = data[0]['state'];
        this.selectedTaluka = data[0]['taluka'];
        // this.getState({ value: this.selectedState });
        // console.log(this.selectedCity, this.selectedState);
       
        this.getcontractDetails(this.advt_id1);
        setTimeout(() => {
          this.setContractDetails();
        }, 2000);
      });
  }

  preBal = 0;
  conAmt = 0;
  netAmt = 0;
  disAmt = 0;
  calculateNetGstAmt(event) {
    this.preBal =
      this.contractForm.get('priviousbal').value == ''
        ? 0
        : this.contractForm.get('priviousbal').value;
    this.conAmt =
      this.contractForm.get('contractamt').value == ''
        ? 0
        : this.contractForm.get('contractamt').value;
    this.disAmt =
      this.contractForm.get('discountamt').value == ''
        ? 0
        : this.contractForm.get('discountamt').value;
    if (this.conAmt != 0) {
      let total = 0;
      let gstamt = 0;
      if (event.value == 'gstInclude') {
        gstamt = Math.round(this.netAmt * (4.76 / 100));
        this.netAmt = this.conAmt - this.disAmt - gstamt;
        this.contractForm.get('netgstprice').setValue(gstamt);
        total = this.netAmt + gstamt + this.preBal;
      } else if (event.value == 'gstExclude') {
        this.netAmt = this.conAmt - this.disAmt;
        gstamt = Math.round(this.netAmt * (5 / 100));
        this.contractForm.get('netgstprice').setValue(gstamt);
        total = this.netAmt + gstamt + this.preBal;
      }
      this.contractForm.get('netamt').setValue(this.netAmt);
      this.contractForm.get('nettotal').setValue(total);
      this.contractForm.get('balamt').setValue(total);
    }
  }

  getcontractDetails(id) {
    
    if (id != 0) {
      this.service.getContractDetails(id).subscribe((res) => {
        // console.log('getContractDetails', res);
        this.contractDetails = res;
      });
      this.service.getAdvtSubDetailsById(id).subscribe((res) => {
        // console.log('getAdvtSubDetailsById', res);
        this.advtSubDetails = res;
      });
    }
  }

  advt_sub_id = 0;
  ds_advtId = 0;
  isInvoice;
  selectAdvtIssue(item) {
    debugger
    console.log(item);
    this.AdvtDetails = [];
    this.contractshow = true;
    this.issueshow = true;
    this.advt_sub_id = item['id'];
    this.advt_id1 = item['advt_id'];
    this.ds_advtId = item['ds_advtId'];
    this.isInvoice = item['isInvoice'];
    this.contract_no = item['contract_no'];
    this.booking_date = item['booking_date'];
    this.getIssues({ value: item['issue_name'] });
    this.issueForm.get('bookingdate').setValue(item['booking_date']);
    this.issueForm.get('bookingno').setValue(item['booking_no']);
    this.issueForm.get('advtname').setValue(item['advt_name']);
    this.issueForm.get('custname').setValue(item['cust_name']);
    this.issueForm.get('repname').setValue(item['rep_name']);
    this.issueForm.get('rep_mobile_no').setValue(item['rep_mob_no']);
    this.issueForm.get('offrepname').setValue(item['off_rep_name']);
    this.issueForm.get('issue').setValue(item['issue_name']);
    this.issueForm.get('advtsize').setValue(item['advt_size']);
    this.issueName = item['issue_name'];
    this.advtSize = item['advt_size'];
    this.stringdates = item['issue_date'];
    debugger;
    
      this.issueForm.get('issuedates').setValue(item['issue_date']);
   
    this.issueForm.get('ro_number').setValue(item['ro_no']);
    this.issueForm.get('ro_dates').setValue(item['ro_date']);
    this.issueForm.get('instruction').setValue(item['instruction']);
    this.issueForm.get('otherdetls').setValue(item['other_details']);
    this.issueForm.get('pageno').setValue(item['page_no']);
    this.issueForm.get('advtamout').setValue(item['advt_amt']);
    this.issueForm.get('advtdiscount').setValue(item['advt_discnt']);
    this.issueForm.get('gstie').setValue(item['gst_include_exclude']);
    this.gstie = item['gst_include_exclude'];
    this.sgstamt = item['sgst'];
    this.igstamt = item['igst'];
    this.showigst
      ? this.issueForm.get('igstamount').setValue(item['igst'])
      : this.issueForm.get('gstamount').setValue(item['sgst']);
    this.issueForm.get('totalamt').setValue(item['total_amount']);
    this.issueForm.get('amount').setValue(item['amount']);
    this.issueForm.get('ispartpayment').setValue(item['is_part_pymt']);
    this.issueForm.get('partAmount').setValue(item['part_pymt_amt']);
    this.issueForm
      .get('remainingAmount')
      .setValue(item['part_pymt_remaning_amt']);
    this.issueForm.get('paymentmode').setValue(item['pymt_mode']);
    this.issueForm.get('checkno').setValue(item['cheque_no']);
    this.issueForm.get('receiptdate').setValue(item['rcpt_date']);
    this.issueForm.get('receiptno').setValue(item['rep_rcpt_no']);
    this.issueForm.get('document_name').setValue(item['img_name']);
    this.addAdvt();
  }

  bookingAdvtAmount = 0;
  contractBalAmt = 0;
  setContractDetails() {
    if (Object.keys(this.contractDetails).length > 0) {
      this.contractshow = true;
      this.contract_id = this.contractDetails[0]['contract_id'];
      this.contract_no = this.contractDetails[0]['contract_no'];
      this.contractForm.get('contractno').setValue(this.contract_no);
      this.contractForm
        .get('contractdate')
        .setValue(this.contractDetails[0]['contract_date']);
      this.contractForm
        .get('repname')
        .setValue(this.contractDetails[0]['rep_name']);
      this.contractForm
        .get('offrepname')
        .setValue(this.contractDetails[0]['off_rep_name']);
      this.contractForm
        .get('advtname')
        .setValue(this.contractDetails[0]['advt_name']);
      this.contractForm
        .get('priviousbal')
        .setValue(
          this.convertToInt(this.contractDetails[0]['contract_prev_bal'])
        );
      this.contractForm
        .get('contractamt')
        .setValue(this.convertToInt(this.contractDetails[0]['contract_amt']));
      this.contractForm
        .get('discountamt')
        .setValue(
          this.convertToInt(this.contractDetails[0]['contract_discnt_amt'])
        );
      this.contractForm
        .get('netamt')
        .setValue(
          this.convertToInt(this.contractDetails[0]['contract_net_amt'])
        );
      this.contractForm
        .get('netgstprice')
        .setValue(
          this.convertToInt(this.contractDetails[0]['contract_net_gst_amt'])
        );
      this.contractForm
        .get('nettotal')
        .setValue(
          this.convertToInt(this.contractDetails[0]['contract_net_total'])
        );
      this.contractForm
        .get('contractfromdate')
        .setValue(this.contractDetails[0]['contract_period_from']);
      this.contractForm
        .get('contracttodate')
        .setValue(this.contractDetails[0]['contract_period_to']);
      this.contractForm
        .get('bookingadvtamt')
        .setValue(
          this.convertToInt(this.contractDetails[0]['contract_bkng_advt_amt'])
        );
      this.bookingAdvtAmount = parseInt(
        this.contractDetails[0]['contract_bkng_advt_amt']
      );
      this.contractForm
        .get('balamt')
        .setValue(
          this.convertToInt(this.contractDetails[0]['contract_bal_amt'])
        );
      this.contractForm
        .get('gstie')
        .setValue(this.contractDetails[0]['gst_include_exclude']);
      this.contractBalAmt = parseInt(
        this.contractDetails[0]['contract_bal_amt']
      );

      this.calculatebookingamt()
    }
  }

  convertToInt(num) {
    return parseInt(num);
  }

  saveAdvt() {
    let data = {
      name: this.ionicForm.get('name').value,
      ownername: this.ionicForm.get('ownername').value,
      email: this.ionicForm.get('email').value,
      address: this.ionicForm.get('address').value,
      country: this.ionicForm.get('country').value,
      state: this.ionicForm.get('state').value,
      taluka: this.ionicForm.get('taluka').value,
      district: this.ionicForm.get('district').value,
      mobileNumber: this.ionicForm.get('mobileNumber').value,
      pincode: this.ionicForm.get('pincode').value,
      advtType: this.ionicForm.get('advtType').value,
      e_advt_name: this.ionicForm.get('e_advt_name').value,
      e_district: this.ionicForm.get('e_district').value,
      dob: this.ionicForm.get('dob').value,
      companyRegistration: this.ionicForm.get('companyRegistration').value,
      gstno: this.ionicForm.get('gstno').value,
      rep_name: this.ionicForm.get('rep_name').value,
      rep_mobile_no: this.ionicForm.get('rep_mobile_no').value,
      landline_no: this.ionicForm.get('landline_no').value,
      instruction: this.ionicForm.get('instruction').value,
    };

    this.service.insertAdvt(data).subscribe((res) => {
      window.alert('New Advertiser Added Successfully...');
      this.ngOnInit();
    });
  }

  oldata(){
    this.service.get_old_data().subscribe((data: any) => {
      this.old_data = data as any;
      console.log(this.old_data, "old data")
  });
  }

  Advttype(){
    
    this.service.get_getadvttype().subscribe((data: any) => {
      this.oldadvttype = data as any;
      console.log(this.oldadvttype, "advt type")
  });
  }
  // onSelectChange(event: MatSelectChange) {
  //   this.selectedOption = event.value;
  // }

  updateAdvt() {
    let data = {
      old_advt_name : this.old_name,
      id: this.advt_id1,
      name: this.ionicForm.get('name').value,
      ownername: this.ionicForm.get('ownername').value,
      email: this.ionicForm.get('email').value,
      address: this.ionicForm.get('address').value,
      country: this.ionicForm.get('country').value,
      state: this.ionicForm.get('state').value,
      taluka: this.ionicForm.get('taluka').value,
      district: this.ionicForm.get('district').value,
      mobileNumber: this.ionicForm.get('mobileNumber').value,
      pincode: this.ionicForm.get('pincode').value,
      advtType: this.ionicForm.get('advtType').value,
      e_advt_name: this.ionicForm.get('e_advt_name').value,
      e_district: this.ionicForm.get('e_district').value,
      dob: this.ionicForm.get('dob').value,
      companyRegistration: this.ionicForm.get('companyRegistration').value,
      gstno: this.ionicForm.get('gstno').value,
      rep_name: this.ionicForm.get('rep_name').value,
      rep_mobile_no: this.ionicForm.get('rep_mobile_no').value,
      landline_no: this.ionicForm.get('landline_no').value,
      instruction: this.ionicForm.get('instruction').value,
      receiptdate:"",
      receiptno:"",
    };

    // console.log('update data', data);
    this.service.updateAdvtPerson(data).subscribe((res) => {
      window.alert('Advertiser updated successfully...');
      this.advtshow = false;
      this.ngOnInit();
    });
  }

  advtCancel() {
    if (this.advt_id1 > 0) {
      if (
        window.confirm(
          'This Operation is no reversable. Do you want to proceed!'
        )
      ) {
        this.service.cancelAdvt(this.advt_id1).subscribe((data) => {
          console.log(data);
          window.alert('Advertiser cancel successfully...');
          this.ngOnInit();
        });
      }
    } else {
      window.alert('Please select the Advertiser..');
    }
    this.ngOnInit();
  }

  saveContract() {
    if (this.advt_id1 > 0) {
      let data = {
        advt_id: this.advt_id1,
        contractno: this.contractForm.get('contractno').value,
        contractdate: this.contractForm.get('contractdate').value
          ? formatDate(
            this.contractForm.get('contractdate').value,
            'yyyy-MM-dd',
            'en-IN',
            'GMT+0530'
          )
          : '',
        repname: this.contractForm.get('repname').value,
        offrepname: this.contractForm.get('offrepname').value,
        advtname: this.contractForm.get('advtname').value,
        priviousbal: this.contractForm.get('priviousbal').value,
        contractamt: this.contractForm.get('contractamt').value,
        discountamt: this.contractForm.get('discountamt').value,
        gstie: this.contractForm.get('gstie').value,
        netamt: this.contractForm.get('netamt').value,
        netgstprice: this.contractForm.get('netgstprice').value,
        nettotal: this.contractForm.get('nettotal').value,
        contractfromdate: this.contractForm.get('contractfromdate').value
          ? formatDate(
            this.contractForm.get('contractfromdate').value,
            'yyyy-MM-dd',
            'en-IN',
            'GMT+0530'
          )
          : '',
        contracttodate: this.contractForm.get('contracttodate').value
          ? formatDate(
            this.contractForm.get('contracttodate').value,
            'yyyy-MM-dd',
            'en-IN',
            'GMT+0530'
          )
          : '',
        bookingadvtamt: this.contractForm.get('bookingadvtamt').value,
        balamt: this.contractForm.get('balamt').value,
        instruction: this.contractForm.get('instruction').value,
      };

      // console.log('contractForm data', data);

      this.service.insertContractDetails(data).subscribe((res) => {
        window.alert('Contract Added Successfully...');
        this.ngOnInit();
      });
    } else {
      window.alert('Please select the Advertiser..');
    }
  }

  

  updateContract() {
    let data = {
      contract_id: this.contract_id,
      advt_id: this.advt_id1,
      contractno: this.contractForm.get('contractno').value,
      contractdate: this.contractForm.get('contractdate').value
        ? formatDate(
          this.contractForm.get('contractdate').value,
          'yyyy-MM-dd',
          'en-IN',
          'GMT+0530'
        )
        : '',
      repname: this.contractForm.get('repname').value,
      offrepname: this.contractForm.get('offrepname').value,
      advtname: this.contractForm.get('advtname').value,
      priviousbal: this.contractForm.get('priviousbal').value,
      contractamt: this.contractForm.get('contractamt').value,
      discountamt: this.contractForm.get('discountamt').value,
      gstie: this.contractForm.get('gstie').value,
      netamt: this.contractForm.get('netamt').value,
      netgstprice: this.contractForm.get('netgstprice').value,
      nettotal: this.contractForm.get('nettotal').value,
      contractfromdate: this.contractForm.get('contractfromdate').value
        ? formatDate(
          this.contractForm.get('contractfromdate').value,
          'yyyy-MM-dd',
          'en-IN',
          'GMT+0530'
        )
        : '',
      contracttodate: this.contractForm.get('contracttodate').value
        ? formatDate(
          this.contractForm.get('contracttodate').value,
          'yyyy-MM-dd',
          'en-IN',
          'GMT+0530'
        )
        : '',
      bookingadvtamt: this.contractForm.get('bookingadvtamt').value,
      balamt: this.contractForm.get('balamt').value,
      instruction: this.contractForm.get('instruction').value,
    };

    const req: any = {
      "rep_name": this.repName,
      "issue_name": ""
    }

    // console.log('updateContract data', data);
    this.service.updateContractDetails(data).subscribe((res) => {
      window.alert('Contract Details Updated Successfully...');
      this.contractshow = false;
      this.advtshow = false;
      this.ngOnInit();
    });
  }

  cancelContract() {
    if (this.contract_id > 0) {
      if (
        window.confirm(
          'This Operation is no reversable. Do you want to proceed!'
        )
      ) {
        this.service
          .cancelContractDetails(this.contract_id)
          .subscribe((data) => {
            // console.log(data);
            window.alert('Contract cancel successfully...');
            this.ngOnInit();
          });
      }
    } else {
      window.alert('Please select the Contract..');
    }
  }

  getAdvtMagDetails() {
    this.advt_mag_name_details = [];
    this.stateGroups = [];
    let abc;
    this.service.getAdvtMagNames().subscribe((data) => {
      // console.log(data);
      for (let index = 0; index < Object.keys(data).length; index++) {
        // console.log(data[index]);
        this.advt_mag_name_details.push({
          id: data[index]['id'],
          is_active: data[index]['is_active'],
          issue_name: data[index]['issue_name'],
          category: data[index]['category'],
          publishing_date: data[index]['publishing_date'].split(','),
        });

        if (index == 0) {
          // console.log(
          //   data.filter((a) => a.category == data[index]['category'])
          // );
        }
      }

      // console.log('Issue/Grantha || stategroup', this.stateGroups);
      //this.advt_mag_name_details = data
    });
  } //end of getAdvtMagDetails

  getPincode(event) {
    if (this.ionicForm.get('pincode').valid) {
      // console.log(this.ionicForm.get('pincode').value);
      this.service
        .getPincodeData(parseInt(this.ionicForm.get('pincode').value))
        .subscribe((data) => {
          this.getCountry({ value: data[0]['country_name'] });
          setTimeout(() => {
            this.getState({ value: data[0]['state_name'] });
          }, 100);
          setTimeout(() => {
            this.getDistricts({ value: data[0]['district_name'] });
          }, 500);
          this.ionicForm.get('country').setValue(data[0]['country_name']);
          this.ionicForm.get('state').setValue(data[0]['state_name']);
          this.ionicForm.get('district').setValue(data[0]['district_name']);
          setTimeout(() => {
            this.ionicForm.get('taluka').setValue(data[0]['taluka_name']);
          }, 500);
        });
    }
  } //getPincode

  getCountry(event) {
    // console.log(event);
    // console.log(event.value);
    let country = this.countries.find((a) => a.country_name == event.value);
    if (country != undefined) {
      this.service.getState(country['country_code']).subscribe((data) => {
        this.states = data;
        // console.log(this.states);
      });
    } else {
    }
  } //getcountry

  showigst = false;
  getState(event) {
    // console.log(event);
    // console.log(event.value);
    // this.selectedState = this.states['state_id'];
    let state = this.states.find((a) => a.state_name == event.value);
    // console.log(state);
    // console.log(this.states);
    if (state != undefined) {
      if (
        state.state_name != 'Maharashtra' ||
        state.state_name != 'महाराष्ट्र'
      ) {
        this.showigst = true;
      } else {
        this.showigst = false;
      }

      this.service
        .getDistrict(parseInt(state['state_id']))
        .subscribe((data) => {
          this.cities = data;
          this.selectedCity = this.cities['district_name'];
          // console.log(this.cities);
        });
    } else {
    }
  } //get state

  getDistricts(event) {
    console.log(event.value);
    this.selectedCity = this.cities['district_id'];
    let district = this.cities.find((a) => a.district_name == event.value);
    if (district != undefined) {
      
      const req={
        "district_id":[district['district_id']]
      }
      this.service.getTaluka1(req).subscribe((data) => {
        this.talukas = data;
        this.selectedTaluka = this.talukas['taluka_name'];
      });
    }
  }

  validmobile = false;
  validemail = false;
  validdob = false;
  validateMobile(event) {
    if (this.ionicForm.get('mobileNumber').valid) {
      this.validmobile = true;
    } else {
      this.validmobile = false;
    }
  }

  validateEmail(event) {
    if (this.ionicForm.get('email').valid) {
      this.validemail = true;
    } else {
      this.validemail = false;
    }
  }

  validatedob(event) {
    this.ionicForm
      .get('dob')
      .setValue(
        formatDate(
          new Date(event.target.value),
          'yyyy-MM-dd',
          'en-IN',
          'GMT+0530'
        )
      );
    if (this.ionicForm.get('dob').valid) {
      this.validdob = true;
    } else {
      this.validdob = false;
    }
  }
  searchAdvtName2() {
    console.log("nice");

  }

  getallcontarctdetails() {
    this.service.getAllContractDetails().subscribe((res) => {
      // console.log(res);
      this.contractData = res;
      this.getContractDetails();
      console.log(res, "off rep");
    });
  }

  getContractDetails() {
    for (
      let index = 0;
      index < Object.keys(this.contractData).length;
      index++
    ) {
      this.contractRepName.push({
        name: this.contractData[index]['rep_name'],
      });
      this.contractOffRepName.push({
        name: this.contractData[index]['off_rep_name'],
      });
      this.contractAdvtName.push({
        name: this.contractData[index]['advt_name'],
      });
    }

    this.contractRepName = this.uniqueid(this.contractRepName, (a) => a.name);
    this.contractOffRepName = this.uniqueid(
      this.contractOffRepName,
      (a) => a.name
    );
    this.contractAdvtName = this.uniqueid(this.contractAdvtName, (a) => a.name);
  }

  dataforreceipt() {
    this.service.dataforrec().subscribe((data: any) => {
      console.log("tow receipt data=", data);
      this.data_for_two_receipt = (data as any);;
      // this.ngOnInit();
    });
  }
  getNewAdvtName() {
    this.service.getNewAdvtName().subscribe((data: any) => {
      console.log("new advt receipt data=", data);
      this.newadvtname = (data as any);;
      // this.ngOnInit();
    });
  }

  getNewRepList() {
    this.service.getNewRepList().subscribe((data: any) => {
      console.log("ADVT new list rep Data=", data);
      this.advtnewreplistForm = (data as any);;
      // this.ngOnInit();
    });
  }
  withoutpagenopatrak() {
    this.service.withoutpagenopatrak().subscribe((data: any) => {
      console.log("prasiddhi ptrak without number", data);
      this.PPwithoutnumber = (data as any);;
      // this.ngOnInit();
      this.getData1()
    });
  }
  PPwithoutnumber1:any
  p: number = 1; // Current page number, starts from 1
  itemsPerPage: number = 10; 
  getData1() {
    // Call your data service to get data
      this.PPwithoutnumber1= this.PPwithoutnumber; // Assign response data to your data array
      this.setPage(1); // Set the initial page
    
  }

  setPage(page: number) {
    // Calculate start and end index of items for the current page
    let startIndex = (page - 1) * this.itemsPerPage;
    let endIndex = Math.min(startIndex + this.itemsPerPage - 1, this.PPwithoutnumber.length - 1);
    
    // Extract items for the current page
    this.PPwithoutnumber1 = this.PPwithoutnumber.slice(startIndex, endIndex + 1);
    this.p = page; // Update current page number
    console.log("pagination",this.p)
  }

  getNewNameList() {
    this.service.getNewNameList().subscribe((data: any) => {
      console.log("ADVT new list name Data=", data);
      this.advtnewNewlistForm = (data as any);;
      // this.ngOnInit();
    });
  }
  // getCreditNoteMain(req1:any) {
  //   this.service.getCreditNoteMain(req1).subscribe((data:any) => {
  //      console.log("ADVT new Credit name Data=",data);
  //     this.advtCreditNote = (data as any);;
  //     // this.ngOnInit();
  //   });
  // }


  getAdvtNames(event) {
    if (event.target.value.length > 2) {
      this.service.getAdvtNames(event.target.value).subscribe((res) => {
        this.advt_names = res;
        // console.log(res);
      });
    }
  }

  issueName;
  advtSize;
  advtsizebyCategory;
  getFilterMagPublishDates = ['10-10-2021', ''];
  getMagIssueDatesandNames;
  filterdMagIssueDatesandNames = [];
  getIssues(e) {
    // console.log(e);
    this.issueName = e.value;
    // console.log('jgfdfgh', this.issueName);
    this.service.getAdvtSizeByCategory(e.value).subscribe((data) => {
      this.advtsizebyCategory = data;
    });
    // console.log('hi', e.option.group.label);
    this.rangeRegistration.reset();
    this.cust_mobile_filter = '';
    this.rep_name = '';
    // console.log(
    //   'advt_mag_name_details',
    //   this.advt_mag_name_details.filter((a) => a.issue_name == this.issueName)
    // );
    // this.getFilterMagPublishDates = this.advt_mag_name_details.filter(
    //   (a) => a.issue_name == this.issueName
    // )[0]['publishing_date'];
    // console.log(this.getFilterMagPublishDates);
    this.service.getAdvtDatesAndNames(e.value).subscribe((data) => {
      this.getMagIssueDatesandNames = data;
      // console.log('Issue Dates and Names:', this.getMagIssueDatesandNames);
      for (
        let index = 0;
        index < Object.keys(this.getMagIssueDatesandNames).length;
        index++
      ) {
        this.filterdMagIssueDatesandNames.push({
          value: `${formatDate(
            this.getMagIssueDatesandNames[index]['issueDate'],
            'dd/MM/yyyy',
            'en-IN',
            'GMT+0530'
          )},${this.getMagIssueDatesandNames[index]['issueName']},${formatDate(
            this.getMagIssueDatesandNames[index]['publishedDate'],
            'dd/MM/yyyy',
            'en-IN',
            'GMT+0530'
          )}`,
          data: `${formatDate(
            this.getMagIssueDatesandNames[index]['issueDate'],
            'dd/MM/yyyy',
            'en-IN',
            'GMT+0530'
          )} ${this.getMagIssueDatesandNames[index]['issueName']} ${formatDate(
            this.getMagIssueDatesandNames[index]['publishedDate'],
            'dd/MM/yyyy',
            'en-IN',
            'GMT+0530'
          )}`,
        });
      }
      // console.log(
      //   'filterdMagIssueDatesandNames:',
      //   this.filterdMagIssueDatesandNames
      // );
    });
    
    this.issueGranthName = this.issueName;

    this.filteredOptions5 = this.issueForm.get('issuedates').valueChanges.pipe(
      startWith(''),
      map((value) => (typeof value === 'string' ? value : value.data)),
      map((data) =>
        data ? this._filter1(data) : this.filterdMagIssueDatesandNames.slice()
      )
    );
  }

  getAdvtSize(e) {
    
    // console.log(e);
    this.advtSize = e.value;
  }
icount:number=0;
  addcate(e) {
    
    // this.advtSize = e.value;
    const data:any = {
      "issue_name":this.issueForm.value.issue,
      "issue_date" : this.issueForm.value.issuedates,
    }
    // console.log(e);
    
    this.service.getAdvtcatsize(data).subscribe((res) => {
      // console.log(res);

      this.advtcatsize = (res as any);;
    });

  }

  getDates;
  stringdates;
  getAdvtDates(e) {
    if(e.value[0]=='ALL'){
      this.getDates =e.value;
      return
    }
    this.getDates =e.value;
    // this.getDates = this.getDates.filter((a) => a != 'all');
    // console.log('getDates', this.getDates);
    this.stringdates = this.getDates.toString();
    this.issueDates = this.stringdates;
    this.issueDates1 =  this.stringdates.split(',');
    debugger;

     
   
    this.getMagIssueDatesandNames.forEach(element => {
      debugger
      if(this.issueDates1[0]== element.id){
        this.newissuedatepp = element.issueName
        this.issuedatespp = element.issueDate
      }
    });
    console.log(this.newissuedatepp, "issuename")
    console.log(this.issuedatespp, "issuedate")

    // this.getMagIssueDatesandNames1.forEach(element => {
    //   debugger
    //   this.issueDates1.forEach(element1 => {
    //     debugger
    //     if (element.id === element1.id) {
    //     element.id == element1.id
    //     this.newissuedatepp = element.issueName
    //     this.issuedatespp = element.issueDate
    //     }
    //   });
    // });
    // console.log(this.newissuedatepp, "issuename")
    // console.log(this.issuedatespp, "issuedate")
  }
  
  getDatess;
  stringdatess;
  getAdvtPaymentDates(e) {
    if(e.value[0]=='ALL'){
      this.getDatess =e.value;
      return
    }
    this.getDatess =e.value;
    
    this.stringdatess = this.getDatess.toString();
    this.issueDates = this.stringdatess;
    this.issueDates1 =  this.stringdatess.split(',');

    this.getMagIssueDatesandNames.forEach(element => {
      if(this.issueDates1[0]== element.id){
        this.newissuedatepp = element.issueName
        this.issuedatespp = element.issueDate
      }
    });
    console.log(this.newissuedatepp, "issuename")
    console.log(this.issuedatespp, "issuedate")

  }

  

  s_no;
  getAnkSerialNo(issuedate) {
    // console.log(issuedate);
    this.service.getNextIssueSerialNo(issuedate).subscribe((res) => {
      // console.log(res);
      if (Object.keys(res).length > 0) {
        this.s_no = parseInt(res[0]['ank_no']) + 1;
      } else {
        this.s_no = 1;
      }
      // console.log(this.s_no);
    });
  }

  addAdvt() {
    debugger;
    
    if(this.AdvtDetails.length==0){
      if(this.advtcatsize!=undefined){
        if(this.advtcatsize[0].advt_count==null || this.advtcatsize[0].advt_count==undefined){
          this.advtplus = 1;
        }
        else{
          this.advtplus= parseInt(this.advtcatsize[0].advt_count)+1
        }
      }else{
        this.advtplus = 1;
      }
     
      this.AdvtDetails.push({
      
        s_no: this.advtplus,
        issue: this.issueName,
        advtsize: this.issueForm.value.advtsize,
        advtdates: this.stringdates,
        issuedate : this.issueForm.value.issuedates,
      });
      debugger;
    }
    else{
      if( this.issueForm.value.issue=='नियिमत अंक'){
        const matchingObjects = this.AdvtDetails.filter(item => (item.issue === this.issueForm.value.issue) && (item.issuedate===this.issueForm.value.issuedates)) ;

          if (matchingObjects.length > 0) {
            const lastObject = matchingObjects[matchingObjects.length - 1];
            this.advtplus = parseInt(lastObject.s_no, 10) + 1;
            console.log('Found object:', lastObject);
          } else {
            // this.advtplus = 1;
            if(this.advtcatsize==null || this.advtcatsize==undefined){
              this.advtplus = 1;
            }
            else{
              this.advtplus= parseInt(this.advtcatsize[0].advt_count)+1
            }
          }
          console.log(this.advtcatsize, "this.advtcatsize");
        }

        else if( this.issueForm.value.issue=='ग्रंथ'){
        const matchingObjects = this.AdvtDetails.filter(item => (item.issue === this.issueForm.value.issue) && (item.issuedate===this.issueForm.value.issuedates)) ;

          if (matchingObjects.length > 0) {
            const lastObject = matchingObjects[matchingObjects.length - 1];
            this.advtplus = parseInt(lastObject.s_no, 10) + 1;
            console.log('Found object:', lastObject);
          } else {
            // this.advtplus = 1;
            if(this.advtcatsize==null || this.advtcatsize==undefined){
              this.advtplus = 1;
            }
            else{
              this.advtplus= parseInt(this.advtcatsize[0].advt_count)+1
            }
          }
          console.log(this.advtcatsize, "this.advtcatsize");
        }
        else if( this.issueForm.value.issue=='विशेषांक'){
          const matchingObjects = this.AdvtDetails.filter(item => (item.issue === this.issueForm.value.issue) && (item.issuedate===this.issueForm.value.issuedates)) ;
  
            if (matchingObjects.length > 0) {
              const lastObject = matchingObjects[matchingObjects.length - 1];
              this.advtplus = parseInt(lastObject.s_no, 10) + 1;
              console.log('Found object:', lastObject);
            } else {
              // this.advtplus = 1;
              if(this.advtcatsize==null || this.advtcatsize==undefined){
                this.advtplus = 1;
              }
              else{
                this.advtplus= parseInt(this.advtcatsize[0].advt_count)+1
              }
            }
            console.log(this.advtcatsize, "this.advtcatsize");
          }

          else if( this.issueForm.value.issue=='प्राेग्राम'){
            const matchingObjects = this.AdvtDetails.filter(item => (item.issue === this.issueForm.value.issue) && (item.issuedate===this.issueForm.value.issuedates)) ;
    
              if (matchingObjects.length > 0) {
                const lastObject = matchingObjects[matchingObjects.length - 1];
                this.advtplus = parseInt(lastObject.s_no, 10) + 1;
                console.log('Found object:', lastObject);
              } else {
                // this.advtplus = 1;
                if(this.advtcatsize==null || this.advtcatsize==undefined){
                  this.advtplus = 1;
                }
                else{
                  this.advtplus= parseInt(this.advtcatsize[0].advt_count)+1
                }
              }
              console.log(this.advtcatsize, "this.advtcatsize");
            }

        this.AdvtDetails.push({
          s_no: this.advtplus,
          issue: this.issueName,
          advtsize: this.issueForm.value.advtsize,
          advtdates: this.stringdates,
          issuedate : this.issueForm.value.issuedates,
        });
      }
    }
  

  modifyAdvt() {
    console.log(this.AdvtDetails);
    this.AdvtDetails[0]['issue'] = this.issueName;
    this.AdvtDetails[0]['advtsize'] = this.advtSize;
    this.AdvtDetails[0]['advtdates'] = this.stringdates;
  }

  deleteAdvt(i) {
    if (window.confirm('Do you really want to remove ?')) {
      this.AdvtDetails.splice(i, 1);
    }
  }

  displayChequqField = false;

  getcheque(event) {
    const value = event.value;
    if (value == 'cheque') {
      this.displayChequqField = true;
    } else {
      this.displayChequqField = false;
    }
  }

  displayPartAmount = false;
  getPartPayment(event) {
    const value = event.value;
    if (value == 'Y') {
      this.displayPartAmount = true;
    } else {
      this.displayPartAmount = false;
    }
  }

  getAdvtPartPayment(event) {
    if (
      parseInt(this.issueForm.value['partAmount']) >
      parseInt(this.issueForm.value['amount'])
    ) {
      window.alert('Please enter amount less than Total Amount!');
      this.issueForm.get('partAmount').setValue(0);
    } else {
      this.issueForm
        .get('remainingAmount')
        .setValue(
          parseInt(this.issueForm.value['amount']) -
          parseInt(this.issueForm.value['partAmount'])
        );
    }
  }

  advtamount = 0;
  discount = 0;
  sgstamt = 0;
  igstamt = 0;
  totalamt = 0;
  remainingamt = 0;
  amountTobePay = 0;

  gstie = '';
  changeType(type) {
    this.gstie = type.value;
    // console.log(this.gstie);
    let state_name = this.ionicForm.get('state').value;
    if (state_name === 'Maharashtra' || state_name === 'महाराष्ट्र') {
      // console.log(state_name);
      this.showigst = false;
    } else {
      this.showigst = true;
    }
    this.getAdvtDiscount();
  }

  getAdvtDiscount() {
    this.advtamount = parseInt(this.issueForm.get('advtamout').value);
    if (this.advtamount != 0) {
      // if(this.ionicForm.get('gstie').value)
      if (this.issueForm.get('advtdiscount').value != '') {
        this.discount = parseInt(this.issueForm.get('advtdiscount').value);
        // console.log(this.discount);
        if (this.gstie == 'gstInclude') {
          if (this.showigst) {
            this.igstamt = Math.round(
              ((this.advtamount - this.advtamount * (this.discount / 100)) *
                4.76) /
              100
            );
            this.issueForm.get('igstamount').setValue(this.igstamt);
            this.totalamt = Math.round(
              this.advtamount - this.advtamount * (this.discount / 100)
            );
            this.issueForm.get('amount').setValue(this.totalamt);
            this.issueForm
              .get('totalamt')
              .setValue(
                this.advtamount -
                this.advtamount * (this.discount / 100) -
                this.igstamt
              );
          } else {
            this.sgstamt = Math.round(
              ((this.advtamount - this.advtamount * (this.discount / 100)) *
                2.38) /
              100
            );
            this.issueForm
              .get('gstamount')
              .setValue(this.sgstamt + this.sgstamt);
            this.totalamt = Math.round(
              this.advtamount - this.advtamount * (this.discount / 100)
            );
            this.issueForm.get('amount').setValue(this.totalamt);
            this.issueForm
              .get('totalamt')
              .setValue(
                this.advtamount -
                this.advtamount * (this.discount / 100) -
                (this.sgstamt + this.sgstamt)
              );
          }
        } else if (this.gstie == 'gstExclude') {
          if (this.showigst) {
            this.igstamt = Math.round(
              ((this.advtamount - this.advtamount * (this.discount / 100)) *
                5) /
              100
            );
            this.issueForm.get('igstamount').setValue(this.igstamt);
            this.totalamt = Math.round(
              this.advtamount -
              this.advtamount * (this.discount / 100) +
              this.igstamt
            );
            this.issueForm.get('amount').setValue(this.totalamt);
            this.issueForm
              .get('totalamt')
              .setValue(
                this.advtamount - this.advtamount * (this.discount / 100)
              );
          } else {
            this.sgstamt = Math.round(
              ((this.advtamount - this.advtamount * (this.discount / 100)) *
                2.5) /
              100
            );
            this.issueForm
              .get('gstamount')
              .setValue(this.sgstamt + this.sgstamt);
            this.totalamt = Math.round(
              this.advtamount -
              this.advtamount * (this.discount / 100) +
              this.sgstamt +
              this.sgstamt
            );
            this.issueForm.get('amount').setValue(this.totalamt);
            this.issueForm
              .get('totalamt')
              .setValue(
                this.advtamount - this.advtamount * (this.discount / 100)
              );
          }
        }
      } else {
        this.discount = 0;
        // console.log(this.discount);
        if (this.gstie == 'gstInclude') {
          if (this.showigst) {
            this.igstamt = Math.round(
              ((this.advtamount - this.advtamount * (this.discount / 100)) *
                4.76) /
              100
            );
            this.issueForm.get('igstamount').setValue(this.igstamt);
            this.totalamt = Math.round(
              this.advtamount - this.advtamount * (this.discount / 100)
            );
            this.issueForm.get('amount').setValue(this.totalamt);
            this.issueForm
              .get('totalamt')
              .setValue(
                this.advtamount -
                this.advtamount * (this.discount / 100) -
                this.igstamt
              );
          } else {
            this.sgstamt = Math.round(
              ((this.advtamount - this.advtamount * (this.discount / 100)) *
                2.38) /
              100
            );
            this.issueForm
              .get('gstamount')
              .setValue(this.sgstamt + this.sgstamt);
            this.totalamt = Math.round(
              this.advtamount - this.advtamount * (this.discount / 100)
            );
            this.issueForm.get('amount').setValue(this.totalamt);
            this.issueForm
              .get('totalamt')
              .setValue(
                this.advtamount -
                this.advtamount * (this.discount / 100) -
                (this.sgstamt + this.sgstamt)
              );
          }
        } else if (this.gstie == 'gstExclude') {
          if (this.showigst) {
            this.igstamt = Math.round(
              ((this.advtamount - this.advtamount * (this.discount / 100)) *
                5) /
              100
            );
            this.issueForm.get('igstamount').setValue(this.igstamt);
            this.totalamt = Math.round(
              this.advtamount -
              this.advtamount * (this.discount / 100) +
              this.igstamt
            );
            this.issueForm.get('amount').setValue(this.totalamt);
            this.issueForm
              .get('totalamt')
              .setValue(
                this.advtamount - this.advtamount * (this.discount / 100)
              );
          } else {
            this.sgstamt = Math.round(
              ((this.advtamount - this.advtamount * (this.discount / 100)) *
                2.5) /
              100
            );
            this.issueForm
              .get('gstamount')
              .setValue(this.sgstamt + this.sgstamt);
            this.totalamt = Math.round(
              this.advtamount -
              this.advtamount * (this.discount / 100) +
              this.sgstamt +
              this.sgstamt
            );
            this.issueForm.get('amount').setValue(this.totalamt);
            this.issueForm
              .get('totalamt')
              .setValue(
                this.advtamount - this.advtamount * (this.discount / 100)
              );
          }
        }
      }
    } else {
      this.issueForm.get('gstamount').setValue(this.advtamount);
      this.issueForm.get('amount').setValue(this.advtamount);
      this.issueForm
        .get('totalamt')
        .setValue(this.advtamount - this.advtamount * (this.discount / 100));
    }
  }

  openModal() { }
  getSelectedRepId;
  getSelectedRep;
  repCommAmt;
  SelectRepresentative(option) {
    // console.log(option);
    this.getSelectedRepId = option['id'];
    this.getSelectedRep = this.getAllRepList.find(
      (a) => a['id'] == parseInt(this.getSelectedRepId)
    );
    // console.log(this.getSelectedRep);
    // this.repCommAmt = parseInt(this.getSelectedRep['rep_advt_comm']);
    let data = this.issue_seller_name.find((a) => a['id'] == option['id']);
    // console.log('data', data);
    this.ionicForm.get('rep_mobile_no').setValue(data['mob_no']);
  }

  setAdvtSubData(repname) {
    this.issueForm.get('repname').setValue(repname);
    let offrepname = this.filterRepresentative({ name: repname });
    if (offrepname) {
      this.issueForm.get('rep_mobile_no').setValue(offrepname[0]['rep_mob_no']);
      // console.log(offrepname);
      this.issueForm.get('offrepname').setValue(offrepname[0]['off_rep_name']);
    }
    this.contractForm
      .get('advtname')
      .setValue(this.ionicForm.get('name').value);
  }

  selected_rep_name1(event, option) {
    if (event.isUserInput) {
      let data = this.filterRepresentative(option);
      this.contractForm.get('offrepname').setValue(data[0]['off_rep_name']);
      this.setAdvtSubData(data[0]['rep_name']);
    }
  }

  filterRepresentative(option) {
    return this.advtwisereplist.filter((a) => a['rep_name'] == option['name']);
  }

  selected_rep_name(event, option) {
    if (event.isUserInput) {
      let data = this.filterRepresentative(option);
      this.issueForm.get('rep_mobile_no').setValue(data[0]['rep_mob_no']);
      this.issueForm.get('offrepname').setValue(data[0]['off_rep_name']);
    }
    this.isValid();
  }

  saveAdvtSubDetails() {
    

    this.service.setnewbookingnumber().subscribe((res) => {
      this.latestbookingnumber = (res as any);;

      console.log(this.latestbookingnumber, "latestbookingnumber")
      if (this.advt_id1 > 0) {
        if (this.imageChange) {
          this.uploader.uploadAll();
        }
  
        setTimeout(() => {
          let data = {
            advt_id: this.advt_id1,
            contract_no: this.contractshow ? this.contract_no : '',
            bookingdate: this.issueForm.get('bookingdate').value
              ? formatDate(
                this.issueForm.get('bookingdate').value,
                'yyyy-MM-dd',
                'en-IN',
                'GMT+0530'
              )
              : '',
            bookingno: this.latestbookingnumber.new_booking_no,
            advtname: this.issueForm.get('advtname').value,
            custname: this.issueForm.get('custname').value,
            repname: this.issueForm.get('repname').value,
            rep_mobile_no: this.issueForm.get('rep_mobile_no').value,
            offrepname: this.issueForm.get('offrepname').value,
            ro_number: this.issueForm.get('ro_number').value,
            ro_dates: this.issueForm.get('ro_dates').value
              ? formatDate(
                this.issueForm.get('ro_dates').value,
                'yyyy-MM-dd',
                'en-IN',
                'GMT+0530'
              )
              : '',
            instruction: this.issueForm.get('instruction').value,
            otherdetls: this.issueForm.get('otherdetls').value,
            pageno: this.issueForm.get('pageno').value,
            advtamout: this.issueForm.get('advtamout').value,
            advtdiscount: this.issueForm.get('advtdiscount').value,
            gstie: this.gstie,
            cgst: this.sgstamt,
            sgst: this.sgstamt,
            igst: this.igstamt,
            totalamt: this.issueForm.get('totalamt').value,
            amount: this.issueForm.get('amount').value,
            ispartpayment: this.issueForm.get('ispartpayment').value,
            partAmount: this.issueForm.get('partAmount').value,
            remainingAmount: this.issueForm.get('remainingAmount').value,
            paymentmode: this.issueForm.get('paymentmode').value,
            chequeno: this.issueForm.get('checkno').value,
            receiptdate: this.issueForm.get('receiptdate').value
              ? formatDate(
                this.issueForm.get('receiptdate').value,
                'yyyy-MM-dd',
                'en-IN',
                'GMT+0530'
              )
              : '',
            receiptno: this.issueForm.get('receiptno').value,
            document_name:
              this.issueForm.get('document_name').value == ''
                ? this.imgPath
                : this.issueForm.get('document_name').value,
          };
  
          // console.log('saveAdvtSubDetails', data);
          this.service.insertAdvtSubDetails(data).subscribe((res) => {
            
  
            for (
              let index = 0;
              index < Object.keys(this.AdvtDetails).length;
              index++
            ) {
              let newadvtdtl = {
                advtsizeno: this.AdvtDetails[index]['s_no'],
                s_no: this.AdvtDetails[index]['s_no'],
                issueName: this.AdvtDetails[index]['issue'],
                advtsize: this.AdvtDetails[index]['advtsize'],
                advt_dates: this.AdvtDetails[index]['advtdates'],
                id: res['id'],
              };
              this.service
                .insertNewadvtdetails(newadvtdtl)
                .subscribe((details) => {
                  // console.log(details);
                });
            }
  
            if (this.contractshow && this.contract_id > 0) {
              this.service
                .updateContractBalAmount(
                  this.contract_id,
                  this.bookingAdvtAmount +
                  parseInt(this.issueForm.get('amount').value),
                  this.contractBalAmt -
                  parseInt(this.issueForm.get('amount').value)
                )
                .subscribe((res) => {
                  console.log(res);
                });
            }
  
            setTimeout(() => {
              window.alert('Advertiesment Added Successfully...');
              this.contractshow = false;
              this.advtshow = false;
              this.AdvtDetails = [];
              this.advtSubDetails = [];
              // console.log(this.owner_name, this.mob_no1, this.advt_name1);
              localStorage.setItem('ownername', this.owner_name);
              localStorage.setItem('mob_no1', this.mob_no1);
              localStorage.setItem('advt_name1', this.advt_name1);
              this.ngOnInit();
              this.owner_name = localStorage.getItem('ownername');
              this.mob_no1 = localStorage.getItem('mob_no1');
              this.advt_name1 = localStorage.getItem('advt_name1');
              this.selected_name();
              localStorage.removeItem('ownername');
              localStorage.removeItem('mob_no1');
              localStorage.removeItem('advt_name1');
              this.myInputField.nativeElement.focus();
            }, 2000);
          });
        }, 1000);
      } else {
        window.alert('Please select Advertiser...');
      }
      
    });
   
  }

  updateAdvtSubDetails() {
    
    // console.log('running fine');
    // console.log(this.issueForm.invalid);
    if( this.issueForm.get('ro_dates').value==""){
      this.issueForm.patchValue({
        ro_dates:'0000-00-00'
      })
      
    }
    if (!this.issueForm.invalid) {
      // console.log('running fine 1');
      debugger;
      this.service.getAdvtcheckforudate(this.advt_sub_id).subscribe((res) => {
        // console.log(res);

  
        this.advtupdatestatus = (res as any);;
        console.log(this.advtupdatestatus, "status for advt update")
      });
      
      if (this.advtupdatestatus == 'N' || this.advtupdatestatus == null) {
        if (this.advt_sub_id > 0) {
          // console.log('running fine 2');
          // if (this.imageChange) {
          //   this.uploader.uploadAll();
          // }

          // setTimeout(() => {
          let data = {
            advt_sub_id: this.advt_sub_id,
            advt_id: this.advt_id1,
            contract_no: this.contractshow ? this.contract_no : '',
            bookingdate:
              this.issueForm.get('bookingdate').value == '0000-00-00'
                ? ''
                : formatDate(
                  this.issueForm.get('bookingdate').value,
                  'yyyy-MM-dd',
                  'en-IN',
                  'GMT+0530'
                ),
            bookingno: this.issueForm.get('bookingno').value,
            advtname: this.issueForm.get('advtname').value,
            custname: this.issueForm.get('custname').value,
            repname: this.issueForm.get('repname').value,
            rep_mobile_no: this.issueForm.get('rep_mobile_no').value,
            offrepname: this.issueForm.get('offrepname').value,
            ro_number: this.issueForm.get('ro_number').value,
            ro_dates:
              this.issueForm.get('ro_dates').value == '0000-00-00'
                ? ''
                : formatDate(
                  this.issueForm.get('ro_dates').value,
                  'yyyy-MM-dd',
                  'en-IN',
                  'GMT+0530'
                ),
            instruction: this.issueForm.get('instruction').value,
            otherdetls: this.issueForm.get('otherdetls').value,
            pageno: this.issueForm.get('pageno').value,
            advtamout: this.issueForm.get('advtamout').value,
            advtdiscount: this.issueForm.get('advtdiscount').value,
            gstie: this.gstie,
            cgst: this.sgstamt,
            sgst: this.sgstamt,
            igst: this.igstamt,
            totalamt: this.issueForm.get('totalamt').value,
            amount: this.issueForm.get('amount').value,
            ispartpayment: this.issueForm.get('ispartpayment').value,
            partAmount: this.issueForm.get('partAmount').value,
            remainingAmount: this.issueForm.get('remainingAmount').value,
            paymentmode: this.issueForm.get('paymentmode').value,
            chequeno: this.issueForm.get('checkno').value,
            receiptdate:
              this.issueForm.get('receiptdate').value == '0000-00-00'
                ? ''
                : formatDate(
                  this.issueForm.get('receiptdate').value,
                  'yyyy-MM-dd',
                  'en-IN',
                  'GMT+0530'
                ),
            receiptno: this.issueForm.get('receiptno').value,
            document_name:
              this.issueForm.get('document_name').value == ''
                ? this.imgPath
                : this.issueForm.get('document_name').value,
          };

          // console.log('updateAdvtSubDetails', data);
          this.service.updateAdvtSubDetails(data).subscribe((res) => {
            debugger;
            for (
              let index = 0;
              index < Object.keys(this.AdvtDetails).length;
              index++
            ) {
              let newadvtdtl = {
                issueName: this.AdvtDetails[index]['issue'],
                advtsize: this.AdvtDetails[index]['advtsize'],
                advt_dates: this.AdvtDetails[index]['advtdates'],
                id: this.ds_advtId,
              };
              this.service
                .updateNewadvtdetails(newadvtdtl)
                .subscribe((details) => {
                  // console.log(details);
                });
            }

            // if (this.contractshow && this.contract_id > 0) {
            //   this.service
            //     .updateContractBalAmount(
            //       this.contract_id,
            //       this.bookingAdvtAmount +
            //         parseInt(this.issueForm.get('amount').value),
            //       this.contractBalAmt -
            //         parseInt(this.issueForm.get('amount').value)
            //     )
            //     .subscribe((res) => {
            //       console.log(res);
            //     });
            // }

            setTimeout(() => {
              window.alert('Advertiesment Updated Successfully...');
              this.contractshow = false;
              this.advtshow = false;
              this.AdvtDetails = [];
              this.advtSubDetails = [];
              // console.log(this.owner_name, this.mob_no1, this.advt_name1);
              localStorage.setItem('ownername', this.owner_name);
              localStorage.setItem('mob_no1', this.mob_no1);
              localStorage.setItem('advt_name1', this.advt_name1);
              this.ngOnInit();
              this.owner_name = localStorage.getItem('ownername');
              this.mob_no1 = localStorage.getItem('mob_no1');
              this.advt_name1 = localStorage.getItem('advt_name1');
              this.selected_name();
              localStorage.removeItem('ownername');
              localStorage.removeItem('mob_no1');
              localStorage.removeItem('advt_name1');
              this.myInputField.nativeElement.focus();
            }, 2000);
          });
          // }, 1000);
        } else {
          window.alert('Please select Advertisement Sub Details...');
        }
      } else {
        window.alert(
          'Already invoice is generated. Advt. sub details will not update.'
        );
      }
    } else {
      this.isValid();
    }
  }

  isrepValid: boolean = true;
  isValid() {
    this.isrepValid = this.issueForm.controls.repname.valid;
  }

  cancelAdvtSubDetails() {
    if (this.advt_sub_id > 0) {
      if (
        window.confirm(
          'This Operation is no reversable. Do you want to proceed!'
        )
      ) {
        this.service
          .cancelAdvtSubDetails(this.advt_sub_id)
          .subscribe((data) => {
            // console.log(data);
            window.alert('Advtertiserment Sub Details cancel successfully...');
            this.ngOnInit();
          });
      }
    } else {
      window.alert('Please select the Advtertiserment Sub Details..');
    }
  }

  totalAmount;
  submitAdd() {
    if (this.imageChange) {
      this.uploader.uploadAll();
    }

    let newadvt = {
      custname: this.ionicForm.get('custname').value,
      name: this.ionicForm.get('name').value,
      ownername: this.ionicForm.get('ownername').value,
      email: this.ionicForm.get('email').value,
      address: this.ionicForm.get('address').value,
      country: this.ionicForm.get('country').value,
      state: this.ionicForm.get('state').value['state_name'],
      taluka: this.ionicForm.get('taluka').value,
      district: this.ionicForm.get('district').value['name'],
      mobileNumber: this.ionicForm.get('mobileNumber').value,
      paymentmode: this.ionicForm.get('paymentmode').value,
      pincode: this.ionicForm.get('pincode').value,
      advtType: this.ionicForm.get('advtType').value,
      checkno: this.ionicForm.get('checkno').value,
      dob: this.ionicForm.get('dob').value,
      companyRegistration: this.ionicForm.get('companyRegistration').value,
      gstno: this.ionicForm.get('gstno').value,
      rep_id: parseInt(this.getSelectedRepId),
      cgst: this.sgstamt,
      sgst: this.sgstamt,
      igst: this.igstamt,
      gstie: this.gstie,
      ispartpayment: 'N',
      //partAmount: this.ionicForm.get('amount').value,
      partAmount: this.ionicForm.get('partAmount').value,
      remainingAmount: this.ionicForm.get('remainingAmount').value,
      receiptdate: this.ionicForm.get('receiptdate').value,
      receiptno: this.ionicForm.get('receiptno').value,
      advtamout: this.ionicForm.get('advtamout').value,
      advtdiscount: this.ionicForm.get('advtdiscount').value,
      tapshil: this.ionicForm.get('tapshil').value,
      document_name: this.ionicForm.get('document_name').value,
      landline_no: this.ionicForm.get('landline_no').value ? null : '',
      ro_number: this.ionicForm.get('ro_number').value,
      ro_dates: this.ionicForm.get('ro_dates').value,
    };

    this.totalAmount = this.ionicForm.get('amount').value;
    let newcredit: number = 0;
    // console.log(newcredit);

    let commamt = (this.totalAmount * this.repCommAmt) / 100;
    this.insertData(newadvt, newcredit, commamt);
  }

  insertData(newadvt, newcredit, commamt) {
    this.service.insertNewadvtPerson(newadvt).subscribe((advtperson) => {
      // console.log(advtperson);
      for (
        let index = 0;
        index < Object.keys(this.AdvtDetails).length;
        index++
      ) {
        let newadvtdtl = {
          ds_custmobile: this.ionicForm.get('mobileNumber').value,
          ds_repid: parseInt(this.getSelectedRepId),
          issueName: this.AdvtDetails[index]['issue'],
          advtsize: this.AdvtDetails[index]['advtsize'],
          advt_dates: this.AdvtDetails[index]['advtdates'],
          comment: this.ionicForm.get('comment').value,
          advt_id: advtperson['id'],
        };
        this.service.insertNewadvtdetails(newadvtdtl).subscribe((details) => {
          // console.log(details);
        });
      }
      this.service
        .insertCustRepAdvt(
          parseInt(this.ionicForm.get('mobileNumber').value),
          parseInt(this.getSelectedRepId)
        )
        .subscribe((a) => {
          this.service
            .insertAdvtComissionLimit(
              parseInt(this.getSelectedRepId),
              parseInt(this.ionicForm.get('mobileNumber').value),
              commamt,
              parseInt(advtperson['id'])
            )
            .subscribe((res) => {
              // console.log(res);
              window.alert('Advertisement has been added!');
              setTimeout(() => {
                this.ionicForm.reset();
              }, 2000);
            });
        });
    });
  }
 
  dateFilter = (d: Date | null): boolean => {
    if (!d) return false;

    const selectedDate = this.advtbillForm.get('date')?.value;
    if (!selectedDate) return true;

    const selectedMonth = new Date(selectedDate).getMonth();
    const selectedYear = new Date(selectedDate).getFullYear();

    return d.getMonth() === selectedMonth && d.getFullYear() === selectedYear;
  };


  startDate_registration = '';
  enddate_registration = '';
  dateIsEnable = false;
  getChangedDateRegistration() {
    this.cust_mobile_filter = '';
    this.issueName = '';
    this.rep_name = '';
    if (
      this.rangeRegistration.get('start').value != null &&
      this.rangeRegistration.get('end').value != null
    ) {
      // console.log(this.rangeRegistration.value);
      this.dateIsEnable = true;
      // console.log(
      //   formatDate(
      //     new Date(this.rangeRegistration.get('start').value),
      //     'yyyy-MM-dd',
      //     'en-IN',
      //     'GMT+0530'
      //   )
      // );
      // console.log(
      //   formatDate(
      //     new Date(this.rangeRegistration.get('end').value),
      //     'dd-MM-yyyy',
      //     'en-IN',
      //     'GMT+0530'
      //   )
      // );
      let startdate = formatDate(
        new Date(this.rangeRegistration.get('start').value),
        'yyyy-MM-dd',
        'en-IN',
        'GMT+0530'
      );
      let endDate = formatDate(
        new Date(this.rangeRegistration.get('end').value),
        'yyyy-MM-dd',
        'en-IN',
        'GMT+0530'
      );
      this.startDate_registration = startdate;
      this.enddate_registration = endDate;
    } else {
      this.dateIsEnable = false;
    }
  }

  getcust_mobile_filter(mobile) {
    // console.log(mobile);
    // console.log(this.cust_mobile_filter.length);
    // this.rangeRegistration.reset();
    // this.startDate_registration = '';
    // this.enddate_registration = '';
    this.advt_name = '';
    this.issueName = '';
    this.rep_name = '';
  }

  getallVouchersData = [];
  getAllVouchers() {
    this.service.getvoucherbyAdvt().subscribe((data) => {
      // console.log('getvoucherbyAdvt', data);
      this.getallVouchersData = data;
      for (let data1 of this.getallVouchersData) {
         this.totalTotal += parseInt(data1.price) || 0; 
         this.pendingTotal += parseInt(data1.remaining_amt) || 0;     
    
              }
    });
  }

  generateinv(event){

    let parts = event.target.value.split('-');

    if(parts[0]!="VSJ"){
      return;

    }
    let parts1 = parts[1].split('/');
    if(parts1[0].length==2){
      let val =parts[0]+"-"+ parts1[0] + "/"
      if(parts1[1]==undefined) {
        this.invoicenumber=val
        return
      }
      
      if(parts1[1].length==4){
        val =val + parts1[1] + "/"
        if(parts1[2]==undefined) {
          this.invoicenumber=val
          return
        }
        if(parts1[2].length==4){
          val=val+ parts1[2]
          return
        }else{
          this.invoicenumber=event.target.value
        }
      }else{
        this.invoicenumber=event.target.value
      }
      this.invoicenumber=event.target.value
    }
    // else{
    //   return;
    // }

    
    // else{
    //   return;
    // }
  
    // else{
    //   return;
    // }
  }

  getIssuesSearchAdvt(e) {
    // console.log(e);
    this.issueName = e.value;
    // this.rangeRegistration.reset();
    // this.startDate_registration = '';
    // this.enddate_registration = '';
    this.advt_name = '';
    this.cust_mobile_filter = '';
    this.rep_name = '';
  }

  reset_cust_mobile_filter(event) {
    this.cust_mobile_filter = '';
  }

  get_rep_name_filter(repname) {
    // console.log(repname);
    // this.rangeRegistration.reset();
    // this.startDate_registration = '';
    // this.enddate_registration = '';
    this.advt_name = '';
    this.issueName = '';
    this.cust_mobile_filter = '';
  }

  get_advt_name_filter(advtname) {
    // console.log(advtname);
    // this.rangeRegistration.reset();
    // this.startDate_registration = '';
    // this.enddate_registration = '';
    this.issueName = '';
    this.cust_mobile_filter = '';
    this.rep_name = '';
  }

  cust_mobile_filter = '';
  rep_name = '';
  getAdvtDetails = [];
  advt_name = '';
  getAdvtReport() {
    // console.log(
    //   // this.startDate_registration,
    //   // this.enddate_registration,
    //   this.advt_name,
    //   this.cust_mobile_filter,
    //   this.issueName,
    //   this.rep_name
    // );
    if (this.advt_name == '') {
      this.advt_name = 'opqr';
    }

    if (this.cust_mobile_filter == '') {
      this.cust_mobile_filter = '777';
    }

    if (this.issueName == '') {
      this.issueName = 'abc';
    }

    if (this.rep_name == '') {
      this.rep_name = 'xyz';
    }

    this.service
      .getAdvtReports(
        this.advt_name,
        this.cust_mobile_filter,
        this.issueName,
        this.rep_name
      )
      .subscribe((data) => {
        console.log(data);
        this.getAdvtDetails = data;
      });
  }

  /*name of the excel-file which will be downloaded. */
  //fileName = 'ExcelSheet.xlsx';

  exportexcel(fileName): void {
    /* table id is passed over here */
    let element = document.getElementById('excel-table');
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* save to file */
    XLSX.writeFile(wb, fileName + '.xlsx');
  }

  view_advt(advt) {
    // console.log(advt);
    this.dialog.open(UpdateAdvtDetailsComponent, {
      data: {
        advt: advt,
        view_mode: true,
      },
    });
  }

  pendingAdvtData;
  displayItemId(advt) {
    
    
    this.voucherdate = advt.voucher_date
    this.pendingAdvtData = advt;
    this.repname = advt.rep_name;
    console.log(this.repname, "repname")
    this.demo1BtnClick();
    this.getentrybyrepname();
    this.advtpymtrecptform.value.advtnamesearch.reset()
  } //end of displayId
  searchText;
  getentrybyrepname(){
    
    this.service.getentrybyrepname(this.repname).subscribe((res) => {
          // console.log(res);
          this.getentrybyrepnamelist = res;
          console.log(res , "getentrybyrepnamelist");
        });
  }

  public demo1TabIndex = 0;
  public demo1BtnClick() {
    const tabCount = 12;
    this.demo1TabIndex = (this.demo1TabIndex + 1) % tabCount;
    this.advtrcptsetvalue();
  }

  // displayitembyrepname(repname){
  //   
   
  // }
  convertDateFormat(dateStr: string): string {
    // Split the input date string by hyphens
    const [day, month, year] = dateStr.split('-');
  
    // Return the new date string in the desired format
    return `${year}-${month}-${day}`;
  }

  advtrcptsetvalue() {
    
    console.log(this.pendingAdvtData, "setdata value");
    // let today = formatDate(new Date(), 'yyyy-MM-dd', 'en-IN', 'GMT+0530');
    // let rcpt_no = `JRJ1000049/${new Date(today).getMonth() + 1 < 4
    //     ? (new Date(today).getFullYear() - 1).toString().slice(-2) +
    //     '-' +
    //     new Date(today).getFullYear().toString().slice(-2)
    //     : new Date(today).getFullYear().toString().slice(-2) +
    //     '-' +
    //     (new Date(today).getFullYear() + 1).toString().slice(-2)
    //   }`;
    // let recdate = this.datePipe.transform(this.voucherdate, 'yyyy-MM-dd');
    let recdate = this.convertDateFormat(this.voucherdate);

   
    const req:any = {
      "date":recdate
    }


    this.service.getReceiptnumber(req).subscribe((data: any) => {
      

      // console.log("auto CrNo=",data);
      this.autogeneratedno = (data as any);;
      console.log(this.autogeneratedno)

      let newg = this.autogeneratedno[0].rcpt_no


      if (this.autogeneratedno == undefined) {
        newg = 1;
      }
      else {
        newg++;
      }
  
  
  const dateString = this.pendingAdvtData['voucher_date'];
  const dateParts = dateString.split('-');
  const formattedDate = new Date(`${dateParts[2]}-${dateParts[1]}-${dateParts[0]}`);
  const today = this.datePipe.transform(formattedDate, 'MM');
  console.log(today);
  
  let rcpt_no = `JRJ` + today + `000` + newg + `/${(formattedDate.getMonth() + 1 < 4
      ? (formattedDate.getFullYear() - 1).toString().slice(-2) +
          '-' +
          formattedDate.getFullYear().toString().slice(-2)
      : formattedDate.getFullYear().toString().slice(-2) +
          '-' +
          (formattedDate.getFullYear() + 1).toString().slice(-2))
  }`;

  this.advtpymtrecptform.get('recptno').setValue(rcpt_no);
    let today1 = formatDate(new Date(), 'yyyy-MM-dd', 'en-IN', 'GMT+0530');
    this.advtpymtrecptform.get('date').setValue(today1);
    this.advtpymtrecptform
      .get('crdtvno')
      .setValue(this.pendingAdvtData['voucher_no']);
    this.advtpymtrecptform
      .get('recptdate')
      .setValue(this.pendingAdvtData['voucher_date']);
    this.advtpymtrecptform
      .get('repname')
      .setValue(this.pendingAdvtData['rep_name']);
    this.advtpymtrecptform
      .get('crdtvamt')
      .setValue(this.pendingAdvtData['remaining_amt']);
    this.advtpymtrecptform
      .get('advtname')
      .setValue(this.pendingAdvtData['advtName']);
      
      

    let name = this.advtpymtrecptform.get('repname').value;
      

    });
    
    // let names = this.advtNames.filter(
    //   (a) => a['name'] === this.pendingAdvtData['rep_name']
    // );
  }
  newval : any=0;
  addadvtpymtrcpt() {
    let data = {
      date: this.advtpymtrecptform.get('date').value,
      crdtvno: this.advtpymtrecptform.get('crdtvno').value,
      recptdate: this.advtpymtrecptform.get('recptdate').value,
      repname: this.advtpymtrecptform.get('repname').value,
      recptno: this.advtpymtrecptform.get('recptno').value,
      crdtvamt: this.advtpymtrecptform.get('crdtvamt').value,
      seamt: this.advtpymtrecptform.get('seamt').value,
      advtname: this.advtpymtrecptform.get('advtname').value,
      balamt: this.advtpymtrecptform.get('balamt').value,
      // booking_no : book.booking_no,
      // billno: this.booksellernamedata[0]['invoice_no'],
      // value: this.val1,
    };
    console.log(data, "Seamt new")
    // let newval = this.advtpymtrecptform.get('seamt').value;
   
    console.log( this.advtInvoicePymtRcptData, " this.advtInvoicePymtRcptData")
    this.dialog.open(AdvtInvoicePaymentReceiptComponent, {
    
      data: {
        data: data,
        pendingAdvtData: this.pendingAdvtData,
        advtInvoicePymtRcptData: this.advtInvoicePymtRcptData,
        advtInvoicePricePerIssue: this.advtInvoicePricePerIssue,
        advtname: this.advtpymtrecptform.get('advtnamesearch').value,
        // billno: this.booksellernamedata[0]['invoice_no'],
        val: data['seamt'],
        newval : this.newval,
        invoicenumberlist : this.arraynew

      },
      height: '1300px',
      width: '1200px',
      maxWidth: '100%',
    });
    debugger;
    console.log(data, "new valuw is")
    // this.dialog.closeAll();
  }

  getOffRep(){
    
    this.service.getOffRepSub().subscribe((data:any) => {
      console.log("represntive name",data);
    this.GetOffRep = (data as any);
    this.filterrep = (data as any);
    });
  }

  searchAdvtName(event) {
    // console.log(event.target.value);
    if (event.target.value.length > 2) {
      this.service
        .getAdvtNamesForPymt(event.target.value)
        .subscribe((res: any) => {
          this.advtNamesData = res;
          // console.log(res);
          this.advtNamesData = this.uniqueid(
            this.advtNamesData,
            (a) => a.advt_name
          );
          // console.log(this.advtNamesData);
        });
    }
  }

  advtInvoiceListData;
  advtInvoiceListData1;
  // const req:any = {
  //   "rep_name":this.repName,
  //   "start_date":this.startDate,
  //   "end_date":this.endDate,
  //   "off_rep_name":this.offRepName,
  //   "issue_name":""
  // }
  // searchAdvtName1() {
  //   this.advtInvoiceListData1 = [];
  //   this.service.getAllAdvtInvoicesForPymt1(this.name).subscribe((res) => {
  //     // console.log(res);
  //     this.advtInvoiceListData1 = res;
  //     console.log(res);
  //   });

  //   setTimeout(() => {
  //     this.advtInvoiceListData = [];
  //     this.advtInvoiceListData = this.advtInvoiceListData1.filter(
  //       (a) => a.advt_name === this.name
  //     );
  //     // console.log(this.advtInvoiceListData);
  //   }, 700);
  // }

  searchAdvtName0() {
    const req:any = {
    "name":this.advtpymtrecptform.value.advtnamesearch,
   
  }
    this.advtInvoiceListData1 = [];
    this.service.getAllAdvtInvoicesForPymt(this.advtpymtrecptform.value.advtnamesearch).subscribe((res) => {
      
      // console.log(res);
      this.advtInvoiceListData1 = res;
      console.log(this.advtInvoiceListData1, "nan value")
    });

    setTimeout(() => {
      this.advtInvoiceListData = [];
      this.advtInvoiceListData = this.advtInvoiceListData1.filter(
        (a) => a.advt_name === this.name
      );
      // console.log(this.advtInvoiceListData);
    }, 700);
  }
  searchAdvtName1() {
    this.getentrybyrepnamelist= [];
    const req:any = {
    "name":this.advtpymtrecptform.value.advtnamesearch,
   
  }
    this.advtInvoiceListData1 = [];
    this.service.getAllAdvtInvoicesForPymt(this.advtpymtrecptform.value.advtnamesearch).subscribe((res) => {
      
      // console.log(res);
      this.getentrybyrepnamelist = res;
      console.log(this.getentrybyrepnamelist, "nan value")
    });

    setTimeout(() => {
      this.advtInvoiceListData = [];
      this.advtInvoiceListData = this.advtInvoiceListData1.filter(
        (a) => a.advt_name === this.name
      );
      // console.log(this.advtInvoiceListData);
    }, 700);
  }

  

  nameid;
  name;
  selectedname(event, option) {
    if (event.isUserInput) {
      // this.nameid = option['id'];
      this.name = option['advt_name'];
    }
  }

  advtInvoicePymtRcptData = [];

  selectedItem(advtInvoice) {
    
    console.log(advtInvoice,"selected dsata");
    console.log(this.advtInvoicePymtRcptData);
  

    // if (Object.keys(this.advtInvoicePymtRcptData).length != 0) { 
    //   let flag: Boolean = false;
    //   for (
    //     let index = 0;
    //     index < Object.keys(this.advtInvoicePymtRcptData).length;
    //     index++
    //   ) {
    //     if (this.advtInvoicePymtRcptData[index]['id'] == advtInvoice['id']) {
    //       flag = true;
    //     }

    //   }
    //   if (!flag) {
    //     this.calculateRcptAmt(advtInvoice);
    //   } else {
    //     window.alert('Receipt is Already Selected...');
    //   }
    // } else {
    //   this.calculateRcptAmt(advtInvoice);
    // }
    this.calculateRcptAmt(advtInvoice);
    console.log(this.advtInvoicePymtRcptData);

  }

  updateIssueAnk(advtInvoice) {
    console.log(advtInvoice, this.advtInvoicePricePerIssue);
    // let data = this.advtInvoicePricePerIssue.find(
    //   (a) => a.id == advtInvoice.id
    // );
    // if (data) {
      const dialogdata = this.dialog.open(UpdateAdvtIssuePerPriceComponent, {
        data: {
          advtInvoice: advtInvoice,
          crdtAmt: parseFloat(this.advtpymtrecptform.get('crdtvamt').value),
          seAmt: parseFloat(this.advtpymtrecptform.get('seamt').value),
          balAmt: parseFloat(this.advtpymtrecptform.get('balamt').value),
        },
        height: '85vh',
        width: '95vw',
        maxWidth: '100%',
      }); 

      dialogdata.afterClosed().subscribe((res) => {
        this.advtInvoiceListData.map((a) => {
          if (a.id == res.advtInvoiceData.id) {
            a.remaining_amt = res.advtInvoiceData.remaining_amt;
          }
        });
        this.advtInvoicePymtRcptData.map((a) => {
          if (a.id == res.advtInvoiceData.id) {
            a.remaining_amt = res.advtInvoiceData.remaining_amt;
          }
        });
debugger;
        this.advtpymtrecptform.get('seamt').setValue(res.selecetdAmt);
        this.advtpymtrecptform.get('balamt').setValue(res.balanceAmt);
        // console.log(this.advtInvoicePricePerIssue);

        this.advtInvoicePricePerIssue.map((a) => {
          if (a.id == res.advtInvoiceData.id) {
            a = res.advtInvoiceData;
          }
        });
        // console.log(this.advtInvoicePricePerIssue);
      });
    // }
    //  else {
    //   window.alert('First select the receipt');
    // }
  }

  deletePymtreceipt(advt) {
    const req:any = {
      "id":advt
    }
    debugger;
    console.log(req, "this delete id")
    if (window.confirm('Are you sure want to delete?')) {
      this.service.deletePymtreceipt(req).subscribe((res) => {     
        console.log(res)
      });
    }
    this.ngOnInit()
  }

  deleteCreditNote(advt) {
    const req:any = {
      "id":advt
    }
    debugger;
    console.log(req, "this delete id")
    if (window.confirm('Are you sure want to delete?')) {
      this.service.deleteCreditNote(req).subscribe((res) => {     
        console.log(res)
      });
    }
    this.ngOnInit()
  }

  advtInvoicePricePerIssue = [];
  calculateRcptAmt(advtInvoice) {
    
    
    this.advtInvoicePymtRcptData.push(advtInvoice);
    const dialogdata = this.dialog.open(AddAdvtIssuePerPriceComponent, {
      
      data: {
        advtInvoice: advtInvoice,
        crdtAmt: parseFloat(this.advtpymtrecptform.get('crdtvamt').value),
        seAmt: parseFloat(this.advtpymtrecptform.get('seamt').value),
        balAmt: parseFloat(this.advtpymtrecptform.get('balamt').value),
        bal: advtInvoice.pay_amt - advtInvoice.rcv_amt,

      },
      height: '85vh',
      width: '95vw',
      maxWidth: '100%',
    });

    dialogdata.afterClosed().subscribe((res) => {
     
      console.log(res,'res');
      if(this.newval == 0 ){
        this.newval = res.newvalue;
      }else{
        this.newval = this.newval + ',' + res.newvalue
      }
      console.log(this.advtInvoiceList, "list")
      console.log(this.newval, "new val2")

      debugger;
       this.arraynew = res.invoicenumberlist;

      this.advtInvoiceListData.map((a) => {
        if (a.id == res.advtInvoiceData.id) {
          a.remaining_amt = res.advtInvoiceData.remaining_amt;
        }
      });
      this.advtInvoicePymtRcptData.map((a) => {
        if (a.id == res.advtInvoiceData.id) {
          a.remaining_amt = res.advtInvoiceData.remaining_amt;
        }
      });

      this.getallVouchersData.forEach((element, index) => {
        if (this.pendingAdvtData.voucher_no == element.voucher_no) {
          element.remaining_amt = res.advtInvoiceData.remaining_amt;
          if (element.remaining_amt == 0) {
            this.getallVouchersData.splice(index, 1); // Remove the object from the array
          }
        }
      });
      debugger;
      this.invoicenumberlist = this.invoicenumberlist

      this.advtpymtrecptform.get('seamt').setValue(res.selecetdAmt);
      this.advtpymtrecptform.get('balamt').setValue(res.balanceAmt);
      // res.advtInvoiceData.map((a) => {
      this.advtInvoicePricePerIssue.push(res.advtInvoiceData);
      // });
      // console.log(this.advtInvoicePricePerIssue);
    });
  }

  update_advt(advt) {
    // console.log(advt);
    this.dialog.open(UpdateAdvtDetailsComponent, {
      data: {
        advt: advt,
        view_mode: false,
      },
    });
  }

  addPublishDates(advt) {
    // console.log(advt);
    let published_dates;

    if (advt['published_dates'] != null) {
      published_dates = advt['published_dates'].split(',');
    } else {
      published_dates = [];
    }

    this.dialog.open(UpdatePlublishDatesComponent, {
      data: {
        published_dates: published_dates,
        advt_dtl_id: advt['advt_dtl_id'],
        manage_mode: false,
        issue_date_mode: false,
        height: '600px',
        width: '600px',
      },
    });
  }

  addIssueDates(advt) {
    // console.log(advt);
    let issue_dates;

    if (advt['issue_dates'] != null) {
      issue_dates = advt['issue_dates'].split(',');
    } else {
      issue_dates = [];
    }

    this.dialog.open(UpdatePlublishDatesComponent, {
      data: {
        published_dates: issue_dates,
        advt_dtl_id: advt['advt_dtl_id'],
        manage_mode: false,
        issue_date_mode: true,
        height: '600px',
        width: '600px',
      },
    });
  }

  Cancel_advt(advt) {
    // console.log(advt);
    if (window.confirm('Do you really want to remove this Advertisement?')) {
      this.service
        .updateAdvtdtlStatus(parseInt(advt['advt_dtl_id']))
        .subscribe((data) => {
          // console.log(data);
          window.alert(
            'Advertisement has been remove. Please try to refresh the page.'
          );
        });
    }
  }

  getAdvtMagName(event, issue_name, id, dateIndex) {
    // console.log(event, issue_name, id, dateIndex);
    if (window.confirm('Do you really want to remove the date?')) {
      let mag = this.advt_mag_name_details.filter((a) => {
        return parseInt(a.id) === parseInt(id);
      });
      // console.log(mag);
      mag[0]['publishing_date'].splice(dateIndex, 1);
      // console.log(mag);
      // console.log(event, issue_name);
      //update Service call
    }
  }

  manageAdvtDates(advt) {
    this.dialog.open(UpdatePlublishDatesComponent, {
      data: {
        published_dates: advt['publishing_date'],
        advt_dtl_id: advt['id'],
        manage_mode: true,
        issue_date_mode: false,
        height: '600px',
        width: '600px',
      },
    });
  }

  remove_mag_advt(advt) {
    //remove Magazine advt

    this.service.updateAdvtMagStatus(advt['id'], 'N').subscribe((data) => {
      // console.log(data);
      this.getAdvtMagDetails();
      window.alert('Status Change!');
    });
  }

  make_it_activate(advt) {
    this.service.updateAdvtMagStatus(advt['id'], 'Y').subscribe((data) => {
      // console.log(data);
      this.getAdvtMagDetails();
      window.alert('Status Change!');
    });
  }

  allSelected = false;
  @ViewChild('mySel') skillSel: MatSelect;
  toggleAllSelection() {
    this.allSelected = !this.allSelected; // to control select-unselect
    // console.log(this.skillSel.options);
    if (this.allSelected) {
      this.skillSel.options.forEach((item: MatOption) => item.select());
    } else {
      this.skillSel.options.forEach((item: MatOption) => {
        item.deselect();
      });
    }
    this.skillSel.close();
  }

  allSelected1 = false;
  @ViewChild('mySel1') skillSel1: MatSelect;
  toggleAllSelection1() {
    this.allSelected1 = !this.allSelected1; // to control select-unselect
    // console.log(this.skillSel.options);
    if (this.allSelected1) {
      this.skillSel1.options.forEach((item: MatOption) => item.select());
    } else {
      this.skillSel1.options.forEach((item: MatOption) => {
        item.deselect();
      });
    }
    this.skillSel1.close();
  }

  allSelectedr1 = false;
  @ViewChild('mySelr1') skillSelr1: MatSelect;
  toggleAllSelectionr1() {
    this.allSelectedr1 = !this.allSelectedr1; // to control select-unselect
    // console.log(this.skillSel.options);
    if (this.allSelectedr1) {
      this.skillSelr1.options.forEach((item: MatOption) => item.select());
    } else {
      this.skillSelr1.options.forEach((item: MatOption) => {
        item.deselect();
      });
    }
    this.skillSelr1.close();
  }

  allSelectedr2 = false;
  @ViewChild('mySelr2') skillSelr2: MatSelect;
  toggleAllSelectionr2() {
    this.allSelectedr2 = !this.allSelectedr2; // to control select-unselect
    // console.log(this.skillSel.options);
    if (this.allSelectedr2) {
      this.skillSelr2.options.forEach((item: MatOption) => item.select());
    } else {
      this.skillSelr2.options.forEach((item: MatOption) => {
        item.deselect();
      });
    }
    this.skillSelr2.close();
  }

  allSelectedc1 = false;
  @ViewChild('mySelc1') skillSelc1: MatSelect;
  toggleAllSelectionc1() {
    this.allSelectedc1 = !this.allSelectedc1; // to control select-unselect
    // console.log(this.skillSel.options);
    if (this.allSelectedc1) {
      this.skillSelc1.options.forEach((item: MatOption) => item.select());
    } else {
      this.skillSelc1.options.forEach((item: MatOption) => {
        item.deselect();
      });
    }
    this.skillSelc1.close();
  }

  allSelectedc2 = false;
  @ViewChild('mySelc2') skillSelc2: MatSelect;
  toggleAllSelectionc2() {
    this.allSelectedc2 = !this.allSelectedc2; // to control select-unselect
    // console.log(this.skillSel.options);
    if (this.allSelectedc2) {
      this.skillSelc2.options.forEach((item: MatOption) => item.select());
    } else {
      this.skillSelc2.options.forEach((item: MatOption) => {
        item.deselect();
      });
    }
    this.skillSelc2.close();
  }

  
  allSelectedc3 = false;
  @ViewChild('mySelc3') skillSelc3: MatSelect;
  toggleAllSelectionc3() {
    this.allSelectedc3 = !this.allSelectedc3; // to control select-unselect
    // console.log(this.skillSel.options);
    if (this.allSelectedc3) {
      this.skillSelc3.options.forEach((item: MatOption) => item.select());
    } else {
      this.skillSelc3.options.forEach((item: MatOption) => {
        item.deselect();
      });
    }
    this.skillSelc3.close();
  }

  allSelected2 = false;
  @ViewChild('mySel2') skillSel2: MatSelect;
  toggleAllSelection2() {
    this.allSelected2 = !this.allSelected2; // to control select-unselect
    // console.log(this.skillSel2.options);
    if (this.allSelected2) {
      this.skillSel2.options.forEach((item: MatOption) => item.select());
    } else {
      this.skillSel2.options.forEach((item: MatOption) => {
        item.deselect();
      });
    }
    this.skillSel2.close();
  }

  allSelected3 = false;
  @ViewChild('mySel3') skillSel3: MatSelect;
  toggleAllSelection3() {
    this.allSelected3 = !this.allSelected3; // to control select-unselect
    // console.log(this.skillSel3.options);
    if (this.allSelected3) {
      this.skillSel3.options.forEach((item: MatOption) => item.select());
    } else {
      this.skillSel3.options.forEach((item: MatOption) => {
        item.deselect();
      });
    }
    this.skillSel3.close();
  }

  allSelected4 = false;
  @ViewChild('mySel4') skillSel4: MatSelect;
  toggleAllSelection4() {
    this.allSelected4 = !this.allSelected4; // to control select-unselect
    // console.log(this.skillSel4.options);
    if (this.allSelected4) {
      this.skillSel4.options.forEach((item: MatOption) => item.select());
    } else {
      this.skillSel4.options.forEach((item: MatOption) => {
        item.deselect();
      });
    }
    this.skillSel4.close();
  }

  startDate_registrationReport = '';
  enddate_registrationReport = '';
  dateIsEnableReport = false;
  getChangedDateRegistrationReport() {
    this.issueGranthName = '';
    this.issueDates = '';
    if (
      this.RegistrationreportRange.get('start').value != null &&
      this.RegistrationreportRange.get('end').value != null
    ) {
      console.log(this.RegistrationreportRange.value);
      this.dateIsEnable = true;
      // console.log(
      //   formatDate(
      //     new Date(this.RegistrationreportRange.get('start').value),
      //     'yyyy-MM-dd',
      //     'en-IN',
      //     'GMT+0530'
      //   )
      // );
      // console.log(
      //   formatDate(
      //     new Date(this.RegistrationreportRange.get('end').value),
      //     'dd-MM-yyyy',
      //     'en-IN',
      //     'GMT+0530'
      //   )
      // );
      this.startDate_registrationReport = formatDate(
        new Date(this.RegistrationreportRange.get('start').value),
        'yyyy-MM-dd',
        'en-IN',
        'GMT+0530'
      );
      this.enddate_registrationReport = formatDate(
        new Date(this.RegistrationreportRange.get('end').value),
        'yyyy-MM-dd',
        'en-IN',
        'GMT+0530'
      );
      // this.startDate_registrationReport = startdate;
      // this.enddate_registrationReport = endDate;
    } else {
      this.dateIsEnableReport = false;
    }
  }

  startDate_PrasiddhiPatrak = '';
  enddate_PrasiddhiPatrak = '';
  getChangedDatePrasiddhiPatrak() {
    if (
      this.PrasiddhipatrakRange.get('start').value != null &&
      this.PrasiddhipatrakRange.get('end').value != null
    ) {
      let startdate = formatDate(
        new Date(this.PrasiddhipatrakRange.get('start').value),
        'yyyy-MM-dd',
        'en-IN',
        'GMT+0530'
      );
      let endDate = formatDate(
        new Date(this.PrasiddhipatrakRange.get('end').value),
        'yyyy-MM-dd',
        'en-IN',
        'GMT+0530'
      );
      this.startDate_PrasiddhiPatrak = startdate;
      this.enddate_PrasiddhiPatrak = endDate;
    }
  }

  getcust_mobile_filter_report(mobile) {
    // console.log(mobile);
    // console.log(this.cust_mobile_filter_report.length);
    this.RegistrationreportRange.reset();
    this.startDate_registrationReport = '';
    this.enddate_registrationReport = '';
    this.issueNameRegistrationReport = '';
  }

  reset_cust_mobile_filter_report(event) {
    this.cust_mobile_filter_report = '';
  }

  issueNameRegistrationReport = '';
  getIssuesRegistrationreport(e) {
    // console.log(e);
    this.issueNameRegistrationReport = e.value;
    this.RegistrationreportRange.reset();
    this.cust_mobile_filter_report = '';
  }

  showindetail: boolean = false;
  showindetaildatewise: boolean = false;
  showoffdatewise: boolean = false;
  showdatewisevrybrfly: boolean = false;
  showoffrepvrybrfly: boolean = false;
  showindetailoffrep: boolean = false;
  selectedReportType;
  checkReportType(event) {
    // console.log(event.value);
    this.selectedReportType = event.value;
    let indetail: boolean = false;
    let brfly: boolean = false;
    let verybrfly: boolean = false;
    let datewise: boolean = false;
    let offrepwise: boolean = false;
    this.selectedReportType.forEach((type) => {
      if (type === 'In Detail') {
        indetail = true;
        if (this.offrepregreport != '') {
          offrepwise = true;
        }
      } else if (type === 'Briefly') {
        brfly = true;
      } else if (type === 'Very Briefly') {
        verybrfly = true;
        if (this.offrepregreport != '') {
          offrepwise = true;
        }
      } else if (type === 'Datewise') {
        datewise = true;
      }
    });

    if (indetail && datewise) {
      this.showindetaildatewise = true;
      this.showindetail = false;
      this.showoffdatewise = false;
      this.showoffrepvrybrfly = false;
      this.showdatewisevrybrfly = false;
      this.showindetailoffrep = false;
      this.inDetailsDatewise();
    } else if (datewise && verybrfly) {
      this.showdatewisevrybrfly = true;
      this.showoffrepvrybrfly = false;
      this.showindetaildatewise = false;
      this.showindetail = false;
      this.showoffdatewise = false;
      this.showindetailoffrep = false;
      this.datewiseBriefly();
      // this.datewiseBriefly();
    } else if (indetail && !datewise && !offrepwise) {
      this.showindetail = true;
      this.showindetaildatewise = false;
      this.showoffdatewise = false;
      this.showoffrepvrybrfly = false;
      this.showindetailoffrep = false;
      this.showdatewisevrybrfly = false;
      this.getRegReportData();
    } else if (offrepwise && verybrfly) {
      this.showoffrepvrybrfly = true;
      this.showindetail = false;
      this.showindetaildatewise = false;
      this.showoffdatewise = false;
      this.showindetailoffrep = false;
      this.showdatewisevrybrfly = false;
      this.offrepVeryBriefly();
    } else if (indetail && offrepwise) {
      this.showindetailoffrep = true;
      this.showoffrepvrybrfly = false;
      this.showindetail = false;
      this.showindetaildatewise = false;
      this.showoffdatewise = false;
      this.showdatewisevrybrfly = false;
      this.inDetailsOffRep();
    }
  }

  inDetailsOffRep() {
    this.getRegReportData();
    setTimeout(() => {
      this.getReportInDetailOffRep();
    }, 200);
  }

  datewiseBriefly() {
    this.getRegReportData();
    setTimeout(() => {
      this.getReportDatewiseBriefly();
    }, 200);
  }

  offrepVeryBriefly() {
    this.getRegReportData();
    setTimeout(() => {
      this.getReportOffRepVeryBriefly();
    }, 200);
  }

  offrepregreport = '';
  getOfficeRep(event) {
    this.offrepregreport = event.value;
    this.checkReportType({ value: this.selectedReportType });
  }

  offrepname = '';
  getOfficeRep1(event) {
    this.offrepname = event.value;
  }

  repregreport = '';
  getRepName1(event) {
    this.repregreport = event.value;
    // console.log(event.value);
  }

  repname = '';
  getRepName2(event) {
    this.repname = event.value;
  }

  cust_mobile_filter_report = '';
  issueGranthName = '';
  issueDates = '';
  getAdvtDetailsReport = [];
  getAdvtRegistrationReport() {
    

    let repregreport:any=[]
    if(this.repregreport[0]=='ALL'){
      repregreport.push(this.repregreport[0])
    }else{
      repregreport=this.repregreport
    }
    let offrepregreport:any=[]
    if(this.offrepregreport[0]=='ALL'){
      offrepregreport.push(this.offrepregreport[0])
    }else{
      offrepregreport=this.offrepregreport
    }

    this.dialog.open(RegistrationReportComponent, {
      data: {
        type: this.selectedReportType,
        start_date: this.startDate_registrationReport,
        end_date: this.enddate_registrationReport,
        rep_name: repregreport,
        off_rep_name: offrepregreport,
        issue_cat: this.issueName,
        issueDates: this.getDates,
        newissuedatepp:this.newissuedatepp
      },
      width: '1200px',
      height: '1300px',
      maxWidth: '100%',
    });

    //Previous Code
    // if (this.startDate_registrationReport == '') {
    //   this.startDate_registrationReport = '1800-01-01';
    // }
    // if (this.issueGranthName == '' && this.issueDates == '') {
    //   this.issueGranthName = 'xyz';
    //   this.issueDates = 'abc';
    // }
    // this.service
    //   .getAdvtRegistrationReports(
    //     this.startDate_registrationReport,
    //     this.enddate_registrationReport,
    //     this.issueGranthName,
    //     this.issueDates
    //   )
    //   .subscribe((data) => {
    //     // console.log(data);
    //     this.getAdvtDetailsReport = data;
    //   });
    // console.log('getAdvtDetailsReport', this.getAdvtDetailsReport);
  }

  //Rep Wise Registration Report :

  startDate_repregistrationReport = '';
  enddate_repregistrationReport = '';
  dateIsEnablerepReport = false;
  getChangedDateRepRegistrationReport() {
    if (
      this.RepwiseRegistrationreportRange.get('start').value != null &&
      this.RepwiseRegistrationreportRange.get('end').value != null
    ) {
      // console.log(this.RepwiseRegistrationreportRange.value);
      this.dateIsEnable = true;
      // console.log(
      //   formatDate(
      //     new Date(this.RepwiseRegistrationreportRange.get('start').value),
      //     'yyyy-MM-dd',
      //     'en-IN',
      //     'GMT+0530'
      //   )
      // );
      // console.log(
      //   formatDate(
      //     new Date(this.RepwiseRegistrationreportRange.get('end').value),
      //     'dd-MM-yyyy',
      //     'en-IN',
      //     'GMT+0530'
      //   )
      // );
      let startdate = formatDate(
        new Date(this.RepwiseRegistrationreportRange.get('start').value),
        'yyyy-MM-dd',
        'en-IN',
        'GMT+0530'
      );
      let endDate = formatDate(
        new Date(this.RepwiseRegistrationreportRange.get('end').value),
        'yyyy-MM-dd',
        'en-IN',
        'GMT+0530'
      );
      this.startDate_repregistrationReport = startdate;
      this.enddate_repregistrationReport = endDate;
    } else {
      this.dateIsEnableReport = false;
    }
  }

  rep_name_report = '';
  getRepNameReport(e) {
    // console.log(e);
    if (e.value == 'All') {
      this.rep_name_report = 'all';
    } else {
      this.rep_name_report = e.value;
    }
  }

  getAdvtDetailsRepReport = [];
  getAdvtRepRegistrationReport() {
    // console.log(
    //   this.startDate_repregistrationReport,
    //   this.enddate_repregistrationReport,
    //   this.rep_name_report
    // );
    if (this.startDate_repregistrationReport == '') {
      window.alert('Please select valid dates...!');
    } else if (this.rep_name_report == '') {
      window.alert('Please select valid Rep. Name...!');
    } else {
      this.service
        .getAdvtReportRepWise(
          this.startDate_repregistrationReport,
          this.enddate_repregistrationReport,
          this.rep_name_report
        )
        .subscribe((data) => {
          console.log(data);
          this.getAdvtDetailsRepReport = data;
        });
    }
  }

  financialYearStartDate;
  financialYearEndDate;
  getFinancialYearsDates(event) {
    // console.log(event);
    this.financialYearStartDate = event.value['startdate'];
    this.financialYearEndDate = event.value['enddate'];
  }

  testData = {
    key1: [1, 2],
    key2: [3, 4, 5],
  };

  bussinessReportData = [];
  abc = [];

  getBussinessReport() {
    this.service
      .getAdvtBusinessReport(
        this.financialYearStartDate,
        this.financialYearEndDate
      )
      .subscribe((data) => {
        // console.log(data);
        for (let index = 0; index < Object.keys(data).length; index++) {
          // console.log(
          //   (data[index]['report_month'] == 'October',
          //   data[index]['total_amount'],
          //   0)
          // );

          if (index == 0) {
            this.bussinessReportData.push({
              rep_id: data[index]['rep_id'],
              rep_name: data[index]['rep_name'],
              district: data[index]['district'],
              taluka: data[index]['taluka'],
              April:
                data[index]['report_month'] == 'April'
                  ? data[index]['total_amount']
                  : 0,
              May:
                data[index]['report_month'] == 'May'
                  ? data[index]['total_amount']
                  : 0,
              June:
                data[index]['report_month'] == 'June'
                  ? data[index]['total_amount']
                  : 0,
              July:
                data[index]['report_month'] == 'July'
                  ? data[index]['total_amount']
                  : 0,
              August:
                data[index]['report_month'] == 'August'
                  ? data[index]['total_amount']
                  : 0,
              September:
                data[index]['report_month'] == 'September'
                  ? data[index]['total_amount']
                  : 0,
              October:
                data[index]['report_month'] == 'October'
                  ? data[index]['total_amount']
                  : 0,
              November:
                data[index]['report_month'] == 'November'
                  ? data[index]['total_amount']
                  : 0,
              December:
                data[index]['report_month'] == 'December'
                  ? data[index]['total_amount']
                  : 0,
              January:
                data[index]['report_month'] == 'January'
                  ? data[index]['total_amount']
                  : 0,
              February:
                data[index]['report_month'] == 'February'
                  ? data[index]['total_amount']
                  : 0,
              March:
                data[index]['report_month'] == 'March'
                  ? data[index]['total_amount']
                  : 0,
            });
          } else if (data[index]['rep_id'] == data[index - 1]['rep_id']) {
            if (data[index]['district'] == data[index - 1]['district']) {
              if (data[index]['taluka'] == data[index - 1]['taluka']) {
                let dataIndex = this.bussinessReportData.findIndex(
                  (a) => a.rep_id == data[index]['rep_id']
                );
                let newabc = this.bussinessReportData.find(
                  (a) => a.rep_id == data[index]['rep_id']
                );
                // console.log(newabc);
                // console.log(this.bussinessReportData[dataIndex]);

                this.bussinessReportData[dataIndex] = {
                  rep_id: data[index]['rep_id'],
                  rep_name: data[index]['rep_name'],
                  district: data[index]['district'],
                  taluka: data[index]['taluka'],
                  April:
                    data[index]['report_month'] == 'April'
                      ? data[index]['total_amount']
                      : this.bussinessReportData[dataIndex]['April'],
                  May:
                    data[index]['report_month'] == 'May'
                      ? data[index]['total_amount']
                      : this.bussinessReportData[dataIndex]['May'],
                  June:
                    data[index]['report_month'] == 'June'
                      ? data[index]['total_amount']
                      : this.bussinessReportData[dataIndex]['June'],
                  July:
                    data[index]['report_month'] == 'July'
                      ? data[index]['total_amount']
                      : this.bussinessReportData[dataIndex]['July'],
                  August:
                    data[index]['report_month'] == 'August'
                      ? data[index]['total_amount']
                      : this.bussinessReportData[dataIndex]['August'],
                  September:
                    data[index]['report_month'] == 'September'
                      ? data[index]['total_amount']
                      : this.bussinessReportData[dataIndex]['September'],
                  October:
                    data[index]['report_month'] == 'October'
                      ? data[index]['total_amount']
                      : this.bussinessReportData[dataIndex]['October'],
                  November:
                    data[index]['report_month'] == 'November'
                      ? data[index]['total_amount']
                      : this.bussinessReportData[dataIndex]['November'],
                  December:
                    data[index]['report_month'] == 'December'
                      ? data[index]['total_amount']
                      : this.bussinessReportData[dataIndex]['December'],
                  January:
                    data[index]['report_month'] == 'January'
                      ? data[index]['total_amount']
                      : this.bussinessReportData[dataIndex]['January'],
                  February:
                    data[index]['report_month'] == 'February'
                      ? data[index]['total_amount']
                      : this.bussinessReportData[dataIndex]['February'],
                  March:
                    data[index]['report_month'] == 'March'
                      ? data[index]['total_amount']
                      : this.bussinessReportData[dataIndex]['March'],
                };
              } else {
                this.bussinessReportData.push({
                  rep_id: data[index]['rep_id'],
                  rep_name: data[index]['rep_name'],
                  district: data[index]['district'],
                  taluka: data[index]['taluka'],
                  April:
                    data[index]['report_month'] == 'April'
                      ? data[index]['total_amount']
                      : 0,
                  May:
                    data[index]['report_month'] == 'May'
                      ? data[index]['total_amount']
                      : 0,
                  June:
                    data[index]['report_month'] == 'June'
                      ? data[index]['total_amount']
                      : 0,
                  July:
                    data[index]['report_month'] == 'July'
                      ? data[index]['total_amount']
                      : 0,
                  August:
                    data[index]['report_month'] == 'August'
                      ? data[index]['total_amount']
                      : 0,
                  September:
                    data[index]['report_month'] == 'September'
                      ? data[index]['total_amount']
                      : 0,
                  October:
                    data[index]['report_month'] == 'October'
                      ? data[index]['total_amount']
                      : 0,
                  November:
                    data[index]['report_month'] == 'November'
                      ? data[index]['total_amount']
                      : 0,
                  December:
                    data[index]['report_month'] == 'December'
                      ? data[index]['total_amount']
                      : 0,
                  January:
                    data[index]['report_month'] == 'January'
                      ? data[index]['total_amount']
                      : 0,
                  February:
                    data[index]['report_month'] == 'February'
                      ? data[index]['total_amount']
                      : 0,
                  March:
                    data[index]['report_month'] == 'March'
                      ? data[index]['total_amount']
                      : 0,
                });
              } //end of taluka
            } else {
              this.bussinessReportData.push({
                rep_id: data[index]['rep_id'],
                rep_name: data[index]['rep_name'],
                district: data[index]['district'],
                taluka: data[index]['taluka'],
                April:
                  data[index]['report_month'] == 'April'
                    ? data[index]['total_amount']
                    : 0,
                May:
                  data[index]['report_month'] == 'May'
                    ? data[index]['total_amount']
                    : 0,
                June:
                  data[index]['report_month'] == 'June'
                    ? data[index]['total_amount']
                    : 0,
                July:
                  data[index]['report_month'] == 'July'
                    ? data[index]['total_amount']
                    : 0,
                August:
                  data[index]['report_month'] == 'August'
                    ? data[index]['total_amount']
                    : 0,
                September:
                  data[index]['report_month'] == 'September'
                    ? data[index]['total_amount']
                    : 0,
                October:
                  data[index]['report_month'] == 'October'
                    ? data[index]['total_amount']
                    : 0,
                November:
                  data[index]['report_month'] == 'November'
                    ? data[index]['total_amount']
                    : 0,
                December:
                  data[index]['report_month'] == 'December'
                    ? data[index]['total_amount']
                    : 0,
                January:
                  data[index]['report_month'] == 'January'
                    ? data[index]['total_amount']
                    : 0,
                February:
                  data[index]['report_month'] == 'February'
                    ? data[index]['total_amount']
                    : 0,
                March:
                  data[index]['report_month'] == 'March'
                    ? data[index]['total_amount']
                    : 0,
              });
            } //end of district
          } else {
            this.bussinessReportData.push({
              rep_id: data[index]['rep_id'],
              rep_name: data[index]['rep_name'],
              district: data[index]['district'],
              taluka: data[index]['taluka'],
              April:
                data[index]['report_month'] == 'April'
                  ? data[index]['total_amount']
                  : 0,
              May:
                data[index]['report_month'] == 'May'
                  ? data[index]['total_amount']
                  : 0,
              June:
                data[index]['report_month'] == 'June'
                  ? data[index]['total_amount']
                  : 0,
              July:
                data[index]['report_month'] == 'July'
                  ? data[index]['total_amount']
                  : 0,
              August:
                data[index]['report_month'] == 'August'
                  ? data[index]['total_amount']
                  : 0,
              September:
                data[index]['report_month'] == 'September'
                  ? data[index]['total_amount']
                  : 0,
              October:
                data[index]['report_month'] == 'October'
                  ? data[index]['total_amount']
                  : 0,
              November:
                data[index]['report_month'] == 'November'
                  ? data[index]['total_amount']
                  : 0,
              December:
                data[index]['report_month'] == 'December'
                  ? data[index]['total_amount']
                  : 0,
              January:
                data[index]['report_month'] == 'January'
                  ? data[index]['total_amount']
                  : 0,
              February:
                data[index]['report_month'] == 'February'
                  ? data[index]['total_amount']
                  : 0,
              March:
                data[index]['report_month'] == 'March'
                  ? data[index]['total_amount']
                  : 0,
            });
          }
        }
        // console.log(this.bussinessReportData);
      });
  }

  getDatesPatrak;
  getAdvtDatesPatrak(e) {
    this.getDatesPatrak = e.value;
    // console.log('getDatesPatrak', this.getDatesPatrak);
  }

  advt_names1 = '';
  getAdvtName(event) {
    this.advt_names1 = event.value;
  }

  reportType = '';
  getReportType(event) {
    // console.log(event.value);
    this.reportType = event.value;
  }

  pageType = '';
  getPagewise(event) {
    this.pageType = event.value;
  }

  prasiddhiPatrak = [];
  getPrasiddhiPatrak() {
    // let patrakDate = this.getDatesPatrak;
    if (this.reportType === 'Report') {
      this.dialog.open(PrasiddhipatrakComponent, {
        data: {
          start_date: this.startDate_PrasiddhiPatrak,
          end_date: this.enddate_PrasiddhiPatrak,
          advt_name: this.advt_names1,
          rep_name: this.repname,
          off_rep_name: this.offrepname,
          issue_category: this.issueName,
          issue_date: this.getDates,
          val: this.pageType,
          issuenamenew: this.newissuedatepp,
          issuedatepp : this.issuedatespp,
          cust_name : this.cust_name

        },
        width: '1300px',
        height: '1300px',
        maxWidth: '100%',
      });
    } else if (this.reportType === 'Browse') {
      this.dialog.open(PrasiddhipatrakbrowseComponent, {
        data: {
          start_date: this.startDate_PrasiddhiPatrak,
          end_date: this.enddate_PrasiddhiPatrak,
          advt_name: this.advt_names1,
          rep_name: this.repname,
          off_rep_name: this.offrepname,
          issue_category: this.issueName,
          issue_date: this.getDates,
          val:  this.pageType,
          issuenamenew: this.newissuedatepp,
          issuedatepp : this.issuedatespp,
          cust_name :this.cust_name
        },
        width: '1300px',
        height: '1300px',
        maxWidth: '100%',
      });
    }
    // this.service
    //   .getAdvtPrasiddhiPatrak(this.issueName, this.getDatesPatrak)
    //   .subscribe((data) => {
    //     // console.log(data);

    //     for (let index = 0; index < Object.keys(data).length; index++) {
    //       this.prasiddhiPatrak.push({
    //         advt_id: data[index]['advt_id'],
    //         advt_person_name: data[index]['advt_person_name'],
    //         cust_name: data[index]['cust_name'],
    //         advt_size: data[index]['advt_size'],
    //         advt_page: [
    //           data[index]['page_no'].split(',')[
    //             data[index]['published_date']
    //               .split(',')
    //               .findIndex((a) => a == patrakDate)
    //           ]
    //             ? data[index]['page_no'].split(',')[
    //                 data[index]['published_date']
    //                   .split(',')
    //                   .findIndex((a) => a == patrakDate)
    //               ]
    //             : '',
    //         ],
    //         comment: data[index]['comment'],
    //       });
    //     }
    //   });
  }

  pp_advt_names = [];
  pp_off_rep_names = [];
  pp_rep_names = [];
  getPrasiddhiPatrakNamesData() {
    this.pp_advt_names = [];
    this.pp_off_rep_names = [];
    this.pp_rep_names = [];
    this.service.getPrasiddhiPatrakOff_Rep_and_advt_name().subscribe((res) => {
      // console.log(res);
      for (let index = 0; index < res.length; index++) {
        this.pp_advt_names.push({
          advt_name: res[index]['name'],
        });

        this.pp_off_rep_names.push({
          off_rep_name: res[index]['off_rep_name'],
        });

        this.pp_rep_names.push({
          rep_name: res[index]['rep_name'],
        });
      }

      this.pp_advt_names = this.uniqueid(
        this.pp_advt_names,
        (a) => a.advt_name
      );

      this.pp_off_rep_names = this.uniqueid(
        this.pp_off_rep_names,
        (a) => a.off_rep_name
      );

      this.pp_rep_names = this.uniqueid(this.pp_rep_names, (a) => a.rep_name);
    });
  }

  imageChange = false;
  ImageUploads(event) {
    // console.log(event.target.value);
    if (event.target.value == '') {
      this.imageChange = false;
    } else {
      this.imageChange = true;
    }
  }

  getadvtinvoiceStartDate;
  getadvtinvoiceEndDate;
  getAdvtDateRange() {
    if (
      this.AdvtGeneratedInvoice.get('start').value != null &&
      this.AdvtGeneratedInvoice.get('end').value != null
    ) {
      // console.log(this.AdvtGeneratedInvoice.value);
      // console.log(
      //   formatDate(
      //     new Date(this.AdvtGeneratedInvoice.get('start').value),
      //     'yyyy-MM-dd',
      //     'en-IN',
      //     'GMT+0530'
      //   )
      // );
      // console.log(
      //   formatDate(
      //     new Date(this.AdvtGeneratedInvoice.get('end').value),
      //     'dd-MM-yyyy',
      //     'en-IN',
      //     'GMT+0530'
      //   )
      // );
      this.getadvtinvoiceStartDate = formatDate(
        new Date(this.AdvtGeneratedInvoice.get('start').value),
        'yyyy-MM-dd',
        'en-IN',
        'GMT+0530'
      );
      this.getadvtinvoiceEndDate = formatDate(
        new Date(this.AdvtGeneratedInvoice.get('end').value),
        'yyyy-MM-dd',
        'en-IN',
        'GMT+0530'
      );
    }
  }

  isGSTInvoice = 'N';
  displaygstNumberInput = false;
  getInvoiceType(event) {
    this.isGSTInvoice = event.value;
    if (this.isGSTInvoice == 'Y') {
      this.displaygstNumberInput = true;
    } else {
      this.displaygstNumberInput = false;
    }
  }

  getGstNumber(gst) {
    this.gstNumber = gst;
  }

  reset_cust_mobile() {
    this.cust_mobile = '';
  }

  //Advt details :
  get_cust_advt_id(advt_id) {
    // console.log(this.advt_id);
    this.reset_cust_mobile();
  }

  reset_advt_id() {
    this.advt_id = '';
  }

  getcust_advt_mobile(mobile) {
    // console.log(mobile);
    //this.cust_id = ''
    // console.log(this.advt_mobile.length);
    this.reset_advt_id();
  }

  reset_advt_mobile() {
    this.advt_mobile = '';
  }

  readAdvtInvoice() {
    this.service
      .getCustInvoicesadvt(
        this.getadvtinvoiceStartDate,
        this.getadvtinvoiceEndDate
      )
      .subscribe((data) => {
        console.log(data);
        this.AdvtInvoiceData = data;
      });
  }

  ViewAdvtInvoice(advt) {
    // console.log(advt);

    let prepareCust = {
      cust_id: advt['id'],
      name: advt['name'],
      mobile: advt['mobile'],
      address: advt['address'],
      invoice_amount: parseInt(advt['invoice_total']),
      cgst: parseInt(advt['cgst']),
      sgst: parseInt(advt['sgst']),
      igst: parseInt(advt['igst']),
      taxable_amount: parseInt(advt['taxable_amount']),
      total_amount: parseInt(advt['total_amount']),
      total_discount: parseInt(advt['total_discount']),
      discounted_price: parseInt(advt['discounted_price']),
      gst_no: advt['gst_no'],
      invoice_id: advt['invoice_id'],
      invoice_date: advt['invoice_date'],
    };

    let prepareAdvt = [];
    this.service
      .getadvtInvoiceData(parseInt(advt['invoice_id']))
      .subscribe((advt_data) => {
        for (let index = 0; index < Object.keys(advt_data).length; index++) {
          prepareAdvt.push({
            issue_name: advt_data[index]['advt_name'],
            publish_date: advt_data[index]['published_date'],
            publish_page: advt_data[index]['page_number'],
            advt_size: advt_data[index]['advt_size'],
          });
        }
      });

    // console.log(prepareAdvt);
    //console.log(this.getRepSelectedBookslist)
    this.invoiceDetails = {
      rep: prepareCust,
      advts: prepareAdvt,
      GST: 'Y',
      GST_no: advt['gst_no'],
    };
    this.viewmode = true;
    this.repInvoice = 'N';

    this.dialog.open(AdvertisementInvoiceComponent, {
      data: {
        book: this.invoiceDetails,
        view: this.viewmode,
        rep_invoice: this.repInvoice,
      },
      height: '1300px',
      width: '1200px',
      maxWidth: '100%',
    });
  }

  /*name of the excel-file which will be downloaded. */
  fileName = 'ExcelSheet.xlsx';

  exportexcel1(): void {
    /* table id is passed over here */
    let element = document.getElementById('excel-table');
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* save to file */
    XLSX.writeFile(wb, this.fileName);
  }

  getAdvtInvoice() {
    let prepareCust = {
      cust_id: this.getAdvtPersonDetail[0]['advt_id'],
      name: this.getAdvtPersonDetail[0]['name'],
      mobile: this.getAdvtPersonDetail[0]['mobile'],
      address:
        this.getAdvtPersonDetail[0]['address'] +
        ',' +
        this.getAdvtPersonDetail[0]['taluka'] +
        ',' +
        this.getAdvtPersonDetail[0]['district'] +
        ',' +
        this.getAdvtPersonDetail[0]['state'] +
        ',' +
        this.getAdvtPersonDetail[0]['country'],
      advt_amount: parseInt(this.getAdvtPersonDetail[0]['advt_amount']),
      advt_discount: parseInt(this.getAdvtPersonDetail[0]['advt_discount']),
      cgst: parseInt(this.getAdvtPersonDetail[0]['cgst']),
      sgst: parseInt(this.getAdvtPersonDetail[0]['sgst']),
      igst: parseInt(this.getAdvtPersonDetail[0]['igst']),
      part_amount: parseInt(this.getAdvtPersonDetail[0]['DS_partAmount']),
      remaining_amount: parseInt(
        this.getAdvtPersonDetail[0]['DS_remainingAmount']
      ),
    };

    let prepareAdvt = [];
    for (
      let index = 0;
      index < Object.keys(this.getAdvertisement).length;
      index++
    ) {
      let issue_name = this.getAdvertisement[index]['issue_name'];
      let issue_date =
        this.getAdvertisement[index]['published_dates'].split(',');
      let issue_pages =
        this.getAdvertisement[index]['published_pages'].split(',');
      let advt_size = this.getAdvertisement[index]['advt_size'];

      if (issue_date.length == 0) {
        window.alert(issue_name + ' does not have publishing dates / pages.');
      } else {
        for (
          let index1 = 0;
          index1 < Object.keys(issue_date).length;
          index1++
        ) {
          prepareAdvt.push({
            issue_name: issue_name,
            publish_date: issue_date[index1],
            publish_page: issue_pages[index1],
            advt_size: advt_size,
          });
        }
      }
    }

    // console.log(prepareAdvt);
    //console.log(this.getRepSelectedBookslist)
    this.invoiceDetails = {
      rep: prepareCust,
      advts: prepareAdvt,
      GST: this.isGSTInvoice,
      GST_no: this.gstNumber,
    };
    this.viewmode = false;
    this.repInvoice = 'N';

    this.dialog.open(AdvertisementInvoiceComponent, {
      data: {
        book: this.invoiceDetails,
        view: this.viewmode,
        rep_invoice: this.repInvoice,
      },
      height: '1300px',
      width: '1200px',
      maxWidth: '100%',
    });
  }

  allSelectedISN = false;
  @ViewChild('isn') skillSelISN: MatSelect;
  toggleAllSelectionISN() {
    this.allSelectedISN = !this.allSelectedISN; // to control select-unselect
    // console.log(this.skillSelISN.options);
    if (this.allSelectedISN) {
      this.skillSelISN.options.forEach((item: MatOption) => item.select());
    } else {
      this.skillSelISN.options.forEach((item: MatOption) => {
        item.deselect();
      });
    }
    this.skillSelISN.close();
  }

  allSelectedtable = false;
  someComplete(): boolean {
    if (this.advtInvoiceList == null) {
      return false;
    }
    return (
      this.advtInvoiceList.filter((t) => t.is_selected).length > 0 &&
      !this.allSelectedtable
    );
  }
  allSelectedtable1 = false;
  someComplete1(): boolean {
    if (this.advtInvoiceList == null) {
      return false;
    }
    return (
      this.advtInvoiceList.filter((t) => t.is_selected).length > 0 &&
      !this.allSelectedtable1
    );
  }

  printAdvtInvoice() {
    // console.log(this.advtInvoiceList.filter((t) => t.is_selected));
    let data = this.advtInvoiceList.filter((t) => t.is_selected);
    this.openAdvtInvoice(data);
  }

  updateAllComplete() {
    this.allSelectedtable =
      this.advtInvoiceList != null &&
      this.advtInvoiceList.every((t) => t.is_selected);
  }

  setAll(completed: boolean) {
    this.allSelectedtable = completed;
    if (this.advtInvoiceList == null) {
      return;
    }
    this.advtInvoiceList.forEach((t) => (t.is_selected = completed));
  }

  getData;
  stringdata;
  getISN(e) {
    // console.log(e.value.toString());
    this.getData = e.value;
    this.getData = this.getData.filter((a) => a != 'all');
    this.stringdata = this.getData.toString();
  }

  getRepName(event) { }

  toInt(no) {
    return parseInt(no);
  }

  filteredinDetailsRegReport;
  addFilters() {
    // console.log('call addFilters');

    if (
      this.startDate_registrationReport != '' &&
      this.enddate_registrationReport != '' &&
      this.repregreport != '' &&
      this.offrepregreport != '' &&
      this.issueName != '' &&
      this.getDates != ''
    ) {
      // console.log('all Filters');
      this.filteredinDetailsRegReport = this.inDetailsRegReport.filter((a) => {
        return (
          a['booking_date'] >= this.startDate_registrationReport &&
          a['booking_date'] <= this.enddate_registrationReport &&
          this.repregreport.includes(a['rep_name']) &&
          this.offrepregreport.includes(a['off_rep_name']) &&
          a['issuename'] === this.issueName &&
          this.getDates.includes(a['issue_date'])
        );
      });
      // console.log(this.filteredinDetailsRegReport);
    } else if (
      this.startDate_registrationReport != '' &&
      this.enddate_registrationReport != '' &&
      this.repregreport == '' &&
      this.offrepregreport != '' &&
      this.issueName != '' &&
      this.getDates != ''
    ) {
      console.log('excluded repname Filters');
      this.filteredinDetailsRegReport = this.inDetailsRegReport.filter((a) => {
        return (
          a['booking_date'] >= this.startDate_registrationReport &&
          a['booking_date'] <= this.enddate_registrationReport &&
          this.offrepregreport.includes(a['off_rep_name']) &&
          a['issuename'] === this.issueName &&
          this.getDates.includes(a['issue_date'])
        );
      });
      // console.log(this.filteredinDetailsRegReport);
    } else if (
      this.startDate_registrationReport != '' &&
      this.enddate_registrationReport != '' &&
      this.repregreport != '' &&
      this.offrepregreport == '' &&
      this.issueName != '' &&
      this.getDates != ''
    ) {
      // console.log('excluded offrepname Filters');
      this.filteredinDetailsRegReport = this.inDetailsRegReport.filter((a) => {
        return (
          a['booking_date'] >= this.startDate_registrationReport &&
          a['booking_date'] <= this.enddate_registrationReport &&
          this.repregreport.includes(a['rep_name']) &&
          a['issuename'] === this.issueName &&
          this.getDates.includes(a['issue_date'])
        );
      });
      // console.log(this.filteredinDetailsRegReport);
    } else if (
      this.startDate_registrationReport != '' &&
      this.enddate_registrationReport != '' &&
      this.repregreport != '' &&
      this.offrepregreport != '' &&
      this.issueName == '' &&
      this.getDates == ''
    ) {
      // console.log('excluded offrepname Filters');
      this.filteredinDetailsRegReport = this.inDetailsRegReport.filter((a) => {
        return (
          a['booking_date'] >= this.startDate_registrationReport &&
          a['booking_date'] <= this.enddate_registrationReport &&
          this.repregreport.includes(a['rep_name']) &&
          this.offrepregreport.includes(a['off_rep_name'])
        );
      });
      // console.log(this.filteredinDetailsRegReport);
    } else if (
      this.startDate_registrationReport != '' &&
      this.enddate_registrationReport != '' &&
      this.repregreport == '' &&
      this.offrepregreport == '' &&
      this.issueName != '' &&
      this.getDates != ''
    ) {
      // console.log('excluded repname and offrepname Filters');
      this.filteredinDetailsRegReport = this.inDetailsRegReport.filter((a) => {
        return (
          a['booking_date'] >= this.startDate_registrationReport &&
          a['booking_date'] <= this.enddate_registrationReport &&
          a['issuename'] === this.issueName &&
          this.getDates.includes(a['issue_date'])
        );
      });
      // console.log(this.filteredinDetailsRegReport);
    } else if (
      this.startDate_registrationReport != '' &&
      this.enddate_registrationReport != '' &&
      this.repregreport == '' &&
      this.offrepregreport != '' &&
      this.issueName == '' &&
      this.getDates == ''
    ) {
      // console.log('all Filters');
      this.filteredinDetailsRegReport = this.inDetailsRegReport.filter((a) => {
        return (
          a['booking_date'] >= this.startDate_registrationReport &&
          a['booking_date'] <= this.enddate_registrationReport &&
          this.offrepregreport.includes(a['off_rep_name'])
        );
      });
      // console.log(this.filteredinDetailsRegReport);
    } else if (
      this.startDate_registrationReport != '' &&
      this.enddate_registrationReport != '' &&
      this.repregreport != '' &&
      this.offrepregreport == '' &&
      this.issueName == '' &&
      this.getDates == ''
    ) {
      // console.log('all Filters');
      this.filteredinDetailsRegReport = this.inDetailsRegReport.filter((a) => {
        return (
          a['booking_date'] >= this.startDate_registrationReport &&
          a['booking_date'] <= this.enddate_registrationReport &&
          this.repregreport.includes(a['rep_name'])
        );
      });
      // console.log(this.filteredinDetailsRegReport);
    } else if (
      this.startDate_registrationReport != '' &&
      this.enddate_registrationReport != '' &&
      this.repregreport == '' &&
      this.offrepregreport == '' &&
      this.issueName == '' &&
      this.getDates == ''
    ) {
      console.log('just datewise Filters');
      this.filteredinDetailsRegReport = this.inDetailsRegReport.filter((a) => {
        return (
          a['booking_date'] >= this.startDate_registrationReport &&
          a['booking_date'] <= this.enddate_registrationReport
        );
      });
      console.log(this.filteredinDetailsRegReport);
    } else if (
      this.startDate_registrationReport == '' &&
      this.enddate_registrationReport == '' &&
      this.repregreport != '' &&
      this.offrepregreport != '' &&
      this.issueName != '' &&
      this.getDates != ''
    ) {
      // console.log('all Filters');
      this.filteredinDetailsRegReport = this.inDetailsRegReport.filter((a) => {
        return (
          this.repregreport.includes(a['rep_name']) &&
          this.offrepregreport.includes(a['off_rep_name']) &&
          a['issuename'] === this.issueName &&
          this.getDates.includes(a['issue_date'])
        );
      });
      // console.log(this.filteredinDetailsRegReport);
    } else if (
      this.startDate_registrationReport == '' &&
      this.enddate_registrationReport == '' &&
      this.repregreport == '' &&
      this.offrepregreport != '' &&
      this.issueName != '' &&
      this.getDates != ''
    ) {
      // console.log('all Filters');
      this.filteredinDetailsRegReport = this.inDetailsRegReport.filter((a) => {
        return (
          this.offrepregreport.includes(a['off_rep_name']) &&
          a['issuename'] === this.issueName &&
          this.getDates.includes(a['issue_date'])
        );
      });
      // console.log(this.filteredinDetailsRegReport);
    } else if (
      this.startDate_registrationReport == '' &&
      this.enddate_registrationReport == '' &&
      this.repregreport != '' &&
      this.offrepregreport == '' &&
      this.issueName != '' &&
      this.getDates != ''
    ) {
      // console.log('all Filters');
      this.filteredinDetailsRegReport = this.inDetailsRegReport.filter((a) => {
        return (
          this.repregreport.includes(a['rep_name']) &&
          a['issuename'] === this.issueName &&
          this.getDates.includes(a['issue_date'])
        );
      });
      // console.log(this.filteredinDetailsRegReport);
    } else if (
      this.startDate_registrationReport == '' &&
      this.enddate_registrationReport == '' &&
      this.repregreport != '' &&
      this.offrepregreport != '' &&
      this.issueName == '' &&
      this.getDates == ''
    ) {
      // console.log('all Filters');
      this.filteredinDetailsRegReport = this.inDetailsRegReport.filter((a) => {
        return (
          this.repregreport.includes(a['rep_name']) &&
          this.offrepregreport.includes(a['off_rep_name'])
        );
      });
      // console.log(this.filteredinDetailsRegReport);
    } else if (
      this.startDate_registrationReport == '' &&
      this.enddate_registrationReport == '' &&
      this.repregreport == '' &&
      this.offrepregreport == '' &&
      this.issueName != '' &&
      this.getDates != ''
    ) {
      // console.log('all Filters');
      this.filteredinDetailsRegReport = this.inDetailsRegReport.filter((a) => {
        return (
          a['issuename'] === this.issueName &&
          this.getDates.includes(a['issue_date'])
        );
      });
      // console.log(this.filteredinDetailsRegReport);
    } else if (
      this.startDate_registrationReport == '' &&
      this.enddate_registrationReport == '' &&
      this.repregreport == '' &&
      this.offrepregreport != '' &&
      this.issueName == '' &&
      this.getDates == ''
    ) {
      // console.log('all Filters');
      this.filteredinDetailsRegReport = this.inDetailsRegReport.filter((a) => {
        return this.offrepregreport.includes(a['off_rep_name']);
      });
      // console.log(this.filteredinDetailsRegReport);
    } else if (
      this.startDate_registrationReport == '' &&
      this.enddate_registrationReport == '' &&
      this.repregreport != '' &&
      this.offrepregreport == '' &&
      this.issueName == '' &&
      this.getDates == ''
    ) {
      // console.log('all Filters');
      this.filteredinDetailsRegReport = this.inDetailsRegReport.filter((a) => {
        return this.repregreport.includes(a['rep_name']);
      });
      // console.log(this.filteredinDetailsRegReport);
    } else if (
      this.startDate_registrationReport == '' &&
      this.enddate_registrationReport == '' &&
      this.repregreport == '' &&
      this.offrepregreport != '' &&
      this.issueName == '' &&
      this.getDates == ''
    ) {
      // console.log('all Filters');
      this.filteredinDetailsRegReport = this.inDetailsRegReport.filter((a) => {
        return this.offrepregreport.includes(a['off_rep_name']);
      });
      // console.log(this.filteredinDetailsRegReport);
    } else if (
      this.startDate_registrationReport == '' &&
      this.enddate_registrationReport == '' &&
      this.repregreport == '' &&
      this.offrepregreport == '' &&
      this.issueName != '' &&
      this.getDates != ''
    ) {
      // console.log('all Filters');
      this.filteredinDetailsRegReport = this.inDetailsRegReport.filter((a) => {
        return (
          a['issuename'] === this.issueName &&
          this.getDates.includes(a['issue_date'])
        );
      });
      // console.log(this.filteredinDetailsRegReport);
    } else if (
      this.startDate_registrationReport == '' &&
      this.enddate_registrationReport == '' &&
      this.repregreport == '' &&
      this.offrepregreport == '' &&
      this.issueName == '' &&
      this.getDates == ''
    ) {
      // console.log('all Filters');
      this.filteredinDetailsRegReport = this.inDetailsRegReport;
      // console.log(this.filteredinDetailsRegReport);
    }
    console.log(this.filteredinDetailsRegReport);
    this.filtersCalculateData();
  }

  filtersCalculateData() {
    this.inDetailsRegReportTotal = {
      total_advt: 0,
      advt_total: 0,
      net_amt: 0,
      gst: 0,
      total_amt: 0,
      rcvd_amt: 0,
      pndg_amt: 0,
    };
    this.inDetailsRegReportTotal.total_advt =
      this.filteredinDetailsRegReport.length;
    for (
      let index = 0;
      index < this.filteredinDetailsRegReport.length;
      index++
    ) {
      this.inDetailsRegReportTotal.net_amt += parseInt(
        this.filteredinDetailsRegReport[index]['total_amount']
      );
      this.inDetailsRegReportTotal.advt_total += parseInt(
        this.filteredinDetailsRegReport[index]['advt_amt']
      );
      this.inDetailsRegReportTotal.gst += parseInt(
        this.filteredinDetailsRegReport[index]['sgst']
      );
      this.inDetailsRegReportTotal.gst += parseInt(
        this.filteredinDetailsRegReport[index]['cgst']
      );
      this.inDetailsRegReportTotal.total_amt += parseInt(
        this.filteredinDetailsRegReport[index]['amount']
      );
      this.inDetailsRegReportTotal.rcvd_amt += parseInt(
        this.filteredinDetailsRegReport[index]['part_pymt_amt']
      );
      this.inDetailsRegReportTotal.pndg_amt += parseInt(
        this.filteredinDetailsRegReport[index]['part_pymt_remaning_amt']
      );
    }
  }

  getRegReportData() {

    
 
    const req:any = {
      "rep_name":this.RegistrationreportRange.value.repname,
      "start_date":this.RegistrationreportRange.value.start,
      "end_date":this.RegistrationreportRange.value.end,
      "off_rep_name":this.RegistrationreportRange.value.offrepname,
      "issue_name":this.RegistrationreportRange.value.issuename,
      "issue_date": this.RegistrationreportRange.value.issuedate
    }

    // {
    //   "start_date":"2021-01-01",
    //   "end_date":"2025-12-31",
    //   "rep_name":["कार्यालय ( पुस्तक विभाग )"],
    //   "off_rep_name":["ALL"],
    //   "issue_name":["ALL"],
    //   "issue_date":["29/10/2023,विवेक साप्ताहिक,22/10/2023"]
    //   }

    this.service.getRegReportInDetails().subscribe((data) => {
      this.inDetailsRegReport = data;
      console.log(this.inDetailsRegReport);
      this.addFilters();

      // this.inDetailsRegReportTotal = {
      //   total_advt: 0,
      //   advt_total: 0,
      //   net_amt: 0,
      //   gst: 0,
      //   total_amt: 0,
      //   rcvd_amt: 0,
      //   pndg_amt: 0,
      // };
      // this.inDetailsRegReportTotal.total_advt = this.inDetailsRegReport.length;
      // for (let index = 0; index < this.inDetailsRegReport.length; index++) {
      //   this.inDetailsRegReportTotal.net_amt += parseInt(
      //     this.inDetailsRegReport[index]['total_amount']
      //   );
      //   this.inDetailsRegReportTotal.advt_total += parseInt(
      //     this.inDetailsRegReport[index]['advt_amt']
      //   );
      //   this.inDetailsRegReportTotal.gst += parseInt(
      //     this.inDetailsRegReport[index]['sgst']
      //   );
      //   this.inDetailsRegReportTotal.gst += parseInt(
      //     this.inDetailsRegReport[index]['cgst']
      //   );
      //   this.inDetailsRegReportTotal.total_amt += parseInt(
      //     this.inDetailsRegReport[index]['amount']
      //   );
      //   this.inDetailsRegReportTotal.rcvd_amt += parseInt(
      //     this.inDetailsRegReport[index]['part_pymt_amt']
      //   );
      //   this.inDetailsRegReportTotal.pndg_amt += parseInt(
      //     this.inDetailsRegReport[index]['part_pymt_remaning_amt']
      //   );
      // }
    });
  }

  inDetailsRegReportsubTotalData = [];
  tempdata;
  getReportDatewiseBriefly() {
    if (this.inDetailsRegReport.length > 0) {
      this.tempdata = this.uniqueid(
        this.inDetailsRegReport,
        (a) => a.booking_date
      );
      this.inDetailsRegReportsubTotalData = [];
      // console.log('why', tempData);
      for (let index = 0; index < this.tempdata.length; index++) {
        let arr = this.inDetailsRegReport.filter((a) => {
          return a.booking_date == this.tempdata[index]['booking_date'];
        });

        let inDetailsRegReportsubTotal = {
          date: '',
          total_advt: 0,
          advt_total: 0,
          net_amt: 0,
          gst: 0,
          total_amt: 0,
          rcvd_amt: 0,
          pndg_amt: 0,
        };

        inDetailsRegReportsubTotal.total_advt = arr.length;
        inDetailsRegReportsubTotal.date = arr[0]['booking_date'];
        for (let index = 0; index < arr.length; index++) {
          inDetailsRegReportsubTotal.net_amt += parseInt(
            arr[index]['total_amount']
          );
          inDetailsRegReportsubTotal.advt_total += parseInt(
            arr[index]['advt_amt']
          );
          inDetailsRegReportsubTotal.gst += parseInt(arr[index]['sgst']);
          inDetailsRegReportsubTotal.gst += parseInt(arr[index]['cgst']);
          inDetailsRegReportsubTotal.total_amt += parseInt(
            arr[index]['amount']
          );
          inDetailsRegReportsubTotal.rcvd_amt += parseInt(
            arr[index]['part_pymt_amt']
          );
          inDetailsRegReportsubTotal.pndg_amt += parseInt(
            arr[index]['part_pymt_remaning_amt']
          );
        }
        this.inDetailsRegReportsubTotalData.push(inDetailsRegReportsubTotal);
      }
      // console.log(this.inDetailsRegReportsubTotalData);
    }
  }

  inDetailsDatewise() {
    this.getRegReportData();
    setTimeout(() => {
      this.getReportDatewise();
    }, 200);
  }

  getReportDatewise() {
    if (this.filteredinDetailsRegReport.length > 0) {
      this.tempdata = this.uniqueid(
        this.filteredinDetailsRegReport,
        (a) => a.booking_date
      );
      this.inDetailsRegReportsubTotalData = [];
      // console.log('why', tempData);
      for (let index = 0; index < this.tempdata.length; index++) {
        let arr = this.filteredinDetailsRegReport.filter((a) => {
          return a.booking_date == this.tempdata[index]['booking_date'];
        });

        let inDetailsRegReportsubTotal = {
          total_advt: 0,
          advt_total: 0,
          net_amt: 0,
          gst: 0,
          total_amt: 0,
          rcvd_amt: 0,
          pndg_amt: 0,
        };

        inDetailsRegReportsubTotal.total_advt = arr.length;
        for (let index = 0; index < arr.length; index++) {
          inDetailsRegReportsubTotal.net_amt += parseInt(
            arr[index]['total_amount']
          );
          inDetailsRegReportsubTotal.advt_total += parseInt(
            arr[index]['advt_amt']
          );
          inDetailsRegReportsubTotal.gst += parseInt(arr[index]['sgst']);
          inDetailsRegReportsubTotal.gst += parseInt(arr[index]['cgst']);
          inDetailsRegReportsubTotal.total_amt += parseInt(
            arr[index]['amount']
          );
          inDetailsRegReportsubTotal.rcvd_amt += parseInt(
            arr[index]['part_pymt_amt']
          );
          inDetailsRegReportsubTotal.pndg_amt += parseInt(
            arr[index]['part_pymt_remaning_amt']
          );
        }
        arr.push(inDetailsRegReportsubTotal);
        this.inDetailsRegReportsubTotalData.push(arr);
      }
    }
  }

  addadvtCNrcpt() {
    console.log(this.contractReportRepName, this.advtCreditNoteform.value.advtname)
    console.log(this.advtCreditNoteform.value)

    const req1 = {
      advt_name: this.advtCreditNoteform.value.advtname
    }
    const req2 = {
      advt_name1: this.advtCreditNoteform.value.advtname,
      cr_vocher: this.advtCreditNoteform.value.crdtvno,
      create_date: this.advtCreditNoteform.value.date,
      receipt_date: this.advtCreditNoteform.value.recptdate,
      rep_name: this.contractReportRepName,
      credit_count: this.newg
    }
    this.service.getCreditNoteMain(req1).subscribe((data: any) => {
      console.log("ADVT new Credit name Data=", data);
      this.advtCreditNote = (data as any);;
      // this.ngOnInit();
    });

    console.log("req2", req2)

  }

  addadvtCNrcpt1() {
    console.log(this.contractReportRepName, this.advtAdvanceStampform.value.advtname)
    console.log(this.advtAdvanceStampform.value)

    const req1 = {
      advt_name: this.advtAdvanceStampform.value.advtname
    }
    // const req2 = {
    //   advt_name1: this.advtCreditNoteform.value.advtname,
    //   cr_vocher: this.advtCreditNoteform.value.crdtvno,
    //   create_date: this.advtCreditNoteform.value.date,
    //   receipt_date: this.advtCreditNoteform.value.recptdate,
    //   rep_name: this.contractReportRepName,
    //   credit_count: this.newg
    // }
    this.service.getCreditNoteMain(req1).subscribe((data: any) => {
      console.log("ADVT new Credit name Data=", data);
      this.advtCreditNote = (data as any);;
      // this.ngOnInit();
    });

    console.log("req1 for stamp", req1)

  }



  calculateTotal(data) {
    this.inDetailsOffRegReportTotal.total_advt = 0;
    this.inDetailsOffRegReportTotal.net_amt = 0;
    this.inDetailsOffRegReportTotal.advt_total = 0;
    this.inDetailsOffRegReportTotal.gst = 0;
    this.inDetailsOffRegReportTotal.total_amt = 0;
    this.inDetailsOffRegReportTotal.rcvd_amt = 0;
    this.inDetailsOffRegReportTotal.pndg_amt = 0;
    this.inDetailsOffRegReportTotal.total_advt = data.length;
    for (let index = 0; index < data.length; index++) {
      this.inDetailsOffRegReportTotal.net_amt += parseInt(
        data[index]['total_amount']
      );
      this.inDetailsOffRegReportTotal.advt_total += parseInt(
        data[index]['advt_amt']
      );
      this.inDetailsOffRegReportTotal.gst += parseInt(data[index]['sgst']);
      this.inDetailsOffRegReportTotal.gst += parseInt(data[index]['cgst']);
      this.inDetailsOffRegReportTotal.total_amt += parseInt(
        data[index]['amount']
      );
      this.inDetailsOffRegReportTotal.rcvd_amt += parseInt(
        data[index]['part_pymt_amt']
      );
      this.inDetailsOffRegReportTotal.pndg_amt += parseInt(
        data[index]['part_pymt_remaning_amt']
      );
    }
  }

  getReportOffRepVeryBriefly() {
    if (this.inDetailsRegReport.length > 0) {
      this.tempdata = this.inDetailsRegReport.filter((a) => {
        return this.offrepregreport.includes(a.off_rep_name);
      });
      this.inDetailsRegReportsubTotalData = [];
      this.calculateTotal(this.tempdata);

      let data = this.uniqueid(this.tempdata, (a) => a.rep_name);

      for (let index = 0; index < Object.keys(data).length; index++) {
        let arr = this.tempdata.filter((a) => {
          return a.rep_name == data[index]['rep_name'];
        });

        let inDetailsRegReportsubTotal = {
          off_rep_name: '',
          rep_name: '',
          total_advt: 0,
          advt_total: 0,
          net_amt: 0,
          gst: 0,
          total_amt: 0,
          rcvd_amt: 0,
          pndg_amt: 0,
        };

        inDetailsRegReportsubTotal.total_advt = arr.length;
        for (let index = 0; index < arr.length; index++) {
          inDetailsRegReportsubTotal.off_rep_name = arr[index]['off_rep_name'];
          inDetailsRegReportsubTotal.rep_name = arr[index]['rep_name'];
          inDetailsRegReportsubTotal.net_amt += parseInt(
            arr[index]['total_amount']
          );
          inDetailsRegReportsubTotal.advt_total += parseInt(
            arr[index]['advt_amt']
          );
          inDetailsRegReportsubTotal.gst += parseInt(arr[index]['sgst']);
          inDetailsRegReportsubTotal.gst += parseInt(arr[index]['cgst']);
          inDetailsRegReportsubTotal.total_amt += parseInt(
            arr[index]['amount']
          );
          inDetailsRegReportsubTotal.rcvd_amt += parseInt(
            arr[index]['part_pymt_amt']
          );
          inDetailsRegReportsubTotal.pndg_amt += parseInt(
            arr[index]['part_pymt_remaning_amt']
          );
        }
        // arr.push(inDetailsRegReportsubTotal);
        this.inDetailsRegReportsubTotalData.push(inDetailsRegReportsubTotal);
      }
    }
  }

  getReportInDetailOffRep() {
    if (this.inDetailsRegReport.length > 0) {
      this.tempdata = [];
      this.tempdata = this.inDetailsRegReport.filter((a) => {
        return this.offrepregreport.includes(a.off_rep_name);
      });

      this.inDetailsRegReportsubTotalData = [];
      this.calculateTotal(this.tempdata);

      let data = this.uniqueid(this.tempdata, (a) => a.rep_name);

      for (let index = 0; index < Object.keys(data).length; index++) {
        let arr = this.tempdata.filter((a) => {
          return a.rep_name == data[index]['rep_name'];
        });

        let inDetailsRegReportsubTotal = {
          total_advt: 0,
          advt_total: 0,
          net_amt: 0,
          gst: 0,
          total_amt: 0,
          rcvd_amt: 0,
          pndg_amt: 0,
        };

        inDetailsRegReportsubTotal.total_advt = arr.length;
        for (let index = 0; index < arr.length; index++) {
          inDetailsRegReportsubTotal.net_amt += parseInt(
            arr[index]['total_amount']
          );
          inDetailsRegReportsubTotal.advt_total += parseInt(
            arr[index]['advt_amt']
          );
          inDetailsRegReportsubTotal.gst += parseInt(arr[index]['sgst']);
          inDetailsRegReportsubTotal.gst += parseInt(arr[index]['cgst']);
          inDetailsRegReportsubTotal.total_amt += parseInt(
            arr[index]['amount']
          );
          inDetailsRegReportsubTotal.rcvd_amt += parseInt(
            arr[index]['part_pymt_amt']
          );
          inDetailsRegReportsubTotal.pndg_amt += parseInt(
            arr[index]['part_pymt_remaning_amt']
          );
        }
        arr.push(inDetailsRegReportsubTotal);
        this.inDetailsRegReportsubTotalData.push(arr);
      }
    }
  }

  startDate_contract;
  enddate_contract;
  getChangedDateContract() {
    if (
      this.contractDetailsForm.get('start').value != null &&
      this.contractDetailsForm.get('end').value != null
    ) {
      // console.log(this.contractDetailsForm.value);
      this.startDate_contract = formatDate(
        new Date(this.contractDetailsForm.get('start').value),
        'yyyy-MM-dd',
        'en-IN',
        'GMT+0530'
      );
      this.enddate_contract = formatDate(
        new Date(this.contractDetailsForm.get('end').value),
        'yyyy-MM-dd',
        'en-IN',
        'GMT+0530'
      );
    }
  }

  getChangedDateContractM1() {
    if (
      this.PaymentReceiptRegister.get('start').value != null &&
      this.PaymentReceiptRegister.get('end').value != null
    ) {
      // console.log(this.contractDetailsForm.value);
      this.startDate_contract = formatDate(
        new Date(this.PaymentReceiptRegister.get('start').value),
        'yyyy-MM-dd',
        'en-IN',
        'GMT+0530'
      );
      this.enddate_contract = formatDate(
        new Date(this.PaymentReceiptRegister.get('end').value),
        'yyyy-MM-dd',
        'en-IN',
        'GMT+0530'
      );
    }
  }
  getChangedDateContractM11() {
    
    if (
      this.advtbillFormsecond.get('start').value != null &&
      this.advtbillFormsecond.get('end').value != null
    ) {
      // console.log(this.contractDetailsForm.value);
      this.start = formatDate(
        new Date(this.advtbillFormsecond.get('start').value),
        'yyyy-MM-dd',
        'en-IN',
        'GMT+0530'
      );
      this.end = formatDate(
        new Date(this.advtbillFormsecond.get('end').value),
        'yyyy-MM-dd',
        'en-IN',
        'GMT+0530'
      );
    }

    this.getAllAdvtInovicesData()
  }


  // getChangedDateContractC1() {
  //   if (
  //     this.CreditReport1.get('start').value != null &&
  //     this.CreditReport1.get('end').value != null
  //   ) {
  //     // console.log(this.contractDetailsForm.value);
  //     this.startDate_contract = formatDate(
  //       new Date(this.CreditReport1.get('start').value),
  //       'yyyy-MM-dd',
  //       'en-IN',
  //       'GMT+0530'
  //     );
  //     this.enddate_contract = formatDate(
  //       new Date(this.CreditReport1.get('end').value),
  //       'yyyy-MM-dd',
  //       'en-IN',
  //       'GMT+0530'
  //     );
  //   }
  // }
  getChangedDateContractM2() {
    if (
      this.CreditNoteForm.get('start').value != null &&
      this.CreditNoteForm.get('end').value != null
    ) {
      // console.log(this.contractDetailsForm.value);
      this.startDate_contract = formatDate(
        new Date(this.CreditNoteForm.get('start').value),
        'yyyy-MM-dd',
        'en-IN',
        'GMT+0530'
      );
      this.enddate_contract = formatDate(
        new Date(this.CreditNoteForm.get('end').value),
        'yyyy-MM-dd',
        'en-IN',
        'GMT+0530'
      );
    }
  }

  getChangedDateContractM3() {
    if (
      this.OutStandingForm.get('start').value != null &&
      this.OutStandingForm.get('end').value != null
    ) {
      // console.log(this.contractDetailsForm.value);
      this.startDate_contract = formatDate(
        new Date(this.OutStandingForm.get('start').value),
        'yyyy-MM-dd',
        'en-IN',
        'GMT+0530'
      );
      this.enddate_contract = formatDate(
        new Date(this.OutStandingForm.get('end').value),
        'yyyy-MM-dd',
        'en-IN',
        'GMT+0530'
      );
    }
  }
  getChangedDateContractM4() {
    if (
      this.GSTFullReportForm.get('start').value != null &&
      this.GSTFullReportForm.get('end').value != null
    ) {
      // console.log(this.contractDetailsForm.value);
      this.startDate_contract = formatDate(
        new Date(this.GSTFullReportForm.get('start').value),
        'yyyy-MM-dd',
        'en-IN',
        'GMT+0530'
      );
      this.enddate_contract = formatDate(
        new Date(this.GSTFullReportForm.get('end').value),
        'yyyy-MM-dd',
        'en-IN',
        'GMT+0530'
      );
    }
  }
  getChangedDateContractM7() {
    
    if (
      this.PersonalNoteform.get('start').value != null &&
      this.PersonalNoteform.get('end').value != null
    ) {
      // console.log(this.contractDetailsForm.value);
      this.startDate_contract = formatDate(
        new Date(this.PersonalNoteform.get('start').value),
        'yyyy-MM-dd',
        'en-IN',
        'GMT+0530'
      );
      this.enddate_contract = formatDate(
        new Date(this.PersonalNoteform.get('end').value),
        'yyyy-MM-dd',
        'en-IN',
        'GMT+0530'
      );
    }
  }
  getChangedDateContractM8() {
    if (
      this.GSTFullReportForm.get('start').value != null &&
      this.GSTFullReportForm.get('end').value != null
    ) {
      // console.log(this.contractDetailsForm.value);
      this.startDate_contract = formatDate(
        new Date(this.GSTFullReportForm.get('start').value),
        'yyyy-MM-dd',
        'en-IN',
        'GMT+0530'
      );
      this.enddate_contract = formatDate(
        new Date(this.GSTFullReportForm.get('end').value),
        'yyyy-MM-dd',
        'en-IN',
        'GMT+0530'
      );
    }
  }
  getChangedDateContractM5() {
    if (
      this.CommissionReportForm.get('start').value != null &&
      this.CommissionReportForm.get('end').value != null
    ) {
      // console.log(this.contractDetailsForm.value);
      this.startDate_contract = formatDate(
        new Date(this.CommissionReportForm.get('start').value),
        'yyyy-MM-dd',
        'en-IN',
        'GMT+0530'
      );
      this.enddate_contract = formatDate(
        new Date(this.CommissionReportForm.get('end').value),
        'yyyy-MM-dd',
        'en-IN',
        'GMT+0530'
      );
    }
  }

  getChangedDateAdvtRemainder() {
    if (
      this.Sendreminderform.get('startDate').value != null &&
      this.Sendreminderform.get('endDate').value != null
    ) {
      // console.log(this.contractDetailsForm.value);
      this.startDate_contract = formatDate(
        new Date(this.Sendreminderform.get('startDate').value),
        'yyyy-MM-dd',
        'en-IN',
        'GMT+0530'
      );
      this.enddate_contract = formatDate(
        new Date(this.Sendreminderform.get('endDate').value),
        'yyyy-MM-dd',
        'en-IN',
        'GMT+0530'
      );
    }
  }
  // startDate_contract;
  // enddate_contract_payment;
  // getChangedDatePaymentRegi() {
  //   if (
  //     this.contractDetailsForm.get('start').value != null &&
  //     this.contractDetailsForm.get('end').value != null
  //   ) {
  //     // console.log(this.contractDetailsForm.value);
  //     this.startDate_contract = formatDate(
  //       new Date(this.contractDetailsForm.get('start').value),
  //       'yyyy-MM-dd',
  //       'en-IN',
  //       'GMT+0530'
  //     );
  //     this.enddate_contract = formatDate(
  //       new Date(this.contractDetailsForm.get('end').value),
  //       'yyyy-MM-dd',
  //       'en-IN',
  //       'GMT+0530'
  //     );
  //   }
  // } 
  contractReportRepName = '';
  getContractRepName(event) {
    this.contractReportRepName = event.value;
  }


  contractReportOffRepName = '';
  getContractOffRepName(event) {
    this.contractReportOffRepName = event.value;
  }
  getRepNames(event:any){
    if(event.target.value!=""){
      this.filterrep=this.GetOffRep.filter((data:any)=>
        data.rep_name.toLowerCase().includes(event.target.value.toLowerCase())
    )
    }else{
      this.filterrep=this.GetOffRep
    }
  }
  getIssuedateNames(event:any){
    
    if(event.target.value!=""){
  
      this.filterissuedate=this.getMagIssueDatesandAdvtNames1.filter((data:any)=>
        data.issueDate.toLowerCase().includes(event.target.value.toLowerCase())
    )
    }else{
      this.filterissuedate=this.getMagIssueDatesandAdvtNames1
    }

  }
  setissueDate(date:any){
    this.Advtremainingamt.patchValue({
      issuedate:date,
      issueDate:date

    })
  }
  getIssueNames(event:any){
    if(event.target.value!=""){
      this.oldissuename=this.issuesub.filter((data:any)=>
        data.issueName.toLowerCase().includes(event.target.value.toLowerCase())
    )
    }else{
      this.oldissuename=this.issuesub
    }
  }
  getContractRepNames(event) {
    
   this.Advtremainingamt.patchValue({
    rep_name: event.source.value
   })

   this.contractReportRepName=event.source.value
   
  }
  contractReportAdvtRepName = '';
  getContractAdvtName(event) {
    

    console.log(this.advtCreditNoteform.value.advtname);
    this.contractReportAdvtRepName = event.value;
  }

  CreditnoteAdvt = '';
  getCAdvtName(event) {
    // console.log(event.value);
    this.CreditnoteAdvt = event.value;
  }

  updateolddata(data) {
    
    let result = this.dialog.open(OlddataupdateComponent, {
      data:data,
      width: '900px',
      height: '500px',
      maxWidth: '100%',
    });
  }
  updateadvttype(data) {
    
   this.Addadvttype.patchValue({
    id: data.id,
    advttype: data.advt_type,
    advttypedeatils : data.advt_types_select
   })

  }
  addid:any
  pageno:any
  newpageno:any
  addpageno(data) {
    


    this.addid = data['DS_ADVTID'];
    this.pageno = data['pages_no'];
    const req:any = {
      "pages_no":this.pageno,
      "DS_ADVTID":this.addid
    }
    this.service.addpp_pageno(req).subscribe((data) => {
      // this.newpageno = data;
      // console.log(this.newpageno, "this.getMagIssnewpagenoueDatesandAdvtNames")
    });
    this.toaster.success('success', 'Page No Added Succesfully');
    this.ngOnInit(); 
   }
  typeclear(){
    this.Addadvttype.reset();
  }

  removeolddata(id) {
    window.alert(
      'This is hard delete operation and will be unrecoverable. This may cause your ' +
        'application behave differently.'
    );
    if (
      window.confirm(
        'Do you really want to remove the data'
      )
    ) {
      this.service.removeOlddatay(parseInt(id)).subscribe((data) => {
        
      });
    }
  }

  removeadvttype(id) {
    
    window.alert(
      'This is hard delete operation and will be unrecoverable. This may cause your ' +
        'application behave differently.'
    );
    if (
      window.confirm(
        'Do you really want to remove the data'
      )
    ) {
      this.service.removeadvttype(parseInt(id)).subscribe((data) => {
        
      });
    }
  }

  contractReportType = '';
  getContractReportType(event) {
    // console.log(event.value);
    this.contractReportType = event.value;
  }

  showContractReportReminder() {
    // console.log(this.contractData);
    if (this.contractReportType !== '') {
      this.dialog.open(ContractBriefReportComponent, {
        data: {
          contractData: this.contractData,
          startDate: this.Sendreminderform.value.start,
          endDate: this.Sendreminderform.value.end,
          repName: this.Sendreminderform.value.Repname,
          advtName: this.Sendreminderform.value.Advtname,
          
        },
        height: '1300px',
        width: '95%',
        maxWidth: '100%',
      });
    }
    else {
      alert('Please select report type');
    }
  }

  showContractReport() {
    // console.log(this.contractData);
    const req:any = {
      "rep_name":this.CreditNoteForm.value.rep_name,
      "start_date":this.CreditNoteForm.value.start,
      "end_date":this.CreditNoteForm.value.end,
      "off_rep_name":this.CreditNoteForm.value.off_rep_name,
      "issue_name":this.CreditNoteForm.value.issue_cat,
      "advt_name" : this.advtName
    }
    this.service.getCreditRegiInDetails1(req).subscribe((data) => {
      
      this.advtcreditnotetRegiInDetailsFormBeans = data;
      this.PaymentadvtOffInDTotal = 0;
      console.log( this.advtcreditnotetRegiInDetailsFormBeans , "data receipt")
    
       
       for (let data1 of this.advtcreditnotetRegiInDetailsFormBeans) {
         
               this.PaymentadvtOffInDTotal += parseInt(data1.credit_v_amt) || 0;
          
              }
       })
       this.service.getCreditRegiInDetails1(req).subscribe((data) => {
        // this.data_for_two_receipt = data;
   
        // this.data_for_two_receipt.forEach(element => {
        //  element.is_selected = false;
        // });

       debugger
    if (this.contractReportType !== '') {
      this.dialog.open(ContractBriefReportComponent, {
        data: {
          contractData: this.contractData,
          startDate: this.startDate_contract,
          endDate: this.enddate_contract,
          repName: this.contractReportRepName,
          offRepName: this.contractReportOffRepName,
          advtName: this.contractReportAdvtRepName,
          reportType: this.contractReportType,
        },
        height: '1300px',
        width: '95%',
        maxWidth: '100%',
      });
    }
    else {
      alert('Please select report type');
    }
  })
  }
  showContractReportoutstanding() {
    
    // console.log(this.contractData);
    if (this.contractReportType !== '') {
      this.dialog.open(ContractBriefReportComponent, {
        data: {
          // contractData: this.OutStandingForm.value.repname,
          startDate: this.OutStandingForm.value.start,
          endDate: this.OutStandingForm.value.end,
          repName: this.OutStandingForm.value.repname,
          offRepName: this.OutStandingForm.value.offrepname,
          advtName: this.OutStandingForm.value.issuecate,
          reportType:this.OutStandingForm.value.advtname,
        },
        height: '1300px',
        width: '95%',
        maxWidth: '100%',
      });
    }
    else {
      alert('Please select report type');
    }
  }

  showContractoneReport() {
    
    // console.log(this.contractData);
    if (this.contractReportType !== '') {
      this.dialog.open(ContractBriefReportComponent, {
        data: {
          // contractData: this.contractData,
          startDate: this.contractDetailsForm.value.start,
          endDate: this.contractDetailsForm.value.end,
          repName: this.contractDetailsForm.value.rep_name,
          offRepName: this.contractDetailsForm.value.off_rep_name,
          advtName: this.contractDetailsForm.value.advt_name,
          reportType: this.contractReportType,
        },
        height: '1300px',
        width: '95%',
        maxWidth: '100%',
      });
    }
    else {
      alert('Please select report type');
    }
  }
  
  showPersonalLedgerReport() {
    // console.log(this.contractData);
    if (this.contractReportType !== '') {
      this.dialog.open(ContractBriefReportComponent, {
        data: {
          contractData: this.contractData,
          startDate: this.startDate_contract,
          endDate: this.enddate_contract,
          repName: this.contractReportRepName,
          offRepName: this.contractReportOffRepName,
          advtName: this.PersonalNoteform.value.advtname,
          reportType: this.contractReportType,
        },
        height: '1300px',
        width: '95%',
        maxWidth: '100%',
      });
    }
    else {
      alert('Please select report type');
    }
  }

  advtNames;
  getMagIssueDatesandAdvtNames;
  getIssuesAdvtNames(e) {
    // console.log(e);
    this.advtNames = e.source.value;
    // console.log('jgfdfgh', this.advtNames);
    this.service.getAdvtDatesAndNames(e.source.value).subscribe((data) => {
      this.getMagIssueDatesandAdvtNames = data;
      console.log(this.getMagIssueDatesandAdvtNames, "this.getMagIssueDatesandAdvtNames")
      this.filterissuedate = data;
    });
  }
  getIssuesAdvtNames1(e) {
    
    // console.log(e);
    this.advtNames =e.source.value;
    // console.log('jgfdfgh', this.advtNames);
    this.service.getAdvtDatesAndNames1(e.source.value).subscribe((data) => {
      this.getMagIssueDatesandAdvtNames1 = data;
      this.filterissuedate = data;
      console.log(this.getMagIssueDatesandAdvtInvoiceNames, "issue date by isssu name")
    });
  }

  advtInvoiceNames1;
  getMagIssueDatesandAdvtInvoiceNames;
  getIssuesAdvtInvoice(e) {
    // console.log(e);
    this.advtInvoiceNames1 = e.value;
    // console.log('jgfdfgh', this.advtNames);
    this.service.getAdvtDatesAndNames(e.value).subscribe((data) => {
      this.getMagIssueDatesandAdvtInvoiceNames = data;
      console.log('advt name', data);
    });
  }

  advtInvoiceIssueDates;
  getAdvtInvoiceDates(e) {
    this.advtInvoiceIssueDates = e.value;
  }

  advtInvoiceStartDate;
  advtInvoiceEndDate;
  getChangedDateAdvtInvoice() {
    if (
      this.advtinvoiceForm.get('start').value != null &&
      this.advtinvoiceForm.get('end').value != null
    ) {
      this.advtInvoiceStartDate = formatDate(
        this.advtinvoiceForm.get('start').value,
        'yyyy-MM-dd',
        'en-IN',
        'GMT+0530'
      );
      this.advtInvoiceEndDate = formatDate(
        this.advtinvoiceForm.get('end').value,
        'yyyy-MM-dd',
        'en-IN',
        'GMT+0530'
      );
    }
  }
  advtstampStartDate:any
  advtstampEndDate:any
  getdateforstamp() {
    
    if (
      this.stampgetdata.get('start').value != null &&
      this.stampgetdata.get('end').value != null
    ) {
      this.advtstampStartDate = formatDate(
        this.stampgetdata.get('start').value,
        'yyyy-MM-dd',
        'en-IN',
        'GMT+0530'
      );
      this.advtstampEndDate = formatDate(
        this.stampgetdata.get('end').value,
        'yyyy-MM-dd',
        'en-IN',
        'GMT+0530'
      );
    }
    debugger
    const advt_name = '';
    const start_date =  this.advtstampStartDate;
    const end_date = this.advtstampEndDate;
  
    // Call the service with the calculated dates
    this.service.getStampdata(advt_name, start_date, end_date).subscribe((data: any) => {
      console.log("ADVT new Credit name Data=", data);
      this.stampdata = data;
      console.log(this.stampdata, "stamp data");
      // this.ngOnInit();
    });
  }


 

  advtNamesIssueDates;
  advtNamesStringDates;
  advtInvoiceNames;
  getAdvtNameDates(e) {
    
    this.advtNamesIssueDates = e.value;
    this.advtNamesStringDates = this.advtNamesIssueDates.toString();

    // console.log('StringDates', this.advtNamesStringDates);
    console.log('IssueDates', this.advtNamesIssueDates);
    this.advtInvoiceNames = [];
    for (let index = 0; index < this.advtNamesIssueDates.length; index++) {
      this.service
        .getAdvtInvoiceforAdvtNames(this.advtNamesIssueDates[index])
        .subscribe((res: any) => {
          // console.log(`${index}`, res);
          if (res) {
            res?.map((a) => {
              this.advtInvoiceNames.push({ advt_name: a.name });
            });
          }
        });
      if (index == this.advtNamesIssueDates.length - 1) {
        console.log(this.advtInvoiceNames);

        //   this.advtInvoiceNames = this.uniqueid(
        //     this.advtInvoiceNames,
        //     (i) => i.advt_name
        //   );
      }
    }
    console.log(this.advtInvoiceNames);

    this.advtInvoiceNames = this.uniqueid(
      this.advtInvoiceNames,
      (i) => i.advt_name
    );
    console.log(this.advtInvoiceNames);

    this.cid =  this.advtbillForm.value.issuedate

    this.service.getinvoicdedetails(this.cid).subscribe((res) => {
      
      // console.log(res);
      this.newissuedate = res;
      // this.newissuedate1 = this.newissuedate[0].DS_ISSUEDATE
      let dates=[]
      this.newissuedate = res;
      this.issueName = res;
      this.newissuedate.forEach((element:any) => {
        dates.push(element.DS_ISSUEDATE)
      });
      this.newissuedate1=dates
      console.log(this.newissuedate1, "invoice list for new issuedaate")
      // for (let index = 0; index < this.advtInvoiceList.length; index++) {
      //   this.advtNamesData.push({
      //     id: this.advtInvoiceList[index]['id'],
      //     advt_name: this.advtInvoiceList[index]['advt_name'],
      //   });
      // }
    });

  }

  advtInvoiceDate(event) {
    // console.log(event.value);
    this.getAdvtInoivceNo(event.value);
  }

  getAdvtInoivceNo(date) {
    // let date = new Date();
    let id = 1;
    this.service
      .getNextAdvtInvoiceNo(formatDate(date, 'yyyy-MM-dd', 'en-IN', 'GMT+0530'))
      .subscribe((res) => {
        // console.log(res);
        if (Object.keys(res).length > 0) {
          id = parseInt(res['advt_invoice_no'].split('/')[3]) + 1;
          // console.log(id);
        }

        let bill_no = `VSJ/${('0' + (date.getMonth() + 1)).slice(-2)}/${date.getMonth() + 1 < 4
            ? (date.getFullYear() - 1).toString().slice(-2) +
            '' +
            date.getFullYear().toString().slice(-2)
            : date.getFullYear().toString().slice(-2) +
            '' +
            (date.getFullYear() + 1).toString().slice(-2)
          }/${('0000' + id).slice(-4)}`;
        //   // console.log(bill_no);
        this.advtbillForm.get('billno').setValue(bill_no);
      });
  }

  openAdvtBill() {
    
    let date=new Date(this.advtbillForm.get('date').value)
    let dialogData = this.dialog.open(AdvtBillComponent, {
      data: {
        issuenames: this.advtNames,
        issueDates: this.newissuedate1,
        issueName :this.issueName,
        advtName: this.getData,
        date: date,
        isupdate: this.isupdate ,
        billno: this.advtbillForm.get('billno').value,
      },
      width: '1200px',
      height: '1300px',
      maxWidth: '100%',

    });

    dialogData.afterClosed().subscribe((output) => {
      this.advtbillForm.reset('');
      // this.getIssuesAdvtNames({ value: '' });
      console.log(output);
      this.ngOnInit();
      this.getAllAdvtInovicesData();
    });
  }

  getAllAdvtInovicesData() {
    
    const req:any = {
      "start_date":this.start,
      "end_date":this.end,
    }

    this.service.getAllAdvtInvoicesList(req).subscribe((res) => {
      
      // console.log(res);
      this.advtInvoiceList = res;
      console.log(res, "invoice list")
      // for (let index = 0; index < this.advtInvoiceList.length; index++) {
      //   this.advtNamesData.push({
      //     id: this.advtInvoiceList[index]['id'],
      //     advt_name: this.advtInvoiceList[index]['advt_name'],
      //   });
      // }
    });
    // this.ngOnInit();
    // console.log(this.advtNamesData);
  }

  cancelAdvtInvoice(id: number) {
    if (
      window.confirm(
        'This operation is not reversable, Do you want to cancel invoice?'
      )
    ) {
      // console.log(id);
      this.service.cancelAdvtInvoice(id).subscribe((res) => {
        if (res['status'] === 'Success') {
          // console.log(res);
          alert(res['message']);
          this.ngOnInit();
        } else {
          alert('Something went wrong, Please try again.');
        }
      });
    }
  }

  viewAdvtInvoice(id: number) {
    let data = this.advtInvoiceList.filter((a) => a.id === id);
    // console.log(id, data);
debugger;
    this.openAdvtInvoice(data);
  }

  openAdvtInvoice(data: any) {
    let dialogdata = this.dialog.open(ViewAdvtBillComponent, {
      data: {
        advtData: data,
      },
      width: '1200px',
      height: '1300px',
      maxWidth: '100%',
    });

    dialogdata.afterClosed().subscribe((output) => {
      this.ngOnInit();
      // this.allSelectedtable = false;
    });
  }

  GenerateCRno2(){
    let indate = this.datePipe.transform(this.Advtremainingamt.value.invoiceDate, 'dd-MM-yyyy');

    // let indate = this.Advtremainingamt.value.invoiceDate;
    console.log(indate, "indate")
  }
  recdate1 :any
  GenerateCRno() {
    

    // let recdate = this.advtCreditNoteform.value.recptdate ;
     this.recdate1 = this.datePipe.transform(this.advtCreditNoteform.value.recptdate, 'yyyy-MM-dd');
    // console.log(recdate);

    this.service.getCreditNoteG1(this.recdate1).subscribe((data: any) => {
      

      // console.log("auto CrNo=",data);
      this.autogeneratedno = (data as any);;
      // console.log(this.autogeneratedno)
      this.advtCreditNoteform.patchValue({
        crdtvno: this.autogeneratedno.new_rcpt
  
      })

    });
    // let newg: Number = 0;
    // // this.newg = this.autogeneratedno;
    // if (this.autogeneratedno == undefined) {
    //   this.newg = 1;
    // }
    // else {
    //   this.newg++;
    // }

    // let today = formatDate(new Date(), 'yyyy-MM-dd', 'en-IN', 'GMT+0530');
    // let rcpt_no = `${new Date(recdate).getMonth() + 1 < 4
    //     ? (new Date(recdate).getFullYear() - 1).toString().slice(-2) +
    //     '-' +
    //     new Date(recdate).getFullYear().toString().slice(-2)
    //     : new Date(recdate).getFullYear().toString().slice(-2) +
    //     '-' +
    //     (new Date(recdate).getFullYear() + 1).toString().slice(-2)
    //   }`;
    // let no: Number = 1;

    // console.log('JCN'+this.datePipe.transform(this.currentDate, 'MM')+'00'+this.newg+'/'+rcpt_no)
    
    //  this.autogeneratedno ++;
  }
  recdate:any
  GenerateAdvanceStampno() {

    // let recdate = this.advtCreditNoteform.value.recptdate ;
    this.recdate = this.datePipe.transform(this.advtAdvanceStampform.value.recptdate, 'yyyy-MM-dd');
    // console.log(recdate, "stampnumber");

    this.service.getAdvtStampNumber(this.recdate).subscribe((data: any) => {

      // console.log("auto CrNo=",data);
      this.autogeneratedno = (data as any);;
      console.log(this.autogeneratedno.new_rcpt)
      this.advtsn = this.autogeneratedno.new_rcpt

      this.advtAdvanceStampform.patchValue({
        crdtvno: this.advtsn
      })

    });
    // let newg: Number = 0;
    // if (this.autogeneratedno == undefined) {
    //   this.newg = 1;
    // }
    // else {
    //   this.newg++;
    // }

    // let today = formatDate(new Date(), 'yyyy-MM-dd', 'en-IN', 'GMT+0530');
    // let rcpt_no = `${new Date(recdate).getMonth() + 1 < 4
    //     ? (new Date(recdate).getFullYear() - 1).toString().slice(-2) +
    //     '-' +
    //     new Date(recdate).getFullYear().toString().slice(-2)
    //     : new Date(recdate).getFullYear().toString().slice(-2) +
    //     '-' +
    //     (new Date(recdate).getFullYear() + 1).toString().slice(-2)
    //   }`;
    // let no: Number = 1;

    // console.log('JCN'+this.datePipe.transform(this.currentDate, 'MM')+'00'+this.newg+'/'+rcpt_no)
    // 
    // this.advtAdvanceStampform.patchValue({
    //   crdtvno: this.advtsn
    // })
    
    //  this.autogeneratedno ++;
  }

  editAdvtInvoice(data: any) {
    console.log(data);
  }

  advtInvoiceReportType;
  getAdvtInvoiceReportType(e) {
    this.advtInvoiceReportType = e.value;
  }

  openAdvtInvoiceRegister() {
    
    // this.issuedatelist = this.advtinvoiceForm.value.issuedate.split(',')[0];
    this.dialog.open(AdvtInvoiceRegisterComponent, {
      data: {
        startDate: this.advtInvoiceStartDate,
        endDate: this.advtInvoiceEndDate,
        issueName: this.advtinvoiceForm.value.issuename,
        issueDates: this.advtinvoiceForm.value.issuedate,
        reportType: this.advtInvoiceReportType,
        
      },
      width: '1200px',
      height: '1300px',
      maxWidth: '100%',
    });
  }

  generateCreditNoteNumber(): string {
    // Format: YYYYMMDD
    const today = new Date();
    const year = today.getFullYear();
    const month = (today.getMonth() + 1).toString().padStart(2, '0');
    const day = today.getDate().toString().padStart(2, '0');

    const creditNoteNumber = `${year}${month}${day}`;

    // Save the generated credit note number in the variable
    this.generatedCreditNote = creditNoteNumber;

    return creditNoteNumber;
  }
  advtname: any
  searchAdvtCN() {

    {
      // console.log(this.contractData);
      if (this.contractReportType !== '') {
        this.dialog.open(ContractBriefReportComponent, {
          data: {
            contractData: this.contractData,
            startDate: this.startDate_contract,
            endDate: this.enddate_contract,
            repName: this.contractReportRepName,
            offRepName: this.contractReportOffRepName,
            advtName: this.contractReportAdvtRepName,
            reportType: this.contractReportType,
          },
          height: '1300px',
          width: '95%',
          maxWidth: '100%',
        });
      }
      // else {
      //   alert('Please select report type');
      // }
    }

  }

  opentworeceipt() {
    
    // console.log(this.contractData);
     
      this.dialog.open(TworeceiptComponent, {
        data: {
          receiptdata: this.newList1,
          
         
        },
        height: '1300px',
        width: '95%',
        maxWidth: '100%',
      });
      console.log(this.data, "open two receipt")
    
  }

  opentwostampreceipt(book:any) {
    
    // console.log(this.contractData);
     
      this.dialog.open(ThreeStampReceiptComponent, {
        data: {
          stampdata: book,
          
         
        },
        height: '1300px',
        width: '95%',
        maxWidth: '100%',
      });
      console.log(this.data, "open two receipt")
    
  }

  showContractReportPRR() {
    const req:any = {
      "rep_name":this.PaymentReceiptRegister.value.rep_name,
      "start_date":this.PaymentReceiptRegister.value.start,
      "end_date":this.PaymentReceiptRegister.value.end,
      "off_rep_name":this.PaymentReceiptRegister.value.off_rep_name,
      "issue_name":this.PaymentReceiptRegister.value.issuename,
      "issue_date":this.PaymentReceiptRegister.value.issueDates,

      "advt_name" : this.advtName
    }

    this.service.getPaymentRegiInDetails1(req).subscribe((data) => {
      
     this.advtPaymentRegiInDetailsFormBeans = data;
     this.PaymentadvtOffInDTotal = 0;
     console.log( this.advtPaymentRegiInDetailsFormBeans , "data receipt")
   
      
      for (let data1 of this.advtPaymentRegiInDetailsFormBeans) {
        
              this.PaymentadvtOffInDTotal += parseInt(data1.rcv_amt) || 0;
         
             }
      });
    
      
  
    this.service.getPaymentRegiInDetails1(req).subscribe((data) => {
     this.data_for_two_receipt = data;

     this.data_for_two_receipt.forEach(element => {
      element.is_selected = false;
     });
     if (this.contractReportType !== '') {
      this.dialog.open(ContractBriefReportComponent, {
        data: {
          contractData: this.contractData,
          startDate: this.startDate_contract,
          endDate: this.enddate_contract,
          repName: this.contractReportRepName,
          offRepName: this.contractReportOffRepName,
          advtName: this.contractReportAdvtRepName,
          reportType: this.contractReportType,
          issueDates:this.PaymentReceiptRegister.value.issueDates
        },
        height: '1300px',
        width: '95%',
        maxWidth: '100%',
      });

      
    
      
    } else {
      alert('Please select report type');
    }
    })
    
    // console.log(this.contractData);
 
  }

crdtvno:any
  openAdvtBill1(book) {
    debugger
    this.advtCreditNote1 = book
    console.log("new book", book)
    const remainval = book.remain_amt;
    console.log("remain val", remainval)
    debugger
    this.crdtvno=this.advtCreditNoteform.value.crdtvno;
    let dialogData = this.dialog.open(OpenselectmodalComponent, {
      data: {
        issuenames: this.advtNames,
        issueDates: this.advtNamesIssueDates,
        advertisement_id: book.advertisement_id,
        date: this.advtbillForm.get('date').value,
        invoice_no: book.invoice_no,
        invoice_date: book.invoice_date,
        advt_amt:book.advt_amt,
        advt_name1: this.advtCreditNoteform.value.advtname,
        cr_vocher: this.advtCreditNoteform.value.crdtvno,
        create_date: this.advtCreditNoteform.value.date,
        receipt_date: formatDate(this.advtCreditNoteform.value.recptdate, 'dd-MM-yyyy', 'en-US'),
        rep_name:   book.rep_name,
        credit_count: this.newg,
        remainvalue : remainval
      },
     
      width: '1200px',
      height: '1300px',
      maxWidth: '100%',

    });

    dialogData.afterClosed().subscribe((output) => {
      this.advtbillForm.reset('');
      // this.getIssuesAdvtNames({ value: '' });
      this.credit_type = output.credit_type
      this.remark = output.remark
      console.log(output);
      this.ngOnInit();
      this.getAllAdvtInovicesData();
    });
  }

  openAdvtBill2() {
   
    let dialogData = this.dialog.open(CommmodalComponent, {
      data: {
        ModalList: this.newList,
        // issueDates: this.advtNamesIssueDates,
        // advertisement_id: book.advertisement_id,
        // date: this.advtbillForm.get('date').value,
        // invoice_no: book.invoice_no,
        // advt_name1: this.advtCreditNoteform.value.advtname,
        // cr_vocher: this.advtCreditNoteform.value.crdtvno,
        // create_date: this.advtCreditNoteform.value.date,
        // receipt_date: this.advtCreditNoteform.value.recptdate,
        // rep_name: this.contractReportRepName,
        // credit_count: this.newg
      },
      width: '95%',
      height: '1300px',
      maxWidth: '100%',

    });

    dialogData.afterClosed().subscribe((output) => {
      
      // this.advtbillForm.reset('');
      // // this.getIssuesAdvtNames({ value: '' });
      // console.log(output);
      // this.ngOnInit();
      // this.getAllAdvtInovicesData();
      this.newList = [];
      this.practice()
    });
  }

  openAdvtBill3() {
    
    const req3:any = {
      "data":this.advtCreditNote1.advertisement_id,
    }
    this.service.getCdatabyid(req3).subscribe((data: any) => {
      console.log("Get databy id",data)
      if(data.length==0){
        debugger
        let dialogData = this.dialog.open(CrreceiptComponent, {
          data: {
            name: data.name,
            add : data.address,
            mobile : data.mobile,
            invoicenum : data.invoice_no,
            cr_vocher : data.cr_vocher,
            invoicedate : data.voc_date,
            jama : data.credit_amt,
            credit_type : this.credit_type,
            remark : this.remark,
            cr_voucher :this.crdtvno,
           
          },
          
          width: '1200px',
          height: '1300px',
          maxWidth: '100%',
    
        });
        dialogData.afterClosed().subscribe((output) => {
        });
      }else{
        let dialogData = this.dialog.open(CrreceiptComponent, {

          data: {
            name: data[data.length-1].name,
            add : data[data.length-1].address,
            mobile : data[data.length-1].mobile,
            invoicenum : data[data.length-1].invoice_no,
            // cr_vocher : data[data.length-1].cr_vocher,
            invoicedate : data[data.length-1].voc_date,
            jama : data[data.length-1].credit_amt,
            credit_type : this.credit_type,
            remark : this.remark,
            cr_voucher :this.crdtvno,
          },
          
          width: '1200px',
          height: '1300px',
          maxWidth: '100%',
    
        });
        dialogData.afterClosed().subscribe((output) => {
        });
      }
    });
  }
  openAdvtBill4() {
    
    let dialogData = this.dialog.open(StampreceiptComponent, {
      data: {
        // console.log(this.newList1, "this.newList")
        stampdata : this.newList1,
        // total : this.total,  formatDate(event.target.value, 'MM', 'en-IN', 'GMT+0530')
        // creation_date : this.advtAdvanceStampform.value.recptdate,
        creation_date : formatDate(this.advtAdvanceStampform.value.recptdate,'yyyy-MM-dd', 'en-IN', 'GMT+0530'),
        current_date : this.datePipe.transform(this.currentDate, 'yyyy-MM-dd') ,
        // issuenames: this.advtNames,
        // issueDates: this.advtNamesIssueDates,
        // advertisement_id: book.advertisement_id,
        // date: this.advtbillForm.get('date').value,
        // invoice_no: book.invoice_no,
        // advt_name1: this.advtCreditNoteform.value.advtname,
        // cr_vocher: this.advtCreditNoteform.value.crdtvno,
        // create_date: this.advtCreditNoteform.value.date,
        // receipt_date: this.advtCreditNoteform.value.recptdate,
        rep_name:  this.contractReportRepName,
        stamp_number: this.advtsn,
        advancenum : this.advtAdvanceStampform.value.crdtvno,
      },
      width: '1200px',
      height: '1300px',
      maxWidth: '100%',
    });
    console.log("hello", this.data)

    dialogData.afterClosed().subscribe((output) => {
      this.advtbillForm.reset('');
      // this.getIssuesAdvtNames({ value: '' });
      
      console.log(output);
      this.ngOnInit();
      this.getAllAdvtInovicesData();
    });
  }

  practice() {

    const req:any = {
      "start_date":this.startDate_contract,
      "end_date":this.enddate_contract,
      "rep_name":this.contractReportRepName[0],
      "issue_name":""
    }

   this.service.practice(req).subscribe((data: any) => {
     console.log("practice",data)
       this.CommissionData = data as any;
      for(let data of this.CommissionData){
        for (let data1 of data.rep_data){
          let copydata=null;
          data1.copydata = copydata
        }
      }
       this.selecteddata = 0;
   });
}



copydata(data1, event){
  if(event.target.checked){
    data1.copydata = data1.rcv_amt;
    this.newList.push(data1);
  }
  else{
    data1.copydata = null;
    this.newList = this.newList.filter((person:any) => person.advt_id !== data1.advt_id).slice();
  }
  console.log(this.newList, "this.newList")
}
newList =[]
total = 0;

copydata1(data1, event){
  if(event.checked){
    this.total = this.total + data1.remain_amt
    this.newList1.push(data1);
  }
  else{
    this.newList1 = this.newList1.filter((person:any) => person.rcpt_no !== data1.rcpt_no).slice();
    this.total = this.total - data1.remain_amt
  }
  console.log(this.newList1, "this.newList")
  console.log(this.total)
}
copydata2(data1, event){
  if(event.checked){
    this.total = this.total + data1.remain_amt
    this.newList1.push(data1);
  }
  else{
    this.newList1 = this.newList1.filter((person:any) => person.rcpt_no !== data1.rcpt_no).slice();
    this.total = this.total - data1.remain_amt
  }
  console.log(this.newList1, "this.newList")
  console.log(this.total)
}

saveolddata(){
  if(this.Advtremainingamt.invalid){
    this.toaster.error('error', 'Fill required data');
    return
    
  }
  const data = this.Advtremainingamt.value;
  let indate = this.datePipe.transform(this.Advtremainingamt.value.invoiceDate, 'dd-MM-yyyy');
  data.invoiceDate=indate
    console.log("penind data", data)
    this.toaster.success('success', 'Data Added Succesfully');
  
    this.service.getaddolddata(data).subscribe((data:any) => {
      console.log("old data=",data);
    
     this.oldata() 
    //  this.toaster.success('success', 'add');
   });
   this.Advtremainingamt.reset();
   this.  invoicenumber = 'VSJ-'
}
saveupdatedata(){
  const data:any = {
    "advt_types_select":this.Addadvttype.value.advttypedeatils,
    "advt_type":this.Addadvttype.value.advttype,
    "id":this.Addadvttype.value.id
  }

  this.service.addupdateddata(data).subscribe((data:any) => {
    console.log("add advt type",data);
    this.toaster.success('success', 'Updadated  Succesfully');

    this.ngOnInit();
    //  this.toaster.success('success', 'add');
   });

}
saveadvttype(){
  if(this.Addadvttype.invalid){
    this.toaster.error('error', 'Fill required data');
    return
    
  }
  const data:any = {
    "advt_type":this.Addadvttype.value.advttype,
    "advt_types_select":this.Addadvttype.value.advttypedeatils
  }
    
  
    this.service.getaddadvttype(data).subscribe((data:any) => {
      console.log("add advt type",data);
    
    this.ngOnInit();
    //  this.toaster.success('success', 'add');
   });
   this.Advtremainingamt.reset();
   this.  invoicenumber = 'VSJ-'
}


selectall(event){
    if(event.checked){
      debugger
      this.total =0;
      this.data_for_two_receipt.forEach(element => {
        element.is_selected = true;
        // this.total = this.total + element.remain_amt
      })
      }

      else{
        this.data_for_two_receipt.forEach(element => {
          element.is_selected = false;
        });;
    }
    }

newList1 =[]

updatePendingAmount(): void {
  const totalamt = this.Advtremainingamt.get('totalamt').value;
  const receivedAmount = this.Advtremainingamt.get('receivedAmount').value;

  if (totalamt !== null && receivedAmount !== null) {
    const pendingamt = totalamt - receivedAmount;
    this.Advtremainingamt.patchValue({ pendingamt }, { emitEvent: false });
  }
}

issueDate:any
// issueName:any
updateAdvtInvoice(data){
  this.isupdate = true;
  // let date=this.datePipe.transform(data.invoice_date,'yyyy-MM-dd')

  if(data.fourth_week_issue_name!="" && data.fourth_week_issue_name!=null && data.fourth_week_issue_name!=undefined){
    this.issueName=data.fourth_week_issue_name
  }else if(data.fifth_week_issue_name !="" && data.fifth_week_issue_name!=null && data.fifth_week_issue_name!=undefined){
    this.issueName=data.fifth_week_issue_name
  }else if(data.third_week_issue_name !="" && data.third_week_issue_name!=null && data.third_week_issue_name!=undefined){
    this.issueName=data.third_week_issue_name
  }
  else if(data.second_week_issue_name !="" && data.second_week_issue_name!=null && data.second_week_issue_name!=undefined){
    this.issueName=data.second_week_issue_name
  }else if(data.first_week_issue_name !="" && data.first_week_issue_name!=null && data.first_week_issue_name!=undefined){
    this.issueName=data.first_week_issue_name
  }

  this.getadvtName(this.issueName)

  if(data.fifth_week_issue_date!="" && data.fifth_week_issue_date!=null && data.fifth_week_issue_date!=undefined){
    this.issueDate=data.fifth_week_issue_date
  }else if(data.fourth_week_issue_date !="" && data.fourth_week_issue_date!=null && data.fourth_week_issue_date!=undefined){
    this.issueDate=data.fourth_week_issue_date
  }else if(data.third_week_issue_date !="" && data.third_week_issue_date!=null && data.third_week_issue_date!=undefined){
    this.issueDate=data.third_week_issue_date
  }
  else if(data.second_week_issue_date !="" && data.second_week_issue_date!=null && data.second_week_issue_date!=undefined){
    this.issueDate=data.second_week_issue_date
  }else if(data.first_week_issue_date !="" && data.first_week_issue_date!=null && data.first_week_issue_date!=undefined){
    this.issueDate=data.first_week_issue_date
  }

  this.advtname = data.advt_name

  let date=data.invoice_date.split("-")
let  date1=date[2]+"-"+date[1]+"-"+date[0]
this.advtbillForm.patchValue({
  billno : data.invoice_no,
  date:date1,
  issuename : this.issueName,
  // issuedate :  this.issueDate,

 
})
}


getadvtName(e) {
  // console.log(e);
  // console.log('jgfdfgh', this.advtNames);
  this.service.getAdvtDatesAndNames(e).subscribe((data) => {
    this.getMagIssueDatesandAdvtNames = data;
    console.log(this.getMagIssueDatesandAdvtNames, "this.getMagIssueDatesandAdvtNames")
    this.filterissuedate = data;

    this.advtbillForm.patchValue({
      issuedate :  [this.issueDate],
     
    })
    this.getISN1()
  });
 
}


getISN1() {
  this.advtNamesIssueDates = [this.issueDate];
  this.advtNamesStringDates = this.advtNamesIssueDates.toString();

  // console.log('StringDates', this.advtNamesStringDates);
  console.log('IssueDates', this.advtNamesIssueDates);
  this.advtInvoiceNames = [];
  for (let index = 0; index < this.advtNamesIssueDates.length; index++) {
    this.service
      .getAdvtInvoiceforAdvtNames(this.advtNamesIssueDates[index])
      .subscribe((res: any) => {
        // console.log(`${index}`, res);
        if (res) {
          res?.map((a) => {
            this.advtInvoiceNames.push({ advt_name: a.name });
          });
        }
      });
    if (index == this.advtNamesIssueDates.length - 1) {
      console.log(this.advtInvoiceNames);

      //   this.advtInvoiceNames = this.uniqueid(
      //     this.advtInvoiceNames,
      //     (i) => i.advt_name
      //   );
    }
  }
  this.advtbillForm.patchValue({
    advt_name :  [this.advtname],
   
  })
  console.log(this.advtInvoiceNames);
this.getISN2()
  this.advtInvoiceNames = this.uniqueid(
    this.advtInvoiceNames,
    (i) => i.advt_name
  );
  console.log(this.advtInvoiceNames);
}

getISN2() {
  // console.log(e.value.toString());
  this.advtNames=this.issueName
  this.getData = [this.advtname];
  this.getData = this.getData.filter((a) => a != 'all');
  this.stringdata = this.getData.toString();
}


onTabClick(event:any){
  console.log(event)
  localStorage.setItem("tab", event.index)
}
deletestamp(item){
  let id = item.id

  this.service.stampreceiptdelete(id).subscribe((res)=>{

  })
  this.toaster.success('delete', 'Stamp Receipt Deleted');

  this.ngOnInit()

}
newordel(e){
  debugger;
  if(e.value == 'deleted'){
    this.deleterec = true;
    const data:any = {
      "value":'advt_pymt_rcpt',
    }
    this.service.getdeleterecipt(data).subscribe((data) => {
      // console.log('Database data', data);
      this.getdeleterecipt = data; 
    });
  }
  else{
    this.deleterec = false
  }
}
newordelinvoice(e){
  debugger;
  if(e.value == 'deleted'){
    this.deleterec = true;
    const data:any = {
      "value":'advt_invoice',
    }
    this.service.getdeleterecipt(data).subscribe((data) => {
      // console.log('Database data', data);
      this.getdeletereciptinvoice = data; 
    });
  }
  else{
    this.deleterec = false
  }
}
newordelstamp(e){
  debugger;
  if(e.value == 'deleted'){
    this.deleterec = true;
    const data:any = {
      "value":'advt_stamp_rcpt',
    }
    this.service.getdeleterecipt(data).subscribe((data) => {
      // console.log('Database data', data);
      this.getdeleterecitstamp = data; 
    });
  }
  else{
    this.deleterec = false
  }
}

newordelcn(e){
  debugger;
  if(e.value == 'deleted'){
    this.deleterec = true;
    const data:any = {
      "value":'credit_note',
    }
    this.service.getdeleterecipt(data).subscribe((data) => {
      // console.log('Database data', data);
      this.getdeleterecitcn = data; 
    });
  }
  else{
    this.deleterec = false
  }
}

}








function moment() {
  throw new Error('Function not implemented.');
}

function getCreditNoteMain(req1: { rep_name: any; }, any: any) {
  throw new Error('Function not implemented.');
}

function selectedModal() {
  throw new Error('Function not implemented.');
}


// function newordelcn(e: any) {
//   throw new Error('Function not implemented.');
// }
// function getAdvtissuename() {
//   throw new Error('Function not implemented.');
// }

