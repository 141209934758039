<body>
    <div>
        <div style="margin-left: 80%; margin-top: -4%; margin-bottom: 2%">
            <button [useExistingCss]="true" class="noPrint" style="margin-right: 2%; margin-left: 2%" mat-raised-button
                color="primary" printSectionId="print-section" ngxPrint>
                print
            </button>
            <!-- <button mat-raised-button class="btn btn-info noPrint" (click)="print()"
                style="margin-right: 2%; margin-left: 2%">
                Print
            </button> -->
            <button mat-dialog-close class="btn noPrint">
                Close
            </button>
        </div>
        <div class="container" id="print-section">
            <div style="margin: 0 15px;">
                <div class="row" style="font-weight: 700;">
                    <div class="col1" style="border-width: 1px 1px 1px 1px;font-weight: 700;">Receipt No</div>
                    <div class="col" style="border-width: 1px 1px 1px 0px;font-weight: 700;">Date</div>
                    <div class="col" style="border-width: 1px 1px 1px 0px;font-weight: 700;">Sub. No</div>
                    <div class="col1" style="border-width: 1px 1px 1px 0px;font-weight: 700;">Subscription Name</div>
                    <div class="col" style="border-width: 1px 1px 1px 0px;font-weight: 700;">Place</div>
                    <div class="col" style="border-width: 1px 1px 1px 0px;font-weight: 700;">Sub. Type</div>
                    <div class="col" style="border-width: 1px 1px 1px 0px;font-weight: 700;">Is Receipt</div>
                    <div class="col1" style="border-width: 1px 1px 1px 0px;font-weight: 700;">Period</div>
                    <div class="col1" style="border-width: 1px 1px 1px 0px;font-weight: 700;">Rep. Name</div>
                    <div class="col1" style="border-width: 1px 1px 1px 0px;font-weight: 700;">Off. Rep. Name</div>
                    <div class="col" style="border-width: 1px 1px 1px 0px;font-weight: 700;">Edition</div>
                    <div class="col" style="border-width: 1px 1px 1px 0px;font-weight: 700;">Total Amt.</div>
                    <div class="col" style="border-width: 1px 1px 1px 0px;font-weight: 700;">Discount</div>
                </div>
                <div class="row" *ngFor="let item of data">
                    <div class="col1" style="border-width: 0px 1px 1px 1px;">{{ item.rcpt_no }}</div>
                    <div class="col" style="border-width: 0px 1px 1px 0px;">{{ item.crdt_voucher_date | date:"dd-MM-yyyy" }}
                    </div>
                    <div class="col" style="border-width: 0px 1px 1px 0px;">{{ item.subno }}</div>
                    <div class="col1" style="border-width: 0px 1px 1px 0px;">{{ item.name }}</div>
                    <div class="col" style="border-width: 0px 1px 1px 0px;">{{ item.district }}</div>
                    <div class="col" style="border-width: 0px 1px 1px 0px;">{{ item.subscription_type }}</div>
                    <div class="col" style="border-width: 0px 1px 1px 0px;">{{ item.rcpt_type }}</div>
                    <div class="col1" style="border-width: 0px 1px 1px 0px;">{{ item.sub_perid_to_date |
                        date:"dd-MM-yyyy" }} - {{ item.sub_perid_from_date | date:"dd-MM-yyyy" }}</div>
                    <div class="col1" style="border-width: 0px 1px 1px 0px;">{{ item.rep_name }}</div>
                    <div class="col1" style="border-width: 0px 1px 1px 0px;">{{ item.office_rep_name }}</div>
                    <div class="col" style="border-width: 0px 1px 1px 0px;">{{ item.magzine_edition }}</div>
                    <div class="col" style="border-width: 0px 1px 1px 0px;">{{ item.total_amt }}</div>
                    <div class="col" style="border-width: 0px 1px 1px 0px;">{{ item.discount }}</div>
                </div>
                <div class="row">
                    <div class="col1" style="border-width: 0px 1px 1px 1px;font-weight: 700;text-align: center;">Total
                    </div>
                    <div class="col" style="border-width: 0px 0px 1px 0px;">
                    </div>
                    <div class="col" style="border-width: 0px 0px 1px 0px;"></div>
                    <div class="col1" style="border-width: 0px 0px 1px 0px;"></div>
                    <div class="col" style="border-width: 0px 0px 1px 0px;"></div>
                    <div class="col" style="border-width: 0px 0px 1px 0px;"></div>
                    <div class="col" style="border-width: 0px 0px 1px 0px;"></div>
                    <div class="col1" style="border-width: 0px 0px 1px 0px;"></div>
                    <div class="col1" style="border-width: 0px 0px 1px 0px;"></div>
                    <div class="col1" style="border-width: 0px 0px 1px 0px;"></div>
                    <div class="col" style="border-width: 0px 1px 1px 0px;"></div>
                    <div class="col" style="border-width: 0px 1px 1px 0px;">{{ total_amt }}</div>
                    <div class="col" style="border-width: 0px 1px 1px 0px;">{{ total_discount }}</div>
                </div>
            </div>
        </div>
    </div>
</body>