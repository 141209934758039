import { LoginComponent } from '../app/pages/login/login.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { AppComponent } from './app.component';
import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component';
import { AuthLayoutComponent } from './layouts/auth-layout/auth-layout.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AppRoutingModule } from './app.routing';
import { ComponentsModule } from './components/components.module';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatOptionModule } from '@angular/material/core';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDialogModule } from '@angular/material/dialog';
import { ModalComponent } from './pages/modal/modal.component';
import { AddNewSubOtpModalComponent } from './pages/add-new-sub-otp-modal/add-new-sub-otp-modal.component';
import { CancelSubDialogComponent } from './pages/cancel-sub-dialog/cancel-sub-dialog.component';
import { CancelRepDialogComponent } from './pages/cancel-rep-dialog/cancel-rep-dialog.component';
import { AddNewAdminDialogComponent } from './pages/add-new-admin-dialog/add-new-admin-dialog.component';
import { FileUploadModule } from 'ng2-file-upload';
import { EditBookDeliveryComponent } from './pages/edit-book-delivery/edit-book-delivery.component';
import { EditBookStockComponent } from './pages/edit-book-stock/edit-book-stock.component';
import { EditBookComponent } from './pages/edit-book/edit-book.component';
import { ViewBookComponent } from './pages/view-book/view-book.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule, MAT_DATE_LOCALE } from '@angular/material/core';
import { SubscriptioninvoiceComponent } from './pages/subscriptioninvoice/subscriptioninvoice.component';
import { UpdateAdvtDetailsComponent } from './pages/update-advt-details/update-advt-details.component';
import { UpdatePlublishDatesComponent } from './pages/update-plublish-dates/update-plublish-dates.component';
import { AdvertisementInvoiceComponent } from './pages/advertisement-invoice/advertisement-invoice.component';
import { CreditvoucherDialogComponent } from './pages/creditvoucher-dialog/creditvoucher-dialog.component';
import { ReciptComponent } from './pages/recipt/recipt.component';
import { BookstoresendmessageComponent } from './pages/bookstoresendmessage/bookstoresendmessage.component';
import { BookstorereceiptComponent } from './pages/bookstorereceipt/bookstorereceipt.component';
import { UpdateOfficeRepresentativeComponent } from './pages/update-office-representative/update-office-representative.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { UpdateSubtypeComponent } from './pages/update-subtype/update-subtype.component';
import { SubscribersreceiptComponent } from './pages/subscribersreceipt/subscribersreceipt.component';
import { AddBookPerPriceComponent } from './pages/add-book-per-price/add-book-per-price.component';
import { UpdateBookPerPriceComponent } from './pages/update-book-per-price/update-book-per-price.component';
import { BookCrdtnoteRcptComponent } from './pages/book-crdtnote-rcpt/book-crdtnote-rcpt.component';
import { ShowBookInvoiceComponent } from './pages/show-book-invoice/show-book-invoice.component';
import { ShowBookCrdtNoteRcptComponent } from './pages/show-book-crdt-note-rcpt/show-book-crdt-note-rcpt.component';
import { ShowBookSellerOutStandingReportComponent } from './pages/show-book-seller-out-standing-report/show-book-seller-out-standing-report.component';
import { AuthorAddressComponent } from './pages/author-address/author-address.component';
import { BookSellerAddressComponent } from './pages/book-seller-address/book-seller-address.component';
import { ShowBookSellerOutstandingDataComponent } from './pages/show-book-seller-outstanding-data/show-book-seller-outstanding-data.component';
import { DatewiseRepresentativewiseComponent } from './pages/datewise-representativewise/datewise-representativewise.component';
import { ReturnReceiptDialogComponent } from './pages/return-receipt-dialog/return-receipt-dialog.component';
import { NgxPrintModule } from 'ngx-print';
import { DatewiseComponent } from './pages/datewise/datewise.component';
import { MonthwiseComponent } from './pages/subscription/monthwise/monthwise.component';
import { OfficeRepresentativewiseComponent } from './pages/office-representativewise/office-representativewise.component';
import { DistrictwiseTalukawiseComponent } from './pages/districtwise-talukawise/districtwise-talukawise.component';
import { RemainingRenewableListOfAddressComponent } from './pages/remaining-renewable-list-of-address/remaining-renewable-list-of-address.component';
import { RepwiseAddressListComponent } from './pages/repwise-address-list/repwise-address-list.component';
import { SubscriptionRenewalRemainderletterComponent } from './pages/subscription-renewal-remainderletter/subscription-renewal-remainderletter.component';
import { RepresentativewiseAddressComponent } from './pages/representativewise-address/representativewise-address.component';
import { SubscribersAddressComponent } from './pages/subscribers-address/subscribers-address.component';
import { ShowDistributionComponent } from './pages/show-distribution/show-distribution.component';
import { MatIconModule } from '@angular/material/icon';
import { UpdateAdvtSizesComponent } from './pages/update-advt-sizes/update-advt-sizes.component';
import { AddressLabelsComponent } from './pages/address-labels/address-labels.component';
import { AddressLabelsMobileNoComponent } from './pages/address-labels-mobile-no/address-labels-mobile-no.component';
import { PincodewiseStatementComponent } from './pages/pincodewise-statement/pincodewise-statement.component';
import { DistrictwiseStatementComponent } from './pages/districtwise-statement/districtwise-statement.component';
import { HandDeliveryComponent } from './pages/hand-delivery/hand-delivery.component';
import { PostStatementComponent } from './pages/post-statement/post-statement.component';
import { PostStatementTapalComponent } from './pages/post-statement-tapal/post-statement-tapal.component';
import { PrintGSTReportComponent } from './pages/print-gstreport/print-gstreport.component';
import { ShowSubscriptionNodaniReceiptComponent } from './pages/show-subscription-nodani-receipt/show-subscription-nodani-receipt.component';
import { LabelsOfHandDeliveryComponent } from './pages/labels-of-hand-delivery/labels-of-hand-delivery.component';
import { PrintOrderStatementComponent } from './pages/subscription/print-order-statement/print-order-statement.component';
import { PrintJavakComponent } from './pages/subscription/print-javak/print-javak.component';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { UpdateStateMasterComponent } from './pages/update-state-master/update-state-master.component';
import { UpdateDistrictMasterComponent } from './pages/update-district-master/update-district-master.component';
import { UpdateTalukaMasterComponent } from './pages/update-taluka-master/update-taluka-master.component';
import { RegistrationReportComponent } from './pages/registration-report/registration-report.component';
import { PrasiddhipatrakComponent } from './pages/prasiddhipatrak/prasiddhipatrak.component';
import { PrasiddhipatrakbrowseComponent } from './pages/prasiddhipatrakbrowse/prasiddhipatrakbrowse.component';
import { HanddeliverylabelslistComponent } from './pages/handdeliverylabelslist/handdeliverylabelslist.component';
import { CreditDebitLedgerReportComponent } from './pages/credit-debit-ledger-report/credit-debit-ledger-report.component';
import { HandDeliveryIndetailReportComponent } from './pages/hand-delivery-indetail-report/hand-delivery-indetail-report.component';
import { OtherStatePincodewiseStatementComponent } from './pages/other-state-pincodewise-statement/other-state-pincodewise-statement.component';
import { ShowSubscriberReceiptLedgerPrintComponent } from './pages/show-subscriber-receipt-ledger-print/show-subscriber-receipt-ledger-print.component';
import { ContractBriefReportComponent } from './pages/contract-brief-report/contract-brief-report.component';
import { AdvtBillComponent } from './pages/advt-bill/advt-bill.component';
import { ViewAdvtBillComponent } from './pages/view-advt-bill/view-advt-bill.component';
import { AdvtInvoiceRegisterComponent } from './pages/advt-invoice-register/advt-invoice-register.component';
import { AddAdvtIssuePerPriceComponent } from './pages/add-advt-issue-per-price/add-advt-issue-per-price.component';
import { UpdateAdvtIssuePerPriceComponent } from './pages/update-advt-issue-per-price/update-advt-issue-per-price.component';
import { AdvtInvoicePaymentReceiptComponent } from './pages/advt-invoice-payment-receipt/advt-invoice-payment-receipt.component';
import { ViewPrintOrderComponent } from './pages/view-print-order/view-print-order.component';
import { UpdatePrintOrderComponent } from './pages/update-print-order/update-print-order.component';
import { DistrictwiseSubscriptionReportComponent } from './pages/subscription/districtwise-subscription-report/districtwise-subscription-report.component';
import { DistrictwiseVarganidarSubscriptionReportComponent } from './pages/subscription/districtwise-varganidar-subscription-report/districtwise-varganidar-subscription-report.component';
import { PrantwiseVarganidarReportComponent } from './pages/subscription/prantwise-varganidar-report/prantwise-varganidar-report.component';
import { UpdatePrantComponent } from './pages/admin/update-prant/update-prant.component';
import { MatSelectModule } from '@angular/material/select';
import { DatewiseVarganidarRojmelReportComponent } from './pages/subscription/datewise-varganidar-rojmel-report/datewise-varganidar-rojmel-report.component';
import { RenewRemainingDataComponent } from './pages/subscription/renew-remaining-data/renew-remaining-data.component';
import { DatePipe } from '@angular/common';
import { OpenselectmodalComponent } from './openselectmodal/openselectmodal.component';
import { CommmodalComponent } from './commmodal/commmodal.component';
import { CrreceiptComponent } from './crreceipt/crreceipt.component';
import { AddmodalComponent } from './addmodal/addmodal.component';
import { AlladdComponent } from './alladd/alladd.component';
import { MatChipsModule } from '@angular/material/chips';
import { TworeceiptComponent } from './tworeceipt/tworeceipt.component';
import { ToastrModule } from 'ngx-toastr';
import { RepbusinessreportComponent } from './repbusinessreport/repbusinessreport.component';
import { PrintchallanComponent } from './printchallan/printchallan.component';
import { OlddataupdateComponent } from './olddataupdate/olddataupdate.component';
import { ExpenseledgerComponent } from './expenseledger/expenseledger.component';
import { AgGridAngular } from 'ag-grid-angular';
import { RepledgerComponent } from './repledger/repledger.component';
import { CreditorsreportComponent } from './creditorsreport/creditorsreport.component';
import { StampreceiptComponent } from './stampreceipt/stampreceipt.component';
import { UpdatejournalvoucherComponent } from './updatejournalvoucher/updatejournalvoucher.component';
import { SubscriberdetailsbriefComponent } from './subscriberdetailsbrief/subscriberdetailsbrief.component';
import { StatewisestatementComponent } from './statewisestatement/statewisestatement.component';
import { ActiveinactivebusinessreportComponent } from './activeinactivebusinessreport/activeinactivebusinessreport.component';
import { StatewisebusinessreportComponent } from './statewisebusinessreport/statewisebusinessreport.component';
import { CountrywisebusinessreportComponent } from './countrywisebusinessreport/countrywisebusinessreport.component';
import { DistrictwisebusinessComponent } from './districtwisebusiness/districtwisebusiness.component';
import { PrantwisebusinessComponent } from './prantwisebusiness/prantwisebusiness.component';
import { TypewisebusinessreportComponent } from './typewisebusinessreport/typewisebusinessreport.component';
import { AlladdlistComponent } from './alladdlist/alladdlist.component';
import { JournaledgerlvoucherComponent } from './journaledgerlvoucher/journaledgerlvoucher.component';
import { ThreeStampReceiptComponent } from './pages/three-stamp-receipt/three-stamp-receipt.component';
import { AdminLayoutModule } from './layouts/admin-layout/admin-layout.module';
import { AuthLayoutModule } from './layouts/auth-layout/auth-layout.module';
// import { Ng2SearchPipeModule } from 'ng2-search-filter';


// import { ReportInDetailComponent } from './report-in-detail/report-in-detail.component';
// import { FileSaverModule } from 'ngx-filesaver';

@NgModule({
  imports: [
    BrowserAnimationsModule,
    FormsModule,
    HttpClientModule,
    ComponentsModule,
    NgbModule,
    RouterModule,
    AppRoutingModule,
    ReactiveFormsModule,
    MatInputModule,
    MatButtonModule,
    MatCardModule,
    MatFormFieldModule,
    MatDialogModule,
    FileUploadModule,
    MatAutocompleteModule,
    NgxPrintModule,
    ScrollingModule,
    MatOptionModule,
    MatSelectModule,
    MatChipsModule,
    AdminLayoutModule,
    AuthLayoutModule,
    // FileSaverModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatIconModule,
    AgGridAngular,
    // Ng2SearchPipeModule,
    ToastrModule.forRoot({
      closeButton: true,
      timeOut: 15000, // 15 seconds
      progressBar: true,
    }),
  ],
  declarations: [
    AppComponent,
    AdminLayoutComponent,
    AuthLayoutComponent,
    LoginComponent,
    ModalComponent,
    AddNewSubOtpModalComponent,
    CancelSubDialogComponent,
    CancelRepDialogComponent,
    AddNewAdminDialogComponent,
    EditBookDeliveryComponent,
    EditBookStockComponent,
    EditBookComponent,
    ViewBookComponent,
    AdvertisementInvoiceComponent,
    CreditvoucherDialogComponent,
    ReciptComponent,
    BookstoresendmessageComponent,
    BookstorereceiptComponent,
    UpdateOfficeRepresentativeComponent,
    UpdateSubtypeComponent,
    SubscribersreceiptComponent,
    AddBookPerPriceComponent,
    UpdateBookPerPriceComponent,
    BookCrdtnoteRcptComponent,
    ShowBookInvoiceComponent,
    ShowBookCrdtNoteRcptComponent,
    ShowBookSellerOutStandingReportComponent,
    AuthorAddressComponent,
    BookSellerAddressComponent,
    ShowBookSellerOutstandingDataComponent,
    DatewiseRepresentativewiseComponent,
    ReturnReceiptDialogComponent,
    DatewiseComponent,
    MonthwiseComponent,
    OfficeRepresentativewiseComponent,
    DistrictwiseTalukawiseComponent,
    RemainingRenewableListOfAddressComponent,
    RepwiseAddressListComponent,
    SubscriptionRenewalRemainderletterComponent,
    RepresentativewiseAddressComponent,
    SubscribersAddressComponent,
    ShowDistributionComponent,
    AddressLabelsComponent,
    AddressLabelsMobileNoComponent,
    PincodewiseStatementComponent,
    DistrictwiseStatementComponent,
    HandDeliveryComponent,
    PostStatementComponent,
    PostStatementTapalComponent,
    PrintGSTReportComponent,
    ShowSubscriptionNodaniReceiptComponent,
    LabelsOfHandDeliveryComponent,
    PrintOrderStatementComponent,
    PrintJavakComponent,
    UpdateStateMasterComponent,
    UpdateDistrictMasterComponent,
    UpdateTalukaMasterComponent,
    RegistrationReportComponent,
    PrasiddhipatrakComponent,
    PrasiddhipatrakbrowseComponent,
    HanddeliverylabelslistComponent,
    CreditDebitLedgerReportComponent,
    HandDeliveryIndetailReportComponent,
    OtherStatePincodewiseStatementComponent,
    ShowSubscriberReceiptLedgerPrintComponent,
    ContractBriefReportComponent,
    AdvtBillComponent,
    ViewAdvtBillComponent,
    AdvtInvoiceRegisterComponent,
    AddAdvtIssuePerPriceComponent,
    UpdateAdvtIssuePerPriceComponent,
    AdvtInvoicePaymentReceiptComponent,
    ViewPrintOrderComponent,
    UpdatePrintOrderComponent,
    DistrictwiseSubscriptionReportComponent,
    DistrictwiseVarganidarSubscriptionReportComponent,
    PrantwiseVarganidarReportComponent,
    UpdatePrantComponent,
    DatewiseVarganidarRojmelReportComponent,
    RenewRemainingDataComponent,
    OpenselectmodalComponent,
    CommmodalComponent,
    CrreceiptComponent,
    AddmodalComponent,
    AlladdComponent,
    RepbusinessreportComponent,
    TworeceiptComponent,
    PrintchallanComponent,
    OlddataupdateComponent,
    // ReportInDetailComponent,
    ExpenseledgerComponent,
    RepledgerComponent,
    CreditorsreportComponent,
    StampreceiptComponent,
    UpdatejournalvoucherComponent,
    SubscriberdetailsbriefComponent,
    StatewisestatementComponent,
    ActiveinactivebusinessreportComponent,
    StatewisebusinessreportComponent,
    CountrywisebusinessreportComponent,
    DistrictwisebusinessComponent,
    PrantwisebusinessComponent,
    TypewisebusinessreportComponent,
    AlladdlistComponent,
    JournaledgerlvoucherComponent,
    ThreeStampReceiptComponent,
  ],
  providers: [
    MatNativeDateModule,
    MatDatepickerModule,
    DatePipe,
    { provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA,NO_ERRORS_SCHEMA],
})
export class AppModule {}
