<div class="header bg-gradient-danger pb-1 pt-5 pt-md-8">
  <div class="container-fluid">
    <div class="header-body"></div>
  </div>
</div>

<div class="container" style="margin-top: -4.1em">
  <!-- Icons is Used as Admin -->
  <mat-tab-group dynamicHeight [(selectedIndex)]="demo1TabIndex"  (selectedTabChange)="onTabClick($event)">
    <mat-tab label="Issue Seller Entry">
      <mat-card class="card">
        <form [formGroup]="issuesellerentryForm" (ngSubmit)="save_issuesellerentry()">
          <div class="form-row">
            <mat-form-field style="margin-left: 7%; margin-top: 10px; width: 40%" class="example-full-width"
              appearance="outline">
              <mat-label>Issue Seller Name :</mat-label>
              <input matInput formControlName="issuesellername" [matAutocomplete]="auto" required />
              <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
                <mat-option (onSelectionChange)="selected_name(option)" *ngFor="let option of filteredOptions | async"
                  [value]="option['name']">
                  {{ option["name"] }}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
            <mat-form-field style="margin-left: 5%; margin-top: 10px; width: 40%" class="example-full-width"
              appearance="outline">
              <mat-label>Mobile No :</mat-label>
              <input matInput formControlName="mnumber" [matAutocomplete]="automno" required />
              <mat-autocomplete autoActiveFirstOption #automno="matAutocomplete">
                <mat-option (onSelectionChange)="selected_name(option)" *ngFor="let option of filteredOptions1 | async"
                  [value]="option['mob_no']">
                  {{ option["mob_no"] }}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
          </div>
          <div class="form-row">
            <mat-form-field class="example-full-width" style="width: 60%; margin-left: 7%; margin-top: 10px"
              appearance="outline">
              <mat-label>Address :</mat-label>
              <textarea matInput placeholder="Address" formControlName="address1"></textarea>
            </mat-form-field>
          </div>
          <div class="form-row">
            <mat-form-field style="margin-left: 7%; margin-top: 10px; width: 24%" class="example-full-width"
              appearance="outline">
              <mat-label>Pincode</mat-label>
              <input (change)="getPincode($event)" matInput #postalCode maxlength="6" placeholder=""
                formControlName="pincode" required />
              <mat-hint align="end">{{ postalCode.value.length }} / 6</mat-hint>
            </mat-form-field>

            <mat-form-field style="width: 27%; margin-left: 15px; margin-top: 10px" appearance="outline">
              <mat-label>Country</mat-label>
              <mat-select (selectionChange)="getCountry($event)" formControlName="country" required>
                <mat-option *ngFor="let c of countries" value="{{ c['name'] }}">{{ c["name"] }}</mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field style="width: 28%; margin-left: 15px; margin-top: 10px" appearance="outline">
              <mat-label>State</mat-label>
              <mat-select (selectionChange)="getState($event)" formControlName="state" required>
                <mat-option *ngFor="let c of states" value="{{ c['state_name'] }}">{{ c["state_name"] }}
                </mat-option>
              </mat-select>
              <span>{{ selectedState }}</span>
            </mat-form-field>
          </div>

          <div class="form-row">
            <mat-form-field style="width: 40%; margin-left: 7%; margin-top: 10px" appearance="outline">
              <mat-label>District</mat-label>
              <mat-select formControlName="district" required>
                <mat-option *ngFor="let c of cities" value="{{ c['name'] }}">
                  {{ c["name"] }}
                </mat-option>
              </mat-select>
              <span>{{ selectedCity }}</span>
            </mat-form-field>

            <mat-form-field class="example-full-width" style="width: 40%; margin-left: 20px; margin-top: 10px"
              appearance="outline">
              <mat-label>Taluka</mat-label>
              <input matInput formControlName="taluka" required />
            </mat-form-field>
          </div>
          <div class="form-row">
            <mat-form-field style="margin-left: 7%; margin-top: 10px; width: 40%"
              [style.color]="validmobile ? null : 'red'" class="example-full-width">
              <mat-label>Mobile</mat-label>
              <span matPrefix style="color: gray">+91 &nbsp;</span>
              <input type="tel" #mobiles (change)="validateMobile($event)" maxlength="10" formControlName="mnumber"
                matInput />
              <span matSuffix style="color: gray"><mat-icon> call</mat-icon></span>
              <mat-hint align="end">{{ mobiles.value.length }} / 10</mat-hint>
              <mat-error *ngIf="validmobile">
                Please enter a valid mobile no.
              </mat-error>
              <mat-error *ngIf="validmobile">
                Mobile no. is <strong>required</strong>
              </mat-error>
            </mat-form-field>
            <mat-form-field class="example-full-width" style="width: 40%; margin-left: 20px; margin-top: 10px"
              [style.color]="validemail ? null : 'red'" appearance="outline">
              <mat-label>Email</mat-label>
              <input (change)="validateEmail($event)" type="email" matInput formControlName="email"
                placeholder="Ex. pat@example.com" />
              <span matSuffix style="color: gray"><mat-icon> email</mat-icon></span>
              <mat-error *ngIf="validemail">
                Please enter a valid email address
              </mat-error>
              <mat-error *ngIf="validemail">
                Email is <strong>required</strong>
              </mat-error>
            </mat-form-field>
          </div>
          <div class="form-row">
            <mat-form-field class="example-full-width" style="margin-left: 7%; margin-top: 10px; width: 40%"
              appearance="outline">
              <mat-label>Landline No.</mat-label>
              <input type="text" #land maxlength="12" formControlName="landline_no" matInput />
              <mat-hint align="end">{{ land.value.length }} / 12</mat-hint>
            </mat-form-field>

            <!-- <label style="margin-left: 10px; margin-top: 10px"
              >Date of Birth :
            </label> -->
            <mat-form-field style="margin-left: 20px; margin-top: 10px; width: 40%" appearance="outline">
              <mat-label>Date of Birth</mat-label>
              <input formControlName="dob" matInput [matDatepicker]="datepicke" (dateChange)="validatedob($event)" />
              <mat-datepicker-toggle matSuffix [for]="datepicke"></mat-datepicker-toggle>
              <mat-datepicker #datepicke> </mat-datepicker>
              <mat-error *ngIf="validdob"> Please enter a valid DOB </mat-error>
              <mat-error *ngIf="validdob">
                DOB is <strong>required</strong>
              </mat-error>
            </mat-form-field>
          </div>
          <div class="form-row">
            <mat-form-field style="margin-left: 7%; width: 40%; margin-top: 10px" class="example-full-width"
              appearance="outline">
              <mat-label>Gst No.</mat-label>
              <input matInput maxlength="15" #gst type="text" formControlName="gstno" />
              <mat-hint align="end">{{ gst.value.length }} / 15</mat-hint>
            </mat-form-field>
            <mat-form-field style="margin-left: 20px; width: 40%; margin-top: 10px" class="example-full-width"
              appearance="outline">
              <mat-label>PAN No.</mat-label>
              <input matInput maxlength="10" #pan type="text" formControlName="panno" />
              <mat-hint align="end">{{ pan.value.length }} / 10</mat-hint>
            </mat-form-field>
          </div>
          <div class="form-row">
            <mat-form-field style="margin-left: 7%; margin-top: 10px; width: 40%" class="example-full-width"
              appearance="outline">
              <mat-label>Deposit Amount &#8377;:</mat-label>
              <input type="number" formControlName="depositamt" matInput required />
            </mat-form-field>
          </div>
          <!-- <div class="form-row">
            <mat-radio-group
              aria-label="Select an option"
              style="margin-left: 7%"
              formControlName="option"
              (change)="select($event)"
            >
              <mat-radio-button value="agent">Agent</mat-radio-button>
              <mat-radio-button value="representative" style="margin-left: 20px"
                >Representative</mat-radio-button
              >
            </mat-radio-group>
          </div> -->
          <div *ngIf="option">
            <div class="form-row">
              <mat-form-field style="margin-left: 7%; margin-top: 10px; width: 25%" appearance="outline">
                <mat-label>Issue/grantha : </mat-label>
                <mat-select (selectionChange)="getIssues($event)" required>
                  <mat-option *ngFor="let item of advtCategorylist" value="{{ item.category }}">
                    {{ item.category }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              <mat-form-field style="margin-left: 20px; margin-top: 10px; width: 25%" appearance="outline">
                <mat-label>Issue Dates : </mat-label>
                <mat-select #mySel (selectionChange)="getAdvtDates($event)" multiple required>
                  <mat-option value="all" (click)="toggleAllSelection()">All</mat-option>
                  <mat-option *ngFor="let item of getMagIssueDatesandNames"
                    value="{{ [item['issueDate'], item['issueName']] }}">{{ item["issueDate"] | date: "dd-MM-yyy" }}
                    {{ item["issueName"] }}
                  </mat-option>
                </mat-select>
              </mat-form-field>

              <mat-form-field appearance="outline" style="margin-left: 20px; margin-top: 10px; width: 25%">
                <mat-label>Booking Date</mat-label>
                <input matInput [matDatepicker]="bdpicker" formControlName="bookingdate" />
                <mat-datepicker-toggle matSuffix [for]="bdpicker"></mat-datepicker-toggle>
                <mat-datepicker #bdpicker></mat-datepicker>
              </mat-form-field>

              <button type="button" style="height: 1%; margin-left: 20px; margin-top: 20px" mat-raised-button
                color="warn" (click)="addAdvt()">
                Add
              </button>
            </div>

            <div class="form-row">
              <table class="table table-striped text-center"
                style="margin-left: 7%; margin-top: 10px; margin-right: 30px">
                <thead>
                  <tr>
                    <th>Sr.No.</th>
                    <th>Issue Name</th>
                    <th>Issue Dates</th>
                    <th>Booking Date</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let sub of AdvtDetails; let i = index">
                    <td>{{ i + 1 }}</td>
                    <td>{{ sub["issue"] }}</td>
                    <td>{{ sub["advtdates"] }}</td>
                    <td>{{ sub["bookingdate"] | date: "dd-MM-yyy" }}</td>
                    <td>
                      <button type="button" class="btn btn-danger btn-sm" (click)="deleteAdvt(i)">
                        Remove
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="form-row">
              <mat-form-field style="margin-left: 7%; width: 60%; margin-top: 10px" class="example-full-width"
                appearance="outline">
                <mat-label>Issue Rate</mat-label>
                <input matInput type="number" formControlName="issuerate" />
              </mat-form-field>
            </div>
            <div class="form-row">
              <mat-form-field style="margin-left: 7%; width: 60%; margin-top: 10px" class="example-full-width"
                appearance="outline">
                <mat-label>Issue copies</mat-label>
                <input matInput type="number" formControlName="issuecopies" />
              </mat-form-field>
            </div>
            <div class="form-row">
              <mat-form-field style="margin-left: 7%; width: 60%; margin-top: 10px" class="example-full-width"
                appearance="outline">
                <mat-label>Gross Amount &#8377;:</mat-label>
                <input matInput type="number" formControlName="grossamt" />
              </mat-form-field>
            </div>
            <div class="form-row">
              <mat-form-field style="margin-left: 7%; width: 40%; margin-top: 10px" class="example-full-width"
                appearance="outline">
                <mat-label>Discount &#37;:</mat-label>
                <input matInput type="number" formControlName="discount" />
              </mat-form-field>
              <mat-form-field style="margin-left: 20px; width: 40%; margin-top: 10px" class="example-full-width"
                appearance="outline">
                <mat-label>Amount &#8377;:</mat-label>
                <input matInput type="number" formControlName="amount" />
              </mat-form-field>
            </div>
            <div class="form-row">
              <mat-form-field style="margin-left: 7%; width: 60%; margin-top: 10px" class="example-full-width"
                appearance="outline">
                <mat-label>Net Amount &#8377;:</mat-label>
                <input matInput type="number" formControlName="netamount" />
              </mat-form-field>
            </div>
            <div class="form-row">
              <mat-form-field style="margin-left: 7%; width: 60%; margin-top: 10px" class="example-full-width"
                appearance="outline">
                <mat-label>Complementary Copies :</mat-label>
                <input matInput type="text" formControlName="complementrycopies" />
              </mat-form-field>
            </div>
            <div class="form-row">
              <mat-form-field style="margin-left: 7%; width: 60%; margin-top: 10px" class="example-full-width"
                appearance="outline">
                <mat-label>Subscription Copies :</mat-label>
                <input matInput type="text" formControlName="subscriptioncopies" />
              </mat-form-field>
            </div>
            <div class="form-row">
              <mat-form-field style="margin-left: 7%; width: 40%; margin-top: 10px" class="example-full-width"
                appearance="outline">
                <mat-label>Distribution Type</mat-label>
                <mat-select formControlName="distributedtype">
                  <mat-option value="railway">Railway</mat-option>
                  <!-- <mat-option value="other">Other</mat-option> -->
                </mat-select>
              </mat-form-field>
              <mat-form-field style="margin-left: 20px; width: 40%; margin-top: 10px" class="example-full-width"
                appearance="outline">
                <mat-label>Sub Type</mat-label>
                <mat-select formControlName="subtype">
                  <mat-option value="central">Central</mat-option>
                  <mat-option value="western">Western</mat-option>
                  <mat-option value="harber">Harber</mat-option>
                  <mat-option value="dadar">Dadar</mat-option>
                  <mat-option value="cstm">CSTM</mat-option>
                  <mat-option value="mumbaicentral">Mumbai Central</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="form-row">
              <mat-form-field style="margin-left: 7%; width: 60%; margin-top: 10px" class="example-full-width"
                appearance="outline">
                <mat-label>Details :</mat-label>
                <input matInput type="text" formControlName="details" />
              </mat-form-field>
            </div>
          </div>
          <div class="form-group">
            <button style="margin-left: 125px; width: 70%; margin-top: 10px" type="submit"
              [disabled]="issuesellerentryForm.invalid" mat-raised-button color="primary">
              Add Issue Seller Entry
            </button>
          </div>
        </form>
      </mat-card>
    </mat-tab>

    <mat-tab label="Issue Sale Challan">
      <mat-card class="card">
        <form [formGroup]="publicationsaleForm" (ngSubmit)="save_publicationsale()">
          <div class="form-row">
            <mat-form-field style="margin-left: 7%; margin-top: 10px; width: 40%" class="example-full-width"
              appearance="outline">
              <mat-label>Challan Date</mat-label>
              <input matInput [matDatepicker]="picker6" formControlName="cdate" />
              <mat-datepicker-toggle matSuffix [for]="picker6"></mat-datepicker-toggle>
              <mat-datepicker #picker6></mat-datepicker>
            </mat-form-field>
            <mat-form-field style="margin-left: 20px; margin-top: 10px; width: 40%" class="example-full-width"
              appearance="outline">
              <mat-label>Challan No. :</mat-label>
              <input type="text" formControlName="challanno" matInput required />
            </mat-form-field>
          </div>
          <div class="form-row">
            <mat-form-field style="margin-left: 7%; margin-top: 10px; width: 40%" appearance="outline">
              <mat-label>Issue/grantha : </mat-label>
              <mat-select (selectionChange)="getIssues($event)" required>
                <mat-option *ngFor="let item of advtCategorylist" value="{{ item.category }}">
                  {{ item.category }}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field style="margin-left: 20px; margin-top: 10px; width: 40%" appearance="outline">
              <mat-label>Issue Dates : </mat-label>
              <mat-select #mySel (selectionChange)="getAdvtDates($event)" multiple required>
                <mat-option value="all" (click)="toggleAllSelection()">All</mat-option>
                <mat-option *ngFor="let item of getMagIssueDatesandNames"
                  value="{{ [item['issueDate'], item['issueName']] }}">{{ item["issueDate"] | date: "dd-MM-yyy" }}
                  {{ item["issueName"] }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="form-row">
            <!-- <mat-form-field
              style="margin-left: 7%; margin-top: 10px; width: 40%"
              appearance="outline"
            >
              <mat-label>Issue Seller Name : </mat-label>
              <mat-select (selectionChange)="getIssues($event)" required>
                <mat-option
                  *ngFor="let item of advtCategorylist"
                  value="{{ item.category }}"
                >
                  {{ item.category }}
                </mat-option>
              </mat-select>
            </mat-form-field> -->
            <mat-form-field style="margin-left: 7%; margin-top: 10px; width: 60%" appearance="outline">
              <mat-label>Issue Seller Name : </mat-label>
              <mat-select #isn (selectionChange)="getISN($event)" multiple required>
                <mat-option value="all" (click)="toggleAllSelectionISN()">All</mat-option>
                <mat-option *ngFor="let item of issueSellerName" value="{{ item['issueSellerName'] }}">
                  {{ item["issueSellerName"] }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="form-row">
            <mat-form-field style="margin-left: 7%; margin-top: 10px; width: 60%" class="example-full-width"
              appearance="outline">
              <mat-label>Sales Copies :</mat-label>
              <input type="text" formControlName="psalescopy" matInput />
            </mat-form-field>
          </div>
          <div class="form-row">
            <mat-form-field style="margin-left: 7%; margin-top: 10px; width: 60%" class="example-full-width"
              appearance="outline">
              <mat-label>Complementary Copies :</mat-label>
              <input type="text" formControlName="complementarycopies" matInput />
            </mat-form-field>
          </div>
          <div class="form-row">
            <mat-form-field style="margin-left: 7%; margin-top: 10px; width: 60%" class="example-full-width"
              appearance="outline">
              <mat-label>Subscription Copies :</mat-label>
              <input type="text" formControlName="subscriptioncopies" matInput />
            </mat-form-field>
          </div>
          <div class="form-row">
            <mat-form-field style="margin-left: 7%; margin-top: 10px; width: 40%" class="example-full-width"
              appearance="outline">
              <mat-label>Issue Rate &#8377;:</mat-label>
              <input type="number" formControlName="issuerate" matInput />
            </mat-form-field>
            <mat-form-field style="margin-left: 20px; margin-top: 10px; width: 40%" class="example-full-width"
              appearance="outline">
              <mat-label>Discount &#8377;:</mat-label>
              <input type="number" formControlName="discountamt" matInput />
            </mat-form-field>
          </div>
          <div class="form-row">
            <mat-form-field style="margin-left: 7%; margin-top: 10px; width: 40%" class="example-full-width"
              appearance="outline">
              <mat-label>Sales Amount &#8377;:</mat-label>
              <input type="number" formControlName="saleamt" matInput />
            </mat-form-field>
            <mat-form-field style="margin-left: 20px; margin-top: 10px; width: 40%" class="example-full-width"
              appearance="outline">
              <mat-label>Sales Discount &#8377;:</mat-label>
              <input type="number" formControlName="salesdiscount" matInput />
            </mat-form-field>
          </div>
          <div class="form-row">
            <mat-form-field style="margin-left: 7%; margin-top: 10px; width: 60%" class="example-full-width"
              appearance="outline">
              <mat-label>Net Amount &#8377;:</mat-label>
              <input type="number" formControlName="netamt" matInput />
            </mat-form-field>
          </div>

          <div class="form-row">
            <mat-form-field style="margin-left: 7%; width: 40%; margin-top: 10px" class="example-full-width"
              appearance="outline">
              <mat-label>Distribution Type</mat-label>
              <mat-select formControlName="distributiontype">
                <mat-option value="railway">Railway</mat-option>
                <!-- <mat-option value="other">Other</mat-option> -->
              </mat-select>
            </mat-form-field>
            <mat-form-field style="margin-left: 20px; width: 40%; margin-top: 10px" class="example-full-width"
              appearance="outline">
              <mat-label>Sub Type</mat-label>
              <mat-select formControlName="subtype">
                <mat-option value="central">Central</mat-option>
                <mat-option value="western">Western</mat-option>
                <mat-option value="harber">Harber</mat-option>
                <mat-option value="dadar">Dadar</mat-option>
                <mat-option value="cstm">CSTM</mat-option>
                <mat-option value="mumbaicentral">Mumbai Central</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="form-row">
            <mat-form-field style="margin-left: 7%; margin-top: 10px; width: 60%" class="example-full-width"
              appearance="outline">
              <mat-label>Details :</mat-label>
              <input type="text" formControlName="details" matInput />
            </mat-form-field>
          </div>
          <div class="form-group">
            <button style="margin-left: 125px; width: 70%; margin-top: 10px" type="submit"
              [disabled]="publicationsaleForm.invalid" mat-raised-button color="primary">
              Add Issue Sele Challan
            </button>
          </div>
        </form>
      </mat-card>
    </mat-tab>

    <mat-tab label="Issue/Granth Sale Bill">
      <mat-card class="card">
        <form [formGroup]="publicationpaymententriesForm" (ngSubmit)="save_publicationpaymententries()">
          <div class="form-row">
            <mat-form-field style="margin-left: 7%; margin-top: 10px; width: 40%" appearance="outline">
              <!-- (selectionChange)="getIssues($event)" -->
              <mat-label>Issue/granth : </mat-label>
              <mat-select (selectionChange)="getIssues($event)" required>
                <mat-option *ngFor="let item of advtCategorylist" value="{{ item.category }}">
                  {{ item.category }}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field style="margin-left: 20px; margin-top: 10px; width: 40%" appearance="outline">
              <mat-label>Issue Dates : </mat-label>
              <mat-select #mySel1 (selectionChange)="getAdvtDates($event)" multiple required>
                <mat-option value="all" (click)="toggleAllSelection1()">All</mat-option>
                <mat-option *ngFor="let item of getMagIssueDatesandNames"
                  value="{{ [item['issueDate'], item['issueName']] }}">{{ item["issueDate"] | date: "dd-MM-yyy" }}
                  {{ item["issueName"] }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="form-row">
            <mat-form-field style="margin-top: 10px; margin-left: 7%; width: 60%" class="example-full-width"
              appearance="outline">
              <mat-label>Publication Month : </mat-label>
              <input matInput type="month" formControlName="monthdate" required />
            </mat-form-field>
          </div>
          <div class="form-row">
            <mat-form-field style="margin-left: 7%; margin-top: 10px; width: 60%" appearance="outline">
              <mat-label>Issue Seller Name : </mat-label>
              <mat-select #isn (selectionChange)="getISN($event)" multiple required>
                <mat-option value="all" (click)="toggleAllSelectionISN()">All</mat-option>
                <mat-option *ngFor="let item of issueSellerName" value="{{ item['issueSellerName'] }}">
                  {{ item["issueSellerName"] }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="form-row">
            <mat-form-field style="margin-left: 7%; margin-top: 10px; width: 40%" class="example-full-width"
              appearance="outline">
              <mat-label>Bill No :</mat-label>
              <input type="text" formControlName="billno" matInput required readonly />
            </mat-form-field>
            <mat-form-field style="margin-left: 20px; margin-top: 10px; width: 40%" class="example-full-width"
              appearance="outline">
              <mat-label>Date</mat-label>
              <input matInput [matDatepicker]="picker9" formControlName="date" required />
              <mat-datepicker-toggle matSuffix [for]="picker9"></mat-datepicker-toggle>
              <mat-datepicker #picker9></mat-datepicker>
            </mat-form-field>
          </div>
          <div class="form-group">
            <button style="margin-left: 125px; width: 70%; margin-top: 10px" type="submit"
              [disabled]="publicationpaymententriesForm.invalid" mat-raised-button color="primary">
              Add Issue/Granth Sale Bill
            </button>
          </div>
        </form>
      </mat-card>
    </mat-tab>

    <mat-tab label="Pending Credit Voucher">
      <div class="add-new-subscriber mat-elevation-z4">
        <div style="margin-top: 20px" class="tableFixHead">
          <table class="table table-striped text-center">
            <thead>
              <tr>
                <th>Sr.No.</th>
                <th>Voucher No.</th>
                <th>Voucher Date</th>
                <th>Rep.Name</th>
                <th>Advt.Name</th>
                <th>Amount</th>
                <th>Cheque no</th>
                <th>Cheque date</th>
                <th>Branch Name</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let book of this.getallVouchersData; let i = index">
                <td>{{ i + 1 }}</td>
                <td>{{ book["voucher_no"] }}</td>
                <td>{{ book["voucher_date"] }}</td>
                <td>{{ book["rep_name"] }}</td>
                <td>{{ book["advtName"] }}</td>
                <td>₹ {{ book["price"] }}</td>
                <td>{{ book["cheque_no"] }}</td>
                <td>{{ book["chequeDate"] | date: "dd-MM-yyy" }}</td>
                <td>{{ book["branch_name"] }}</td>
                <td>
                  <button type="button" class="btn btn-danger btn-sm"
                    (click)="displayItemId(book['id'], book['voucher_no'])">
                    Mark as Completed
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </mat-tab>

    <mat-tab label="Issue/Granth Representative">
      <mat-card style="margin-top: 20px">
        <table class="table table-striped table-fixed">
          <thead>
            <tr>
              <th>Sr. No</th>
              <th>Representative Name</th>
              <th>Representative Mobile No</th>
              <th>Representative District</th>
              <th>Office Representative</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of issuewisereplist; let i = index">
              <td>{{ i + 1 }}</td>
              <td>{{ item["rep_name"] }}</td>
              <td>{{ item["rep_mob_no"] }}</td>
              <td>{{ item["district"] }}</td>
              <td>{{ item["off_rep_name"] }}</td>
              <td>
                <button type="button" class="btn btn-info" (click)="update(item)">
                  Update
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </mat-card>
    </mat-tab>

  </mat-tab-group>
</div>