<div class="header bg-gradient-danger pb-1 pt-5 pt-md-8">
  <div class="container-fluid">
    <div class="header-body"></div>
  </div>
</div>

<div class="container" style="margin-top: -4.1em">
  <!-- Icons is Used as Admin -->
  <mat-tab-group dynamicHeight>
    <mat-tab label="Book Invoice">
      <div
        class="add-new-subscriber mat-elevation-z4"
        style="overflow-x: scroll !important"
      >
        <div style="margin-top: 20px">
          <button
            style="margin-left: 3%"
            *ngIf="!displayCustOption"
            (click)="displayRepoption = !displayRepoption"
            mat-raised-button
            color="primary"
          >
            Representative Invoice
          </button>
          <button
            style="margin-left: 3%"
            *ngIf="!displayRepoption"
            (click)="displayCustOption = !displayCustOption"
            mat-raised-button
            color="warn"
          >
            Customer Invoice
          </button>
        </div>

        <mat-form-field
          *ngIf="displayRepoption"
          appearance="fill"
          style="margin-top: 5%"
        >
          <mat-label>Enter a date range</mat-label>
          <mat-date-range-input
            [formGroup]="invoicegetBook"
            [rangePicker]="picker1"
          >
            <input
              matStartDate
              formControlName="start"
              placeholder="Start date"
            />
            <input
              matEndDate
              formControlName="end"
              placeholder="End date"
              (dateChange)="getDateRange()"
            />
          </mat-date-range-input>
          <mat-datepicker-toggle
            matSuffix
            [for]="picker1"
          ></mat-datepicker-toggle>
          <mat-date-range-picker #picker1></mat-date-range-picker>

          <mat-error
            *ngIf="
              invoicegetBook.controls.start.hasError('matStartDateInvalid')
            "
            >Invalid start date
          </mat-error>
          <mat-error
            *ngIf="invoicegetBook.controls.end.hasError('matEndDateInvalid')"
            >Invalid end date
          </mat-error>
        </mat-form-field>

        <mat-form-field
          *ngIf="displayRepoption"
          appearance="fill"
          style="margin-left: 3%"
        >
          <mat-label>Representative Name</mat-label>
          <mat-select (selectionChange)="SelectRepresentative($event)">
            <mat-option
              *ngFor="let rep of getAllRepList"
              value="{{ rep['id'] }}"
              >{{ rep["name"] }}</mat-option
            >
          </mat-select>
        </mat-form-field>

        <mat-form-field
          *ngIf="displayRepoption"
          appearance="fill"
          style="margin-left: 3%"
        >
          <mat-label>Book Names</mat-label>
          <mat-select (selectionChange)="getRepSelectedBooks($event)" multiple>
            <mat-option *ngFor="let brp of getRepSpecificBooks" [value]="brp"
              >{{ brp["book_name"] }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field
          *ngIf="displayRepoption"
          appearance="fill"
          style="margin-left: 3%"
        >
          <mat-label>Invoice Type</mat-label>
          <mat-select (selectionChange)="getInvoiceType($event)">
            <mat-option value="Y">GST</mat-option>
            <mat-option value="N">Non-GST</mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field
          class="example-form-field"
          *ngIf="displaygstNumberInput && displayRepoption"
          appearance="fill"
          style="margin-left: 3%"
        >
          <mat-label>GST Number : </mat-label>
          <input
            matInput
            type="text"
            [(ngModel)]="gstNumber"
            (ngModelChange)="getGstNumber($event)"
          />
        </mat-form-field>

        <button
          style="margin-left: 2%"
          *ngIf="displayRepoption"
          (click)="generateBookInvoice()"
          mat-raised-button
          color="primary"
        >
          Generate Invoice
        </button>

        <!-- Customer invoicing data-->
        <mat-form-field
          *ngIf="displayCustOption"
          appearance="fill"
          style="margin-top: 5%"
        >
          <mat-label>Enter a date range</mat-label>
          <mat-date-range-input
            [formGroup]="invoicegetCustBook"
            [rangePicker]="picker3"
          >
            <input
              matStartDate
              formControlName="start"
              placeholder="Start date"
            />
            <input
              matEndDate
              formControlName="end"
              placeholder="End date"
              (dateChange)="getCustDateRange()"
            />
          </mat-date-range-input>
          <mat-datepicker-toggle
            matSuffix
            [for]="picker3"
          ></mat-datepicker-toggle>
          <mat-date-range-picker #picker3></mat-date-range-picker>

          <mat-error
            *ngIf="
              invoicegetCustBook.controls.start.hasError('matStartDateInvalid')
            "
            >Invalid start date
          </mat-error>
          <mat-error
            *ngIf="
              invoicegetCustBook.controls.end.hasError('matEndDateInvalid')
            "
            >Invalid end date
          </mat-error>
        </mat-form-field>

        <mat-form-field
          *ngIf="displayCustOption"
          appearance="fill"
          style="margin-left: 3%"
        >
          <mat-label>Customer Name</mat-label>
          <mat-select (selectionChange)="SelectCustomer($event)">
            <mat-option
              *ngFor="let rep of getAllCustList"
              value="{{ rep['mobile'] }}"
              >{{ rep["name"] }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field
          *ngIf="displayCustOption"
          appearance="fill"
          style="margin-left: 3%"
        >
          <mat-label>Book Names</mat-label>
          <mat-select (selectionChange)="getRepSelectedBooks($event)" multiple>
            <mat-option *ngFor="let brp of getSpecificBooksCust" [value]="brp"
              >{{ brp["book_name"] }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field
          *ngIf="displayCustOption"
          appearance="fill"
          style="margin-left: 3%"
        >
          <mat-label>Invoice Type</mat-label>
          <mat-select (selectionChange)="getInvoiceType($event)">
            <mat-option value="Y">GST</mat-option>
            <mat-option value="N">Non-GST</mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field
          class="example-form-field"
          *ngIf="displaygstNumberInput && displayCustOption"
          appearance="fill"
          style="margin-left: 3%"
        >
          <mat-label>GST Number : </mat-label>
          <input
            matInput
            type="text"
            [(ngModel)]="gstNumber"
            (ngModelChange)="getGstNumber($event)"
          />
        </mat-form-field>

        <button
          style="margin-left: 2%"
          *ngIf="displayCustOption"
          (click)="generateCustInvoice()"
          mat-raised-button
          color="primary"
        >
          Generate Invoice
        </button>
      </div>

      <mat-tab-group
        dynamicHeight
        style="margin-top: 3%"
        *ngIf="!displayRepoption && !displayCustOption"
      >
        <mat-tab label="Representative Invoices">
          <mat-form-field appearance="fill" style="margin-top: 5%">
            <mat-label>Enter a date range</mat-label>
            <mat-date-range-input
              [formGroup]="repGeneratedInvoice"
              [rangePicker]="picker2"
            >
              <input
                matStartDate
                formControlName="start"
                placeholder="Start date"
              />
              <input
                matEndDate
                formControlName="end"
                placeholder="End date"
                (dateChange)="getRepInvoiceDateRange()"
              />
            </mat-date-range-input>
            <mat-datepicker-toggle
              matSuffix
              [for]="picker2"
            ></mat-datepicker-toggle>
            <mat-date-range-picker #picker2></mat-date-range-picker>

            <mat-error
              *ngIf="
                repGeneratedInvoice.controls.start.hasError(
                  'matStartDateInvalid'
                )
              "
              >Invalid start date</mat-error
            >
            <mat-error
              *ngIf="
                repGeneratedInvoice.controls.end.hasError('matEndDateInvalid')
              "
              >Invalid end date</mat-error
            >
          </mat-form-field>

          <button
            style="margin-left: 3%"
            (click)="getRepInvoice()"
            mat-raised-button
            color="primary"
          >
            Get Report
          </button>

          <div style="margin-top: 20px" class="tableFixHead">
            <button
              type="button"
              (click)="exportexcel()"
              class="btn btn-danger"
            >
              Export
            </button>
            <table class="table table-striped text-center">
              <thead>
                <tr>
                  <th>Sr.No.</th>
                  <th>Invoice No</th>
                  <th>Invoice Date</th>
                  <th>Client Name</th>
                  <th>Total Amount</th>
                  <th>Status</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let book of this.RepInvoiceData; let i = index">
                  <td>{{ i + 1 }}</td>
                  <td>{{ book["invoice_id"] }}</td>
                  <td>{{ book["invoice_date"] | date: "dd-MM-yyy" }}</td>
                  <td>{{ book["name"] }}</td>
                  <td>{{ book["total_amount"] }}</td>
                  <td>{{ book["status"] }}</td>
                  <td>
                    <button
                      type="button"
                      (click)="ViewInvoice(book)"
                      class="btn btn-danger"
                    >
                      View
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </mat-tab>

        <mat-tab
          label="Customer Invoices"
          *ngIf="!displayRepoption && !displayCustOption"
        >
          <mat-form-field appearance="fill" style="margin-top: 5%">
            <mat-label>Enter a date range</mat-label>
            <mat-date-range-input
              [formGroup]="CustGeneratedInvoice"
              [rangePicker]="picker4"
            >
              <input
                matStartDate
                formControlName="start"
                placeholder="Start date"
              />
              <input
                matEndDate
                formControlName="end"
                placeholder="End date"
                (dateChange)="getCustInvoiceDateRange()"
              />
            </mat-date-range-input>
            <mat-datepicker-toggle
              matSuffix
              [for]="picker4"
            ></mat-datepicker-toggle>
            <mat-date-range-picker #picker4></mat-date-range-picker>

            <mat-error
              *ngIf="
                CustGeneratedInvoice.controls.start.hasError(
                  'matStartDateInvalid'
                )
              "
              >Invalid start date</mat-error
            >
            <mat-error
              *ngIf="
                CustGeneratedInvoice.controls.end.hasError('matEndDateInvalid')
              "
              >Invalid end date</mat-error
            >
          </mat-form-field>

          <button
            style="margin-left: 3%"
            (click)="getCustInvoice()"
            mat-raised-button
            color="primary"
          >
            Get Report
          </button>

          <div style="margin-top: 20px" class="tableFixHead">
            <button
              type="button"
              (click)="exportexcel()"
              class="btn btn-danger"
            >
              Export
            </button>
            <table class="table table-striped text-center">
              <thead>
                <tr>
                  <th>Sr.No.</th>
                  <th>Invoice No</th>
                  <th>Invoice Date</th>
                  <th>Client Name</th>
                  <th>Total Amount</th>
                  <th>Status</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let book of this.CustInvoiceData; let i = index">
                  <td>{{ i + 1 }}</td>
                  <td>{{ book["invoice_id"] }}</td>
                  <td>{{ book["invoice_date"] | date: "dd-MM-yyy" }}</td>
                  <td>{{ book["name"] }}</td>
                  <td>{{ book["total_amount"] }}</td>
                  <td>{{ book["status"] }}</td>
                  <td>
                    <button
                      type="button"
                      (click)="ViewCustInvoice(book)"
                      class="btn btn-danger"
                    >
                      View
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </mat-tab>
      </mat-tab-group>
    </mat-tab>

    <mat-tab label="Subscription Invoice">
      <button
        style="margin-left: 3%; margin-top: 5%"
        (click)="newCustSubInvoice = !newCustSubInvoice"
        mat-raised-button
        color="warn"
      >
        New Invoice
      </button>

      <mat-form-field
        class="example-form-field"
        *ngIf="newCustSubInvoice"
        appearance="fill"
        style="margin-left: 3%; margin-top: 5%"
      >
        <mat-label>Customer No.</mat-label>
        <input
          matInput
          type="text"
          [(ngModel)]="cust_id"
          (ngModelChange)="get_cust_id($event)"
        />
        <button
          *ngIf="cust_id"
          matSuffix
          mat-icon-button
          aria-label="Clear"
          (click)="reset_cust_id()"
        >
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>

      <span
        *ngIf="newCustSubInvoice"
        style="margin-left: 3%; font-size: large; font-weight: bold"
      >
        OR
      </span>

      <mat-form-field
        *ngIf="newCustSubInvoice"
        class="example-form-field"
        appearance="fill"
        style="margin-left: 3%; margin-top: 5%"
      >
        <mat-label>Customer Mobile No.</mat-label>
        <input
          matInput
          type="tel"
          [(ngModel)]="cust_mobile"
          #mobileValidate
          maxlength="10"
          (ngModelChange)="getcust_mobile($event)"
        />
        <button
          *ngIf="cust_mobile"
          matSuffix
          mat-icon-button
          aria-label="Clear"
          (click)="reset_cust_mobile()"
        >
          <mat-icon>close</mat-icon>
          <mat-hint align="end"
            >{{ mobileValidate.value.length }} / 10</mat-hint
          >
        </button>
      </mat-form-field>

      <button
        *ngIf="newCustSubInvoice"
        style="margin-left: 3%"
        (click)="getSubscriber()"
        mat-raised-button
        color="primary"
      >
        Find
      </button>

      <div
        *ngIf="displayNewSubscriber && newCustSubInvoice"
        style="
          margin-left: 3%;
          margin-top: 2%;
          margin-bottom: 2%;
          text-align: center;
        "
      >
        <span
          >You are generating invoice for :
          <b> {{ getSubDetails[0]["name"] }}</b>
        </span>
      </div>

      <mat-form-field
        *ngIf="displayNewSubscriber && newCustSubInvoice"
        appearance="fill"
        style="margin-left: 3%"
      >
        <mat-label>Invoice Type</mat-label>
        <mat-select (selectionChange)="getInvoiceType($event)">
          <mat-option value="Y">GST</mat-option>
          <mat-option value="N">Non-GST</mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field
        class="example-form-field"
        *ngIf="
          displaygstNumberInput && displayNewSubscriber && newCustSubInvoice
        "
        appearance="fill"
        style="margin-left: 3%"
      >
        <mat-label>GST Number : </mat-label>
        <input
          matInput
          type="text"
          [(ngModel)]="gstNumber"
          (ngModelChange)="getGstNumber($event)"
        />
      </mat-form-field>

      <button
        *ngIf="displayNewSubscriber && newCustSubInvoice"
        style="margin-left: 3%"
        (click)="getSubInvoice()"
        mat-raised-button
        color="primary"
      >
        Generate
      </button>

      <mat-form-field
        *ngIf="!newCustSubInvoice"
        appearance="fill"
        style="margin-top: 5%; margin-left: 3%"
      >
        <mat-label>Enter a date range</mat-label>
        <mat-date-range-input
          [formGroup]="CustSubGeneratedInvoice"
          [rangePicker]="picker5"
        >
          <input
            matStartDate
            formControlName="start"
            placeholder="Start date"
          />
          <input
            matEndDate
            formControlName="end"
            placeholder="End date"
            (dateChange)="getCustInvoiceDateRangeSubscription()"
          />
        </mat-date-range-input>
        <mat-datepicker-toggle
          matSuffix
          [for]="picker5"
        ></mat-datepicker-toggle>
        <mat-date-range-picker #picker5></mat-date-range-picker>

        <mat-error
          *ngIf="
            CustSubGeneratedInvoice.controls.start.hasError(
              'matStartDateInvalid'
            )
          "
          >Invalid start date</mat-error
        >
        <mat-error
          *ngIf="
            CustSubGeneratedInvoice.controls.end.hasError('matEndDateInvalid')
          "
          >Invalid end date</mat-error
        >
      </mat-form-field>

      <button
        *ngIf="!newCustSubInvoice"
        style="margin-left: 3%"
        (click)="getCustSubInvoice()"
        mat-raised-button
        color="primary"
      >
        Get Report
      </button>

      <div
        *ngIf="!newCustSubInvoice"
        style="margin-top: 20px"
        class="tableFixHead"
      >
        <button type="button" (click)="exportexcel()" class="btn btn-danger">
          Export
        </button>
        <table class="table table-striped text-center">
          <thead>
            <tr>
              <th>Sr.No.</th>
              <th>Invoice No</th>
              <th>Invoice Date</th>
              <th>Client Name</th>
              <th>Total Amount</th>
              <th>Status</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let book of this.CustSubInvoiceData; let i = index">
              <td>{{ i + 1 }}</td>
              <td>{{ book["invoice_id"] }}</td>
              <td>{{ book["invoice_date"] | date: "dd-MM-yyy" }}</td>
              <td>{{ book["name"] }}</td>
              <td>{{ book["total_amount"] }}</td>
              <td>{{ book["status"] }}</td>
              <td>
                <button
                  type="button"
                  (click)="ViewCustInvoiceSubscription(book)"
                  class="btn btn-danger"
                >
                  View
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </mat-tab>

    <mat-tab label="Advtertisement Invoice">
      <button
        style="margin-left: 3%; margin-top: 5%"
        (click)="newCustadvtInvoice = !newCustadvtInvoice"
        mat-raised-button
        color="warn"
      >
        New Invoice
      </button>

      <mat-form-field
        class="example-form-field"
        *ngIf="newCustadvtInvoice"
        appearance="fill"
        style="margin-left: 3%; margin-top: 5%"
      >
        <mat-label>Advtertisement No.</mat-label>
        <input
          matInput
          type="text"
          [(ngModel)]="advt_id"
          (ngModelChange)="get_cust_advt_id($event)"
        />
        <button
          *ngIf="advt_id"
          matSuffix
          mat-icon-button
          aria-label="Clear"
          (click)="reset_advt_id()"
        >
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>

      <span
        *ngIf="newCustadvtInvoice"
        style="margin-left: 3%; font-size: large; font-weight: bold"
      >
        OR
      </span>

      <mat-form-field
        *ngIf="newCustadvtInvoice"
        class="example-form-field"
        appearance="fill"
        style="margin-left: 3%; margin-top: 5%"
      >
        <mat-label>Customer Mobile No.</mat-label>
        <input
          matInput
          type="tel"
          [(ngModel)]="advt_mobile"
          #mobileValidate
          maxlength="10"
          (ngModelChange)="getcust_advt_mobile($event)"
        />
        <button
          *ngIf="advt_mobile"
          matSuffix
          mat-icon-button
          aria-label="Clear"
          (click)="reset_advt_mobile()"
        >
          <mat-icon>close</mat-icon>
          <mat-hint align="end"
            >{{ mobileValidate.value.length }} / 10</mat-hint
          >
        </button>
      </mat-form-field>

      <button
        *ngIf="newCustadvtInvoice"
        style="margin-left: 3%"
        (click)="getAdvtDetails()"
        mat-raised-button
        color="primary"
      >
        Find
      </button>

      <div
        *ngIf="displayadvtSubscriber && newCustadvtInvoice"
        style="
          margin-left: 3%;
          margin-top: 2%;
          margin-bottom: 2%;
          text-align: center;
        "
      >
        <span
          >You are generating invoice for :
          <b> {{ getAdvtPersonDetail[0]["name"] }}</b>
        </span>
      </div>

      <mat-form-field
        *ngIf="displayadvtSubscriber && newCustadvtInvoice"
        appearance="fill"
        style="margin-left: 3%"
      >
        <mat-label>Invoice Type</mat-label>
        <mat-select (selectionChange)="getInvoiceType($event)">
          <mat-option value="Y">GST</mat-option>
          <mat-option value="N">Non-GST</mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field
        class="example-form-field"
        *ngIf="
          displaygstNumberInput && displayadvtSubscriber && newCustadvtInvoice
        "
        appearance="fill"
        style="margin-left: 3%"
      >
        <mat-label>GST Number : </mat-label>
        <input
          matInput
          type="text"
          [(ngModel)]="gstNumber"
          (ngModelChange)="getGstNumber($event)"
        />
      </mat-form-field>

      <button
        *ngIf="displayadvtSubscriber && newCustadvtInvoice"
        style="margin-left: 3%"
        (click)="getAdvtInvoice()"
        mat-raised-button
        color="primary"
      >
        Generate
      </button>

      <mat-form-field
        *ngIf="!newCustadvtInvoice"
        appearance="fill"
        style="margin-top: 5%; margin-left: 3%"
      >
        <mat-label>Enter a date range</mat-label>
        <mat-date-range-input
          [formGroup]="AdvtGeneratedInvoice"
          [rangePicker]="picker6"
        >
          <input
            matStartDate
            formControlName="start"
            placeholder="Start date"
          />
          <input
            matEndDate
            formControlName="end"
            placeholder="End date"
            (dateChange)="getAdvtDateRange()"
          />
        </mat-date-range-input>
        <mat-datepicker-toggle
          matSuffix
          [for]="picker6"
        ></mat-datepicker-toggle>
        <mat-date-range-picker #picker6></mat-date-range-picker>

        <mat-error
          *ngIf="
            AdvtGeneratedInvoice.controls.start.hasError('matStartDateInvalid')
          "
          >Invalid start date</mat-error
        >
        <mat-error
          *ngIf="
            AdvtGeneratedInvoice.controls.end.hasError('matEndDateInvalid')
          "
          >Invalid end date</mat-error
        >
      </mat-form-field>

      <button
        *ngIf="!newCustadvtInvoice"
        style="margin-left: 3%"
        (click)="readAdvtInvoice()"
        mat-raised-button
        color="primary"
      >
        Get Report
      </button>

      <div
        *ngIf="!newCustadvtInvoice"
        style="margin-top: 20px"
        class="tableFixHead"
      >
        <button type="button" (click)="exportexcel()" class="btn btn-danger">
          Export
        </button>
        <table class="table table-striped text-center">
          <thead>
            <tr>
              <th>Sr.No.</th>
              <th>Invoice No</th>
              <th>Invoice Date</th>
              <th>Client Name</th>
              <th>Total Amount</th>
              <th>Status</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let book of this.AdvtInvoiceData; let i = index">
              <td>{{ i + 1 }}</td>
              <td>{{ book["invoice_id"] }}</td>
              <td>{{ book["invoice_date"] | date: "dd-MM-yyy" }}</td>
              <td>{{ book["name"] }}</td>
              <td>{{ book["total_amount"] }}</td>
              <td>{{ book["status"] }}</td>
              <td>
                <button
                  type="button"
                  (click)="ViewAdvtInvoice(book)"
                  class="btn btn-danger"
                >
                  View
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </mat-tab>
  </mat-tab-group>
</div>
