import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DigisubservicesService } from 'src/app/services/digisubservices.service';

@Component({
  selector: 'app-subscription-accounts',
  templateUrl: './subscription-accounts.component.html',
  styleUrls: ['./subscription-accounts.component.scss']
})
export class SubscriptionAccountsComponent implements OnInit {

  cashamt = 0;
  chequeamt = 0;
  onlineamt = 0;
  totalamt = 0;
  commission = 0;
  netoutstanding = 0;
  ionicForm: FormGroup;
  displaypart = false;
  previousoutstandng = 0;
  prevcommamt = 0;
  rep
  rep_receipt
  voucher_no=''
  constructor(private formBuilder: FormBuilder, public service: DigisubservicesService,
    private dialogRef: MatDialogRef<SubscriptionAccountsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void {

    this.rep = this.data['rep']

    this.rep_receipt = this.rep['rep_receipt']

    this.ionicForm = this.formBuilder.group({
      partamt: [''],
      paymentmode: ['', [Validators.minLength(2)]],
      clsoutstnd: [''],
      checkno: [''],
      comment: ['']
    })

    this.ionicForm.get('paymentmode').setValue("whole");
    console.log(this.rep)

    //console.log(this.rep['rep'])


    if (this.rep['payoptn'] == "cash") {
      this.cashamt = parseInt(this.rep['totalAmount'])
    } else if (this.rep['payoptn'] == "cheque") {
      this.chequeamt = parseInt(this.rep['totalAmount'])
    } else if (this.rep['payoptn'] == "online") {
      this.onlineamt = parseInt(this.rep['totalAmount'])
    }



    console.log(this.rep['rep_id'])
    this.service.getcurrentcommpay_book(parseInt(this.rep['rep_id']), parseInt(this.rep['id'])).subscribe(curr => {
      console.log(curr);
      if (Object.keys(curr).length != 0) {

        this.commission = parseInt(curr[0]['currntcommamt']);
      }

    })

    this.service.getPrevCommpay_book(parseInt(this.rep['rep_id']), parseInt(this.rep['id'])).subscribe(pre => {
      console.log(pre)

      if (Object.keys(pre).length != 0) {
        this.previousoutstandng = parseInt(pre[0]['closingamt'])
        this.prevcommamt = parseInt(pre[0]['prevcomm'])

      }

      this.totalamt = this.onlineamt + this.cashamt + this.chequeamt;
      this.netoutstanding = this.totalamt - (this.commission + this.prevcommamt) - this.previousoutstandng;
      this.ionicForm.get('clsoutstnd').setValue(0)
    });


    this.service.getVoucher_no(parseInt(this.rep['id'])).subscribe(data => 
      {
        this.voucher_no = data[0]['voucher_no'];
        
      })

  }

  getChangedPaymentmode(event) {
    console.log(event.target.value)

    if (event.target.value == "part") {
      this.displaypart = true;
    } else {
      this.displaypart = false;
      this.ionicForm.get('clsoutstnd').setValue(0)
    }

  }

  getPartAmount(event) {
    this.ionicForm.get('clsoutstnd').setValue((this.netoutstanding - parseInt(event.target.value)))
  }




  submitForm() {


    console.log(parseInt(this.rep['rep_id']))
    let restcredit = 0
    this.service.getRepcredit(parseInt(this.rep['rep_id'])).subscribe(res => {
      console.log(res)
      if (this.rep['payoptn'].toString() == "cash") {
        restcredit = parseInt(res[0]['ds_tempcredit']) + this.cashamt;

        let msg = "Dear " + this.rep['repName'] + " , Your collected cash amount of INR " + this.cashamt +
          " has been reeived to the office."
        //this.dataservice.sendSMS(parseInt(this.rep['repmobile']), msg).subscribe(a => { });
      }
      else if (this.rep['payoptn'].toString() == "cheque") {
        restcredit = parseInt(res[0]['ds_tempcredit'])

        let msg = "Dear " + this.rep['repName'] + " , Your collected Cheque amount of INR " + this.chequeamt + " has been received to the office.Regards, Hindustan Prakashan Sanstha."

        let template = "VIVEK35"
        // this.service.sendSMS(parseInt(this.rep['repmobile']), msg,template).subscribe(a => { });

      } else if (this.rep['payoptn'].toString() == "online") {

        let msg = "Dear " + this.rep['repName'] + " , Your collected Online amount of INR " + this.onlineamt + " has been received to the office.Regards, Hindustan Prakashan Sanstha."

        let template = "VIVEK37"
        // this.service.sendSMS(parseInt(this.rep['repmobile']), msg , template).subscribe(a => { });

      } else {
        restcredit = parseInt(res[0]['ds_tempcredit'])

      }

      if(this.rep['task'] == "book")
      {
        this.bookDataSubmission(restcredit)
      }else if (this.rep['task'] == "subscription")
      {
        this.SubscriptionDataSubmission(restcredit)
      } else if(this.rep['task'] == "advt")
      {
        this.advtDataSubmission(restcredit)
      }
      
    })

  }

  advtDataSubmission(restcredit)
  {
    if (this.displaypart) {
      //if its partial transction then check for other partial transaction make it N and then add new entry 
      this.service.updatePartialPayment_advt(parseInt(this.rep['rep_id'])).subscribe(a => {
        this.service.updatepaymentdep_advt(parseInt(this.rep['id']), this.ionicForm.value['clsoutstnd'], "Y", this.ionicForm.value['comment']).subscribe(abc => {
        });
      })

    }
    else {
      //if this is the whole payment then mark all partial transaction as N
      this.service.updatePartialPayment_advt(parseInt(this.rep['rep_id'])).subscribe(a => {
        this.service.updatepaymentdep_advt(parseInt(this.rep['id']), this.ionicForm.value['clsoutstnd'], "N", this.ionicForm.value['comment']).subscribe(abc => {
        });
      })

    }


    this.service.updateCreditLimit(parseInt(this.rep['rep_id']), 0, restcredit).subscribe(res => { });
    this.service.updatepaymntstus_advt(parseInt(this.rep['id']), "Y").subscribe(abc => {
      this.dialogRef.close()
    });
    //this.dataservice.updateMagpaymntstus(parseInt(this.rep['id']), "Y").subscribe(abc => { });
    this.service.insertCashNtfcn(this.cashamt, parseInt(this.rep['rep_id'])).subscribe(res => { })

  }


  bookDataSubmission(restcredit)
  {
    if (this.displaypart) {
      //if its partial transction then check for other partial transaction make it N and then add new entry 
      this.service.updatePartialPayment_book(parseInt(this.rep['rep_id'])).subscribe(a => {
        this.service.updatepaymentdep_book(parseInt(this.rep['id']), this.ionicForm.value['clsoutstnd'], "Y", this.ionicForm.value['comment']).subscribe(abc => {
        });
      })

    }
    else {
      //if this is the whole payment then mark all partial transaction as N
      this.service.updatePartialPayment_book(parseInt(this.rep['rep_id'])).subscribe(a => {
        this.service.updatepaymentdep_book(parseInt(this.rep['id']), this.ionicForm.value['clsoutstnd'], "N", this.ionicForm.value['comment']).subscribe(abc => {
        });
      })

    }


    this.service.updateCreditLimit(parseInt(this.rep['rep_id']), 0, restcredit).subscribe(res => { });
    this.service.updatepaymntstus_book(parseInt(this.rep['id']), "Y").subscribe(abc => {
      this.dialogRef.close()
    });
    //this.dataservice.updateMagpaymntstus(parseInt(this.rep['id']), "Y").subscribe(abc => { });
    this.service.insertCashNtfcn(this.cashamt, parseInt(this.rep['rep_id'])).subscribe(res => { })

  }


  SubscriptionDataSubmission(restcredit)
  {
    if (this.displaypart) {
      //if its partial transction then check for other partial transaction make it N and then add new entry 
      this.service.updatePartialPayment(parseInt(this.rep['rep_id'])).subscribe(a => {
        this.service.updatepaymentdep(parseInt(this.rep['id']), this.ionicForm.value['clsoutstnd'], "Y", this.ionicForm.value['comment']).subscribe(abc => {
        });
      })

    }
    else {
      //if this is the whole payment then mark all partial transaction as N
      this.service.updatePartialPayment(parseInt(this.rep['rep_id'])).subscribe(a => {
        this.service.updatepaymentdep(parseInt(this.rep['id']), this.ionicForm.value['clsoutstnd'], "N", this.ionicForm.value['comment']).subscribe(abc => {
        });
      })

    }


    this.service.updateCreditLimit(parseInt(this.rep['rep_id']), 0, restcredit).subscribe(res => { });
    this.service.updatepaymntstus_book(parseInt(this.rep['id']), "Y").subscribe(abc => {   });
    this.service.updateMagpaymntstus(parseInt(this.rep['id']), "Y").subscribe(abc => {
      this.dialogRef.close()
     });
    this.service.insertCashNtfcn(this.cashamt, parseInt(this.rep['rep_id'])).subscribe(res => { })

  }


}
