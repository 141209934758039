<div mat-dialog-content>
    <div mat-dialog-actions style="float:right" >
        <button mat-button mat-dialog-close>Close</button>
    </div>
    <div class="text-center">
        <h1 style="font-size: medium;font-weight: bold;">Cancel Subscription</h1>
        
    </div>

    <div class="cancel-subscriber mat-elevation-z4">
        <form class="form" [formGroup]="form1" (ngSubmit)=submitForm()>
            <div class="form-row">
                <div class="col form-group">
                    <label style="margin-left: 20px; margin-top: 20px;">Mobile Number </label>
                    <input required style="width: 85%; margin-left: 20px;" formControlName="mobileNumber"
                        type="number" class="form-control" placeholder="" [readonly]="nochangenumber"
                        (change)="getValidateEvent($event)">
                    <label *ngIf="disableOtherFields && !startLoading"
                        style="color: red;font-weight: bold;margin-left: 25px;">Please check
                        subscriber is exist or not.</label>
                    <label *ngIf="startLoading" style="color: red;font-weight: bold;margin-left: 25px;">Loading,
                        Please wait</label>

                    <label *ngIf="!disableOtherFields"
                        style="color: green;font-weight: bold;margin-left: 25px;">You are
                        Cancelling Subscription for {{ subscribersName }}</label>
                </div>
            </div>

            <div *ngIf="!disableOtherFields" class="col form-group">
                <label style="margin-left: 20px; margin-top: 20px;">
                    Please select the Magazine for Cancelation :
                </label>
            </div>
            <div class="form-group" *ngIf="!disableOtherFields">
                <select required formControlName="cancelmags0" (change)="getcancelmag($event)"
                    class="form-control" placeholder="Select Magazine" style="margin-left: 35px;width:65%">
                    <option *ngFor="let cancelmag of cancelCheckbox;let i = index" value="{{i}}">
                        {{cancelmag['name']}} -
                        {{cancelmag['edition']}} - End_date : {{cancelmag['end_date']}}</option>
                </select>
            </div>

            <div class="form-group">
                <button *ngIf="!disableOtherFields" style="margin-left: 23%; width: 50%;"
                    [disabled]="form1.invalid" type="submit" class="btn btn-danger btn-block">Cancel
                    Subscription</button>

                <button *ngIf="disableOtherFields" style="margin-left: 23%; width: 50%;"
                    class="btn btn-danger btn-block" type="button" (click)="getsubmags()">
                    Validate Number
                </button>
            </div>
        </form>
    </div>

</div>
