import { DatePipe, formatDate } from '@angular/common';
// import { FileSaverService } from 'ngx-filesaver';
import { Component, OnInit, ViewChild, ElementRef, ChangeDetectionStrategy } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ContractBriefReportComponent } from '../../contract-brief-report/contract-brief-report.component';
import { DigisubservicesService } from 'src/app/services/digisubservices.service';
import { MatDialog } from '@angular/material/dialog';
import { AddNewSubOtpModalComponent } from 'src/app/pages/add-new-sub-otp-modal/add-new-sub-otp-modal.component';
import { CancelSubDialogComponent } from 'src/app/pages/cancel-sub-dialog/cancel-sub-dialog.component';
import { EncdecriptService } from 'src/app/services/encdecrypt.service';
import { Router } from '@angular/router';
import * as XLSX from 'xlsx';
import * as fileSaver from 'file-saver';
import { UpdateTrackingComponent } from '../../update-tracking/update-tracking.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AddNewSubscriberComponent } from '../../add-new-subscriber/add-new-subscriber.component';
import { AddNewMagazineComponent } from '../../add-new-magazine/add-new-magazine.component';
import { AddCancelSubscriberComponent } from '../../add-cancel-subscriber/add-cancel-subscriber.component';
import { MatOption } from '@angular/material/core';
import { MatSelect } from '@angular/material/select';
import { SubscriptionAccountsComponent } from '../../subscription-accounts/subscription-accounts.component';
import { SubscriptioninvoiceComponent } from '../../subscriptioninvoice/subscriptioninvoice.component';
import { CreditvoucherDialogComponent } from '../../creditvoucher-dialog/creditvoucher-dialog.component';
import { Observable } from 'rxjs';
import { debounce, debounceTime, map, startWith } from 'rxjs/operators';
import { ToWords } from 'to-words';
import { UpdateOfficeRepresentativeComponent } from '../../update-office-representative/update-office-representative.component';
import { UpdateSubtypeComponent } from '../../update-subtype/update-subtype.component';
import { DatewiseRepresentativewiseComponent } from '../../datewise-representativewise/datewise-representativewise.component';
import { ReturnReceiptDialogComponent } from '../../return-receipt-dialog/return-receipt-dialog.component';
import { DatewiseComponent } from '../../datewise/datewise.component';
import { MonthwiseComponent } from '../monthwise/monthwise.component';
import { OfficeRepresentativewiseComponent } from '../../office-representativewise/office-representativewise.component';
import { DistrictwiseTalukawiseComponent } from '../../districtwise-talukawise/districtwise-talukawise.component';
import { RemainingRenewableListOfAddressComponent } from '../../remaining-renewable-list-of-address/remaining-renewable-list-of-address.component';
import { RepwiseAddressListComponent } from '../../repwise-address-list/repwise-address-list.component';
import { SubscriptionRenewalRemainderletterComponent } from '../../subscription-renewal-remainderletter/subscription-renewal-remainderletter.component';
import { RepresentativewiseAddressComponent } from '../../representativewise-address/representativewise-address.component';
import { SubscribersAddressComponent } from '../../subscribers-address/subscribers-address.component';
import { ShowDistributionComponent } from '../../show-distribution/show-distribution.component';
import { AddressLabelsComponent } from '../../address-labels/address-labels.component';
import { AddressLabelsMobileNoComponent } from '../../address-labels-mobile-no/address-labels-mobile-no.component';
import { PincodewiseStatementComponent } from '../../pincodewise-statement/pincodewise-statement.component';
import { DistrictwiseStatementComponent } from '../../districtwise-statement/districtwise-statement.component';
import { HandDeliveryComponent } from '../../hand-delivery/hand-delivery.component';
import { PostStatementComponent } from '../../post-statement/post-statement.component';
import { PostStatementTapalComponent } from '../../post-statement-tapal/post-statement-tapal.component';
import { UpdateReturnReceiptComponent } from '../../update-return-receipt/update-return-receipt.component';
import { PrintGSTReportComponent } from '../../print-gstreport/print-gstreport.component';
import { ShowSubscriptionNodaniReceiptComponent } from '../../show-subscription-nodani-receipt/show-subscription-nodani-receipt.component';
import { LabelsOfHandDeliveryComponent } from '../../labels-of-hand-delivery/labels-of-hand-delivery.component';
import { PrintOrderStatementComponent } from '../print-order-statement/print-order-statement.component';
import { PrintJavakComponent } from '../print-javak/print-javak.component';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { HanddeliverylabelslistComponent } from '../../handdeliverylabelslist/handdeliverylabelslist.component';
import { OtherStatePincodewiseStatementComponent } from '../../other-state-pincodewise-statement/other-state-pincodewise-statement.component';
import { ShowSubscriberReceiptLedgerPrintComponent } from '../../show-subscriber-receipt-ledger-print/show-subscriber-receipt-ledger-print.component';
import { ViewPrintOrderComponent } from '../../view-print-order/view-print-order.component';
import { UpdatePrintOrderComponent } from '../../update-print-order/update-print-order.component';
import { DistrictwiseSubscriptionReportComponent } from '../districtwise-subscription-report/districtwise-subscription-report.component';
import { DistrictwiseVarganidarSubscriptionReportComponent } from '../districtwise-varganidar-subscription-report/districtwise-varganidar-subscription-report.component';
import { PrantwiseVarganidarReportComponent } from '../prantwise-varganidar-report/prantwise-varganidar-report.component';
import { DatewiseVarganidarRojmelReportComponent } from '../datewise-varganidar-rojmel-report/datewise-varganidar-rojmel-report.component';
import { RenewRemainingDataComponent } from '../renew-remaining-data/renew-remaining-data.component';
import { AddmodalComponent } from 'src/app/addmodal/addmodal.component';
import { AlladdComponent } from 'src/app/alladd/alladd.component';
import { AlladdlistComponent } from 'src/app/alladdlist/alladdlist.component';
import { PrintchallanComponent } from 'src/app/printchallan/printchallan.component';
import { RepbusinessreportComponent } from 'src/app/repbusinessreport/repbusinessreport.component';
import { SubscriberdetailsbriefComponent } from 'src/app/subscriberdetailsbrief/subscriberdetailsbrief.component';
import { StatewisestatementComponent } from 'src/app/statewisestatement/statewisestatement.component';
import { ActiveinactivebusinessreportComponent } from 'src/app/activeinactivebusinessreport/activeinactivebusinessreport.component';
import { StatewisebusinessreportComponent } from 'src/app/statewisebusinessreport/statewisebusinessreport.component';
import { CountrywisebusinessreportComponent } from 'src/app/countrywisebusinessreport/countrywisebusinessreport.component';
import { DistrictwisebusinessComponent } from 'src/app/districtwisebusiness/districtwisebusiness.component';
import { PrantwisebusinessComponent } from 'src/app/prantwisebusiness/prantwisebusiness.component';
import { TypewisebusinessreportComponent } from 'src/app/typewisebusinessreport/typewisebusinessreport.component';


export const _filter = (opt: string[], value: string): string[] => {
  const filterValue = value.toLowerCase();

  return opt.filter((item) => item.toLowerCase().includes(filterValue));
};

export const _filter1 = (opt: string[], value: string): string[] => {
  const filterValue = value.toLowerCase();

  return opt.filter((item) => item.toLowerCase().includes(filterValue));
};

export const _filter2 = (opt: string[], value: string): string[] => {
  const filterValue = value.toLowerCase();

  return opt.filter((item) => item.toLowerCase().includes(filterValue));
};

export const _filter3 = (opt: string[], value: string): string[] => {
  const filterValue = value.toLowerCase();

  return opt.filter((item) => item.toLowerCase().includes(filterValue));
};

export const _filter4 = (opt: string[], value: string): string[] => {
  const filterValue = value.toLowerCase();

  return opt.filter((item) => item.toLowerCase().includes(filterValue));
};

export const _filter5 = (opt: string[], value: string): string[] => {
  const filterValue = value.toLowerCase();

  return opt.filter((item) => item.toLowerCase().includes(filterValue));
};

export const _filter6 = (opt: string[], value: string): string[] => {
  const filterValue = value.toLowerCase();

  return opt.filter((item) => item.toLowerCase().includes(filterValue));
};

export const _filter7 = (opt: string[], value: string): string[] => {
  const filterValue = value.toLowerCase();

  return opt.filter((item) => item.toLowerCase().includes(filterValue));
};

export const _filter8 = (opt: string[], value: string): string[] => {
  const filterValue = value.toLowerCase();

  return opt.filter((item) => item.toLowerCase().includes(filterValue));
};

export const _filter9 = (opt: string[], value: string): string[] => {
  const filterValue = value.toLowerCase();

  return opt.filter((item) => item.toLowerCase().includes(filterValue));
};

export const _filter10 = (opt: string[], value: string): string[] => {
  const filterValue = value.toLowerCase();

  return opt.filter((item) => item.toLowerCase().includes(filterValue));
};

export const _filter11 = (opt: string[], value: string): string[] => {
  const filterValue = value.toLowerCase();

  return opt.filter((item) => item.toLowerCase().includes(filterValue));
};

export const _filter12 = (opt: string[], value: string): string[] => {
  const filterValue = value.toLowerCase();

  return opt.filter((item) => item.toLowerCase().includes(filterValue));
};

export const _filter13 = (opt: string[], value: string): string[] => {
  const filterValue = value.toLowerCase();

  return opt.filter((item) => item.toLowerCase().includes(filterValue));
};

export const _filter14 = (opt: string[], value: string): string[] => {
  const filterValue = value.toLowerCase();

  return opt.filter((item) => item.toLowerCase().includes(filterValue));
};

export const _filter15 = (opt: string[], value: string): string[] => {
  const filterValue = value.toLowerCase();

  return opt.filter((item) => item.toLowerCase().includes(filterValue));
};

export const _filter16 = (opt: string[], value: string): string[] => {
  const filterValue = value.toLowerCase();

  return opt.filter((item) => item.toLowerCase().includes(filterValue));
};

export const _filter17 = (opt: string[], value: string): string[] => {
  const filterValue = value.toLowerCase();

  return opt.filter((item) => item.toLowerCase().includes(filterValue));
};

export const _filter18 = (opt: string[], value: string): string[] => {
  const filterValue = value.toLowerCase();

  return opt.filter((item) => item.toLowerCase().includes(filterValue));
};

export const _filter19 = (opt: string[], value: string): string[] => {
  const filterValue = value.toLowerCase();

  return opt.filter((item) => item.toLowerCase().includes(filterValue));
};

export const _filter20 = (opt: string[], value: string): string[] => {
  const filterValue = value.toLowerCase();

  return opt.filter((item) => item.toLowerCase().includes(filterValue));
};

// export const _filter21 = (opt: string[], value: string): string[] => {
//   const filterValue = value.toLowerCase();

//   return opt.filter((item) => item.toLowerCase().includes(filterValue));
// };

export const _filter22 = (opt: string[], value: string): string[] => {
  const filterValue = value.toLowerCase();

  return opt.filter((item) => item.toLowerCase().includes(filterValue));
};

export const _filter23 = (opt: string[], value: string): string[] => {
  const filterValue = value.toLowerCase();

  return opt.filter((item) => item.toLowerCase().includes(filterValue));
};

export const _filter24 = (opt: string[], value: string): string[] => {
  const filterValue = value.toLowerCase();

  return opt.filter((item) => item.toLowerCase().includes(filterValue));
};

export const _filter25 = (opt: string[], value: string): string[] => {
  const filterValue = value.toLowerCase();

  return opt.filter((item) => item.toLowerCase().includes(filterValue));
};

export const _filter26 = (opt: string[], value: string): string[] => {
  const filterValue = value.toLowerCase();

  return opt.filter((item) => item.toLowerCase().includes(filterValue));
};

export const _filter27 = (opt: string[], value: string): string[] => {
  const filterValue = value.toLowerCase();

  return opt.filter((item) => item.toLowerCase().includes(filterValue));
};

export const _filter28 = (opt: string[], value: string): string[] => {
  const filterValue = value.toLowerCase();

  return opt.filter((item) => item.toLowerCase().includes(filterValue));
};

@Component({
  selector: 'app-subscription-details',
  templateUrl: './subscription-details.component.html',
  styleUrls: ['./subscription-details.component.scss'],
  changeDetection:ChangeDetectionStrategy.OnPush
})
export class SubscriptionDetailsComponent implements OnInit {

  trackById(index: number, book: any): number {
    return index; // Ensure `id` is a unique identifier
  }

  delivery_mode_update_btn: boolean = false;
  dataSource: MatTableDataSource<any>;
  displayedColumns: string[] = [
    'rcpt_no',
    'entry_date',
    'subno',
    'name',
    'district',
    'subscription_type',
    'rcpt_type',
    'sub_perid_from_date',
    'sub_perid_to_date',
    'delivery_mode',
    'distribution_rep',
    'temp_rcpt_no',
    'temp_rcpt_date',
    'rep_name',
    'office_rep_name',
    'total_amt',
    'discount',
    'net_amt',
    'rep_com_amt',
    'crdt_voucher_no',
    'crdt_voucher_entry_date',
    'magzine_edition',
    'remark',
    'remark1',
    'remark2',
    'rcptstopdate',
    'rcptstopremark',
    'rcptstartdate',
    'rcptstartremark',
    'action',
  ];

  @ViewChild(MatPaginator) paginator: MatPaginator;
  form1: FormGroup;
  getAllRepList = [
    {
      id: 777,
      mobile: '1234567893',
      name: 'All',
      rep_address: '',
      rep_advt_comm: '0',
      rep_book_comm: '0',
      rep_comm: '0',
    },
  ];
  BookForm: FormGroup;
  invoiceForm: FormGroup;
  subform: FormGroup;
  subtypeform: FormGroup;
  adddeliverymodeform: FormGroup;
  adddeliverymode: FormGroup;

  generateform: FormGroup;
  addsubdeliverymodeform: FormGroup;
  subdetailsform: FormGroup;
  returnrcptform: FormGroup;
  receiptLedger: FormGroup;
  paymentreceiptledger: FormGroup

  voucherform: FormGroup;
  daterepForm: FormGroup;
  offrepForm: FormGroup;
  subaddform: FormGroup;
  subledgerForm: FormGroup;
  distributionForm: FormGroup;
  renewalreportForm: FormGroup;
  RenewalreportForm: FormGroup;
  RenewalTypereportForm: FormGroup;
  databyvocuher : FormGroup
  RemainingRenewalreportForm: FormGroup;
  datewisedata:any
  PendingAddForm: FormGroup;
  AllAddForm: FormGroup;
  iscustomer: any = 'no';

  displayinvoiceForm = true;
  subRenewalReportForm: FormGroup;
  invoicedate = formatDate(new Date(), 'yyyy-MM-dd', 'en-IN', 'GMT+0530');
  searchTerm = false;
  displayChequqField = false;
  mag = [];
  magname = [];
  magyear = [];
  selectedState = '';
  selectedCity = '';
  selectedTaluka = '';
  subscriberDetails = {
    name: '',
    address: '',
  };
  submagsDetails;
  totalamount = 0;
  totalmagamount = 0;
  totaldonationamount = 0;
  displayTotal = false;
  public copy: string;
  data1;
  data2;
  needToUpdate = false;
  imgPath: string;
  disableOtherFields = true;
  getMagazine;
  subid;
  cancelCheckbox = [];
  changedMobileNumber;
  nochangenumber = false;
  cust_id = '';
  cust_mobile;
  gstNumber = '';
  newCustSubInvoice = false;
  newCustadvtInvoice = false;
  invoiceDetails;
  viewmode = false;
  repInvoice = 'Y';
  CustSubInvoiceData = [];
  getRepInvoiceData = [];
  voucherNoList :any
  AssignDisRepForm: FormGroup;

  CustSubGeneratedInvoice = new FormGroup({
    start: new FormControl(),
    end: new FormControl(),
  });

  dateform = new FormGroup({
    start: new FormControl(),
    end: new FormControl(),
  });

  rangeRegistration = new FormGroup({
    start: new FormControl(),
    end: new FormControl(),
  });

  rangerepwisereport = new FormGroup({
    start: new FormControl(),
    end: new FormControl(),
  });

  renewalReport = new FormGroup({
    start: new FormControl(),
    end: new FormControl(),
  });

  DistPinReport = new FormGroup({
    start: new FormControl(),
    end: new FormControl(),
  });
  toWords: any;

  subdaterange = new FormGroup({
    start: new FormControl(),
    end: new FormControl(),
  });

  rtnrcptdaterange = new FormGroup({
    start: new FormControl(),
    end: new FormControl(),
  });

  rcptldgrForm = new FormGroup({
    start: new FormControl(),
    end: new FormControl(),
  });

  pendingvoucherForm = new FormGroup({
    start: new FormControl(),
    end: new FormControl(),
  });

  datewiseForm = new FormGroup({
    start: new FormControl(),
    end: new FormControl(),
  });

  monthwiseForm = new FormGroup({
    start: new FormControl(),
    end: new FormControl(),
  });

  gstreportForm = new FormGroup({
    start: new FormControl(),
    end: new FormControl(),
  });
  GetOffRep: any;
  contractReportAdvtRepName: any;
  datavale: any[];
  formattedDate: any;
  issue_date: string;
  Filtereddetails: any;
  repno: string;
  repname: string;
  offrep: string;
  vnumber: string;
  newdismode: any;
  subdistmode: any;
  pindcodelist: any;
  checklatestdata: any;
  distname: any;
  dist_rep_name: any;
  dev_mode: any;
  distributionmodesData1: any;
  id: any;
  Getprantname: any;
  disRepData: any;
  talukaname1: any;
  prantdata: any;
  dis_rep_data: any;
  subscriberwisereplist1: any;
  checkstatus: any;
  subscriberlistbrief: any[];
  disRepData1: any;
  databyvc: any;
  totalcvamt: any;
  serachlist: any;
  readnum: any;
  rep_nameforcv: any='';
  labeldata: any;
  Add: any;

  constructor(
    private router: Router,
    private fb: FormBuilder,
    public service: DigisubservicesService,
    public dialog: MatDialog,
    public encr: EncdecriptService,
    private _snackBar: MatSnackBar,
    private datePipe: DatePipe,
    
  ) {
    this.form1 = fb.group({
      mobileNumber: '',
      cancelmags0: { name: '', edition: '' },
      cancelmags1: { name: '', edition: '' },
      cancelmags2: { name: '', edition: '' },
      cancelmags3: { name: '', edition: '' },
      cancelmags4: { name: '', edition: '' },
      cancelmags5: { name: '', edition: '' },
    });

    this.subform = fb.group({
      iscomplimentary: [''],
      subname: [''],
      subno: [''],
      month: [''],
      year: [''],
      showname: [''],
      voucherid: [' '],
      subtype: [''],
      title: ['', [Validators.minLength(2)]],
      name: ['', [Validators.minLength(2)]],
      e_name: [''],
      address: ['', [Validators.minLength(2)]],
      pincode: [
        '',
        [Validators.maxLength(6), Validators.pattern('^[1-9][0-9]{5}$')],
      ],
      country: ['', [Validators.minLength(2)]],
      state: ['', [Validators.minLength(2)]],
      district: ['', [Validators.minLength(2)]],
      e_district: [''],
      taluka: ['', [Validators.minLength(2)]],
      mobileNumber: [
        '',
        [Validators.maxLength(10), Validators.pattern('^[7-9][0-9]{9}$')],
      ],
      telephone: [''],
      email: [
        '',
        [Validators.pattern('[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+.[a-z]{2,3}$')],
      ],
      dob: [''],
      doa: [''],
      repname: [''],
      repmobno: [
        '',
        [Validators.maxLength(10), Validators.pattern('^[7-9][0-9]{9}$')],
      ],
      gst_no: [''],
      remark: [''],
      crepname: [''],
      repmobno1: [''],
      officerep: [''],
      crdtdate: [''],
      crdtvno: [''],
      crdtventrydate: [''],
      rep_receipt: [],
      crdtamt: [''],
      seamt: [''],
      balamt: [''],
      entrydate: [''],
      magnames: [''],
      magedition: [''],
      newid: [''],

      duration: ['',Validators.required],
      subyear: [''],
      subfromdate: [''],
      subtodate: [''],
      distype: [''],
      disrep: [''],
      trecptno: [''],
      rdate: [''],
      discount: [''],
      netamount: [''],
      repcompercent: [''],
      repcomprice: [''],
      cdno: [''],
      chequeDate: [''],
      bankname: [''],
      subremark: [''],
      subremark1: [''],
      subremark2: [''],
      emp_mob_number: [''],
      isactive: [''],
      rcptstopdate: [''],
      rcptstopremark: [''],
      days: [''],
      rcptstartdate: [''],
      rcptstartremark: ['']
    });

    this.subtypeform = fb.group({
      subtype: [''],
      price: [''],
      date: [''],
      shortname : ['']
    });

    this.adddeliverymode = fb.group({
      deliverymode: [''],
    });

    this.adddeliverymodeform = fb.group({
      deliverymode: [''],
      subdeliverymode: [''],
    });

    this.addsubdeliverymodeform = fb.group({
      deliverymode: [''],
      subdeliverymode: [''],
    });

    this.generateform = fb.group({
      publisheddate: [''],
      start: [''],
      end: [''],
      title: [''],
      subname: [''],
      subtype: [''],
      countryname: [''],
      statename: [''],
      districtname: [''],
      talukaname: [''],
      pincode: [''],
      rep: [''],
      officerep: [''],
      delivery_mode_name: [''],
      issue_date: [''],
    });

    this.subdetailsform = fb.group({
      cust_id: [''],
      sub_name: [''],
      rcpt_no: [''],
      rep: [''],
      off_rep: [''],
      v_no: [''], 
      active_inactive: [''],
      hastetapal: [''],
      dist_name: [''],
      subtype: [''],
      tal_name:[''],
      prant_name: [''],
      country: [''],
      state:[''],
      
    });

    this.returnrcptform = fb.group({
      subname: [''],
      rep: [''],
      off_rep: [''],
      district: [''],
      taluka: [''],
      state: [''],
      country: [''],
    });

    this.receiptLedger = fb.group({
      start: [''],
      end: [''],
      cust_name: [''],
    });
    this.paymentreceiptledger = fb.group({
      start: [''],
      end: [''],
      rcpt_number: [''],
      rep: [''],
      country: [''],
      state: [''],
      district: [''],
      taluka: [''],
      prant_name: [''],
      dis_rep: [''],
      active_inactive: [''],
    })

    this.voucherform = fb.group({
      rep: [''],
      voucher_no: [''],
    });

    this.daterepForm = fb.group({
      start: [''],
      end: [''],
      rep_name: [''],
      off_rep_name: [''],
      district: [''],
      tal_name: [''],
      active: [''],
      state: [''],
      country : [''],
      prant_name:[''],
      duration: [' '],
    });

    this.subRenewalReportForm = fb.group({
      start: [''],
      end: [''],
      rep_name: [''],
      off_rep_name: [''],
      district: [''],
      taluka: [''],
      statename:['']
    });

    this.offrepForm = fb.group({
      off_rep_name: [''],
      start: [''],
      end: [''],
    });

    this.subaddform = fb.group({
      start: [''],
      end: [''],
      titles: [''],
      complimentary: [''],
      subtype: [''],
      taluka: [''],
      district: [''],
    });

    this.subledgerForm = fb.group({
      sub_name: [''],
      sub_no: [''],
      start: [''],
      end: [''],
    });

    this.AssignDisRepForm = fb.group({
      disrep1: [''],
      dismode: [''],
      dissubmode: [''],
    });

    this.distributionForm = fb.group({
      disrep: [''],
      dismode: [''],
      subdismode: [''],
      vajan: [''],
      tapalrate: [''],
    });

    this.renewalreportForm = fb.group({
      start: [''],
      end: [''],
      rep: ['']
    });

    this.RenewalreportForm = fb.group({
      date: [''],
      reportType: [''],
      rep: [''],
      start: [''],
      end: [''],
      officer: [''],
      district: [''],
      taluka: [''],
      prantname: [''],
      country:[''],
      statename:[''],
    });
    this.databyvocuher = fb.group({
      start : [''],
      end : [''],
      cvnumber : [''],
      repname : ['']
    })
    this.RenewalTypereportForm = fb.group({
      // date: [''],
      start: [''],
      end: [''],
      reportType: [''],
      rep: [''],

      officer: [''],
      district: [''],
      taluka: [''],
      country:[''],
      statename:[''],
      prantname:[''],

    });
    this.RemainingRenewalreportForm = fb.group({
      // date: [''],
      start: [''],
      end: [''],
      reportType: [''],
      rep: [''],

      officer: [''],
      district: [''],
      taluka: [''],
      country:[''],
      statename:[''],
      prantname:[''],
    });

    this.PendingAddForm = fb.group({
      date: [''],
      start: [''],
      end: [''],
      reportType: [''],
      rep: [''],
      prantname: [''],
      country:[''],
      statename:[''],
      duration:[''],
      subtype:[''],


      // officer: [''],
      district: [''],
      taluka: [''],
      modetype: [''],
    });

    this.AllAddForm = fb.group({


      subtype: [''],
      district: [''],
      taluka: [''],
      modetype: [''],
      start: [''],
      end: [''],
      country:[''],
      statename:[''],
      prantname: [''],
      duration:[''],
      reportType: ['',Validators.required],
    });
  }

  selected = 'Print';
  sub_data = [];
  rep_name_data = [];
  sub_no = [];
  sub_mob_no = [];
  filteredOptions: Observable<string[]>;
  filteredOptions1: Observable<string[]>;
  filteredOptions2: Observable<string[]>;
  filteredOptions3: Observable<string[]>;
  filteredOptions4: Observable<string[]>;
  filteredOptions5: Observable<string[]>;
  filteredOptions6: Observable<string[]>;
  filteredOptions7: Observable<string[]>;
  filteredOptions8: Observable<string[]>;
  filteredOptions9: Observable<string[]>;
  filteredOptions10: Observable<string[]>;
  filteredOptions11: Observable<string[]>;
  filteredOptions12: Observable<string[]>;
  filteredOptions13: Observable<string[]>;
  filteredOptions14: Observable<string[]>;
  filteredOptions15: Observable<string[]>;
  filteredOptions16: Observable<string[]>;
  filteredOptions17: Observable<string[]>;
  filteredOptions18: Observable<string[]>;
  filteredOptions19: Observable<string[]>;
  filteredOptions20: Observable<string[]>;
  // filteredOptions21: Observable<string[]>;
  filteredOptions22: Observable<string[]>;
  filteredOptions23: Observable<string[]>;
  filteredOptions24: Observable<string[]>;
  filteredOptions25: Observable<string[]>;
  filteredOptions26: Observable<string[]>;
  filteredOptions27: Observable<string[]>;
  filteredOptions28: Observable<string[]>;
  isAdminAuthorize = false;
  countries = [];
  states = [];
  cities = [];
  talukas = [];
  titles = [];
  title:any;
  occupations = [];
  districtList = [];
  Notifications = [];
  subscriptionpaymentList = [];
  subno;
  duration;
  month = new Date();
  receipttype = 'SVV';
  val = 1;
  magedition;
  subscriptiontype = [];
  subscriberwisereplist;
  delivery_mode;
  subDeliveryModeData = [];
  subDistributionModeData = [];
  FilteredsubDistributionModeData = [];
  iscomplementary: boolean = false;
  issue_seller_name = [];
  distributorList = [];
  getSubDetailsData = [];
  FilteredgetSubDetailsData = [];
  totalamountofsub = 0;
  totaldiscountofsub = 0;
  totalnetamtofsub = 0;
  totalrepcomofsub = 0;
  receiptledger;
  showGSTReceiptData = [];
  exportGSTReceiptData = [];
  Filetredreceiptledger;
  receiptledgertotalamt = 0;
  gstreporttotalamt = 0;
  receiptledgerdiscounttotal = 0;
  sub_sub_no_data = [];
  sub_sub_name_data = [];
  sub_rep_data = [];
  sub_off_rep_data = [];
  sub_v_no_data = [];
  sub_rcpt_no_data = [];
  voucher_rep_data = [];
  voucher_no_data = [];
  returnrcptdata = [];
  Filteredreturnrcptdata = [];
  rtnrcpt_sub_name = [];
  rtnrcpt_rep_name = [];
  rtnrcpt_off_rep_name = [];
  rtnrcpt_district = [];
  rtnrcpt_taluka = [];
  rtnrcpt_state = [];
  rtnrcpt_country = [];
  datewiserepwisedata = [];
  Filtereddatewiserepwisedata = [];
  Filtereddatewisedata = [];
  daterepwiserep_name = [];
  filtereddaterepwiserep_name = [];
  Filteredmonthwisedata = [];
  offrepwisedata = [];
  Filteredoffrepwisedata = [];
  offrepwiseoff_rep_name = [];
  Filtereddistricttalukawisedata = [];
  renewable_rceipt_data = [];
  repwisesubaddresslist = [];
  repwiseaddress = [];
  subscribersaddressdata = [];
  Filteredsubscribersaddressdata = [];
  titles1 = [];
  district = [];
  taluka = [];
  subtype = [];
  complimentary = [];
  subscribersledgerdata = [];
  Filteredsubscribersledgerdata = [];
  subscribersledgernames = [];
  subscribersledgersub_no = [];
  pkgBookingData = [];
  filtereddistrictwisesubcout = [];
  filteredpincodewisesubcout = [];
  labelsforDispatch = [];
  FilteredlabelsforPostStatement = [];
  FilteredlabelsforDispatch = [];
  advtCategorylist;
  distributionmoderepData;
  distributionmodesData;
  subdistributionmodesData;
  length = 100000;
  pageIndex = 1;
  pageSize = 13000;
  previousPageIndex: 0;
  length1 = 100000;
  pageIndex1 = 1;
  pageSize1 = 13000;
  previousPageIndex1: 0;
  length2 = 100000;
  pageIndex2 = 1;
  pageSize2 = 13000;
  previousPageIndex2: 0;
  message: string;
  subscription_all_data : boolean = true;
  
  public demo1BtnClick11(tabCount:any) {
    
    this.demo1TabIndex =  Number(tabCount);
    
  }

  ngOnInit() {
    this.demo1BtnClick11( localStorage.getItem("tab"))

    console.log(this.LabelsDisModes, "this.LabelsDisModes")
    let currentDate = new Date();

    // Subtract six months from the current date
    currentDate.setMonth(currentDate.getMonth() - 6);

    // Format the date using DatePipe
    this.formattedDate = this.datePipe.transform(currentDate, 'yyyy-MM-dd');
    // console.log(sessionStorage.getItem('mobile'));
    this.subform.get('magedition').setValue(this.selected);
    this.magedition = this.selected;
    this.getsubno();
    this.getOffRep();
    this.getprantname();

    // this.service.getsubcrdtvoucherPackageData().subscribe((data) => {
    //   // console.log('pkgBookingData', data);
    //   this.pkgBookingData = data;
    // });

    this.service.getdistname().subscribe((data) => {
      // console.log('pkgBookingData', data);
      this.distname = data;
    });
    this.service.gettaluka().subscribe((data) => {
      this.talukaname1 = data;
    });
    this.service.get_dis_rep().subscribe((data) => {
      this.dis_rep_data = data;
    });

    this.service.getprant().subscribe((data) => {
      // console.log('pkgBookingData', data);
      this.prantdata = data;
    });

    this.service.geTotalSubscribersData().subscribe((data) => {
      // console.log(data);
      if (data) {
        this.length = data['total_count'];
      }
    });

    this.getSubscribersDetails(this.pageIndex, this.pageSize);

    this.service.getDeliveryMode().subscribe((data) => {
      // console.log('getDeliveryMode', data);
      this.delivery_mode = data;
    });

    this.service.getDistributorData().subscribe((data) => {
      // console.log(data);
      this.distributorList = data;
    });

    this.service.getIssuesellersname().subscribe((data) => {
      this.issue_seller_name = data;
      // console.log('issue_seller_name', this.issue_seller_name);
    });

    this.service.getSubDeliveryMode().subscribe((data) => {
      // console.log(data);
      this.subDeliveryModeData = data;
    });

    this.service.getSubDistributionMode().subscribe((data) => {
      // console.log(data);
      this.subDistributionModeData = data;
    });

    this.service.getSubscriptionWiseRep().subscribe((data) => {
      this.subscriberwisereplist = data;
    });

    this.service.getAllDistributionModes().subscribe((res) => {
      this.distributionmodesData = res;
    });

    this.service.getAllDistributionModes1().subscribe((res) => {
      this.distributionmodesData1 = res;
    });
    this.service.getAllSubDistributionModes().subscribe((res) => {
      this.subdistributionmodesData = res;
    });

    this.service.getRepwiseAddress().subscribe((data) => {
      // console.log(data);
      this.repwiseaddress = data;
      this.getrepwiseAddress();
    });
    // console.log('month', this.month);

    this.service.getReturnRcpt().subscribe((data) => {
      this.returnrcptdata = data;
      this.Filteredreturnrcptdata = this.returnrcptdata;
      // console.log({ data });
debugger
      for (
        let index = 0;
        index < Object.keys(this.returnrcptdata).length;
        index++
      ) {
        this.rtnrcpt_sub_name.push({
          name: this.returnrcptdata[index]['name'],
        });
        this.rtnrcpt_rep_name.push({
          rep_name: this.returnrcptdata[index]['rep_name'],
        });
        this.rtnrcpt_off_rep_name.push({
          office_rep_name: this.returnrcptdata[index]['office_rep_name'],
        });
        this.rtnrcpt_district.push({
          district: this.returnrcptdata[index]['district'],
        });
        this.rtnrcpt_taluka.push({
          taluka: this.returnrcptdata[index]['taluka'],
        });
        this.rtnrcpt_state.push({
          state: this.returnrcptdata[index]['state'],
        });
        this.rtnrcpt_country.push({
          country: this.returnrcptdata[index]['country'],
        });
      }
      this.rtnrcpt_sub_name = this.uniqueid(
        this.rtnrcpt_sub_name,
        (it) => it.name
      );
      this.rtnrcpt_rep_name = this.uniqueid(
        this.rtnrcpt_rep_name,
        (it) => it.rep_name
      );
      this.rtnrcpt_off_rep_name = this.uniqueid(
        this.rtnrcpt_off_rep_name,
        (it) => it.off_rep_name
      );
      this.rtnrcpt_district = this.uniqueid(
        this.rtnrcpt_district,
        (it) => it.district
      );
    });

    this.service.getsubcrdtvoucherPackageData().subscribe((data) => {
      // console.log('pkgBookingData', data);
      this.pkgBookingData = data;
      this.getAllVouchers();
    });
    this.service.getdistmode().subscribe((data) => {
      console.log('new dis_mode data', data);
      this.newdismode = data;
    });

    const abc = this.distributionForm.value.subdismode;
    console.log(abc, "abc")

    this.subform
      .get('entrydate')
      .setValue(formatDate(new Date(), 'yyyy-MM-dd', 'en-IN', 'GMT+0530'));
    this.subform
      .get('subyear')
      .setValue(formatDate(new Date(), 'yyyy-MM-dd', 'en-IN', 'GMT+0530'));
    this.subtypeform
      .get('date')
      .setValue(formatDate(new Date(), 'yyyy-MM-dd', 'en-IN', 'GMT+0530'));
    this.service.getsubnotification().subscribe((sub: any[]) => {
      // console.log(sub);
      this.service.getUpdatesubnotification().subscribe((updt) => {
        // console.log(updt);

        for (let index = 0; index < Object.keys(updt).length; index++) {
          this.Notifications.push(updt[index]);
        }

        for (let index = 0; index < Object.keys(sub).length; index++) {
          this.Notifications.push(sub[index]);
        }
      });
    });

    // this.month=new Date(this.subform.value.crdtdate)

    this.getRcptNo();

    this.service.getSubscriptionType().subscribe((data) => {
      // console.log('getSubscriptionType', data);
      this.subscriptiontype = data;
    });

    // this.service.get_AllRepresentative_List().subscribe((data) => {
    //   // console.log(data);
    //   for (let index = 0; index < Object.keys(data).length; index++) {
    //     this.getAllRepList.push(data[index]);
    //     // console.log('getAllRepList', this.getAllRepList);
    //   }
    // });

    this.service.get_distinct_city_list().subscribe((data) => {
      // console.log(data);
      this.districtList = data;
    });

    // Read All Contries
    // this.service.getAllContries().subscribe((data) => {
    //   // console.log(data);

    //   this.countries = data;
    // });

    this.service.getCountry().subscribe((data) => {
      this.countries = data;
    });

    this.service.getOccupation().subscribe((data) => {
      // console.log(data);
      this.occupations = data;
    });

    this.service.getAlltitle().subscribe((data) => {
      // console.log(data);
      this.titles = data;
    });

    this.service.getSubscribersAddressTitles().subscribe((res) => {
      // console.log(res);
      this.titles1 = res;
    });

    this.service.getSubscribersAddressTitles1().subscribe((res) => {
      // console.log(res);
      this.titles1 = res;
    });

    this.service.getSubscribersAddressDistricts().subscribe((res) => {
      this.district = res;
    });

    this.service.getSubscribersAddressTalukas().subscribe((res) => {
      this.taluka = res;
    });

    this.service.getSubscribersAddressSubType().subscribe((res) => {
      this.subtype = res;
    });

    //temporary hide
    // this.service.getSubscribersAddressData().subscribe((data) => {
    //   console.log(data);
    //   this.subscribersaddressdata = data;
    //   this.Filteredsubscribersaddressdata = this.subscribersaddressdata;

    //   for (
    //     let index = 0;
    //     index < Object.keys(this.subscribersaddressdata).length;
    //     index++
    //   ) {
    //     this.titles1.push({
    //       title: this.subscribersaddressdata[index]['title'],
    //     });

    //     this.district.push({
    //       district: this.subscribersaddressdata[index]['district'],
    //     });

    //     this.taluka.push({
    //       taluka: this.subscribersaddressdata[index]['taluka'],
    //     });

    //     this.subtype.push({
    //       subtype: this.subscribersaddressdata[index]['subtype'],
    //     });

    //     this.complimentary.push({
    //       complimentary: this.subscribersaddressdata[index]['is_complimentary'],
    //     });
    //   }

    //   this.titles1 = this.uniqueid(this.titles1, (a) => a.title);
    //   this.district = this.uniqueid(this.district, (a) => a.district);
    //   this.taluka = this.uniqueid(this.taluka, (a) => a.taluka);
    //   this.subtype = this.uniqueid(this.subtype, (a) => a.subtype);
    //   this.complimentary = this.uniqueid(
    //     this.complimentary,
    //     (a) => a.complimentary
    //   );
    //   // console.log(this.titles1);
    // });

    this.service.getRepresentativeSubAddressData().subscribe((data) => {
      // console.log('data', data);
      this.repwisesubaddresslist = data;
    });

    this.service.getReceiptLedgerTotal().subscribe((res) => {
      // console.log(res);
      if (res) {
        this.length1 = res['total_count'];
      }
    });

    this.getReceiptLedgerDetails(
      formatDate(
        new Date(new Date().getFullYear(), new Date().getMonth()),
        'yyyy-MM-dd',
        'en-IN',
        'GMT+0530'
      ),
      formatDate(
        new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0),
        'yyyy-MM-dd',
        'en-IN',
        'GMT+0530'
      )
    );

    //commented due to performance issue - this replaced with another logic
    // this.service.getReceiptLedger().subscribe((data) => {
    //   this.receiptledger = data;
    //   for (let i = 0; i < Object.keys(this.receiptledger).length; i++) {
    //     this.showGSTReceiptData.push({
    //       crdt_voucher_date: this.receiptledger[i]['crdt_voucher_date'],
    //       crdt_voucher_no: this.receiptledger[i]['crdt_voucher_no'],
    //       delivery_mode: this.receiptledger[i]['delivery_mode'],
    //       discount: this.receiptledger[i]['discount'],
    //       distribution_rep: this.receiptledger[i]['distribution_rep'],
    //       district: this.receiptledger[i]['district'],
    //       e_district: this.receiptledger[i]['e_district'],
    //       entry_date: this.receiptledger[i]['entry_date'],
    //       id: this.receiptledger[i]['id'],
    //       magzine_edition: this.receiptledger[i]['magzine_edition'],
    //       name: this.receiptledger[i]['name'],
    //       e_name: this.receiptledger[i]['e_name'],
    //       net_amt: this.receiptledger[i]['net_amt'],
    //       office_rep_name: this.receiptledger[i]['office_rep_name'],
    //       rcpt_no: this.receiptledger[i]['rcpt_no'],
    //       rcpt_type: this.receiptledger[i]['rcpt_type'],
    //       rep_com_amt: this.receiptledger[i]['rep_com_amt'],
    //       rep_com_percent: this.receiptledger[i]['rep_com_percent'],
    //       rep_mob_no: this.receiptledger[i]['rep_mob_no'],
    //       rep_name: this.receiptledger[i][''],
    //       sub_perid_from_date: this.receiptledger[i]['sub_perid_from_date'],
    //       sub_perid_to_date: this.receiptledger[i]['sub_perid_to_date'],
    //       subno: this.receiptledger[i]['subno'],
    //       subscription_type: this.receiptledger[i]['subscription_type'],
    //       subscription_year: this.receiptledger[i]['subscription_year'],
    //       total_amt: this.receiptledger[i]['total_amt'],
    //       bill_no: '',
    //       gst_no: this.receiptledger[i]['gst_no'],
    //       hsn_code: '',
    //     });
    //   }
    //   console.log(this.showGSTReceiptData);
    //   this.Filetredreceiptledger = this.filteredReceiptLedgerData();
    //   this.calculatercptledger();
    //   this.calculateGSTReportData();
    // });

    if (sessionStorage.getItem('mobile') == null) {
      this.router.navigate(['/']);
    }

    this.service
      .checkadminissuper(parseInt(sessionStorage.getItem('id')))
      .subscribe((a) => {
        if (Object.keys(a).length > 0) {
          this.isAdminAuthorize = true;
        } else {
          this.isAdminAuthorize = false;
        }
      });

    this.filteredOptions3 = this.subform.get('crepname').valueChanges.pipe(
      startWith(''),
      map((value) => (typeof value === 'string' ? value : value.name)),
      map((name) =>
        name ? this._filter3(name) : this.issue_seller_name.slice()
      )
    );

    this.getRepName();

    //change for OEM
    this.service.getMag().subscribe((res) => {
      // console.log(res);
      this.mag = [];
      this.magname = [];
      this.magyear = [];

      for (let i = 0; i < Object.keys(res).length; i++) {
        // this.mag.push({
        //   name: res[i]['name'],
        //   print: res[i]['print'],
        //   digital: res[i]['digital'],
        //   printdigital: res[i]['printdigital'],
        //   year: parseInt(res[i]['year']),
        // });

        if (i == 0) {
          this.magname[0] = {
            name: res[i]['name'],
          };
          this.subform.get('magnames').setValue(res[i]['name']);
          this.magnames = res[i]['name'];

          // this.magyear[0] = {
          //   year: parseInt(res[i]['year']),
          // };
          // console.log('mags', this.magyear);
        }
        // else {
        //   if (
        //     this.magname.find((a) => a['name'] == res[i]['name']) == undefined
        //   ) {
        //     this.magname.push({
        //       name: res[i]['name'],
        //     });
        //     this.subform.get('magnames').setValue(res[i]['name']);
        //     this.subform.get('magedition').setValue('Print');
        //   }

        //   this.magyear.push({
        //     year: parseInt(res[i]['year']),
        //   });
        // }
        // console.log(this.magname);
      }
    });

    //commented due to performance issue and replaced with another logic
    // this.service.getDatewiseAndRepwiseList().subscribe((data) => {
    //   console.log('daterep', data);
    //   this.datewiserepwisedata = data;
    //   this.Filtereddatewiserepwisedata = this.datewiserepwisedata;
    //   this.Filtereddatewisedata = this.datewiserepwisedata;
    //   this.Filteredmonthwisedata = this.datewiserepwisedata;
    //   this.getrep_name_data();
    // });

    this.getrep_name_data();

    this.service.getOfficeRepwiseList().subscribe((data) => {
      this.offrepwisedata = data;
      console.log(this.offrepwisedata);
      // this.Filteredoffrepwisedata = this.offrepwisedata;
      // this.Filtereddistricttalukawisedata = this.offrepwisedata;
      // this.getoffice_rep_data();
    });

    this.service.getAdvtCategory().subscribe((data) => {
      // console.log('Database data', data);
      this.advtCategorylist = data;
    });

    this.service.getRemainingRenewableReceiptData().subscribe((data) => {
      this.renewable_rceipt_data = data;
    });

    this.service.getSubscriberledgerDataCount().subscribe((data) => {
      if (data) {
        this.length2 = data['total_count'];
      }
    });

    this.getSubscriberLedgerData(this.pageIndex2, this.pageSize2);

    //commented due to performance issue is also replaced with another logic
    // this.service.getSubscriberLedgerData().subscribe((data) => {
    //   this.subscribersledgerdata = data;
    //   this.Filteredsubscribersledgerdata = this.subscribersledgerdata;
    //   // console.log('subscribersledgerdata', this.subscribersledgerdata);

    //   for (
    //     let index = 0;
    //     index < Object.keys(this.subscribersledgerdata).length;
    //     index++
    //   ) {
    //     this.subscribersledgernames.push({
    //       sub_name: this.subscribersledgerdata[index]['name'],
    //     });

    //     this.subscribersledgersub_no.push({
    //       sub_no: this.subscribersledgerdata[index]['subno'],
    //     });
    //   }
    //   this.subscribersledgernames = this.uniqueid(
    //     this.subscribersledgernames,
    //     (a) => a.sub_name
    //   );
    // });

    this.service.getDistributionModeRep().subscribe((data) => {
      this.distributionmoderepData = data;
      // console.log('distributionmoderepData', this.distributionmoderepData);
    });

    this.getsubscriptionPayment();
    this.packageData();
   
    this.enableDisableRule('second');
    this.getLabelforDispatch();

    this.getGSTReportData(
      formatDate(
        new Date(new Date().getFullYear(), new Date().getMonth()),
        'yyyy-MM-dd',
        'en-IN',
        'GMT+0530'
      ),
      formatDate(
        new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0),
        'yyyy-MM-dd',
        'en-IN',
        'GMT+0530'
      )
    );

    this.getAllPrintOrderData();
    this.isrcpt = 'New';
  } //end of ngOninit

  getSubscriberLedgerData(pageIndex, pageSize) {
    this.service
      .getSubscriberLedgerDataWithPagination(pageIndex, pageSize)
      .subscribe((res) => {
        this.subscribersledgerdata = res;
        this.Filteredsubscribersledgerdata = this.subscribersledgerdata;
debugger
        for (
          let index = 0;
          index < Object.keys(this.subscribersledgerdata).length;
          index++
        ) {
          this.subscribersledgernames.push({
            sub_name: this.subscribersledgerdata[index]['name'],
          });

          this.subscribersledgersub_no.push({
            sub_no: this.subscribersledgerdata[index]['subno'],
          });
        }
        this.subscribersledgernames = this.uniqueid(
          this.subscribersledgernames,
          (a) => a.sub_name
        );
      });
  }

  getGSTReportData(start, end) {
    this.service.getGSTReportData(start, end).subscribe((res) => {
      // console.log('getGSTReportData ', res);
      this.showGSTReceiptData = [];
      for (let i = 0; i < Object.keys(res).length; i++) {
        this.showGSTReceiptData.push({
          crdt_voucher_date: res[i]['crdt_voucher_date'],
          crdt_voucher_no: res[i]['crdt_voucher_no'],
          delivery_mode: res[i]['delivery_mode'],
          discount: res[i]['discount'],
          distribution_rep: res[i]['distribution_rep'],
          district: res[i]['district'],
          e_district: res[i]['e_district'],
          entry_date: res[i]['entry_date'],
          id: res[i]['id'],
          magzine_edition: res[i]['magzine_edition'],
          name: res[i]['name'],
          e_name: res[i]['e_name'],
          net_amt: res[i]['net_amt'],
          office_rep_name: res[i]['office_rep_name'],
          rcpt_no: res[i]['rcpt_no'],
          rcpt_type: res[i]['rcpt_type'],
          rep_com_amt: res[i]['rep_com_amt'],
          rep_com_percent: res[i]['rep_com_percent'],
          rep_mob_no: res[i]['rep_mob_no'],
          rep_name: res[i][''],
          sub_perid_from_date: res[i]['sub_perid_from_date'],
          sub_perid_to_date: res[i]['sub_perid_to_date'],
          subno: res[i]['subno'],
          subscription_type: res[i]['subscription_type'],
          subscription_year: res[i]['subscription_year'],
          total_amt: res[i]['total_amt'],
          bill_no: '',
          gst_no: res[i]['gst_no'] == '' ? '27AAATH0433Q1ZI' : res[i]['gst_no'],
          hsn_code: '4902',
          // date: res[i]['month'] + ' ' + res[i]['year'],
        });

        this.exportGSTReceiptData.push({
          SR_NO: i + 1,
          Receipt_Date: formatDate(
            res[i]['crdt_voucher_date'],
            'dd-MM-yyyy',
            'en-IN',
            'GMT+0530'
          ),
          Customer_Name: res[i]['e_name'],
          Address_of_Company: res[i]['e_district'],
          Receipt_No: res[i]['rcpt_no'],
          GST_No: res[i]['gst_no'] == '' ? '27AAATH0433Q1ZI' : res[i]['gst_no'],
          HSN_Code: '4902',
          Total_Amount: res[i]['total_amt'],
        });
      }
    });
    setTimeout(() => {
      this.calculateGSTReportData();
    }, 500);
    console.log(this.showGSTReceiptData);
  }

  getReceiptLedgerDetails(startdate, enddate) {
    this.service.getReceiptLedgerData(startdate, enddate, this.cust_name).subscribe((data) => {
      this.receiptledger = data;
      // console.log('receiptledger', this.receiptledger);
      this.Filetredreceiptledger = this.filteredReceiptLedgerData();
      this.calculatercptledger();
    });
  }
  getReceiptLedgerDetails3(data) {
    this.service.getReceiptLedgerData3(data).subscribe((data) => {
      debugger
      this.receiptledger = data;
      // console.log('receiptledger', this.receiptledger);
      this.Filetredreceiptledger = this.filteredReceiptLedgerData();
      // this.calculatercptledger();
    });
  }
  getalldata() {
    this.service.getalldata().subscribe((data) => {
      this.receiptledger = data;
      // console.log('receiptledger', this.receiptledger);
      this.Filetredreceiptledger = this.filteredReceiptLedgerData();
      this.calculatercptledger();
    });
  }

  getSubscribersDetails(pageIndex, pageSize) {

    // const req={
    //   "start_date":this.startdate,
    //   "end_date":this.endDate,
    //   "cust_id" : this.cust_id,

    // }

    // this.service.getSubscribersDeatilsDataWithFilter(req).subscribe((data) => {
    //   this.receiptledger = data;
    //   // console.log('receiptledger', this.receiptledger);
      
    // });

    this.service
      .getSubscribersDeatilsDataWithPagination(pageIndex, pageSize, this.formattedDate)
      .subscribe((data) => {
        this.getSubDetailsData = data;
        this.FilteredgetSubDetailsData = [];
        this.dataSource = new MatTableDataSource(
          this.FilteredgetSubDetailsData
        );
        // this.dataSource.paginator = this.paginator;
        for (
          let index = 0;
          index < Object.keys(this.getSubDetailsData).length;
          index++
        ) {
          this.totalamountofsub =
            this.totalamountofsub +
            parseInt(this.getSubDetailsData[index]['total_amt']);
          this.totaldiscountofsub =
            this.totaldiscountofsub +
            parseInt(this.getSubDetailsData[index]['discount']);
          this.totalnetamtofsub =
            this.totalnetamtofsub +
            parseInt(this.getSubDetailsData[index]['net_amt']);
          this.totalrepcomofsub =
            this.totalrepcomofsub +
            parseInt(this.getSubDetailsData[index]['rep_com_amt']);
          this.sub_rep_data.push({
            rep_name: this.getSubDetailsData[index]['rep_name'],
          });
          this.sub_off_rep_data.push({
            office_rep_name: this.getSubDetailsData[index]['office_rep_name'],
          });
          this.sub_v_no_data.push({
            crdt_voucher_no: this.getSubDetailsData[index]['crdt_voucher_no'],
          });
          this.sub_rcpt_no_data.push({
            rcpt_no: this.getSubDetailsData[index]['rcpt_no'],
          });
          this.sub_sub_no_data.push({
            subno: this.getSubDetailsData[index]['subno'],
          });

          this.sub_sub_name_data.push({
            name: this.getSubDetailsData[index]['name'],
          });
        }
        this.sub_rep_data = this.uniqueid(
          this.sub_rep_data,
          (it) => it.rep_name
        );
        this.sub_off_rep_data = this.uniqueid(
          this.sub_off_rep_data,
          (it) => it.office_rep_name
        );
        this.sub_v_no_data = this.uniqueid(
          this.sub_v_no_data,
          (it) => it.crdt_voucher_no
        );

        this.sub_sub_name_data = this.uniqueid(
          this.sub_sub_name_data,
          (a) => a.name
        );

        this.total_no_data = Object.keys(this.FilteredgetSubDetailsData).length;
      });
  }

  // Package pending credit voucher Data start
  packageData() { } // Package pending credit voucher Data End

  repaddresslist = [];
  // repwiseaddresslist = [];
  getrepwiseAddress() {
   debugger
    let temp = this.uniqueid(this.repwiseaddress, (a) => a.rep_name);
    for (let index = 0; index < Object.keys(temp).length; index++) {
      this.repaddresslist.push({
        id: temp[index]['id'],
        rep_name: temp[index]['rep_name'],
        rep_mob_no: temp[index]['rep_mob_no'],
        office_rep_name: temp[index]['office_rep_name'],
        pincode: temp[index]['pincode'],
        address: temp[index]['address'],
        country: temp[index]['country'],
        district: temp[index]['district'],
        state: temp[index]['state'],
        taluka: temp[index]['taluka'],
        sub_perid_from_date: temp[index]['sub_perid_from_date'],
        sub_perid_to_date: temp[index]['sub_perid_to_date'],
        subscription_year: temp[index]['subscription_year'],
        sub_count: 0,
        sub_on: 0,
        sub_off: 0,
      });
    }
    // console.log(this.repaddresslist);

    for (
      let index = 0;
      index < Object.keys(this.repaddresslist).length;
      index++
    ) {
      let arr = this.repwiseaddress.filter((a) => {
        return a['rep_name'] == this.repaddresslist[index]['rep_name'];
      });

      let sub_count = 0;
      let sub_on = 0;
      let sub_off = 0;
      for (let i = 0; i < Object.keys(arr).length; i++) {
        sub_count = sub_count + 1;
        let today = formatDate(new Date(), 'yyyy-MM-dd', 'en-IN', 'GMT+0530');
        if (arr[i]['sub_perid_to_date'] > today) {
          sub_on = sub_on + 1;
        } else {
          sub_off = sub_off + 1;
        }
      }

      this.repaddresslist[index]['sub_count'] = sub_count;
      this.repaddresslist[index]['sub_on'] = sub_on;
      this.repaddresslist[index]['sub_off'] = sub_off;

      // this.repwiseaddresslist.push(arr);
    }
    // console.log(this.repwiseaddresslist);
    // console.log(this.repaddresslist);
  }

  representativewiseadd() {
    this.dialog.open(RepresentativewiseAddressComponent, {
      data: {
        repaddresslist: this.repaddresslist,
      },
      width: '1200px',
      height: '1300px',
      maxWidth: '100%',
    });
  }

  getoffice_rep_data() {
    for (
      let index = 0;
      index < Object.keys(this.Filteredoffrepwisedata).length;
      index++
    ) {
      this.offrepwiseoff_rep_name.push({
        office_rep_name: this.Filteredoffrepwisedata[index]['office_rep_name'],
      });
    }

    this.offrepwiseoff_rep_name = this.uniqueid(
      this.offrepwiseoff_rep_name,
      (a) => a.office_rep_name
    );
  }

  getrep_name_data() {
    // console.log(
    //   'this.Filtereddatewiserepwisedata',
    //   this.Filtereddatewiserepwisedata
    // );
    this.service.getRepNameofSubDetails().subscribe((res) => {
      // console.log(res);
      this.daterepwiserep_name = res;
      this.filtereddaterepwiserep_name = this.daterepwiserep_name;
    });
    // for (
    //   let index = 0;
    //   index < Object.keys(this.Filtereddatewiserepwisedata).length;
    //   index++
    // ) {
    //   this.daterepwiserep_name.push({
    //     rep_name: this.Filtereddatewiserepwisedata[index]['rep_name'],
    //   });
    // }

    // this.daterepwiserep_name = this.uniqueid(
    //   this.daterepwiserep_name,
    //   (a) => a.rep_name
    // );

    // console.log('this.daterepwiserep_name', this.daterepwiserep_name);
  }

  searchRepName(event) {
    // console.log(event.target.value);
    let timeout: any;
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      console.log(event.target.value);
      this.filtereddaterepwiserep_name = this.daterepwiserep_name.filter(
        (a) => {
          return a.rep_name
            .toLowerCase()
            .match(
              RegExp(
                `.*${event.target.value.toLowerCase().split('').join('.*')}.*`
              )
            );
        }
      );
    }, 200);
  }

  filteredDistrictName;
  showList: boolean = false;
  timeout;
  searchDistrict(event) {
    // console.log('running', this.timeout);
    if (event.target.value != 0) {
      this.showList = true;
      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        this.service
          .getSearchedDistrictName(event.target.value)
          .subscribe((res) => {
            console.log(res);
            this.filteredDistrictName = res;
          });
      }, 400);
    }
    // };
  }

  filteredTalukasName;
  selectDistrict(event, data) {
    if (event.isUserInput) {
      // console.log('called', data);
      // console.log('name', this.daterepForm.get('district').value);
      this.showList = false;

      this.getDailyReportData(
        this.daterepstartdate,
        this.daterependdate,
        this.rep_name,
        this.off_rep_name1,
        this.daterepForm.get('district').value,
        this.daterepForm.get('taluka').value
      );

      this.service.getTaluka(data['district_id']).subscribe((res) => {
        // console.log(res);
        this.filteredTalukasName = res;
      });
    }
  }

  getTalukaNames(_event) {
    // console.log(event.value);
    // console.log(this.daterepForm.get('taluka').value);
    this.getDailyReportData(
      this.daterepstartdate,
      this.daterependdate,
      this.rep_name,
      this.off_rep_name1,
      this.daterepForm.get('district').value,
      this.daterepForm.get('taluka').value
    );
  }
  getactive(event) {
    
    // console.log(event.value);
    // console.log(this.daterepForm.get('taluka').value);
    this.datewisedata = event.value
  }

  hideSearch() { }

  uniqueid(data, key) {
    return [...new Map(data.map((x) => [key(x), x])).values()];
  }

  getRepName() {
    debugger
    this.filteredOptions = this.subform.get('mobileNumber').valueChanges.pipe(
      startWith(''),
      map((value) => (typeof value === 'string' ? value : value.mobno)),
      map((mobno) => (mobno ? this._filter(mobno) : this.sub_data.slice()))
    );

    this.filteredOptions1 = this.subform.get('subname').valueChanges.pipe(
      startWith(''),
      map((value) => (typeof value === 'string' ? value : value.subname)),
      map((subname) =>
        subname ? this._filter1(subname) : this.sub_data.slice()
      )
    );

    this.filteredOptions2 = this.subform.get('subno').valueChanges.pipe(
      startWith(''),
      map((value) => (typeof value === 'string' ? value : value.subno)),
      map((subno) => (subno ? this._filter2(subno) : this.sub_data.slice()))
    );

    this.filteredOptions4 = this.subform.get('repname').valueChanges.pipe(
      startWith(''),
      map((value) => (typeof value === 'string' ? value : value.name)),
      map((name) =>
        name ? this._filter4(name) : this.issue_seller_name.slice()
      )
    );

    this.filteredOptions5 = this.subform.get('duration').valueChanges.pipe(
      startWith(''),
      map((value) => (typeof value === 'string' ? value : value.subtype)),
      map((subtype) =>
        subtype ? this._filter5(subtype) : this.subscriptiontype.slice()
      )
      
    );
    console.log(this.filteredOptions5, "filteredOptions5")

    this.filteredOptions6 = this.subform.get('disrep').valueChanges.pipe(
      startWith(''),
      map((value) => (typeof value === 'string' ? value : value.name)),
      map((name) => (name ? this._filter6(name) : this.distributorList.slice()))
    );

    this.filteredOptions7 = this.subdetailsform.get('rep').valueChanges.pipe(
      startWith(''),
      map((value) => (typeof value === 'string' ? value : value.rep_name)),
      map((rep_name) =>
        rep_name ? this._filter7(rep_name) : this.sub_rep_data.slice()
      )
    );

    this.filteredOptions8 = this.subdetailsform
      .get('off_rep')
      .valueChanges.pipe(
        startWith(''),
        map((value) =>
          typeof value === 'string' ? value : value.office_rep_name
        ),
        map((office_rep_name) =>
          office_rep_name
            ? this._filter8(office_rep_name)
            : this.sub_off_rep_data.slice()
        )
      );

    this.filteredOptions9 = this.subdetailsform.get('v_no').valueChanges.pipe(
      startWith(''),
      map((value) =>
        typeof value === 'string' ? value : value.crdt_voucher_no
      ),
      map((crdt_voucher_no) =>
        crdt_voucher_no
          ? this._filter9(crdt_voucher_no)
          : this.sub_v_no_data.slice()
      )
    );

    this.filteredOptions10 = this.subdetailsform
      .get('rcpt_no')
      .valueChanges.pipe(
        startWith(''),
        map((value) => (typeof value === 'string' ? value : value.rcpt_no)),
        map((rcpt_no) =>
          rcpt_no ? this._filter10(rcpt_no) : this.sub_rcpt_no_data.slice()
        )
      );

    this.filteredOptions11 = this.subdetailsform
      .get('cust_id')
      .valueChanges.pipe(
        startWith(''),
        map((value) => (typeof value === 'string' ? value : value.subno)),
        map((subno) =>
          subno ? this._filter11(subno) : this.sub_sub_no_data.slice()
        )
      );

    this.filteredOptions12 = this.voucherform.get('rep').valueChanges.pipe(
      startWith(''),
      map((value) => (typeof value === 'string' ? value : value.rep_name)),
      map((rep_name) =>
        rep_name ? this._filter12(rep_name) : this.voucher_rep_data.slice()
      )
    );

    this.filteredOptions13 = this.voucherform
      .get('voucher_no')
      .valueChanges.pipe(
        startWith(''),
        map((value) => (typeof value === 'string' ? value : value.voucher_no)),
        map((voucher_no) =>
          voucher_no ? this._filter13(voucher_no) : this.voucher_no_data.slice()
        )
      );

    this.filteredOptions14 = this.returnrcptform
      .get('subname')
      .valueChanges.pipe(
        startWith(''),
        map((value) => (typeof value === 'string' ? value : value.name)),
        map((name) =>
          name ? this._filter14(name) : this.rtnrcpt_sub_name.slice()
        )
      );

    this.filteredOptions15 = this.returnrcptform.get('rep').valueChanges.pipe(
      startWith(''),
      map((value) => (typeof value === 'string' ? value : value.rep_name)),
      map((rep_name) =>
        rep_name ? this._filter15(rep_name) : this.rtnrcpt_rep_name.slice()
      )
    );

    this.filteredOptions16 = this.returnrcptform
      .get('off_rep')
      .valueChanges.pipe(
        startWith(''),
        map((value) =>
          typeof value === 'string' ? value : value.office_rep_name
        ),
        map((office_rep_name) =>
          office_rep_name
            ? this._filter16(office_rep_name)
            : this.rtnrcpt_off_rep_name.slice()
        )
      );

    this.filteredOptions17 = this.returnrcptform
      .get('district')
      .valueChanges.pipe(
        startWith(''),
        map((value) => (typeof value === 'string' ? value : value.district)),
        map((district) =>
          district ? this._filter17(district) : this.rtnrcpt_district.slice()
        )
      );

    this.filteredOptions18 = this.returnrcptform
      .get('taluka')
      .valueChanges.pipe(
        startWith(''),
        map((value) => (typeof value === 'string' ? value : value.taluka)),
        map((taluka) =>
          taluka ? this._filter18(taluka) : this.rtnrcpt_taluka.slice()
        )
      );

    this.filteredOptions19 = this.returnrcptform.get('state').valueChanges.pipe(
      startWith(''),
      map((value) => (typeof value === 'string' ? value : value.state)),
      map((state) =>
        state ? this._filter19(state) : this.rtnrcpt_state.slice()
      )
    );

    this.filteredOptions20 = this.returnrcptform
      .get('country')
      .valueChanges.pipe(
        startWith(''),
        map((value) => (typeof value === 'string' ? value : value.country)),
        map((country) =>
          country ? this._filter20(country) : this.rtnrcpt_country.slice()
        )
      );

    // this.filteredOptions21 = this.daterepForm.get('rep_name').valueChanges.pipe(
    //   startWith(''),
    //   map((value) => (typeof value === 'string' ? value : value.rep_name)),
    //   map((rep_name) =>
    //     rep_name ? this._filter21(rep_name) : this.daterepwiserep_name.slice()
    //   )
    // );

    this.filteredOptions22 = this.daterepForm
      .get('off_rep_name')
      .valueChanges.pipe(
        startWith(''),
        map((value) =>
          typeof value === 'string' ? value : value.office_rep_name
        ),
        map((office_rep_name) =>
          office_rep_name
            ? this._filter22(office_rep_name)
            : this.offrepwiseoff_rep_name.slice()
        )
      );

    this.filteredOptions23 = this.subledgerForm
      .get('sub_name')
      .valueChanges.pipe(
        startWith(''),
        map((value) => (typeof value === 'string' ? value : value.sub_name)),
        map((sub_name) =>
          sub_name
            ? this._filter23(sub_name)
            : this.subscribersledgernames.slice()
        )
      );

    this.filteredOptions24 = this.subledgerForm.get('sub_no').valueChanges.pipe(
      startWith(''),
      map((value) => (typeof value === 'string' ? value : value.sub_no)),
      map((sub_no) =>
        sub_no ? this._filter24(sub_no) : this.subscribersledgersub_no.slice()
      )
    );

    this.filteredOptions25 = this.subdetailsform
      .get('sub_name')
      .valueChanges.pipe(
        startWith(''),
        map((value) => (typeof value === 'string' ? value : value.name)),
        map((name) =>
          name ? this._filter25(name) : this.sub_sub_name_data.slice()
        )
      );

    this.filteredOptions26 = this.AssignDisRepForm.get(
      'disrep1'
    ).valueChanges.pipe(
      startWith(''),
      map((value) => (typeof value === 'string' ? value : value.name)),
      map((name) =>
        name ? this._filter26(name) : this.distributorList.slice()
      )
    );

    this.filteredOptions27 = this.AssignDisRepForm.get(
      'dismode'
    ).valueChanges.pipe(
      startWith(''),
      map((value) =>
        typeof value === 'string' ? value : value.sub_delivery_mode
      ),
      map((sub_delivery_mode) =>
        sub_delivery_mode
          ? this._filter27(sub_delivery_mode)
          : this.subDeliveryModeData.slice()
      )
    );

    this.filteredOptions28 = this.AssignDisRepForm.get(
      'dissubmode'
    ).valueChanges.pipe(
      startWith(''),
      map((value) =>
        typeof value === 'string' ? value : value.subdistributionmode
      ),
      map((subdistributionmode) =>
        subdistributionmode
          ? this._filter28(subdistributionmode)
          : this.FilteredsubDistributionModeData.slice()
      )
    );
  }

  calculatercptledger() {
    this.receiptledgertotalamt = 0;
    this.receiptledgerdiscounttotal = 0;
    for (
      let index = 0;
      index < Object.keys(this.Filetredreceiptledger).length;
      index++
    ) {
      this.receiptledgertotalamt =
        this.receiptledgertotalamt +
        parseInt(this.Filetredreceiptledger[index]['total_amt']);
      this.receiptledgerdiscounttotal =
        this.receiptledgerdiscounttotal +
        parseInt(this.Filetredreceiptledger[index]['discount']);
    }
  }

  getsubno() {
    // this.service.getSubNames().subscribe((res) => {
    //   console.log(res);
    //   this.sub_data = res;
    // });
    this.service.getSubNoAndMobileNo().subscribe((data) => {
      // this.sub_data = data;
      // console.log('subdata', data);
      debugger
      if (data['id'] == 0) {
        this.subform.get('subno').setValue(1);
      } else {
        this.subform.get('subno').setValue(parseInt(data['id']) + 1);
      }
    });
  }

  getlatestRcptNo(){
    
    this.service.getSubscriberlatestRcptNo(formatDate(this.month, 'yyyy-MM-dd', 'en-IN', 'GMT+0530'), this.subform.value.rep_receipt
     )
    .subscribe((data) => {
    this.checklatestdata = data;
    console.log(data)
    if(this.checklatestdata.check=='TRUE'){
      this.getRcptNo1()
    }else{
      this.addsub()
      debugger
    }
   
    console.log(this.checklatestdata, "checklatestdata")
    });
  }

  getRcptNo1() {
    
    // this.month=new Date(this.subform.value.crdtdate)
    this.service.getSubscriberRcptNo(
        formatDate(this.month, 'yyyy-MM-dd', 'en-IN', 'GMT+0530')
      )
      .subscribe((data) => {
        // console.log(data);
        let value = data[0]['rcpt_no'];
        // console.log(value);
        if (Object.keys(data).length > 0) {
          // console.log('1', data[0]['invoice_no'].split('/'));
          value = parseInt(data[0]['rcpt_no'].split('/')[2]);
          if (isNaN(value)) {
            value = 0;
          }
        } else {
          value = 0;
        }

        let months = ('0' + (this.month.getMonth() + 1)).slice(-2);
        let year = this.month.getFullYear().toString().slice(-2);
        let nextyear = (this.month.getFullYear() + 1).toString().slice(-2);
        if (isNaN(value) || value == null) {
          // console.log('if work');
          value = 1;
          let receiptNo = `${this.receipttype}${months}/${year}-${nextyear}/${value}`;
          console.log(receiptNo, "receipt number")
          this.subform.get('rep_receipt').setValue(receiptNo);
        } else {
          // console.log('else work');
          value = parseInt(value) + 1;
          let receiptNo = `${this.receipttype}${months}/${year}-${nextyear}/${value}`;
          this.subform.get('rep_receipt').setValue(receiptNo);
        }

        if (
          formatDate(this.month, 'yyyy-MM-dd', 'en-IN', 'GMT+0530') >=
          formatDate(
            new Date(new Date().getFullYear(), new Date('4').getMonth(), 1),
            'yyyy-MM-dd',
            'en-IN',
            'GMT+0530'
          )
        ) {
          // let receiptNo = `${this.receipttype}${months}/${year}-${nextyear}/${value <= 8 ? '0' + (value + 1) : value + 1
          //   }`;
          // this.subform.get('rep_receipt').setValue(receiptNo);
        // } else {
        //   let receiptNo = `${this.receipttype}${months}/${parseInt(year) - 1
        //     }-${year}/${value <= 8 ? '0' + (value + 1) : value + 1}`;
        //   this.subform.get('rep_receipt').setValue(receiptNo);
        }

        this.subform.get('month').setValue(months);
        this.subform.get('year').setValue(this.month.getFullYear());
        this.addsub()
        debugger
      });
  }


  getRcptNo() {
    debugger;
    
    // this.month=new Date(this.subform.value.crdtdate)
    this.service.getSubscriberRcptNo(
        formatDate(this.month, 'yyyy-MM-dd', 'en-IN', 'GMT+0530')
      )
      .subscribe((data) => {
        // console.log(data);
        let value;
        // let value = data[0]['rcpt_no'];
        // console.log(value);
        if (Object.keys(data).length > 0) {
          
          // console.log('1', data[0]['invoice_no'].split('/'));
          value = parseInt(data[0]['rcpt_no'].split('/')[2]);
          if (isNaN(value)) {
            value = 0;
          }
        } else {
          value = 0;
        }

        let months = ('0' + (this.month.getMonth() + 1)).slice(-2);
        let year = this.month.getFullYear().toString().slice(-2);
        let nextyear = (this.month.getFullYear() + 1).toString().slice(-2);
        if (isNaN(value) || value == null) {
          // console.log('if work');
          value = 1;
          let receiptNo = `${this.receipttype}${months}/${year}-${nextyear}/${value}`;
          console.log(receiptNo, "receipt number")
          this.subform.get('rep_receipt').setValue(receiptNo);
        } else {
          // console.log('else work');
          value = parseInt(value) + 1;
          let receiptNo = `${this.receipttype}${months}/${year}-${nextyear}/${value}`;
          this.subform.get('rep_receipt').setValue(receiptNo);
        }

        if (
          formatDate(this.month, 'yyyy-MM-dd', 'en-IN', 'GMT+0530') >=
          formatDate(
            new Date(new Date().getFullYear(), new Date('4').getMonth(), 1),
            'yyyy-MM-dd',
            'en-IN',
            'GMT+0530'
          )
        ) {
          // let receiptNo = `${this.receipttype}${months}/${year}-${nextyear}/${value <= 8 ? '0' + (value + 1) : value + 1
          //   }`;
          // this.subform.get('rep_receipt').setValue(receiptNo);
        // } else {
        //   let receiptNo = `${this.receipttype}${months}/${parseInt(year) - 1
        //     }-${year}/${value <= 8 ? '0' + (value + 1) : value + 1}`;
        //   this.subform.get('rep_receipt').setValue(receiptNo);
        }

        this.subform.get('month').setValue(months);
        this.subform.get('year').setValue(this.month.getFullYear());
      });
  }

  rcptldgrstartdate;
  rcptldgrenddate;
  getChangedDatercptldger() {
    if (
      this.rcptldgrForm.get('start').value != null &&
      this.rcptldgrForm.get('end').value != null
    ) {
      this.rcptldgrstartdate = formatDate(
        new Date(this.rcptldgrForm.get('start').value),
        'yyyy-MM-dd',
        'en-IN',
        'GMT+0530'
      );
      this.rcptldgrenddate = formatDate(
        new Date(this.rcptldgrForm.get('end').value),
        'yyyy-MM-dd',
        'en-IN',
        'GMT+0530'
      );

     

      // this.Filetredreceiptledger = this.receiptledger.filter((a) => {
      //   return (
      //     new Date(a['entry_date']) >= new Date(this.rcptldgrstartdate) &&
      //     new Date(a['entry_date']) <= new Date(this.rcptldgrenddate)
      //   );
      // });
      this.calculatercptledger();
    }
  }

  rcpt_no1 = '';
  rcptno(_event) {
    // console.log(event);
    if (this.rcpt_no1 != '') {
      this.Filetredreceiptledger = this.receiptledger.filter((a) => {
        return (
          a['rcpt_no'].toLowerCase().indexOf(this.rcpt_no1.toLowerCase()) > -1
        );
      });
      this.calculatercptledger();
    }
  }

  reset_rcptno() {
    this.rcpt_no1 = '';
    if (
      this.rcptldgrstartdate != null &&
      this.rcptldgrenddate != null &&
      this.rcpt_no1 == ''
    ) {
      this.Filetredreceiptledger = this.receiptledger.filter((a) => {
        return (
          new Date(a['entry_date']) >= new Date(this.rcptldgrstartdate) &&
          new Date(a['entry_date']) <= new Date(this.rcptldgrenddate)
        );
      });
      this.calculatercptledger();
    } else {
      this.Filetredreceiptledger = this.receiptledger;
      this.calculatercptledger();
    }
  }

  cust_name = '';
  custname(event) {
    
    this.cust_name = event.target.value
    // console.log(event);
    if (this.cust_name != '') {
      this.Filetredreceiptledger = this.receiptledger.filter((a) => {
        return (
          a['name'].toLowerCase().indexOf(this.cust_name.toLowerCase()) > -1
        );
      });
      this.calculatercptledger();
    }
  }

  reset_custname() {
    this.cust_name = '';
    if (
      this.rcptldgrstartdate != null &&
      this.rcptldgrenddate != null &&
      this.cust_name == ''
    ) {
      this.Filetredreceiptledger = this.receiptledger.filter((a) => {
        return (
          new Date(a['entry_date']) >= new Date(this.rcptldgrstartdate) &&
          new Date(a['entry_date']) <= new Date(this.rcptldgrenddate)
        );
      });
      this.calculatercptledger();
    } else {
      this.Filetredreceiptledger = this.receiptledger;
      this.calculatercptledger();
    }
  }

  pndgvuchrstartdate;
  pndgvuchrenddate;
  getChangedDatependingvoucher() {
    if (
      this.pendingvoucherForm.get('start').value != null &&
      this.pendingvoucherForm.get('end').value != null
    ) {
      this.pndgvuchrstartdate = formatDate(
        new Date(this.pendingvoucherForm.get('start').value),
        'yyyy-MM-dd',
        'en-IN',
        'GMT+0530'
      );
      this.pndgvuchrenddate = formatDate(
        new Date(this.pendingvoucherForm.get('end').value),
        'yyyy-MM-dd',
        'en-IN',
        'GMT+0530'
      );

      this.FilerterdgetallVouchersData = this.getallVouchersData.filter((a) => {
        return (
          new Date(a['voucher_date']) >= new Date(this.pndgvuchrstartdate) &&
          new Date(a['voucher_date']) <= new Date(this.pndgvuchrenddate)
        );
      });
      console.log(this.FilerterdgetallVouchersData, "pending voucher data")
      this.calculatevoucher();
    }
  }

  rep1 = '';
  get_rep1(event, option) {
    // console.log(event);
    if (event.isUserInput) {
      this.rep1 = option['rep_name'];
      if (
        this.pndgvuchrstartdate != null &&
        this.pndgvuchrenddate != null &&
        this.rep1 != ''
      ) {
        this.FilerterdgetallVouchersData = this.getallVouchersData.filter(
          (a) => {
            return (
              new Date(a['voucher_date']) >=
              new Date(this.pndgvuchrstartdate) &&
              new Date(a['voucher_date']) <= new Date(this.pndgvuchrenddate) &&
              a['rep_name'].toLowerCase().indexOf(this.rep1.toLowerCase()) > -1
            );
          }
        );
        this.calculatevoucher();
      } else {
        this.FilerterdgetallVouchersData = this.getallVouchersData.filter(
          (a) => {
            return (
              a['rep_name'].toLowerCase().indexOf(this.rep1.toLowerCase()) > -1
            );
          }
        );

        console.log(this.FilerterdgetallVouchersData)
        this.calculatevoucher();
      }
    }
  }

  reset_rep1() {
    this.rep1 = '';
    this.voucherform.get('rep').reset();
    if (
      this.pndgvuchrstartdate != null &&
      this.pndgvuchrenddate != null &&
      this.rep1 == ''
    ) {
      this.FilerterdgetallVouchersData = this.getallVouchersData.filter((a) => {
        return (
          new Date(a['voucher_date']) >= new Date(this.pndgvuchrstartdate) &&
          new Date(a['voucher_date']) <= new Date(this.pndgvuchrenddate)
        );
      });
      this.calculatevoucher();
    } else {
      this.FilerterdgetallVouchersData = this.getallVouchersData;
      this.calculatevoucher();

      console.log(this.FilerterdgetallVouchersData)
    }
  }

  isrcptno = false;
  isreceiptactive(event) {
    // console.log(event.target.value);
    if (event.target.value == 'N') {
      // console.log('if works');
      this.isrcptno = true;
      this.subform
        .get('rcptstopdate')
        .setValue(formatDate(new Date(), 'yyyy-MM-dd', 'en-IN', 'GMT+0530'));
    }
  }

  stopdate = '';
  startdate = '';
  activeReceipt(book) {
    // console.log(book);

    if (window.confirm('Do you want active recipt')) {
      let val = window.prompt('Enter the start remark here');
      // console.log(new Date(book['rcptstopdate']));
      let stopdate = new Date(book['rcptstopdate']);
      let startdate = new Date();
      // console.log(book['sub_perid_to_date']);
      let sub_perid_to_date = new Date(book['sub_perid_to_date']);
      if (
        formatDate(stopdate, 'yyyy-MM-dd', 'en-IN', 'GMT+0530') != '0000-00-00'
      ) {
        let days = Math.floor(
          (Date.UTC(
            startdate.getFullYear(),
            startdate.getMonth(),
            startdate.getDate()
          ) -
            Date.UTC(
              stopdate.getFullYear(),
              stopdate.getMonth(),
              stopdate.getDate()
            )) /
          (1000 * 60 * 60 * 24)
        );
        // console.log(days);

        if (days != 0) {
          // console.log(sub_perid_to_date);
          sub_perid_to_date.setDate(sub_perid_to_date.getDate() + days);

          this.service
            .updateActive(
              book['sub_id'],
              formatDate(startdate, 'yyyy-MM-dd', 'en-IN', 'GMT+0530'),
              formatDate(sub_perid_to_date, 'yyyy-MM-dd', 'en-IN', 'GMT+0530'),
              val,
              days
            )
            .subscribe((_data) => {
              window.alert('Receipt is activated successfully..');
            });
        }
      }
    }

    // console.log(stopdate, startdate, days);
    // console.log(
    //   book['sub_perid_to_date'],
    //   formatDate(new Date(sub_perid_to_date), 'yyyy-MM-dd', 'en-IN', 'GMT+0530')
    // );
  }

  deletesubscriberrcpt(item){
    let data = {
      subTypeId: item.id,
    };

    if (window.confirm('Do you want to Delete receipt')) {
  
      this.service.deletesubscriberrcpt(data).subscribe((res) => {
          console.log(res);
          alert('Subscriber receipt deleted successsfully...');
          this.subrcptId = 0;
          this.ngOnInit();
        });
    }
  }


  inactiveReceipt1(book){
    
    
    let id = book.sub_id;
    console.log(id, "inactive id")

    if(book.is_active ==='N'){
      this.checkstatus = 'active'
    }
    else{
      this.checkstatus = 'inactive'
    }
    this.service.inactivesub(id, this.checkstatus).subscribe((a) => {
      // console.log(a);
       if(a.code =='00'){
        alert(a.message+'Success');
       }else{
        alert(a.message+'Error');
       }
      
    });
    this.ngOnInit()
  }

  inactiveReceipt(book){
    
    // let id = book.sub_id;
    // console.log(id, "inactive id")
    // this.service.inactivesub(id).subscribe((a) => {
    //   // console.log(a);
    //    if(a.code =='00'){
    //     alert(a.message+'Success');
    //    }else{
    //     alert(a.message+'Error');
    //    }
      
    // });
  }

  returnReceipt(book) {
    const dialogdata = this.dialog.open(ReturnReceiptDialogComponent, {
      data: {
        bookdata: book,
      },
      width: '500px',
      height: '600px',
      maxWidth: '100%',
    });

    dialogdata.afterClosed().subscribe((result) => {
      if (result['data'] == 'close') {
        location.reload();
        // console.log('The dialog was closed');
      }
    });
  }

  voucher_no1 = '';
  get_voucher_no(event, option) {
    // console.log(event);
    if (event.isUserInput) {
      this.voucher_no1 = option['voucher_no'];
      if (
        this.pndgvuchrstartdate != null &&
        this.pndgvuchrenddate != null &&
        this.voucher_no1 != ''
      ) {
        this.FilerterdgetallVouchersData = this.getallVouchersData.filter(
          (a) => {
            return (
              new Date(a['voucher_date']) >=
              new Date(this.pndgvuchrstartdate) &&
              new Date(a['voucher_date']) <= new Date(this.pndgvuchrenddate) &&
              a['voucher_no']
                .toLowerCase()
                .indexOf(this.voucher_no1.toLowerCase()) > -1
            );
          }
        );
        this.calculatevoucher();
      } else {
        this.FilerterdgetallVouchersData = this.getallVouchersData.filter(
          (a) => {
            return (
              a['voucher_no']
                .toLowerCase()
                .indexOf(this.voucher_no1.toLowerCase()) > -1
            );
          }
        );
        this.calculatevoucher();
        console.log(this.FilerterdgetallVouchersData)
      }
    }
  }

  reset_voucher_no() {
    this.voucher_no1 = '';
    this.voucherform.get('voucher_no').reset();
    if (
      this.pndgvuchrstartdate != null &&
      this.pndgvuchrenddate != null &&
      this.voucher_no1 == ''
    ) {
      this.FilerterdgetallVouchersData = this.getallVouchersData.filter((a) => {
        return (
          new Date(a['voucher_date']) >= new Date(this.pndgvuchrstartdate) &&
          new Date(a['voucher_date']) <= new Date(this.pndgvuchrenddate)
        );
      });
      this.calculatevoucher();
    } else {
      this.FilerterdgetallVouchersData = this.getallVouchersData;
      this.calculatevoucher();
    }
  }

  total = 0;
  balamt = 0;
  totalamtinword: string;
  durationn(event, option) {
    
    if (event.isUserInput) {
      // console.log('option', option);
      // this.subform.get('duration').setValue(event.target.value);
      this.duration = option['subtype'];
      this.subform.get('subtype').setValue(this.duration);
      for (
        let index = 0;
        index < Object.keys(this.subscriptiontype).length;
        index++
      ) {
        if (this.duration == this.subscriptiontype[index]['subtype']) {
          let crdtamt = this.subform.get('crdtamt').value;
          this.total = parseInt(this.subscriptiontype[index]['price']);
          this.balamt = crdtamt - this.subscriptiontype[index]['price'];
          this.subform.get('balamt').setValue(this.balamt);
          this.subform
            .get('seamt')
            .setValue(parseInt(this.subscriptiontype[index]['price']));
          this.toWords = new ToWords();
          this.totalamtinword = this.toWords.convert(this.total, {
            currency: true,
          });
        }
      }
    }
  }

  calcu;

  distribution(event, option) {
    if (event.isUserInput) {
      let val = option['name'];
    }
  }

  update(item) {
    this.dialog.open(UpdateOfficeRepresentativeComponent, {
      data: {
        item: item,
        type: 'sub',
      },
    });
    setTimeout(() => {
      this.service.getSubscriptionWiseRep().subscribe((data) => {
        this.subscriberwisereplist1 = data;
      });
    }, 5000);
  }

  netamt = 0;
  discountcal(event) {
    let val = event.target.value;
    // console.log('discountcal', event.target.value);

    this.netamt = (this.total * val) / 100;
    this.subform.get('netamount').setValue(this.netamt);
  }

  repcomamt = 0;
  repcommission(event) {
    let val = event.target.value;
    this.repcomamt = (this.total * val) / 100;
    this.subform.get('repcomprice').setValue(this.repcomamt);
  }

  private _filter(mobno: string): any[] {
    const filterValue = mobno;

    return this.sub_data.filter((option) =>
      // option.mobno.toLowerCase().includes(filterValue)
      option.mobno.toLowerCase().includes(filterValue)
    );
  }

  private _filter1(subname: string): any[] {
    const filterValue = subname;

    return this.sub_data.filter((option) =>
      // option.mobno.toLowerCase().includes(filterValue)
      option.subname.toLowerCase().includes(filterValue)
    );
  }

  private _filter2(subno: string): any[] {
    const filterValue = subno;

    return this.sub_data.filter((option) =>
      // option.mobno.toLowerCase().includes(filterValue)
      option.subno.toLowerCase().includes(filterValue)
    );
  }

  private _filter3(name: string): any[] {
    const filterValue = name;
    return this.issue_seller_name.filter((option) =>
      // option.mobno.toLowerCase().includes(filterValue)
      option.name.toLowerCase().includes(filterValue)
    );
  }

  private _filter4(name: string): any[] {
    const filterValue = name;
    return this.issue_seller_name.filter((option) =>
      // option.mobno.toLowerCase().includes(filterValue)
      option.name.toLowerCase().includes(filterValue)
    );
  }

  private _filter5(subtype: string): any[] {
    const filterValue = subtype;
    return this.subscriptiontype.filter((option) =>
      // option.mobno.toLowerCase().includes(filterValue)
      option.subtype.toLowerCase().includes(filterValue)
    );
  }

  private _filter6(name: string): any[] {
    const filterValue = name;
    return this.distributorList.filter((option) =>
      // option.mobno.toLowerCase().includes(filterValue)
      option.name.toLowerCase().includes(filterValue)
    );
  }

  private _filter7(rep_name: string): any[] {
    const filterValue = rep_name;

    return this.sub_rep_data.filter((option) =>
      // option.mobno.toLowerCase().includes(filterValue)
      option.rep_name.toLowerCase().includes(filterValue)
    );
  }

  private _filter8(office_rep_name: string): any[] {
    const filterValue = office_rep_name;

    return this.sub_off_rep_data.filter((option) =>
      // option.mobno.toLowerCase().includes(filterValue)
      option.office_rep_name.toLowerCase().includes(filterValue)
    );
  }

  private _filter9(crdt_voucher_no: string): any[] {
    const filterValue = crdt_voucher_no;

    return this.sub_v_no_data.filter((option) =>
      // option.mobno.toLowerCase().includes(filterValue)
      option.crdt_voucher_no.toLowerCase().includes(filterValue)
    );
  }

  private _filter10(rcpt_no: string): any[] {
    const filterValue = rcpt_no;

    return this.sub_rcpt_no_data.filter((option) =>
      // option.mobno.toLowerCase().includes(filterValue)
      option.rcpt_no.toLowerCase().includes(filterValue)
    );
  }

  private _filter11(subno: string): any[] {
    const filterValue = subno;

    return this.sub_sub_no_data.filter((option) =>
      // option.mobno.toLowerCase().includes(filterValue)
      option.subno.toLowerCase().includes(filterValue)
    );
  }

  private _filter12(rep_name: string): any[] {
    const filterValue = rep_name;

    return this.voucher_rep_data.filter((option) =>
      // option.mobno.toLowerCase().includes(filterValue)
      option.rep_name.toLowerCase().includes(filterValue)
    );
  }

  private _filter13(voucher_no: string): any[] {
    const filterValue = voucher_no;

    return this.voucher_no_data.filter((option) =>
      // option.mobno.toLowerCase().includes(filterValue)
      option.voucher_no.toLowerCase().includes(filterValue)
    );
  }

  private _filter14(name: string): any[] {
    const filterValue = name;

    return this.rtnrcpt_sub_name.filter((option) =>
      // option.mobno.toLowerCase().includes(filterValue)
      option.name.toLowerCase().includes(filterValue)
    );
  }

  private _filter15(rep_name: string): any[] {
    const filterValue = rep_name;

    return this.rtnrcpt_rep_name.filter((option) =>
      // option.mobno.toLowerCase().includes(filterValue)
      option.rep_name.toLowerCase().includes(filterValue)
    );
  }

  private _filter16(office_rep_name: string): any[] {
    const filterValue = office_rep_name;

    return this.rtnrcpt_off_rep_name.filter((option) =>
      // option.mobno.toLowerCase().includes(filterValue)
      option.office_rep_name.toLowerCase().includes(filterValue)
    );
  }

  private _filter17(district: string): any[] {
    const filterValue = district;

    return this.rtnrcpt_district.filter((option) =>
      // option.mobno.toLowerCase().includes(filterValue)
      option.district.toLowerCase().includes(filterValue)
    );
  }

  private _filter18(taluka: string): any[] {
    const filterValue = taluka;

    return this.rtnrcpt_taluka.filter((option) =>
      // option.mobno.toLowerCase().includes(filterValue)
      option.taluka.toLowerCase().includes(filterValue)
    );
  }

  private _filter19(state: string): any[] {
    const filterValue = state;

    return this.rtnrcpt_state.filter((option) =>
      // option.mobno.toLowerCase().includes(filterValue)
      option.state.toLowerCase().includes(filterValue)
    );
  }

  private _filter20(country: string): any[] {
    const filterValue = country;

    return this.rtnrcpt_country.filter((option) =>
      // option.mobno.toLowerCase().includes(filterValue)
      option.country.toLowerCase().includes(filterValue)
    );
  }

  // private _filter21(rep_name: string): any[] {
  //   const filterValue = rep_name;

  //   return this.daterepwiserep_name.filter((option) =>
  //     // option.mobno.toLowerCase().includes(filterValue)
  //     option.rep_name.toLowerCase().includes(filterValue)
  //   );
  // }

  private _filter22(office_rep_name: string): any[] {
    const filterValue = office_rep_name;

    return this.offrepwiseoff_rep_name.filter((option) =>
      // option.mobno.toLowerCase().includes(filterValue)
      option.office_rep_name.toLowerCase().includes(filterValue)
    );
  }

  private _filter23(sub_name: string): any[] {
    const filterValue = sub_name;

    return this.subscribersledgernames.filter((option) =>
      // option.mobno.toLowerCase().includes(filterValue)
      option.sub_name.toLowerCase().includes(filterValue)
    );
  }

  private _filter24(sub_no: string): any[] {
    const filterValue = sub_no;

    return this.subscribersledgersub_no.filter((option) =>
      // option.mobno.toLowerCase().includes(filterValue)
      option.sub_no.toLowerCase().includes(filterValue)
    );
  }

  private _filter25(name: string): any[] {
    const filterValue = name;

    return this.sub_sub_name_data.filter((option) =>
      // option.mobno.toLowerCase().includes(filterValue)
      option.name.toLowerCase().includes(filterValue)
    );
  }

  private _filter26(name: string): any[] {
    const filterValue = name;
    return this.distributorList.filter((option) =>
      // option.mobno.toLowerCase().includes(filterValue)
      option.name.toLowerCase().includes(filterValue)
    );
  }

  private _filter27(sub_delivery_mode: string): any[] {
    const filterValue = sub_delivery_mode;
    return this.subDeliveryModeData.filter((option) =>
      // option.mobno.toLowerCase().includes(filterValue)
      option.sub_delivery_mode.toLowerCase().includes(filterValue)
    );
  }

  private _filter28(subdistributionmode: string): any[] {
    const filterValue = subdistributionmode;
    return this.FilteredsubDistributionModeData.filter((option) =>
      // option.mobno.toLowerCase().includes(filterValue)
      option.subdistributionmode.toLowerCase().includes(filterValue)
    );
  }

  getSubNames(event) {
    // console.log(event.target.value);
    if (event.target.value) {
      this.service.getSubNames(event.target.value).subscribe((res) => {
        // console.log(res);
        this.sub_data = res;
      });
    }
  }

  getRepNames(event) {
    if (event.target.value) {
      this.service.getRepNames(event.target.value).subscribe((res) => {
        // console.log(res);
        this.rep_name_data = res;
      });
    }
  }

  getSubNo(event) {
    if (event.target.value) {
      this.service.getSubNos(event.target.value).subscribe((res) => {
        // console.log(res);
        this.sub_no = res;
      });
    }
  }

  getSubMobileNo(event) {
    if (event.target.value.length >= 2) {
      this.service.getSubMobNos(event.target.value).subscribe((res) => {
        // console.log(res);
        this.sub_mob_no = res;
      });
    }
  }

  selectedname: boolean = false;
  selectedrcpt: boolean = false;
  subId;
  subrcptId;
  subrcptVoucherNo;
  subrcptEntryDate;
  subrcptSelectAmt;
  selected_name(event, id) {
    
    if(this.subscription_all_data === true){
      if (event.isUserInput) {
        this.service.getSubscriberData(id.id).subscribe((data) => {
          debugger;
  
          this.subrcptEntryDate = data[0]['entrydate'];
          
          // this.subrcptEntryDate = data[0]['entrydate'].substring(0, 10);
          // this.subrcptEntryDate = new Date().toISOString().substring(0, 10);
          console.log(this.subrcptEntryDate, "filter date")
          this.subform.get('entrydate').setValue(this.subrcptEntryDate)

          this.selectedname = true;
          this.subId = id.id;
          this.getCountry({ value: data[0]['country'] });
          if (data[0]['is_complimentary'] == 'Y') {
            this.subform
              .get('iscomplimentary')
              .setValue(data[0]['is_complimentary']);
            this.iscomplementary = true;
          } else if (data[0]['is_complimentary'] === 'N') {
            this.iscomplementary = false;
          }
          setTimeout(() => {
            this.getState({ value: data[0]['state'] });
          }, 500);
          setTimeout(() => {
            this.getDistricts({ value: data[0]['district'].toString().trim() });
          }, 1500);
          
          this.subform.get('subtype').setValue(data[0]['subtype']);
          this.subform.get('month').setValue(data[0]['month']);
          this.subform.get('year').setValue(data[0]['year']);
          this.subform.get('subno').setValue(data[0]['subno']);
          this.subform.get('title').setValue(data[0]['title']);
          this.subform.get('name').setValue(data[0]['name']);
          this.subform.get('e_name').setValue(data[0]['e_name']);
          this.subform.get('address').setValue(data[0]['address']);
          this.subform.get('pincode').setValue(data[0]['pincode']);
          this.subform.get('country').setValue(data[0]['country']);
          this.subform.get('state').setValue(data[0]['state']);
          this.subform.get('e_district').setValue(data[0]['e_district']);
          // this.subform.get('entrydate').setValue(data[0]['entrydate']);
          // this.subform.get('voucherid').setValue('vid');
          // this.selectedState = data[0]['state'];
          // this.selectedCity = data[0]['district'];
          setTimeout(() => {
            let dist = data[0]['district'] + '';
            let taluka = data[0]['taluka'] + '';
            this.subform.get('district').setValue(dist.trim());
            this.subform.get('taluka').setValue(taluka.trim());
          }, 1500);
          this.subform
            .get('mobileNumber')
            .setValue(
              data[0]['mobileNumber'] === '0' ? '' : data[0]['mobileNumber']
            );
          this.subform.get('telephone').setValue(data[0]['telephone']);
          this.subform.get('email').setValue(data[0]['email']);
          this.subform.get('dob').setValue(data[0]['dob']);
          this.subform.get('doa').setValue(data[0]['date_of_marriage']);
          this.subform.get('voucherid').setValue(data[0]['vid']);
  
          if (this.pendingbookdata == undefined) {
            this.subform.get('repname').setValue(data[0]['repname']);
            this.subform
              .get('repmobno')
              .setValue(data[0]['repmobno'] === '0' ? '' : data[0]['repname']);
          }
          this.subform.get('gst_no').setValue(data[0]['gst_no']);
          this.subform.get('remark').setValue(data[0]['remark']);
          this.service.getSubscriberEntryDate(id.id).subscribe((_data) => {
            // console.log(data);
            // this.subform
            //   .get('subyear')
            //   .setValue(
            //     formatDate(
            //       data[0]['entry_date'],
            //       'yyyy-MM-dd',
            //       'en-IN',
            //       'GMT+0530'
            //     )
            //   );
          });
        });
        // this.subform.reset()
        
        //get data of sub details
        this.service.getSubDetailsData(id.id).subscribe((data) => {
  
          console.log(data, "new data");
          if (Object.keys(data).length != 0) {
            this.selectedrcpt = true;
            this.subrcptId = data[0]['id'];
            // if (this.pendingbookdata == undefined) {
              this.subform.get('month').setValue(data[0]['month']);
              this.subform.get('year').setValue(data[0]['year']);
              this.subform.get('crepname').setValue(data[0]['rep_name']);
              this.subform.get('repmobno1').setValue(data[0]['rep_mob_no'] ? data[0]['rep_mob_no'] : null);
              this.subform.get('officerep').setValue(data[0]['office_rep_name']);
              this.subform.get('crdtdate').setValue(data[0]['crdt_voucher_date']);
              this.subform.get('crdtvno').setValue(data[0]['crdt_voucher_no']);
              this.subrcptVoucherNo = data[0]['crdt_voucher_no'];
              this.subform.get('rep_receipt').setValue(data[0]['rcpt_no']);
              this.subform.get('crdtamt').setValue(data[0]['crdt_voucher_amt']);
              this.subform.get('seamt').setValue(data[0]['select_entry_amt']);
              this.subrcptSelectAmt = parseFloat(data[0]['select_entry_amt']);
              this.subform.get('balamt').setValue(data[0]['balance_amt']);
              this.subform.get('voucherid').setValue(data[0]['vid']);
              this.subform.get('repname').setValue(data[0]['rep_name']);
              this.subform.get('repmobno').setValue(data[0]['rep_mob_no'] === '0' ? null : data[0]['rep_mob_no']);
            
              debugger;
              // this.subform.get('entrydate').setValue(data[0]['created_on'])
              // this.subrcptEntryDate = data[0]['entry_date']
              // console.log(this.subrcptEntryDate, "filter date")
              // this.subform.get('entrydate').setValue(this.subrcptEntryDate)

              
  
            // } else {
            //   this.subform.get('repname').setValue(data[0]['rep_name']);
            //   this.subform.get('repmobno').setValue(data[0]['rep_mob_no']);
            // }
            console.log()
             
            this.subform.get('magnames').setValue(data[0]['magzine_name']);
            this.subform.get('magedition').setValue(data[0]['magzine_edition']);
            this.subform.get('duration').setValue(data[0]['subscription_type']);
            this.duration = data[0]['subscription_type'];
            this.subform
              .get('subyear')
              .setValue(
                formatDate(
                  data[0]['subscription_year'],
                  'yyyy-MM-dd',
                  'en-IN',
                  'GMT+0530'
                )
              );
            this.subform
              .get('subfromdate')
              .setValue(data[0]['sub_perid_from_date']);
              debugger;
            this.subform.get('subtodate').setValue(data[0]['sub_perid_to_date']);
            this.subform.get('distype').setValue(data[0]['delivery_mode']);
            this.subform.get('disrep').setValue(data[0]['distribution_rep']);
            this.subform.get('trecptno').setValue(data[0]['temp_rcpt_no']);
            this.subform.get('rdate').setValue(data[0]['temp_rcpt_date']);
            this.total = data[0]['total_amt'];
            this.subform.get('discount').setValue(data[0]['discount']);
            this.subform.get('netamount').setValue(data[0]['net_amt']);
            this.subform
              .get('repcompercent')
              .setValue(data[0]['rep_com_percent']);
            this.subform.get('repcomprice').setValue(data[0]['rep_com_amt']);
            // this.subform.get('cdno').setValue(data[0]['cheque_draft_no']);
            // this.subform.get('chequeDate').setValue(data[0]['cheque_date']);
            // this.subform.get('bankname').setValue(data[0]['bank_name']);
            this.subform.get('subremark').setValue(data[0]['remark']);
            this.subform.get('subremark1').setValue(data[0]['remark1']);
            this.subform.get('subremark2').setValue(data[0]['remark2']);
            this.subform
              .get('emp_mob_number')
              .setValue(
                data[0]['work_by'] === '0'
                  ? parseInt(sessionStorage.getItem('mobile'))
                  : data[0]['work_by']
              );
            this.subform.get('isactive').setValue(data[0]['is_active']);
            // if (data[0]['is_active'] == 'N') {
            this.isrcptno = true;
            // }
            this.subform.get('rcptstopdate').setValue(data[0]['rcptstopdate']);
            this.subform
              .get('rcptstopremark')
              .setValue(data[0]['rcptstopremark']);
            this.subform.get('days').setValue(data[0]['days']);
            this.subform.get('rcptstartdate').setValue(data[0]['rcptstartdate']);
            this.subform
              .get('rcptstartremark')
              .setValue(data[0]['rcptstartremark']);
          } else {
            if (this.pendingbookdata != undefined) {
              this.subform.get('repname').setValue(data[0]['repname']);
              this.subform.get('repmobno').setValue(data[0]['repmobno']);
            }
          }
        });
        this.enableDisableRule('first');
      }
    }
    else{
      if (event.isUserInput) {
        this.service.getSubscriberData(id.id).subscribe((data) => {
  
          // console.log('pending voucher', this.pendingbookdata);
          // this.datavale = data
          // console.log(data, "new data");
          this.selectedname = true;
          this.subId = id.id;
          this.getCountry({ value: data[0]['country'] });
          if (data[0]['is_complimentary'] == 'Y') {
            this.subform
              .get('iscomplimentary')
              .setValue(data[0]['is_complimentary']);
            this.iscomplementary = true;
          } else if (data[0]['is_complimentary'] === 'N') {
            this.iscomplementary = false;
          }
          setTimeout(() => {
            this.getState({ value: data[0]['state'] });
          }, 500);
          setTimeout(() => {
            this.getDistricts({ value: data[0]['district'].toString().trim() });
          }, 1500);
          this.subform.get('subtype').setValue(data[0]['subtype']);
          this.subform.get('month').setValue(data[0]['month']);
          this.subform.get('year').setValue(data[0]['year']);
          this.subform.get('subno').setValue(data[0]['subno']);
          this.subform.get('title').setValue(data[0]['title']);
          this.subform.get('name').setValue(data[0]['name']);
          this.subform.get('e_name').setValue(data[0]['e_name']);
          this.subform.get('address').setValue(data[0]['address']);
          this.subform.get('pincode').setValue(data[0]['pincode']);
          this.subform.get('country').setValue(data[0]['country']);
          this.subform.get('state').setValue(data[0]['state']);
          this.subform.get('e_district').setValue(data[0]['e_district']);
          // this.subform.get('voucherid').setValue('vid');
          // this.selectedState = data[0]['state'];
          // this.selectedCity = data[0]['district'];
          setTimeout(() => {
            let dist = data[0]['district'] + '';
            let taluka = data[0]['taluka'] + '';
            this.subform.get('district').setValue(dist.trim());
            this.subform.get('taluka').setValue(taluka.trim());
          }, 1500);
          this.subform
            .get('mobileNumber')
            .setValue(
              data[0]['mobileNumber'] === '0' ? '' : data[0]['mobileNumber']
            );
          this.subform.get('telephone').setValue(data[0]['telephone']);
          this.subform.get('email').setValue(data[0]['email']);
          this.subform.get('dob').setValue(data[0]['dob']);
          this.subform.get('doa').setValue(data[0]['date_of_marriage']);
          this.subform.get('voucherid').setValue(data[0]['vid']);
  
          if (this.pendingbookdata == undefined) {
            this.subform.get('repname').setValue(data[0]['repname']);
            this.subform
              .get('repmobno')
              .setValue(data[0]['repmobno'] === '0' ? '' : data[0]['repname']);
          }
          this.subform.get('gst_no').setValue(data[0]['gst_no']);
          this.subform.get('remark').setValue(data[0]['remark']);
          this.service.getSubscriberEntryDate(id.id).subscribe((_data) => {
            // console.log(data);
            // this.subform
            //   .get('subyear')
            //   .setValue(
            //     formatDate(
            //       data[0]['entry_date'],
            //       'yyyy-MM-dd',
            //       'en-IN',
            //       'GMT+0530'
            //     )
            //   );
          });
        });
        // this.subform.reset()
        
        //get data of sub details
        this.service.getSubDetailsData(id.id).subscribe((data) => {
  
          console.log(data, "new data");
          
          if (Object.keys(data).length != 0) {
            console.log("it working")
            this.selectedrcpt = true;
            this.subrcptId = data[0]['id'];
            // if (this.pendingbookdata == undefined) {
              // this.subform.get('crepname').setValue(data[0]['rep_name']);
              // this.subform.get('repmobno1').setValue(data[0]['rep_mob_no']);
              // this.subform.get('officerep').setValue(data[0]['office_rep_name']);
              // this.subform.get('crdtdate').setValue(data[0]['crdt_voucher_date']);
              // this.subform.get('crdtvno').setValue(data[0]['crdt_voucher_no']);
              this.subrcptVoucherNo = data[0]['crdt_voucher_no'];
              // this.subform.get('rep_receipt').setValue(data[0]['rcpt_no']);
              // this.subform.get('crdtamt').setValue(data[0]['crdt_voucher_amt']);
              this.subform.get('seamt').setValue(data[0]['select_entry_amt']);
              this.subrcptSelectAmt = parseFloat(data[0]['select_entry_amt']);
              this.subform.get('balamt').setValue(data[0]['balance_amt']);
              this.subform.get('voucherid').setValue(data[0]['vid']);
              this.subform.get('repname').setValue(data[0]['rep_name']);
              this.subform.get('repmobno').setValue(data[0]['rep_mob_no']);
  
            // } else {
            //   this.subform.get('repname').setValue(data[0]['rep_name']);
            //   this.subform.get('repmobno').setValue(data[0]['rep_mob_no']);
            // }
            console.log()
            
            this.subform
              .get('entrydate')
              .setValue(
                data[0]['entry_date'] != ''
                  ? data[0]['entry_date']
                  : formatDate(new Date(), 'yyyy-MM-dd', 'en-IN', 'GMT+0530'
                  )
              );
            debugger;
            this.subrcptEntryDate = this.subform.value.entrydate
            // console.log(this.subrcptEntryDate, "filter date")
            this.subform.get('entrydate').setValue(this.subrcptEntryDate)

            
            this.subform.get('magnames').setValue(data[0]['magzine_name']);
            this.subform.get('magedition').setValue(data[0]['magzine_edition']);
            this.subform.get('duration').setValue(data[0]['subscription_type']);
            this.duration = data[0]['subscription_type'];
            this.subform
              .get('subyear')
              .setValue(
                formatDate(
                  data[0]['subscription_year'],
                  'yyyy-MM-dd',
                  'en-IN',
                  'GMT+0530'
                )
              );
            this.subform
              .get('subfromdate')
              .setValue(data[0]['sub_perid_from_date']);
            this.subform.get('subtodate').setValue(data[0]['sub_perid_to_date']);
            this.subform.get('distype').setValue(data[0]['delivery_mode']);
            this.subform.get('disrep').setValue(data[0]['distribution_rep']);
            this.subform.get('trecptno').setValue(data[0]['temp_rcpt_no']);
            this.subform.get('rdate').setValue(data[0]['temp_rcpt_date']);
            this.total = data[0]['total_amt'];
            this.subform.get('discount').setValue(data[0]['discount']);
            this.subform.get('netamount').setValue(data[0]['net_amt']);
            this.subform
              .get('repcompercent')
              .setValue(data[0]['rep_com_percent']);
            this.subform.get('repcomprice').setValue(data[0]['rep_com_amt']);
            // this.subform.get('cdno').setValue(data[0]['cheque_draft_no']);
            // this.subform.get('chequeDate').setValue(data[0]['cheque_date']);
            // this.subform.get('bankname').setValue(data[0]['bank_name']);
            this.subform.get('subremark').setValue(data[0]['remark']);
            this.subform.get('subremark1').setValue(data[0]['remark1']);
            this.subform.get('subremark2').setValue(data[0]['remark2']);
            this.subform
              .get('emp_mob_number')
              .setValue(
                data[0]['work_by'] === '0'
                  ? parseInt(sessionStorage.getItem('mobile'))
                  : data[0]['work_by']
              );
            this.subform.get('isactive').setValue(data[0]['is_active']);
            // if (data[0]['is_active'] == 'N') {
            this.isrcptno = true;
            // }
            this.subform.get('rcptstopdate').setValue(data[0]['rcptstopdate']);
            this.subform
              .get('rcptstopremark')
              .setValue(data[0]['rcptstopremark']);
            this.subform.get('days').setValue(data[0]['days']);
            this.subform.get('rcptstartdate').setValue(data[0]['rcptstartdate']);
            this.subform
              .get('rcptstartremark')
              .setValue(data[0]['rcptstartremark']);
          } else {
            if (this.pendingbookdata != undefined) {
              this.subform.get('repname').setValue(data[0]['repname']);
              this.subform.get('repmobno').setValue(data[0]['repmobno']);
            }
          }
        });
        this.enableDisableRule('first');
      }
    }

    // console.log(event.value);
   
  }

  getallVouchersData = [];
  FilerterdgetallVouchersData = [];
  total_amount = 0;
  total_remaining_amt = 0;
  vouchersData;
  getAllVouchers() {
debugger
    this.service.getvoucherbySub().subscribe((data) => {
      console.log(data, 'i want to see ');
      for (let index = 0; index < Object.keys(data).length; index++) {
        this.getallVouchersData.push({
          id: data[index]['id'],
          advtName: data[index]['advtName'],
          bank: data[index]['bank'],
          branch_name: data[index]['branch_name'],
          chequeDate: data[index]['chequeDate'],
          cheque_no: data[index]['cheque_no'],
          entry_date: data[index]['entry_date'],
          price: data[index]['price'],
          remaining_amt: data[index]['remaining_amt'],
          rep_name: data[index]['rep_name'],
          voucher_date: data[index]['voucher_date'],
          voucher_no: data[index]['voucher_no'],
          package: 'N',
          pkg_name: 'N',
          packageyear: 'N',
        });
      }

      // console.log(
      //   'pkgBookingData',
      //   this.pkgBookingData,
      //   Object.keys(this.pkgBookingData).length
      // );
      for (
        let index = 0;
        index < Object.keys(this.pkgBookingData).length;
        index++
      ) {
        this.getallVouchersData.push({
          id: this.pkgBookingData[index]['id'],
          advtName: this.pkgBookingData[index]['client_name'],
          bank: this.pkgBookingData[index]['bank'],
          branch_name: this.pkgBookingData[index]['branch_name'],
          chequeDate: this.pkgBookingData[index]['cheque_date'],
          cheque_no: this.pkgBookingData[index]['cheque_no'],
          entry_date: this.pkgBookingData[index]['entry_date'],
          price: this.pkgBookingData[index]['subscription_actual_amt'],
          remaining_amt:
            this.pkgBookingData[index]['subscription_remaining_amt'],
          rep_name: this.pkgBookingData[index]['rep_name'],
          voucher_date: this.pkgBookingData[index]['voucher_date'],
          voucher_no: this.pkgBookingData[index]['voucher_no'],
          package: 'Y',
          pkg_name: this.pkgBookingData[index]['pkg_name'],
          packageyear: this.pkgBookingData[index]['year'],
        });
      }
      // this.getallVouchersData = data;
      // this.FilerterdgetallVouchersData = this.getallVouchersData;
      this.FilerterdgetallVouchersData = this.getallVouchersData.sort((a, b) => {
        return new Date(a['voucher_date']).getTime() - new Date(b['voucher_date']).getTime();
      });




      // console.log('getAllVOuchersData', this.getallVouchersData);
      let id = sessionStorage.getItem('pending_id');
      this.vouchersData = this.getallVouchersData.filter((a) => {
        return parseInt(a.id) === parseInt(id);
      });
      for (
        let index = 0;
        index < Object.keys(this.FilerterdgetallVouchersData).length;
        index++
      ) {
        this.voucher_rep_data.push({
          rep_name: this.FilerterdgetallVouchersData[index]['rep_name'],
        });
        this.voucher_no_data.push({
          voucher_no: this.FilerterdgetallVouchersData[index]['voucher_no'],
        });
      }
      this.voucher_rep_data = this.uniqueid(
        this.voucher_rep_data,
        (a) => a.rep_name
      );
      console.log('VouchersData', this.FilerterdgetallVouchersData);
      this.calculatevoucher();
    });
  }

  calculatevoucher() {
    this.total_amount = 0;
    this.total_remaining_amt = 0;
    for (
      let index = 0;
      index < Object.keys(this.FilerterdgetallVouchersData).length;
      index++
    ) {
      this.total_amount =
        this.total_amount +
        parseInt(this.FilerterdgetallVouchersData[index]['price']);
      this.total_remaining_amt =
        this.total_remaining_amt +
        parseInt(this.FilerterdgetallVouchersData[index]['remaining_amt']);
    }
  }

  pendingbookdata;
  displayItemId(book) {
    
    
    this.subscription_all_data = false
    this.pendingbookdata = book;
    // console.log(this.pendingbookdata);
    if (this.pendingbookdata['pkg_name'] != 'N') {
      this.subscriptiontype.push({
        id: this.pendingbookdata['id'],
        subtype: this.pendingbookdata['pkg_name'],
        price: this.pendingbookdata['price'],
        date: this.pendingbookdata['entry_date'],
      });
    }
    // console.log(this.subscriptiontype);
    this.demo1BtnClick();
    // this.dialog.open(CreditvoucherDialogComponent, {
    //   data: {
    //     id: id,
    //     voucher_no: voucher_no,
    //     dept: 'subscription',
    //   },
    // });
    // sessionStorage.setItem('pending_id', id);
    // location.reload();
  } //end of displayId
 

  public demo1TabIndex = 0;
  public demo1BtnClick() {
    const tabCount = 13;
    this.demo1TabIndex = (this.demo1TabIndex + 1) % tabCount;
    this.subscriptionsetvalue();
  }

  public demo1BtnClick1() {
    const tabCount = 21;
    this.demo1TabIndex = (0 + 14) % tabCount;
    this.setHandDeliveryData();
  }

  setHandDeliveryData() {
    // console.log(this.handDeliveryData);
    this.AssignDisRepForm.get('disrep1').setValue(
      this.handDeliveryData['dis_rep_name']
    );
  }

  subscriptionsetvalue() {
    debugger
    for (
      let index = 0;
      index < Object.keys(this.getallVouchersData).length;
      index++
    ) {
      if (
        this.pendingbookdata['voucher_no'] ==
        this.getallVouchersData[index]['voucher_no']
      ) {
        this.pendingbookdata = this.getallVouchersData[index];
        // console.log('after if this.pendingbookdata', this.pendingbookdata);
        this.subform
          .get('showname')
          .setValue(this.getallVouchersData[index]['advtName']);
        this.subform
          .get('crdtamt')
          .setValue(this.getallVouchersData[index]['remaining_amt']);
        this.subform
          .get('crdtventrydate')
          .setValue(this.getallVouchersData[index]['entry_date']);
        this.subform
          .get('crdtdate')
          .setValue(this.getallVouchersData[index]['voucher_date']);
        this.month = new Date(this.getallVouchersData[index]['voucher_date'])
        this.getRcptNo()
        this.subform
          .get('crdtvno')
          .setValue(this.getallVouchersData[index]['voucher_no']);
        this.subform
          .get('crepname')
          .setValue(this.getallVouchersData[index]['rep_name']);
          // this.subform.get('repmobno1').setValue(data[0]['rep_mob_no']);
          // this.subform.get('officerep').setValue(data[0]['off_rep_name']);
        // for (
        //   let index1 = 0;
        //   index1 < Object.keys(this.issue_seller_name).length;
        //   index1++
        // ) {
        //   if (
        //     this.subform.get('crepname').value ==
        //     this.issue_seller_name[index1]['name']
        //   ) {
        //     this.subform
        //       .get('repmobno1')
        //       .setValue(this.issue_seller_name[index1]['mob_no']);
        //   }
        // }
        let data = this.subscriberwisereplist.filter(
          (a) => a['rep_name'] == this.subform.get('crepname').value
        );
        
        // console.log(data);
        if (Object.keys(data).length != 0) {
          this.subform.get('repmobno1').setValue(data[0]['rep_mob_no']);
          this.subform.get('officerep').setValue(data[0]['off_rep_name']);
        }
        this.subform
          .get('cdno')
          .setValue(this.getallVouchersData[index]['cheque_no']);
        this.subform
          .get('chequeDate')
          .setValue(this.getallVouchersData[index]['chequeDate']);
        this.subform
          .get('bankname')
          .setValue(this.getallVouchersData[index]['bank']);
        // console.log('2564', sessionStorage.getItem('mobile'));
        this.subform
          .get('emp_mob_number')
          .setValue(parseInt(sessionStorage.getItem('mobile')));
      }
    }
  }

  toggle: boolean;
  toggle1: boolean;
  toggle2: boolean;
  isrcpt;
  enableDisableRule(data) {
    // console.log(data);
    this.toggle = false;
    this.toggle1 = false;
    this.toggle2 = false;
    if (data == 'first') {
      this.toggle = true;
      this.toggle1 = false;
      this.toggle2 = false;
      this.isrcpt = 'Renew';
      // this.getsubno();
    } else if (data == 'second') {
      debugger;
      this.toggle = false;
      this.toggle1 = true;
      this.toggle = false;
      
     
    //  this.subform.reset();
     this.subform.get('entrydate').setValue(formatDate(new Date(), 'yyyy-MM-dd', 'en-IN', 'GMT+0530'
        ));
      this.isrcpt = 'New';
      this.subform.get('magnames').setValue('विवेक साप्ताहिक');
      this.subform.get('magedition').setValue('Print');
      // this.subform
      //   .get('entrydate')
      //   .setValue(formatDate(new Date(), 'yyyy-MM-dd', 'en-IN', 'GMT+0530'));
      // this.subform
      //   .get('subyear')
      //   .setValue(formatDate(new Date(), 'yyyy-MM-dd', 'en-IN', 'GM+0530'));
      this.subscriptionsetvalue();
      this.iscomplementary = false;
      this.selectedname = false;
      this.getRcptNo();
      this.getsubno();
      this.getRepName();
    } else if (data == 'third') {
      this.toggle = false;
      this.toggle1 = false;
      this.toggle2 = true;
    }
  }

  getsubscriptionPayment() {
    this.service.getPendingPayment().subscribe((res) => {
      // console.log(res);
debugger
      this.subscriptionpaymentList = [];
      for (let index = 0; index < Object.keys(res).length; index++) {
        this.subscriptionpaymentList.push({
          rep_id: parseInt(res[index]['repId']),
          name: res[index]['repName'],
          id: parseInt(res[index]['id']),
          claim_name: res[index]['name'],
          totalAmount: parseInt(res[index]['totalAmount']),
          payoptn: res[index]['payoptn'],
          repName: res[index]['repName'],
          repmobile: res[index]['repmobile'],
          rep_receipt: res[index]['rep_receipt'],
          task: 'subscription',
        });
      }
    });
  }

  displayBookPayment(paymentdetails) {
    // console.log(paymentdetails);
    this.dialog.open(SubscriptionAccountsComponent, {
      data: {
        rep: paymentdetails,
        view: false,
      },
    });
  }

  validatesubmobile(event) {
    const mobile = event.target.value;
    this.service.checksubisexist(parseInt(mobile)).subscribe((a) => {
      // console.log(a);
      if (Object.keys(a).length > 0) {
        window.alert('Subscriber Already Exist');
        this.subform.get('paymentmode').disable();
        this.subform.controls['mobileNumber'].setErrors({ incorrect: true });
      } else {
        this.subform.get('paymentmode').enable();
      }
    });
  }

  subscribersName;
  validatesubmobileno(mobile) {
    this.service.checksubisexist(parseInt(mobile)).subscribe((a) => {
      // console.log(a);
      if (Object.keys(a).length > 0) {
        this.subscribersName = a[0]['name'];
      }
    });
  }

  validsubcat = false;
  showbussiness = false;
  getSearchTerm(event) {
    if (this.subform.get('subCat').valid) {
      const value = event.value;
      this.validsubcat = true;
      if (value == 'business') {
        this.searchTerm = true;
        this.showbussiness = true;
      } else {
        this.searchTerm = false;
        this.showbussiness = false;
      }
    } else {
      this.validsubcat = false;
      this.showbussiness = false;
    }
  }

  getcheque(event) {
    const value = event.value;
    if (value == 'cheque') {
      this.displayChequqField = true;
    } else {
      this.displayChequqField = false;
    }
  }

  getmagEdition(event) {
    this.magedition = event.value;
  }

  updatesubscriber() {
    if (window.confirm('Do you want to update subscribers data')) {
      let subdata = {
        subid: this.subId,
        subtype: this.subform.get('subtype').value,
        month: this.subform.get('month').value,
        year: this.subform.get('year').value,
        subno: this.subform.get('subno').value,
        title: this.subform.get('title').value,
        name: this.subform.get('name').value,
        e_name: this.subform.get('e_name').value,
        address: this.subform.get('address').value,
        pincode: this.subform.get('pincode').value,
        country: this.subform.get('country').value,
        state: this.subform.get('state').value,
        district: this.subform.get('district').value,
        e_district: this.subform.get('e_district').value,
        taluka: this.subform.get('taluka').value,
        mobileNumber: this.subform.get('mobileNumber').value,
        telephone: this.subform.get('telephone').value,
        email: this.subform.get('email').value,
        dob: this.subform.get('dob').value,
        doa: this.subform.get('doa').value,
        repname: this.subform.get('repname').value,
        repmobno: this.subform.get('repmobno').value,
        gst_no: this.subform.get('gst_no').value,
        remark: this.subform.get('remark').value,
      };
      // console.log('sub', subdata);
      this.service.updateSub(subdata).subscribe((_data) => {
        window.alert('Subscriber Data Updated Successfully...');
        // this.subform.reset();
        this.getAllVouchers();
        this.subform.get('magnames').setValue('विवेक साप्ताहिक');
        this.subform.get('magedition').setValue('Print');
        // this.subform
        //   .get('entrydate')
        //   .setValue(formatDate(new Date(), 'yyyy-MM-dd', 'en-IN', 'GMT+0530'));
        this.subscriptionsetvalue();
        this.iscomplementary = false;
        this.selectedname = false;
        this.selectedrcpt = false;
        this.getRcptNo();
        // this.getsubno();
        this.getRepName();
      });
    }
  }

  deletesubscriber() {
    if (window.confirm('Do you want to delete subscriber')) {
      this.service.deleteSub(this.subId).subscribe((_res) => {
        // console.log(res);
        alert('Subscriber deleted successfully...');
        this.subId = 0;
        // this.ngOnInit();
        location.reload();
      });
    }
  }

  updatesubscriberreceipt() {

    if (window.confirm('Do you want to update receipt')) {

      let subdetails = {
        id: this.subrcptId,
        crepname: this.subform.get('crepname').value,
        repmobno1: this.subform.get('repmobno1').value,
        officerep: this.subform.get('officerep').value,
        crdtdate: this.subform.get('crdtdate').value,
        crdtvno: this.subform.get('crdtvno').value,
        // rep_receipt: this.subform.get('rep_receipt').value,
        // crdtamt: this.subform.get('crdtamt').value,
        // seamt: this.subform.get('seamt').value,
        // balamt: this.subform.get('balamt').value,
        // entrydate: this.subform.get('entrydate').value,
        // magnames: this.subform.get('magnames').value,
        magedition: this.subform.get('magedition').value,
        duration: this.subform.get('duration').value,
        // subyear: this.subform.get('subyear').value,
        subfromdate: this.subform.get('subfromdate').value,
        subtodate: this.subform.get('subtodate').value,
        distype: this.subform.get('distype').value,
        disrep: this.subform.get('disrep').value,
        // trecptno: this.subform.get('trecptno').value,
        // rdate: this.subform.get('rdate').value,
        // totalamt: this.total,
        // discount: this.subform.get('discount').value,
        // netamount: this.subform.get('netamount').value,
        // repcompercent: this.subform.get('repcompercent').value,
        // repcomprice: this.subform.get('repcomprice').value,
        // cdno: this.subform.get('cdno').value,
        // chequeDate: this.subform.get('chequeDate').value,
        // bankname: this.subform.get('bankname').value,
        subremark: this.subform.get('subremark').value,
        subremark1: this.subform.get('subremark1').value,
        subremark2: this.subform.get('subremark2').value,
        // number: this.subform.get('emp_mob_number').value,
        ds_subisactive: this.subform.get('isactive').value,
        rcptstopdate: this.subform.get('rcptstopdate').value,
        rcptstopremark: this.subform.get('rcptstopremark').value,
        // rcptstartdate: this.subform.get('rcptstartdate').value,
        // rcptstartremark: this.subform.get('rcptstartremark').value,
        newid: this.subform.get('subno').value,
        newvid: this.subform.get('voucherid').value,

        balamt: this.subform.get('balamt').value,
        vamount: this.subform.get('crdtamt').value,
        select_entry_amt: this.subform.get('seamt').value,
      };

      console.log(subdetails, "subdetail")

      console.log(this.subform.get('subno').value, "newid");
      console.log(this.subform.get('voucherid').value, "vid");
      console.log(this.subform.get('balamt').value, "balamt");



      // const newid = this.subform.get('subno').value,

      this.service.updateSubDetails(subdetails).subscribe((_data) => {

        this.service.updateSubDetails1(subdetails).subscribe((_data) => {
          alert('Subscribers Details Updated Successfully');
          this.subform.reset();
          this.getAllVouchers();
          this.subform.get('magnames').setValue('विवेक साप्ताहिक');
          this.subform.get('magedition').setValue('Print');
          this.subform
            .get('entrydate')
            .setValue(formatDate(new Date(), 'yyyy-MM-dd', 'en-IN', 'GMT+0530'));
          this.subscriptionsetvalue();
          this.iscomplementary = false;
          this.selectedname = false;
          this.selectedrcpt = false;
          this.getRcptNo();
          this.getsubno();
          this.getRepName();
          // this.ngOnInit();
          location.reload();
        });
       
      });
      
    }
   
  }
  
  deletesubscriberreceipt() {

    if (window.confirm('Do you want to Delete receipt')) {
      console.log(
        'checking data',
        this.subrcptEntryDate,
        this.subrcptVoucherNo,
        this.subrcptSelectAmt
      );
      let date = new Date();
      console.log(date.setMonth(2));
      console.log(date);
      console.log(
        formatDate(date, 'yyyy-MM-dd', 'en-IN', 'GMT+0530') <
        formatDate(this.subrcptEntryDate, 'yyyy-MM-dd', 'en-IN', 'GMT+0530')
      );
      
      this.service
        .deleteSubDetails(
          this.subrcptId,
          this.subrcptVoucherNo,
          this.subrcptSelectAmt,
          formatDate(date, 'yyyy-MM-dd', 'en-IN', 'GMT+0530') <
          formatDate(this.subrcptEntryDate, 'yyyy-MM-dd', 'en-IN', 'GMT+0530')
        )
        .subscribe((_res) => {
          // console.log(res);
          alert('Subscriber receipt deleted successsfully...');
          this.subrcptId = 0;
          this.ngOnInit();
        });
    }
  }

  isComplimenatry() {
    debugger;

    if (!this.selectedname) {
      this.getsubno();
      let subdata = {
        subtype: this.subform.get('subtype').value,
        month: this.subform.get('month').value,
        year: this.subform.get('year').value,
        subno: this.subform.get('subno').value,
        title: this.subform.get('title').value,
        name: this.subform.get('name').value,
        e_name: this.subform.get('e_name').value,
        address: this.subform.get('address').value,
        pincode: this.subform.get('pincode').value,
        country: this.subform.get('country').value,
        state: this.subform.get('state').value,
        district: this.subform.get('district').value,
        e_district: this.subform.get('e_district').value,
        taluka: this.subform.get('taluka').value,
        mobileNumber: this.subform.get('mobileNumber').value,
        telephone: this.subform.get('telephone').value,
        email: this.subform.get('email').value,
        dob: this.subform.get('dob').value,
        doa: this.subform.get('doa').value,
        repname: this.subform.get('repname').value,
        repmobno: this.subform.get('repmobno').value,
        gst_no: this.subform.get('gst_no').value,
        remark: this.subform.get('remark').value,
      };

      this.service.addSub(subdata).subscribe(
        (data) => {
          // if (data.message && data.message === "Subscription No. already exist") {
          //   console.warn(data.message);
          //   return
          // } 
          // console.log('addsub', data);
          debugger
          this.subId = data['id'];
          this.getRcptNo();
          let subdetails = {
            iscomplimentary: 'Y',
            subid: this.subId,
            crepname: this.subform.get('crepname').value,
            repmobno1: this.subform.get('repmobno1').value,
            officerep: this.subform.get('officerep').value,
            crdtdate: '',
            crdtvno: '',
            crdtventrydate: '',
            rep_receipt: '',
            crdtamt: 0,
            seamt: 0,
            balamt: 0,
            entrydate: this.subform.get('entrydate').value,
            magnames: this.subform.get('magnames').value,
            magedition: this.subform.get('magedition').value,
            duration: this.subform.get('duration').value,
            subyear: this.subform.get('subyear').value,
            subfromdate: this.subform.get('subfromdate').value,
            subtodate: this.subform.get('subtodate').value,
            distype: this.subform.get('distype').value,
            disrep: this.subform.get('disrep').value,
            trecptno: this.subform.get('trecptno').value,
            rdate: this.subform.get('rdate').value,
            voucherid: this.subform.get('voucherid').value,
            totalamt: 0,
            discount: 0,
            netamount: 0,
            repcompercent: 0,
            repcomprice: 0,
            cdno: '',
            chequeDate: '',
            bankname: '',
            subremark: this.subform.get('subremark').value,
            number: this.subform.get('emp_mob_number').value,
            ds_subisactive: 'Y',
            isrcpt: this.isrcpt,
          };
debugger;
          this.service.addSubDetails(subdetails).subscribe(
            (_data) => {
              // console.log('new addSubDetails', data);
              window.alert('Subscriber Data Added Successfully...');

              this.service
                .updateSubType(this.subId, this.subform.get('duration').value)
                .subscribe((_data) => { });

              this.subform.reset();
              setTimeout(() => {
                this.getAllVouchers();
              }, 500);
              this.subform.get('magnames').setValue('विवेक साप्ताहिक');
              this.subform.get('magedition').setValue('Print');
              this.subform
                .get('entrydate')
                .setValue(
                  formatDate(new Date(), 'yyyy-MM-dd', 'en-IN', 'GMT+0530')
                );
              setTimeout(() => {
                this.subscriptionsetvalue();
              }, 500);
              this.iscomplementary = false;
              this.selectedname = false;
              this.getRcptNo();
              this.getsubno();
              this.getRepName();
            },
            (_error) => {
              alert('Looks a like receipt no is already in use.');
              this.getRcptNo();
              this.selectedname = true;
            }
          );
        },
        (_error) => {
          alert('Subscriber no is already assigned...');
          this.getsubno();
          this.getRcptNo();
        }
      );
    } else {
      this.getRcptNo();
      let subdetails = {
        subid: this.subId,
        crepname: this.subform.get('crepname').value,
        repmobno1: this.subform.get('repmobno1').value,
        officerep: this.subform.get('officerep').value,
        crdtdate: '',
        crdtvno: '',
        crdtventrydate: '',
        rep_receipt: '',
        crdtamt: 0,
        seamt: 0,
        balamt: 0,
        entrydate: this.subform.get('entrydate').value,
        magnames: this.subform.get('magnames').value,
        magedition: this.subform.get('magedition').value,
        duration: this.subform.get('duration').value,
        subyear: this.subform.get('subyear').value,
        subfromdate: this.subform.get('subfromdate').value,
        subtodate: this.subform.get('subtodate').value,
        distype: this.subform.get('distype').value,
        disrep: this.subform.get('disrep').value,
        trecptno: this.subform.get('trecptno').value,
        rdate: this.subform.get('rdate').value,
        totalamt: 0,
        discount: 0,
        netamount: 0,
        repcompercent: 0,
        repcomprice: 0,
        cdno: '',
        chequeDate: '',
        bankname: '',
        subremark: this.subform.get('subremark').value,
        number: this.subform.get('emp_mob_number').value,
        pkg_name: this.pendingbookdata == undefined
          ? 'N'
          : this.pendingbookdata['pkg_name'],
        ds_subisactive: 'Y',
        isrcpt: this.isrcpt,
      };
debugger;
      this.service.addSubDetails(subdetails).subscribe(
        (_data) => {
          // console.log('new addSubDetails', data);
          window.alert('Subscriber Data Added Successfully...');

          this.service
            .updateSubType(this.subId, this.subform.get('duration').value)
            .subscribe((_data) => { });

          // console.log('subrcpt Id :', this.subrcptId);
          // this.service
          //   .cancelSubscriberrcpt(this.subrcptId)
          //   .subscribe((_data) => { });

          this.subform.reset();
          setTimeout(() => {
            this.getAllVouchers();
          }, 500);
          this.subform.get('magnames').setValue('विवेक साप्ताहिक');
          this.subform.get('magedition').setValue('Print');
          this.subform
            .get('entrydate')
            .setValue(
              formatDate(new Date(), 'yyyy-MM-dd', 'en-IN', 'GMT+0530')
            );
          setTimeout(() => {
            this.subscriptionsetvalue();
          }, 1000);
          this.iscomplementary = false;
          this.selectedname = false;
          this.getRcptNo();
          this.getsubno();
          this.getRepName();
        },
        (_error) => {
          alert('Looks a like receipt no is already in use.');
          this.getRcptNo();
        }
      );
    }
  }

  addsub() {
    debugger;
    console.log(this.subform)
// return
    {
      if (
        this.subform.get('seamt').value != 0 ||
        this.subform.get('seamt').value != undefined ||
        this.subform.get('seamt').value != null
      ) {
        if (this.iscomplementary) {
          this.isComplimenatry();
        } else {
          if (!this.selectedname) {
            debugger;
            this.getsubno();
            let subdata = {
              subtype: this.subform.get('subtype').value,
              month: this.subform.get('month').value,
              year: this.subform.get('year').value,
              subno: this.subform.get('subno').value,
              title: this.subform.get('title').value,
              name: this.subform.get('name').value,
              e_name: this.subform.get('e_name').value,
              address: this.subform.get('address').value,
              pincode: this.subform.get('pincode').value,
              country: this.subform.get('country').value,
              state: this.subform.get('state').value,
              district: this.subform.get('district').value,
              e_district: this.subform.get('e_district').value,
              taluka: this.subform.get('taluka').value,
              mobileNumber: this.subform.get('mobileNumber').value,
              telephone: this.subform.get('telephone').value,
              email: this.subform.get('email').value,
              dob: this.subform.get('dob').value,
              doa: this.subform.get('doa').value,
              repname: this.subform.get('repname').value,
              repmobno: this.subform.get('repmobno').value,
              gst_no: this.subform.get('gst_no').value,
              remark: this.subform.get('remark').value,
            };
            // console.log({ subdata });

            this.service.addSub(subdata).subscribe(
              (data) => {
                // if (data.message && data.message === "Subscription No. already exist") {
                //   console.warn(data.message);
                //   return
                // } 
                this.subId = data['id'];

                // this.getRcptNo();
                console.log(this.subform)
                let subdetails = {
                  iscomplimentary: 'N',
                  subid: this.subId,
                  crepname: this.subform.get('crepname').value,
                  repmobno1: this.subform.get('repmobno1').value,
                  officerep: this.subform.get('officerep').value,
                  crdtdate: this.subform.get('crdtdate').value,
                  crdtvno: this.subform.get('crdtvno').value,
                  crdtventrydate: this.subform.get('crdtventrydate').value,
                  rep_receipt: this.subform.get('rep_receipt').value,
                  crdtamt: this.subform.get('crdtamt').value,
                  seamt: this.subform.get('seamt').value,
                  balamt: this.subform.get('balamt').value,
                  entrydate: this.subform.get('entrydate').value,
                  magnames: this.subform.get('magnames').value,
                  magedition: this.subform.get('magedition').value,
                  duration: this.subform.get('duration').value,
                  subyear: this.subform.get('subyear').value,
                  subfromdate: this.subform.get('subfromdate').value,
                  subtodate: this.subform.get('subtodate').value,
                  distype: this.subform.get('distype').value,
                  disrep: this.subform.get('disrep').value,
                  trecptno: this.subform.get('trecptno').value,
                  rdate: this.subform.get('rdate').value,
                  totalamt: this.total,
                  discount: this.subform.get('discount').value,
                  netamount: this.subform.get('netamount').value,
                  repcompercent: this.subform.get('repcompercent').value,
                  repcomprice: this.subform.get('repcomprice').value,
                  cdno: this.subform.get('cdno').value,
                  chequeDate: this.subform.get('chequeDate').value,
                  bankname: this.subform.get('bankname').value,
                  subremark: this.subform.get('subremark').value,
                  number: this.subform.get('emp_mob_number').value,
                  pkg_name:
                    this.pendingbookdata == undefined
                      ? 'N'
                      : this.pendingbookdata['pkg_name'],
                  ds_subisactive: 'Y',
                  isrcpt: this.isrcpt,
                };
                console.log( this.subform.get('rep_receipt').value, "new receipt number");
                debugger;

                this.service.addSubDetails(subdetails).subscribe(
                  (data) => {
                    console.log('new addSubDetails', data);
                    debugger;
                    window.alert( data.receipt_number + 'is new subscriber Data Added Successfully...');
                    this.subform.reset();
                    this.ngOnInit();
                    if (this.pendingbookdata != undefined) {
                      if (this.pendingbookdata['package'] == 'N') {
                        this.service
                          .updatecreditvoucherofsub(
                            this.pendingbookdata['id'],
                            this.balamt
                          )
                          .subscribe((_data) => {
                            // console.log('price updated successfully...', data);
                          });
                      } else if (this.pendingbookdata['package'] == 'Y') {
                        this.service
                          .updatecreditvoucherofsubpkg(
                            this.pendingbookdata['id'],
                            this.balamt
                          )
                          .subscribe((_data) => {
                            // console.log(data);
                          });
                      }
                    }

                    this.service
                      .updateSubType(
                        this.subId,
                        this.subform.get('duration').value
                      )
                      .subscribe((_data) => { });

                    // this.subform.reset();
                    // this.ngOnInit();
                    this.subform.get('magnames').setValue('विवेक साप्ताहिक');
                    this.subform.get('magedition').setValue('Print');
                    this.subform
                      .get('entrydate')
                      .setValue(
                        formatDate(
                          new Date(),
                          'yyyy-MM-dd',
                          'en-IN',
                          'GMT+0530'
                        )
                      );
                    // setTimeout(() => {
                    //   this.getAllVouchers();
                    // }, 1500);
                    setTimeout(() => {
                      this.subscriptionsetvalue();
                    }, 2000);
                    this.iscomplementary = false;
                    this.selectedname = false;
                    this.getRcptNo();
                    this.getsubno();
                    this.getRepName();
                    this.ngOnInit();
                  },
                  (_error) => {
                    alert('Looks a like receipt no is already in use.');
                    // setTimeout(() => {
                    //   this.getAllVouchers();
                    // }, 500);
                    setTimeout(() => {
                      this.subscriptionsetvalue();
                      this.balamt =
                        parseInt(this.subform.get('crdtamt').value) -
                        parseInt(this.subform.get('seamt').value);
                      this.subform.get('balamt').setValue(this.balamt);
                      // console.log('bal amt', this.balamt);
                    }, 1000);
                    this.getRcptNo();
                    this.selectedname = true;
                  }
                );
              },
              (_error) => {
                alert('Subscriber no is alrady assigned...');
                // setTimeout(() => {
                //   this.getAllVouchers();
                // }, 500);
                setTimeout(() => {
                  this.subscriptionsetvalue();
                  this.balamt =
                    parseInt(this.subform.get('crdtamt').value) -
                    parseInt(this.subform.get('seamt').value);
                  this.subform.get('balamt').setValue(this.balamt);
                  // console.log('bal amt', this.balamt);
                }, 1000);
                this.getsubno();
                this.getRcptNo();
              }
            );
          } else {
            this.getRcptNo();
            console.log('pendingbookdata', this.pendingbookdata);
            console.log(
              this.pendingbookdata == undefined
                ? 'N'
                : this.pendingbookdata['pkg_name']
            );
            let subdetails = {
              subid: this.subId,
              crepname: this.subform.get('crepname').value,
              repmobno1: this.subform.get('repmobno1').value,
              officerep: this.subform.get('officerep').value,
              crdtdate: this.subform.get('crdtdate').value,
              crdtvno: this.subform.get('crdtvno').value,
              crdtventrydate: this.subform.get('crdtventrydate').value,
              rep_receipt: this.subform.get('rep_receipt').value,
              crdtamt: this.subform.get('crdtamt').value,
              seamt: this.subform.get('seamt').value,
              balamt: this.subform.get('balamt').value,
              entrydate: this.subform.get('entrydate').value,
              magnames: this.subform.get('magnames').value,
              magedition: this.subform.get('magedition').value,
              duration: this.subform.get('duration').value,
              subyear: this.subform.get('subyear').value,
              subfromdate: this.subform.get('subfromdate').value,
              subtodate: this.subform.get('subtodate').value,
              distype: this.subform.get('distype').value,
              disrep: this.subform.get('disrep').value,
              trecptno: this.subform.get('trecptno').value,
              rdate: this.subform.get('rdate').value,
              totalamt: this.total,
              discount: this.subform.get('discount').value,
              netamount: this.subform.get('netamount').value,
              repcompercent: this.subform.get('repcompercent').value,
              repcomprice: this.subform.get('repcomprice').value,
              cdno: this.subform.get('cdno').value,
              chequeDate: this.subform.get('chequeDate').value,
              bankname: this.subform.get('bankname').value,
              subremark: this.subform.get('subremark').value,
              number: this.subform.get('emp_mob_number').value,
              pkg_name:
                this.pendingbookdata == undefined
                  ? 'N'
                  : this.pendingbookdata['pkg_name'],
              ds_subisactive: 'Y',
              isrcpt: this.isrcpt,
            };
            this.service.addSubDetails(subdetails).subscribe(
              (data) => {
                console.log('SubDetails', subdetails);
                debugger;
                window.alert(data.receipt_number + 'is new subscriber Data Added Successfully...');

                // console.log('subrcpt Id :', this.subrcptId);
                // this.service
                //   .cancelSubscriberrcpt(this.subrcptId)
                //   .subscribe((_data) => { });

                if (this.pendingbookdata != undefined) {
                  if (this.pendingbookdata['package'] == 'N') {
                    this.service
                      .updatecreditvoucherofsub(
                        this.pendingbookdata['id'],
                        this.balamt
                      )
                      .subscribe((_data) => {
                        // console.log(data);
                      });
                  } else if (this.pendingbookdata['package'] == 'Y') {
                    this.service
                      .updatecreditvoucherofsubpkg(
                        this.pendingbookdata['id'],
                        this.balamt
                      )
                      .subscribe((_data) => {
                        // console.log(data);
                      });
                  }
                }

                this.service
                  .updateSubType(this.subId, this.subform.get('duration').value)
                  .subscribe((_data) => { });

                this.subform.reset();
                // this.ngOnInit();
                // setTimeout(() => {
                //   this.getAllVouchers();
                // }, 1500);
                this.subform.get('magnames').setValue('विवेक साप्ताहिक');
                this.subform.get('magedition').setValue('Print');
                this.subform
                  .get('entrydate')
                  .setValue(
                    formatDate(new Date(), 'yyyy-MM-dd', 'en-IN', 'GMT+0530')
                  );
                setTimeout(() => {
                  this.subscriptionsetvalue();
                }, 2000);
                this.iscomplementary = false;
                this.selectedname = false;
                this.getRcptNo();
                this.getsubno();
                this.getRepName();
              },
              (_error) => {
                alert('Looks a like receipt no is already in use.');
                // setTimeout(() => {
                //   this.getAllVouchers();
                // }, 500);
                setTimeout(() => {
                  this.subscriptionsetvalue();
                  this.balamt =
                    parseInt(this.subform.get('crdtamt').value) -
                    parseInt(this.subform.get('seamt').value);
                  this.subform.get('balamt').setValue(this.balamt);
                  // console.log('bal amt', this.balamt);
                }, 1000);
                this.getRcptNo();
              }
            );
          }
        }
        // this.ngOnInit()
        
        // this.service.getRepcredit(parseInt(userid)).subscribe((res) => {
        //   RepCredit = res;
        //   tempcredit = res[0]['ds_tempcredit'];
        //   console.log('RepCredit', RepCredit);
        // });

        let newmag = {
          ds_custmobile: this.subform.get('mobileNumber').value,
          // ds_repid: this.subform.get('rep_id').value,
          ds_mag1: this.subform.get('magnames').value,
          ds_mag1edition: this.subform.get('magedition').value,
          // ds_mag1enddt: this.subform.get('magenddt').value,
          // ds_totamt: this.subform.get('magprice').value,
          // ds_mag1startdt: this.subform.get('magstartdt').value,
          rep_receipt: this.subform.get('rep_receipt').value,
        };
      } else {
        alert('Please select a subscription type manually..');
      }
    }
    // this.subform.reset()
  }

  DateRepwise() {
    if (this.daterepstartdate != '' && this.daterependdate != '') {
      this.dialog.open(DatewiseVarganidarRojmelReportComponent, {
        data: {
          startDate: this.daterepstartdate,
          endDate: this.daterependdate,
          data: this.Filtereddatewiserepwisedata,
          rep_name: this.rep_name.toString(),
          dataset : 'first',
        },
        width: '1300px',
        height: '1300px',
        maxWidth: '100%',
      });
    } else {
      alert('Please select from to date');
    }
  }

  DateRepwise1() {
    if (this.daterepstartdate != '' && this.daterependdate != '') {
      this.dialog.open(ActiveinactivebusinessreportComponent, {
        data: {
          startDate: this.daterepstartdate,
          endDate: this.daterependdate,
          data: this.Filtereddatewiserepwisedata,
          rep_name: this.rep_name.toString(),
          dataset : this.datewisedata,
          
        },
        width: '1300px',
        height: '1300px',
        maxWidth: '100%',
      });
    } else {
      alert('Please select from to date');
    }
  }

  statewise() {
    if (this.daterepstartdate != '' && this.daterependdate != '') {
      this.dialog.open(StatewisebusinessreportComponent, {
        data: {
          startDate: this.daterepstartdate,
          endDate: this.daterependdate,
          data: this.Filtereddatewiserepwisedata,
          rep_name: this.rep_name.toString(),
          dataset : this.datewisedata,
          state : this.daterepForm.value.state,
          prant : this.daterepForm.value.prant_name,

          
        },
        width: '1300px',
        height: '1300px',
        maxWidth: '100%',
      });
    } else {
      alert('Please select from to date');
    }
  }

  countrywise() {
    if (this.daterepstartdate != '' && this.daterependdate != '') {
      this.dialog.open(CountrywisebusinessreportComponent, {
        data: {
          startDate: this.daterepstartdate,
          endDate: this.daterependdate,
          data: this.Filtereddatewiserepwisedata,
          rep_name: this.rep_name.toString(),
          dataset : this.datewisedata,
          country : this.daterepForm.value.country,
          
        },
        width: '1300px',
        height: '1300px',
        maxWidth: '100%',
      });
    } else {
      alert('Please select from to date');
    }
  }

  districtwise() {
    if (this.daterepstartdate != '' && this.daterependdate != '') {
      this.dialog.open(DistrictwisebusinessComponent, {
        data: {
          startDate: this.daterepstartdate,
          endDate: this.daterependdate,
          data: this.Filtereddatewiserepwisedata,
          rep_name: this.rep_name.toString(),
          dataset : this.datewisedata,
          district : this.daterepForm.value.district,
          
        },
        width: '1300px',
        height: '1300px',
        maxWidth: '100%',
      });
    } else {
      alert('Please select from to date');
    }
  }

  prantwise() {
    if (this.daterepstartdate != '' && this.daterependdate != '') {
      this.dialog.open(PrantwisebusinessComponent, {
        data: {
          startDate: this.daterepstartdate,
          endDate: this.daterependdate,
          data: this.Filtereddatewiserepwisedata,
          rep_name: this.rep_name.toString(),
          dataset : this.datewisedata,
          prant : this.daterepForm.value.prant_name,
          
        },
        width: '1300px',
        height: '1300px',
        maxWidth: '100%',
      });
    } else {
      alert('Please select from to date');
    }
  }

  getTotalNetAmount() {
    return this.FilteredgetSubDetailsData.map((t) =>
      parseInt(t.net_amt)
    ).reduce((acc, value) => acc + value, 0);
  }

  getTotalRepComAmount() {
    return this.FilteredgetSubDetailsData.map((t) =>
      parseInt(t.rep_com_amt)
    ).reduce((acc, value) => acc + value, 0);
  }

  getTotalDiscountAmount() {
    return this.FilteredgetSubDetailsData.map((t) =>
      parseInt(t.discount)
    ).reduce((acc, value) => acc + value, 0);
  }

  getTotalTotalAmount() {
    return this.FilteredgetSubDetailsData.map((t) =>
      parseInt(t.total_amt)
    ).reduce((acc, value) => acc + value, 0);
  }

  insertCommissionClose(newsub, magname, userid, commamt, ds_id) {
    this.service
      .insertComissionLimit(
        parseInt(userid),
        parseInt(newsub['mobileNumber']),
        commamt,
        ds_id
      )
      .subscribe((_res) => {
        let msg =
          'Dear ' +
          newsub['name'] +
          ' , Your ' +
          magname +
          ' has been received to our office. Regards, Hindustan Prakashan Sanstha.';
        let template = 'Vivek31';

        this.service
          .sendSMS(parseInt(newsub['mobileNumber']), msg, template)
          .subscribe((_a) => {
            // console.log('msg sent successfully....');
          });
      });
  }

  magnames;
  getmagyear(event) {
    const year = event.value;
    // console.log('year', year);
    let todayDateStringto = new Date();
    todayDateStringto.setFullYear(todayDateStringto.getFullYear() + year);
    // console.log('mag', this.mag);
    for (let index = 0; index < Object.keys(this.mag).length; index++) {
      if (
        this.mag[index]['name'] == this.magnames &&
        parseInt(this.mag[index]['year']) == year
      ) {
        this.displayTotal = true;
        this.subform.get('magnames').setValue(this.mag[index]['name']);
        this.subform.get('magprice').setValue(this.mag[index][this.magedition]);
        this.subform.get('magedition').setValue(this.magedition);
        this.totalmagamount = parseInt(this.mag[index][this.magedition]);
      } else {
        this.displayTotal = false;
      }
    }
    this.subform.get('totalMagAmount').setValue(this.totalmagamount);
  }

  getmagdetails(event) {
    this.magnames = event.value;
    // console.log('magname', this.magnames);
  }

  getotherDonation(event) {
    const value = event.target.value;

    if (parseInt(value) >= 0) {
      this.totaldonationamount = parseInt(value);
    } else {
      this.totaldonationamount = 0;
    }
    this.subform.get('otherdonation').setValue(this.totaldonationamount);
  }

  getValidateEvent(event) {
    this.changedMobileNumber = event.target.value;
    this.validatesubmobileno(this.changedMobileNumber);
  }

  startLoading = false;
  getsubmags() {
    this.startLoading = true;
    this.cancelCheckbox = [];
    let mobileNumber = this.changedMobileNumber;
    this.service
      .getSubmagByIdorNumberCancel(parseInt(mobileNumber))
      .subscribe((res) => {
        // console.log(res);
        this.getMagazine = res;
        this.nochangenumber = true;
        this.startLoading = false;
        if (Object.keys(res).length > 0) {
          this.disableOtherFields = false;
        } else {
          this.disableOtherFields = true;
        }

        // console.log(res);
        setTimeout(() => {
          for (
            let index = 0;
            index < Object.keys(this.getMagazine).length;
            index++
          ) {
            this.cancelCheckbox.push({
              name: this.getMagazine[index]['ds_mag1'],
              edition: this.getMagazine[index]['ds_mag1edition'],
              end_date: this.getMagazine[index]['ds_mag1enddt'],
            });
          }
        }, 1000);

        // console.log(this.cancelCheckbox);
      });
  }

  openModal() {
    let mobile = parseInt(sessionStorage.getItem('mobile'));

    var sixdigitsrandom = Math.floor(100000 + Math.random() * 900000);
    let msg =
      'Dear Subscriber, Your Verification Code is ' +
      sixdigitsrandom +
      ', Regards Hindustan Prakashan Sanstha.';
    let template = 'VIVEK1';
    this.service.sendSMS(mobile, msg, template).subscribe((_a) => { });

    this.dialog.open(AddNewSubOtpModalComponent, {
      data: {
        message: sixdigitsrandom,
        Form: this.subform,
        height: '600px',
        width: '600px',
      },
    });
  }

  getbookrcptStartDate = '';
  getbookrcptEndDate = '';
  getBookReceiptDateRange() {
    if (
      this.generateform.get('start').value != null &&
      this.generateform.get('end').value != null
    ) {
      this.getbookrcptStartDate = formatDate(
        new Date(this.generateform.get('start').value),
        'yyyy-MM-dd',
        'en-IN',
        'GMT+0530'
      );
      this.getbookrcptEndDate = formatDate(
        new Date(this.generateform.get('end').value),
        'yyyy-MM-dd',
        'en-IN',
        'GMT+0530'
      );

      // this.filteredLabelDispatchData();
    }
  }

  title1 = '';
  get_titles(event) {
    this.title1 = event.value;
    // console.log(sub_name);
    // this.filteredLabelDispatchData();
  }

  reset_titles() {
    this.title1 = '';
    this.generateform.get('title').reset();
    this.filteredLabelDispatchData();
  }

  sub_names = '';
  getsubnames(event) {
    this.sub_names = event.value;
    // console.log(sub_name);
    // this.filteredLabelDispatchData();
  }

  reset_sub_names() {
    this.sub_names = '';
    this.generateform.get('subname').reset();
    this.filteredLabelDispatchData();
  }

  sub_types = '';
  getsubtypes(event) {
    this.sub_types = event.value;
    if (this.sub_types != '') {
      this.FilteredlabelsforDispatch = this.labelsforDispatch.filter((a) => {
        return this.sub_types.includes(a['Subscription_Type']);
      });
    }
  }

  reset_sub_types() {
    this.sub_types = '';
    this.generateform.get('subtype').reset();
    if (this.sub_types == '') {
      this.FilteredlabelsforDispatch = this.labelsforDispatch;
    }
  }

  filteredData() {
    if (
      (this.getbookrcptStartDate != '' && this.getbookrcptEndDate != '') ||
      this.title1 != '' ||
      this.state_names != '' ||
      this.sub_names != '' ||
      this.sub_types != '' ||
      this.country_names != '' ||
      this.district_names != '' ||
      this.taluka_names != '' ||
      this.pincodes != '' ||
      this.repnames != '' ||
      this.officerepnames != '' ||
      this.mode_names != ''
    ) {
      this.FilteredlabelsforDispatch = this.labelsforDispatch.filter((a) => {
        let dateCondition = true;

        if (this.getbookrcptStartDate != '' && this.getbookrcptEndDate != '') {
          dateCondition =
            new Date(a['entry_date']) >= new Date(this.getbookrcptStartDate) &&
            new Date(a['entry_date']) <= new Date(this.getbookrcptEndDate);
        }

        let titleCondition = true;
        if (this.title1 != '') {
          titleCondition = this.title1.includes(a['title']);
        }

        let stateCondition = true;
        if (this.state_names != '') {
          stateCondition = this.state_names.includes(a['state']);
        }

        let subCondition = true;
        if (this.sub_names != '') {
          subCondition = this.sub_names.includes(a['sub']);
        }

        let subTypeCondition = true;
        if (this.sub_types != '') {
          subTypeCondition = this.sub_types.includes(a['Subscription_Type']);
        }

        let countryCondition = true;
        if (this.country_names != '') {
          countryCondition = this.country_names.includes(a['country']);
        }

        let distCondition = true;
        if (this.district_names != '') {
          distCondition = this.district_names.includes(a['district']);
        }

        let talukaCondition = true;
        if (this.taluka_names != '') {
          talukaCondition = this.taluka_names.includes(a['taluka']);
        }

        let pincodesCondition = true;
        if (this.pincodes != '') {
          pincodesCondition = this.pincodes.includes(a['pincode']);
        }

        let repnamesCondition = true;
        if (this.repnames != '') {
          repnamesCondition = this.repnames.includes(a['rep_name']);
        }

        let officerepnamesCondition = true;
        if (this.officerepnames != '') {
          officerepnamesCondition = this.officerepnames.includes(a['office_rep_name']);
        }

        let modetypeCondition = true;
        if (this.mode_names != '') {
          modetypeCondition = this.mode_names.includes(a['delivery_mode']);
          // this.mode_names.includes(a['delivery_mode'])
        }

        // Add more conditions for other fields as needed

        return (
          dateCondition &&
          titleCondition &&
          stateCondition &&
          subCondition &&
          subTypeCondition &&
          countryCondition &&
          distCondition &&
          talukaCondition &&
          pincodesCondition &&
          repnamesCondition &&
          officerepnamesCondition &&
          modetypeCondition
          // Add more conditions here
        );
        // Add more conditions here
      });
    } else {
      // Handle other cases where no specific filters are applied
      this.FilteredlabelsforDispatch = this.labelsforDispatch;
    }
    console.log('FilteredlabelsforDispatch', this.FilteredlabelsforDispatch);
  }

  filteredLabelDispatchData() {
    debugger;
    if (
      (this.getbookrcptStartDate != '' && this.getbookrcptEndDate != '') ||
      this.title1 != '' ||
      this.state_names != '' ||
      this.sub_names != '' ||
      this.sub_types != '' ||
      this.country_names != '' ||
      this.district_names != '' ||
      this.taluka_names != '' ||
      this.pincodes != '' ||
      this.repnames != '' ||
      this.officerepnames != '' ||
      this.mode_names != ''
    ) {
      this.FilteredlabelsforDispatch = this.labelsforDispatch.filter((a) => {
        let dateCondition = true;
        
        if (this.getbookrcptStartDate != '' && this.getbookrcptEndDate != '') {
          dateCondition =
            new Date(a['entry_date']) >= new Date(this.getbookrcptStartDate) &&
            new Date(a['entry_date']) <= new Date(this.getbookrcptEndDate);
        }

        let titleCondition = true;
        if (this.title1 != '') {
          titleCondition = this.title1.includes(a['title']);
        }

        let stateCondition = true;
        if (this.state_names != '') {
          stateCondition = this.state_names.includes(a['state']);
        }

        let subCondition = true;
        if (this.sub_names != '') {
          subCondition = this.sub_names.includes(a['sub']);
        }

        let subTypeCondition = true;
        if (this.sub_types != '') {
          subTypeCondition = this.sub_types.includes(a['Subscription_Type']);
        }

        let countryCondition = true;
        if (this.country_names != '') {
          countryCondition = this.country_names.includes(a['country']);
        }

        

        let distCondition = true;
        if (this.district_names != '') {
          distCondition = this.district_names.includes(a['district']);
        }

        let talukaCondition = true;
        if (this.taluka_names != '') {
          talukaCondition = this.taluka_names.includes(a['taluka']);
        }

        let pincodesCondition = true;
        if (this.pincodes != '') {
          pincodesCondition = this.pincodes.includes(a['pincode']);
        }

        let repnamesCondition = true;
        if (this.repnames != '') {
          debugger;
          repnamesCondition = this.repnames.includes(a['rep_name']);
        }

        let officerepnamesCondition = true;
        if (this.officerepnames != '') {
          officerepnamesCondition = this.officerepnames.includes(a['office_rep_name']);
        }
        let modetypeCondition = true;
        if (this.mode_names != '') {
          modetypeCondition = this.mode_names.includes(a['delivery_mode']);
          // this.mode_names.includes(a['delivery_mode'])
        }

        // let issueCondition = true;
        // if (this.issue_date != '') {
        //   issueCondition = new Date(a['issue_date']) == new Date(formatDate(
        //     new Date(this.issue_date),
        //     'yyyy-MM-dd',
        //     'en-IN',
        //     'GMT+0530'
        //   ));

        //   // this.mode_names.includes(a['delivery_mode'])
        // }

        // Add more conditions for other fields as needed

        return (
          dateCondition &&
          titleCondition &&
          stateCondition &&
          subCondition &&
          subTypeCondition &&
          countryCondition &&
          distCondition &&
          talukaCondition &&
          pincodesCondition &&
          repnamesCondition &&
          officerepnamesCondition &&
          modetypeCondition
          // Add more conditions here
        );
        // Add more conditions here
      });
     
    } else {
      // Handle other cases where no specific filters are applied
      this.FilteredlabelsforDispatch = this.labelsforDispatch;
    }
    console.log('FilteredlabelsforDispatch', this.FilteredlabelsforDispatch);
    this.FilteredlabelsforDispatch = this.FilteredlabelsforDispatch.filter((item, index, self) =>
      index === self.findIndex((t) => t.subno === item.subno)
  );

  }
  searchdeatils() {
    

    if (
      ((this.enddate_registration != "" || this.enddate_registration != undefined)) ||
      (this.subdetailsform.value.cust_id != "" || this.subdetailsform.value.cust_id != undefined) ||
      (this.subdetailsform.value.rcpt_no != "" || this.subdetailsform.value.rcpt_no != undefined) ||
      (this.subdetailsform.value.sub_name != "" || this.subdetailsform.value.sub_name != undefined) ||
      (this.subdetailsform.value.rep != "" || this.subdetailsform.value.rep != undefined) ||
      (this.subdetailsform.value.off_rep != "" || this.subdetailsform.value.off_rep != undefined) ||
      (this.subdetailsform.value.v_no != "" || this.subdetailsform.value.v_no != undefined) ||
      (this.subdetailsform.value.active_inactive != "" || this.subdetailsform.value.active_inactive != undefined) ||
      (this.subdetailsform.value.hastetapal != "" || this.subdetailsform.value.hastetapal != undefined) || 
      (this.subdetailsform.value.dist_name != "" || this.subdetailsform.value.dist_name != undefined) ||
      (this.subdetailsform.value.subtype != "" || this.subdetailsform.value.subtype != undefined) ||
      (this.subdetailsform.value.tal_name != "" || this.subdetailsform.value.tal_name != undefined) ||
      (this.subdetailsform.value.prant_name != "" || this.subdetailsform.value.prant_name != undefined) ||
      (this.subdetailsform.value.country != "" || this.subdetailsform.value.country != undefined) ||
      (this.subdetailsform.value.state != "" || this.subdetailsform.value.state != undefined) 

    ) {
      
      let list = this.getSubDetailsData.filter((a) => {
        
        let dateCondition = true;
        if (this.startDate_registration != "" && this.enddate_registration != "") {
          dateCondition =
            new Date(a['sub_perid_to_date']) >= new Date(this.startDate_registration) &&
            new Date(a['sub_perid_to_date']) <= new Date(this.enddate_registration);
        }
        debugger;

        let countryCondition = true;
        if (this.subdetailsform.value.country != "") {
          countryCondition = (a['country'] == this.subdetailsform.value.country);
        }

        let stateCondition = true;
        if (this.subdetailsform.value.state != "") {
          stateCondition = (a['state'] == this.subdetailsform.value.state);
        }

        let subnoCondition = true;
        if (this.subdetailsform.value.cust_id != "") {
          subnoCondition = (a['subno'] == this.subdetailsform.value.cust_id);
        }

        let repnoCondition = true;
        if (this.subdetailsform.value.rcpt_no != "") {
          repnoCondition = (a['rcpt_no'] == this.subdetailsform.value.rcpt_no);
        }

        let subnameCondition = true;
        if (this.subdetailsform.value.sub_name != "") {
          subnameCondition = (a['name'] == this.subdetailsform.value.sub_name);
        }

        let repnameCondition = true;
        if (this.subdetailsform.value.rep != "") {
          repnameCondition = (a['rep_name'] == this.subdetailsform.value.rep);
        }

        let offrepCondition = true;
        if (this.subdetailsform.value.off_rep != "") {
          offrepCondition = (a['office_rep_name'] == this.subdetailsform.value.off_rep);
        }

        let vouchernoCondition = true;
        if (this.subdetailsform.value.v_no != "") {
          vouchernoCondition = (a['crdt_voucher_no'] == this.subdetailsform.value.v_no);
        }

        let activeinactiveCondition = true;
        if (this.subdetailsform.value.active_inactive != "") {
          activeinactiveCondition = (a['is_active'] == this.subdetailsform.value.active_inactive);
        }

        let hastetapalCondition = true;
        if (this.subdetailsform.value.hastetapal != "") {
          hastetapalCondition = (a['delivery_mode'] == this.subdetailsform.value.hastetapal);
        }

        let distCondition = true;  
        if (this.subdetailsform.value.dist_name != "") {
          distCondition = (a['district'] == this.subdetailsform.value.dist_name);
        }

        let subtypeCondition = true;  
        if (this.subdetailsform.value.subtype != "") {
          subtypeCondition = (a['subscription_type'] == this.subdetailsform.value.subtype);
        }

        
        let talukaCondition = true;  
        if (this.subdetailsform.value.tal_name != "") {
          talukaCondition = (a['taluka'] == this.subdetailsform.value.tal_name);
        }

        let prantCondition = true;  
        if (this.subdetailsform.value.prant_name != "") {
          prantCondition = (a['prant'] == this.subdetailsform.value.prant_name);
        }

        return (
          dateCondition &&
          subnoCondition &&
          repnoCondition &&
          subnameCondition &&
          repnameCondition &&
          offrepCondition &&
          vouchernoCondition &&
          activeinactiveCondition &&
          hastetapalCondition &&
          distCondition &&
          subtypeCondition &&
          talukaCondition &&
          prantCondition &&
          countryCondition &&
          stateCondition
          // Add more conditions here
          
        );

      });
      console.log(list, "subscriber details1")
      this.subscriberlistbrief = list
      console.log(this.dataSource, "subscriber details")

      this.dataSource = new MatTableDataSource(
        list
      );
      // this.dataSource.paginator = this.paginator;
      for (
        let index = 0;
        index < Object.keys(list).length;
        index++
      ) {
        this.totalamountofsub =
          this.totalamountofsub +
          parseInt(list[index]['total_amt']);
        this.totaldiscountofsub =
          this.totaldiscountofsub +
          parseInt(list[index]['discount']);
        this.totalnetamtofsub =
          this.totalnetamtofsub +
          parseInt(list[index]['net_amt']);
        this.totalrepcomofsub =
          this.totalrepcomofsub +
          parseInt(list[index]['rep_com_amt']);
        this.sub_rep_data.push({
          rep_name: list[index]['rep_name'],
        });
        this.sub_off_rep_data.push({
          office_rep_name: list[index]['office_rep_name'],
        });
        this.sub_v_no_data.push({
          crdt_voucher_no: list[index]['crdt_voucher_no'],
        });
        this.sub_rcpt_no_data.push({
          rcpt_no: list[index]['rcpt_no'],
        });
        this.sub_sub_no_data.push({
          subno: list[index]['subno'],
        });

        this.sub_sub_name_data.push({
          name: list[index]['name'],
        });
      }
      this.sub_rep_data = this.uniqueid(
        this.sub_rep_data,
        (it) => it.rep_name
      );
      this.sub_off_rep_data = this.uniqueid(
        this.sub_off_rep_data,
        (it) => it.office_rep_name
      );
      this.sub_v_no_data = this.uniqueid(
        this.sub_v_no_data,
        (it) => it.crdt_voucher_no
      );

      this.sub_sub_name_data = this.uniqueid(
        this.sub_sub_name_data,
        (a) => a.name
      );

      this.total_no_data = Object.keys(list).length;

    }
  }

  subscriberreport(){
    this.searchdeatils()
    this.dialog.open(SubscriberdetailsbriefComponent, {
      data: {
        subscriberbrieflist: this.subscriberlistbrief,
      },
      width: '1200px',
      height: '1300px',
      maxWidth: '100%',
    });
  }

  country_names = '';
  getcountryname(event) {
    this.country_names = event.value;
    console.log(this.country_names);
    // this.filteredLabelDispatchData();
  }

  reset_country_names() {
    this.country_names = '';
    this.generateform.get('countryname').reset();
    this.filteredLabelDispatchData();
  }

  state_names = '';
  getstatename(event) {
    console.log(event.value);

    this.state_names = event.value;
    let state = this.states.find((a) =>
      this.state_names.includes(a.state_name)
    );
    console.log(state);

    this.service.getDistrict(parseInt(state['state_id'])).subscribe((data) => {
      this.cities = data;
      // this.selectedCity = this.cities['district_name'];
      // console.log('cities', this.cities);
    });


  }

  reset_state_names() {
    this.state_names = '';
    this.generateform.get('statename').reset();
    this.filteredLabelDispatchData();
  }

  district_names = '';
  getdistrictname(event) {
    this.district_names = event.value;

  }

  reset_district_names() {
    this.district_names = '';
    this.generateform.get('districtname').reset();
    this.filteredLabelDispatchData();
  }

  taluka_names = '';
  gettalukaname(event) {
    this.taluka_names = event.value;
    this.filteredLabelDispatchData();
    this.service.pincodebttaluka(this.taluka_names).subscribe((data) => {
      this.pindcodelist = data
    });
  }

  reset_taluka_names() {
    this.taluka_names = '';
    this.generateform.get('talukaname').reset();
    this.filteredLabelDispatchData();
  }

  pincodes = '';
  getpincode(event) {
    this.pincodes = event.value;
    // this.filteredLabelDispatchData();
  }

  reset_pincodes() {
    this.pincodes = '';
    this.generateform.get('talukaname').reset();
    this.filteredLabelDispatchData();
  }

  repnames = '';
  getrep(event) {
    // console.log(event.value.length);
    if (event.value.length <= 1) {
      this.person_name = event.value[0];
      // console.log(this.person_name);
    } else {
      this.person_name = 'प्रतिनिधीनुसार';
    }
    this.repnames = event.value;
    // this.filteredLabelDispatchData();
  }

  reset_repnames() {
    this.repnames = '';
    this.generateform.get('rep').reset();
    this.filteredLabelDispatchData();
  }

  officerepnames = '';
  getofficerep(event) {
    this.officerepnames = event.value;
    // this.filteredLabelDispatchData();
  }

  reset_office_rep_names() {
    this.officerepnames = '';
    this.generateform.get('officerep').reset();
    this.filteredLabelDispatchData();
  }

  show_mob_no = '';
  show_mob_no_hide = false;
  getmob_no(event) {
    // console.log(event.value);
    this.show_mob_no = event.value;
    if (this.show_mob_no == 'yes') {
      this.show_mob_no_hide = true;
    } else if (this.show_mob_no == 'no') {
      this.show_mob_no_hide = false;
    }
  }

  getIsCustomer(e: any) {
    this.iscustomer = e.value
  }

  mode_names = '';
  getmode_name(event) {
    this.mode_names = event.value;
    // this.filteredLabelDispatchData();
  }

  reset_delivery_mode_names() {
    this.mode_names = '';
    this.generateform.get('delivery_mode_name').reset();
    this.filteredData();
  }

  cancelMagList = [];
  getcancelmag(event) {
    // console.log(event);
    // console.log(event.target.value);
    let index = event.target.value;
    // console.log(this.cancelCheckbox[index]);
    // console.log(
    //   this.cancelMagList.find(
    //     (a) =>
    //       a['name'] == this.cancelCheckbox[index]['name'] &&
    //       a['edition'] == this.cancelCheckbox[index]['edition']
    //   )
    // );
    if (
      this.cancelMagList.find(
        (a) =>
          a['name'] == this.cancelCheckbox[index]['name'] &&
          a['edition'] == this.cancelCheckbox[index]['edition']
      ) == undefined
    ) {
      this.cancelMagList.push(this.cancelCheckbox[index]);
    }
  }

  disrepnames = '';
  getdistributionrep(event) {
    debugger
    this.dist_rep_name = event.value
    // console.log(event.value.length);
    if (event.value.length <= 1) {
      this.person_name = event.value[0];
      // console.log(this.person_name);
    } else {
      this.person_name = 'प्रतिनिधीनुसार';
    }
    this.disrepnames = event.value;
    if (this.disrepnames != '') {
      this.FilteredlabelsforDispatch = this.labelsforDispatch.filter((a) => {
        return (
          this.disrepnames.includes(a['distribution_rep']) &&
          a['delivery_mode'] === 'हस्ते'
        );
      });
      this.getFilteredlabelslistfordispatch();
    }
  }

  reset_distribution_rep_names() {
    debugger;
    this.disrepnames = '';
    this.dist_rep_name = '';
    this.distributionForm.get('disrep').reset();
    if (this.disrepnames == '') {
      this.FilteredlabelsforDispatch = this.labelsforDispatch;
      this.getFilteredlabelslistfordispatch();
    }
  }

  FilteredLabelsListForDispatch = [];
  getFilteredlabelslistfordispatch() {
    if (
      this.disrepnames != '' &&
      this.subdismode != '' &&
      this.dismode1 != ''
    ) {
      this.FilteredLabelsListForDispatch = this.labelsforDispatch.filter(
        (a) => {
          return (
            this.disrepnames.includes(a['distribution_rep']) &&
            a['dis_sub_mode'] == this.subdismode &&
            a['dis_mode'] == this.dismode1 &&
            a['delivery_mode'] === 'हस्ते'
          );
        }
      );
      // console.log(this.FilteredLabelsListForDispatch);
    } else if (
      this.disrepnames != '' &&
      this.dismode1 != '' &&
      this.subdismode == ''
    ) {
      this.FilteredLabelsListForDispatch = this.labelsforDispatch.filter(
        (a) => {
          return (
            this.disrepnames.includes(a['distribution_rep']) &&
            a['dis_mode'] == this.dismode1 &&
            a['delivery_mode'] === 'हस्ते'
          );
        }
      );
      // console.log(this.FilteredLabelsListForDispatch);
    } else if (
      this.disrepnames != '' &&
      this.dismode1 == '' &&
      this.subdismode == ''
    ) {
      this.FilteredLabelsListForDispatch = this.labelsforDispatch.filter(
        (a) => {
          return (
            this.disrepnames.includes(a['distribution_rep']) &&
            a['delivery_mode'] === 'हस्ते'
          );
        }
      );
      // console.log(this.FilteredLabelsListForDispatch);
    } else {
      this.FilteredLabelsListForDispatch = this.labelsforDispatch.filter(
        (a) => {
          return a['delivery_mode'] === 'हस्ते';
        }
      );
      // console.log(this.FilteredLabelsListForDispatch);
    }
  }

  submitForm() {
    let mobile = parseInt(this.form1.value.mobileNumber);
    this.form1.patchValue({ mobileNumber: mobile });
    for (
      let index = 0;
      index < Object.keys(this.cancelMagList).length;
      index++
    ) {
      this.form1.get('cancelmags' + index).setValue(this.cancelMagList[index]);
    }
    // console.log(this.form1.value);
    this.cancelMagList = [];
    this.disableOtherFields = true;
    this.open_cancel_subsciber_dialog(this.form1.value);

    this.form1.reset();
    this.nochangenumber = false;
  }

  open_cancel_subsciber_dialog(value) {
    let mobile = parseInt(value.mobileNumber);
    var sixdigitsrandom = Math.floor(100000 + Math.random() * 900000);
    // console.log(value);

    let msg =
      'Dear Subscriber, Your Verification Code is ' +
      sixdigitsrandom +
      ', Regards Hindustan Prakashan Sanstha.';
    let template = 'VIVEK1';

    this.service
      .sendSMS(parseInt(sessionStorage.getItem('mobile')), msg, template)
      .subscribe((_a) => { });

    this.dialog.open(CancelSubDialogComponent, {
      data: {
        message: sixdigitsrandom,
        magdata: value,
        height: '600px',
        width: '600px',
      },
    });
  }

  validatefname = false;
  validateName(_event) {
    if (this.subform.get('name').valid) {
      this.validatefname = true;
    } else {
      this.validatefname = false;
    }
  }

  getPincode(_event) {
    if (this.subform.get('pincode').valid) {
      // console.log(this.ionicForm.get('pincode').value);
      this.service
        .getPincodeData(parseInt(this.subform.get('pincode').value))
        .subscribe((data) => {
          // console.log('pincode data', data);
          this.getCountry({ value: data[0]['country_name'] });
          setTimeout(() => {
            this.getState({ value: data[0]['state_name'] });
          }, 100);
          setTimeout(() => {
            this.getDistricts({ value: data[0]['district_name'] });
          }, 500);
          this.subform.get('country').setValue(data[0]['country_name']);
          this.subform.get('state').setValue(data[0]['state_name']);
          this.subform.get('district').setValue(data[0]['district_name']);
          setTimeout(() => {
            this.subform.get('taluka').setValue(data[0]['taluka_name']);
          }, 500);
          // this.selectedCity = data[0]['district_name'];
          // this.selectedState = data[0]['state_name'];
          // this.selectedTaluka = data[0]['taluka_name'];
        });
      // this.service
      //   .getPincodeDetails(parseInt(this.subform.get('pincode').value))
      //   .subscribe((data) => {
      //     // console.log(data);
      //     this.subform
      //       .get('taluka')
      //       .setValue(data[0]['PostOffice'][0]['Block']);
      //     this.getCountry({ value: data[0]['PostOffice'][0]['Country'] });
      //     this.subform
      //       .get('district')
      //       .setValue({ name: data[0]['PostOffice'][0]['District'] });
      //     this.subform
      //       .get('state')
      //       .setValue({ state_name: data[0]['PostOffice'][0]['State'] });
      //     this.subform
      //       .get('country')
      //       .setValue(data[0]['PostOffice'][0]['Country']);
      //     this.selectedCity = data[0]['PostOffice'][0]['District'];
      //     this.selectedState = data[0]['PostOffice'][0]['State'];
      //     // console.log(this.selectedCity, this.selectedState);
      //   });
    }
  } //getPincode

  getCountry(event) {
    debugger
    // console.log(event);
    let country = this.countries.find((a) => a.country_name == event.value);
    // console.log(country);
    debugger
    if (country != undefined) {
      this.service.getState(country['country_code']).subscribe((data) => {
        this.states = data;
      });
    } else {
    }
    console.log(this.states)
  } //getcountry

  showigst = false;

  getState(event) {
    // console.log(event);
    // console.log(this.states);
    this.selectedState = this.states['state_id'];
    let state = this.states.find((a) => a.state_name === event.value);
    // console.log(state);
    if (state != undefined) {
      if (
        state.state_name != 'Maharashtra' ||
        state.state_name != 'महाराष्ट्र'
      ) {
        this.showigst = true;
      } else {
        this.showigst = false;
      }

      this.service
        .getDistrict(parseInt(state['state_id']))
        .subscribe((data) => {
          this.cities = data;
          this.selectedCity = this.cities['district_name'];
          // console.log('cities', this.cities);
        });
    } else {
    }
  } //get state

  getDistricts(event) {
    let distname = event.value + '';
    this.selectedCity = this.cities['district_id'];
    // let district = this.cities.find((a) => a.district_name === distname.trim());
    let districtNames = distname.split(',');

    // Trim each district name
    districtNames = districtNames.map(name => name.trim());
    
    // Find the city object that matches any of the district names
    // let district = this.cities.find(city => districtNames.includes(city.district_name));
    let district =[]
    this.cities.forEach(city => {
     districtNames.forEach(element => {
      if (city.district_name==element){
        district.push(city.district_id)
      }
     });
    });
    if (district != undefined) {
      const req={
        "district_id":district
      }
      
      this.service.getTaluka(district).subscribe((data) => {
        this.talukas = data;
        this.selectedTaluka = this.talukas['taluka_name'];
        console.log( this.talukas, this.selectedCity, " this.talukas")
      });
    }
  }

  getDistricts1(event) {
    debugger
    let distname = event.value + '';
    this.selectedCity = this.cities['district_id'];
    // let district = this.cities.find((a) => a.district_name === distname.trim());
    let districtNames = distname.split(',');

    // Trim each district name
    districtNames = districtNames.map(name => name.trim());
    
    // Find the city object that matches any of the district names
    // let district = this.cities.find(city => districtNames.includes(city.district_name));
    let district =[]
    this.distname.forEach(city => {
      if (city.district_name==districtNames[0]){
        district.push(city.id)
      }
    });
    if (district != undefined) {
      const req={
        "district_id":district
      }
      
      this.service.getTaluka(district).subscribe((data) => {
        this.talukas = data;
        this.selectedTaluka = this.talukas['taluka_name'];
        console.log( this.talukas, this.selectedCity, " this.talukas")
      });
    }
  }

  validmobile = false;
  validemail = false;
  validateMobile(_event) {
    if (this.subform.get('mobileNumber').valid) {
      this.validmobile = true;
    } else {
      this.validmobile = false;
    }
  }

  validateEmail(_event) {
    if (this.subform.get('email').valid) {
      this.validemail = true;
    } else {
      this.validemail = false;
    }
  }

  validdoa = false;
  validdob = false;
  validgender = false;
  validatedoa(event) {
    this.subform
      .get('doa')
      .setValue(
        formatDate(
          new Date(event.target.value),
          'yyyy-MM-dd',
          'en-IN',
          'GMT+0530'
        )
      );
    if (this.subform.get('doa').valid) {
      this.validdoa = true;
    } else {
      this.validdoa = false;
    }
  }

  validatedob(event) {
    this.subform
      .get('dob')
      .setValue(
        formatDate(
          new Date(event.target.value),
          'yyyy-MM-dd',
          'en-IN',
          'GMT+0530'
        )
      );
    if (this.subform.get('dob').valid) {
      this.validdob = true;
    } else {
      this.validdob = false;
    }
  }

  validategender(_event) {
    if (this.subform.get('gender').valid) {
      this.validgender = true;
    } else {
      this.validgender = false;
    }
  }

  validbusinessname = false;
  validbusinessaddress = false;
  validbusinessemail = false;
  validbusinessphone = false;

  validatebussiness(_event) {
    if (this.subform.get('businessname').valid) {
      this.validbusinessname = true;
    } else {
      this.validbusinessname = false;
    }
  }

  validateBAddress(_event) {
    if (this.subform.get('businessaddress').valid) {
      this.validbusinessaddress = true;
    } else {
      this.validbusinessaddress = false;
    }
  }

  validateBEmail(_event) {
    if (this.subform.get('businessemail').valid) {
      this.validbusinessemail = true;
    } else {
      this.validbusinessemail = false;
    }
  }

  validateBmobile(_event) {
    if (this.subform.get('businessphone').valid) {
      this.validbusinessphone = true;
    } else {
      this.validbusinessphone = false;
    }
  }

  validbusinesstype = false;

  checkBusiness(_event) {
    if (this.subform.get('businesstype').valid) {
      this.validbusinesstype = true;
    } else {
      this.validbusinesstype = false;
    }
  }

  validbcity = false;
  validbpin = false;

  validatebcity(_event) {
    if (this.subform.get('bcity').valid) {
      this.validbcity = true;
    } else {
      this.validbcity = false;
    }
  }

  validatebpincode(_event) {
    if (this.subform.get('bpin').valid) {
      this.validbpin = true;
    } else {
      this.validbpin = false;
    }
  }

  getSelectedRepId;
  getSelectedRep;
  // SelectRepresentative(event) {
  //   // console.log(event.value);
  //   this.getSelectedRepId = event.value;
  //   this.getSelectedRep = this.getAllRepList.find(
  //     (a) => a['id'] == parseInt(this.getSelectedRepId)
  //   );
  //   this.subform
  //     .get('rep_comm')
  //     .setValue(parseInt(this.getSelectedRep['rep_comm']));
  // }

  startDate_registration = '';
  enddate_registration = '';
  getChangedDateRegistration() {
    this.cust_mobile_filter = '';
    if (
      this.subdaterange.get('start').value != null &&
      this.subdaterange.get('end').value != null
    ) {
      let startdate = formatDate(
        new Date(this.subdaterange.get('start').value),
        'yyyy-MM-dd',
        'en-IN',
        'GMT+0530'
      );
      let endDate = formatDate(
        new Date(this.subdaterange.get('end').value),
        'yyyy-MM-dd',
        'en-IN',
        'GMT+0530'
      );
      this.startDate_registration = startdate;
      this.enddate_registration = endDate;

      //this.FilteredBookRegistration = this.bookregistrationdetails.filter(a => { return new Date(a['received_date']) >= new Date(startdate) && new Date(a['received_date']) <= new Date(endDate) });
      this.FilteredgetSubDetailsData = this.getSubDetailsData.filter((a) => {
        return (
          new Date(a['entry_date']) >= new Date(startdate) &&
          new Date(a['entry_date']) <= new Date(endDate)
        );
      });
      this.calculatesubdetailstotal();
    }
  }

  total_no_data = 0;
  calculatesubdetailstotal() {
    // this.dataSource = new MatTableDataSource(this.FilteredgetSubDetailsData);
    // this.dataSource.paginator = this.paginator;
    this.totalamountofsub = 0;
    this.totaldiscountofsub = 0;
    this.totalnetamtofsub = 0;
    this.totalrepcomofsub = 0;
    this.total_no_data = Object.keys(this.FilteredgetSubDetailsData).length;
    for (
      let index = 0;
      index < Object.keys(this.FilteredgetSubDetailsData).length;
      index++
    ) {
      this.totalamountofsub =
        this.totalamountofsub +
        parseInt(this.FilteredgetSubDetailsData[index]['total_amt']);
      this.totaldiscountofsub =
        this.totaldiscountofsub +
        parseInt(this.FilteredgetSubDetailsData[index]['discount']);
      this.totalnetamtofsub =
        this.totalnetamtofsub +
        parseInt(this.FilteredgetSubDetailsData[index]['net_amt']);
      this.totalrepcomofsub =
        this.totalrepcomofsub +
        parseInt(this.FilteredgetSubDetailsData[index]['rep_com_amt']);
    }
  }

  getcust_mobile_filter(_mobile) {
    // console.log(mobile, this.cust_mobile_filter);
    if (
      this.startDate_registration != null &&
      this.enddate_registration != null
    ) {
      this.startDate_registration = '';
      this.enddate_registration = '';
      this.FilteredgetSubDetailsData = this.getSubDetailsData.filter((a) => {
        return (
          new Date(a['entry_date']) >= new Date(this.startDate_registration) &&
          new Date(a['entry_date']) <= new Date(this.enddate_registration)
        );
      });
      this.calculatesubdetailstotal();
    }
  }

  reset_cust_mobile_filter(_event) {
    this.cust_mobile_filter = '';
  }

  getGstNumber(gst) {
    this.gstNumber = gst;
  }

  cust_mobile_filter = '';
  getCustDetails;
  getCustReport() {
    // console.log(
    //   this.startDate_registration,
    //   this.enddate_registration,
    //   this.cust_mobile_filter,
    //   this.cust_id
    // );
    if (this.cust_mobile_filter.length == 0 && this.cust_id == '') {
      this.service
        .getCustbymobileordate(
          this.startDate_registration,
          this.enddate_registration,
          777,
          777
        )
        .subscribe((data) => {
          // console.log(data);
          this.getCustDetails = data;
          if (Object.keys(data).length == 0) {
            window.alert(
              'No Subscriber found. Please try to add New Subscriber.'
            );
            this.displayNewSubscriber = true;
          } else {
            this.displayNewSubscriber = false;
          }
        });
    } else if (this.startDate_registration == '' && this.cust_id == '') {
      if (this.cust_mobile_filter.length == 10) {
        this.service
          .getCustbymobileordate('', '', this.cust_mobile_filter, 777)
          .subscribe((data) => {
            // console.log(data);
            this.getCustDetails = data;
            if (Object.keys(data).length == 0) {
              window.alert(
                'No Subscriber found. Please try to add New Subscriber.'
              );
              this.displayNewSubscriber = true;
            } else {
              this.displayNewSubscriber = false;
            }
          });
      } else {
        window.alert('Please Enter valid Mobile Number!');
      }
    } else if (
      this.cust_mobile_filter.length == 0 &&
      this.startDate_registration == ''
    ) {
      this.service
        .getCustbymobileordate('', '', 777, this.cust_id)
        .subscribe((data) => {
          // console.log(data);
          this.getCustDetails = data;
          if (Object.keys(data).length == 0) {
            window.alert(
              'No Subscriber found. Please try to add New Subscriber.'
            );
            this.displayNewSubscriber = true;
          } else {
            this.displayNewSubscriber = false;
          }
        });
    }
  }

  startDate_ReturnRcpt = '';
  enddate_ReturnRcpt = '';
  getChangedDateReturnRcpt() {
    if (
      this.rtnrcptdaterange.get('start').value != null &&
      this.rtnrcptdaterange.get('end').value != null
    ) {
      let startdate = formatDate(
        new Date(this.rtnrcptdaterange.get('start').value),
        'yyyy-MM-dd',
        'en-IN',
        'GMT+0530'
      );
      let endDate = formatDate(
        new Date(this.rtnrcptdaterange.get('end').value),
        'yyyy-MM-dd',
        'en-IN',
        'GMT+0530'
      );
      this.startDate_ReturnRcpt = startdate;
      this.enddate_ReturnRcpt = endDate;

      this.Filteredreturnrcptdata = this.returnrcptdata.filter((a) => {
        return (
          new Date(a['date']) >= new Date(startdate) &&
          new Date(a['date']) <= new Date(endDate)
        );
      });
    }
  }
  getChangedDateRcptLedger() {
    if (
      this.receiptLedger.get('start').value != null &&
      this.receiptLedger.get('end').value != null
    ) {
      this.sdate = formatDate(
        new Date(this.receiptLedger.get('start').value),
        'yyyy-MM-dd',
        'en-IN',
        'GMT+0530'
      );
      this.edate = formatDate(
        new Date(this.receiptLedger.get('end').value),
        'yyyy-MM-dd',
        'en-IN',
        'GMT+0530'
      );
      console.log(this.receiptledger, 'this.receiptledger')
      // this.FiletredreceiptledgerData = this.receiptledger.filter((a) => {
      //   console.log(
      //     new Date(a['entry_date']) >= new Date(this.sdate) &&
      //     new Date(a['entry_date']) <= new Date(this.edate))
      //   return (
      //     new Date(a['entry_date']) >= new Date(this.sdate) &&
      //     new Date(a['entry_date']) <= new Date(this.edate)
      //   );
      // });
      // this.service
      //     .getReceiptLedgerData(this.sdate, this.edate, this.cust_name)
      //     .subscribe((data) => {
      //       this.FiletredreceiptledgerData = data;
      //     });

    }
    // this.selectSubDate()
  }
  rcptldgrstartdate1='';
  rcptldgrenddate1 = ''
  getChangedDateRcptLedger1() {
    if (
      this.paymentreceiptledger.get('start').value != null &&
      this.paymentreceiptledger.get('end').value != null
    ) {
      this.rcptldgrstartdate1 = formatDate(
        new Date(this.paymentreceiptledger.get('start').value),
        'yyyy-MM-dd',
        'en-IN',
        'GMT+0530'
      );
      this.rcptldgrenddate1 = formatDate(
        new Date(this.paymentreceiptledger.get('end').value),
        'yyyy-MM-dd',
        'en-IN',
        'GMT+0530'
      );
      console.log(this.paymentreceiptledger, 'this.receiptledger')
    

    }
  }

  sub_name = '';
  get_sub_name(event, option) {
    if (event.isUserInput) {
      this.sub_name = option['name'];
      if (this.startDate_ReturnRcpt != '' && this.enddate_ReturnRcpt != '') {
        this.Filteredreturnrcptdata = this.returnrcptdata.filter((a) => {
          return (
            new Date(a['date']) >= new Date(this.startDate_ReturnRcpt) &&
            new Date(a['date']) <= new Date(this.enddate_ReturnRcpt) &&
            a['name'].toLowerCase().indexOf(this.sub_name.toLowerCase()) > -1
          );
        });
      } else {
        this.Filteredreturnrcptdata = this.returnrcptdata.filter((a) => {
          return (
            a['name'].toLowerCase().indexOf(this.sub_name.toLowerCase()) > -1
          );
        });
      }
    }
  }

  reset_sub_name() {
    this.sub_name = '';
    this.returnrcptform.get('subname').reset();
    if (
      this.startDate_ReturnRcpt != '' &&
      this.enddate_ReturnRcpt != '' &&
      this.sub_name == ''
    ) {
      this.Filteredreturnrcptdata = this.returnrcptdata.filter((a) => {
        return (
          new Date(a['date']) >= new Date(this.startDate_ReturnRcpt) &&
          new Date(a['date']) <= new Date(this.enddate_ReturnRcpt)
        );
      });
    } else {
      this.Filteredreturnrcptdata = this.returnrcptdata;
    }
  }

  rep2 = '';
  get_rtn_rep(event, option) {
    if (event.isUserInput) {
      this.rep2 = option['rep_name'];
      if (this.startDate_ReturnRcpt != '' && this.enddate_ReturnRcpt != '') {
        this.Filteredreturnrcptdata = this.returnrcptdata.filter((a) => {
          return (
            new Date(a['date']) >= new Date(this.startDate_ReturnRcpt) &&
            new Date(a['date']) <= new Date(this.enddate_ReturnRcpt) &&
            a['rep_name'].toLowerCase().indexOf(this.rep2.toLowerCase()) > -1
          );
        });
      } else {
        this.Filteredreturnrcptdata = this.returnrcptdata.filter((a) => {
          return (
            a['rep_name'].toLowerCase().indexOf(this.rep2.toLowerCase()) > -1
          );
        });
      }
    }
  }

  reset_rtn_rep() {
    this.rep2 = '';
    this.returnrcptform.get('rep').reset();
    if (
      this.startDate_ReturnRcpt != '' &&
      this.enddate_ReturnRcpt != '' &&
      this.rep2 == ''
    ) {
      this.Filteredreturnrcptdata = this.returnrcptdata.filter((a) => {
        return (
          new Date(a['date']) >= new Date(this.startDate_ReturnRcpt) &&
          new Date(a['date']) <= new Date(this.enddate_ReturnRcpt)
        );
      });
    } else {
      this.Filteredreturnrcptdata = this.returnrcptdata;
    }
  }

  off_rep1 = '';
  get_off_rep1(event, option) {
    if (event.isUserInput) {
      this.off_rep1 = option['office_rep_name'];
      if (this.startDate_ReturnRcpt != '' && this.enddate_ReturnRcpt != '') {
        this.Filteredreturnrcptdata = this.returnrcptdata.filter((a) => {
          return (
            new Date(a['date']) >= new Date(this.startDate_ReturnRcpt) &&
            new Date(a['date']) <= new Date(this.enddate_ReturnRcpt) &&
            a['office_rep_name']
              .toLowerCase()
              .indexOf(this.off_rep1.toLowerCase()) > -1
          );
        });
      } else {
        this.Filteredreturnrcptdata = this.returnrcptdata.filter((a) => {
          return (
            a['office_rep_name']
              .toLowerCase()
              .indexOf(this.off_rep1.toLowerCase()) > -1
          );
        });
      }
    }
  }

  reset_off_rep1() {
    this.off_rep1 = '';
    this.returnrcptform.get('off_rep').reset();
    if (
      this.startDate_ReturnRcpt != '' &&
      this.enddate_ReturnRcpt != '' &&
      this.off_rep1 == ''
    ) {
      this.Filteredreturnrcptdata = this.returnrcptdata.filter((a) => {
        return (
          new Date(a['date']) >= new Date(this.startDate_ReturnRcpt) &&
          new Date(a['date']) <= new Date(this.enddate_ReturnRcpt)
        );
      });
    } else {
      this.Filteredreturnrcptdata = this.returnrcptdata;
    }
  }

  district1 = '';
  get_district(event, option) {
    if (event.isUserInput) {
      this.district1 = option['district'];
      if (this.startDate_ReturnRcpt != '' && this.enddate_ReturnRcpt != '') {
        this.Filteredreturnrcptdata = this.returnrcptdata.filter((a) => {
          return (
            new Date(a['date']) >= new Date(this.startDate_ReturnRcpt) &&
            new Date(a['date']) <= new Date(this.enddate_ReturnRcpt) &&
            a['district'].toLowerCase().indexOf(this.district1.toLowerCase()) >
            -1
          );
        });
      } else {
        this.Filteredreturnrcptdata = this.returnrcptdata.filter((a) => {
          return (
            a['district'].toLowerCase().indexOf(this.district1.toLowerCase()) >
            -1
          );
        });
      }
    }
  }

  reset_district() {
    this.district1 = '';
    this.returnrcptform.get('district').reset();
    if (
      this.startDate_ReturnRcpt != '' &&
      this.enddate_ReturnRcpt != '' &&
      this.district1 == ''
    ) {
      this.Filteredreturnrcptdata = this.returnrcptdata.filter((a) => {
        return (
          new Date(a['date']) >= new Date(this.startDate_ReturnRcpt) &&
          new Date(a['date']) <= new Date(this.enddate_ReturnRcpt)
        );
      });
    } else {
      this.Filteredreturnrcptdata = this.returnrcptdata;
    }
  }

  taluka1 = '';
  get_taluka(event, option) {
    if (event.isUserInput) {
      this.taluka1 = option['taluka'];
      if (this.startDate_ReturnRcpt != '' && this.enddate_ReturnRcpt != '') {
        this.Filteredreturnrcptdata = this.returnrcptdata.filter((a) => {
          return (
            new Date(a['date']) >= new Date(this.startDate_ReturnRcpt) &&
            new Date(a['date']) <= new Date(this.enddate_ReturnRcpt) &&
            a['taluka'].toLowerCase().indexOf(this.taluka1.toLowerCase()) > -1
          );
        });
      } else {
        this.Filteredreturnrcptdata = this.returnrcptdata.filter((a) => {
          return (
            a['taluka'].toLowerCase().indexOf(this.taluka1.toLowerCase()) > -1
          );
        });
      }
    }
  }

  reset_taluka() {
    this.taluka1 = '';
    this.returnrcptform.get('taluka').reset();
    if (
      this.startDate_ReturnRcpt != '' &&
      this.enddate_ReturnRcpt != '' &&
      this.taluka1 == ''
    ) {
      this.Filteredreturnrcptdata = this.returnrcptdata.filter((a) => {
        return (
          new Date(a['date']) >= new Date(this.startDate_ReturnRcpt) &&
          new Date(a['date']) <= new Date(this.enddate_ReturnRcpt)
        );
      });
    } else {
      this.Filteredreturnrcptdata = this.returnrcptdata;
    }
  }

  state1 = '';
  get_state(event, option) {
    if (event.isUserInput) {
      this.state1 = option['state'];
      if (this.startDate_ReturnRcpt != '' && this.enddate_ReturnRcpt != '') {
        this.Filteredreturnrcptdata = this.returnrcptdata.filter((a) => {
          return (
            new Date(a['date']) >= new Date(this.startDate_ReturnRcpt) &&
            new Date(a['date']) <= new Date(this.enddate_ReturnRcpt) &&
            a['state'].toLowerCase().indexOf(this.state1.toLowerCase()) > -1
          );
        });
      } else {
        this.Filteredreturnrcptdata = this.returnrcptdata.filter((a) => {
          return (
            a['state'].toLowerCase().indexOf(this.state1.toLowerCase()) > -1
          );
        });
      }
    }
  }

  reset_state() {
    this.state1 = '';
    this.returnrcptform.get('state').reset();
    if (
      this.startDate_ReturnRcpt != '' &&
      this.enddate_ReturnRcpt != '' &&
      this.state1 == ''
    ) {
      this.Filteredreturnrcptdata = this.returnrcptdata.filter((a) => {
        return (
          new Date(a['date']) >= new Date(this.startDate_ReturnRcpt) &&
          new Date(a['date']) <= new Date(this.enddate_ReturnRcpt)
        );
      });
    } else {
      this.Filteredreturnrcptdata = this.returnrcptdata;
    }
  }

  country1 = '';
  get_country(event, option) {
    if (event.isUserInput) {
      this.country1 = option['country'];
      if (this.startDate_ReturnRcpt != '' && this.enddate_ReturnRcpt != '') {
        this.Filteredreturnrcptdata = this.returnrcptdata.filter((a) => {
          return (
            new Date(a['date']) >= new Date(this.startDate_ReturnRcpt) &&
            new Date(a['date']) <= new Date(this.enddate_ReturnRcpt) &&
            a['country'].toLowerCase().indexOf(this.country1.toLowerCase()) > -1
          );
        });
      } else {
        this.Filteredreturnrcptdata = this.returnrcptdata.filter((a) => {
          return (
            a['country'].toLowerCase().indexOf(this.country1.toLowerCase()) > -1
          );
        });
      }
    }
  }

  reset_country() {
    this.country1 = '';
    this.returnrcptform.get('country').reset();
    if (
      this.startDate_ReturnRcpt != '' &&
      this.enddate_ReturnRcpt != '' &&
      this.country1 == ''
    ) {
      this.Filteredreturnrcptdata = this.returnrcptdata.filter((a) => {
        return (
          new Date(a['date']) >= new Date(this.startDate_ReturnRcpt) &&
          new Date(a['date']) <= new Date(this.enddate_ReturnRcpt)
        );
      });
    } else {
      this.Filteredreturnrcptdata = this.returnrcptdata;
    }
  }

  /*name of the excel-file which will be downloaded. */
  fileName = 'ExcelSheet.xlsx';

  exportexcel(fileName): void {
    /* table id is passed over here */
    let element = document.getElementById('excel-table');
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* save to file */
    XLSX.writeFile(wb, fileName + '.xlsx');
  }

  exportexcel1(fileName): void {
    /* table id is passed over here */
    let element = document.getElementById('excel-table1');
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* save to file */
    XLSX.writeFile(wb, fileName + '.xlsx');
  }

  excelToExport(name) {
    const EXCEL_TYPE =
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const EXCEL_EXTENSION = '.xlsx';

    // custom code
    const worksheet = XLSX.utils.json_to_sheet(this.FilteredgetSubDetailsData);

    const workbook = {
      Sheets: {
        dataSheet: worksheet,
      },
      SheetNames: ['dataSheet'],
    };

    const excelBuffer = XLSX.write(workbook, {
      bookType: 'xlsx',
      type: 'array',
    });

    const blobData = new Blob([excelBuffer], { type: EXCEL_TYPE });

    fileSaver.saveAs(blobData, name);
  }

  excelToExportGSTReport(name) {
    const EXCEL_TYPE =
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const EXCEL_EXTENSION = '.xlsx';

    // custom code
    const worksheet = XLSX.utils.json_to_sheet(this.exportGSTReceiptData);

    const workbook = {
      Sheets: {
        dataSheet: worksheet,
      },
      SheetNames: ['dataSheet'],
    };

    const excelBuffer = XLSX.write(workbook, {
      bookType: 'xlsx',
      type: 'array',
    });

    const blobData = new Blob([excelBuffer], { type: EXCEL_TYPE });

    fileSaver.saveAs(blobData, name);
  }

  openSnackBar(message: string) {
    this._snackBar.open(message, '', {
      duration: 2000,
    });
  }

  update_tracking(ds_id) {
    // console.log(ds_id);
    this.dialog.open(UpdateTrackingComponent, {
      data: {
        ds_id: ds_id,
        tracking_details: true,
        start_date: false,
        end_date: false,
        edition: '',
        mobileNumber: '',
      },
    });

    this.dialog.afterAllClosed.subscribe((_result) => {
      //this.isLoginDialogOpen = false;
      this.openSnackBar('Refreshing the data');
      this.getCustReport();
      // console.log('The dialog was closed');
    });
  }

  block_subscriber(cust_id, status, mobile) {
    if (status == 'Y') {
      if (window.confirm('Do you really want to Unblock the User?')) {
        this.service
          .updateUserBlockingStatus(cust_id, status, parseInt(mobile))
          .subscribe((_data) => {
            this.getCustReport();
          });
      }
    } else {
      if (window.confirm('Do you really want to Unblock the User?')) {
        this.service
          .updateUserBlockingStatus(cust_id, status, parseInt(mobile))
          .subscribe((_data) => {
            this.getCustReport();
          });
      }
    }
  }

  Update_start_date(mobile, ds_id) {
    // console.log(mobile);
    // console.log(mobile);
    this.dialog.open(UpdateTrackingComponent, {
      data: {
        ds_id: ds_id,
        tracking_details: false,
        start_date: true,
        end_date: false,
        edition: 'Print',
        mobileNumber: parseInt(mobile),
      },
    });

    this.dialog.afterAllClosed.subscribe((_result) => {
      //this.isLoginDialogOpen = false;
      this.openSnackBar('Refreshing the data');
      this.getCustReport();
      // console.log('The dialog was closed');
    });
  }

  Update_end_date(mobile, ds_id) {
    // console.log(mobile);
    // console.log(mobile);
    this.dialog.open(UpdateTrackingComponent, {
      data: {
        ds_id: ds_id,
        tracking_details: false,
        start_date: false,
        end_date: true,
        edition: 'Print',
        mobileNumber: parseInt(mobile),
      },
    });

    this.dialog.afterAllClosed.subscribe((_result) => {
      //this.isLoginDialogOpen = false;
      this.openSnackBar('Refreshing the data');
      this.getCustReport();
      // console.log('The dialog was closed');
    });
  }

  startDateRepwise = '';
  endDateRepwise = '';
  getRepwiseReport() {
    this.cust_mobile_filter = '';
    if (
      this.rangerepwisereport.get('start').value != null &&
      this.rangerepwisereport.get('end').value != null
    ) {
      let startdate = formatDate(
        new Date(this.rangerepwisereport.get('start').value),
        'yyyy-MM-dd',
        'en-IN',
        'GMT+0530'
      );
      let endDate = formatDate(
        new Date(this.rangerepwisereport.get('end').value),
        'yyyy-MM-dd',
        'en-IN',
        'GMT+0530'
      );
      this.startDateRepwise = startdate;
      this.endDateRepwise = endDate;
    }
  }

  repwiseReportId;
  getSelectedRepdetails(event) {
    // console.log(event.value);
    this.repwiseReportId = parseInt(event.value);
    //let getSelectedRep = this.getAllRepList.find(a => a['id'] == parseInt(this.getSelectedRepId))
    //console.log(this.getSelectedRep)
  }

  repwiseReport = [];
  getRepWiseReport1() {
    this.service
      .readRepWiseReports(
        this.repwiseReportId,
        this.startDateRepwise,
        this.endDateRepwise
      )
      .subscribe((data) => {
        // console.log(data);
        this.repwiseReport = data;
      });
  }

  startDateRenewal = '';
  endDateRenewal = '';
  getrenewalDates() {
    this.cust_mobile_filter = '';
    if (
      this.renewalReport.get('start').value != null &&
      this.renewalReport.get('end').value != null
    ) {
      let startdate = formatDate(
        new Date(this.renewalReport.get('start').value),
        'yyyy-MM-dd',
        'en-IN',
        'GMT+0530'
      );
      let endDate = formatDate(
        new Date(this.renewalReport.get('end').value),
        'yyyy-MM-dd',
        'en-IN',
        'GMT+0530'
      );
      this.startDateRenewal = startdate;
      this.endDateRenewal = endDate;
    }
  }

  renewalReportdata;
  getRenewalReport() {
    if (
      this.repwiseReportId == '' ||
      this.repwiseReportId == 0 ||
      this.repwiseReportId == undefined
    ) {
      this.repwiseReportId = 777;
    }

    this.service
      .getSubRenewal(
        this.startDateRenewal,
        this.endDateRenewal,
        this.repwiseReportId
      )
      .subscribe((data) => {
        // console.log(data);
        this.renewalReportdata = data;
      });
  }

  startDateLabel = '';
  endDateLabel = '';
  getLabelDates() {
    if (
      this.generateform.get('start').value != null &&
      this.generateform.get('end').value != null
    ) {
      let startdate = formatDate(
        new Date(this.generateform.get('start').value),
        'yyyy-MM-dd',
        'en-IN',
        'GMT+0530'
      );
      let endDate = formatDate(
        new Date(this.generateform.get('end').value),
        'yyyy-MM-dd',
        'en-IN',
        'GMT+0530'
      );
      this.startDateLabel = startdate;
      this.endDateLabel = endDate;
    }
  }

  labelDetails = [];
  generateLabel() {
    this.service
      .getlabels(this.startDateLabel, this.endDateLabel)
      .subscribe((data) => {
        // console.log(data);
        //this.labelDetails = data

        //this.labelData = []
        for (let index = 0; index < Object.keys(data).length; index++) {
          //console.log(this.data5[index])
          if (data[index + 3] == undefined) {
            if (data[index + 2] == undefined) {
              if (data[index + 1] == undefined) {
                this.labelDetails.push({
                  data1: data[index],
                  data2: {
                    address: '',
                    ditrict: '',
                    name: '',
                    pincode: '',
                    state: '',
                    taluka: '',
                  },
                  data3: {
                    address: '',
                    ditrict: '',
                    name: '',
                    pincode: '',
                    state: '',
                    taluka: '',
                  },
                  data4: {
                    address: '',
                    ditrict: '',
                    name: '',
                    pincode: '',
                    state: '',
                    taluka: '',
                  },
                });
              } else {
                this.labelDetails.push({
                  data1: data[index],
                  data2: data[index + 1],
                  data3: {
                    address: '',
                    ditrict: '',
                    name: '',
                    pincode: '',
                    state: '',
                    taluka: '',
                  },
                });
              }
            } else {
              this.labelDetails.push({
                data1: data[index],
                data2: data[index + 1],
                data3: data[index + 2],
                data4: {
                  address: '',
                  ditrict: '',
                  name: '',
                  pincode: '',
                  state: '',
                  taluka: '',
                },
              });
            }
          } else {
            this.labelDetails.push({
              data1: data[index],
              data2: data[index + 1],
              data3: data[index + 2],
              data4: data[index + 3],
            });
          }

          if (index + 3 < Object.keys(data).length) {
            index = index + 3;
          } else if (index + 2 < Object.keys(data).length) {
            index = index + 2;
          } else if (index + 1 < Object.keys(data).length) {
            index = index + 1;
          } else {
            index = index;
          }
        }

        // console.log(this.labelDetails);
      });
  }

  getLabelforDispatch() {
    // let date = formatDate(new Date(), 'yyyy-MM-dd', 'en-IN', 'GMT+0530');
    let date = new Date();
    let weekfirstday = date.getDate() - date.getDay();
    let prevfirestday = date.getDate() - date.getDay();
    // console.log(
    //   formatDate(date.setDate(weekfirstday), 'yyyy-MM-dd', 'en-IN', 'GMT+0530')
    // );

    // console.log(
    //   formatDate(
    //     date.setDate(weekfirstday - 7),
    //     'yyyy-MM-dd',
    //     'en-IN',
    //     'GMT+0530'
    //   )
    // );

    // this.service
    //   .getlabelsforDispatch(
    //     formatDate(
    //       date.setDate(weekfirstday),
    //       'yyyy-MM-dd',
    //       'en-IN',
    //       'GMT+0530'
    //     ),
    //     formatDate(
    //       date.setDate(weekfirstday - 7),
    //       'yyyy-MM-dd',
    //       'en-IN',
    //       'GMT+0530'
    //     )
    //   )
    //   .subscribe((data) => {
    //     console.log(data);
    //     this.labelsforDispatch = data;
    //     this.FilteredlabelsforDispatch = this.labelsforDispatch;
    //     this.getDatafromLabelsDispatch();
    //   });

    this.getLabelforDispatchData(
      formatDate(date.setDate(weekfirstday), 'yyyy-MM-dd', 'en-IN', 'GMT+0530'),
      formatDate(
        date.setDate(prevfirestday - 7),
        'yyyy-MM-dd',
        'en-IN',
        'GMT+0530'
      )
    );
  }

  myFilter = (d: Date | null): boolean => {
    const day = (d || new Date()).getDay();
    // Prevent Saturday and Sunday from being selected.
    return (
      day !== 1 && day !== 2 && day !== 3 && day !== 4 && day !== 5 && day !== 6
    );
  };

  issuedate = '';
  getIssueDate(event) {
    // console.log('getIssueDAte', event.value);
    this.issuedate = formatDate(event.value, 'yyyy-MM-dd', 'en-IN', 'GMT+0530');
    // console.log(this.issuedate);
    const weekfirstday = event.value.getDate() - event.value.getDay();
    // console.log(formatDate(event.value, 'yyyy-MM-dd', 'en-IN', 'GMT+0530'));
    this.generateform
      .get('publisheddate')
      .setValue(
        formatDate(
          event.value.setDate(weekfirstday - 7),
          'yyyy-MM-dd',
          'en-IN',
          'GMT+0530'
        )
      );
    this.getLabelforDispatchData(
      formatDate(this.issuedate, 'yyyy-MM-dd', 'en-IN', 'GMT+0530'),
      formatDate(
        this.generateform.get('publisheddate').value,
        'yyyy-MM-dd',
        'en-IN',
        'GMT+0530'
      )
    );
  }

  getLabelforDispatchData(curweekfirstday, preweekfirstday) {
    this.service.getlabelsforDispatch(curweekfirstday, preweekfirstday)
      .subscribe((data) => {
        console.log(data, "labelsforDispatch");
        this.labelsforDispatch = data;
        this.FilteredlabelsforDispatch = this.labelsforDispatch;
        this.FilteredlabelsforPostStatement = this.labelsforDispatch;
        this.getDatafromLabelsDispatch();
        this.getFilteredlabelslistfordispatch();
      });

     this.databyfilter()
  }
  databyfilter(){
    const req1:any = {
      "preweekfirstday":this.issuedate,
      "dist_type":this.dis_mode,
      "sub_dist_type":this.sub_mode,
      "dist_rep":this.dist_rep_name,
      "dis_country":this.country_names,
      "dis_state":this.state_names,
      "rep_name":this.repnames,
      "off_rep_name": this.officerepnames,
      "district":this.district_names,
      "dis_tal": this.taluka_names,
      "delivery_mode":this.dev_mode,
      "dis_pin": this.pincodes,
      "title":this.title1,
      "subtype":this.sub_types, 
    }

    this.service
    .getnewapi1(this.issuedate)
    .subscribe((res) => {
      this.disRepData = res;
      console.log(this.disRepData, "this. repdata");
    });
  }

  databyfilteraddress(){
    const req1:any = {
      "issue_date":this.issuedate,
      "dist_type":this.dis_mode,
      "sub_dist_type":this.sub_mode,
      "dist_rep":this.dist_rep_name,
      "country":this.country_names,
      "state":this.state_names,
      "rep_name":this.repnames,
      "off_rep_name": this.officerepnames,
      "district":this.district_names,
      "taluka": this.taluka_names,
      "dev_mode":this.dev_mode,
      "pincode": this.pincodes,
      "title":this.title1,
      "subtype":this.sub_types, 
    }

    this.service.getSbhetdata(this.issuedate).subscribe((res) => {
      this.labeldata = res;
      console.log(this.disRepData, "this. label");
    });

    // this.service.getSbhetdata().subscribe((data) => {
    //   this.sbhetdata = data;
    //   console.log('issueSellerName of sbhet ', data);
    // });
  }

  databyfiltercount(){
    const req1:any = {
      "issue_date":this.issuedate,
      "dist_type":this.dis_mode,
      "sub_dist_type":this.sub_mode,
      "dist_rep":this.dist_rep_name,
      "country":this.country_names,
      "state":this.state_names,
      "rep_name":this.repnames,
      "off_rep_name": this.officerepnames,
      "district":this.district_names,
      "taluka": this.taluka_names,
      "dev_mode":this.dev_mode,
      "pincode": this.pincodes,
      "title":this.title1,
      "subtype":this.sub_types, 
    }

    this.service
    .getlabeldata(req1)
    .subscribe((res) => {
      this.labeldata = res;
      console.log(this.disRepData, "this. label");
    });
  }

  LabelsDispatchTitles = [];
  LabelsDispatchSubnames = [];
  LabelsDispatchSubtypes = [];
  // LabelsDispatchCountryName = [];
  // LabelsDispatchStateName = [];
  // LabelsDispatchDistrictName = [];
  // LabelsDispatchTalukaName = [];
  LabelsDispatchPincode = [];
  LabelsDispatchRepNames = [];
  LabelsDispatchOffRepNames = [];
  LabelsDispatchDisRepNames = [];
  LabelsDisModes = [];
  LabelsSubDisModes = [];
  FilteredLabelsSubDisModes = [];
  LabelsDispatchDeliveryModes = [];
  getDatafromLabelsDispatch() {
    for (
      let index = 0;
      index < Object.keys(this.FilteredlabelsforDispatch).length;
      index++
    ) {
      // if (this.FilteredlabelsforDispatch[index]['title'] != '') {
      this.LabelsDispatchTitles.push({
        title: this.FilteredlabelsforDispatch[index]['title'],
      });
      // }

      this.LabelsDispatchSubnames.push({
        sub_name: this.FilteredlabelsforDispatch[index]['name'],
      });

      this.LabelsDispatchSubtypes.push({
        sub_type: this.FilteredlabelsforDispatch[index]['Subscription_Type'],
      });

      // this.LabelsDispatchCountryName.push({
      //   country_name: this.FilteredlabelsforDispatch[index]['country'],
      // });

      // this.LabelsDispatchStateName.push({
      //   state_name: this.FilteredlabelsforDispatch[index]['state'],
      // });

      // this.LabelsDispatchDistrictName.push({
      //   district_name: this.FilteredlabelsforDispatch[index]['district'],
      // });

      // this.LabelsDispatchTalukaName.push({
      //   taluka_name: this.FilteredlabelsforDispatch[index]['taluka'],
      // });

      this.LabelsDispatchPincode.push({
        pincode: this.FilteredlabelsforDispatch[index]['pincode'],
      });

      this.LabelsDispatchRepNames.push({
        rep_name: this.FilteredlabelsforDispatch[index]['rep_name'],
      });

      this.LabelsDispatchOffRepNames.push({
        off_rep_name: this.FilteredlabelsforDispatch[index]['office_rep_name'],
      });

      // Step 1: Add items to LabelsDispatchDisRepNames
      this.LabelsDispatchDisRepNames.push({
        dis_rep_name: this.FilteredlabelsforDispatch[index]['distribution_rep'],
      });

     


      this.LabelsDisModes.push({
        dis_mode: this.FilteredlabelsforDispatch[index]['dis_mode'],
      });

      // if (this.FilteredlabelsforDispatch[index]['dis_mode'] != '') {
      //   this.LabelsDisModes.push({
      //     dis_mode: this.FilteredlabelsforDispatch[index]['dis_mode'],
      //   });
      // }

      // if (this.FilteredlabelsforDispatch[index]['dis_mode'] != '') {
      //   this.LabelsSubDisModes.push({
      //     dis_mode: this.FilteredlabelsforDispatch[index]['dis_mode'],
      //     sub_dis_mode: this.FilteredlabelsforDispatch[index]['dis_sub_mode'],
      //   });
      // }

      this.LabelsDispatchDeliveryModes.push({
        mode_name: this.FilteredlabelsforDispatch[index]['delivery_mode'],
      });
    }

    this.LabelsDispatchTitles = this.uniqueid(
      this.LabelsDispatchTitles,
      (a) => a['title']
    );

    this.LabelsDispatchSubnames = this.uniqueid(
      this.LabelsDispatchSubnames,
      (a) => a['sub_name']
    );

    this.LabelsDispatchSubtypes = this.uniqueid(
      this.LabelsDispatchSubtypes,
      (a) => a['sub_type']
    );
    this.LabelsDispatchSubtypes.sort((a, b) => a.sub_type.localeCompare(b.sub_type));
 

    // this.LabelsDispatchCountryName = this.uniqueid(
    //   this.LabelsDispatchCountryName,
    //   (a) => a['country_name']
    // );

    // this.LabelsDispatchStateName = this.uniqueid(
    //   this.LabelsDispatchStateName,
    //   (a) => a['state_name']
    // );

    // this.LabelsDispatchDistrictName = this.uniqueid(
    //   this.LabelsDispatchDistrictName,
    //   (a) => a['district_name']
    // );

    // this.LabelsDispatchTalukaName = this.uniqueid(
    //   this.LabelsDispatchTalukaName,
    //   (a) => a['taluka_name']
    // );

    this.LabelsDispatchPincode = this.uniqueid(
      this.LabelsDispatchPincode,
      (a) => a['pincode']
    );

    this.LabelsDispatchRepNames = this.uniqueid(
      this.LabelsDispatchRepNames,
      (a) => a['rep_name']
    );

    this.LabelsDispatchOffRepNames = this.uniqueid(
      this.LabelsDispatchOffRepNames,
      (a) => a['off_rep_name']
    );

    this.LabelsDispatchDisRepNames = this.uniqueid(
      this.LabelsDispatchDisRepNames,
      (a) => a['dis_rep_name']
    );

    this.LabelsDisModes = this.uniqueid(
      this.LabelsDisModes,
      (a) => a['dis_mode']
    );

    this.LabelsSubDisModes = this.uniqueid(
      this.LabelsSubDisModes,
      (a) => a['sub_dis_mode']
    );

    this.LabelsDispatchDeliveryModes = this.uniqueid(
      this.LabelsDispatchDeliveryModes,
      (a) => a['mode_name']
    );
    // console.log('running');

     // Step 2: Use a Map to store unique items based on dis_rep_name
     let uniqueMap = new Map<string, any>();
     this.LabelsDispatchDisRepNames.forEach(item => {
       uniqueMap.set(item.dis_rep_name, item);
     });

     // Step 3: Convert the Map back to an ArrayList
     let uniqueArrayList: any[] = Array.from(uniqueMap.values());
     this.LabelsDispatchDisRepNames=uniqueArrayList
 
  }

  getReport() {
    this.districtwiseSubscriberCount();
    this.pincodewiseSubscriberCount();
  }

  total_sub_count = 0;
  districtwiseSubscriberCount() {
    this.total_sub_count = 0;
    let tempdata = this.uniqueid(
      this.FilteredlabelsforDispatch,
      (a) => a['district']
    );

    // console.log(tempdata);
    this.filtereddistrictwisesubcout = [];
    for (let index = 0; index < Object.keys(tempdata).length; index++) {
      let arr = this.FilteredlabelsforDispatch.filter(
        (a) => a['district'] == tempdata[index]['district']
      );

      this.filtereddistrictwisesubcout.push({
        name: tempdata[index]['name'],
        Subscription_Type: tempdata[index]['Subscription_Type'],
        address: tempdata[index]['address'],
        country: tempdata[index]['country'],
        delivery_mode: tempdata[index]['delivery_mode'],
        district: tempdata[index]['district'],
        pincode: tempdata[index]['pincode'],
        state: tempdata[index]['state'],
        sub_perid_to_date: tempdata[index]['sub_perid_to_date'],
        taluka: tempdata[index]['taluka'],
        sub: Object.keys(arr).length,
        parcel: '',
      });

      this.total_sub_count += Object.keys(arr).length;
    }
  }

  total_pin_sub_count = 0;
  pincodewiseSubscriberCount() {
    this.total_pin_sub_count = 0;
    let tempdata = this.uniqueid(
      this.FilteredlabelsforDispatch,
      (a) => a['pincode']
    );

    // console.log(tempdata);
    this.filteredpincodewisesubcout = [];
    for (let index = 0; index < Object.keys(tempdata).length; index++) {
      let arr = this.FilteredlabelsforDispatch.filter(
        (a) => a['pincode'] == tempdata[index]['pincode']
      );

      this.filteredpincodewisesubcout.push({
        name: tempdata[index]['name'],
        Subscription_Type: tempdata[index]['Subscription_Type'],
        address: tempdata[index]['address'],
        country: tempdata[index]['country'],
        delivery_mode: tempdata[index]['delivery_mode'],
        district: tempdata[index]['district'],
        pincode: tempdata[index]['pincode'],
        state: tempdata[index]['state'],
        sub_perid_to_date: tempdata[index]['sub_perid_to_date'],
        taluka: tempdata[index]['taluka'],
        sub: Object.keys(arr).length,
        parcel: '',
      });

      this.total_pin_sub_count += Object.keys(arr).length;
    }
  }

  getAddressLabels() {
    this.filteredLabelDispatchData()
    console.log('FilteredlabelsforDispatch', this.FilteredlabelsforDispatch);
    this.dialog.open(AddressLabelsComponent, {
      data: {
        delivery_mode: this.mode_names,
        rep_names: this.repnames,
        state_name: this.state_names,
        district_name: this.district_names,
        taluka_name: this.taluka_names,
        addresslabels: this.FilteredlabelsforDispatch,
        iscustomer: this.iscustomer,
      },
      width: '1300px',
      height: '1300px',
      maxWidth: '100%',
    });
  }

  getAddressLabelswithMobileNo() {
    this.filteredLabelDispatchData()
    console.log('FilteredlabelsforDispatch', this.FilteredlabelsforDispatch);
    this.dialog.open(AddressLabelsMobileNoComponent, {
      data: {
        addresslabels: this.FilteredlabelsforDispatch,
        iscustomer: this.iscustomer,
      },
      width: '1200px',
      height: '1300px',
      maxWidth: '100%',
    });
  }

  get_cust_id(event, option) {
    // console.log(this.cust_id);
    if (event.isUserInput) {
      this.cust_id = option['subno'];
      if (
        this.startDate_registration != '' &&
        this.enddate_registration != ''
      ) {
        this.FilteredgetSubDetailsData = this.getSubDetailsData.filter((a) => {
          return (
            new Date(a['entry_date']) >=
            new Date(this.startDate_registration) &&
            new Date(a['entry_date']) <= new Date(this.enddate_registration) &&
            a['subno'] === this.cust_id
          );
        });
        this.calculatesubdetailstotal();
      } else {
        this.FilteredgetSubDetailsData = this.getSubDetailsData.filter((a) => {
          return a['subno'] === this.cust_id;
        });
        this.calculatesubdetailstotal();
      }
    }
  }

  reset_cust_id() {
    this.cust_id = '';
    this.subdetailsform.get('cust_id').reset();
    if (
      this.startDate_registration != '' &&
      this.enddate_registration != '' &&
      this.cust_id == ''
    ) {
      this.FilteredgetSubDetailsData = this.getSubDetailsData.filter((a) => {
        return (
          new Date(a['entry_date']) >= new Date(this.startDate_registration) &&
          new Date(a['entry_date']) <= new Date(this.enddate_registration)
        );
      });
      this.calculatesubdetailstotal();
    } else {
      this.FilteredgetSubDetailsData = this.getSubDetailsData;
      this.calculatesubdetailstotal();
    }
  }

  sub_names1 = '';
  get_sub_name1(event, option) {
    if (event.isUserInput) {
      this.sub_names1 = option['sub_name'];
      if (
        this.startDate_registration != '' &&
        this.enddate_registration != ''
      ) {
        this.FilteredgetSubDetailsData = this.getSubDetailsData.filter((a) => {
          return (
            new Date(a['entry_date']) >=
            new Date(this.startDate_registration) &&
            new Date(a['entry_date']) <= new Date(this.enddate_registration) &&
            a['name'] === this.sub_names1
          );
        });
        this.calculatesubdetailstotal();
      } else {
        this.FilteredgetSubDetailsData = this.getSubDetailsData.filter((a) => {
          return a['name'] === this.sub_names1;
        });
        this.calculatesubdetailstotal();
      }
    }
  }

  reset_sub_names1() {
    this.sub_names1 = '';
    this.subdetailsform.get('sub_name').reset();
    if (
      this.startDate_registration != '' &&
      this.enddate_registration != '' &&
      this.sub_names1 == ''
    ) {
      this.FilteredgetSubDetailsData = this.getSubDetailsData.filter((a) => {
        return (
          new Date(a['entry_date']) >= new Date(this.startDate_registration) &&
          new Date(a['entry_date']) <= new Date(this.enddate_registration)
        );
      });
      this.calculatesubdetailstotal();
    } else {
      this.FilteredgetSubDetailsData = this.getSubDetailsData;
      this.calculatesubdetailstotal();
    }
  }

  rcpt_no = '';
  get_rcpt_no(event, option) {
    // console.log(event);
    if (event.isUserInput) {
      this.rcpt_no = option['rcpt_no'];
      if (
        this.startDate_registration != '' &&
        this.enddate_registration != ''
      ) {
        this.FilteredgetSubDetailsData = this.getSubDetailsData.filter((a) => {
          return (
            new Date(a['entry_date']) >=
            new Date(this.startDate_registration) &&
            new Date(a['entry_date']) <= new Date(this.enddate_registration) &&
            a['rcpt_no'] === this.rcpt_no
          );
        });
        this.calculatesubdetailstotal();
      } else {
        this.FilteredgetSubDetailsData = this.getSubDetailsData.filter((a) => {
          return a['rcpt_no'] === this.rcpt_no;
        });
        this.calculatesubdetailstotal();
      }
    }
  }

  reset_rcpt_no() {
    this.rcpt_no = '';
    this.subdetailsform.get('rcpt_no').reset();
    if (
      this.startDate_registration != '' &&
      this.enddate_registration != '' &&
      this.rcpt_no == ''
    ) {
      this.FilteredgetSubDetailsData = this.getSubDetailsData.filter((a) => {
        return (
          new Date(a['entry_date']) >= new Date(this.startDate_registration) &&
          new Date(a['entry_date']) <= new Date(this.enddate_registration)
        );
      });
      this.calculatesubdetailstotal();
    } else {
      this.FilteredgetSubDetailsData = this.getSubDetailsData;
      this.calculatesubdetailstotal();
    }
  }

  rep = '';
  get_rep(event, option) {
    // console.log(event);
    if (event.isUserInput) {
      this.rep = option['rep_name'];
      if (
        this.startDate_registration != '' &&
        this.enddate_registration != ''
      ) {
        this.FilteredgetSubDetailsData = this.getSubDetailsData.filter((a) => {
          return (
            new Date(a['entry_date']) >=
            new Date(this.startDate_registration) &&
            new Date(a['entry_date']) <= new Date(this.enddate_registration) &&
            a['rep_name'].toLowerCase().indexOf(this.rep.toLowerCase()) > -1
          );
        });
        this.calculatesubdetailstotal();
      } else {
        this.FilteredgetSubDetailsData = this.getSubDetailsData.filter((a) => {
          return (
            a['rep_name'].toLowerCase().indexOf(this.rep.toLowerCase()) > -1
          );
        });
        this.calculatesubdetailstotal();
      }
    }
  }

  reset_rep() {
    this.rep = '';
    this.subdetailsform.get('rep').reset();
    if (
      this.startDate_registration != '' &&
      this.enddate_registration != '' &&
      this.rep == ''
    ) {
      this.FilteredgetSubDetailsData = this.getSubDetailsData.filter((a) => {
        return (
          new Date(a['entry_date']) >= new Date(this.startDate_registration) &&
          new Date(a['entry_date']) <= new Date(this.enddate_registration)
        );
      });
      this.calculatesubdetailstotal();
    } else {
      this.FilteredgetSubDetailsData = this.getSubDetailsData;
      this.calculatesubdetailstotal();
    }
  }

  off_rep = '';
  get_off_rep(event, option) {
    // console.log(event);
    if (event.isUserInput) {
      this.off_rep = option['office_rep_name'];
      if (
        this.startDate_registration != '' &&
        this.enddate_registration != ''
      ) {
        this.FilteredgetSubDetailsData = this.getSubDetailsData.filter((a) => {
          return (
            new Date(a['entry_date']) >=
            new Date(this.startDate_registration) &&
            new Date(a['entry_date']) <= new Date(this.enddate_registration) &&
            a['office_rep_name']
              .toLowerCase()
              .indexOf(this.off_rep.toLowerCase()) > -1
          );
        });
        this.calculatesubdetailstotal();
      } else {
        this.FilteredgetSubDetailsData = this.getSubDetailsData.filter((a) => {
          return (
            a['office_rep_name']
              .toLowerCase()
              .indexOf(this.off_rep.toLowerCase()) > -1
          );
        });
        this.calculatesubdetailstotal();
      }
    }
  }

  reset_off_rep() {
    this.off_rep = '';
    this.subdetailsform.get('off_rep').reset();
    if (
      this.startDate_registration != '' &&
      this.enddate_registration != '' &&
      this.rep == ''
    ) {
      this.FilteredgetSubDetailsData = this.getSubDetailsData.filter((a) => {
        return (
          new Date(a['entry_date']) >= new Date(this.startDate_registration) &&
          new Date(a['entry_date']) <= new Date(this.enddate_registration)
        );
      });
      this.calculatesubdetailstotal();
    } else {
      this.FilteredgetSubDetailsData = this.getSubDetailsData;
      this.calculatesubdetailstotal();
    }
  }

  v_no = '';
  get_v_no(event, option) {
    // console.log(
    //   this.v_no,
    //   this.startDate_registration,
    //   this.enddate_registration
    // );
    if (event.isUserInput) {
      this.v_no = option['crdt_voucher_no'];
      if (
        this.startDate_registration != '' &&
        this.enddate_registration != '' &&
        this.v_no != ''
      ) {
        // console.log('first');

        this.FilteredgetSubDetailsData = this.getSubDetailsData.filter((a) => {
          return (
            new Date(a['entry_date']) >=
            new Date(this.startDate_registration) &&
            new Date(a['entry_date']) <= new Date(this.enddate_registration) &&
            a['crdt_voucher_no']
              .toLowerCase()
              .indexOf(this.v_no.toLowerCase()) > -1
          );
        });
        this.calculatesubdetailstotal();
      } else if (
        this.startDate_registration == '' &&
        this.enddate_registration == '' &&
        this.v_no != ''
      ) {
        // console.log('second');
        this.FilteredgetSubDetailsData = this.getSubDetailsData.filter((a) => {
          return (
            a['crdt_voucher_no']
              .toLowerCase()
              .indexOf(this.v_no.toLowerCase()) > -1
          );
        });
        this.calculatesubdetailstotal();
      }
    }
  }

  reset_v_no() {
    this.v_no = '';
    this.subdetailsform.get('v_no').reset();
    // console.log(this.v_no);
    if (
      this.startDate_registration != '' &&
      this.enddate_registration != '' &&
      this.v_no == ''
    ) {
      this.FilteredgetSubDetailsData = this.getSubDetailsData.filter((a) => {
        return (
          new Date(a['entry_date']) >= new Date(this.startDate_registration) &&
          new Date(a['entry_date']) <= new Date(this.enddate_registration)
        );
      });
      this.calculatesubdetailstotal();
    } else {
      this.FilteredgetSubDetailsData = this.getSubDetailsData;
      this.calculatesubdetailstotal();
    }
  }

  getcust_mobile(_mobile) {
    // console.log(mobile);
    // console.log(this.cust_mobile.length);
    this.rangeRegistration.reset();
  }

  reset_cust_mobile() {
    this.cust_mobile = '';
  }

  isGSTInvoice = 'N';
  displaygstNumberInput = false;
  getInvoiceType(event) {
    this.isGSTInvoice = event.value;
    if (this.isGSTInvoice == 'Y') {
      this.displaygstNumberInput = true;
    } else {
      this.displaygstNumberInput = false;
    }
  }

  getSubDetails = [];
  displayNewSubscriber = false;
  getSubscriber() {
    // console.log(this.cust_mobile);
    // console.log(this.cust_id);
    if (this.cust_id == '' || this.cust_id == undefined) {
      this.service
        .getSubByIdorNumber(123, parseInt(this.cust_mobile))
        .subscribe((data) => {
          // console.log(data);
          this.getSubDetails = data;
          if (Object.keys(data).length == 0) {
            window.alert(
              'No Subscriber found. Please try to add New Subscriber.'
            );
            this.displayNewSubscriber = true;
          } else {
            this.displayNewSubscriber = false;
          }
        });
    } else if (this.cust_mobile == '' || this.cust_mobile == undefined) {
      this.service
        .getSubByIdorNumber(parseInt(this.cust_id), 777)
        .subscribe((data) => {
          // console.log(data);
          this.getSubDetails = data;
          if (Object.keys(data).length == 0) {
            window.alert(
              'No Subscriber found. Please try to add New Subscriber.'
            );
            this.displayNewSubscriber = true;
          } else {
            this.displayNewSubscriber = false;
          }
        });
    } else {
      window.alert('Please Enter Valid Details!');
    }
  }

  update_Details(book) {
    // console.log(book);
    this.service
      .getSubByIdorNumber(123, parseInt(book['mobile']))
      .subscribe((data) => {
        // console.log(data);
        this.getSubDetails = data;
        this.dialog.open(AddNewSubscriberComponent, {
          data: {
            sub: this.getSubDetails[0],
            height: '600px',
            width: '600px',
            updatemode: true,
          },
        });
      });
  }

  renew_subscription(mobile) {
    // console.log(mobile);
    this.dialog.open(AddNewMagazineComponent, {
      data: {
        mobile: mobile,
        height: '600px',
        width: '600px',
      },
    });
  }

  cancel_Subscription(mobile) {
    // console.log(mobile);
    this.dialog.open(AddCancelSubscriberComponent, {
      data: {
        mobile: mobile,
        height: '600px',
        width: '600px',
      },
    });
  }

  AddNewSub() {
    this.dialog.open(AddNewSubscriberComponent, {
      data: {
        sub: null,
        height: '600px',
        width: '600px',
        updatemode: false,
      },
    });
  }

  PrintData() {
    let element = document.getElementById('excel-table');
    let newWin = window.open('');
    newWin.document.write(element.outerHTML);
    newWin.print();
    newWin.close();
  }

  DistEndDate = '';
  newDistEndDate = '';
  displayWeeklyReport = false;
  getChangedDate(event) {
    // console.log(event.value);
    // console.log(this.newDistEndDate);
    this.DistEndDate = formatDate(
      event.value,
      'yyyy-MM-dd',
      'en-IN',
      'GMT+0530'
    );
    // console.log(this.DistEndDate);
    //this.update()
    this.DistPinReport.reset();
    this.displayWeeklyReport = false;
  }

  startDateDisct = '';
  endDateDisct = '';
  getDisctdates() {
    this.newDistEndDate = '';
    this.displayWeeklyReport = true;
    if (
      this.DistPinReport.get('start').value != null &&
      this.DistPinReport.get('end').value != null
    ) {
      let startdate = formatDate(
        new Date(this.DistPinReport.get('start').value),
        'yyyy-MM-dd',
        'en-IN',
        'GMT+0530'
      );
      let endDate = formatDate(
        new Date(this.DistPinReport.get('end').value),
        'yyyy-MM-dd',
        'en-IN',
        'GMT+0530'
      );
      this.startDateDisct = startdate;
      this.endDateDisct = endDate;
    }
  }

  allSelected = false;
  @ViewChild('mySel') skillSel: MatSelect;
  toggleAllSelection() {
    this.allSelected = !this.allSelected; // to control select-unselect
    // console.log(this.skillSel.options);
    if (this.allSelected) {
      this.skillSel.options.forEach((item: MatOption) => item.select());
    } else {
      this.skillSel.options.forEach((item: MatOption) => {
        item.deselect();
      });
    }
    this.skillSel.close();
  }

  allSelected1 = false;
  @ViewChild('mySel1') skillSel1: MatSelect;
  toggleAllSelection1() {
    this.allSelected1 = !this.allSelected1; // to control select-unselect
    // console.log(this.skillSel1.options);
    if (this.allSelected1) {
      this.skillSel1.options.forEach((item: MatOption) => item.select());
    } else {
      this.skillSel1.options.forEach((item: MatOption) => {
        item.deselect();
      });
      this.sub_names = '';
    }
    this.skillSel1.close();
  }

  allSelected2 = false;
  @ViewChild('mySel2') skillSel2: MatSelect;
  toggleAllSelection2() {
    this.allSelected2 = !this.allSelected2; // to control select-unselect
    // console.log(this.skillSel2.options);
    if (this.allSelected2) {
      this.skillSel2.options.forEach((item: MatOption) => item.select());
    } else {
      this.skillSel2.options.forEach((item: MatOption) => {
        item.deselect();
      });
      this.sub_types = '';
    }
    this.skillSel2.close();
  }

  allSelected3 = false;
  @ViewChild('mySel3') skillsel3: MatSelect;
  toggleAllSelection3() {
    this.allSelected3 = !this.allSelected3;
    if (this.allSelected3) {
      this.skillsel3.options.forEach((item: MatOption) => item.select());
    } else {
      this.skillsel3.options.forEach((item: MatOption) => item.deselect());
      this.country_names = '';
    }
    this.skillsel3.close();
  }

  allSelected4 = false;
  @ViewChild('mySel4') skillsel4: MatSelect;
  toggleAllSelection4() {
    this.allSelected4 = !this.allSelected4;
    if (this.allSelected4) {
      this.skillsel4.options.forEach((item: MatOption) => item.select());
    } else {
      this.skillsel4.options.forEach((item: MatOption) => item.deselect());
      this.state_names = '';
    }
    this.skillsel4.close();
  }

  allSelected5 = false;
  @ViewChild('mySel5') skillsel5: MatSelect;
  toggleAllSelection5() {
    this.allSelected5 = !this.allSelected5;
    if (this.allSelected5) {
      this.skillsel5.options.forEach((item: MatOption) => item.select());
    } else {
      this.skillsel5.options.forEach((item: MatOption) => item.deselect());
      this.district_names = '';
    }
    this.skillsel5.close();
  }

  allSelected6 = false;
  @ViewChild('mySel6') skillsel6: MatSelect;
  toggleAllSelection6() {
    // this.allSelected6 = !this.allSelected6;
    // if (this.allSelected6) {
    //   this.skillsel6.options.forEach((item: MatOption) => item.select());
    // } else {
    //   this.skillsel6.options.forEach((item: MatOption) => item.deselect());
    //   this.taluka_names = '';
    // }
    // this.skillsel6.close();
  }

  allSelected7 = false;
  @ViewChild('mySel7') skillsel7: MatSelect;
  toggleAllSelection7() {
    this.allSelected7 = !this.allSelected7;
    if (this.allSelected7) {
      this.skillsel7.options.forEach((item: MatOption) => item.select());
    } else {
      this.skillsel7.options.forEach((item: MatOption) => item.deselect());
      this.pincodes = '';
    }
    this.skillsel7.close();
  }

  allSelected8 = false;
  @ViewChild('mySel8') skillsel8: MatSelect;
  toggleAllSelection8() {
    this.allSelected8 = !this.allSelected8;
    if (this.allSelected8) {
      this.skillsel8.options.forEach((item: MatOption) => item.select());
    } else {
      this.skillsel8.options.forEach((item: MatOption) => item.deselect());
      this.repnames = '';
    }
    this.skillsel8.close();
  }

  allSelected9 = false;
  @ViewChild('mySel9') skillsel9: MatSelect;
  toggleAllSelection9() {
    this.allSelected9 = !this.allSelected9;
    if (this.allSelected9) {
      this.skillsel9.options.forEach((item: MatOption) => item.select());
    } else {
      this.skillsel9.options.forEach((item: MatOption) => item.deselect());
      this.officerepnames = '';
    }
    this.skillsel9.close();
  }

  allSelected10 = false;
  @ViewChild('mySel10') skillsel10: MatSelect;
  toggleAllSelection10() {
    this.allSelected10 = !this.allSelected10;
    if (this.allSelected10) {
      this.skillsel10.options.forEach((item: MatOption) => item.select());
    } else {
      this.skillsel10.options.forEach((item: MatOption) => item.deselect());
      this.mode_names = '';
    }
    this.skillsel10.close();
  }

  allSelected11 = false;
  @ViewChild('mySel11') skillsel11: MatSelect;
  toggleAllSelection11() {
    this.allSelected11 = !this.allSelected11;
    if (this.allSelected11) {
      this.skillsel11.options.forEach((item: MatOption) => item.select());
    } else {
      this.skillsel11.options.forEach((item: MatOption) => item.deselect());
      this.title1 = '';
    }
    this.skillsel11.close();
  }

  allSelected12 = false;
  @ViewChild('mySel12') skillsel12: MatSelect;
  toggleAllSelection12() {
    this.allSelected12 = !this.allSelected12;
    if (this.allSelected12) {
      this.skillsel12.options.forEach((item: MatOption) => item.select());
    } else {
      this.skillsel12.options.forEach((item: MatOption) => item.deselect());
      this.title1 = '';
    }
    this.skillsel12.close();
  }

  allSelected15 = false;
  @ViewChild('mySel15') skillsel15: MatSelect;
  toggleAllSelection15() {
    this.allSelected15 = !this.allSelected15;
    if (this.allSelected15) {
      this.skillsel15.options.forEach((item: MatOption) => item.select());
    } else {
      this.skillsel15.options.forEach((item: MatOption) => item.deselect());
      this.title1 = '';
    }
    this.skillsel15.close();
  }

  allSelected16 = false;
  @ViewChild('mySel16') skillsel16: MatSelect;
  toggleAllSelection16() {
    this.allSelected16 = !this.allSelected16;
    if (this.allSelected16) {
      this.skillsel16.options.forEach((item: MatOption) => item.select());
    } else {
      this.skillsel16.options.forEach((item: MatOption) => item.deselect());
      this.title1 = '';
    }
    this.skillsel16.close();
  }

  getSelectDiscts = [];
  pincodeWiseSub;
  getLabelSubDisct(event) {
    // console.log(event);
    this.getSelectDiscts = event.value;
  }

  district_strings = '';
  getPincodeWiseReports() {
    // console.log(this.getSelectDiscts.toString());
    let disct_string = '';
    for (let index = 0; index < this.getSelectDiscts.length; index++) {
      if (index == 0) {
        disct_string = this.getSelectDiscts[index]['city'];
      } else {
        disct_string = disct_string + ',' + this.getSelectDiscts[index]['city'];
      }
    }

    if (
      (this.startDateDisct == '' || this.DistEndDate == '') &&
      this.getSelectDiscts.length == 0
    ) {
      window.alert('Please Enter Valid Values!');
    } else {
      if (this.displayWeeklyReport) {
        this.service
          .read_distwise_pincode_report(
            this.startDateDisct,
            this.endDateDisct,
            disct_string
          )
          .subscribe((data) => {
            // console.log(data);
            this.pincodeWiseSub = data;
          });
      } else {
        this.service
          .read_distwise_pincode_report('', this.DistEndDate, disct_string)
          .subscribe((data) => {
            // console.log(data);
            this.pincodeWiseSub = data;
          });
      }
      // console.log(disct_string);
      this.district_strings = disct_string;
    }
  }

  send_renewal_sms(_mobile) {
    // console.log(mobile);
  }

  View_renewal_details(_sub_id) { }

  mark_as_read(_ntf_id) { }

  activate_user(_sub_id) { }

  view_user_details(_sub_id) { }

  getSubInvoice() {
    let prepareCust = {
      cust_id: this.getSubDetails[0]['cust_id'],
      name: this.getSubDetails[0]['title'] + this.getSubDetails[0]['name'],
      mobile: this.getSubDetails[0]['mobileNumber'],
      address:
        this.getSubDetails[0]['address'] +
        ',' +
        this.getSubDetails[0]['taluka'] +
        ',' +
        this.getSubDetails[0]['city'] +
        ',' +
        this.getSubDetails[0]['state'],
    };

    let prepareMag = [];
    for (let index = 0; index < this.getMagazine.length; index++) {
      prepareMag.push({
        book_name:
          this.getMagazine[index]['ds_mag1'] +
          ' - Edition : ' +
          this.getMagazine[index]['ds_mag1edition'] +
          '   Start Date : ' +
          this.getMagazine[index]['ds_mag1startdt'] +
          ' & End Date : ' +
          this.getMagazine[index]['ds_mag1enddt'],
        book_qty: 1,
        book_rate: parseInt(this.getMagazine[index]['ds_totamt']),
        book_total: parseInt(this.getMagazine[index]['ds_totamt']),
      });
    }

    //console.log(this.getRepSelectedBookslist)
    this.invoiceDetails = {
      rep: prepareCust,
      books: prepareMag,
      GST: this.isGSTInvoice,
      GST_no: this.gstNumber,
    };
    this.viewmode = false;
    this.repInvoice = 'N';
    this.GenerateInvoiceSubscriber();
  }

  GenerateInvoiceSubscriber() {
    this.dialog.open(SubscriptioninvoiceComponent, {
      data: {
        book: this.invoiceDetails,
        view: this.viewmode,
        rep_invoice: this.repInvoice,
      },
      height: '1300px',
      width: '1200px',
      maxWidth: '100%',
    });
  }

  getCustSubInvoiceStartDate;
  getCustSubInvoiceEndDate;
  getCustInvoiceDateRangeSubscription() {
    if (
      this.CustSubGeneratedInvoice.get('start').value != null &&
      this.CustSubGeneratedInvoice.get('end').value != null
    ) {
      this.getCustSubInvoiceStartDate = formatDate(
        new Date(this.CustSubGeneratedInvoice.get('start').value),
        'yyyy-MM-dd',
        'en-IN',
        'GMT+0530'
      );
      this.getCustSubInvoiceEndDate = formatDate(
        new Date(this.CustSubGeneratedInvoice.get('end').value),
        'yyyy-MM-dd',
        'en-IN',
        'GMT+0530'
      );
    }
  }

  selected_rep1_name(event, option) {
    if (event.isUserInput) {
      // console.log(option);
      this.subform.get('repmobno').setValue(option['mob_no']);
    }
  }

  selected_rep_name(event, option) {
    if (event.isUserInput) {
      let data = this.subscriberwisereplist.filter(
        (a) => a['rep_name'] == option['name']
      );
      // console.log(data);
      this.subform.get('repmobno1').setValue(data[0]['rep_mob_no']);
      this.subform.get('officerep').setValue(data[0]['off_rep_name']);
    }
  }

  getCustSubInvoice() {
    this.service
      .getCustInvoicesSubscription(
        this.getCustSubInvoiceStartDate,
        this.getCustSubInvoiceEndDate
      )
      .subscribe((data) => {
        // console.log(data);
        this.CustSubInvoiceData = data;
      });
  }

  ViewCustInvoiceSubscription(book) {
    // console.log(book);
    this.service
      .getSubByIdorNumber(parseInt(book['id']), 777)
      .subscribe((data) => {
        // console.log(data);
        this.getSubDetails = data;

        this.getSelectedRep = {
          id: this.getSubDetails[0]['cust_id'],
          name: this.getSubDetails[0]['title'] + this.getSubDetails[0]['name'],
          mobile: this.getSubDetails[0]['mobileNumber'],
          address:
            this.getSubDetails[0]['address'] +
            ',' +
            this.getSubDetails[0]['taluka'] +
            ',' +
            this.getSubDetails[0]['city'] +
            ',' +
            this.getSubDetails[0]['state'],
        };

        //this.getSelectedRep = this.getAllRepList.find(a => a['id'] == parseInt(book['id']))

        if (
          parseInt(book['cgst']) == 0 ||
          parseInt(book['sgst']) == 0 ||
          parseInt(book['igst']) == 0
        ) {
          this.isGSTInvoice = 'N';
        } else {
          this.isGSTInvoice = 'Y';
        }

        this.service
          .getCustInvoicesDataSubscription(parseInt(book['invoice_id']))
          .subscribe((data) => {
            // console.log(data);
            this.getRepInvoiceData = data;
            let getBookInvoiceDetails = {
              invoice_details: book,
              bookDetail: this.getRepInvoiceData,
            };
            this.invoiceDetails = {
              rep: this.getSelectedRep,
              books: getBookInvoiceDetails,
              GST: this.isGSTInvoice,
              GST_no: book['gst_no'],
            };
            this.viewmode = true;
            this.repInvoice = 'N';
            setTimeout(() => {
              this.GenerateInvoiceSubscriber();
            }, 1000);
          });
      });
  }

  addsubtype() {
    let subtype = {
      subtype: this.subtypeform.get('subtype').value,
      price: this.subtypeform.get('price').value,
      date: this.subtypeform.get('date').value,
      shortname: this.subtypeform.get('shortname').value,
    };
    // console.log('subtype data', subtype);

    if (subtype['subtype'] != '' && subtype['subtype'] != null) {
      this.service.addSubscriptionType(subtype).subscribe((_data) => {
        // console.log('addSubscriptionType', data);
        window.alert('Subscription Added Successfully...');
        setTimeout(() => {
          this.subtypeform.reset();
          this.subtypeform
            .get('date')
            .setValue(
              formatDate(new Date(), 'yyyy-MM-dd', 'en-IN', 'GMT+0530')
            );
        }, 2000);
      });
    } else {
      window.alert('You, Forget to enter data in Subscription Type field');
    }
  }

  // getDate() {
  //   // console.log('duration', this.duration);
  //   let val: number;
  //   if (this.subform.get('subfromdate').value != null && this.duration != '') {
  //     let date = new Date(
  //       formatDate(
  //         this.subform.get('subfromdate').value,
  //         'yyyy-MM-dd',
  //         'en-IN',
  //         'GMT+0530'
  //       )
  //     );
  //     let nextdate;
  //     // console.log('date', date);
  //     if (this.duration == 'वार्षिक') {
  //       val = 1;
  //       nextdate = new Date(date.setFullYear(date.getFullYear() + val));
  //       this.subform
  //         .get('subtodate')
  //         .setValue(
  //           formatDate(
  //             new Date(nextdate.setDate(nextdate.getDate() - 1)),
  //             'yyyy-MM-dd',
  //             'en-IN',
  //             'GMT+0530'
  //           )
  //         );
  //     } else if (this.duration == 'त्रैवार्षिक') {
  //       val = 3;
  //       nextdate = new Date(date.setFullYear(date.getFullYear() + val));
  //       console.log('nextDate', nextdate);

  //       this.subform
  //         .get('subtodate')
  //         .setValue(
  //           formatDate(
  //             new Date(nextdate.setDate(nextdate.getDate() - 1)),
  //             'yyyy-MM-dd',
  //             'en-IN',
  //             'GMT+0530'
  //           )
  //         );
  //     } else if (this.duration == 'पंचवार्षिक') {
  //       val = 5;
  //       nextdate = new Date(date.setFullYear(date.getFullYear() + val));
  //       this.subform
  //         .get('subtodate')
  //         .setValue(
  //           formatDate(
  //             new Date(nextdate.setDate(nextdate.getDate() - 1)),
  //             'yyyy-MM-dd',
  //             'en-IN',
  //             'GMT+0530'
  //           )
  //         );
  //     } else if (
  //       this.duration != 'वार्षिक' &&
  //       this.duration != 'त्रैवार्षिक' &&
  //       this.duration != 'पंचवार्षिक'
  //     ) {
  //       // console.log(this.pkgBookingData);
  //       for (
  //         let index = 0;
  //         index < Object.keys(this.pkgBookingData).length;
  //         index++
  //       ) {
  //         if (this.duration == this.pkgBookingData[index]['pkg_name']) {
  //           if (this.pkgBookingData[index]['year'] == '1 year') {
  //             val = 1;
  //             nextdate = new Date(date.setFullYear(date.getFullYear() + val));
  //             this.subform
  //               .get('subtodate')
  //               .setValue(
  //                 formatDate(
  //                   new Date(nextdate.setDate(nextdate.getDate() - 1)),
  //                   'yyyy-MM-dd',
  //                   'en-IN',
  //                   'GMT+0530'
  //                 )
  //               );
  //           } else if (this.pkgBookingData[index]['year'] == '3 year') {
  //             val = 3;
  //             nextdate = new Date(date.setFullYear(date.getFullYear() + val));
  //             this.subform
  //               .get('subtodate')
  //               .setValue(
  //                 formatDate(
  //                   new Date(nextdate.setDate(nextdate.getDate() - 1)),
  //                   'yyyy-MM-dd',
  //                   'en-IN',
  //                   'GMT+0530'
  //                 )
  //               );
  //           } else if (this.pkgBookingData[index]['year'] == '5 year') {
  //             val = 5;
  //             nextdate = new Date(date.setFullYear(date.getFullYear() + val));
  //             this.subform
  //               .get('subtodate')
  //               .setValue(
  //                 formatDate(
  //                   new Date(nextdate.setDate(nextdate.getDate() - 1)),
  //                   'yyyy-MM-dd',
  //                   'en-IN',
  //                   'GMT+0530'
  //                 )
  //               );
  //           }
  //         }
  //       }
  //     }
  //   }
  // }

  getDate() {
    let val: number;
    if (this.subform.get('subfromdate').value != null && this.duration != '') {
      let date = new Date(
        formatDate(
          this.subform.get('subfromdate').value,
          'yyyy-MM-dd',
          'en-IN',
          'GMT+0530'
        )
      );
      let nextdate;
  
      if (this.duration == 'वार्षिक') {
        val = 1;
        nextdate = new Date(date.setFullYear(date.getFullYear() + val));
      } else if (this.duration == 'त्रैवार्षिक') {
        val = 3;
        nextdate = new Date(date.setFullYear(date.getFullYear() + val));
      } else if (this.duration == 'पंचवार्षिक') {
        val = 5;
        nextdate = new Date(date.setFullYear(date.getFullYear() + val));
      } else {
        let matched = false;
        for (let index = 0; index < Object.keys(this.pkgBookingData).length; index++) {
          if (this.duration == this.pkgBookingData[index]['pkg_name']) {
            matched = true;
            if (this.pkgBookingData[index]['year'] == '1 year') {
              val = 1;
            } else if (this.pkgBookingData[index]['year'] == '3 year') {
              val = 3;
            } else if (this.pkgBookingData[index]['year'] == '5 year') {
              val = 5;
            }
            nextdate = new Date(date.setFullYear(date.getFullYear() + val));
            break;
          }
        }
        if (!matched) {
          val = 1; // Default to 1 year
          nextdate = new Date(date.setFullYear(date.getFullYear() + val));
        }
      }
  
      this.subform
        .get('subtodate')
        .setValue(
          formatDate(
            new Date(nextdate.setDate(nextdate.getDate() - 1)),
            'yyyy-MM-dd',
            'en-IN',
            'GMT+0530'
          )
        );
    }
  }
  

  updatesubtype(item) {
    this.dialog.open(UpdateSubtypeComponent, {
      data: {
        item: item,
      },
    });
    this.service.getSubscriptionType().subscribe((data) => {
      // console.log('getSubscriptionType', data);
      this.subscriptiontype = data;
    });
  }
  addDelivery(){
    
    console.log(this.adddeliverymode.value)
    this.dev_mode = this.adddeliverymode.value.deliverymode

    this.service.addSubDelivery( this.dev_mode).subscribe((_data) => {
      window.alert('SubDelivery Mode Added Successfully...');
      // this.adddeliverymodeform.reset();
      // this.ngOnInit();
    });
  }

  addSubDelivery() {
    let subdeliverydata = {
      delivery_mode_id: this.adddeliverymodeform.get('deliverymode').value,
      subdeliverymode: this.adddeliverymodeform.get('subdeliverymode').value,
    };

    this.service.addSubDeliveryMode(subdeliverydata).subscribe((_data) => {
      window.alert('SubDelivery Mode Added Successfully...');
      this.adddeliverymodeform.reset();
      this.ngOnInit();
    });

    this.service.getSubDeliveryMode().subscribe((data) => {
      this.subDeliveryModeData = data;
    });
  }

  addSubDeliveryofSub() {
    let subofsubdeliverydata = {
      sub_delivery_mode_id:
        this.addsubdeliverymodeform.get('deliverymode').value,
      subdeliverymode: this.addsubdeliverymodeform.get('subdeliverymode').value,
    };

    this.service.addSubModes(subofsubdeliverydata).subscribe((_data) => {
      window.alert('Delivery Modes Added Successfully...');
      this.addsubdeliverymodeform.reset();
      this.ngOnInit();
    });
  }

  complementry(event: boolean) {
    // console.log(event);
    this.iscomplementary = event;
  }

  daterepstartdate = 'abc';
  daterependdate = 'pqr';
  getChangedDaterep() {
    if (
      this.daterepForm.get('start').value != null &&
      this.daterepForm.get('end').value != null
    ) {
      this.daterepstartdate = formatDate(
        new Date(this.daterepForm.get('start').value),
        'yyyy-MM-dd',
        'en-IN',
        'GMT+0530'
      );
      this.daterependdate = formatDate(
        new Date(this.daterepForm.get('end').value),
        'yyyy-MM-dd',
        'en-IN',
        'GMT+0530'
      );
      this.getDailyReportData(
        this.daterepstartdate,
        this.daterependdate,
        this.rep_name,
        this.off_rep_name1,
        this.daterepForm.get('district').value,
        this.daterepForm.get('tal_name').value
      );
      // this.getrep_name_data();
    }
  }

  getDailyReportData(
    startDate,
    endDate,
    repName,
    offRepName,
    districtName,
    talukaName
  ) {
    // console.log('repName.toString()', repName.toString());

    this.service
      .getDatewiseAndRepwiseData(
        startDate,
        endDate,
        repName.toString(),
        offRepName,
        districtName,
        talukaName
      )
      .subscribe((res) => {
        // console.log(res);
        this.Filtereddatewiserepwisedata = res;
        this.Filtereddatewisedata = res;
        this.Filteredmonthwisedata = res;
        this.Filteredoffrepwisedata = res;
        this.Filtereddistricttalukawisedata = res;
        this.getoffice_rep_data();
      });
  }

  rep_name = 'xyz';
  getrepnames(event) {
    this.rep_name = event.value;
    // this.getDailyReportData(
    //   this.daterepstartdate,
    //   this.daterependdate,
    //   this.rep_name,
    //   'lmn'
    // );
    this.getDailyReportData(
      this.daterepstartdate,
      this.daterependdate,
      this.rep_name,
      this.off_rep_name1,
      this.daterepForm.get('district').value,
      this.daterepForm.get('taluka').value
    );
  }

  Datewise() {
    console.log(this.Filtereddatewisedata);

    this.dialog.open(DatewiseComponent, {
      data: {
        datewisedata: this.Filtereddatewisedata,
        startdate: this.daterepstartdate,
        enddate: this.daterependdate,
      },
      width: '1300px',
      height: '1300px',
      maxWidth: '100%',
    });
  }

  // datewisestartdate;
  // datewiseenddate;
  // getChangedDateDatewise() {
  //   if (
  //     this.datewiseForm.get('start').value != null &&
  //     this.datewiseForm.get('end').value != null
  //   ) {
  //     this.datewisestartdate = formatDate(
  //       new Date(this.datewiseForm.get('start').value),
  //       'yyyy-MM-dd',
  //       'en-IN',
  //       'GMT+0530'
  //     );
  //     this.datewiseenddate = formatDate(
  //       new Date(this.datewiseForm.get('end').value),
  //       'yyyy-MM-dd',
  //       'en-IN',
  //       'GMT+0530'
  //     );

  //     // this.Filtereddatewisedata = this.datewiserepwisedata.filter((a) => {
  //     //   return (
  //     //     new Date(a['entry_date']) >= new Date(this.datewisestartdate) &&
  //     //     new Date(a['entry_date']) <= new Date(this.datewiseenddate)
  //     //   );
  //     // });

  //     this.service
  //       .getDatewiseAndRepwiseData(
  //         this.datewisestartdate,
  //         this.datewiseenddate,
  //         'xyz'
  //       )
  //       .subscribe((res) => {
  //         // console.log(res);
  //         this.Filtereddatewisedata = res;
  //       });
  //   }
  // }

  // monthwisestartdate;
  // monthwiseenddate;
  // getChangedDateMonthwise() {
  //   if (
  //     this.monthwiseForm.get('start').value != null &&
  //     this.monthwiseForm.get('end').value != null
  //   ) {
  //     this.monthwisestartdate = formatDate(
  //       new Date(this.monthwiseForm.get('start').value),
  //       'yyyy-MM-dd',
  //       'en-IN',
  //       'GMT+0530'
  //     );
  //     this.monthwiseenddate = formatDate(
  //       new Date(this.monthwiseForm.get('end').value),
  //       'yyyy-MM-dd',
  //       'en-IN',
  //       'GMT+0530'
  //     );

  //     // this.Filteredmonthwisedata = this.datewiserepwisedata.filter((a) => {
  //     //   return (
  //     //     new Date(a['entry_date']) >= new Date(this.monthwisestartdate) &&
  //     //     new Date(a['entry_date']) <= new Date(this.monthwiseenddate)
  //     //   );
  //     // });
  //     this.service
  //       .getDatewiseAndRepwiseData(
  //         this.monthwisestartdate,
  //         this.monthwiseenddate,
  //         'xyz'
  //       )
  //       .subscribe((res) => {
  //         // console.log(res);
  //         this.Filteredmonthwisedata = res;
  //       });
  //   }
  // }

  Monthwise() {
    this.dialog.open(MonthwiseComponent, {
      data: {
        monthwisedata: this.Filteredmonthwisedata,
        startdate: this.daterepstartdate,
        enddate: this.daterependdate,
      },
      width: '1300px',
      height: '1300px',
      maxWidth: '100%',
    });
  }

  offrepwise() {
    this.dialog.open(OfficeRepresentativewiseComponent, {
      data: {
        offrepwisedata: this.Filteredoffrepwisedata,
        startDate: this.daterepstartdate,
        endDate: this.daterependdate,
        off_rep_name: this.off_rep_name1,
      },
      width: '1400px',
      height: '1300px',
      maxWidth: '100%',
    });
  }

  repwise() {
    this.dialog.open(RepbusinessreportComponent, {
      data: {
        offrepwisedata: this.Filteredoffrepwisedata,
        startDate: this.daterepstartdate,
        endDate: this.daterependdate,
        off_rep_name: this.off_rep_name1,
      },
      width: '1300px',
      height: '1300px',
      maxWidth: '100%',
    });
  }

  // getrenewalreport1(){
  //   console.log("its working")
  // }

  contractReportType = '';
  getContractReportType(event) {
    // console.log(event.value);
    this.contractReportType = event.value;
  }

  getrenewalreport1() {


    // console.log(this.contractData);
    if (this.contractReportType !== '') {

      console.log(this.contractReportType)
      debugger
      this.dialog.open(ContractBriefReportComponent, {
       
        data: {
          // contractData: this.contractData,
          startDate: this.datePipe.transform(this.RenewalreportForm.value.start, 'yyyy-MM-dd') || '',

          endDate: this.datePipe.transform(this.RenewalreportForm.value.end, 'yyyy-MM-dd') || '',
          repName: this.RenewalreportForm.value.rep,
          offRepName: this.RenewalreportForm.value.officer,
          prantname : this.RenewalreportForm.value.prantname,
          // advtName: this.RenewalreportForm.value.rep district,
          districtName: this.RenewalreportForm.value.district == "" ? "" : this.RenewalreportForm.value.district,
          talukaName: this.RenewalreportForm.value.taluka == "" ? "" : this.RenewalreportForm.value.taluka,
          reportType: this.RenewalreportForm.value.reportType,
          country: this.RenewalreportForm.value.country == "" ? "" : this.RenewalreportForm.value.country,
          state: this.RenewalreportForm.value.state == "" ? "" : this.RenewalreportForm.value.state,
        },
        height: '1300px',
        width: '95%',
        maxWidth: '100%',
      });
    }
    else {
      alert('Please select report type');
    }
  }

  getrenewalreport3() {


    // console.log(this.contractData);
    if (this.contractReportType !== '') {
      console.log(this.contractReportType)
      this.dialog.open(ContractBriefReportComponent, {
        data: {
          // contractData: this.contractData,
          startDate: this.datePipe.transform(this.RenewalTypereportForm.value.start, 'yyyy-MM-dd') || '',
          endDate: this.datePipe.transform(this.RenewalTypereportForm.value.end, 'yyyy-MM-dd') || '',
          repName: this.RenewalTypereportForm.value.rep,
          offRepName: this.RenewalTypereportForm.value.officer,
          // advtName: this.RenewalreportForm.value.rep district,
          districtName: this.RenewalTypereportForm.value.district == "" ? "" : this.RenewalTypereportForm.value.district,
          talukaName: this.RenewalTypereportForm.value.taluka == "" ? "" : this.RenewalTypereportForm.value.taluka,
          reportType: this.RenewalTypereportForm.value.reportType,
          country: this.RenewalTypereportForm.value.country == "" ? "" : this.RenewalTypereportForm.value.country,
          state: this.RenewalTypereportForm.value.state == "" ? "" : this.RenewalTypereportForm.value.state,
          prantname : this.RenewalTypereportForm.value.prantname,
          
          
        },
        height: '1300px',
        width: '95%',
        maxWidth: '100%',
      });
    }
    else {
      alert('Please select report type');
    }
  }
debugger
  getrenewalreportRemaing() {
    debugger
    this.generateMonthNames( this.datePipe.transform(this.RemainingRenewalreportForm.value.start, 'yyyy-MM-dd') || '', this.datePipe.transform(this.RemainingRenewalreportForm.value.end, 'yyyy-MM-dd') || '')



    // console.log(this.contractData);
    if (this.contractReportType !== '') {
      console.log(this.contractReportType)
      this.dialog.open(ContractBriefReportComponent, {
        data: {
          // contractData: this.contractData,
          startDate: this.datePipe.transform(this.RemainingRenewalreportForm.value.start, 'yyyy-MM-dd') || '',
          endDate: this.datePipe.transform(this.RemainingRenewalreportForm.value.end, 'yyyy-MM-dd') || '',
          repName: this.RemainingRenewalreportForm.value.rep,
          offRepName: this.RemainingRenewalreportForm.value.officer,
          months: this.months,
          districtName: this.RemainingRenewalreportForm.value.district == "" ? "" : this.RemainingRenewalreportForm.value.district,
          talukaName: this.RemainingRenewalreportForm.value.taluka == "" ? "" : this.RemainingRenewalreportForm.value.taluka,
          reportType: this.RemainingRenewalreportForm.value.reportType,
          country: this.RemainingRenewalreportForm.value.country == "" ? "" : this.RemainingRenewalreportForm.value.country,
          state: this.RemainingRenewalreportForm.value.state == "" ? "" : this.RemainingRenewalreportForm.value.state,
          prantname : this.RemainingRenewalreportForm.value.prantname,

        },
        height: '1300px',
        width: '95%',
        maxWidth: '100%',
      });
    }
    else {
      alert('Please select report type');
    }
  }

  getrenewalreport4() {

    // console.log(this.contractData);
    if (this.contractReportType !== '') {
      console.log(this.contractReportType)
      this.dialog.open(ContractBriefReportComponent, {
        data: {
          // contractData: this.contractData,
          // date:   this.datePipe.transform(this.RenewalTypereportForm.value.start, 'yyyy-MM-dd') || '',
          // endDate:  this.datePipe.transform(this.RenewalTypereportForm.value.end, 'yyyy-MM-dd') || '',
          repName: this.RenewalTypereportForm.value.rep,
          // advtName: this.RenewalreportForm.value.rep district,
          districtName: this.RenewalTypereportForm.value.district == "" ? "" : this.RenewalTypereportForm.value.district,
          talukaName: this.RenewalTypereportForm.value.taluka == "" ? "" : this.RenewalTypereportForm.value.taluka,
          reportType: this.RenewalTypereportForm.value.reportType,
          country: this.RenewalTypereportForm.value.country == "" ? "" : this.RenewalTypereportForm.value.country,
          state: this.RenewalTypereportForm.value.state == "" ? "" : this.RenewalTypereportForm.value.state,
          prantname: this.RenewalTypereportForm.value.prantname == "" ? "" : this.RenewalTypereportForm.value.prantname,
        },
        height: '1300px',
        width: '95%',
        maxWidth: '100%',
      });
    }
    else {
      alert('Please select report type');
    }
  }

  getrenewalreport5() {


    // console.log(this.contractData);
    // if (this.contractReportType !== '') {
    console.log(this.contractReportType)
    this.dialog.open(AddmodalComponent, {
      data: {
        // contractData: this.contractData,
        start_date: this.datePipe.transform(this.PendingAddForm.value.start, 'yyyy-MM-dd') || '',
        end_date: this.datePipe.transform(this.PendingAddForm.value.end, 'yyyy-MM-dd') || '',
        // repName: this.PendingAddForm.value.rep,
        repName: this.PendingAddForm.value.rep,
        districtName: this.PendingAddForm.value.district == "" ? "" : this.PendingAddForm.value.district,
        talukaName: this.PendingAddForm.value.taluka == "" ? "" : this.PendingAddForm.value.taluka,
        modeType: this.PendingAddForm.value.modetype,
        country: this.PendingAddForm.value.country == "" ? "" : this.PendingAddForm.value.country,
        state: this.PendingAddForm.value.state == "" ? "" : this.PendingAddForm.value.state,
        prantname: this.PendingAddForm.value.prantname == "" ? "" : this.PendingAddForm.value.prantname,
        duration: this.PendingAddForm.value.duration == "" ? "" : this.PendingAddForm.value.duration,
        reportType: this.PendingAddForm.value.reportType,


      },

      height: '1300px',
      width: '95%',
      maxWidth: '100%',
    });

  }

  getrenewalreport6() {


    // console.log(this.contractData);
    if (this.AllAddForm.value.reportType !== 'Address List') {
    // console.log(this.contractReportType)
    debugger
    this.dialog.open(AlladdComponent, {
      data: {
        // contractData: this.contractData,
        date: this.datePipe.transform(this.AllAddForm.value.start, 'yyyy-MM-dd') || '',
        endDate: this.datePipe.transform(this.AllAddForm.value.end, 'yyyy-MM-dd') || '',
        // repName: this.PendingAddForm.value.rep,
        // repName: this.AllAddForm.value.rep,
        districtName: this.AllAddForm.value.district == "" ? "" : this.AllAddForm.value.district,
        talukaName: this.AllAddForm.value.taluka == "" ? "" : this.AllAddForm.value.taluka,
        modeType: this.AllAddForm.value.modetype,
        country: this.AllAddForm.value.country == "" ? "" : this.AllAddForm.value.country,
        state: this.AllAddForm.value.state == "" ? "" : this.AllAddForm.value.state,
        prantname: this.AllAddForm.value.prantname == "" ? "" : this.AllAddForm.value.prantname,
        duration: this.AllAddForm.value.duration == "" ? "" : this.AllAddForm.value.duration,
        reportType: this.AllAddForm.value.reportType,
      },

      height: '1300px',
      width: '95%',
      maxWidth: '100%',
    });
  }else {
    debugger
    this.dialog.open(AlladdlistComponent, {
      data: {
        // contractData: this.contractData,
        date: this.datePipe.transform(this.AllAddForm.value.start, 'yyyy-MM-dd') || '',
        endDate: this.datePipe.transform(this.AllAddForm.value.end, 'yyyy-MM-dd') || '',
        // repName: this.PendingAddForm.value.rep,
        // repName: this.AllAddForm.value.rep,
        districtName: this.AllAddForm.value.district == "" ? "" : this.AllAddForm.value.district,
        talukaName: this.AllAddForm.value.taluka == "" ? "" : this.AllAddForm.value.taluka,
        modeType: this.AllAddForm.value.modetype,
        country: this.AllAddForm.value.country == "" ? "" : this.AllAddForm.value.country,
        state: this.AllAddForm.value.state == "" ? "" : this.AllAddForm.value.state,
        prantname: this.AllAddForm.value.prantname == "" ? "" : this.AllAddForm.value.prantname,
        duration: this.AllAddForm.value.duration == "" ? "" : this.AllAddForm.value.duration,
        reportType: this.AllAddForm.value.reportType,
      },

      height: '1300px',
      width: '95%',
      maxWidth: '100%',
    });
    
  }
    // console.log(this.data, "all add")
  }

  dateoffrepstartdate = '';
  dateoffrependdate = '';
  getChangedDateOffRep() {
    if (
      this.offrepForm.get('start').value != null &&
      this.offrepForm.get('end').value != null
    ) {
      this.dateoffrepstartdate = formatDate(
        new Date(this.offrepForm.get('start').value),
        'yyyy-MM-dd',
        'en-IN',
        'GMT+0530'
      );
      this.dateoffrependdate = formatDate(
        new Date(this.offrepForm.get('end').value),
        'yyyy-MM-dd',
        'en-IN',
        'GMT+0530'
      );

      this.filteredOffRepData();
      // this.getoffice_rep_data();
      // this.getrep_name_data();
    }
  } 
  startDate_contract;
  enddate_contract; 
  getChangedDateContractM1() {
    if (
      this.RenewalTypereportForm.get('start').value != null &&
      this.RenewalTypereportForm.get('end').value != null
    ) {
      // console.log(this.contractDetailsForm.value);
      this.startDate_contract = formatDate(
        new Date(this.RenewalTypereportForm.get('start').value),
        'yyyy-MM-dd',
        'en-IN',
        'GMT+0530'
      );
      
      this.enddate_contract = formatDate(
        new Date(this.RenewalTypereportForm.get('end').value),
        'yyyy-MM-dd',
        'en-IN',
        'GMT+0530'
      );
    }

    // this.generateMonthNames(this.startDate_contract,this.enddate_contract)
  }

  startDate_contract_data;
  enddate_contract_data; 
  getChangedDateContractByVC() {
    if (
      this.databyvocuher.get('start').value != null &&
      this.databyvocuher.get('end').value != null
    ) {
      // console.log(this.contractDetailsForm.value);
      this.startDate_contract_data = formatDate(
        new Date(this.databyvocuher.get('start').value),
        'yyyy-MM-dd',
        'en-IN',
        'GMT+0530'
      );
      
      this.enddate_contract_data = formatDate(
        new Date(this.databyvocuher.get('end').value),
        'yyyy-MM-dd',
        'en-IN',
        'GMT+0530'
      );
    }
    console.log( this.startDate_contract_data , this.enddate_contract_data)

    const req1:any = { 
      "start_date":this.startDate_contract_data,
      "invoice_rep_name" : this.databyvocuher.value.repname,
      // "financialYear":this.date,
      "end_date":this.enddate_contract_data,
      "voucher_no":this.databyvocuher.value.cvnumber,
    }

    this.service.getdatabyvc(req1).subscribe((data) => {
      // console.log('pkgBookingData', data);
     
      this.databyvc = data;
      this.totalcvamt = 0
      // for(let data1 of this.databyvc){
       
      //   for(let data2 of data1.voucher_data){
      //     debugger;
      //     this.totalcvamt += parseInt(data2.crdt_voucher_amt) || 0;
      //   }
      // }
    });

    // this.generateMonthNames(this.startDate_contract,this.enddate_contract)
  }
  months:any

  generateMonthNames(startDate: any, endDate: any){
    
    const months = [];
    const start = new Date(startDate);
    const end = new Date(endDate);
    const monthNames = ['January', 'February', 'March', 'April', 'May', 'June', 
                        'July', 'August', 'September', 'October', 'November', 'December'];

    while (start <= end) {
      months.push(monthNames[start.getMonth()]);
      start.setMonth(start.getMonth() + 1);
    }

    this.months=months;
  }


  getChangedDateContractA1() {
    if (
      this.AllAddForm.get('start').value != null &&
      this.AllAddForm.get('end').value != null
    ) {
      // console.log(this.contractDetailsForm.value);
      this.startDate_contract = formatDate(
        new Date(this.AllAddForm.get('start').value),
        'yyyy-MM-dd',
        'en-IN',
        'GMT+0530'
      );
      this.enddate_contract = formatDate(
        new Date(this.AllAddForm.get('end').value),
        'yyyy-MM-dd',
        'en-IN',
        'GMT+0530'
      );
    }
  }

  getChangedDateContractA4() {
    if (
      this.PendingAddForm.get('start').value != null &&
      this.PendingAddForm.get('end').value != null
    ) {
      // console.log(this.contractDetailsForm.value);
      this.startDate_contract = formatDate(
        new Date(this.PendingAddForm.get('start').value),
        'yyyy-MM-dd',
        'en-IN',
        'GMT+0530'
      );
      this.enddate_contract = formatDate(
        new Date(this.PendingAddForm.get('end').value),
        'yyyy-MM-dd',
        'en-IN',
        'GMT+0530'
      );
    }
  }

  filteredOffRepData() {
    // console.log(this.offrepwisedata);

    if (
      this.dateoffrepstartdate != '' &&
      this.dateoffrependdate != '' &&
      this.off_rep_name1 == ''
    ) {
      // this.Filteredoffrepwisedata = this.offrepwisedata.filter((a) => {
      //   return (
      //     new Date(a['entry_date']) >= new Date(this.dateoffrepstartdate) &&
      //     new Date(a['entry_date']) <= new Date(this.dateoffrependdate)
      //   );
      // });
    } else if (
      this.dateoffrepstartdate == '' &&
      this.dateoffrependdate == '' &&
      this.off_rep_name1 != ''
    ) {
      // this.Filteredoffrepwisedata = this.offrepwisedata.filter((a) => {
      //   return (
      //     a['office_rep_name']
      //       .toLowerCase()
      //       .indexOf(this.off_rep_name1.toLowerCase()) > -1
      //   );
      // });
    } else if (
      this.dateoffrepstartdate != '' &&
      this.dateoffrependdate != '' &&
      this.off_rep_name1 != ''
    ) {
      // this.Filteredoffrepwisedata = this.offrepwisedata.filter((a) => {
      //   return (
      //     new Date(a['entry_date']) >= new Date(this.dateoffrepstartdate) &&
      //     new Date(a['entry_date']) <= new Date(this.dateoffrependdate) &&
      //     a['office_rep_name']
      //       .toLowerCase()
      //       .indexOf(this.off_rep_name1.toLowerCase()) > -1
      //   );
      // });
    }
    // console.log(this.Filteredoffrepwisedata);
  }

  off_rep_name1 = 'lmn';
  getoff_rep_name(event, option) {
    if (event.isUserInput) {
      this.off_rep_name1 = option['office_rep_name'];
      // this.getDailyReportData(
      //   this.daterepstartdate,
      //   this.daterependdate,
      //   'xyz',
      //   this.off_rep_name1
      // );
      this.getDailyReportData(
        this.daterepstartdate,
        this.daterependdate,
        this.rep_name,
        this.off_rep_name1,
        this.daterepForm.get('district').value,
        this.daterepForm.get('taluka').value
      );
      // if (this.off_rep_name1 != '') {
      //   this.filteredOffRepData();
      //   this.getoffice_rep_data();
      // }
    }
  }

  reset_off_rep_name() {
    this.off_rep_name1 = 'lmn';
    this.daterepForm.get('off_rep_name').reset();
    // if (this.off_rep_name1 == '') {
    //   this.Filteredoffrepwisedata = this.offrepwisedata;
    //   this.getRepName();
    //   this.getoffice_rep_data();
    // }
  }

  districtandtalukawise() {
    this.dialog.open(DistrictwiseTalukawiseComponent, {
      data: {
        startDate: this.daterepstartdate,
          endDate: this.daterependdate,
          data: this.Filtereddatewiserepwisedata,
          rep_name: this.rep_name.toString(),
          dataset : this.datewisedata,
          district : this.daterepForm.value.district,
          taluka : this.daterepForm.value.tal_name,
      },
      width: '1300px',
      height: '1300px',
      maxWidth: '100%',
    });
  }

  typewiseawise() {
    this.dialog.open(TypewisebusinessreportComponent, {
      data: {
        startDate: this.daterepstartdate,
          endDate: this.daterependdate,
          data: this.Filtereddatewiserepwisedata,
          rep_name: this.rep_name.toString(),
          dataset : this.datewisedata,
          district : this.daterepForm.value.district,
          taluka : this.daterepForm.value.tal_name,
          type : this.daterepForm.value.duration,
      },
      width: '1300px',
      height: '1300px',
      maxWidth: '100%',
    });
  }

  renewablereceiptdata() {
    // this.dialog.open(RemainingRenewableListOfAddressComponent, {
    //   data: {
    //     renewable_rceipt_data: this.renewable_rceipt_data,
    //   },
    //   width: '1200px',
    //   height: '1300px',
    //   maxWidth: '100%',
    // });

    this.dialog.open(RenewRemainingDataComponent, {
      data: {
        startDate: this.subrenewaldatestartdate,
        endDate: this.subrenewaldateenddate,
        renewRemainingData: this.renewRemainingData,
      },
      width: '1200px',
      height: '1300px',
      maxWidth: '100%',
    });
  }

  repwiseAddress() {
    this.dialog.open(RepwiseAddressListComponent, {
      data: {
        repwisesubaddresslist: this.repwisesubaddresslist,
      },
      width: '1400px',
      height: '1300px',
      maxWidth: '100%',
    });
  }

  subrenewalletter() {
    this.dialog.open(SubscriptionRenewalRemainderletterComponent, {
      data: {
        subrenewletterdata: this.repwisesubaddresslist,
      },
      width: '1100px',
      height: '1300px',
      maxWidth: '100%',
    });
  }

  subAddress() {
    this.dialog.open(SubscribersAddressComponent, {
      data: {
        subscribersaddressdata: this.Filteredsubscribersaddressdata,
      },
      width: '1200px',
      height: '1300px',
      maxWidth: '100%',
    });
  }

  getsubaddStartDate;
  getsubaddEndDate;
  getSubAddressDateRange() {
    if (
      this.subaddform.get('start').value != null &&
      this.subaddform.get('end').value != null
    ) {
      this.getsubaddStartDate = formatDate(
        new Date(this.subaddform.get('start').value),
        'yyyy-MM-dd',
        'en-IN',
        'GMT+0530'
      );
      this.getsubaddEndDate = formatDate(
        new Date(this.subaddform.get('end').value),
        'yyyy-MM-dd',
        'en-IN',
        'GMT+0530'
      );

      // this.Filteredsubscribersaddressdata = this.subscribersaddressdata.filter(
      //   (a) => {
      //     return (
      //       new Date(a['created_on']) >= new Date(this.getsubaddStartDate) &&
      //       new Date(a['created_on']) <= new Date(this.getsubaddEndDate)
      //     );
      //   }
      // );

      this.service
        .getSubscribersAddressData(
          this.getsubaddStartDate,
          this.getsubaddEndDate,
          'lmn',
          'pqr',
          'stu',
          'vwx',
          'jkl'
        )
        .subscribe((res) => {
          // console.log(res);
          this.Filteredsubscribersaddressdata = res;
        });

      // console.log('filtered data', this.Filteredsubscribersaddressdata);
    }
  }

  gettitles(event) {
    // console.log(event.value);
    let title = event.value;
    if (this.getsubaddStartDate != null && this.getsubaddEndDate != null) {
      // this.Filteredsubscribersaddressdata = this.subscribersaddressdata.filter(
      //   (a) => {
      //     return (
      //       new Date(a['created_on']) >= new Date(this.getsubaddStartDate) &&
      //       new Date(a['created_on']) <= new Date(this.getsubaddEndDate) &&
      //       a['title'].toLowerCase().indexOf(title.toLowerCase()) > -1
      //     );
      //   }
      // );
      this.service
        .getSubscribersAddressData(
          this.getsubaddStartDate,
          this.getsubaddEndDate,
          title,
          'pqr',
          'stu',
          'vwx',
          'jkl'
        )
        .subscribe((res) => {
          // console.log(res);
          this.Filteredsubscribersaddressdata = res;
        });
    } else {
      // this.Filteredsubscribersaddressdata = this.subscribersaddressdata.filter(
      //   (a) => {
      //     return a['title'].toLowerCase().indexOf(title.toLowerCase()) > -1;
      //   }
      // );
      this.service
        .getSubscribersAddressData(
          'abc',
          'def',
          title,
          'pqr',
          'stu',
          'vwx',
          'jkl'
        )
        .subscribe((res) => {
          // console.log(res);
          this.Filteredsubscribersaddressdata = res;
        });
    }
  }

  getdistrict(event) {
    let district = event.value;
    if (this.getsubaddStartDate != null && this.getsubaddEndDate != null) {
      // this.Filteredsubscribersaddressdata = this.subscribersaddressdata.filter(
      //   (a) => {
      //     return (
      //       new Date(a['created_on']) >= new Date(this.getsubaddStartDate) &&
      //       new Date(a['created_on']) <= new Date(this.getsubaddEndDate) &&
      //       a['district'].toLowerCase().indexOf(district.toLowerCase()) > -1
      //     );
      //   }
      // );
      this.service
        .getSubscribersAddressData(
          this.getsubaddStartDate,
          this.getsubaddEndDate,
          'lmn',
          district,
          'stu',
          'vwx',
          'jkl'
        )
        .subscribe((res) => {
          // console.log(res);
          this.Filteredsubscribersaddressdata = res;
        });
    } else {
      // this.Filteredsubscribersaddressdata = this.subscribersaddressdata.filter(
      //   (a) => {
      //     return (
      //       a['district'].toLowerCase().indexOf(district.toLowerCase()) > -1
      //     );
      //   }
      // );
      this.service
        .getSubscribersAddressData(
          'abc',
          'def',
          'lmn',
          district,
          'stu',
          'vwx',
          'jkl'
        )
        .subscribe((res) => {
          // console.log(res);
          this.Filteredsubscribersaddressdata = res;
        });
    }
  }
  // officer rep list
  getOffRep() {

    this.service.getOffRepSub().subscribe((data: any) => {
      console.log("ADVT Data1=", data);
      this.GetOffRep = (data as any);;
    });
  }

  getprantname() {

    this.service.getprantname().subscribe((data: any) => {
      console.log("ADVT Data1=", data);
      this.Getprantname = (data as any);;
    });
  }

  gettaluka(event) {
    let taluka = event.value;
    if (this.getsubaddStartDate != null && this.getsubaddEndDate != null) {
      // this.Filteredsubscribersaddressdata = this.subscribersaddressdata.filter(
      //   (a) => {
      //     return (
      //       new Date(a['created_on']) >= new Date(this.getsubaddStartDate) &&
      //       new Date(a['created_on']) <= new Date(this.getsubaddEndDate) &&
      //       a['taluka'].toLowerCase().indexOf(taluka.toLowerCase()) > -1
      //     );
      //   }
      // );
      this.service
        .getSubscribersAddressData(
          this.getsubaddStartDate,
          this.getsubaddEndDate,
          'lmn',
          'pqr',
          taluka,
          'vwx',
          'jkl'
        )
        .subscribe((res) => {
          // console.log(res);
          this.Filteredsubscribersaddressdata = res;
        });
    } else {
      // this.Filteredsubscribersaddressdata = this.subscribersaddressdata.filter(
      //   (a) => {
      //     return a['taluka'].toLowerCase().indexOf(taluka.toLowerCase()) > -1;
      //   }
      // );
      this.service
        .getSubscribersAddressData(
          'abc',
          'def',
          'lmn',
          'pqr',
          taluka,
          'vwx',
          'jkl'
        )
        .subscribe((res) => {
          // console.log(res);
          this.Filteredsubscribersaddressdata = res;
        });
    }
  }

  getsubtype(event) {
    let subtype = event.value;
    if (this.getsubaddStartDate != null && this.getsubaddEndDate != null) {
      // this.Filteredsubscribersaddressdata = this.subscribersaddressdata.filter(
      //   (a) => {
      //     return (
      //       new Date(a['created_on']) >= new Date(this.getsubaddStartDate) &&
      //       new Date(a['created_on']) <= new Date(this.getsubaddEndDate) &&
      //       a['subtype'].toLowerCase().indexOf(subtype.toLowerCase()) > -1
      //     );
      //   }
      // );
      this.service
        .getSubscribersAddressData(
          this.getsubaddStartDate,
          this.getsubaddEndDate,
          'lmn',
          'pqr',
          'stu',
          subtype,
          'jkl'
        )
        .subscribe((res) => {
          // console.log(res);
          this.Filteredsubscribersaddressdata = res;
        });
    } else {
      // this.Filteredsubscribersaddressdata = this.subscribersaddressdata.filter(
      //   (a) => {
      //     return a['subtype'].toLowerCase().indexOf(subtype.toLowerCase()) > -1;
      //   }
      // );
      this.service
        .getSubscribersAddressData(
          'abc',
          'def',
          'lmn',
          'pqr',
          'stu',
          subtype,
          'jkl'
        )
        .subscribe((res) => {
          // console.log(res);
          this.Filteredsubscribersaddressdata = res;
        });
    }
  }

  getcomplimentary(event) {
    let complimentary = event.value;
    if (this.getsubaddStartDate != null && this.getsubaddEndDate != null) {
      // this.Filteredsubscribersaddressdata = this.subscribersaddressdata.filter(
      //   (a) => {
      //     return (
      //       new Date(a['created_on']) >= new Date(this.getsubaddStartDate) &&
      //       new Date(a['created_on']) <= new Date(this.getsubaddEndDate) &&
      //       a['complimentary']
      //         .toLowerCase()
      //         .indexOf(complimentary.toLowerCase()) > -1
      //     );
      //   }
      // );
      this.service
        .getSubscribersAddressData(
          this.getsubaddStartDate,
          this.getsubaddEndDate,
          'lmn',
          'pqr',
          'stu',
          'vwx',
          complimentary
        )
        .subscribe((res) => {
          // console.log(res);
          this.Filteredsubscribersaddressdata = res;
        });
    } else {
      // this.Filteredsubscribersaddressdata = this.subscribersaddressdata.filter(
      //   (a) => {
      //     return (
      //       a['complimentary']
      //         .toLowerCase()
      //         .indexOf(complimentary.toLowerCase()) > -1
      //     );
      //   }
      // );
      this.service
        .getSubscribersAddressData(
          'abc',
          'def',
          'lmn',
          'pqr',
          'stu',
          'vwx',
          complimentary
        )
        .subscribe((res) => {
          // console.log(res);
          this.Filteredsubscribersaddressdata = res;
        });
    }
  }

  sub_name1 = '';
  getsub_name1(event, option) {
    if (event.isUserInput) {
      this.sub_name1 = option['sub_name'];
      if (this.sub_name1 != '') {
        this.Filteredsubscribersledgerdata = this.subscribersledgerdata.filter(
          (a) => {
            return (
              a['name']
                .toLowerCase()
                .indexOf(this.sub_name1.toLocaleLowerCase()) > -1
            );
          }
        );
      }
    }
  }

  reset_sub_name1() {
    this.sub_name1 = '';
    this.subledgerForm.get('sub_name').reset();
    if (this.sub_name1 == '') {
      this.Filteredsubscribersledgerdata = this.subscribersledgerdata;
    }
    this.getRepName();
  }

  sub_no1 = '';
  getsub_no1(event, option) {
    if (event.isUserInput) {
      this.sub_no1 = option['sub_no'];
      if (this.sub_no1 != '') {
        this.Filteredsubscribersledgerdata = this.subscribersledgerdata.filter(
          (a) => {
            return a['subno'] == this.sub_no1;
          }
        );
      }
    }
  }

  reset_sub_no1() {
    this.sub_no1 = '';
    this.subledgerForm.get('sub_no').reset();
    if (this.sub_no1 == '') {
      this.Filteredsubscribersledgerdata = this.subscribersledgerdata;
    }
    this.getRepName();
  }

  subledgerstartDate;
  subledgerendDate;
  getChangedDatesubledger() {
    if (
      this.subledgerForm.get('start').value != null &&
      this.subledgerForm.get('end').value != null
    ) {
      this.subledgerstartDate = formatDate(
        new Date(this.subledgerForm.get('start').value),
        'yyyy-MM-dd',
        'en-IN',
        'GMT+0530'
      );
      this.subledgerendDate = formatDate(
        new Date(this.subledgerForm.get('end').value),
        'yyyy-MM-dd',
        'en-IN',
        'GMT+0530'
      );

      this.Filteredsubscribersledgerdata = this.subscribersledgerdata.filter(
        (a) => {
          return (
            new Date(a['entry_date']) >= new Date(this.subledgerstartDate) &&
            new Date(a['entry_date']) <= new Date(this.subledgerendDate)
          );
        }
      );
      // console.log('filtered data', this.Filteredsubscribersledgerdata);
    }
  }

  reset_Datesubledger() {
    this.subledgerstartDate = '';
    this.subledgerendDate = '';
    this.subledgerForm.get('start').reset();
    this.subledgerForm.get('end').reset();
    if (this.subledgerstartDate == '' && this.subledgerendDate == '') {
      this.Filteredsubscribersledgerdata = this.subscribersledgerdata;
    }
  }

  startDate;
  endDate;
  getChangedDatedist() {
    if (
      this.dateform.get('start').value != '' &&
      this.dateform.get('end').value != ''
    ) {
      this.startDate = formatDate(
        this.dateform.get('start').value,
        'yyyy-MM-dd',
        'en-IN',
        'GMT+0530'
      );

      this.endDate = formatDate(
        this.dateform.get('end').value,
        'yyyy-MM-dd',
        'en-IN',
        'GMT+0530'
      );
    }
  }

  openDistribution() {
    this.dialog.open(ShowDistributionComponent, {
      data: {
        start: this.startDate,
        end: this.endDate,
      },
      width: '1200px',
      height: '1300px',
      maxWidth: '100%',
    });
  }

  dispatchdate = '';
  sundayDate = '';
  getDispatchDate(event) {
    this.dispatchdate = formatDate(
      event.value,
      'dd/MM/yyyy',
      'en-IN',
      'GMT+0530'
    );
    // console.log(this.dispatchdate);

    let date = event.value;
    const weekfirstday = date.getDate() - date.getDay();
    this.sundayDate = formatDate(
      date.setDate(weekfirstday + 7),
      'dd/MM/yyyy',
      'en-IN',
      'GMT+0530'
    );
    // console.log(this.sundayDate);
  }

  getPincodewiseStatement() {
    this.dialog.open(PincodewiseStatementComponent, {
      data: {
        sundaydate: this.sundayDate,
        dispatchdate: this.dispatchdate,
        data: this.FilteredlabelsforDispatch,
      },
      width: '1200px',
      height: '1300px',
      maxWidth: '100%',
    });
  }

  getOtherStatePincodewiseStatement() {
    this.dialog.open(OtherStatePincodewiseStatementComponent, {
      data: {
        sundaydate: this.sundayDate,
        dispatchdate: this.dispatchdate,
        // data: this.FilteredlabelsforDispatch,
        data: this.FilteredlabelsforDispatch, 
      },
      width: '1200px',
      height: '1300px',
      maxWidth: '100%',
    });
  }

  getDistrictwiseStatement() {
    this.dialog.open(DistrictwiseStatementComponent, {
      data: {
        sundaydate: this.sundayDate,
        dispatchdate: this.dispatchdate,
        data: this.FilteredlabelsforDispatch, 
        type : this.mode_names,
        state : this.state_names,
        district : this.district_names
      },
      width: '1200px',
      height: '1300px',
      maxWidth: '100%',
    });
  }
  getStatewiseStatement() {
    this.dialog.open(StatewisestatementComponent, {
      data: {
        sundaydate: this.sundayDate,
        dispatchdate: this.dispatchdate,
        data: this.FilteredlabelsforDispatch, 
        type : this.mode_names,
        state : this.state_names,
        issuedate :  this.issuedate,
        country_names : this.country_names,
      },
      width: '1200px',
      height: '1300px',
      maxWidth: '100%',
    });
  }

  getMaxListLength(list1: any[], list2: any[]): number {
    return Math.max(list1.length, list2.length);
  }

  maxLength:any

  person_name = 'प्रतिनिधीनुसार';
  handDeliveryData;
  handDelivery() {
    debugger;
    const req1:any = {
      "issue_date":this.issuedate,
      "dist_type":this.dis_mode,
      "sub_dist_type":this.sub_mode,
      "dist_rep":this.dist_rep_name,
      "country":this.country_names,
      "state":this.state_names,
      "rep_name":this.repnames,
      "off_rep_name": this.officerepnames,
      "district":this.district_names,
      "taluka": this.taluka_names,
      "dev_mode":this.dev_mode,
      "pincode": this.pincodes,
      "title":this.title1,
      "subtype":this.sub_types, 
    }

    this.service
    .getnewapi1(req1)
    .subscribe((res) => {
      this.disRepData1 = res;
      // console.log(this.disRepData);

       // if(confi)
       
       this.service.getSbhetdata(this.issuedate).subscribe((res) => {
        this.labeldata = res;
        console.log(this.labeldata, "this. label");
        let data = this.dialog.open(HandDeliveryComponent, {
          data: {
            
            data: this.labeldata,
            data1: this.disRepData1,
            name: this.person_name,
            isuuedate: this.issuedate,
            dist_name : this.dist_rep_name,
            dis_mode : this.dismode1,
            dis_sub_mode : this.subdismode
          },
          width: '1200px',
          height: '1300px',
          maxWidth: '100%',
        });
    
        data.afterClosed().subscribe((result) => {
          if (result['data'] == 'refresh') {
            this.handDeliveryData = result['val'];
            // if (localStorage.getItem('goto') == 'delivery_mode') {
            this.demo1BtnClick1();
            //   localStorage.removeItem('goto');
            // }
          }
        });
      });
   
    });

    console.log(this.labeldata, "compare data 1")
    console.log(this.disRepData1, "compare data 2") 
  }

  handDeliveryLabel() {

    const req1:any = {
      "issue_date":this.issuedate,
      "dist_type":this.dis_mode,
      "sub_dist_type":this.sub_mode,
      "dist_rep":this.dist_rep_name,
      "country":this.country_names,
      "state":this.state_names,
      "rep_name":this.repnames,
      "off_rep_name": this.officerepnames,
      "district":this.district_names,
      "taluka": this.taluka_names,
      "dev_mode":this.dev_mode,
      "pincode": this.pincodes,
      "title":this.title1,
      "subtype":this.sub_types, 
    }

    this.service
    .getnewapi1(req1)
    .subscribe((res) => {
      this.disRepData1 = res;
      // console.log(this.disRepData);

       // if(confi)
       
       this.service.getSbhetdata(this.issuedate).subscribe((res) => {
        this.labeldata = res;
        console.log(this.labeldata, "this. label");
        let data = this.dialog.open(LabelsOfHandDeliveryComponent, {
          data: {
            
            data1: this.labeldata,
            data2: this.disRepData1,
            name: this.person_name,
            isuuedate: this.issuedate,
            dispatchdate : this.dispatchdate,
            dist_name : this.dist_rep_name,
            dis_mode : this.dismode1,
            dis_sub_mode : this.subdismode

          },
          width: '1200px',
          height: '1300px',
          maxWidth: '100%',
        });
    
        data.afterClosed().subscribe((result) => {
          if (result['data'] == 'refresh') {
            this.handDeliveryData = result['val'];
            // if (localStorage.getItem('goto') == 'delivery_mode') {
            this.demo1BtnClick1();
            //   localStorage.removeItem('goto');
            // }
          }
        });
      });
   
    });

    console.log(this.labeldata, "compare data 1")
    console.log(this.disRepData1, "compare data 2") 

    // this.labeldata = ''
    // this.databyfilteraddress()
    // const req1:any = {
    //   "issue_date":this.issuedate,
    //   "dist_type":this.dis_mode,
    //   "sub_dist_type":this.sub_mode,
    //   "dist_rep":this.dist_rep_name,
    //   "country":this.country_names,
    //   "state":this.state_names,
    //   "rep_name":this.repnames,
    //   "off_rep_name": this.officerepnames,
    //   "district":this.district_names,
    //   "taluka": this.taluka_names,
    //   "dev_mode":this.dev_mode,
    //   "pincode": this.pincodes,
    //   "title":this.title1,
    //   "subtype":this.sub_types, 
    // }

    // this.service
    // .getnewapi1(req1)
    // .subscribe((res) => {
    //   this.disRepData1 = res;
    // let data = this.dialog.open(LabelsOfHandDeliveryComponent, {
    //   data: {
        
    //     data1: this.labeldata,
    //     data2: this.disRepData1,
    //     name: this.person_name,
    //     isuuedate: this.issuedate,
    //   },
    //   width: '1200px',
    //   height: '1300px',
    //   maxWidth: '100%',
    // });

    // data.afterClosed().subscribe((result) => {
    //   if (result['data'] == 'refresh') {
    //     this.handDeliveryData = result['val'];
    //     this.demo1BtnClick1();
       
    //   }
    // });
    // });
  }

  handDeliveryLabelList() {
    
    // console.log(this.FilteredLabelsListForDispatch);

    this.dialog.open(HanddeliverylabelslistComponent, {
      data: {
        data: this.disRepData,
        dis_mode:this.dis_mode,
        mode: this.sub_mode,
        vitarandate: this.dispatchdate,
        dist_rep_name : this.dist_rep_name,
        issue_date : this.issuedate,
        start_date : this.getbookrcptStartDate,
        end_date : this.getbookrcptEndDate,
        title : this.title1,
        sub_name : this.sub_names,
        sub_type : this.sub_types,
        country_name : this.country_names,
        state_name : this.state_names,
        dist_name : this.district_names,
        taluka_name : this.taluka_names,
        pincode : this.pincodes,
        offrep_name : this.officerepnames,
        rep_name : this.repnames,
        del_mode : this.mode_names,
      },
      width: '1300px',
      height: '1300px',
      maxWidth: '100%',
    });
  }

  printOrder() {
    // console.log(this.issuedate);

    let data = this.dialog.open(PrintOrderStatementComponent, {
      data: {
        issuedate: this.issuedate,
        issuetype : this.sub_types
      },
      width: '1200px',
      height: '1300px',
      maxWidth: '100%',
    });

    data.afterClosed().subscribe((_a) => {
      this.getAllPrintOrderData();
    });
  }

  printOrderData;
  getAllPrintOrderData() {
    this.service.getAllPrintOrderData().subscribe((res) => {
      // console.log(res);
      this.printOrderData = res;
    });
  }

  viewPrintOrder(data: any) {
    // console.log(data);
    this.dialog.open(ViewPrintOrderComponent, {
      data: {
        data: data,
      },
      width: '1200px',
      height: '1300px',
      maxWidth: '100%',
    });
  }

  updatePrintOrder(data: any) {
    
    console.log(data);
    this.dialog.open(UpdatePrintOrderComponent, {
      data: {
        data: data,
      },
      width: '1200px',
      height: '1300px',
      maxWidth: '100%',
    });
  }

  cancelPrintOrder(id: number) {
    // console.log(id);
    
    const req: any = {
      "order_id": id
    }
    if (window.confirm('Are you sure want to delete?')) {
      this.service.cancelPrintOrder(req).subscribe((res) => {

        console.log(res)
      });
    }
  }

  printJavak() {
    this.dialog.open(PrintJavakComponent, {
      data: {
        issuedate: this.issuedate,
        data: this.FilteredlabelsforDispatch,
      },
      width: '1200px',
      height: '1300px',
      maxWidth: '100%',
    });
  }

  printchallan() {
    this.dialog.open(PrintchallanComponent, {
      data: {
        issuedate: this.issuedate,
        data: this.FilteredlabelsforDispatch,
      },
      width: '1200px',
      height: '1300px',
      maxWidth: '100%',
    });
  }

  distSubReport() {
    this.dialog.open(DistrictwiseSubscriptionReportComponent, {
      data: {
        issuedate: this.issuedate,
        data: this.FilteredlabelsforDispatch,
      },
      width: '1200px',
      height: '1300px',
      maxWidth: '100%',
    });
  }

  distVSubReport() {
    this.dialog.open(DistrictwiseVarganidarSubscriptionReportComponent, {
      data: {
        issuedate: this.issuedate,
        data: this.FilteredlabelsforDispatch,
      },
      width: '1200px',
      height: '1300px',
      maxWidth: '100%',
    });
  }

  prantwiseReport() {
    this.dialog.open(PrantwiseVarganidarReportComponent, {
      data: {
        issuedate: this.issuedate,
        data: this.FilteredlabelsforDispatch,
      },
      width: '1200px',
      height: '1300px',
      maxWidth: '100%',
    });
  }

  // datewiseVarganidarRojmelReport() {
  //   if (this.getbookrcptStartDate != '' && this.getbookrcptEndDate != '') {
  //     this.dialog.open(DatewiseVarganidarRojmelReportComponent, {
  //       data: {
  //         // issuedate: this.issuedate,
  //         startDate: this.getbookrcptStartDate,
  //         endDate: this.getbookrcptEndDate,
  //         data: this.FilteredlabelsforDispatch,
  //       },
  //       width: '1200px',
  //       height: '1300px',
  //       maxWidth: '100%',
  //     });
  //   } else {
  //     alert('Please select from to date');
  //   }
  // }

  sub_mode = '';
  postStatement() {
    if (this.subdismode === 'जी.पी.ओ' && this.dismode1 === 'टपाल') {
      this.postStatementTapal();
    } else {
      
      const req1:any = {
        "issue_date":this.issuedate,
        "dist_type":this.dis_mode,
        "sub_dist_type":this.sub_mode,
        "dist_rep":this.dist_rep_name,
        "country":this.country_names,
        "state":this.state_names,
        "rep_name":this.repnames,
        "off_rep_name": this.officerepnames,
        "district":this.district_names,
        "taluka": this.taluka_names,
        "dev_mode":this.dev_mode,
        "pincode": this.pincodes,
        "title":this.title1,
        "subtype":this.sub_types, 
      }
  
      this.service
      .getnewapi1(req1)
      .subscribe((res) => {
        this.disRepData1 = res;
       
     
   this.dialog.open(PostStatementComponent, {
        data: {
          data: this.labeldata,
          data1: this.disRepData1,
          mode: this.sub_mode,
          prakashandate: this.issuedate,
          vitarandate: this.dispatchdate,
          dist_name : this.dist_rep_name,
          dis_mode : this.dismode1,
          dis_sub_mode : this.subdismode
        },
        width: '1200px',
        height: '1300px',
        maxWidth: '100%',
      });
      });
      // this.dialog.open(PostStatementComponent, {
      //   data: {
      //     data: this.disRepData,
      //     data1: this.dis_mode,
      //     mode: this.sub_mode,
      //     prakashandate: this.issuedate,
      //     vitarandate: this.dispatchdate,
      //   },
      //   width: '1200px',
      //   height: '1300px',
      //   maxWidth: '100%',
      // });
    }
  }

  postStatementTapal() {
    this.dialog.open(PostStatementTapalComponent, {
      data: {
        // data: this.FilteredlabelsforDispatch,
        data: this.labelsforDispatch,
        dis_mode: this.dismode1,
        mode: this.subdismode,
        prakashandate: this.issuedate,
        vitarandate: this.dispatchdate,
        vajan: this.distributionForm.get('vajan').value,
        tapalrate: this.distributionForm.get('tapalrate').value,
      },
      width: '1200px',
      height: '1300px',
      maxWidth: '100%',
    });
  }

  distributionMode(event, option) {
    if (event.isUserInput) {
      let val = option['id'];
      this.FilteredsubDistributionModeData =
        this.subDistributionModeData.filter((a) => {
          return a['sub_deliver_mode_id'] == val;
        });

      // console.log(this.FilteredsubDistributionModeData);
    }
  }

  assignDistributionMode() {
    let data = {
      disrep: this.AssignDisRepForm.get('disrep1').value,
      dismode: this.AssignDisRepForm.get('dismode').value,
      dissubmode: this.AssignDisRepForm.get('dissubmode').value,
    };

    // console.log('data', data);

    this.service.addAssignDistributionMode(data).subscribe((_data) => {
      window.alert(
        'Distribution Mode Assign to Representative Successfully...'
      );
      this.AssignDisRepForm.reset();
      this.ngOnInit();
    });
  }

  dis_mode_id = 0;
  dis_mode_show = false;
  updateDisMode(id) {
    for (
      let index = 0;
      index < Object.keys(this.distributionmoderepData).length;
      index++
    ) {
      if (id == this.distributionmoderepData[index]['id']) {
        this.dis_mode_show = true;
        this.dis_mode_id = this.distributionmoderepData[index]['id'];
        this.AssignDisRepForm.get('disrep1').setValue(
          this.distributionmoderepData[index]['dis_rep']
        );
        this.AssignDisRepForm.get('dismode').setValue(
          this.distributionmoderepData[index]['dis_mode']
        );
        this.AssignDisRepForm.get('dissubmode').setValue(
          this.distributionmoderepData[index]['dis_sub_mode']
        );
      }
    }
  }

  removeassigndismode(id) {
    if (
      window.confirm('This Operatin is not reversable? Do you want to procced?')
    ) {
      this.service.deleteAssignDisMode(id).subscribe((_res) => {
        window.alert('Record deleted successfully...');
        this.ngOnInit();
      });
    }
  }

  updateassignDistributionMode() {
    let data = {
      id: this.dis_mode_id,
      disrep: this.AssignDisRepForm.get('disrep1').value,
      dismode: this.AssignDisRepForm.get('dismode').value,
      dissubmode: this.AssignDisRepForm.get('dissubmode').value,
    };

    // console.log('data', data);

    this.service.updateAssignDistributionMode(data).subscribe((_data) => {
      window.alert('Distribution Mode Updated Successfully...');
      this.AssignDisRepForm.reset();
      this.dis_mode_show = false;
      this.ngOnInit();
    });
  }



  delivery_mode1(id){
    // this.id = id;
    this.service.deletedelmode(id).subscribe((_data) => {
      window.alert('Delivery Mode Delete Successfully...');
      this.ngOnInit();
    });
  }
delivery_update_data:any
  update_delivery_mode1(item){
    this.delivery_update_data = item
    this.delivery_mode_update_btn = true
    

    this.adddeliverymode.patchValue({
      deliverymode : item.delivery_mode,
    })
    // this.id = id;
    // this.service.deletedelmode(id).subscribe((_data) => {
    //   window.alert('Delivery Mode Delete Successfully...');
    //   this.ngOnInit();
    // });
  }
  
  update_delivery_modeapi(){
    this.id =  this.delivery_update_data.id
    this.dev_mode = this.adddeliverymode.value.deliverymode;
    this.service.updatedeliverymode( this.id, this.dev_mode).subscribe((_data) => {
      window.alert('Delivery Mode Updated Successfully...');
      this.ngOnInit();
    });
  }

  dis_mode_show1 = false;
  dis_mode_id1 = 0;
  updatedismode(id) {
    // console.log(this.distributionmodesData);
    for (
      let index = 0;
      index < Object.keys(this.distributionmodesData).length;
      index++
    ) {
      if (id == this.distributionmodesData[index]['id']) {
        this.dis_mode_show1 = true;
        this.dis_mode_id1 = this.distributionmodesData[index]['id'];
        this.adddeliverymodeform
          .get('deliverymode')
          .setValue(this.distributionmodesData[index]['delivery_mode_id']);
        this.adddeliverymodeform
          .get('subdeliverymode')
          .setValue(this.distributionmodesData[index]['sub_delivery_mode']);
      }
    }
  }

  removedismode(id) {
    if (
      window.confirm('This Operatin is not reversable? Do you want to procced?')
    ) {
      this.service.deleteDisMode(id).subscribe((_res) => {
        window.alert('Record deleted successfully...');
        this.ngOnInit();
      });
    }
  }

  updateaddSubDelivery() {
    let data = {
      id: this.dis_mode_id1,
      deliverymode: this.adddeliverymodeform.get('deliverymode').value,
      subdeliverymode: this.adddeliverymodeform.get('subdeliverymode').value,
    };

    // console.log(data);
    this.service.updateAddSubDeliveryMode(data).subscribe((_res) => {
      window.alert('Delivery Mode Updated Successfully...');
      this.adddeliverymodeform.reset();
      this.dis_mode_show1 = false;
      this.ngOnInit();
    });
  }

  sub_dis_mode_show = false;
  sub_dis_mode_id = 0;
  updatesubdismode(id) {
    // console.log(this.subdistributionmodesData);

    for (
      let index = 0;
      index < Object.keys(this.subdistributionmodesData).length;
      index++
    ) {
      if (id == this.subdistributionmodesData[index]['id']) {
        this.sub_dis_mode_show = true;
        this.sub_dis_mode_id = this.subdistributionmodesData[index]['id'];
        this.addsubdeliverymodeform
          .get('deliverymode')
          .setValue(
            this.subdistributionmodesData[index]['sub_deliver_mode_id']
          );
        this.addsubdeliverymodeform
          .get('subdeliverymode')
          .setValue(this.subdistributionmodesData[index]['subdeliverymode']);
      }
    }
  }

  removesubdismode(id) {
    if (
      window.confirm('This Operatin is not reversable? Do you want to procced?')
    ) {
      this.service.deleteSubDisMode(id).subscribe((_res) => {
        window.alert('Record deleted successfully...');
        this.ngOnInit();
      });
    }
  }

  updateaddSubDeliveryofSub() {
    let data = {
      id: this.sub_dis_mode_id,
      deliverymode: this.addsubdeliverymodeform.get('deliverymode').value,
      subdeliverymode: this.addsubdeliverymodeform.get('subdeliverymode').value,
    };

    // console.log(data);

    this.service.updateAddSubDeliverySubMode(data).subscribe((_res) => {
      window.alert('Sub Distribution Mode Updated Successfully...');
      this.addsubdeliverymodeform.reset();
      this.sub_dis_mode_show = false;
      this.ngOnInit();
    });
  }

  dismode1 = '';
  dis_mode: any
  getdismode(event: any) {
    debugger
    this.dismode1 = event.value;
    this.dis_mode = this.dismode1
    this.service.getsubdistmode(this.dis_mode).subscribe((data) => {
      // console.log('pkgBookingData', data);
      this.subdistmode = data;
    });
    // if (this.dismode1 != '') {
    //   this.FilteredLabelsSubDisModes = this.LabelsSubDisModes.filter(
    //     (a) => a['dis_mode'] == this.dismode1
    //   );
    //   console.log(this.FilteredLabelsSubDisModes);
    //   // console.log(this.labelsforDispatch);

    //   this.FilteredlabelsforPostStatement = this.labelsforDispatch.filter(
    //     (a) => a['dis_mode'] == this.dismode1
    //   );
    //   this.getFilteredlabelslistfordispatch();
    //   // console.log(this.FilteredlabelsforPostStatement);
    // }
  }

  reset_dismode() {
    this.dismode1 = '';
    this.distributionForm.get('dismode').reset();
    if (this.dismode1 == '') {
      this.FilteredlabelsforPostStatement = this.labelsforDispatch;
      this.getFilteredlabelslistfordispatch();
    }
  }

  subdismode = '';
  getsubdismode(event) {

    // console.log('LabelsSubDisModes', this.LabelsSubDisModes);
    this.subdismode = event.value;
    this.sub_mode = this.subdismode;
    // for (
    //   let index = 0;
    //   index < Object.keys(this.LabelsSubDisModes).length;
    //   index++
    // ) {
    //   if (this.subdismode == this.LabelsSubDisModes[index]['sub_dis_mode']) {
    //     this.sub_mode = this.LabelsSubDisModes[index]['sub_dis_mode'];
    //     this.dismode1 = this.LabelsSubDisModes[index]['dis_mode'];
    //   }
    // }
    if (this.subdismode != '' && this.dismode1 != '') {
      this.FilteredlabelsforPostStatement = this.labelsforDispatch.filter(
        (a) =>
          a['dis_sub_mode'] == this.subdismode && a['dis_mode'] == this.dismode1
      );
      this.getFilteredlabelslistfordispatch();
      // console.log(
      //   'FilteredlabelsforPostStatement',
      //   this.FilteredlabelsforPostStatement
      // );
    }
  }

  reset_subdismode() {
    this.subdismode = '';
    this.distributionForm.get('subdismode').reset();
    if (this.subdismode == '') {
      this.FilteredlabelsforPostStatement = this.labelsforDispatch;
      this.getFilteredlabelslistfordispatch();
    }
  }

  updateReturnReceipt(item) {
    // console.log(item);

    let data = this.dialog.open(UpdateReturnReceiptComponent, {
      data: {
        val: item,
      },
      width: '500px',
      height: '500px',
      maxWidth: '100%',
    });

    data.afterClosed().subscribe((result) => {
      if (result['res'] == 'close') {
        this.ngOnInit();
      }
    });
  }

  filteredReceiptLedgerData() {
    let test = [];
    let data = this.uniqueid(this.receiptledger, (a) => a.name);
    // console.log('data', data);

    for (let index = 0; index < Object.keys(data).length; index++) {
      let arr = this.receiptledger.filter(
        (a) => a['name'] == data[index]['name']
      );
      // console.log(arr);

      for (let i = 0; i < Object.keys(arr).length; i++) {
        test.push(arr[i]);
      }
    }
    // console.log('test', test);
    return test;
  }

  gstreportstartdate;
  gstreportenddate;
  getChangedDategstreport() {
    if (
      this.gstreportForm.get('start').value != null &&
      this.gstreportForm.get('end').value != null
    ) {
      this.gstreportstartdate = formatDate(
        new Date(this.gstreportForm.get('start').value),
        'yyyy-MM-dd',
        'en-IN',
        'GMT+0530'
      );
      this.gstreportenddate = formatDate(
        new Date(this.gstreportForm.get('end').value),
        'yyyy-MM-dd',
        'en-IN',
        'GMT+0530'
      );

      // this.showGSTReceiptData = this.showGSTReceiptData.filter((a) => {
      //   return (
      //     new Date(a['entry_date']) >= new Date(this.gstreportstartdate) &&
      //     new Date(a['entry_date']) <= new Date(this.gstreportenddate)
      //   );
      // });
      this.getGSTReportData(this.gstreportstartdate, this.gstreportenddate);
    }
    // this.calculateGSTReportData();
  }

  calculateGSTReportData() {
    // console.log(this.showGSTReceiptData);
    this.gstreporttotalamt = 0;
    for (
      let index = 0;
      index < Object.keys(this.showGSTReceiptData).length;
      index++
    ) {
      this.gstreporttotalamt =
        this.gstreporttotalamt +
        parseInt(this.showGSTReceiptData[index]['total_amt']);
    }
    // console.log(this.gstreporttotalamt);

    this.exportGSTReceiptData.push({
      SR_NO: 'Total',
      Receipt_Date: '',
      Customer_Name: '',
      Address_of_Company: '',
      Receipt_No: '',
      GST_No: '',
      HSN_Code: '',
      Total_Amount: this.gstreporttotalamt,
    });
  }

  GSTReportPrint() {
    
    this.dialog.open(PrintGSTReportComponent, {
      data: {
        val: this.showGSTReceiptData,
        totalamt: this.gstreporttotalamt,
        startdate : this.gstreportstartdate,
        enddate : this.gstreportenddate,
      },
      width: '1200px',
      height: '1300px',
      maxWidth: '100%',
    });
  }

  addBillGSTHSNData(event, id, key) {
    // console.log(event.target.value, id);
    switch (key) {
      case 'billno':
        this.showGSTReceiptData[id]['rcpt_no'] = event.target.value;
        this.exportGSTReceiptData[id]['Receipt_No'] = event.target.value;
        break;

      case 'gstno':
        this.showGSTReceiptData[id]['gst_no'] = event.target.value;
        this.exportGSTReceiptData[id]['GST_No'] = event.target.value;
        break;

      case 'hsncode':
        this.showGSTReceiptData[id]['hsn_code'] = event.target.value;
        this.exportGSTReceiptData[id]['HSN_Code'] = event.target.value;
        break;
    }
  }

  subdate = '';
  sdate = '';
  edate = '';
  FiletredreceiptledgerData = [];
  selectSubDate() {
    // console.log(this.subdate);
    // this.subdate = formatDate(this.subdate, 'yyyy-MM-dd', 'en-IN', 'GMT+0530');
    this.sdate = formatDate(this.receiptLedger.value.start, 'yyyy-MM-dd', 'en-IN', 'GMT+0530');
    this.edate = formatDate(this.receiptLedger.value.end, 'yyyy-MM-dd', 'en-IN', 'GMT+0530');


    // if (this.subdate != '') {
    //   this.FiletredreceiptledgerData = this.receiptledger.filter((a) => {
    //     return a['entry_date'] == this.subdate;
    //   });
    // }

    this.filteredReceiptLedgerData = this.receiptledger.filter((entry) => {
      // Convert entry['entry_date'] to a Date object if it's not already
      let entryDate = new Date(entry['entry_date']);
      let startDate = new Date(this.sdate);
      let endDate = new Date(this.edate);
      
      // Check if entryDate is between startDate and endDate
      return entryDate >= startDate && entryDate <= endDate;
  });
  }

  subReceipt() {
     this.service
          .getReceiptLedgerData(this.sdate, this.edate, this.cust_name)
          .subscribe((data) => {
            this.FiletredreceiptledgerData = data;

            this.dialog.open(ShowSubscriptionNodaniReceiptComponent, {
              data: {
                val: this.FiletredreceiptledgerData,
                startDate: this.receiptLedger.value.start,
                endDate: this.receiptLedger.value.end,
                name : this.cust_name
              },
              width: '1200px',
              height: '1300px',
              maxWidth: '100%',
            });
          });
    
    console.log(this.FiletredreceiptledgerData,"helllo")
    
  }

  subReceiptLedger() {
    this.dialog.open(ShowSubscriberReceiptLedgerPrintComponent, {
      data: {
        val: this.Filetredreceiptledger,
      },
      width: '1300px',
      height: '1300px',
      maxWidth: '100%',
    });
  }

  handlePageEvent(e: PageEvent) {
    this.length = e.length;
    this.pageSize = e.pageSize;
    this.pageIndex = e.pageIndex + 1;

    this.getSubscribersDetails(this.pageIndex, this.pageSize);
  }

  // handlePageEvent1(e: PageEvent) {
  //   this.length1 = e.length;
  //   this.pageSize1 = e.pageSize;
  //   this.pageIndex1 = e.pageIndex + 1;

  //   this.getReceiptLedgerDetails(this.pageIndex1, this.pageSize1);
  // }

  handlePageEvent2(e: PageEvent) {
    this.length2 = e.length;
    this.pageSize2 = e.pageSize;
    this.pageIndex2 = e.pageIndex + 1;

    this.getSubscriberLedgerData(this.pageIndex2, this.pageSize2);
  }

  getrenewStartDate = '';
  getrenewEndDate = '';
  getRenewReportDateRange() {
    if (
      this.renewalreportForm.get('start').value != null &&
      this.renewalreportForm.get('end').value != null
    ) {
      this.getrenewStartDate = formatDate(
        new Date(this.renewalreportForm.get('start').value),
        'yyyy-MM-dd',
        'en-IN',
        'GMT+0530'
      );
      this.getrenewEndDate = formatDate(
        new Date(this.renewalreportForm.get('end').value),
        'yyyy-MM-dd',
        'en-IN',
        'GMT+0530'
      );
    }
  }

  rep_names = '';
  selected_rep_names(event, option) {
    if (event.isUserInput) {
      this.rep_names = option['repname'];
    }
  }

  renewalreportData = [];
  getRenewReport() {
    if (
      this.rep_names != '' &&
      this.getrenewStartDate != '' &&
      this.getrenewEndDate != ''
    ) {
      this.service
        .getAllLabelForDispatch(
          this.getrenewStartDate,
          this.getrenewEndDate,
          this.rep_names
        )
        .subscribe((res) => {
          // console.log(res);
          this.renewalreportData = res;
        });
    } else if (
      this.rep_names == '' &&
      this.getrenewStartDate != '' &&
      this.getrenewEndDate != ''
    ) {
      this.service
        .getAllLabelForDispatch(
          this.getrenewStartDate,
          this.getrenewEndDate,
          'xyz'
        )
        .subscribe((res) => {
          // console.log(res);
          this.renewalreportData = res;
        });
    } else if (
      this.rep_names != '' &&
      this.getrenewStartDate == '' &&
      this.getrenewEndDate == ''
    ) {
      this.service
        .getAllLabelForDispatch('abc', 'pqr', this.rep_names)
        .subscribe((res) => {
          // console.log(res);
          this.renewalreportData = res;
        });
    } else if (
      this.rep_names == '' &&
      this.getrenewStartDate == '' &&
      this.getrenewEndDate == ''
    ) {
      this.service
        .getAllLabelForDispatch('abc', 'pqr', 'xyz')
        .subscribe((res) => {
          // console.log(res);
          this.renewalreportData = res;
        });
    }
  }

  getAddressDistrictwiseStatement() {
    this.getRenewReport();
    setTimeout(() => {
      // this.dialog.open(AddressLabelsMobileNoComponent, {
      //   data: {
      //     addresslabels: this.renewalreportData,
      //   },
      //   width: '1200px',
      //   height: '1300px',
      //   maxWidth: '100%',
      // });
      this.dialog.open(DistrictwiseStatementComponent, {
        data: {
          sundaydate: this.sundayDate,
          dispatchdate: this.dispatchdate,
          data: this.renewalreportData,
        },
        width: '1200px',
        height: '1300px',
        maxWidth: '100%',
      });
    }, 1000);
  }

  getRenewPincodewiseStatement() {
    this.getRenewReport();
    setTimeout(() => {
      this.dialog.open(PincodewiseStatementComponent, {
        data: {
          sundaydate: this.sundayDate,
          dispatchdate: this.dispatchdate,
          data: this.renewalreportData,
        },
        width: '1200px',
        height: '1300px',
        maxWidth: '100%',
      });
    }, 1000);
  }

  subrenewaldatestartdate = '';
  subrenewaldateenddate = '';
  getChangedDateofSubRenewalReport() {
    if (
      this.subRenewalReportForm.get('start').value != null &&
      this.subRenewalReportForm.get('end').value != null
    ) {
      this.subrenewaldatestartdate = formatDate(
        new Date(this.subRenewalReportForm.get('start').value),
        'yyyy-MM-dd',
        'en-IN',
        'GMT+0530'
      );
      this.subrenewaldateenddate = formatDate(
        new Date(this.subRenewalReportForm.get('end').value),
        'yyyy-MM-dd',
        'en-IN',
        'GMT+0530'
      );

      this.getRenewRemainingData(
        this.subrenewaldatestartdate,
        this.subrenewaldateenddate,
        this.subrenewalRep_name,
        this.subrenewalOffRep_name,
        this.subRenewalReportForm.get('district').value,
        this.subRenewalReportForm.get('taluka').value
      );
      // this.getrep_name_data();
    }
  }

  subrenewalRep_name = '';
  getsubRenewalrepnames(event) {
    this.subrenewalRep_name = event.value;

    this.getRenewRemainingData(
      this.subrenewaldatestartdate,
      this.subrenewaldateenddate,
      this.subrenewalRep_name,
      this.subrenewalOffRep_name,
      this.subRenewalReportForm.get('district').value,
      this.subRenewalReportForm.get('taluka').value
    );
  }

  subrenewalOffRep_name = '';
  getsubRenewaloff_rep_name(event, option) {
    if (event.isUserInput) {
      this.subrenewalOffRep_name = option['office_rep_name'];

      this.getRenewRemainingData(
        this.subrenewaldatestartdate,
        this.subrenewaldateenddate,
        this.subrenewalRep_name,
        this.subrenewalOffRep_name,
        this.subRenewalReportForm.get('district').value,
        this.subRenewalReportForm.get('taluka').value
      );
    }
  }

  getsubRenewalDistrict(event, data) {
    if (event.isUserInput) {

      this.getRenewRemainingData(
        this.subrenewaldatestartdate,
        this.subrenewaldateenddate,
        this.subrenewalRep_name,
        this.subrenewalOffRep_name,
        this.subRenewalReportForm.get('district').value,
        this.subRenewalReportForm.get('taluka').value
      );

      this.service.getTaluka(data['district_id']).subscribe((res) => {
        // console.log(res);
        this.filteredTalukasName = res;
      });
    }
  }

  getsubRenewalTalukaNames(_event) {
    // console.log(event.value);
    // console.log(this.subRenewalReportForm.get('taluka').value);
    this.getRenewRemainingData(
      this.subrenewaldatestartdate,
      this.subrenewaldateenddate,
      this.subrenewalRep_name,
      this.subrenewalOffRep_name,
      this.subRenewalReportForm.get('district').value,
      this.subRenewalReportForm.get('taluka').value
    );
  }

  renewRemainingData;
  getRenewRemainingData(startDate, endDate, rep_name, off_rep_name, district, taluka) {
    this.service.getRenewableDataByDate(startDate, endDate, rep_name, off_rep_name, district, taluka).subscribe((res) => {
      console.log(res);
      this.renewRemainingData = res;
    });
  }

  deletemaster(item){
    let data = {
      subTypeId: item.id,
    };

    this.service.deletemaster(data).subscribe(
      (data) => {
        alert('Master delete successfully...');
        // this.addform.reset();
        // this.ngOnInit();
      }
    );
  }
  onTabClick(event:any){
    console.log(event)
    localStorage.setItem("tab", event.index)
  }

  district_id = 0;
  getDistrict1(event) {
    debugger
    this.selectedCity = this.cities['district_id'];
    let district = this.cities.find((a) => a.district_name == event.value);
    let dist=[]
    dist.push(district['district_id'])
    if (district != undefined) {
      this.service.getTaluka(dist).subscribe((data) => {
        this.talukas = data;
        this.selectedTaluka = this.talukas['taluka_name'];
      });
    }
  }
  getDistrict(event) {
    
    this.district_id = parseInt(event.value);
    this.getDistrict1(event)
  }

  exportExcel(fileName): void {
    /* table id is passed over here */
    let element = document.getElementById('print-section');
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* save to file */
    XLSX.writeFile(wb, fileName + '.xlsx');
  }

  searchpaymentreceipt(){
    console.log(this.paymentreceiptledger.value)

     const data={
      "start_date":this.rcptldgrstartdate1,
      "end_date":this.rcptldgrenddate1,
      "rcpt_type" : this.paymentreceiptledger.value.active_inactive,
      "prant_name" : this.paymentreceiptledger.value.prant_name,
      "district" : this.paymentreceiptledger.value.district,
      "taluka" : this.paymentreceiptledger.value.taluka,
      "distribution_rep" : this.paymentreceiptledger.value.dis_rep,
      "rep_name" : this.paymentreceiptledger.value.rep,
      "rcpt_no" : this.paymentreceiptledger.value.rcpt_number,
      "country" : this.paymentreceiptledger.value.country,
      "state" : this.paymentreceiptledger.value.state,


    }
    
    this.getReceiptLedgerDetails3(data);
  }

 
  getVoucherNo(event) {
    // console.log('length',event.target.value.length);
    if (event.target.value.length > 5) {
      this.service.getCreditVoucherNo(event.target.value).subscribe((res) => {
        this.voucherNoList = res;
        console.log(this.voucherNoList);
      });
    }
  }
  selected_vouchers(event){
     this.readnum = event.source.value;
    debugger;
    // this.serachlist =  this.databyvc.filter((item)=>{
    //   return  item.voucher_no === this.readnum
    // })

    // this.databyvc = this.serachlist
    // console.log(this.serachlist, "new list")
    // console.log(readnum)
   
  }
  selected_rep(event){
    this.rep_nameforcv = event.source.value;
  }

  searchdatabycv(){
    console.log(this.databyvocuher.value.repname);
    this.getChangedDateContractByVC();
   debugger
    // if (this.rep_nameforcv != '') {
    //   this.serachlist = this.databyvc.filter((item) => {
    //     return item.voucher_no === this.readnum && item.invoice_rep_name === this.rep_nameforcv
    //   })
    // }
    // else {
    //   this.serachlist = this.databyvc.filter((item) => {
    //     return item.voucher_no === this.readnum
    //   })
    // }


    // this.databyvc = this.serachlist
  }
  

}
