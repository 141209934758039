
  <div style="margin-left: 70%;  margin-bottom: 2%">
        <button
            [printStyle]="{table : {'border-collapse':'collapse'}, td : {'border': 'solid 1px', 'font-size':'12px', 'font-weight':'400', 'text-align':'center'}, th : {'border': 'solid 1px', 'font-size':'15px', 'font-weight':'600', 'text-align':'center'}, div : {'font-size':'14px', 'font-weight':'600'}, span : {'font-size':'14px', 'font-weight':'600', 'float':'left'}}"
            class="noPrint" style="margin-right: 2%; margin-left: 2%" mat-raised-button color="primary"   [useExistingCss]="true"

            printSectionId="print-section" ngxPrint>
            print
        </button>
        <!-- <button class="noPrint" style="margin-top: 2% 2% 0 2%;"
            (click)="exportExcel('Datewise_Subscription_Report')" mat-raised-button color="primary">
            Export
        </button> -->
        <button mat-dialog-close class="btn noPrint">
            Close
        </button>
    </div>

<table  class="table table-bordered">
    <thead>
        <tr>
            <th>Pending Address</th>
        </tr>
    </thead>

    <tbody id="print-section">
        <div class="row" >

          <ng-container  *ngFor="let data1 of PendingAdd; let i = index"  style="page-break-inside: avoid;">
            <div class="col-md-12" style="text-align: center; font-size: 17px;">
                {{data1.rep_name}}
            </div>
            <div class="col-3" style="padding: 40px; font-weight: 40px;"*ngFor="let data2 of data1.rep_data,; let i1 = index">
             <b>   {{data2.sub_no}}<br> {{data2.name}} <br> {{data2.address}} <br>{{data2.taluka}} <br> {{data2.district}} - {{data2.pincode}} {{data2.state}} <br>
                 मो.क्र. - {{data2.mobileNumber}} </b>
            </div>
          </ng-container>


           

        </div>

        <!-- <tr *ngFor="let data1 of PendingAdd; let i = index">
            <td>{{data1.rep_name}}</td>
            <td *ngFor="let data2 of data1.rep_data,; let i1 = index">
                <div>
                    {{data2.name}} ,<br> {{data2.address}} , <br> {{data2.pincode}} , {{data2.country}} , {{data2.state}} , <br> {{data2.taluka}} , {{data2.district}} . <br> 
                    {{data2.mobileNumber}} , {{data2.telephone}}
                </div> <br>
            </td>
        </tr> -->

        <!-- <tr *ngFor="let row of generateRows(PendingAdd, 6)">
            <td *ngFor="let item of row.rep_data">
                <div>
                    {{item.name}} ,<br> {{item.address}} , <br> {{item.pincode}} , {{item.country}} , {{item.state}} , <br> {{item.taluka}} , {{item.district}} . <br> 
                    {{item.mobileNumber}} , {{item.telephone}}
                </div> <br>
            </td>
        </tr> -->

    </tbody>

</table>



  