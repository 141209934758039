<!-- CLI -->
<!-- To install angualr CLI -->
<!-- npm install -g @angular/cli@latest -->
<!-- To create new angular project -->
<!-- ng new my-app -->
<!-- To generate a component using use below command -->
<!-- ng generate component editable-data-table-RC1 -->
<!-- To generate a module -->
<!-- ng generate module modules/myheader -->
<!-- To generate a service -->
<!-- ng generate service data -->
<!-- To generate a model class for data types -->
<!-- ng generate class customers model -->
<!-- To deprecate a published npm package -->
<!-- npm deprecate "angular-inline-editable-table@1.0.0" "Version 2 has date type columns' support. Please use version 2"
 -->

<!-- This module is created with the help of this
 https://medium.com/@nikolasleblanc/building-an-angular-4-component-library-with-the-angular-cli-and-ng-packagr-53b2ade0701e -->


<!-- Search -->
<mat-form-field *ngIf="isSearchable">
  <input matInput #search (keyup)="applyFilter($event.target.value)" placeholder="Search">
</mat-form-field>

<button type="button" (click)="exportexcel()" class="btn btn-danger">
  Export
</button>

<!--  Add row 
<div id="addRowDv" *ngIf="isAddRequired">
<button mat-mini-fab color="primary">
  <mat-icon matTooltip="Add row" class="material-icons" (click)="openDialog('add')">add_box</mat-icon>
</button>
</div> -->
<!-- Table -->
<table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8" id="excel-table">

  <!--  Columns -->
  <ng-container *ngFor="let column of columns; let i = index" matColumnDef="{{column}}">

    <th mat-header-cell *matHeaderCellDef> {{column.toUpperCase()}} </th>

    <td mat-cell *matCellDef="let data; let j = index">

      <!-- When Nothing is Ediatble -->
      <p *ngIf="j!==k">{{data[column]}}</p>

      <!-- When only Provided fields are editable -->
      <div *ngIf="(j===k && column.toLowerCase()!=='actions') && (editableFields && editableFields.indexOf(column) !== -1)">
        <!-- Editable text box -->

        <div *ngIf="(dateFields.indexOf(column) === -1)">
          <mat-form-field>
            <!-- Spinner -->
            <ngx-spinner bdColor="white" size="small" color="#03a9f4" type="square-loader">
            </ngx-spinner>

            <input matInput #input maxlength="{{maxCharsInColumn}}" placeholder="{{column.toUpperCase()}}" [value]="data[column]" [(ngModel)]="data[column]"
              autofocus>
            <mat-hint align="end" *ngIf="(maxCharsInColumn && (dateFields.indexOf(column) === -1))">{{data[column]?.length || 0}}/{{maxCharsInColumn}}</mat-hint>
          </mat-form-field>
        </div>

        <div *ngIf="(dateFields && dateFields.indexOf(column) !== -1)">

          <mat-form-field>
            <!-- Spinner -->
            <ngx-spinner bdColor="white" size="small" color="#03a9f4" type="square-loader">
            </ngx-spinner>
            <input matInput #dateInput [matDatepicker]="picker" placeholder="PICK A DATE" [value]="data[column]" [(ngModel)]="data[column]">
            <mat-datepicker #picker></mat-datepicker>
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          </mat-form-field>
        </div>


      </div>
      <!-- When All fields are editable -->
      <div *ngIf="(j===k && column.toLowerCase()!=='actions') && (!editableFields)">

        <!-- Editable text box -->
        <div *ngIf="(dateFields.indexOf(column) === -1)">
          <mat-form-field>
            <!-- Spinner -->
            <ngx-spinner bdColor="white" size="small" color="#03a9f4" type="square-loader">
            </ngx-spinner>
            <input matInput #input maxlength="{{maxCharsInColumn}}" placeholder="{{column.toUpperCase()}}" [value]="data[column]" [(ngModel)]="data[column]"
              autofocus>
            <mat-hint align="end" *ngIf="(maxCharsInColumn && (dateFields.indexOf(column) === -1))">{{data[column]?.length || 0}}/{{maxCharsInColumn}}</mat-hint>
          </mat-form-field>
        </div>


        <div *ngIf="(dateFields && dateFields.indexOf(column) !== -1)">

          <mat-form-field>
            <!-- Spinner -->
            <ngx-spinner bdColor="white" size="small" color="#03a9f4" type="square-loader">
            </ngx-spinner>
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <input matInput [matDatepicker]="picker" placeholder="PICK A DATE" [value]="data[column]" [(ngModel)]="data[column]">
            <mat-datepicker #picker></mat-datepicker>
          </mat-form-field>
        </div>

      </div>

      <!-- Actions icons -->
      <mat-icon *ngIf="(column && column.toLowerCase()==='actions' && j!==k )" matTooltip="Delete row" class="material-icons" color="primary"
        (click)="openDialog(data)"></mat-icon>
      <mat-icon id='edit{{j}}' *ngIf="(column && column.toLowerCase()==='actions' && j!==k )" matTooltip="Edit row" class="material-icons"
        color="primary" (click)="openModal(data)">editors</mat-icon>
      <mat-icon id='edit{{j}}' *ngIf="(column && column.toLowerCase()==='actions' && j!==k )" matTooltip="Edit row" class="material-icons"
        color="primary" (click)="openEdit(data, j)"></mat-icon>
      

      <mat-icon *ngIf="(column && column.toLowerCase()==='actions' && j===k )" matTooltip="Update row" class="material-icons" color="primary"
        (click)="doneEdit(data)">done</mat-icon>
      <mat-icon *ngIf="(column && column.toLowerCase()==='actions' && j===k )" matTooltip="Cancel" class="material-icons" color="primary"
        (click)="closeEdit(data)">clear</mat-icon>
    </td>

  </ng-container>

  <!-- Rows -->
  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;" [ngClass]="{'highlight': selectedRowIndex == row.id}"></tr>

</table>
<!-- Pagination -->
<mat-paginator [length]="100"
[pageSize]="10"
[pageSizeOptions]="pageSizeOptions" [pageSizeOptions]="paginationOptions" showFirstLastButtons></mat-paginator>

<!-- User Notifications -->
<notifier-container></notifier-container>