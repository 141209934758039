import { DigisubservicesService } from 'src/app/services/digisubservices.service';
import { Component, OnInit } from '@angular/core';
import Chart from 'chart.js';
import { Router } from '@angular/router';

// core components
import {
  chartOptions,
  parseOptions,
  chartExample1,
  chartExample2
} from "../../variables/charts";

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  //public datasets: any;
  public repdata = [
    {"repid":61,"repname":"Shridhar Gokhale","subscribers":18,"newsubscribers":0}
  ];
  public salesChart;
  public clicked: boolean = true;
  public clicked1: boolean = false;
  public headersData = [{ "totalsub": "6873", "asb": "3920", "bvp": "1082", "totalrep": "17" }];

  public monthlokkup = {
    0: "Jan",
    1: "Feb",
    2: "Mar",
    3: "Apr",
    4: "May",
    5: "Jun",
    6: "Jul",
    7: "Aug",
    8: "Sep",
    9: "Oct",
    10: "Nov",
    11: "Dec"
  }



  constructor(private router: Router, public service: DigisubservicesService) { }
  ngOnInit() {

    if (sessionStorage.getItem("mobile") == null) {
      this.router.navigate(['/']);
    }


    // Setting Header Data
    this.service.getPortaldashboardheadings().subscribe(
      headings => {


        this.headersData = headings[0];
        //console.log(this.headersData);
      }
    )


    this.service.getPortaldashboardrepdata().subscribe(rep=>{
      this.repdata = []; 

      for (let index = 0; index < Object.keys(rep).length; index++) {
        this.repdata.push({
          "repid":parseInt(rep[index]['repid']),
          "repname":rep[index]['repname'],
          "subscribers":parseInt(rep[index]['subscribers']),
          "newsubscribers":parseInt(rep[index]['newsubscribers'])
        });
        
      }
      
    })



    this.service.getPortaldashboardtotalsales().subscribe(sales => {
      //console.log(sales);
      let sale = sales[0];
      //console.log(sale)
      let currentmonth = new Date().getMonth();
      let prevsixmonth = currentmonth - 6;
      let monthsArray = []
      for (let index = 0; index < Object.keys(sale).length; index++) {

        if (this.monthlokkup[index] == "Jan") {
          monthsArray[0] = parseInt(sale[this.monthlokkup[index]])
        }
        if (this.monthlokkup[index] == "Feb") {
          monthsArray[1] = parseInt(sale[this.monthlokkup[index]])
        }
        if (this.monthlokkup[index] == "Mar") {
          monthsArray[2] = parseInt(sale[this.monthlokkup[index]])
        }
        if (this.monthlokkup[index] == "Apr") {
          monthsArray[3] = parseInt(sale[this.monthlokkup[index]])
        }
        if (this.monthlokkup[index] == "May") {
          monthsArray[4] = parseInt(sale[this.monthlokkup[index]])
        }
        if (this.monthlokkup[index] == "Jun") {
          monthsArray[5] = parseInt(sale[this.monthlokkup[index]])
        }
        if (this.monthlokkup[index] == "Jul") {
          monthsArray[6] = parseInt(sale[this.monthlokkup[index]])
        }
        if (this.monthlokkup[index] == "Aug") {
          monthsArray[7] = parseInt(sale[this.monthlokkup[index]])
        }
        if (this.monthlokkup[index] == "Sep") {
          monthsArray[8] = parseInt(sale[this.monthlokkup[index]])
        }
        if (this.monthlokkup[index] == "Oct") {
          monthsArray[9] = parseInt(sale[this.monthlokkup[index]])
        }
        if (this.monthlokkup[index] == "Nov") {
          monthsArray[10] = parseInt(sale[this.monthlokkup[index]])
        }
        if (this.monthlokkup[index] == "Dec") {
          monthsArray[11] = parseInt(sale[this.monthlokkup[index]])
        }



      }

      //console.log(sale)
      //console.log(Object.keys(monthsArray))
      //console.log(Object.values(monthsArray))

      //create Dataset
      let data1 = {
        labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
        datasets: [{
          label: 'Sales Amount',
          data: monthsArray//[0, 20, 10, 30, 15, 40, 20, 60, 60]
        }]
      }

      //console.log(data1)

      var chartSales = document.getElementById('chart-sales');

      this.salesChart = new Chart(chartSales, {
        type: 'line',
        options: chartExample1.options,
        data: data1//chartExample1.data1
      });


    })


    this.service.getPortaldashboardnewsubs().subscribe(newsubs => {


      //console.log(newsubs);
      let newsub = newsubs[0];
      //console.log(newsub)
      let currentmonth = new Date().getMonth();
      let prevsixmonth = currentmonth - 6;
      let monthsArray = []
      for (let index = 0; index < Object.keys(newsub).length; index++) {

        if (this.monthlokkup[index] == "Jan") {
          monthsArray[0] = parseInt(newsub[this.monthlokkup[index]])
        }
        if (this.monthlokkup[index] == "Feb") {
          monthsArray[1] = parseInt(newsub[this.monthlokkup[index]])
        }
        if (this.monthlokkup[index] == "Mar") {
          monthsArray[2] = parseInt(newsub[this.monthlokkup[index]])
        }
        if (this.monthlokkup[index] == "Apr") {
          monthsArray[3] = parseInt(newsub[this.monthlokkup[index]])
        }
        if (this.monthlokkup[index] == "May") {
          monthsArray[4] = parseInt(newsub[this.monthlokkup[index]])
        }
        if (this.monthlokkup[index] == "Jun") {
          monthsArray[5] = parseInt(newsub[this.monthlokkup[index]])
        }
        if (this.monthlokkup[index] == "Jul") {
          monthsArray[6] = parseInt(newsub[this.monthlokkup[index]])
        }
        if (this.monthlokkup[index] == "Aug") {
          monthsArray[7] = parseInt(newsub[this.monthlokkup[index]])
        }
        if (this.monthlokkup[index] == "Sep") {
          monthsArray[8] = parseInt(newsub[this.monthlokkup[index]])
        }
        if (this.monthlokkup[index] == "Oct") {
          monthsArray[9] = parseInt(newsub[this.monthlokkup[index]])
        }
        if (this.monthlokkup[index] == "Nov") {
          monthsArray[10] = parseInt(newsub[this.monthlokkup[index]])
        }
        if (this.monthlokkup[index] == "Dec") {
          monthsArray[11] = parseInt(newsub[this.monthlokkup[index]])
        }



      }

      //console.log(newsub)
      //console.log(Object.keys(monthsArray))
      //console.log(Object.values(monthsArray))

      //create Dataset
      let data1 = {
        labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
        datasets: [{
          label: 'New Subscribers',
          data: monthsArray//[0, 20, 10, 30, 15, 40, 20, 60, 60]
        }]
      }

      //console.log(data1)

      var chartOrders = document.getElementById('chart-orders');

      


      var ordersChart = new Chart(chartOrders, {
        type: 'bar',
        options: chartExample2.options,
        data: data1//chartExample2.data
      });

    })

    parseOptions(Chart, chartOptions());


    //this.datasets = [
    //[0, 20, 10, 30, 15, 40, 20, 60, 60],
    // [0, 20, 5, 25, 10, 30, 15, 40, 40]
    //];
    //this.data = this.datasets[0];



  }




  //public updateOptions() {
  //  this.salesChart.data.datasets[0].data = this.data;
  //   this.salesChart.update();
  //}

}
