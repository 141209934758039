<div *ngIf="dataLoaded">
    <app-editable-table 
      [columns]="['id', 'name', 'mobile', 'email', 'Actions']"
      [editableColumns] = "['name', 'mobile', 'email']"
      [dateColumns] ="['']"
      [data]="tableData" 
      [pageSizeOptions]="[5,6,9,12,20,50,100]" 
      [searchable]="true" 
      (action)="action($event)"
      [notification]="yourMessage"
      [maxChar]="23">
    </app-editable-table>
    </div>
