import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { DigisubservicesService } from 'src/app/services/digisubservices.service';
import { EncdecriptService } from 'src/app/services/encdecrypt.service';
import { CancelSubDialogComponent } from '../cancel-sub-dialog/cancel-sub-dialog.component';

@Component({
  selector: 'app-add-cancel-subscriber',
  templateUrl: './add-cancel-subscriber.component.html',
  styleUrls: ['./add-cancel-subscriber.component.scss']
})
export class AddCancelSubscriberComponent implements OnInit {

  form1: FormGroup
  cancelCheckbox = []
  changedMobileNumber;
  getMagazine;
  nochangenumber = false;
  disableOtherFields = true;


  mobile
  constructor(private router: Router, private fb: FormBuilder, public service: DigisubservicesService,
    public dialog: MatDialog, public encr: EncdecriptService, private _snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data : any) {

      this.mobile = this.data['mobile']

    this.form1 = fb.group({
      mobileNumber: '',
      cancelmags0: { name: '', edition: '' },
      cancelmags1: { name: '', edition: '' },
      cancelmags2: { name: '', edition: '' },
      cancelmags3: { name: '', edition: '' },
      cancelmags4: { name: '', edition: '' },
      cancelmags5: { name: '', edition: '' },
    });

    this.form1.get('mobileNumber').setValue(this.mobile);
    this.validatesubmobileno(this.mobile)
    this.changedMobileNumber = parseInt(this.mobile)
    this.getsubmags()
  }

  ngOnInit(): void {
  }

  subscribersName
  validatesubmobileno(mobile) {
    this.service.checksubisexist(parseInt(mobile)).subscribe(a => {
      console.log(a)
      if (Object.keys(a).length > 0) {
        this.subscribersName = a[0]['name']
      }

    })
  }

  
  getValidateEvent(event) {
    this.changedMobileNumber = event.target.value;
    this.validatesubmobileno(this.changedMobileNumber);
  }


  startLoading = false
  getsubmags() {
    this.startLoading = true
    this.cancelCheckbox = []
    let mobileNumber = this.changedMobileNumber
    this.service.getSubmagByIdorNumberCancel(parseInt(mobileNumber)).subscribe(res => {
      console.log(res)
      this.getMagazine = res;
      this.nochangenumber = true
      this.startLoading = false
      if (Object.keys(res).length > 0) {
        this.disableOtherFields = false;
      }
      else {
        this.disableOtherFields = true;
      }



      console.log(res)
      setTimeout(() => {
        for (let index = 0; index < Object.keys(this.getMagazine).length; index++) {

          this.cancelCheckbox.push({
            name: this.getMagazine[index]['ds_mag1'],
            edition: this.getMagazine[index]['ds_mag1edition'],
            end_date: this.getMagazine[index]['ds_mag1enddt']
          })

        }
      }, 1000);

      console.log(this.cancelCheckbox)
    })

  }


  cancelMagList = []
  getcancelmag(event) {
    console.log(event)
    console.log(event.target.value)
    let index = event.target.value;
    console.log(this.cancelCheckbox[index])
    console.log(this.cancelMagList.find(a => a['name'] == this.cancelCheckbox[index]['name'] && a['edition'] == this.cancelCheckbox[index]['edition']))
    if (this.cancelMagList.find(a => a['name'] == this.cancelCheckbox[index]['name'] && a['edition'] == this.cancelCheckbox[index]['edition']) == undefined) {
      this.cancelMagList.push(this.cancelCheckbox[index])
    }


  }


  submitForm() {
    let mobile = parseInt(this.form1.value.mobileNumber);
    this.form1.patchValue({ mobileNumber: mobile });
    for (let index = 0; index < Object.keys(this.cancelMagList).length; index++) {
      this.form1.get('cancelmags' + index).setValue(this.cancelMagList[index])
    }
    console.log(this.form1.value);
    this.cancelMagList = []
    this.disableOtherFields = true;
    //this.open_cancel_subsciber_dialog(this.form1.value);
    this.submitForm_Cancel_mag(this.form1.value)
    //this.form1.reset()
    this.nochangenumber = false
  }

  

  submitForm_Cancel_mag(magdata) {
    
            //console.log(this.data)
      console.log(magdata)

      for (let index = 0; index < Object.keys(magdata).length; index++) {

        if (magdata['cancelmags' + index]['name'] != "") {

          this.service.updateMagCancel(parseInt(magdata['mobileNumber']),
            magdata['cancelmags' + index]['name'], magdata['cancelmags' + index]['edition']).subscribe(a => {
              
              let msg = "Dear Subscriber (Sub.id : ) , Your subscription for "+magdata['cancelmags' + index]['name']+
        " has been cancelled. Thanks, Hindustan Prakashan Sanstha"
          let template="Vivek26"

              //let msg = "Dear Subscriber (Sub.id : ) , Your subscription for " + magdata['cancelmags' + index]['name'] +
                " has been cancelled."
              //let template = "Brahman11"

              this.service.sendSMS(parseInt(magdata['mobileNumber']), msg, template).subscribe(a => { });

            })
        }


      }

      setTimeout(() => {
        this.form1.reset()
      }, 1000);
   
  }


}
