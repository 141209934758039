import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DigisubservicesService } from 'src/app/services/digisubservices.service';

@Component({
  selector: 'app-prasiddhipatrakbrowse',
  templateUrl: './prasiddhipatrakbrowse.component.html',
  styleUrls: ['./prasiddhipatrakbrowse.component.scss'],
})
export class PrasiddhipatrakbrowseComponent implements OnInit {
  today;
  start_date = '';
  end_date = '';
  advt_name = '';
  rep_name = '';
  off_rep_name = '';
  issue_category = '';
  issue_dates = '';
  issue_date;
  issue_name;
  prasiddhipatrakData = [];
  filteredprasiddhipatrakData = [];
  prasiddhipatrak = [];
  prasiddhipatraksummary = [];
  updatePrasiddhiPatrak = [];
  val: any;
  total_count: number;
  total_page: number;
  cust_name: any;

  constructor(
    private dialogref: MatDialogRef<PrasiddhipatrakbrowseComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private service: DigisubservicesService
  ) {}
  issuenamenew:any
  issuedatepp:any
  
  ngOnInit(): void {
    // console.log('Browse dialog');
    debugger
    this.issuenamenew = this.data['issuenamenew']
    this.cust_name = this .data['cust_name']
    this.issuedatepp = this.data['issuedatepp']
    this.start_date = this.data['start_date'];
    this.end_date = this.data['end_date'];
    this.val = this.data['val'];

    this.advt_name =
      this.data['advt_name'] === '' ? '' : this.data['advt_name'];
    this.rep_name = this.data['rep_name'] === '' ? '' : this.data['rep_name'];
    this.off_rep_name =
      this.data['off_rep_name'] === '' ? '' : this.data['off_rep_name'];
    this.issue_category =
      this.data['issue_category'] === undefined
        ? ''
        : this.data['issue_category'];
    this.issue_dates =
      this.data['issue_date'] === undefined ? '' : this.data['issue_date'];

    this.issue_date = this.data['issue_date'] + '';
    this.issue_name = this.issue_date.split(',')[1];
    this.issue_date = this.issue_date.split(',')[0];
    this.today = new Date();
    this.getPrasiddhiPatrakData(this.val);
  }

  getPrasiddhiPatrakData(val) {
    
    const req={
      'value':val,
      'issue_name' : this.issue_category,
      'issue_date' : this.issue_dates,
      'start_date': this.start_date,
      'end_date': this.end_date,
      'advt_name' : this.advt_name,
      'rep_name' : this.rep_name,
      'off_rep_name' : this.off_rep_name
      
    }
    console.log(this.val, "type")
    this.service.getPrasiddhiPatrakData(req).subscribe((data) => {
      // console.log(data);
      this.prasiddhipatrakData = data;
      console.log(this.prasiddhipatrakData, "prasidhi patrak data");
      // this.filtersData();
      this.getData();
      this.total_count=0
      this.total_page=0
      this.prasiddhipatrakData.forEach(element => {
        this.total_count += parseInt((element.advt_data.length));
        element.total_count = parseInt((element.advt_data.length));
       element.advt_data.forEach(element1 => {
        

            this.total_page += parseInt((element1.pages_no));
          
        });

        console.log( this.total_page, " this.total_count")
      });

      // setTimeout(() => {
      //   console.log(this.filteredprasiddhipatrakData);
      // }, 200);
    });
  }

  filtersData() {
    if (
      this.start_date != '' &&
      this.end_date != '' &&
      this.advt_name != '' &&
      this.rep_name != '' &&
      this.off_rep_name != '' &&
      this.issue_category != '' &&
      this.issue_dates != ''
    ) {
      this.filteredprasiddhipatrakData = this.prasiddhipatrakData.filter(
        (a) => {
          return (
            a['booking_date'] >= this.start_date &&
            a['booking_date'] <= this.end_date &&
            this.advt_name.includes(a['name']) &&
            this.rep_name.includes(a['rep_name']) &&
            this.off_rep_name.includes(a['off_rep_name']) &&
            a['DS_ISSUENAME'] === this.issue_category &&
            this.issue_dates.includes(a['DS_ISSUEDATE'])
          );
        }
      );
    } else if (
      this.start_date == '' &&
      this.end_date == '' &&
      this.advt_name != '' &&
      this.rep_name != '' &&
      this.off_rep_name != '' &&
      this.issue_category != '' &&
      this.issue_dates != ''
    ) {
      this.filteredprasiddhipatrakData = this.prasiddhipatrakData.filter(
        (a) => {
          return (
            this.advt_name.includes(a['name']) &&
            this.rep_name.includes(a['rep_name']) &&
            this.off_rep_name.includes(a['off_rep_name']) &&
            a['DS_ISSUENAME'] === this.issue_category &&
            this.issue_dates.includes(a['DS_ISSUEDATE'])
          );
        }
      );
    } else if (
      this.start_date != '' &&
      this.end_date != '' &&
      this.advt_name == '' &&
      this.rep_name != '' &&
      this.off_rep_name != '' &&
      this.issue_category != '' &&
      this.issue_dates != ''
    ) {
      this.filteredprasiddhipatrakData = this.prasiddhipatrakData.filter(
        (a) => {
          return (
            a['booking_date'] >= this.start_date &&
            a['booking_date'] <= this.end_date &&
            this.rep_name.includes(a['rep_name']) &&
            this.off_rep_name.includes(a['off_rep_name']) &&
            a['DS_ISSUENAME'] === this.issue_category &&
            this.issue_dates.includes(a['DS_ISSUEDATE'])
          );
        }
      );
    } else if (
      this.start_date != '' &&
      this.end_date != '' &&
      this.advt_name != '' &&
      this.rep_name == '' &&
      this.off_rep_name != '' &&
      this.issue_category != '' &&
      this.issue_dates != ''
    ) {
      this.filteredprasiddhipatrakData = this.prasiddhipatrakData.filter(
        (a) => {
          return (
            a['booking_date'] >= this.start_date &&
            a['booking_date'] <= this.end_date &&
            this.advt_name.includes(a['name']) &&
            this.off_rep_name.includes(a['off_rep_name']) &&
            a['DS_ISSUENAME'] === this.issue_category &&
            this.issue_dates.includes(a['DS_ISSUEDATE'])
          );
        }
      );
    } else if (
      this.start_date != '' &&
      this.end_date != '' &&
      this.advt_name != '' &&
      this.rep_name != '' &&
      this.off_rep_name == '' &&
      this.issue_category != '' &&
      this.issue_dates != ''
    ) {
      this.filteredprasiddhipatrakData = this.prasiddhipatrakData.filter(
        (a) => {
          return (
            a['booking_date'] >= this.start_date &&
            a['booking_date'] <= this.end_date &&
            this.advt_name.includes(a['name']) &&
            this.rep_name.includes(a['rep_name']) &&
            a['DS_ISSUENAME'] === this.issue_category &&
            this.issue_dates.includes(a['DS_ISSUEDATE'])
          );
        }
      );
    } else if (
      this.start_date != '' &&
      this.end_date != '' &&
      this.advt_name != '' &&
      this.rep_name != '' &&
      this.off_rep_name != '' &&
      this.issue_category == '' &&
      this.issue_dates == ''
    ) {
      this.filteredprasiddhipatrakData = this.prasiddhipatrakData.filter(
        (a) => {
          return (
            a['booking_date'] >= this.start_date &&
            a['booking_date'] <= this.end_date &&
            this.advt_name.includes(a['name']) &&
            this.rep_name.includes(a['rep_name']) &&
            this.off_rep_name.includes(a['off_rep_name'])
          );
        }
      );
    } else if (
      this.start_date == '' &&
      this.end_date == '' &&
      this.advt_name == '' &&
      this.rep_name != '' &&
      this.off_rep_name != '' &&
      this.issue_category != '' &&
      this.issue_dates != ''
    ) {
      this.filteredprasiddhipatrakData = this.prasiddhipatrakData.filter(
        (a) => {
          return (
            this.rep_name.includes(a['rep_name']) &&
            this.off_rep_name.includes(a['off_rep_name']) &&
            a['DS_ISSUENAME'] === this.issue_category &&
            this.issue_dates.includes(a['DS_ISSUEDATE'])
          );
        }
      );
    } else if (
      this.start_date == '' &&
      this.end_date == '' &&
      this.advt_name != '' &&
      this.rep_name == '' &&
      this.off_rep_name != '' &&
      this.issue_category != '' &&
      this.issue_dates != ''
    ) {
      this.filteredprasiddhipatrakData = this.prasiddhipatrakData.filter(
        (a) => {
          return (
            this.advt_name.includes(a['name']) &&
            this.off_rep_name.includes(a['off_rep_name']) &&
            a['DS_ISSUENAME'] === this.issue_category &&
            this.issue_dates.includes(a['DS_ISSUEDATE'])
          );
        }
      );
    } else if (
      this.start_date == '' &&
      this.end_date == '' &&
      this.advt_name != '' &&
      this.rep_name != '' &&
      this.off_rep_name == '' &&
      this.issue_category != '' &&
      this.issue_dates != ''
    ) {
      this.filteredprasiddhipatrakData = this.prasiddhipatrakData.filter(
        (a) => {
          return (
            this.advt_name.includes(a['name']) &&
            this.rep_name.includes(a['rep_name']) &&
            a['DS_ISSUENAME'] === this.issue_category &&
            this.issue_dates.includes(a['DS_ISSUEDATE'])
          );
        }
      );
    } else if (
      this.start_date == '' &&
      this.end_date == '' &&
      this.advt_name != '' &&
      this.rep_name != '' &&
      this.off_rep_name != '' &&
      this.issue_category == '' &&
      this.issue_dates == ''
    ) {
      this.filteredprasiddhipatrakData = this.prasiddhipatrakData.filter(
        (a) => {
          return (
            this.advt_name.includes(a['name']) &&
            this.rep_name.includes(a['rep_name']) &&
            this.off_rep_name.includes(a['off_rep_name'])
          );
        }
      );
    } else if (
      this.start_date != '' &&
      this.end_date != '' &&
      this.advt_name == '' &&
      this.rep_name == '' &&
      this.off_rep_name != '' &&
      this.issue_category != '' &&
      this.issue_dates != ''
    ) {
      this.filteredprasiddhipatrakData = this.prasiddhipatrakData.filter(
        (a) => {
          return (
            a['booking_date'] >= this.start_date &&
            a['booking_date'] <= this.end_date &&
            this.off_rep_name.includes(a['off_rep_name']) &&
            a['DS_ISSUENAME'] === this.issue_category &&
            this.issue_dates.includes(a['DS_ISSUEDATE'])
          );
        }
      );
    } else if (
      this.start_date != '' &&
      this.end_date != '' &&
      this.advt_name == '' &&
      this.rep_name != '' &&
      this.off_rep_name == '' &&
      this.issue_category != '' &&
      this.issue_dates != ''
    ) {
      this.filteredprasiddhipatrakData = this.prasiddhipatrakData.filter(
        (a) => {
          return (
            a['booking_date'] >= this.start_date &&
            a['booking_date'] <= this.end_date &&
            this.rep_name.includes(a['rep_name']) &&
            a['DS_ISSUENAME'] === this.issue_category &&
            this.issue_dates.includes(a['DS_ISSUEDATE'])
          );
        }
      );
    } else if (
      this.start_date != '' &&
      this.end_date != '' &&
      this.advt_name == '' &&
      this.rep_name != '' &&
      this.off_rep_name != '' &&
      this.issue_category == '' &&
      this.issue_dates == ''
    ) {
      this.filteredprasiddhipatrakData = this.prasiddhipatrakData.filter(
        (a) => {
          return (
            a['booking_date'] >= this.start_date &&
            a['booking_date'] <= this.end_date &&
            this.rep_name.includes(a['rep_name']) &&
            this.off_rep_name.includes(a['off_rep_name'])
          );
        }
      );
    } else if (
      this.start_date != '' &&
      this.end_date != '' &&
      this.advt_name != '' &&
      this.rep_name == '' &&
      this.off_rep_name == '' &&
      this.issue_category != '' &&
      this.issue_dates != ''
    ) {
      this.filteredprasiddhipatrakData = this.prasiddhipatrakData.filter(
        (a) => {
          return (
            a['booking_date'] >= this.start_date &&
            a['booking_date'] <= this.end_date &&
            this.advt_name.includes(a['name']) &&
            a['DS_ISSUENAME'] === this.issue_category &&
            this.issue_dates.includes(a['DS_ISSUEDATE'])
          );
        }
      );
    } else if (
      this.start_date != '' &&
      this.end_date != '' &&
      this.advt_name != '' &&
      this.rep_name == '' &&
      this.off_rep_name != '' &&
      this.issue_category == '' &&
      this.issue_dates == ''
    ) {
      this.filteredprasiddhipatrakData = this.prasiddhipatrakData.filter(
        (a) => {
          return (
            a['booking_date'] >= this.start_date &&
            a['booking_date'] <= this.end_date &&
            this.advt_name.includes(a['name']) &&
            this.off_rep_name.includes(a['off_rep_name'])
          );
        }
      );
    } else if (
      this.start_date != '' &&
      this.end_date != '' &&
      this.advt_name != '' &&
      this.rep_name != '' &&
      this.off_rep_name == '' &&
      this.issue_category == '' &&
      this.issue_dates == ''
    ) {
      this.filteredprasiddhipatrakData = this.prasiddhipatrakData.filter(
        (a) => {
          return (
            a['booking_date'] >= this.start_date &&
            a['booking_date'] <= this.end_date &&
            this.advt_name.includes(a['name']) &&
            this.rep_name.includes(a['rep_name'])
          );
        }
      );
    } else if (
      this.start_date == '' &&
      this.end_date == '' &&
      this.advt_name == '' &&
      this.rep_name == '' &&
      this.off_rep_name != '' &&
      this.issue_category != '' &&
      this.issue_dates != ''
    ) {
      this.filteredprasiddhipatrakData = this.prasiddhipatrakData.filter(
        (a) => {
          return (
            this.off_rep_name.includes(a['off_rep_name']) &&
            a['DS_ISSUENAME'] === this.issue_category &&
            this.issue_dates.includes(a['DS_ISSUEDATE'])
          );
        }
      );
    } else if (
      this.start_date == '' &&
      this.end_date == '' &&
      this.advt_name == '' &&
      this.rep_name != '' &&
      this.off_rep_name == '' &&
      this.issue_category != '' &&
      this.issue_dates != ''
    ) {
      this.filteredprasiddhipatrakData = this.prasiddhipatrakData.filter(
        (a) => {
          return (
            this.rep_name.includes(a['rep_name']) &&
            a['DS_ISSUENAME'] === this.issue_category &&
            this.issue_dates.includes(a['DS_ISSUEDATE'])
          );
        }
      );
    } else if (
      this.start_date == '' &&
      this.end_date == '' &&
      this.advt_name == '' &&
      this.rep_name != '' &&
      this.off_rep_name != '' &&
      this.issue_category == '' &&
      this.issue_dates == ''
    ) {
      this.filteredprasiddhipatrakData = this.prasiddhipatrakData.filter(
        (a) => {
          return (
            this.rep_name.includes(a['rep_name']) &&
            this.off_rep_name.includes(a['off_rep_name'])
          );
        }
      );
    } else if (
      this.start_date == '' &&
      this.end_date == '' &&
      this.advt_name != '' &&
      this.rep_name == '' &&
      this.off_rep_name == '' &&
      this.issue_category != '' &&
      this.issue_dates != ''
    ) {
      this.filteredprasiddhipatrakData = this.prasiddhipatrakData.filter(
        (a) => {
          return (
            this.advt_name.includes(a['name']) &&
            a['DS_ISSUENAME'] === this.issue_category &&
            this.issue_dates.includes(a['DS_ISSUEDATE'])
          );
        }
      );
    } else if (
      this.start_date == '' &&
      this.end_date == '' &&
      this.advt_name != '' &&
      this.rep_name == '' &&
      this.off_rep_name != '' &&
      this.issue_category == '' &&
      this.issue_dates == ''
    ) {
      this.filteredprasiddhipatrakData = this.prasiddhipatrakData.filter(
        (a) => {
          return (
            this.advt_name.includes(a['name']) &&
            this.off_rep_name.includes(a['off_rep_name'])
          );
        }
      );
    } else if (
      this.start_date == '' &&
      this.end_date == '' &&
      this.advt_name != '' &&
      this.rep_name != '' &&
      this.off_rep_name == '' &&
      this.issue_category == '' &&
      this.issue_dates == ''
    ) {
      this.filteredprasiddhipatrakData = this.prasiddhipatrakData.filter(
        (a) => {
          return (
            this.advt_name.includes(a['name']) &&
            this.rep_name.includes(a['rep_name'])
          );
        }
      );
    } else if (
      this.start_date != '' &&
      this.end_date != '' &&
      this.advt_name == '' &&
      this.rep_name == '' &&
      this.off_rep_name == '' &&
      this.issue_category != '' &&
      this.issue_dates != ''
    ) {
      this.filteredprasiddhipatrakData = this.prasiddhipatrakData.filter(
        (a) => {
          return (
            a['booking_date'] >= this.start_date &&
            a['booking_date'] <= this.end_date &&
            a['DS_ISSUENAME'] === this.issue_category &&
            this.issue_dates.includes(a['DS_ISSUEDATE'])
          );
        }
      );
    } else if (
      this.start_date != '' &&
      this.end_date != '' &&
      this.advt_name == '' &&
      this.rep_name == '' &&
      this.off_rep_name != '' &&
      this.issue_category == '' &&
      this.issue_dates == ''
    ) {
      this.filteredprasiddhipatrakData = this.prasiddhipatrakData.filter(
        (a) => {
          return (
            a['booking_date'] >= this.start_date &&
            a['booking_date'] <= this.end_date &&
            this.off_rep_name.includes(a['off_rep_name'])
          );
        }
      );
    } else if (
      this.start_date != '' &&
      this.end_date != '' &&
      this.advt_name == '' &&
      this.rep_name != '' &&
      this.off_rep_name == '' &&
      this.issue_category == '' &&
      this.issue_dates == ''
    ) {
      this.filteredprasiddhipatrakData = this.prasiddhipatrakData.filter(
        (a) => {
          return (
            a['booking_date'] >= this.start_date &&
            a['booking_date'] <= this.end_date &&
            this.rep_name.includes(a['rep_name'])
          );
        }
      );
    } else if (
      this.start_date != '' &&
      this.end_date != '' &&
      this.advt_name != '' &&
      this.rep_name == '' &&
      this.off_rep_name == '' &&
      this.issue_category == '' &&
      this.issue_dates == ''
    ) {
      this.filteredprasiddhipatrakData = this.prasiddhipatrakData.filter(
        (a) => {
          return (
            a['booking_date'] >= this.start_date &&
            a['booking_date'] <= this.end_date &&
            this.advt_name.includes(a['name'])
          );
        }
      );
    } else if (
      this.start_date == '' &&
      this.end_date == '' &&
      this.advt_name == '' &&
      this.rep_name == '' &&
      this.off_rep_name == '' &&
      this.issue_category != '' &&
      this.issue_dates != ''
    ) {
      this.filteredprasiddhipatrakData = this.prasiddhipatrakData.filter(
        (a) => {
          return (
            a['DS_ISSUENAME'] === this.issue_category &&
            this.issue_dates.includes(a['DS_ISSUEDATE'])
          );
        }
      );
    } else if (
      this.start_date == '' &&
      this.end_date == '' &&
      this.advt_name == '' &&
      this.rep_name == '' &&
      this.off_rep_name != '' &&
      this.issue_category == '' &&
      this.issue_dates == ''
    ) {
      this.filteredprasiddhipatrakData = this.prasiddhipatrakData.filter(
        (a) => {
          return this.off_rep_name.includes(a['off_rep_name']);
        }
      );
    } else if (
      this.start_date == '' &&
      this.end_date == '' &&
      this.advt_name == '' &&
      this.rep_name != '' &&
      this.off_rep_name == '' &&
      this.issue_category == '' &&
      this.issue_dates == ''
    ) {
      this.filteredprasiddhipatrakData = this.prasiddhipatrakData.filter(
        (a) => {
          return this.rep_name.includes(a['rep_name']);
        }
      );
    } else if (
      this.start_date == '' &&
      this.end_date == '' &&
      this.advt_name != '' &&
      this.rep_name == '' &&
      this.off_rep_name == '' &&
      this.issue_category == '' &&
      this.issue_dates == ''
    ) {
      this.filteredprasiddhipatrakData = this.prasiddhipatrakData.filter(
        (a) => {
          return this.advt_name.includes(a['name']);
        }
      );
    } else if (
      this.start_date != '' &&
      this.end_date != '' &&
      this.advt_name == '' &&
      this.rep_name == '' &&
      this.off_rep_name == '' &&
      this.issue_category == '' &&
      this.issue_dates == ''
    ) {
      this.filteredprasiddhipatrakData = this.prasiddhipatrakData.filter(
        (a) => {
          return (
            a['booking_date'] >= this.start_date &&
            a['booking_date'] <= this.end_date
          );
        }
      );
    } else if (
      this.start_date == '' &&
      this.end_date == '' &&
      this.advt_name == '' &&
      this.rep_name == '' &&
      this.off_rep_name == '' &&
      this.issue_category == '' &&
      this.issue_dates == ''
    ) {
      this.filteredprasiddhipatrakData = this.prasiddhipatrakData;
    }
  }

  total_advt;
  multicolorcount = 0;
  multicolorpanetotal;
  bandwpanetotal;
  getData() {
    let data = this.uniqueId(
      this.prasiddhipatrakData,
      (a) => a.DS_advtsize
    );
    // console.log(data);
    this.total_advt = 0;
    this.multicolorcount = 0;
    this.multicolorpanetotal = 0;
    this.bandwpanetotal = 0;
    for (let index = 0; index < data.length; index++) {
      let arr = this.prasiddhipatrakData.filter(
        (a) => data[index]['DS_advtsize'] === a['DS_advtsize']
      );

      let prasiddhi = {
        total: 0,
        advtsize: '',
      };

      // let prasiddhipatraksumary = {
      //   advtsize: '',
      //   total: 0,
      //   totalPane: 0,
      // };

      // console.log(arr);
      this.total_advt += arr.length;
      prasiddhi.total = arr.length;
      // prasiddhipatraksumary.total = arr.length;
      // if (arr[0]['DS_advtsize'].indexOf('Multicolour') > -1) {
      //   prasiddhipatraksumary.totalPane = arr.length;
      // } else if (arr[0]['DS_advtsize'].indexOf('B & W- Quarter Page') > -1) {
      //   prasiddhipatraksumary.totalPane = 0.25 * arr.length;
      // } else if (arr[0]['DS_advtsize'].indexOf('B & W- Half Page') > -1) {
      //   prasiddhipatraksumary.totalPane = 0.5 * arr.length;
      // } else if (arr[0]['DS_advtsize'].indexOf('B & W- full Page') > -1) {
      //   prasiddhipatraksumary.totalPane = arr.length;
      // } else if (
      //   arr[0]['DS_advtsize'].indexOf('B & W- 6 x 8.3 Cms') > -1 ||
      //   arr[0]['DS_advtsize'].indexOf('B & W- 5 x 8.3 Cms') > -1
      // ) {
      //   prasiddhipatraksumary.totalPane = 0.125 * arr.length;
      // } else if (
      //   arr[0]['DS_advtsize'].indexOf('B & W- 5 x 5.5 Cms') > -1 ||
      //   arr[0]['DS_advtsize'].indexOf('B & W- 4 x 5.5 Cms') > -1
      // ) {
      //   prasiddhipatraksumary.totalPane = 0.833 * arr.length;
      // }
      prasiddhi.advtsize = arr[0]['DS_advtsize'];
      // prasiddhipatraksumary.advtsize = arr[0]['DS_advtsize'];
      arr.push(prasiddhi);
      this.prasiddhipatrak.push(arr);
      // this.prasiddhipatraksummary.push(prasiddhipatraksumary);
      // console.log(
      //   this.prasiddhipatraksummary,
      //   this.prasiddhipatraksummary.length
      // );
    }
    for (let j = 0; j < this.prasiddhipatraksummary.length; j++) {
      // console.log(this.prasiddhipatraksummary[j]['advtsize']);
      if (
        this.prasiddhipatraksummary[j]['advtsize'].indexOf('Multicolour') > -1
      ) {
        this.multicolorcount++;
        this.multicolorpanetotal += parseInt(
          this.prasiddhipatraksummary[j]['totalPane']
        );
      } else {
        this.bandwpanetotal += parseInt(
          this.prasiddhipatraksummary[j]['totalPane']
        );
      }
    }
    // console.log(this.multicolorcount);
    // console.log(this.multicolorpanetotal);
  }

  uniqueId(data, key) {
    return [...new Map(data.map((x) => [key(x), x])).values()];
  }

  setPanNo(event, data) {
    // console.log(event.target.value);
    console.log(data);
    if (this.updatePrasiddhiPatrak.length != 0) {
      let flag = false;
      for (let index = 0; index < this.updatePrasiddhiPatrak.length; index++) {
        if (this.updatePrasiddhiPatrak[index]['id'] === data['DS_ADVTID']) {
          this.updatePrasiddhiPatrak[index]['pages'] = event.target.value;
          flag = false;
          break;
        } else {
          flag = true;
        }
      }
      if (flag) {
        this.updatePrasiddhiPatrak.push({
          id: data['DS_ADVTID'],
          pages: event.target.value,
        });
      }
    } else {
      this.updatePrasiddhiPatrak.push({
        id: data['DS_ADVTID'],
        booking_no: data['booking_no'],
        pages: event.target.value,
      });
    }
  }

  saveData() {
    // console.log(this.updatePrasiddhiPatrak);
    for (let index = 0; index < this.updatePrasiddhiPatrak.length; index++) {
      // console.log(this.updatePrasiddhiPatrak[index]);
      this.service
        .updatePagenoPrasiddhiPatrak(this.updatePrasiddhiPatrak[index])
        .subscribe((res) => {
          alert('Page Updated Successfully...');
          this.dialogref.close();
        });
    }
  }
}
