<div class="container" id="print-section">
  <table class="table table-bordered">
    <thead>
          <tr>
              <th colspan="3">Vivek Saptahik</th>
              <th colspan="6">Address List</th>
              <th colspan="3">Date :</th>
              <!-- <th *ngIf="totalweeks == 54" colspan="131"></th>
              <th *ngIf="totalweeks == 53" colspan="130"></th>
              <th *ngIf="totalweeks == 52" colspan="129"></th> -->
          </tr>
          <tr>
              <!-- <th colspan="2">Datewise</th> -->
              <!-- <th colspan="5">In Details</th> -->
              <!-- <th colspan="3">Date From  {{startDate}} To {{endDate}}</th> -->
              <!-- <th colspan="2">Page No</th> -->


              
              <!-- <th *ngIf="totalweeks == 54" colspan="131"></th>
              <th *ngIf="totalweeks == 53" colspan="130"></th>
              <th *ngIf="totalweeks == 52" colspan="129"></th> -->
          </tr>
          <!-- <tr>
              <th colspan="11">सा. विवेक जाहिरात वार्षिक करार २०२२-२३ नोंदणी तक्ता</th>
              <th colspan="4">Indetail</th>
              <th *ngIf="totalweeks == 54" colspan="131"></th>
              <th *ngIf="totalweeks == 53" colspan="130"></th>
              <th *ngIf="totalweeks == 52" colspan="129"></th>
          </tr> -->
          <tr>
            <td style = "padding: 8px;">Sr No</td>
            <td style="padding: 8px;">Name</td>
            <td style="padding: 8px;">Address</td>
            <td style="padding: 8px;">Pincode</td>
            <td style="padding: 8px;">Country</td>
            <td style="padding: 8px;">State</td>
            <td style="padding: 8px;">Taluka</td>
            <td style="padding: 8px;">District</td>
            <td style="padding: 8px;">Mobile Number</td>
            <td style="padding: 8px;">Month</td>
            <!-- <td style="padding: 8px;">Telephone</td> -->
            <td style="padding: 8px;">Prant Name</td>
          </tr>
      </thead>

      <!-- <tbody *ngFor="let data2 of allAddList; let i = index"> -->
          
        <tr *ngFor="let data2 of allAddList; let i = index">
          <td style="padding: 8px;">{{i + 1}}</td>
          <td style="padding: 8px;">{{data2.name}}</td>
          <td style="padding: 8px;">{{data2.address}}</td>
          <td style="padding: 8px;">{{data2.pincode}}</td>
          <td style="padding: 8px;">{{data2.country}}</td>
          <td style="padding: 8px;">{{data2.state}}</td>
          <td style="padding: 8px;">{{data2.taluka}}</td>
          <td style="padding: 8px;">{{data2.district}}</td>
          <td style="padding: 8px;">{{data2.mobileNumber}}</td>
          <td style="padding: 8px;">{{data2.month}}</td>
          <!-- <td style="padding: 8px;">{{data2.telephone}}</td> -->
          <td style="padding: 8px;">{{data2.prant_name}}</td>
        </tr>
      <!-- </tbody> -->

      <tfoot>
          <!-- <tr>
            <td colspan="10">Total:</td>
            <td colspan="1">{{CredittadvtInDTotal}}</td>
            <td colspan="1"></td>

          </tr> -->
        </tfoot> 
  </table>
  <!-- </div> -->
</div>