import { Component, OnInit, Inject, ChangeDetectorRef } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
// import { DigisubservicesService } from 'src/app/services/digisubservices.service';
import { FormBuilder } from '@angular/forms';
import { AdvertisementDetailsComponent } from '../pages/advertisement-details/advertisement-details.component';
import { DigisubservicesService } from 'src/app/services/digisubservices.service';


@Component({
  selector: 'app-addmodal',
  templateUrl: './addmodal.component.html',
  styleUrls: ['./addmodal.component.scss']
})
export class AddmodalComponent implements OnInit {
  PendingAdd: any;
  start_date: any;
  end_date: any;


  constructor(private dialogRef: MatDialogRef<AddmodalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private service: DigisubservicesService,
    private cdr: ChangeDetectorRef,private fb: FormBuilder
  )
   { }
repname:any
district:any
taluka:any;
modetype:any
duration:any
state:any
country:any
prantname:any

  ngOnInit(): void {

    this.repname= this.data['repName']
        this.district= this.data['districtName']
        this.taluka= this.data['talukaName']
        this.modetype= this.data['modeType']
        this.start_date = this.data['start_date']
        this.end_date = this.data['end_date']
        this.duration = this.data['duration']
        this.state = this.data['state']
        this.country = this.data['country']
        this.prantname = this.data['prantname']


        const req:any = {
    
          "delivery_mode":this.modetype,
          "district":this.district,
          "taluka":this.taluka,
          "rep_name":this.repname,
          "start_date" : this.start_date,
          "end_date" : this.end_date,
          "country":this.country,
          "state":this.state,
          "prant_name":this.prantname,
          "sub_type":this.duration,
          
         
      
        }

        console.log("req for add", req)

        
  this.service.getgetpendingAdd(req).subscribe((data:any) => {
    console.log("pending add=",data);
   this.PendingAdd = (data as any);;

 });
        
  }

  

}
