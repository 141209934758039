<div class="header bg-gradient-danger pb-1 pt-5 pt-md-8">
  <div class="container-fluid">
    <div class="header-body"></div>
  </div>
</div>
<div class="container-fluid" style="margin-top: -4.1em">
  <!-- Icons is Used as Admin -->
  <mat-tab-group dynamicHeight [(selectedIndex)]="demo1TabIndex"  (selectedTabChange)="onTabClick($event)">
    <mat-tab label="Pending Credit Voucher" >
      <div class="add-new-subscriber1 mat-elevation-z4">
        <div style="margin-top: 2%">
          <form [formGroup]="voucherform">
            <mat-form-field appearance="fill" style="margin-top: 1%">
              <mat-label>Enter a date range</mat-label>
              <mat-date-range-input [formGroup]="pendingvoucherForm" [rangePicker]="pickerpndg">
                <input matStartDate formControlName="start" placeholder="Start date" />
                <input matEndDate formControlName="end" placeholder="End date"
                  (dateChange)="getChangedDatependingvoucher()" />
              </mat-date-range-input>
              <mat-datepicker-toggle matSuffix [for]="pickerpndg"></mat-datepicker-toggle>
              <mat-date-range-picker #pickerpndg></mat-date-range-picker>

              <mat-error *ngIf="
                  pendingvoucherForm.controls.start.hasError(
                    'matStartDateInvalid'
                  )
                ">Invalid start date
              </mat-error>
              <mat-error *ngIf="
                  pendingvoucherForm.controls.end.hasError('matEndDateInvalid')
                ">Invalid end date
              </mat-error>
            </mat-form-field>

            <mat-form-field appearance="fill" class="example-full-width" style="margin-left: 3%">
              <mat-label>Representative</mat-label>
              <input matInput formControlName="rep" [matAutocomplete]="autorep2" />
              <button *ngIf="rep1" matSuffix mat-icon-button aria-label="Clear" (click)="reset_rep1()">
                <mat-icon>close</mat-icon>
              </button>
              <mat-autocomplete autoActiveFirstOption #autorep2="matAutocomplete">
                <mat-option (onSelectionChange)="get_rep1($event, option)"
                  *ngFor="let option of filteredOptions12 | async" [value]="option['rep_name']">
                  {{ option["rep_name"] }}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>

            <mat-form-field appearance="fill" class="example-full-width" style="margin-left: 3%">
              <mat-label>Voucher No</mat-label>
              <input matInput formControlName="voucher_no" [matAutocomplete]="autovoucherno" />
              <button *ngIf="voucher_no1" matSuffix mat-icon-button aria-label="Clear" (click)="reset_voucher_no()">
                <mat-icon>close</mat-icon>
              </button>
              <mat-autocomplete autoActiveFirstOption #autovoucherno="matAutocomplete">
                <mat-option (onSelectionChange)="get_voucher_no($event, option)"
                  *ngFor="let option of filteredOptions13 | async" [value]="option['voucher_no']">
                  {{ option["voucher_no"] }}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
            <button class="noPrint" style="margin-top: -1%; margin-left: 10%;" mat-raised-button color="primary"
            printSectionId="print-section"  [useExistingCss]="true"  ngxPrint>
            print
          </button>
          <button class="noPrint" style="margin-top: -1%; margin-left: 2%" (click)="exportExcel('Pending_credit_vocuher')"
            mat-raised-button color="primary">
            Export
        </button>
          </form>
        </div>
        <div style="margin-top: 20px;" class="tableFixHead" id="print-section">
          <table class="table table-striped text-center" >
            <thead>
              <tr>
                <th class="btn-hide">Action</th>
                <th>Sr.No.</th>
                <th>Voucher No.</th>
                <th class="btn-hide">Voucher Date</th>
                <th>Rep.Name</th>
                <th>Subscription Name</th>
                <th>Package Name</th>
                <th>Amount</th>
                <th>Remaining Amount</th>
                <th class="btn-hide">Cheque no</th>
                <th class="btn-hide">Cheque date</th>
                <th class="btn-hide">Bank Name</th>
                <th class="btn-hide">Branch Name</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="
                  let book of FilerterdgetallVouchersData;
                  let i = index
                ">
                <td class="btn-hide">
                  <button type="button" class="btn btn-danger btn-sm  " (click)="displayItemId(book)">
                    Mark as Completed
                  </button>
                </td>
                <td>{{ i + 1 }}</td>
                <td>{{ book["voucher_no"] }}</td>
                <td class="btn-hide">{{ book["voucher_date"] }}</td>
                <td>{{ book["rep_name"] }}</td>
                <td>{{ book["advtName"] }}</td>
                <td><span *ngIf="book['pkg_name'] != 'N'">{{ book['pkg_name'] }}</span></td>
                <td>₹ {{ book["price"] }}</td>
                <td>₹ {{ book["remaining_amt"] }}</td>
                <td class="btn-hide">
                  <span *ngIf="book['cheque_no'] != 0">
                    {{ book["cheque_no"] }}
                  </span>
                  <span *ngIf="book['cheque_no'] == 0">-</span>
                </td>
                <td class="btn-hide">
                  <span *ngIf="book['cheque_no'] != 0">
                    {{ book["chequeDate"] | date: "dd-MM-yyy" }}
                  </span>
                  <span *ngIf="book['cheque_no'] == 0">-</span>
                </td>
                <td class="btn-hide">{{ book["bank"] }}</td>
                <td class="btn-hide">{{ book["branch_name"] }}</td>
              </tr>
              <tr>
                <td colspan="7"></td>
                <td>{{ this.total_amount }}</td>
                <td>{{ this.total_remaining_amt }}</td>
                <td colspan="4"></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </mat-tab>

    <mat-tab label="Subscription Registration">
      <mat-card class="sub" style="margin-top: 20px">
        <form class="form" [formGroup]="subform" (ngSubmit)="addsub()">
          <div class="form-row">
            <mat-form-field appearance="outline" class="example-full-width"
              style="width: 18%; margin-left: 5%; margin-top: 10px">
              <mat-label>Subscribers Name</mat-label>
              <input matInput formControlName="subname" (keyup)="getSubNames($event)" [matAutocomplete]="auto1" />
              <mat-autocomplete autoActiveFirstOption #auto1="matAutocomplete">
                <mat-option (onSelectionChange)="selected_name($event, option)" *ngFor="let option of sub_data"
                  [value]="option['subname']">
                  {{ option["subname"] }}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
            <span>OR</span>
            <mat-form-field appearance="outline" class="example-full-width" style="width: 11%; margin-top: 10px">
              <mat-label>Subscribers No</mat-label>
              <input matInput formControlName="subno" (keyup)="getSubNo($event)" [matAutocomplete]="auto2" />
              <mat-autocomplete autoActiveFirstOption #auto2="matAutocomplete">
                <mat-option (onSelectionChange)="selected_name($event, option)" *ngFor="let option of sub_no"
                  [value]="option['subno']">
                  {{ option["subno"] }}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
            <span>OR</span>
            <mat-form-field appearance="outline" class="example-full-width" style="width: 11%; margin-top: 10px"
              [style.color]="validmobile ? null : 'red'">
              <mat-label>Mobile</mat-label>
              <input type="tel" #mobiles1 maxlength="10" formControlName="mobileNumber" (keyup)="getSubMobileNo($event)"
                [matAutocomplete]="auto" matInput />
              <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
                <mat-option (onSelectionChange)="selected_name($event, option)" *ngFor="let option of sub_mob_no"
                  [value]="option['mobno']">
                  {{ option["mobno"] }}
                </mat-option>
              </mat-autocomplete>
              <mat-hint align="end">{{ mobiles1.value.length }} / 10</mat-hint>
              <mat-error *ngIf="validmobile">
                Please enter a valid mobile no.
              </mat-error>
              <mat-error *ngIf="validmobile">
                Mobile no. is <strong>required</strong>
              </mat-error>
            </mat-form-field>

            <button type="button" (click)="enableDisableRule('first')" [ngStyle]="{
              'background-color': toggle ? 'skyblue' : '#ddc05f'
            }" style="
              width: 8%;
              margin-left: 4.3%;
              height: 09vh;
              margin-top: 14px;
              
            ">
            Renewal
          </button>
          <button type="button" (click)="enableDisableRule('second')" [ngStyle]="{
              'background-color': toggle1 ? 'skyblue' : '#ddc05f'
            }" style="width: 8%; height: 09vh; margin-top: 14px;">
            New
          </button>
          <!-- <button type="button" (click)="enableDisableRule('third')" [ngStyle]="{
              'background-color': toggle2 ? 'skyblue' : '#ddc05f'
            }" style="width: 11.3%; height: 06vh;">
            Cancel
          </button> -->
          </div>
          <div class="form-row">
            <mat-form-field appearance="outline" class="example-full-width" style="width: 21%; margin-left: 5%">
              <mat-label>Name</mat-label>
              <input matInput formControlName="showname" />
            </mat-form-field>
            <!-- <button type="button" (click)="enableDisableRule('first')" [ngStyle]="{
                'background-color': toggle ? 'skyblue' : '#ddc05f'
              }" style="
                width: 12%;
                margin-left: 4.3%;
                height: 06vh;
                
              ">
              Renewal
            </button>
            <button type="button" (click)="enableDisableRule('second')" [ngStyle]="{
                'background-color': toggle1 ? 'skyblue' : '#ddc05f'
              }" style="width: 12%; height: 06vh;">
              New
            </button>
            <button type="button" (click)="enableDisableRule('third')" [ngStyle]="{
                'background-color': toggle2 ? 'skyblue' : '#ddc05f'
              }" style="width: 11.3%; height: 06vh;">
              Cancel
            </button> -->
            <!-- <mat-checkbox style="margin-left: 1%" formControlName="iscomplimentary" [checked]="iscomplementary"
            (change)="complementry($event.checked)">Complemenatry</mat-checkbox> -->

            <mat-form-field appearance="outline" class="example-full-width"
              style="width: 12.3%; margin-left: 1%;">
              <mat-label></mat-label>
              <input matInput formControlName="subtype" />
            </mat-form-field>
            <mat-form-field appearance="outline" class="example-full-width" style="width: 05%;">
              <mat-label>MM</mat-label>
              <input matInput formControlName="month" />
            </mat-form-field>
            <mat-form-field appearance="outline" class="example-full-width" style="width: 06%;">
              <mat-label>YY</mat-label>
              <input matInput formControlName="year" />
            </mat-form-field>
            <mat-form-field appearance="outline" class="example-full-width" style="width: 9%;">
              <mat-label>Subscribers No</mat-label>
              <input matInput formControlName="subno" />
            </mat-form-field>
          </div>
          
          <div class="form-row">
            <mat-form-field style="width: 10%; margin-left: 5%" appearance="outline">
              <mat-label>Title</mat-label>
              <mat-select formControlName="title" [(ngModel)]="title">
                <mat-option *ngFor="let c of titles" value="{{ c['title'] }}">{{
                  c["title"]
                  }}</mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field appearance="outline" class="example-full-width" style="width: 30%; margin-left: 0.5%"
              [style.color]="validatefname ? null : 'red'">
              <mat-label>Name</mat-label>
              <input matInput (change)="validateName($event)" formControlName="name" required/>

              <mat-error *ngIf="validatefname">
                Please enter a valid name
              </mat-error>
              <mat-error *ngIf="validatefname">
                Name is <strong>required</strong>
              </mat-error>
            </mat-form-field>

            <mat-form-field appearance="outline" class="example-full-width" style="width: 23%; margin-left: 3%">
              <mat-label>Name (In English)</mat-label>
              <input matInput formControlName="e_name" />
            </mat-form-field>
          </div>

          <div class="form-row">
            <mat-form-field class="example-full-width" style="width: 41%; margin-left: 5%" appearance="outline">
              <mat-label>Address</mat-label>
              <textarea matInput placeholder="Address" formControlName="address" required></textarea>
            </mat-form-field>

            <mat-form-field style="margin-left: 5%; width: 21%" class="example-full-width" appearance="outline">
              <mat-label>Pincode</mat-label>
              <input (change)="getPincode($event)" matInput #postalCode maxlength="6" placeholder=""
                formControlName="pincode" />
              <mat-hint align="end">{{ postalCode.value.length }} / 6</mat-hint>
            </mat-form-field>
          </div>

          <div class="form-row">
           

             <mat-form-field style="width: 14%; margin-left: 5%" appearance="outline">
              <mat-label>Country</mat-label>
              <mat-select (selectionChange)="getCountry($event)" formControlName="country" >
                <mat-option *ngFor="let c of countries" value="{{ c['country_name'] }}">{{ c["country_name"] }}
                </mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field style="width: 14%; margin-left: 3%" appearance="outline">
              <mat-label>State</mat-label>
              <mat-select (selectionChange)="getState($event)" formControlName="state">
                <mat-option *ngFor="let c of states" value="{{ c['state_name'] }}">{{ c["state_name"] }}
                </mat-option>
              </mat-select>
              <span>{{ selectedState }}</span>
            </mat-form-field>
            <mat-form-field style="width: 14%; margin-left: 3%" appearance="outline">
              <mat-label>District</mat-label>
              <mat-select (selectionChange)="getDistricts($event)" formControlName="district">
                <mat-option *ngFor="let c of cities" value="{{ c['district_name'] }}">{{ c["district_name"] }}
                </mat-option>
              </mat-select>
              <span>{{ selectedCity }}</span>
            </mat-form-field>
            <mat-form-field style="margin-left: 3%; width: 14%" class="example-full-width" appearance="outline">
              <mat-label>District (In English)</mat-label>
              <input matInput type="text" placeholder="" formControlName="e_district" />
            </mat-form-field>
          </div>

          <div class="form-row">
            <mat-form-field style="width: 14%; margin-left: 5%" appearance="outline">
              <mat-label>Taluka</mat-label>
              <mat-select formControlName="taluka">
                <mat-option *ngFor="let c of talukas" value="{{ c['taluka_name'] }}">{{ c["taluka_name"] }}</mat-option>
              </mat-select>
              <span>{{ selectedTaluka }}</span>
            </mat-form-field>

            <mat-form-field appearance="outline" class="example-full-width" style="margin-left: 3%; width: 14%"
            [style.color]="validmobile ? null : 'red'">
            <mat-label>Mobile</mat-label>
            <span matPrefix>+91 &nbsp;</span>
            <input type="tel" #mobiles (change)="validateMobile($event)" maxlength="10" formControlName="mobileNumber"
              matInput />
            <mat-hint align="end">{{ mobiles.value.length }} / 10</mat-hint>
            <mat-error *ngIf="validmobile">
              Please enter a valid mobile no.
            </mat-error>
            <mat-error *ngIf="validmobile">
              Mobile no. is <strong>required</strong>
            </mat-error>
          </mat-form-field>

          <mat-form-field class="example-full-width" appearance="outline" style="width: 14%; margin-left: 3%">
            <mat-label>Telephone</mat-label>
            <input matInput type="number" formControlName="telephone" />
          </mat-form-field>

          <mat-form-field appearance="outline" class="example-full-width" style="width: 14%; margin-left: 3%"
          [style.color]="validemail ? null : 'red'">
          <mat-label>Email</mat-label>
          <input (change)="validateEmail($event)" type="email" matInput formControlName="email"
            placeholder="Ex. pat@example.com" />
          <mat-error *ngIf="validemail">
            Please enter a valid email address
          </mat-error>
          <mat-error *ngIf="validemail">
            Email is <strong>required</strong>
          </mat-error>
        </mat-form-field>
          </div>

          <div class="form-row">
            <mat-form-field appearance="outline" style="margin-left: 5%; width: 19%">
              <mat-label>Date of Birth</mat-label>
              <input formControlName="dob" matInput type="date" />
            </mat-form-field>
            <mat-form-field appearance="outline" style="margin-left: 3%; width: 19%">
              <mat-label>Marriage Date</mat-label>
              <input formControlName="doa" matInput type="date" />
            </mat-form-field>

            <mat-form-field class="example-full-width" appearance="outline" style="width: 19%; margin-left: 3%">
              <mat-label>Representative Name</mat-label>
              <input matInput formControlName="repname" [matAutocomplete]="autore" />
              <mat-autocomplete autoActiveFirstOption #autore="matAutocomplete">
                <mat-option (onSelectionChange)="selected_rep1_name($event, option)"
                  *ngFor="let option of filteredOptions4 | async" [value]="option['name']">
                  {{ option["name"] }}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
          </div>

          <div class="form-row">
            <mat-form-field appearance="outline" class="example-full-width" style="margin-left: 5%; width: 19%"
            [style.color]="validmobile ? null : 'red'">
            <mat-label>Representative Mobile No</mat-label>
            <span matPrefix>+91 &nbsp;</span>
            <input type="tel" #m (change)="validateMobile($event)" maxlength="10" formControlName="repmobno"
              matInput />
            <mat-hint align="end">{{ m.value.length }} / 10</mat-hint>
            <mat-error *ngIf="validmobile">
              Please enter a valid mobile no.
            </mat-error>
            <mat-error *ngIf="validmobile">
              Mobile no. is <strong>required</strong>
            </mat-error>
          </mat-form-field>

          <mat-form-field class="example-full-width" appearance="outline" style="width: 19%; margin-left: 3%">
            <mat-label>GST No</mat-label>
            <input matInput formControlName="gst_no" />
          </mat-form-field>
          <mat-form-field class="example-full-width" appearance="outline" style="width: 19%; margin-left: 3%">
            <mat-label>Remark</mat-label>
            <input matInput formControlName="remark" />
          </mat-form-field>
          </div>
          <div class="form-row">
            
          </div>
          <div class="form-row" *ngIf="selectedname">
            <button style="
                margin-left: 20%;
                width: 40%;
              " type="button" (click)="updatesubscriber()" mat-raised-button color="primary"
              class="btn btn-primary btn-block">
              Update
            </button>
            <button style="margin-left: 2%;width: 20%;" type="button" (click)="deletesubscriber()" mat-raised-button
              color="danger" class="btn btn-danger btn-block">
              Delete
            </button>
          </div>

          <div class="form-row" style="
              justify-content: center;
              background-color: #8bbdbd;
              margin-bottom: 2%;
              margin-top: 2%;
            ">
            <h2 style="text-align: center; margin-top: 10px; color: black">
              Subscription Details
            </h2>
          </div>

          <div class="form-row">
            <mat-form-field class="example-full-width" appearance="outline" style="width: 35%; margin-left: 5%">
              <mat-label>Current Representative Name</mat-label>
              <input matInput formControlName="crepname" [matAutocomplete]="autorep" />
              <mat-autocomplete autoActiveFirstOption #autorep="matAutocomplete">
                <mat-option (onSelectionChange)="selected_rep_name($event, option)"
                  *ngFor="let option of filteredOptions3 | async" [value]="option['name']">
                  {{ option["name"] }}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>

            <mat-form-field appearance="outline" class="example-full-width" style="margin-left: 5%; width: 35%"
              [style.color]="validmobile ? null : 'red'">
              <mat-label>Representative Mobile No</mat-label>
              <span matPrefix>+91 &nbsp;</span>
              <input type="tel" #m1 (change)="validateMobile($event)" maxlength="10" formControlName="repmobno1"
                matInput />
              <mat-hint align="end">{{ m1.value.length }} / 10</mat-hint>
              <mat-error *ngIf="validmobile">
                Please enter a valid mobile no.
              </mat-error>
              <mat-error *ngIf="validmobile">
                Mobile no. is <strong>required</strong>
              </mat-error>
            </mat-form-field>
          </div>

          <div class="form-row">
            <mat-form-field appearance="outline" class="example-full-width" style="margin-left: 5%; width: 35%">
              <mat-label>Office Representative</mat-label>
              <input matInput formControlName="officerep" />
            </mat-form-field>

            <mat-form-field appearance="outline" class="example-full-width" style="margin-left: 5%; width: 35%" >
              <mat-label>VoucherId</mat-label>
              <input matInput formControlName="voucherid" />
            </mat-form-field>
          </div>

          <div class="form-row" *ngIf="!iscomplementary">
            <mat-form-field appearance="outline" class="example-full-width" style="margin-left: 5%; width: 23%">
              <mat-label>CR. Voucher Date</mat-label>
              <input matInput formControlName="crdtdate"  />
            </mat-form-field>
            <mat-form-field appearance="outline" class="example-full-width" style="width: 23%; margin-left: 3%">
              <mat-label>CR. Voucher No:</mat-label>
              <input matInput formControlName="crdtvno" readonly />
            </mat-form-field>
            <mat-form-field appearance="outline" class="example-full-width" style="width: 23%; margin-left: 3%">
              <mat-label>Receipt No.</mat-label>
              <input matInput formControlName="rep_receipt"  />
            </mat-form-field>
          </div>

          <div class="form-row" *ngIf="!iscomplementary">
            <mat-form-field appearance="outline" class="example-full-width" style="margin-left: 5%; width: 23%">
              <mat-label>Credit Voucher Amount</mat-label>
              <input matInput formControlName="crdtamt" />
            </mat-form-field>
            <mat-form-field appearance="outline" class="example-full-width" style="width: 23%; margin-left: 3%">
              <mat-label>Select Entry Amount</mat-label>
              <input matInput formControlName="seamt" readonly />
            </mat-form-field>
            <mat-form-field appearance="outline" class="example-full-width" style="width: 23%; margin-left: 3%">
              <mat-label>Balance Amount</mat-label>
              <input matInput formControlName="balamt" readonly />
            </mat-form-field>
          </div>

          <div class="form-row">
            <mat-form-field appearance="outline" style="margin-left: 5%; width: 35%">
              <mat-label>Entry Date</mat-label>
              <input formControlName="entrydate" matInput type="date" readonly />
            </mat-form-field>

            <mat-checkbox style="margin-left: 1%" formControlName="iscomplimentary" [checked]="iscomplementary"
            (change)="complementry($event.checked)">Complemenatry</mat-checkbox>
          </div>

          <div class="form-row">
            <mat-form-field appearance="outline" style="margin-left: 5%; width: 35%">
              <mat-label>Magazine Name</mat-label>
              <mat-select required (selectionChange)="getmagdetails($event)" formControlName="magnames">
                <mat-option *ngFor="let mags of magname" value="{{ mags['name'] }}">{{ mags["name"] }}
                </mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field appearance="outline" style="margin-left: 5%; width: 35%">
              <mat-label>Edition </mat-label>
              <mat-select required (selectionChange)="getmagEdition($event)" formControlName="magedition">
                <mat-option value="Print">Print</mat-option>
                <mat-option value="Digital">Digital</mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="form-row">
            <mat-form-field class="example-full-width" appearance="outline" style="width: 35%; margin-left: 5%">
              <mat-label>Subscription Type</mat-label>
              <input matInput formControlName="duration" [matAutocomplete]="autodua" />
              <mat-autocomplete autoActiveFirstOption #autodua="matAutocomplete">
                <mat-option (onSelectionChange)="durationn($event, option)"
                  *ngFor="let option of subscriptiontype " [value]="option['subtype']">
                  {{ option["subtype"] }}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
            <mat-form-field appearance="outline" style="margin-left: 5%; width: 35%">
              <mat-label>Subscription Year</mat-label>
              <input formControlName="subyear" matInput type="date" />
            </mat-form-field>
          </div>

          <div class="form-row">
            <mat-form-field appearance="outline" style="margin-left: 5%; width: 35%">
              <mat-label>Subscription Period: From</mat-label>
              <input formControlName="subfromdate" matInput type="date" (change)="getDate()" />
            </mat-form-field>
            <mat-form-field appearance="outline" style="margin-left: 5%; width: 35%">
              <mat-label>To</mat-label>
              <input formControlName="subtodate" matInput type="date" />
            </mat-form-field>
          </div>

          <div class="form-row">
            <label style="margin-left: 5%; margin-top: -20px">
              (Note-Magazin Will Start From Issue Date- )
            </label>
          </div>

          <div class="form-row">
            <!-- (selectionChange)="SelectRepresentative($event)" -->
            <mat-form-field appearance="outline" style="margin-left: 5%; width: 35%">
              <mat-label>Delivery Mode</mat-label>
              <mat-select required formControlName="distype">
                <mat-option *ngFor="let rep of delivery_mode" value="{{ rep['delivery_mode'] }}">{{ rep["delivery_mode"]
                  }}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field class="example-full-width" appearance="outline" style="width: 35%; margin-left: 5%">
              <mat-label>Distribution Representative</mat-label>
              <input matInput formControlName="disrep" [matAutocomplete]="autodis" />
              <mat-autocomplete autoActiveFirstOption #autodis="matAutocomplete">
                <mat-option (onSelectionChange)="distribution($event, option)"
                  *ngFor="let option of filteredOptions6 | async" [value]="option['name']">
                  {{ option["name"] }}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
          </div>

          <div class="form-row">
            <mat-form-field appearance="outline" class="example-full-width" style="width: 35%; margin-left: 5%">
              <mat-label>Temp. Receipt No</mat-label>
              <input type="number" matInput formControlName="trecptno" />
            </mat-form-field>
            <mat-form-field appearance="outline" class="example-full-width" style="width: 35%; margin-left: 5%">
              <mat-label>Date</mat-label>
              <input matInput type="date" formControlName="rdate" />
              <!-- <mat-datepicker-toggle[matDatepicker]="d"
                matSuffix
                [for]="d"
              ></mat-datepicker-toggle>
              <mat-datepicker #d></mat-datepicker> -->
            </mat-form-field>
          </div>

          <div class="form-group" *ngIf="!iscomplementary">
            <label style="margin-left: 5%; width: 23%; font-weight: bold">Total Amount : {{ total }}</label>
            <mat-form-field appearance="outline" class="example-full-width" style="width: 23%; margin-left: 3%">
              <mat-label>Discount</mat-label>
              <input matInput type="number" (change)="discountcal($event)" formControlName="discount" />
            </mat-form-field>
            <mat-form-field appearance="outline" class="example-full-width" style="width: 23%; margin-left: 3%">
              <mat-label>Net Amount:</mat-label>
              <input matInput type="number" formControlName="netamount" />
            </mat-form-field>
          </div>

          <div class="form-row" *ngIf="!iscomplementary">
            <label style="margin-left: 5%; font-weight: bold">
              Amount In Word - {{ this.totalamtinword }}
            </label>
          </div>

          <div class="form-row" *ngIf="!iscomplementary">
            <label style="margin-left: 5%; margin-top: 20px">Representative Commission:</label>
            <mat-form-field appearance="outline" class="example-full-width" style="width: 25%; margin-left: 4%">
              <mat-label>%</mat-label>
              <input type="number" matInput formControlName="repcompercent" (change)="repcommission($event)" />
            </mat-form-field>
            <mat-form-field appearance="outline" class="example-full-width" style="width: 25%; margin-left: 4%">
              <mat-label>Amt.</mat-label>
              <input type="number" matInput formControlName="repcomprice" />
            </mat-form-field>
          </div>

          <div class="form-row" *ngIf="!iscomplementary">
            <label style="margin-left: 5%; font-weight: bold">Payment Details</label>
          </div>

          <div class="form-row" *ngIf="!iscomplementary">
            <mat-form-field appearance="outline" class="example-full-width" style="width: 35%; margin-left: 5%">
              <mat-label>Cheque/Draft No:</mat-label>
              <input matInput formControlName="cdno" />
            </mat-form-field>
            <mat-form-field appearance="outline" class="example-full-width" style="width: 35%; margin-left: 5%">
              <mat-label>Cheque Date</mat-label>
              <input matInput type="date" formControlName="chequeDate" />
            </mat-form-field>
          </div>

          <div class="form-row">
            <mat-form-field *ngIf="!iscomplementary" appearance="outline" class="example-full-width"
              style="width: 35%; margin-left: 5%">
              <mat-label>Bank Name:</mat-label>
              <input matInput formControlName="bankname" />
            </mat-form-field>
          </div>

          <div class="form-row">
            <mat-form-field class="example-full-width" appearance="outline" style="width: 35%; margin-left: 5%">
              <mat-label>Remark</mat-label>
              <input matInput formControlName="subremark" />
            </mat-form-field>
            <mat-form-field class="example-full-width" appearance="outline" style="width: 35%; margin-left: 5%">
              <mat-label>Remark_1</mat-label>
              <input matInput formControlName="subremark1" />
            </mat-form-field>
          </div>
          <div class="form-row">
            <mat-form-field class="example-full-width" appearance="outline" style="width: 35%; margin-left: 5%">
              <mat-label>Remark_2</mat-label>
              <input matInput formControlName="subremark2" />
            </mat-form-field>
            <mat-form-field class="example-full-width" appearance="outline" style="width: 35%; margin-left: 5%">
              <mat-label>Employee ID</mat-label>
              <input matInput formControlName="emp_mob_number" readonly />
            </mat-form-field>
          </div>
          <div class="form-row" *ngIf="selectedrcpt">
            <mat-form-field appearance="outline" style="width: 35%; margin-left: 5%">
              <mat-label>Receipt Active</mat-label>
              <select matNativeControl formControlName="isactive" (change)="isreceiptactive($event)">
                <option value="Y">Yes</option>
                <option value="N">No</option>
              </select>
            </mat-form-field>
          </div>
          <div class="form-row" *ngIf="isrcptno">
            <mat-form-field appearance="outline" style="width: 35%; margin-left: 5%">
              <mat-label>Days Added</mat-label>
              <input matInput type="number" formControlName="days" readonly />
            </mat-form-field>
          </div>
          <div class="form-row" *ngIf="isrcptno">
            <mat-form-field appearance="outline" style="width: 35%; margin-left: 5%">
              <mat-label>Receipt Stop Date</mat-label>
              <input matInput type="date" formControlName="rcptstopdate" readonly />
            </mat-form-field>
            <mat-form-field class="example-full-width" appearance="outline" style="width: 35%; margin-left: 5%">
              <mat-label>Remark</mat-label>
              <input matInput formControlName="rcptstopremark" />
            </mat-form-field>
          </div>

          <div class="form-row" *ngIf="isrcptno">
            <mat-form-field appearance="outline" style="width: 35%; margin-left: 5%">
              <mat-label>Receipt Start Date</mat-label>
              <input matInput type="date" formControlName="rcptstartdate"  />
            </mat-form-field>
            <mat-form-field class="example-full-width" appearance="outline" style="width: 35%; margin-left: 5%">
              <mat-label>Remark</mat-label>
              <input matInput formControlName="rcptstartremark" readonly />
            </mat-form-field>
          </div>

          <div class="form-group" *ngIf="!selectedrcpt" >
            <button style="margin-left: 5%; margin-right: 25%; width: 60%"  type="submit"
              mat-raised-button color="primary" class="btn btn-primary btn-block" [disabled]="subform.invalid">
              Add Subscription
            </button>
          </div>
          <div class="form-row" *ngIf="selectedrcpt" >
            <button style="margin-left: 10%; width: 25%" [disabled]="subform.invalid" type="submit" mat-raised-button
              color="primary" class="btn btn-primary btn-block">
              Add Subscription
            </button>
            <button style="margin-left: 5%; width: 25%" type="button" (click)="updatesubscriberreceipt()"
              mat-raised-button color="primary" class="btn btn-primary btn-block">
              Update
            </button>
            <button style="margin-left: 2%;width: 20%;" type="button" (click)="deletesubscriberreceipt()"
              mat-raised-button color="danger" class="btn btn-danger btn-block">
              Delete
            </button>
          </div>
        </form>
      </mat-card>
    </mat-tab>

    <mat-tab label="Subscribers Details">
      <form [formGroup]="subdetailsform">
        <div>
          <mat-form-field appearance="fill" style="margin-left: 3%;margin-top: 5%; width: 20%;">
            <mat-label>Enter a date range</mat-label>
            <mat-date-range-input [formGroup]="subdaterange" [rangePicker]="picker1">
              <input matStartDate formControlName="start" placeholder="Start date" />
              <input matEndDate formControlName="end" placeholder="End date"
                (dateChange)="getChangedDateRegistration()" />
            </mat-date-range-input>
            <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
            <mat-date-range-picker #picker1></mat-date-range-picker>
  
            <mat-error *ngIf="subdaterange.controls.start.hasError('matStartDateInvalid')">Invalid start date
            </mat-error>
            <mat-error *ngIf="subdaterange.controls.end.hasError('matEndDateInvalid')">Invalid end date
            </mat-error>
          </mat-form-field>
  
          <mat-form-field appearance="fill" class="example-full-width" style="margin-left: 3%; margin-top: 5%; width: 20%;">
            <mat-label>Subscriber No.</mat-label>
            <input matInput formControlName="cust_id" [matAutocomplete]="autocustid" />
            <button *ngIf="cust_id" matSuffix mat-icon-button aria-label="Clear" (click)="reset_cust_id()">
              <mat-icon>close</mat-icon>
            </button>
            <mat-autocomplete autoActiveFirstOption #autocustid="matAutocomplete">
              <mat-option (onSelectionChange)="get_cust_id($event, option)"
                *ngFor="let option of filteredOptions11 | async" [value]="option['subno']">
                {{ option["subno"] }}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
  
          <mat-form-field appearance="fill" class="example-full-width" style="margin-left: 3%; margin-top: 5%; width: 20%;">
            <mat-label>Receipt No.</mat-label>
            <input matInput formControlName="rcpt_no" [matAutocomplete]="autorcptno" />
            <button *ngIf="rcpt_no" matSuffix mat-icon-button aria-label="Clear" (click)="reset_rcpt_no()">
              <mat-icon>close</mat-icon>
            </button>
            <mat-autocomplete autoActiveFirstOption #autorcptno="matAutocomplete">
              <mat-option (onSelectionChange)="get_rcpt_no($event, option)"
                *ngFor="let option of filteredOptions10 | async" [value]="option['rcpt_no']">
                {{ option["rcpt_no"] }}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>

          <mat-form-field appearance="fill" class="example-full-width" style="margin-left: 3%; margin-top: 5%; width: 20%;">
            <mat-label>Subscriber Name</mat-label>
            <input matInput formControlName="sub_name" [matAutocomplete]="autosubnames" />
            <button *ngIf="sub_names1" matSuffix mat-icon-button aria-label="Clear" (click)="reset_sub_names1()">
              <mat-icon>close</mat-icon>
            </button>
            <mat-autocomplete autoActiveFirstOption #autosubnames="matAutocomplete">
              <mat-option (onSelectionChange)="get_sub_name1($event, option)"
                *ngFor="let option of filteredOptions25 | async" [value]="option['name']">
                {{ option["name"] }}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
  
        </div>
       

        <div>
          <mat-form-field appearance="fill" class="example-full-width" style="margin-left: 3%; margin-top: 5%; width: 20%;">
            <mat-label>Representative</mat-label>
            <input matInput formControlName="rep" [matAutocomplete]="autorep1" />
            <button *ngIf="rep" matSuffix mat-icon-button aria-label="Clear" (click)="reset_rep()">
              <mat-icon>close</mat-icon>
            </button>
            <mat-autocomplete autoActiveFirstOption #autorep1="matAutocomplete">
              <mat-option (onSelectionChange)="get_rep($event, option)" *ngFor="let option of filteredOptions7 | async"
                [value]="option['rep_name']">
                {{ option["rep_name"] }}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
  
          <mat-form-field appearance="fill" class="example-full-width" style="margin-left: 3%; margin-top: 5%; width: 20%;">
            <mat-label>Office Representative</mat-label>
            <input matInput formControlName="off_rep" [matAutocomplete]="autooffrep" />
            <button *ngIf="off_rep" matSuffix mat-icon-button aria-label="Clear" (click)="reset_off_rep()">
              <mat-icon>close</mat-icon>
            </button>
            <mat-autocomplete autoActiveFirstOption #autooffrep="matAutocomplete">
              <mat-option (onSelectionChange)="get_off_rep($event, option)"
                *ngFor="let option of filteredOptions8 | async" [value]="option['office_rep_name']">
                {{ option["office_rep_name"] }}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
  
          <mat-form-field appearance="fill" class="example-full-width" style="margin-left: 3%; margin-top: 5%; width: 20%;">
            <mat-label>Voucher No</mat-label>
            <input matInput formControlName="v_no" [matAutocomplete]="autovno" />
            <button *ngIf="v_no" matSuffix mat-icon-button aria-label="Clear" (click)="reset_v_no()">
              <mat-icon>close</mat-icon>
            </button>
            <mat-autocomplete autoActiveFirstOption #autovno="matAutocomplete">
              <mat-option (onSelectionChange)="get_v_no($event, option)" *ngFor="let option of filteredOptions9 | async"
                [value]="option['crdt_voucher_no']">
                {{ option["crdt_voucher_no"] }}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>

          <mat-form-field class="example-full-width" style="margin-left: 3%; width: 20%;  margin-top: 5%;">
            <mat-label>Show Active / Inactive : </mat-label>
            <mat-select matNativeControl formControlName="active_inactive">
              <mat-option [value]="'Y'">Active</mat-option>
              <mat-option [value]="'N'">Inactive</mat-option>
             
            </mat-select>
         
          </mat-form-field>
        </div>

        <div>
          <mat-form-field class="example-full-width" style="margin-left: 3%; width: 20%;  margin-top: 5%;">
            <mat-label>Delivery Modewise : </mat-label>
            <mat-select matNativeControl formControlName="hastetapal">
              <mat-option [value]="'हस्ते'">हस्ते</mat-option>
              <mat-option [value]="'टपाल'">टपाल</mat-option>
            </mat-select>
          </mat-form-field>

          <!-- new filter country -->
          <mat-form-field style="margin-left: 7%; margin-top: 5%; width: 20%;" appearance="fill">
            <mat-label>Country</mat-label>
            <mat-select (selectionChange)="getCountry($event)" formControlName="country" >
              <mat-option *ngFor="let c of countries" value="{{ c['country_name'] }}">{{ c["country_name"] }}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field style="margin-left: 3%; margin-top: 5%; width: 20%;" appearance="fill">
            <mat-label>State</mat-label>
            <mat-select (selectionChange)="getState($event)" formControlName="state">
              <mat-option *ngFor="let c of states" value="{{ c['state_name'] }}">{{ c["state_name"] }}
              </mat-option>
            </mat-select>
            <span>{{ selectedState }}</span>
          </mat-form-field>
          <mat-form-field style="margin-left: 3%; margin-top: 5%; width: 20%;" appearance="fill">
            <mat-label>District</mat-label>
            <mat-select (selectionChange)="getDistricts($event)" formControlName="dist_name">
              <mat-option *ngFor="let c of cities" value="{{ c['district_name'] }}">{{ c["district_name"] }}
              </mat-option>
            </mat-select>
            <span>{{ selectedCity }}</span>
          </mat-form-field>
          
          <mat-form-field style="margin-left: 3%; margin-top: 5%; width: 20%;" appearance="fill">
            <mat-label>Taluka</mat-label>
            <mat-select formControlName="tal_name">
              <mat-option *ngFor="let c of talukas" value="{{ c['taluka_name'] }}">{{ c["taluka_name"] }}</mat-option>
            </mat-select>
            <span>{{ selectedTaluka }}</span>
          </mat-form-field>
           <!-- country -->


          <!-- <mat-form-field appearance="fill" class="example-full-width" style="margin-left: 3%; margin-top: 5%; width: 20%;">
            <mat-label>District Name</mat-label>
            <input matInput formControlName="dist_name" [matAutocomplete]="autosubnames" />
            <button *ngIf="sub_names1" matSuffix mat-icon-button aria-label="Clear" (click)="reset_sub_names1()">
              <mat-icon>close</mat-icon>
            </button>
            <mat-autocomplete autoActiveFirstOption #autosubnames="matAutocomplete">
              
              <mat-option 
                *ngFor="let option of distname | async" [value]="option['district_name']">
                {{ option["district_name"] }}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field> -->

          
          <!-- <mat-form-field appearance="fill" class="example-full-width" style="margin-left: 3%; margin-top: 5%; width: 20%;">
            <mat-label>District Name</mat-label>
            <input matInput formControlName="dist_name" [matAutocomplete]="districtname" />
            <button *ngIf="sub_names1" matSuffix mat-icon-button aria-label="Clear" (click)="reset_sub_names1()">
              <mat-icon>close</mat-icon>
            </button>
            <mat-autocomplete autoActiveFirstOption #districtname="matAutocomplete">
              <mat-option  *ngFor="let option of distname" [value]="option['district_name']">
                {{ option["district_name"] }}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
          <mat-form-field appearance="fill" class="example-full-width" style="margin-left: 3%; margin-top: 5%; width: 20%;">
            <mat-label>Taluka Name</mat-label>
            <input matInput formControlName="tal_name" [matAutocomplete]="talukatname" />
            <button *ngIf="sub_names1" matSuffix mat-icon-button aria-label="Clear" (click)="reset_sub_names1()">
              <mat-icon>close</mat-icon>
            </button>
            <mat-autocomplete autoActiveFirstOption #talukatname="matAutocomplete">
              <mat-option  *ngFor="let option of talukaname1" [value]="option['taluka_name']">
                {{ option["taluka_name"] }}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>              -->
          <mat-form-field class="example-full-width" style="margin-left: 3%; width: 20%;  margin-top: 5%;">
            <mat-label>Subscription Type : </mat-label>
            <mat-select matNativeControl formControlName="subtype">
              <mat-option [value]="'वार्षिक'">वार्षिक</mat-option>
              <mat-option [value]="'त्रैवार्षिक'">त्रैवार्षिक</mat-option>
              <mat-option [value]="'पंचवार्षिक'">पंचवार्षिक</mat-option>

            </mat-select>
          </mat-form-field>

          <mat-form-field appearance="fill" class="example-full-width" style="margin-left: 3%; margin-top: 5%; width: 20%;">
            <mat-label>Prant Name</mat-label>
            <input matInput formControlName="prant_name" [matAutocomplete]="prantname" />
            <button *ngIf="sub_names1" matSuffix mat-icon-button aria-label="Clear" (click)="reset_sub_names1()">
              <mat-icon>close</mat-icon>
            </button>
            <mat-autocomplete autoActiveFirstOption #prantname="matAutocomplete">
              <mat-option  *ngFor="let option of prantdata" [value]="option['prant_name']">
                {{ option["prant_name"] }}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>

        </div>
      </form>
      <!-- <button
        style="margin-left: 3%"
        (click)="getCustReport()"
        mat-raised-button
        color="primary"
      >
        Get Report
      </button> -->

      <div style="margin-left: 40%;">
        <button mat-raised-button matTooltip="Download Execelsheet" class="btn btn-danger new-btn-danger"   style="float: none;" (click)="excelToExport('Return_Report_List')">Export</button>
        <button mat-raised-button matTooltip="search" class="btn btn-danger new-btn-danger"   style="float: none;" (click)="searchdeatils()">Search</button>
        <button mat-raised-button matTooltip="search" class="btn btn-danger new-btn-danger"   style="float: none;" (click)="subscriberreport ()">Brief</button>

      </div>

      <!-- <div>
        <button mat-raised-button matTooltip="search" class="btn btn-danger new-btn-danger"   style="float: none;" (click)="searchdeatils()">Search</button>
      </div> -->

      <div>
        <div class="mat-elevation-z8 matTable" tabindex="0">
          <table mat-table [dataSource]="dataSource">
            <ng-container matColumnDef="rcpt_no">
              <th mat-header-cell *matHeaderCellDef>Receipt No.</th>
              <td mat-cell *matCellDef="let element"> {{element.rcpt_no}} </td>
              <td mat-footer-cell *matFooterCellDef></td>
            </ng-container>

            <ng-container matColumnDef="entry_date">
              <th mat-header-cell *matHeaderCellDef> Date </th>
              <td mat-cell *matCellDef="let element"> {{element.entry_date}} </td>
              <td mat-footer-cell *matFooterCellDef>Counts of Records:</td>
            </ng-container>

            <ng-container matColumnDef="subno">
              <th mat-header-cell *matHeaderCellDef> Subscription No </th>
              <td mat-cell *matCellDef="let element"> {{element.subno}} </td>
              <td mat-footer-cell *matFooterCellDef>{{ total_no_data }}</td>
            </ng-container>

            <ng-container matColumnDef="name">
              <th mat-header-cell *matHeaderCellDef> Subscription Name </th>
              <td mat-cell *matCellDef="let element"> {{element.name}} </td>
              <td mat-footer-cell *matFooterCellDef></td>
            </ng-container>

            <ng-container matColumnDef="district">
              <th mat-header-cell *matHeaderCellDef> Place </th>
              <td mat-cell *matCellDef="let element"> {{element.district}} </td>
              <td mat-footer-cell *matFooterCellDef></td>
            </ng-container>

            <ng-container matColumnDef="subscription_type">
              <th mat-header-cell *matHeaderCellDef> Subscription Type </th>
              <td mat-cell *matCellDef="let element"> {{element.subscription_type}} </td>
              <td mat-footer-cell *matFooterCellDef></td>
            </ng-container>

            <ng-container matColumnDef="rcpt_type">
              <th mat-header-cell *matHeaderCellDef> Is_Receipt </th>
              <td mat-cell *matCellDef="let element"> {{element.rcpt_type}} </td>
              <td mat-footer-cell *matFooterCellDef></td>
            </ng-container>

            <ng-container matColumnDef="sub_perid_from_date">
              <th mat-header-cell *matHeaderCellDef> Subscription From Date</th>
              <td mat-cell *matCellDef="let element"> {{element.sub_perid_from_date}} </td>
              <td mat-footer-cell *matFooterCellDef></td>
            </ng-container>

            <ng-container matColumnDef="sub_perid_to_date">
              <th mat-header-cell *matHeaderCellDef> Perid To Date</th>
              <td mat-cell *matCellDef="let element"> {{element.sub_perid_to_date}} </td>
              <td mat-footer-cell *matFooterCellDef></td>
            </ng-container>

            <ng-container matColumnDef="delivery_mode">
              <th mat-header-cell *matHeaderCellDef> Delivery Mode </th>
              <td mat-cell *matCellDef="let element"> {{element.delivery_mode}} </td>
              <td mat-footer-cell *matFooterCellDef></td>
            </ng-container>

            <ng-container matColumnDef="distribution_rep">
              <th mat-header-cell *matHeaderCellDef> Distribution Representative Name </th>
              <td mat-cell *matCellDef="let element"> {{element.distribution_rep}} </td>
              <td mat-footer-cell *matFooterCellDef></td>
            </ng-container>

            <ng-container matColumnDef="temp_rcpt_no">
              <th mat-header-cell *matHeaderCellDef> Temp Receipt No</th>
              <td mat-cell *matCellDef="let element"> {{element.temp_rcpt_no}} </td>
              <td mat-footer-cell *matFooterCellDef></td>
            </ng-container>

            <ng-container matColumnDef="temp_rcpt_date">
              <th mat-header-cell *matHeaderCellDef> Date </th>
              <td mat-cell *matCellDef="let element"> {{element.temp_rcpt_date}} </td>
              <td mat-footer-cell *matFooterCellDef></td>
            </ng-container>

            <ng-container matColumnDef="rep_name">
              <th mat-header-cell *matHeaderCellDef> Representative Name</th>
              <td mat-cell *matCellDef="let element"> {{element.rep_name}} </td>
              <td mat-footer-cell *matFooterCellDef></td>
            </ng-container>

            <ng-container matColumnDef="office_rep_name">
              <th mat-header-cell *matHeaderCellDef> Office Representative Name </th>
              <td mat-cell *matCellDef="let element"> {{element.office_rep_name}} </td>
              <td mat-footer-cell *matFooterCellDef></td>
            </ng-container>

            <ng-container matColumnDef="total_amt">
              <th mat-header-cell *matHeaderCellDef> Total Amount </th>
              <td mat-cell *matCellDef="let element"> {{element.total_amt}} </td>
              <td mat-footer-cell *matFooterCellDef>{{getTotalTotalAmount()}}</td>
            </ng-container>

            <ng-container matColumnDef="discount">
              <th mat-header-cell *matHeaderCellDef> Discount </th>
              <td mat-cell *matCellDef="let element"> {{element.discount}} </td>
              <td mat-footer-cell *matFooterCellDef>{{getTotalDiscountAmount()}}</td>
            </ng-container>

            <ng-container matColumnDef="net_amt">
              <th mat-header-cell *matHeaderCellDef> Net Amount </th>
              <td mat-cell *matCellDef="let element"> {{element.net_amt}} </td>
              <td mat-footer-cell *matFooterCellDef> {{getTotalNetAmount()}} </td>
            </ng-container>

            <ng-container matColumnDef="rep_com_amt">
              <th mat-header-cell *matHeaderCellDef> Rrpresentative Commission </th>
              <td mat-cell *matCellDef="let element"> {{element.rep_com_amt}}</td>
              <td mat-footer-cell *matFooterCellDef>{{getTotalRepComAmount()}}</td>
            </ng-container>

            <ng-container matColumnDef="crdt_voucher_no">
              <th mat-header-cell *matHeaderCellDef> Voucher No </th>
              <td mat-cell *matCellDef="let element"> {{element.crdt_voucher_no}} </td>
              <td mat-footer-cell *matFooterCellDef></td>
            </ng-container>

            <ng-container matColumnDef="crdt_voucher_entry_date">
              <th mat-header-cell *matHeaderCellDef> Voucher Entry Date </th>
              <td mat-cell *matCellDef="let element"> {{element.crdt_voucher_entry_date}} </td>
              <td mat-footer-cell *matFooterCellDef></td>
            </ng-container>

            <ng-container matColumnDef="magzine_edition">
              <th mat-header-cell *matHeaderCellDef> Edition </th>
              <td mat-cell *matCellDef="let element"> {{element.magzine_edition}} </td>
              <td mat-footer-cell *matFooterCellDef></td>
            </ng-container>

            <ng-container matColumnDef="remark">
              <th mat-header-cell *matHeaderCellDef> Remark </th>
              <td mat-cell *matCellDef="let element"> {{element.remark}} </td>
              <td mat-footer-cell *matFooterCellDef></td>
            </ng-container>

            <ng-container matColumnDef="remark1">
              <th mat-header-cell *matHeaderCellDef> Remark1 </th>
              <td mat-cell *matCellDef="let element"> {{element.remark1}} </td>
              <td mat-footer-cell *matFooterCellDef></td>
            </ng-container>

            <ng-container matColumnDef="remark2">
              <th mat-header-cell *matHeaderCellDef> Remark2 </th>
              <td mat-cell *matCellDef="let element"> {{element.remark2}} </td>
              <td mat-footer-cell *matFooterCellDef></td>
            </ng-container>

            <ng-container matColumnDef="rcptstopdate">
              <th mat-header-cell *matHeaderCellDef> Receipt Stop Date </th>
              <td mat-cell *matCellDef="let element"> {{element.rcptstopdate}} </td>
              <td mat-footer-cell *matFooterCellDef></td>
            </ng-container>

            <ng-container matColumnDef="rcptstopremark">
              <th mat-header-cell *matHeaderCellDef> Receipt Stop Remark </th>
              <td mat-cell *matCellDef="let element"> {{element.rcptstopremark}} </td>
              <td mat-footer-cell *matFooterCellDef></td>
            </ng-container>

            <ng-container matColumnDef="rcptstartdate">
              <th mat-header-cell *matHeaderCellDef> Receipt Start Date</th>
              <td mat-cell *matCellDef="let element"> {{element.rcptstartdate}} </td>
              <td mat-footer-cell *matFooterCellDef></td>
            </ng-container>

            <ng-container matColumnDef="rcptstartremark">
              <th mat-header-cell *matHeaderCellDef> Receipt Start Remark </th>
              <td mat-cell *matCellDef="let element"> {{element.rcptstartremark}} </td>
              <td mat-footer-cell *matFooterCellDef></td>
            </ng-container>

            <ng-container matColumnDef="action">
              <th colspan="2" mat-header-cell *matHeaderCellDef> Action </th>
              <td colspan="2" mat-cell *matCellDef="let element">
                <button *ngIf="element['is_active'] == 'N'" class="btn btn-primary" mat-raised-button type="button"
                  (click)="inactiveReceipt1(element)">
                  Active
                </button>
                <button *ngIf="element['is_active'] == 'Y'" class="btn btn-primary" mat-raised-button type="button"
                  (click)="inactiveReceipt1(element)">
                  Inactive
                </button>
                <button class="btn btn-danger" mat-raised-button type="button" (click)="returnReceipt(element)">
                  Return
                </button>
              </td>
              <td mat-footer-cell *matFooterCellDef></td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            <tr mat-footer-row *matFooterRowDef="displayedColumns; sticky: true"></tr>
          </table>
        </div>

        <mat-paginator [pageSize]="13000" (page)="handlePageEvent($event)" [length]="length" [pageSizeOptions]="[500, 1000, 3000, 7000, 12000, 15000, 20000]" 
          showFirstLastButtons aria-label="Select page of periodic elements">
        </mat-paginator>
      </div>
    </mat-tab>

    <mat-tab label="Return Receipt Report">
      <mat-card>
        <form [formGroup]="returnrcptform">
          <div>
            <mat-form-field appearance="fill" style="margin-top: 1%; width:20%;">
              <mat-label>Enter a date range</mat-label>
              <mat-date-range-input [formGroup]="rtnrcptdaterange" [rangePicker]="pickerrtn">
                <input matStartDate formControlName="start" placeholder="Start date" />
                <input matEndDate formControlName="end" placeholder="End date"
                  (dateChange)="getChangedDateReturnRcpt()" />
              </mat-date-range-input>
              <mat-datepicker-toggle matSuffix [for]="pickerrtn"></mat-datepicker-toggle>
              <mat-date-range-picker #pickerrtn></mat-date-range-picker>
  
              <mat-error *ngIf="
                  rtnrcptdaterange.controls.start.hasError('matStartDateInvalid')
                ">Invalid start date
              </mat-error>
              <mat-error *ngIf="
                  rtnrcptdaterange.controls.end.hasError('matEndDateInvalid')
                ">Invalid end date
              </mat-error>
            </mat-form-field>
  
            <mat-form-field appearance="fill" class="example-full-width" style="margin-left: 2%; margin-top: 1%; width:20%;">
              <mat-label>Subscriber Name</mat-label>
              <input matInput formControlName="subname" [matAutocomplete]="autosubname" />
              <button *ngIf="sub_name" matSuffix mat-icon-button aria-label="Clear" (click)="reset_sub_name()">
                <mat-icon>close</mat-icon>
              </button>
              <mat-autocomplete autoActiveFirstOption #autosubname="matAutocomplete">
                <mat-option (onSelectionChange)="get_sub_name($event, option)"
                  *ngFor="let option of filteredOptions14 | async" [value]="option['name']">
                  {{ option["name"] }}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>

            <mat-form-field appearance="fill" class="example-full-width" style="margin-left: 2%; margin-top: 1%; width:20%;">
              <mat-label>Representative</mat-label>
              <input matInput formControlName="rep" [matAutocomplete]="autorep3" />
              <button *ngIf="rep2" matSuffix mat-icon-button aria-label="Clear" (click)="reset_rtn_rep()">
                <mat-icon>close</mat-icon>
              </button>
              <mat-autocomplete autoActiveFirstOption #autorep3="matAutocomplete">
                <mat-option (onSelectionChange)="get_rtn_rep($event, option)"
                  *ngFor="let option of filteredOptions15 | async" [value]="option['rep_name']">
                  {{ option["rep_name"] }}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
  
            <mat-form-field appearance="fill" class="example-full-width" style="margin-left: 2%; margin-top: 1%; width:20%;">
              <mat-label>Office Representative</mat-label>
              <input matInput formControlName="off_rep" [matAutocomplete]="autooffrep1" />
              <button *ngIf="off_rep1" matSuffix mat-icon-button aria-label="Clear" (click)="reset_off_rep1()">
                <mat-icon>close</mat-icon>
              </button>
              <mat-autocomplete autoActiveFirstOption #autooffrep1="matAutocomplete">
                <mat-option (onSelectionChange)="get_off_rep1($event, option)"
                  *ngFor="let option of filteredOptions16 | async" [value]="option['office_rep_name']">
                  {{ option["office_rep_name"] }}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
  


          </div>
         

          <!-- <mat-form-field
            appearance="fill"
            class="example-full-width"
            style="margin-left: 3%; margin-top: 1%"
          >
            <mat-label>Receipt No.</mat-label>
            <input
              matInput
              formControlName="rcpt_no"
              [matAutocomplete]="autorcptno"
            />
            <button
              *ngIf="rcpt_no"
              matSuffix
              mat-icon-button
              aria-label="Clear"
              (click)="reset_rcpt_no()"
            >
              <mat-icon>close</mat-icon>
            </button>
            <mat-autocomplete
              autoActiveFirstOption
              #autorcptno="matAutocomplete"
            >
              <mat-option
                (onSelectionChange)="get_rcpt_no($event, option)"
                *ngFor="let option of filteredOptions10 | async"
                [value]="option['rcpt_no']"
              >
                {{ option["rcpt_no"] }}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field> -->


          <mat-form-field appearance="fill" class="example-full-width" style="margin-top: 2%;width:20%">
            <mat-label>District</mat-label>
            <input matInput formControlName="district" [matAutocomplete]="autodst" />
            <button *ngIf="district1" matSuffix mat-icon-button aria-label="Clear" (click)="reset_district()">
              <mat-icon>close</mat-icon>
            </button>
            <mat-autocomplete autoActiveFirstOption #autodst="matAutocomplete">
              <mat-option (onSelectionChange)="get_district($event, option)"
                *ngFor="let option of filteredOptions17 | async" [value]="option['district']">
                {{ option["district"] }}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>

          <mat-form-field appearance="fill" class="example-full-width" style="margin-left: 2%; margin-top: 2%;width:20%">
            <mat-label>Taluka</mat-label>
            <input matInput formControlName="taluka" [matAutocomplete]="autotlk" />
            <button *ngIf="taluka1" matSuffix mat-icon-button aria-label="Clear" (click)="reset_taluka()">
              <mat-icon>close</mat-icon>
            </button>
            <mat-autocomplete autoActiveFirstOption #autotlk="matAutocomplete">
              <mat-option (onSelectionChange)="get_taluka($event, option)"
                *ngFor="let option of filteredOptions18 | async" [value]="option['taluka']">
                {{ option["taluka"] }}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>

          <mat-form-field appearance="fill" class="example-full-width" style="margin-left: 2%; margin-top: 2%;width:20%">
            <mat-label>State</mat-label>
            <input matInput formControlName="state" [matAutocomplete]="autostt" />
            <button *ngIf="state1" matSuffix mat-icon-button aria-label="Clear" (click)="reset_state()">
              <mat-icon>close</mat-icon>
            </button>
            <mat-autocomplete autoActiveFirstOption #autostt="matAutocomplete">
              <mat-option (onSelectionChange)="get_state($event, option)"
                *ngFor="let option of filteredOptions19 | async" [value]="option['state']">
                {{ option["state"] }}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>

          <mat-form-field appearance="fill" class="example-full-width" style="margin-left: 2%; margin-top: 2%;width:20%">
            <mat-label>Country</mat-label>
            <input matInput formControlName="country" [matAutocomplete]="autoct" />
            <button *ngIf="country1" matSuffix mat-icon-button aria-label="Clear" (click)="reset_country()">
              <mat-icon>close</mat-icon>
            </button>
            <mat-autocomplete autoActiveFirstOption #autoct="matAutocomplete">
              <mat-option (onSelectionChange)="get_country($event, option)"
                *ngFor="let option of filteredOptions20 | async" [value]="option['country']">
                {{ option["country"] }}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
        </form>
        <div class="tableFixHead" id="excel-table1">
          <button mat-raised-button matTooltip="Download Execelsheet" class="btn btn-danger new-btn-danger"
            (click)="exportexcel1('Return_Report_List')">Export</button>
          <table class="table table-striped text-center">
            <thead>
              <tr>
                <th>एन्ट्री दि</th>
                <th>अंक दि</th>
                <th>ग्राहक क्र</th>
                <th>ग्राहकाचे नाव</th>
                <th>ठिकाण</th>
                <th>संपर्क</th>
                <th>परत येणाचे कारण</th>
                <th>प्रतिनिधींचे नाव</th>
                <th>कार्यालय प्रतिनिधींचे नाव</th>
                <th>कार्यवाही</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of Filteredreturnrcptdata">
                <td>{{ item["date"] | date: "dd-MM-yyy" }}</td>
                <td>{{ item["ank_date"] | date: "dd-MM-yyy" }}</td>
                <td>{{ item["subno"] }}</td>
                <td>{{ item["name"] }}</td>
                <td>{{ item["district"] }}</td>
                <td>{{ item["mobileNumber"] }}</td>
                <td>{{ item["reason"] }}</td>
                <td>{{ item["rep_name"] }}</td>
                <td>{{ item['office_rep_name'] }}</td>
                <td>{{ item["karyavahi"] }}</td>
                <td>
                  <button class="btn-sm btn-outline-info" type="button" (click)="updateReturnReceipt(item)">
                    <mat-icon>edit</mat-icon>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </mat-card>
    </mat-tab>

    <mat-tab label="Subscriber Receipt Ledger">
      <mat-card style="display: flow;">
        <form [formGroup]="receiptLedger">

        <!-- <mat-form-field appearance="fill" style="width:300px">
          <mat-label>Select Date</mat-label>
          <input matInput [matDatepicker]="pickersubdate" [(ngModel)]="subdate" (ngModelChange)="selectSubDate()">
          <mat-datepicker-toggle matSuffix [for]="pickersubdate"></mat-datepicker-toggle>
          <mat-datepicker #pickersubdate></mat-datepicker>
        </mat-form-field> -->
        <mat-form-field appearance="fill" style="margin-top: 1%; width:30%;">
          <mat-label>Enter a date range</mat-label>
          <mat-date-range-input [rangePicker]="pickerrtl">
            <input matStartDate formControlName="start" placeholder="Start date" />
            <input matEndDate formControlName="end" placeholder="End date"
              (dateChange)="getChangedDateRcptLedger()" />
          </mat-date-range-input>
          <mat-datepicker-toggle matSuffix [for]="pickerrtl"></mat-datepicker-toggle>
          <mat-date-range-picker #pickerrtl></mat-date-range-picker>
        </mat-form-field>

        <mat-form-field appearance="fill" style="margin-top: 1%; margin-left: 1%">
          <mat-label>Customer Name</mat-label>
          <input type="text" matInput [(ngModel)]="cust_name" formControlName="cust_name" (keyup)="custname($event)" />
          <button *ngIf="cust_name" matSuffix mat-icon-button aria-label="Clear" (click)="reset_custname()">
            <mat-icon>close</mat-icon>
          </button>
        </mat-form-field>
        <button type="button" class="btn" mat-raised-button color="primary" style="float: none;margin-left: 2%; width: 250px;" (click)="subReceipt()">Subcriber
          Receipt</button>
        </form>
      </mat-card>
      <mat-card>
        <div class="form-row">
          <form [formGroup]="paymentreceiptledger">
            <mat-form-field appearance="fill" style="margin-top: 1%; width: 20%;">
              <mat-label>Enter a date range</mat-label>
              <mat-date-range-input [rangePicker]="pickerrcpt">
                <input matStartDate formControlName="start" placeholder="Start date" />
                <input matEndDate formControlName="end" placeholder="End date" (dateChange)="getChangedDateRcptLedger1()" />
              </mat-date-range-input>
              <mat-datepicker-toggle matSuffix [for]="pickerrcpt"></mat-datepicker-toggle>
              <mat-date-range-picker #pickerrcpt></mat-date-range-picker>
        
              <mat-error *ngIf="
                        rcptldgrForm.controls.start.hasError('matStartDateInvalid')
                      ">Invalid start date
              </mat-error>
              <mat-error *ngIf="rcptldgrForm.controls.end.hasError('matEndDateInvalid')">Invalid end date
              </mat-error>
            </mat-form-field>
            <mat-form-field appearance="fill" style="margin-top: 1%; margin-left: 1%;width: 20%;">
              <mat-label>Receipt No</mat-label>
              <input type="text" formControlName="rcpt_number" matInput [(ngModel)]="rcpt_no1" (ngModelChange)="rcptno($event)" />
              <button *ngIf="rcpt_no1" matSuffix mat-icon-button aria-label="Clear" (click)="reset_rcptno()">
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>
            <mat-form-field appearance="fill" style="margin-top: 1%; margin-left: 1%; width: 20%;">
              <mat-label>Represantative Name</mat-label>
              <mat-select formControlName="rep" multiple>
                <mat-option value="ALL" (click)="toggleAllSelection()">All</mat-option>
                <mat-option *ngFor="let option of GetOffRep" [value]="option['rep_name']">{{ option["rep_name"]
                  }}</mat-option>
              </mat-select>
            </mat-form-field>
        
            <!-- <mat-form-field appearance="fill"  style="margin-top: 1%; margin-left: 1%; margin-right: 1%;">
                    <mat-label>District Name</mat-label>
                    <input matInput formControlName="dist_name" [matAutocomplete]="districtname" (selectionChange)="getDistricts($event)" />
                    <button *ngIf="sub_names1" matSuffix mat-icon-button aria-label="Clear" (click)="reset_sub_names1()">
                      <mat-icon>close</mat-icon>
                    </button>
                    <mat-autocomplete autoActiveFirstOption #districtname="matAutocomplete">
                      <mat-option  *ngFor="let option of distname" [value]="option['district_name']">
                        {{ option["district_name"] }}
                      </mat-option>
                    </mat-autocomplete>
                    
                  </mat-form-field> -->
                  <mat-form-field style="margin-left: 3%; margin-top: 5%; width: 20%;" appearance="fill">
                    <mat-label>Country</mat-label>
                    <mat-select (selectionChange)="getCountry($event)" formControlName="country" >
                      <mat-option *ngFor="let c of countries" value="{{ c['country_name'] }}">{{ c["country_name"] }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
        
                  <mat-form-field style="margin-top: 5%; width: 20%;" appearance="fill">
                    <mat-label>State</mat-label>
                    <mat-select (selectionChange)="getState($event)" formControlName="state">
                      <mat-option *ngFor="let c of states" value="{{ c['state_name'] }}">{{ c["state_name"] }}
                      </mat-option>
                    </mat-select>
                    <span>{{ selectedState }}</span>
                  </mat-form-field>
                  <mat-form-field style="margin-left: 3%; margin-top: 5%; width: 20%;" appearance="fill">
                    <mat-label>District</mat-label>
                    <mat-select (selectionChange)="getDistricts($event)" formControlName="district">
                      <mat-option *ngFor="let c of cities" value="{{ c['district_name'] }}">{{ c["district_name"] }}
                      </mat-option>
                    </mat-select>
                    <span>{{ selectedCity }}</span>
                  </mat-form-field>
                  
                  <mat-form-field style="margin-left: 3%; margin-top: 5%; width: 20%;" appearance="fill">
                    <mat-label>Taluka</mat-label>
                    <mat-select formControlName="taluka">
                      <mat-option *ngFor="let c of talukas" value="{{ c['taluka_name'] }}">{{ c["taluka_name"] }}</mat-option>
                    </mat-select>
                    <span>{{ selectedTaluka }}</span>
                  </mat-form-field>
            <!-- <mat-form-field style="margin-top: 1%; margin-left: 1%; margin-right: 1%;width: 20%;">
              <mat-label>District</mat-label>
              <mat-select (selectionChange)="getDistricts1($event)" formControlName="district" multiple>
                <mat-option *ngFor="let c of distname" value="{{ c['district_name'] }}">{{ c["district_name"] }}
                </mat-option>
              </mat-select>
              <span>{{ selectedCity }}</span>
            </mat-form-field>
        
            <mat-form-field style="margin-top: 1%; width: 20%;" appearance="fill">
              <mat-label>Taluka</mat-label>
              <mat-select formControlName="taluka" multiple>
                <mat-option *ngFor="let c of talukas" value="{{ c['taluka_name'] }}">{{ c["taluka_name"] }}</mat-option>
              </mat-select>
              <span>{{ selectedTaluka }}</span>
            </mat-form-field> -->
        
            <mat-form-field appearance="fill" style="margin-top: 1%; margin-left: 1%;width: 20%;">
              <mat-label>Prant Name</mat-label>
              <input matInput formControlName="prant_name" [matAutocomplete]="prantname1" />
              <button *ngIf="sub_names1" matSuffix mat-icon-button aria-label="Clear" (click)="reset_sub_names1()">
                <mat-icon>close</mat-icon>
              </button>
              <mat-autocomplete autoActiveFirstOption #prantname1="matAutocomplete">
                <mat-option *ngFor="let option of prantdata" [value]="option['prant_name']">
                  {{ option["prant_name"] }}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
        
            <mat-form-field style="margin-top: 1%; margin-left: 1%; margin-right: 1%;width: 20%;" appearance="fill">
              <mat-label>Dis. Reprensantive</mat-label>
              <mat-select formControlName="dis_rep">
                <mat-option *ngFor="let c of dis_rep_data" value="{{ c['id'] }}">{{ c["distribution_rep"] }}</mat-option>
              </mat-select>
              <span>{{ selectedTaluka }}</span>
            </mat-form-field>
        
            <mat-form-field class="fill" style="margin-top: 1%;width: 20%;">
              <mat-label>Show New / Renew : </mat-label>
              <mat-select matNativeControl formControlName="active_inactive" multiple>
                <mat-option [value]="'ALL'">All</mat-option>
                <mat-option [value]="'New'">New</mat-option>
                <mat-option [value]="'Renew'">Renew</mat-option>
        
              </mat-select>
        
            </mat-form-field>
        
            <!-- <mat-form-field appearance="fill" style="margin-top: 1%; margin-left: 1%">
                    <mat-label>Customer Name</mat-label>
                    <input type="text" matInput [(ngModel)]="cust_name" (ngModelChange)="custname($event)" />
                    <button *ngIf="cust_name" matSuffix mat-icon-button aria-label="Clear" (click)="reset_custname()">
                      <mat-icon>close</mat-icon>
                    </button>
                  </mat-form-field> -->
          </form>
          <button type="button" class="btn " mat-raised-button color="primary"
            style="float: none;height: 44px;margin-left: 2%;margin-top: 2%; width: 250px;"
            (click)="subReceiptLedger()">Subcriber
            Receipt Ledger</button>
            <button type="button" class="btn " mat-raised-button color="primary"
            style="float: none;height: 44px;margin-left: 2%;margin-top: 2%; width: 250px;"
            (click)="searchpaymentreceipt()">Search</button>
        </div>
        <div class="tableFixHead mt-3" style="height: 400px;">
          <table class="table table-striped text-center">
            <thead>
              <tr>
                <th>Sr. No</th>
                <th>Receipt No</th>
                <th>Date</th>
                <th>Subscription No.</th>
                <th>Subscription Name</th>
                <th>Place</th>
                <th>Subscription Type</th>
                <th>Is Receipt</th>
                <th>Period</th>
                <th>Representative Name</th>
                <th>Office Representative Name</th>
                <th>Edition</th>
                <th>Total Amount</th>
                <th>Discount</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of Filetredreceiptledger; let i = index;">
                <td>{{i+1}}</td>
                <td>{{ item["rcpt_no"] }}</td>
                <!-- <td>{{ item["entry_date"] | date: "dd-MM-yyy" }}</td> -->
                <td>{{ item["crdt_voucher_date"] | date: "dd-MM-yyy" }}</td>
                <td>{{ item["subno"] }}</td>
                <td>{{ item["name"] }}</td>
                <td>{{ item["district"] }}</td>
                <td>{{ item["subscription_type"] }}</td>
                <td>{{ item["rcpt_type"] }}</td>
                <td>
                  {{ item["sub_perid_from_date"] | date: "dd-MM-yyy" }} -
                  {{ item["sub_perid_to_date"] | date: "dd-MM-yyy" }}
                </td>
                <td>{{ item["rep_name"] }}</td>
                <td>{{ item["office_rep_name"] }}</td>
                <td>{{ item["magzine_edition"] }}</td>
                <td>{{ item["total_amt"] }}</td>
                <td>{{ item["discount"] }}</td>
                <td> <button style="margin-left: 2%;width: 20%;" type="button" (click)="deletesubscriberrcpt(item)"
                  mat-raised-button color="danger" class="btn btn-danger btn-block">
                  Delete
                </button></td>
              </tr>
             
            </tbody>
            <tfoot>
              <tr>
                <td colspan="11"></td>
                <td>{{ this.receiptledgertotalamt }}</td>
                <td>{{ this.receiptledgerdiscounttotal }}</td>
              </tr>
            </tfoot>
          </table>
        </div>
       
      </mat-card>
    </mat-tab>

    <mat-tab label="GST Report">
      <mat-card>
        <div class="form-row">
          <mat-form-field appearance="fill" style="margin-top: 1%">
            <mat-label>Enter a date range</mat-label>
            <mat-date-range-input [formGroup]="gstreportForm" [rangePicker]="pickergst">
              <input matStartDate formControlName="start" placeholder="Start date" />
              <input matEndDate formControlName="end" placeholder="End date" (dateChange)="getChangedDategstreport()" />
            </mat-date-range-input>
            <mat-datepicker-toggle matSuffix [for]="pickergst"></mat-datepicker-toggle>
            <mat-date-range-picker #pickergst></mat-date-range-picker>

            <mat-error *ngIf="
                          gstreportForm.controls.start.hasError('matStartDateInvalid')
                        ">Invalid start date
            </mat-error>
            <mat-error *ngIf="gstreportForm.controls.end.hasError('matEndDateInvalid')">Invalid end date
            </mat-error>
          </mat-form-field>
          <div style="margin-left: 5%;margin-top: 2%;">
            <button class="btn"  mat-raised-button color="primary" (click)="GSTReportPrint()">GST
              Report Print</button>
          </div>
        </div>
        <div id="excel-table">
          <div class="tableFixHead">
            <button type="button" (click)="excelToExportGSTReport('GST_Report')" class="btn btn-danger">
              Export
            </button>
            <!-- <cdk-virtual-scroll-viewport appendOnly itemSize="50" class="example-viewport"> -->
            <table class="table table-striped text-center">
              <thead>
                <tr>
                  <th>Sr. No.</th>
                  <th>Receipt Date</th>
                  <th>Customer Name</th>
                  <th>Address of Company</th>
                  <th>Receipt No</th>
                  <th>GST No</th>
                  <th>HSN Code</th>
                  <th>Total Amount</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let item of showGSTReceiptData; let i = index">
                  <td>{{ i + 1 }}</td>
                  <td>{{ item["crdt_voucher_date"] | date:"dd-MM-yyyy" }}</td>
                  <td>{{ item["e_name"] }}</td>
                  <td>{{ item["e_district"] }}</td>
                  <td><input type="text" value="{{ item['rcpt_no'] }}"
                      (change)="addBillGSTHSNData($event, i, 'billno')"></td>
                  <td><input type="text" value="{{ item['gst_no'] }}" (change)="addBillGSTHSNData($event, i, 'gstno')">
                  </td>
                  <td><input type="text" value="{{ item['hsn_code'] }}"
                      (change)="addBillGSTHSNData($event, i, 'hsncode')"></td>
                  <td>{{ item["total_amt"] }}</td>
                </tr>
                <tr>
                  <td colspan="7"></td>
                  <td>{{ this.gstreporttotalamt }}</td>
                </tr>
              </tbody>
            </table>
            <!-- </cdk-virtual-scroll-viewport> -->
          </div>
        </div>
      </mat-card>
    </mat-tab>

    <mat-tab label="Subscription Business Report">
      <mat-card>
        <form [formGroup]="daterepForm">
          <div class="form-row">
            <mat-form-field appearance="fill" style="margin-top: 1%; width: 30%;">
              <mat-label>Enter a date range</mat-label>
              <mat-date-range-input [rangePicker]="pickerdaterep">
                <input matStartDate formControlName="start" placeholder="Start date" />
                <input matEndDate formControlName="end" placeholder="End date" (dateChange)="getChangedDaterep()" />
              </mat-date-range-input>
              <mat-datepicker-toggle matSuffix [for]="pickerdaterep"></mat-datepicker-toggle>
              <mat-date-range-picker #pickerdaterep></mat-date-range-picker>
            </mat-form-field>
            <mat-form-field appearance="fill" style="margin-left: 3%; margin-top: 1%; width: 30%;">
              <mat-label>Representative Name</mat-label>
              <mat-select formControlName="rep_name" (selectionChange)="getrepnames($event)" multiple>
              
                <mat-option *ngFor="let option of filtereddaterepwiserep_name" [value]="option['rep_name']">{{
                  option["rep_name"]
                  }}</mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field appearance="fill" class="example-full-width" style="margin-left: 3%; margin-top: 1%; width: 30%;">
              <mat-label>Office Representative Name</mat-label>
              <input matInput formControlName="off_rep_name" [matAutocomplete]="autooff_rep_name" />
              <button *ngIf="off_rep_name1 != 'lmn'" matSuffix mat-icon-button aria-label="Clear"
                (click)="reset_off_rep_name()">
                <mat-icon>close</mat-icon>
              </button>
              <mat-autocomplete autoActiveFirstOption #autooff_rep_name="matAutocomplete" multiple>
                <mat-option (onSelectionChange)="getoff_rep_name($event, option)"
                  *ngFor="let option of filteredOptions22 | async" [value]="option['office_rep_name']">
                  {{ option["office_rep_name"] }}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
          </div>
         
          <div class="form-row">
            <mat-form-field style="margin-top: 1%;  width: 30%;" appearance="fill">
              <mat-label>Country</mat-label>
              <mat-select (selectionChange)="getCountry($event)" formControlName="country" >
                <mat-option *ngFor="let c of countries" value="{{ c['country_name'] }}">{{ c["country_name"] }}
                </mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field appearance="fill" class="example-full-width" style="margin-left: 3%; width: 30%; margin-top: 1%;">
              <mat-label>Prant Name</mat-label>
              <input matInput formControlName="prant_name" [matAutocomplete]="prantname" />
              <button *ngIf="sub_names1" matSuffix mat-icon-button aria-label="Clear" (click)="reset_sub_names1()">
                <mat-icon>close</mat-icon>
              </button>
              <mat-autocomplete autoActiveFirstOption #prantname="matAutocomplete">
                <mat-option  *ngFor="let option of prantdata" [value]="option['prant_name']">
                  {{ option["prant_name"] }}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>

            <mat-form-field style="margin-left: 3%; margin-top: 1%;  width: 30%;" appearance="fill">
              <mat-label>State</mat-label>
              <mat-select (selectionChange)="getState($event)" formControlName="state">
                <mat-option *ngFor="let c of states" value="{{ c['state_name'] }}">{{ c["state_name"] }}
                </mat-option>
              </mat-select>
              <span>{{ selectedState }}</span>
            </mat-form-field>

            <!-- <mat-form-field appearance="fill" class="example-full-width" style=" margin-top: 1%; width: 30%; margin-left: 3%;">
              <mat-label>District</mat-label>
              <input matInput formControlName="district" (keyup)="searchDistrict($event)"
                [matAutocomplete]="auto_district" />
              <mat-autocomplete autoActiveFirstOption #auto_district="matAutocomplete">
                <mat-option (onSelectionChange)="selectDistrict($event, option)"
                  *ngFor="let option of filteredDistrictName" [value]="option['district_name']">
                  {{ option["district_name"] }}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field> -->
            <mat-form-field style=" margin-top: 1%; width: 30%;" appearance="fill">
              <mat-label>District</mat-label>
              <mat-select (selectionChange)="getDistricts($event)" formControlName="district">
                <mat-option *ngFor="let c of cities" value="{{ c['district_name'] }}">{{ c["district_name"] }}
                </mat-option>
              </mat-select>
              <span>{{ selectedCity }}</span>
            </mat-form-field>

            <!-- <div class="combobox">
                        (focusout)="hideSearch()" 
                        <input type="text" formControlName="district" (keyup)="searchDistrict($event)" />
                        <ul *ngIf="filteredDistrictName && showList">
                          <li *ngFor="let option of filteredDistrictName" (click)="selectDistrict(option)">
                            {{
                            option["district_name"]
                            }}
                          </li>
                        </ul>
                      </div> -->


            <!-- <mat-form-field appearance="fill" style=" margin-top: 1%; width: 30%;">
              <mat-label>Taluka</mat-label>
              <mat-select formControlName="taluka" (selectionChange)="getTalukaNames($event)">
                <mat-option *ngFor="let option of filteredTalukasName" [value]="option['taluka_name']">{{
                  option["taluka_name"]
                  }}</mat-option>
              </mat-select>
            </mat-form-field> -->

            <mat-form-field  style=" margin-top: 1%; width: 30%; margin-left: 3%;" appearance="fill">
              <mat-label>Taluka</mat-label>
              <mat-select formControlName="tal_name">
                <mat-option *ngFor="let c of talukas" value="{{ c['taluka_name'] }}">{{ c["taluka_name"] }}</mat-option>
              </mat-select>
              <span>{{ selectedTaluka }}</span>
            </mat-form-field>

            <mat-form-field class="example-full-width" appearance="fill" style="width: 30%; margin-left: 3%; margin-top: 1%;">
              <mat-label>Subscription Type</mat-label>
              <input matInput formControlName="duration" [matAutocomplete]="autodua" />
              <mat-autocomplete autoActiveFirstOption #autodua="matAutocomplete">
                <mat-option (onSelectionChange)="durationn($event, option)"
                  *ngFor="let option of subscriptiontype " [value]="option['subtype']">
                  {{ option["subtype"] }}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>

            <mat-form-field appearance="fill" style=" margin-top: 1%; width: 30%;">
              <mat-label>New/Renew</mat-label>
              <mat-select formControlName="active" (selectionChange)="getactive($event)">
                <!-- <div style="position: sticky; top: 0;background: #fff;z-index: 10;padding: 10px;"><input type="text"
                              style="width: 100%;height: 2rem;border-radius: .2rem;padding-left: 10px;" placeholder="search"
                              (change)="searchDistrict($event)" /></div> -->
                <!-- <mat-option *ngFor="let option of filteredTalukasName" [value]="option['taluka_name']">{{
                  option["taluka_name"]
                  }}</mat-option> -->
                  <mat-option value="active">New</mat-option>
                  <mat-option value="inactive">Renew</mat-option>
              </mat-select>
            </mat-form-field>

           
           
           
          </div>
        </form>
        <div style="display: flow-root;">
          <button style="margin-left: 2%;width: 250px ;" mat-raised-button color="primary" (click)="DateRepwise()" style="width: 250px;">
            Datewise/Representativewise
          </button>
          <button style="margin-left: 2%;width: 250px ;" mat-raised-button color="primary" (click)="Datewise()">
            Datewise
          </button>
          <button style="margin-left: 2%;width: 250px;" mat-raised-button color="primary" (click)="Monthwise()">
            Monthwise
          </button>
          <button style="width: 250px;margin-left: 2%; " mat-raised-button color="primary" (click)="offrepwise()">
            Office Representativewise
          </button>
          <button style="width: 250px; margin-top: 2%;" mat-raised-button color="primary" (click)="repwise()">
             Representativewise
          </button>
          <button style="width: 250px; margin-top: 2%;margin-left: 2%;" mat-raised-button color="primary" (click)="countrywise()">
            Countrywise
          </button>
          <button style="width: 250px; margin-top: 2%;margin-left: 2%;" mat-raised-button color="primary" (click)="prantwise()">
            Pranttwise
          </button>
          <button style="width: 250px; margin-top: 2%;margin-left: 2%;" mat-raised-button color="primary" (click)="statewise()">
            Statewise
          </button>
          <button style="width: 250px; margin-top: 2%;margin-left: 0%;" mat-raised-button color="primary" (click)="districtwise()">
            Districtwise
          </button>
          <button style="width: 250px; margin-top: 2%;margin-left: 2%;" mat-raised-button color="primary" (click)="districtandtalukawise()">
            Talukawise
          </button>
          <button style="width: 250px; margin-top: 2%;margin-left: 2%;" mat-raised-button color="primary" (click)="typewiseawise()">
            Typewise
          </button>
          <button style="width: 250px; margin-top: 2%;margin-left: 2%;" mat-raised-button color="primary" (click)="DateRepwise1()">
            New/Renew
          </button>
        
        </div>
     
      </mat-card>

      <!-- <mat-card>
        <mat-form-field appearance="fill" style="margin-top: 1%; margin-right: 2%">
          <mat-label>Enter a date range</mat-label>
          <mat-date-range-input [formGroup]="datewiseForm" [rangePicker]="pickerdatewise">
            <input matStartDate formControlName="start" placeholder="Start date" />
            <input matEndDate formControlName="end" placeholder="End date" (dateChange)="getChangedDateDatewise()" />
          </mat-date-range-input>
          <mat-datepicker-toggle matSuffix [for]="pickerdatewise"></mat-datepicker-toggle>
          <mat-date-range-picker #pickerdatewise></mat-date-range-picker>

          <mat-error *ngIf="datewiseForm.controls.start.hasError('matStartDateInvalid')">Invalid start date
          </mat-error>
          <mat-error *ngIf="datewiseForm.controls.end.hasError('matEndDateInvalid')">Invalid end date
          </mat-error>
        </mat-form-field>
        <button mat-raised-button color="primary" (click)="Datewise()">
          Datewise
        </button>
      </mat-card> -->
      <!-- <mat-card>
        <button mat-raised-button color="primary" style="margin-left: 2%;"
          (click)="datewiseVarganidarRojmelReport()">Datetwise
          Varganidar Rojmel Report</button>
      </mat-card> -->

      <!-- <mat-card>
        <mat-form-field appearance="fill" style="margin-top: 1%; margin-right: 2%">
          <mat-label>Enter a date range</mat-label>
          <mat-date-range-input [formGroup]="monthwiseForm" [rangePicker]="pickermonthwise">
            <input matStartDate formControlName="start" placeholder="Start date" />
            <input matEndDate formControlName="end" placeholder="End date" (dateChange)="getChangedDateMonthwise()" />
          </mat-date-range-input>
          <mat-datepicker-toggle matSuffix [for]="pickermonthwise"></mat-datepicker-toggle>
          <mat-date-range-picker #pickermonthwise></mat-date-range-picker>

          <mat-error *ngIf="monthwiseForm.controls.start.hasError('matStartDateInvalid')">Invalid start date
          </mat-error>
          <mat-error *ngIf="monthwiseForm.controls.end.hasError('matEndDateInvalid')">Invalid end date
          </mat-error>
        </mat-form-field>
        <button mat-raised-button color="primary" (click)="Monthwise()">
          Monthwise
        </button>
      </mat-card> -->

      <!-- <mat-card>
        <form [formGroup]="offrepForm">
          <mat-form-field appearance="fill" style="margin-top: 1%">
            <mat-label>Enter a date range</mat-label>
            <mat-date-range-input [rangePicker]="pickerdateoffrep">
              <input matStartDate formControlName="start" placeholder="Start date" />
              <input matEndDate formControlName="end" placeholder="End date" (dateChange)="getChangedDateOffRep()" />
            </mat-date-range-input>
            <mat-datepicker-toggle matSuffix [for]="pickerdateoffrep"></mat-datepicker-toggle>
            <mat-date-range-picker #pickerdateoffrep></mat-date-range-picker>
          </mat-form-field>
          <mat-form-field appearance="fill" class="example-full-width" style="margin-left: 2%; margin-top: 1%">
            <mat-label>Office Representative Name</mat-label>
            <input matInput formControlName="off_rep_name" [matAutocomplete]="autooff_rep_name" />
            <button *ngIf="off_rep_name1" matSuffix mat-icon-button aria-label="Clear" (click)="reset_off_rep_name()">
              <mat-icon>close</mat-icon>
            </button>
            <mat-autocomplete autoActiveFirstOption #autooff_rep_name="matAutocomplete">
              <mat-option (onSelectionChange)="getoff_rep_name($event, option)"
                *ngFor="let option of filteredOptions22 | async" [value]="option['office_rep_name']">
                {{ option["office_rep_name"] }}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
        </form>
        <button mat-raised-button color="primary" (click)="offrepwise()">
          Office Representativewise
        </button>
      </mat-card> -->

      <!-- <mat-card>

        <button mat-raised-button color="primary" (click)="renewablereceiptdata()">
          Renew Remaining data
        </button>

        <button mat-raised-button color="primary" style="margin-left: 2%" (click)="repwiseAddress()">
          Representativewise Address List
        </button>
      </mat-card> -->
    </mat-tab>

    <!-- <mat-tab label="Subscription Business Report">
      <mat-form-field appearance="fill" style="margin-top: 5%">
        <mat-label>Enter a date range</mat-label>
        <mat-date-range-input [formGroup]="rangerepwisereport" [rangePicker]="picker2">
          <input matStartDate formControlName="start" placeholder="Start date" />
          <input matEndDate formControlName="end" placeholder="End date" (dateChange)="getRepwiseReport()" />
        </mat-date-range-input>
        <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
        <mat-date-range-picker #picker2></mat-date-range-picker>

        <mat-error *ngIf="
            rangerepwisereport.controls.start.hasError('matStartDateInvalid')
          ">Invalid start date
        </mat-error>
        <mat-error *ngIf="rangerepwisereport.controls.end.hasError('matEndDateInvalid')">Invalid end date
        </mat-error>
      </mat-form-field>

      <mat-form-field appearance="fill" style="margin-left: 3%">
        <mat-label>Representative Name</mat-label>
        <mat-select name="rep" (selectionChange)="getSelectedRepdetails($event)">
          <mat-option *ngFor="let rep of getAllRepList" [value]="rep['id']">
            {{ rep["name"] }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <button style="margin-left: 3%" (click)="getRepWiseReport1()" mat-raised-button color="primary">
        Get Report
      </button>

      <div class="add-new-subscriber mat-elevation-z4" style="overflow-x: scroll !important" id="excel-table">
        <div style="margin-top: 20px" class="tableFixHead">
          <button type="button" (click)="exportexcel('Subscribers_Bussiness_report')" class="btn btn-danger">
            Export
          </button>
          <table class="table table-striped text-center">
            <thead>
              <tr>
                <th>अ.क्र.</th>
                <th>प्रतिनिधींचे नाव</th>
                <th>विभाग</th>
                <th>ठिकाण</th>
                <th>मासिकाचे नाव</th>
                <th>Plan</th>
                <th>ग्राहक संख्या</th>
                <th>रक्कम</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let book of this.repwiseReport; let i = index">
                <td>{{ i + 1 }}</td>
                <td>{{ book["rep_name"] }}</td>
                <td>{{ book["subcity"] }}</td>
                <td>{{ book["taluka"] }}</td>
                <td>{{ book["magname"] }}</td>
                <td>{{ book["Plan"] }}</td>
                <td>{{ book["cust_count"] }}</td>
                <td>{{ book["totalamount"] }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </mat-tab> -->

    <!-- <mat-tab label="Subscription Renewal Report">
      <mat-card>
        <form [formGroup]="subRenewalReportForm">
          <div class="form-row">
            <mat-form-field appearance="fill" style="margin-top: 1%; width: 30%;">
              <mat-label>Select a date range</mat-label>
              <mat-date-range-input [rangePicker]="pickersubrenewaldate">
                <input matStartDate formControlName="start" placeholder="Start date" />
                <input matEndDate formControlName="end" placeholder="End date"
                  (dateChange)="getChangedDateofSubRenewalReport()" />
              </mat-date-range-input>
              <mat-datepicker-toggle matSuffix [for]="pickersubrenewaldate"></mat-datepicker-toggle>
              <mat-date-range-picker #pickersubrenewaldate></mat-date-range-picker>
            </mat-form-field>
            <mat-form-field appearance="fill" style="margin-left: 3%; margin-top: 1%; width: 30%;">
              <mat-label>Representative Name</mat-label>
              <mat-select formControlName="rep_name" (selectionChange)="getsubRenewalrepnames($event)" multiple>
                <mat-option *ngFor="let option of filtereddaterepwiserep_name" [value]="option['rep_name']">{{
                  option["rep_name"]
                  }}</mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field appearance="fill" class="example-full-width" style="margin-left: 3%; margin-top: 1%; width: 30%;">
              <mat-label>Office Representative Name</mat-label>
              <input matInput formControlName="off_rep_name" [matAutocomplete]="autooff_rep_name" />
              <mat-autocomplete autoActiveFirstOption #autooff_rep_name="matAutocomplete" multiple>
                <mat-option (onSelectionChange)="getsubRenewaloff_rep_name($event, option)"
                  *ngFor="let option of filteredOptions22 | async" [value]="option['office_rep_name']">
                  {{ option["office_rep_name"] }}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
          </div>
          <div class="form-row">
            <mat-form-field appearance="fill" class="example-full-width" style=" margin-top: 1%; width: 30%;">
              <mat-label>District</mat-label>
              <input matInput formControlName="district" (keyup)="searchDistrict($event)"
                [matAutocomplete]="auto_district" />
              <mat-autocomplete autoActiveFirstOption #auto_district="matAutocomplete">
                <mat-option (onSelectionChange)="getsubRenewalDistrict($event, option)"
                  *ngFor="let option of filteredDistrictName" [value]="option['district_name']">
                  {{ option["district_name"] }}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>

            <mat-form-field appearance="fill" style="margin-left: 3%; margin-top: 1%; width: 30%;">
              <mat-label>Taluka</mat-label>
              <mat-select formControlName="taluka" (selectionChange)="getsubRenewalTalukaNames($event)">
                <mat-option *ngFor="let option of filteredTalukasName" [value]="option['taluka_name']">{{
                  option["taluka_name"]
                  }}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </form>
        <div style="display: flow-root;">
          <button mat-raised-button color="primary" (click)="renewablereceiptdata()">
            Renew Remaining data
          </button>
          <button style="margin-left: 2%;width: 340px;" mat-raised-button color="primary" (click)="subrenewalletter()">
            Subscription Renewal Remainder Letter
          </button>
        </div>
      
      </mat-card>

    </mat-tab> -->
    <mat-tab label="Print Order">
      
      <mat-card>
        <button mat-raised-button color="primary" style="margin-left: 2%;width: 150px;" (click)="printOrder()">Print Order</button>

        <div class="tableFixHead" style="margin-top: 2%;">
          <table class="table table-bordered " >
            <thead>
              <tr>
                <th>आदेश क्र</th>
                <th>आदेश दिनांक</th>
                <th>अंक दिनांक...ते</th>
                <th>एकूण प्रती</th>
                <th>एकूण पृष्ठ</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of printOrderData">
                <td>{{ item['order_id'] }}</td>
                <td>{{ item['order_date'] }}</td>
                <td>{{ item['issue_date_from'] | date:"dd-MM-yyyy" }} ते {{ item['issue_date_to'] | date:"dd-MM-yyyy" }}
                </td>
                <td>{{ item['total_prati'] }}</td>
                <td>{{ item['total_prustha'] }}</td>
                <td><button mat-icon-button type="button" class="btn-sm btn-outline-primary" matTooltip="View"
                    (click)="viewPrintOrder(item)"><mat-icon>visibility</mat-icon></button>
                  <button mat-icon-button type="button" class="btn-sm btn-outline-info" matTooltip="Update"
                    (click)="updatePrintOrder(item)"><mat-icon>edit</mat-icon></button>
                  <button mat-icon-button class="btn-sm btn-outline-danger" matTooltip="Delete" style="margin-left: 2%;"
                    (click)="cancelPrintOrder(item['order_id'])"><mat-icon>delete</mat-icon></button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </mat-card>

      <mat-card>
        <button mat-raised-button color="primary" style="margin-left: 2%;width:100px" (click)="printJavak()">जावक</button>

        <!-- <button mat-raised-button color="primary" style="margin-left: 2%;width:100px" (click)="printchallan()">challan</button> -->

      </mat-card>

      <mat-card>
        <div class="row">
          <div class="col-md-4 col-12">
            <button mat-raised-button color="primary" style="margin-left: 2%;width:250px; height: 50px;" (click)="distSubReport()">Districtwise
              Subscription Report</button>
          </div>
          <div class="col-md-4 col-12">
            <button mat-raised-button color="primary" style="margin-left: 2%;width:250px; height: 50px;" (click)="distVSubReport()">Districtwise
              Varganidar
              Subscription Report</button>
          </div>
          <div class="col-md-4 col-12">
            <button mat-raised-button color="primary" style="margin-left: 2%;width:250px; height: 50px;" (click)="prantwiseReport()">Prantwise
              Report</button>
          </div>
        </div>
      </mat-card>

      <!-- <mat-card>
        <div class="tableFixHead" id="excel-table">
          <button type="button" mat-raised-button matTooltip="Download Execelsheet" class="btn btn-danger"
            (click)="exportexcel('Districtwise_List')">Export</button>
          <table class="table table-striped text-center">
            <thead>
              <tr>
                <th>District Name</th>
                <th>Subscriber Total</th>
                <th>Parcel Total</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of filtereddistrictwisesubcout;">
                <td>{{ item['district'] }}</td>
                <td>{{ item['sub'] }}</td>
                <td></td>
              </tr>
              <tr style="font-weight: 700;">
                <td>Total</td>
                <td>{{ total_sub_count }}</td>
                <td></td>
              </tr>
            </tbody>
          </table>
        </div>
      </mat-card> -->

      <!-- <mat-card>
        <div class="tableFixHead" id="excel-table1">
          <button mat-raised-button matTooltip="Download Execelsheet" class="btn btn-danger"
            (click)="exportexcel1('Pincodewise_List')">Export</button>
          <table class="table table-striped text-center">
            <thead>
              <tr>
                <th>Pincode No</th>
                <th>Subscriber Total</th>
                <th>Parcel Total</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of filteredpincodewisesubcout">
                <td>{{ item['pincode'] }}</td>
                <td>{{ item['sub'] }}</td>
                <td></td>
              </tr>
              <tr style="font-weight: 700;">
                <td>Total</td>
                <td>{{ total_pin_sub_count }}</td>
                <td></td>
              </tr>
            </tbody>
          </table>
        </div>
      </mat-card> -->

      <!-- <div class="add-new-subscriber mat-elevation-z4" style="overflow-x: scroll !important">
        <div style="margin-top: 20px" class="tableFixHead"> -->
      <!-- <button type="button" (click)="PrintData()" class="btn btn-danger">
            Print
          </button> -->
      <!-- <table style="table-layout: fixed; width: 210mm; height: 297mm" class="table table-striped text-center"
            id="excel-table">
            <tbody>
              <tr *ngFor="let book of this.labelDetails; let i = index">
                <td style="
                    width: 20mm;
                    height: 39mm;
                    padding: 5px;
                    font-size: smaller;
                  ">
                  {{ book["data1"]["name"] }}<br />{{ book["data1"]["address"]
                  }}<br />{{ book["data1"]["taluka"] }}<br />{{
                  book["data1"]["district"]
                  }}<br />{{ book["data1"]["state"] }}
                </td>
                <td style="
                    width: 20mm;
                    height: 39mm;
                    padding: 5px;
                    font-size: smaller;
                  ">
                  {{ book["data2"]["name"] }}<br />{{ book["data2"]["address"]
                  }}<br />{{ book["data2"]["taluka"] }}<br />{{
                  book["data2"]["district"]
                  }}<br />{{ book["data2"]["state"] }}
                </td>
                <td style="
                    width: 20mm;
                    height: 39mm;
                    padding: 5px;
                    font-size: smaller;
                  ">
                  {{ book["data3"]["name"] }}<br />{{ book["data3"]["address"]
                  }}<br />{{ book["data3"]["taluka"] }}<br />{{
                  book["data3"]["district"]
                  }}<br />{{ book["data3"]["state"] }}
                </td>
                <td style="
                    width: 20mm;
                    height: 39mm;
                    padding: 5px;
                    font-size: smaller;
                  ">
                  {{ book["data4"]["name"] }}<br />{{ book["data4"]["address"]
                  }}<br />{{ book["data4"]["taluka"] }}<br />{{
                  book["data4"]["district"]
                  }}<br />{{ book["data4"]["state"] }}
                </td>
              </tr>
            </tbody>
          </table> -->
      <!-- <table style="table-layout: fixed; width: 210mm; height: 297mm;" class="table table-borderedless text-center"
            id="excel-table">
            <tbody>
              <div *ngFor="let item of labelsforDispatch">
                <td style="
                    width: 20mm;
                    height: 39mm;
                    padding: 5px;
                    font-size: smaller;
                  ">
                  {{ item["name"] }}<br />{{ item["address"]
                  }}<br />{{ item["taluka"] }}<br />{{
                  item["district"]
                  }}<br />{{ item["state"] }}
                </td>
              </div>
            </tbody>
          </table> -->
      <!-- <div class="container" id="excel-table">
            <div class="row">
              <div class="col-3" *ngFor="let item of labelsforDispatch" style="margin-bottom: 1.5%">
                <span style="font-size: 20px">{{ item["name"] }}</span><br />
                <span style="font-size: 20px">{{ item["address"] }} {{ item["taluka"] }}
                  {{ item["district"] }}</span><br />
                <span style="font-size: 20px">{{ item["state"] }} </span>
                <span style="font-size: 20px">{{ item["country"] }} </span>
                <span style="font-size: 20px" *ngIf="item['pincode'] != 0">- {{ item["pincode"] }}</span><br
                  *ngIf="item['pincode'] != 0" />
                <span style="font-size: 20px" *ngIf="item['mob_no'] != 0">{{
                  item["mob_no"]
                  }}</span>
              </div>
            </div>
          </div> -->
      <!-- </div>
      </div> -->
    </mat-tab>
    <!-- new tab -->

    <mat-tab label="Posting Label & Statement">
      <form [formGroup]="generateform">
        <div class="form-row">
          <mat-form-field style="margin-left: 7%; margin-top: 20px; width: 25%" appearance="outline">
            <mat-label>Issue/Grantha Category : </mat-label>
            <mat-select>
              <mat-option *ngFor="let item of advtCategorylist" value="{{ item['category'] }}">
                {{ item["category"] }}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field appearance="outline" style="margin-left: 3%; width: 25%; margin-top: 20px">
            <mat-label>Select issue date</mat-label>
            <input matInput [matDatepickerFilter]="myFilter" formControlName="issue_date" [matDatepicker]="pickerissue"
              (dateChange)="getIssueDate($event)">
            <mat-datepicker-toggle matSuffix [for]="pickerissue"></mat-datepicker-toggle>
            <mat-datepicker #pickerissue></mat-datepicker>
          </mat-form-field>

          <mat-form-field style="margin-left: 3%; margin-top: 20px; width: 25%" appearance="outline">
            <mat-label>Select Published date</mat-label>
            <input matInput required formControlName="publisheddate" [matDatepicker]="pickerpubdate" />
            <mat-datepicker-toggle matSuffix [for]="pickerpubdate"></mat-datepicker-toggle>
            <mat-datepicker #pickerpubdate></mat-datepicker>
          </mat-form-field>
        </div>
        <div class="form-row">
          <mat-form-field appearance="outline" style="margin-left: 7%; width: 25%">
            <mat-label>Enter a date from...to </mat-label>
            <mat-date-range-input [rangePicker]="pickerbrr">
              <input matStartDate formControlName="start" placeholder="Start date" />
              <input matEndDate formControlName="end" placeholder="End date" (dateChange)="getBookReceiptDateRange()" />
            </mat-date-range-input>
            <mat-datepicker-toggle matSuffix [for]="pickerbrr"></mat-datepicker-toggle>
            <mat-date-range-picker #pickerbrr></mat-date-range-picker>

            <mat-error *ngIf="
                generateform.controls.start.hasError('matStartDateInvalid')
              ">Invalid start date</mat-error>
            <mat-error *ngIf="generateform.controls.end.hasError('matEndDateInvalid')">Invalid end date</mat-error>
          </mat-form-field>
          <mat-form-field appearance="outline" style="width: 25%; margin-left: 3%">
            <mat-label>Title</mat-label>
            <mat-select #mySel11 formControlName="title" (selectionChange)="get_titles($event)" multiple>
              <mat-option value="all" (click)="toggleAllSelection11()">All</mat-option>
              <mat-option *ngFor="let option of LabelsDispatchTitles" [value]="option['title']">{{
                option["title"]
                }}</mat-option>
            </mat-select>
            <button *ngIf="title1" matSuffix mat-icon-button aria-label="Clear" (click)="reset_titles()">
              <mat-icon>close</mat-icon>
            </button>
          </mat-form-field>
          <mat-form-field appearance="outline" style="width: 25%; margin-left: 3%">
            <mat-label>Subscriber Name</mat-label>
            <mat-select #mySel1 formControlName="subname" (selectionChange)="getsubnames($event)" multiple>
              <mat-option value="all" (click)="toggleAllSelection1()">All</mat-option>
              <mat-option *ngFor="let option of LabelsDispatchSubnames" [value]="option['sub_name']">{{
                option["sub_name"]
                }}</mat-option>
            </mat-select>
            <button *ngIf="sub_names" matSuffix mat-icon-button aria-label="Clear" (click)="reset_sub_names()">
              <mat-icon>close</mat-icon>
            </button>
          </mat-form-field>
        </div>
        <!-- <div class="form-row">
          <mat-form-field appearance="outline" style="width: 25%; margin-left: 7%">
            <mat-label>Title</mat-label>
            <mat-select #mySel11 formControlName="title" (selectionChange)="get_titles($event)" multiple>
              <mat-option value="all" (click)="toggleAllSelection11()">All</mat-option>
              <mat-option *ngFor="let option of LabelsDispatchTitles" [value]="option['title']">{{
                option["title"]
                }}</mat-option>
            </mat-select>
            <button *ngIf="title1" matSuffix mat-icon-button aria-label="Clear" (click)="reset_titles()">
              <mat-icon>close</mat-icon>
            </button>
          </mat-form-field>
        </div> -->
        <!-- <div class="form-row">
          <mat-form-field appearance="outline" style="width: 25%; margin-left: 3%">
            <mat-label>Subscriber Name</mat-label>
            <mat-select #mySel1 formControlName="subname" (selectionChange)="getsubnames($event)" multiple>
              <mat-option value="all" (click)="toggleAllSelection1()">All</mat-option>
              <mat-option *ngFor="let option of LabelsDispatchSubnames" [value]="option['sub_name']">{{
                option["sub_name"]
                }}</mat-option>
            </mat-select>
            <button *ngIf="sub_names" matSuffix mat-icon-button aria-label="Clear" (click)="reset_sub_names()">
              <mat-icon>close</mat-icon>
            </button>
          </mat-form-field>
        </div> -->
        <div class="form-row">
          <mat-form-field appearance="outline" style="width: 25%; margin-left: 7%">
            <mat-label>Subscriber Type</mat-label>
            <mat-select #mySel2 formControlName="subtype" (selectionChange)="getsubtypes($event)" multiple>
              <!-- <mat-option value="all" (click)="toggleAllSelection2()">All</mat-option> -->
              <mat-option *ngFor="let option of LabelsDispatchSubtypes" [value]="option['sub_type']">{{
                option["sub_type"]
                }}</mat-option>
            </mat-select>
            <button *ngIf="sub_types" matSuffix mat-icon-button aria-label="Clear" (click)="reset_sub_types()">
              <mat-icon>close</mat-icon>
            </button>
          </mat-form-field>
          <mat-form-field appearance="outline" style="width: 25%; margin-left: 3%">
            <mat-label>Country Name</mat-label>
            <mat-select #mySel3 formControlName="countryname"
              (selectionChange)="getCountry($event); getcountryname($event)" multiple>
              <!-- <mat-option value="all" (click)="toggleAllSelection3()">All</mat-option> -->
              <!-- *ngFor="let option of LabelsDispatchCountryName" getcountryname($event) -->
              <mat-option *ngFor="let option of countries" [value]="option['country_name']">{{
                option["country_name"]
                }}</mat-option>
            </mat-select>
            <button *ngIf="country_names" matSuffix mat-icon-button aria-label="Clear" (click)="reset_country_names()">
              <mat-icon>close</mat-icon>
            </button>
          </mat-form-field>
          <mat-form-field appearance="outline" style="width: 25%; margin-left: 3%">
            <mat-label>State Name</mat-label>
            <mat-select #mySel4 formControlName="statename" (selectionChange)="getstatename($event)" multiple>
              <!-- <mat-option value="all" (click)="toggleAllSelection4()">All</mat-option> -->
              <!-- LabelsDispatchStateName getstatename()-->
              <mat-option *ngFor="let option of states" [value]="option['state_name']">{{
                option["state_name"]
                }}</mat-option>
            </mat-select>
            <button *ngIf="state_names" matSuffix mat-icon-button aria-label="Clear" (click)="reset_state_names()">
              <mat-icon>close</mat-icon>
            </button>
          </mat-form-field>
        </div>
        <!-- <div class="form-row">
          <mat-form-field appearance="outline" style="width: 25%; margin-left: 3%">
            <mat-label>Country Name</mat-label>
            <mat-select #mySel3 formControlName="countryname" (selectionChange)="getcountryname($event)" multiple>
              <mat-option value="all" (click)="toggleAllSelection3()">All</mat-option>
              <mat-option *ngFor="let option of LabelsDispatchCountryName" [value]="option['country_name']">{{
                option["country_name"]
                }}</mat-option>
            </mat-select>
            <button *ngIf="country_names" matSuffix mat-icon-button aria-label="Clear" (click)="reset_country_names()">
              <mat-icon>close</mat-icon>
            </button>
          </mat-form-field>
        </div> -->
        <!-- <div class="form-row">
          <mat-form-field appearance="outline" style="width: 25%; margin-left: 3%">
            <mat-label>State Name</mat-label>
            <mat-select #mySel4 formControlName="statename" (selectionChange)="getstatename($event)" multiple>
              <mat-option value="all" (click)="toggleAllSelection4()">All</mat-option>
              <mat-option *ngFor="let option of LabelsDispatchStateName" [value]="option['state_name']">{{
                option["state_name"]
                }}</mat-option>
            </mat-select>
            <button *ngIf="state_names" matSuffix mat-icon-button aria-label="Clear" (click)="reset_state_names()">
              <mat-icon>close</mat-icon>
            </button>
          </mat-form-field>
        </div> -->
        <div class="form-row">
          <mat-form-field appearance="outline" style="width: 25%; margin-left: 7%">
            <mat-label>District Name</mat-label>
            <mat-select #mySel5 formControlName="districtname"
              (selectionChange)="getDistricts($event); getdistrictname($event)" multiple>
              <!-- <mat-option value="all" (click)="toggleAllSelection5()">All</mat-option> -->
              <!-- LabelsDispatchDistrictName getdistrictname() -->
              <mat-option *ngFor="let option of cities" [value]="option['district_name']">{{
                option["district_name"]
                }}</mat-option>
            </mat-select>
            <button *ngIf="district_names" matSuffix mat-icon-button aria-label="Clear"
              (click)="reset_district_names()">
              <mat-icon>close</mat-icon>
            </button>
          </mat-form-field>
          <mat-form-field appearance="outline" style="width: 25%; margin-left: 3%">
            <mat-label>Taluka Name</mat-label>
            <mat-select #mySel6 formControlName="talukaname" (selectionChange)="gettalukaname($event)" multiple>
              <mat-option value="ALL" (click)="toggleAllSelection6()">All</mat-option>
              <!-- LabelsDispatchTalukaName -->
              <mat-option *ngFor="let option of talukas" [value]="option['taluka_name']">{{
                option["taluka_name"]
                }}</mat-option>
            </mat-select>
            <button *ngIf="taluka_names" matSuffix mat-icon-button aria-label="Clear" (click)="reset_taluka_names()">
              <mat-icon>close</mat-icon>
            </button>
          </mat-form-field>
          <mat-form-field appearance="outline" style="width: 25%; margin-left: 3%">
            <mat-label>Pincode</mat-label>
            <mat-select #mySel7 formControlName="pincode" (selectionChange)="getpincode($event)" multiple>
              <!-- <mat-option value="all" (click)="toggleAllSelection7()">ALL</mat-option> -->
              <mat-option *ngFor="let option of pindcodelist" [value]="option['pincode']">{{
                option["pincode"]
                }}</mat-option>
            </mat-select>
            <button *ngIf="pincodes" matSuffix mat-icon-button aria-label="Clear" (click)="reset_pincodes()">
              <mat-icon>close</mat-icon>
            </button>
          </mat-form-field>
        </div>
        <!-- <div class="form-row">
          <mat-form-field appearance="outline" style="width: 25%; margin-left: 3%">
            <mat-label>Taluka Name</mat-label>
            <mat-select #mySel6 formControlName="talukaname" (selectionChange)="gettalukaname($event)" multiple>
              <mat-option value="all" (click)="toggleAllSelection6()">All</mat-option>
              <mat-option *ngFor="let option of LabelsDispatchTalukaName" [value]="option['taluka_name']">{{
                option["taluka_name"]
                }}</mat-option>
            </mat-select>
            <button *ngIf="taluka_names" matSuffix mat-icon-button aria-label="Clear" (click)="reset_taluka_names()">
              <mat-icon>close</mat-icon>
            </button>
          </mat-form-field>
        </div> -->
        <!-- <div class="form-row">
          <mat-form-field appearance="outline" style="width: 25%; margin-left: 3%">
            <mat-label>Pincode</mat-label>
            <mat-select #mySel7 formControlName="pincode" (selectionChange)="getpincode($event)" multiple>
              <mat-option value="all" (click)="toggleAllSelection7()">All</mat-option>
              <mat-option *ngFor="let option of LabelsDispatchPincode" [value]="option['pincode']">{{
                option["pincode"]
                }}</mat-option>
            </mat-select>
            <button *ngIf="pincodes" matSuffix mat-icon-button aria-label="Clear" (click)="reset_pincodes()">
              <mat-icon>close</mat-icon>
            </button>
          </mat-form-field>
        </div> -->
        <div class="form-row">
          <mat-form-field appearance="outline" style="width: 25%; margin-left: 7%">
            <mat-label>Representative</mat-label>
            <mat-select #mySel8 formControlName="rep" (selectionChange)="getrep($event)" multiple>
              <mat-option value="all" (click)="toggleAllSelection8()">All</mat-option>
              <mat-option *ngFor="let option of LabelsDispatchRepNames" [value]="option['rep_name']">{{
                option["rep_name"]
                }}</mat-option>
            </mat-select>
            <button *ngIf="repnames" matSuffix mat-icon-button aria-label="Clear" (click)="reset_repnames()">
              <mat-icon>close</mat-icon>
            </button>
          </mat-form-field>
          <mat-form-field appearance="outline" style="width: 25%; margin-left: 3%">
            <mat-label>Office Representative</mat-label>
            <mat-select #mySel9 formControlName="officerep" (selectionChange)="getofficerep($event)" multiple>
              <mat-option value="all" (click)="toggleAllSelection9()">All</mat-option>
              <mat-option *ngFor="let option of LabelsDispatchOffRepNames" [value]="option['off_rep_name']">{{
                option["off_rep_name"]
                }}</mat-option>
            </mat-select>
            <button *ngIf="officerepnames" matSuffix mat-icon-button aria-label="Clear"
              (click)="reset_office_rep_names()">
              <mat-icon>close</mat-icon>
            </button>
          </mat-form-field>
          <mat-form-field appearance="outline" style="width: 25%; margin-left: 3%">
            <mat-label>Delivery Mode</mat-label>
            <mat-select #mySel10 formControlName="delivery_mode_name" (selectionChange)="getmode_name($event)" multiple>
              <mat-option value="all" (click)="toggleAllSelection10()">All</mat-option>
              <mat-option *ngFor="let option of LabelsDispatchDeliveryModes" [value]="option['mode_name']">{{
                option["mode_name"]
                }}</mat-option>
            </mat-select>
            <button *ngIf="mode_names" matSuffix mat-icon-button aria-label="Clear"
              (click)="reset_delivery_mode_names()">
              <mat-icon>close</mat-icon>
            </button>
          </mat-form-field>
        </div>
        <!-- <div class="form-row">
          <mat-form-field appearance="outline" style="width: 25%; margin-left: 3%">
            <mat-label>Office Representative</mat-label>
            <mat-select #mySel9 formControlName="officerep" (selectionChange)="getofficerep($event)" multiple>
              <mat-option value="all" (click)="toggleAllSelection9()">All</mat-option>
              <mat-option *ngFor="let option of LabelsDispatchOffRepNames" [value]="option['off_rep_name']">{{
                option["off_rep_name"]
                }}</mat-option>
            </mat-select>
            <button *ngIf="officerepnames" matSuffix mat-icon-button aria-label="Clear"
              (click)="reset_office_rep_names()">
              <mat-icon>close</mat-icon>
            </button>
          </mat-form-field>
        </div> -->
        <!-- <div class="form-row">
          <mat-form-field appearance="outline" style="width: 25%; margin-left: 3%">
            <mat-label>Delivery Mode</mat-label>
            <mat-select #mySel10 formControlName="delivery_mode_name" (selectionChange)="getmode_name($event)" multiple>
              <mat-option value="all" (click)="toggleAllSelection10()">All</mat-option>
              <mat-option *ngFor="let option of LabelsDispatchDeliveryModes" [value]="option['mode_name']">{{
                option["mode_name"]
                }}</mat-option>
            </mat-select>
            <button *ngIf="mode_names" matSuffix mat-icon-button aria-label="Clear"
              (click)="reset_delivery_mode_names()">
              <mat-icon>close</mat-icon>
            </button>
          </mat-form-field>
        </div> -->
        <div class="form-row">
          <mat-form-field appearance="outline" style="width: 25%; margin-left: 7%">
            <mat-label>Show Mobile No. in Labels</mat-label>
            <mat-select (selectionChange)="getmob_no($event)">
              <mat-option value="yes">Yes</mat-option>
              <mat-option value="no">No</mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field appearance="outline" style="width: 25%; margin-left: 3%">
            <mat-label>Show Customer No</mat-label>
            <mat-select  (selectionChange)="getIsCustomer($event)">
              <mat-option  value="yes">Yes</mat-option>
              <mat-option value="no">No</mat-option>
            </mat-select>
          </mat-form-field>
          <div style="margin-left: 3%">
            <button *ngIf="!show_mob_no_hide" mat-raised-button color="primary" style="margin-left: 3%;padding-top: 31px;padding-bottom: 21px;"
              (click)="getAddressLabels()">Districtwise Posting Address Label</button>
            <button *ngIf="show_mob_no_hide" mat-raised-button color="primary" style="margin-left: 3%;margin-top: 2%;"
              (click)="getAddressLabelswithMobileNo()">
              Districtwise Posting Address Label
            </button>
          </div>
        </div>
        <!-- <div class="form-group">
          <button style="margin-left: 10%; width: 15%" (click)="generateLabel()" mat-raised-button color="primary">
            Get Report
          </button>
        </div> -->
      </form>
      <!-- <button style="margin-left: 10%;" (click)="getReport()" mat-raised-button color="primary">
        Get Report
      </button> -->


      <!-- <div>
        <button *ngIf="!show_mob_no_hide" mat-raised-button color="primary" style="margin-left: 10%;margin-top: 2%;"
          (click)="getAddressLabels()">Address
          Districtwise</button>
        <button *ngIf="show_mob_no_hide" mat-raised-button color="primary" style="margin-left: 10%;margin-top: 2%;"
          (click)="getAddressLabelswithMobileNo()">
          Address
          Districtwise
        </button>
      </div> -->

      <mat-card>
        <div style="text-align: center;
    font-size: 2rem;
    font-weight: 500;">Post Statement</div>
        <div class="form-row">
          <mat-form-field appearance="outline" style="margin-left: 3%; width: 25%; margin-top: 20px">
            <mat-label>Select Dispatch Date</mat-label>
            <input matInput [matDatepicker]="pickerdispatch" (dateChange)="getDispatchDate($event)">
            <mat-datepicker-toggle matSuffix [for]="pickerdispatch"></mat-datepicker-toggle>
            <mat-datepicker #pickerdispatch></mat-datepicker>
          </mat-form-field>
        </div>
        <div class="form-row">
          <button mat-raised-button color="primary" style="margin-left: 3%;margin-top: 2%;"
            (click)="getDistrictwiseStatement()">Districtwise Statement</button>
            <button mat-raised-button color="primary" style="margin-left: 3%;margin-top: 2%;"
            (click)="getStatewiseStatement()">Statewise Statement</button>
          <button mat-raised-button color="primary" style="margin-left: 2%;margin-top: 2%;"
            (click)="getPincodewiseStatement()">Pincodewise Statement</button>
          <button mat-raised-button color="primary" style="margin-left: 2%;margin-top: 2%;"
            (click)="getOtherStatePincodewiseStatement()">Other State Pincodewise Statement</button>
        </div>
      </mat-card>

      <!-- <mat-card>
        <mat-form-field appearance="outline" style="margin-left: 3%; width: 25%; margin-top: 20px">
          <mat-label>Select Dispatch Date</mat-label>
          <input matInput [matDatepicker]="pickerdispatch1" (dateChange)="getDispatchDate($event)">
          <mat-datepicker-toggle matSuffix [for]="pickerdispatch1"></mat-datepicker-toggle>
          <mat-datepicker #pickerdispatch1></mat-datepicker>
        </mat-form-field>
        <button mat-raised-button color="primary" style="margin-left: 2%;margin-top: 2%;"
          (click)="getDistrictwiseStatement()">Districtwise Statement</button>
      </mat-card> -->

      <mat-card>
        <div style="text-align: center;
            font-size: 2rem;
            font-weight: 500;margin-bottom: 1rem;">Hand Delivery Statement</div>
        <!-- <div class="form-row"> -->
        <form [formGroup]="distributionForm">
          <div class="form-row">
            <mat-form-field appearance="outline" style="width: 25%; margin-left: 7%">
              <mat-label>Distribution Representative</mat-label>
              <mat-select #mySel12 formControlName="disrep" (selectionChange)="getdistributionrep($event)" multiple>
                <mat-option value="ALL" (click)="toggleAllSelection()">All</mat-option>
                <mat-option *ngFor="let option of LabelsDispatchDisRepNames" [value]="option['dis_rep_name']">{{
                  option["dis_rep_name"]
                  }}</mat-option>
              </mat-select>
              <button *ngIf="disrepnames" matSuffix mat-icon-button aria-label="Clear"
                (click)="reset_distribution_rep_names()">
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>
            <mat-form-field appearance="outline" style="width: 25%; margin-left: 3%">
              <mat-label>Distribution Mode</mat-label>
              <mat-select #mySel13 formControlName="dismode" (selectionChange)="getdismode($event)">
                <mat-option *ngFor="let option of newdismode" [value]="option['dis_mode']">{{
                  option["dis_mode"]
                  }}</mat-option>
              </mat-select>
              <button *ngIf="dismode1" matSuffix mat-icon-button aria-label="Clear" (click)="reset_dismode()">
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>
            <mat-form-field appearance="outline" style="width: 25%; margin-left: 3%">
              <mat-label>Sub Distribution Mode</mat-label>
              <mat-select #mySel14 formControlName="subdismode" (selectionChange)="getsubdismode($event)">
                <mat-option *ngFor="let option of subdistmode" [value]="option['dis_sub_mode']">{{
                  option["dis_sub_mode"]
                  }}</mat-option>
              </mat-select>
              <button *ngIf="subdismode" matSuffix mat-icon-button aria-label="Clear" (click)="reset_subdismode()">
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>
          </div>
          <!-- <div class="form-row">
            <mat-form-field appearance="outline" style="width: 25%; margin-left: 3%">
              <mat-label>Distribution Mode</mat-label>
              <mat-select #mySel13 formControlName="subdismode" (selectionChange)="getdismode($event)">
                <mat-option *ngFor="let option of LabelsDisModes" [value]="option['dis_mode']">{{
                  option["dis_mode"]
                  }}</mat-option>
              </mat-select>
              <button *ngIf="dismode1" matSuffix mat-icon-button aria-label="Clear" (click)="reset_dismode()">
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>
            <mat-form-field appearance="outline" style="width: 25%; margin-left: 3%">
              <mat-label>Sub Distribution Mode</mat-label>
              <mat-select #mySel13 formControlName="subdismode" (selectionChange)="getsubdismode($event)">
                <mat-option *ngFor="let option of FilteredLabelsSubDisModes" [value]="option['sub_dis_mode']">{{
                  option["sub_dis_mode"]
                  }}</mat-option>
              </mat-select>
              <button *ngIf="subdismode" matSuffix mat-icon-button aria-label="Clear" (click)="reset_subdismode()">
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>
          </div> -->
          <div class="form-row">
            <mat-form-field appearance="outline" style="width: 25%; margin-left: 7%">
              <mat-label>एका प्रतीचे वजन</mat-label>
              <input matInput type="number" formControlName="vajan">
            </mat-form-field>
            <mat-form-field appearance="outline" style="width: 25%; margin-left: 3%">
              <mat-label>टपाल दर</mat-label>
              <input matInput type="number" formControlName="tapalrate">
            </mat-form-field>
          </div>
        </form>
        <div class="form-row">
          <button mat-raised-button color="primary" style="margin-left: 7%;" (click)="handDeliveryLabelList()">Dispatch
            Address List</button>
          <button mat-raised-button color="primary" style="margin-left: 3%;" (click)="handDeliveryLabel()">Parcel
            Label</button>
          <button mat-raised-button color="primary" style="margin-left: 3%;" (click)="postStatement()">Dispatch
            Statement</button>
            <button mat-raised-button color="primary" style="margin-left: 7%;" (click)="handDelivery()">All
              Distributor
              Copies Total</button>
        </div>
        <div class="form-row">
        
        </div>
        <!-- </div> -->
      </mat-card>
    </mat-tab>

    <!-- <mat-tab label="Renewal Report">
      <mat-card>
        <form [formGroup]="renewalreportForm">
          <div class="form-row">
            <mat-form-field appearance="outline" style="margin-left: 7%; width: 40%">
              <mat-label>Enter a date from...to </mat-label>
              <mat-date-range-input [rangePicker]="pickerrr">
                <input matStartDate formControlName="start" placeholder="Start date" />
                <input matEndDate formControlName="end" placeholder="End date"
                  (dateChange)="getRenewReportDateRange()" />
              </mat-date-range-input>
              <mat-datepicker-toggle matSuffix [for]="pickerrr"></mat-datepicker-toggle>
              <mat-date-range-picker #pickerrr></mat-date-range-picker>

              <mat-error *ngIf="renewalreportForm.controls.start.hasError('matStartDateInvalid')
                                      ">Invalid start date</mat-error>
              <mat-error *ngIf="renewalreportForm.controls.end.hasError('matEndDateInvalid')">Invalid end date
              </mat-error>
            </mat-form-field>
          </div>
          <div class="form-row">
            <mat-form-field appearance="outline" class="example-full-width" style="margin-left: 7%; width: 40%">
              <mat-label>Representative Name</mat-label>
              <input matInput formControlName="rep" (keyup)="getRepNames($event)" [matAutocomplete]="autorepname1" />
              <mat-autocomplete autoActiveFirstOption #autorepname1="matAutocomplete">
                <mat-option (onSelectionChange)="selected_rep_names($event, option)"
                  *ngFor="let option of rep_name_data" [value]="option['repname']">
                  {{ option["repname"] }}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
          </div>
        </form>
       

        <div>
          <button mat-raised-button color="primary" style="margin-left: 10%;margin-top: 2%;"
            (click)="getAddressDistrictwiseStatement()">
            Districtwise Statement
          </button>
          <button mat-raised-button color="primary" style="margin-left: 2%;margin-top: 2%;"
            (click)="getRenewPincodewiseStatement()">Pincodewise Statement</button>
        </div>
      </mat-card>
    </mat-tab> -->

    <mat-tab label="Representativewise Address">
      <mat-card>
        <button mat-raised-button color="primary" (click)="representativewiseadd()">Representativewise
          Address</button>
        <div class="tableFixHead" style="margin-top: 20px;">
          <table class="table table-bordered">
            <thead>
              <tr>
                <th>नाव</th>
                <th>ठिकाण</th>
                <th>मो. नं.</th>
                <th>वर्गणीदार संख्या</th>
                <th>चालू</th>
                <th>बंद</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of repaddresslist">
                <td>{{item['rep_name']}}</td>
                <td>{{item['district']}}</td>
                <td>{{item['rep_mob_no']}}</td>
                <td>{{item['sub_count']}}</td>
                <td>{{item['sub_on']}}</td>
                <td>{{item['sub_off']}}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </mat-card>
    </mat-tab>

    <!-- <mat-tab label="Subscribers Address">
      <mat-card>
        <form [formGroup]="subaddform">
          <div class="form-row">
            <mat-form-field appearance="outline" style="margin-left: 1%; width: 40%; margin-top: 20px">
              <mat-label>Enter a date from...to </mat-label>
              <mat-date-range-input [rangePicker]="pickeras">
                <input matStartDate formControlName="start" placeholder="Start date" />
                <input matEndDate formControlName="end" placeholder="End date"
                  (dateChange)="getSubAddressDateRange()" />
              </mat-date-range-input>
              <mat-datepicker-toggle matSuffix [for]="pickeras"></mat-datepicker-toggle>
              <mat-date-range-picker #pickeras></mat-date-range-picker>

              <mat-error *ngIf="
                subaddform.controls.start.hasError('matStartDateInvalid')
              ">Invalid start date</mat-error>
              <mat-error *ngIf="subaddform.controls.end.hasError('matEndDateInvalid')">Invalid end date</mat-error>
            </mat-form-field>
          </div>
          <div class="form-row">
            <mat-form-field appearance="outline" style="margin-left: 1%; margin-top: 2%;width: 40%;">
              <mat-label>Titles</mat-label>
              <mat-select formControlName="titles" (selectionChange)="gettitles($event)">
                <mat-option *ngFor="let option of titles1" [value]="option['title']">{{ option["title"]
                  }}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="form-row">
            <mat-form-field appearance="outline" style="margin-left: 1%; margin-top: 2%;width: 40%;">
              <mat-label>District</mat-label>
              <mat-select formControlName="district" (selectionChange)="getdistrict($event)">
                <mat-option *ngFor="let option of district" [value]="option['district']">{{ option["district"]
                  }}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="form-row">
            <mat-form-field appearance="outline" style="margin-left: 1%; margin-top: 2%;width: 40%;">
              <mat-label>Taluka</mat-label>
              <mat-select formControlName="taluka" (selectionChange)="gettaluka($event)">
                <mat-option *ngFor="let option of taluka" [value]="option['taluka']">{{ option["taluka"]
                  }}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="form-row">
            <mat-form-field appearance="outline" style="margin-left: 1%; margin-top: 2%;width: 40%;">
              <mat-label>Subscription Type</mat-label>
              <mat-select formControlName="subtype" (selectionChange)="getsubtype($event)">
                <mat-option *ngFor="let option of subtype" [value]="option['subtype']">{{ option["subtype"]
                  }}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="form-row">
            <mat-form-field appearance="outline" style="margin-left: 1%; margin-top: 2%;width: 40%;">
              <mat-label>Complimentary</mat-label>
              <mat-select formControlName="complimentary" (selectionChange)="getcomplimentary($event)">
                <mat-option value="Y">Yes</mat-option>
                <mat-option value="N">No</mat-option>
                <mat-option *ngFor="let option of complimentary" [value]="option['complimentary']">{{
                  option["complimentary"]
                  }}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </form>
        <button mat-raised-button color="primary" (click)="subAddress()">Subscriber Address</button>
      </mat-card>
    </mat-tab> -->

    <mat-tab label="Districtwise Pincode List">
      <mat-form-field appearance="fill" class="example-form-field" style="margin-top: 5%">
        <mat-label>Select Date for All Report</mat-label>
        <input matInput [(ngModel)]="newDistEndDate" [matDatepicker]="datepicker"
          (dateChange)="getChangedDate($event)" />
        <mat-datepicker-toggle matSuffix [for]="datepicker"></mat-datepicker-toggle>
        <mat-datepicker #datepicker> </mat-datepicker>
      </mat-form-field>

      <span style="margin-left: 3%; font-size: large; font-weight: bold">
        OR
      </span>

      <mat-form-field appearance="fill" style="margin-top: 5%; margin-left: 3%">
        <mat-label>Enter a date range for Weekly Report</mat-label>
        <mat-date-range-input [formGroup]="DistPinReport" [rangePicker]="picker5">
          <input matStartDate formControlName="start" placeholder="Start date" />
          <input matEndDate formControlName="end" placeholder="End date" (dateChange)="getDisctdates()" />
        </mat-date-range-input>
        <mat-datepicker-toggle matSuffix [for]="picker5"></mat-datepicker-toggle>
        <mat-date-range-picker #picker5></mat-date-range-picker>

        <mat-error *ngIf="DistPinReport.controls.start.hasError('matStartDateInvalid')">Invalid start date
        </mat-error>
        <mat-error *ngIf="DistPinReport.controls.end.hasError('matEndDateInvalid')">Invalid end date
        </mat-error>
      </mat-form-field>

      <mat-form-field appearance="fill" style="margin-left: 3%">
        <mat-label>District Name</mat-label>
        <mat-select #mySel (selectionChange)="getLabelSubDisct($event)" multiple>
          <mat-option [value]="{ city: 'all' }" (click)="toggleAllSelection()">All</mat-option>
          <mat-option *ngFor="let brp of districtList" [value]="brp">{{ brp["city"] }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <button style="margin-left: 3%" (click)="getPincodeWiseReports()" mat-raised-button color="primary">
        Get Report
      </button>

      <div class="add-new-subscriber mat-elevation-z4" style="overflow-x: scroll !important" id="excel-table">
        <div style="margin-top: 20px" class="tableFixHead">
          <button type="button" (click)="exportexcel('Districtwise_Pincode_list')" class="btn btn-danger">
            Export
          </button>
          <table class="table table-striped text-center">
            <caption>
              Showing Data for :
              {{
              district_strings
              }}
            </caption>
            <thead>
              <tr>
                <th>अ.क्र.</th>
                <th>पिनकोड</th>
                <th>वर्गणीदार संख्या</th>
                <th>जिल्हा</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let book of this.pincodeWiseSub; let i = index">
                <td>{{ i + 1 }}</td>
                <td>{{ book["pincode"] }}</td>
                <td>{{ book["sub_count"] }}</td>
                <td>{{ book["city"] }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </mat-tab>

    <!-- <mat-tab label="Actvity Log">
      <div class="add-new-subscriber mat-elevation-z4" style="overflow-x: scroll !important" id="excel-table">
        <div style="margin-top: 20px" class="tableFixHead">
          <button type="button" (click)="exportexcel('Activity_log')" class="btn btn-danger">
            Export
          </button>
          <table class="table table-striped text-center">
            <thead>
              <tr>
                <th>अ.क्र.</th>
                <th>ग्राहक क्र.</th>
                <th>Activity</th>
                <th colspan="2">Action</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let book of this.Notifications; let i = index">
                <td>{{ i + 1 }}</td>
                <td>{{ book["sub_id"] }}</td>
                <td>{{ book["msg"] }}</td>
                <td>
                  <button *ngIf="book['msg'].indexOf('Renew') &gt;= 0" type="button" class="btn btn-danger btn-sm"
                    (click)="View_renewal_details(book['sub_id'])">
                    View
                  </button>
                  <button *ngIf="book['msg'].indexOf('Update') &gt;= 0" type="button" class="btn btn-danger btn-sm"
                    (click)="mark_as_read(book['id'])">
                    Mark As Read
                  </button>
                  <button *ngIf="book['msg'].indexOf('Activation') &gt;= 0" type="button" class="btn btn-success btn-sm"
                    (click)="activate_user(book['sub_id'])">
                    Activate
                  </button>
                </td>
                <td>
                  <button *ngIf="book['msg'].indexOf('Activation') &gt;= 0" type="button" class="btn btn-danger btn-sm"
                    (click)="view_user_details(book['sub_id'])">
                    View
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </mat-tab> -->

    <!-- <mat-tab label="Subscription Account">
     
      <mat-card style="margin-top: 2%;">
        <form [formGroup]="subledgerForm">
          <div class="form-row">
            <mat-form-field appearance="fill" class="example-full-width"
              style="margin-left: 1%; margin-top: 0.5%; width: 35%;">
              <mat-label>Subscriber Name</mat-label>
              <input matInput formControlName="sub_name" [matAutocomplete]="autooff_sub_name" />
              <button *ngIf="sub_name1" matSuffix mat-icon-button aria-label="Clear" (click)="reset_sub_name1()">
                <mat-icon>close</mat-icon>
              </button>
              <mat-autocomplete autoActiveFirstOption #autooff_sub_name="matAutocomplete">
                <mat-option (onSelectionChange)="getsub_name1($event, option)"
                  *ngFor="let option of filteredOptions23 | async" [value]="option['sub_name']">
                  {{ option["sub_name"] }}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>

            <mat-form-field appearance="fill" class="example-full-width"
              style="margin-left: 5%; margin-top: 0.5%; width: 20%;">
              <mat-label>Subscriber No</mat-label>
              <input matInput formControlName="sub_no" [matAutocomplete]="auto_sub_no" />
              <button *ngIf="sub_no1" matSuffix mat-icon-button aria-label="Clear" (click)="reset_sub_no1()">
                <mat-icon>close</mat-icon>
              </button>
              <mat-autocomplete autoActiveFirstOption #auto_sub_no="matAutocomplete">
                <mat-option (onSelectionChange)="getsub_no1($event, option)"
                  *ngFor="let option of filteredOptions24 | async" [value]="option['sub_no']">
                  {{ option["sub_no"] }}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
          </div>
          <div class="form-row">
            <mat-form-field appearance="fill" style="margin-top: 1%; margin-left: 1%;width: 35%;">
              <mat-label>Enter a date range</mat-label>
              <mat-date-range-input [rangePicker]="pickerdatesubledger">
                <input matStartDate formControlName="start" placeholder="Start date" />
                <input matEndDate formControlName="end" placeholder="End date"
                  (dateChange)="getChangedDatesubledger()" />
              </mat-date-range-input>
              <mat-datepicker-toggle *ngIf="!subledgerstartDate && !subledgerendDate" matSuffix
                [for]="pickerdatesubledger"></mat-datepicker-toggle>
              <button *ngIf="subledgerstartDate && subledgerendDate" matSuffix mat-icon-button aria-label="Clear"
                (click)="reset_Datesubledger()">
                <mat-icon>close</mat-icon>
              </button>
              <mat-date-range-picker #pickerdatesubledger></mat-date-range-picker>
            </mat-form-field>
          </div>
        </form>
        <div class="tableFixHead">
          <table class="table table-bordered">
            <thead>
              <tr>
                <th>वर्गणी पावती क्र</th>
                <th>वर्गणी पावती दिनांक</th>
                <th>वर्गणी प्रकार</th>
                <th>वर्गणी कालावधी</th>
                <th>वर्गणी रक्कम</th>
                <th>बँक डिटेल्स</th>
                <th>प्रतिनिधीचे नाव</th>
                <th>Delivery Mode</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of Filteredsubscribersledgerdata">
                <td>{{item['rcpt_no']}}</td>
                <td>{{item['entry_date'] | date:"dd-MM-yyy"}}</td>
                <td>{{item['subtype']}}</td>
                <td>{{item['sub_perid_from_date'] | date:"dd-MM-yyy"}} - {{item['sub_perid_to_date'] |
                  date:"dd-MM-yyy"}}</td>
                <td>{{item['total_amt']}}</td>
                <td><span *ngIf="item['cheque_draft_no'] != 0">
                    {{item['bank_name']}}
                    {{item['cheque_draft_no']}}
                  </span>
                  <span *ngIf="item['cheque_draft_no'] == 0">
                    Cash
                  </span>
                </td>
                <td>{{item['rep_name']}}</td>
                <td>{{item['delivery_mode']}}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <mat-paginator (page)="
        lePageEvent2($event)" [length]="length2" [pageSizeOptions]="[500, 1000, 2000]"
          showFirstLastButtons aria-label="Select page of periodic elements">
        </mat-paginator>
      </mat-card>
    </mat-tab> -->

   
    <mat-tab label="Subscription Invoice">
      <button style="margin-left: 3%; margin-top: 5%" (click)="newCustSubInvoice = !newCustSubInvoice" mat-raised-button
        color="warn">
        New Invoice
      </button>

      <mat-form-field class="example-form-field" *ngIf="newCustSubInvoice" appearance="fill"
        style="margin-left: 3%; margin-top: 5%">
        <mat-label>Customer No.</mat-label>
        <input matInput type="text" [(ngModel)]="cust_id" (ngModelChange)="get_cust_id($event)" />
        <button *ngIf="cust_id" matSuffix mat-icon-button aria-label="Clear" (click)="reset_cust_id()">
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>

      <span *ngIf="newCustSubInvoice" style="margin-left: 3%; font-size: large; font-weight: bold">
        OR
      </span>

      <mat-form-field *ngIf="newCustSubInvoice" class="example-form-field" appearance="fill"
        style="margin-left: 3%; margin-top: 5%">
        <mat-label>Customer Mobile No.</mat-label>
        <input matInput type="tel" [(ngModel)]="cust_mobile" #mobileValidate maxlength="10"
          (ngModelChange)="getcust_mobile($event)" />
        <button *ngIf="cust_mobile" matSuffix mat-icon-button aria-label="Clear" (click)="reset_cust_mobile()">
          <mat-icon>close</mat-icon>
          <mat-hint align="end">{{ mobileValidate.value.length }} / 10</mat-hint>
        </button>
      </mat-form-field>

      <button *ngIf="newCustSubInvoice" style="margin-left: 3%" (click)="getSubscriber()" mat-raised-button
        color="primary">
        Find
      </button>
      <!-- <div *ngIf="!displayNewSubscriber && newCustSubInvoice" style="
          margin-left: 3%;
          margin-top: 2%;
          margin-bottom: 2%;
          text-align: center;
        ">
        <span>No subscriber details available.</span>
      </div> -->
      
      <div *ngIf="newCustSubInvoice" style="margin-top: 20px" class="tableFixHead">
        <button type="button" (click)="exportexcel()" class="btn btn-danger">
          Export
        </button>
        <table class="table table-striped text-center">
          <thead>
            <tr>
              <th>Sr.No.</th>
              <th>Sub No</th>
              <th>Name</th>
              <th>Receipt Number</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of getSubDetails; let i = index">
              <td>{{ i + 1 }}</td>
              <td>{{ item["subno"] }}</td>
              <td>{{ item["name"] }}</td>
              <td>{{ item["rcpt_no"] }}</td>
              <td>
                <button type="button" (click)="ViewCustInvoiceSubscription(book)" class="btn btn-danger">
                  View
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>


      <div *ngIf="displayNewSubscriber && newCustSubInvoice" style="
          margin-left: 3%;
          margin-top: 2%;
          margin-bottom: 2%;
          text-align: center;
        ">
        <span>You are generating invoice for :
          <b> {{ getSubDetails[0]["name"] }}</b>
        </span>
      </div>

      <mat-form-field *ngIf="displayNewSubscriber && newCustSubInvoice" appearance="fill" style="margin-left: 3%">
        <mat-label>Invoice Type</mat-label>
        <mat-select (selectionChange)="getInvoiceType($event)">
          <mat-option value="Y">GST</mat-option>
          <mat-option value="N">Non-GST</mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field class="example-form-field" *ngIf="
          displaygstNumberInput && displayNewSubscriber && newCustSubInvoice
        " appearance="fill" style="margin-left: 3%">
        <mat-label>GST Number : </mat-label>
        <input matInput type="text" [(ngModel)]="gstNumber" (ngModelChange)="getGstNumber($event)" />
      </mat-form-field>

      <button *ngIf="displayNewSubscriber && newCustSubInvoice" style="margin-left: 3%" (click)="getSubInvoice()"
        mat-raised-button color="primary">
        Generate
      </button>

      <mat-form-field *ngIf="!newCustSubInvoice" appearance="fill" style="margin-top: 5%; margin-left: 3%">
        <mat-label>Enter a date range</mat-label>
        <mat-date-range-input [formGroup]="CustSubGeneratedInvoice" [rangePicker]="picker5">
          <input matStartDate formControlName="start" placeholder="Start date" />
          <input matEndDate formControlName="end" placeholder="End date"
            (dateChange)="getCustInvoiceDateRangeSubscription()" />
        </mat-date-range-input>
        <mat-datepicker-toggle matSuffix [for]="picker5"></mat-datepicker-toggle>
        <mat-date-range-picker #picker5></mat-date-range-picker>

        <mat-error *ngIf="
            CustSubGeneratedInvoice.controls.start.hasError(
              'matStartDateInvalid'
            )
          ">Invalid start date</mat-error>
        <mat-error *ngIf="
            CustSubGeneratedInvoice.controls.end.hasError('matEndDateInvalid')
          ">Invalid end date</mat-error>
      </mat-form-field>

      <button *ngIf="!newCustSubInvoice" style="margin-left: 3%" (click)="getCustSubInvoice()" mat-raised-button
        color="primary">
        Get Report
      </button>

      <div *ngIf="!newCustSubInvoice" style="margin-top: 20px" class="tableFixHead">
        <button type="button" (click)="exportexcel()" class="btn btn-danger">
          Export
        </button>
        <table class="table table-striped text-center">
          <thead>
            <tr>
              <th>Sr.No.</th>
              <th>Invoice No</th>
              <th>Invoice Date</th>
              <th>Client Name</th>
              <th>Total Amount</th>
              <th>Status</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let book of this.CustSubInvoiceData; let i = index">
              <td>{{ i + 1 }}</td>
              <td>{{ book["invoice_id"] }}</td>
              <td>{{ book["invoice_date"] | date: "dd-MM-yyy" }}</td>
              <td>{{ book["name"] }}</td>
              <td>{{ book["total_amount"] }}</td>
              <td>{{ book["status"] }}</td>
              <td>
                <button type="button" (click)="ViewCustInvoiceSubscription(book)" class="btn btn-danger">
                  View
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </mat-tab>

    <mat-tab label="Distribution Representative">
      <mat-card>
        <div class="form-row">
          <mat-form-field appearance="fill" style="width: 40%;">
            <mat-label>Enter a date range</mat-label>
            <mat-date-range-input [formGroup]="dateform" [rangePicker]="pickerdist">
              <input matStartDate formControlName="start" placeholder="Start date" />
              <input matEndDate formControlName="end" placeholder="End date" (dateChange)="getChangedDatedist()" />
            </mat-date-range-input>
            <mat-datepicker-toggle matSuffix [for]="pickerdist"></mat-datepicker-toggle>
            <mat-date-range-picker #pickerdist></mat-date-range-picker>
            <mat-error *ngIf="
                                          dateform.controls.start.hasError(
                                            'matStartDateInvalid'
                                          )
                                        ">Invalid start date</mat-error>
            <mat-error *ngIf="
                                          dateform.controls.end.hasError('matEndDateInvalid')
                                        ">Invalid end date</mat-error>
          </mat-form-field>
        </div>
        <button mat-raised-button type="button" color="primary" (click)="openDistribution()">
          Distribution
        </button>
      </mat-card>
    </mat-tab>

    <mat-tab label="Masters Entry">
      <mat-card style="margin-top: 20px; border-radius: 10px; padding-bottom: 30px">
        <h2 style="text-align: center">Add New Subscription Type</h2>
        <form [formGroup]="subtypeform" (ngSubmit)="addsubtype()">
          <div class="form-row">
            <mat-form-field appearance="outline" class="example-full-width" style="margin-left: 7%; width: 35%">
              <mat-label>Subscription Type</mat-label>
              <input matInput formControlName="subtype" />
            </mat-form-field>
            <mat-form-field appearance="outline" class="example-full-width" style="margin-left: 5%; width: 35%">
              <mat-label>Price</mat-label>
              <input matInput type="number" formControlName="price" />
            </mat-form-field>
          </div>
          <div class="form-row">
            <mat-form-field appearance="outline" class="example-full-width" style="margin-left: 7%; width: 35%">
              <mat-label>Date</mat-label>
              <input matInput type="date" formControlName="date" />
            </mat-form-field>

            <mat-form-field appearance="outline" class="example-full-width" style="margin-left: 5%; width: 35%">
              <mat-label>Subscription Short Name</mat-label>
              <input matInput formControlName="shortname" />
            </mat-form-field>
          </div>
          <div class="form-group">
            <button style="margin-left: 5%; margin-right: 25%; width: 60%" [disabled]="subtypeform.invalid"
              type="submit" mat-raised-button color="primary" class="btn btn-primary btn-block">
              Add Subscrition Type
            </button>
          </div>
        </form>
      </mat-card>

      <mat-card style="margin-top: 20px">
        <table class="table table-striped fixedtable text-center">
          <thead>
            <tr>
              <th>Subtype</th>
              <th>Price</th>
              <th>Date</th>
              <th>Shortname</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of this.subscriptiontype">
              <td>{{ item["subtype"] }}</td>
              <td>{{ item["price"] }}</td>
              <td>
                <span *ngIf="item['updated_date'] === '0000-00-00 00:00:00'">{{ item["date"] | date: "dd-MM-yyy"
                  }}</span>
                <span *ngIf="item['updated_date'] !== '0000-00-00 00:00:00'">{{ item["updated_date"] | date: "dd-MM-yyy"
                  }}</span>
              </td>
              <td>{{item.short_name}}</td>
              <td style="display: flex; justify-content: center">
                <button class="btn-sm btn-outline-info" matTooltip="Update" type="button" (click)="updatesubtype(item)">
                  <mat-icon>edit</mat-icon>
                </button>
                <button matTooltip="Update" class="btn-sm btn-outline-info" mat-raised-button
                  (click)="deletemaster(item)">
                  <mat-icon>delete</mat-icon>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </mat-card>
    </mat-tab>

    <mat-tab label="Add Delivery Mode">
      <mat-card>
        <form [formGroup]="adddeliverymode" (ngSubmit)="addDelivery()">
          <mat-form-field appearance="outline" style="margin-left: 5%; width: 35%" class="example-full-width">
            <mat-label>Delivery Mode</mat-label>
            <input matInput formControlName="deliverymode" />
          </mat-form-field>

          <button  class="btn btn-primary" mat-raised-button color="primary"
          style="margin-left: 9%; margin-right: 47%; width: 10%; margin-top: -6%;" type="submit">
          Save
        </button>


        <button *ngIf="delivery_mode_update_btn == true" mat-raised-button class="btn btn-primary" color="primary"
        style="margin-left: 15%; margin-right: 35%; width: 10%;  margin-top: -6%;" (click)="update_delivery_modeapi()">
        Update
      </button>
        </form>

        <div class="tableFixHead1" style="padding: 20px 20px;">
          <table class="table table-striped">
            <thead>
              <tr>
                <th>Sr no</th>
                <th>Delivery Mode</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of distributionmodesData1; let i = index">
                <td>{{ i + 1 }}</td>
                <td>{{ item['delivery_mode'] }}</td>
                <td>
                  <button style="margin-right: 2%;" matTooltip="Delete" class="btn-sm btn-outline-info"
                    (click)="delivery_mode1(item['id'])">
                    <mat-icon>close</mat-icon>
                  </button>
                  <button  style="margin-right: 2%;" matTooltip="Delete" class="btn-sm btn-outline-info"
                  (click)="update_delivery_mode1(item)">
                  <mat-icon>edit</mat-icon>
                </button>
                 
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </mat-card>

      <mat-card class="cards" style="margin-top: 20px">
        <form [formGroup]="adddeliverymodeform" (ngSubmit)="addSubDelivery()">
          <div class="form-row">
            <mat-form-field style="margin-left: 7%; width: 35%" appearance="outline">
              <mat-label>Delivery Mode</mat-label>
              <mat-select formControlName="deliverymode">
                <mat-option *ngFor="let mode of this.delivery_mode" [value]="mode['id']">
                  {{ mode["delivery_mode"] }}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field appearance="outline" style="margin-left: 5%; width: 35%" class="example-full-width">
              <mat-label>Distribution Mode</mat-label>
              <input matInput formControlName="subdeliverymode" />
            </mat-form-field>
          </div>
          <div class="form-group">
            <button *ngIf="!dis_mode_show1" mat-raised-button class="btn btn-primary" mat-raised-button color="primary"
              style="margin-left: 15%; margin-right: 35%; width: 40%">
              Save
            </button>
            <button type="button" *ngIf="dis_mode_show1" mat-raised-button class="btn btn-info" 
              (click)="updateaddSubDelivery()" style="margin-left: 15%; margin-right: 35%; width: 40%">
              Update
            </button>
          </div>
        </form>
        <div class="tableFixHead1" style="padding: 20px 20px;">
          <table class="table table-striped">
            <thead>
              <tr>
                <th>Delivery Mode</th>
                <th>Distribution Mode</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of distributionmodesData">
                <td>{{ item['delivery_mode'] }}</td>
                <td>{{ item['sub_delivery_mode'] }}</td>
                <td>
                  <button style="margin-right: 2%;" matTooltip="Update" class="btn-sm btn-outline-info"
                    (click)="updatedismode(item['id'])">
                    <mat-icon>edit</mat-icon>
                  </button>
                  <button matTooltip="Remove" class="btn-sm btn-outline-danger" (click)="removedismode(item['id'])">
                    <mat-icon>close</mat-icon>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </mat-card>

      <mat-card class="cards" style="margin-top: 5%; padding: 20px 10px">
        <h1 style="text-align: center">Add Distribution Mode</h1>
        <form [formGroup]="addsubdeliverymodeform" (ngSubmit)="addSubDeliveryofSub()">
          <div class="form-row">
            <mat-form-field style="margin-left: 7%; width: 35%" appearance="outline">
              <mat-label>Distribution Mode</mat-label>
              <mat-select formControlName="deliverymode">
                <mat-option (onSelectionChange)="distributionMode($event, mode)"
                  *ngFor="let mode of this.subDeliveryModeData" [value]="mode['id']">
                  {{ mode["sub_delivery_mode"] }}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field appearance="outline" style="margin-left: 5%; width: 35%" class="example-full-width">
              <mat-label>Sub Distribution Mode</mat-label>
              <input matInput formControlName="subdeliverymode" />
            </mat-form-field>
          </div>
          <div class="form-group">
            <button *ngIf="!this.sub_dis_mode_show" mat-raised-button class="btn btn-primary" mat-raised-button color="primary"
              style="margin-left: 15%; margin-right: 35%; width: 40%">
              Save
            </button>
            <button *ngIf="this.sub_dis_mode_show" type="button" mat-raised-button class="btn btn-info"
              (click)="updateaddSubDeliveryofSub()" style="margin-left: 15%; margin-right: 35%; width: 40%">
              Update
            </button>
          </div>
        </form>
        <div class="tableFixHead1" style="padding: 20px 20px;">
          <table class="table table-striped">
            <thead>
              <tr>
                <th>Distribution Mode</th>
                <th>Sub Distribution Mode</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of subdistributionmodesData">
                <td>{{ item['sub_delivery_mode'] }}</td>
                <td>{{ item['subdeliverymode'] }}</td>
                <td>
                  <button style="margin-right: 2%;" matTooltip="Update" class="btn-sm btn-outline-info"
                    (click)="updatesubdismode(item['id'])">
                    <mat-icon>edit</mat-icon>
                  </button>
                  <button matTooltip="Remove" class="btn-sm btn-outline-danger" (click)="removesubdismode(item['id'])">
                    <mat-icon>close</mat-icon>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </mat-card>

      <mat-card>
        <h1 style="text-align: center">Assign Distribution Representative</h1>
        <form [formGroup]="AssignDisRepForm">
          <div class="form-row">
            <mat-form-field class="example-full-width" appearance="outline" style="width: 30%; margin-left: 5%">
              <mat-label>Distribution Representative</mat-label>
              <input matInput formControlName="disrep1" [matAutocomplete]="autodisrep" />
              <mat-autocomplete autoActiveFirstOption #autodisrep="matAutocomplete">
                <mat-option *ngFor="let option of filteredOptions26 | async" [value]="option['name']">
                  {{ option["name"] }}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>

            <mat-form-field class="example-full-width" appearance="outline" style="width: 30%; margin-left: 2%">
              <mat-label>Distribution Mode</mat-label>
              <input matInput formControlName="dismode" [matAutocomplete]="autodismode" />
              <mat-autocomplete autoActiveFirstOption #autodismode="matAutocomplete">
                <mat-option (onSelectionChange)="distributionMode($event, option)"
                  *ngFor="let option of filteredOptions27 | async" [value]="option['sub_delivery_mode']">
                  {{ option["sub_delivery_mode"] }}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>

            <mat-form-field class="example-full-width" appearance="outline" style="width: 30%; margin-left: 2%">
              <mat-label>Distribution Sub Mode</mat-label>
              <input matInput formControlName="dissubmode" [matAutocomplete]="autodissubmode" />
              <mat-autocomplete autoActiveFirstOption #autodissubmode="matAutocomplete">
                <mat-option *ngFor="let option of filteredOptions28 | async" [value]="option['subdistributionmode']">
                  {{ option["subdistributionmode"] }}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
          </div>
          <div class="form-group">
            <button *ngIf="!dis_mode_show" mat-raised-button class="btn btn-primary" type="button" mat-raised-button color="primary"
              style="margin-left: 15%; margin-right: 35%; width: 40%" (click)="assignDistributionMode()">
              Save
            </button>
            <button *ngIf="dis_mode_show" mat-raised-button class="btn btn-info" type="button"
              style="margin-left: 15%; margin-right: 35%; width: 40%" (click)="updateassignDistributionMode()">
              Update
            </button>
          </div>
        </form>
      </mat-card>

      <mat-card>
        <div class="tableFixHead" style="padding: 20px 20px;">
          <table class="table table-striped text-center">
            <thead>
              <tr>
                <td>Distribution Representative</td>
                <td>Distribution Mode</td>
                <td>Distribution Sub Mode</td>
                <td>Action</td>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of distributionmoderepData">
                <td>{{ item['dis_rep'] }}</td>
                <td>{{ item['dis_mode'] }}</td>
                <td>{{ item['dis_sub_mode'] }}</td>
                <td>
                  <button style="margin-right: 2%;" matTooltip="Update" class="btn-sm btn-outline-info"
                    (click)="updateDisMode(item['id'])">
                    <mat-icon>edit</mat-icon>
                  </button>
                  <button matTooltip="Remove" class="btn-sm btn-outline-danger"
                    (click)="removeassigndismode(item['id'])">
                    <mat-icon>close</mat-icon>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </mat-card>
    </mat-tab>

    <mat-tab label="Subscription Representative">
      <mat-card style="margin-top: 20px">
        <table class="table table-striped table-fixed">
          <thead>
            <tr>
              <th>Sr. No</th>
              <th>Representative Name</th>
              <th>Representative Mobile No</th>
              <th>Representative District</th>
              <th>Office Representative</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of subscriberwisereplist1; let i = index">
              <td>{{ i + 1 }}</td>
              <td>{{ item["rep_name"] }}</td>
              <td>{{ item["rep_mob_no"] }}</td>
              <td>{{ item["district"] }}</td>
              <td>{{ item["off_rep_name"] }}</td>
              <td>
                <button type="button" class="btn-sm btn-outline-info" matTooltip="Update" (click)="update(item)">
                  <mat-icon>edit</mat-icon>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </mat-card>
    </mat-tab>

    <mat-tab label="Subscription Renewal Report">
      <mat-card style="height: 600px;">
        <h3 class="text-center" style="font-size: 25px;">Delivery Modewise</h3>
        <div class="form-row " [formGroup]="RenewalreportForm">
          <mat-form-field appearance="outline" style="width: 40%; margin-top: 2%; margin-left: 7%;">
            <mat-label >Enter a date range</mat-label>
            <mat-date-range-input  [rangePicker]="pickerdist1">
              <input matStartDate formControlName="start" placeholder="Start date" />
              <input matEndDate formControlName="end" placeholder="End date" (dateChange)="getChangedDatedist()" />
            </mat-date-range-input>
            <mat-datepicker-toggle matSuffix [for]="pickerdist1"></mat-datepicker-toggle>
            <mat-date-range-picker #pickerdist1></mat-date-range-picker>
            <mat-error *ngIf="
            RenewalreportForm.controls.start.hasError(
                                            'matStartDateInvalid'
                                          )
                                        ">Invalid start date</mat-error>
            <mat-error *ngIf="
            RenewalreportForm.controls.end.hasError('matEndDateInvalid')
                                        ">Invalid end date</mat-error>
            <!-- <mat-select formControlName="date">
              <mat-option value="2023-2024">2023-2024</mat-option>
              <mat-option value="2024-2025">2024-2025</mat-option>
              <mat-option value="2025-2026">2025-2026</mat-option>
              <mat-option value="2026-2027">2026-2027</mat-option>
              <mat-option value="2027-2028">2027-2028</mat-option>

            </mat-select> -->
          </mat-form-field>

          <mat-form-field appearance="outline" style="margin-left: 3%; margin-top: 2%;width: 40%;">
            <mat-label>Officer Name</mat-label>
            <mat-select formControlName="officer" multiple>
              <mat-option value="ALL" (click)="toggleAllSelection()">All</mat-option>
              <!-- <mat-option *ngFor="let option of GetOffRep" [value]="option['office_rep']">{{ option["office_rep"]
                }}</mat-option> -->
                <mat-option *ngFor="let option of LabelsDispatchOffRepNames" [value]="option['off_rep_name']">{{
                  option["off_rep_name"]
                  }}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div class="form-row" [formGroup]="RenewalreportForm">
          <mat-form-field appearance="outline" style="margin-left: 7%; width: 40%" class="example-full-width">
            <mat-label>Country</mat-label>
            <mat-select (selectionChange)="getCountry($event)" formControlName="country" >
              <mat-option *ngFor="let c of countries" value="{{ c['country_name'] }}">{{ c["country_name"] }}
              </mat-option>
            </mat-select>
          </mat-form-field>


          <mat-form-field appearance="outline" style="margin-left: 3%; width: 40%">
            <mat-label>State Name</mat-label>
            <mat-select #mySel4 formControlName="statename" (selectionChange)="getstatename($event)" multiple>
              <!-- <mat-option value="all" (click)="toggleAllSelection4()">All</mat-option> -->
              <!-- LabelsDispatchStateName getstatename()-->
              <mat-option *ngFor="let option of states" [value]="option['state_name']">{{
                option["state_name"]
                }}</mat-option>
            </mat-select>
            <button *ngIf="state_names" matSuffix mat-icon-button aria-label="Clear" (click)="reset_state_names()">
              <mat-icon>close</mat-icon>
            </button>
          </mat-form-field>
          <mat-form-field appearance="outline" style="margin-left: 7%; margin-top: 2%;width: 40%;">
            <mat-label>District</mat-label>
            <mat-select formControlName="district" (selectionChange)="getdistrict($event)" multiple>
              <mat-option value="ALL" (click)="toggleAllSelection()">All</mat-option>
              <mat-option *ngFor="let option of district" [value]="option['district']">{{ option["district"]
                }}</mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field appearance="outline" style="margin-left: 3%; margin-top: 2%;width: 40%;">
            <mat-label>Taluka</mat-label>
            <mat-select formControlName="taluka" (selectionChange)="gettaluka($event)" multiple>
              <mat-option value="ALL" (click)="toggleAllSelection()">All</mat-option>
              <mat-option *ngFor="let option of taluka" [value]="option['taluka']">{{ option["taluka"]
                }}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div class="form-row" [formGroup]="RenewalreportForm">
          <mat-form-field appearance="outline" style="margin-left: 7%; width: 40%" class="example-full-width">
            <mat-label>Represantative Name</mat-label>
            <mat-select formControlName="rep"  multiple>
              <mat-option value="ALL" (click)="toggleAllSelection()">All</mat-option>
              <mat-option *ngFor="let option of GetOffRep" [value]="option['rep_name']">{{ option["rep_name"]
                }}</mat-option>
            </mat-select>      
          </mat-form-field> 
          <!-- <mat-form-field appearance="outline" style="margin-left: 5%; width: 35%" class="example-full-width">
            <mat-label>Office Reprensantive Name</mat-label>
            <input matInput formControlName="subdeliverymode" />
           
          </mat-form-field> -->
          <mat-form-field appearance="outline" style="margin-left: 3%; width: 40%" class="example-full-width">
            <mat-label>Prant Name</mat-label>
            <mat-select formControlName="prantname"  multiple>
              <mat-option value="ALL" (click)="toggleAllSelection()">All</mat-option>
              <mat-option *ngFor="let option of Getprantname" [value]="option['prant_name']">{{ option["prant_name"]
                }}</mat-option>
            </mat-select>      
          </mat-form-field> 

        </div>
        <div class="form-row" [formGroup]="RenewalreportForm">
		  
          
          <mat-form-field appearance="outline" style="margin-left: 7%; width: 40%;">
            <mat-label>Select type of report</mat-label>
            <mat-select #mySel1 formControlName="reportType" (selectionChange)="getContractReportType($event)">
              <!-- <mat-option value="all" (click)="toggleAllSelection()">All</mat-option> -->
              <mat-option value="Renewaljilha">Zilha Wise
              </mat-option>
              <mat-option value="Renewaltaluka">Taluka Wise
              </mat-option>
              <mat-option value="RenewalOff">Office Wise
              </mat-option>
              <mat-option value="Renewalrep">Reprensentative Wise
              </mat-option>
              <mat-option value="PrantwiseDeliverymode">Prant Wise
              </mat-option>
              <!-- <mat-option value="Statewise">StateWise
              </mat-option> -->
            </mat-select>
          </mat-form-field>
        </div>
        <div class="form-row" style="margin-left: 7%;">
          <button class="btn btn-info" (click)="getrenewalreport1()">Subscription
            Report Print</button>
            <button type="button" (click)="excelToExportGSTReport('GST_Report')" class="btn btn-danger">
              Export
            </button>
        </div>
        
        <div id="excel-table">
          <div class="tableFixHead">
           
          </div>
        </div>
      </mat-card>

      <mat-card style="height: 600px;">
        <h3 class="text-center" style="font-size: 25px;">Subscription Type Report</h3>
        <div class="form-row " [formGroup]="RenewalTypereportForm">
          <mat-form-field appearance="outline" style="margin-left: 7%; width: 40%; ">
            <mat-label>Receipt Date</mat-label>
            <mat-date-range-input [rangePicker]="pickerrRT1" >
              <input matStartDate formControlName="start" placeholder="Start date" />
              <input matEndDate formControlName="end" placeholder="End date"
                (dateChange)="getChangedDateContractM1()" />
            </mat-date-range-input>
            <mat-datepicker-toggle matSuffix [for]="pickerrRT1"></mat-datepicker-toggle>
            <mat-date-range-picker #pickerrRT1></mat-date-range-picker>

            <mat-error *ngIf="
            RenewalTypereportForm.controls.start.hasError(
                                  'matStartDateInvalid'
                                )
                              ">Invalid start date
            </mat-error>
            <mat-error *ngIf="
            RenewalTypereportForm.controls.end.hasError('matEndDateInvalid')
                              ">Invalid end date
            </mat-error>
          </mat-form-field>

          <mat-form-field appearance="outline" style="margin-left: 3%; width: 40%;">
            <mat-label>Officer Name</mat-label>
            <mat-select formControlName="officer" multiple>
              <mat-option value="ALL" (click)="toggleAllSelection()">All</mat-option>
              <!-- <mat-option *ngFor="let option of GetOffRep" [value]="option['office_rep']">{{ option["office_rep"]
                }}</mat-option> -->
                <mat-option *ngFor="let option of LabelsDispatchOffRepNames" [value]="option['off_rep_name']">{{
                  option["off_rep_name"]
                  }}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div class="form-row" [formGroup]="RenewalTypereportForm">
          <mat-form-field appearance="outline" style="margin-left: 7%; width: 40%" class="example-full-width">
            <mat-label>Country</mat-label>
            <mat-select (selectionChange)="getCountry($event)" formControlName="country" >
              <mat-option *ngFor="let c of countries" value="{{ c['country_name'] }}">{{ c["country_name"] }}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field appearance="outline" style="margin-left: 3%; width: 40%">
            <mat-label>State Name</mat-label>
            <mat-select #mySel4 formControlName="statename" (selectionChange)="getstatename($event)" multiple>
              <!-- <mat-option value="all" (click)="toggleAllSelection4()">All</mat-option> -->
              <!-- LabelsDispatchStateName getstatename()-->
              <mat-option *ngFor="let option of states" [value]="option['state_name']">{{
                option["state_name"]
                }}</mat-option>
            </mat-select>
            <button *ngIf="state_names" matSuffix mat-icon-button aria-label="Clear" (click)="reset_state_names()">
              <mat-icon>close</mat-icon>
            </button>
          </mat-form-field>

          <mat-form-field appearance="outline" style="margin-left: 7%; margin-top: 2%;width: 40%;">
            <mat-label>District</mat-label>
            <mat-select formControlName="district" (selectionChange)="getdistrict($event)" multiple>
              <mat-option value="ALL" (click)="toggleAllSelection()">All</mat-option>
              <mat-option *ngFor="let option of district" [value]="option['district']">{{ option["district"]
                }}</mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field appearance="outline" style="margin-left: 3%; margin-top: 2%;width: 40%;">
            <mat-label>Taluka</mat-label>
            <mat-select formControlName="taluka" (selectionChange)="gettaluka($event)" multiple>
              <mat-option value="ALL" (click)="toggleAllSelection()">All</mat-option>
              <mat-option *ngFor="let option of taluka" [value]="option['taluka']">{{ option["taluka"]
                }}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div class="form-row" [formGroup]="RenewalTypereportForm">
          <mat-form-field appearance="outline" style="margin-left: 7%; width: 40%" class="example-full-width">
            <mat-label>Represantative Name</mat-label>
            <mat-select formControlName="rep"  multiple>
              <mat-option value="ALL" (click)="toggleAllSelection()">All</mat-option>
              <mat-option *ngFor="let option of GetOffRep" [value]="option['rep_name']">{{ option["rep_name"]
                }}</mat-option>
            </mat-select>          </mat-form-field>
          <!-- <mat-form-field appearance="outline" style="margin-left: 5%; width: 35%" class="example-full-width">
            <mat-label>Office Reprensantive Name</mat-label>
            <input matInput formControlName="subdeliverymode" />
           
          </mat-form-field> -->
          <mat-form-field appearance="outline" style="margin-left: 3%; width: 40%" class="example-full-width">
            <mat-label>Prant Name</mat-label>
            <mat-select formControlName="prantname"  multiple>
              <mat-option value="ALL" (click)="toggleAllSelection()">All</mat-option>
              <mat-option *ngFor="let option of Getprantname" [value]="option['prant_name']">{{ option["prant_name"]
                }}</mat-option>
            </mat-select>      
          </mat-form-field> 

          
          <mat-form-field appearance="outline" style="margin-left: 7%; width: 40%;">
            <mat-label>Select type of report</mat-label>
            <mat-select #mySel1 formControlName="reportType" (selectionChange)="getContractReportType($event)">
              <!-- <mat-option value="all" (click)="toggleAllSelection()">All</mat-option> -->
              <mat-option value="SubscriptionTypeAll">Subscription Type 
              </mat-option>
              <mat-option value="SubscriptionTypeTaluka">Subscription Type Taluka
              </mat-option>
              <mat-option value="SubscriptionTypeOffReprensantive">Subscription Type Off Reprensantive
              </mat-option>
              <mat-option value="SubscriptionTypeReprensantive">Subscription Type Reprensantive
              </mat-option>
            </mat-select>
          </mat-form-field>
          
        </div>
        <div class="form-row" style="margin-left: 7%;">
          <button class="btn btn-info" (click)="getrenewalreport3()">Subscription
            Report Print</button>
            <button type="button" (click)="excelToExportGSTReport('GST_Report')" class="btn btn-danger">
              Export
            </button>
        </div>
        
        <div id="excel-table">
          <div class="tableFixHead">
            
          </div>
        </div>
      </mat-card>

      <mat-card style="height: 600px;">
        <h3 class="text-center" style="font-size: 25px;">Remaining Subscription Renewal</h3>


        <div class="form-row " [formGroup]="RemainingRenewalreportForm">
          <mat-form-field appearance="outline" style="margin-left: 7%; width: 40%; margin-top: 3%;">
            <mat-label>Receipt Date</mat-label>
            <mat-date-range-input [rangePicker]="pickerrRSR" >
              <input matStartDate formControlName="start" placeholder="Start date" />
              <input matEndDate formControlName="end" placeholder="End date"
                (dateChange)="getChangedDateContractM1()" />
            </mat-date-range-input>
            <mat-datepicker-toggle matSuffix [for]="pickerrRSR"></mat-datepicker-toggle>
            <mat-date-range-picker #pickerrRSR
            ></mat-date-range-picker>

            <mat-error *ngIf="
            RemainingRenewalreportForm.controls.start.hasError(
                                  'matStartDateInvalid'
                                )
                              ">Invalid start date
            </mat-error>
            <mat-error *ngIf="
            RemainingRenewalreportForm.controls.end.hasError('matEndDateInvalid')
                              ">Invalid end date
            </mat-error>
          </mat-form-field>

          <mat-form-field appearance="outline" style="margin-left: 3%; margin-top: 2%;width: 40%; padding-top: 10px;">
            <mat-label>Officer Name</mat-label>
            <mat-select formControlName="officer" multiple>
              <mat-option value="ALL" (click)="toggleAllSelection()">All</mat-option>
              <!-- <mat-option *ngFor="let option of GetOffRep" [value]="option['office_rep']">{{ option["office_rep"]
                }}</mat-option> -->
                <mat-option *ngFor="let option of LabelsDispatchOffRepNames" [value]="option['off_rep_name']">{{
                  option["off_rep_name"]
                  }}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div class="form-row" [formGroup]="RemainingRenewalreportForm">
          <mat-form-field appearance="outline" style="margin-left: 7%; width: 40%" class="example-full-width">
            <mat-label>Country</mat-label>
            <mat-select (selectionChange)="getCountry($event)" formControlName="country" >
              <mat-option *ngFor="let c of countries" value="{{ c['country_name'] }}">{{ c["country_name"] }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field appearance="outline" style="margin-left: 3%; width: 40%">
            <mat-label>State Name</mat-label>
            <mat-select #mySel4 formControlName="statename" (selectionChange)="getstatename($event)" multiple>
              <!-- <mat-option value="all" (click)="toggleAllSelection4()">All</mat-option> -->
              <!-- LabelsDispatchStateName getstatename()-->
              <mat-option *ngFor="let option of states" [value]="option['state_name']">{{
                option["state_name"]
                }}</mat-option>
            </mat-select>
            <button *ngIf="state_names" matSuffix mat-icon-button aria-label="Clear" (click)="reset_state_names()">
              <mat-icon>close</mat-icon>
            </button>
          </mat-form-field>
          <mat-form-field appearance="outline" style="margin-left: 7%; margin-top: 2%;width: 40%;">
            <mat-label>District</mat-label>
            <mat-select formControlName="district" (selectionChange)="getdistrict($event)" multiple>
              <mat-option value="ALL" (click)="toggleAllSelection()">All</mat-option>
              <mat-option *ngFor="let option of district" [value]="option['district']">{{ option["district"]
                }}</mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field appearance="outline" style="margin-left: 3%; margin-top: 2%;width: 40%;">
            <mat-label>Taluka</mat-label>
            <mat-select formControlName="taluka" (selectionChange)="gettaluka($event)" multiple>
              <mat-option value="ALL" (click)="toggleAllSelection()">All</mat-option>
              <mat-option *ngFor="let option of taluka" [value]="option['taluka']">{{ option["taluka"]
                }}</mat-option>
            </mat-select>
          </mat-form-field>
          
        </div>
        <div class="form-row" [formGroup]="RemainingRenewalreportForm">
          <mat-form-field appearance="outline" style="margin-left: 7%; width: 40%" class="example-full-width">
            <mat-label>Represantative Name</mat-label>
            <mat-select formControlName="rep"  multiple>
              <mat-option value="ALL" (click)="toggleAllSelection()">All</mat-option>
              <mat-option *ngFor="let option of GetOffRep" [value]="option['rep_name']">{{ option["rep_name"]
                }}</mat-option>
            </mat-select>          </mat-form-field>
          <!-- <mat-form-field appearance="outline" style="margin-left: 5%; width: 35%" class="example-full-width">
            <mat-label>Office Reprensantive Name</mat-label>
            <input matInput formControlName="subdeliverymode" />
           
          </mat-form-field> -->
          <!-- <div class="form-row" [formGroup]="RemainingRenewalreportForm"> -->
            <!-- <mat-form-field style="width: 20%; margin-left: 3%" appearance="outline"> -->
            

            
            <mat-form-field appearance="outline" style="margin-left: 3%; width: 40%" class="example-full-width">
              <mat-label>Prant Name</mat-label>
              <mat-select formControlName="prantname"  multiple>
                <mat-option value="ALL" (click)="toggleAllSelection()">All</mat-option>
                <mat-option *ngFor="let option of Getprantname" [value]="option['prant_name']">{{ option["prant_name"]
                  }}</mat-option>
              </mat-select>      
            </mat-form-field> 
           
            <!-- <mat-form-field style="margin-left: 7%; width: 40%;" appearance="fill"> -->

          <mat-form-field appearance="outline" style="margin-left: 7%; width: 40%;">
            <mat-label>Select type of report</mat-label>
            <mat-select #mySel1 formControlName="reportType" (selectionChange)="getContractReportType($event)">
              <!-- <mat-option value="all" (click)="toggleAllSelection()">All</mat-option> -->
              <mat-option value="RemainingSubscriptionDistrict">Remaining Subscription District
              </mat-option>
              <mat-option value="RemainingSubscriptionTaluka">Remaining Subscription Taluka
              </mat-option>
              <mat-option value="RemainingSubscriptionOffReprensantive">Remaining Subscription Off Reprensantive
              </mat-option>
              <mat-option value="RemainingSubscriptionReprensantive">Remaining Subscription Reprensantive
              </mat-option>
            </mat-select>
          </mat-form-field>
          
        </div>
         
        <div class="form-row" style="margin-left: 7%;">
          <button class="btn btn-info" (click)="getrenewalreportRemaing()">Remaining Subscription
            </button>
            <button type="button" (click)="excelToExportGSTReport('GST_Report')" class="btn btn-danger">
              Export
            </button>
        </div>
        
        <div id="excel-table">
          <div class="tableFixHead">
           
            <!-- <cdk-virtual-scroll-viewport appendOnly itemSize="50" class="example-viewport"> -->
            <!-- <table class="table table-striped text-center">
              <thead>
                <tr>
                  <th>Sr. No.</th>
                  <th>Receipt Date</th>
                  <th>Customer Name</th>
                  <th>Address of Company</th>
                  <th>Receipt No</th>
                  <th>GST No</th>
                  <th>HSN Code</th>
                  <th>Total Amount</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let item of showGSTReceiptData; let i = index">
                  <td>{{ i + 1 }}</td>
                  <td>{{ item["crdt_voucher_date"] | date:"dd-MM-yyyy" }}</td>
                  <td>{{ item["e_name"] }}</td>
                  <td>{{ item["e_district"] }}</td>
                  <td><input type="text" value="{{ item['rcpt_no'] }}"
                      (change)="addBillGSTHSNData($event, i, 'billno')"></td>
                  <td><input type="text" value="{{ item['gst_no'] }}" (change)="addBillGSTHSNData($event, i, 'gstno')">
                  </td>
                  <td><input type="text" value="{{ item['hsn_code'] }}"
                      (change)="addBillGSTHSNData($event, i, 'hsncode')"></td>
                  <td>{{ item["total_amt"] }}</td>
                </tr>
                <tr>
                  <td colspan="7"></td>
                  <td>{{ this.gstreporttotalamt }}</td>
                </tr>
              </tbody>
            </table> -->
            <!-- </cdk-virtual-scroll-viewport> -->
          </div>
        </div>
      </mat-card>


      <mat-card style="height: 500px;">
        <h3 class="text-center" style="font-size: 25px;">Address Label</h3>
<!-- 
        <div class="row">
          <div  class="col-12" style="color: black; font-size: 25px; margin-bottom: 20px;text-align: center;">
            All Address
           </div>
        </div> -->

        <div class="form-row" [formGroup]="AllAddForm">
          <mat-form-field appearance="outline" style="margin-left: 7%; width: 40%; ">
            <mat-label>Receipt Date</mat-label>
            <mat-date-range-input [rangePicker]="pickerAA" >
              <input matStartDate formControlName="start" placeholder="Start date" />
              <input matEndDate formControlName="end" placeholder="End date"
                (dateChange)="getChangedDateContractA1()" />
            </mat-date-range-input>
            <mat-datepicker-toggle matSuffix [for]="pickerAA"></mat-datepicker-toggle>
            <mat-date-range-picker #pickerAA
            ></mat-date-range-picker>

            <mat-error *ngIf="
            AllAddForm.controls.start.hasError(
                                  'matStartDateInvalid'
                                )
                              ">Invalid start date
            </mat-error>
            <mat-error *ngIf="
            AllAddForm.controls.end.hasError('matEndDateInvalid')
                              ">Invalid end date
            </mat-error>
          </mat-form-field>

          <mat-form-field appearance="outline" style="margin-left: 3%; width: 40%" class="example-full-width">
            <mat-label>Country</mat-label>
            <mat-select (selectionChange)="getCountry($event)" formControlName="country" >
              <mat-option *ngFor="let c of countries" value="{{ c['country_name'] }}">{{ c["country_name"] }}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field appearance="outline" style="margin-left: 7%; width: 40%; margin-top: 2%">
            <mat-label>State Name</mat-label>
            <mat-select #mySel4 formControlName="statename" (selectionChange)="getstatename($event)" multiple>
              <!-- <mat-option value="all" (click)="toggleAllSelection4()">All</mat-option> -->
              <!-- LabelsDispatchStateName getstatename()-->
              <mat-option *ngFor="let option of states" [value]="option['state_name']">{{
                option["state_name"]
                }}</mat-option>
            </mat-select>
            <button *ngIf="state_names" matSuffix mat-icon-button aria-label="Clear" (click)="reset_state_names()">
              <mat-icon>close</mat-icon>
            </button>
          </mat-form-field>
          <mat-form-field appearance="outline" style="margin-left: 3%; margin-top: 2%;width: 40%;">
            <mat-label>District</mat-label>
            <mat-select formControlName="district" (selectionChange)="getdistrict($event)" multiple>
              <mat-option value="ALL" (click)="toggleAllSelection()">All</mat-option>
              <mat-option *ngFor="let option of district" [value]="option['district']">{{ option["district"]
                }}</mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field appearance="outline" style="margin-left: 7%; margin-top: 2%;width: 40%;">
            <mat-label>Taluka</mat-label>
            <mat-select formControlName="taluka" (selectionChange)="gettaluka($event)" multiple>    
              <mat-option value="ALL" (click)="toggleAllSelection()">All</mat-option>
              <mat-option *ngFor="let option of taluka" [value]="option['taluka']">{{ option["taluka"]
                }}</mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field appearance="outline" style="margin-left: 3%; margin-top: 2%;width: 40%;">
            <mat-label>Delivery Mode</mat-label>
            <mat-select formControlName="modetype"  multiple>
              <mat-option value="ALL" (click)="toggleAllSelection()">All</mat-option>
              <mat-option value="टपाल" >टपाल</mat-option>
              <mat-option value="हस्ते" >हस्ते</mat-option>
            </mat-select>      
            </mat-form-field>
        </div>

        <div class="form-row" [formGroup]="AllAddForm">
          
          <!-- <mat-form-field appearance="outline" style="margin-left: 5%; width: 35%" class="example-full-width">
            <mat-label>Office Reprensantive Name</mat-label>
            <input matInput formControlName="subdeliverymode" />
           
          </mat-form-field> -->

          

    

           
            
            <!-- <mat-form-field appearance="outline" style="margin-left: 1%; width: 40%" class="example-full-width">
              <mat-label>Prant Name</mat-label>
              <mat-select formControlName="prantname"  multiple>
                <mat-option value="ALL" (click)="toggleAllSelection()">All</mat-option>
                <mat-option *ngFor="let option of Getprantname" [value]="option['prant_name']">{{ option["prant_name"]
                  }}</mat-option>
              </mat-select>      
            </mat-form-field>  -->
          <!-- <div class="form-row">
            <mat-form-field style="margin-left: 3%; width: 40%;">
              <mat-label>Select type of report</mat-label>
              <mat-select #mySel1 formControlName="reportType" (selectionChange)="getContractReportType($event)">
                <mat-option value="all" (click)="toggleAllSelection()">All</mat-option>
                <mat-option value="Comman">Comman
                </mat-option>
                
              </mat-select>
            </mat-form-field>
          </div> -->
        </div>
        <!-- <div class="form-row" style="margin-left: 7%;"> -->
          <div class="form-row" [formGroup]="AllAddForm" class="example-full-width">
          <!-- <button class="btn btn-info" (click)="getrenewalreport6()">Search</button> -->
        
          <mat-form-field appearance="outline" style="margin-left: 7%; width: 40%" class="example-full-width">
            <mat-label>Prant Name</mat-label>
            <mat-select formControlName="prantname" multiple>
              <mat-option value="ALL" (click)="toggleAllSelection()">All</mat-option>
              <mat-option *ngFor="let option of Getprantname" [value]="option['prant_name']">
                {{ option["prant_name"] }}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <!-- <mat-form-field appearance="outline" style="width: 35%; margin-left: 3%">
            <mat-label>Subscription Type</mat-label>
            <input matInput formControlName="duration" [matAutocomplete]="autodua" />
            <mat-autocomplete autoActiveFirstOption #autodua="matAutocomplete">
              <mat-option (onSelectionChange)="durationn($event, option)"
                *ngFor="let option of subscriptiontype " [value]="option['subtype']">
                {{ option["subtype"] }}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field> -->
          <mat-form-field class="example-full-width" appearance="outline" style="width: 40%; margin-left: 3%;">
            <mat-label>Subscriber Type</mat-label>
            <mat-select #mySel2 formControlName="subtype" (selectionChange)="getsubtypes($event)" multiple>
              <!-- <mat-option value="all" (click)="toggleAllSelection2()">All</mat-option> -->
              <mat-option *ngFor="let option of LabelsDispatchSubtypes" [value]="option['sub_type']">{{
                option["sub_type"]
                }}</mat-option>
            </mat-select>
            <button *ngIf="sub_types" matSuffix mat-icon-button aria-label="Clear" (click)="reset_sub_types()">
              <mat-icon>close</mat-icon>
            </button>
          </mat-form-field>

          <!-- <div class="form-row" [formGroup]="AllAddForm"> -->

            <mat-form-field class="example-full-width" appearance="outline" style="width: 40%; margin-left: 7%">
              <mat-label>Select type of report</mat-label>
              <mat-select #mySel15 formControlName="reportType" >
                <!-- <mat-option value="all" (click)="toggleAllSelection()">All</mat-option> -->
                <mat-option value="Address">Addresss
                </mat-option>
                <mat-option value="Address List">Address List
                </mat-option>
              </mat-select>
            </mat-form-field>
  
          <!-- </div> -->
          <button class="btn btn-info " [disabled]="AllAddForm.invalid" (click)="getrenewalreport6()">Search</button>
        

        </div>
      
        <!-- <div class="form-row" style="width: 40%; margin: 0 auto; text-align: right;">
          <button class="btn btn-info" (click)="getrenewalreport6()">Search</button>
      </div> -->
      
      </mat-card>


      <mat-card style="height: 600px;">
        <h3 class="text-center" style="font-size: 25px;"> Representativewise Address</h3>

        <div class="form-row" [formGroup]="PendingAddForm">
          <mat-form-field appearance="outline" style="margin-left: 7%; width: 40%; margin-top: 2%;">
            <mat-label>Receipt Date</mat-label>
            <mat-date-range-input [rangePicker]="pickerAA4" >
              <input matStartDate formControlName="start" placeholder="Start date" />
              <input matEndDate formControlName="end" placeholder="End date"
                (dateChange)="getChangedDateContractA4()" />
            </mat-date-range-input>
            <mat-datepicker-toggle matSuffix [for]="pickerAA4"></mat-datepicker-toggle>
            <mat-date-range-picker #pickerAA4
            ></mat-date-range-picker>

          </mat-form-field>
          <mat-form-field appearance="outline" style="margin-left: 1%; width: 40%; margin-top: 2%;">
            <mat-label>Country</mat-label>
            <mat-select (selectionChange)="getCountry($event)" formControlName="country" >
              <mat-option *ngFor="let c of countries" value="{{ c['country_name'] }}">{{ c["country_name"] }}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field appearance="outline" style="margin-left: 7%; width: 40%; margin-top: 2%;">
            <mat-label>State Name</mat-label>
            <mat-select #mySel4 formControlName="statename" (selectionChange)="getstatename($event)" multiple>
              <!-- <mat-option value="all" (click)="toggleAllSelection4()">All</mat-option> -->
              <!-- LabelsDispatchStateName getstatename()-->
              <mat-option *ngFor="let option of states" [value]="option['state_name']">{{
                option["state_name"]
                }}</mat-option>
            </mat-select>
            <button *ngIf="state_names" matSuffix mat-icon-button aria-label="Clear" (click)="reset_state_names()">
              <mat-icon>close</mat-icon>
            </button>
          </mat-form-field>

          <mat-form-field appearance="outline" style="margin-left: 1%; margin-top: 2%;width: 40%;">
            <mat-label>District</mat-label>
            <mat-select formControlName="district" (selectionChange)="getdistrict($event)" multiple>
              <mat-option value="ALL" (click)="toggleAllSelection()">All</mat-option>
              <mat-option *ngFor="let option of district" [value]="option['district']">{{ option["district"]
                }}</mat-option>
            </mat-select>
          </mat-form-field>
        
        </div>

        <div class="form-row" [formGroup]="PendingAddForm">
          
          <mat-form-field appearance="outline" style="margin-left: 7%; margin-top: 2%;width: 40%;">
            <mat-label>Taluka</mat-label>
            <mat-select formControlName="taluka" (selectionChange)="gettaluka($event)" multiple>
              <mat-option value="ALL" (click)="toggleAllSelection()">All</mat-option>
              <mat-option *ngFor="let option of taluka" [value]="option['taluka']">{{ option["taluka"]
                }}</mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field appearance="outline" style="margin-left: 1%; width: 40%; margin-top: 2%;" class="example-full-width">
            <mat-label>Represantative Name</mat-label>
            <mat-select formControlName="rep"  multiple>
              <mat-option value="ALL" (click)="toggleAllSelection()">All</mat-option>
              <mat-option *ngFor="let option of GetOffRep" [value]="option['rep_name']">{{ option["rep_name"]
                }}</mat-option>
            </mat-select>      
              </mat-form-field>
          

        </div>
        <div class="form-row" [formGroup]="PendingAddForm">
          
          <!-- <mat-form-field appearance="outline" style="margin-left: 5%; width: 35%" class="example-full-width">
            <mat-label>Office Reprensantive Name</mat-label>
            <input matInput formControlName="subdeliverymode" />
           
          </mat-form-field> -->
          <mat-form-field appearance="outline" style="margin-left: 7%; width: 40%" class="example-full-width">
            <mat-label>Delivery Mode</mat-label>
            <mat-select formControlName="modetype"  multiple>
              <mat-option value="ALL" (click)="toggleAllSelection()">All</mat-option>
              <mat-option value="टपाल" >टपाल</mat-option>
              <mat-option value="हस्ते" >हस्ते</mat-option>
            </mat-select>      
            </mat-form-field>

            <mat-form-field appearance="outline" style="margin-left: 1%; width: 40%" class="example-full-width">
              <mat-label>Prant Name</mat-label>
              <mat-select formControlName="prantname"  multiple>
                <mat-option value="ALL" (click)="toggleAllSelection()">All</mat-option>
                <mat-option *ngFor="let option of Getprantname" [value]="option['prant_name']">{{ option["prant_name"]
                  }}</mat-option>
              </mat-select>      
            </mat-form-field> 

           
           

        </div>

        
     
        <div class="form-row" [formGroup]="PendingAddForm" class="example-full-width">

          <!-- <button class="btn btn-info" (click)="getrenewalreport5()">Search</button> -->

          <mat-form-field class="example-full-width" appearance="outline" style="width: 35%; margin-left: 7%;">
            <mat-label>Subscriber Type</mat-label>
            <mat-select #mySel2 formControlName="subtype" (selectionChange)="getsubtypes($event)" multiple>
              <!-- <mat-option value="all" (click)="toggleAllSelection2()">All</mat-option> -->
              <mat-option *ngFor="let option of LabelsDispatchSubtypes" [value]="option['sub_type']">{{
                option["sub_type"]
                }}</mat-option>
            </mat-select>
            <button *ngIf="sub_types" matSuffix mat-icon-button aria-label="Clear" (click)="reset_sub_types()">
              <mat-icon>close</mat-icon>
            </button>
          </mat-form-field>

         
       

          <button class="btn btn-info" (click)="getrenewalreport5()">Search</button>
        </div>

        
        
        </mat-card>
    </mat-tab>

    <mat-tab label="Subscription Data By Credit Voucher">
      <mat-card>
        <div >
          <form [formGroup]="databyvocuher">
          <mat-form-field style="margin-left: 1%; width: 25%; " appearance="outline">
            <mat-label>Receipt Date</mat-label>
            <mat-date-range-input [rangePicker]="pickerrRT1vc" >
              <input matStartDate formControlName="start" placeholder="Start date" />
              <input matEndDate formControlName="end" placeholder="End date"
                (dateChange)="getChangedDateContractByVC()" />
            </mat-date-range-input>
            <mat-datepicker-toggle matSuffix [for]="pickerrRT1vc"></mat-datepicker-toggle>
            <mat-date-range-picker #pickerrRT1vc></mat-date-range-picker>
          </mat-form-field>


          <!-- <mat-form-field appearance="outline" style="margin-left: 5%; width: 35%" class="example-full-width">
            <mat-label>Credit Voucher</mat-label>
            <input matInput formControlName="cvnumber" /> -->
            <!-- <mat-form-field class="example-full-width" appearance="outline" style="width: 30%; margin-left: 7%">
              <mat-label>Search Credit Voucher No :</mat-label>
              <input matInput formControlName="cvnumber" (keyup)="getVoucherNo($event)" [matAutocomplete]="auto1" />
              <mat-autocomplete autoActiveFirstOption #auto1="matAutocomplete">
                <mat-option  *ngFor="let option of voucherNoList"
                  [value]="option.id">
                  {{ option.voucher_no }}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field> -->

            <mat-form-field class="example-full-width" appearance="outline" style="width: 25%; margin-left: 3%">
              <mat-label>Search Credit Voucher No :</mat-label>
              <!-- formControlName="sadvtname" -->
              <input matInput formControlName="cvnumber" (keyup)="getVoucherNo($event)" [matAutocomplete]="cvnumber" />
              <mat-autocomplete autoActiveFirstOption #cvnumber="matAutocomplete">
                <mat-option (onSelectionChange)="selected_vouchers($event)" *ngFor="let option of voucherNoList"
                  [value]="option['voucher_no']">
                  {{ option["voucher_no"] }}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>

            <mat-form-field class="example-full-width" appearance="outline" style="width: 25%; margin-left: 3%">
              <mat-label>Representative Name</mat-label>
              <input matInput formControlName="repname" (keyup)="getRepNames($event)" [matAutocomplete]="autoreabc" />
              <mat-autocomplete autoActiveFirstOption #autoreabc="matAutocomplete">
                <mat-option (onSelectionChange)="selected_rep_names($event, option)"
                  *ngFor="let option of rep_name_data" [value]="option['repname']">
                  {{ option["repname"] }}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>

            <button class="noPrint" style="margin-left: 3%;" mat-raised-button color="primary"
            printSectionId="print-section" (click)="searchdatabycv()">
            Search
        </button>
           
          </form>
        </div>

        <div>
          <div >
            <button class="noPrint" style="margin-top: 2%;" mat-raised-button color="primary"
        [useExistingCss]="true"
            printSectionId="print-section" ngxPrint>
            print
        </button>
            <div id="print-section" style="margin-top: 50px;">
                <table class="table table-bordered">
                    <thead>
                        <tr>
                            <th colspan="3" style="border:none; text-align: center;" class="br-bottom br-right br-top">हिंदुस्तान प्रकाशन संस्था - विवेक साप्ताहिक  </th>

                            <th colspan="2" style="border:none; text-align: center;" class="br-bottom br-right br-top">Data By Credit Voucher</th>
                            <!-- <th colspan="2">Advt. Outstanding Report</th> -->
                           
                            <th colspan="3" style="border:none" class="br-bottom br-right br-top"> Date : From {{ startDate_contract_data | date: 'dd-MM-yyyy' }} - To {{ enddate_contract_data | date: 'dd-MM-yyyy' }}</th>                   
                             <th colspan="3" style="border:none" class="br-bottom br-right br-top">दिनांक :</th>
                            
                        </tr>
                        <ng-container >
                            <tr>

                            </tr>
                            <tr>
                                <th colspan="1" style="border:none" class="br-bottom br-right">Sr</th>
                                <th colspan="1" style="border:none" class="br-bottom br-right">Receipt No</th>
                                <th colspan="1" style="border:none;" class="br-bottom br-right">Voucher Date</th>
                                <th colspan="1" style="border:none" class="br-bottom br-right">Name</th>
                                <th colspan="1" style="border:none" class="br-bottom br-right">Sub No</th>
                                <th colspan="1" style="border:none" class="br-bottom br-right">Sub Type</th>                                
                                <th colspan="1" style="border:none" class="br-bottom br-right" >New/Renew</th>
                                <th colspan="1" style="border:none" class="br-bottom br-right" >Bank</th>
                                <th colspan="1" style="border:none" class="br-bottom br-right" >Amt</th>
                                <!-- <th colspan="1" style="border:none"  class="br-bottom br-right" >Balance</th> -->
                                <th colspan="1" style="border:none"  class="br-bottom br-right" >End Date</th>
                            </tr> 
                        </ng-container>
                       
                          
                    </thead>
                    <tbody>
                        <ng-container  *ngFor="let item1 of databyvc; let i = index">
                        <tr>
                            <td colspan="4" style="border:none; font-weight: bold;" class=" br-right br-bottom br-top">Voucher Number : {{item1.voucher_no}}</td>
                            <td colspan="3" style="border:none; font-weight: bold;" class=" br-right br-bottom br-top">Voucher Amt : {{item1.crdt_voucher_amt}}</td>
                            <td colspan="3" style="border:none; font-weight: bold;" class=" br-right br-bottom br-top">Voucher Rep Name : {{item1.invoice_rep_name}}</td>
    
                        </tr>
                            <ng-container  *ngFor="let item of item1.voucher_data; let i = index">
                                <tr>
                                  <td colspan="1" style="border:none;" class=" br-right">{{i + 1}}</td>
                                    <td colspan="1" style="border:none;" class=" br-right">{{item.rcpt_no}}</td>
                                    <td colspan="1" style="border:none" class=" br-right">{{item.crdt_voucher_date}}</td>
                                    <td colspan="1" style="border:none" class=" br-right">{{item.name}}</td>
                                    <td colspan="1" style="border:none" class=" br-right">{{item.subno}}</td>
                                    <td colspan="1" style="border:none" class=" br-right">{{item.subtype}}</td>
                                    <td colspan="1" style="border:none; text-align: end;" class=" br-right">{{item.rcpt_type}}</td>
                                    <td colspan="1" style="border:none; text-align: end;" class=" br-right">{{item.bank_name}}</td>
                                    <td colspan="1" style="border:none; text-align: end;" class=" br-right">{{item.select_entry_amt}}</td>
                                    <!-- <td colspan="1" style="border:none; text-align: end;" class=" br-right">{{item.balance_amt}}</td> -->
                                    <td colspan="1" style="border:none; text-align: end;" class=" br-right">{{item.sub_perid_to_date}}</td> 
                                  </tr>
                              </ng-container >
                              <tr>
                                <th colspan="8">Total</th>
                                <th colspan="1" style="text-align: end"> {{item1.total_entry_amt}}</th>
                                <th colspan="1" style="text-align: end">{{item1.invoice_bal_amt}}</th>
                                <th colspan="1"></th>

      
                              </tr>
                              
                                <!-- <tr>
                                    <td style="border:none" class="br-bottom br-right br-top" style=" font-weight: bold;">{{item1.advt_data.length}}</td>
                                    <td style="border:none" class="br-bottom br-right br-top" style="text-align: center; font-weight: bold;" colspan="4">एकूण</td>
                                    <td style="border:none" class="br-bottom br-right br-top" style="text-align: end; font-weight: bold;" >{{item1.total_advt_amount}}</td>
                                    <td style="border:none" class="br-bottom br-right br-top" style="text-align: end; font-weight: bold;" >{{item1.total_discounted_amt}}</td>
                                    <td style="border:none" class="br-bottom br-right br-top" style="text-align: end; font-weight: bold;" >{{item1.total_before_gst}}</td>
                                    <td style="border:none" class="br-bottom br-right br-top" style="text-align: end; font-weight: bold;">{{item1.total_gst}}</td>
                                    <td style="border:none" class="br-bottom br-right br-top" style="text-align: end; font-weight: bold;">{{item1.total_amt}}</td>
                                    <td style="border:none" class="br-bottom br-right br-top" style="text-align: end; font-weight: bold;">{{item1.total_rcv}}</td>
                                    <td style="border:none" class="br-bottom br-right br-top" style="text-align: end; font-weight: bold;">{{item1.total_pending}}</td>
                                </tr> -->
                              
                        </ng-container>
                        </tbody>
        
                    <tfoot>
                        <!-- 18 -->
                        <!-- <tr>
                            <td style="border:none" class="br-bottom br-right br-top" style=" font-weight: bold;">{{inDetailsbriflydata.length}}</td>
                            <td style="border:none" class="br-bottom br-right br-top" style="text-align: center; font-weight: bold;" colspan="4">एकूण</td>
                            <td style="border:none" class="br-bottom br-right br-top" style="text-align: end; font-weight: bold;" >{{advtamtb}}</td>
                            <td style="border:none" class="br-bottom br-right br-top" style="text-align: end; font-weight: bold;" >{{totaladvtamt}}</td>
                            <td style="border:none" class="br-bottom br-right br-top" style="text-align: end; font-weight: bold;" >{{totalbeforegst_amt}}</td>
                            <td style="border:none" class="br-bottom br-right br-top" style="text-align: end; font-weight: bold;">{{totalgst}}</td>
                            <td style="border:none" class="br-bottom br-right br-top" style="text-align: end; font-weight: bold;">{{netadvtamt}}</td>
                            <td style="border:none" class="br-bottom br-right br-top" style="text-align: end; font-weight: bold;">{{jamatotalb}}</td>
                            <td style="border:none" class="br-bottom br-right br-top" style="text-align: end; font-weight: bold;">{{netadvtamt - jamatotal}}</td>
                        </tr> -->
                    </tfoot>  
                </table>
               
            </div>
        </div>
        </div>

<!-- <div id="excel-table">
          <div class="tableFixHead">
            <button type="button" (click)="excelToExportGSTReport('GST_Report')" class="btn btn-danger">
              Export
            </button>
          </div>
        </div> -->
      </mat-card>
    </mat-tab>

    <!-- <mat-tab label="Remaining Subscription Renewal">
      <mat-card>
        <div class="form-row " [formGroup]="RemainingRenewalreportForm">
          <mat-form-field appearance="outline" style="margin-left: 1%; width: 40%; margin-top: 3%;">
            <mat-label>Receipt Date</mat-label>
            <mat-date-range-input [rangePicker]="pickerrRSR" >
              <input matStartDate formControlName="start" placeholder="Start date" />
              <input matEndDate formControlName="end" placeholder="End date"
                (dateChange)="getChangedDateContractM1()" />
            </mat-date-range-input>
            <mat-datepicker-toggle matSuffix [for]="pickerrRSR"></mat-datepicker-toggle>
            <mat-date-range-picker #pickerrRSR
            ></mat-date-range-picker>

            <mat-error *ngIf="
            RemainingRenewalreportForm.controls.start.hasError(
                                  'matStartDateInvalid'
                                )
                              ">Invalid start date
            </mat-error>
            <mat-error *ngIf="
            RemainingRenewalreportForm.controls.end.hasError('matEndDateInvalid')
                              ">Invalid end date
            </mat-error>
          </mat-form-field>

          <mat-form-field appearance="outline" style="margin-left: 3%; margin-top: 2%;width: 40%; padding-top: 10px;">
            <mat-label>Officer Name</mat-label>
            <mat-select formControlName="officer" multiple>
              <mat-option value="ALL" (click)="toggleAllSelection()">All</mat-option>
              <mat-option *ngFor="let option of GetOffRep" [value]="option['office_rep']">{{ option["office_rep"]
                }}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div class="form-row" [formGroup]="RemainingRenewalreportForm">
          <mat-form-field appearance="outline" style="margin-left: 1%; margin-top: 2%;width: 40%;">
            <mat-label>District</mat-label>
            <mat-select formControlName="district" (selectionChange)="getdistrict($event)" multiple>
              <mat-option value="ALL" (click)="toggleAllSelection()">All</mat-option>
              <mat-option *ngFor="let option of district" [value]="option['district']">{{ option["district"]
                }}</mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field appearance="outline" style="margin-left: 3%; margin-top: 2%;width: 40%;">
            <mat-label>Taluka</mat-label>
            <mat-select formControlName="taluka" (selectionChange)="gettaluka($event)" multiple>
              <mat-option value="ALL" (click)="toggleAllSelection()">All</mat-option>
              <mat-option *ngFor="let option of taluka" [value]="option['taluka']">{{ option["taluka"]
                }}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div class="form-row" [formGroup]="RemainingRenewalreportForm">
          <mat-form-field appearance="outline" style="margin-left: 1%; width: 40%" class="example-full-width">
            <mat-label>Represantative Name</mat-label>
            <mat-select formControlName="rep"  multiple>
              <mat-option value="ALL" (click)="toggleAllSelection()">All</mat-option>
              <mat-option *ngFor="let option of GetOffRep" [value]="option['rep_name']">{{ option["rep_name"]
                }}</mat-option>
            </mat-select>          </mat-form-field>
         
          <mat-form-field appearance="outline" style="margin-left: 3%; width: 40%;">
            <mat-label>Select type of report</mat-label>
            <mat-select #mySel1 formControlName="reportType" (selectionChange)="getContractReportType($event)">
              <mat-option value="RemainingSubscriptionDistrict">Remaining Subscription District
              </mat-option>
              <mat-option value="RemainingSubscriptionTaluka">Remaining Subscription Taluka
              </mat-option>
              <mat-option value="RemainingSubscriptionOffReprensantive">Remaining Subscription Off Reprensantive
              </mat-option>
              <mat-option value="RemainingSubscriptionReprensantive">Remaining Subscription Reprensantive
              </mat-option>
            </mat-select>
          </mat-form-field>
          
        </div>
        <div class="form-row">
          <button class="btn btn-info" (click)="getrenewalreportRemaing()">Remaining Subscription
            </button>
        </div>
        
        <div id="excel-table">
          <div class="tableFixHead">
            <button type="button" (click)="excelToExportGSTReport('GST_Report')" class="btn btn-danger">
              Export
            </button>
           
          </div>
        </div>
      </mat-card>
    </mat-tab> -->

    <!-- <mat-tab label="Pending Address Reprensantive">
      <mat-card>

        <div class="form-row" [formGroup]="PendingAddForm">
          <mat-form-field appearance="outline" style="margin-left: 1%; margin-top: 2%;width: 40%;">
            <mat-label>District</mat-label>
            <mat-select formControlName="district" (selectionChange)="getdistrict($event)" multiple>
              <mat-option value="ALL" (click)="toggleAllSelection()">All</mat-option>
              <mat-option *ngFor="let option of district" [value]="option['district']">{{ option["district"]
                }}</mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field appearance="outline" style="margin-left: 3%; margin-top: 2%;width: 40%;">
            <mat-label>Taluka</mat-label>
            <mat-select formControlName="taluka" (selectionChange)="gettaluka($event)" multiple>
              <mat-option value="ALL" (click)="toggleAllSelection()">All</mat-option>
              <mat-option *ngFor="let option of taluka" [value]="option['taluka']">{{ option["taluka"]
                }}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div class="form-row" [formGroup]="PendingAddForm">
          <mat-form-field appearance="outline" style="margin-left: 1%; width: 40%" class="example-full-width">
            <mat-label>Represantative Name</mat-label>
            <mat-select formControlName="rep"  multiple>
              <mat-option value="ALL" (click)="toggleAllSelection()">All</mat-option>
              <mat-option *ngFor="let option of GetOffRep" [value]="option['rep_name']">{{ option["rep_name"]
                }}</mat-option>
            </mat-select>      
              </mat-form-field>
          <mat-form-field appearance="outline" style="margin-left: 5%; width: 35%" class="example-full-width">
            <mat-label>Office Reprensantive Name</mat-label>
            <input matInput formControlName="subdeliverymode" />
           
          </mat-form-field>
          <mat-form-field appearance="outline" style="margin-left: 1%; width: 40%" class="example-full-width">
            <mat-label>Pending Address Name</mat-label>
            <mat-select formControlName="modetype"  multiple>
              <mat-option value="ALL" (click)="toggleAllSelection()">All</mat-option>
              <mat-option value="टपाल" >टपाल</mat-option>
              <mat-option value="हस्ते" >हस्ते</mat-option>
            </mat-select>      
            </mat-form-field>
         
          <div class="form-row">
            <mat-form-field style="margin-left: 3%; width: 40%;">
              <mat-label>Select type of report</mat-label>
              <mat-select #mySel1 formControlName="reportType" (selectionChange)="getContractReportType($event)">
                <mat-option value="all" (click)="toggleAllSelection()">All</mat-option>
                <mat-option value="Comman">Comman
                </mat-option>
                
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <div class="form-row">
          <button class="btn btn-info" (click)="getrenewalreport5()">
            Address</button>
        </div>
        
        <div id="excel-table">
          <div class="tableFixHead">
            <button type="button" (click)="excelToExportGSTReport('GST_Report')" class="btn btn-danger">
              Export
            </button>
          </div>
        </div>
      </mat-card>
    </mat-tab> -->

    

    <!-- <mat-tab label="Address Label">
      <mat-card>
        <div class="row">
          <div  class="col-12" style="color: black; font-size: 25px; margin-bottom: 20px;text-align: center;">
            All Address
           </div>
        </div>

        <div class="form-row" [formGroup]="AllAddForm">
          <mat-form-field appearance="outline" style="margin-left: 7%; margin-top: 2%;width: 40%;">
            <mat-label>District</mat-label>
            <mat-select formControlName="district" (selectionChange)="getdistrict($event)" multiple>
              <mat-option value="ALL" (click)="toggleAllSelection()">All</mat-option>
              <mat-option *ngFor="let option of district" [value]="option['district']">{{ option["district"]
                }}</mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field appearance="outline" style="margin-left: 3%; margin-top: 2%;width: 40%;">
            <mat-label>Taluka</mat-label>
            <mat-select formControlName="taluka" (selectionChange)="gettaluka($event)" multiple>
              <mat-option value="ALL" (click)="toggleAllSelection()">All</mat-option>
              <mat-option *ngFor="let option of taluka" [value]="option['taluka']">{{ option["taluka"]
                }}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div class="form-row" [formGroup]="AllAddForm">
          
       

          <mat-form-field appearance="outline" style="margin-left: 7%; width: 40%; ">
            <mat-label>Receipt Date</mat-label>
            <mat-date-range-input [rangePicker]="pickerAA" >
              <input matStartDate formControlName="start" placeholder="Start date" />
              <input matEndDate formControlName="end" placeholder="End date"
                (dateChange)="getChangedDateContractA1()" />
            </mat-date-range-input>
            <mat-datepicker-toggle matSuffix [for]="pickerAA"></mat-datepicker-toggle>
            <mat-date-range-picker #pickerAA
            ></mat-date-range-picker>

            <mat-error *ngIf="
            AllAddForm.controls.start.hasError(
                                  'matStartDateInvalid'
                                )
                              ">Invalid start date
            </mat-error>
            <mat-error *ngIf="
            AllAddForm.controls.end.hasError('matEndDateInvalid')
                              ">Invalid end date
            </mat-error>
          </mat-form-field>

          <mat-form-field appearance="outline" style="margin-left: 3%; width: 40%;" class="example-full-width">
            <mat-label>Pending Address Name</mat-label>
            <mat-select formControlName="modetype"  multiple>
              <mat-option value="ALL" (click)="toggleAllSelection()">All</mat-option>
              <mat-option value="टपाल" >टपाल</mat-option>
              <mat-option value="हस्ते" >हस्ते</mat-option>
            </mat-select>      
            </mat-form-field>
         
        
        </div>
        <div class="form-row" style="margin-left: 7%;">
          <button class="btn btn-info" (click)="getrenewalreport6()">
            Search</button>
        </div>
        
       
      </mat-card>


      <mat-card>
        <div class="row">
          <div  class="col-12" style="color: black; font-size: 25px; margin-bottom: 20px;text-align: center;">
            Representativewise Address
           </div>
        </div>
        <div class="form-row" [formGroup]="PendingAddForm">
          <mat-form-field appearance="outline" style="margin-left: 1%; margin-top: 2%;width: 40%;">
            <mat-label>District</mat-label>
            <mat-select formControlName="district" (selectionChange)="getdistrict($event)" multiple>
              <mat-option value="ALL" (click)="toggleAllSelection()">All</mat-option>
              <mat-option *ngFor="let option of district" [value]="option['district']">{{ option["district"]
                }}</mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field appearance="outline" style="margin-left: 3%; margin-top: 2%;width: 40%;">
            <mat-label>Taluka</mat-label>
            <mat-select formControlName="taluka" (selectionChange)="gettaluka($event)" multiple>
              <mat-option value="ALL" (click)="toggleAllSelection()">All</mat-option>
              <mat-option *ngFor="let option of taluka" [value]="option['taluka']">{{ option["taluka"]
                }}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div class="form-row" [formGroup]="PendingAddForm">
          <mat-form-field appearance="outline" style="margin-left: 1%; width: 40%" class="example-full-width">
            <mat-label>Represantative Name</mat-label>
            <mat-select formControlName="rep"  multiple>
              <mat-option value="ALL" (click)="toggleAllSelection()">All</mat-option>
              <mat-option *ngFor="let option of GetOffRep" [value]="option['rep_name']">{{ option["rep_name"]
                }}</mat-option>
            </mat-select>      
              </mat-form-field>
         
          <mat-form-field appearance="outline" style="margin-left: 3%; width: 40%" class="example-full-width">
            <mat-label>Pending Address Name</mat-label>
            <mat-select formControlName="modetype"  multiple>
              <mat-option value="ALL" (click)="toggleAllSelection()">All</mat-option>
              <mat-option value="टपाल" >टपाल</mat-option>
              <mat-option value="हस्ते" >हस्ते</mat-option>
            </mat-select>      
            </mat-form-field>
         
          
        </div>
        <div class="form-row">
          <button class="btn btn-info" (click)="getrenewalreport5()">
            Search</button>
        </div>
        
       
      </mat-card>
    </mat-tab> -->

    
  </mat-tab-group>

  
</div>