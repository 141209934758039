import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { DigisubservicesService } from 'src/app/services/digisubservices.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-maps',
  templateUrl: './maps.component.html',
  styleUrls: ['./maps.component.scss']
})
export class MapsComponent implements OnInit 
{
  form : FormGroup;
  data1;

  constructor(private router : Router,private fb : FormBuilder, public service : DigisubservicesService) 
  { 
    this.form = fb.group({
      searchedSub : ['']
    });
  }

  ngOnInit() {
    if(sessionStorage.getItem("mobile") == null){
      this.router.navigate(['/']);
    }
  }

  submitForm()
  {
    //console.log(this.form.value['searchedSub'].toString().toLowerCase())
    let resultState = this.service.getSearchedSub({searchedSub:this.form.value['searchedSub'].toString().toLowerCase()});
    resultState.subscribe((data)=>{
      this.data1 = data;
      //console.log("Data from Searched_Sub.php : ",this.data1);
    });
  }

}
 