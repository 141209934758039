import { Component, OnInit } from '@angular/core';
import { DigisubservicesService } from 'src/app/services/digisubservices.service';
import { formatDate } from '@angular/common';
import { Form, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import * as XLSX from 'xlsx';

interface tabledata{
  'Dates':string;
  'Name':string;
  'Commission':number;
  'Collected Amount':number;
  'Submitted Amount':number;
  'Outstanding Payment':number;
  'Payment Status':string;
}
@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {
  model;
  data1;
  data2;
  data3;
  data4;
  date;
  todate;
  fromdate;
  date1;
  data7;
  DailyBatchStatus: any[];
    selecteddate;
    tableHeaders = ['Header 1', 'Header 2', 'Header 3'];
  tableRows = [
    ['Cell', 'Cell', 'Cell'],
    ['Cell', 'Cell', 'Cell'],
    ['Cell', 'Cell', 'Cell']
  ];
  datalength;
  tableData : tabledata[] = [];
  
 yourMessage = []
  dataLoaded = false;

  


  constructor(private router : Router,public service : DigisubservicesService) {
    
   }
   action(_value){

   }

  ngOnInit() {
    if(sessionStorage.getItem("mobile") == null){
      this.router.navigate(['/']);
    }
    this.date = new FormControl(new Date());
    this.todate = new FormControl(new Date());
    this.fromdate = new FormControl(new Date());

    let resultState = this.service.getPortalAllRepList();
    resultState.subscribe((data)=>{
      this.data1 = data;
    });

    let resultState1 = this.service.getPortalRepWiseCommission();
    resultState1.subscribe((data)=>{
      this.data2 = data;
    });

    let resultState3 = this.service.getPortalSubRenewal();
    resultState3.subscribe((data)=>{
      this.data3 = data;
    });

    let resultState4 = this.service.getPortalSubEmailMobile();
    resultState4.subscribe((data)=>{
      this.data4 = data;
    });
  }

  /*name of the excel-file which will be downloaded. */ 
fileName= 'ExcelSheet.xlsx';  

exportexcel(): void 
    {
       /* table id is passed over here */   
       let element = document.getElementById('excel-table'); 
       const ws: XLSX.WorkSheet =XLSX.utils.table_to_sheet(element);

       /* generate workbook and add the worksheet */
       const wb: XLSX.WorkBook = XLSX.utils.book_new();
       XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

       /* save to file */
       XLSX.writeFile(wb, this.fileName);
			
    }

  selectDailydata()
    {
        //console.log(_value)
        //console.log(formatDate(new Date(this.date.value), "yyyy-MM-dd", "en-US", "GMT+0530") );
        let tofrmtdate;
        let fromfrmtdate;
        /*if(_value == null)
        {
          this.tableData = [];
          this.datalength = Object.keys(this.tableData).length 
          tofrmtdate = fromfrmtdate = formatDate(new Date(), "yyyy-MM-dd", "en-US", "GMT+0530")
        }
        else
        {*/
          this.tableData=[];
          this.datalength = Object.keys(this.tableData).length 
          tofrmtdate = formatDate(new Date(this.todate.value), "yyyy-MM-dd", "en-US", "GMT+0530")
          fromfrmtdate = formatDate(new Date(this.fromdate.value), "yyyy-MM-dd", "en-US", "GMT+0530")
        //}

        this.service.getPortalWiseCollectionDetails(tofrmtdate,fromfrmtdate).subscribe((res : any[]) => {
          this.DailyBatchStatus = res;
          //console.log(this.DailyBatchStatus);
          
          for (let index = 0; index < Object.keys(this.DailyBatchStatus).length; index++) {
            
            this.tableData.push({
              'Dates': this.DailyBatchStatus[index]['dt'],
              'Name':this.DailyBatchStatus[index]['name'],
              'Commission':this.DailyBatchStatus[index]['comission'],
              'Collected Amount':this.DailyBatchStatus[index]['collectedamt'],
              'Submitted Amount':this.DailyBatchStatus[index]['submittedamt'],
              'Outstanding Payment':this.DailyBatchStatus[index]['outstandingamt'],
              'Payment Status':this.DailyBatchStatus[index]['payment_details']
            })
          }

          //console.log(this.tableData)
          this.datalength = Object.keys(this.tableData).length 
          if (Object.keys(this.tableData).length == 0)
          {
            this.dataLoaded=true;
          }
          else{
            this.dataLoaded=false;
          }
          //this.collection.data = this.DailyBatchStatus
        })
    }

    
}
