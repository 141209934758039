import { Component, OnInit, Inject, ChangeDetectorRef } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DigisubservicesService } from 'src/app/services/digisubservices.service';
import { ContractBriefReportComponent } from '../pages/contract-brief-report/contract-brief-report.component';
import { FormBuilder } from '@angular/forms';
import { AdvertisementDetailsComponent } from '../pages/advertisement-details/advertisement-details.component';
import { ToWords } from 'to-words';
import { NgxPrintDirective } from 'ngx-print';

@Component({
  selector: 'app-crreceipt',
  templateUrl: './crreceipt.component.html',
  styleUrls: ['./crreceipt.component.scss']
})
export class CrreceiptComponent implements OnInit {
  public globalId: string = '';
  totalinWords: String;
  toWords: any;
  credit_type: any;
  remark: any;
  constructor(private dialogRef: MatDialogRef<ContractBriefReportComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private service: DigisubservicesService,
    private cdr: ChangeDetectorRef,private fb: FormBuilder,public activeModal: MatDialogRef<AdvertisementDetailsComponent>,
    @Inject(MAT_DIALOG_DATA) public data1: any
  )
   { }
name:any
add:any
mobile:any;
invoicenum:any
invoicedate:any
CreitVC:any
jama:any
  ngOnInit(): void {
    
    // data: {
    //   name: data[0].name,
    //   add : data[0].address,
    //   mobile : data[0].mobile,
    //   invoicenum : data[0].invoice_no,
    //   invoicedate : data[0].voc_date,
    //   jama : data[0].credit_amt,
     
    // },
    debugger
      this.name= this.data['name']
        this.add= this.data['add']
        this.mobile= this.data['mobile']
        this.invoicenum= this.data['invoicenum']
        this.invoicedate= this.data['invoicedate']
        this.jama=this.data['jama']
        this.credit_type= this.data['credit_type']
        this.remark= this.data['remark']
        this.CreitVC=this.data['cr_voucher']

        console.log(this.data, "gst data")


        this.toWords = new ToWords();
        this.totalinWords = this.toWords.convert(this.data['jama'], {
          currency: true,
        });
       
   
  }
  // print(): void {
  //   // Call ngxPrint directive to print the specified section
  //   NgxPrintDirective.print({printSectionId: 'print-section'});
  // }

}
