 <div class="header bg-gradient-danger pb-1 pt-5 pt-md-8">
  <div class="container-fluid">
    <div class="header-body"></div>
  </div>
</div>

<div class="container" style="margin-top: -4.1em">
  <!-- Icons is Used as Admin  -->
  <mat-tab-group dynamicHeight [(selectedIndex)]="demo1TabIndex"  (selectedTabChange)="onTabClick($event)">
    <mat-tab label="Manage Book">
      <div class="add-new-subscriber mat-elevation-z4" style="overflow-x: scroll !important">
        <div style="margin-top: 20px">
          <div class="text-center">
            <h1 style="font-size: medium; font-weight: bold">Add New Book</h1>
          </div>
          <form class="form" [formGroup]="BookForm" (ngSubmit)="save()">
            <div class="form-row">
              <mat-form-field appearance="outline" class="example-full-width"
                style="width: 40%; margin-left: 35px; margin-top: 20px">
                <mat-label>Book Name</mat-label>
                <input matInput formControlName="bookname" [matAutocomplete]="autobookname" />
                <mat-autocomplete autoActiveFirstOption #autobookname="matAutocomplete">
                  <mat-option *ngFor="let option of filteredOptions8 | async" [value]="option['bookname']"
                    (onSelectionChange)="selected_bookname(option)">
                    {{ option["bookname"] }}
                  </mat-option>
                </mat-autocomplete>
              </mat-form-field>
              <!-- form-group end.// -->

              <mat-form-field class="example-full-width" appearance="outline"
                style="width: 40%; margin-left: 35px; margin-top: 20px">
                <mat-label>Book Description</mat-label>
                <input matInput type="text" formControlName="bookdesc" />
              </mat-form-field>
            </div>
            <div class="form-row">
              <mat-form-field class="example-full-width" appearance="outline"
                style="width: 30%; margin-left: 35px; margin-top: 10px">
                <mat-label>Author Name</mat-label>
                <input matInput type="text" formControlName="authorname" />
              </mat-form-field>
              <!-- form-group end.// -->
              <label style="margin-left: 35px; margin-top: 25px">Book Pages :</label>
              <mat-form-field class="example-full-width" appearance="outline"
                style="width: 10%; margin-left: 10px; margin-top: 10px">
                <mat-label>B/w</mat-label>
                <input (change)="caltotalpages($event)" matInput type="number" formControlName="bookpagesbw" />
              </mat-form-field>
              <mat-form-field class="example-full-width" appearance="outline"
                style="width: 10%; margin-left: 10px; margin-top: 10px">
                <mat-label>Inside Color</mat-label>
                <input (change)="caltotalpages($event)" matInput type="number" formControlName="bkpinsidecolor" />
              </mat-form-field>
              <mat-form-field class="example-full-width" appearance="outline"
                style="width: 10%; margin-left: 10px; margin-top: 10px">
                <mat-label>Cover</mat-label>
                <input (change)="caltotalpages($event)" matInput type="number" formControlName="bkpcover" />
              </mat-form-field>
              <mat-form-field class="example-full-width" appearance="outline"
                style="width: 10%; margin-left: 15px; margin-top: 10px">
                <mat-label>Total Pages</mat-label>
                <input matInput type="number" formControlName="totalpages" readonly />
              </mat-form-field>
            </div>
            <div class="form-row">
              <mat-form-field class="example-full-width" appearance="outline"
                style="width: 30%; margin-left: 35px; margin-top: 10px">
                <mat-label>Book Size</mat-label>
                <input matInput type="text" formControlName="booksize" />
              </mat-form-field>
              <label style="margin-left: 35px; margin-top: 25px">Paper :</label>
              <mat-form-field class="example-full-width" appearance="outline"
                style="width: 15%; margin-left: 10px; margin-top: 10px">
                <mat-label>B/w</mat-label>
                <input matInput type="text" formControlName="paperbw" />
              </mat-form-field>
              <mat-form-field class="example-full-width" appearance="outline"
                style="width: 15%; margin-left: 05px; margin-top: 10px">
                <mat-label>Insie color</mat-label>
                <input matInput type="text" formControlName="paperinsidecolor" />
              </mat-form-field>
              <mat-form-field class="example-full-width" appearance="outline"
                style="width: 15%; margin-left: 05px; margin-top: 10px">
                <mat-label>Cover</mat-label>
                <input matInput type="text" formControlName="papercover" />
              </mat-form-field>
            </div>
            <div class="form-row">
              <mat-form-field class="example-full-width" appearance="outline"
                style="width: 40%; margin-left: 35px; margin-top: 10px">
                <mat-label>Binding</mat-label>
                <input matInput type="text" formControlName="binding" />
              </mat-form-field>
            </div>
            <div class="form-row">
              <mat-form-field class="example-full-width" appearance="outline"
                style="width: 40%; margin-left: 35px; margin-top: 10px">
                <mat-label>Book Language</mat-label>
                <input matInput type="text" formControlName="booklang" />
              </mat-form-field>
              <!-- form-group end.// -->

              <mat-form-field class="example-full-width" appearance="outline"
                style="width: 40%; margin-left: 35px; margin-top: 10px">
                <mat-label>Book Publish Year</mat-label>
                <input matInput type="text" formControlName="bookyear" />
              </mat-form-field>
            </div>
            <div class="form-row">
              <mat-form-field class="example-full-width" appearance="outline"
                style="width: 40%; margin-left: 35px; margin-top: 10px">
                <mat-label>Book Price</mat-label>
                <input matInput type="number" formControlName="price" />
              </mat-form-field>
              <!-- form-group end.// -->
              <mat-form-field class="example-full-width" appearance="outline"
                style="width: 40%; margin-left: 35px; margin-top: 10px">
                <mat-label>Book Sale Price</mat-label>
                <input matInput type="number" formControlName="saleprice" (change)="getSalePrice($event)" />
              </mat-form-field>
              <!-- form-group end.// -->
              <mat-form-field *ngIf="displayDiscount" class="example-full-width" appearance="outline"
                style="width: 40%; margin-left: 35px; margin-top: 10px">
                <mat-label>Discount upto</mat-label>
                <input matInput type="date" formControlName="discountupto" />
              </mat-form-field>
            </div>
            <div class="form-row">
              <mat-form-field class="example-full-width" appearance="outline"
                style="width: 25%; margin-left: 35px; margin-top: 10px">
                <mat-label>Book Edition</mat-label>
                <input matInput type="text" formControlName="bookedition" readonly />
              </mat-form-field>
              <mat-form-field class="example-full-width" appearance="outline"
                style="width: 25%; margin-left: 35px; margin-top: 10px">
                <mat-label>Date</mat-label>
                <input matInput type="date" formControlName="bookedtndate" />
              </mat-form-field>
              <mat-form-field class="example-full-width" appearance="outline"
                style="width: 25%; margin-left: 35px; margin-top: 10px">
                <mat-label>Copies</mat-label>
                <input matInput type="number" formControlName="bookedtncpoies" />
              </mat-form-field>
            </div>

            <div class="form-row">
              <mat-form-field class="example-full-width" appearance="outline" style="width: 40%; margin-left: 35px">
                <mat-label>Opening Stock</mat-label>
                <input matInput type="number" formControlName="opening_stock" />
              </mat-form-field>
              <mat-form-field class="example-full-width" appearance="outline" style="width: 40%; margin-left: 35px">
                <mat-label>लेखक मानधन</mat-label>
                <input matInput type="text" formControlName="author_honorarium" />
              </mat-form-field>
            </div>

            <div class="form-row">
              <!-- form-group end.// -->
              <mat-form-field class="example-full-width" appearance="outline"
                style="width: 28%; margin-left: 35px; margin-top: 10px">
                <mat-label>Book Category</mat-label>
                <input matInput type="text" formControlName="bookcategory" />
              </mat-form-field>
              <mat-form-field class="example-full-width" appearance="outline"
                style="width: 30%; margin-left: 35px; margin-top: 10px">
                <mat-label>is Delivery Charges included in Price</mat-label>
                <mat-select formControlName="isdelivery">
                  <mat-option value="Y">Yes</mat-option>
                  <mat-option value="N">No</mat-option>
                </mat-select>
              </mat-form-field>
              <!-- form-group end.// -->
            </div>

            <div class="form-row">
              <div class="col form-group">
                <label style="margin-left: 35px">Publish Status : </label>
                <select style="margin-left: 35px; width: 60%" class="form-control" placeholder="Select One"
                  formControlName="isupcoming" (change)="getSearchTerm($event)" required>
                  <option value="N">Publish Now</option>
                  <option value="Y">Upcomming</option>
                </select>
              </div>
              <!-- form-group end.// -->

              <div class="col form-group" *ngIf="displayucoming">
                <label>Upcomming Book Date : </label>

                <input style="width: 60%" formControlName="upcomingdate" type="date" class="form-control" />
              </div>
              <!-- form-group end.// -->
            </div>

            <div class="form-row">
              <div class="form-group">
                <label style="margin-left: 35px">Upload Book Image : </label>
                <input style="margin-left: 35px; margin-right: 45px; width: 90%" (change)="ImageUploads($event)"
                  type="file" name="file" ng2FileSelect [uploader]="uploader" class="form-control" placeholder=" " />
              </div>
            </div>
            <div class="form-group">
              <button [disabled]="BookForm.invalid" style="margin-right: 27%; width: 40%" type="submit"
                class="btn btn-danger btn-block">
                Add New Book
              </button>
            </div>
            <!-- form-group// -->
          </form>
        </div>

        <div style="margin-top: 20%" class="tableFixHead">
          <table class="table table-striped text-center table-fixed">
            <thead>
              <th>Sr.No.</th>
              <th>Book Name</th>
              <th>Author</th>
              <th>Price</th>
              <th>Sale Price</th>
              <th>Image</th>
              <th colspan="3">Action</th>
            </thead>
            <tbody>
              <tr *ngFor="let book of this.bookdata; let i = index">
                <td>{{ i + 1 }}</td>
                <td>{{ book["bookname"] }}</td>
                <td>{{ book["author"] }}</td>
                <td>{{ book["price"] }}</td>
                <td>{{ book["saleprice"] }}</td>
                <td>
                  <img src="{{ this.bookimgpath + book['bookimg'] }}" width="60" />
                </td>
                <td>
                  <button type="button" class="btn btn-danger btn-sm" (click)="ViewBooks(book['bookid'])">
                    View
                  </button>
                </td>
                <td>
                  <button type="button" class="btn btn-danger btn-sm" (click)="EditBooks(book['bookid'])">
                    Edit
                  </button>
                </td>
                <td>
                  <button type="button" class="btn btn-danger btn-sm" (click)="removebook(book['bookid'])">
                    Remove
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </mat-tab>

    <mat-tab label="Book Seller Entry">
      <mat-card class="sub" style="margin-top: 20px">
        <form class="form" [formGroup]="subform" (ngSubmit)="addBookSellerEntry()">
          <div class="form-row">
            <mat-form-field appearance="outline" class="example-full-width"
              style="width: 22%; margin-left: 5%; margin-top: 10px">
              <mat-label>Seller Name</mat-label>
              <input matInput formControlName="subname" [matAutocomplete]="auto1" />
              <mat-autocomplete autoActiveFirstOption #auto1="matAutocomplete">
                <mat-option *ngFor="let option of filteredOptions1 | async" [value]="option['name']"
                  (onSelectionChange)="selected_name($event, option)">
                  {{ option["name"] }}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
            <span style="margin-top: 30px; margin-left: 1%">OR</span>
            <mat-form-field appearance="outline" class="example-full-width"
              style="width: 23%; margin-top: 10px; margin-left: 1%" [style.color]="validmobile ? null : 'red'">
              <mat-label>Mobile</mat-label>
              <span matPrefix>+91 &nbsp;</span>
              <input type="tel" #mobiles1 maxlength="10" formControlName="mobileNumber" [matAutocomplete]="auto"
                matInput />
              <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
                <mat-option (onSelectionChange)="selected_name($event, option)"
                  *ngFor="let option of filteredOptions | async" [value]="option['mob_no']">
                  {{ option["mob_no"] }}
                </mat-option>
              </mat-autocomplete>
              <mat-hint align="end">{{ mobiles1.value.length }} / 10</mat-hint>
              <mat-error *ngIf="validmobile">
                Please enter a valid mobile no.
              </mat-error>
              <mat-error *ngIf="validmobile">
                Mobile no. is <strong>required</strong>
              </mat-error>
            </mat-form-field>
          </div>
          <div class="form-row">
            <mat-form-field style="width: 10%; margin-left: 5%" appearance="outline">
              <mat-label>Title</mat-label>
              <mat-select formControlName="title">
                <mat-option *ngFor="let c of titles" value="{{ c['title'] }}">{{
                  c["title"]
                  }}</mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field appearance="outline" class="example-full-width" style="width: 64%; margin-left: 0.5%"
              [style.color]="validatefname ? null : 'red'">
              <mat-label>Name</mat-label>
              <input matInput (change)="validateName($event)" formControlName="name" />

              <mat-error *ngIf="validatefname">
                Please enter a valid name
              </mat-error>
              <mat-error *ngIf="validatefname">
                Name is <strong>required</strong>
              </mat-error>
            </mat-form-field>
          </div>

          <div class="form-row">
            <mat-form-field class="example-full-width" style="width: 75%; margin-left: 5%" appearance="outline">
              <mat-label>Address</mat-label>
              <textarea matInput placeholder="Address" formControlName="address"></textarea>
            </mat-form-field>
          </div>

          <div class="form-row">
            <mat-form-field style="margin-left: 5%; width: 23%" class="example-full-width" appearance="outline">
              <mat-label>Pincode</mat-label>
              <input (change)="getPincode($event)" matInput #postalCode maxlength="6" placeholder=""
                formControlName="pincode" />
              <mat-hint align="end">{{ postalCode.value.length }} / 6</mat-hint>
            </mat-form-field>

            <mat-form-field style="width: 23%; margin-left: 3%" appearance="outline">
              <mat-label>Country</mat-label>
              <mat-select (selectionChange)="getCountry($event)" formControlName="country">
                <mat-option *ngFor="let c of countries" value="{{ c['country_name'] }}">{{ c["country_name"]
                  }}</mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field style="width: 23%; margin-left: 3%" appearance="outline">
              <mat-label>State</mat-label>
              <mat-select (selectionChange)="getState($event)" formControlName="state">
                <mat-option *ngFor="let c of states" value="{{ c['state_name'] }}">{{ c["state_name"] }}
                </mat-option>
              </mat-select>
              <span>{{ selectedState }}</span>
            </mat-form-field>
          </div>

          <div class="form-row">
            <mat-form-field style="width: 35%; margin-left: 5%" appearance="outline">
              <mat-label>District</mat-label>
              <mat-select (selectionChange)="getDistricts($event)" formControlName="district">
                <mat-option *ngFor="let c of cities" value="{{ c['district_name'] }}">{{
                  c["district_name"]
                  }}</mat-option>
              </mat-select>
              <span>{{ selectedCity }}</span>
            </mat-form-field>

            <!-- <mat-form-field class="example-full-width" appearance="outline" style="width: 35%; margin-left: 5%">
              <mat-label>Taluka</mat-label>
              <input matInput formControlName="taluka" />
            </mat-form-field> -->
            <mat-form-field style="width: 35%; margin-left: 5%" appearance="outline">
              <mat-label>Taluka</mat-label>
              <mat-select formControlName="taluka">
                <mat-option *ngFor="let c of talukas" value="{{ c['taluka_name'] }}">{{ c["taluka_name"] }}</mat-option>
              </mat-select>
              <span>{{ selectedTaluka }}</span>
            </mat-form-field>
          </div>

          <div class="form-row">
            <mat-form-field appearance="outline" class="example-full-width" style="margin-left: 5%; width: 35%"
              [style.color]="validmobile ? null : 'red'">
              <mat-label>Mobile</mat-label>
              <span matPrefix>+91 &nbsp;</span>
              <input type="tel" #mobiles (change)="validateMobile($event)" maxlength="10" formControlName="mobileNumber"
                matInput />
              <mat-hint align="end">{{ mobiles.value.length }} / 10</mat-hint>
              <mat-error *ngIf="validmobile">
                Please enter a valid mobile no.
              </mat-error>
              <mat-error *ngIf="validmobile">
                Mobile no. is <strong>required</strong>
              </mat-error>
            </mat-form-field>

            <mat-form-field appearance="outline" class="example-full-width" style="width: 35%; margin-left: 5%"
              [style.color]="validemail ? null : 'red'">
              <mat-label>Email</mat-label>
              <input (change)="validateEmail($event)" type="email" matInput formControlName="email"
                placeholder="Ex. pat@example.com" />
              <mat-error *ngIf="validemail">
                Please enter a valid email address
              </mat-error>
              <mat-error *ngIf="validemail">
                Email is <strong>required</strong>
              </mat-error>
            </mat-form-field>
          </div>

          <div class="form-row">
            <mat-form-field appearance="outline" style="margin-left: 5%; width: 35%">
              <mat-label>Date of Birth</mat-label>
              <input formControlName="dob" type="date" matInput />
            </mat-form-field>

            <mat-form-field appearance="outline" style="margin-left: 5%; width: 35%">
              <mat-label>Marriage Date</mat-label>
              <input formControlName="doa" type="date" matInput />
            </mat-form-field>
          </div>

          <div class="form-row" *ngIf="!this.bookselleravailable">
            <button style="
                margin-left: 20%;
                margin-right: 20%;
                width: 40%;
                margin-bottom: 10px;
              " type="button" (click)="saveBookSellerEntry()" mat-raised-button color="primary"
              class="btn btn-primary btn-block">
              Save
            </button>
          </div>

          <div class="form-row" *ngIf="this.bookselleravailable">
            <button style="
                margin-left: 20%;
                margin-right: 20%;
                width: 40%;
                margin-bottom: 10px;
              " type="button" (click)="updatebookseller()" mat-raised-button color="primary"
              class="btn btn-primary btn-block">
              Update
            </button>
          </div>

          <div class="form-row" style="
              justify-content: center;
              background-color: #8bbdbd;
              margin-bottom: 2%;
              margin-top: 2%;
            ">
            <h2 style="text-align: center; margin-top: 10px; color: black">
              Books Booking Details
            </h2>
          </div>

          <div class="form-row">
            <mat-form-field appearance="outline" style="margin-left: 5%; width: 35%">
              <mat-label>Order No</mat-label>
              <input formControlName="orderno" type="text" matInput readonly />
            </mat-form-field>
            <mat-form-field appearance="outline" style="margin-left: 5%; width: 35%">
              <mat-label>Date</mat-label>
              <input formControlName="bookdate" type="date" (change)="bookingdateChange($event)" matInput />
            </mat-form-field>
          </div>

          <div class="form-row">
            <mat-form-field class="example-full-width" appearance="outline" style="width: 24%; margin-left: 5%">
              <mat-label>Book Name</mat-label>
              <input matInput formControlName="bookname" [matAutocomplete]="autobkn" />
              <mat-autocomplete autoActiveFirstOption #autobkn="matAutocomplete">
                <mat-option (onSelectionChange)="selected_id($event, option)"
                  *ngFor="let option of filteredOptions2 | async" [value]="option['bookname']">
                  {{ option["bookname"] }}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>

            <mat-form-field appearance="outline" style="margin-left: 2.5%; width: 23%">
              <mat-label>Available Stock</mat-label>
              <input formControlName="availablestock" type="text" matInput readonly />
            </mat-form-field>

            <mat-form-field class="example-full-width" appearance="outline" style="width: 23%; margin-left: 2.5%">
              <mat-label>Author Name</mat-label>
              <input matInput formControlName="authorname" [matAutocomplete]="autoathrn" />
              <mat-autocomplete autoActiveFirstOption #autoathrn="matAutocomplete">
                <mat-option (onSelectionChange)="selected_id($event, option)"
                  *ngFor="let option of filteredOptions3 | async" [value]="option['author']">
                  {{ option["author"] }}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
            <mat-checkbox style="margin-left: 02%; margin-top: 15px" formControlName="iscomplimentary"
              [checked]="iscomplementary" (change)="complementry($event.checked)">Complemenatry</mat-checkbox>
          </div>

          <div class="tableFixHead1">
            <table class="table table-bordered border-primary text-center table-fixed">
              <thead>
                <th>Sr.No.</th>
                <th>Book Name</th>
                <th>Author Name</th>
                <th *ngIf="!iscomplementary">Book Price</th>
                <th *ngIf="!iscomplementary">Book Sale Price</th>
                <th *ngIf="!iscomplementary">Discount %</th>
                <th *ngIf="!iscomplementary">Discount Amount</th>
                <th *ngIf="!iscomplementary">Total After Discount</th>
                <th>Book Copies</th>
                <th>Total</th>
                <th *ngIf="!iscomplementary">Shipping/Posting Charges</th>
                <th>Representative Commission %</th>
                <th>Amount</th>
                <th>Remove</th>
              </thead>
              <tbody>
                <tr *ngFor="let book of this.book_data; let i = index">
                  <td>{{ i + 1 }}</td>
                  <td>{{ book["bookname"] }}</td>
                  <td>{{ book["author"] }}</td>
                  <td *ngIf="!iscomplementary">{{ book["price"] }}</td>
                  <td *ngIf="!iscomplementary">{{ book["saleprice"] }}</td>
                  <!-- {{ book["available_stock"] }} -->
                  <td *ngIf="!iscomplementary">
                    <mat-form-field class="example-full-width" appearance="outline" style="width: 30%">
                      <input matInput (change)="bookdiscount($event, book)" formControlName="discount" type="number" />
                    </mat-form-field>
                  </td>
                  <td *ngIf="!iscomplementary">
                    <!-- <div *ngIf="this.amount == 0">
                      {{ book["price"] - book["saleprice"] }}
                    </div>
                    <div *ngIf="this.amount != 0">
                      {{ book["discountamt"] }}
                    </div> -->
                    {{ book["discountamt"] }}
                  </td>
                  <td *ngIf="!iscomplementary">{{ book["totalad"] }}</td>
                  <td>
                    <mat-form-field class="example-full-width" appearance="outline" style="width: 30%">
                      <input matInput (change)="bookcopies1($event, book)" formControlName="bookcopies" type="number" />
                    </mat-form-field>
                  </td>
                  <td>{{ book["total"] }}</td>
                  <td *ngIf="!iscomplementary">
                    <mat-form-field class="example-full-width" appearance="outline" style="width: 30%">
                      <input matInput (change)="bookshipping($event, book)" formControlName="shippingcharging"
                        type="number" />
                    </mat-form-field>
                  </td>
                  <td>
                    <mat-form-field appearance="outline" class="example-full-width" style="width: 30%">
                      <input matInput formControlName="repcompercent" (change)="calcommission($event, book)" />
                      <span matSuffix>%</span>
                    </mat-form-field>
                  </td>
                  <td>
                    {{ book["commissionamt"] }}
                  </td>
                  <td style="display: flex; justify-content: center">
                    <button type="button" class="btn btn-danger" (click)="remove(book)">
                      <mat-icon>clear</mat-icon>
                    </button>
                  </td>
                </tr>
                <tr>
                  <!-- <td></td> -->
                  <td class="big">Total-</td>
                  <td></td>
                  <td></td>
                  <td *ngIf="!iscomplementary"></td>
                  <td *ngIf="!iscomplementary"></td>
                  <td *ngIf="!iscomplementary"></td>
                  <td *ngIf="!iscomplementary"></td>
                  <td *ngIf="!iscomplementary"></td>
                  <td></td>
                  <td>{{ this.total_amt }}</td>
                  <td *ngIf="!iscomplementary">{{ this.shipping_total }}</td>
                  <td>{{ this.comprecentagetotal }}</td>
                  <td>{{ this.commissiontotal }}</td>
                  <td></td>
                </tr>
                <tr>
                  <td></td>
                  <td *ngIf="!iscomplementary"></td>
                  <td *ngIf="!iscomplementary"></td>
                  <td *ngIf="!iscomplementary"></td>
                  <td *ngIf="!iscomplementary"></td>
                  <td></td>
                  <td class="big">Total</td>
                  <td class="bgcolor" colspan="2">
                    {{ this.total_amt + this.shipping_total }}
                  </td>
                  <td colspan="5"></td>
                </tr>
              </tbody>
            </table>
          </div>

          <div class="form-row" style="margin-top: 2%">
            <mat-form-field class="example-full-width" appearance="outline" style="width: 35%; margin-left: 5%">
              <mat-label>Current Representative Name</mat-label>
              <input matInput formControlName="crepname" [matAutocomplete]="autorep" />
              <mat-autocomplete autoActiveFirstOption #autorep="matAutocomplete">
                <mat-option (onSelectionChange)="selected_rep_name($event, option)"
                  *ngFor="let option of filteredOptions4 | async" [value]="option['name']">
                  {{ option["name"] }}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>

            <mat-form-field appearance="outline" class="example-full-width" style="margin-left: 5%; width: 35%"
              [style.color]="validmobile ? null : 'red'">
              <mat-label>Representative Mobile No</mat-label>
              <span matPrefix>+91 &nbsp;</span>
              <input type="tel" #m1 (change)="validateMobile($event)" maxlength="10" formControlName="repmobno1"
                matInput />
              <mat-hint align="end">{{ m1.value.length }} / 10</mat-hint>
              <mat-error *ngIf="validmobile">
                Please enter a valid mobile no.
              </mat-error>
              <mat-error *ngIf="validmobile">
                Mobile no. is <strong>required</strong>
              </mat-error>
            </mat-form-field>
          </div>
          <div class="form-row">
            <mat-form-field appearance="outline" class="example-full-width" style="width: 35%; margin-left: 5%">
              <mat-label>Office Representative</mat-label>
              <input type="text" matInput formControlName="officerep" />
            </mat-form-field>
          </div>

          <!-- <div class="form-row">
            <label style="margin-left: 5%; margin-top: 20px"
              >Representative Commission:</label
            >
            <mat-form-field
              appearance="outline"
              class="example-full-width"
              style="width: 25%; margin-left: 4%"
            >
              <mat-label>%</mat-label>
              <input
                type="number"
                matInput
                formControlName="repcompercent"
                (change)="calcommission($event)"
              />
            </mat-form-field>
            <mat-form-field
              appearance="outline"
              class="example-full-width"
              style="width: 25%; margin-left: 4%"
            >
              <mat-label>Amt.</mat-label>
              <input type="number" matInput formControlName="repcomprice" />
            </mat-form-field>
          </div> -->

          <div class="form-row">
            <mat-form-field class="example-full-width" appearance="outline" style="width: 35%; margin-left: 5%">
              <mat-label>Dispatch to</mat-label>
              <input matInput formControlName="dispatchto" />
            </mat-form-field>

            <mat-radio-group aria-label="Select an option" style="width: 35%; margin-left: 5%" formControlName="option"
              (change)="select($event)">
              <mat-radio-button value="Representative">Representative</mat-radio-button>
              <mat-radio-button value="Client" style="margin-left: 20px">Client</mat-radio-button>
            </mat-radio-group>
          </div>

          <div class="form-row">
            <mat-form-field appearance="outline" style="margin-left: 5%; width: 35%">
              <mat-label>Delivery Mode</mat-label>
              <mat-select (selectionChange)="durationn($event)" formControlName="deliverymode">
                <mat-option value="By Hand">By Hand</mat-option>
                <mat-option value="Courier">Courier</mat-option>
                <mat-option value="Post">Post</mat-option>
                <mat-option value="S.T.">S.T.</mat-option>
                <mat-option value="Railway">Railway</mat-option>
                <mat-option value="Transport">Transport</mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="form-row">
            <label style="margin-left: 5%; font-weight: bold">Payment Details</label>
          </div>

          <div class="form-row">
            <mat-form-field appearance="outline" class="example-full-width" style="width: 35%; margin-left: 5%">
              <mat-label>Cheque/Draft No:</mat-label>
              <input matInput formControlName="cdno" />
            </mat-form-field>
            <mat-form-field appearance="outline" class="example-full-width" style="width: 35%; margin-left: 5%">
              <mat-label>Cheque Date</mat-label>
              <input matInput [matDatepicker]="chequedate" formControlName="chequeDate" />
              <mat-datepicker-toggle matSuffix [for]="chequedate"></mat-datepicker-toggle>
              <mat-datepicker #chequedate></mat-datepicker>
            </mat-form-field>
          </div>

          <div class="form-row">
            <mat-form-field appearance="outline" class="example-full-width" style="width: 35%; margin-left: 5%">
              <mat-label>Bank Name:</mat-label>
              <input matInput formControlName="bankname" />
            </mat-form-field>
            <mat-form-field appearance="outline" style="margin-left: 5%; width: 35%">
              <mat-label>Payment Mode</mat-label>
              <mat-select (selectionChange)="selectedpymtmode($event)" formControlName="pymtmode">
                <mat-option value="Online">Online</mat-option>
                <mat-option value="Cash">Cash</mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="form-row">
            <mat-form-field appearance="outline" class="example-full-width" style="width: 35%; margin-left: 5%">
              <mat-label>Deposit Amount:</mat-label>
              <input matInput formControlName="depositamt" />
            </mat-form-field>
          </div>

          <div class="form-group">
            <button style="margin-left: 5%; margin-right: 25%; width: 60%" [disabled]="subform.invalid" type="submit"
              mat-raised-button color="primary" class="btn btn-primary btn-block">
              Add Book Seller
            </button>
          </div>
        </form>
      </mat-card>
    </mat-tab>

    <mat-tab label="Book Invoice">
      <div class="add-new-subscriber mat-elevation-z4">
        <!-- Customer invoicing data-->
        <mat-form-field appearance="fill" style="margin-top: 5%">
          <mat-label>Enter a date range</mat-label>
          <mat-date-range-input [formGroup]="invoicegetCustBook" [rangePicker]="picker3">
            <input matStartDate formControlName="start" placeholder="Start date" />
            <input matEndDate formControlName="end" placeholder="End date" (dateChange)="getCustDateRange()" />
          </mat-date-range-input>
          <mat-datepicker-toggle matSuffix [for]="picker3"></mat-datepicker-toggle>
          <mat-date-range-picker #picker3></mat-date-range-picker>

          <mat-error *ngIf="
              invoicegetCustBook.controls.start.hasError('matStartDateInvalid')
            ">Invalid start date
          </mat-error>
          <mat-error *ngIf="
              invoicegetCustBook.controls.end.hasError('matEndDateInvalid')
            ">Invalid end date
          </mat-error>
        </mat-form-field>

        <mat-form-field appearance="fill" style="margin-left: 3%">
          <mat-label>Customer Name</mat-label>
          <mat-select (selectionChange)="SelectCustomer($event)">
            <mat-option *ngFor="let rep of uniquedata" value="{{ rep['id'] }}">{{ rep["name"] }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field appearance="fill" style="margin-left: 3%">
          <mat-label>Invoice Type</mat-label>
          <mat-select (selectionChange)="getInvoiceType($event)">
            <mat-option value="Y">GST</mat-option>
            <mat-option value="N">Non-GST</mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field class="example-form-field" appearance="fill" style="margin-left: 3%"
          *ngIf="displaygstNumberInput">
          <mat-label>GST Number : </mat-label>
          <input matInput type="text" [(ngModel)]="gstNumber" (ngModelChange)="getGstNumber($event)" />
        </mat-form-field>

        <mat-form-field appearance="fill" style="margin-left: 3%;">
          <mat-label>Date</mat-label>
          <input [(ngModel)]="invoicedate" type="date" (ngModelChange)="invoicedateChange($event)" matInput required />
        </mat-form-field>

        <button style="margin-left: 2%" (click)="generateCustInvoice()" mat-raised-button color="primary">
          Generate Invoice
        </button>

        <div style="margin-top: 20px" class="tableFixHead">
          <button type="button" (click)="exportexcel()" class="btn btn-danger">
            Export
          </button>
          <table class="table table-striped text-center">
            <thead>
              <tr>
                <th>Sr.No.</th>
                <th>Order No</th>
                <th>Date</th>
                <th>Book Seller Name</th>
                <th>Mobile No</th>
                <th>Representative Name</th>
                <th>Total Amount</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let book of this.bookinvoicedata; let i = index">
                <td>{{ i + 1 }}</td>
                <td>{{ book["orderno"] }}</td>
                <td>{{ book["date"] | date: "dd-MM-yyy" }}</td>
                <td>{{ book["name"] }}</td>
                <td>{{ book["mob"] }}</td>
                <td>{{ book["repname"] }}</td>
                <td>{{ book["total"] }}</td>
                <td>
                  <button class="btn btn-danger btn-sm" type="button" (click)="cancel(book)">
                    Cancel
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </mat-tab>

    <mat-tab label="Pending Credit Voucher">
      <div class="add-new-subscriber1 mat-elevation-z4">
        <button class="noPrint" style=" margin-left: 1%; margin-top: 2%;" mat-raised-button color="primary"
        printSectionId="print-section" [useExistingCss]="true" ngxPrint>
        Print
      </button>
      <button class="noPrint" style="margin-top: 2%; margin-left: 1%" (click)="exportExcel('Pending_credit_vocuher')"
      mat-raised-button color="primary">
      Export
  </button>
        <div style="margin-top: 20px; margin-left: 20px" class="tableFixHead" id="print-section">
          <table class="table table-striped text-center">
            <thead>
              <tr>
                <th>Action</th>
                <th>Sr.No.</th>
                <th>Voucher No.</th>
                <th>Voucher Date</th>
                <th>Entry Date</th> 
                <th>Rep.Name</th>
                <th>Book Seller Name</th>
                <th>Book Name</th>
                <th>Amount</th>
                <th>Commission</th>
                <th>Remaining Amount</th>
                <th>Cheque no</th>
                <th>Bank Name</th>
                <th>Cheque date</th>
                <th>Branch Name</th>
                
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let book of this.getallVouchersData; let i = index">
                <td>
                  <button type="button" class="btn btn-danger btn-sm" (click)="displayItemId(book, bookpymtrecpt)">
                    Mark as Completed
                  </button>
                </td>
                <td>{{ i + 1 }}</td>
                
                <td>{{ book["voucher_no"] }}</td>
                <td>{{ book["voucher_date"] }}</td>
                <td>{{ book["entry_date"] }}</td>
                
                <td>{{ book["rep_name"] }}</td>
                <td>{{ book["book_seller_name"] }}</td>
                <td>{{ book["book_names"] }}</td>
                <td>₹ {{ book["price"] }}</td>
                <td>{{ book["commission"] }}</td>
                <td>{{ book["remaining_amt"] }}</td>
                <td>
                  <span *ngIf="book['cheque_no'] == 0">&#8211;</span>
                  <span *ngIf="book['cheque_no'] != 0">
                    {{ book["cheque_no"] }}
                  </span>
                </td>
                <td>{{ book["bank"] }}</td>
                <td>
                  <span *ngIf="book['cheque_no'] == 0">&#8211;</span>
                  <span *ngIf="book['cheque_no'] != 0">
                    {{ book["chequeDate"] | date: "dd-MM-yyy" }}
                  </span>
                </td>
                <td>{{ book["branch_name"] }}</td>
                
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </mat-tab>

    <mat-tab label="Book Payment Receipt">
      <mat-card>
        <form class="form" [formGroup]="bookpymtrecptform">
          <div class="form-row">
            <mat-form-field appearance="outline" style="width: 40%; margin-left: 50%">
              <mat-label>Date</mat-label>
              <input matInput type="date" formControlName="date" readonly />
            </mat-form-field>
          </div>
          <div class="form-row">
            <mat-form-field appearance="outline" style="width: 40%; margin-left: 5%">
              <mat-label>Credit Voucher No</mat-label>
              <input matInput type="text" formControlName="crdtvno" readonly />
            </mat-form-field>
            <mat-form-field appearance="outline" style="width: 40%; margin-left: 5%">
              <mat-label>Receipt Date</mat-label>
              <input matInput type="date" formControlName="recptdate" readonly />
            </mat-form-field>
          </div>
          <div class="form-row">
            <mat-form-field appearance="outline" style="width: 40%; margin-left: 5%">
              <mat-label>Representative Name</mat-label>
              <input matInput type="text" formControlName="repname" readonly />
            </mat-form-field>
            <mat-form-field appearance="outline" style="width: 40%; margin-left: 5%">
              <mat-label>Receipt No</mat-label>
              <input matInput type="text" formControlName="recptno" readonly />
            </mat-form-field>
          </div>
          <div class="form-row">
            <mat-form-field appearance="outline" style="width: 27%; margin-left: 5%">
              <mat-label>Credit Voucher Amount</mat-label>
              <input matInput type="number" formControlName="crdtvamt" readonly />
            </mat-form-field>
            <mat-form-field appearance="outline" style="width: 27%; margin-left: 2%">
              <mat-label>Select Entry Amount</mat-label>
              <input matInput type="number" formControlName="seamt" readonly />
            </mat-form-field>
            <mat-form-field appearance="outline" style="width: 27%; margin-left: 2%">
              <mat-label>Balance Amount</mat-label>
              <input matInput type="number" formControlName="balamt" readonly />
            </mat-form-field>
          </div>
          <div class="form-row">
            <mat-form-field appearance="outline" style="width: 40%; margin-left: 5%">
              <mat-label>Book Seller Name</mat-label>
              <input matInput type="text" formControlName="booksellername" readonly />
            </mat-form-field>
          </div>
          <div class="form-group">
            <mat-form-field appearance="outline" style="width: 40%; margin-left: 5%">
              <mat-label>Book Seller Name Search</mat-label>
              <input formControlName="booksellernamesearch" type="text" matInput [matAutocomplete]="autobsname" />
              <mat-autocomplete autoActiveFirstOption #autobsname="matAutocomplete">
                <mat-option (onSelectionChange)="selectedname($event, option)"
                  *ngFor="let option of filteredOptions6 | async" [value]="option['name']">
                  {{ option["name"] }}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
            <button type="button" mat-raised-button color="primary" style="margin-left: 5%"
              (click)="searchbooksellername()">
              Search
            </button>
          </div>
          <div style="margin-top: 20px; margin-left: 20px" class="tableFixHead1">
            <table class="table table-bordered table-hover text-center">
              <thead>
                <tr>
                  <th>Invoice Date</th>
                  <th>Invoice No</th>
                  <th>Book Seller Name</th>
                  <th>Invoice Amount</th>
                  <th>Amount Remaining</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let book of this.booksellernamedata">
                  <td>{{ book["invoice_date"] | date: "dd-MM-yyy" }}</td>
                  <td>{{ book["invoice_no"] }}</td>
                  <td>{{ book["receiver_name"] }}</td>
                  <td>{{ book["invoice_total"] }}</td>
                  <td>{{ book["remaining_amt"] }}</td>
                  <td>
                    <button [(disabled)]="this.click" type="button" mat-raised-button color="primary"
                      (click)="selectedItem(book)">
                      Select
                    </button>
                    <button style="margin-left: 1%" type="button" mat-raised-button class="btn btn-info"
                      (click)="updatePerBooksRate(book)">
                      Update
                    </button>
                  </td>
                </tr>
                <!-- <tr>
                  <td colspan="5">Total</td>
                </tr> -->
              </tbody>
            </table>
          </div>
          <div class="form-group">
            <button style="
                margin-left: 5%;
                margin-right: 25%;
                width: 60%;
                margin-top: 20px;
              " [disabled]="bookpymtrecptform.invalid" type="button" mat-raised-button color="primary"
              class="btn btn-primary btn-block" (click)="addbookpymtrcpt()">
              Add
            </button>
          </div>
        </form>
      </mat-card>
    </mat-tab>

    <mat-tab label="Book Credit Note">
      <mat-card>
        <form [formGroup]="bookcrdtnoteForm">
          <div class="form-row">
            <mat-form-field appearance="outline" class="example-full-width" style="width: 35%; margin-left: 05%">
              <mat-label>Credit Note No</mat-label>
              <input matInput formControlName="crdtnoteno" />
            </mat-form-field>
            <mat-form-field appearance="outline" class="example-full-width" style="width: 35%; margin-left: 05%">
              <mat-label>Date</mat-label>
              <input matInput type="date" formControlName="date" />
            </mat-form-field>
          </div>
          <div class="form-row">
            <mat-form-field class="example-full-width" appearance="outline" style="width: 35%; margin-left: 5%">
              <mat-label>Representative Name</mat-label>
              <input matInput formControlName="repname" [matAutocomplete]="autorepname" />
              <mat-autocomplete autoActiveFirstOption #autorepname="matAutocomplete">
                <mat-option (onSelectionChange)="selected_repname($event, option)"
                  *ngFor="let option of filteredOptions10 | async" [value]="option['name']">
                  {{ option["name"] }}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
            <mat-form-field appearance="outline" class="example-full-width" style="width: 35%; margin-left: 05%">
              <mat-label>Office Representative Name</mat-label>
              <input matInput type="text" formControlName="offrepname" />
            </mat-form-field>
          </div>
          <div class="form-row">
            <mat-form-field appearance="outline" class="example-full-width" style="width: 60%; margin-left: 5%">
              <mat-label>Book Seller Name</mat-label>
              <input matInput formControlName="bsname" [matAutocomplete]="autobs" />
              <mat-autocomplete autoActiveFirstOption #autobs="matAutocomplete">
                <mat-option *ngFor="let option of filteredOptions9 | async" [value]="option['name']"
                  (onSelectionChange)="selected_booksellername($event, option)">
                  {{ option["name"] }}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
          </div>
        </form>
        <div>
          <div>
            <mat-progress-spinner *ngIf="showrcpt" style="margin-left: auto; margin-right: auto" color="warn"
              mode="indeterminate">
            </mat-progress-spinner>
          </div>
          <div *ngIf="!showrcpt">
            <table class="table table-striped">
              <thead>
                <tr>
                  <th>Invoice No</th>
                  <th>Invoice Date</th>
                  <th>Book Seller Name</th>
                  <th>Amount</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let item of this.BookBillingData; let i = index">
                  <td>{{ item["invoice_no"] }}</td>
                  <td>{{ item["invoice_date"] }}</td>
                  <td>{{ item["receiver_name"] }}</td>
                  <td>
                    <span *ngIf="item['remaining_amt'] != 0">{{
                      item["remaining_amt"]
                      }}</span>
                    <span *ngIf="item['remaining_amt'] == 0">{{
                      item["invoice_total"]
                      }}</span>
                  </td>
                  <td style="display: flex; justify-items: center">
                    <button type="button" class="btn btn-primary" mat-raised-button (click)="billSelected(item)">
                      Select
                    </button>
                  </td>
                </tr>
                <tr *ngIf="showmsg">
                  <td colspan="5" style="text-align: center">
                    <h2 style="color: red">Data is not Available</h2>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div *ngIf="displaybooks" style="margin-top: 5%">
          <table class="table table-striped">
            <thead>
              <tr>
                <th>Book Name</th>
                <th>Net Amount</th>
                <th>Copies</th>
                <th>Shipping Charges</th>
                <th>Total</th>
                <th>Receive Amount</th>
                <th>Pending Amount</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of this.booksDataofCrdtNote; let i = index">
                <td>{{ item["book_name"] }}</td>
                <td>
                  {{
                  ConvertToInt(item["netamt"]) -
                  ConvertToInt(item["discountamt"])
                  }}
                </td>
                <td>{{ item["copies"] }}</td>
                <td>{{ item["shipping_charges"] }}</td>
                <td>
                  {{
                  ConvertToInt(item["total"]) +
                  ConvertToInt(item["shipping_charges"])
                  }}
                </td>
                <td>{{ item["received_amt"] }}</td>
                <td>{{ item["remaining_amt"] }}</td>
                <td style="display: flex; justify-items: center">
                  <button type="button" class="btn btn-primary" mat-raised-button (click)="booksSelected(item)">
                    Select
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div *ngIf="selectedbooks" style="margin-top: 5%" class="tableFixHead1">
          <form [formGroup]="bookselectedForm">
            <table class="table table-bordered">
              <thead>
                <tr>
                  <th>Book Name</th>
                  <th>Net Amount</th>
                  <th>Copies</th>
                  <th>Shipping Charges</th>
                  <th>Total</th>
                  <th>Receive Amount</th>
                  <th>Pending Amount</th>
                  <th>Return Book Copies</th>
                  <th>Credit Note Amount</th>
                  <th>Remark</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let item of this.selectedBooks; let i = index">
                  <td>{{ item["book_name"] }}</td>
                  <td>{{ item["netamt"] }}</td>
                  <td>{{ item["copies"] }}</td>
                  <td>{{ item["shipping_charges"] }}</td>
                  <td>{{ item["total"] }}</td>
                  <td>{{ item["received_amt"] }}</td>
                  <td>{{ item["remaining_amt"] }}</td>
                  <td>
                    <mat-form-field appearance="outline" class="example-full-width" style="width: 30%">
                      <mat-label></mat-label>
                      <input matInput type="number" formControlName="returncopies" (change)="copies($event, item)" />
                    </mat-form-field>
                  </td>
                  <td>
                    <mat-form-field appearance="outline" class="example-full-width" style="width: 30%">
                      <mat-label></mat-label>
                      <input matInput type="number" formControlName="crdtnoteamt"
                        (change)="crdtNoteAmt($event, item)" />
                    </mat-form-field>
                  </td>
                  <td>
                    <mat-form-field appearance="outline" class="example-full-width" style="width: 100%">
                      <mat-label></mat-label>
                      <textarea matInput type="text" formControlName="remark"
                        (change)="remark($event, item)"></textarea>
                    </mat-form-field>
                  </td>
                </tr>
                <tr>
                  <td colspan="8"></td>
                  <td>{{ this.crdtnotetotal }}</td>
                  <td></td>
                </tr>
              </tbody>
            </table>
          </form>
        </div>
        <div class="form-group">
          <button class="btn btn-primary" mat-raised-button type="submit" (click)="submitCrdtNote()"
            style="margin-right: 42%; width: 25%">
            Save
          </button>
        </div>
      </mat-card>
    </mat-tab>

    <mat-tab label="Book Credit Note Report">
      <mat-card style="padding: 10px 10px">
        <div class="tableFixHead" style="margin-top: 2%">
          <table class="table table-striped table-hover text-center">
            <thead>
              <tr>
                <td>Credit Note No.</td>
                <td>Date</td>
                <td>Book Seller Name</td>
                <td>Representative Name</td>
                <td>Credit Note Total</td>
                <td>Action</td>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of this.FilteredBookCreditNoteData">
                <td>{{ item["crdtnote_no"] }}</td>
                <td>{{ item["date"] | date: "dd-MM-yyy" }}</td>
                <td>{{ item["book_seller_name"] }}</td>
                <td>{{ item["rep_name"] }}</td>
                <td>{{ item["crdtnote_total"] }}</td>
                <td>
                  <button type="button" mat-raised-button class="btn btn-primary" (click)="showcrdtrcpt(item)">
                    Show
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </mat-card>
    </mat-tab>

    <mat-tab label="Manage BookDelivery">
      <div class="add-new-subscriber mat-elevation-z4" style="overflow-x: scroll !important">
        <div style="margin-top: 20px; overflow: auto; height: 40%">
          <!-- class="tableFixHead" -->
          <mat-form-field appearance="fill" style="margin-top: 5%; margin-left: 2%">
            <mat-label>Enter a date range</mat-label>
            <mat-date-range-input [formGroup]="Managerange" [rangePicker]="picker4">
              <input matStartDate formControlName="start" placeholder="Start date" />
              <input matEndDate formControlName="end" placeholder="End date"
                (dateChange)="getChangedDate_managebook()" />
            </mat-date-range-input>
            <mat-datepicker-toggle matSuffix [for]="picker4"></mat-datepicker-toggle>
            <mat-date-range-picker #picker4></mat-date-range-picker>

            <mat-error *ngIf="Managerange.controls.start.hasError('matStartDateInvalid')">Invalid start date
            </mat-error>
            <mat-error *ngIf="Managerange.controls.end.hasError('matEndDateInvalid')">Invalid end date</mat-error>
          </mat-form-field>

          <mat-form-field class="example-form-field" appearance="fill" style="margin-left: 3%">
            <mat-label>Customer Name</mat-label>
            <input matInput type="text" [(ngModel)]="custname" (ngModelChange)="getcust_name($event)" />
            <button *ngIf="custname" matSuffix mat-icon-button aria-label="Clear" (click)="reset_custname($event)">
              <mat-icon>close</mat-icon>
            </button>
          </mat-form-field>

          <mat-form-field class="example-form-field" appearance="fill" style="margin-left: 3%">
            <mat-label>Representative Name</mat-label>
            <input matInput type="text" [(ngModel)]="repname" (ngModelChange)="getRep_name($event)" />
            <button *ngIf="repname" matSuffix mat-icon-button aria-label="Clear" (click)="reset_repname($event)">
              <mat-icon>close</mat-icon>
            </button>
          </mat-form-field>
          <div class="tableFixHead">
            <table class="table table-striped text-center">
              <thead>
                <tr>
                  <th>Sr.No.</th>
                  <th>Date</th>
                  <th>Order No</th>
                  <th>Client Name</th>
                  <th>Representative Name</th>
                  <th>Book Name</th>
                  <th>Copies</th>
                  <th>Is Complimentary</th>
                  <th>Bill No. <br />Date</th>
                  <th>Receipt No. <br />Date</th>
                  <th>Dispatch To</th>
                  <th>Dispatch Mode</th>
                  <th>Mark as Deliverd</th>
                </tr>
              </thead>
              <tbody>
                <!-- *ngFor="
                  let book of this.FilteredDeliveryManageBooks;
                  let i = index
                " -->
                <tr *ngFor="
                    let book of this.FilteredDeliveryManageBooks;
                    let i = index
                  ">
                  <td>{{ i + 1 }}</td>
                  <!-- <td>{{ book["received_date"] | date: "dd-MM-yyy" }}</td>
                <td>{{ book["name"] }}</td>
                <td>{{ book["book_name"] }}</td>
                <td>{{ book["book_qty"] }}</td>
                <td>{{ book["book_amount"] }}</td>
                <td>{{ book["rep_name"] }}</td> -->
                  <td>{{ book["bookingdate"] | date: "dd-MM-yyy" }}</td>
                  <td>{{ book["order_no"] }}</td>
                  <td>{{ book["name"] }}</td>
                  <td>{{ book["rep"] }}</td>
                  <td>{{ book["bookname"] }}</td>
                  <td>{{ book["bookcopies"] }}</td>
                  <td>{{ book["iscomplimentary"] }}</td>
                  <td>
                    {{ book["invoice_no"] }} <br />
                    {{ book["invoice_date"] | date: "dd-MM-yyy" }}
                  </td>
                  <td>
                    {{ book["rcptno"] }} <br />
                    {{ book["rcptdate"] | date: "dd-MM-yyy" }}
                  </td>
                  <td>{{ book["dispatchto"] }}</td>
                  <td>{{ book["deliverymode"] }}</td>
                  <td>
                    <button type="button" class="btn btn-danger btn-sm" (click)="markAsDelivered(book)">
                      Mark as Delivered
                    </button>
                  </td>
                  <!-- <td>
                  <button
                    type="button"
                    class="btn btn-danger btn-sm"
                    (click)="EditBookDelivery(book['id'])"
                  >
                    Edit
                  </button>
                </td>
                <td>
                  <button
                    type="button"
                    class="btn btn-danger btn-sm"
                    (click)="markAsDelivered(book['id'])"
                  >
                    Mark as Delivered
                  </button>
                </td>
                <td>
                  <button
                    type="button"
                    class="btn btn-danger btn-sm"
                    (click)="CancelBook(book['bid'])"
                  >
                    Cancel
                  </button>
                </td> -->
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </mat-tab>

    <mat-tab label="Book Registartion Report">
      <mat-form-field appearance="fill" style="margin-top: 5%">
        <mat-label>Enter a date range</mat-label>
        <mat-date-range-input [formGroup]="rangeRegistration" [rangePicker]="picker1">
          <input matStartDate formControlName="start" placeholder="Start date" />
          <input matEndDate formControlName="end" placeholder="End date" (dateChange)="getChangedDateRegistration()" />
        </mat-date-range-input>
        <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
        <mat-date-range-picker #picker1></mat-date-range-picker>

        <mat-error *ngIf="range.controls.start.hasError('matStartDateInvalid')">Invalid start date</mat-error>
        <mat-error *ngIf="range.controls.end.hasError('matEndDateInvalid')">Invalid end date</mat-error>
      </mat-form-field>

      <mat-form-field class="example-form-field" appearance="fill" style="margin-left: 3%">
        <mat-label>Customer Name</mat-label>
        <input matInput type="text" [(ngModel)]="cust_name_registration"
          (ngModelChange)="getcust_name_registration($event)" />
        <button *ngIf="cust_name_registration" matSuffix mat-icon-button aria-label="Clear"
          (click)="reset_custname_registration($event)">
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>

      <mat-form-field class="example-form-field" appearance="fill" style="margin-left: 3%">
        <mat-label>Representative Name</mat-label>
        <input matInput type="text" [(ngModel)]="rep_name_registration"
          (ngModelChange)="getRep_name_registration($event)" />
        <button *ngIf="rep_name_registration" matSuffix mat-icon-button aria-label="Clear"
          (click)="reset_repname_registration($event)">
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>

      <!-- <mat-form-field appearance="fill" style="margin-left: 3%">
        <mat-label>Payment Status</mat-label>
        <mat-select (selectionChange)="FilterPayment($event)">
          <mat-option value="all">All</mat-option>
          <mat-option value="Completed">Completed</mat-option>
          <mat-option value="Pending">Pending</mat-option>
        </mat-select>
      </mat-form-field> -->

      <mat-form-field appearance="fill" style="margin-left: 3%">
        <mat-label>Book Name</mat-label>
        <mat-select (selectionChange)="FilterBook($event)">
          <mat-option *ngFor="let book_name of bookregistration_bookname" value="{{ book_name }}">{{ book_name
            }}</mat-option>
        </mat-select>
      </mat-form-field>

      <div class="add-new-subscriber mat-elevation-z4" style="overflow-x: scroll !important" id="excel-table">
        <div style="margin-top: 20px" class="tableFixHead">
          <button type="button" (click)="exportexcel()" class="btn btn-danger">
            Export
          </button>
          <table class="table table-striped text-center">
            <thead>
              <tr>
                <th>अ.क्र.</th>
                <th>नोंदणी दिनांक</th>
                <th>नोंदणी क्र.</th>
                <th>ग्राहकाचे नाव</th>
                <th>ठिकाण</th>
                <th>संपर्क क्रमांक</th>
                <th>प्रतिनिधींचे नाव</th>
                <th>कार्यालय प्रतिनिधींचे नाव</th>
                <th>पुस्तकाचे नाव</th>
                <!-- <th>लेखकाचे नाव</th> -->
                <th>पुस्तकाच्या प्रति</th>
                <th>Is Complimentary</th>
                <th>पुस्तकाचे मूल्य</th>
                <!-- <th>मूळ किंमत</th> -->
                <th>सवलत मूल्य</th>
                <th>निव्वळ रक्कम</th>
                <th>कुरिअर चार्जेस</th>
                <th>एकूण रक्कम</th>
                <th>जमा</th>
                <th>येणे</th>
                <th>Invoice No</th>
                <th>पावती क्र.</th>
                <th>प्रतिनिधींचे वर्तन</th>
                <th>पाठविलेले माध्यम</th>
                <th>पुस्तक पाठवल्याचा दिनांक</th>
                <th>Remark</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="
                  let book of this.FilteredBookRegistration1;
                  let i = index
                ">
                <td>{{ i + 1 }}</td>
                <td>{{ book["booking_date"] | date: "dd-MM-yyy" }}</td>
                <td>{{ book["orderno"] }}</td>
                <td>{{ book["name"] }}</td>
                <td>{{ book["district"] }}</td>
                <td>{{ book["mob_no"] }}</td>
                <td>{{ book["crep_name"] }}</td>
                <td>{{ book["office_rep_name"] }}</td>
                <td>{{ book["bookname"] }}</td>
                <!-- <td>{{ book["authorname"] }}</td> -->
                <td>{{ book["bookcopies"] }}</td>
                <td>{{ book["iscomplimentary"] }}</td>
                <td>{{ book["bookprice"] }}</td>
                <!-- <td>{{ book["bookprice"] }}</td> -->
                <td>{{ book["totalafterdiscount"] }}</td>
                <td>{{ book["total"] }}</td>
                <td>{{ book["shipping_charges"] }}</td>
                <td>
                  {{
                  ConvertToInt(book["total"]) +
                  ConvertToInt(book["shipping_charges"])
                  }}
                </td>
                <td>{{ book["receivedamt"] }}</td>
                <td>{{ book["remainingamt"] }}</td>
                <td>{{ book["invoiceno"] }}</td>
                <td>{{ book["rcptno"] }}</td>
                <td>{{ book["repcommissionamt"] }}</td>
                <td>{{ book["delivery_mode"] }}</td>
                <td></td>
                <td></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </mat-tab>

    <mat-tab label="Bookwise Report">
      <form class="form" [formGroup]="bookName">
        <mat-form-field appearance="fill" style="margin-top: 5%">
          <mat-label>Enter a date range</mat-label>
          <mat-date-range-input [formGroup]="BookrangeRegistration" [rangePicker]="picker2">
            <input matStartDate formControlName="start" placeholder="Start date" />
            <input matEndDate formControlName="end" placeholder="End date" (dateChange)="getBookDateRange()" />
          </mat-date-range-input>
          <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
          <mat-date-range-picker #picker2></mat-date-range-picker>

          <mat-error *ngIf="range.controls.start.hasError('matStartDateInvalid')">Invalid start date</mat-error>
          <mat-error *ngIf="range.controls.end.hasError('matEndDateInvalid')">Invalid end date</mat-error>
        </mat-form-field>

        <mat-form-field class="example-full-width" appearance="outline" style="width: 25%; margin-left: 3%">
          <mat-label>Book Name</mat-label>
          <input matInput formControlName="bookname" [matAutocomplete]="autobkwn" />
          <mat-autocomplete autoActiveFirstOption #autobkwn="matAutocomplete">
            <mat-option (onSelectionChange)="getBookname($event, option)"
              *ngFor="let option of filteredOptions7 | async" [value]="option['bookname']">
              {{ option["bookname"] }}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>

        <button style="margin-left: 3%" (click)="getBookwiseReportonclick()" mat-raised-button color="primary">
          Get Report
        </button>
      </form>
      <!-- <button
        style="margin-left: 3%"
        (click)="displayFilter = !displayFilter"
        mat-raised-button
        color="primary"
      >
        More Filters
      </button> -->

      <mat-form-field *ngIf="displayFilter" appearance="fill">
        <mat-label>Representative Name</mat-label>
        <mat-select name="rep" (selectionChange)="getRepname($event)">
          <mat-option *ngFor="let rep of repnameList" [value]="rep">
            {{ rep }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field *ngIf="displayFilter" appearance="fill" style="margin-left: 3%">
        <mat-label>Payment Mode</mat-label>
        <mat-select name="paymode" (selectionChange)="getpaymentmode($event)">
          <mat-option *ngFor="let pay of paymodes" [value]="pay">
            {{ pay }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <button style="margin-left: 3%" *ngIf="displayFilter" (click)="ClearFilter()" mat-raised-button color="warn">
        Clear Filter
      </button>

      <div class="add-new-subscriber mat-elevation-z4" style="overflow-x: scroll !important" id="excel-table">
        <mat-progress-spinner *ngIf="showbooks" style="margin-left: auto; margin-right: auto" color="warn"
          mode="indeterminate">
        </mat-progress-spinner>

        <div style="margin-top: 20px" *ngIf="!showbooks" class="tableFixHead">
          <button type="button" (click)="exportexcel()" class="btn btn-danger">
            Export
          </button>
          <table class="table table-striped text-center">
            <thead>
              <tr>
                <th>अ.क्र.</th>
                <th>नोंदणी दिनांक</th>
                <th>नोंदणी क्र.</th>
                <th>ग्राहकाचे नाव</th>
                <th>ठिकाण</th>
                <th>संपर्क क्रमांक</th>
                <th>प्रतिनिधींचे नाव</th>
                <th>कार्यालय प्रतिनिधींचे नाव</th>
                <th>पुस्तकाचे नाव</th>
                <th>लेखकाचे नाव</th>
                <th>पुस्तकाच्या प्रति</th>
                <th>Is Complimentary</th>
                <th>मूळ किंमत</th>
                <th>सवलत मूल्य</th>
                <th>निव्वळ रक्कम</th>
                <th>कुरिअर चार्जेस</th>
                <th>एकूण रक्कम</th>
                <th>जमा</th>
                <th>येणे</th>
                <th>Invoice No</th>
                <th>पावती क्र.</th>
                <th>प्रतिनिधींचे वर्तन</th>
                <th>पाठविलेले माध्यम</th>
                <th>पुस्तक पाठवल्याचा दिनांक</th>
                <th>Total Stock</th>
                <th>Available Stock</th>
                <th>Remark</th>
              </tr>
            </thead>
            <tbody>
              <!-- old data source this.filterBookwiseReport -->
              <tr *ngFor="let book of this.filterBookwiseReport; let i = index">
                <td>{{ i + 1 }}</td>
                <td>{{ book["booking_date"] | date: "dd-MM-yyy" }}</td>
                <td>{{ book["orderno"] }}</td>
                <td>{{ book["name"] }}</td>
                <td>{{ book["address"] }}</td>
                <td>{{ book["mob_no"] }}</td>
                <td>{{ book["crep_name"] }}</td>
                <td>{{ book["office_rep_name"] }}</td>
                <td>{{ book["bookname"] }}</td>
                <td>{{ book["authorname"] }}</td>
                <td>{{ book["bookcopies"] }}</td>
                <td>{{ book["iscomplimentary"] }}</td>
                <td>{{ book["bookprice"] }}</td>
                <td>{{ book["totalafterdiscount"] }}</td>
                <td>{{ book["total"] }}</td>
                <td>{{ book["shipping_charges"] }}</td>
                <td>
                  {{
                  ConvertToInt(book["total"]) +
                  ConvertToInt(book["shipping_charges"])
                  }}
                </td>
                <td>{{ book["received_amt"] }}</td>
                <td>
                  {{
                  ConvertToInt(book["remaining_amt"])
                  }}
                </td>
                <td>{{ book["invoiceno"] }}</td>
                <td>{{ book["rcptno"] }}</td>
                <td>{{ book["rep_com_price"] }}</td>
                <td>{{ book["delivery_mode"] }}</td>
                <td></td>
                <td>{{ book["totalstock"] }}</td>
                <td>{{ book["availablestock"] }}</td>
                <td></td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <td colspan="2">Total</td>
                <td colspan="8"></td>
                <td>{{ filterBookwiseReportTotal['totalbookcopies'] }}</td>
                <td></td>
                <td>{{ filterBookwiseReportTotal['totalbookprice'] }}</td>
                <td>{{ filterBookwiseReportTotal['totalafterdiscount'] }}</td>
                <td>{{ filterBookwiseReportTotal['total'] }}</td>
                <td>{{ filterBookwiseReportTotal['totalshipping_charges'] }}</td>
                <td>{{ filterBookwiseReportTotal['total'] + filterBookwiseReportTotal['totalshipping_charges'] }}</td>
                <td>{{ filterBookwiseReportTotal['totaldepositamt'] }}</td>
                <td>{{ filterBookwiseReportTotal['totalamount'] - filterBookwiseReportTotal['totaldepositamt'] }}</td>
                <td colspan="5"></td>
                <td>{{ filterBookwiseReportTotal['totalstock'] }}</td>
                <td>{{ filterBookwiseReportTotal['availablestock'] }}</td>
                <td></td>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
    </mat-tab>

    <mat-tab label="Book Account">
      <div class="add-new-subscriber mat-elevation-z4" style="overflow-x: scroll !important">
        <div style="margin-top: 20px">
          <button (click)="getBookPayments()" mat-fab color="warn" class="btn"
            aria-label="Example icon button with a refresh icon">
            <mat-icon>refresh</mat-icon>
          </button>
          <table class="table table-striped text-center">
            <thead>
              <tr>
                <th>Sr.No.</th>
                <th>Rep. Name</th>
                <th>Outstanding</th>
                <th>Claim Against</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let book of this.bookpaymentList; let i = index">
                <td>{{ i + 1 }}</td>
                <td>{{ book["name"] }}</td>
                <td>₹ {{ book["totalAmount"] }}</td>
                <td>{{ book["claim_name"] }}</td>
                <td>
                  <button type="button" class="btn btn-danger btn-sm" (click)="displayBookPayment(book)">
                    View
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </mat-tab>

    <mat-tab label="Book Seller Ledger">
      <mat-card style="padding: 10px 10px">
        <div style="margin-top: 20px; margin-left: 20px" class="tableFixHead">
          <table class="table table-bordered table-hover text-center">
            <thead>
              <tr>
                <th style="background: #fff; padding: 4px" colspan="3">
                  Debit
                </th>
                <th style="background: #fff; padding: 4px" colspan="4">
                  Credit
                </th>
              </tr>
              <tr>
                <th style="background: #fff; padding: 4px">Invoice No</th>
                <th style="background: #fff; padding: 4px" rowspan="2">
                  Publication Name
                </th>
                <th style="background: #fff; padding: 4px" rowspan="2">
                  Invoice Amount
                </th>
                <th style="background: #fff; padding: 4px">Receipt No</th>
                <th style="background: #fff; padding: 4px">
                  Credit Voucher No
                </th>
                <th style="background: #fff; padding: 4px" colspan="2">
                  Bank Details
                </th>
                <th style="background: #fff; padding: 4px" rowspan="2">
                  Receipt Amount Rs
                </th>
                <th style="background: #fff; padding: 4px" rowspan="2">
                  Balance Amount
                </th>
              </tr>
              <tr>
                <th style="background: #fff; padding: 4px">Date</th>
                <th style="background: #fff; padding: 4px">Date</th>
                <th style="background: #fff; padding: 4px">Date</th>
                <th style="background: #fff; padding: 4px">Cheque No</th>
                <th style="background: #fff; padding: 4px">Date</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td colspan="7">
                  <form [formGroup]="bsform">
                    <mat-form-field appearance="outline" class="example-full-width" style="width: 80%; margin-left: 5%">
                      <mat-label>Book Seller Name</mat-label>
                      <input matInput formControlName="subname" [matAutocomplete]="autobsn" />
                      <mat-autocomplete autoActiveFirstOption #autobsn="matAutocomplete">
                        <mat-option (onSelectionChange)="selected($event, option)"
                          *ngFor="let option of filteredOptions5 | async" [value]="option['name']">
                          {{ option["name"] }}
                        </mat-option>
                      </mat-autocomplete>
                    </mat-form-field>
                  </form>
                </td>
              </tr>
              <tr *ngFor="let book of this.BookSellerLegderData; let i = index">
                <td>
                  {{ book["invoice_no"] }} <br />{{
                  book["invoice_date"] | date: "dd-MM-yyy"
                  }}
                </td>
                <td>Vivek Book Department</td>
                <td>{{ book["invoice_total"] }}</td>
                <td>
                  {{ book["receipt_no"] }}
                  <br />{{ book["receipt_date"] | date: "dd-MM-yyy" }}
                </td>
                <td>
                  {{ book["voucher_no"] }}
                  <br />{{ book["voucher_date"] | date: "dd-MM-yyy" }}
                </td>
                <td>
                  {{ book["voucher_no"] }}
                  <br />{{ book["entry_date"] | date: "dd-MM-yyy" }}
                </td>
                
                
                <td>{{ book["cheque_no"] }}</td>
                <td>{{ book["cheque_date"] | date: "dd-MM-yyy" }}</td>
                <td>{{ book["select_entry_amt"] }}</td>
                <td></td>
              </tr>
              <tr>
                <td colspan="3">Balance</td>
                <td colspan="6">Pending</td>
              </tr>
            </tbody>
          </table>
        </div>
      </mat-card>
    </mat-tab>

    <mat-tab label="Book Billing Register">
      <mat-card style="padding: 10px 10px">
        <mat-form-field appearance="fill" style="margin-left: 5%">
          <mat-label>Enter a date range</mat-label>
          <mat-date-range-input [formGroup]="BookbillingRegister" [rangePicker]="pickerbbr">
            <input matStartDate formControlName="start" placeholder="Start date" />
            <input matEndDate formControlName="end" placeholder="End date" (dateChange)="getBookBillingDateRange()" />
          </mat-date-range-input>
          <mat-datepicker-toggle matSuffix [for]="pickerbbr"></mat-datepicker-toggle>
          <mat-date-range-picker #pickerbbr></mat-date-range-picker>

          <mat-error *ngIf="
              BookbillingRegister.controls.start.hasError('matStartDateInvalid')
            ">Invalid start date</mat-error>
          <mat-error *ngIf="
              BookbillingRegister.controls.end.hasError('matEndDateInvalid')
            ">Invalid end date</mat-error>
        </mat-form-field>
        <mat-form-field class="example-form-field" appearance="fill" style="margin-left: 3%">
          <mat-label>Invoice No</mat-label>
          <input matInput type="text" [(ngModel)]="invoice_no" (ngModelChange)="getinvoice_no($event)" />
          <button *ngIf="invoice_no" matSuffix mat-icon-button aria-label="Clear" (click)="reset_invoice_no($event)">
            <mat-icon>close</mat-icon>
          </button>
        </mat-form-field>
        <mat-form-field class="example-form-field" appearance="fill" style="margin-left: 3%">
          <mat-label>Customer Name</mat-label>
          <input matInput type="text" [(ngModel)]="cust_name" (ngModelChange)="getcustomer_name($event)" />
          <button *ngIf="cust_name" matSuffix mat-icon-button aria-label="Clear" (click)="reset_cust_name($event)">
            <mat-icon>close</mat-icon>
          </button>
        </mat-form-field>
        <div>
          <div style="margin-left: 70%; margin-bottom: 2%">
            <button
              [printStyle]="{table : {'border-collapse':'collapse'}, td : {'border': 'solid 1px', 'font-size':'14px', 'font-weight':'600', 'text-align':'center'}, th : {'border': 'solid 1px', 'font-size':'16px', 'font-weight':'600', 'text-align':'center'}, div : {'display':'none'}, span : {'font-size':'14px', 'font-weight':'600', 'float':'left'}}"
              class="noPrint" style="margin-right: 2%; margin-left: 2%" mat-raised-button color="primary"
              printSectionId="print-section" ngxPrint>
              print
            </button>
            <button class="noPrint" style="margin-top: 2% 2% 0 2%;" (click)="exportExcel('Book_Billing_Register')"
              mat-raised-button color="primary">
              Export
            </button>
            <!-- <button mat-dialog-close class="btn noPrint">
              Close
            </button> -->
          </div>
        </div>
        <div style="margin-top: 20px; margin-left: 20px" class="tableFixHead" id="print-section">
          <table class="table table-bordered table-hover text-center">
            <thead>
              <tr>
                <th>Sr. No.</th>
                <th>Invoice Date</th>
                <th>Invoice No</th>
                <th>Book Seller Name</th>
                <!-- <th>Publication Name</th> -->
                <!-- <th>Representative Name</th> -->
                <!-- <th>Discount</th> -->
                <!-- <th>Shipping Charges</th> -->
                <!-- <th>Net Amount</th> -->
                <th>Invoice Amount</th>

                <th>Receipt</th>
                <th>Pending</th>
                <th>
                  <div>Action</div>
                </th>
              </tr>
            </thead>
            <tbody>
              <ng-container *ngFor="let book of this.Filteredbookbillingdata; let i=index; let end=last">
                <tr *ngIf="!end">
                  <td>{{ i+1 }}</td>
                  <td>{{ book["invoice_date"] | date: "dd-MM-yyyy" }}</td>
                  <td>{{ book["invoice_no"] }}</td>
                  <td>{{ book["name"] }}</td>
                  <!-- <td></td> -->
                  <!-- <td>{{ book["repname"] }}</td> -->
                  <!-- <td>{{ book["discount"] }}</td> -->
                  <!-- <td>{{ book["shipping_charges"] }}</td> -->
                  <!-- <td>
                  {{ book["total"] - book["shipping_charges"] }}
                </td> -->
                  <td>{{ book["invoice_total"] }}</td>
                  <td>
                    {{ toInt(book["invoice_total"]) - toInt(book["remaining_amt"]) }}
                    <!-- <div *ngIf="book['rcpt_generated'] == N">No</div>
                  <div *ngIf="book['rcpt_generated'] == Y">Yes</div> -->
                  </td>
                  <td>
                    {{ book["remaining_amt"] }}
                    <!-- <span *ngIf="book['balamt'] == null || book['balamt'] == 0">
                    {{ book["invoice_total"] }}
                  </span>
                  <span *ngIf="book['balamt'] != null">
                    {{ book["balamt"] }}
                  </span> -->
                  </td>
                  <td style="justify-items: center">
                    <button class="btn btn-info" (click)="show(book)">
                      show
                    </button>
                  </td>
                </tr>
                <tr *ngIf="end">
                  <td colspan="4">{{ book['name'] }}</td>
                  <td>{{ book['invoice_total'] }}</td>
                  <td>{{ book['receipt'] }}</td>
                  <td>{{ book['pending'] }}</td>
                  <td></td>
                </tr>
              </ng-container>
            </tbody>
          </table>
        </div>
      </mat-card>
    </mat-tab>

    <mat-tab label="Book Receipt Register">
      <mat-card style="padding: 10px 10px">
        <form [formGroup]="bookrcptregisterform">
          <div class="form-row">
            <mat-form-field appearance="outline" style="margin-left: 7%; width: 40%">
              <mat-label>Enter a date from...to </mat-label>
              <mat-date-range-input [rangePicker]="pickerbrr">
                <input matStartDate formControlName="start" placeholder="Start date" />
                <input matEndDate formControlName="end" placeholder="End date"
                  (dateChange)="getBookReceiptDateRange()" />
              </mat-date-range-input>
              <mat-datepicker-toggle matSuffix [for]="pickerbrr"></mat-datepicker-toggle>
              <mat-date-range-picker #pickerbrr></mat-date-range-picker>

              <mat-error *ngIf="
                  bookrcptregisterform.controls.start.hasError(
                    'matStartDateInvalid'
                  )
                ">Invalid start date</mat-error>
              <mat-error *ngIf="
                  bookrcptregisterform.controls.end.hasError(
                    'matEndDateInvalid'
                  )
                ">Invalid end date</mat-error>
            </mat-form-field>
          </div>
          <!-- <div class="form-row">
            <mat-form-field
              appearance="outline"
              class="example-full-width"
              style="width: 20%; margin-left: 7%"
            >
              <mat-label>महिन्या प्रमाणे</mat-label>
              <input matInput formControlName="monthwise" />
            </mat-form-field>
            <mat-form-field
              appearance="outline"
              class="example-full-width"
              style="width: 20%; margin-left: 2%"
            >
              <mat-label>विस्तृत</mat-label>
              <input matInput formControlName="vistrut" />
            </mat-form-field>
            <mat-form-field
              appearance="outline"
              class="example-full-width"
              style="width: 20%; margin-left: 2%"
            >
              <mat-label>संक्षिप्त</mat-label>
              <input matInput formControlName="sankshipt" />
            </mat-form-field>
          </div> -->
          <div class="form-row">
            <mat-form-field appearance="outline" class="example-full-width" style="width: 40%; margin-left: 7%">
              <mat-label>प्रतिनिधी प्रमाणे</mat-label>
              <input matInput formControlName="repwise" (change)="getrepwise($event)" />
            </mat-form-field>
          </div>
          <div class="form-row">
            <mat-form-field appearance="outline" class="example-full-width" style="width: 40%; margin-left: 7%">
              <mat-label>बिल क्रं प्रमाणे</mat-label>
              <input matInput formControlName="billnowise" (change)="getbillnowise($event)" />
            </mat-form-field>
          </div>
          <div class="form-row">
            <mat-form-field appearance="outline" class="example-full-width" style="width: 40%; margin-left: 7%">
              <mat-label>Book Seller प्रमाणे</mat-label>
              <input matInput formControlName="booksellerwise" (change)="getbooksellerwise($event)" />
            </mat-form-field>
          </div>
        </form>
        <div id="excel-table">
          <div style="margin-top: 20px; margin-left: 20px" class="tableFixHead">
            <button type="button" (click)="exportexcel()" class="btn btn-danger">
              Export
            </button>
            <table class="table table-bordered table-hover text-center">
              <thead>
                <tr>
                  <th>Receipt Date</th>
                  <th>Receipt No</th>
                  <th>Book Seller Name</th>
                  <th>Representative Name</th>
                  <th>Bill No</th>
                  <th>Bill Date</th>
                  <th>Received Amount</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let book of FilteredbookRcptRegisterdata">
                  <td>{{ book["receipt_date"] | date: "dd-MM-yyy" }}</td>
                  <td>{{ book["receipt_no"] }}</td>
                  <td>{{ book["book_seller_name"] }}</td>
                  <td>{{ book["rep_name"] }}</td>
                  <td>{{ book["bill_no"] }}</td>
                  <td>{{ book["billdate"] | date: "dd-MM-yyy" }}</td>
                  <td>{{ book["select_entry_amt"] }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </mat-card>
    </mat-tab>

    <mat-tab label="Book Seller Credit Note">
      <mat-card style="padding: 10px 10px">
        <form [formGroup]="booksellercrdtnoteform">
          <div class="form-row">
            <mat-form-field appearance="outline" style="margin-left: 7%; width: 40%">
              <mat-label>Enter a date from...to </mat-label>
              <mat-date-range-input [rangePicker]="pickerbscn">
                <input matStartDate formControlName="start" placeholder="Start date" />
                <input matEndDate formControlName="end" placeholder="End date"
                  (dateChange)="getBookCreditDateRange()" />
              </mat-date-range-input>
              <mat-datepicker-toggle matSuffix [for]="pickerbscn"></mat-datepicker-toggle>
              <mat-date-range-picker #pickerbscn></mat-date-range-picker>

              <mat-error *ngIf="
                  booksellercrdtnoteform.controls.start.hasError(
                    'matStartDateInvalid'
                  )
                ">Invalid start date</mat-error>
              <mat-error *ngIf="
                  booksellercrdtnoteform.controls.end.hasError(
                    'matEndDateInvalid'
                  )
                ">Invalid end date</mat-error>
            </mat-form-field>
          </div>
          <div class="form-row">
            <mat-form-field appearance="outline" class="example-full-width" style="width: 20%; margin-left: 7%">
              <mat-label>महिन्या प्रमाणे</mat-label>
              <input matInput formControlName="monthwise" />
            </mat-form-field>
            <mat-form-field appearance="outline" class="example-full-width" style="width: 20%; margin-left: 2%">
              <mat-label>विस्तृत</mat-label>
              <input matInput formControlName="vistrut" />
            </mat-form-field>
            <mat-form-field appearance="outline" class="example-full-width" style="width: 20%; margin-left: 2%">
              <mat-label>संक्षिप्त</mat-label>
              <input matInput formControlName="sankshipt" />
            </mat-form-field>
          </div>
          <div class="form-row">
            <mat-form-field appearance="outline" class="example-full-width" style="width: 40%; margin-left: 7%">
              <mat-label>प्रतिनिधी प्रमाणे</mat-label>
              <input matInput formControlName="repwise" />
            </mat-form-field>
          </div>
          <div class="form-row">
            <mat-form-field appearance="outline" class="example-full-width" style="width: 40%; margin-left: 7%">
              <mat-label>प्रकाशना प्रमाणे</mat-label>
              <input matInput formControlName="prakashanwise" />
            </mat-form-field>
          </div>
          <div class="form-row">
            <mat-form-field appearance="outline" class="example-full-width" style="width: 40%; margin-left: 7%">
              <mat-label>Book Seller प्रमाणे</mat-label>
              <input matInput formControlName="booksellerwise" />
            </mat-form-field>
          </div>
        </form>
        <div style="margin-top: 20px; margin-left: 20px" class="tableFixHead">
          <table class="table table-bordered table-hover text-center">
            <thead>
              <tr>
                <th>Receipt Date</th>
                <th>Receipt No</th>
                <th>Book Seller Name</th>
                <th>Bill No</th>
                <th>Bill Date</th>
                <th>Received Amount</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
              <!-- <tr>
                <td colspan="5">Total</td>
              </tr> -->
            </tbody>
          </table>
        </div>
      </mat-card>
    </mat-tab>

    <mat-tab label="Book Seller Out Standing">
      <mat-card style="padding: 10px 10px">
        <form [formGroup]="bookoutstandingform">
          <div class="form-row">
            <mat-form-field appearance="outline" style="margin-left: 7%; width: 40%">
              <mat-label>Enter a date from...to </mat-label>
              <mat-date-range-input [rangePicker]="pickerbso">
                <input matStartDate formControlName="start" placeholder="Start date" />
                <input matEndDate formControlName="end" placeholder="End date" (dateChange)="getbookoutstaningdate()" />
              </mat-date-range-input>
              <mat-datepicker-toggle matSuffix [for]="pickerbso"></mat-datepicker-toggle>
              <mat-date-range-picker #pickerbso></mat-date-range-picker>

              <mat-error *ngIf="
                  bookoutstandingform.controls.start.hasError(
                    'matStartDateInvalid'
                  )
                ">Invalid start date</mat-error>
              <mat-error *ngIf="
                  bookoutstandingform.controls.end.hasError('matEndDateInvalid')
                ">Invalid end date</mat-error>
            </mat-form-field>
          </div>
          <div class="form-row">
            <mat-form-field appearance="outline" class="example-full-width" style="width: 40%; margin-left: 7%">
              <mat-label>कार्यालय प्रतिनिधी प्रमाणे</mat-label>
              <input matInput formControlName="officerepwise" (change)="getofficerepname($event)" />
            </mat-form-field>
          </div>
          <div class="form-row">
            <mat-form-field appearance="outline" class="example-full-width" style="width: 40%; margin-left: 7%">
              <mat-label>प्रतिनिधी प्रमाणे</mat-label>
              <input matInput formControlName="repwise" (change)="getrepname($event)" />
            </mat-form-field>
          </div>
          <div class="form-row">
            <mat-form-field appearance="outline" class="example-full-width" style="width: 40%; margin-left: 7%">
              <mat-label>Book Seller प्रमाणे</mat-label>
              <input matInput formControlName="booksellerwise" (change)="getbooksellername($event)" />
            </mat-form-field>
          </div>
          <div class="form-row">
            <mat-form-field appearance="outline" class="example-full-width" style="width: 40%; margin-left: 7%">
              <mat-label>Book प्रमाणे</mat-label>
              <input matInput formControlName="bookwise" (change)="getbookname($event)" />
            </mat-form-field>
          </div>
          <!-- <div class="form-row">
            <mat-form-field
              appearance="outline"
              class="example-full-width"
              style="width: 25%; margin-left: 7%"
            >
              <mat-label>Representative Name</mat-label>
              <input matInput formControlName="repname" />
            </mat-form-field>
            <mat-form-field
              appearance="outline"
              class="example-full-width"
              style="width: 25%; margin-left: 5%"
            >
              <mat-label>Mobile No</mat-label>
              <input matInput type="number" formControlName="mob" />
            </mat-form-field>
          </div> -->
          <div class="row">
            <button style="margin-left: 14%" type="button" mat-raised-button class="btn btn-primary"
              (click)="getTotalBilling()">
              Total Billing
            </button>
            <button style="margin-left: 4%" type="button" mat-raised-button class="btn btn-info"
              (click)="getPendingBilling()">
              Pending Billing
            </button>
          </div>
        </form>
        <!-- <div *ngFor="let "></div> -->
        <div style="margin-top: 20px; margin-left: 20px" class="tableFixHead">
          <table class="table table-bordered table-hover text-center">
            <thead>
              <tr>
                <th>Invoice No</th>
                <th>Invoice Date</th>
                <th>Book Seller Name</th>
                <th>Representative Name</th>
                <th>Mobile No.</th>
                <th>Office Representative Name</th>
                <th>Invoice Amount</th>
                <th>Outstanding Amount</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of Filteredbookoutstandingdata">
                <td>{{ item["invoice_no"] }}</td>
                <td>{{ item["invoice_date"] | date: "dd-MM-yyy" }}</td>
                <td>{{ item["name"] }}</td>
                <td>{{ item["rep_name"] }}</td>
                <td>{{ item["rep_mob_no"] }}</td>
                <td>{{ item["office_rep_name"] }}</td>
                <td>{{ item["invoice_total"] }}</td>
                <td>{{ item["remaining_amt"] }}</td>
              </tr>
              <tr>
                <td colspan="5">Total:</td>
              </tr>
            </tbody>
          </table>
        </div>
      </mat-card>
    </mat-tab>

    <mat-tab label="Manage Representative Book Commission">
      <div class="add-new-subscriber mat-elevation-z4" style="overflow-x: scroll !important" id="excel-table">
        <div style="margin-top: 20px" class="tableFixHead">
          <table class="table table-striped text-center">
            <thead>
              <tr>
                <th>Sr.No</th>
                <th>प्रतिनिधींचे नाव</th>
                <th>ठिकाण</th>
                <th>बुक सेलर नाव</th>
                <th>बिल नंबर</th>
                <th>दिनांक</th>
                <th>रक्कम</th>
                <th>पुस्तकाचे नाव</th>
                <th>वर्तन %</th>
                <th>वर्तन रक्कम</th>
                <th>दिलेले कमिशन</th>
                <th>कमिशन दिलयाचा तपशील व्हाउचर दिनांक</th>
                <th>देणे बाकी</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let rep of this.repcommissiondata; let i = index">
                <td>{{ i + 1 }}</td>
                <td>{{ rep["rep_name"] }}</td>
                <td>{{ rep["district"] }}</td>
                <td>{{ rep["receiver_name"] }}</td>
                <td>{{ rep["invoice_no"] }}%</td>
                <td>{{ rep["invoice_date"] }}</td>
                <td>{{ rep["invoice_total"] }}</td>
                <td>{{ rep["book_name"] }}</td>
                <td>{{ rep["percent"] }}</td>
                <td>{{ rep["amount"] }}</td>
                <td></td>
                <td></td>
                <td></td>
                <!-- <td>
                  <button
                    type="button"
                    (click)="GetUpdatedCommissionValue(rep['id'])"
                    class="btn btn-danger"
                  >
                    Update
                  </button>
                </td> -->
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </mat-tab>

    <mat-tab label="Book Stock">
      <div class="add-new-subscriber mat-elevation-z4" style="overflow-x: scroll !important">
        <div style="margin-top: 20px" class="tableFixHead">
          <table class="table table-striped text-center">
            <thead>
              <tr>
                <th>Sr.No.</th>
                <th>Book Name</th>
                <th>Author Name</th>
                <th>Page</th>
                <th>Book Size</th>
                <th>Book Price</th>
                <th>Book Sale Price</th>
                <th>Book Printed Copies</th>
                <th>Total Copies</th>
                <th>Available Stock</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let book of bookData; let i = index">
                <td>{{ i + 1 }}</td>
                <td>{{ book["bookname"] }}</td>
                <td>{{ book["author"] }}</td>
                <td>{{ book["book_pages"] }}</td>
                <td>{{ book["booksize"] }}</td>
                <td>{{ book["price"] }}</td>
                <td>{{ book["saleprice"] }}</td>
                <td>
                  {{ book["bookedition"] }} <br />
                  {{ book["total_stock"] }}
                </td>
                <td>{{ book["total_copies"] }}</td>
                <td>{{ book["available_stock"] }}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div style="margin-top: 20px" class="tableFixHead">
          <table class="table table-striped text-center">
            <thead>
              <tr>
                <th>Sr.No.</th>
                <th>Book Name</th>
                <th>Author Name</th>
                <th>Total Pages</th>
                <th>Price</th>
                <th>Available Stock</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let book of this.bookslist; let i = index">
                <td>{{ i + 1 }}</td>
                <td>{{ book["bookname"] }}</td>
                <td>{{ book["author"] }}</td>
                <td>{{ book["book_pages"] }}</td>
                <td>{{ book["price"] }}</td>
                <td>{{ book["available_stock"] }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </mat-tab>

    <mat-tab label="Delivered Books">
      <mat-form-field appearance="fill" style="margin-top: 5%; margin-left: 3%">
        <mat-label>Enter a Delivery date range</mat-label>
        <mat-date-range-input [formGroup]="range" [rangePicker]="picker">
          <input matStartDate formControlName="start" placeholder="Start date" />
          <input matEndDate formControlName="end" placeholder="End date" (dateChange)="getChangedDate()" />
        </mat-date-range-input>
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-date-range-picker #picker></mat-date-range-picker>

        <mat-error *ngIf="range.controls.start.hasError('matStartDateInvalid')">Invalid start date</mat-error>
        <mat-error *ngIf="range.controls.end.hasError('matEndDateInvalid')">Invalid end date</mat-error>
      </mat-form-field>

      <mat-form-field class="example-form-field" appearance="fill" style="margin-left: 3%">
        <mat-label>Customer Name</mat-label>
        <input matInput type="text" [(ngModel)]="cust_name_delivery"
          (ngModelChange)="getcust_name_deliveredBook($event)" />
        <button *ngIf="cust_name_delivery" matSuffix mat-icon-button aria-label="Clear"
          (click)="reset_custname_deliveredBook($event)">
          <mat-icon>close</mat-icon>
        </button>
      </mat-form-field>

      <!-- Display Table Details -->

      <table mat-table [dataSource]="FilteredDeliveryBooks" class="mat-elevation-z8">
        <!--- Note that these columns can be defined in any order.
                      The actual rendered columns are set as a property on the row definition" -->

        <!-- Name Column -->
        <ng-container matColumnDef="Name">
          <th mat-header-cell *matHeaderCellDef>Name</th>
          <td mat-cell *matCellDef="let element">{{ element.name }}</td>
        </ng-container>

        <!-- Weight Column -->
        <ng-container matColumnDef="Mobile">
          <th mat-header-cell *matHeaderCellDef>Mobile</th>
          <td mat-cell *matCellDef="let element">{{ element.mobile }}</td>
        </ng-container>

        <!-- Symbol Column -->
        <ng-container matColumnDef="Book Name">
          <th mat-header-cell *matHeaderCellDef>Book Name</th>
          <td mat-cell *matCellDef="let element">{{ element.book_name }}</td>
        </ng-container>

        <!-- Symbol Column -->
        <ng-container matColumnDef="Received Date">
          <th mat-header-cell *matHeaderCellDef>Received Date</th>
          <td mat-cell *matCellDef="let element">
            {{ element.received_date | date: "dd-MM-yyyy" }}
          </td>
        </ng-container>

        <!-- Symbol Column -->
        <ng-container matColumnDef="Status">
          <th mat-header-cell *matHeaderCellDef>Status</th>
          <td mat-cell *matCellDef="let element">Delivered</td>
        </ng-container>

        <!-- Symbol Column -->
        <ng-container matColumnDef="Action">
          <th mat-header-cell *matHeaderCellDef>Action</th>
          <td mat-cell *matCellDef="let element">
            <button mat-raised-button color="warn" (click)="EditDeliveredBook(element.id)">
              View
            </button>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
        <tr mat-row *matRowDef="let row; columns: columnsToDisplay"></tr>
      </table>
    </mat-tab>

    <mat-tab label="Master Book Size">
      <mat-card style="margin-left: 20px; margin-right: 20px">
        <form [formGroup]="booksizeform" (ngSubmit)="addbooksize()">
          <div class="form-group">
            <mat-form-field class="example-full-width" appearance="outline" style="width: 35%; margin-left: 5%">
              <mat-label>Book Size</mat-label>
              <input matInput formControlName="booksize" />
            </mat-form-field>
            <button type="submit" mat-raised-button color="primary" style="margin-left: 5%">
              Add Book Size
            </button>
          </div>
        </form>
      </mat-card>
    </mat-tab>

    <mat-tab label="Book-store Representative">
      <mat-card>
        <table class="table table-striped table-fixed">
          <thead>
            <tr>
              <th>Sr. No</th>
              <th>Representative Name</th>
              <th>Representative Mobile No</th>
              <th>Representative District</th>
              <th>Office Representative</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of bookwisereplist; let i = index">
              <td>{{ i + 1 }}</td>
              <td>{{ item["rep_name"] }}</td>
              <td>{{ item["rep_mob_no"] }}</td>
              <td>{{ item["district"] }}</td>
              <td>{{ item["off_rep_name"] }}</td>
              <td>
                <button type="button" class="btn btn-info" (click)="update(item)">
                  Update
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </mat-card>
    </mat-tab>

    <mat-tab label="Master Author">
      <mat-card>
        <form [formGroup]="authorForm" (ngSubmit)="saveauthor()">
          <div class="form-row">
            <button style="margin-left: 58%; width: 25%; margin-bottom: 1%" type="button" mat-raised-button
              class="btn btn-info" (click)="getAuthorAddress()">
              Author Address
            </button>
          </div>
          <div class="form-row">
            <!-- <mat-form-field
              appearance="outline"
              class="example-full-width"
              style="width: 22%; margin-left: 5%; margin-top: 10px"
            >
              <mat-label>Seller Name</mat-label>
              <input
                matInput
                formControlName="subname"
                [matAutocomplete]="auto1"
              />
              <mat-autocomplete autoActiveFirstOption #auto1="matAutocomplete">
                <mat-option
                  *ngFor="let option of filteredOptions1 | async"
                  [value]="option['name']"
                  (onSelectionChange)="selected_name(option)"
                >
                  {{ option["name"] }}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field> -->
            <mat-form-field class="example-full-width" appearance="outline" style="width: 75%; margin-left: 8%">
              <mat-label>Author Name</mat-label>
              <input matInput formControlName="authorname" [matAutocomplete]="autoauthor" />
              <mat-autocomplete autoActiveFirstOption #autoauthor="matAutocomplete">
                <mat-option *ngFor="let option of filteredOptions11 | async" [value]="option['name']"
                  (onSelectionChange)="selected_authorname($event, option)">
                  {{ option["name"] }}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
          </div>
          <div class="form-row">
            <mat-form-field class="example-full-width" appearance="outline" style="width: 75%; margin-left: 8%">
              <mat-label>Address</mat-label>
              <textarea matInput formControlName="address"></textarea>
            </mat-form-field>
          </div>
          <div class="form-row">
            <mat-form-field class="example-full-width" appearance="outline" style="width: 35%; margin-left: 8%">
              <mat-label>Pincode</mat-label>
              <input matInput formControlName="pincode" />
            </mat-form-field>
            <mat-form-field class="example-full-width" appearance="outline" style="width: 35%; margin-left: 5%">
              <mat-label>Country</mat-label>
              <input matInput formControlName="country" />
            </mat-form-field>
          </div>
          <div class="form-row">
            <mat-form-field class="example-full-width" appearance="outline" style="width: 23%; margin-left: 8%">
              <mat-label>State</mat-label>
              <input matInput formControlName="state" />
            </mat-form-field>
            <mat-form-field class="example-full-width" appearance="outline" style="width: 23%; margin-left: 2.7%">
              <mat-label>District</mat-label>
              <input matInput formControlName="district" />
            </mat-form-field>
            <mat-form-field class="example-full-width" appearance="outline" style="width: 23%; margin-left: 3%">
              <mat-label>Taluka</mat-label>
              <input matInput formControlName="taluka" />
            </mat-form-field>
          </div>
          <div class="form-row">
            <mat-form-field class="example-full-width" appearance="outline" style="width: 35%; margin-left: 8%">
              <mat-label>Mobile No</mat-label>
              <input matInput formControlName="mob_no" />
            </mat-form-field>
            <mat-form-field class="example-full-width" appearance="outline" style="width: 35%; margin-left: 5%">
              <mat-label>Email</mat-label>
              <input matInput formControlName="email" />
            </mat-form-field>
          </div>
          <div class="form-row">
            <mat-form-field class="example-full-width" appearance="outline" style="width: 35%; margin-left: 8%">
              <mat-label>PAN No</mat-label>
              <input matInput formControlName="pan_no" />
            </mat-form-field>
            <mat-form-field class="example-full-width" appearance="outline" style="width: 35%; margin-left: 5%">
              <mat-label>Aadhar No</mat-label>
              <input matInput formControlName="aadhar_no" />
            </mat-form-field>
          </div>
          <div class="form-row">
            <mat-form-field class="example-full-width" appearance="outline" style="width: 35%; margin-left: 8%">
              <mat-label>Bank Name</mat-label>
              <input matInput formControlName="bank_name" />
            </mat-form-field>
            <mat-form-field class="example-full-width" appearance="outline" style="width: 35%; margin-left: 5%">
              <mat-label>Branch</mat-label>
              <input matInput formControlName="branch" />
            </mat-form-field>
          </div>
          <div class="form-row">
            <mat-form-field class="example-full-width" appearance="outline" style="width: 35%; margin-left: 8%">
              <mat-label>Account No</mat-label>
              <input matInput type="number" formControlName="acc_no" />
            </mat-form-field>
            <mat-form-field class="example-full-width" appearance="outline" style="width: 35%; margin-left: 5%">
              <mat-label>IFSC Code</mat-label>
              <input matInput formControlName="ifsc_code" />
            </mat-form-field>
          </div>
          <div class="form-row">
            <button *ngIf="!this.isauthor" type="submit" mat-raised-button [disabled]="authorForm.invalid"
              class="btn btn-primary" style="margin-left: 15%; margin-right: 30%; width: 50%">
              Save
            </button>
            <button *ngIf="this.isauthor" type="button" mat-raised-button [disabled]="authorForm.invalid"
              class="btn btn-info" style="margin-left: 15%; margin-right: 5%; width: 25%" (click)="updateAuthor()">
              Update
            </button>
            <button *ngIf="this.isauthor" mat-raised-button class="btn btn-danger" type="button"
              style="margin-right: 15%; width: 25%" (click)="deleteAuthorData()">
              Delete
            </button>
          </div>
        </form>
      </mat-card>
      <!-- <mat-card>
        <div class="tableFixHead1">
          <table class="table table-striped text-center">
            <thead>
              <tr>
                <th>Author Name</th>
                <th>Address</th>
                <th>Mobile NO</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of this.authorData">
                <td>{{ item["author_name"] }}</td>
                <td>{{ item["author_address"] }}</td>
                <td>{{ item["mob_no"] }}</td>
                <td style="display: flex; justify-content: center">
                  <button
                    mat-raised-button
                    class="btn btn-danger"
                    type="button"
                    (click)="deleteAuthorData(item)"
                  >
                    Delete
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </mat-card> -->
    </mat-tab>

    <mat-tab label="Book Seller Address">
      <mat-card>
        <form [formGroup]="bookselleraddressForm">
          <div class="form-row">
            <mat-form-field appearance="outline" class="example-full-width" style="width: 50%; margin-left: 5%">
              <mat-label>Book Seller Name</mat-label>
              <input matInput formControlName="bsname" [matAutocomplete]="autobsname1" />
              <mat-autocomplete autoActiveFirstOption #autobsname1="matAutocomplete">
                <mat-option *ngFor="let option of filteredOptions12 | async" [value]="option['name']"
                  (onSelectionChange)="selected_book_name($event, option)">
                  {{ option["name"] }}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
          </div>
          <div class="form-row">
            <mat-form-field appearance="outline" style="width: 50%; margin-left: 5%">
              <mat-label>Date</mat-label>
              <mat-date-range-input [rangePicker]="pickerdate">
                <input matStartDate formControlName="start" placeholder="Start date" />
                <input matEndDate formControlName="end" placeholder="End date" (dateChange)="getAuthorDate()" />
              </mat-date-range-input>
              <mat-datepicker-toggle matSuffix [for]="pickerdate"></mat-datepicker-toggle>
              <mat-date-range-picker #pickerdate></mat-date-range-picker>

              <mat-error *ngIf="
                  bookselleraddressForm.controls.start.hasError(
                    'matStartDateInvalid'
                  )
                ">Invalid start date</mat-error>
              <mat-error *ngIf="
                  bookselleraddressForm.controls.end.hasError(
                    'matEndDateInvalid'
                  )
                ">Invalid end date</mat-error>
            </mat-form-field>
          </div>
          <div class="form-row">
            <mat-form-field class="example-full-width noPrint" appearance="outline" style="width: 50%; margin-left: 5%">
              <mat-label>Country</mat-label>
              <input matInput formControlName="country" (change)="country($event)" />
            </mat-form-field>
          </div>
          <div class="form-row">
            <mat-form-field class="example-full-width noPrint" appearance="outline" style="width: 50%; margin-left: 5%">
              <mat-label>State</mat-label>
              <input matInput formControlName="state" (change)="state($event)" />
            </mat-form-field>
          </div>
          <div class="form-row">
            <mat-form-field class="example-full-width noPrint" appearance="outline" style="width: 50%; margin-left: 5%">
              <mat-label>District</mat-label>
              <input matInput formControlName="district" (change)="district($event)" />
            </mat-form-field>
          </div>
          <div class="form-row">
            <mat-form-field class="example-full-width noPrint" appearance="outline" style="width: 50%; margin-left: 5%">
              <mat-label>Taluka</mat-label>
              <input matInput formControlName="taluka" (change)="taluka($event)" />
            </mat-form-field>
          </div>
          <div class="form-row">
            <mat-form-field style="width: 50%; margin-left: 5%" appearance="outline">
              <mat-label>Select Complimentary</mat-label>
              <mat-select formControlName="complimentary" (selectionChange)="isComplementaryAddress($event)">
                <mat-option value="Y">Yes</mat-option>
                <mat-option value="N">No</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <!-- <div class="form-row">
            <mat-form-field
              class="example-full-width noPrint"
              appearance="outline"
              style="width: 50%; margin-left: 5%"
            >
              <mat-label>Office Representative</mat-label>
              <input matInput formControlName="office_rep_name" />
            </mat-form-field>
          </div> -->
          <!-- <div class="form-row">
            <mat-form-field
              class="example-full-width"
              appearance="outline"
              style="width: 50%; margin-left: 5%"
            >
              <mat-label>Representative Name</mat-label>
              <input
                matInput
                formControlName="repname"
                [matAutocomplete]="autorepname1"
              />
              <mat-autocomplete
                autoActiveFirstOption
                #autorepname1="matAutocomplete"
              >
                <mat-option
                  (onSelectionChange)="selected_authorrepname($event, option)"
                  *ngFor="let option of filteredOptions13 | async"
                  [value]="option['name']"
                >
                  {{ option["name"] }}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
          </div> -->
          <div class="form-row">
            <button mat-raised-button class="btn btn-primary" style="margin-left: 8%; width: 15%"
              (click)="showfulladdress()">
              Full Address
            </button>
            <button mat-raised-button class="btn btn-info" style="margin-left: 5%; width: 15%"
              (click)="showlistaddress()">
              List Address
            </button>
          </div>
        </form>
      </mat-card>
      <mat-card>
        <table class="table table-striped text-center">
          <thead>
            <tr>
              <th>Book Seller Name</th>
              <th>Place</th>
              <th>Mobile No.</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of showFilteredBookSellerAddress">
              <td>{{ item["name"] }}</td>
              <td>{{ item["district"] }}</td>
              <td>{{ item["mobile_no"] }}</td>
            </tr>
          </tbody>
        </table>
      </mat-card>
    </mat-tab>
  </mat-tab-group>
</div>