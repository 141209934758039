import { formatDate } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DigisubservicesService } from 'src/app/services/digisubservices.service';

@Component({
  selector: 'app-update-tracking',
  templateUrl: './update-tracking.component.html',
  styleUrls: ['./update-tracking.component.scss']
})
export class UpdateTrackingComponent implements OnInit {

  TrackingDetails: FormGroup;
  group: FormGroup;
  displayByhand = false;
  isStartDate = false
  isEndDate = false
  isTrackingDetails = false
  newDateValue = ''
  ds_id=0
  mobile=0
  OldDates=[]
  //OldEndDates=[]
  constructor(private fb: FormBuilder, public service: DigisubservicesService,
    private dialogRef: MatDialogRef<UpdateTrackingComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void {
    console.log(this.data)
    this.isStartDate = this.data['start_date'];
    this.isEndDate = this.data['end_date'];
    this.isTrackingDetails = this.data['tracking_details'];
    this.ds_id = parseInt(this.data['ds_id']);
    this.mobile = parseInt(this.data['mobileNumber'])
    
    if(this.isStartDate)
    {
      this.service.getOldStartDates(this.ds_id , this.mobile).subscribe(data => {
        console.log(data)
        this.OldDates = data
      })
    }

    if(this.isEndDate)
    {
      this.service.getOldendDates(this.ds_id , this.mobile).subscribe(data => {
        console.log(data)
        this.OldDates = data
      })
    }

    this.TrackingDetails = this.fb.group({
      trasnport_details: [''],
      trasnporter_name: [''],
      trasnport_method: ['']
    });

    this.group = this.fb.group({
      start_end_date:['']
    })


  }

  getChangedDate(event) {
    if (window.confirm("Do you really want to change the date?")) {
      console.log(event.value)
      this.newDateValue = formatDate(event.value, "yyyy-MM-dd", "en-IN", "GMT+0530")
      console.log(this.newDateValue)
      this.update()
    }

  }

  getTrackingMethod(event) {
    console.log(event.target.value)

    if (event.target.value == "byhand" || event.target.value == "byhandpost") {
      this.displayByhand = true;
    } else {
      this.displayByhand = false;
    }
  }

  save() {

  }

  update() {
    if (this.data['tracking_details']) {
      this.service.updateSubTrackingDetails(this.data['ds_id'], this.TrackingDetails.value['trasnport_method']
        , this.TrackingDetails.value['trasnporter_name'], this.TrackingDetails.value['trasnport_details']).subscribe(data => {
          window.alert("Tracking Details has been updated")
          this.dialogRef.close()
        })
    } else if (this.data['start_date']) {
      if (this.newDateValue == "") {
        window.alert("Please Select Valid Date.")
      } else {
        this.service.updateStartDate(parseInt(this.data['mobileNumber']), this.newDateValue, this.data['edition']).subscribe(data => {
          window.alert("Date has been updated!")
          this.dialogRef.close()
        })
      }

    } else if (this.data['end_date']) {
      if (this.newDateValue == "") {
        window.alert("Please Select Valid Date.")
      } else {
        this.service.updateEndDate(parseInt(this.data['mobileNumber']), this.newDateValue, this.data['edition']).subscribe(data => {
          window.alert("Date has been updated!")
          this.dialogRef.close()
        })
      }
    }

  }

}
