import { formatDate } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { DigisubservicesService } from 'src/app/services/digisubservices.service';
import { BookinvoiceComponent } from '../bookinvoice/bookinvoice.component';
import * as XLSX from 'xlsx';
import { SubscriptioninvoiceComponent } from '../subscriptioninvoice/subscriptioninvoice.component';
import { AdvertisementInvoiceComponent } from '../advertisement-invoice/advertisement-invoice.component';

@Component({
  selector: 'app-invoice',
  templateUrl: './invoice.component.html',
  styleUrls: ['./invoice.component.scss'],
})
export class InvoiceComponent implements OnInit {
  displayRepoption = false;
  displayCustOption = false;
  getAllRepList = [];
  getRepSpecificBooks = [];
  RepInvoiceData = [];
  CustInvoiceData = [];
  CustSubInvoiceData = [];
  AdvtInvoiceData = [];
  gstNumber = '';
  newCustSubInvoice = false;
  newCustadvtInvoice = false;
  invoicegetBook = new FormGroup({
    start: new FormControl(),
    end: new FormControl(),
  });

  invoicegetCustBook = new FormGroup({
    start: new FormControl(),
    end: new FormControl(),
  });

  repGeneratedInvoice = new FormGroup({
    start: new FormControl(),
    end: new FormControl(),
  });

  CustGeneratedInvoice = new FormGroup({
    start: new FormControl(),
    end: new FormControl(),
  });

  CustSubGeneratedInvoice = new FormGroup({
    start: new FormControl(),
    end: new FormControl(),
  });

  AdvtGeneratedInvoice = new FormGroup({
    start: new FormControl(),
    end: new FormControl(),
  });

  cust_id;
  cust_mobile;
  advt_id;
  advt_mobile;

  constructor(
    private router: Router,
    public dialog: MatDialog,
    private fb: FormBuilder,
    public service: DigisubservicesService
  ) {}

  ngOnInit(): void {
    this.service.get_AllRepresentative_List().subscribe((data) => {
      console.log(data);
      this.getAllRepList = data;
    });
  }

  getSelectedRepId;
  getSelectedRep;
  SelectRepresentative(event) {
    console.log(event.value);
    this.getSelectedRepId = event.value;
    this.getSelectedRep = this.getAllRepList.find(
      (a) => a['id'] == parseInt(this.getSelectedRepId)
    );
    this.service
      .Rep_invoiceBooks(
        parseInt(this.getSelectedRepId),
        this.getinvoicestartDate,
        this.getinvoiceEndDate
      )
      .subscribe((data) => {
        console.log(data);
        this.getRepSpecificBooks = data;
      });
  }

  getinvoicestartDate;
  getinvoiceEndDate;
  getDateRange() {
    if (
      this.invoicegetBook.get('start').value != null &&
      this.invoicegetBook.get('end').value != null
    ) {
      console.log(this.invoicegetBook.value);
      console.log(
        formatDate(
          new Date(this.invoicegetBook.get('start').value),
          'yyyy-MM-dd',
          'en-IN',
          'GMT+0530'
        )
      );
      console.log(
        formatDate(
          new Date(this.invoicegetBook.get('end').value),
          'dd-MM-yyyy',
          'en-IN',
          'GMT+0530'
        )
      );
      this.getinvoicestartDate = formatDate(
        new Date(this.invoicegetBook.get('start').value),
        'yyyy-MM-dd',
        'en-IN',
        'GMT+0530'
      );
      this.getinvoiceEndDate = formatDate(
        new Date(this.invoicegetBook.get('end').value),
        'yyyy-MM-dd',
        'en-IN',
        'GMT+0530'
      );

      //this.FilteredBookRegistration = this.bookregistrationdetails.filter(a => { return new Date(a['received_date']) >= new Date(startdate) && new Date(a['received_date']) <= new Date(endDate) });
    }
  }

  getCustinvoicestartDate;
  getCustinvoiceEndDate;
  getAllCustList = [];
  getCustlistWithoutFilter = [];
  getSpecificBooksCust = [];
  getCustDateRange() {
    this.getAllCustList = [];
    if (
      this.invoicegetCustBook.get('start').value != null &&
      this.invoicegetCustBook.get('end').value != null
    ) {
      console.log(this.invoicegetCustBook.value);
      console.log(
        formatDate(
          new Date(this.invoicegetCustBook.get('start').value),
          'yyyy-MM-dd',
          'en-IN',
          'GMT+0530'
        )
      );
      console.log(
        formatDate(
          new Date(this.invoicegetCustBook.get('end').value),
          'dd-MM-yyyy',
          'en-IN',
          'GMT+0530'
        )
      );
      this.getCustinvoicestartDate = formatDate(
        new Date(this.invoicegetCustBook.get('start').value),
        'yyyy-MM-dd',
        'en-IN',
        'GMT+0530'
      );
      this.getCustinvoiceEndDate = formatDate(
        new Date(this.invoicegetCustBook.get('end').value),
        'yyyy-MM-dd',
        'en-IN',
        'GMT+0530'
      );

      this.service
        .getCustInvoicesName(
          this.getCustinvoicestartDate,
          this.getCustinvoiceEndDate
        )
        .subscribe((data) => {
          for (let index = 0; index < Object.keys(data).length; index++) {
            if (index == 0) {
              this.getAllCustList.push(data[index]);
            } else {
              if (
                this.getAllCustList.find(
                  (a) =>
                    parseInt(a['mobile']) === parseInt(data[index]['mobile'])
                ) == undefined
              ) {
                this.getAllCustList.push(data[index]);
              }
            }
          }
          this.getCustlistWithoutFilter = data;
        });
    }
  }

  getSelectedCust;
  SelectCustomer(event) {
    console.log(event.value);
    let custMobile = event.value;
    let selectedCust = '';
    this.getSelectedCust = this.getCustlistWithoutFilter.find(
      (a) => parseInt(a['mobile']) == parseInt(custMobile)
    );
    console.log(this.getSelectedCust);
    for (
      let index = 0;
      index < Object.keys(this.getCustlistWithoutFilter).length;
      index++
    ) {
      if (
        parseInt(this.getCustlistWithoutFilter[index]['mobile']) ==
        parseInt(custMobile)
      ) {
        if (selectedCust == '') {
          selectedCust = this.getCustlistWithoutFilter[index]['bdp_id'];
        } else {
          selectedCust =
            selectedCust + ',' + this.getCustlistWithoutFilter[index]['bdp_id'];
        }
      }
    }
    console.log(selectedCust);
    //let selectedCust = this.getCustlistWithoutFilter.find(a => a['mobile'] == parseInt(custMobile))
    console.log(selectedCust);
    //this.service.Rep_invoiceBooks(parseInt(this.getSelectedRepId) , this.getinvoicestartDate , this.getinvoiceEndDate).subscribe(data=>{
    // console.log(data)
    // this.getRepSpecificBooks = data
    //})

    setTimeout(() => {
      this.service.getCustInvoicesbookNames(selectedCust).subscribe((data) => {
        console.log(data);
        this.getSpecificBooksCust = data;
      });
    }, 1000);
  }

  getRepSelectedBookslist;
  getRepSelectedBooks(event) {
    console.log(event);
    this.getRepSelectedBookslist = event.value;
  }

  invoiceDetails;
  viewmode = false;
  repInvoice = 'Y';
  generateBookInvoice() {
    console.log(this.getRepSelectedBookslist);
    this.invoiceDetails = {
      rep: this.getSelectedRep,
      books: this.getRepSelectedBookslist,
      GST: this.isGSTInvoice,
      GST_no: this.gstNumber,
    };
    this.viewmode = false;
    this.repInvoice = 'Y';
    this.GenerateInvoice();
  }

  generateCustInvoice() {
    console.log(this.getRepSelectedBookslist);
    this.invoiceDetails = {
      rep: this.getSelectedCust,
      books: this.getRepSelectedBookslist,
      GST: this.isGSTInvoice,
      GST_no: this.gstNumber,
    };
    this.viewmode = false;
    this.repInvoice = 'N';
    this.GenerateInvoice();
  }

  GenerateInvoice() {
    this.dialog.open(BookinvoiceComponent, {
      data: {
        book: this.invoiceDetails,
        view: this.viewmode,
        rep_invoice: this.repInvoice,
      },
      height: '1300px',
      width: '1200px',
      maxWidth: '100%',
    });
  }

  isGSTInvoice = 'N';
  displaygstNumberInput = false;
  getInvoiceType(event) {
    this.isGSTInvoice = event.value;
    if (this.isGSTInvoice == 'Y') {
      this.displaygstNumberInput = true;
    } else {
      this.displaygstNumberInput = false;
    }
  }

  getGeneratedInvoiceStartDate;
  getGeneratedInvoiceEndDate;
  getRepInvoiceDateRange() {
    if (
      this.repGeneratedInvoice.get('start').value != null &&
      this.repGeneratedInvoice.get('end').value != null
    ) {
      console.log(this.repGeneratedInvoice.value);
      console.log(
        formatDate(
          new Date(this.repGeneratedInvoice.get('start').value),
          'yyyy-MM-dd',
          'en-IN',
          'GMT+0530'
        )
      );
      console.log(
        formatDate(
          new Date(this.repGeneratedInvoice.get('end').value),
          'dd-MM-yyyy',
          'en-IN',
          'GMT+0530'
        )
      );
      this.getGeneratedInvoiceStartDate = formatDate(
        new Date(this.repGeneratedInvoice.get('start').value),
        'yyyy-MM-dd',
        'en-IN',
        'GMT+0530'
      );
      this.getGeneratedInvoiceEndDate = formatDate(
        new Date(this.repGeneratedInvoice.get('end').value),
        'yyyy-MM-dd',
        'en-IN',
        'GMT+0530'
      );
    }
  }

  getGeneratedInvoiceStartDateCust;
  getGeneratedInvoiceEndDateCust;
  getCustInvoiceDateRange() {
    if (
      this.CustGeneratedInvoice.get('start').value != null &&
      this.CustGeneratedInvoice.get('end').value != null
    ) {
      console.log(this.CustGeneratedInvoice.value);
      console.log(
        formatDate(
          new Date(this.CustGeneratedInvoice.get('start').value),
          'yyyy-MM-dd',
          'en-IN',
          'GMT+0530'
        )
      );
      console.log(
        formatDate(
          new Date(this.CustGeneratedInvoice.get('end').value),
          'dd-MM-yyyy',
          'en-IN',
          'GMT+0530'
        )
      );
      this.getGeneratedInvoiceStartDateCust = formatDate(
        new Date(this.CustGeneratedInvoice.get('start').value),
        'yyyy-MM-dd',
        'en-IN',
        'GMT+0530'
      );
      this.getGeneratedInvoiceEndDateCust = formatDate(
        new Date(this.CustGeneratedInvoice.get('end').value),
        'yyyy-MM-dd',
        'en-IN',
        'GMT+0530'
      );
    }
  }

  getRepInvoice() {
    this.service
      .getRepInvoices(
        this.getGeneratedInvoiceStartDate,
        this.getGeneratedInvoiceEndDate
      )
      .subscribe((data) => {
        console.log(data);
        this.RepInvoiceData = data;
      });
  }

  getCustInvoice() {
    this.service
      .getCustInvoices(
        this.getGeneratedInvoiceStartDateCust,
        this.getGeneratedInvoiceEndDateCust
      )
      .subscribe((data) => {
        console.log(data);
        this.CustInvoiceData = data;
      });
  }

  /*name of the excel-file which will be downloaded. */
  fileName = 'ExcelSheet.xlsx';

  exportexcel(): void {
    /* table id is passed over here */
    let element = document.getElementById('excel-table');
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* save to file */
    XLSX.writeFile(wb, this.fileName);
  }

  getRepInvoiceData = [];
  ViewInvoice(book) {
    this.getSelectedRep = this.getAllRepList.find(
      (a) => a['id'] == parseInt(book['id'])
    );

    if (
      parseInt(book['cgst']) == 0 ||
      parseInt(book['sgst']) == 0 ||
      parseInt(book['igst']) == 0
    ) {
      this.isGSTInvoice = 'N';
    } else {
      this.isGSTInvoice = 'Y';
    }

    this.service
      .getRepInvoicesData(parseInt(book['invoice_id']))
      .subscribe((data) => {
        console.log(data);
        this.getRepInvoiceData = data;
        let getBookInvoiceDetails = {
          invoice_details: book,
          bookDetail: this.getRepInvoiceData,
        };
        this.invoiceDetails = {
          rep: this.getSelectedRep,
          books: getBookInvoiceDetails,
          GST: this.isGSTInvoice,
          GST_no: book['gst_no'],
        };
        this.viewmode = true;
        this.repInvoice = 'Y';
        setTimeout(() => {
          this.GenerateInvoice();
        }, 1000);
      });
  }

  ViewCustInvoice(book) {
    this.getSelectedRep = this.getAllRepList.find(
      (a) => a['id'] == parseInt(book['id'])
    );

    if (
      parseInt(book['cgst']) == 0 ||
      parseInt(book['sgst']) == 0 ||
      parseInt(book['igst']) == 0
    ) {
      this.isGSTInvoice = 'N';
    } else {
      this.isGSTInvoice = 'Y';
    }

    this.service
      .getCustInvoicesData(parseInt(book['invoice_id']))
      .subscribe((data) => {
        console.log(data);
        this.getRepInvoiceData = data;
        let getBookInvoiceDetails = {
          invoice_details: book,
          bookDetail: this.getRepInvoiceData,
        };
        this.invoiceDetails = {
          rep: this.getSelectedRep,
          books: getBookInvoiceDetails,
          GST: this.isGSTInvoice,
          GST_no: book['gst_no'],
        };
        this.viewmode = true;
        this.repInvoice = 'N';
        setTimeout(() => {
          this.GenerateInvoice();
        }, 1000);
      });
  }

  getGstNumber(gst) {
    this.gstNumber = gst;
  }

  get_cust_id(cust_id) {
    console.log(this.cust_id);

    this.reset_cust_mobile();
  }

  reset_cust_id() {
    this.cust_id = '';
  }

  getcust_mobile(mobile) {
    console.log(mobile);
    //this.cust_id = ''
    console.log(this.cust_mobile.length);
    this.reset_cust_id();
  }

  reset_cust_mobile() {
    this.cust_mobile = '';
  }

  //Advt details :
  get_cust_advt_id(advt_id) {
    console.log(this.advt_id);
    this.reset_cust_mobile();
  }

  reset_advt_id() {
    this.advt_id = '';
  }

  getcust_advt_mobile(mobile) {
    console.log(mobile);
    //this.cust_id = ''
    console.log(this.advt_mobile.length);
    this.reset_advt_id();
  }

  reset_advt_mobile() {
    this.advt_mobile = '';
  }

  //end of advt

  getSubDetails = [];
  displayNewSubscriber = false;
  getMagazine;
  //isValidSub= false;
  getSubscriber() {
    console.log(this.cust_mobile);
    console.log(this.cust_id);
    if (this.cust_id == '' || this.cust_id == undefined) {
      this.service
        .getSubByIdorNumber(123, parseInt(this.cust_mobile))
        .subscribe((data) => {
          console.log(data);
          this.getSubDetails = data;
          if (Object.keys(data).length > 0) {
            this.displayNewSubscriber = true;
            this.service
              .getSubmagByIdorNumber(parseInt(this.cust_mobile))
              .subscribe((res) => {
                console.log(res);
                //this.startLoading = false
                this.getMagazine = res;
              });
          } else {
            window.alert(
              'No Subscriber found. Please try to add New Subscriber.'
            );
            this.displayNewSubscriber = false;
          }
        });
    } else if (this.cust_mobile == '' || this.cust_mobile == undefined) {
      this.service
        .getSubByIdorNumber(parseInt(this.cust_id), 777)
        .subscribe((data) => {
          console.log(data);
          this.getSubDetails = data;
          if (Object.keys(data).length > 0) {
            this.displayNewSubscriber = true;
            this.service
              .getSubmagByIdorNumber(
                parseInt(this.getSubDetails[0]['mobileNumber'])
              )
              .subscribe((res) => {
                console.log(res);
                //this.startLoading = false
                this.getMagazine = res;
              });
          } else {
            window.alert(
              'No Subscriber found. Please try to add New Subscriber.'
            );
            this.displayNewSubscriber = false;
          }
        });
    } else {
      window.alert('Please Enter Valid Details!');
    }
  }

  getAdvtPersonDetail = [];
  getAdvertisement;
  displayadvtSubscriber = false;
  getAdvtDetails() {
    console.log(this.advt_id);
    console.log(this.advt_mobile);

    if (this.advt_mobile == '' || this.advt_mobile == undefined) {
      this.service
        .getAdvtMobileorid(this.advt_id, 777, 'xyz')
        .subscribe((data) => {
          if (Object.keys(data).length > 0) {
            this.displayadvtSubscriber = true;

            console.log(data);
            this.getAdvtPersonDetail = data;

            this.service
              .getAdvtByid(parseInt(this.advt_id))
              .subscribe((advt) => {
                console.log(data);
                this.getAdvertisement = advt;
              });
          } else {
            this.displayadvtSubscriber = false;
            window.alert(
              'No Advertisement Found. Please try with different number.'
            );
          }
        });
    } else if (this.advt_id == '' || this.advt_id == undefined) {
      this.service
        .getAdvtMobileorid(-1, this.advt_mobile, 'xyz')
        .subscribe((data) => {
          console.log(data);
          if (Object.keys(data).length > 0) {
            this.displayadvtSubscriber = true;
            this.getAdvtPersonDetail = data;

            this.service
              .getAdvtByid(parseInt(this.advt_id))
              .subscribe((advt) => {
                console.log(data);
                this.getAdvertisement = advt;
              });
          } else {
            this.displayadvtSubscriber = false;
            window.alert(
              'No Advertisement Found. Please try with different number.'
            );
          }
        });
    }
  }

  getAdvtInvoice() {
    let prepareCust = {
      cust_id: this.getAdvtPersonDetail[0]['advt_id'],
      name: this.getAdvtPersonDetail[0]['name'],
      mobile: this.getAdvtPersonDetail[0]['mobile'],
      address:
        this.getAdvtPersonDetail[0]['address'] +
        ',' +
        this.getAdvtPersonDetail[0]['taluka'] +
        ',' +
        this.getAdvtPersonDetail[0]['district'] +
        ',' +
        this.getAdvtPersonDetail[0]['state'] +
        ',' +
        this.getAdvtPersonDetail[0]['country'],
      advt_amount: parseInt(this.getAdvtPersonDetail[0]['advt_amount']),
      advt_discount: parseInt(this.getAdvtPersonDetail[0]['advt_discount']),
      cgst: parseInt(this.getAdvtPersonDetail[0]['cgst']),
      sgst: parseInt(this.getAdvtPersonDetail[0]['sgst']),
      igst: parseInt(this.getAdvtPersonDetail[0]['igst']),
      part_amount: parseInt(this.getAdvtPersonDetail[0]['DS_partAmount']),
      remaining_amount: parseInt(
        this.getAdvtPersonDetail[0]['DS_remainingAmount']
      ),
    };

    let prepareAdvt = [];
    for (
      let index = 0;
      index < Object.keys(this.getAdvertisement).length;
      index++
    ) {
      let issue_name = this.getAdvertisement[index]['issue_name'];
      let issue_date =
        this.getAdvertisement[index]['published_dates'].split(',');
      let issue_pages =
        this.getAdvertisement[index]['published_pages'].split(',');
      let advt_size = this.getAdvertisement[index]['advt_size'];

      if (issue_date.length == 0) {
        window.alert(issue_name + ' does not have publishing dates / pages.');
      } else {
        for (
          let index1 = 0;
          index1 < Object.keys(issue_date).length;
          index1++
        ) {
          prepareAdvt.push({
            issue_name: issue_name,
            publish_date: issue_date[index1],
            publish_page: issue_pages[index1],
            advt_size: advt_size,
          });
        }
      }
    }

    console.log(prepareAdvt);
    //console.log(this.getRepSelectedBookslist)
    this.invoiceDetails = {
      rep: prepareCust,
      advts: prepareAdvt,
      GST: this.isGSTInvoice,
      GST_no: this.gstNumber,
    };
    this.viewmode = false;
    this.repInvoice = 'N';

    this.dialog.open(AdvertisementInvoiceComponent, {
      data: {
        book: this.invoiceDetails,
        view: this.viewmode,
        rep_invoice: this.repInvoice,
      },
      height: '1300px',
      width: '1200px',
      maxWidth: '100%',
    });
  }

  getSubInvoice() {
    let prepareCust = {
      cust_id: this.getSubDetails[0]['cust_id'],
      name: this.getSubDetails[0]['title'] + this.getSubDetails[0]['name'],
      mobile: this.getSubDetails[0]['mobileNumber'],
      address:
        this.getSubDetails[0]['address'] +
        ',' +
        this.getSubDetails[0]['taluka'] +
        ',' +
        this.getSubDetails[0]['city'] +
        ',' +
        this.getSubDetails[0]['state'],
    };

    let prepareMag = [];
    for (let index = 0; index < this.getMagazine.length; index++) {
      prepareMag.push({
        book_name:
          this.getMagazine[index]['ds_mag1'] +
          ' - Edition : ' +
          this.getMagazine[index]['ds_mag1edition'] +
          '   Start Date : ' +
          this.getMagazine[index]['ds_mag1startdt'] +
          ' & End Date : ' +
          this.getMagazine[index]['ds_mag1enddt'],
        book_qty: 1,
        book_rate: parseInt(this.getMagazine[index]['ds_totamt']),
        book_total: parseInt(this.getMagazine[index]['ds_totamt']),
      });
    }

    //console.log(this.getRepSelectedBookslist)
    this.invoiceDetails = {
      rep: prepareCust,
      books: prepareMag,
      GST: this.isGSTInvoice,
      GST_no: this.gstNumber,
    };
    this.viewmode = false;
    this.repInvoice = 'N';
    this.GenerateInvoiceSubscriber();
  }

  GenerateInvoiceSubscriber() {
    this.dialog.open(SubscriptioninvoiceComponent, {
      data: {
        book: this.invoiceDetails,
        view: this.viewmode,
        rep_invoice: this.repInvoice,
      },
      height: '1300px',
      width: '1200px',
      maxWidth: '100%',
    });
  }

  getCustSubInvoiceStartDate;
  getCustSubInvoiceEndDate;
  getCustInvoiceDateRangeSubscription() {
    if (
      this.CustSubGeneratedInvoice.get('start').value != null &&
      this.CustSubGeneratedInvoice.get('end').value != null
    ) {
      console.log(this.CustSubGeneratedInvoice.value);
      console.log(
        formatDate(
          new Date(this.CustSubGeneratedInvoice.get('start').value),
          'yyyy-MM-dd',
          'en-IN',
          'GMT+0530'
        )
      );
      console.log(
        formatDate(
          new Date(this.CustSubGeneratedInvoice.get('end').value),
          'dd-MM-yyyy',
          'en-IN',
          'GMT+0530'
        )
      );
      this.getCustSubInvoiceStartDate = formatDate(
        new Date(this.CustSubGeneratedInvoice.get('start').value),
        'yyyy-MM-dd',
        'en-IN',
        'GMT+0530'
      );
      this.getCustSubInvoiceEndDate = formatDate(
        new Date(this.CustSubGeneratedInvoice.get('end').value),
        'yyyy-MM-dd',
        'en-IN',
        'GMT+0530'
      );
    }
  }

  getCustSubInvoice() {
    this.service
      .getCustInvoicesSubscription(
        this.getCustSubInvoiceStartDate,
        this.getCustSubInvoiceEndDate
      )
      .subscribe((data) => {
        console.log(data);
        this.CustSubInvoiceData = data;
      });
  }

  getadvtinvoiceStartDate;
  getadvtinvoiceEndDate;
  getAdvtDateRange() {
    if (
      this.AdvtGeneratedInvoice.get('start').value != null &&
      this.AdvtGeneratedInvoice.get('end').value != null
    ) {
      console.log(this.AdvtGeneratedInvoice.value);
      console.log(
        formatDate(
          new Date(this.AdvtGeneratedInvoice.get('start').value),
          'yyyy-MM-dd',
          'en-IN',
          'GMT+0530'
        )
      );
      console.log(
        formatDate(
          new Date(this.AdvtGeneratedInvoice.get('end').value),
          'dd-MM-yyyy',
          'en-IN',
          'GMT+0530'
        )
      );
      this.getadvtinvoiceStartDate = formatDate(
        new Date(this.AdvtGeneratedInvoice.get('start').value),
        'yyyy-MM-dd',
        'en-IN',
        'GMT+0530'
      );
      this.getadvtinvoiceEndDate = formatDate(
        new Date(this.AdvtGeneratedInvoice.get('end').value),
        'yyyy-MM-dd',
        'en-IN',
        'GMT+0530'
      );
    }
  }

  readAdvtInvoice() {
    this.service
      .getCustInvoicesadvt(
        this.getadvtinvoiceStartDate,
        this.getadvtinvoiceEndDate
      )
      .subscribe((data) => {
        console.log(data);
        this.AdvtInvoiceData = data;
      });
  }

  ViewAdvtInvoice(advt) {
    console.log(advt);

    let prepareCust = {
      cust_id: advt['id'],
      name: advt['name'],
      mobile: advt['mobile'],
      address: advt['address'],
      invoice_amount: parseInt(advt['invoice_total']),
      cgst: parseInt(advt['cgst']),
      sgst: parseInt(advt['sgst']),
      igst: parseInt(advt['igst']),
      taxable_amount: parseInt(advt['taxable_amount']),
      total_amount: parseInt(advt['total_amount']),
      total_discount: parseInt(advt['total_discount']),
      discounted_price: parseInt(advt['discounted_price']),
      gst_no: advt['gst_no'],
      invoice_id: advt['invoice_id'],
      invoice_date: advt['invoice_date'],
    };

    let prepareAdvt = [];
    this.service
      .getadvtInvoiceData(parseInt(advt['invoice_id']))
      .subscribe((advt_data) => {
        for (let index = 0; index < Object.keys(advt_data).length; index++) {
          prepareAdvt.push({
            issue_name: advt_data[index]['advt_name'],
            publish_date: advt_data[index]['published_date'],
            publish_page: advt_data[index]['page_number'],
            advt_size: advt_data[index]['advt_size'],
          });
        }
      });

    console.log(prepareAdvt);
    //console.log(this.getRepSelectedBookslist)
    this.invoiceDetails = {
      rep: prepareCust,
      advts: prepareAdvt,
      GST: 'Y',
      GST_no: advt['gst_no'],
    };
    this.viewmode = true;
    this.repInvoice = 'N';

    this.dialog.open(AdvertisementInvoiceComponent, {
      data: {
        book: this.invoiceDetails,
        view: this.viewmode,
        rep_invoice: this.repInvoice,
      },
      height: '1300px',
      width: '1200px',
      maxWidth: '100%',
    });
  }

  ViewCustInvoiceSubscription(book) {
    console.log(book);

    this.service
      .getSubByIdorNumber(parseInt(book['id']), 777)
      .subscribe((data) => {
        console.log(data);
        this.getSubDetails = data;

        this.getSelectedRep = {
          id: this.getSubDetails[0]['cust_id'],
          name: this.getSubDetails[0]['title'] + this.getSubDetails[0]['name'],
          mobile: this.getSubDetails[0]['mobileNumber'],
          address:
            this.getSubDetails[0]['address'] +
            ',' +
            this.getSubDetails[0]['taluka'] +
            ',' +
            this.getSubDetails[0]['city'] +
            ',' +
            this.getSubDetails[0]['state'],
        };

        //this.getSelectedRep = this.getAllRepList.find(a => a['id'] == parseInt(book['id']))

        if (
          parseInt(book['cgst']) == 0 ||
          parseInt(book['sgst']) == 0 ||
          parseInt(book['igst']) == 0
        ) {
          this.isGSTInvoice = 'N';
        } else {
          this.isGSTInvoice = 'Y';
        }

        this.service
          .getCustInvoicesDataSubscription(parseInt(book['invoice_id']))
          .subscribe((data) => {
            console.log(data);
            this.getRepInvoiceData = data;
            let getBookInvoiceDetails = {
              invoice_details: book,
              bookDetail: this.getRepInvoiceData,
            };
            this.invoiceDetails = {
              rep: this.getSelectedRep,
              books: getBookInvoiceDetails,
              GST: this.isGSTInvoice,
              GST_no: book['gst_no'],
            };
            this.viewmode = true;
            this.repInvoice = 'N';
            setTimeout(() => {
              this.GenerateInvoiceSubscriber();
            }, 1000);
          });
      });
  }
}
