<div>
    <div style="margin-left: 80%; margin-bottom: 2%">
        <!-- 'word-wrap': 'break-word'
        'max-width':'fit-content' -->
        <button class="noPrint" style="margin-top: 2%;" mat-raised-button color="primary"
        [useExistingCss]="true"
            printSectionId="print-section" ngxPrint>
            Print
        </button>
        <button mat-dialog-close class="btn noPrint">Close</button>
        <mat-card *ngIf="showindetail">
            <div class="tableFixHead" id="excel-table">
              <button type="button" mat-raised-button matTooltip="Download Execelsheet" class="btn btn-danger"
                (click)="exportexcel('Registration Report')" style="margin-top: 2%;">
                Export
              </button>
              <table class="table table-bordered">
                <thead>
                  <tr>
                    <th colspan="3">Vivek Saptahik</th>
                    <th colspan="5">Advt. Registreation Report</th>
                    <th colspan="2">In Detail</th>
                    <th colspan="2"></th>
                    <th colspan="3"></th>
                    <th colspan="3">Date :</th>
                  </tr>
                  <tr>
                    <th colspan="3">Date. From :</th>
                    <th colspan="2">To :</th>
                    <th colspan="3">Issue/Grantha Categary :</th>
                    <th colspan="2">Issue Date :</th>
                    <th colspan="5">Issue Name :</th>
                    <th colspan="3">Page No</th>
                  </tr>
                  <tr>
                    <th>Issue Sr. No</th>
                    <th>Booking Date</th>
                    <th>Booking No</th>
                    <th>Advertiser Name</th>
                    <th>Customer Name</th>
                    <th>Representative Name</th>
                    <th>Mobile No</th>
                    <th>Office Represantative Name</th>
                    <th>RO Number</th>
                    <th>Instruction</th>
                    <th>Advt.Size</th>
                    <th>Advt Amount Rs</th>
                    <th>Net Amount</th>
                    <th>GST 5%</th>
                    <th>Total Amount</th>
                    <th>Received Amount</th>
                    <th>Receipt No. and Date</th>
                    <th>Pending Amount</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let item of filteredinDetailsRegReport; let i = index">
                    <td>{{ i + 1 }}</td>
                    <td>{{ item["booking_date"] | date : "dd-MM-yyy" }}</td>
                    <td>{{ item["booking_no"] }}</td>
                    <td>{{ item["advt_name"] }}</td>
                    <td>{{ item["cust_name"] }}</td>
                    <td>{{ item["rep_name"] }}</td>
                    <td>{{ item["rep_mob_no"] }}</td>
                    <td>{{ item["off_rep_name"] }}</td>
                    <td>{{ item["ro_no"] }}</td>
                    <td>{{ item["instruction"] }}</td>
                    <td>{{ item["advtsize"] }}</td>
                    <td>{{ item["advt_amt"] }}</td>
                    <td>{{ item["total_amount"] }}</td>
                    <td>{{ toInt(item["sgst"]) + toInt(item["cgst"]) }}</td>
                    <td>{{ item["amount"] }}</td>
                    <td>{{ item["part_pymt_amt"] }}</td>
                    <td>
                      {{ item["rep_rcpt_no"] }}<br />
                      {{ item["rcpt_date"] | date : "dd-MM-yyy" }}
                    </td>
                    <td>{{ item["part_pymt_remaning_amt"] }}</td>
                  </tr>
                </tbody>
                <tfoot>
                  <tr>
                    <td colspan="2">Total</td>
                    <td colspan="2">
                      Total No of Advt :
                      {{ this.inDetailsRegReportTotal.total_advt }}
                    </td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td>{{ this.inDetailsRegReportTotal.advt_total }}</td>
                    <td>{{ this.inDetailsRegReportTotal.net_amt }}</td>
                    <td>{{ this.inDetailsRegReportTotal.gst }}</td>
                    <td>{{ this.inDetailsRegReportTotal.total_amt }}</td>
                    <td>{{ this.inDetailsRegReportTotal.rcvd_amt }}</td>
                    <td></td>
                    <td>{{ this.inDetailsRegReportTotal.pndg_amt }}</td>
                  </tr>
                </tfoot>
              </table>
            </div>
          </mat-card>
          <!-- <mat-card *ngIf="showindetaildatewise">
            <div class="tableFixHead" id="excel-table">
              <button type="button" mat-raised-button matTooltip="Download Execelsheet" class="btn btn-danger"
                (click)="exportexcel('Registration Report')" style="float: right">
                Export
              </button>
              <div *ngFor="let data of inDetailsRegReportsubTotalData;
                          let s = index; let end = last
                          ">
                <table class="table table-bordered">
                  <thead *ngIf="s == 0">
                    <tr>
                      <th colspan="3">Vivek Saptahik</th>
                      <th colspan="5">Advt. Registreation Report</th>
                      <th colspan="2">Datewise</th>
                      <th colspan="2">In Detail</th>
                      <th colspan="3"></th>
                      <th colspan="3">Date :</th>
                    </tr>
                    <tr>
                      <th colspan="3">Date. From :</th>
                      <th colspan="2">To :</th>
                      <th colspan="3">Issue/Grantha Categary :</th>
                      <th colspan="2">Issue Date :</th>
                      <th colspan="5">Issue Name :</th>
                      <th colspan="3">Page No</th>
                    </tr>
                    <tr>
                      <th>Issue Sr. No</th>
                      <th>Booking Date</th>
                      <th>Booking No</th>
                      <th>Advertiser Name</th>
                      <th>Customer Name</th>
                      <th>Representative Name</th>
                      <th>Mobile No</th>
                      <th>Office Represantative Name</th>
                      <th>RO Number</th>
                      <th>Instruction</th>
                      <th>Advt.Size</th>
                      <th>Advt Amount Rs</th>
                      <th>Net Amount</th>
                      <th>GST 5%</th>
                      <th>Total Amount</th>
                      <th>Received Amount</th>
                      <th>Receipt No. and Date</th>
                      <th>Pending Amount</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let item of data; let i = index; let last = last">
                      <td *ngIf="i == 0" colspan="3">Date</td>
                      <td *ngIf="i == 0">{{ item["booking_date"] | date : "dd-MM-yyy" }}</td>
                      <td *ngIf="i == 0"></td>
                      <td *ngIf="i == 0"></td>
                      <td *ngIf="i == 0"></td>
                      <td *ngIf="i == 0"></td>
                      <td *ngIf="i == 0"></td>
                      <td *ngIf="i == 0"></td>
                      <td *ngIf="i == 0"></td>
                      <td *ngIf="i == 0"></td>
                      <td *ngIf="i == 0"></td>
                      <td *ngIf="i == 0"></td>
                      <td *ngIf="i == 0"></td>
                      <td *ngIf="i == 0"></td>
                      <td *ngIf="i == 0"></td>
                      <td *ngIf="i == 0"></td>
                    </tr>
                    <tr *ngFor="let item of data; let i = index; let last = last">
                      <td *ngIf="!last">{{ i + 1 }}</td>
                      <td *ngIf="!last">{{ item["booking_date"] | date : "dd-MM-yyy" }}</td>
                      <td *ngIf="!last">{{ item["booking_no"] }}</td>
                      <td *ngIf="!last">{{ item["advt_name"] }}</td>
                      <td *ngIf="!last">{{ item["cust_name"] }}</td>
                      <td *ngIf="!last">{{ item["rep_name"] }}</td>
                      <td *ngIf="!last">{{ item["rep_mob_no"] }}</td>
                      <td *ngIf="!last">{{ item["off_rep_name"] }}</td>
                      <td *ngIf="!last">{{ item["ro_no"] }}</td>
                      <td *ngIf="!last">{{ item["instruction"] }}</td>
                      <td *ngIf="!last">{{ item["advtsize"] }}</td>
                      <td *ngIf="!last">{{ item["advt_amt"] }}</td>
                      <td *ngIf="!last">{{ item["total_amount"] }}</td>
                      <td *ngIf="!last">{{ toInt(item["sgst"]) + toInt(item["cgst"]) }}</td>
                      <td *ngIf="!last">{{ item["amount"] }}</td>
                      <td *ngIf="!last">{{ item["part_pymt_amt"] }}</td>
                      <td *ngIf="!last">
                        {{ item["rep_rcpt_no"] }}<br />
                        {{ item["rcpt_date"] | date : "dd-MM-yyy" }}
                      </td>
                      <td *ngIf="!last">{{ item["part_pymt_remaning_amt"] }}</td>
                    </tr>
                    <tr *ngFor="let item of data; let i = index; let last = last">
                      <td *ngIf="last" colspan="2">Total</td>
                      <td *ngIf="last" colspan="2">
                        Total No of Advt : {{ item["total_advt"] }}
                      </td>
                      <td *ngIf="last"></td>
                      <td *ngIf="last"></td>
                      <td *ngIf="last"></td>
                      <td *ngIf="last"></td>
                      <td *ngIf="last"></td>
                      <td *ngIf="last"></td>
                      <td *ngIf="last"></td>
                      <td *ngIf="last">{{ item["advt_total"] }}</td>
                      <td *ngIf="last">{{ item["net_amt"] }}</td>
                      <td *ngIf="last">{{ item["gst"] }}</td>
                      <td *ngIf="last">{{ item["total_amt"] }}</td>
                      <td *ngIf="last">{{ item["rcvd_amt"] }}</td>
                      <td *ngIf="last"></td>
                      <td *ngIf="last">{{ item["pndg_amt"] }}</td>
                    </tr>
                  </tbody>
                  <tfoot *ngIf="end">
                    <tr>
                      <td colspan="2">Total</td>
                      <td colspan="2">
                        Total No of Advt :
                        {{ this.inDetailsRegReportTotal.total_advt }}
                      </td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td>{{ this.inDetailsRegReportTotal.advt_total }}</td>
                      <td>{{ this.inDetailsRegReportTotal.net_amt }}</td>
                      <td>{{ this.inDetailsRegReportTotal.gst }}</td>
                      <td>{{ this.inDetailsRegReportTotal.total_amt }}</td>
                      <td>{{ this.inDetailsRegReportTotal.rcvd_amt }}</td>
                      <td></td>
                      <td>{{ this.inDetailsRegReportTotal.pndg_amt }}</td>
                    </tr>
                  </tfoot>
                </table>
              </div>
            </div>
          </mat-card> -->
    </div>
    <div class="container" id="print-section1">
        <!-- Indetail Report -->
        <ng-container *ngIf="indetail && !datewise && !officerep">
            <!-- <div style=""> -->
            <table class="table table-bordered">
                <thead>
                    <tr>
                        <th colspan="3">
                            <p>Vivek Saptahik</p>
                        </th>
                        <th colspan="5">
                            <p>Advt. Registreation Report</p>
                        </th>
                        <th colspan="2">In Detail</th>
                        <!-- <th colspan="2"></th> -->
                        <!-- <th colspan="3"></th> -->
                        <th colspan="5">Date : {{ today | date:"dd-MM-yyyy" }}</th>
                        <th colspan="3">Page No
                            <!-- <h5 class="page-number"></h5> -->
                        </th>
                    </tr>
                    <tr>
                        <th colspan="3">
                            <div>Date. From : {{ start_date | date:"dd-MM-yyyy"}}</div>
                        </th>
                        <th colspan="2" class="leftAlign">
                            <div>To : {{ end_date | date:"dd-MM-yyyy"}}</div>
                        </th>
                        <th colspan="3" class="leftAlign">
                            <div>Issue/Grantha Category : {{ issue_cat }}</div>
                        </th>
                        <th colspan="2" class="leftAlign">
                            <div>Issue Date : {{ issueDate | date:"dd-MM-yyyy"}}
                            </div>
                        </th>
                        <th colspan="5">Issue Name : {{ issue_name }}</th>
                        <th colspan="3">
                        </th>
                    </tr>
                    <tr>
                        <th>Sr.<br />No</th>
                        <th>Booking<br />Date</th>
                        <th>Booking<br />No</th>
                        <th colspan="2">Advertiser Name</th>
                        <th>Customer<br />Name</th>
                        <th>Representative<br />Name</th>
                        <!-- <th>Mobile No</th> -->
                        <th>Office<br />Rep. Name</th>
                        <th>RO Number<br />Instruction</th>
                        <!-- <th></th> -->
                        <th>Advt. Size</th>
                        <th>Advt.<br />Amt.</th>
                        <th>Net<br /> Amt.</th>
                        <th>GST 5%</th>
                        <th>Total<br />Amt.</th>
                        <th>Received<br />Amt.</th>
                        <th>Receipt
                            No.<br />and
                            Date</th>
                        <th>Pending<br />Amt.</th>
                    </tr>
                </thead>
                <tbody>
                    <ng-container *ngFor="let item of inDetailsRegReport1; let i = index">
                        <tr>
                            <td><span>{{ i + 1 }}</span></td>
                            <td> {{item.booking_date}}</td>
                            <td>
                               {{item.booking_no}}
                            </td>
                            <td colspan="2">
                                {{item.advt_name}}
                            </td>
                            <td>{{ item.cust_name }}</td>
                            <td>
                                {{item.rep_name}}
                            </td>
                            <!-- <td class="right"><span>{{ item['rep_mob_no'] }}</span></td> -->
                            <td>
                                {{ item['off_rep_name'] }}
                            </td>
                            <td>
                                {{ item['ro_no'] }}  
                            </td>
                            <!-- <td></td> -->
                            <td>
                            {{ item['advtsize'] }}
                            </td>
                            <td class="right"><span>{{ item['advt_amt'] }}</span></td>
                            <td class="right"><span>{{ item['amount'] }}</span></td>
                            <td class="right"><span>{{ toInt(item['sgst']) + toInt(item['cgst']) }}</span></td>
                            <td class="right"><span>{{ item['igst'] }}</span></td>
                            <td class="right"><span>{{ item['part_pymt_amt'] }}</span></td>
                            <td>{{ item['rep_rcpt_no'] }}
                                <ng-container *ngIf="item['rcpt_date'] != '0000-00-00'">
                                    <br />{{ item['rcpt_date'] | date:"dd-MM-yyy" }}
                                </ng-container>
                            </td>
                            <td class="right"><span>{{ item['part_pymt_remaning_amt'] }}</span></td>
                        </tr>
                    </ng-container>
                    <tr>
                        <td></td>
                        <!-- <td></td> -->
                        <td colspan="4" class="boldText">
                            <p>Total No of Advt : <b>{{ this.inDetailsRegReportTotal.total_advt }}</b></p>
                        </td>
                        <!-- <td colspan="2"></td> -->
                        <!-- <td></td> -->
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td></td>
                        <td class="right"><span><b>{{ this.inDetailsRegReportTotal.advt_total }}</b></span></td>
                        <td class="right"><span><b>{{ this.inDetailsRegReportTotal.net_amt }}</b></span></td>
                        <td class="right"><span><b>{{ this.inDetailsRegReportTotal.gst }}</b></span></td>
                        <td class="right"><span><b>{{ this.inDetailsRegReportTotal.total_amt }}</b></span></td>
                        <td class="right"><span><b>{{ this.inDetailsRegReportTotal.rcvd_amt }}</b></span></td>
                        <td></td>
                        <td class="right"><span><b>{{ this.inDetailsRegReportTotal.pndg_amt }}</b></span></td>
                    </tr>
                </tbody>
            </table>
            <!-- </div> -->
        </ng-container>
        <!-- <div style="margin: 25px" *ngIf="indetail && !datewise && !officerep">
            <div class="row" style="border: 1px solid black;">
                <div class="col3">Vivek Saptahik</div>
                <div class="col5">Advt. Registreation Report</div>
                <div class="col2">In Detail</div>
                <div class="col2"></div>
                <div class="col3"></div>
                <div class="col3">Date : </div>
            </div>
            <div class="row" style="border-width: 0 1px 0 1px; border-color: black; border-style: solid;">
                <div class="col3">Date. From :</div>
                <div class="col2">To :</div>
                <div class="col3">Issue/Grantha Categary :</div>
                <div class="col2">Issue Date :</div>
                <div class="col5">Issue Name :</div>
                <div class="col3">Page No</div>
            </div>
            <div class="row" style="border: 1px solid black;">
                <div class="col">Issue Sr.
                    No</div>
                <div class="col">Booking Date</div>
                <div class="col">Booking
                    No</div>
                <div class="col">Advertiser Name</div>
                <div class="col">Customer Name</div>
                <div class="col">Representative
                    Name</div>
                <div class="col">Mobile No</div>
                <div class="col">Office
                    Represantative
                    Name</div>
                <div class="col">RO Number</div>
                <div class="col">Instruction</div>
                <div class="col">Advt.Size</div>
                <div class="col">Advt Amount Rs</div>
                <div class="col">Net
                    Amount</div>
                <div class="col">GST 5%</div>
                <div class="col">Total
                    Amount</div>
                <div class="col">Received
                    Amount</div>
                <div class="col">Receipt
                    No. and
                    Date</div>
                <div class="col">Pending
                    Amount</div>
            </div>
            <div class="row" style="border: 1px solid black;"
                *ngFor="let item of filteredinDetailsRegReport; let i = index">
                <div class="col">{{ i + 1 }}</div>
                <div class="col">{{ item['booking_date'] | date:"dd-MM-yyy" }}</div>
                <div class="col">{{ item['booking_no'] }}</div>
                <div class="col">{{ item['advt_name'] }}</div>
                <div class="col">{{ item['cust_name'] }}</div>
                <div class="col">{{ item['rep_name'] }}</div>
                <div class="col">{{ item['rep_mob_no'] }}</div>
                <div class="col">{{ item['off_rep_name'] }}</div>
                <div class="col">{{ item['ro_no'] }}</div>
                <div class="col">{{ item['instruction'] }}</div>
                <div class="col">{{ item['advtsize'] }}</div>
                <div class="col">{{ item['advt_amt'] }}</div>
                <div class="col">{{ item['total_amount'] }}</div>
                <div class="col">{{ toInt(item['sgst']) + toInt(item['cgst']) }}</div>
                <div class="col">{{ item['amount'] }}</div>
                <div class="col">{{ item['part_pymt_amt'] }}</div>
                <div class="col">{{ item['rep_rcpt_no'] }}<br />
                    <span *ngIf="item['rcpt_date'] != '0000-00-00'">
                        {{ item['rcpt_date'] | date:"dd-MM-yyy" }}
                    </span>
                </div>
                <div class="col">{{ item['part_pymt_remaning_amt'] }}</div>
            </div>
            <div class="row" style="border: 1px solid black;">
                <div class="col2">Total
                </div>
                <div class="col2">Total No of Advt : {{ this.inDetailsRegReportTotal.total_advt }}</div>
                <div class="col"></div>
                <div class="col"></div>
                <div class="col"></div>
                <div class="col"></div>
                <div class="col"></div>
                <div class="col"></div>
                <div class="col"></div>
                <div class="col">{{ this.inDetailsRegReportTotal.advt_total }}</div>
                <div class="col">{{ this.inDetailsRegReportTotal.net_amt }}</div>
                <div class="col">{{ this.inDetailsRegReportTotal.gst }}</div>
                <div class="col">{{ this.inDetailsRegReportTotal.total_amt }}</div>
                <div class="col">{{ this.inDetailsRegReportTotal.rcvd_amt }}</div>
                <div class="col"></div>
                <div class="col">{{ this.inDetailsRegReportTotal.pndg_amt }}</div>
            </div>
        </div> -->
        <!-- Indetail and Datewise Report -->
        <!-- <ng-container *ngIf="indetail && datewise">
            <table class="table table-bordered">
                <thead>
                    <tr>
                        <th colspan="3">
                            <p>Vivek Saptahik</p>
                        </th>
                        <th colspan="5">
                            <p>Advt. Registreation Report</p>
                        </th>
                        <th colspan="2">In Detail</th>
                        <th colspan="5">Date : {{ today | date:"dd-MM-yyyy" }}</th>
                        <th colspan="3">Page No
                        </th>
                    </tr>
                </thead>
            </table>
        </ng-container> -->
        <div style="margin: 25px" *ngIf="indetail && datewise">
            <div class="row" style="border: 1px solid black;">
                <div class="col3">Vivek Saptahik</div>
                <div class="col5">Advt. Registreation Report</div>
                <div class="col2">Datewise</div>
                <div class="col2">In Detail</div>
                <div class="col3"></div>
                <div class="col3">Date : </div>
            </div>
            <div class="row" style="border-width: 0 1px 0 1px; border-color: black; border-style: solid;">
                <div class="col3">Date. From :</div>
                <div class="col2">To :</div>
                <div class="col3">Issue/Grantha Categary :</div>
                <div class="col2">Issue Date :</div>
                <div class="col5">Issue Name :</div>
                <div class="col3">Page No</div>
            </div>
            <div class="row" style="border: 1px solid black;">
                <div class="col">Issue Sr.
                    No</div>
                <div class="col">Booking Date</div>
                <div class="col">Booking
                    No</div>
                <div class="col">Advertiser Name</div>
                <div class="col">Customer Name</div>
                <!-- <div class="col">Representative
                    Name</div>
                <div class="col">Mobile No</div>
                <div class="col">Office
                    Represantative
                    Name</div> -->
                <div class="col">RO Number</div>
                <div class="col">Instruction</div>
                <div class="col">Advt.Size</div>
                <div class="col">Advt Amount Rs</div>
                <div class="col">Net
                    Amount</div>
                <div class="col">GST 5%</div>
                <div class="col">Total
                    Amount</div>
                <div class="col">Received
                    Amount</div>
                <div class="col">Receipt
                    No. and
                    Date</div>
                <div class="col">Pending
                    Amount</div>
            </div>
            <div *ngFor="let data of inDetailsRegReportsubTotalData; let s = index;">
                <div *ngFor="let item of data; let i = index; let last = last">
                    <div class="row" style="border: 1px solid black;" *ngIf="i == 0">
                        <div class="col3" style="text-align: center;">Date</div>
                        <div class="col1">{{ item['booking_date'] | date:"dd-MM-yyy" }}</div>
                        <div class="col1"></div>
                        <div class="col1"></div>
                        <div class="col1"></div>
                        <div class="col1"></div>
                        <div class="col1"></div>
                        <div class="col1"></div>
                        <div class="col1"></div>
                        <div class="col1"></div>
                        <div class="col1"></div>
                        <div class="col1"></div>
                        <div class="col1"></div>
                        <div class="col1"></div>
                        <div class="col1"></div>
                        <div class="col1"></div>
                    </div>
                    <div *ngIf="!last" class="row" style="border: 1px solid black;">
                        <div class="col">{{ i + 1 }}</div>
                        <div class="col">{{ item['booking_date'] | date:"dd-MM-yyy" }}</div>
                        <div class="col">{{ item['booking_no'] }}</div>
                        <div class="col">{{ item['advt_name'] }}</div>
                        <div class="col">{{ item['cust_name'] }}</div>
                        <!-- <div class="col">{{ item['rep_name'] }}</div>
                        <div class="col">{{ item['rep_mob_no'] }}</div>
                        <div class="col">{{ item['off_rep_name'] }}</div> -->
                        <div class="col">{{ item['ro_no'] }}</div>
                        <div class="col">{{ item['instruction'] }}</div>
                        <div class="col">{{ item['advtsize'] }}</div>
                        <div class="col">{{ item['advt_amt'] }}</div>
                        <div class="col">{{ item['total_amount'] }}</div>
                        <div class="col">{{ toInt(item['sgst']) + toInt(item['cgst']) }}</div>
                        <div class="col">{{ item['amount'] }}</div>
                        <div class="col">{{ item['part_pymt_amt'] }}</div>
                        <div class="col">{{ item['rep_rcpt_no'] }}<br />
                            <span *ngIf="item['rcpt_date'] != '0000-00-00'">
                                {{ item['rcpt_date'] | date:"dd-MM-yyy" }}
                            </span>
                        </div>
                        <div class="col">{{ item['part_pymt_remaning_amt'] }}</div>
                    </div>
                    <div *ngIf="last" class="row" style="border: 1px solid black;">
                        <div class="col2">Total
                        </div>
                        <div class="col2">Total No of Advt : {{ item["total_advt"] }}</div>
                        <div class="col"></div>
                        <div class="col"></div>
                        <div class="col"></div>
                        <div class="col"></div>
                        <div class="col"></div>
                        <div class="col"></div>
                        <div class="col"></div>
                        <div class="col">{{ item["advt_total"] }}</div>
                        <div class="col">{{ item["net_amt"] }}</div>
                        <div class="col">{{ item["gst"] }}</div>
                        <div class="col">{{ item["total_amt"] }}</div>
                        <div class="col">{{ item["rcvd_amt"] }}</div>
                        <div class="col"></div>
                        <div class="col">{{ item["pndg_amt"] }}</div>
                    </div>
                </div>
            </div>
            <!-- <div class="row" style="border: 1px solid black;" *ngFor="let item of inDetailsRegReport; let i = index">
                <div class="col">{{ i + 1 }}</div>
                <div class="col">{{ item['booking_date'] | date:"dd-MM-yyy" }}</div>
                <div class="col">{{ item['booking_no'] }}</div>
                <div class="col">{{ item['advt_name'] }}</div>
                <div class="col">{{ item['cust_name'] }}</div>
                <div class="col">{{ item['rep_name'] }}</div>
                <div class="col">{{ item['rep_mob_no'] }}</div>
                <div class="col">{{ item['off_rep_name'] }}</div>
                <div class="col">{{ item['ro_no'] }}</div>
                <div class="col">{{ item['instruction'] }}</div>
                <div class="col">{{ item['advtsize'] }}</div>
                <div class="col">{{ item['advt_amt'] }}</div>
                <div class="col">{{ item['total_amount'] }}</div>
                <div class="col">{{ toInt(item['sgst']) + toInt(item['cgst']) }}</div>
                <div class="col">{{ item['amount'] }}</div>
                <div class="col">{{ item['part_pymt_amt'] }}</div>
                <div class="col">{{ item['rep_rcpt_no'] }}<br />
                    {{ item['rcpt_date'] | date:"dd-MM-yyy" }}</div>
                <div class="col">{{ item['part_pymt_remaning_amt'] }}</div>
            </div> -->
            <div class="row" style="border: 1px solid black;">
                <div class="col2">Total
                </div>
                <div class="col2">Total No of Advt : {{ this.inDetailsRegReportTotal.total_advt }}</div>
                <div class="col"></div>
                <div class="col"></div>
                <div class="col"></div>
                <div class="col"></div>
                <div class="col"></div>
                <div class="col"></div>
                <div class="col"></div>
                <div class="col">{{ this.inDetailsRegReportTotal.advt_total }}</div>
                <div class="col">{{ this.inDetailsRegReportTotal.net_amt }}</div>
                <div class="col">{{ this.inDetailsRegReportTotal.gst }}</div>
                <div class="col">{{ this.inDetailsRegReportTotal.total_amt }}</div>
                <div class="col">{{ this.inDetailsRegReportTotal.rcvd_amt }}</div>
                <div class="col"></div>
                <div class="col">{{ this.inDetailsRegReportTotal.pndg_amt }}</div>
            </div>
        </div>
        <!-- Datewise and very briefly Report -->
        <div style="margin: 25px" *ngIf="datewise && verybrfly">
            <div class="row" style="border: 1px solid black;">
                <div class="cols3">Vivek Saptahik</div>
                <div class="cols4">Advt. Registreation Report</div>
                <div class="cols">Datewise</div>
                <div class="cols">Very Brief</div>
            </div>
            <div class="row" style="border-width: 0 1px 0 1px; border-color: black; border-style: solid;">
                <div class="cols3">Date. From :</div>
                <div class="cols">To :</div>
                <div class="cols3">Issue/Grantha Categary :</div>
                <div class="cols">Issue Date :</div>
                <div class="cols">Issue Name :</div>
            </div>
            <div class="row" style="border: 1px solid black;">
                <div class="cols"></div>
                <div class="cols">Booking Date</div>
                <div class="cols">Total No of Advt</div>
                <div class="cols">Advt Amount Rs</div>
                <div class="cols">Net
                    Amount</div>
                <div class="cols">GST 5%</div>
                <div class="cols">Total
                    Amount</div>
                <div class="cols">Received
                    Amount</div>
                <div class="cols">Pending
                    Amount</div>
            </div>
            <div class="row" style="border: 1px solid black;"
                *ngFor="let item of inDetailsRegReportsubTotalData; let s = index;">
                <div class="cols">
                </div>
                <div class="cols">{{ item['date'] | date:"dd-MM-yyy" }}</div>
                <div class="cols">{{ item["total_advt"] }}</div>
                <div class="cols">{{ item["advt_total"] }}</div>
                <div class="cols">{{ item["net_amt"] }}</div>
                <div class="cols">{{ item["gst"] }}</div>
                <div class="cols">{{ item["total_amt"] }}</div>
                <div class="cols">{{ item["rcvd_amt"] }}</div>
                <div class="cols">{{ item["pndg_amt"] }}</div>
            </div>
            <div class="row" style="border: 1px solid black;">
                <div class="cols height"></div>
                <div class="cols height"></div>
                <div class="cols height"></div>
                <div class="cols height"></div>
                <div class="cols height"></div>
                <div class="cols height"></div>
                <div class="cols height"></div>
                <div class="cols height"></div>
                <div class="cols height"></div>
            </div>
            <div class="row" style="border: 1px solid black;">
                <div class="cols">Total
                </div>
                <div class="cols2">Total No of Advt : {{ this.inDetailsRegReportTotal.total_advt }}</div>
                <div class="cols">{{ this.inDetailsRegReportTotal.advt_total }}</div>
                <div class="cols">{{ this.inDetailsRegReportTotal.net_amt }}</div>
                <div class="cols">{{ this.inDetailsRegReportTotal.gst }}</div>
                <div class="cols">{{ this.inDetailsRegReportTotal.total_amt }}</div>
                <div class="cols">{{ this.inDetailsRegReportTotal.rcvd_amt }}</div>
                <div class="cols">{{ this.inDetailsRegReportTotal.pndg_amt }}</div>
            </div>
        </div>
        <!-- Indetail and office Represantative Report -->
        <div style="margin: 25px" *ngIf="indetail && officerep && !datewise">
            <div class="row" style="border: 1px solid black;">
                <div class="col3">Vivek Saptahik</div>
                <div class="col3">Advt. Registreation Report</div>
                <div class="col2"></div>
                <div class="col4">Office Representative</div>
                <div class="col2">In Detail</div>
                <div class="col4">Date : </div>
            </div>
            <div class="row" style="border-width: 0 1px 0 1px; border-color: black; border-style: solid;">
                <div class="col3">Date. From :</div>
                <div class="col2">To :</div>
                <div class="col3">Issue/Grantha Categary :</div>
                <div class="col2">Issue Date :</div>
                <div class="col5">Issue Name :</div>
                <div class="col3">Page No</div>
            </div>
            <div class="row" style="border: 1px solid black;">
                <div class="col">Issue Sr.
                    No</div>
                <div class="col">Booking Date</div>
                <div class="col">Booking
                    No</div>
                <div class="col">Advertiser Name</div>
                <div class="col">Customer Name</div>
                <div class="col">Representative
                    Name</div>
                <div class="col">Mobile No</div>
                <div class="col">Office
                    Represantative
                    Name</div>
                <div class="col">RO Number</div>
                <div class="col">Instruction</div>
                <div class="col">Advt.Size</div>
                <div class="col">Advt Amount Rs</div>
                <div class="col">Net
                    Amount</div>
                <div class="col">GST 5%</div>
                <div class="col">Total
                    Amount</div>
                <div class="col">Received
                    Amount</div>
                <div class="col">Receipt
                    No. and
                    Date</div>
                <div class="col">Pending
                    Amount</div>
            </div>
            <div *ngFor="let data of inDetailsRegReportsubTotalData; let s = index;">
                <div *ngFor="let item of data; let i = index; let last = last">
                    <!-- <div class="row" style="border: 1px solid black;" *ngIf="i == 0">
                        <div class="col3" style="text-align: center;">Office Represantativewise</div>
                        <div class="col1">{{ item['off_rep_name'] }}</div>
                        <div class="col1"></div>
                        <div class="col1"></div>
                        <div class="col1"></div>
                        <div class="col1"></div>
                        <div class="col1"></div>
                        <div class="col1"></div>
                        <div class="col1"></div>
                        <div class="col1"></div>
                        <div class="col1"></div>
                        <div class="col1"></div>
                        <div class="col1"></div>
                        <div class="col1"></div>
                        <div class="col1"></div>
                        <div class="col1"></div>
                    </div>
                    <div class="row" style="border: 1px solid black;" *ngIf="i == 0">
                        <div class="col3" style="text-align: center;">Represantativewise</div>
                        <div class="col1">{{ item['rep_name'] }}</div>
                        <div class="col1">{{ item['rep_mob_no'] }}</div>
                        <div class="col1"></div>
                        <div class="col1"></div>
                        <div class="col1"></div>
                        <div class="col1"></div>
                        <div class="col1"></div>
                        <div class="col1"></div>
                        <div class="col1"></div>
                        <div class="col1"></div>
                        <div class="col1"></div>
                        <div class="col1"></div>
                        <div class="col1"></div>
                        <div class="col1"></div>
                        <div class="col1"></div>
                    </div> -->
                    <div *ngIf="!last" class="row" style="border: 1px solid black;">
                        <div class="col">{{ i + 1 }}</div>
                        <div class="col">{{ item['booking_date'] | date:"dd-MM-yyy" }}</div>
                        <div class="col">{{ item['booking_no'] }}</div>
                        <div class="col">{{ item['advt_name'] }}</div>
                        <div class="col">{{ item['cust_name'] }}</div>
                        <div class="col"></div>
                        <div class="col"></div>
                        <div class="col"></div>
                        <div class="col">{{ item['ro_no'] }}</div>
                        <div class="col">{{ item['instruction'] }}</div>
                        <div class="col">{{ item['advtsize'] }}</div>
                        <div class="col">{{ item['advt_amt'] }}</div>
                        <div class="col">{{ item['total_amount'] }}</div>
                        <div class="col">{{ toInt(item['sgst']) + toInt(item['cgst']) }}</div>
                        <div class="col">{{ item['amount'] }}</div>
                        <div class="col">{{ item['part_pymt_amt'] }}</div>
                        <div class="col">{{ item['rep_rcpt_no'] }}<br />
                            <span *ngIf="item['rcpt_date'] != '0000-00-00'">
                                {{ item['rcpt_date'] | date:"dd-MM-yyy" }}
                            </span>
                        </div>
                        <div class="col">{{ item['part_pymt_remaning_amt'] }}</div>
                    </div>
                    <!-- <div *ngIf="last" class="row" style="border: 1px solid black;">
                        <div class="col2">Total
                        </div>
                        <div class="col2">Total No of Advt : {{ item["total_advt"] }}</div>
                        <div class="col"></div>
                        <div class="col"></div>
                        <div class="col"></div>
                        <div class="col"></div>
                        <div class="col"></div>
                        <div class="col"></div>
                        <div class="col"></div>
                        <div class="col">{{ item["advt_total"] }}</div>
                        <div class="col">{{ item["net_amt"] }}</div>
                        <div class="col">{{ item["gst"] }}</div>
                        <div class="col">{{ item["total_amt"] }}</div>
                        <div class="col">{{ item["rcvd_amt"] }}</div>
                        <div class="col"></div>
                        <div class="col">{{ item["pndg_amt"] }}</div>
                    </div> -->
                </div>
            </div>
            <div class="row" style="border: 1px solid black;">
                <div class="col2">Total
                </div>
                <div class="col2">Total No of Advt : {{ this.inDetailsOffRegReportTotal.total_advt }}</div>
                <div class="col"></div>
                <div class="col"></div>
                <div class="col"></div>
                <div class="col"></div>
                <div class="col"></div>
                <div class="col"></div>
                <div class="col"></div>
                <div class="col">{{ this.inDetailsOffRegReportTotal.advt_total }}</div>
                <div class="col">{{ this.inDetailsOffRegReportTotal.net_amt }}</div>
                <div class="col">{{ this.inDetailsOffRegReportTotal.gst }}</div>
                <div class="col">{{ this.inDetailsOffRegReportTotal.total_amt }}</div>
                <div class="col">{{ this.inDetailsOffRegReportTotal.rcvd_amt }}</div>
                <div class="col"></div>
                <div class="col">{{ this.inDetailsOffRegReportTotal.pndg_amt }}</div>
            </div>
        </div>
        <!-- Office Represantative and very briefly Report -->
        <div style="margin: 25px" *ngIf="verybrfly && officerep">
            <div class="row" style="border: 1px solid black;">
                <div class="cols3" style="text-align: center;">Vivek Saptahik</div>
                <div class="cols3">Advt. Registreation Report</div>
                <div class="cols2" style="text-align: center;">Office Representative</div>
                <div class="cols">Very Brief</div>
            </div>
            <div class="row" style="border-width: 0 1px 0 1px; border-color: black; border-style: solid;">
                <div class="cols3">Date. From :</div>
                <div class="cols">To :</div>
                <div class="cols3">Issue/Grantha Categary :</div>
                <div class="cols">Issue Date :</div>
                <div class="cols">Issue Name :</div>
            </div>
            <div class="row" style="border: 1px solid black;">
                <div class="cols2">Represantative Name</div>
                <div class="cols">Total No
                    of Advt</div>
                <div class="cols">Advt Amount Rs</div>
                <div class="cols">Net
                    Amount</div>
                <div class="cols">GST 5%</div>
                <div class="cols">Total
                    Amount</div>
                <div class="cols">Received
                    Amount</div>
                <div class="cols">Pending
                    Amount</div>
            </div>
            <div *ngFor="let item of inDetailsRegReportsubTotalData; let i = index; let last = last">
                <div class="row" style="border: 1px solid black;" *ngIf="i == 0">
                    <div class="cols3" style="text-align: center;">Office Represantativewise</div>
                    <div class="cols">{{ item['advt_amt'] }}</div>
                    <div class="cols"></div>
                    <div class="cols"></div>
                    <div class="cols"></div>
                    <div class="cols"></div>
                    <div class="cols"></div>
                </div>
                <div class="row" style="border: 1px solid black;">
                    <div class="cols2">{{ item['rep_name'] }}</div>
                    <div class="cols">{{ item["advt_amt"] }}</div>
                    <div class="cols">{{ item["advt_total"] }}</div>
                    <div class="cols">{{ item["net_amt"] }}</div>
                    <div class="cols">{{ item["gst"] }}</div>
                    <div class="cols">{{ item["total_amt"] }}</div>
                    <div class="cols">{{ item["rcvd_amt"] }}</div>
                    <div class="cols">{{ item["pndg_amt"] }}</div>
                </div>
            </div>
            <div class="row" style="border: 1px solid black;">
                <div class="cols2 height"></div>
                <div class="cols height"></div>
                <div class="cols height"></div>
                <div class="cols height"></div>
                <div class="cols height"></div>
                <div class="cols height"></div>
                <div class="cols height"></div>
                <div class="cols height"></div>
            </div>
            <div class="row" style="border: 1px solid black;">
                <div class="cols">Total
                </div>
                <div class="cols2">Total No of Advt : {{ this.inDetailsOffRegReportTotal.total_advt }}</div>
                <div class="cols">{{ this.inDetailsOffRegReportTotal.net_amt }}</div>
                <div class="cols">{{ this.inDetailsOffRegReportTotal.net_amt }}</div>
                <div class="cols">{{ this.inDetailsOffRegReportTotal.gst }}</div>
                <div class="cols">{{ this.inDetailsOffRegReportTotal.total_amt }}</div>
                <div class="cols">{{ this.inDetailsOffRegReportTotal.rcvd_amt }}</div>
                <div class="cols">{{ this.inDetailsOffRegReportTotal.pndg_amt }}</div>
            </div>
        </div>
    </div>

    <div class="container" >
        <div *ngIf="type=='InDetail'">
            <div id="print-section" style="margin-top: 50px;">
                <table class="table table-bordered">
                    <thead>
                        <tr>
                            <th colspan="3" style="border:none" class="br-bottom br-right br-top">हिंदुस्तान प्रकाशन संस्था - विवेक साप्ताहिक  </th>
                            <th colspan="3" style="border:none" class="br-bottom br-right br-top">अंकाचे नाव : &nbsp; {{newissuedatepp}}  </th>

                            <th colspan="3" style="border:none" class="br-bottom br-right br-top">Advertieser Register Report - In Details</th>
                            <th colspan="2"  style="border:none" class="br-bottom br-right br-top">अंकाचे कॅटेगरी : &nbsp; {{issue_cat}}</th> 
                            <!-- <th colspan="2">Advt. Outstanding Report</th> -->
                           
                            <th colspan="4" style="border:none" class="br-bottom br-right br-top"> Date : From {{ start_date | date: 'dd-MM-yyyy' }} - To {{ end_date | date: 'dd-MM-yyyy' }}</th>                   
                             <th colspan="3" style="border:none" class="br-bottom br-right br-top">दिनांक :{{today1}}</th>
                            <th colspan="3" style="border:none" class="br-bottom br-top">पृष्ठ क्र :1</th>
                            
                        </tr>
                      
                        <tr>
                            <th colspan="1" style="border:none" class="br-bottom br-right">Sr</th>
                            <!-- <th colspan="1" style="border:none" class="br-bottom br-right">Issue name</th> -->
                            <th colspan="1" style="border:none" class="br-bottom br-right">Booking Date</th>
                            <th colspan="1" style="border:none" class="br-bottom br-right">Booking No</th>
                            <th colspan="1" style="border:none" class="br-bottom br-right">Advertiser Name</th>
                            <th colspan="1" style="border:none" class="br-bottom br-right">Customer Name</th>
                            <th colspan="1" style="border:none" class="br-bottom br-right">Represntive Name</th>
                            <th colspan="1" style="border:none" class="br-bottom br-right">Mobile Number</th>
                            <th colspan="1" style="border:none" class="br-bottom br-right">Office Rep Name</th>
                            <th colspan="1" style="border:none"  class="br-bottom br-right">RO Number</th>
                            <th colspan="1" style="border:none" class="br-bottom br-right">Instruction</th>
                            <th colspan="1" style="border:none" class="br-bottom br-right">Advt. Size</th>
                            
                            <th colspan="1" style="border:none" class="br-bottom br-right" style="text-align: end;">Advt Amt</th>
                            <th colspan="1" style="border:none" class="br-bottom br-right" style="text-align: end;">Disc Amt</th>
                            <th colspan="1" style="border:none" class="br-bottom br-right" style="text-align: end;">Net Amt</th>
                            <th colspan="1" style="border:none" class="br-bottom br-right" style="text-align: end;">Before GST Amt</th>
                            <th colspan="1" style="border:none" class="br-bottom br-right" style="text-align: end;">GST</th>
                            <th colspan="1" style="border:none"  class="br-bottom br-right" style="text-align: end;">Total Amt</th>
                            <th colspan="1" style="border:none" class="br-bottom br-right" style="text-align: end;">Receipt Number & Date</th>
                            <th colspan="1" style="border:none" class="br-bottom br-right" style="text-align: end;">जमा बाकी</th>
                            <th colspan="1" style="border:none" class="br-bottom" style="text-align: end;">येणे बाकी</th>
                        </tr>   
                    </thead>
                    <!-- *ngFor="let data of OutstandingInDetailFormBeans; let i = index" -->
                    <tbody *ngFor="let item of inDetailsRegReport1; let i = index">
                        <tr>
                      <td colspan="1" style="border:none; font-weight: bold;" class=" br-right">{{item.advtsizeno}}</td>
                      <!-- <td colspan="1" style="border:none" class=" br-right">{{item.issuename}}</td> -->
                      <td colspan="1" style="border:none" class=" br-right">{{item.booking_date}}</td>
                      <td colspan="1" style="border:none" class=" br-right">{{item.booking_no}}</td>
                      <td colspan="1" style="border:none" class=" br-right">{{item.advt_name}}</td>
                      <td colspan="1" style="border:none" class=" br-right">{{item.cust_name}}</td>
                      <td colspan="1" style="border:none" class=" br-right">{{item.rep_name}}</td>
                      <td colspan="1" style="border:none" class=" br-right">{{item.rep_mob_no}}</td>
                      <td colspan="1" style="border:none" class=" br-right">{{item.off_rep_name}}</td>
                      <td colspan="1" style="border:none" class=" br-right">{{item.ro_no}}</td>
                      <td colspan="1" style="border:none" class=" br-right">{{item.instruction}}</td>
                      <td colspan="1" style="border:none" class=" br-right">{{item.advtsize}}</td>
                      <td colspan="1" style="border:none; text-align: end;" class=" br-right">{{item.advt_amt}}</td>
                      <td colspan="1" style="border:none; text-align: end;" class=" br-right">{{item.disc_amt}}</td>
                      <td colspan="1" style="border:none; text-align: end;" class=" br-right">{{item.amount}}</td>
                      <td colspan="1" style="border:none; text-align: end;" class=" br-right">{{item.beforegst_amt}}</td>
                      <td colspan="1" style="border:none; text-align: end;" class=" br-right">{{item.totalgst}} </td>
                      <td colspan="1" style="border:none; text-align: end;" class=" br-right">{{item.total_amount}}</td>
                      <td colspan="1" style="border:none" class=" br-right">{{item.rep_rcpt_no}} {{item.rcpt_date}}</td>
                      <td colspan="1" style="border:none; text-align: end;" class=" br-right">{{item.pymt_rcv_amt}}</td>
                      <!-- <td colspan="1" style="border:none; text-align: end;" class=" br-right">{{item.amount - item.part_pymt_amt}}</td>      -->
                      <td colspan="1" style="border:none; text-align: end;" class=" br-right">{{item.remaingamt}}</td>     

                    </tr>
                    </tbody>
        
                    <tfoot>
                        <!-- 18 -->
                        <tr>
                            <td style="border:none" class="br-bottom br-right br-top" style=" font-weight: bold;">{{inDetailsRegReport1.length}}</td>
                            <td style="border:none" class="br-bottom br-right br-top" style="text-align: center; font-weight: bold;" colspan="10">एकूण</td>
                            <td style="border:none" class="br-bottom br-right br-top" style="text-align: end; font-weight: bold;" >{{advtamt}}</td>
                            <td style="border:none" class="br-bottom br-right br-top" style="text-align: end; font-weight: bold;" >{{totaldisc_total}}</td>
                            <td style="border:none" class="br-bottom br-right br-top" style="text-align: end; font-weight: bold;" >{{totaladvtamt}}</td>
                            <td style="border:none" class="br-bottom br-right br-top" style="text-align: end; font-weight: bold;" >{{totalbeforegst_amt}}</td>
                            <td style="border:none" class="br-bottom br-right br-top" style="text-align: end; font-weight: bold;">{{totalgst}}</td>
                            <td style="border:none" class="br-bottom br-right br-top" style="text-align: end; font-weight: bold;">{{netadvtamt}}</td>
                            <td style="border:none" class="br-bottom br-right br-top" style="text-align: end; font-weight: bold;"></td>
                            <td style="border:none" class="br-bottom br-right br-top" style="text-align: end; font-weight: bold;">{{jamatotal}}</td>
                            <td style="border:none" class="br-bottom br-right br-top" style="text-align: end; font-weight: bold;">{{netadvtamt - jamatotal}}</td>
                        </tr>
                    </tfoot>  
                </table>
               
            </div>
        </div>
        <div *ngIf="type=='Briefly'">
            <div id="print-section" style="margin-top: 50px;">
                <table class="table table-bordered">
                    <thead>
                        <tr>
                            <th colspan="2" style="border:none" class="br-bottom br-right br-top">हिंदुस्तान प्रकाशन संस्था - विवेक साप्ताहिक  </th>
                            <th colspan="1" style="border:none" class="br-bottom br-right br-top">अंकाचे नाव : &nbsp; {{newissuedatepp}}  </th>

                            <th colspan="2" style="border:none" class="br-bottom br-right br-top">Briefly</th>
                            <th colspan="2"  style="border:none" class="br-bottom br-right br-top">अंकाचे कॅटेगरी : &nbsp;{{issue_cat}}</th> 
                            <!-- <th colspan="2">Advt. Outstanding Report</th> -->
                           
                            <th colspan="2" style="border:none" class="br-bottom br-right br-top"> Date : From {{ start_date | date: 'dd-MM-yyyy' }} - To {{ end_date | date: 'dd-MM-yyyy' }}</th>                   
                             <th colspan="1" style="border:none" class="br-bottom br-right br-top">दिनांक :{{today1}}</th>
                            <th colspan="1" style="border:none" class="br-bottom br-top">पृष्ठ क्र :1</th>
                            
                        </tr>
                        <ng-container >
                            <tr>

                            </tr>
                            <tr>
                                <th colspan="1" style="border:none" class="br-bottom br-right">Sr</th>
                                <th colspan="1" style="border:none" class="br-bottom br-right">Advt Name</th>
                                <th colspan="1" style="border:none;" class="br-bottom br-right">Client</th>
                                <th colspan="1" style="border:none" class="br-bottom br-right">Representative</th>
                                <th colspan="1" style="border:none" class="br-bottom br-right">Advt. Size</th>
                                <th colspan="1" style="border:none" class="br-bottom br-right">Advt Amt</th>                                
                                <th colspan="1" style="border:none" class="br-bottom br-right" >Net Amt</th>
                                <th colspan="1" style="border:none" class="br-bottom br-right" >GST Amt</th>
                                <th colspan="1" style="border:none"  class="br-bottom br-right" >Total Amt</th>
                                <th colspan="1" style="border:none"  class="br-bottom br-right" >Receive Amt</th>
                                <th colspan="1" style="border:none"  class="br-bottom br-right" >Pending Amt</th>


                            </tr> 
                        </ng-container>
                          
                    </thead>
                    <!-- *ngFor="let data of OutstandingInDetailFormBeans; let i = index" -->
                    <tbody *ngFor="let item of inDetailsbriflydata; let i = index">
                    <tr>
                      <td colspan="1" style="border:none; font-weight: bold;" class=" br-right">{{item.advtsizeno}}</td>
                      <!-- <td colspan="1" style="border:none" class=" br-right">{{item.issuename}}</td> -->
                      <td colspan="1" style="border:none" class=" br-right">{{item.advt_name}}</td>
                      <td colspan="1" style="border:none" class=" br-right">{{item.client}}</td>
                      <td colspan="1" style="border:none" class=" br-right">{{item.rep_name}}</td>
                      <td colspan="1" style="border:none" class=" br-right">{{item.advtsize}}</td>

                      <td colspan="1" style="border:none; text-align: end;" class=" br-right">{{item.advt_amt}}</td>
                      <td colspan="1" style="border:none; text-align: end;" class=" br-right">{{item.total_amount}}</td>
                      <td colspan="1" style="border:none; text-align: end;" class=" br-right">{{item.gst}}</td>
                      <td colspan="1" style="border:none; text-align: end;" class=" br-right">{{item.amount}}</td>
                      <td colspan="1" style="border:none; text-align: end;" class=" br-right">{{item.pymt_rcv_amt}}</td>
                      <td colspan="1" style="border:none; text-align: end;" class=" br-right">{{item.remaingamt}}</td>  

                    </tr>
                    </tbody>
        
                    <tfoot>
                        <!-- 18 -->
                        <tr>
                            <td style="border:none" class="br-bottom br-right br-top" style=" font-weight: bold;">{{inDetailsbriflydata.length}}</td>
                            <td style="border:none" class="br-bottom br-right br-top" style="text-align: center; font-weight: bold;" colspan="4">एकूण</td>
                            <td style="border:none" class="br-bottom br-right br-top" style="text-align: end; font-weight: bold;" >{{advtamtb}}</td>
                            <td style="border:none" class="br-bottom br-right br-top" style="text-align: end; font-weight: bold;" >{{netadvtamtb}}</td>
                            <td style="border:none" class="br-bottom br-right br-top" style="text-align: end; font-weight: bold;">{{totalgst}}</td>
                            <td style="border:none" class="br-bottom br-right br-top" style="text-align: end; font-weight: bold;">{{totaladvtamt}}</td>
                            <td style="border:none" class="br-bottom br-right br-top" style="text-align: end; font-weight: bold;">{{jamatotalb}}</td>
                            <td style="border:none" class="br-bottom br-right br-top" style="text-align: end; font-weight: bold;">{{totaladvtamt - jamatotalb}}</td>
                        </tr>
                    </tfoot>  
                </table>
               
            </div>
        </div>
        <div *ngIf="type=='Very Briefly'">
            <div id="print-section" style="margin-top: 50px;">
                <table class="table table-bordered">
                    <thead>
                        <tr>
                            <th colspan="2" style="border:none" class="br-bottom br-right br-top">हिंदुस्तान प्रकाशन संस्था - विवेक साप्ताहिक  </th>
                            <th colspan="1" style="border:none" class="br-bottom br-right br-top">अंकाचे नाव : &nbsp; {{newissuedatepp}}  </th>

                            <th colspan="1" style="border:none" class="br-bottom br-right br-top">Very Briefly</th>
                            <th colspan="1"  style="border:none" class="br-bottom br-right br-top">अंकाचे कॅटेगरी : &nbsp; {{issue_cat}}</th> 
                            <!-- <th colspan="2">Advt. Outstanding Report</th> -->
                           
                            <th colspan="3" style="border:none" class="br-bottom br-right br-top"> Date : From {{ start_date | date: 'dd-MM-yyyy' }} - To {{ end_date | date: 'dd-MM-yyyy' }}</th>                   
                             <th colspan="1" style="border:none" class="br-bottom br-right br-top">दिनांक :{{today1}}</th>
                            <th colspan="1" style="border:none" class="br-bottom br-top">पृष्ठ क्र :1</th>
                            
                        </tr>
                        <ng-container >
                            <tr>

                            </tr>
                            <tr>
                                <th colspan="1" style="border:none" class="br-bottom br-right">Sr.No</th>
                                <th colspan="1" style="border:none" class="br-bottom br-right">Register No</th>
                                <th colspan="1" style="border:none" class="br-bottom br-right">Advt Name</th>
                                <th colspan="1" style="border:none;" class="br-bottom br-right">Client</th>
                                <th colspan="1" style="border:none" class="br-bottom br-right">Advt Size</th>
                                <th colspan="1" style="border:none" class="br-bottom br-right">Amount</th>                                
                                <th colspan="1" style="border:none" class="br-bottom br-right" >Net Amt</th>
                                <th colspan="1" style="border:none"  class="br-bottom br-right" >GST Amt</th>
                                <th colspan="1" style="border:none" class="br-bottom br-right" >Total Amt</th>
                                <th colspan="1" style="border:none"  class="br-bottom br-right" >Received Amt</th>

                            </tr> 
                        </ng-container>
                          
                    </thead>
                    <!-- *ngFor="let data of OutstandingInDetailFormBeans; let i = index" -->
                    <tbody >
                        <ng-container *ngFor="let item1 of inDetailsoff_repdata; let i = index">
                            <tr>
                                <td colspan="6" class="br-bottom br-right br-top" style="font-weight: bold">Represantative:-{{item1.rep_name}}</td>
                                <td colspan="4" class="br-bottom br-right  br-top"></td>

                            </tr>                     
                            <ng-container *ngFor="let item of item1.advt_data; let i = index">
                                <tr>
                                <td colspan="1" style="border:none" class=" br-right">{{i+1}}</td>
                                <td colspan="1" style="border:none;;" class=" br-right">{{item.booking_no}}</td>
                                <!-- <td colspan="1" style="border:none" class=" br-right">{{item.issuename}}</td> -->
                                <td colspan="1" style="border:none" class=" br-right">{{item.advt_name}}</td>
                                <td colspan="1" style="border:none" class=" br-right"></td>
                                <td colspan="1" style="border:none; text-align: end;" class=" br-right">{{item.advtsize}}</td>
                                <td colspan="1" style="border:none; text-align: end;" class=" br-right">{{item.total_amount}}</td>
                                <td colspan="1" style="border:none; text-align: end;" class=" br-right">{{item.total_amount}}</td>
                                <td colspan="1" style="border:none; text-align: end;" class=" br-right">{{item.gst}}</td>
                                <td colspan="1" style="border:none; text-align: end;" class=" br-right">{{item.amount}}</td>
                                <td colspan="1" style="border:none; text-align: end;" class=" br-right">{{item.remaingamt}}</td>  
                            </tr>
                            </ng-container>
                        </ng-container>
                    </tbody>
        
                    <tfoot>
                        <!-- 18 -->
                        <tr>
                            <td style="border:none" class="br-bottom br-right br-top" style=" font-weight: bold;"></td>
                            <td style="border:none" class="br-bottom br-right br-top" style="text-align: center; font-weight: bold;" colspan="4">एकूण</td>
                            <td style="border:none" class="br-bottom br-right br-top" style="text-align: end; font-weight: bold;" >{{netadvtamtb}}</td>
                            <td style="border:none" class="br-bottom br-right br-top" style="text-align: end; font-weight: bold;" >{{netadvtamtb}}</td>
                            <td style="border:none" class="br-bottom br-right br-top" style="text-align: end; font-weight: bold;">{{remaingtotalb}}</td>
                            <td style="border:none" class="br-bottom br-right br-top" style="text-align: end; font-weight: bold;">{{totalgst}}</td>
                            <td style="border:none" class="br-bottom br-right br-top" style="text-align: end; font-weight: bold;">{{jamatotalb}}</td>
                            <!-- <td style="border:none" class="br-bottom br-right br-top" style="text-align: end; font-weight: bold;">{{totalgst}}</td> -->
                        </tr>
                    </tfoot>  
                </table>
               
            </div>
        </div>
        <div *ngIf="type=='Datewise'">
            <div id="print-section" style="margin-top: 50px;">
                <table class="table table-bordered">
                    <thead>
                        <tr>
                            <th colspan="2" style="border:none" class="br-bottom br-right br-top">हिंदुस्तान प्रकाशन संस्था - विवेक साप्ताहिक  </th>
                            <th colspan="1" style="border:none" class="br-bottom br-right br-top">अंकाचे नाव : &nbsp; {{newissuedatepp}}  </th>

                            <th colspan="1" style="border:none" class="br-bottom br-right br-top">Briefly</th>
                            <th colspan="1"  style="border:none" class="br-bottom br-right br-top">अंकाचे कॅटेगरी : &nbsp;{{issue_cat}}</th> 
                            <!-- <th colspan="2">Advt. Outstanding Report</th> -->
                           
                            <th colspan="2" style="border:none" class="br-bottom br-right br-top"> Date : From {{ start_date | date: 'dd-MM-yyyy' }} - To {{ end_date | date: 'dd-MM-yyyy' }}</th>                   
                             <th colspan="1" style="border:none" class="br-bottom br-right br-top">दिनांक :{{today1}}</th>
                            <th colspan="1" style="border:none" class="br-bottom br-top">पृष्ठ क्र :1</th>
                            
                        </tr>
                        <ng-container >
                            <tr>

                            </tr>
                            <tr>
                                <th colspan="1" style="border:none" class="br-bottom br-right">Sr</th>
                                <th colspan="1" style="border:none" class="br-bottom br-right">Registration Date</th>
                                <th colspan="1" style="border:none;" class="br-bottom br-right">Sankhya</th>
                                <th colspan="1" style="border:none" class="br-bottom br-right">Advt Amt</th>
                                <th colspan="1" style="border:none" class="br-bottom br-right">Net Amt</th>
                                <th colspan="1" style="border:none" class="br-bottom br-right">GST</th>                                
                                <th colspan="1" style="border:none" class="br-bottom br-right" >Total Amt</th>
                                <th colspan="1" style="border:none" class="br-bottom br-right" >Receive Credit Note</th>
                                <th colspan="1" style="border:none"  class="br-bottom br-right" >Remaing Amt</th>
                            </tr> 
                        </ng-container>
                          
                    </thead>
                    <!-- *ngFor="let data of OutstandingInDetailFormBeans; let i = index" -->
                    <tbody *ngFor="let item of inDetailsoff_repdatab; let i = index">
                    <tr>
                      <td colspan="1" style="border:none; font-weight: bold;" class=" br-right">{{i+1}}</td>
                      <!-- <td colspan="1" style="border:none" class=" br-right">{{item.issuename}}</td> -->
                      <td colspan="1" style="border:none" class=" br-right">{{item.booking_date}}</td>
                      <td colspan="1" style="border:none" class=" br-right">{{item.sankhya}}</td>
                      <td colspan="1" style="border:none; text-align: end;" class=" br-right">{{item.advt_amt}}</td>
                      <td colspan="1" style="border:none; text-align: end;" class=" br-right">{{item.total_amount}}</td>
                      <td colspan="1" style="border:none; text-align: end;" class=" br-right">{{item.gst}}</td>
                      <td colspan="1" style="border:none; text-align: end;" class=" br-right">{{item.total_amount}}</td>
                      <td colspan="1" style="border:none; text-align: end;" class=" br-right">{{item.pymt_rcv_amt}}</td>
                      <td colspan="1" style="border:none; text-align: end;" class=" br-right">{{item.remaingamt}}</td>  

                    </tr>
                    </tbody>
        
                    <tfoot>
                        <!-- 18 -->
                        <tr>
                            <td style="border:none" class="br-bottom br-right br-top" style=" font-weight: bold;">{{inDetailsoff_repdatab.length}}</td>
                            <td style="border:none" class="br-bottom br-right br-top" style="text-align: center; font-weight: bold;" colspan="2">एकूण</td>
                            <td style="border:none" class="br-bottom br-right br-top" style="text-align: end; font-weight: bold;" >{{advtamtb}}</td>
                            <td style="border:none" class="br-bottom br-right br-top" style="text-align: end; font-weight: bold;" >{{netadvtamtb}}</td>
                            <td style="border:none" class="br-bottom br-right br-top" style="text-align: end; font-weight: bold;">{{totalgst}}</td>
                            <td style="border:none" class="br-bottom br-right br-top" style="text-align: end; font-weight: bold;">{{netadvtamtb}}</td>
                            <td style="border:none" class="br-bottom br-right br-top" style="text-align: end; font-weight: bold;">{{jamatotalb}}</td>
                            <td style="border:none" class="br-bottom br-right br-top" style="text-align: end; font-weight: bold;">{{remaingtotalb}}</td>
                        </tr>
                    </tfoot>  
                </table>
               
            </div>
        </div>
        <div *ngIf="type=='Represantative Indetails'">
            <div id="print-section" style="margin-top: 50px;">
                <table class="table table-bordered">
                    <thead>
                        <tr>
                            <th colspan="3" style="border:none" class="br-bottom br-right br-top">हिंदुस्तान प्रकाशन संस्था - विवेक साप्ताहिक  </th>
                            <th colspan="3" style="border:none" class="br-bottom br-right br-top">अंकाचे नाव : &nbsp; {{newissuedatepp}}  </th>

                            <th colspan="3" style="border:none" class="br-bottom br-right br-top">Representive Register Report - In Details</th>
                            <th colspan="2"  style="border:none" class="br-bottom br-right br-top">अंकाचे कॅटेगरी : &nbsp;{{issue_cat}}</th> 
                            <!-- <th colspan="2">Advt. Outstanding Report</th> -->
                           
                            <th colspan="4" style="border:none" class="br-bottom br-right br-top"> Date : From {{ start_date | date: 'dd-MM-yyyy' }} - To {{ end_date | date: 'dd-MM-yyyy' }}</th>                   
                             <th colspan="3" style="border:none" class="br-bottom br-right br-top">दिनांक :{{today1}}</th>
                            <th colspan="3" style="border:none" class="br-bottom br-top">पृष्ठ क्र :1</th>
                            
                        </tr>
                      
                        <tr>
                            <th colspan="1" style="border:none" class="br-bottom br-right">Sr</th>
                            <!-- <th colspan="1" style="border:none" class="br-bottom br-right">Issue name</th> -->
                            <th colspan="1" style="border:none" class="br-bottom br-right">Booking Date</th>
                            <th colspan="1" style="border:none" class="br-bottom br-right">Booking No</th>
                            <th colspan="1" style="border:none" class="br-bottom br-right">Advertiser Name</th>
                            <th colspan="1" style="border:none" class="br-bottom br-right">Customer Name</th>
                            <th colspan="1" style="border:none" class="br-bottom br-right">Represntive Name</th>
                            <th colspan="1" style="border:none" class="br-bottom br-right">Mobile Number</th>
                            <th colspan="1" style="border:none" class="br-bottom br-right">Office Rep Name</th>
                            <th colspan="1" style="border:none"  class="br-bottom br-right">RO Number</th>
                            <th colspan="1" style="border:none" class="br-bottom br-right">Instruction</th>
                            <th colspan="1" style="border:none" class="br-bottom br-right">Advt. Size</th>
                            
                            <th colspan="1" style="border:none" class="br-bottom br-right" style="text-align: end;">Advt Amt</th>
                            <th colspan="1" style="border:none" class="br-bottom br-right" style="text-align: end;">Disc Amt</th>
                            <th colspan="1" style="border:none" class="br-bottom br-right" style="text-align: end;">Net Amt</th>
                            <th colspan="1" style="border:none" class="br-bottom br-right" style="text-align: end;">Before GST Amt</th>
                            <th colspan="1" style="border:none" class="br-bottom br-right" style="text-align: end;">GST</th>
                            <th colspan="1" style="border:none"  class="br-bottom br-right" style="text-align: end;">Total Amt</th>
                            <th colspan="1" style="border:none" class="br-bottom br-right" style="text-align: end;">Receipt Number & Date</th>
                            <th colspan="1" style="border:none" class="br-bottom br-right" style="text-align: end;">जमा बाकी</th>
                            <th colspan="1" style="border:none" class="br-bottom" style="text-align: end;">येणे बाकी</th>
                        </tr>   
                    </thead>
                    <!-- *ngFor="let data of OutstandingInDetailFormBeans; let i = index" -->
                    <tbody>
                    <ng-container  *ngFor="let item1 of inDetailsRegReportrep; let i = index">
                    <tr>
                        <td colspan="3" style="border:none" class=" br-right br-bottom br-top">Represantative Name : {{item1.rep_name}}</td>
                        <td colspan="2" style="border:none" class=" br-right br-bottom br-top">Mobile Number : {{item1.rep_mob_no}}</td>
                        <td colspan="15" style="border:none" class=" br-right br-bottom br-top"></td>

                    </tr>
                        <ng-container  *ngFor="let item of item1.advt_data; let i = index">
                            <tr>
                            <td colspan="1" style="border:none; font-weight: bold;" class=" br-right">{{item.advtsizeno}}</td>
                            <!-- <td colspan="1" style="border:none" class=" br-right">{{item.issuename}}</td> -->
                            <td colspan="1" style="border:none" class=" br-right">{{item.booking_date}}</td>
                            <td colspan="1" style="border:none" class=" br-right">{{item.booking_no}}</td>
                            <td colspan="1" style="border:none" class=" br-right">{{item.advt_name}}</td>
                            <td colspan="1" style="border:none" class=" br-right">{{item.cust_name}}</td>
                            <td colspan="1" style="border:none" class=" br-right">{{item.rep_name}}</td>
                            <td colspan="1" style="border:none" class=" br-right">{{item.rep_mob_no}}</td>
                            <td colspan="1" style="border:none" class=" br-right">{{item.off_rep_name}}</td>
                            <td colspan="1" style="border:none" class=" br-right">{{item.ro_no}}</td>
                            <td colspan="1" style="border:none" class=" br-right">{{item.instruction}}</td>
                            <td colspan="1" style="border:none" class=" br-right">{{item.advtsize}}</td>
                            <td colspan="1" style="border:none; text-align: end;" class=" br-right">{{item.advt_amt}}</td>
                            <td colspan="1" style="border:none; text-align: end;" class=" br-right">{{item.disc_amt}}</td>
                            <td colspan="1" style="border:none; text-align: end;" class=" br-right">{{item.amount}}</td>
                            <td colspan="1" style="border:none; text-align: end;" class=" br-right">{{item.beforegst_amt}}</td>
                            <td colspan="1" style="border:none; text-align: end;" class=" br-right">{{item.totalgst}} </td>
                            <td colspan="1" style="border:none; text-align: end;" class=" br-right">{{item.total_amount}}</td>
                            <td colspan="1" style="border:none" class=" br-right">{{item.rep_rcpt_no}} {{item.rcpt_date}}</td>
                            <td colspan="1" style="border:none; text-align: end;" class=" br-right">{{item.pymt_rcv_amt}}</td>
                            <!-- <td colspan="1" style="border:none; text-align: end;" class=" br-right">{{item.amount - item.part_pymt_amt}}</td>      -->
                            <td colspan="1" style="border:none; text-align: end;" class=" br-right">{{item.remaining_amt}}</td>     
                            </tr>
                              
                          </ng-container >
                          <tr>
                            <td style="border:none" class="br-bottom br-right br-top" style=" font-weight: bold;" colspan="11">Total</td>
                            <td colspan="1" style="border:none; text-align: end;" class=" br-right br-bottom br-top"> {{item1.total_advt_amount}}</td>
                            <td colspan="1" style="border:none; text-align: end;" class=" br-right br-bottom br-top"> {{item1.total_discount}}</td>
                            <td colspan="1" style="border:none; text-align: end;" class=" br-right br-bottom br-top"> {{item1.total_discounted_amt}}</td>
                            <td colspan="1" style="border:none; text-align: end;" class=" br-right br-bottom br-top"> {{item1.total_before_gst}}</td>
                            <td colspan="1" style="border:none; text-align: end;" class=" br-right br-bottom br-top"> {{item1.total_gst}}</td>
                            <td colspan="1" style="border:none; text-align: end;" class=" br-right br-bottom br-top"> {{item1.total_amt}}</td>
                            <td colspan="1" style="border:none; text-align: end;" class=" br-right br-bottom br-top"> </td>
                            <td colspan="1" style="border:none; text-align: end;" class=" br-right br-bottom br-top"> {{item1.total_rcv}}</td>
                            <td colspan="1" style="border:none; text-align: end;" class=" br-right br-bottom br-top"> {{item1.total_pending}}</td>
                        </tr>
                    </ng-container>
                    </tbody>
        
                    <tfoot>
                        <!-- 18 -->
                        <tr>
                            <td style="border:none" class="br-bottom br-right br-top" style=" font-weight: bold;">{{inDetailsRegReportrep.length}}</td>
                            <td style="border:none" class="br-bottom br-right br-top" style="text-align: center; font-weight: bold;" colspan="10">एकूण</td>
                            <td style="border:none" class="br-bottom br-right br-top" style="text-align: end; font-weight: bold;" >{{advtamt}}</td>
                            <td style="border:none" class="br-bottom br-right br-top" style="text-align: end; font-weight: bold;" >{{totaldisc_total}}</td>
                            <td style="border:none" class="br-bottom br-right br-top" style="text-align: end; font-weight: bold;" >{{totaladvtamt}}</td>
                            <td style="border:none" class="br-bottom br-right br-top" style="text-align: end; font-weight: bold;" >{{totalbeforegst_amt}}</td>
                            <td style="border:none" class="br-bottom br-right br-top" style="text-align: end; font-weight: bold;">{{totalgst}}</td>
                            <td style="border:none" class="br-bottom br-right br-top" style="text-align: end; font-weight: bold;">{{netadvtamt}}</td>
                            <td style="border:none" class="br-bottom br-right br-top" style="text-align: end; font-weight: bold;"></td>
                            <td style="border:none" class="br-bottom br-right br-top" style="text-align: end; font-weight: bold;">{{jamatotal}}</td>

                            <td style="border:none" class="br-bottom br-right br-top" style="text-align: end; font-weight: bold;">{{netadvtamt - jamatotal}}</td>
                        </tr>
                    </tfoot>  
                </table>
               
            </div>
        </div>
        <div *ngIf="type=='Represantative Briefly'">
            <div id="print-section" style="margin-top: 50px;">
                <table class="table table-bordered">
                    <thead>
                        <tr>
                            <th colspan="2" style="border:none" class="br-bottom br-right br-top">हिंदुस्तान प्रकाशन संस्था - विवेक साप्ताहिक  </th>
                            <th colspan="2" style="border:none" class="br-bottom br-right br-top">अंकाचे नाव : &nbsp; {{newissuedatepp}}  </th>

                            <th colspan="2" style="border:none" class="br-bottom br-right br-top">Representive Briefly</th>
                            <th colspan="2"  style="border:none" class="br-bottom br-right br-top">अंकाचे कॅटेगरी : &nbsp;{{issue_cat}}</th> 
                            <!-- <th colspan="2">Advt. Outstanding Report</th> -->
                           
                            <th colspan="2" style="border:none" class="br-bottom br-right br-top"> Date : From {{ start_date | date: 'dd-MM-yyyy' }} - To {{ end_date | date: 'dd-MM-yyyy' }}</th>                   
                             <th colspan="1" style="border:none" class="br-bottom br-right br-top">दिनांक :{{today1}}</th>
                            <th colspan="1" style="border:none" class="br-bottom br-top">पृष्ठ क्र :1</th>
                            
                        </tr>
                        <ng-container >
                            <tr>

                            </tr>
                            <tr>
                                <th colspan="1" style="border:none" class="br-bottom br-right">Sr</th>
                                <th colspan="1" style="border:none" class="br-bottom br-right">Advt Name</th>
                                <th colspan="1" style="border:none;" class="br-bottom br-right">Client</th>
                                <th colspan="1" style="border:none" class="br-bottom br-right">Representative</th>
                                <th colspan="1" style="border:none" class="br-bottom br-right">Advt. Size</th>
                                <th colspan="1" style="border:none" class="br-bottom br-right">Advt Amt</th>                                
                                <th colspan="1" style="border:none" class="br-bottom br-right" >Net Amt</th>
                                <th colspan="1" style="border:none" class="br-bottom br-right" >Before Gst</th>
                                <th colspan="1" style="border:none" class="br-bottom br-right" >GST Amt</th>
                                <th colspan="1" style="border:none"  class="br-bottom br-right" >Total Amt</th>
                                <th colspan="1" style="border:none"  class="br-bottom br-right" >Receive Amt</th>
                                <th colspan="1" style="border:none"  class="br-bottom br-right" >Pending Amt</th>
                            </tr> 
                        </ng-container>
                          
                    </thead>
                    <tbody>
                        <ng-container  *ngFor="let item1 of inDetailsRegReportrep; let i = index">
                        <tr>
                            <td colspan="3" style="border:none" class=" br-right br-bottom br-top">Represantative Name : {{item1.rep_name}}</td>
                            <td colspan="3" style="border:none" class=" br-right br-bottom br-top">Mobile Number : {{item1.rep_mob_no}}</td>
                            <td colspan="6" style="border:none" class=" br-right br-bottom br-top"></td>
    
                        </tr>
                            <ng-container  *ngFor="let item of item1.advt_data; let i = index">
                                <tr>
                                    <td colspan="1" style="border:none; font-weight: bold;" class=" br-right">{{item.advtsizeno}}</td>
                                    <td colspan="1" style="border:none" class=" br-right">{{item.advt_name}}</td>
                                    <td colspan="1" style="border:none" class=" br-right">{{item.client}}</td>
                                    <td colspan="1" style="border:none" class=" br-right">{{item.rep_name}}</td>
                                    <td colspan="1" style="border:none" class=" br-right">{{item.advtsize}}</td>
                                    <td colspan="1" style="border:none; text-align: end;" class=" br-right">{{item.advt_amt}}</td>
                                    <td colspan="1" style="border:none; text-align: end;" class=" br-right">{{item.amount}}</td>
                                    <td colspan="1" style="border:none; text-align: end;" class=" br-right">{{item.beforegst_amt}}</td>
                                    <td colspan="1" style="border:none; text-align: end;" class=" br-right">{{item.gst}}</td>
                                    <td colspan="1" style="border:none; text-align: end;" class=" br-right">{{item.total_amount}}</td>
                                    <td colspan="1" style="border:none; text-align: end;" class=" br-right">{{item.pymt_rcv_amt}}</td>
                                    <td colspan="1" style="border:none; text-align: end;" class=" br-right">{{item.remaining_amt}}</td>  
                                  </tr>
                              </ng-container >
                              
                                <tr>
                                    <td style="border:none" class="br-bottom br-right br-top" style=" font-weight: bold;">{{item1.advt_data.length}}</td>
                                    <td style="border:none" class="br-bottom br-right br-top" style="text-align: center; font-weight: bold;" colspan="4">एकूण</td>
                                    <td style="border:none" class="br-bottom br-right br-top" style="text-align: end; font-weight: bold;" >{{item1.total_advt_amount}}</td>
                                    <td style="border:none" class="br-bottom br-right br-top" style="text-align: end; font-weight: bold;" >{{item1.total_discounted_amt}}</td>
                                    <td style="border:none" class="br-bottom br-right br-top" style="text-align: end; font-weight: bold;" >{{item1.total_before_gst}}</td>
                                    <td style="border:none" class="br-bottom br-right br-top" style="text-align: end; font-weight: bold;">{{item1.total_gst}}</td>
                                    <td style="border:none" class="br-bottom br-right br-top" style="text-align: end; font-weight: bold;">{{item1.total_amt}}</td>
                                    <td style="border:none" class="br-bottom br-right br-top" style="text-align: end; font-weight: bold;">{{item1.total_rcv}}</td>
                                    <td style="border:none" class="br-bottom br-right br-top" style="text-align: end; font-weight: bold;">{{item1.total_pending}}</td>
                                </tr>
                              
                        </ng-container>
                        </tbody>
        
                    <tfoot>
                        <!-- 18 -->
                        <tr>
                            <td style="border:none" class="br-bottom br-right br-top" style=" font-weight: bold;">{{inDetailsbriflydata.length}}</td>
                            <td style="border:none" class="br-bottom br-right br-top" style="text-align: center; font-weight: bold;" colspan="4">एकूण</td>
                            <td style="border:none" class="br-bottom br-right br-top" style="text-align: end; font-weight: bold;" >{{advtamtb}}</td>
                            <td style="border:none" class="br-bottom br-right br-top" style="text-align: end; font-weight: bold;" >{{totaladvtamt}}</td>
                            <td style="border:none" class="br-bottom br-right br-top" style="text-align: end; font-weight: bold;" >{{totalbeforegst_amt}}</td>
                            <td style="border:none" class="br-bottom br-right br-top" style="text-align: end; font-weight: bold;">{{totalgst}}</td>
                            <td style="border:none" class="br-bottom br-right br-top" style="text-align: end; font-weight: bold;">{{netadvtamt}}</td>
                            <td style="border:none" class="br-bottom br-right br-top" style="text-align: end; font-weight: bold;">{{jamatotalb}}</td>
                            <td style="border:none" class="br-bottom br-right br-top" style="text-align: end; font-weight: bold;">{{netadvtamt - jamatotal}}</td>
                        </tr>
                    </tfoot>  
                </table>
               
            </div>
        </div>
        <div *ngIf="type=='Represantative very Briefly'">
            <div id="print-section" style="margin-top: 50px;">
                <table class="table table-bordered">
                    <thead>
                        <tr>
                            <th colspan="2" style="border:none" class="br-bottom br-right br-top">हिंदुस्तान प्रकाशन संस्था - विवेक साप्ताहिक  </th>
                            <th colspan="2" style="border:none" class="br-bottom br-right br-top">अंकाचे नाव : &nbsp; {{newissuedatepp}}  </th>

                            <th colspan="2" style="border:none" class="br-bottom br-right br-top">Representive Very Briefly</th>
                            <th colspan="2"  style="border:none" class="br-bottom br-right br-top">अंकाचे कॅटेगरी : &nbsp;{{issue_cat}}</th> 
                            <!-- <th colspan="2">Advt. Outstanding Report</th> -->
                           
                            <th colspan="2" style="border:none" class="br-bottom br-right br-top"> Date : From {{ start_date | date: 'dd-MM-yyyy' }} - To {{ end_date | date: 'dd-MM-yyyy' }}</th>                   
                             <th colspan="1" style="border:none" class="br-bottom br-right br-top">दिनांक :{{today1}}</th>
                            <th colspan="1" style="border:none" class="br-bottom br-top">पृष्ठ क्र :1</th>
                            
                        </tr>
                        <ng-container >
                            <tr>

                            </tr>
                            <tr>
                                <th colspan="1" style="border:none" class="br-bottom br-right">Sr</th>
                                <th colspan="1" style="border:none" class="br-bottom br-right">Advt Name</th>
                                <th colspan="1" style="border:none;" class="br-bottom br-right">Client</th>
                                <th colspan="1" style="border:none" class="br-bottom br-right">Representative</th>
                                <th colspan="1" style="border:none" class="br-bottom br-right">Advt. Size</th>
                                <th colspan="1" style="border:none" class="br-bottom br-right">Advt Amt</th>                                
                                <th colspan="1" style="border:none" class="br-bottom br-right" >Net Amt</th>
                                <th colspan="1" style="border:none" class="br-bottom br-right" >Before GST Amt</th>
                                <th colspan="1" style="border:none" class="br-bottom br-right" >GST Amt</th>
                                <th colspan="1" style="border:none"  class="br-bottom br-right" >Total Amt</th>
                                <th colspan="1" style="border:none"  class="br-bottom br-right" >Receive Amt</th>
                                <th colspan="1" style="border:none"  class="br-bottom br-right" >Pending Amt</th>
                            </tr> 
                        </ng-container>
                          
                    </thead>
                    <tbody>
                        <ng-container  *ngFor="let item1 of inDetailsRegReportrep; let i = index">
                        <!-- <tr>
                            <td colspan="3" style="border:none" class=" br-right br-bottom br-top">Represantative Name : {{item1.rep_name}}</td>
                            <td colspan="2" style="border:none" class=" br-right br-bottom br-top">Mobile Number : {{item1.rep_mob_no}}</td>
                            <td colspan="6" style="border:none" class=" br-right br-bottom br-top"></td>
    
                        </tr> -->
                            <!-- <ng-container  *ngFor="let item of item1.advt_data; let i = index">
                                <tr>
                                    <td colspan="1" style="border:none; font-weight: bold;" class=" br-right">{{item.advtsizeno}}</td>
                                    <td colspan="1" style="border:none" class=" br-right">{{item.advt_name}}</td>
                                    <td colspan="1" style="border:none" class=" br-right">{{item.client}}</td>
                                    <td colspan="1" style="border:none" class=" br-right">{{item.rep_name}}</td>
                                    <td colspan="1" style="border:none" class=" br-right">{{item.advtsize}}</td>
                                    <td colspan="1" style="border:none; text-align: end;" class=" br-right">{{item.advt_amt}}</td>
                                    <td colspan="1" style="border:none; text-align: end;" class=" br-right">{{item.amount}}</td>
                                    <td colspan="1" style="border:none; text-align: end;" class=" br-right">{{item.gst}}</td>
                                    <td colspan="1" style="border:none; text-align: end;" class=" br-right">{{item.total_amount}}</td>
                                    <td colspan="1" style="border:none; text-align: end;" class=" br-right">{{item.pymt_rcv_amt}}</td>
                                    <td colspan="1" style="border:none; text-align: end;" class=" br-right">{{item.remaining_amt}}</td>  
                                  </tr>
                              </ng-container > -->
                              
                                <tr>
                                    <td style="border:none" class="br-bottom br-right br-top" style=" font-weight: bold;">{{item1.advt_data.length}}</td>
                                    <td style="border:none" class="br-bottom br-right br-top" style="text-align: start; font-weight: bold;" colspan="4">{{item1.rep_name}}</td>
                                    <td style="border:none" class="br-bottom br-right br-top" style="text-align: end; font-weight: bold;" >{{item1.total_advt_amount}}</td>
                                    <td style="border:none" class="br-bottom br-right br-top" style="text-align: end; font-weight: bold;" >{{item1.total_discounted_amt}}</td>
                                    <td style="border:none" class="br-bottom br-right br-top" style="text-align: end; font-weight: bold;">{{item1.total_before_gst}}</td>
                                    <td style="border:none" class="br-bottom br-right br-top" style="text-align: end; font-weight: bold;">{{item1.total_gst}}</td>
                                    <td style="border:none" class="br-bottom br-right br-top" style="text-align: end; font-weight: bold;">{{item1.total_amt}}</td>
                                    <td style="border:none" class="br-bottom br-right br-top" style="text-align: end; font-weight: bold;">{{item1.total_rcv}}</td>
                                    <td style="border:none" class="br-bottom br-right br-top" style="text-align: end; font-weight: bold;">{{item1.total_pending}}</td>
                                </tr>
                              
                        </ng-container>
                        </tbody>
        
                    <tfoot>
                        <!-- 18 -->
                        <tr>
                            <td style="border:none" class="br-bottom br-right br-top" style=" font-weight: bold;">{{inDetailsbriflydata.length}}</td>
                            <td style="border:none" class="br-bottom br-right br-top" style="text-align: center; font-weight: bold;" colspan="4">एकूण</td>
                            <td style="border:none" class="br-bottom br-right br-top" style="text-align: end; font-weight: bold;" >{{advtamtb}}</td>
                            <td style="border:none" class="br-bottom br-right br-top" style="text-align: end; font-weight: bold;" >{{totaladvtamt}}</td>
                            <td style="border:none" class="br-bottom br-right br-top" style="text-align: end; font-weight: bold;" >{{totalbeforegst_amt}}</td>
                            <td style="border:none" class="br-bottom br-right br-top" style="text-align: end; font-weight: bold;">{{totalgst}}</td>
                            <td style="border:none" class="br-bottom br-right br-top" style="text-align: end; font-weight: bold;">{{netadvtamt}}</td>
                            <td style="border:none" class="br-bottom br-right br-top" style="text-align: end; font-weight: bold;">{{jamatotalb}}</td>
                            <td style="border:none" class="br-bottom br-right br-top" style="text-align: end; font-weight: bold;">{{netadvtamt - jamatotal}}</td>
                        </tr>
                    </tfoot>  
                </table>
               
            </div>
        </div>
        <div *ngIf="type=='Off_Represantative Indetails'">
            <div id="print-section" style="margin-top: 50px;">
                <table class="table table-bordered">
                    <thead>
                        <tr>
                            <th colspan="3" style="border:none" class="br-bottom br-right br-top">हिंदुस्तान प्रकाशन संस्था - विवेक साप्ताहिक  </th>
                            <th colspan="3" style="border:none" class="br-bottom br-right br-top">अंकाचे नाव : &nbsp; {{newissuedatepp}}  </th>

                            <th colspan="3" style="border:none" class="br-bottom br-right br-top">Off. Representive Register Report - In Details</th>
                            <th colspan="2"  style="border:none" class="br-bottom br-right br-top">अंकाचे कॅटेगरी : &nbsp;{{issue_cat}}</th> 
                            <!-- <th colspan="2">Advt. Outstanding Report</th> -->
                           
                            <th colspan="4" style="border:none" class="br-bottom br-right br-top"> Date : From {{ start_date | date: 'dd-MM-yyyy' }} - To {{ end_date | date: 'dd-MM-yyyy' }}</th>                   
                             <th colspan="3" style="border:none" class="br-bottom br-right br-top">दिनांक :{{today1}}</th>
                            <th colspan="3" style="border:none" class="br-bottom br-top">पृष्ठ क्र :1</th>
                            
                        </tr>
                      
                        <tr>
                            <th colspan="1" style="border:none" class="br-bottom br-right">Sr</th>
                            <!-- <th colspan="1" style="border:none" class="br-bottom br-right">Issue name</th> -->
                            <th colspan="1" style="border:none" class="br-bottom br-right">Booking Date</th>
                            <th colspan="1" style="border:none" class="br-bottom br-right">Booking No</th>
                            <th colspan="1" style="border:none" class="br-bottom br-right">Advertiser Name</th>
                            <th colspan="1" style="border:none" class="br-bottom br-right">Customer Name</th>
                            <th colspan="1" style="border:none" class="br-bottom br-right">Represntive Name</th>
                            <th colspan="1" style="border:none" class="br-bottom br-right">Mobile Number</th>
                            <th colspan="1" style="border:none" class="br-bottom br-right">Office Rep Name</th>
                            <th colspan="1" style="border:none"  class="br-bottom br-right">RO Number</th>
                            <th colspan="1" style="border:none" class="br-bottom br-right">Instruction</th>
                            <th colspan="1" style="border:none" class="br-bottom br-right">Advt. Size</th>
                            
                            <th colspan="1" style="border:none" class="br-bottom br-right" style="text-align: end;">Advt Amt</th>
                            <th colspan="1" style="border:none" class="br-bottom br-right" style="text-align: end;">Disc Amt</th>
                            <th colspan="1" style="border:none" class="br-bottom br-right" style="text-align: end;">Net Amt</th>
                            <th colspan="1" style="border:none" class="br-bottom br-right" style="text-align: end;">Before GST Amt</th>
                            <th colspan="1" style="border:none" class="br-bottom br-right" style="text-align: end;">GST</th>
                            <th colspan="1" style="border:none"  class="br-bottom br-right" style="text-align: end;">Total Amt</th>
                            <th colspan="1" style="border:none" class="br-bottom br-right" style="text-align: end;">Receipt Number & Date</th>
                            <th colspan="1" style="border:none" class="br-bottom br-right" style="text-align: end;">जमा बाकी</th>
                            <th colspan="1" style="border:none" class="br-bottom" style="text-align: end;">येणे बाकी</th>
                        </tr>   
                    </thead>
                    <!-- *ngFor="let data of OutstandingInDetailFormBeans; let i = index" -->
                    <tbody >
                        <ng-container *ngFor="let item2 of inDetailsRegReportbtoff; let i = index">
                           <tr>
                            <td colspan="2" style="border:none" class=" br-right br-top br-bottom">Off-Represantative</td>
                            <td colspan="2" style="border:none" class=" br-right br-top br-bottom">{{item2.off_rep_name}}</td>
                            <td colspan="16" style="border:none" class=" br-right br-top br-bottom"></td>

                           </tr> 
                           <ng-container *ngFor="let item1 of item2.rep_data; let i = index">
                            <tr >
                            <td colspan="1" style="border:none" class=" br-right br-top br-bottom">Represantative</td>
                            <td colspan="1" style="border:none" class=" br-right br-top br-bottom">{{item1.rep_name}}</td>
                            <td colspan="1" style="border:none" class=" br-right br-top br-bottom">Mobile Number</td>
                            <td colspan="1" style="border:none" class=" br-right br-top br-bottom">{{item1.rep_mob_no}}</td>
                            <td colspan="16" style="border:none" class=" br-right br-top br-bottom"></td>
                            </tr>

                            <ng-container *ngFor="let item of item1.advt_data; let i = index">
                                <tr >
                                    <td colspan="1" style="border:none; font-weight: bold;" class=" br-right">{{item.advtsizeno}}</td>
                                    <!-- <td colspan="1" style="border:none" class=" br-right">{{item.issuename}}</td> -->
                                    <td colspan="1" style="border:none" class=" br-right">{{item.booking_date}}</td>
                                    <td colspan="1" style="border:none" class=" br-right">{{item.booking_no}}</td>
                                    <td colspan="1" style="border:none" class=" br-right">{{item.advt_name}}</td>
                                    <td colspan="1" style="border:none" class=" br-right">{{item.cust_name}}</td>
                                    <td colspan="1" style="border:none" class=" br-right">{{item.rep_name}}</td>
                                    <td colspan="1" style="border:none" class=" br-right">{{item.rep_mob_no}}</td>
                                    <td colspan="1" style="border:none" class=" br-right">{{item.off_rep_name}}</td>
                                    <td colspan="1" style="border:none" class=" br-right">{{item.ro_no}}</td>
                                    <td colspan="1" style="border:none" class=" br-right">{{item.instruction}}</td>
                                    <td colspan="1" style="border:none" class=" br-right">{{item.advtsize}}</td>
                                    <td colspan="1" style="border:none; text-align: end;" class=" br-right">{{item.advt_amt}}</td>
                                    <td colspan="1" style="border:none; text-align: end;" class=" br-right">{{item.disc_amt}}</td>
                                    <td colspan="1" style="border:none; text-align: end;" class=" br-right">{{item.total_amount}}</td>
                                    <td colspan="1" style="border:none; text-align: end;" class=" br-right">{{item.beforegst_amt}}</td>
                                    <td colspan="1" style="border:none; text-align: end;" class=" br-right">{{item.gst}} </td>
                                    <td colspan="1" style="border:none; text-align: end;" class=" br-right">{{item.amount}}</td>
                                    <td colspan="1" style="border:none" class=" br-right">{{item.rep_rcpt_no}} {{item.rcpt_date}}</td>
                                    <td colspan="1" style="border:none; text-align: end;" class=" br-right">{{item.pymt_rcv_amt}}</td>
                                    <!-- <td colspan="1" style="border:none; text-align: end;" class=" br-right">{{item.amount - item.part_pymt_amt}}</td>      -->
                                    <td colspan="1" style="border:none; text-align: end;" class=" br-right">{{item.remaingamt}}</td> 
                                </tr>
                            </ng-container>
                            <tr>
                            <td style="border:none" class="br-bottom br-right br-top" style=" font-weight: bold;" colspan="11">Total</td>
                            <td colspan="1" style="border:none; text-align: end;" class=" br-right br-bottom br-top"> {{item1.total_advt_amount}}</td>
                            <td colspan="1" style="border:none; text-align: end;" class=" br-right br-bottom br-top"> {{item1.total_discount}}</td>
                            <td colspan="1" style="border:none; text-align: end;" class=" br-right br-bottom br-top"> {{item1.total_discounted_amt}}</td>
                            <td colspan="1" style="border:none; text-align: end;" class=" br-right br-bottom br-top"> {{item1.total_before_gst}}</td>
                            <td colspan="1" style="border:none; text-align: end;" class=" br-right br-bottom br-top"> {{item1.total_gst}}</td>
                            <td colspan="1" style="border:none; text-align: end;" class=" br-right br-bottom br-top"> {{item1.total_amt}}</td>
                            <td colspan="1" style="border:none; text-align: end;" class=" br-right br-bottom br-top"> </td>
                            <td colspan="1" style="border:none; text-align: end;" class=" br-right br-bottom br-top"> {{item1.pymt_rcv_amt}}</td>
                            <td colspan="1" style="border:none; text-align: end;" class=" br-right br-bottom br-top"> {{item1.total_pending}}</td>
                            </tr>
                           </ng-container>
                        </ng-container>
                        <tr>
                    </tr>
                    </tbody>
        
                    <tfoot>
                        <!-- 18 -->
                        <tr>
                            <td style="border:none" class="br-bottom br-right br-top" style=" font-weight: bold;">{{inDetailsRegReportbtoff.length}}</td>
                            <td style="border:none" class="br-bottom br-right br-top" style="text-align: center; font-weight: bold;" colspan="10">एकूण</td>
                            <td style="border:none" class="br-bottom br-right br-top" style="text-align: end; font-weight: bold;" >{{advtamt}}</td>
                            <td style="border:none" class="br-bottom br-right br-top" style="text-align: end; font-weight: bold;" >{{totaldisc_total}}</td>
                            <td style="border:none" class="br-bottom br-right br-top" style="text-align: end; font-weight: bold;" >{{netadvtamt}}</td>
                            <td style="border:none" class="br-bottom br-right br-top" style="text-align: end; font-weight: bold;" >{{totalbeforegst_amt}}</td>
                            <td style="border:none" class="br-bottom br-right br-top" style="text-align: end; font-weight: bold;">{{totalgst}}</td>
                            <td style="border:none" class="br-bottom br-right br-top" style="text-align: end; font-weight: bold;">{{totaladvtamt}}</td>
                            <td style="border:none" class="br-bottom br-right br-top" style="text-align: end; font-weight: bold;"></td>
                            <td style="border:none" class="br-bottom br-right br-top" style="text-align: end; font-weight: bold;">{{jamatotal}}</td>
                            <td style="border:none" class="br-bottom br-right br-top" style="text-align: end; font-weight: bold;">{{netadvtamt - jamatotal}}</td>
                        </tr>
                    </tfoot>  
                </table>
               
            </div>
        </div>
        <div *ngIf="type=='Off_Represantative Briefly'">
            <div id="print-section" style="margin-top: 50px;">
                <table class="table table-bordered">
                    <thead>
                        <tr>
                            <th colspan="2" style="border:none" class="br-bottom br-right br-top">हिंदुस्तान प्रकाशन संस्था - विवेक साप्ताहिक  </th>
                            <th colspan="2" style="border:none" class="br-bottom br-right br-top">अंकाचे नाव : &nbsp; {{newissuedatepp}}  </th>

                            <th colspan="2" style="border:none" class="br-bottom br-right br-top">Off. Representive Briefly</th>
                            <th colspan="2"  style="border:none" class="br-bottom br-right br-top">अंकाचे कॅटेगरी : &nbsp;{{issue_cat}}</th> 
                            <!-- <th colspan="2">Advt. Outstanding Report</th> -->
                           
                            <th colspan="2" style="border:none" class="br-bottom br-right br-top"> Date : From {{ start_date | date: 'dd-MM-yyyy' }} - To {{ end_date | date: 'dd-MM-yyyy' }}</th>                   
                             <th colspan="1" style="border:none" class="br-bottom br-right br-top">दिनांक :{{today1}}</th>
                            <th colspan="1" style="border:none" class="br-bottom br-top">पृष्ठ क्र :1</th>
                            
                        </tr>
                        <ng-container >
                            <tr>

                            </tr>
                            <tr>
                                <th colspan="1" style="border:none" class="br-bottom br-right">Sr</th>
                                <th colspan="1" style="border:none" class="br-bottom br-right">Advt Name</th>
                                <th colspan="1" style="border:none;" class="br-bottom br-right">Client</th>
                                <th colspan="1" style="border:none" class="br-bottom br-right">Representative</th>
                                <th colspan="1" style="border:none" class="br-bottom br-right">Advt. Size</th>
                                <th colspan="1" style="border:none" class="br-bottom br-right">Advt Amt</th>                                
                                <th colspan="1" style="border:none" class="br-bottom br-right" >Net Amt</th>
                                <th colspan="1" style="border:none" class="br-bottom br-right" >Before Gst</th>
                                <th colspan="1" style="border:none" class="br-bottom br-right" >GST Amt</th>
                                <th colspan="1" style="border:none"  class="br-bottom br-right" >Total Amt</th>
                                <th colspan="1" style="border:none"  class="br-bottom br-right" >Receive Amt</th>
                                <th colspan="1" style="border:none"  class="br-bottom br-right" >Pending Amt</th>
                            </tr> 
                        </ng-container>
                          
                    </thead>
                    <!-- *ngFor="let data of OutstandingInDetailFormBeans; let i = index" -->
                    <tbody >
                        <ng-container *ngFor="let item2 of inDetailsRegReportbtoff; let i = index">
                           <tr>
                            <td colspan="3" style="border:none" class=" br-right br-top br-bottom">Off-Represantative</td>
                            <td colspan="2" style="border:none" class=" br-right br-top br-bottom">{{item2.off_rep_name}}</td>
                            <td colspan="7" style="border:none" class=" br-right br-top br-bottom"></td>

                           </tr> 
                           <ng-container *ngFor="let item1 of item2.rep_data; let i = index">
                            <tr >
                            <td colspan="1" style="border:none" class=" br-right br-top br-bottom">Represantative</td>
                            <td colspan="1" style="border:none" class=" br-right br-top br-bottom">{{item1.rep_name}}</td>
                            <td colspan="1" style="border:none" class=" br-right br-top br-bottom">Mobile Number</td>
                            <td colspan="1" style="border:none" class=" br-right br-top br-bottom">{{item1.rep_mob_no}}</td>
                            <td colspan="8" style="border:none" class=" br-right br-top br-bottom"></td>
                            </tr>

                            <ng-container *ngFor="let item of item1.advt_data; let i = index">
                                <tr>
                                    <td colspan="1" style="border:none; font-weight: bold;" class=" br-right">{{item.advtsizeno}}</td>
                                    <td colspan="1" style="border:none" class=" br-right">{{item.advt_name}}</td>
                                    <td colspan="1" style="border:none" class=" br-right">{{item.client}}</td>
                                    <td colspan="1" style="border:none" class=" br-right">{{item.rep_name}}</td>
                                    <td colspan="1" style="border:none" class=" br-right">{{item.advtsize}}</td>
                                    <td colspan="1" style="border:none; text-align: end;" class=" br-right">{{item.advt_amt}}</td>
                                    <td colspan="1" style="border:none; text-align: end;" class=" br-right">{{item.amount}}</td>
                                    <td colspan="1" style="border:none; text-align: end;" class=" br-right">{{item.beforegst_amt}}</td>
                                    <td colspan="1" style="border:none; text-align: end;" class=" br-right">{{item.gst}}</td>
                                    <td colspan="1" style="border:none; text-align: end;" class=" br-right">{{item.total_amount}}</td>
                                    <td colspan="1" style="border:none; text-align: end;" class=" br-right">{{item.pymt_rcv_amt}}</td>
                                    <td colspan="1" style="border:none; text-align: end;" class=" br-right">{{item.remaining_amt}}</td>  
                                  </tr>
                            </ng-container>
                            <!-- <tr>
                            <td style="border:none" class="br-bottom br-right br-top" style=" font-weight: bold;" colspan="4">Total</td>
                            <td colspan="1" style="border:none; text-align: end;" class=" br-right br-bottom br-top"> {{item1.total_advt_amount}}</td>
                            <td colspan="1" style="border:none; text-align: end;" class=" br-right br-bottom br-top"> {{item1.total_discounted_amt}}</td>
                            <td colspan="1" style="border:none; text-align: end;" class=" br-right br-bottom br-top"> {{item1.rep_name}}</td>
                            <td colspan="1" style="border:none; text-align: end;" class=" br-right br-bottom br-top"> {{item1.total_amt}}</td>
                            <td colspan="1" style="border:none; text-align: end;" class=" br-right br-bottom br-top"> </td>
                            <td colspan="1" style="border:none; text-align: end;" class=" br-right br-bottom br-top"> {{item1.total_rcv}}</td>
                            <td colspan="1" style="border:none; text-align: end;" class=" br-right br-bottom br-top"> {{item1.total_pending}}</td>
                            </tr> -->
                            <tr>
                                <td style="border:none" class="br-bottom br-right br-top" style=" font-weight: bold;">{{item1.advt_data.length}}</td>
                                <td style="border:none" class="br-bottom br-right br-top" style="text-align: center; font-weight: bold;" colspan="4">एकूण</td>
                                <td style="border:none" class="br-bottom br-right br-top" style="text-align: end; font-weight: bold;" >{{item1.total_advt_amount}}</td>
                                <td style="border:none" class="br-bottom br-right br-top" style="text-align: end; font-weight: bold;" >{{item1.total_discounted_amt}}</td>
                                <td style="border:none" class="br-bottom br-right br-top" style="text-align: end; font-weight: bold;" >{{item1.total_before_gst}}</td>
                                <td style="border:none" class="br-bottom br-right br-top" style="text-align: end; font-weight: bold;">{{item1.total_gst}}</td>
                                <td style="border:none" class="br-bottom br-right br-top" style="text-align: end; font-weight: bold;">{{item1.total_amt}}</td>
                                <td style="border:none" class="br-bottom br-right br-top" style="text-align: end; font-weight: bold;">{{item1.total_rcv}}</td>
                                <td style="border:none" class="br-bottom br-right br-top" style="text-align: end; font-weight: bold;">{{item1.total_pending}}</td>
                            </tr>
                           </ng-container>
                        </ng-container>
                        <tr>
                    </tr>
                    </tbody>
        
                    <tfoot>
                        <!-- 18 -->
                       
                        <tr>
                            <td style="border:none" class="br-bottom br-right br-top" style=" font-weight: bold;">{{inDetailsbriflydata.length}}</td>
                            <td style="border:none" class="br-bottom br-right br-top" style="text-align: center; font-weight: bold;" colspan="4">एकूण</td>
                            <td style="border:none" class="br-bottom br-right br-top" style="text-align: end; font-weight: bold;" >{{advtamtb}}</td>
                            <td style="border:none" class="br-bottom br-right br-top" style="text-align: end; font-weight: bold;" >{{totaladvtamt}}</td>
                            <td style="border:none" class="br-bottom br-right br-top" style="text-align: end; font-weight: bold;" >{{totalbeforegst_amt}}</td>
                            <td style="border:none" class="br-bottom br-right br-top" style="text-align: end; font-weight: bold;">{{totalgst}}</td>
                            <td style="border:none" class="br-bottom br-right br-top" style="text-align: end; font-weight: bold;">{{netadvtamt}}</td>
                            <td style="border:none" class="br-bottom br-right br-top" style="text-align: end; font-weight: bold;">{{jamatotalb}}</td>
                            <td style="border:none" class="br-bottom br-right br-top" style="text-align: end; font-weight: bold;">{{netadvtamt - jamatotal}}</td>
                        </tr>
                    </tfoot>  
                </table>
               
            </div>
        </div>
        <div *ngIf="type=='Off_Represantative very Briefly'">
            <div id="print-section" style="margin-top: 50px;">
                <table class="table table-bordered">
                    <thead>
                        <tr>
                            <th colspan="2" style="border:none" class="br-bottom br-right br-top">हिंदुस्तान प्रकाशन संस्था - विवेक साप्ताहिक  </th>
                            <th colspan="2" style="border:none" class="br-bottom br-right br-top">अंकाचे नाव : &nbsp; {{newissuedatepp}}  </th>

                            <th colspan="2" style="border:none" class="br-bottom br-right br-top">Off. Representive Very Briefly</th>
                            <th colspan="2"  style="border:none" class="br-bottom br-right br-top">अंकाचे कॅटेगरी : &nbsp;{{issue_cat}}</th> 
                            <!-- <th colspan="2">Advt. Outstanding Report</th> -->
                           
                            <th colspan="2" style="border:none" class="br-bottom br-right br-top"> Date : From {{ start_date | date: 'dd-MM-yyyy' }} - To {{ end_date | date: 'dd-MM-yyyy' }}</th>                   
                             <th colspan="1" style="border:none" class="br-bottom br-right br-top">दिनांक :{{today1}}</th>
                            <th colspan="1" style="border:none" class="br-bottom br-top">पृष्ठ क्र :1</th>
                            
                        </tr>
                        <ng-container >
                            <tr>

                            </tr>
                            <tr>
                                <th colspan="1" style="border:none" class="br-bottom br-right">Sr</th>
                                <!-- <th colspan="1" style="border:none" class="br-bottom br-right">Advt Name</th>
                                <th colspan="1" style="border:none;" class="br-bottom br-right">Client</th>
                                <th colspan="1" style="border:none" class="br-bottom br-right">Representative</th>
                                <th colspan="1" style="border:none" class="br-bottom br-right">Advt. Size</th> -->
                                <th colspan="4" style="border:none" class="br-bottom br-right">Name</th>                                
                                <th colspan="1" style="border:none" class="br-bottom br-right">Advt Amt</th>                                
                                <th colspan="1" style="border:none" class="br-bottom br-right" >Net Amt</th>
                                <th colspan="1" style="border:none" class="br-bottom br-right" >Before GST Amt</th>
                                <th colspan="1" style="border:none" class="br-bottom br-right" >GST Amt</th>
                                <th colspan="1" style="border:none"  class="br-bottom br-right" >Total Amt</th>
                                <th colspan="1" style="border:none"  class="br-bottom br-right" >Receive Amt</th>
                                <th colspan="1" style="border:none"  class="br-bottom br-right" >Pending Amt</th>
                            </tr> 
                        </ng-container>
                          
                    </thead>
                    <!-- *ngFor="let data of OutstandingInDetailFormBeans; let i = index" -->
                    <tbody >
                        <ng-container *ngFor="let item2 of inDetailsRegReportbtoff; let i = index">
                           <tr>
                            <td colspan="2" style="border:none" class=" br-right br-top br-bottom">Off-Represantative</td>
                            <td colspan="3" style="border:none" class=" br-right br-top br-bottom">{{item2.off_rep_name}}</td>
                            <td colspan="7" style="border:none" class=" br-right br-top br-bottom"></td>

                           </tr> 
                           <ng-container *ngFor="let item1 of item2.rep_data; let i = index">
                            <tr >
                            <td colspan="2" style="border:none" class=" br-right br-top br-bottom">Represantative</td>
                            <td colspan="1" style="border:none" class=" br-right br-top br-bottom">{{item1.rep_name}}</td>
                            <td colspan="1" style="border:none" class=" br-right br-top br-bottom">Mobile Number</td>
                            <td colspan="1" style="border:none" class=" br-right br-top br-bottom">{{item1.rep_mob_no}}</td>
                            <td colspan="7" style="border:none" class=" br-right br-top br-bottom"></td>
                            </tr>

                            <!-- <ng-container *ngFor="let item of item1.advt_data; let i = index">
                                <tr >
                                    <td colspan="1" style="border:none; font-weight: bold;" class=" br-right">{{item.advtsizeno}}</td>
                                    <td colspan="1" style="border:none" class=" br-right">{{item.advt_name}}</td>
                                    <td colspan="1" style="border:none" class=" br-right">{{item.client}}</td>
                                    <td colspan="1" style="border:none" class=" br-right">{{item.rep_name}}</td>
                                    <td colspan="1" style="border:none" class=" br-right">{{item.advtsize}}</td>
                                    <td colspan="1" style="border:none; text-align: end;" class=" br-right">{{item.advt_amt}}</td>
                                    <td colspan="1" style="border:none; text-align: end;" class=" br-right">{{item.total_amount}}</td>
                                    <td colspan="1" style="border:none; text-align: end;" class=" br-right">{{item.gst}}</td>
                                    <td colspan="1" style="border:none; text-align: end;" class=" br-right">{{item.amount}}</td>
                                    <td colspan="1" style="border:none; text-align: end;" class=" br-right">{{item.pymt_rcv_amt}}</td>
                                    <td colspan="1" style="border:none; text-align: end;" class=" br-right">{{item.remaingamt}}</td> 
                                </tr>
                            </ng-container> -->
                            <!-- <tr>
                            <td style="border:none" class="br-bottom br-right br-top" style=" font-weight: bold;" colspan="4">Total</td>
                            <td colspan="1" style="border:none; text-align: end;" class=" br-right br-bottom br-top"> {{item1.total_advt_amount}}</td>
                            <td colspan="1" style="border:none; text-align: end;" class=" br-right br-bottom br-top"> {{item1.total_discounted_amt}}</td>
                            <td colspan="1" style="border:none; text-align: end;" class=" br-right br-bottom br-top"> {{item1.rep_name}}</td>
                            <td colspan="1" style="border:none; text-align: end;" class=" br-right br-bottom br-top"> {{item1.total_amt}}</td>
                            <td colspan="1" style="border:none; text-align: end;" class=" br-right br-bottom br-top"> </td>
                            <td colspan="1" style="border:none; text-align: end;" class=" br-right br-bottom br-top"> {{item1.total_rcv}}</td>
                            <td colspan="1" style="border:none; text-align: end;" class=" br-right br-bottom br-top"> {{item1.total_pending}}</td>
                            </tr> -->
                            <tr>
                                <td style="border:none" class="br-bottom br-right br-top" style=" font-weight: bold;">{{item1.advt_data.length}}</td>
                                <td style="border:none" class="br-bottom br-right br-top" style="text-align: center; font-weight: bold;" colspan="4">एकूण</td>
                                <td style="border:none" class="br-bottom br-right br-top" style="text-align: end; font-weight: bold;" >{{item1.total_advt_amount}}</td>
                                <td style="border:none" class="br-bottom br-right br-top" style="text-align: end; font-weight: bold;" >{{item1.total_discounted_amt}}</td>
                                <td style="border:none" class="br-bottom br-right br-top" style="text-align: end; font-weight: bold;">{{item1.total_before_gst}}</td>
                                <td style="border:none" class="br-bottom br-right br-top" style="text-align: end; font-weight: bold;">{{item1.total_gst}}</td>
                                <td style="border:none" class="br-bottom br-right br-top" style="text-align: end; font-weight: bold;">{{item1.total_amt}}</td>
                                <td style="border:none" class="br-bottom br-right br-top" style="text-align: end; font-weight: bold;">{{item1.pymt_rcv_amt}}</td>
                                <td style="border:none" class="br-bottom br-right br-top" style="text-align: end; font-weight: bold;">{{item1.total_pending}}</td>
                            </tr>
                           </ng-container>
                        </ng-container>
                        <tr>
                    </tr>
                    </tbody>
        
                    <tfoot>
                        <!-- 18 -->
                       
                        <tr>
                            <td style="border:none" class="br-bottom br-right br-top" style=" font-weight: bold;">{{inDetailsRegReportbtoff.length}}</td>
                            <td style="border:none" class="br-bottom br-right br-top" style="text-align: center; font-weight: bold;" colspan="4">एकूण</td>
                            <td style="border:none" class="br-bottom br-right br-top" style="text-align: end; font-weight: bold;" >{{advtamtb}}</td>
                            <td style="border:none" class="br-bottom br-right br-top" style="text-align: end; font-weight: bold;" >{{totaladvtamt}}</td>
                            <td style="border:none" class="br-bottom br-right br-top" style="text-align: end; font-weight: bold;" >{{totalbeforegst_amt}}</td>
                            <td style="border:none" class="br-bottom br-right br-top" style="text-align: end; font-weight: bold;">{{totalgst}}</td>
                            <td style="border:none" class="br-bottom br-right br-top" style="text-align: end; font-weight: bold;">{{netadvtamt}}</td>
                            <td style="border:none" class="br-bottom br-right br-top" style="text-align: end; font-weight: bold;">{{jamatotalb}}</td>
                            <td style="border:none" class="br-bottom br-right br-top" style="text-align: end; font-weight: bold;">{{netadvtamt - jamatotal}}</td>
                        </tr>
                    </tfoot>  
                </table>
               
            </div>
        </div>

        <!-- datewise details -->
        <div *ngIf="type=='Datewise Indetails'">
            <div id="print-section" style="margin-top: 50px;">
                <table class="table table-bordered">
                    <thead>
                        <tr>
                            <th colspan="3" style="border:none" class="br-bottom br-right br-top">हिंदुस्तान प्रकाशन संस्था - विवेक साप्ताहिक  </th>
                            <th colspan="3" style="border:none" class="br-bottom br-right br-top">अंकाचे नाव : &nbsp; {{newissuedatepp}}  </th>

                            <th colspan="3" style="border:none" class="br-bottom br-right br-top">Datewise Register Report - In Details</th>
                            <th colspan="2"  style="border:none" class="br-bottom br-right br-top">अंकाचे कॅटेगरी : &nbsp;{{issue_cat}}</th> 
                            <!-- <th colspan="2">Advt. Outstanding Report</th> -->
                           
                            <th colspan="4" style="border:none" class="br-bottom br-right br-top"> Date : From {{ start_date | date: 'dd-MM-yyyy' }} - To {{ end_date | date: 'dd-MM-yyyy' }}</th>                   
                             <th colspan="3" style="border:none" class="br-bottom br-right br-top">दिनांक :{{today1}}</th>
                            <th colspan="3" style="border:none" class="br-bottom br-top">पृष्ठ क्र :1</th>
                            
                        </tr>
                      
                        <tr>
                            <th colspan="1" style="border:none" class="br-bottom br-right">Sr</th>
                            <!-- <th colspan="1" style="border:none" class="br-bottom br-right">Issue name</th> -->
                            <th colspan="1" style="border:none" class="br-bottom br-right">Booking Date</th>
                            <th colspan="1" style="border:none" class="br-bottom br-right">Booking No</th>
                            <th colspan="1" style="border:none" class="br-bottom br-right">Advertiser Name</th>
                            <th colspan="1" style="border:none" class="br-bottom br-right">Customer Name</th>
                            <th colspan="1" style="border:none" class="br-bottom br-right">Represntive Name</th>
                            <th colspan="1" style="border:none" class="br-bottom br-right">Mobile Number</th>
                            <th colspan="1" style="border:none" class="br-bottom br-right">Office Rep Name</th>
                            <th colspan="1" style="border:none"  class="br-bottom br-right">RO Number</th>
                            <th colspan="1" style="border:none" class="br-bottom br-right">Instruction</th>
                            <th colspan="1" style="border:none" class="br-bottom br-right">Advt. Size</th>
                            
                            <th colspan="1" style="border:none" class="br-bottom br-right" style="text-align: end;">Advt Amt</th>
                            <th colspan="1" style="border:none" class="br-bottom br-right" style="text-align: end;">Disc Amt</th>
                            <th colspan="1" style="border:none" class="br-bottom br-right" style="text-align: end;">Net Amt</th>
                            <th colspan="1" style="border:none" class="br-bottom br-right" style="text-align: end;">Before GST Amt</th>
                            <th colspan="1" style="border:none" class="br-bottom br-right" style="text-align: end;">GST</th>
                            <th colspan="1" style="border:none"  class="br-bottom br-right" style="text-align: end;">Total Amt</th>
                            <th colspan="1" style="border:none" class="br-bottom br-right" style="text-align: end;">Receipt Number & Date</th>
                            <th colspan="1" style="border:none" class="br-bottom br-right" style="text-align: end;">जमा बाकी</th>
                            <th colspan="1" style="border:none" class="br-bottom" style="text-align: end;">येणे बाकी</th>
                        </tr>   
                    </thead>
                    <!-- *ngFor="let data of OutstandingInDetailFormBeans; let i = index" -->
                    <tbody>
                    <ng-container  *ngFor="let item1 of inDetailsRegReportdatewise; let i = index">
                    <tr>
                        <td colspan="3" style="border:none" class=" br-right br-bottom br-top">Booking Date : {{item1.booking_date}}</td>
                        <td colspan="2" style="border:none" class=" br-right br-bottom br-top"></td>
                        <td colspan="15" style="border:none" class=" br-right br-bottom br-top"></td>

                    </tr>
                        <ng-container  *ngFor="let item of item1.advt_data; let i = index">
                            <tr>
                            <td colspan="1" style="border:none; font-weight: bold;" class=" br-right">{{item.advtsizeno}}</td>
                            <!-- <td colspan="1" style="border:none" class=" br-right">{{item.issuename}}</td> -->
                            <td colspan="1" style="border:none" class=" br-right">{{item.booking_date}}</td>
                            <td colspan="1" style="border:none" class=" br-right">{{item.booking_no}}</td>
                            <td colspan="1" style="border:none" class=" br-right">{{item.advt_name}}</td>
                            <td colspan="1" style="border:none" class=" br-right">{{item.cust_name}}</td>
                            <td colspan="1" style="border:none" class=" br-right">{{item.rep_name}}</td>
                            <td colspan="1" style="border:none" class=" br-right">{{item.rep_mob_no}}</td>
                            <td colspan="1" style="border:none" class=" br-right">{{item.off_rep_name}}</td>
                            <td colspan="1" style="border:none" class=" br-right">{{item.ro_no}}</td>
                            <td colspan="1" style="border:none" class=" br-right">{{item.instruction}}</td>
                            <td colspan="1" style="border:none" class=" br-right">{{item.advtsize}}</td>
                            <td colspan="1" style="border:none; text-align: end;" class=" br-right">{{item.advt_amt}}</td>
                            <td colspan="1" style="border:none; text-align: end;" class=" br-right">{{item.disc_amt}}</td>
                            <td colspan="1" style="border:none; text-align: end;" class=" br-right">{{item.amount}}</td>
                            <td colspan="1" style="border:none; text-align: end;" class=" br-right">{{item.beforegst_amt}}</td>
                            <td colspan="1" style="border:none; text-align: end;" class=" br-right">{{item.totalgst}} </td>
                            <td colspan="1" style="border:none; text-align: end;" class=" br-right">{{item.total_amount}}</td>
                            <td colspan="1" style="border:none" class=" br-right">{{item.rep_rcpt_no}} {{item.rcpt_date}}</td>
                            <td colspan="1" style="border:none; text-align: end;" class=" br-right">{{item.pymt_rcv_amt}}</td>
                            <!-- <td colspan="1" style="border:none; text-align: end;" class=" br-right">{{item.amount - item.part_pymt_amt}}</td>      -->
                            <td colspan="1" style="border:none; text-align: end;" class=" br-right">{{item.remaining_amt}}</td>     
                            </tr>
                              
                          </ng-container >
                          <tr>
                            <td style="border:none" class="br-bottom br-right br-top" style=" font-weight: bold;" colspan="11">Total</td>
                            <td colspan="1" style="border:none; text-align: end;" class=" br-right br-bottom br-top"> {{item1.total_advt_amount}}</td>
                            <td colspan="1" style="border:none; text-align: end;" class=" br-right br-bottom br-top"> {{item1.total_discount}}</td>
                            <td colspan="1" style="border:none; text-align: end;" class=" br-right br-bottom br-top"> {{item1.total_discounted_amt}}</td>
                            <td colspan="1" style="border:none; text-align: end;" class=" br-right br-bottom br-top"> {{item1.total_before_gst}}</td>
                            <td colspan="1" style="border:none; text-align: end;" class=" br-right br-bottom br-top"> {{item1.total_gst}}</td>
                            <td colspan="1" style="border:none; text-align: end;" class=" br-right br-bottom br-top"> {{item1.total_amt}}</td>
                            <td colspan="1" style="border:none; text-align: end;" class=" br-right br-bottom br-top"> </td>
                            <td colspan="1" style="border:none; text-align: end;" class=" br-right br-bottom br-top"> {{item1.total_rcv}}</td>
                            <td colspan="1" style="border:none; text-align: end;" class=" br-right br-bottom br-top"> {{item1.total_pending}}</td>
                        </tr>
                    </ng-container>
                    </tbody>
        
                    <tfoot>
                        <!-- 18 -->
                        <tr>
                            <td style="border:none" class="br-bottom br-right br-top" style=" font-weight: bold;">{{inDetailsRegReportdatewise.length}}</td>
                            <td style="border:none" class="br-bottom br-right br-top" style="text-align: center; font-weight: bold;" colspan="10">एकूण</td>
                            <td style="border:none" class="br-bottom br-right br-top" style="text-align: end; font-weight: bold;" >{{advtamt}}</td>
                            <td style="border:none" class="br-bottom br-right br-top" style="text-align: end; font-weight: bold;" >{{totaldisc_total}}</td>
                            <td style="border:none" class="br-bottom br-right br-top" style="text-align: end; font-weight: bold;" >{{totaladvtamt}}</td>
                            <td style="border:none" class="br-bottom br-right br-top" style="text-align: end; font-weight: bold;" >{{totalbeforegst_amt}}</td>
                            <td style="border:none" class="br-bottom br-right br-top" style="text-align: end; font-weight: bold;">{{totalgst}}</td>
                            <td style="border:none" class="br-bottom br-right br-top" style="text-align: end; font-weight: bold;">{{netadvtamt}}</td>
                            <td style="border:none" class="br-bottom br-right br-top" style="text-align: end; font-weight: bold;"></td>
                            <td style="border:none" class="br-bottom br-right br-top" style="text-align: end; font-weight: bold;">{{jamatotal}}</td>

                            <td style="border:none" class="br-bottom br-right br-top" style="text-align: end; font-weight: bold;">{{netadvtamt - jamatotal}}</td>
                        </tr>
                    </tfoot>  
                </table>
               
            </div>
        </div>
        <div *ngIf="type=='Datewise Briefly'">
            <div id="print-section" style="margin-top: 50px;">
                <table class="table table-bordered">
                    <thead>
                        <tr>
                            <th colspan="2" style="border:none" class="br-bottom br-right br-top">हिंदुस्तान प्रकाशन संस्था - विवेक साप्ताहिक  </th>
                            <th colspan="2" style="border:none" class="br-bottom br-right br-top">अंकाचे नाव : &nbsp; {{newissuedatepp}}  </th>

                            <th colspan="2" style="border:none" class="br-bottom br-right br-top">Datewise Briefly</th>
                            <th colspan="2"  style="border:none" class="br-bottom br-right br-top">अंकाचे कॅटेगरी : &nbsp;{{issue_cat}}</th> 
                            <!-- <th colspan="2">Advt. Outstanding Report</th> -->
                           
                            <th colspan="2" style="border:none" class="br-bottom br-right br-top"> Date : From {{ start_date | date: 'dd-MM-yyyy' }} - To {{ end_date | date: 'dd-MM-yyyy' }}</th>                   
                             <th colspan="1" style="border:none" class="br-bottom br-right br-top">दिनांक :{{today1}}</th>
                            <th colspan="1" style="border:none" class="br-bottom br-top">पृष्ठ क्र :1</th>
                            
                        </tr>
                        <ng-container >
                            <tr>

                            </tr>
                            <tr>
                                <th colspan="1" style="border:none" class="br-bottom br-right">Sr</th>
                                <th colspan="1" style="border:none" class="br-bottom br-right">Advt Name</th>
                                <th colspan="1" style="border:none;" class="br-bottom br-right">Client</th>
                                <th colspan="1" style="border:none" class="br-bottom br-right">Representative</th>
                                <th colspan="1" style="border:none" class="br-bottom br-right">Advt. Size</th>
                                <th colspan="1" style="border:none" class="br-bottom br-right">Advt Amt</th>                                
                                <th colspan="1" style="border:none" class="br-bottom br-right" >Net Amt</th>
                                <th colspan="1" style="border:none" class="br-bottom br-right" >Before Gst</th>
                                <th colspan="1" style="border:none" class="br-bottom br-right" >GST Amt</th>
                                <th colspan="1" style="border:none"  class="br-bottom br-right" >Total Amt</th>
                                <th colspan="1" style="border:none"  class="br-bottom br-right" >Receive Amt</th>
                                <th colspan="1" style="border:none"  class="br-bottom br-right" >Pending Amt</th>
                            </tr> 
                        </ng-container>
                          
                    </thead>
                    <tbody>
                        <ng-container  *ngFor="let item1 of inDetailsRegReportdatewise; let i = index">
                        <tr>
                            <td colspan="3" style="border:none" class=" br-right br-bottom br-top">Booking Date : {{item1.booking_date}}</td>
                            <td colspan="3" style="border:none" class=" br-right br-bottom br-top"></td>
                            <td colspan="6" style="border:none" class=" br-right br-bottom br-top"></td>
    
                        </tr>
                            <ng-container  *ngFor="let item of item1.advt_data; let i = index">
                                <tr>
                                    <td colspan="1" style="border:none; font-weight: bold;" class=" br-right">{{item.advtsizeno}}</td>
                                    <td colspan="1" style="border:none" class=" br-right">{{item.advt_name}}</td>
                                    <td colspan="1" style="border:none" class=" br-right">{{item.client}}</td>
                                    <td colspan="1" style="border:none" class=" br-right">{{item.rep_name}}</td>
                                    <td colspan="1" style="border:none" class=" br-right">{{item.advtsize}}</td>
                                    <td colspan="1" style="border:none; text-align: end;" class=" br-right">{{item.advt_amt}}</td>
                                    <td colspan="1" style="border:none; text-align: end;" class=" br-right">{{item.amount}}</td>
                                    <td colspan="1" style="border:none; text-align: end;" class=" br-right">{{item.beforegst_amt}}</td>
                                    <td colspan="1" style="border:none; text-align: end;" class=" br-right">{{item.gst}}</td>
                                    <td colspan="1" style="border:none; text-align: end;" class=" br-right">{{item.total_amount}}</td>
                                    <td colspan="1" style="border:none; text-align: end;" class=" br-right">{{item.pymt_rcv_amt}}</td>
                                    <td colspan="1" style="border:none; text-align: end;" class=" br-right">{{item.remaining_amt}}</td>  
                                  </tr>
                              </ng-container >
                              <tr>
                                <td style="border:none" class="br-bottom br-right br-top" style=" font-weight: bold;">{{item1.advt_data.length}}</td>
                                <td style="border:none" class="br-bottom br-right br-top" style="text-align: center; font-weight: bold;" colspan="4">एकूण</td>
                                <td style="border:none" class="br-bottom br-right br-top" style="text-align: end; font-weight: bold;" >{{item1.total_advt_amount}}</td>
                                <td style="border:none" class="br-bottom br-right br-top" style="text-align: end; font-weight: bold;" >{{item1.total_discounted_amt}}</td>
                                <td style="border:none" class="br-bottom br-right br-top" style="text-align: end; font-weight: bold;" >{{item1.total_before_gst}}</td>
                                <td style="border:none" class="br-bottom br-right br-top" style="text-align: end; font-weight: bold;">{{item1.total_gst}}</td>
                                <td style="border:none" class="br-bottom br-right br-top" style="text-align: end; font-weight: bold;">{{item1.total_amt}}</td>
                                <td style="border:none" class="br-bottom br-right br-top" style="text-align: end; font-weight: bold;">{{item1.total_rcv}}</td>
                                <td style="border:none" class="br-bottom br-right br-top" style="text-align: end; font-weight: bold;">{{item1.total_pending}}</td>
                            </tr>
                        </ng-container>
                        </tbody>
        
                    <tfoot>
                        <!-- 18 -->
                        <tr>
                            <td style="border:none" class="br-bottom br-right br-top" style=" font-weight: bold;">{{inDetailsRegReportdatewise.length}}</td>
                            <td style="border:none" class="br-bottom br-right br-top" style="text-align: center; font-weight: bold;" colspan="4">एकूण</td>
                            <td style="border:none" class="br-bottom br-right br-top" style="text-align: end; font-weight: bold;" >{{advtamtb}}</td>
                            <td style="border:none" class="br-bottom br-right br-top" style="text-align: end; font-weight: bold;" >{{totaladvtamt}}</td>
                            <td style="border:none" class="br-bottom br-right br-top" style="text-align: end; font-weight: bold;" >{{totalbeforegst_amt}}</td>
                            <td style="border:none" class="br-bottom br-right br-top" style="text-align: end; font-weight: bold;">{{totalgst}}</td>
                            <td style="border:none" class="br-bottom br-right br-top" style="text-align: end; font-weight: bold;">{{netadvtamt}}</td>
                            <td style="border:none" class="br-bottom br-right br-top" style="text-align: end; font-weight: bold;">{{jamatotalb}}</td>
                            <td style="border:none" class="br-bottom br-right br-top" style="text-align: end; font-weight: bold;">{{netadvtamt - jamatotal}}</td>
                        </tr>
                    </tfoot>  
                </table>
               
            </div>
        </div>
        <div *ngIf="type=='datewise very Briefly'">
            <div id="print-section" style="margin-top: 50px;">
                <table class="table table-bordered">
                    <thead>
                        <tr>
                            <th colspan="2" style="border:none" class="br-bottom br-right br-top">हिंदुस्तान प्रकाशन संस्था - विवेक साप्ताहिक  </th>
                            <th colspan="2" style="border:none" class="br-bottom br-right br-top">अंकाचे नाव : &nbsp; {{newissuedatepp}}  </th>

                            <th colspan="2" style="border:none" class="br-bottom br-right br-top">Datewise Very Briefly</th>
                            <th colspan="2"  style="border:none" class="br-bottom br-right br-top">अंकाचे कॅटेगरी : &nbsp;{{issue_cat}}</th> 
                            <!-- <th colspan="2">Advt. Outstanding Report</th> -->
                           
                            <th colspan="2" style="border:none" class="br-bottom br-right br-top"> Date : From {{ start_date | date: 'dd-MM-yyyy' }} - To {{ end_date | date: 'dd-MM-yyyy' }}</th>                   
                             <th colspan="1" style="border:none" class="br-bottom br-right br-top">दिनांक :{{today1}}</th>
                            <th colspan="1" style="border:none" class="br-bottom br-top">पृष्ठ क्र :1</th>
                            
                        </tr>
                        <ng-container >
                            <tr>

                            </tr>
                            <tr>
                                <th colspan="1" style="border:none" class="br-bottom br-right">Sr</th>
                                <th colspan="1" style="border:none" class="br-bottom br-right">Advt Name</th>
                                <th colspan="1" style="border:none;" class="br-bottom br-right">Client</th>
                                <th colspan="1" style="border:none" class="br-bottom br-right">Representative</th>
                                <th colspan="1" style="border:none" class="br-bottom br-right">Advt. Size</th>
                                <th colspan="1" style="border:none" class="br-bottom br-right">Advt Amt</th>                                
                                <th colspan="1" style="border:none" class="br-bottom br-right" >Net Amt</th>
                                <th colspan="1" style="border:none" class="br-bottom br-right" >Before GST Amt</th>
                                <th colspan="1" style="border:none" class="br-bottom br-right" >GST Amt</th>
                                <th colspan="1" style="border:none"  class="br-bottom br-right" >Total Amt</th>
                                <th colspan="1" style="border:none"  class="br-bottom br-right" >Receive Amt</th>
                                <th colspan="1" style="border:none"  class="br-bottom br-right" >Pending Amt</th>
                            </tr> 
                        </ng-container>
                          
                    </thead>
                    <tbody>
                        <ng-container  *ngFor="let item1 of inDetailsRegReportdatewise; let i = index">
                        <!-- <tr>
                            <td colspan="3" style="border:none" class=" br-right br-bottom br-top">Represantative Name : {{item1.rep_name}}</td>
                            <td colspan="2" style="border:none" class=" br-right br-bottom br-top">Mobile Number : {{item1.rep_mob_no}}</td>
                            <td colspan="6" style="border:none" class=" br-right br-bottom br-top"></td>
    
                        </tr> -->
                            <!-- <ng-container  *ngFor="let item of item1.advt_data; let i = index">
                                <tr>
                                    <td colspan="1" style="border:none; font-weight: bold;" class=" br-right">{{item.advtsizeno}}</td>
                                    <td colspan="1" style="border:none" class=" br-right">{{item.advt_name}}</td>
                                    <td colspan="1" style="border:none" class=" br-right">{{item.client}}</td>
                                    <td colspan="1" style="border:none" class=" br-right">{{item.rep_name}}</td>
                                    <td colspan="1" style="border:none" class=" br-right">{{item.advtsize}}</td>
                                    <td colspan="1" style="border:none; text-align: end;" class=" br-right">{{item.advt_amt}}</td>
                                    <td colspan="1" style="border:none; text-align: end;" class=" br-right">{{item.amount}}</td>
                                    <td colspan="1" style="border:none; text-align: end;" class=" br-right">{{item.gst}}</td>
                                    <td colspan="1" style="border:none; text-align: end;" class=" br-right">{{item.total_amount}}</td>
                                    <td colspan="1" style="border:none; text-align: end;" class=" br-right">{{item.pymt_rcv_amt}}</td>
                                    <td colspan="1" style="border:none; text-align: end;" class=" br-right">{{item.remaining_amt}}</td>  
                                  </tr>
                              </ng-container > -->
                              
                                <tr>
                                    <td style="border:none" class="br-bottom br-right br-top" style=" font-weight: bold;">{{item1.advt_data.length}}</td>
                                    <td style="border:none" class="br-bottom br-right br-top" style="text-align: start; font-weight: bold;" colspan="4">{{item1.booking_date}}</td>
                                    <td style="border:none" class="br-bottom br-right br-top" style="text-align: end; font-weight: bold;" >{{item1.total_advt_amount}}</td>
                                    <td style="border:none" class="br-bottom br-right br-top" style="text-align: end; font-weight: bold;" >{{item1.total_discounted_amt}}</td>
                                    <td style="border:none" class="br-bottom br-right br-top" style="text-align: end; font-weight: bold;">{{item1.total_before_gst}}</td>
                                    <td style="border:none" class="br-bottom br-right br-top" style="text-align: end; font-weight: bold;">{{item1.total_gst}}</td>
                                    <td style="border:none" class="br-bottom br-right br-top" style="text-align: end; font-weight: bold;">{{item1.total_amt}}</td>
                                    <td style="border:none" class="br-bottom br-right br-top" style="text-align: end; font-weight: bold;">{{item1.total_rcv}}</td>
                                    <td style="border:none" class="br-bottom br-right br-top" style="text-align: end; font-weight: bold;">{{item1.total_pending}}</td>
                                </tr>
                              
                        </ng-container>
                        </tbody>
        
                    <tfoot>
                        <!-- 18 -->
                        <tr>
                            <td style="border:none" class="br-bottom br-right br-top" style=" font-weight: bold;">{{inDetailsRegReportdatewise.length}}</td>
                            <td style="border:none" class="br-bottom br-right br-top" style="text-align: center; font-weight: bold;" colspan="4">एकूण</td>
                            <td style="border:none" class="br-bottom br-right br-top" style="text-align: end; font-weight: bold;" >{{advtamtb}}</td>
                            <td style="border:none" class="br-bottom br-right br-top" style="text-align: end; font-weight: bold;" >{{totaladvtamt}}</td>
                            <td style="border:none" class="br-bottom br-right br-top" style="text-align: end; font-weight: bold;" >{{totalbeforegst_amt}}</td>
                            <td style="border:none" class="br-bottom br-right br-top" style="text-align: end; font-weight: bold;">{{totalgst}}</td>
                            <td style="border:none" class="br-bottom br-right br-top" style="text-align: end; font-weight: bold;">{{netadvtamt}}</td>
                            <td style="border:none" class="br-bottom br-right br-top" style="text-align: end; font-weight: bold;">{{jamatotalb}}</td>
                            <td style="border:none" class="br-bottom br-right br-top" style="text-align: end; font-weight: bold;">{{netadvtamt - jamatotal}}</td>
                        </tr>
                    </tfoot>  
                </table>
               
            </div>
        </div>
    </div>

</div>
<div *ngIf="isLoading" class="main-loading">
    <span class="loader"></span>
    <p>Loading...</p>
</div>