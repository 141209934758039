import { formatDate } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { DigisubservicesService } from 'src/app/services/digisubservices.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

interface subscribers {
  value: number,
  viewValue: string
}
@Component({
  selector: 'app-add-new-magazine',
  templateUrl: './add-new-magazine.component.html',
  styleUrls: ['./add-new-magazine.component.scss']
})
export class AddNewMagazineComponent implements OnInit {

  form: FormGroup
  getAllRepList = [];
  mag = [];
  getMagazine
  RepCredit = 0
  getRepName
  userid = 0
  getoldrepid;
  needtoupdateCustList = false;
  subid = 0

  constructor(public service: DigisubservicesService, public fb: FormBuilder,
    private dialogRef : MatDialogRef<AddNewMagazineComponent> , 
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.form = fb.group({
      ds_mag1: [''],
      ds_custmobile: [''],
      ds_mag1edition: [''],
      ds_repid: ['', ''],
      ds_mag1startdt: [''],
      ds_totamt: [''],
      ds_mag1enddt: [''],
      paymethod: [''],
      chequeno: [''],
      year: ['']
    });
  }

  getMagNames = []
  getMagYears = []
  ngOnInit(): void {

    this.userid = parseInt(sessionStorage.getItem('id'))
    this.mobileNumber = parseInt(this.data['mobile'])
    this.getsubmags({target:{value:this.mobileNumber}})
    this.form.get("ds_custmobile").setValue(this.mobileNumber)
    this.service.get_AllRepresentative_List().subscribe(data => {
      console.log(data)
      this.getAllRepList = data
    })

    this.service.getRepcredit(this.userid).subscribe(res => {
      //console.log(res)
      this.RepCredit = res;
    })

    //change for OEM
    this.service.getMag().subscribe(res => {
      console.log(res)
      this.mag = [];

      for (let i = 0; i < Object.keys(res).length; i++) {
        this.mag.push({
          name: res[i]['name'],
          Print: res[i]['print'],
          digital: res[i]['digital'],
          printdigital: res[i]['printdigital'],
          year: res[i]['year'],
        })
      }

      for (let index = 0; index < Object.keys(res).length; index++) {

        if (index == 0) {
          this.getMagNames.push(res[index]['name'])
          this.getMagYears.push(res[index]['year'])
        } else {
          if (this.getMagNames.find(a => a == res[index]['name']) == undefined) {
            this.getMagNames.push(res[index]['name'])
          }
          if (this.getMagYears.find(a => a == res[index]['year']) == undefined) {
            this.getMagYears.push(res[index]['year'])
          }
        }
      }
      //name.push(amount) 

    })
  }

  subscribersName
  getSubAllDetails = {address: "",
  city: "",
  cust_id: "",
  name: "",
  pincode: "",
  state: "",
  taluka: ""}
  validatesubmobileno(mobile) {
    this.service.checksubisexist(parseInt(mobile)).subscribe(a => {
      console.log(a)
      if (Object.keys(a).length > 0) {
        this.getSubAllDetails = a[0]
        this.subscribersName = a[0]['name']
      }else
      {
        window.alert("No Valid Subscriber found!")
      }

    })
  }

  disableOtherFields = true
  startLoading = false
  mobileNumber
  getsubmags(event) {
    console.log(event)
    this.startLoading = true;
    this.mobileNumber = event.target.value;
    this.validatesubmobileno(parseInt(this.mobileNumber))

    this.service.getSubmagByIdorNumber(parseInt(this.mobileNumber)).subscribe(res => {
      //console.log(res)
      this.startLoading = false
      this.getMagazine = res;
      if (Object.keys(res).length > 0) {
        this.disableOtherFields = false;
      }
      else {
        this.disableOtherFields = true;
      }
    })

    this.service.getcustrep(parseInt(this.mobileNumber)).subscribe(res => {

      this.getoldrepid = res[0]['rep_id']
      this.service.getRepbyid(this.getoldrepid).subscribe(res => {
        //console.log(res)
        this.getRepName = res[0]['name'];
      })
    })

    this.service.getSubprofile(parseInt(this.mobileNumber)).subscribe(sub => {

      this.subid = parseInt(sub[0]['subid']);
    })
  }

  edition
  getmagedition(event) {
    this.edition = event.target.value;
  }

  todayDateStringto

  getchangedYear(yearvalue, magname, magedit) {
    let year = parseInt(yearvalue);
    //console.log(year)
    let getmagenddate;
    let getmag = this.getMagazine.find(a => a["ds_mag1"] == magname && a["ds_mag1edition"] === magedit) //["ds_mag1enddt"]
    //console.log(getmag)
    if (getmag != undefined) {
      getmagenddate = getmag.ds_mag1enddt
    }
    else {
      getmagenddate = formatDate(new Date(), "yyyy-MM-dd", "en-US", "GMT+0530")
    }
    //console.log(new Date(getmagenddate))

    if (new Date(getmagenddate).getFullYear() < new Date().getFullYear()) {
      this.todayDateStringto = new Date();
    } else if (new Date(getmagenddate).getFullYear() >= new Date().getFullYear()) {
      this.todayDateStringto = new Date(getmagenddate);
    }

    //console.log(this.todayDateStringto)
    //console.log(formatDate(new Date(), "yyyy-MM-dd", "en-US", "GMT+0530"))
    if (year == null) {
      this.todayDateStringto.setFullYear(this.todayDateStringto.getFullYear() + 1)
      //console.log(this.todayDateStringto)
      return this.todayDateStringto
    }
    else {
      this.todayDateStringto.setFullYear(this.todayDateStringto.getFullYear() + year)
      //console.log(formatDate(this.todayDateStringto, "yyyy-MM-dd", "en-US", "GMT+0530"))
      return formatDate(this.todayDateStringto, "yyyy-MM-dd", "en-US", "GMT+0530")
    }

  }


  displayTotal = false
  totalmagamount = 0
  magname;
  getmagname(event) {
    this.magname = event.target.value;
  }

  getmagdetails(event) {
    const year = event.target.value;
    let todayDateStringto = new Date();
    todayDateStringto.setFullYear(todayDateStringto.getFullYear() + 1)
    //console.log(formatDate(todayDateStringto, "yyyy-MM-dd", "en-IN", "GMT+0530"))
    //console.log(value)
    for (let index = 0; index < Object.keys(this.mag).length; index++) {

      if (this.mag[index]['name'] == this.magname && parseInt(this.mag[index]['year']) == year) {
        this.displayTotal = true;
        this.form.get('ds_mag1').setValue(this.mag[index]['name'])
        this.form.get('ds_totamt').setValue(this.mag[index][this.edition])
        this.form.get('ds_mag1edition').setValue(this.edition)
        this.form.get('ds_mag1startdt').setValue(formatDate(new Date(), "yyyy-MM-dd", "en-IN", "GMT+0530"))
        this.form.get('ds_mag1enddt').setValue(this.getchangedYear(year, this.mag[index]['name'], this.edition))
        this.totalmagamount = parseInt(this.mag[index][this.edition])
      } else {
        this.displayTotal = false;
      }

    }

  }

  displayChequqField = false;
  getcheque(event) {
    const value = event.target.value;
    if (value == "cheque") {
      this.displayChequqField = true;
    }
    else {
      this.displayChequqField = false;
    }
    //console.log(value);

  }



  insertNewMag() {

    let id = this.userid;
    this.form.patchValue({ ds_repid: id });
    let mobile = parseInt(this.form.get('ds_custmobile').value);
    this.form.patchValue({ ds_custmobile: mobile });
    let paymethod = this.form.get('paymethod').value;
    console.log(paymethod)

    let newmag = {
      ds_custmobile: parseInt(this.form.value['ds_custmobile']),
      ds_repid: this.userid,
      ds_mag1: this.form.value['ds_mag1'],
      ds_mag1edition: this.form.value['ds_mag1edition'],
      ds_mag1enddt: this.form.value['ds_mag1enddt'],
      ds_totamt: parseInt(this.form.value['ds_totamt']),
      ds_mag1startdt: this.form.value['ds_mag1startdt']
    }

    let commamt = (this.totalmagamount * (parseInt(this.getSelectedRep['rep_comm']) / 100));

    let getmag = this.getMagazine.find(a => a["ds_mag1"] == newmag.ds_mag1 && a["ds_mag1edition"] === newmag.ds_mag1edition)
    let ds_new_id = 0
    if (getmag == undefined) {
      if (paymethod == "cash") {
        this.service.NewMagazine(newmag, "C").subscribe(res => {
          //console.log(res)
          ds_new_id = res['id']
          this.insertCommissionClose(mobile, commamt, ds_new_id)
        })
      } else if (paymethod == "cheque") {
        this.service.NewMagazine(newmag, "CH:" + this.form.value['chequeno']).subscribe(res => {
          //console.log(res)
          ds_new_id = res['id']
          this.insertCommissionClose(mobile, commamt, ds_new_id)
        })
      } else if (paymethod == "online") {
        this.service.NewMagazine(this.insertNewMag, "O").subscribe(res => {
          //console.log(res)
          ds_new_id = res['id']
          this.insertCommissionClose(mobile, commamt, ds_new_id)
        })
      }
      else {
        this.service.NewMagazine(newmag, "P").subscribe(res => {
          //console.log(res)
          ds_new_id = res['id']
          this.insertCommissionClose(mobile, commamt, ds_new_id)
        })
      }

      let msg = "Dear " + this.subscribersName + " (Sub.id : " + this.subid + ") , Your new subscription for " + newmag.ds_mag1 +
        " has been added. Thank You and Welcome in Vivek Parivar."
      // Dear {#var#} (Sub.id :{#var#}) , Your new subscription for {#var#} has been added. Thank You and Welcome in Vivek Parivar.
      let template = "VIVEK18"


      //let msg = "Dear "+this.form.get('name').value+" , Your new subscription for "+newmag.ds_mag1+
      //" has been added. Thank you for showing intrest in our products."
      this.service.sendSMS(mobile, msg, template).subscribe(a => { });


    }
    else {
      this.service.updateNewMag(mobile, newmag.ds_mag1, newmag.ds_mag1edition).subscribe(abc => {

        if (paymethod == "cash") {
          this.service.NewMagazine(newmag, "C").subscribe(res => {
            //console.log(res)
            ds_new_id = res['id']
            this.insertCommissionClose(mobile, commamt, ds_new_id)
          })
        } else if (paymethod == "cheque") {
          this.service.NewMagazine(newmag, "CH:" + this.form.value['chequeno']).subscribe(res => {
            //console.log(res)
            ds_new_id = res['id']
            this.insertCommissionClose(mobile, commamt, ds_new_id)
          })
        } else if (paymethod == "online") {
          this.service.NewMagazine(newmag, "O").subscribe(res => {
            //console.log(res)
            ds_new_id = res['id']
            this.insertCommissionClose(mobile, commamt, ds_new_id)
          })
        }
        else {
          this.service.NewMagazine(newmag, "P").subscribe(res => {
            //console.log(res)
            ds_new_id = res['id']
            this.insertCommissionClose(mobile, commamt, ds_new_id)
          })
        }

        let msg = "Dear " + this.subscribersName + " (Sub.id : " + this.subid + ") , Your subscription for " + newmag.ds_mag1 +
          " has been Renew. Thank you for showing interest in our Family. Regards, Hindustan Prakashan Sanstha"
        let template = "Vivek21"
        this.service.sendSMS(mobile, msg, template).subscribe(a => { });

      })
    }

    this.service.updateCreditLimit(parseInt(this.RepCredit[0]['ds_repid']), 0,
      parseInt(this.RepCredit[0]['ds_tempcredit']))
      .subscribe(res => {
        //console.log(res)
        ds_new_id = res['id']
        this.insertCommissionClose(mobile, commamt, ds_new_id)
      })


      setTimeout(() => {
        window.alert("Magazine Added ..!");
        this.form.reset();
        this.dialogRef.close()
            
      }, 1000);
    
  }


  insertCommissionClose(mobile, commamt, ds_id) {
    this.service.insertComissionLimit(this.userid, mobile, commamt, ds_id).subscribe(res => {
      //console.log(res)

      if (this.needtoupdateCustList) {
        this.service.updatecustrep(parseInt(this.getoldrepid), mobile).subscribe(a => {

          this.service.insertCustRepdata(mobile, this.userid).subscribe(res => {
            //console.log(res)
          })
        })
      }

      this.service.insertSubRenew(this.subid).subscribe(a => {

      })
    })
  }

  getSelectedRepId
  getSelectedRep
  SelectRepresentative(event) {
    console.log(event.target.value)
    this.getSelectedRepId = parseInt(event.target.value);
    this.getSelectedRep = this.getAllRepList.find(a => a['id'] == parseInt(this.getSelectedRepId))
    console.log(this.getSelectedRep)
    //this.form.get('rep_comm').setValue(parseInt(this.getSelectedRep['rep_comm']))

    if (parseInt(this.getoldrepid) != this.getSelectedRepId) {
      this.needtoupdateCustList = true;
    }

    this.userid = this.getSelectedRepId//parseInt(sessionStorage.getItem('id'))

    this.service.getRepcredit(this.userid).subscribe(res => {
      //console.log(res)
      this.RepCredit = res;
    })


  }

}
