import { Component, OnInit } from '@angular/core';
import { DigisubservicesService } from 'src/app/services/digisubservices.service';
import { formatDate } from '@angular/common';
import { Form, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';


interface tableDataSubscriber{
  'RepName' : string,
  'SubMobile' : string,
  'SubName' : string,
  'SubEmail' : string,
  'SubId' : number
}

interface subscribers{
  value : number,
  viewValue : string
}
@Component({
  selector: 'app-new-subscribers-data',
  templateUrl: './new-subscribers-data.component.html',
  styleUrls: ['./new-subscribers-data.component.scss']
})
export class NewSubscribersDataComponent implements OnInit {

  subscriberTableData : tableDataSubscriber[] = [];
  subscriberData : any[];
  todate;
  fromdate;

  subscribers : subscribers[] = [
    {value : 60, viewValue : "Deo Rajani"},
    /*{value : 61, viewValue : "Shridhar Gokhale"},
    {value : 62, viewValue : "Ramchandra Joshi"},
    {value : 63, viewValue : "Ramdas Joshi"},
    {value : 64, viewValue : "Sandeep Kadu"},
    {value : 65, viewValue : "Niyojita Kenjale"},
    {value : 66, viewValue : "Anant Kulkarni"},
    {value : 67, viewValue : "Madhuri Kulkarni"},
    {value : 68, viewValue : "Pramod Kulkarni"},
    {value : 69, viewValue : "Rajeev Kulkarni"},
    {value : 70, viewValue : "Shreepad Kulkarni"},
    {value : 71, viewValue : "Waman Kulkarni"},
    {value : 72, viewValue : "Pravin Lanjekar"},
    {value : 73, viewValue : "Rajendra Pharande"},
    {value : 74, viewValue : "Sunil Rajiwade"},
    {value : 75, viewValue : "Vaishali Ramdasi"},
    {value : 76, viewValue : "Mahesh Rane"},*/
  ];
 
  form : FormGroup;
  DailyBatchStatus: any[];
    selecteddate;
    tableHeaders = ['Header 1', 'Header 2', 'Header 3'];
  tableRows = [
    ['Cell', 'Cell', 'Cell'],
    ['Cell', 'Cell', 'Cell'],
    ['Cell', 'Cell', 'Cell']
  ];
  datalength;
  
 yourMessage = []
  dataLoaded = false;
  date1;
  frmtdate;


  constructor(private router : Router,public service : DigisubservicesService, private fb : FormBuilder) {
    this.form = fb.group({
      website : ['', '']
    });
   }

  ngOnInit(): void {
    if(sessionStorage.getItem("mobile") == null){
      this.router.navigate(['/']);
    }
    this.date1 = new FormControl(new Date());
    this.todate = new FormControl(new Date());
    this.fromdate = new FormControl(new Date());

    this.service.getPortalAllRepList().subscribe(rep=>{

      this.subscribers = [];
      for (let index = 0; index < Object.keys(rep).length; index++) {
        
        this.subscribers.push({
            value:parseInt(rep[index]['id']) , viewValue:rep[index]['name']
        })

      }
      
    })
  }

  selectDate(_value)
    {
        //console.log(_value)
        //console.log(formatDate(new Date(this.date1.value), "yyyy-MM-dd", "en-US", "GMT+0530") );
        if(_value == null)
        {
          this.subscriberTableData = [];
          this.datalength = Object.keys(this.subscriberTableData).length 
          this.frmtdate = formatDate(new Date(), "yyyy-MM-dd", "en-US", "GMT+0530")
        }
        else
        {
          this.subscriberTableData=[];
          this.datalength = Object.keys(this.subscriberTableData).length 
          this.frmtdate = formatDate(new Date(this.date1.value), "yyyy-MM-dd", "en-US", "GMT+0530")
        }

        
    }

   /* submit()
    {
      let id = parseInt(this.form.get('website').value);
      //console.log("Id : ",id,"Date : ",this.frmtdate);
        this.service.getNewSubscibersDataDateWise(id, this.frmtdate).subscribe((res : any[]) => {
          this.DailyBatchStatus = res;
          //console.log(this.DailyBatchStatus);
          
          for (let index = 0; index < Object.keys(this.DailyBatchStatus).length; index++) {
            
            this.subscriberTableData.push({
              'RepId': this.DailyBatchStatus[index]['repid'],
              'RepName':this.DailyBatchStatus[index]['repname'],
              'SubMobile':this.DailyBatchStatus[index]['submobile'],
              'SubName':this.DailyBatchStatus[index]['subname'],
              'SubEmail':this.DailyBatchStatus[index]['subemail'],
              'SubId':this.DailyBatchStatus[index]['subid']
            })
          }

          //console.log(this.subscriberTableData)
          this.datalength = Object.keys(this.subscriberTableData).length 
          if (Object.keys(this.subscriberTableData).length == 0)
          {
            this.dataLoaded=true;
          }
          else{
            this.dataLoaded=false;
          }
          //this.collection.data = this.DailyBatchStatus
        })
    } */



    selectDailydata()
    {
        //console.log(_value)
        //console.log(formatDate(new Date(this.date.value), "yyyy-MM-dd", "en-US", "GMT+0530") );
        let tofrmtdate;
        let fromfrmtdate;
          this.subscriberTableData=[];
          this.datalength = Object.keys(this.subscriberTableData).length 
          tofrmtdate = formatDate(new Date(this.todate.value), "yyyy-MM-dd", "en-US", "GMT+0530")
          fromfrmtdate = formatDate(new Date(this.fromdate.value), "yyyy-MM-dd", "en-US", "GMT+0530")


        let id = parseInt(this.form.get('website').value);
          this.service.getNewSubscibersDataDateWise(id, tofrmtdate , fromfrmtdate).subscribe((res : any[]) => {
            this.DailyBatchStatus = res;
            for (let index = 0; index < Object.keys(this.DailyBatchStatus).length; index++) {
              
              this.subscriberTableData.push({
                'RepName':this.DailyBatchStatus[index]['repname'],
                'SubMobile':this.DailyBatchStatus[index]['submobile'],
                'SubName':this.DailyBatchStatus[index]['subname'],
                'SubEmail':this.DailyBatchStatus[index]['subemail'],
                'SubId':this.DailyBatchStatus[index]['subid']
              })
            }
            this.datalength = Object.keys(this.subscriberTableData).length 
            if (Object.keys(this.subscriberTableData).length == 0)
            {
              this.dataLoaded=true;
            }
            else{
              this.dataLoaded=false;
            }
          })

        


        /* this.service.getPortalWiseCollectionDetails(tofrmtdate,fromfrmtdate).subscribe((res : any[]) => {
          this.DailyBatchStatus = res;
          //console.log(this.DailyBatchStatus);
          
          for (let index = 0; index < Object.keys(this.DailyBatchStatus).length; index++) {
            
            this.tableData.push({
              'Dates': this.DailyBatchStatus[index]['dt'],
              'Name':this.DailyBatchStatus[index]['name'],
              'Commission':this.DailyBatchStatus[index]['comission'],
              'Collected Amount':this.DailyBatchStatus[index]['collectedamt'],
              'Submitted Amount':this.DailyBatchStatus[index]['submittedamt'],
              'Outstanding Payment':this.DailyBatchStatus[index]['outstandingamt'],
              'Payment Status':this.DailyBatchStatus[index]['payment_details']
            })
          }

          //console.log(this.tableData)
          this.datalength = Object.keys(this.tableData).length 
          if (Object.keys(this.tableData).length == 0)
          {
            this.dataLoaded=true;
          }
          else{
            this.dataLoaded=false;
          }
          //this.collection.data = this.DailyBatchStatus
        })*/



    }

    action(event)
    {
      
    } 
  }
