import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DigisubservicesService } from 'src/app/services/digisubservices.service';

@Component({
  selector: 'app-update-issue-dates',
  templateUrl: './update-issue-dates.component.html',
  styleUrls: ['./update-issue-dates.component.scss'],
})
export class UpdateIssueDatesComponent implements OnInit {
  updateForm: FormGroup;
  item;

  constructor(
    private dialogref: MatDialogRef<UpdateIssueDatesComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    private service: DigisubservicesService
  ) {
    this.updateForm = fb.group({
      issueName: [''],
      issueDate: [''],
      PublishedDate: [''],
      price: [''],
      lang: [''],
    });
  }

  ngOnInit(): void {
    this.item = this.data['val'];

    this.updateForm.get('issueName').setValue(this.item['issueName']);
    this.updateForm.get('issueDate').setValue(this.item['issueDate']);
    this.updateForm.get('PublishedDate').setValue(this.item['PublishedDate']);
    this.updateForm.get('price').setValue(this.item['price']);
    this.updateForm.get('lang').setValue(this.item['lang']);
  }

  updateData() {
    let data = {
      id: this.item['id'],
      issueName: this.updateForm.get('issueName').value,
      issueDate: this.updateForm.get('issueDate').value,
      PublishedDate: this.updateForm.get('PublishedDate').value,
      price: this.updateForm.get('price').value,
      lang: this.updateForm.get('lang').value,
    };

    this.service.updateIssueDate(data).subscribe((res) => {
      this.dialogref.close({ res: 'close' });
    });
  }
}
