<div mat-dialog-content>
    <div class="text-center">
        <h1 style="font-size: medium;font-weight: bold;">Total Outstanding</h1>
    </div>

    <form class="form" [formGroup]="ionicForm" (ngSubmit)=submitForm()>

        <div class="form-row">
            <label style=" margin-top: 5%;    margin-right: 6%; margin-left: 10%;">Receipt Number : </label>
            <label style=" margin-top: 5%;"> {{this.rep_receipt}} </label>
        </div>

        <div class="form-row">
            <label style=" margin-top: 5%;    margin-right: 6%; margin-left: 10%;">Voucher Number : </label>
            <label style=" margin-top: 5%;"> {{this.voucher_no}} </label>
        </div>

        <div class="form-row">
            <label style=" margin-top: 5%;    margin-right: 6%;">Previous Outstanding : </label>
            <label style=" margin-top: 5%;">₹ {{this.previousoutstandng}} </label>
            <!-- <input  style="width: 90%;" value="{{this.previousoutstandng}}" type="text" readonly class="form-control" placeholder=""> -->
        </div>
        <!-- form-group end.// -->

        <div class="form-row">
            <label style=" margin-top: 5%;     margin-right: 5%;">Previous commission : </label>
            <label style=" margin-top: 5%;">₹ {{this.prevcommamt}}</label>
            <!-- <input style="width: 90%;" value="{{this.prevcommamt}}" readonly type="text" class="form-control" placeholder=""/> -->
        </div>


        <div class="form-row">
            <label style=" margin-top: 5%; margin-right: 37%;">Cash : </label>
            <label style=" margin-top: 5%;">₹ {{this.cashamt}}</label>
            <!-- <input readonly style="width: 90%; " value="{{this.cashamt}}" type="text" class="form-control" placeholder=""> -->
        </div>
        <!-- form-group end.// -->

        <div class="form-row">
            <label style=" margin-top: 5%;margin-right: 32%;">Cheque :</label>
            <label style=" margin-top: 5%;">₹ {{this.chequeamt}}</label>
            <!-- <input readonly style="width: 90%; " value="{{this.chequeamt}}" type="text" class="form-control" placeholder=""> -->
        </div>


        <div class="form-row">
            <label style=" margin-top: 5%; margin-right: 35%;">Online :</label>
            <label style=" margin-top: 5%;">₹ {{this.onlineamt}}</label>
            <!-- <input readonly style="width: 90%; " value="{{this.onlineamt}}" type="text" class="form-control" placeholder=""> -->
        </div>
        <!-- form-group end.// -->

        <div class="form-row">
            <label style=" margin-top: 5%;margin-right: 20%;">Total Amount :</label>
            <label style=" margin-top: 5%;">₹ {{this.totalamt}}</label>
            <!-- <input readonly style="width: 90%;" value="{{this.totalamt}}" type="text" class="form-control" placeholder=""> -->
        </div>


        <div class="form-row">
            <label style=" margin-top: 5%;margin-right: 26%;">Comission : </label>
            <label style=" margin-top: 5%;">₹ {{this.commission}}</label>
            <!-- <input readonly style="width: 90%;" value="{{this.commission}}" type="number" class="form-control"> -->
        </div>
        <!-- form-group end.// -->


        <div class="form-row">
            <label style=" margin-top: 5%;margin-right: 15%;">Net Outstanding : </label>
            <label style=" margin-top: 5%;">₹ {{this.netoutstanding}}</label>
            <!-- <input readonly  style="width: 90%;" value="{{this.netoutstanding}}" type="number" class="form-control" placeholder=""> -->
        </div>
        <!-- form-group end.//  (change)="getSalePrice($event)" -->





        <div class="form-row">
            <label style="margin-top: 20px;">Payment Mode : </label>
            <select style="width: 90%;" class="form-control" (change)="getChangedPaymentmode($event)"
                placeholder="Select One" formControlName="paymentmode" required>
                <option value="part">Part Payment</option>
                <option value="whole">Whole Payment</option>
            </select>
        </div>
        <!-- form-group end.// -->


        <div class="form-row" *ngIf="this.displaypart">
            <label style="margin-top: 20px;">Partial Amount : </label>
            <input style="width: 90%;" (change)="getPartAmount($event)" formControlName="partamt" type="number"
                class="form-control">
        </div>



        <div class="form-row">
            <label style="margin-top: 20px;">Closing Outstanding : </label>
            <input readonly style=" width: 90%;" formControlName="clsoutstnd" type="number" class="form-control"
                placeholder="">
        </div>

        <div class="form-row">
            <label style="margin-top: 20px;">Comments : </label>
            <textarea style=" width: 90%;" formControlName="comment" type="text" class="form-control"
                placeholder=""> </textarea>
        </div>


        <div mat-dialog-actions style="float:right">
            <button mat-button mat-dialog-close>Close</button>
            <button [disabled]="ionicForm.invalid" mat-button type="submit">Confirm</button>
        </div>
        <!-- form-group// -->


    </form>

</div>