import { formatDate } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { DigisubservicesService } from 'src/app/services/digisubservices.service';
import { EncdecriptService } from 'src/app/services/encdecrypt.service';

@Component({
  selector: 'app-update-plublish-dates',
  templateUrl: './update-plublish-dates.component.html',
  styleUrls: ['./update-plublish-dates.component.scss']
})
export class UpdatePlublishDatesComponent implements OnInit {

  newDateValue
  newDateArray
  publishedDates=[]
  advt_dtl_id
  manage_mode
  issue_date_mode
  constructor(private router: Router, private formBuilder: FormBuilder, public service: DigisubservicesService,
    private dialogRef: MatDialogRef<UpdatePlublishDatesComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, public encr: EncdecriptService, private _snackBar: MatSnackBar) 
    { 

    }

  ngOnInit(): void {
    console.log(this.data)
    this.publishedDates = this.data['published_dates'];
    this.newDateArray = this.publishedDates;
    this.advt_dtl_id = this.data['advt_dtl_id'];
    this.manage_mode = this.data['manage_mode'];
    this.issue_date_mode = this.data['issue_date_mode'];
  }

  getChangedDate(event) {
    if (window.confirm("Do you really want to add the date?")) {
      console.log(event.value)
      this.newDateValue = formatDate(event.value, "yyyy-MM-dd", "en-IN", "GMT+0530")
      console.log(this.newDateValue)
      //this.update()

      this.newDateArray.push(this.newDateValue)
      this.updateDetails(this.newDateArray.sort().toString())
    }

  }

  
  update_date(i)
  {
    let sampleDate = formatDate(new Date(), "yyyy-MM-dd", "en-IN", "GMT+0530")
    let promptData = window.prompt("Please Enter New Date in YYYY-MM-DD format",sampleDate)
    let newDate
    console.log(promptData)
    if (promptData == null)
    {
      window.alert("No data has been changed!")
    }else 
    {
      console.log(new Date(promptData))
      newDate = formatDate(promptData, "yyyy-MM-dd", "en-IN", "GMT+0530")
      console.log(newDate)
      for (let index = 0; index < this.newDateArray.length; index++) {
      
        if(index == i)
        {
          this.newDateArray[index] = newDate
        }
    }

    console.log(this.newDateArray.sort())
    this.updateDetails(this.newDateArray.sort().toString())
    }

    
  }

  remove_date(i)
  {
    console.log(i)

    this.newDateArray.splice(i , 1)
    this.updateDetails(this.newDateArray.sort().toString())
    
  }


  updateDetails(dates)
  {
    if(this.manage_mode)
    {
        this.service.updateAdvtMagDates(this.advt_dtl_id , dates).subscribe(data => {
          console.log(data)
          window.alert("Data has been updated!")
        })
    }else if(this.issue_date_mode)
    {
      this.service.updateAdvtIssueDates(this.advt_dtl_id , dates).subscribe(data=>{
        console.log(data)
  
        window.alert("Data has been updated!")
      })
    }else 
    {
      this.service.updateAdvtPublishDates(this.advt_dtl_id , dates).subscribe(data=>{
        console.log(data)
  
        window.alert("Data has been updated!")
      })
    }
    
  }


}
