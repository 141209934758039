<div class="example-small-box1 mat-elevation-z4" >
  <form [formGroup]="form" (ngSubmit)="selectDailydata()" >
    <mat-form-field style="margin-top: 2em;margin-left: 2em;">
      <mat-label>Select Representative</mat-label>
      <mat-select formControlName="website">
        <mat-option *ngFor="let website of subscribers" [value]="website.value">
          {{website.viewValue}}
        </mat-option>
      </mat-select>
      <mat-error *ngIf="form.get('website').hasError('required')">
          Please Select Representative
      </mat-error>
    </mat-form-field>

    <!-- <mat-form-field style="margin-top: 2em;margin-left: 2em;">
      <input matInput [matDatepicker]="picker1" placeholder="Select Date" (dateChange)="selectDate($event)" 
      [formControl]="date1">
      <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
      <mat-datepicker #picker1></mat-datepicker>
    </mat-form-field> -->

    <mat-form-field style="margin-top: 2em;margin-left: 2em;">
      <input matInput [matDatepicker]="picker1" placeholder="To Date"  [formControl]="todate">
      <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
      <mat-datepicker #picker1></mat-datepicker>
    </mat-form-field>
    
    <mat-form-field style="margin-top: 2em;margin-left: 2em;">
      <input matInput [matDatepicker]="picker2" placeholder="From Date"  [formControl]="fromdate">
      <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
      <mat-datepicker #picker2></mat-datepicker>
    </mat-form-field>

    <div style="margin-top: 1em;margin-left: 2em;">
      <button mat-raised-button color="primary" (click)="selectDailydata()">get Report</button>
    </div>

    <div *ngIf="datalength > 0">
      <app-editable-table 
        [columns]="['RepName', 'SubMobile', 'SubName', 'SubEmail', 'SubId']"
        [editableColumns] = "[]"
        [dateColumns] ="[]"
        [data]="subscriberTableData" 
        [pageSizeOptions]="[5,6,9,12,20,50,100]" 
        [searchable]="true" 
        (action)="action($event)"
        [notification]="yourMessage"
        [maxChar]="23"
        >
        
      </app-editable-table>
      </div>
    
      <div *ngIf="dataLoaded">
            <h1>There is No Data Available for this date. Please select working date.</h1>
      </div>
    
      
    
  </form>
</div>